import { connect } from 'react-redux';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

import { compsClearFilter, compsFilterDistanceChange } from 'actions/comps';
import {
  rentalCompsClearFilter,
  rentalCompsFilterDistanceChange
} from 'actions/rental-comps';

import {
  getCompsDistanceFilterValue,
  getCompsSubject,
  getCompsMapId,
  getCompsViews
} from 'selectors/comps-context';
import { getIsRentalContext } from 'selectors/property-explorer';

import DistanceControl from 'legacy/components/DistanceControl';

const mapStateToProps = (state) => {
  const subject = getCompsSubject(state);
  return {
    ...getCompsViews(state),
    isRentalContext: getIsRentalContext(state),
    mapId: getCompsMapId(state),
    geoLocationRef: subject.geoLocation,
    value: getCompsDistanceFilterValue(state),
    options: [
      {
        label: 'Any',
        value: undefined
      },
      {
        label: '0.5 Mile',
        value: 0.5
      },
      {
        label: '1 Mile',
        value: 1
      },
      {
        label: '2 Miles',
        value: 2
      },
      {
        label: '3 Miles',
        value: 3
      }
    ]
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isRentalContext, mapId, geoLocationRef } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    onClickDraw: () => {
      dispatchProps.routeChange({
        view: stateProps.viewMap,
        options: { inherit: true }
      });
    },
    onChange: (value) => {
      return isRentalContext
        ? !value
          ? dispatchProps.rentalCompsClearFilter('distance', mapId)
          : dispatchProps.rentalCompsFilterDistanceChange(
              mapId,
              value,
              geoLocationRef
            )
        : !value
        ? dispatchProps.compsClearFilter('distance', mapId)
        : dispatchProps.compsFilterDistanceChange(mapId, value, geoLocationRef);
    }
  };
};

export default connect(
  mapStateToProps,
  {
    routeChange,
    compsClearFilter,
    compsFilterDistanceChange,
    rentalCompsFilterDistanceChange,
    rentalCompsClearFilter
  },
  mergeProps
)(DistanceControl);
