import { watchEvery } from 'legacy/utils/saga';
import { call, put, select } from 'redux-saga/effects';

import HC_CONSTANTS from 'HC_CONSTANTS';

import apiUtil from 'legacy/utils/api';

import {
  GET_REPORT_NEARBY_PROPERTIES,
  getReportNearbyPropertiesSuccess,
  getReportNearbyPropertiesFailure
} from 'actions/get-report';

import { getSubjectAdjusted } from 'selectors/subject';

export function* fetchNearbyProperties() {
  try {
    const subject = yield select(getSubjectAdjusted);
    const response = yield call(
      apiUtil.POST,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/comps`,
      {
        okToSplit: true,
        limit: 1000,
        subject,
        addressIdsToSkip: []
      }
    );
    const { compsFarmList } = response;
    if (compsFarmList && compsFarmList.length) {
      yield put(getReportNearbyPropertiesSuccess(compsFarmList));
    } else {
      yield put(getReportNearbyPropertiesFailure());
    }
  } catch (e) {
    yield put(getReportNearbyPropertiesFailure());
  }
}

function* handleGetNearbyProperties(action) {
  yield call(fetchNearbyProperties);
}

export default function registerCompsPhotosSaga() {
  watchEvery({
    [GET_REPORT_NEARBY_PROPERTIES]: handleGetNearbyProperties
  });
}
