import PropTypes from 'prop-types';
import { Dialog } from 'legacy/components/Dialog';
import { Button, FlatButton } from 'legacy/components/Button';

import { ErrorModal as theme } from 'legacy/css-modules';
const ErrorModal = ({
  open,
  message,
  messageSecondary,
  helpLink,
  helpLinkLabel,
  isDismissible,
  actionType,
  actionLabel,
  onClose,
  onErrorActionClick,
  onReload
}) => {
  return (
    <Dialog
      title={message}
      active={open}
      onClose={isDismissible ? onClose : undefined}
    >
      {messageSecondary}
      <div className={theme.ButtonWrapper}>
        {actionType && (
          <FlatButton onClick={() => onErrorActionClick(actionType)} primary>
            {actionLabel}
          </FlatButton>
        )}
        {!!helpLink && (
          <Button href={helpLink} target="help" primary>
            {helpLinkLabel}
          </Button>
        )}
      </div>
      <div className="error-modal-timestamp">{new Date().toString()}</div>
    </Dialog>
  );
};

ErrorModal.defaultProps = {
  open: false
};

ErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,

  open: PropTypes.bool
};

export default ErrorModal;
