export const FORM_NAME = 'propertyExplorerPropertyDetails';
export const FIELD_NAMES = [
  'grossLivingAreaSqft',
  'siteAreaSqft',
  'bedrooms',
  'bathrooms',
  'propertyType',
  'isOwnerOccupied',
  'yearBuilt',
  'basement',
  'quality'
];
/* `null` is a valid option for these properties. The values of these properties
 * should by stringified before setting Redux Form and parsed before sending to API
 */
export const FIELD_VALUES_TO_PARSE = ['isOwnerOccupied', 'basement'];
