import React, { Component, Fragment } from 'react';
import { Input } from 'legacy/components/Input';
import { FormError } from '@hc/component-lib/hclib/components/atoms/form-error';
import { FilterRange as theme } from 'legacy/css-modules';

import { FILTER_SAVE_TYPES } from 'legacy/appstore/constants';

import { isFilterSet } from 'legacy/utils/filters';
import { isPopulated } from 'legacy/utils/utils';

const _prependPlusMinus = (v, display) =>
  v > 0 ? `+${display}` : v < 0 ? `-${display}` : v === 0 ? '0' : '-';

const formatRelative = {
  [FILTER_SAVE_TYPES.REL_EXACT]: (v) => _prependPlusMinus(v, Math.abs(v)),
  [FILTER_SAVE_TYPES.REL_PCT]: (v) =>
    _prependPlusMinus(v, `${Math.round(Math.abs(v) * 100)}%`)
};

class FilterRange extends Component {
  static defaultProps = {
    relativeType: FILTER_SAVE_TYPES.REL_EXACT
  };

  state = {
    minVal: null,
    maxVal: null,
    minFocused: false,
    maxFocused: false,
    minValDisplay: '',
    maxValDisplay: ''
  };
  componentDidMount() {
    this.setValuesFromProps();
  }

  componentDidUpdate(prevProps) {
    if (
      // Filter is set
      (this.props.absoluteValue && !prevProps.absoluteValue) ||
      // Filter is cleared
      (!this.props.absoluteValue && prevProps.absoluteValue) ||
      // Filter is changed
      (this.props.absoluteValue &&
        prevProps.absoluteValue &&
        (this.props.absoluteValue[0] !== prevProps.absoluteValue[0] ||
          this.props.absoluteValue[1] !== prevProps.absoluteValue[1]))
    ) {
      this.setValuesFromProps();
    }
  }

  setValuesFromProps = (props) => {
    const {
      absoluteValue,
      min,
      max,
      float,
      formatter = (v) => v,
      adjuster
    } = this.props;
    let minVal = null;
    let maxVal = null;
    let minValDisplay = '';
    let maxValDisplay = '';
    if (absoluteValue && absoluteValue.length === 2) {
      minVal = ![null, ''].includes(absoluteValue[0])
        ? float
          ? Math.max(min, absoluteValue[0])
          : Math.round(Math.max(min, absoluteValue[0]))
        : null;
      maxVal = ![null, ''].includes(absoluteValue[1])
        ? float
          ? absoluteValue[1]
          : Math.round(absoluteValue[1])
        : null;
      minValDisplay =
        minVal === null
          ? null
          : minVal < min
          ? min
          : minVal > max
          ? max
          : minVal;
      maxValDisplay =
        maxVal === null
          ? null
          : maxVal < min
          ? min
          : maxVal > max
          ? max
          : maxVal;
    }
    maxVal = adjuster ? adjuster(maxVal, this.props.dataHcName) : maxVal;
    this.setState(
      {
        // Adjust display values if applying the filter from props
        // to support +/- quick filters after a subject is adjusted
        minValDisplay: isFilterSet(minValDisplay)
          ? formatter(minValDisplay)
          : '',
        maxValDisplay: isFilterSet(maxValDisplay)
          ? formatter(maxValDisplay)
          : '',
        minVal,
        maxVal
      },
      this.handleErrorChecking
    );
  };

  adjustMaxValForYearFilter = (val, currYear) =>
    val > currYear ? currYear : val;

  parseInputValue = (v, forceParseFloat) => {
    const { float, max, min } = this.props;
    let parsedVal = '';
    if (v && v !== '') {
      // Allow entering of a '.' for decmial ranges
      if (float && v[v.length - 1] === '.' && !forceParseFloat) {
        parsedVal = v;
      } else {
        parsedVal = float ? parseFloat(v) : parseInt(v);

        if (isNaN(parsedVal)) {
          parsedVal = '';
        } else {
          if (min) {
            parsedVal = Math.max(min, parsedVal);
          }
          if (max) {
            parsedVal = Math.min(max, parsedVal);
          }
        }
      }
    }
    return parsedVal;
  };

  handleFocusMin = () => {
    this.setState({ minFocused: true });
  };

  handleFocusMax = () => {
    this.setState({ maxFocused: true });
  };

  handleChangeMinVal = (minVal) => {
    this.setState({
      minVal: this.parseInputValue(minVal)
    });
  };

  handleChangeMaxVal = (maxVal) => {
    this.setState({
      maxVal: this.parseInputValue(maxVal)
    });
  };

  handleErrorChecking = () => {
    const { minVal, maxVal } = this.state;
    this.setState({
      error:
        isPopulated(minVal) &&
        isPopulated(maxVal) &&
        minVal > maxVal &&
        'There is a problem with this filter! The minimum value is greater than the maximum value.'
    });
  };

  handleBlur = () => {
    const minVal = this.parseInputValue(this.state.minVal, true);
    const maxVal = this.parseInputValue(this.state.maxVal, true);
    const absoluteValue = [
      isPopulated(minVal) ? minVal : null,
      isPopulated(maxVal) ? maxVal : null
    ];
    this.props.onChangeAbsolute(absoluteValue);
    this.setState(
      {
        minFocused: false,
        maxFocused: false
      },
      this.handleErrorChecking
    );
  };

  render() {
    const { dataHcName, relativeValue, relativeType, disabled } = this.props;
    const {
      minFocused,
      maxFocused,
      minVal,
      maxVal,
      minValDisplay,
      maxValDisplay,
      error
    } = this.state;
    return (
      <Fragment>
        <div className={theme.FilterRange}>
          <div className={theme.InputCell}>
            <Input
              placeholder="Min"
              value={minFocused ? minVal : minValDisplay}
              className={theme.FilterInput}
              theme={theme}
              dataHcName={`${dataHcName}-min-field`}
              onChange={this.handleChangeMinVal}
              onFocus={this.handleFocusMin}
              onBlur={this.handleBlur}
              disabled={disabled}
            />
            <div className={theme.RelativeValue}>
              {formatRelative[relativeType](relativeValue && relativeValue[0])}{' '}
              from subject
            </div>
          </div>
          <div className={theme.Hyphen}> — </div>
          <div className={theme.InputCell}>
            <Input
              placeholder="Max"
              value={maxFocused ? maxVal : maxValDisplay}
              className={theme.FilterInput}
              theme={theme}
              dataHcName={`${dataHcName}-max-field`}
              onChange={this.handleChangeMaxVal}
              onFocus={this.handleFocusMax}
              onBlur={this.handleBlur}
              disabled={disabled}
            />
            <div className={theme.RelativeValue}>
              {formatRelative[relativeType](relativeValue && relativeValue[1])}{' '}
              from subject
            </div>
          </div>
        </div>
        {error && (
          <div className={theme.Error}>
            <FormError value={error} />
          </div>
        )}
      </Fragment>
    );
  }
}

export default FilterRange;
