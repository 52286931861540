import classNames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';
import { Skeleton, Props as SkeletonProps } from './Skeleton';
import styles from './SkeletonFlex.css';

interface Props extends CSSProperties {
  dataHcName: string;
  numCells: number;
  className?: string;
  style?: CSSProperties;
  skeletonProps?: Omit<SkeletonProps, 'dataHcName'>;
  cellWidth?: CSSProperties['width'];
}
export const SkeletonFlex = ({
  dataHcName,
  className,
  style = {},
  numCells,
  skeletonProps,
  cellWidth,
  ...styleProps // The rest of the props are any css styles
}: Props) => {
  const flexStyle = { ...style, ...styleProps };
  const cells: ReactNode[] = [];
  for (let i = 0; i < numCells; i++) {
    const key = `${dataHcName}-${i}`;
    cells.push(
      <div
        key={key}
        className={styles.Cell}
        style={cellWidth ? { flex: `0 1 ${cellWidth}` } : undefined}
      >
        <Skeleton type="text" {...(skeletonProps || {})} dataHcName={key} />
      </div>
    );
  }
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.SkeletonFlex, className)}
      style={flexStyle}
    >
      {cells}
    </div>
  );
};
