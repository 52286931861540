import { STATUSES } from 'src/constants';
import {
  HuellActions,
  PREFERENCES_FETCH,
  PREFERENCES_FETCH_ERROR,
  PREFERENCES_FETCH_SUCCESS,
  PREFERENCES_PATCH,
  PREFERENCES_PATCH_SUCCESS
} from '../actions';
import { Preferences } from '../types';

interface PreferencesStateLoading {
  status: STATUSES.LOADING;
  fetching: true;
}

interface PreferencesStateSuccess {
  status: STATUSES.SUCCESS;
  fetching: boolean;
  data: Preferences;
}

interface PreferencesStateError {
  status: STATUSES.ERROR;
  fetching: boolean;
}

export type PreferencesState =
  | PreferencesStateLoading
  | PreferencesStateSuccess
  | PreferencesStateError
  | null;

const INITIAL_STATE = null;

export const preferencesReducer = <A>(
  state: PreferencesState = INITIAL_STATE,
  action: A & HuellActions
): PreferencesState => {
  switch (action.type) {
    case PREFERENCES_FETCH: {
      if (!state) {
        return {
          status: STATUSES.LOADING,
          fetching: true
        };
      } else {
        return {
          ...state,
          fetching: true
        };
      }
    }

    case PREFERENCES_FETCH_SUCCESS: {
      return {
        status: STATUSES.SUCCESS,
        fetching: false,
        data: action.payload.preferences
      };
    }

    case PREFERENCES_FETCH_ERROR: {
      return {
        status: STATUSES.ERROR,
        fetching: false
      };
    }

    case PREFERENCES_PATCH: {
      if (state) {
        return {
          ...state,
          fetching: true
        };
      } else {
        return {
          status: STATUSES.LOADING,
          fetching: true
        };
      }
    }

    case PREFERENCES_PATCH_SUCCESS: {
      return {
        status: STATUSES.SUCCESS,
        fetching: false,
        data: action.payload.preferences
      };
    }

    default:
      return state;
  }
};
