import {
  PROPERTY_DETAILS_TYPE_GENERAL,
  PROPERTY_DETAILS_TYPE_SOURCES
} from 'legacy/appstore/constants';
import { EVENTS } from 'legacy/appstore/events';

import { getAnalyticsSubjectAddressSlug } from 'selectors/analytics';

export const PROPERTY_QUERY_REQUEST = 'PROPERTY_QUERY_REQUEST';
export const PROPERTY_QUERY_SUCCESS = 'PROPERTY_QUERY_SUCCESS';
export const PROPERTY_VIEW_DETAILS = 'PROPERTY_VIEW_DETAILS';
export const PROPERTY_VIEW_SOURCES = 'PROPERTY_VIEW_SOURCES';
export const PROPERTY_QUERY_DETAILS_BASIC = 'PROPERTY_QUERY_DETAILS_BASIC';
export const PROPERTY_QUERY_DETAILS_EXTENDED =
  'PROPERTY_QUERY_DETAILS_EXTENDED';
export const PROPERTY_QUERY_DETAILS_COMPLETE =
  'PROPERTY_QUERY_DETAILS_COMPLETE';
export const PROPERTY_QUERY_FEATURES_FOR_SOURCE =
  'PROPERTY_QUERY_FEATURES_FOR_SOURCE';
export const PROPERTY_QUERY_MLS_HISTORY = 'PROPERTY_QUERY_MLS_HISTORY';
export const PROPERTY_QUERY_TRANSACTION_HISTORY =
  'PROPERTY_QUERY_TRANSACTION_HISTORY';

export const propertyQueryRequest = (addressId, requests) => {
  // Generic action to trigger a graphql request and update loading statuses
  return {
    type: PROPERTY_QUERY_REQUEST,
    payload: { addressId, requests }
  };
};

export const propertyQuerySuccess = (addressId, graphQLResponse) => {
  // Generic action that can handle any successful property graphql response
  return {
    type: PROPERTY_QUERY_SUCCESS,
    payload: { addressId, graphQLResponse }
  };
};

export const propertyViewSources = (addressId) => {
  return {
    type: PROPERTY_VIEW_SOURCES,
    payload: { addressId },
    meta: {
      router: {
        options: { inherit: true },
        query: {
          visiblePropertyDetailsType: PROPERTY_DETAILS_TYPE_SOURCES,
          addressIdPropertyDetails: addressId
        }
      },
      analytics: {
        event: EVENTS.REPORT_VIEW_SUBJECT_SOURCES,
        propsSelector: getAnalyticsSubjectAddressSlug
      }
    }
  };
};

export const propertyQueryDetailsBasic = (
  { addressId, addressSlug, streetAddress, zipcode, unit },
  queryFeatures
) => {
  return {
    type: PROPERTY_QUERY_DETAILS_BASIC,
    payload: {
      addressId,
      addressSlug,
      streetAddress,
      zipcode,
      unit,
      queryFeatures
    }
  };
};

export const propertyViewDetails = (addressId) => {
  return {
    type: PROPERTY_VIEW_DETAILS,
    payload: { addressId },
    meta: {
      router: {
        options: { inherit: true },
        query: {
          visiblePropertyDetailsType: PROPERTY_DETAILS_TYPE_GENERAL,
          addressIdPropertyDetails: addressId
        }
      },
      analytics: {
        event: EVENTS.REPORT_VIEW_SUBJECT_DETAILS,
        propsSelector: getAnalyticsSubjectAddressSlug
      }
    }
  };
};

export const propertyQueryDetailsExtended = (addressId) => {
  return {
    type: PROPERTY_QUERY_DETAILS_EXTENDED,
    payload: {
      successAction: propertyQuerySuccess,
      addressId
    }
  };
};

export const propertyQueryDetailsComplete = (addressId) => {
  // Fetch basic and extended property details from all sources
  return {
    type: PROPERTY_QUERY_DETAILS_COMPLETE,
    payload: {
      successAction: propertyQuerySuccess,
      addressId
    }
  };
};

export const propertyQueryFeaturesForSource = (addressId, source) => {
  // Fetch property features for a specific source
  return {
    type: PROPERTY_QUERY_FEATURES_FOR_SOURCE,
    payload: {
      successAction: propertyQuerySuccess,
      addressId,
      source
    }
  };
};

export const propertyQueryMlsHistory = (addressId) => {
  return {
    type: PROPERTY_QUERY_MLS_HISTORY,
    payload: { addressId }
  };
};

export const propertyQueryTransactionHistory = (addressId) => {
  return {
    type: PROPERTY_QUERY_TRANSACTION_HISTORY,
    payload: { addressId }
  };
};
