import { watchEvery } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { all, call, put, select } from 'redux-saga/effects';
import get from 'lodash/get';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { PREFERENCES_KEYS } from 'legacy/appstore/constants';

import apiUtil from 'legacy/utils/api';
import { FILTERS } from 'legacy/utils/filters';
import { prepFiltersToSaveForApi } from 'legacy/utils/saved-filters';

import {
  preferencesFetchSuccess,
  preferencesFetchFailure,
  preferencesSaveSuccess,
  preferencesSaveFailure,
  PREFERENCES_FETCH,
  PREFERENCES_SAVE
} from 'actions/preferences';
import {
  transformUiPreferences,
  transformCompsFilters
} from 'legacy/utils/filter-conversion.utils';
import {
  COMPS_FILTERS_SAVE,
  COMPS_DELETE_SAVED_FILTER,
  COMPS_CHANGE_FILTER_SET_PRIMARY
} from 'actions/comps';
import {
  RENTAL_COMPS_FILTERS_SAVE,
  RENTAL_COMPS_DELETE_SAVED_FILTER,
  RENTAL_COMPS_CHANGE_FILTER_SET_PRIMARY
} from 'actions/rental-comps';

import { getIsLoggedIn } from 'selectors/auth';
import { getCompsFilters } from 'selectors/comps';
import { getRentalCompsFilters } from 'selectors/rental-comps';
import { getPropertyLookupId } from 'selectors/report-data';
import {
  getPreferencesValues,
  getPreferencesFiltersComps,
  getPreferencesFiltersRentalComps
} from 'selectors/preferences';
import {
  convertPropertyTypeLegacyToNew,
  convertPropertyTypeNewToLegacy
} from '../../utils/effective-date.utils';
import { transform } from 'lodash';
const RENTAL_FIELD_TRANSFORM_MAPPING_NEW_OLD = {
  closeDateRental: 'leasedDate',
  closePriceRental: 'leasedPrice',
  listPriceRental: 'lastListPriceRental'
};

const RENTAL_FIELD_TRANSFORM_MAPPING_OLD_NEW = {
  leasedDate: 'closeDateRental',
  leasedPrice: 'closePriceRental',
  lastListPriceRental: 'listPriceRental'
};
const URL_PREFERENCES = `${HC_CONSTANTS.HUELL_PROXY_URL}/preferences/`;

const _oldFiltersToNew = (oldFilterContract = {}) => {
  const compFiltersPayload = {};
  Object.entries(oldFilterContract.filterSets).forEach(([id, filterSet]) => {
    compFiltersPayload[id] = {
      label: filterSet.label,
      createdByReportId: filterSet.createdBy,
      values: {}
    };
    Object.entries(oldFilterContract.filterSets[id].values).forEach(
      ([compField, filterValue]) => {
        compFiltersPayload[id].values[compField] = {
          field: compField,
          relativeValue: filterValue.value
        };
      }
    );
  });
  return compFiltersPayload;
};

const _newFiltersToOld = (newFilterContract = {}, activeFilterSet = null) => {
  if (newFilterContract.statusCode) return {};
  const oldFilterContract = {
    activeFilterSet,
    filterSets: {}
  };
  Object.entries(newFilterContract).forEach(([id, filterSet]) => {
    oldFilterContract.filterSets[id] = {
      label: filterSet.label,
      createdBy: filterSet.createdByReportId,
      values: {}
    };
    Object.entries(newFilterContract[id].values).forEach(
      ([compField, filterValue]) => {
        if (FILTERS[compField]) {
          oldFilterContract.filterSets[id].values[compField] = {
            saveType: FILTERS[compField].saveType,
            value:
              compField === `propertyType` &&
              Array.isArray(filterValue.relativeValue)
                ? filterValue.relativeValue.map(convertPropertyTypeNewToLegacy)
                : filterValue.relativeValue
          };
        }
      }
    );
  });
  return oldFilterContract;
};

export function* fetchPreferences(action) {
  try {
    let preferences = transformUiPreferences(
      yield call(
        apiUtil.GET,
        URL_PREFERENCES,
        {},
        { expect404: true, skipTransform: true }
      ),
      { transformToOld: true }
    );
    const [reportPreferences, savedCompFilters, savedRentalCompFilters] =
      yield all([
        yield call(
          apiUtil.GET,
          `${HC_CONSTANTS.HUELL_PROXY_URL}/report_preferences/`,
          {},
          { expect404: true, skipTransform: true }
        ),
        transformCompsFilters(
          yield call(
            apiUtil.GET,
            `${HC_CONSTANTS.HUELL_PROXY_URL}/comp_filters/`,
            { compType: 'SOLD' },
            { expect404: true, skipTransform: true }
          ),
          { transformToOld: true }
        ),
        transformCompsFilters(
          yield call(
            apiUtil.GET,
            `${HC_CONSTANTS.HUELL_PROXY_URL}/comp_filters/`,
            { compType: 'RENTAL' },
            { expect404: true, skipTransform: true }
          ),
          { transformToOld: true }
        )
      ]);

    if (
      preferences.statusCode === 404 &&
      reportPreferences.statusCode === 404 &&
      savedCompFilters.statusCode === 404 &&
      savedRentalCompFilters.statusCode === 404
    ) {
      yield put(preferencesFetchFailure(404));
    } else {
      // Transform to Old
      const savedTableRentalComps = get(preferences, [
        PREFERENCES_KEYS.TABLE_COLUMNS_RENTAL_COMPS
      ]);
      if (savedTableRentalComps) {
        if (savedTableRentalComps.order) {
          // Transform rental keys to old format before sving to redux state
          savedTableRentalComps.order = savedTableRentalComps.order.map(
            (col) => {
              return RENTAL_FIELD_TRANSFORM_MAPPING_NEW_OLD[col] || col;
            }
          );
        }
        if (savedTableRentalComps.inactive) {
          Object.entries(savedTableRentalComps.inactive).forEach(
            ([key, value]) => {
              if (RENTAL_FIELD_TRANSFORM_MAPPING_NEW_OLD[key]) {
                savedTableRentalComps.inactive[
                  RENTAL_FIELD_TRANSFORM_MAPPING_NEW_OLD[key]
                ] = value;
                delete savedTableRentalComps.inactive[key];
              }
            }
          );
        }
      }
      // Combine new preferences endpoints w/ old to create old contract (new components use a cleaner setup)
      if (
        savedCompFilters.statusCode !== 404 ||
        savedRentalCompFilters.statusCode !== 404
      ) {
        const combined = {
          ...(preferences || {})
        };
        if (!savedCompFilters.statusCode) {
          combined[PREFERENCES_KEYS.FILTERS_COMPS] = _newFiltersToOld(
            savedCompFilters,
            reportPreferences?.preferences?.compFilterSetId
          );
        }
        if (!savedRentalCompFilters.statusCode) {
          const oldFilterContract = _newFiltersToOld(
            savedRentalCompFilters,
            reportPreferences?.preferences?.rentalCompFilterSetId
          );
          // Update transformed rental filter keys
          for (const filterSetId in oldFilterContract.filterSets) {
            for (const compFieldNew in RENTAL_FIELD_TRANSFORM_MAPPING_NEW_OLD) {
              const compFieldOld =
                RENTAL_FIELD_TRANSFORM_MAPPING_NEW_OLD[compFieldNew];
              const filterSet = oldFilterContract.filterSets[filterSetId];
              if (filterSet.values[compFieldNew]) {
                filterSet.values[compFieldOld] = filterSet.values[compFieldNew];
                delete filterSet.values[compFieldNew];
              }
            }
          }
          combined[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS] = oldFilterContract;
        }
        yield put(preferencesFetchSuccess(combined));
      } else {
        yield put(preferencesFetchSuccess(preferences));
      }
    }
  } catch (e) {
    console.error(e);
    yield put(preferencesFetchFailure(e.errorCode));
    report(`Fetch Preferences Failure: ${e.message}`, { e, action });
  }
}

export function* savePreferences(action) {
  try {
    const isLoggedIn = yield select(getIsLoggedIn);
    // Only attempt to save if logged in
    if (isLoggedIn) {
      const { values, keyToDelete } = action.payload;
      const preferencesOld = yield select(getPreferencesValues);
      const postBody = {
        ...preferencesOld,
        ...values
      };
      if (keyToDelete && postBody.hasOwnProperty(keyToDelete)) {
        delete postBody[keyToDelete];
      }
      const savedTableRentalComps = get(postBody, [
        PREFERENCES_KEYS.TABLE_COLUMNS_RENTAL_COMPS
      ]);
      if (savedTableRentalComps) {
        // Transform to new
        if (savedTableRentalComps.order) {
          // Transform rental keys to old format before sving to redux state
          savedTableRentalComps.order = savedTableRentalComps.order.map(
            (col) => {
              return RENTAL_FIELD_TRANSFORM_MAPPING_OLD_NEW[col] || col;
            }
          );
        }
        if (savedTableRentalComps.inactive) {
          Object.entries(savedTableRentalComps.inactive).forEach(
            ([key, value]) => {
              if (RENTAL_FIELD_TRANSFORM_MAPPING_OLD_NEW[key]) {
                savedTableRentalComps.inactive[
                  RENTAL_FIELD_TRANSFORM_MAPPING_OLD_NEW[key]
                ] = value;
                delete savedTableRentalComps.inactive[key];
              }
            }
          );
        }
        postBody[PREFERENCES_KEYS.TABLE_COLUMNS_RENTAL_COMPS] =
          savedTableRentalComps;
      }
      const response = transformUiPreferences(
        yield call(
          apiUtil.POST,
          URL_PREFERENCES,
          transformUiPreferences(postBody, { transformToOld: false }),
          { skipTransform: true }
        ),
        { transformToOld: true }
      );
      yield put(
        preferencesSaveSuccess(
          { ...preferencesOld, ...response },
          action.payload.noToast
        )
      );
    }
  } catch (e) {
    console.error(e);
    yield put(preferencesSaveFailure(e.errorCode));
    report(`Save Preferences Failure: ${e.message}`, { e, action });
  }
}

function* handleRentalCompSaveFilters(action) {
  const effects = [];
  const { filterSetId, filterSetName, makeDefault } = action.payload;
  const propertyLookupId = yield select(getPropertyLookupId);
  const preferencesBeforeSave = yield select(getPreferencesValues);
  const filters = yield select(getRentalCompsFilters);
  const currentSavedFilters = yield select(getPreferencesFiltersRentalComps);
  const oldFilterContract = prepFiltersToSaveForApi(
    filters,
    filterSetId,
    filterSetName,
    propertyLookupId,
    makeDefault,
    currentSavedFilters
  );
  const compFiltersPayload = _oldFiltersToNew(oldFilterContract);
  for (const filterSetId in compFiltersPayload) {
    for (const compFieldOld in compFiltersPayload[filterSetId].values) {
      const compFieldNew = RENTAL_FIELD_TRANSFORM_MAPPING_OLD_NEW[compFieldOld];
      if (compFieldNew) {
        compFiltersPayload[filterSetId].values[compFieldNew] = {
          ...compFiltersPayload[filterSetId].values[compFieldOld],
          field: compFieldNew
        };
        delete compFiltersPayload[filterSetId].values[compFieldOld];
      }
    }
  }

  // Update property-type strings to format expected by huell
  if (Array.isArray(compFiltersPayload[filterSetId].values?.propertyType)) {
    compFiltersPayload[filterSetId].values.propertyType.relativeValue =
      compFiltersPayload[filterSetId].values.propertyType.relativeValue.map(
        convertPropertyTypeLegacyToNew
      );
  }
  if (makeDefault) {
    const reportPreferencesPayload = {
      rentalCompFilterSetId: filterSetId
    };
    const compFilterSetId =
      preferencesBeforeSave?.[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS]
        ?.activeFilterSet;
    // Call report_preferences
    if (compFilterSetId) {
      reportPreferencesPayload.compFilterSetId = compFilterSetId;
    }
    effects.push(
      call(
        apiUtil.POST,
        `${HC_CONSTANTS.HUELL_PROXY_URL}/report_preferences/`,
        reportPreferencesPayload,
        { skipTransform: true }
      )
    );
  }

  effects.push(
    call(
      apiUtil.POST,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/comp_filters/?compType=RENTAL`,
      transformCompsFilters(compFiltersPayload, { transformToOld: false }),
      { skipTransform: true }
    )
  );

  yield all(effects);

  const newCombinedPreferences = {
    ...preferencesBeforeSave,
    [PREFERENCES_KEYS.FILTERS_RENTAL_COMPS]: oldFilterContract
  };
  yield put(preferencesSaveSuccess(newCombinedPreferences));
}

export function* handleCompSaveFilters(action) {
  const effects = [];
  const { filterSetId, filterSetName, makeDefault } = action.payload;
  const propertyLookupId = yield select(getPropertyLookupId);
  const preferencesBeforeSave = yield select(getPreferencesValues);
  const filters = yield select(getCompsFilters);
  const currentSavedFilters = yield select(getPreferencesFiltersComps);
  const oldFilterContract = prepFiltersToSaveForApi(
    filters,
    filterSetId,
    filterSetName,
    propertyLookupId,
    makeDefault,
    currentSavedFilters
  );
  const compFiltersPayload = _oldFiltersToNew(oldFilterContract);
  // Update property-type strings to format expected by huell
  if (Array.isArray(compFiltersPayload[filterSetId].values?.propertyType)) {
    compFiltersPayload[filterSetId].values.propertyType.relativeValue =
      compFiltersPayload[filterSetId].values.propertyType.relativeValue.map(
        convertPropertyTypeLegacyToNew
      );
  }

  if (makeDefault) {
    const reportPreferencesPayload = {
      compFilterSetId: filterSetId
    };
    const rentalCompFilterSetId =
      preferencesBeforeSave?.[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS]
        ?.activeFilterSet;
    // Call report_preferences
    if (rentalCompFilterSetId) {
      reportPreferencesPayload.rentalCompFilterSetId = rentalCompFilterSetId;
    }
    effects.push(
      call(
        apiUtil.POST,
        `${HC_CONSTANTS.HUELL_PROXY_URL}/report_preferences/`,
        reportPreferencesPayload,
        { skipTransform: true }
      )
    );
  }

  effects.push(
    call(
      apiUtil.POST,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/comp_filters/?compType=SOLD`,
      transformCompsFilters(compFiltersPayload, { transformToOld: false }),
      { skipTransform: true }
    )
  );

  yield all(effects);

  const newCombinedPreferences = {
    ...preferencesBeforeSave,
    [PREFERENCES_KEYS.FILTERS_COMPS]: oldFilterContract
  };
  yield put(preferencesSaveSuccess(newCombinedPreferences));
}

export function* handleDeleteSavedFilterComps(action) {
  const { filterSetId } = action.payload;
  const oldPreferences = { ...(yield select(getPreferencesValues) || {}) };
  const compFilters = oldPreferences[PREFERENCES_KEYS.FILTERS_COMPS];
  if (compFilters?.activeFilterSet === filterSetId) {
    // Remove key from report preferences
    oldPreferences[PREFERENCES_KEYS.FILTERS_COMPS].activeFilterSet = null;
    const rentalCompFilters =
      oldPreferences[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS];
    const reportPreferencesPayload = {};
    if (rentalCompFilters?.activeFilterSet) {
      reportPreferencesPayload.rentalCompFilterSetId =
        rentalCompFilters.activeFilterSet;
    }
    yield call(
      apiUtil.POST,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/report_preferences/`,
      reportPreferencesPayload,
      { skipTransform: true }
    );
  }
  if (oldPreferences[PREFERENCES_KEYS.FILTERS_COMPS]) {
    delete oldPreferences[PREFERENCES_KEYS.FILTERS_COMPS].filterSets[
      filterSetId
    ];
  }

  const compFiltersPayload = _oldFiltersToNew(
    oldPreferences[PREFERENCES_KEYS.FILTERS_COMPS]
  );
  yield call(
    apiUtil.POST,
    `${HC_CONSTANTS.HUELL_PROXY_URL}/comp_filters/?compType=SOLD`,
    transformCompsFilters(compFiltersPayload, { transformToOld: false }),
    { skipTransform: true }
  );
  yield put(preferencesSaveSuccess(oldPreferences));
}

export function* handleDeleteSavedFilterRentalComps(action) {
  const { filterSetId } = action.payload;
  const oldPreferences = { ...(yield select(getPreferencesValues) || {}) };
  const rentalCompFilters =
    oldPreferences[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS];
  if (rentalCompFilters?.activeFilterSet === filterSetId) {
    // Remove key from report preferences
    oldPreferences[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS].activeFilterSet =
      null;
    const compFilters = oldPreferences[PREFERENCES_KEYS.FILTERS_COMPS];
    const reportPreferencesPayload = {};
    if (compFilters?.activeFilterSet) {
      reportPreferencesPayload.compFilterSetId = compFilters.activeFilterSet;
    }
    yield call(
      apiUtil.POST,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/report_preferences/`,
      reportPreferencesPayload,
      { skipTransform: true }
    );
  }
  if (oldPreferences[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS]) {
    delete oldPreferences[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS].filterSets[
      filterSetId
    ];
  }

  const compFiltersPayload = _oldFiltersToNew(
    oldPreferences[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS]
  );
  yield call(
    apiUtil.POST,
    `${HC_CONSTANTS.HUELL_PROXY_URL}/comp_filters/?compType=RENTAL`,
    transformCompsFilters(compFiltersPayload, { transformToOld: false }),
    { skipTransform: true }
  );
  yield put(preferencesSaveSuccess(oldPreferences));
}

function* handleChangeFilterSetPrimaryComps(action) {
  const { filterSetId } = action.payload;
  const oldPreferences = { ...(yield select(getPreferencesValues) || {}) };
  const savedRentalCompFilters = {
    ...(oldPreferences[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS] || {})
  };
  const reportPreferencesPayload = {
    compFilterSetId:
      filterSetId ===
      oldPreferences[PREFERENCES_KEYS.FILTERS_COMPS].activeFilterSet
        ? null
        : filterSetId
  };
  if (savedRentalCompFilters.activeFilterSet) {
    reportPreferencesPayload.rentalCompFilterSetId =
      savedRentalCompFilters.activeFilterSet;
  }
  yield call(
    apiUtil.POST,
    `${HC_CONSTANTS.HUELL_PROXY_URL}/report_preferences/`,
    reportPreferencesPayload,
    { skipTransform: true }
  );
  oldPreferences[PREFERENCES_KEYS.FILTERS_COMPS].activeFilterSet =
    filterSetId ===
    oldPreferences[PREFERENCES_KEYS.FILTERS_COMPS].activeFilterSet
      ? null
      : filterSetId;

  yield put(preferencesSaveSuccess(oldPreferences));
}

function* handleChangeFilterSetPrimaryRentalComps(action) {
  const { filterSetId } = action.payload;
  const oldPreferences = { ...(yield select(getPreferencesValues) || {}) };
  const savedCompFilters = {
    ...(oldPreferences[PREFERENCES_KEYS.FILTERS_COMPS] || {})
  };
  const reportPreferencesPayload = {
    rentalCompFilterSetId:
      filterSetId ===
      oldPreferences[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS].activeFilterSet
        ? null
        : filterSetId
  };
  if (savedCompFilters.activeFilterSet) {
    reportPreferencesPayload.compFilterSetId = savedCompFilters.activeFilterSet;
  }
  yield call(
    apiUtil.POST,
    `${HC_CONSTANTS.HUELL_PROXY_URL}/report_preferences/`,
    reportPreferencesPayload,
    { skipTransform: true }
  );
  oldPreferences[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS].activeFilterSet =
    filterSetId ===
    oldPreferences[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS].activeFilterSet
      ? null
      : filterSetId;

  yield put(preferencesSaveSuccess(oldPreferences));
}

export default function registerSagas() {
  watchEvery({
    [PREFERENCES_FETCH]: fetchPreferences,
    [PREFERENCES_SAVE]: savePreferences,
    [COMPS_FILTERS_SAVE]: handleCompSaveFilters,
    [RENTAL_COMPS_FILTERS_SAVE]: handleRentalCompSaveFilters,
    [COMPS_DELETE_SAVED_FILTER]: handleDeleteSavedFilterComps,
    [RENTAL_COMPS_DELETE_SAVED_FILTER]: handleDeleteSavedFilterRentalComps,
    [COMPS_CHANGE_FILTER_SET_PRIMARY]: handleChangeFilterSetPrimaryComps,
    [RENTAL_COMPS_CHANGE_FILTER_SET_PRIMARY]:
      handleChangeFilterSetPrimaryRentalComps
  });
}
