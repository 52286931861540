import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { ValueSelectorItem } from 'src/lib/components/ValueSelector/ValueSelectorItem';
import { ValueSelectorButton } from 'src/lib/components/ValueSelector/ValueSelectorButton';
import { documentPatch } from 'src/lib/report-api/actions';
import { useDocumentRole } from '../../hooks';
import { AvmDocument, DocumentRoles } from '../../types';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { AvmType, AvmTypeLabel } from '../../constants';

import styles from './ReportValueSelector.css';

interface Props {
  dataHcName: string;
  reportId: number;
  value?: number | null;
  onClose?: VoidFunction;
  className?: string;
  onClickSelectComps: VoidFunction;
}

export const AdjustedCompBasedAvgSelectorItem = ({
  dataHcName,
  reportId,
  value,
  className,
  onClose,
  onClickSelectComps
}: Props) => {
  const dispatch = useDispatch();
  const {
    data: [avmDocument]
  } = useDocumentRole<AvmDocument>(reportId, DocumentRoles.Avm);

  if (avmDocument?.role !== DocumentRoles.Avm) {
    return <LoadingSpinner dataHcName={dataHcName} containerHeight="380px" />;
  }
  const handleSelect = () => {
    if (value && onClose) {
      onClose();
    } else {
      onClickSelectComps();
    }
    dispatch(
      documentPatch(reportId, avmDocument.documentId, [
        { op: 'replace', path: '/data/selected', value: 'adjustedCompBasedAvg' }
      ])
    );
  };

  return (
    <ValueSelectorItem
      title={AvmTypeLabel.adjustedCompBasedAvg}
      dataHcName={dataHcName}
      value={value}
      className={classNames(styles.AVMSelectionItem, className)}
    >
      {value ? (
        <span data-hc-name={`${dataHcName}-value-description`}>
          Average of all the selected comparables (based on sales or most recent
          list price) plus the user&apos;s price adjustment for each
        </span>
      ) : (
        <span data-hc-name={`${dataHcName}-nonvalue-description`}>
          Average of all the selected comparables (based on sales or most recent
          list price) plus the user&apos;s price adjustment for each comparable.
          Click below to select comps now.
        </span>
      )}
      <ValueSelectorButton
        dataHcName={`${dataHcName}-value-selector-button`}
        onClick={handleSelect}
      >
        {avmDocument.data[AvmType.CompMarket].priceMean
          ? 'Use For Report'
          : 'Select Comps'}
      </ValueSelectorButton>
    </ValueSelectorItem>
  );
};
