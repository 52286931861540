import { PureComponent } from 'react';
import classNames from 'classnames';
import { Button } from 'legacy/components/Button';
import { FormError } from '@hc/component-lib/hclib/components/atoms/form-error';
import { BlueDialogHeader } from '@hc/component-lib/hclib/components/organisms/dialog';
import { Dialog } from 'legacy/components/Dialog';

import {
  IMG_HEIGHT_MIN,
  IMG_SIZE_MAX,
  IMG_FILE_TYPES
} from 'legacy/forms/personalization-form';
import { STATUSES } from 'legacy/appstore/constants';

import { humanFileSize } from 'legacy/utils/files';
import { scrollToTop } from 'legacy/utils/dom';

import TextField from 'legacy/components/generic/TextField';
import Loading from 'legacy/components/generic/Loading';
import FileField from 'legacy/components/generic/FileField';
import SelectFieldState from 'legacy/components/generic/SelectFieldState';
import CheckboxField from 'legacy/components/generic/CheckboxField';

import { PersonalizationModal as theme } from 'legacy/css-modules';

const Row = ({ className, children }) => {
  return (
    <div className={classNames(theme.FormRow, { [className]: className })}>
      {children}
    </div>
  );
};

const RowInline = ({ className, children }) => {
  return (
    <div
      className={classNames(theme.FormRowInline, { [className]: className })}
    >
      {children}
    </div>
  );
};

const Column = ({ className, children }) => {
  return (
    <div className={classNames(theme.FormColumn, { [className]: className })}>
      {children}
    </div>
  );
};

class PersonalizationModal extends PureComponent {
  componentDidMount() {
    scrollToTop();
  }

  render() {
    const {
      status,
      uploadStatus = {},
      isOpen,
      dirty,
      valid,
      formValues,
      validateEmail,
      onClose,
      onSubmit,
      onUpload,
      onIncludeOnChange,
      onDeleteImg,
      onChangeName
    } = this.props;

    const HeaderComponent = (
      <BlueDialogHeader
        dataHcName="personalization-header-section"
        title={
          <span
            className={theme.title}
            data-hc-name="personalization-header-text"
          >
            Personalize Reports
          </span>
        }
        subtitle={
          <span
            className={theme.subtitle}
            data-hc-name="personalization-subheader-text"
          >
            Brand shared reports with your personal info
          </span>
        }
        theme={theme}
      />
    );

    return (
      <Dialog
        active={isOpen}
        title={HeaderComponent}
        onClose={() => onClose(dirty)}
        theme={theme}
        className={theme.PersonalizationModal}
        type="small"
        dataHcName="personalization-modal"
        closeButton="light"
      >
        <Loading
          key="personalization-modal"
          size="xlarge"
          loading={!status || status === STATUSES.LOADING}
          overlayColor="light"
          fullPage
          overlay
          spinnerDataHcName="personalization-modal-spinner"
        >
          <div className={theme.PersonalizationForm}>
            <section className={theme.PersonalizationFormContent}>
              <form onSubmit={onSubmit.bind(this, formValues)}>
                <RowInline>
                  <Column>
                    <TextField
                      name="firstName"
                      label="First Name"
                      maxLength={35}
                      onChange={(e, val) => {
                        onIncludeOnChange('includeName');
                        onChangeName(val);
                      }}
                      inputTheme="hc"
                      fullWidth
                    />
                  </Column>
                  <Column>
                    <TextField
                      inputTheme="hc"
                      name="lastName"
                      label="Last Name"
                      maxLength={35}
                      onChange={(e, val) => {
                        onIncludeOnChange('includeName');
                        onChangeName(val);
                      }}
                      fullWidth
                    />
                  </Column>
                </RowInline>

                <Row>
                  <TextField
                    inputTheme="hc"
                    name="companyName"
                    label="Company Name"
                    type="text"
                    maxLength={64}
                    onChange={() => onIncludeOnChange('includeCompanyName')}
                  />
                </Row>

                <Row>
                  <TextField
                    inputTheme="hc"
                    name="addressLine1"
                    label="Address Line 1"
                    type="text"
                    maxLength={40}
                    onChange={() => onIncludeOnChange('includeAddressLine1')}
                  />
                </Row>

                <Row>
                  <TextField
                    inputTheme="hc"
                    name="addressLine2"
                    label="Address Line 2"
                    type="text"
                    maxLength={40}
                    onChange={() => onIncludeOnChange('includeAddressLine2')}
                  />
                </Row>

                <Row>
                  <TextField
                    inputTheme="hc"
                    name="city"
                    label="City"
                    type="text"
                    maxLength={25}
                    onChange={() =>
                      onIncludeOnChange('includeCityStateZipcode')
                    }
                  />
                </Row>

                <RowInline>
                  <Column className={theme.StateColumn}>
                    <SelectFieldState
                      name="state"
                      placeholder="State"
                      className={theme.SelectFieldState}
                      label="State"
                      onChange={() =>
                        onIncludeOnChange('includeCityStateZipcode')
                      }
                    />
                  </Column>
                  <Column>
                    <TextField
                      inputTheme="hc"
                      name="zipcode"
                      label="Zipcode"
                      type="text"
                      maxLength={5}
                      onChange={() =>
                        onIncludeOnChange('includeCityStateZipcode')
                      }
                    />
                  </Column>
                </RowInline>

                <Row>
                  <TextField
                    inputTheme="hc"
                    name="email"
                    type="email"
                    label="Email"
                    maxLength={40}
                    validate={validateEmail}
                    onChange={() => onIncludeOnChange('includeEmail')}
                    fullWidth
                  />
                </Row>

                <Row>
                  <TextField
                    inputTheme="hc"
                    name="phone"
                    type="tel"
                    label="Phone"
                    maxLength={20}
                    onChange={() => onIncludeOnChange('includePhone')}
                  />
                </Row>

                <Row>
                  <TextField
                    inputTheme="hc"
                    name="websiteUrl"
                    label="Website"
                    type="text"
                    maxLength={40}
                    onChange={() => onIncludeOnChange('includeWebsite')}
                  />
                </Row>

                <Row>
                  <TextField
                    inputTheme="hc"
                    name="licenseNumber"
                    label="License Number"
                    type="text"
                    maxLength={100}
                    onChange={() => onIncludeOnChange('includeLicenseNumber')}
                  />
                </Row>

                <Row>
                  <FileField
                    name="photoUrl"
                    label="User Photo"
                    avatar
                    dataHcNameButton="user-photo-upload-button"
                    onUpload={(e, fieldName) =>
                      onUpload(e, fieldName, 'includePhoto')
                    }
                    accept="image/jpeg, image/png"
                    status={uploadStatus.photoUrl || {}}
                    onDelete={onDeleteImg}
                    deleteLabel="Remove photo"
                    className="input-long"
                  />
                </Row>
                <Row>
                  <FileField
                    name="companyLogoUrl"
                    label="Company Logo"
                    dataHcNameButton="company-logo-upload-button"
                    onUpload={(e, fieldName) =>
                      onUpload(e, fieldName, 'includeCompanyLogo')
                    }
                    accept="image/jpeg, image/png"
                    status={uploadStatus.companyLogoUrl || {}}
                    onDelete={onDeleteImg}
                    deleteLabel="Remove photo"
                    className="input-long"
                  />
                </Row>
                <Row>
                  <Column className={theme.ImageFileReqs}>
                    {`(Maximum file size ${humanFileSize(
                      IMG_SIZE_MAX
                    )}, At least ${IMG_HEIGHT_MIN}px in height, ${IMG_FILE_TYPES.map(
                      (ft) => ft.split('/')[1].toUpperCase()
                    ).join(', ')})`}
                  </Column>
                </Row>

                <div className={theme.PersonalizationFormFooter}>
                  <Button
                    type="submit"
                    label="Save"
                    disabled={
                      !valid ||
                      status === STATUSES.LOADING ||
                      (status !== STATUSES.DOES_NOT_EXIST && !dirty)
                    }
                    dataHcName="personalization-save-button"
                  />

                  {((status !== STATUSES.DOES_NOT_EXIST && !dirty) ||
                    !valid) && (
                    <div className={theme.DisabledSubmitMessage}>
                      <FormError
                        value={
                          !valid
                            ? 'Please fix the errors above'
                            : 'Please make a change before saving'
                        }
                      />
                    </div>
                  )}
                </div>
              </form>
            </section>
          </div>
        </Loading>
      </Dialog>
    );
  }
}

export default PersonalizationModal;
