import { COMP_TYPE_DEFAULT } from 'legacy/appstore/constants';
import { VIEWS, ACTION_PAGE_LOAD_COMPS } from 'legacy/routes/constants';
import {
  routeEffectiveDateAddress,
  routeEffectiveDateReport
} from 'legacy/routes/effective-date';
import * as Async from 'legacy/async-pages';
import { getRouteQueryCompsPages } from 'selectors/routes';
import { handleErrors } from 'legacy/utils/saga';
import CompsPageWrapper from 'legacy/pages/CompsPageWrapper';
export default {
  [VIEWS.EFFECTIVE_DATE_ADDRESS]: {
    path: '/effective-date/lookup/:addressSlug/:effectiveDate',
    query: ['clientFileId'],
    component: Async.PropertyExplorer,
    loginRequired: true,
    saga: handleErrors(routeEffectiveDateAddress)
  },
  [VIEWS.EFFECTIVE_DATE_REPORT]: {
    path: '/effective-date/report/:reportId',
    query: ['chartModal', 'commentModal'],
    component: Async.PropertyExplorer,
    loginRequired: true,
    saga: handleErrors(routeEffectiveDateReport)
  },
  [VIEWS.EFFECTIVE_DATE_COMPS]: {
    path: '/effective-date/report/:reportId/comps',
    query: ['viewType', 'viewSelected'],
    loginRequired: true,
    saga: handleErrors(routeEffectiveDateReport),
    querySelector: getRouteQueryCompsPages,
    component: CompsPageWrapper(COMP_TYPE_DEFAULT),
    actionType: ACTION_PAGE_LOAD_COMPS,
    template: {
      LeftPane: Async.CompMap,
      RightPane: Async.CompsList
    },
    props: {
      compType: COMP_TYPE_DEFAULT,
      viewBack: VIEWS.VALUE_REPORT,
      viewSelection: VIEWS.COMPS_SELECTION,
      viewMap: VIEWS.COMPS_SELECTION_MAP,
      mobileFullWidth: 'RightPane'
    }
  },
  [VIEWS.EFFECTIVE_DATE_COMPS_MAP]: {
    path: '/effective-date/report/:reportId/comps/draw',
    component: Async.CompsSelectionMap,
    loginRequired: true,
    saga: handleErrors(routeEffectiveDateReport)
  }
};
