import { AxiosResponse, AxiosError } from 'axios';
import {
  all,
  actionChannel,
  take,
  put,
  call,
  select,
  takeEvery
} from 'redux-saga/effects';
import {
  REPORT_FETCH,
  ReportFetchAction,
  reportFetchSuccess,
  reportFetchError,
  ReportRequiredAction,
  REPORT_REQUIRED,
  reportFetch
} from '../actions';
import { Report } from '../types';
import { ReportApi } from '../api';
// TODO: REMOVE THIS IMPORT
// @ts-ignore: Importing from untyped legacy directory
import { getToken } from 'selectors/account-details';
import { selReportStatus } from '../selectors';
import { STATUSES } from '../../../constants';

export function* handleReportFetch(action: ReportFetchAction) {
  try {
    const token: string = yield select(getToken);
    const response: AxiosResponse<Report> = yield call(
      ReportApi.fetchReport,
      token,
      action.payload.reportId
    );
    yield put(reportFetchSuccess(response.data));
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status !== 404) {
        console.log('[TODO] Report Error To Sentry');
      }
      yield put(
        reportFetchError(action.payload.reportId, error.response.data.message)
      );
    } else if (error.request) {
      yield put(
        reportFetchError(action.payload.reportId, error.request.data.message)
      );
    }
  }
}

// Using an action channel prevents duplicate api requests from sibling components by allowing
// the _FETCH action dispatched from the first _REQUIRED action to update state
// before any additional _REQUIRED actions are evaluated
function* takeReportRequired() {
  const requiredChannel: typeof REPORT_REQUIRED = yield actionChannel(
    REPORT_REQUIRED
  );
  while (true) {
    const action: ReportRequiredAction = yield take<ReportRequiredAction>(
      requiredChannel
    );
    const { reportId } = action.payload;
    const status: STATUSES | undefined = yield select(
      selReportStatus(reportId)
    );
    if (!status) {
      yield put(reportFetch(reportId));
    }
  }
}

export function* reportSagas() {
  yield takeEvery(REPORT_FETCH, handleReportFetch);
  yield all([call(takeReportRequired)]);
}
