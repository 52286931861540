import classNames from 'classnames';
const FontIcon = ({ size, color, style, className, isMaterial, icon }) => {
  return (
    <i
      className={classNames(className, { 'material-icons': isMaterial })}
      style={{
        ...style,
        color: style.color ? style.color : color,
        fontSize: style.fontSize ? style.fontSize : size
      }}
    >
      {icon}
    </i>
  );
};

FontIcon.defaultProps = {
  size: '24px',
  color: 'inherit',
  style: {},
  isMaterial: true
};

export default FontIcon;
