import React, { ReactElement } from 'react';

import {
  PhotoGridThumbnail,
  PhotoGridThumbnailProps
} from './PhotoGridThumbnail';

import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from './PhotoGrid.css';
import classNames from 'classnames';
import { enforceChildTypes } from '../../utils/children';

interface Props {
  // QA selector
  dataHcName: string;
  // custom className for component
  className?: string;
  // children of type PhotoGridThumbnail
  children:
    | ReactElement<PhotoGridThumbnailProps>[]
    | ReactElement<PhotoGridThumbnailProps>;
}

export const PhotoGrid = ({ dataHcName, className, children }: Props) => {
  enforceChildTypes([PhotoGridThumbnail.name], children);
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.PhotoGrid, className)}
    >
      {children}
    </div>
  );
};
