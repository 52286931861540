import React from 'react';
import HC_CONSTANTS from 'HC_CONSTANTS';
import classNames from 'classnames';
import PopoverEndgame from 'legacy/components/PopoverEndgame';
import IconButton from 'legacy/components/generic/IconButton';
import TagIcon from 'legacy/assets/svg-inline/tag.svg';
import styles from './CompTags.css-module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCompsTags } from 'selectors/comps';
import { Checkbox } from '@hc/component-lib/hclib/components/atoms/checkbox';
import {
  compsTagAdd,
  compsTagRemove
} from '../../appstore/actions/comps.actions';
import { getCurrentTeam } from 'selectors/account-details';
// interface Props {
//   dataHcName: string;
//   hcAddressId: number;
//   classNameTrigger?: string;
//   classNameContent?: string;
// }

const DEFAULT_TAGS = ['Fair Market Value Match', 'After Repair Value Match'];

export const CompTags = ({
  dataHcName,
  hcAddressId,
  classNameTrigger,
  classNameContent
}) => {
  const dispatch = useDispatch();
  const org = useSelector(getCurrentTeam);
  const tags = useSelector(getCompsTags);
  const tagOptions = HC_CONSTANTS.COMP_TAGS?.[org.id] || DEFAULT_TAGS;
  let count = 0;
  if (tags) {
    Object.keys(tags).forEach((t) => {
      if (tags[t][hcAddressId]) {
        count++;
      }
    });
  }
  return (
    <PopoverEndgame
      classNameTrigger={classNameTrigger}
      className={classNames(styles.Content, classNameContent)}
      closeOnClick={false}
      overlay
      trigger={
        <IconButton
          dataHcName={`${dataHcName}-trigger`}
          icon={TagIcon}
          className={styles.CompTagsTrigger}
        >
          {count ? (
            <div className={styles.Count} data-hc-name={`${dataHcName}-count`}>
              {count}
            </div>
          ) : null}
        </IconButton>
      }
      content={
        <ul
          data-hc-name={`${dataHcName}-content`}
          className={styles.TagControl}
        >
          {tagOptions.map((tag) => {
            const checked = tags?.[tag]?.[hcAddressId];
            return (
              <li data-hc-name={`${dataHcName}-option-${tag}`} key={tag}>
                <Checkbox
                  className={styles.Checkbox}
                  dataHcName={`${dataHcName}-option-${tag}-checkbox`}
                  checked={checked}
                  onChange={(addTag) => {
                    if (addTag) {
                      dispatch(compsTagAdd(hcAddressId, tag));
                    } else {
                      dispatch(compsTagRemove(hcAddressId, tag));
                    }
                  }}
                />
                <span data-hc-name={`${dataHcName}-option-${tag}-label`}>
                  {tag}
                </span>
              </li>
            );
          })}
        </ul>
      }
    />
  );
};
