import { STATUSES } from 'legacy/appstore/constants';

import {
  GET_REPORT_RENTAL,
  GET_REPORT_RENTAL_SUCCESS,
  GET_REPORT_RENTAL_FAILURE
} from 'actions/get-report';

const INITIAL_SORT = {
  attr: 'similarityScoreAdjusted',
  order: 'desc' // or 'asc'
};
const INITIAL_STATE = {
  status: STATUSES.INIT,
  message: null,
  values: {},
  sort: INITIAL_SORT
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_RENTAL:
      return {
        ...state,
        status: STATUSES.LOADING
      };

    case GET_REPORT_RENTAL_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        values: {
          ...action.payload.report
        }
      };

    case GET_REPORT_RENTAL_FAILURE:
      return {
        ...state,
        status: STATUSES.ERROR,
        message: action.payload.message
      };

    default:
      return state;
  }
};
