import { STATUSES } from 'legacy/appstore/constants';
import {
  EFFECTIVE_DATE_GET_REPORT,
  EFFECTIVE_DATE_GET_REPORT_SUCCESS,
  EFFECTIVE_DATE_CREATE_REPORT_FAILURE,
  EFFECTIVE_DATE_GET_REPORT_FAILURE,
  EFFECTIVE_DATE_ERROR_CTA
} from 'actions/effective-date';

const INITIAL_STATE = {
  status: STATUSES.INIT,
  data: {}
};

export const effectiveDateReducer = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
    case EFFECTIVE_DATE_GET_REPORT: {
      return {
        ...state,
        status: STATUSES.LOADING,
        reportId: action.payload.reportId,
        data: {}
      };
    }

    case EFFECTIVE_DATE_GET_REPORT_SUCCESS: {
      return {
        ...state,
        status: STATUSES.SUCCESS,
        reportId: action.payload.report.metadata.reportMetadata.id,
        timelineSubjectDetailsMissing:
          !!action.payload.report.metadata.timelineSubjectDetailsMissing,
        data: action.payload.report.metadata.reportMetadata
      };
    }

    case EFFECTIVE_DATE_CREATE_REPORT_FAILURE: {
      return {
        ...state,
        status: STATUSES.ERROR,
        errorType: 'create'
      };
    }

    case EFFECTIVE_DATE_GET_REPORT_FAILURE: {
      return {
        ...state,
        status: STATUSES.ERROR,
        errorType: action.payload.statusCode === 404 ? 'not-found' : 'get'
      };
    }

    case EFFECTIVE_DATE_ERROR_CTA: {
      return { ...INITIAL_STATE };
    }

    default: {
      return state;
    }
  }
};
