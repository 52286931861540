import { call, select } from 'redux-saga/effects';

import { STATUSES } from 'legacy/appstore/constants';

import { initPropertyExplorer } from 'sagas/init-property-explorer';

import { getReportStatusUserVersion } from 'selectors/report-status';

export default function* routeCompsCompare({ view, params, query }) {
  try {
    const reportStatus = yield select(getReportStatusUserVersion);
    if (reportStatus === STATUSES.INIT) {
      yield call(initPropertyExplorer);
    }
  } catch (e) {
    console.error(e);
  }
}
