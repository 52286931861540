import { call } from 'redux-saga/effects';
import graphqlRequest from 'legacy/services/graphql';

import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

export const taxHistoryQuery = ({ addressId }) => {
  return {
    key: QUERIES.TAX_HISTORY,
    query: `
      taxHistory (addressId: ${addressId}) {
        history {
          year: assessmentYear
          taxAmount,
          taxYear
        }
      }
    `
  };
};

const fetchTaxHistory = function* (addressId) {
  const query = taxHistoryQuery(addressId);
  return yield call(graphqlRequest, {
    source: PROPERTY_DATA_SOURCES.PR,
    query
  });
};

export default fetchTaxHistory;
