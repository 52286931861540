import { watchEvery } from 'legacy/utils/saga';
import { all, call, put, select } from 'redux-saga/effects';
import reportSchemaService from 'legacy/services/report-schema';
import HC_CONSTANTS from 'HC_CONSTANTS';
import {
  COMP_TYPE_DEFAULT,
  COMP_TYPE_RENTAL,
  MAP_ID_COMPS,
  MAP_ID_RENTAL_COMPS
} from 'legacy/appstore/constants';

import apiUtil from 'legacy/utils/api';
import { buildRadiusGeoJson } from 'legacy/utils/maps';

import {
  processPropertyList,
  processRentalPropertyList
} from 'legacy/services/report-schema';
import { selectScrubDataArgs } from 'sagas/mls-compliance.saga';
import { GET_REPORT_COMPS } from 'actions/get-report';
import {
  COMPS_REFRESH,
  compsRefreshReadyForRecompute,
  compsFarmUpdateSuccess,
  compsFarmUpdateFailure
} from 'actions/comps';
import {
  RENTAL_COMPS_REFRESH,
  rentalCompsRefreshReadyForRecompute,
  rentalCompsFarmUpdateSuccess,
  rentalCompsFarmUpdateFailure
} from 'actions/rental-comps';
import { checkShouldScrub } from 'legacy/utils/mls-compliance.utils';
import {
  getSubjectSelected,
  getSubjectRental,
  getSubjectLocationUnknown,
  getSubjectDefault,
  getSubjectAdjusted
} from 'selectors/subject';
import {
  getCompsAddressIdsAll,
  getCompsFilters,
  getCompsSelected
} from 'selectors/comps';
import { getPreferencesSavedDistanceFilterByMapId } from 'selectors/preferences';
import {
  getRentalCompsAddressIdsAll,
  getRentalCompsFilters,
  getRentalCompsSelected
} from 'selectors/rental-comps';
import { getMapDrawPolygons } from 'selectors/map-draw';
import { keyBy } from 'lodash';

const STORE_METHODS = {
  [COMP_TYPE_DEFAULT]: {
    getAllAddressIds: getCompsAddressIdsAll,
    getSavedDistanceFilter:
      getPreferencesSavedDistanceFilterByMapId(MAP_ID_COMPS),
    getSubject: getSubjectSelected,

    farmUpdateSuccess: compsFarmUpdateSuccess,
    farmUpdateFailure: compsFarmUpdateFailure
  },
  [COMP_TYPE_RENTAL]: {
    getAllAddressIds: getRentalCompsAddressIdsAll,
    getSavedDistanceFilter:
      getPreferencesSavedDistanceFilterByMapId(MAP_ID_RENTAL_COMPS),
    getSubject: getSubjectRental,

    farmUpdateSuccess: rentalCompsFarmUpdateSuccess,
    farmUpdateFailure: rentalCompsFarmUpdateFailure
  }
};

export function* fetchComps(compType, geojson) {
  const storeMethods = STORE_METHODS[compType];

  try {
    // Get GeoJson for Report or Build from Saved Prefs
    const subject = yield select(storeMethods.getSubject);
    const addressIdsToSkip = yield select(storeMethods.getAllAddressIds);
    let distance;

    // use distance instead of geojson when subject is center of geojson
    if (
      geojson &&
      geojson.properties &&
      geojson.properties.isSubjectCoordinates
    ) {
      distance = geojson.properties.radius / 1609.34;
    }

    const payload = {
      okToSplit: geojson ? true : undefined,
      limit: geojson ? 1000 : undefined,
      subject,
      addressIdsToSkip: geojson ? addressIdsToSkip : undefined,
      filters: compType === COMP_TYPE_RENTAL ? { forRentals: true } : undefined
    };

    if (distance) {
      payload.filters = payload.filters
        ? { ...payload.filters, ...{ distance_miles: distance } }
        : { distance_miles: distance };
    } else {
      payload.geojson = geojson;
    }

    const response = yield call(
      apiUtil.POST,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/comps`,
      payload
    );
    const { compsFarmList, skippedAddressIds, ...confidence } = response;
    const { state, orgId } = yield call(selectScrubDataArgs);
    const shouldScrubData = checkShouldScrub(state, orgId);
    yield put(
      storeMethods.farmUpdateSuccess(
        compType === COMP_TYPE_RENTAL
          ? processRentalPropertyList(compsFarmList, shouldScrubData)
          : processPropertyList(compsFarmList, {
              idsToKeep: skippedAddressIds,
              shouldScrubData
            }),
        skippedAddressIds,
        confidence
      )
    );
  } catch (e) {
    yield put(storeMethods.farmUpdateFailure());
  }
}

function* handleGetComps(action) {
  const storeMethods = STORE_METHODS[COMP_TYPE_DEFAULT];
  const distance = yield select(
    getPreferencesSavedDistanceFilterByMapId(MAP_ID_COMPS)
  );
  let geojson;
  if (distance) {
    const subject = yield select(getSubjectSelected);
    geojson = buildRadiusGeoJson(subject.geoLocation, distance, true);
  }
  const locationUnknown = yield select(getSubjectLocationUnknown);
  if (locationUnknown) {
    // Send empty farm list to state since we don't know subject location
    yield put(storeMethods.farmUpdateSuccess([], []));
  } else {
    yield call(fetchComps, COMP_TYPE_DEFAULT, geojson);
  }
}

function* handleRefreshComps() {
  try {
    const previousCompAddressIds = yield select(getCompsAddressIdsAll);
    const subjectDefault = yield select(getSubjectDefault);
    const subjectAdjusted = yield select(getSubjectAdjusted);
    const filters = yield select(getCompsFilters);
    // No custom params required for the hc farm list
    const userFarmParams = {
      subject: subjectAdjusted,
      okToSplit: !!filters.distance
    };
    // Distance filter only applies to the user farm list
    if (filters.distance) {
      if (typeof filters.distance === 'number') {
        userFarmParams.filters = { distance_miles: filters.distance };
        userFarmParams.limit = 1000;
      } else {
        const polygons = yield select(getMapDrawPolygons(MAP_ID_COMPS));
        if (polygons && polygons.length) {
          userFarmParams.geojson = polygons[0];
          userFarmParams.limit = 1000;
        }
      }
    }
    // Gets a new HC farm list and user farm list
    // We need to get both because the HC farm list should be scored
    // according to an unmodified subject with no additional filters
    // User farm list uses an adjusted subject and any distance filters
    const [hcResponse, userResponse] = yield all([
      call(apiUtil.POST, `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/comps`, {
        subject: subjectDefault
      }),
      call(
        apiUtil.POST,
        `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/comps`,
        userFarmParams
      )
    ]);
    const { state, orgId } = yield call(selectScrubDataArgs);
    const shouldScrubData = checkShouldScrub(state, orgId);
    const selectedComps = keyBy(yield select(getCompsSelected), 'addressId');
    // Ensure all selected comps are in the new HC farm
    const selectedCompsInHcFarm = {};
    hcResponse.compsFarmList.forEach((comp) => {
      if (selectedComps[comp.addressId]) {
        // Keep track of selected comps in updated hc farm
        selectedCompsInHcFarm[comp.addressId] = true;
        // Overwrite old comp data with new comp data
        selectedComps[comp.addressId] = comp;
      }
      if (shouldScrubData) {
        comp.salesPrice = null;
        comp.salesPriceAdjusted = null;
        comp.leasedPrice = null;
      }
    });
    // Ensure all selected comps are in the new User farm
    const selectedCompsInUserFarm = {};
    userResponse.compsFarmList.forEach((comp) => {
      if (selectedComps[comp.addressId]) {
        // Keep track of selected comps in updated user farm
        selectedCompsInUserFarm[comp.addressId] = true;
        // Overwrite old comp data with new comp data
        selectedComps[comp.addressId] = comp;
      }
      if (shouldScrubData) {
        comp.salesPrice = null;
        comp.salesPriceAdjusted = null;
        comp.leasedPrice = null;
      }
    });
    // Add selected comps that were not returned with the update farm lists
    Object.keys(selectedComps).forEach((addressIdStr) => {
      const addressId = parseInt(addressIdStr);
      if (!selectedCompsInHcFarm[addressId]) {
        hcResponse.compsFarmList.push(selectedComps[addressIdStr]);
      }
      if (!selectedCompsInUserFarm[addressId]) {
        userResponse.compsFarmList.push(selectedComps[addressIdStr]);
      }
    });

    yield put(
      compsRefreshReadyForRecompute({
        hcCompsFarmList: reportSchemaService(hcResponse, shouldScrubData)
          .compsFarmList,
        userCompsFarmList: reportSchemaService(userResponse, shouldScrubData)
          .compsFarmList,
        previousCompAddressIds
      })
    );
  } catch (e) {
    console.error(e);
    yield put(compsFarmUpdateFailure());
    throw e;
  }
}

function* handleRentalCompsRefresh() {
  try {
    const { state, orgId } = yield call(selectScrubDataArgs);
    const shouldScrubData = checkShouldScrub(state, orgId);
    const previousCompAddressIds = yield select(getRentalCompsAddressIdsAll);
    const subject = yield select(getSubjectRental);
    const filters = yield select(getRentalCompsFilters);
    // No custom params required for the hc farm list
    const userFarmParams = {
      subject,
      okToSplit: !!filters.distance,
      filters: {
        forRentals: true
      }
    };
    // Distance filter only applies to the user farm list
    if (filters.distance) {
      if (typeof filters.distance === 'number') {
        userFarmParams.filters.distance_miles = filters.distance;
        userFarmParams.limit = 1000;
      } else {
        const polygons = yield select(getMapDrawPolygons(MAP_ID_RENTAL_COMPS));
        if (polygons && polygons.length) {
          userFarmParams.geojson = polygons[0];
          userFarmParams.limit = 1000;
        }
      }
    }
    // Gets a new HC farm list and user farm list
    // We need to get both because the HC farm list should be scored
    // according to an unmodified subject with no additional filters
    // User farm list uses an adjusted subject and any distance filters
    const [hcResponse, userResponse] = yield all([
      call(apiUtil.POST, `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/comps`, {
        subject,
        filters: {
          forRentals: true
        }
      }),
      call(
        apiUtil.POST,
        `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/comps`,
        userFarmParams
      )
    ]);
    const selectedComps = keyBy(
      yield select(getRentalCompsSelected),
      'addressId'
    );

    // Ensure all selected comps are in the new HC farm
    const selectedCompsInHcFarm = {};
    hcResponse.compsFarmList.forEach((comp) => {
      if (selectedComps[comp.addressId]) {
        // Keep track of selected comps in updated hc farm
        selectedCompsInHcFarm[comp.addressId] = true;
        // Overwrite old comp data with new comp data
        selectedComps[comp.addressId] = comp;
      }
      if (shouldScrubData) {
        comp.salesPrice = null;
        comp.salesPriceAdjusted = null;
        comp.leasedPrice = null;
      }
    });
    // Ensure all selected comps are in the new User farm
    const selectedCompsInUserFarm = {};
    userResponse.compsFarmList.forEach((comp) => {
      if (selectedComps[comp.addressId]) {
        // Keep track of selected comps in updated user farm
        selectedCompsInUserFarm[comp.addressId] = true;
        // Overwrite old comp data with new comp data
        selectedComps[comp.addressId] = comp;
      }
      if (shouldScrubData) {
        comp.salesPrice = null;
        comp.salesPriceAdjusted = null;
        comp.leasedPrice = null;
      }
    });
    // Add selected comps that were not returned with the update farm lists
    Object.keys(selectedComps).forEach((addressIdStr) => {
      const addressId = parseInt(addressIdStr);
      if (!selectedCompsInHcFarm[addressId]) {
        hcResponse.compsFarmList.push(selectedComps[addressIdStr]);
      }
      if (!selectedCompsInUserFarm[addressId]) {
        userResponse.compsFarmList.push(selectedComps[addressIdStr]);
      }
    });
    yield put(
      rentalCompsRefreshReadyForRecompute({
        hcCompsFarmList: processRentalPropertyList(
          hcResponse.compsFarmList,
          shouldScrubData
        ),
        userCompsFarmList: processRentalPropertyList(
          userResponse.compsFarmList,
          shouldScrubData
        ),
        previousCompAddressIds
      })
    );
  } catch (e) {
    console.error(e);
    yield put(rentalCompsFarmUpdateFailure());
    throw e;
  }
}

export default function registerCompsPhotosSaga() {
  watchEvery({
    [GET_REPORT_COMPS]: handleGetComps,
    [COMPS_REFRESH]: handleRefreshComps,
    [RENTAL_COMPS_REFRESH]: handleRentalCompsRefresh
  });
}
