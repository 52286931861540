import React, { useEffect, useState, useRef } from 'react';
//@ts-ignore
import HC_CONSTANTS from 'HC_CONSTANTS';
import { loadMapApi, getHeading } from 'src/lib/street-view/utils/maps';
import { useStreetView } from 'src/lib/street-view/hooks/useStreetView';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { NullState } from 'src/lib/components/NullState/NullState';
import { GeoLocation } from 'src/lib/property-lookup/types';

import styles from './StreetViewInteractive.css';
import { STATUSES } from 'src/constants';

let panorama: google.maps.StreetViewPanorama;

interface Props {
  geoLocation: GeoLocation;
  dataHcName: string;
}

export const StreetViewInteractive = ({ geoLocation, dataHcName }: Props) => {
  const { GOOGLE_MAPS_KEY } = HC_CONSTANTS;
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const streetView = useStreetView(geoLocation);

  const processSVData = (
    data: google.maps.StreetViewPanoramaData | null,
    status: google.maps.StreetViewStatus
  ) => {
    if (status === 'OK' && data?.location) {
      const { location } = data;

      const streetViewLocation = {
        lat: location.latLng?.lat(),
        lng: location.latLng?.lng()
      };

      const propertyLocation = {
        lat: geoLocation.latitude,
        lng: geoLocation.longitude
      };
      const heading = getHeading(streetViewLocation, propertyLocation);

      panorama.setVisible(true);

      if (panorama && location.pano && heading) {
        panorama.setPano(location.pano);
        panorama.setPov({
          heading,
          pitch: 0
        });
        panorama.setVisible(true);
      }
    }
  };

  const initMap = (): void => {
    if (ref.current) {
      const sv = new google.maps.StreetViewService();
      sv.getPanorama(
        {
          location: { lat: geoLocation.latitude, lng: geoLocation.longitude },
          radius: 50,
          source: window.google.maps.StreetViewSource.OUTDOOR
        },
        processSVData
      );

      const options = {
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM
        },
        panControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM
        },
        motionTracking: false
      };
      panorama = new google.maps.StreetViewPanorama(ref.current, options);
    }
  };

  useEffect((): void => {
    if (!window.google) {
      const googleApi = loadMapApi(GOOGLE_MAPS_KEY);
      googleApi.addEventListener('load', () => {
        setGoogleLoaded(true);
      });
    } else {
      initMap();
    }
  }, []);

  useEffect((): void => {
    if (!panorama && googleLoaded) {
      initMap();
    }
  }, [googleLoaded]);

  if (
    !window.google ||
    !streetView.status ||
    streetView.status === STATUSES.LOADING
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  } else if (
    window.google &&
    streetView.status === STATUSES.SUCCESS &&
    streetView.streetViewExists
  ) {
    return (
      <div data-hc-name={dataHcName} ref={ref} className={styles.StreetView} />
    );
  } else {
    return (
      <div className={styles.NullStateWrapper}>
        <NullState
          dataHcName={`${dataHcName}-null-state`}
          title="Street View Unavailable at this Location"
          className={styles.NullState}
        />
      </div>
    );
  }
};
