export const ORGANIZATION_USAGE_FETCH_REQUEST =
  'ORGANIZATION_USAGE_FETCH_REQUEST';
export const ORGANIZATION_USAGE_SUCCESS = 'ORGANIZATION_USAGE_SUCCESS';
export const ORGANIZATION_USAGE_FAILURE = 'ORGANIZATION_USAGE_FAILURE';
export const ORGANIZATION_USAGE_CLOSE_TRIAL_EXPIRED_MODAL =
  'ORGANIZATION_USAGE_CLOSE_TRIAL_EXPIRED_MODAL';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const ORGANIZATION_USAGE_ACCESS_SUCCESS =
  'ORGANIZATION_USAGE_ACCESS_SUCCESS';

export const fetchOrganizationUsage = (deferred) => ({
  type: ORGANIZATION_USAGE_FETCH_REQUEST,
  payload: {
    deferred
  }
});
export const organizationUsageSuccess = (orgUsage) => ({
  type: ORGANIZATION_USAGE_SUCCESS,
  payload: { orgUsage }
});
export const organizationUsageFailure = (reason) => ({
  type: ORGANIZATION_USAGE_FAILURE,
  payload: {
    reason
  }
});
export const organizationUsageCloseTrialExpiredModal = () => ({
  type: ORGANIZATION_USAGE_CLOSE_TRIAL_EXPIRED_MODAL,
  payload: {}
});
export const subscriptionDetailsSuccess = (subscriptionDetails) => ({
  type: SUBSCRIPTION_SUCCESS,
  payload: {
    subscriptionDetails
  }
});

export const organizationUsageAccessSuccess = (access) => {
  return {
    type: ORGANIZATION_USAGE_ACCESS_SUCCESS,
    payload: {
      access
    }
  };
};
