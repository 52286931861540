import React from 'react';
import classNames from 'classnames';

import { RadioButton as theme } from 'legacy/css-modules';

const RadioButton = ({
  dataHcName,
  disabled,
  label,
  value,
  onChange,
  className,
  checked
}) => {
  return (
    <div
      data-hc-name={dataHcName}
      onClick={disabled ? undefined : () => onChange(value)}
      className={classNames(theme.RadioButton, className, {
        [theme.disabled]: disabled
      })}
    >
      <input onChange={() => {}} type="radio" id={label} checked={checked} />
      <div className={theme.Radio}>
        <div />
      </div>
      {label}
    </div>
  );
};

export default RadioButton;
