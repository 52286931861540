import React, { ReactNode } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from '../../query-client/queryClient';

interface Props {
  children: ReactNode;
}
export const HcsQueryClientProvider = ({ children }: Props) => (
  <QueryClientProvider client={queryClient}>
    {children} <ReactQueryDevtools />
  </QueryClientProvider>
);
