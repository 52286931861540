/**
 *  trackable events
 */
export const VR_EVENT_PREFIX = 'dvr_';

export const EVENTS = {
  // auth events
  AUTH_LOGIN: 'login',
  AUTH_LOGOUT: 'logout',
  AUTH_SIGN_UP_MODAL_SHOWN: 'sign_up_modal_show',
  AUTH_SIGN_UP_COMPLETE: 'sign_up_complete',

  // avm events
  AVM_CHANGE_SELECTED: 'avm_change_selected',
  AVM_USER_OPINION_UPDATE: 'avm_user_opinion_update',
  AVM_OPEN_SELECTOR: 'avm_open_selector',
  RENTAL_AVM_CHANGE_SELECTED: 'rental_avm_change_selected',
  RENTAL_AVM_USER_OPINION_UPDATE: 'rental_avm_user_opinion_update',
  RENTAL_AVM_OPEN_SELECTOR: 'rental_avm_open_selector',

  // comps events
  COMPS_TAG_ADD: 'comps_tag_add',
  COMPS_TAG_REMOVE: 'comps_tag_remove',
  COMPS_TAG_FILTER: 'comps_tag_filter',
  COMPS_SELECT_CLICK: 'comps_select_btn',
  COMPS_ADD_SEARCH: 'comps_add_searched',
  COMPS_LIST_VIEW_CHANGE: 'comps_list_change_view',
  COMPS_LIST_SELECTED_TOGGLE: 'comps_list_selected_toggle',
  COMPS_FILTER: 'comps_filter',
  COMPS_FILTERS_APPLY: 'comps_filters_apply',
  COMPS_FILTER_CHANGE_DISTANCE: 'comps_change_distance',
  COMPS_FILTER_CHANGE_ABSOLUTE: 'comps_change_absolute',
  COMPS_FILTER_CHANGE_RELATIVE: 'comps_change_relative',
  COMPS_FILTER_CLEAR: 'comps_filter_clear',
  COMPS_COMPARE_INIT: 'comps_compare_init',
  COMPS_COMPARE_ADJUST_START: 'comps_compare_adjust_start',
  COMPS_COMPARE_SAVE: 'comps_compare_save',
  COMPS_ADJUST_START: 'comps_adjust_start',
  COMPS_ADJUST_SAVE: 'comps_adjust_save',
  COMPS_SORT: 'comps_sort',
  COMPS_VIEW_PROPERTY_DETAILS: 'comps_view_property_details',
  COMPS_LOOKUP_PROPERTY: 'comps_lookup_property',
  COMPS_SAVE_FITLERS_MODAL_OPEN: 'comps_save_filters_modal_open',
  COMPS_CLEAR_SAVE_FITLERS_MODAL_OPEN: 'comps_clear_save_filters_modal_open',
  COMPS_SAVE_FITLERS: 'comps_save_fitlers',
  COMPS_CLEAR_SAVE_FILTERS: 'comps_clear_save_filters',
  COMPS_SAVE_FILTERS_TOOLTIP: 'comps_save_fitlers_tooltip',
  COMPS_CHANGE_FILTER_SET_ACTIVE: 'comps_change_filter_set_active',
  COMPS_CHANGE_FILTER_SET_PRIMARY: 'comps_change_filter_set_primary',
  COMPS_DELETE_FILTER_SET: 'comps_delete_filter_set',
  COMPS_SEARCH_BY_MLS_NUMBER: 'comps_search_by_mls_number',
  COMPS_SEARCH_BY_MLS_NUMBER_SELECT: 'comps_search_by_mls_number_select',
  COMPS_SEARCH_BY_MLS_NUMBER_DUPLICATE_ADDRESS_CONFIRM:
    'comps_search_by_mls_duplicate_address_confirm',
  COMPS_SEARCH_BY_MLS_NUMBER_DUPLICATE_ADDRESS_CANCEL:
    'comps_search_by_mls_duplicate_address_cancel',

  // rental comps events
  RENTAL_COMPS_SELECT_CLICK: 'rental_comps_select_btn',
  RENTAL_COMPS_ADD_SEARCH: 'rental_comps_add_searched',
  RENTAL_COMPS_LIST_VIEW_CHANGE: 'rental_comps_list_change_view',
  RENTAL_COMPS_LIST_SELECTED_TOGGLE: 'rental_comps_list_selected_toggle',
  RENTAL_COMPS_FILTER: 'rental_comps_filter',
  RENTAL_COMPS_FILTERS_APPLY: 'rental_comps_filters_apply',
  RENTAL_COMPS_FILTER_CHANGE_RELATIVE: 'renal_comps_filters_change_absolute',
  RENTAL_COMPS_FILTER_CHANGE_ABSOLUTE: 'renal_comps_filters_change_relative',
  RENTAL_COMPS_FILTER_CLEAR: 'rental_comps_filter_clear',
  RENTAL_COMPS_COMPARE_INIT: 'rental_comps_compare_init',
  RENTAL_COMPS_COMPARE_ADJUST_START: 'rental_comps_compare_adjust_start',
  RENTAL_COMPS_COMPARE_SAVE: 'rental_comps_compare_save',
  RENTAL_COMPS_ADJUST_START: 'rental_comps_adjust_start',
  RENTAL_COMPS_ADJUST_SAVE: 'rental_comps_adjust_save',
  RENTAL_COMPS_SORT: 'rental_comps_sort',
  RENTAL_COMPS_LOOKUP_PROPERTY: 'rental_comps_lookup_property',
  RENTAL_COMPS_SAVE_FITLERS_MODAL_OPEN: 'rental_comps_save_filters_modal_open',
  RENTAL_COMPS_CLEAR_SAVE_FITLERS_MODAL_OPEN:
    'rental_comps_clear_save_filters_modal_open',
  RENTAL_COMPS_SAVE_FITLERS: 'rental_comps_save_filters',
  RENTAL_COMPS_CLEAR_SAVE_FILTERS: 'rental_comps_clear_save_filters',
  RENTAL_COMPS_SAVE_FILTERS_TOOLTIP: 'rental_comps_save_fitlers_tooltip',
  RENTAL_COMPS_CHANGE_FILTER_SET_ACTIVE:
    'rental_comps_change_filter_set_active',
  RENTAL_COMPS_CHANGE_FILTER_SET_PRIMARY:
    'rental_comps_change_filter_set_primary',
  RENTAL_COMPS_DELETE_FILTER_SET: 'rental_comps_delete_filter_set',

  // download events
  DOWNLOAD_PDF: 'download_report_pdf',
  DOWNLOAD_EXCEL: 'download_report_excel',
  DOWNLOAD_SECTION_EXCEL: 'download_section_excel',

  // report events
  REPORT_PULL_NEW: 'report_pull_new',
  REPORT_VIEW: 'report_view',
  REPORT_UPDATE_COMMENT: 'report_update_comment',
  REPORT_SIMULTANEOUS_EDIT_ERROR: 'report_simultaneous_edit_error',
  REPORT_ADJUST_SUBJECT_PROPERTY_DETAILS_START:
    'report_adjust_subject_details_start',
  REPORT_SUBJECT_ADJUST_PROPERTY_DETAILS:
    'report_subject_adjust_property_details',
  REPORT_ADJUST_SUBJECT_CONDITION_UPDATE:
    'report_adjust_subject_condition_update',
  REPORT_CHANGE_FORECAST_AREA_TYPE: 'report_change_forecast_area_type',
  REPORT_VIEW_SUBJECT_SOURCES: 'report_view_subject_sources',
  REPORT_VIEW_SUBJECT_DETAILS: 'report_view_subject_details',

  // persona events
  PERSONA_SELECT: 'persona_select',

  // shared report events
  SHARE_LINK_CREATE: 'share_link_create',
  SHARE_LINK_CONFIGURE: 'share_link_configure',
  SHARE_MODAL_OPEN: 'share_modal_open',
  SHARE_SEND: 'share_send',
  SHARE_LINK_COPY: 'copy_share_link',

  // cobranding events
  COBRANDING_UPDATE: 'cobranding_update',
  COBRANDING_GO_TO_EDIT: 'cobranding_go_to_edit',
  COBRANDING_CONTACT_ME_TOGGLE: 'cobranding_contact_me_toggle',

  // misc events
  PHOTOS_FULLSCREEN_OPEN: 'photos_fullscreen_open',
  PHOTOS_FULLSCREEN_OPEN_FOR_TRANSACTION:
    'photos_fullscreen_open_for_transaction',
  MLS_SHEET_VIEW: 'mls_sheet_view',
  PAGE_VIEWED: 'page_viewed',
  GLOSSARY_OPEN: 'glossary_open',
  SELECT_ORGANIZATION: 'select_organization',
  SETTNGS_OPEN: 'settings_open',
  VIEW_PLANS: 'view_plans',
  ERROR_MODAL: 'error_modal',
  VR_URL_REDIRECT: 'vr_url_redirect',

  // archived reports
  ARCHIVED_REPORTS_MODAL_OPEN: 'archived_reports_modal_open',
  ARCHIVED_REPORTS_VIEW_NEW: 'archived_reports_view_new',
  ARCHIVED_REPORTS_VIEW_ACTIVE: 'archived_reports_view_active',
  ARCHIVED_REPORTS_DOWNLOAD_EXPIRED: 'archived_reports_download_expired',

  // search events
  SEARCH_SUBJECT_PROPERTY: 'search_subject_property',
  SEARCH_NO_RESULT: 'search_no_result',
  SEARCH_NO_RESULT_HELP_CLICK: 'search_no_result_help_click',
  SEARCH_MLS_LISTINGS_PROPERTY_PREVIEW: 'search_mls_listings_property_preview',
  CLIENT_ID_SEARCH_SELECT: 'client_id_search_select',
  VIEW_PROPERTY_PREVIEW: 'view_property_preview',

  // Map events
  MAP_CONTROL_PANEL_TOGGLE: 'map_control_panel_toggle',
  MAP_DRAW_SUBMIT: 'map_draw_submit',
  MAP_DRAW_ADD: 'map_draw_add',
  MAP_DRAW_EDIT: 'map_draw_edit',
  MAP_DRAW_DELETE: 'map_draw_delete',
  MAP_CHANGE_PIN_TYPE: 'map_change_pin_type',
  MAP_HEATMAP_LAYER: 'map_heatmap_layer',

  CHART_BUTTON_FORECAST: 'chart_button_forecast',
  CHART_BUTTON_NEARBY: 'chart_button_nearby',
  CHART_BUTTON_VALUATIONS: 'chart_button_valuation',
  CHART_BUTTON_MARKET: 'chart_button_market'
};

export const EVENT_TYPE_AUTH = 'auth';
export const EVENT_TYPE_AVM = 'avm';
export const EVENT_TYPE_COMPS = 'comps';
export const EVENT_TYPE_RENTAL_COMPS = 'rental_comps';
export const EVENT_TYPE_DOWNLOADS = 'downloads';
export const EVENT_TYPE_REPORT = 'report';
export const EVENT_TYPE_PERSONA = 'persona';
export const EVENT_TYPE_SHARED_REPORT = 'shared_report';
export const EVENT_TYPE_COBRANDING = 'cobranding';
export const EVENT_TYPE_MISC = 'misc';
export const EVENT_TYPE_ARCHIVED_REPORTS = 'archived_reports';
export const EVENT_TYPE_SEARCH = 'search';
export const EVENT_TYPE_MAP = 'map';

export const EVENT_TYPES = {
  // EVENT_TYPE_AUTH
  AUTH_LOGIN: EVENT_TYPE_AUTH,
  AUTH_LOGOUT: EVENT_TYPE_AUTH,
  AUTH_SIGN_UP_MODAL_SHOWN: EVENT_TYPE_AUTH,
  AUTH_SIGN_UP_COMPLETE: EVENT_TYPE_AUTH,
  // EVENT_TYPE_AVM
  AVM_CHANGE_SELECTED: EVENT_TYPE_AVM,
  AVM_USER_OPINION_UPDATE: EVENT_TYPE_AVM,
  AVM_OPEN_SELECTOR: EVENT_TYPE_AVM,
  RENTAL_AVM_CHANGE_SELECTED: EVENT_TYPE_AVM,
  RENTAL_AVM_USER_OPINION_UPDATE: EVENT_TYPE_AVM,
  RENTAL_AVM_OPEN_SELECTOR: EVENT_TYPE_AVM,
  // EVENT_TYPE_COMPS
  COMPS_SELECT_CLICK: EVENT_TYPE_COMPS,
  COMPS_ADD_SEARCH: EVENT_TYPE_COMPS,
  COMPS_LIST_VIEW_CHANGE: EVENT_TYPE_COMPS,
  COMPS_LIST_SELECTED_TOGGLE: EVENT_TYPE_COMPS,
  COMPS_FILTER: EVENT_TYPE_COMPS,
  COMPS_FILTER_CLEAR: EVENT_TYPE_COMPS,
  COMPS_COMPARE_INIT: EVENT_TYPE_COMPS,
  COMPS_COMPARE_ADJUST_START: EVENT_TYPE_COMPS,
  COMPS_COMPARE_SAVE: EVENT_TYPE_COMPS,
  COMPS_ADJUST_START: EVENT_TYPE_COMPS,
  COMPS_ADJUST_SAVE: EVENT_TYPE_COMPS,
  COMPS_SORT: EVENT_TYPE_COMPS,
  COMPS_VIEW_PROPERTY_DETAILS: EVENT_TYPE_COMPS,
  COMPS_LOOKUP_PROPERTY: EVENT_TYPE_COMPS,
  COMPS_SAVE_FITLERS_MODAL_OPEN: EVENT_TYPE_COMPS,
  COMPS_CLEAR_SAVE_FITLERS_MODAL_OPEN: EVENT_TYPE_COMPS,
  COMPS_SAVE_FITLERS: EVENT_TYPE_COMPS,
  COMPS_CLEAR_SAVE_FILTERS: EVENT_TYPE_COMPS,
  COMPS_SAVE_FILTERS_TOOLTIP: EVENT_TYPE_COMPS,
  // EVENT_TYPE_RENTAL_COMPS
  RENTAL_COMPS_SELECT_CLICK: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_ADD_SEARCH: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_LIST_VIEW_CHANGE: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_LIST_SELECTED_TOGGLE: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_FILTER: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_FILTER_CLEAR: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_COMPARE_INIT: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_COMPARE_ADJUST_START: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_COMPARE_SAVE: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_ADJUST_START: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_ADJUST_SAVE: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_SORT: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_LOOKUP_PROPERTY: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_SAVE_FITLERS_MODAL_OPEN: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_CLEAR_SAVE_FITLERS_MODAL_OPEN: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_SAVE_FITLERS: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_CLEAR_SAVE_FILTERS: EVENT_TYPE_RENTAL_COMPS,
  RENTAL_COMPS_SAVE_FILTERS_TOOLTIP: EVENT_TYPE_RENTAL_COMPS,
  // EVENT_TYPE_DOWNLOADS
  DOWNLOAD_PDF: EVENT_TYPE_DOWNLOADS,
  DOWNLOAD_EXCEL: EVENT_TYPE_DOWNLOADS,
  DOWNLOAD_SECTION_EXCEL: EVENT_TYPE_DOWNLOADS,
  // EVENT_TYPE_REPORT
  REPORT_PULL_NEW: EVENT_TYPE_REPORT,
  REPORT_VIEW: EVENT_TYPE_REPORT,
  REPORT_UPDATE_COMMENT: EVENT_TYPE_REPORT,
  REPORT_SIMULTANEOUS_EDIT_ERROR: EVENT_TYPE_REPORT,
  REPORT_ADJUST_SUBJECT_PROPERTY_DETAILS_START: EVENT_TYPE_REPORT,
  REPORT_ADJUST_SUBJECT_PROPERTY_DETAILS_SAVE: EVENT_TYPE_REPORT,
  REPORT_ADJUST_SUBJECT_CONDITION: EVENT_TYPE_REPORT,
  REPORT_CHANGE_FORECAST_AREA_TYPE: EVENT_TYPE_REPORT,
  REPORT_VIEW_SUBJECT_SOURCES: EVENT_TYPE_REPORT,
  REPORT_VIEW_SUBJECT_DETAILS: EVENT_TYPE_REPORT,
  // EVENT_TYPE_PERSONA
  PERSONA_SELECT: EVENT_TYPE_PERSONA,
  // EVENT_TYPE_SHARED_REPORT
  SHARE_LINK_CREATE: EVENT_TYPE_SHARED_REPORT,
  SHARE_LINK_CONFIGURE: EVENT_TYPE_SHARED_REPORT,
  SHARE_MODAL_OPEN: EVENT_TYPE_SHARED_REPORT,
  SHARE_SEND: EVENT_TYPE_SHARED_REPORT,
  SHARE_LINK_COPY: EVENT_TYPE_SHARED_REPORT,
  // EVENT_TYPE_COBRANDING
  COBRANDING_UPDATE: EVENT_TYPE_COBRANDING,
  COBRANDING_GO_TO_EDIT: EVENT_TYPE_COBRANDING,
  COBRANDING_CONTACT_ME_TOGGLE: EVENT_TYPE_COBRANDING,
  // EVENT_TYPE_MISC
  PHOTOS_FULLSCREEN_OPEN: EVENT_TYPE_MISC,
  PAGE_VIEWED: EVENT_TYPE_MISC,
  GLOSSARY_OPEN: EVENT_TYPE_MISC,
  SELECT_ORGANIZATION: EVENT_TYPE_MISC,
  SETTNGS_OPEN: EVENT_TYPE_MISC,
  VIEW_PLANS: EVENT_TYPE_MISC,
  ERROR_MODAL: EVENT_TYPE_MISC,
  PHOTOS_FULLSCREEN_OPEN_FOR_TRANSACTION: EVENT_TYPE_MISC,
  MLS_SHEET_VIEW: EVENT_TYPE_MISC,
  VR_URL_REDIRECT: EVENT_TYPE_MISC,
  // EVENT_TYPE_ARCHIVED_REPORTS
  ARCHIVED_REPORTS_MODAL_OPEN: EVENT_TYPE_ARCHIVED_REPORTS,
  ARCHIVED_REPORTS_VIEW_NEW: EVENT_TYPE_ARCHIVED_REPORTS,
  ARCHIVED_REPORTS_VIEW_ACTIVE: EVENT_TYPE_ARCHIVED_REPORTS,
  ARCHIVED_REPORTS_DOWNLOAD_EXPIRED: EVENT_TYPE_ARCHIVED_REPORTS,
  // EVENT_TYPE_SEARCH
  SEARCH_SUBJECT_PROPERTY: EVENT_TYPE_SEARCH,
  SEARCH_NO_RESULT: EVENT_TYPE_SEARCH,
  SEARCH_NO_RESULT_HELP_CLICK: EVENT_TYPE_SEARCH,
  SEARCH_MLS_LISTINGS_PROPERTY_PREVIEW: EVENT_TYPE_SEARCH,
  // EVENT_TYPE_MAP
  MAP_CONTROL_PANEL_TOGGLE: EVENT_TYPE_MAP,
  MAP_DRAW_ADD: EVENT_TYPE_MAP,
  MAP_DRAW_EDIT: EVENT_TYPE_MAP,
  MAP_DRAW_DELETE: EVENT_TYPE_MAP,
  MAP_CHANGE_PIN_TYPE: EVENT_TYPE_MAP,
  MAP_HEATMAP_LAYER: EVENT_TYPE_MAP
};
