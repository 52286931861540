import React from 'react';
import { BackIcon } from 'src/lib/inline-svgs';
import styles from './BackButton.css';
import classNames from 'classnames';

interface Props {
  dataHcName: string;
  className?: string;
}
export const BackButton = ({ dataHcName, className }: Props) => {
  return (
    <button className={classNames(styles.Button, className)}>
      <BackIcon height="16px" dataHcName={`${dataHcName}-icon`} />
    </button>
  );
};
