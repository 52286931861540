import {
  createRef,
  forwardRef,
  Children,
  Component,
  cloneElement,
  Fragment
} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { ID_PORTAL_FIXED } from 'legacy/appstore/constants';

import { isFunc } from 'legacy/utils/utils';

import { PopoverEndgame as theme } from 'legacy/css-modules';

const CARET_WIDTH = 14;
const CARET_HEIGHT = 16;
// Renders Popover outside of current DOM tree
const PopoverContentPortal = ({ children }) => {
  return createPortal(children, document.getElementById(ID_PORTAL_FIXED));
};

const PopoverButton = forwardRef((props, ref) => {
  const {
    children,
    showOnMouseEnter,
    className,
    onClick,
    onMouseEnter,
    onMouseLeave
  } = props;
  return Children.map(children, (child) => {
    // If the button is component we need to wrap it in a span to access the ClientBoundingRect when positioning
    const isComponent = child && typeof child.type === 'function';
    const cloned = cloneElement(child, {
      ...child.props,
      ...{
        className: !isComponent
          ? classNames(child.props.className, className)
          : child.props.className,
        ref: isComponent ? undefined : ref,
        onClick: !showOnMouseEnter
          ? (e) => {
              onClick(e);
              if (isFunc(child.props.onClick)) {
                child.props.onClick();
              }
            }
          : undefined,
        onMouseEnter: showOnMouseEnter
          ? (e) => {
              onMouseEnter(e);
              if (isFunc(child.props.onMouseEnter)) {
                child.props.onMouseEnter();
              }
            }
          : undefined,
        onMouseLeave: showOnMouseEnter
          ? (e) => {
              onMouseLeave(e);
              if (isFunc(child.props.onMouseLeave)) {
                child.props.onMouseLeave(e);
              }
            }
          : undefined
      }
    });
    if (isComponent) {
      return (
        <span className={classNames(theme.RefWrap, className)} ref={ref}>
          {cloned}
        </span>
      );
    } else {
      return cloned;
    }
  });
});

const PopoverContent = forwardRef((props, ref) => {
  const {
    className,
    caretPosition,
    content,
    contentPosition,
    isOpen,
    isPositioned,
    maxWidth,
    showOnMouseEnter,
    overlay,
    onClose,
    onMouseLeave,
    anchor,
    closeOnClick
  } = props;
  const style = {
    ...contentPosition,
    maxWidth
  };
  return (
    <PopoverContentPortal>
      {isOpen && (
        <Fragment>
          <div
            ref={ref}
            className={classNames(
              theme.PopoverContent,
              theme[anchor],
              className,
              {
                [theme.visible]: isPositioned
              }
            )}
            style={style}
            onMouseLeave={showOnMouseEnter ? onMouseLeave : undefined}
            onClick={(e) => {
              e.stopPropagation();
              if (closeOnClick) {
                onClose(e);
              }
            }}
          >
            {content}
            <div style={caretPosition} className={theme.PopoverCaret}>
              <div />
            </div>
          </div>
          {overlay && <div className={theme.Overlay} onClick={onClose} />}
        </Fragment>
      )}
    </PopoverContentPortal>
  );
});

class PopoverEndgame extends Component {
  state = {
    anchor: 'bottom',
    isOpen: false,
    isPositioned: false
  };

  Content = createRef();
  Button = createRef();

  static defaultProps = {
    maxWidth: '400px', // Max Width for the PopoverContent applied as inline style
    pagePadding: 15, // Padding between PopupContent and the edge of the window
    showOnMouseEnter: false, // Show popover onMouseEnter
    overlay: true
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeWindow);
    if (!this.props.overlay) {
      window.removeEventListener('click', this.handleClose);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.state;
    const { repositionTrigger } = this.props;
    if (
      (isOpen && !prevState.isOpen) ||
      (isOpen && repositionTrigger !== prevProps.repositionTrigger)
    ) {
      this.positionContent();
    }
  }

  positionContent = () => {
    const { pagePadding } = this.props;
    if (
      !this.Button ||
      !this.Button.current ||
      !this.Content ||
      !this.Content.current
    )
      return;
    const Button = this.Button.current;
    const Content = this.Content.current;
    const button = Button.getBoundingClientRect();
    if (button.left < pagePadding) {
      this.handleClose();
    }
    const content = Content.getBoundingClientRect();
    const width = Math.min(content.width, window.innerWidth - pagePadding * 2);
    const height = Math.min(
      content.height,
      window.innerHeight - pagePadding * 2
    );
    const defaultTop = Math.max(
      pagePadding,
      button.top + button.height - pagePadding
    );
    const anchor = defaultTop + height > window.innerHeight ? 'top' : 'bottom';
    const buttonCenter = button.left + button.width / 2;
    const top =
      anchor === 'top'
        ? Math.max(pagePadding, button.top - height) - CARET_HEIGHT
        : Math.max(pagePadding, button.top + button.height) + CARET_HEIGHT;
    const left = Math.max(
      Math.min(
        buttonCenter - width / 2,
        window.innerWidth - width - pagePadding * 2
      ),
      pagePadding
    );

    this.setState({
      isPositioned: true,
      caretPosition: {
        left: Math.min(
          buttonCenter - left - CARET_WIDTH / 2,
          window.innerWidth - CARET_WIDTH - pagePadding * 2
        )
      },
      contentPosition: {
        width: `${width}px`,
        left,
        top
      },
      anchor
    });
  };

  handleClose = (e) => {
    const clickedOutsideComponent = this.isEventOutsideBoundsOfComponent(e);
    if (!clickedOutsideComponent) {
      e.stopPropagation();
    }
    const { overlay, showOnMouseEnter } = this.props;
    if ((overlay && !showOnMouseEnter) || clickedOutsideComponent) {
      if (!overlay) {
        window.removeEventListener('click', this.handleClose);
      }
      this.setState({
        isOpen: false,
        isPositioned: false
      });
    }
  };

  handleToggle = () => {
    if (!this.state.isOpen && !this.props.overlay) {
      window.addEventListener('click', this.handleClose);
    } else if (this.state.isOpen && !this.props.overlay) {
      window.removeEventListener('click', this.handleClose);
    }
    this.setState({
      isOpen: !this.state.isOpen,
      isPositioned: false
    });
  };

  handleMouseEnter = () => {
    this.setState({
      isOpen: true,
      positioned: false
    });
  };

  isWithinBounds = (pos, bounds) => {
    const x1 = bounds.x;
    const x2 = x1 + bounds.width;
    const y1 = bounds.y;
    const y2 = y1 + bounds.height;
    return pos.x >= x1 && pos.x <= x2 && pos.y >= y1 && pos.y <= y2;
  };

  isEventOutsideBoundsOfComponent = (e) => {
    if (!e || !this.Button || !this.Content) return true;
    if (this.props.showOnMouseEnter) {
      const pos = {
        x: e.clientX,
        y: e.clientY
      };
      const inBoundsButton =
        this.Button &&
        this.Button.current &&
        this.isWithinBounds(pos, this.Button.current.getBoundingClientRect());
      const inBoundsContent =
        this.Content &&
        this.Content.current &&
        this.isWithinBounds(pos, this.Content.current.getBoundingClientRect());
      return (
        (!inBoundsButton && !inBoundsContent) ||
        !this.Button ||
        !this.Button.current
      );
    } else if (!this.props.overlay) {
      return (
        !this.Button.current.contains(e.target) &&
        !this.Content.current.contains(e.target)
      );
    }
  };

  handleMouseLeave = (e) => {
    if (!e) {
      return this.handleClose();
    } else {
      this.handleClose(e);
    }
  };

  handleResizeWindow = () => {
    window.requestAnimationFrame(() => {
      this.positionContent();
    });
  };

  render() {
    const { anchor, caretPosition, contentPosition, isOpen, isPositioned } =
      this.state;
    const { content, trigger, showOnMouseEnter, classNameTrigger, ...props } =
      this.props;
    return (
      <Fragment>
        <PopoverButton
          onClick={this.handleToggle}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          showOnMouseEnter={showOnMouseEnter}
          className={classNameTrigger}
          children={trigger}
          ref={this.Button}
        />
        <PopoverContent
          anchor={anchor}
          content={content}
          isOpen={isOpen}
          isPositioned={isPositioned}
          caretPosition={caretPosition}
          contentPosition={contentPosition}
          ref={this.Content}
          onClose={this.handleClose}
          onMouseLeave={this.handleMouseLeave}
          showOnMouseEnter={showOnMouseEnter}
          {...props}
        />
      </Fragment>
    );
  }
}

export default PopoverEndgame;
