import classNames from 'classnames';

import { SimilarityLevel as theme } from 'legacy/css-modules';

const SimilarityLevelDot = () => {
  return (
    <svg height="7" width="7" className={theme.SimilarityLevelDot}>
      <circle cx="3.5" cy="3.5" r="3.5" strokeWidth="0" />
    </svg>
  );
};

const SimilarityLevel = ({ similarityLevel, className }) => {
  return (
    <div
      className={classNames(
        className,
        theme.SimilarityLevel,
        theme[similarityLevel]
      )}
    >
      <SimilarityLevelDot />
      <SimilarityLevelDot />
      <SimilarityLevelDot />
    </div>
  );
};

export default SimilarityLevel;
