import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { put, call } from 'redux-saga/effects';
import { resetVrState } from 'legacy/appstore/clear-reducer-on-actions';
import { fetchOrganizationUsage } from 'actions/organization-usage';
import { searchProperty } from 'actions/property-search';
import { fetchRecentReports } from 'actions/recent-reports';
import PropertyGraph from 'legacy/services/property-graph';
import { VIEWS } from 'legacy/routes/constants';

export default function* routeSearch({ view, params, query }) {
  yield put(resetVrState());
  yield put(fetchRecentReports());
  yield put(fetchOrganizationUsage());

  if (query.street_address || (query.listing_id && query.listing_source)) {
    yield put(searchProperty(query));
  } else if (query.address_slug || query.address_id) {
    const propertyIdentifier = {};
    if (query.address_slug) {
      propertyIdentifier.slug = query.address_slug;
    }
    if (query.address_id) {
      propertyIdentifier.hcAddressId = query.address_id;
    }
    const response = yield call(PropertyGraph.fetchAddress, propertyIdentifier);
    try {
      if (
        response?.propertyLookup?.address?.streetAddress &&
        response?.propertyLookup?.address?.zipcode
      ) {
        query.street_address = response?.propertyLookup?.address?.streetAddress;
        query.zipcode = response?.propertyLookup?.address?.zipcode;
        if (response?.propertyLookup?.address?.unit) {
          query.unit = response?.propertyLookup?.address?.unit;
        }
        yield put(
          routeChange({
            view: VIEWS.SEARCH,
            query,
            options: { inherit: false }
          })
        );
      }
    } catch (e) {
      console.warn('Invalid Query Params');
    }
  }
}
