import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { report } from 'hc-ravenjs-logger';
import { getCurrentView } from '@hc/redux-saga-router-plus/hclib/selectors';
import matchMediaService from 'legacy/services/match-media';
import { optionalSingleSignOn } from 'legacy/appstore/actions/auth.actions';
import Modals from 'legacy/components/Modals';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { VIEWS } from 'legacy/routes/constants';
import { getFeatureFlagsStatus, getFeatureFlagsData } from 'legacy/appstore/selectors/feature-flags';
import { getHasAgileOpsRole } from 'legacy/appstore/selectors/account-details.selectors';
import { getIsLoggedIn } from 'legacy/appstore/selectors/auth';
import { STATUSES } from 'legacy/appstore/constants';
import { NullStateCard } from '@hc/component-lib/hclib/components/molecules/null-state-card';

const mapStateToProps = (state) => {
  return {
    view: getCurrentView(state),
    isAgileOps: getHasAgileOpsRole(state),
    reportApiFeatureFlag: (getFeatureFlagsData(state) || {})['report-api'],
    featureFlagsStatus: getFeatureFlagsStatus(state),
    isLoggedIn: getIsLoggedIn(state)
  };
};

class AppContainer extends PureComponent {
  componentDidMount() {
    if (this.props.view !== VIEWS.LOGOUT) {
      this.props.optionalSingleSignOn();
    }
  }
  componentWillMount() {
    matchMediaService.subscribe();
  }

  componentWillUnmount() {
    matchMediaService.unsubscribe();
  }

  /** Catch and report all React errors
   * Errors within sagas are caught and reported in `saga.utils.js` */
  componentDidCatch(error, errorInfo) {
    console.error(error);
    report(error, {
      errorInfo: {
        errorLogSrc: 'app.container.jsx -> componentDidCatch',
        ...errorInfo
      },
      originalError: error
    });
  }

  render() {
    const { featureFlagsStatus, isLoggedIn, isAgileOps, reportApiFeatureFlag } = this.props;
    const shouldRedirect = isLoggedIn && featureFlagsStatus === STATUSES.SUCCESS && reportApiFeatureFlag && !isAgileOps;
    console.log('isAO, reportApiFeatureFlag', isAgileOps, reportApiFeatureFlag);
    return (
      <div id="app-container">
        {!isLoggedIn || (featureFlagsStatus === STATUSES.SUCCESS && !shouldRedirect)
          ? this.props.children
          : featureFlagsStatus === STATUSES.ERROR
          ? (
            <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
              <NullStateCard
                title="An Error Occurred. Please Refresh the Page"
              />
            </div>
          )
          : <LoadingSpinner />
        }
        <Modals />
      </div>
    );
  }
}

// Entry point for mounting and unmounting the application
export default connect(mapStateToProps, { optionalSingleSignOn })(AppContainer);
