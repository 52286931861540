import apiUtil from 'legacy/utils/api';
import { call, delay } from 'redux-saga/effects';
import FileSaver from 'file-saver';

import { toSnakeCase } from 'legacy/utils/transform';
/**
 *  TODO consolidate these download methods into a single method
 */
export function* downloadFile(fileName, downloadUrl, postData) {
  // Icky browser detection:  Safari won't download blob urls
  // and there is no way to test for that as a feature, so just
  // detect it and work around by downloading straight from the server
  const document = window.document;
  const a = document.createElement('a');
  const downloadLinkSupported = 'download' in a;
  const isSafari =
    window.safari || /CriOS\/[\d]+/.test(window.navigator.userAgent);
  if (!downloadLinkSupported && isSafari) {
    // Stupid hardcoded timeout:  Just long enough to show a progress bar that indicates we
    // are doing something to hopefully prevent user confusion since we can't actually
    // detect that the download started.
    yield delay(2000);

    // Load the download into a sacrificial hidden iframe.  We could just use
    // window.location.replace(downloadUrl), but with that approach if for some reason
    // the download fails the whole page would be replaced with an error page.
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    // SECURITY: only known urls are used @jnettleman
    // eslint-disable-next-line scanjs-rules/assign_to_src
    iframe.src = downloadUrl;
    document.body.appendChild(iframe);
  }
  const responseData = yield call(apiUtil.POST, downloadUrl, postData, {
    receiveFile: true
  });
  FileSaver.saveAs(responseData, fileName);
}

export function* downloadPOST(
  downloadUrl,
  urlParams,
  requestData,
  headers = {}
) {
  const requestOptions = {
    receiveFile: true,
    fullResponse: true,
    urlParams: toSnakeCase(urlParams),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...headers
    }
  };
  const response = yield call(
    apiUtil.POST,
    downloadUrl,
    requestData,
    requestOptions
  );
  const responseData = yield call([response, response.blob]);
  const responseHeaders = response.headers;
  const fileName = responseHeaders['content-disposition']
    ? responseHeaders['content-disposition']
        .split(';')[1]
        .trim()
        .split('=')[1]
        .replace(/"/g, '')
    : requestData.name;
  FileSaver.saveAs(responseData, fileName);
}
