import { useMemo } from 'react';
import { Listing, PropertyIdentifierInput } from '../types';
import { GET_LISTINGS } from '../api/queries';
import { useQuery } from '@apollo/client';

export interface LimitListing {
  type: 'listing';
  listingID: string;
}
export interface LimitLatestListing {
  type: 'latestListing';
  salesOnly?: boolean;
}
export interface LimitEffectiveDate {
  type: 'effectiveDate';
  date: string;
}
export type LimitType = LimitListing | LimitLatestListing | LimitEffectiveDate;

export const useListings = (hcAddressId: number, limit?: LimitType) => {
  const {
    loading,
    error,
    data: queryData
  } = useQuery<
    {
      propertyLookup: {
        listings: Listing[];
      };
    },
    PropertyIdentifierInput
  >(GET_LISTINGS, {
    variables: { hcAddressId }
  });

  const data = useMemo(() => {
    const listingsData = queryData?.propertyLookup.listings;
    if (limit && listingsData) {
      // For limit type sthat should only return a single result
      let foundResult = false;
      return listingsData.filter((listing) => {
        if (
          limit.type === 'latestListing' &&
          !foundResult &&
          ((limit.salesOnly && !listing.isListingRental) || !limit.salesOnly)
        ) {
          foundResult = true;
          return true;
        } else if (
          limit.type === 'listing' &&
          listing.listingID === limit.listingID
        ) {
          return true;
        } else if (
          limit.type === 'effectiveDate' &&
          listing.listingStatus.listingDate &&
          listing.listingStatus.listingDate <= limit.date
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      return listingsData;
    }
  }, [queryData, limit]);

  return {
    loading,
    error,
    data
  };
};
