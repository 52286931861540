import React from 'react';
import { PropertyDetailsHeader } from 'src/lib/components/PropertyDetailsHeader';
import { useComp } from '../../hooks';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import {
  formatMissing,
  formatNumber,
  formatPropertyType,
  formatSqFt
} from 'src/lib/utils';
import { CompId } from 'src/lib/report-api/types/schemas';

interface Props {
  reportId: number;
  compId: CompId;
  dataHcName: string;
  className?: string;
}
export const CompDetailsHeader = ({
  reportId,
  compId,
  dataHcName,
  className
}: Props) => {
  const { compSchema } = useComp(reportId, compId);

  if (compSchema === undefined || compSchema === null) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  return (
    <PropertyDetailsHeader
      dataHcName={dataHcName}
      address={compSchema.address}
      propertyAttributes={[
        `${formatPropertyType(compSchema.propertyType)}`,
        `${formatNumber(compSchema.livingSpace.bedrooms?.count)} Beds`,
        `${formatMissing(
          compSchema.livingSpace.bathrooms?.summaryCount
        )} Baths`,
        `${formatSqFt(compSchema.livingSpace.livingArea)}`,
        `${formatSqFt(compSchema.latestListing.site.lot?.areaSqFt)} Lot`
      ]}
      publicRemarks={compSchema.latestListing.publicRemarks}
      className={className}
    />
  );
};
