import {
  CLIENT_ID_SEARCH_SELECT,
  CLIENT_ID_SEARCH_RESULTS,
  CLIENT_ID_SEARCH_SUBMIT,
  CLIENT_ID_INPUT_CHANGE
} from 'actions/clientIdSearch';
import { STATUSES } from 'legacy/appstore/constants';

const INITIAL_STATE = {
  input: '',
  results: [],
  status: STATUSES.INIT
};

export function clientIdSearchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLIENT_ID_SEARCH_SUBMIT:
      return {
        ...state,
        status: STATUSES.LOADING,
        input: action.payload.input
      };

    case CLIENT_ID_SEARCH_RESULTS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        results: action.payload.results
      };

    case CLIENT_ID_INPUT_CHANGE:
      return {
        ...state,
        status: STATUSES.INIT,
        input: action.payload.input,
        results: []
      };

    case CLIENT_ID_SEARCH_SELECT: {
      return {
        status: STATUSES.INIT,
        input: '',
        results: []
      };
    }

    default:
      return state;
  }
}
