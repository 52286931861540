import classNames from 'classnames';
import React, { ReactNode, useRef } from 'react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { FilterButton } from 'src/lib/components/FilterButton/FilterButton';
import { Popover } from 'src/lib/components/Popover';
import { useVisibleInOverflow } from 'src/lib/hooks/useVisibleInOverflow';
import { useDocumentRole } from 'src/lib/report-api/hooks';
import { useRerender } from 'src/lib/report-api/hooks/useRerender';
import { DocumentRoles } from 'src/lib/report-api/types';
import { CompFields } from 'src/lib/report-api/types/CompFields';
import { useCompFilterBarState } from '../useCompFilterBarState';
import styles from './CompFilterButton.css';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
  label: string;
  content: ReactNode;
  active: boolean;
  compField: CompFields;
}
const MoreFiltersPortal = ({ children }: { children: ReactNode }) => {
  const {
    compFilterBarState: { overflowPortalId }
  } = useCompFilterBarState();
  const elm = overflowPortalId
    ? document.getElementById(overflowPortalId)
    : null;
  // Rerender in case the portal is mounted simultaneously
  useRerender({ deps: [elm], shouldRerender: !elm, max: 1 });
  if (!elm) return null;
  return createPortal(children, elm);
};

export const CompFilterButton = ({
  dataHcName,
  className,
  label,
  content,
  active,
  compField,
  reportId
}: Props) => {
  const {
    compFilterBarState: {
      buttonsVisible,
      overflowVisible,
      overflowButtonVisible,
      overflowCount
    },
    compFilterBarActions: { compFilterButtonVisibilityChange }
  } = useCompFilterBarState();
  const ref = useRef<HTMLButtonElement>(null);
  const { visibleFull } = useVisibleInOverflow(ref);
  const {
    data: [filterDocument]
  } = useDocumentRole(reportId, DocumentRoles.Filter);
  // Rerender whenever the filters change to ensure button is still visible
  useRerender({ deps: [filterDocument] });
  useEffect(() => {
    if (buttonsVisible[compField] !== visibleFull && ref.current) {
      // Need to wait until the overflow button is in the dom since it affects the parent size
      if (!!overflowCount === overflowButtonVisible) {
        compFilterButtonVisibilityChange(compField, visibleFull);
      }
    }
  }, [
    buttonsVisible[compField],
    visibleFull,
    overflowButtonVisible,
    overflowCount
  ]);
  const button = (
    // The button needs this extra div in order to calculate visiblity correctly
    // Without it the margin can cause an infinite loop
    <div className={styles.CompFilterButton}>
      <FilterButton
        ref={ref}
        dataHcName={dataHcName}
        className={classNames(className)}
        active={active}
      >
        {label}
      </FilterButton>
    </div>
  );
  return (
    <>
      <Popover
        trigger={button}
        content={visibleFull && content}
        theme={{
          Button: classNames({ [styles.hidden || '']: !visibleFull })
        }}
        active={!visibleFull ? false : undefined}
      />
      {!visibleFull && overflowVisible && (
        <MoreFiltersPortal>
          <div
            className={styles.OverflowFilter}
            data-hc-name={`${dataHcName}-filter-overflow-${compField}`}
          >
            {content}
          </div>
        </MoreFiltersPortal>
      )}
    </>
  );
};
