import { GeoLocation } from 'src/lib/property-lookup/types';

export const createStreetViewId = ({ latitude, longitude }: GeoLocation) => {
  return `${latitude},${longitude}`;
};

export const createStreetViewIdFromLatLng = ([latitude, longitude]: [
  number,
  number
]): string => {
  return createStreetViewId({ latitude, longitude });
};
