import { combineReducers } from 'redux';
import { documentRoleReducer } from './documentRoleReducer';
import { documentReducer } from './documentReducer';
import { reportReducer } from './reportReducer';
import { REPORT_API_REDUCER_KEY } from '../constants';
import {
  cobrandingReducer,
  COBRANDING_REDUCER_KEY
} from './cobranding.reducer';
import {
  compFiltersReducer,
  COMP_FILTER_BAR_REDUCER_KEY
} from '../features/CompFilterBar/compFilterBar.reducer';

export const reportApiReducer = combineReducers({
  reports: reportReducer,
  documents: documentReducer,
  documentRoles: documentRoleReducer,
  [COBRANDING_REDUCER_KEY]: cobrandingReducer,
  [COMP_FILTER_BAR_REDUCER_KEY]: compFiltersReducer
});

export type ReportApiState = ReturnType<typeof reportApiReducer>;
export interface StateWithReportApi {
  [REPORT_API_REDUCER_KEY]: ReportApiState;
}
