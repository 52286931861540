import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Price.css';
import { ListingStatusNormalized } from 'src/lib/utils/listingStatus';

interface Props {
  dataHcName: string;
  className?: string;
  price: string | ReactNode;
  date: string | ReactNode;
  label: string | ReactNode;
  listingStatusColor?: ListingStatusNormalized;
}
export const Price = ({
  dataHcName,
  className,
  listingStatusColor,
  price,
  date,
  label
}: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.Price, className)}
    >
      <div>
        <span
          className={classNames(
            styles.Label,
            listingStatusColor
              ? styles[listingStatusColor.toLowerCase()]
              : undefined
          )}
          data-hc-name={`${dataHcName}-label`}
        >
          {label}
        </span>
        <span className={styles.Date}>
          {' '}
          ・ <span data-hc-name={`${dataHcName}-date`}>{date}</span>
        </span>
      </div>
      <div className={styles.Value} data-hc-name={`${dataHcName}-value`}>
        {price}
      </div>
    </div>
  );
};
