import React from 'react';
import { CommentIcon } from 'src/lib/inline-svgs';
import { useDocumentRole } from '../../hooks';
import { DocumentRoles, SubjectDocument } from '../../types';
import styles from './SubjectCommentIcon.css';
import { isLoading } from 'src/lib/utils/general';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';

interface Props {
  reportId: number;
  onClick?: VoidFunction;
  dataHcName?: string;
  height?: string;
  width?: string;
}

export const SubjectCommentIcon = ({
  reportId,
  height = '30px',
  width,
  dataHcName = 'subject-comment-icon',
  onClick
}: Props) => {
  const {
    data: [subjectDocument],
    status: subjectDocStatus
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  if (isLoading(subjectDocStatus) || subjectDocument == null) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  return (
    <div
      data-hc-name={dataHcName}
      className={styles.CommentIcon}
      onClick={onClick}
    >
      {!!subjectDocument.data.comments && (
        <div className={styles.CommentIconIndicator} />
      )}
      <CommentIcon
        height={height}
        width={width}
        dataHcName="subject-comment-icon-svg"
      />
    </div>
  );
};

export default CommentIcon;
