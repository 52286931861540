import { createSelector } from 'reselect';

export const getFeatureFlagsState = (state) => state.featureFlags;

export const getFeatureFlagsStatus = createSelector(
  getFeatureFlagsState,
  (featureFlagsState) => featureFlagsState.status
);

export const getFeatureFlagsData = createSelector(
  getFeatureFlagsState,
  (featureFlagsState) => featureFlagsState.data
);
