import {
  UNIQUE_LINK_FETCH_SUCCESS,
  // SHARE_REPORT_SUBMIT_SUCCESS,
  UNIQUE_LINK_FETCH_DOES_NOT_EXIST,
  UNIQUE_LINK_CONFIGURE_SUCCESS
} from 'actions/share-report';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  // This allows values to be loaded asynchronously after form component mounts
  switch (action.type) {
    case UNIQUE_LINK_FETCH_SUCCESS:
    case UNIQUE_LINK_CONFIGURE_SUCCESS:
      const { formValues } = action.payload;
      // Set both values and initial so the form is not dirty when initial values are loaded
      return {
        ...state,
        initial: {
          ...state.initial,
          ...formValues
        },
        values: {
          ...state.values,
          ...formValues
        }
      };

    case UNIQUE_LINK_FETCH_DOES_NOT_EXIST:
      return INITIAL_STATE;

    default:
      return state;
  }
};
