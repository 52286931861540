import {
  call,
  put,
  select,
  actionChannel,
  take,
  takeEvery
} from 'redux-saga/effects';
import { report } from 'hc-ravenjs-logger';
import PropertyGraph from 'legacy/services/property-graph';
import {
  mlsCoverageFetch,
  MLS_COVERAGE_REQUIRED,
  mlsCoverageFetchFailure,
  mlsCoverageFetchSuccess,
  MLS_COVERAGE_FETCH
} from '../actions/mls-coverage.actions';
import { getPropertyMlsCoverageStatus } from 'selectors/mls-coverage';
import { STATUSES } from 'legacy/appstore/constants';
import sagaMiddleware from 'middleware/saga';

export function* handleMlsCoverageFetch(action) {
  const { addressId } = action.payload;
  try {
    const response = yield call(PropertyGraph.fetchMlsCoverage, addressId);
    const propertyLookup = response.propertyLookup;
    yield put(
      mlsCoverageFetchSuccess(addressId, propertyLookup.mlsCoverage || [])
    );
  } catch (e) {
    yield put(mlsCoverageFetchFailure(addressId));
    console.error(e);
    report('MLS Coverage: Saga Failed to Fetch Data', { e, action });
  }
}

function* takeMlsCoverageRequired() {
  const requiredChannel = yield actionChannel(MLS_COVERAGE_REQUIRED);
  while (true) {
    const action = yield take(requiredChannel);
    const status = yield select(
      getPropertyMlsCoverageStatus(action.payload.addressId)
    );
    if (!status || status === STATUSES.INIT) {
      yield put(mlsCoverageFetch(action.payload.addressId));
    }
  }
}

function* runSagas() {
  yield takeEvery(MLS_COVERAGE_FETCH, handleMlsCoverageFetch);
  yield call(takeMlsCoverageRequired);
}

export default function registerMlsCoverageSaga() {
  sagaMiddleware.run(runSagas);
}
