import React from 'react';
import classNames from 'classnames';

import { Dialog } from 'src/lib/components/Dialog';
import { StreetViewInteractive } from 'src/lib/street-view/features/StreetViewInteractive';
import { formatFullAddress } from 'src/lib/utils/formatters';
import { Address, GeoLocation } from 'src/lib/property-lookup/types';

import styles from './StreetViewDialog.css';

interface Props {
  dataHcName: string;
  onClose: VoidFunction;
  active: boolean;
  address: Address;
  geoLocation: GeoLocation;
  className?: string;
}

export const StreetViewDialog = ({
  dataHcName,
  onClose,
  active,
  address,
  geoLocation,
  className
}: Props) => {
  return (
    <Dialog
      dataHcName={dataHcName}
      active={active}
      onClose={onClose}
      title={formatFullAddress(address)}
      type="large"
      className={classNames(styles.StreetViewDialog, className)}
      noContentPadding
    >
      <StreetViewInteractive
        geoLocation={geoLocation}
        dataHcName={`${dataHcName}-interactive`}
      />
    </Dialog>
  );
};
