export const ORG_REPORTS_FETCH_PEXP = 'ORG_REPORTS_FETCH_PEXP';
export const ORG_REPORTS_FETCH_PEXP_SUCCESS = 'ORG_REPORTS_FETCH_PEXP_SUCCESS';
export const ORG_REPORTS_FETCH_PEXP_FAILURE = 'ORG_REPORTS_FETCH_PEXP_FAILURE';
export const ORG_REPORTS_FETCH_EFFECTIVE_DATE =
  'ORG_REPORTS_FETCH_EFFECTIVE_DATE';
export const ORG_REPORTS_FETCH_EFFECTIVE_DATE_SUCCESS =
  'ORG_REPORTS_FETCH_EFFECTIVE_DATE_SUCCESS';
export const ORG_REPORTS_FETCH_EFFECTIVE_DATE_FAILURE =
  'ORG_REPORTS_FETCH_EFFECTIVE_DATE_FAILURE';

export const orgReportsFetchPexp = (addressId) => {
  return {
    type: ORG_REPORTS_FETCH_PEXP,
    payload: { addressId }
  };
};

export const orgReportsFetchPexpSuccess = (reports) => {
  return {
    type: ORG_REPORTS_FETCH_PEXP_SUCCESS,
    payload: { reports }
  };
};

export const orgReportsFetchPexpFailure = () => {
  return {
    type: ORG_REPORTS_FETCH_PEXP_FAILURE
  };
};

export const orgReportsFetchEffectiveDate = (addressId) => {
  return {
    type: ORG_REPORTS_FETCH_EFFECTIVE_DATE,
    payload: { addressId }
  };
};

export const orgReportsFetchEffectiveDateSuccess = (reports) => {
  return {
    type: ORG_REPORTS_FETCH_EFFECTIVE_DATE_SUCCESS,
    payload: { reports }
  };
};

export const orgReportsFetchEffectiveDateFailure = () => {
  return {
    type: ORG_REPORTS_FETCH_EFFECTIVE_DATE_FAILURE
  };
};
