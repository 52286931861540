import { ReactNode, Children, isValidElement } from 'react';

export const noChildren = (children?: ReactNode | null) => {
  if (children == null) {
    throw Error('Children need to be defined');
  }
};

const getTypeName = (element?: ReactNode) => {
  return isValidElement(element)
    ? typeof element.type === 'string'
      ? element.type
      : element.type.name
      ? element.type.name
      : typeof element.type === 'symbol'
      ? (element.type as symbol).description
      : 'unknown'
    : typeof element;
};

const formatTypes = (types: string[]) => {
  return types.length === 1 ? types[0] : types.join(', ').slice(0, -2);
};

// If we are checking for an array of one type or a single element we can use this
export const enforceChildTypes = (
  types: string[],
  children: ReactNode
): void => {
  noChildren(children);
  if (Array.isArray(children) && children.length === 0) {
    return; // if the array is empty no checking in necessary
  }
  const theElementType = getTypeName(
    Array.isArray(children) ? children[0] : children
  );
  if (!theElementType || !types.includes(theElementType)) {
    throw Error(
      `Children need to be one of of types [${formatTypes(
        types
      )}], child is of type ${theElementType}`
    );
  }
};

export const enforceChildTypesOnMany = (
  types: string[],
  children?: ReactNode | null
) => {
  const childArray = Children.toArray(children);

  childArray.forEach((child: ReactNode) => {
    enforceChildTypes(types, child);
  });
};
