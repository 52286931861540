import React from 'react';
import { useDispatch } from 'react-redux';
import { MultiSelect, Option } from 'src/lib/components';
import { FilterCard } from 'src/lib/components/FilterCard';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { PropertyType } from 'src/lib/property-lookup/types';
import { formatPropertyType } from 'src/lib/utils';
import { isLoading } from 'src/lib/utils/general';
import { documentPatch } from '../../actions';
import { useDocumentRole, useReport } from '../../hooks';
import { DocumentRoles, FilterDocument, SubjectDocument } from '../../types';
import { CompFields } from '../../types/CompFields';
import { compFieldGetters } from '../../utils/comps.utils';
import { CompFilterClearButton } from './CompFilterClearButton';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
}
const PROPERTY_TYPE_OPTIONS: Option<PropertyType>[] = [
  {
    value: PropertyType.SingleFamilyDetached,
    label: formatPropertyType(PropertyType.SingleFamilyDetached)
  },
  {
    value: PropertyType.Condo,
    label: formatPropertyType(PropertyType.Condo)
  },
  {
    value: PropertyType.SingleFamilyAttached,
    label: formatPropertyType(PropertyType.SingleFamilyAttached)
  },
  {
    value: PropertyType.MultiFamily,
    label: formatPropertyType(PropertyType.MultiFamily)
  },
  {
    value: PropertyType.Manufactured,
    label: formatPropertyType(PropertyType.Manufactured)
  }
];
export const CompFilterPropertyType = ({
  reportId,
  dataHcName,
  className
}: Props) => {
  const dispatch = useDispatch();
  const {
    status: filterDocumentStatus,
    data: [filterDocument]
  } = useDocumentRole<FilterDocument>(reportId, DocumentRoles.Filter);
  const {
    status: subjectStatus,
    data: [subjectDocument]
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  const { status: reportStatus, data: report } = useReport(reportId);
  if (
    isLoading([filterDocumentStatus, reportStatus, subjectStatus]) ||
    !filterDocument ||
    !report ||
    !subjectDocument
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const filterValue = filterDocument.data.filters[CompFields.propertyType];
  const subjectValue = compFieldGetters[CompFields.propertyType](
    subjectDocument.data
  );
  return (
    <FilterCard
      label="Property Type"
      info={`Subject: ${formatPropertyType(subjectValue)}`}
      className={className}
      dataHcName={dataHcName}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compField={CompFields.propertyType}
          dataHcName={`${dataHcName}-clear`}
        />
      }
    >
      <MultiSelect
        dataHcName={`${dataHcName}-select`}
        value={filterValue?.absoluteValue || []}
        options={PROPERTY_TYPE_OPTIONS}
        onChange={(value: PropertyType[]) => {
          if (value.length === 0) {
            dispatch(
              documentPatch(reportId, filterDocument.documentId, [
                {
                  op: 'remove',
                  path: `/data/filters/${CompFields.propertyType}`
                }
              ])
            );
          } else {
            dispatch(
              documentPatch(reportId, filterDocument.documentId, [
                {
                  op: 'add',
                  path: `/data/filters/${CompFields.propertyType}/absoluteValue`,
                  value
                }
              ])
            );
          }
        }}
      />
    </FilterCard>
  );
};
