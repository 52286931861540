import classNames from 'classnames';
import React from 'react';
import { SubjectPropertyCard } from '../SubjectPropertyCard';

import styles from './CompCompare.css';

interface Props {
  dataHcName: string;
  reportId: number;
}
export const SubjectCompareCol = ({ dataHcName, reportId }: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CompareCol, styles.subjectCol)}
    >
      <SubjectPropertyCard
        reportId={reportId}
        dataHcName={`${dataHcName}-property-card`}
      />
    </div>
  );
};
