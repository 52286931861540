// Checkout Lifecycle
export const SELF_SERVICE_CHECKOUT_SETUP = 'SELF_SERVICE_CHECKOUT_SETUP';
export const SELF_SERVICE_CHECKOUT_COMPLETE = 'SELF_SERVICE_CHECKOUT_COMPLETE';
// Payment Methods
export const SELF_SERVICE_PAYMENT_METHODS_FETCH =
  'SELF_SERVICE_PAYMENT_METHODS_FETCH';
export const SELF_SERVICE_PAYMENT_METHODS_FETCH_SUCCESS =
  'SELF_SERVICE_PAYMENT_METHODS_FETCH_SUCCESS';
export const SELF_SERVICE_PAYMENT_METHODS_FETCH_ERROR =
  'SELF_SERVICE_PAYMENT_METHODS_FETCH_ERROR';
// Packages
export const SELF_SERVICE_PACKAGES_FETCH = 'SELF_SERVICE_PACKAGES_FETCH';
export const SELF_SERVICE_PACKAGES_FETCH_SUCCESS =
  'SELF_SERVICE_PACKAGES_FETCH_SUCCESS';
export const SELF_SERVICE_PACKAGES_FETCH_ERROR =
  'SELF_SERVICE_PACKAGES_FETCH_ERROR';
// Setup Intent
export const SELF_SERVICE_SETUP_INTENT = 'SELF_SERVICE_SETUP_INTENT';
export const SELF_SERVICE_SETUP_INTENT_SUCCESS =
  'SELF_SERVICE_SETUP_INTENT_SUCCESS';
export const SELF_SERVICE_SETUP_INTENT_ERROR =
  'SELF_SERVICE_SETUP_INTENT_ERROR';
export const SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK =
  'SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK';
export const SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_SUCCESS =
  'SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_SUCCESS';
export const SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_ERROR =
  'SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_ERROR';
export const SELF_SERVICE_SETUP_INTENT_CHARGE_CARD =
  'SELF_SERVICE_SETUP_INTENT_CHARGE_CARD';
// Payment Intent
export const SELF_SERVICE_PAYMENT_INTENT = 'SELF_SERVICE_PAYMENT_INTENT';
export const SELF_SERVICE_PAYMENT_INTENT_SUCCESS =
  'SELF_SERVICE_PAYMENT_INTENT_SUCCESS';
export const SELF_SERVICE_PAYMENT_INTENT_AUTHENTICATION_REQUIRED =
  'SELF_SERVICE_PAYMENT_INTENT_AUTHENTICATION_REQUIRED';
export const SELF_SERVICE_PAYMENT_INTENT_ERROR =
  'SELF_SERVICE_PAYMENT_INTENT_ERROR';
export const SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK =
  'SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK';
export const SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK_SUCCESS =
  'SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK_SUCCESS';
export const SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK_ERROR =
  'SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK_ERROR';

// CHECKOUT LIFECYCLE
export const selfServiceCheckoutSetup = () => {
  return {
    type: SELF_SERVICE_CHECKOUT_SETUP
  };
};

export const selfServiceCheckoutComplete = () => {
  return {
    type: SELF_SERVICE_CHECKOUT_COMPLETE
  };
};

// PAYMENT METHODS
export const selfServicePaymentMethodsFetch = () => {
  return {
    type: SELF_SERVICE_PAYMENT_METHODS_FETCH
  };
};

export const selfServicePaymentMethodsFetchSuccess = (paymentMethods) => {
  return {
    type: SELF_SERVICE_PAYMENT_METHODS_FETCH_SUCCESS,
    payload: { paymentMethods }
  };
};

export const selfServicePaymentMethodsFetchError = () => {
  return {
    type: SELF_SERVICE_PAYMENT_METHODS_FETCH_ERROR
  };
};

// PACKAGES
export const selfServicePackagesFetch = () => {
  return {
    type: SELF_SERVICE_PACKAGES_FETCH
  };
};

export const selfServicePackagesFetchSuccess = (packages) => {
  return {
    type: SELF_SERVICE_PACKAGES_FETCH_SUCCESS,
    payload: { packages }
  };
};

export const selfServicePackagesFetchError = () => {
  return {
    type: SELF_SERVICE_PACKAGES_FETCH_ERROR
  };
};

// SETUP INTENT
export const selfServiceSetupIntent = () => {
  return {
    type: SELF_SERVICE_SETUP_INTENT
  };
};

export const selfServiceSetupIntentSuccess = ({
  clientSecret,
  publishableKey
}) => {
  return {
    type: SELF_SERVICE_SETUP_INTENT_SUCCESS,
    payload: { clientSecret, publishableKey }
  };
};

export const selfServiceSetupIntentError = () => {
  return {
    type: SELF_SERVICE_SETUP_INTENT_ERROR
  };
};

export const selfServiceSetupIntentCheckWebhook = () => {
  return {
    type: SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK
  };
};

export const selfServiceSetupIntentCheckWebhookSuccess = (freeItemsAdded) => {
  return {
    type: SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_SUCCESS,
    payload: { freeItemsAdded }
  };
};

export const selfServiceSetupIntentCheckWebhookError = () => {
  return {
    type: SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_ERROR
  };
};

export const selfServiceSetupIntentChargeCard = () => {
  return {
    type: SELF_SERVICE_SETUP_INTENT_CHARGE_CARD
  };
};

// PAYMENT INTENT
export const selfServicePaymentIntent = () => {
  return {
    type: SELF_SERVICE_PAYMENT_INTENT
  };
};

export const selfServicePaymentIntentSuccess = ({
  clientSecret,
  paymentMethodId
}) => {
  return {
    type: SELF_SERVICE_PAYMENT_INTENT_SUCCESS,
    payload: { clientSecret, paymentMethodId }
  };
};

export const selfServicePaymentIntentAuthenticationRequired = ({
  clientSecret,
  paymentMethodId
}) => {
  return {
    type: SELF_SERVICE_PAYMENT_INTENT_AUTHENTICATION_REQUIRED,
    payload: { clientSecret, paymentMethodId }
  };
};

export const selfServicePaymentIntentError = ({ errorCode, errorMessage }) => {
  return {
    type: SELF_SERVICE_PAYMENT_INTENT_ERROR,
    payload: { errorCode, errorMessage }
  };
};

export const selfServicePaymentIntentCheckWebhook = (clientSecret) => {
  return {
    type: SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK
  };
};

export const selfServicePaymentIntentCheckWebhookSuccess = () => {
  return {
    type: SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK_SUCCESS
  };
};

export const selfServicePaymentIntentCheckWebhookError = () => {
  return {
    type: SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK_ERROR
  };
};
