import store from 'legacy/appstore';
import { putAccountDetailsOnState } from 'legacy/appstore/actions/account-details.actions';
import HC_CONSTANTS from 'HC_CONSTANTS';
import { getAuthClient } from '@hc/authentication-lib/hclib/clients';

const authClient = getAuthClient(
  HC_CONSTANTS.LOGIN_API_URL,
  `${HC_CONSTANTS.AUTH_LIB_URL}/`,
  updateUserContextCallback
);

export const updateUserContextCallback = (userContext) => {
  store.dispatch(putAccountDetailsOnState(userContext));
};

let refreshAccessTokenPromise;
export const refreshAccessToken = async () => {
  if (!refreshAccessTokenPromise) {
    refreshAccessTokenPromise = authClient
      .checkOrRefreshToken()
      .then((userContext) => {
        updateUserContextCallback(userContext);
        return userContext;
      })
      .finally(() => {
        refreshAccessTokenPromise = undefined;
      });
  }
  return refreshAccessTokenPromise;
};
