import React from 'react';
import { Button } from 'legacy/components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getPreviousRoute } from '@hc/redux-saga-router-plus/hclib/selectors';
import { VIEWS } from 'legacy/routes/constants';
import { STATUSES } from 'legacy/appstore/constants';
import { Dialog } from 'legacy/components/Dialog';
import {
  getEffectiveDateReportStatus,
  getEffectiveDateReportErrorType
} from 'selectors/effective-date';
import { effectiveDateErrorCta } from 'actions/effective-date';
import { EffectiveDateErrorDialog as styles } from 'legacy/css-modules';

export const EffectiveDateErrorDialog = () => {
  const dispatch = useDispatch();
  const status = useSelector(getEffectiveDateReportStatus);
  const errorType = useSelector(getEffectiveDateReportErrorType);
  const previousRoute = useSelector(getPreviousRoute);
  return (
    <Dialog
      active={status === STATUSES.ERROR}
      title={
        errorType === 'create'
          ? 'Error creating report'
          : errorType === 'not-found'
          ? 'Report not found'
          : 'An error occurred'
      }
    >
      {errorType === 'create' || errorType === 'not-found' ? (
        <div>
          <h4 className={styles.Message}>
            {errorType === 'not-found'
              ? 'We could not find that report. Please check that you have permissions to view the report you are trying to see and try again.'
              : 'We could not create a report for this date. Please select a more recent date and try again'}
          </h4>
          {errorType === 'create' && (
            <p>We have been alerted about this issue</p>
          )}
          {previousRoute.view && errorType === 'create' ? (
            <Button
              onClick={() => dispatch(effectiveDateErrorCta(previousRoute))}
            >
              Go Back
            </Button>
          ) : (
            <Button
              onClick={() =>
                dispatch(effectiveDateErrorCta({ view: VIEWS.SEARCH }))
              }
            >
              Back to Search
            </Button>
          )}
        </div>
      ) : (
        <div>
          <h4 className={styles.Message}>
            Please refresh your browser to continue working on this report.
          </h4>
          <p>We have been alerted about this issue</p>
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </Button>
        </div>
      )}
    </Dialog>
  );
};
