export const LISTINGS_REQUIRED = 'LISTINGS_REQUIRED';
export const LISTINGS_FETCH = 'LISTINGS_FETCH';
export const LISTINGS_FETCH_SUCCESS = 'LISTINGS_FETCH_SUCCESS';
export const LISTINGS_FETCH_FAILURE = 'LISTINGS_FETCH_FAILURE';

export const listingsRequired = (addressId) => {
  return {
    type: LISTINGS_REQUIRED,
    payload: { addressId }
  };
};

export const listingsFetch = (addressId) => {
  return {
    type: LISTINGS_FETCH,
    payload: { addressId }
  };
};

export const listingsFetchSuccess = (addressId, listings) => {
  return {
    type: LISTINGS_FETCH_SUCCESS,
    payload: { addressId, listings }
  };
};

export const listingsFetchFailure = (addressId) => {
  return {
    type: LISTINGS_FETCH_FAILURE,
    payload: { addressId }
  };
};
