import { STATUSES } from 'src/constants';
import { DocumentRoles, FarmDocument, FilteredDocument } from '../types';
import { CompSchema } from '../types/schemas';
import { useDocumentRole } from './useDocumentRole';

export const useCompFarmListFiltered = (reportId: number) => {
  const {
    data: [farmDocument],
    status: farmStatus
  } = useDocumentRole<FarmDocument>(reportId, DocumentRoles.Farm);
  const {
    data: [filteredDocument],
    status: filteredStatus
  } = useDocumentRole<FilteredDocument>(reportId, DocumentRoles.Filtered);
  const data: CompSchema[] = [];
  if (
    farmStatus === STATUSES.SUCCESS &&
    farmDocument &&
    filteredStatus === STATUSES.SUCCESS &&
    filteredDocument
  ) {
    filteredDocument.data.forEach((compId) => {
      const schema = farmDocument.data[compId];
      if (schema) {
        data.push(schema);
      }
    });
  }
  return {
    status,
    data
  };
};
