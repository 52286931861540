import { put } from 'redux-saga/effects';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

import { VR_REDIRECT_VIEW_MOD } from 'legacy/routes/constants';
import { EVENTS } from 'legacy/appstore/events';

import { analyticsEvent } from 'actions/analytics';

export default function* routeVrRedirect({ view, params, query }) {
  const viewRedirect = `${view.replace(VR_REDIRECT_VIEW_MOD, '')}`;
  // Record attempt to access old url to mixpanel
  // This will help us determine when we can remove this redirect
  yield put(analyticsEvent(EVENTS.VR_URL_REDIRECT));
  yield put(
    routeChange({
      view: viewRedirect,
      params,
      query,
      options: { inherit: false }
    })
  );
}
