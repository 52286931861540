import React from 'react';
import classNames from 'classnames';
import styles from './CompControls.css';
import { CompKeywordSearch } from '../CompKeywordSearch';
import { CompFilterBar } from '../CompFilterBar';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
}
export const CompControls = ({ dataHcName, reportId, className }: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CompControls, className)}
    >
      <div className={styles.KeywordSearch}>
        <CompKeywordSearch
          dataHcName={`${dataHcName}-keyword-search`}
          reportId={reportId}
        />
      </div>
      {/* TODO: Phase II Feature - Saved filters goes here */}
      {/* <div className={styles.FilterSets}></div> */}
      <div className={styles.Filters}>
        <CompFilterBar
          dataHcName={`${dataHcName}-filter-bar`}
          reportId={reportId}
        />
      </div>
      {/* TODO: Phase III Feature - Refresh Comps goes here */}
      {/* <div className={styles.RefreshComps}></div> */}
    </div>
  );
};
