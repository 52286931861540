import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { FullscreenCarousel } from '@hc/component-lib/hclib/components/molecules/fullscreen-carousel';
import styles from '../../lib/components/FullscreenCarousel/FullscreenCarousel.css';
import {
  PhotoGrid,
  PhotoGridSizeControl
} from '@hc/component-lib/hclib/components/molecules/photo-grid';
import classNames from 'classnames';
import {
  ID_PORTAL_FIXED,
  PROPERTY_TYPE_SEARCH_RESULT,
  PROPERTY_TYPE_MLS_LISTING
} from 'legacy/appstore/constants';
import { getReportDataEffectiveDate } from 'selectors/report-data';
import { displayDate, displayDateCompact } from 'legacy/utils/dates';
import scrollIntoView from 'scroll-into-view';
import { EffectiveDateWarning } from 'legacy/containers/effective-date-warning';
import PropertyHeader from 'legacy/containers/property-header';
import { usePhotos } from 'legacy/hocs/usePhotos';
import { useListings } from 'legacy/hocs/useListings';
import Price from 'legacy/components/Price';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import TransactionDetails from 'legacy/components/TransactionDetails';
import { getPhotosDialogTransaction } from 'selectors/photos-dialog';
import { checkUrlIsStreetView, checkUrlIsMapTile } from 'legacy/utils/photos';

import { PhotosPage as theme } from 'legacy/css-modules';

const PhotosPage = (props) => {
  const {
    price,
    addressId,
    latestListingOnly,
    propertyContext,
    active,
    property,
    onClickBack,
    onChangeComplete
  } = props;
  const transaction = useSelector(getPhotosDialogTransaction);
  let { photos } = usePhotos(addressId, {
    property:
      propertyContext === PROPERTY_TYPE_MLS_LISTING ||
      propertyContext === PROPERTY_TYPE_SEARCH_RESULT
        ? {
            ...property,
            geoLocation: {
              latitude: property.lat,
              longitude: property.lon
            }
          }
        : property,
    latestListingOnly: latestListingOnly
  });

  const listingId = latestListingOnly && photos?.[0]?.externalSourceId;
  const { listings } = useListings(addressId);
  const [size, setSize] = useState(props.size);
  const isEffectiveDateReport = useSelector(getIsEffectiveDateReport);
  const effectiveDate = useSelector(getReportDataEffectiveDate);
  const [fullscreenIndex, setFullscreenIndex] = useState(null);
  const firstListingPrecedingEffectiveDate = listings.find(
    (l) => l.listingStatus.listingDate < effectiveDate
  );
  const node =
    firstListingPrecedingEffectiveDate &&
    document.querySelector(
      `[data-listing-header='${firstListingPrecedingEffectiveDate.listingID}']`
    );
  // Scroll to effective date listing onMount
  useEffect(() => {
    if (node && isEffectiveDateReport && !transaction.transactionId) {
      scrollIntoView(node, {
        time: 0,
        align: {
          top: 0
        }
      });
    }
  }, [node, transaction, isEffectiveDateReport]);
  const keyboardNavigationListener = useCallback(
    (e) => {
      const { keyCode } = e;
      switch (keyCode) {
        case 27:
          return fullscreenIndex === null && onClickBack();
      }
    },
    [fullscreenIndex, onClickBack]
  );
  useEffect(() => {
    window.addEventListener('keyup', keyboardNavigationListener);
    return () => {
      window.removeEventListener('keyup', keyboardNavigationListener);
    };
  }, [keyboardNavigationListener]);
  useEffect(() => {
    setSize(props.size);
  }, [active, props.size]);

  // Remove vendor photos from fullscreen carousel if viewing transaction photos
  // Filter fullscreen photos to only show those from transaction
  if (transaction?.hcMlsId) {
    photos = photos.filter((p) => {
      return (
        !checkUrlIsStreetView(p.httpUrl) &&
        !checkUrlIsMapTile(p.httpUrl) &&
        p.listingDate === transaction.listingDate &&
        p.mlsNumber === transaction.hcMlsId.toString()
      );
    });
  }

  const vendorPhotos = [];
  if (
    photos &&
    photos.length >= 2 &&
    checkUrlIsStreetView(photos[photos.length - 2].httpUrl)
  ) {
    vendorPhotos.push(photos[photos.length - 2]);
  }
  if (
    photos &&
    photos.length &&
    checkUrlIsMapTile(photos[photos.length - 1].httpUrl)
  ) {
    vendorPhotos.push(photos[photos.length - 1]);
  }
  return (
    <ReactCSSTransitionGroup
      transitionName={theme}
      transitionEnterTimeout={500}
      transitionLeaveTimeout={400}
      transitionEnter
      transitionLeave
    >
      {active && (
        <div className={theme.PhotosPage}>
          <PropertyHeader
            dataHcName="photo-property-header"
            addressId={addressId}
            propertyContext={propertyContext}
            theme={theme}
            onClickBack={onClickBack}
          >
            <div>
              <EffectiveDateWarning className={theme.EffectiveDateWarning} />
              {price && price.price && (
                <Price
                  className={classNames({
                    [theme.PriceEffectiveDate]: isEffectiveDateReport
                  })}
                  value={price.price}
                  label={price.priceLabel}
                />
              )}
            </div>
          </PropertyHeader>
          <div className={theme.Photos}>
            {photos && photos.length ? (
              <div className={theme.Controls}>
                <PhotoGridSizeControl
                  dataHcName="photo-size-control"
                  onChangeComplete={onChangeComplete}
                  value={size}
                  numPhotos={photos.length}
                  onChange={setSize}
                />
              </div>
            ) : null}
            {listings.map((listing, i) => {
              if (
                !listing.photos.length ||
                (transaction.hcMlsId &&
                  (listing.listingStatus.listingDate !==
                    transaction.listingDate ||
                    listing.mlsID !== transaction.hcMlsId.toString())) ||
                (listingId && listing.listingID !== listingId)
              )
                return null;
              return (
                <Fragment key={`photos-${listing.listingID}-${i}`}>
                  <div className={theme.TransactionSeparator}>
                    <TransactionDetails
                      dataHcName={`transaction-details-separator-${listing.listingDate}-${listing.listingID}`}
                      listing={listing}
                    />
                  </div>
                  <PhotoGrid
                    dataHcName={`photo-grid-${i}`}
                    onClick={(photo) => {
                      setFullscreenIndex(
                        photos.findIndex((p) => p.httpUrl === photo.httpUrl)
                      );
                    }}
                    size={window.innerWidth <= 800 ? 1 : size}
                    photos={listing.photos.map((photo) => {
                      return {
                        httpUrl: photo.httpUrl,
                        thumbnailOverlay:
                          photo.imageDate && displayDateCompact(photo.imageDate)
                      };
                    })}
                    theme={theme}
                    fullscreen
                  />
                </Fragment>
              );
            })}
            {vendorPhotos.length &&
            (!transaction.hcMlsId ||
              propertyContext === PROPERTY_TYPE_MLS_LISTING ||
              propertyContext === PROPERTY_TYPE_SEARCH_RESULT) ? (
              <>
                <div className={theme.TransactionSeparator} />
                <PhotoGrid
                  dataHcName={`photo-grid-vendor`}
                  onClick={(photo) => {
                    setFullscreenIndex(
                      photos.findIndex((p) => p.httpUrl === photo.httpUrl)
                    );
                  }}
                  size={window.innerWidth <= 800 ? 1 : size}
                  photos={vendorPhotos}
                  theme={theme}
                  fullscreen
                />
              </>
            ) : null}
          </div>
        </div>
      )}
      <FullscreenCarousel
        dataHcName="photo-page-fullscreen-carousel"
        fullscreenPortalId={ID_PORTAL_FIXED}
        theme={styles}
        active={fullscreenIndex !== null}
        index={fullscreenIndex}
        photos={photos.map((photo) => {
          return {
            httpUrl: photo.httpUrl,
            fullscreenMetadataLower:
              photo.imageDate && displayDate(photo.imageDate),
            fullscreenMetadataUpper: (
              <TransactionDetails
                dataHcName={`transaction-details-fullscreen-${photo.currentStatusDate}`}
                listing={listings.find(
                  (l) => l.listingID === photo.externalSourceId
                )}
              />
            )
          };
        })}
        onClose={() => {
          setFullscreenIndex(null);
        }}
        onChange={(photo, i) => {
          setFullscreenIndex(i);
        }}
      />
    </ReactCSSTransitionGroup>
  );
};

export default PhotosPage;
