import { EVENTS } from 'legacy/appstore/events';

import { getPropsIsCompsMapShowingControls } from 'selectors/analytics';

export const UI_COMPS_FILTERS_TOGGLE = 'UI_COMPS_FILTERS_TOGGLE';
export const UI_AVM_DETAILS_SHOW = 'UI_AVM_DETAILS_SHOW';
export const UI_AVM_DETAILS_HIDE = 'UI_AVM_DETAILS_HIDE';
export const SHOW_OVERLAY = 'SHOW_OVERLAY';
export const HIDE_OVERLAY = 'HIDE_OVERLAY';
export const SHOW_LEFT_SIDE_NAV = 'SHOW_LEFT_SIDE_NAV';
export const HIDE_LEFT_SIDE_NAV = 'HIDE_LEFT_SIDE_NAV';
export const UI_UPDATE_LOADING_PROGRESS = 'UI_UPDATE_LOADING_PROGRESS';
export const UI_COMPS_MAP_CONTROLS_TOGGLE = 'UI_COMPS_MAP_CONTROLS_TOGGLE';
export const UI_COMPS_MAP_CONTROLS_HIDE = 'UI_COMPS_MAP_CONTROLS_HIDE';

export const uiCompsFiltersToggle = () => {
  return {
    type: UI_COMPS_FILTERS_TOGGLE
  };
};

export const uiAvmDetailsShow = (reportType) => ({
  type: UI_AVM_DETAILS_SHOW,
  payload: { reportType }
});
export const uiAvmDetailsHide = () => ({
  type: UI_AVM_DETAILS_HIDE
});

export const showOverlay = (content) => ({
  type: SHOW_OVERLAY,
  payload: { content }
});
export const hideOverlay = () => ({
  type: HIDE_OVERLAY
});

export const showLeftSideNav = () => ({
  type: SHOW_LEFT_SIDE_NAV
});
export const hideLeftSideNav = () => ({
  type: HIDE_LEFT_SIDE_NAV
});

export const uiUpdateLoadingProgress = (progress) => ({
  type: UI_UPDATE_LOADING_PROGRESS,
  payload: { progress }
});

export const uiCompsMapControlsToggle = (
  mapId,
  controlType,
  bypassTracking
) => ({
  // Tracking can be bypassed because this is dispatched when clicking "choose comps"
  type: UI_COMPS_MAP_CONTROLS_TOGGLE,
  payload: { controlType },
  meta: {
    analytics: !bypassTracking && {
      event: EVENTS.MAP_CONTROL_PANEL_TOGGLE,
      props: {
        map_id: mapId,
        control_type: controlType
      },
      propsSelector: getPropsIsCompsMapShowingControls
    }
  }
});

export const uiCompsMapControlsHide = (mapId) => {
  return {
    type: UI_COMPS_MAP_CONTROLS_HIDE,
    payload: { mapId }
  };
};
