import React from 'react';
import styles from './CompFarmListHeader.css';
import classNames from 'classnames';
import { useDocumentRole } from '../../hooks';
import {
  AvmDocument,
  CompDocument,
  FilteredDocument,
  DocumentRoles
} from '../../types';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { AvmType } from '../../constants';
import { formatAvmLabel, formatMissing, formatMoney } from 'src/lib/utils';
import { Toggle } from 'src/lib/components/Toggle';
import { Checkbox } from 'src/lib/components/Checkbox';
import {
  CompareViewIcon,
  CardViewIcon,
  TableViewIcon,
  CompactViewIcon
} from '../../../inline-svgs';

export enum ListViewType {
  Card = 'card',
  Table = 'table',
  Compact = 'compact',
  Compare = 'compare'
}
interface Props {
  reportId: number;
  dataHcName: string;
  listViewType: ListViewType;
  selectedCompsOnly: boolean;
  className?: string;
  onChangeListViewType: (listViewType: ListViewType) => void;
  onChangeSelectedCompsOnly: (selectedCompsOnly: boolean) => void;
}
export const CompFarmListHeader = ({
  reportId,
  dataHcName,
  className,
  selectedCompsOnly,
  listViewType,
  onChangeListViewType,
  onChangeSelectedCompsOnly
}: Props) => {
  const {
    data: [avmDocument]
  } = useDocumentRole<AvmDocument>(reportId, DocumentRoles.Avm);
  const { data: compDocuments } = useDocumentRole<CompDocument>(
    reportId,
    DocumentRoles.Comp
  );
  const {
    data: [filteredDocument]
  } = useDocumentRole<FilteredDocument>(reportId, DocumentRoles.Filtered);
  return (
    <header
      className={classNames(styles.Header, className)}
      data-hc-name={`${dataHcName}-header`}
    >
      <div className={styles.LeftColumn}>
        {avmDocument?.role !== DocumentRoles.Avm ? (
          <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />
        ) : (
          <>
            <div className={styles.Value}>
              <span data-hc-name={`${dataHcName}-value-label`}>
                {avmDocument.data.selected === AvmType.CompMarket
                  ? formatAvmLabel(AvmType.CompMarket)
                  : formatAvmLabel(AvmType.CompAvm)}
              </span>
              &nbsp;
              <span data-hc-name={`${dataHcName}-value`}>
                {avmDocument.data.selected === AvmType.CompMarket
                  ? formatMoney(avmDocument.data.adjustedCompBasedAvg.priceMean)
                  : formatMoney(avmDocument.data[AvmType.CompAvm].priceMean)}
              </span>
            </div>
            <div className={styles.Counts}>
              <span data-hc-name={`${dataHcName}-selected-comps-count`}>
                {compDocuments.length}
              </span>
              <span> comps selected out of </span>
              <span data-hc-name={`${dataHcName}-available-comps-count`}>
                {formatMissing(filteredDocument?.data.length || null)}
              </span>
              <span> available</span>
            </div>
          </>
        )}
      </div>
      <div className={styles.RightColumn}>
        <Toggle
          dataHcName={`${dataHcName}-view-toggle`}
          value={listViewType}
          className={styles.Toggle}
          options={[
            {
              label: (
                <CompareViewIcon
                  dataHcName={`${dataHcName}-view-toggle-compare`}
                  height="20px"
                />
              ),
              value: ListViewType.Compare,
              disabled: compDocuments.length === 0
            },
            {
              label: (
                <CardViewIcon
                  dataHcName={`${dataHcName}-view-toggle-card`}
                  height="20px"
                />
              ),
              value: ListViewType.Card
            },
            {
              label: (
                <TableViewIcon
                  dataHcName={`${dataHcName}-view-toggle-table`}
                  height="20px"
                />
              ),
              value: ListViewType.Table
            },
            {
              label: (
                <CompactViewIcon
                  dataHcName={`${dataHcName}-view-toggle-compact`}
                  height="20px"
                />
              ),
              value: ListViewType.Compact
            }
          ]}
          onChange={onChangeListViewType}
        />
        <Checkbox
          dataHcName={`${dataHcName}-selected-comps-only`}
          checked={selectedCompsOnly}
          className={styles.Checkbox}
          disabled={compDocuments.length === 0 && !selectedCompsOnly}
          label="Show selected comps only"
          onChange={onChangeSelectedCompsOnly}
        />
      </div>
    </header>
  );
};
