import React, { useState } from 'react';
import { Dropdown } from 'legacy/components/Dropdown';
import classNames from 'classnames';

import stylesDropdown from './Dropdown.css-module.css';
import styles from './MultiInput.css-module.css';

// type SearchTypeOptions {
//   input: React.ReactNode; // Expects an unstyled input component
//   label: string;
// }

// type Props {
//   dataHcName?: string;
//   options: SearchTypeOptions[];
//   inputTypeSelected: 'label' of one of the input options
//   dropdownWidth?: number;
// }
export const MultiInput = ({
  dataHcName = 'multi-search',
  options,
  className,
  inputTypeSelected,
  dropdownWidth
}) => {
  const [inputType, setInputType] = useState(inputTypeSelected);
  const selectedOption = options.find((o) => o.label === inputType);
  return (
    <div
      className={classNames(styles.MultiInput, className)}
      data-hc-name={dataHcName}
    >
      {options.length > 1 && (
        <div
          className={styles.DropdownCell}
          style={dropdownWidth ? { flex: `0 0 ${dropdownWidth}px` } : undefined}
        >
          <Dropdown
            dataHcName={`${dataHcName}-dropdown`}
            theme={stylesDropdown}
            options={options.map(({ label }) => ({ label, value: label }))}
            value={inputType}
            onChange={setInputType}
          />
        </div>
      )}
      <div className={styles.InputCell}>{selectedOption?.input}</div>
    </div>
  );
};
