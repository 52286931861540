import {
  FilterRangeType,
  RANGE_TYPES
} from 'src/lib/components/FilterRange/types';

export const capitalizeFirstLetter = (str: string): string =>
  str
    .toLowerCase()
    .split(',')
    .map((s) => {
      s = s.trim();
      return s.charAt(0).toUpperCase() + s.slice(1);
    })
    .join(',');

export const testForCommaLastChar = (newValue: FilterRangeType): boolean => {
  if (RANGE_TYPES.MIN in newValue) {
    return newValue[RANGE_TYPES.MIN]?.slice(-1) === ',';
  }
  return newValue[RANGE_TYPES.MAX]?.slice(-1) === ',';
};

export const trimWithEllipsis = (str: string, max: number) => {
  if (str.length > max) {
    return `${str.slice(0, max - 3)}...`;
  }
  return str;
};

export const camelCaseToSnakeCase = (str: string) => {
  if (!str) return str;
  const upperChars = str.match(/([A-Z])/g);
  const lowerChars = str.match(/([a-z])/g);
  if (!upperChars || !lowerChars) {
    return str;
  }
  upperChars.forEach((char) => {
    str = str.replace(char, '_' + char.toLowerCase());
  });

  if (str.slice(0, 1) === '_') {
    str = str.slice(1);
  }

  return str;
};

export const undefinedNullOrEmptyString = (value?: string | null): boolean => {
  return value === undefined || value === null || value.length === 0;
};

export const snakeCaseToCamelCase = (str: string) => {
  if (!str) return str;
  const parts = str.split('_');
  if (parts.length) {
    const result = parts.reduce((curr, part) => {
      if (curr === '') {
        return `${part.toLowerCase()}`;
      } else {
        const firstChar = part.charAt(0).toUpperCase();
        const rest = part.slice(1).toLowerCase();
        return `${curr}${firstChar}${rest}`;
      }
    });
    return result;
  } else {
    return str;
  }
};
