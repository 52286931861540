import React from 'react';
import classNames from 'classnames';

import { AlertIcon } from 'src/lib/inline-svgs';

import styles from './NullState.css';

interface Props {
  icon?: React.ReactNode;
  title: string;
  dataHcName: string;
  children?: React.ReactNode;
  className?: string;
}

export const NullState = ({
  icon,
  title,
  dataHcName,
  children,
  className
}: Props) => {
  return (
    <div
      className={classNames(styles.NullState, className)}
      data-hc-name={dataHcName}
    >
      <div data-hc-name={`${dataHcName}-icon`} className={styles.Icon}>
        {icon ? icon : <AlertIcon dataHcName={`${dataHcName}-alert-icon`} />}
      </div>
      <h3 data-hc-name={`${dataHcName}-title`} className={styles.Title}>
        {title}
      </h3>
      <div>{children}</div>
    </div>
  );
};
