import PropTypes from 'prop-types';
import CobrandingImgUser from 'legacy/components/CobrandingImgUser';
import CobrandingImgCompany from 'legacy/components/CobrandingImgCompany';
import classNames from 'classnames';
import { CobrandingBadge as theme } from 'legacy/css-modules';

const CobrandingContactInfo = ({
  cobrandingData,
  isCobrandedReport,
  inline
}) => {
  return (
    <div className={theme.Cell}>
      {cobrandingData.includeName &&
        (cobrandingData.firstName || cobrandingData.lastName) && (
          <div title={`${cobrandingData.firstName} ${cobrandingData.lastName}`}>
            <div className={theme.NoWrap}>{cobrandingData.firstName}</div>&nbsp;
            <div className={theme.NoWrap}>{cobrandingData.lastName}</div>
          </div>
        )}
      {cobrandingData.includeEmail && cobrandingData.email && (
        <div
          className={theme.UserInfo}
          title={cobrandingData.email}
          data-hc-name="main-menu-personalization-email"
        >
          {cobrandingData.email}
        </div>
      )}
      {isCobrandedReport &&
        cobrandingData.includePhone &&
        cobrandingData.phone && (
          <div className={theme.UserInfo} title={cobrandingData.phone}>
            {cobrandingData.phone}
          </div>
        )}
      {(cobrandingData.includeCompanyName ||
        cobrandingData.includeLicenseNumber) && (
        <div className={classNames(theme.UserInfo, theme.Wrap)}>
          {cobrandingData.includeCompanyName && (
            <span
              title={cobrandingData.companyName}
              data-hc-name="main-menu-personalization-org"
            >
              {cobrandingData.companyName} &nbsp;
            </span>
          )}
          {cobrandingData.includeLicenseNumber &&
            !cobrandingData.includeCompanyLogo && (
              <span>{cobrandingData.licenseNumber}</span>
            )}
        </div>
      )}
      {cobrandingData.includeLicenseNumber &&
        cobrandingData.includeCompanyLogo && (
          <div className={theme.UserInfo}>
            <span>{cobrandingData.licenseNumber}</span>
          </div>
        )}
    </div>
  );
};

const CobrandingBadge = ({
  cobrandingData,
  show,
  showContactInfo,
  openPersonalization,
  showEdit,
  isCobrandedReport
}) => {
  return (
    <section
      className={theme.CobrandingBadge}
      data-hc-name="main-menu-personalization-section"
    >
      {(cobrandingData.includePhoto || cobrandingData.includeCompanyLogo) && (
        <span>
          <div className={theme.Cell}>
            {cobrandingData.includePhoto && (
              <span
                className={
                  !isCobrandedReport && openPersonalization && 'hc-pointer'
                }
                onClick={
                  !isCobrandedReport &&
                  openPersonalization &&
                  openPersonalization
                }
              >
                <CobrandingImgUser url={cobrandingData.photoUrl} />
              </span>
            )}
          </div>
          <div className={theme.Cell}>
            {cobrandingData.includeCompanyLogo && (
              <CobrandingImgCompany url={cobrandingData.companyLogoUrl} />
            )}
          </div>
        </span>
      )}
      {showContactInfo && (
        <CobrandingContactInfo
          cobrandingData={cobrandingData}
          isCobrandedReport={isCobrandedReport}
        />
      )}
      {isCobrandedReport && (
        <div className={theme.Cell}>
          {cobrandingData.includeAddressLine1 && (
            <div className={theme.UserInfo}>{cobrandingData.addressLine1}</div>
          )}
          {cobrandingData.includeAddressLine2 && (
            <div className={theme.UserInfo}>{cobrandingData.addressLine2}</div>
          )}
          {cobrandingData.includeCityStateZipcode && (
            <div className={theme.UserInfo}>
              {cobrandingData.city}, {cobrandingData.state}{' '}
              {cobrandingData.zipcode}
            </div>
          )}
          {cobrandingData.includeWebsite && (
            <div className={theme.UserInfo}>{cobrandingData.websiteUrl}</div>
          )}
        </div>
      )}
      {showEdit && (
        <div>
          <span
            className={theme.ProfileLink}
            data-hc-name="main-menu-profile-link"
            onClick={openPersonalization}
          >
            Edit Profile
          </span>
        </div>
      )}
    </section>
  );
};

CobrandingBadge.defaultProps = {
  show: false,
  showContactInfo: false,
  showEdit: false
};

CobrandingBadge.propTypes = {
  cobrandingData: PropTypes.object.isRequired,

  openPersonalization: PropTypes.func,
  show: PropTypes.bool,
  showEdit: PropTypes.bool,
  showContactInfo: PropTypes.bool
};

export default CobrandingBadge;
