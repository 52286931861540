import React from 'react';
import { useSelector } from 'react-redux';
import { TIMELINE_SUBJECT_DETAILS_MISSING_MSG } from 'legacy/appstore/constants';
import { AlertIcon } from '@hc/component-lib/hclib/assets/inline-svgs';
import { getEffectiveDateTimelineSubjectDetailsMissing } from 'selectors/effective-date';
import Tooltip from 'legacy/components/generic/Tooltip';
import { TimelineSubjectDetailsMissingWarning as styles } from 'legacy/css-modules';

export const TimelineSubjectDetailsMissingWarning = () => {
  const timelineSubjectDetailsMissing = useSelector(
    getEffectiveDateTimelineSubjectDetailsMissing
  );
  if (!timelineSubjectDetailsMissing) return null;
  return (
    <Tooltip
      className={styles.Tooltip}
      trigger={<AlertIcon className={styles.Trigger} />}
      shift="right"
      content={TIMELINE_SUBJECT_DETAILS_MISSING_MSG}
      transparentScreen
    />
  );
};
