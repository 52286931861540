import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  AVM_TYPE_DESCRIPTION,
  AVM_TYPE_ALT_TEXT,
  AVM_KEY_HC,
  AVM_KEY_USER,
  AVM_KEY_COMPS,
  AVM_KEY_COMPS_AVG,
  AVM_KEY_USER_ENTERED,
  AVM_KEY_RENTAL_HC,
  AVM_KEY_RENTAL_COMPS,
  AVM_KEY_RENTAL_COMPS_AVG,
  AVM_KEY_RENTAL_USER_ENTERED,
  LABEL_FOR_AVM_KEY
} from 'legacy/appstore/constants';

import { dollarsFormatter } from 'legacy/utils/formatters';
import { camelCaseToSnakeCase } from 'legacy/utils/transform';

import { getIsMobile } from 'selectors/match-media';
import { getIsEffectiveDateReport } from './property-explorer.selectors';

// NOTE: These prevent a circular import
const _hasCompFarm = (state) => !isEmpty(get(state, ['comps', 'farm'], {}));
const _hasRentalCompFarm = (state) =>
  !isEmpty(get(state, ['rentalComps', 'farm'], {}));
export const getAvmsState = (state) => state.avms;

export const getAvmsLastRunTime = createSelector(
  getAvmsState,
  (avmsState) => avmsState.lastAvmRunTime
);

export const getAvmSelectedKey = createSelector(
  getAvmsState,
  (avmsState) => avmsState.selected
);

export const getAvmSelectedKeyForJson = createSelector(
  getAvmSelectedKey,
  (avmSelectedKey) => camelCaseToSnakeCase(avmSelectedKey)
);

export const getAvmRentalSelectedKey = createSelector(
  getAvmsState,
  (avmsState) => avmsState.selectedRental
);

export const getAvmRentalSelectedKeyForJson = createSelector(
  getAvmRentalSelectedKey,
  (avmSelectedKey) => camelCaseToSnakeCase(avmSelectedKey)
);

export const getAvmSelected = createSelector(
  getAvmsState,
  getAvmSelectedKey,
  (avmState, selectedAvmKey) => avmState[selectedAvmKey]
);

export const getAvmRentalSelected = createSelector(
  getAvmsState,
  getAvmRentalSelectedKey,
  (avmState, avmKey) => avmState[avmKey]
);

export const getAvmHc = createSelector(
  getAvmsState,
  (avmsState) => avmsState[AVM_KEY_HC]
);

export const getIsAvmHcEmpty = createSelector(
  getAvmHc,
  (avmHc) => !avmHc.value
);

export const getAvmUser = createSelector(
  getAvmsState,
  (avmsState) => avmsState[AVM_KEY_USER]
);

export const getAvmComps = createSelector(
  getAvmsState,
  (avmsState) => avmsState[AVM_KEY_COMPS]
);

export const getAvmCompsAvg = createSelector(
  getAvmsState,
  (avmsState) => avmsState[AVM_KEY_COMPS_AVG]
);

export const getAvmUserEntered = createSelector(
  getAvmsState,
  (avmsState) => avmsState[AVM_KEY_USER_ENTERED]
);

export const getAvmRentalHc = createSelector(
  getAvmsState,
  (avmsState) => avmsState[AVM_KEY_RENTAL_HC]
);

export const getAvmRentalComps = createSelector(
  getAvmsState,
  (avmsState) => avmsState[AVM_KEY_RENTAL_COMPS]
);
export const getAvmRentalCompsAvg = createSelector(
  getAvmsState,
  (avmsState) => avmsState[AVM_KEY_RENTAL_COMPS_AVG]
);

export const getAvmRentalUserEntered = createSelector(
  getAvmsState,
  (avmsState) => avmsState[AVM_KEY_RENTAL_USER_ENTERED]
);

export const getAvmValue = createSelector(
  getAvmSelected,
  getAvmHc,
  (avmSelected, avmHc) =>
    isEmpty(avmSelected) ? avmHc.value : avmSelected.value
);

export const getAvmAdjustmentDifference = createSelector(
  getAvmHc,
  getAvmUser,
  (avmHc, avmUser) =>
    !isEmpty(avmHc) && !isEmpty(avmUser) ? avmUser.value - avmHc.value : 0
);

export const getAvmCompsExists = createSelector(
  getAvmComps,
  (avmsComps) => avmsComps && avmsComps.value
);

export const getAvmRentalCompsExists = createSelector(
  getAvmRentalComps,
  (avmsRentalComps) => avmsRentalComps && avmsRentalComps.value
);

export const getAvmUserExists = createSelector(
  getAvmUser,
  (avmUser) => avmUser && avmUser.value
);

export const getAvmRental = createSelector(
  getAvmsState,
  (avmsState) => avmsState[AVM_KEY_RENTAL_HC]
);

export const getHasComputedAvmValues = createSelector(
  getAvmUser,
  getAvmComps,
  (avmUser, avmComps) => !isEmpty(avmUser) || !isEmpty(avmComps)
);

export const getAvmHcValueByQuality = createSelector(
  getAvmHc,
  (avmHc) => avmHc.valueByQuality || {}
);

export const getAvmUserValueByQuality = createSelector(
  getAvmUser,
  (avmUser) => avmUser.valueByQuality || {}
);

export const getAvmByKey = (avmKey) => (state) => {
  if (avmKey === AVM_KEY_HC) {
    return getAvmHc(state);
  } else if (avmKey === AVM_KEY_USER) {
    return getAvmUser(state);
  } else if (avmKey === AVM_KEY_COMPS) {
    return getAvmComps(state);
  } else if (avmKey === AVM_KEY_COMPS_AVG) {
    return getAvmCompsAvg(state);
  } else if (avmKey === AVM_KEY_USER_ENTERED) {
    return getAvmUserEntered(state);
  }
};

export const getAvmByKeyWithFallback = (avmKey) => (state) => {
  let avm = getAvmByKey(avmKey)(state);
  if (avmKey !== AVM_KEY_HC && (!avm || !avm.value)) {
    avm = getAvmByKey(AVM_KEY_HC)(state);
  }
  return avm;
};

const _buildAvmSelectorComps = (
  avm,
  hasSelectedComps,
  hasCompFarm,
  isMobile,
  avmKey
) => ({
  avmKey,
  avm,
  dataHcNames: {
    label: 'valuation-comps-value-text',
    button: 'valuation-adjust-comps-button',
    description: 'valuation-comp-value-desc'
  },
  label: LABEL_FOR_AVM_KEY[avmKey],
  value: dollarsFormatter(avm.value),
  altText: hasCompFarm
    ? `${AVM_TYPE_ALT_TEXT[avmKey]} Click below to select comps now.`
    : `${AVM_TYPE_ALT_TEXT[avmKey]} Click below to draw a comp area now.`,
  subtext: AVM_TYPE_DESCRIPTION[avmKey],
  displayAltText: !hasSelectedComps,
  buttonText: hasCompFarm
    ? hasSelectedComps
      ? 'Use For Report'
      : 'Select Comps'
    : 'Draw Comp Area',
  hideButton: isMobile && !hasCompFarm
});

export const getAvmSelectorComps = createSelector(
  getAvmComps,
  getAvmCompsExists,
  _hasCompFarm,
  getIsMobile,
  (avmComps, hasSelectedComps, hasCompFarm, isMobile) =>
    _buildAvmSelectorComps(
      avmComps,
      hasSelectedComps,
      hasCompFarm,
      isMobile,
      AVM_KEY_COMPS
    )
);

export const getAvmSelectorCompsAvg = createSelector(
  getAvmCompsAvg,
  getAvmCompsExists,
  _hasCompFarm,
  getIsMobile,
  (avmComps, hasSelectedComps, hasCompFarm, isMobile) =>
    _buildAvmSelectorComps(
      avmComps,
      hasSelectedComps,
      hasCompFarm,
      isMobile,
      AVM_KEY_COMPS_AVG
    )
);

export const getAvmSelectorRentalComps = createSelector(
  getAvmRentalComps,
  getAvmRentalCompsExists,
  _hasRentalCompFarm,
  getIsMobile,
  (avmComps, hasSelectedComps, hasCompFarm, isMobile) =>
    _buildAvmSelectorComps(
      avmComps,
      hasSelectedComps,
      hasCompFarm,
      isMobile,
      AVM_KEY_RENTAL_COMPS
    )
);

export const getAvmSelectorRentalCompsAvg = createSelector(
  getAvmRentalCompsAvg,
  getAvmRentalCompsExists,
  _hasRentalCompFarm,
  getIsMobile,
  (avmComps, hasSelectedComps, hasCompFarm, isMobile) =>
    _buildAvmSelectorComps(
      avmComps,
      hasSelectedComps,
      hasCompFarm,
      isMobile,
      AVM_KEY_RENTAL_COMPS_AVG
    )
);

export const getAvmsForAvmSelector = createSelector(
  getAvmSelectedKey,
  getAvmHc,
  getAvmUser,
  getAvmComps,
  getAvmCompsAvg,
  getAvmCompsExists,
  getIsAvmHcEmpty,
  _hasCompFarm,
  getIsMobile,
  getIsEffectiveDateReport,
  (
    avmSelectedKey,
    avmHc,
    avmUser,
    avmComps,
    avmCompsAvg,
    hasSelectedComps,
    isAvmHcEmpty,
    hasCompFarm,
    isMobile,
    isEffectiveDate
  ) => {
    let avms = [];
    if (avmSelectedKey !== AVM_KEY_HC) {
      avms.push({
        showNullState: isAvmHcEmpty,
        nullStateInfoKey: 'noAvmHc',
        avmKey: AVM_KEY_HC,
        avm: avmHc,
        label: 'HouseCanary Value',
        value: dollarsFormatter(avmHc.value),
        subtext: AVM_TYPE_DESCRIPTION[AVM_KEY_HC],
        buttonText: 'Use For Report',
        dataHcNames: {
          value: 'valuation-hc-value',
          button: 'valuation-hc-button'
        }
      });
    }
    if (avmSelectedKey !== AVM_KEY_USER && !isEffectiveDate) {
      const hasAdjustedPropertyDetails = !isEmpty(avmUser);
      avms.push({
        showNullState: isAvmHcEmpty,
        nullStateInfoKey: 'noAvmAdjusted',
        avmKey: AVM_KEY_USER,
        avm: avmUser,
        label: 'Adjusted Value',
        dataHcNames: {
          label: 'valuation-adjusted-value-text',
          button: 'valuation-adjust-details-button',
          description: 'valuation-adjusted-value-desc'
        },
        value: dollarsFormatter(avmUser.value),
        altText: AVM_TYPE_ALT_TEXT[AVM_KEY_USER],
        displayAltText: !hasAdjustedPropertyDetails,
        subtext: AVM_TYPE_DESCRIPTION[AVM_KEY_USER],
        buttonText: hasAdjustedPropertyDetails
          ? 'Use For Report'
          : 'Adjust Details'
      });
    }
    if (avmSelectedKey !== AVM_KEY_COMPS) {
      avms.push(
        _buildAvmSelectorComps(
          avmComps,
          hasSelectedComps,
          hasCompFarm,
          isMobile,
          AVM_KEY_COMPS
        )
      );
    }
    if (avmSelectedKey !== AVM_KEY_COMPS_AVG) {
      avms.push(
        _buildAvmSelectorComps(
          avmCompsAvg,
          hasSelectedComps,
          hasCompFarm,
          isMobile,
          AVM_KEY_COMPS_AVG
        )
      );
    }
    return avms;
  }
);

export const getAvmSelectorUserEntered = createSelector(
  getAvmSelectedKey,
  getAvmUserEntered,
  getAvmHc,
  (avmSelectedKey, avmUserEntered, avmHc) => {
    return {
      avmKey: AVM_KEY_USER_ENTERED,
      avm: avmUserEntered,
      label: 'Your Opinion of Price: ',
      buttonText: 'Use For Report',
      isSelected: avmSelectedKey === AVM_KEY_USER_ENTERED,
      value: avmUserEntered.value,
      referenceAvm: avmHc,
      maxLength: 10,
      warningText:
        'Your price is outside HouseCanary’s predicted value range. Are you sure?',
      dataHcNames: {
        label: 'valuation-user-entered-text',
        button: 'valuation-user-entered-button',
        input: 'valuation-user-entered-value'
      }
    };
  }
);

const _buildAvmSelectorRentalComps = (
  avmComps,
  avmKey,
  hasSelectedComps,
  hasRentalCompFarm,
  isMobile
) => ({
  avmKey,
  altText: AVM_TYPE_ALT_TEXT[avmKey],
  subtext: AVM_TYPE_DESCRIPTION[avmKey],
  label: LABEL_FOR_AVM_KEY[avmKey],
  displayAltText: !hasSelectedComps,
  buttonText: hasRentalCompFarm
    ? hasSelectedComps
      ? 'Use For Report'
      : 'Select Rental Comps'
    : 'Draw Rental Comp Area',
  value: dollarsFormatter(avmComps.value),
  dataHcNames: {
    label: 'rental-valuation-comps-header',
    button: 'adjust-rental-comps-button',
    description: 'rental-valuation-comps-text'
  },
  hideButton: isMobile && !hasRentalCompFarm
});

export const getAvmsRentalForAvmSelector = createSelector(
  getAvmRentalSelectedKey,
  getAvmRental,
  getAvmRentalComps,
  getAvmRentalCompsAvg,
  _hasRentalCompFarm,
  getAvmRentalCompsExists,
  getIsMobile,
  (
    avmSelectedKey,
    avmHc,
    avmComps,
    avmCompsAvg,
    hasRentalCompFarm,
    hasSelectedComps,
    isMobile
  ) => {
    let avms = [];
    if (avmSelectedKey !== AVM_KEY_RENTAL_HC) {
      avms.push({
        showNullState: !avmHc || !avmHc.value,
        nullStateInfoKey: 'noAvmRental',
        avmKey: AVM_KEY_RENTAL_HC,
        avm: avmHc,
        label: 'HouseCanary Rental Value',
        subtext: AVM_TYPE_DESCRIPTION[AVM_KEY_RENTAL_HC],
        buttonText: 'Use For Report',
        value: dollarsFormatter(avmHc.value)
      });
    }
    if (avmSelectedKey !== AVM_KEY_RENTAL_COMPS) {
      avms.push(
        _buildAvmSelectorRentalComps(
          avmComps,
          AVM_KEY_RENTAL_COMPS,
          hasSelectedComps,
          hasRentalCompFarm,
          isMobile
        )
      );
    }
    if (avmSelectedKey !== AVM_KEY_RENTAL_COMPS_AVG) {
      avms.push(
        _buildAvmSelectorRentalComps(
          avmCompsAvg,
          AVM_KEY_RENTAL_COMPS_AVG,
          hasSelectedComps,
          hasRentalCompFarm,
          isMobile
        )
      );
    }
    return avms;
  }
);

export const getAvmRentalSelectorUserEntered = createSelector(
  getAvmRentalSelectedKey,
  getAvmRentalUserEntered,
  getAvmRental,
  (avmSelectedKey, avmUserEntered, avmHc) => {
    return {
      avmKey: AVM_KEY_RENTAL_USER_ENTERED,
      avm: avmUserEntered,
      label: 'Your Opinion of Rental Price: ',
      buttonText: 'Use For Report',
      isSelected: avmSelectedKey === AVM_KEY_RENTAL_USER_ENTERED,
      value: avmUserEntered.value,
      referenceAvm: avmHc,
      maxLength: 5,
      warningText:
        'Your price is outside HouseCanary’s predicted rental value range. Are you sure?',
      dataHcNames: {
        label: 'rental-valuation-user-entered-header',
        button: 'rental-valuation-user-entered-button',
        input: 'rental-valuation-user-entered-value-textbox'
      }
    };
  }
);

export const getAvmWithFallback = (state) => {
  let avm = getAvmSelected(state);
  if (!avm || !avm.value) {
    avm = getAvmByKey(AVM_KEY_HC)(state);
  }
  return avm;
};

export const getAvmShowBarChart = (state) => {
  const avmSelectedKey = getAvmSelectedKey(state);
  const isAvmHcEmpty = getIsAvmHcEmpty(state);
  // NOTE: this functionality is going to be needed in the next release,
  //       this selector works so I am going to comment out the code.
  return avmSelectedKey === AVM_KEY_HC && !isAvmHcEmpty;
  // if (avmSelectedKey === AVM_KEY_USER_ENTERED) {
  //   return false;
  // } else if (avmSelectedKey === AVM_KEY_COMPS) {
  //   // NOTE: I cannot use a selector because of a circular import
  //   if (state.comps.selected.length > 1) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } else {
  //   return true;
  // }
};

export const getAvmRentalShowBarChart = (state) => {
  const avmSelectedKey = getAvmRentalSelectedKey(state);
  // NOTE: this functionality is going to be needed in the next release,
  //       this selector works so I am going to comment out the code.
  return avmSelectedKey === AVM_KEY_RENTAL_HC;
  // if (avmSelectedKey === AVM_KEY_RENTAL_USER_ENTERED) {
  //   return false;
  // } else if (avmSelectedKey === AVM_KEY_RENTAL_COMPS) {
  //   // NOTE: I cannot use a selector because of a circular import
  //   if (state.rentalComps.selected.length > 1) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } else {
  //   return true;
  // }
};

export const getAvmForCompSelectionPage = createSelector(
  getAvmSelectedKey,
  getAvmComps,
  getAvmCompsAvg,
  (avmSelectedKey, avmComps, avmCompsAvg) =>
    avmSelectedKey === AVM_KEY_COMPS_AVG ? avmCompsAvg : avmComps
);

export const getAvmKeyForCompSelectionPage = createSelector(
  getAvmSelectedKey,
  (avmSelectedKey) =>
    avmSelectedKey === AVM_KEY_COMPS_AVG ? AVM_KEY_COMPS_AVG : AVM_KEY_COMPS
);

export const getAvmForRentalCompSelectionPage = createSelector(
  getAvmRentalSelectedKey,
  getAvmRentalComps,
  getAvmRentalCompsAvg,
  (avmSelectedKey, avmComps, avmCompsAvg) =>
    avmSelectedKey === AVM_KEY_RENTAL_COMPS_AVG ? avmCompsAvg : avmComps
);

export const getAvmKeyForRentalCompSelectionPage = createSelector(
  getAvmSelectedKey,
  (avmSelectedKey) =>
    avmSelectedKey === AVM_KEY_RENTAL_COMPS_AVG
      ? AVM_KEY_RENTAL_COMPS_AVG
      : AVM_KEY_RENTAL_COMPS
);

export const getSelectedConfidenceScoreKey = createSelector(
  getAvmSelectedKey,
  getAvmHc,
  getAvmSelected,
  (avmSelectedKey, avmHc, avmSelected) =>
    !avmSelected.value || avmSelectedKey === AVM_KEY_USER_ENTERED
      ? null
      : avmSelected.value > avmHc.maxVal || avmSelected.value < avmHc.minVal
      ? avmSelectedKey
      : AVM_KEY_HC
);

export const getAvmSelectedConfidenceScoreKeyForJson = createSelector(
  getSelectedConfidenceScoreKey,
  (selectedConfidenceScoreKey) =>
    camelCaseToSnakeCase(selectedConfidenceScoreKey)
);

export const getAvmCompValueConfidence = createSelector(
  getAvmHc,
  getAvmForCompSelectionPage,
  getAvmComps,
  (avmHc, avmComps, compConfidence) =>
    !avmComps.value
      ? {}
      : avmComps.value > avmHc.maxVal || avmComps.value < avmHc.minVal
      ? {
          fsd: compConfidence.fsd,
          valuationSuitabilityScore: compConfidence.valuationSuitabilityScore,
          valuationSuitabilityScoreDesc:
            compConfidence.valuationSuitabilityScoreDesc
        }
      : {
          fsd: avmHc.fsd,
          valuationSuitabilityScore: avmHc.valuationSuitabilityScore,
          valuationSuitabilityScoreDesc: avmHc.valuationSuitabilityScoreDesc
        }
);

export const getAvmRentalCompValueConfidence = createSelector(
  getAvmRentalHc,
  getAvmForRentalCompSelectionPage,
  getAvmRentalComps,
  (avmHc, avmComps, compConfidence) =>
    !avmComps.value
      ? {}
      : avmComps.value > avmHc.maxVal || avmComps.value < avmHc.minVal
      ? {
          fsd: compConfidence.fsd,
          valuationSuitabilityScore: compConfidence.valuationSuitabilityScore,
          valuationSuitabilityScoreDesc:
            compConfidence.valuationSuitabilityScoreDesc
        }
      : {
          fsd: avmHc.fsd,
          valuationSuitabilityScore: avmHc.valuationSuitabilityScore,
          valuationSuitabilityScoreDesc: avmHc.valuationSuitabilityScoreDesc
        }
);

export const getCompAvgPriorToRefresh = createSelector(
  getAvmsState,
  (avmsState) => avmsState.compAvgPriorToRefresh
);

export const getCompValuePriorToRefresh = createSelector(
  getAvmsState,
  (avmsState) => avmsState.compValuePriorToRefresh
);

export const getAvmPriorToRefreshForCompSelectionPage = createSelector(
  getAvmSelectedKey,
  getCompValuePriorToRefresh,
  getCompAvgPriorToRefresh,
  (avmSelectedKey, avmComps, avmCompsAvg) =>
    avmSelectedKey === AVM_KEY_COMPS_AVG ? avmCompsAvg : avmComps
);

export const getAvmForCompSelectionPageHasChanged = createSelector(
  getAvmForCompSelectionPage,
  getAvmPriorToRefreshForCompSelectionPage,
  (avm, avmPriorToRefresh) => {
    if (
      avmPriorToRefresh &&
      avmPriorToRefresh.value !== avm.value &&
      (!!avm.value || !!avmPriorToRefresh.value)
    ) {
      return true;
    } else {
      return false;
    }
  }
);

export const getRentalCompAvgPriorToRefresh = createSelector(
  getAvmsState,
  (avmsState) => avmsState.rentalCompAvgPriorToRefresh
);

export const getRentalCompValuePriorToRefresh = createSelector(
  getAvmsState,
  (avmsState) => avmsState.rentalCompValuePriorToRefresh
);

export const getAvmPriorToRefreshForRentalCompSelectionPage = createSelector(
  getAvmRentalSelectedKey,
  getRentalCompValuePriorToRefresh,
  getRentalCompAvgPriorToRefresh,
  (avmSelectedKey, avmComps, avmCompsAvg) =>
    avmSelectedKey === AVM_KEY_RENTAL_COMPS_AVG ? avmCompsAvg : avmComps
);

export const getAvmForRentalCompSelectionPageHasChanged = createSelector(
  getAvmForRentalCompSelectionPage,
  getAvmPriorToRefreshForRentalCompSelectionPage,
  (avm, avmPriorToRefresh) => {
    if (
      avmPriorToRefresh &&
      avmPriorToRefresh.value !== avm.value &&
      (!!avm.value || !!avmPriorToRefresh.value)
    ) {
      return true;
    } else {
      return false;
    }
  }
);
