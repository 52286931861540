import HC_CONSTANTS from 'HC_CONSTANTS';

const { HCS_PLATFORM_URL } = HC_CONSTANTS;

export function* routeEffectiveDateAddress({
  params: { addressSlug, effectiveDate },
  query: { clientFileId }
}) {
  const clientFileIdParam = clientFileId ? `&clientFileId=${clientFileId}` : '';
  // SECURITY: Open known url - @jnettleman
  // eslint-disable-next-line
  window.open(
    `${HCS_PLATFORM_URL}/pexp/create/${addressSlug}?effectiveDate=${effectiveDate}${clientFileIdParam}`,
    '_self'
  );
}

export function* routeEffectiveDateReport({ params: { reportId } }) {
  // SECURITY: Open known url - @jnettleman
  // eslint-disable-next-line
  window.open(`${HCS_PLATFORM_URL}/pexp/report/${reportId}`, '_self');
}
