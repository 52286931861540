import React from 'react';
import { RangeChart } from 'src/lib/components/RangeChart/RangeChart';
import { AvmDocument, DocumentRoles } from '../../types';
import { useDocumentRole } from '../../hooks';
import { formatMoney, formatPricePerSqFt } from '../../../utils/formatters';
import { AvmTypeLabel, AvmType } from '../../constants';
import { ReportValueSelector } from '../ReportValueSelector/ReportValueSelector';
import { CompBasedAvmSelectorItem } from '../ReportValueSelector/CompBasedAvmSelectorItem';
import { AdjustedCompBasedAvgSelectorItem } from '../ReportValueSelector/AdjustedCompBasedAvgSelectorItem';

import styles from './ReportValue.css';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { isLoading } from 'src/lib/utils/general';

interface Props {
  reportId: number;
  dataHcName: string;
  onClickSelectComps: VoidFunction;
}

export const ReportValue = ({
  reportId,
  onClickSelectComps,
  dataHcName
}: Props) => {
  const {
    data: [avmDocument],
    status: avmDocStatus
  } = useDocumentRole<AvmDocument>(reportId, DocumentRoles.Avm);
  if (isLoading(avmDocStatus) || !avmDocument) {
    return <LoadingSpinner dataHcName={dataHcName} containerHeight="380px" />;
  }
  const avmType = avmDocument.data.selected;
  const avm = avmDocument.data[avmType];

  return (
    <div data-hc-name={dataHcName}>
      <div className={styles.Label}>
        {`${AvmTypeLabel[avmType]} :`}
        <ReportValueSelector
          reportId={reportId}
          dataHcName="report-value-selector"
          onClickSelectComps={onClickSelectComps}
        />
      </div>
      {avmType === AvmType.CompAvm &&
        !avmDocument.data.compBasedAvm.priceMean && (
          <CompBasedAvmSelectorItem
            dataHcName={'report-value-comp-selector'}
            reportId={reportId}
            className={styles.AvmSelectorInValue}
            onClickSelectComps={onClickSelectComps}
          />
        )}
      {avmType === AvmType.CompMarket &&
        !avmDocument.data.adjustedCompBasedAvg.priceMean && (
          <AdjustedCompBasedAvgSelectorItem
            dataHcName={'report-value-comp-selector'}
            reportId={reportId}
            className={styles.AvmSelectorInValue}
            onClickSelectComps={onClickSelectComps}
          />
        )}
      {avmType === AvmType.HouseCanary && (
        <RangeChart
          dataHcName={'report-value-chart'}
          lowLabel="Low Estimate"
          highLabel="High Estimate"
          mainValue={
            <div className={styles.MainValue}>
              <div className={styles.Value}>{formatMoney(avm.priceMean)}</div>
            </div>
          }
          lowTop={formatMoney(avm.priceLower)}
          highTop={formatMoney(avm.priceUpper)}
          lowBottom={formatPricePerSqFt(avm.minValPerSqFt)}
          highBottom={formatPricePerSqFt(avm.maxValPerSqFt)}
        />
      )}
      {avmType !== AvmType.HouseCanary &&
        (avmDocument.data.compBasedAvm.priceMean ||
          avmDocument.data.adjustedCompBasedAvg.priceMean) && (
          <div
            className={styles.MainValue}
            data-hc-name={`${dataHcName}-main-value`}
          >
            <div className={styles.Value} data-hc-name={`${dataHcName}-value`}>
              {formatMoney(avm.priceMean)}
            </div>
          </div>
        )}
      {avmType === AvmType.UserEntered && (
        <span
          className={styles.Disclaimer}
          data-hc-name={`${dataHcName}-disclaimer`}
        >
          This value is not generated or endorsed by HouseCanary, and it does
          not represent a broker price opinion (BPO) or a formal appraisal.
        </span>
      )}
    </div>
  );
};
