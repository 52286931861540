// This module handles breaking changes in the report schema, also clarifies rental data keys
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';

import {
  QUALITY_KEYS,
  AVM_KEY_COMPS,
  AVM_KEY_COMPS_AVG,
  AVM_KEY_RENTAL_COMPS,
  AVM_KEY_RENTAL_COMPS_AVG
} from 'legacy/appstore/constants';
import { checkShouldScrub } from 'legacy/utils/mls-compliance.utils';
import {
  calcCompValue,
  calcCompAdjustedSoldPrice,
  calcRentalCompAdjustedPrice
} from 'legacy/utils/avms';
import { ageToYear } from 'legacy/utils/dates';
import {
  FILTERS,
  TRANSFORMED_FILTER_KEYS_SOLD_LEGACY_NEW,
  TRANSFORMED_FILTER_KEYS_RENTAL_LEGACY_NEW
} from 'legacy/utils/filters';
import { FILTER_SAVE_METHODS } from 'legacy/utils/saved-filters';
import { camelCaseToSnakeCase } from 'legacy/utils/transform';
import { isObject } from 'legacy/utils/utils';

const STATUSES_TO_NORMALIZE = {
  Active: 'Active',
  Contingent: 'Pending',
  Pending: 'Pending',
  'Contingent (with Kick Out)': 'Pending',
  Leased: 'Sold',
  Sold: 'Sold'
};

const QUALITY_TO_CONDITION_OLD = {
  poor: 1,
  subpar: 2,
  fair: 3,
  good: 4,
  excellent: 5
};

const CONDITION_TO_QUALITY = {
  1: QUALITY_KEYS.CON5,
  2: QUALITY_KEYS.CON4,
  3: QUALITY_KEYS.CON3,
  4: QUALITY_KEYS.CON2,
  5: QUALITY_KEYS.CON2
};

const translateQuality = (quality) => {
  const condtionFromOldQuality = QUALITY_TO_CONDITION_OLD[quality];
  if (condtionFromOldQuality) {
    return CONDITION_TO_QUALITY[condtionFromOldQuality];
  }
  return quality;
};

const processProperty = (property, { forceKeep = false, shouldScrubData }) => {
  if (!property) {
    return property;
  }
  // Normalize Listing Statuses and Populate propertyStatus
  if (!property.propertyStatus) {
    let statusNormalized = STATUSES_TO_NORMALIZE[property.listingStatus];
    if (statusNormalized) {
      // Changed 'Sold' normalized status to 'Leased' for rental comps
      property.propertyStatus =
        property.isRentalComp && statusNormalized === 'Sold'
          ? 'Leased'
          : statusNormalized;
    } else {
      if (property.salesPrice || forceKeep) {
        // Null listing data and status, but keep comp when there is sales data
        property.lastListPrice = null;
        property.lastListDate = null;
        property.propertyStatus = 'Sold';
      } else if (!property.userAdded) {
        // Delete comp if no listing data
        return null;
      }
    }
    if (property.isRentalComp) {
      property.propertyStatusRental = property.propertyStatus;
      delete property.propertyStatus;
    }
  }
  // Change IGP property type to Multifamily
  if (property.propertyType === 'Income Generating Property') {
    property.propertyType = 'Multifamily';
  }
  // Add yearBuilt field to simpilify filtering logic
  property.yearBuilt = parseInt(ageToYear(property.age), 10);

  if (shouldScrubData) {
    property.salesPrice = null;
    property.salesPriceAdjusted = null;
    property.leasedPrice = null;
  }
  return property;
};

// Iterates List of Properties and calls processProperty on each
export const processPropertyList = (
  propertyList,
  { idsToKeep, shouldScrubData }
) => {
  if (!propertyList) {
    return propertyList;
  }
  let propertyListProcessed = [];
  propertyList.forEach((property) => {
    const propertyProcessed = processProperty(property, {
      forceKeep: idsToKeep.indexOf(property.addressId) > -1,
      shouldScrubData
    });
    if (propertyProcessed) {
      propertyListProcessed.push(propertyProcessed);
    }
  });
  return propertyListProcessed;
};

const processPropertyListKeepSelectedComps = (
  propertyList,
  report,
  shouldScrubData
) =>
  processPropertyList(propertyList, {
    idsToKeep: get(report, ['metadata', 'selectedCompsByAddressId'], []),
    shouldScrubData
  });

const RENTAL_PROPERTY_KEYS_TO_CHANGE = {
  lastListPrice: 'lastListPriceRental',
  lastListDate: 'lastListDateRental',
  propertyStatus: 'propertyStatusRental'
};

const processRentalProperty = (property, report, shouldScrubData) => {
  for (let key in RENTAL_PROPERTY_KEYS_TO_CHANGE) {
    if (!property.hasOwnProperty(RENTAL_PROPERTY_KEYS_TO_CHANGE[key])) {
      property[RENTAL_PROPERTY_KEYS_TO_CHANGE[key]] = property[key];
      delete property[key];
    }
  }
  property.isRentalComp = true;
  if (shouldScrubData) {
    property.leasedPrice = null;
    property.salesPrice = null;
    property.salesPriceAdjusted = null;
  }
  return property;
};

export const processRentalPropertyList = (
  propertyList,
  report,
  shouldScrubData
) => {
  return propertyList.map((p) =>
    processRentalProperty(p, report, shouldScrubData)
  );
};

const processRentalPropertyMapping = (
  propertyMapping,
  report,
  shouldScrubData
) => {
  let transformed = {};
  for (let addressId in propertyMapping) {
    transformed[addressId] = processRentalProperty(
      propertyMapping[addressId],
      report,
      shouldScrubData
    );
  }
  return transformed;
};

const OLD_FILTER_KEYS = {
  grossLivingAreaSqft_minmax: 'grossLivingAreaSqft',
  siteAreaSqft_minmax: 'lotSize',
  bedrooms_minmax: 'bedrooms',
  bathrooms_minmax: 'bathrooms',
  salesPrice_minmax: 'salesPrice',
  salesDate_minmax: 'salesDate',
  lastListPrice_minmax: 'lastListPrice',
  lastListDate_minmax: 'lastListDate',
  lastListPriceRental_minmax: 'lastListPriceRental',
  lastListDateRental_minmax: 'lastListDateRental',
  propertyType_included: 'propertyType',
  propertyStatus_included: 'propertyStatus',
  propertyStatusRental_included: 'propertyStatusRental',
  similarityLevelAdjusted_included: 'similarity',
  yearBuilt_minmax: 'yearBuilt',
  leasedPrice_minmax: 'leasedPrice',
  leasedDate_minmax: 'leasedDate',
  stories_minmax: 'stories',
  garageNumCars_minmax: 'garageNumCars',
  pool_boolean: 'pool',
  basement_boolean: 'basement'
};

const convertToRelativeFilters = (filters, report) => {
  if (
    isObject(filters) &&
    !isEmpty(filters) &&
    !filters[
      Object.keys(filters).find((k) => k !== 'distance')
    ]?.hasOwnProperty('absoluteValue')
  ) {
    const subject = {
      ...get(report, 'subject', {}),
      ...get(report, ['metadata', 'propertyEdits', 'subject'], {})
    };
    const transformedFilters = {};
    Object.entries(filters).forEach(([oldFilterKey, absoluteValue]) => {
      // New filter keys are unmodified property attribute names
      if (OLD_FILTER_KEYS.hasOwnProperty(oldFilterKey)) {
        const attribute = OLD_FILTER_KEYS[oldFilterKey];
        const filterConfig = FILTERS[attribute];
        const filterMethods = FILTER_SAVE_METHODS[filterConfig.saveType];
        const relativeValue = filterMethods.save(
          absoluteValue,
          subject[attribute]
        );
        transformedFilters[attribute] = {
          absoluteValue,
          relativeValue
        };
      }
    });
    return transformedFilters;
  } else {
    return filters;
  }
};

const processRentalCompsFilters = (filters, report) => {
  if (isObject(filters)) {
    // Change rental list_date keys to append _rental
    if (filters.hasOwnProperty('lastListDate_minmax')) {
      filters['lastListDateRental_minmax'] = filters['lastListDate_minmax'];
      delete filters['lastListDate_minmax'];
    }
    if (filters.hasOwnProperty('lastListPrice_minmax')) {
      filters['lastListPriceRental_minmax'] = filters['lastListPrice_minmax'];
      delete filters['lastListPrice_minmax'];
    }
    if (filters.hasOwnProperty('propertyStatus_included')) {
      filters['propertyStatusRental_included'] =
        filters['propertyStatus_included'];
      delete filters['propertyStatus_included'];
    }
    filters = convertToRelativeFilters(filters, report);
  }
  return filters;
};

export const processPropertyEdits = (propertyEdits) => {
  // NOTE: Currently only used to translate condition mappings
  const subjectQuality = get(propertyEdits, ['subject', 'quality']);
  if (subjectQuality) {
    return {
      ...propertyEdits,
      subject: {
        ...propertyEdits.subject,
        quality: translateQuality(subjectQuality)
      }
    };
  }
  return propertyEdits;
};

export const processSubject = (subject, report, shouldScrubData) => {
  // NOTE: Currently only used to translate condition mappings
  if (shouldScrubData) {
    subject.salesPrice = null;
  }
  return {
    ...subject,
    quality: translateQuality(subject.quality)
  };
};

const processSubjectPurchaseHistory = (
  subjectPurchaseHistory,
  report,
  shouldScrubData
) => {
  if (shouldScrubData) {
    return subjectPurchaseHistory.map((item) => {
      if (item.source !== 'MLS') {
        return item;
      } else {
        item.salesPrice = null;
        return item;
      }
    });
  } else {
    return subjectPurchaseHistory;
  }
};

// Define functions to process sections of the report. Nested attributes supported with '.' delimitter
const REPORT_KEYS_TO_PROCESS = {
  subject: processSubject,
  subjectPurchaseHistory: processSubjectPurchaseHistory,
  compsFarmList: processPropertyListKeepSelectedComps,
  activeListings: processPropertyListKeepSelectedComps,
  historicalSimilarComps: processPropertyListKeepSelectedComps,
  recentSimilarSales: processPropertyListKeepSelectedComps,
  'metadata.selectedRentalComps': processRentalPropertyMapping,
  'metadata.rentalCompFarm': processRentalPropertyMapping,
  'metadata.activeFiltersRentalComps': processRentalCompsFilters,
  'metadata.activeFiltersComps': convertToRelativeFilters,
  'metadata.propertyEdits': processPropertyEdits,
  propertyLookup: (property, report, shouldScrubData) =>
    processProperty(property, { forceKeep: true, shouldScrubData })
};

// TODO: Remove this temporary fix after Jan 1 2020
const _compAvgFix = (reportOriginal) => {
  // First fix is to support a key-change that was only deployed to the dev environment
  const report = { ...reportOriginal };
  const BROKEN_KEY = 'soldCompBasedAvg';
  const BROKEN_RENTAL_KEY = 'leasedRentalCompBasedAvg';
  const BROKEN_RENTAL_KEY2 = 'rentalCompBasedAvg';
  if (report[BROKEN_KEY]) {
    report[AVM_KEY_COMPS_AVG] = report[BROKEN_KEY];
    delete report[BROKEN_KEY];
    if (report.metadata.selectedAvm === camelCaseToSnakeCase(BROKEN_KEY)) {
      report.metadata.selectedAvm = camelCaseToSnakeCase(AVM_KEY_COMPS_AVG);
    }
  }
  if (report[BROKEN_RENTAL_KEY]) {
    report[AVM_KEY_RENTAL_COMPS_AVG] = report[BROKEN_RENTAL_KEY];
    delete report[BROKEN_RENTAL_KEY];
    if (
      report.metadata.selectedRentalAvm ===
      camelCaseToSnakeCase(BROKEN_RENTAL_KEY)
    ) {
      report.metadata.selectedRentalAvm = camelCaseToSnakeCase(
        AVM_KEY_RENTAL_COMPS_AVG
      );
    }
  }
  if (report[BROKEN_RENTAL_KEY2]) {
    report[AVM_KEY_RENTAL_COMPS_AVG] = report[BROKEN_RENTAL_KEY2];
    delete report[BROKEN_RENTAL_KEY2];
    if (
      report.metadata.selectedRentalAvm ===
      camelCaseToSnakeCase(BROKEN_RENTAL_KEY2)
    ) {
      report.metadata.selectedRentalAvm = camelCaseToSnakeCase(
        AVM_KEY_RENTAL_COMPS_AVG
      );
    }
  }

  // Calculate the adjusted comp avms if the HC comp avm exists
  const subject = {
    ...get(report, ['subject'], {}),
    ...get(report, ['metadata', 'propertyEdits', 'subject'], {})
  };
  if (
    (!report[AVM_KEY_COMPS_AVG] || isEmpty(report[AVM_KEY_COMPS_AVG])) &&
    report[AVM_KEY_COMPS]
  ) {
    const farm = get(report, ['compsFarmList'], []);
    const compSelectedIds = get(
      report,
      ['metadata', 'selectedCompsByAddressId'],
      []
    );
    const compsSelected = [];
    farm.forEach((comp) => {
      if (compSelectedIds.includes(comp.addressId)) {
        compsSelected.push(comp);
      }
    });
    const compAvm = { ...report[AVM_KEY_COMPS] };
    const avg = calcCompValue(
      compsSelected,
      subject,
      calcCompAdjustedSoldPrice
    );
    // override comp based avm with calculated adjused comp based average
    // if fsd, valuation_suitability_score, and valuation_suitability_score_desc keys exist in comp based avm,
    // they will not be overridden and should be the same values for adjusted comp based average.
    report[AVM_KEY_COMPS_AVG] = { ...compAvm, ...avg };
  }
  // Calculate the adjusted rental comp avms if the HC comp avm exists
  if (
    (!report[AVM_KEY_RENTAL_COMPS_AVG] ||
      isEmpty(report[AVM_KEY_RENTAL_COMPS_AVG])) &&
    report[AVM_KEY_RENTAL_COMPS]
  ) {
    const rentalCompsSelected = Object.entries(
      get(report, ['metadata', 'selectedRentalComps'], {})
    ).map(([i, comp]) => {
      return {
        ...comp,
        ...get(report, ['metadata', 'propertyEdits', comp.addressId], {})
      };
    });
    const rentalCompAvm = { ...report[AVM_KEY_RENTAL_COMPS] };
    const rentalAvg = calcCompValue(
      rentalCompsSelected,
      subject,
      calcRentalCompAdjustedPrice
    );
    // override rental comp based avm with calculated adjused comp based average
    // if fsd, valuation_suitability_score, and valuation_suitability_score_desc keys exist in rental comp based avm,
    // they will not be overridden and should be the same values for rental adjusted comp based average.
    report[AVM_KEY_RENTAL_COMPS_AVG] = { ...rentalCompAvm, ...rentalAvg };
  }
  return report;
};

export const reportSchemaService = (report, scrubDataArgs) => {
  if (!report) {
    return report;
  }
  const { state, orgId } = scrubDataArgs || {};
  const shouldScrubData = checkShouldScrub(state, orgId);
  const DOES_NOT_EXIST = 'DOES_NOT_EXIST';
  for (let key in REPORT_KEYS_TO_PROCESS) {
    const path = key.split('.');
    const attribute = get(report, path, DOES_NOT_EXIST);
    if (attribute !== DOES_NOT_EXIST) {
      const value = REPORT_KEYS_TO_PROCESS[key](
        attribute,
        report,
        shouldScrubData
      );
      report = set(report, path, value);
    }
  }
  const reportNew = _compAvgFix(report);
  return reportNew;
};

// Define functions to process specifically for the rental report
const RENTAL_REPORT_KEYS_TO_PROCESS = {
  subject: processRentalProperty,
  compsFarmList: processRentalPropertyList
};

export const rentalReportSchemaService = (report, scrubDataArgs) => {
  if (!report) {
    return report;
  }
  report = reportSchemaService(report, scrubDataArgs);
  const { state, orgId } = scrubDataArgs || {};
  const shouldScrubData = checkShouldScrub(state, orgId);
  for (let key in RENTAL_REPORT_KEYS_TO_PROCESS) {
    const path = key.split('.');
    const value = RENTAL_REPORT_KEYS_TO_PROCESS[key](
      get(report, path),
      report,
      shouldScrubData
    );
    report = set(report, path, value);
  }
  return report;
};

export default reportSchemaService;
