import React, { CSSProperties, ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import styles from './Table.css';

export interface Props {
  // dataHcName not required because it will be passed down from Table
  dataHcName?: string;
  // Belongs to a sticky column
  sticky?: boolean;
  // Offset from left if column is sticky
  stickyOffset?: number;
  // Width required when sticky is true
  width?: number;
  // Inline styles
  style?: CSSProperties;
  // Height of the cell
  height?: number;
  // Class
  className?: string;
  // TableHeaderCells
  children: ReactNode;
  // Text Align
  align?: 'left' | 'right' | 'center';
  // Sort Arrow + Direction
  sort?: 'ASC' | 'DESC';
  // Whether rows are sortable, renders a placeholder for the sort arrow in TableHeaderCells
  // to prevent the cell from resizing when sorting by the column
  sortable?: boolean;
  onClick?: VoidFunction;
}

export const TableHeaderCell = ({
  dataHcName,
  className,
  align = 'left',
  height,
  width,
  children,
  sticky,
  stickyOffset,
  sort,
  sortable,
  style = {},
  onClick
}: Props) => {
  useEffect(() => {
    if (sticky && width === undefined) {
      console.warn(
        '[TableHeaderCell] sticky is true but a width is not defined'
      );
    }
  }, []);
  return (
    <th
      data-hc-name={dataHcName}
      className={classNames(styles.TableHeaderCell, className, {
        [styles.sticky || '']: sticky,
        [styles.clickable || '']: !!onClick
      })}
      style={{
        ...style,
        height: height !== undefined ? `${height}px` : style.height,
        width: width !== undefined ? `${width}px` : style.width,
        left: stickyOffset !== undefined ? `${stickyOffset}px` : style.left
      }}
      onClick={onClick}
    >
      <div className={styles.Content}>
        <div
          className={classNames({
            [styles.left || '']: align === 'left',
            [styles.right || '']: align === 'right',
            [styles.center || '']: align === 'center'
          })}
        >
          {children}
        </div>
        {(sort || sortable) && (
          <div
            className={classNames(styles.Sort, {
              [styles.ASC || '']: sort === 'ASC',
              [styles.DESC || '']: sort === 'DESC'
            })}
          />
        )}
      </div>
    </th>
  );
};
