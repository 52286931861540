import get from 'lodash/get';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import {
  AVM_KEY_HC,
  AVM_KEY_USER,
  AVM_KEY_COMPS
} from 'legacy/appstore/constants';

import {
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_HC_VERSION_SUCCESS
} from 'actions/get-report';

const KEYS_BASED_ON_AVM = ['forecastModels', 'chartData'];

const INITIAL_STATE = {
  [AVM_KEY_HC]: {},
  [AVM_KEY_USER]: {},
  [AVM_KEY_COMPS]: {}
};

const _buildUpdatedState = (currentState, report) => {
  let updatedState = { ...currentState };
  forEach(KEYS_BASED_ON_AVM, (key) => {
    const data = get(report, key, false);
    if (data && (data.constructor !== Object || !isEmpty(data))) {
      updatedState[key] = data;
    }
  });
  return updatedState;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_HC_VERSION_SUCCESS:
      return {
        ...state,
        [AVM_KEY_HC]: _buildUpdatedState(
          state[AVM_KEY_HC],
          action.payload.report
        )
      };

    case GET_REPORT_USER_VERSION_SUCCESS:
      const updatedAvmState = _buildUpdatedState(
        state[AVM_KEY_USER],
        action.payload.report
      );
      return {
        ...state,
        // NOTE: Currently, the comps avm and user avm are handled the same way. This should change.
        [AVM_KEY_USER]: updatedAvmState,
        [AVM_KEY_COMPS]: updatedAvmState
      };

    default:
      return state;
  }
};
