import React from 'react';

import {
  AVM_TYPE_DESCRIPTION,
  LABEL_FOR_AVM_KEY
} from 'legacy/appstore/constants';

import { dollarsFormatter } from 'legacy/utils/formatters';

import Tooltip from 'legacy/components/generic/Tooltip';

import { ValueTableCell as theme } from 'legacy/css-modules';

const ValueTableCell = ({ currentValue, currentAvmType }) => {
  if (!currentValue) return '--';
  const formattedValue = dollarsFormatter(currentValue);
  if (!currentAvmType) return formattedValue;
  return (
    <Tooltip
      theme={theme}
      shift="right"
      trigger={formattedValue}
      content={
        <div className={theme.AvmTooltip}>
          <h3 className={theme.Label}>{LABEL_FOR_AVM_KEY[currentAvmType]}</h3>
          <div className={theme.Description}>
            {AVM_TYPE_DESCRIPTION[currentAvmType]}
          </div>
        </div>
      }
      transparentScreen
    />
  );
};

export default ValueTableCell;
