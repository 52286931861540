import { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Dialog } from 'legacy/components/Dialog';
import { TextButton } from 'legacy/components/Button';

import { ID_PORTAL_FIXED } from 'legacy/appstore/constants';

import PropertyHeaderDetails from 'legacy/containers/property-header-details';
import PropertyTransactionHistory from 'legacy/containers/property-transaction-history';

import { TransactionHistoryModal as theme } from 'legacy/css-modules';

const Portal = ({ addressId, active, onClose, compType }) => {
  const target = document.getElementById(ID_PORTAL_FIXED);
  if (target) {
    return createPortal(
      <Dialog
        dataHcName="transaction-history-modal"
        className={theme.Dialog}
        theme={theme}
        type="large"
        active={active}
        onClose={onClose}
      >
        <PropertyHeaderDetails compType={compType} addressId={addressId} />
        <h2 className={theme.Title}>Transaction History</h2>
        <PropertyTransactionHistory
          addressId={addressId}
          dataHcName="transaction-history-modal"
          hideListingRemarks
        />
      </Dialog>,
      target
    );
  } else {
    return null;
  }
};

class TransactionHistoryModal extends Component {
  state = {
    active: false
  };

  handleOpen = () => this.setState({ active: true });

  handleClose = () => this.setState({ active: false });

  render() {
    const { addressId, compType } = this.props;
    const { active } = this.state;
    return (
      <Fragment>
        <TextButton
          className={theme.Button}
          theme={theme}
          onClick={this.handleOpen}
        >
          View Transaction History
        </TextButton>
        <Portal
          addressId={addressId}
          active={active}
          onClose={this.handleClose}
          compType={compType}
        />
      </Fragment>
    );
  }
}

export default TransactionHistoryModal;
