import { createSelector } from 'reselect';
import { STATUSES } from 'src/constants';
import { createFactorySelector } from 'src/lib/utils/redux';
import { selReportApiState } from '../selectors';
import { COBRANDING_REDUCER_KEY } from '../reducer/cobranding.reducer';

export const selCobrandingState = createSelector(
  selReportApiState,
  (reportApiState) => {
    const test = reportApiState[COBRANDING_REDUCER_KEY];
    return test;
  }
);

export const selCobrandingByReportId = createFactorySelector((id: number) =>
  createSelector(selCobrandingState, (cobrandingState) => {
    return cobrandingState[id];
  })
);

export const selCobrandingStatus = createFactorySelector((id: number) =>
  createSelector(
    selCobrandingByReportId(id),
    (cobrandingState) => cobrandingState?.status
  )
);

export const selCobrandingData = createFactorySelector((id: number) =>
  createSelector(selCobrandingByReportId(id), (cobrandingState) => {
    if (cobrandingState) {
      if (cobrandingState.status === STATUSES.SUCCESS) {
        return cobrandingState.data;
      }
    }
    return undefined;
  })
);
