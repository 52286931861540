import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { isLoading } from 'src/lib/utils/general';
import { documentPatch } from '../../actions';
import { useDocumentRole } from '../../hooks';
import { DocumentRoles, FilterDocument } from '../../types';
import { CompFilters } from '../../types/schemas';
import styles from './CompFilterClearButton.css';
interface Props {
  reportId: number;
  dataHcName: string;
  className?: string;
  compField: keyof CompFilters;
}
export const CompFilterClearButton = ({
  reportId,
  dataHcName,
  className,
  compField
}: Props) => {
  const dispatch = useDispatch();
  const {
    status: filterDocumentStatus,
    data: [filterDocument]
  } = useDocumentRole<FilterDocument>(reportId, DocumentRoles.Filter);
  const filterValue = filterDocument?.data.filters[compField];
  if (isLoading(filterDocumentStatus) || !filterDocument || !filterValue) {
    return null;
  }

  const handleClick = () => {
    dispatch(
      documentPatch(reportId, filterDocument.documentId, [
        {
          op: 'remove',
          path: `/data/filters/${compField}`
        }
      ])
    );
  };
  return (
    <button
      data-hc-name={dataHcName}
      className={classNames(styles.CompFilterClearButton, className)}
      onClick={handleClick}
    >
      Clear Filter
    </button>
  );
};
