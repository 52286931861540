import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Checkbox.css';

interface Props {
  checked: boolean;
  dataHcName: string;
  onChange: (v: boolean) => void;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  label?: string | number | ReactNode;
  style?: CSSProperties;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}
export const Checkbox = ({
  checked,
  dataHcName,
  onChange,
  children,
  className,
  disabled,
  label,
  style,
  onMouseEnter,
  onMouseLeave
}: Props) => {
  return (
    <label
      data-hc-name={dataHcName}
      className={classNames(
        styles.Checkbox,
        {
          [styles.disabled || '']: disabled
        },
        className
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <input
        data-hc-name={`${dataHcName}-input`}
        checked={checked}
        className={styles.Input}
        disabled={disabled}
        onClick={() => {
          if (!disabled) onChange(!checked);
        }}
        onChange={() => null}
        type="checkbox"
      />
      <div
        data-hc-name={`${dataHcName}-check`}
        className={classNames(styles.Check, {
          [styles.checked || '']: checked
        })}
        style={style}
      />
      {label ? (
        <span data-hc-name={`${dataHcName}-label`} className={styles.Label}>
          {label}
        </span>
      ) : null}
      {children}
    </label>
  );
};
