import { connect } from 'react-redux';

import { changeGlossarySelected } from 'actions/support';

import { getGlossarySelectedValues } from 'selectors/support';
import { getIsLessThanSmallMedium } from 'selectors/match-media';

import Glossary from 'legacy/components/Glossary';

const mapStateToProps = (state) => {
  const glossaryValues = getGlossarySelectedValues(state);
  return {
    open: !!glossaryValues.length,
    width: getIsLessThanSmallMedium(state) ? '100%' : 400,
    glossaryValues
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(changeGlossarySelected())
});

export default connect(mapStateToProps, mapDispatchToProps)(Glossary);
