import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportRequired } from '../actions';
import { selReportStatus, selReportData } from '../selectors';
// import { useReportEvents } from './useReportEvents';

export const useReport = (reportId: number) => {
  const dispatch = useDispatch();
  const status = useSelector(selReportStatus(reportId));
  // useReportEvents(reportId);
  useEffect(() => {
    if (!status) {
      dispatch(reportRequired(reportId));
    }
  }, [status, reportId]);
  return {
    status,
    data: useSelector(selReportData(reportId))
  };
};
