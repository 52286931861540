export const TOGGLE_CONTACT_SUPPORT = 'TOGGLE_CONTACT_SUPPORT';
export const TOGGLE_SHARE_COMPONENT = 'TOGGLE_SHARE_COMPONENT';
export const TOGGLE_MOBILE_SEARCH = 'TOGGLE_MOBILE_SEARCH';
export const HIDE_MOBILE_SEARCH = 'HIDE_MOBILE_SEARCH';
export const SHOW_NAVBAR_SEARCH = 'SHOW_NAVBAR_SEARCH';
export const HIDE_NAVBAR_SEARCH = 'HIDE_NAVBAR_SEARCH';
/**
 *
 * @param payload: Object with the appropriate property value set to true/false, used to show/hide
 * certain component on the UI accordingly
 * @returns {{type: string, payload: {}}}
 */
export function toggleContactSupport() {
  return { type: TOGGLE_CONTACT_SUPPORT };
}

export function toggleShareComponent() {
  return { type: TOGGLE_SHARE_COMPONENT };
}

export function toggleMobileSearch() {
  return { type: TOGGLE_MOBILE_SEARCH };
}

export function hideMobileSearch() {
  return { type: HIDE_MOBILE_SEARCH };
}

export function showNavbarSearch() {
  return { type: SHOW_NAVBAR_SEARCH };
}

export function hideNavbarSearch() {
  return { type: HIDE_NAVBAR_SEARCH };
}
