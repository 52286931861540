import { Document } from '../types';
import { STATUSES } from '../../../constants';
import {
  DOCUMENTS_CREATE_SUCCESS,
  DOCUMENT_DELETE_SUCCESS,
  DOCUMENT_FETCH,
  DOCUMENT_FETCH_ERROR,
  DOCUMENT_FETCH_SUCCESS,
  DOCUMENT_PATCH_SUCCESS,
  DOCUMENT_ROLE_FETCH_SUCCESS,
  ReportApiActions
} from '../actions';

interface DocumentStateLoading {
  status: STATUSES.LOADING;
  fetching: true;
}

interface DocumentStateSuccess {
  status: STATUSES.SUCCESS;
  fetching: boolean;
  data: Document;
}

interface DocumentStateError {
  status: STATUSES.ERROR;
  fetching: boolean;
  message: string;
}

export type DocumentState =
  | DocumentStateLoading
  | DocumentStateSuccess
  | DocumentStateError;

type DocumentsReportState = Record<string, DocumentState>;

type DocumentsState = Record<number, DocumentsReportState>;

const INITIAL_STATE = {} as DocumentsState;

export const documentReducer = (
  state: DocumentsState = INITIAL_STATE,
  action: ReportApiActions
): DocumentsState => {
  switch (action.type) {
    case DOCUMENT_ROLE_FETCH_SUCCESS: {
      const { reportId, documents } = action.payload;
      const updatedState = { ...state };
      let reportState = updatedState[reportId];
      if (!reportState) {
        const emptyReportDocumentState = {} as DocumentsReportState;
        updatedState[reportId] = emptyReportDocumentState;
        reportState = updatedState[reportId];
      }
      documents.forEach((document) => {
        if (reportState) {
          reportState[document.documentId] = {
            status: STATUSES.SUCCESS,
            fetching: false,
            data: document
          };
        }
      });
      return updatedState;
    }

    case DOCUMENT_FETCH: {
      const { reportId, documentId } = action.payload;
      const updatedState = { ...state };
      let reportState = updatedState[reportId];
      if (!reportState) {
        const emptyReportState = {} as DocumentsReportState;
        updatedState[reportId] = emptyReportState;
        reportState = updatedState[reportId];
      }
      if (reportState) {
        const documentState = reportState[documentId];
        if (documentState) {
          reportState[documentId] = {
            ...documentState,
            fetching: true
          };
        } else {
          reportState[documentId] = {
            status: STATUSES.LOADING,
            fetching: true
          };
        }
      }
      return updatedState;
    }

    case DOCUMENT_FETCH_SUCCESS: {
      const { reportId, documentId, document } = action.payload;
      return {
        ...state,
        [reportId]: {
          ...state[reportId],
          [documentId]: {
            status: STATUSES.SUCCESS,
            fetching: false,
            data: document
          }
        }
      };
    }

    case DOCUMENT_FETCH_ERROR: {
      const { reportId, documentId, message } = action.payload;
      return {
        ...state,
        [reportId]: {
          ...state[reportId],
          [documentId]: {
            status: STATUSES.ERROR,
            fetching: false,
            message
          }
        }
      };
    }

    case DOCUMENT_PATCH_SUCCESS: {
      const { reportId, documents } = action.payload;
      const updatedState = {
        ...state,
        [reportId]: {
          ...state[reportId]
        }
      };
      const reportState = updatedState[reportId];
      if (reportState) {
        documents.forEach((document) => {
          reportState[document.documentId] = {
            status: STATUSES.SUCCESS,
            fetching: false,
            data: document
          };
        });
      }
      return updatedState;
    }

    case DOCUMENT_DELETE_SUCCESS: {
      const { reportId, documentId } = action.payload;
      const updatedState = { ...state };
      const reportState = updatedState[reportId];
      if (reportState) {
        delete reportState[documentId];
        updatedState[reportId] = { ...reportState };
      }
      return updatedState;
    }

    case DOCUMENTS_CREATE_SUCCESS: {
      const { reportId, documents } = action.payload;
      const updatedState = { ...state };
      const reportState = { ...updatedState[reportId] };
      if (reportState) {
        documents.forEach((document) => {
          reportState[document.documentId] = {
            status: STATUSES.SUCCESS,
            fetching: false,
            data: document
          };
        });
        updatedState[reportId] = reportState;
      }
      return updatedState;
    }

    default:
      return state;
  }
};
