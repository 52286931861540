import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { ValueSelectorButton } from 'src/lib/components/ValueSelector/ValueSelectorButton';
import { ValueSelectorItem } from 'src/lib/components/ValueSelector/ValueSelectorItem';
import { documentPatch } from 'src/lib/report-api/actions';
import { useDocumentRole } from '../../hooks';
import { AvmDocument, DocumentRoles } from '../../types';

import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { AvmTypeLabel, AvmType } from '../../constants';

import styles from './ReportValueSelector.css';

interface Props {
  dataHcName: string;
  reportId: number;
  value?: number | null;
  onClose?: VoidFunction;
  className?: string;
  onClickSelectComps: VoidFunction;
}

export const CompBasedAvmSelectorItem = ({
  dataHcName,
  reportId,
  value,
  className,
  onClose,
  onClickSelectComps
}: Props) => {
  const dispatch = useDispatch();
  const {
    data: [avmDocument]
  } = useDocumentRole<AvmDocument>(reportId, DocumentRoles.Avm);

  if (avmDocument?.role !== DocumentRoles.Avm) {
    return <LoadingSpinner dataHcName={dataHcName} containerHeight="380px" />;
  }

  const handleSelect = () => {
    if (value && onClose) {
      dispatch(
        documentPatch(reportId, avmDocument.documentId, [
          { op: 'replace', path: '/data/selected', value: AvmType.CompAvm }
        ])
      );
      onClose();
    } else {
      dispatch(
        documentPatch(reportId, avmDocument.documentId, [
          { op: 'replace', path: '/data/selected', value: AvmType.CompAvm }
        ])
      );
      onClickSelectComps();
    }
  };

  return (
    <ValueSelectorItem
      title={AvmTypeLabel.compBasedAvm}
      dataHcName={dataHcName}
      value={value}
      className={classNames(styles.AVMSelectionItem, className)}
    >
      {value ? (
        <span data-hc-name={`${dataHcName}-value-description`}>
          Adjusted average of comparables&apos; HouseCanary Estimated Value
        </span>
      ) : (
        <span data-hc-name={`${dataHcName}-nonvalue-description`}>
          The adjusted average HouseCanary Estimated Value of the comparables
          you select. Click below to select comps now.
        </span>
      )}
      <ValueSelectorButton
        dataHcName={`${dataHcName}-value-selector-button`}
        onClick={handleSelect}
      >
        {avmDocument.data[AvmType.CompAvm].priceMean
          ? 'Use For Report'
          : 'Select Comps'}
      </ValueSelectorButton>
    </ValueSelectorItem>
  );
};
