import React, {
  useMemo,
  cloneElement,
  CSSProperties,
  ReactElement,
  Fragment,
  ReactNode
} from 'react';
import classNames from 'classnames';
import { Props as TableCellProps, TableCell } from './TableCell';
import {
  Props as TableHeaderCellProps,
  TableHeaderCell
} from './TableHeaderCell';
import styles from './Table.css';

const isTableCell = (
  child: ReactElement<TableCellProps> | ReactElement<TableHeaderCellProps>
): child is ReactElement<TableCellProps> => {
  return child.type === TableCell;
};

const isTableHeaderCell = (
  child: ReactElement<TableCellProps> | ReactElement<TableHeaderCellProps>
): child is ReactElement<TableHeaderCellProps> => {
  return child.type === TableHeaderCell;
};

export interface Props {
  // dataHcName not required because it will be passed down from Table
  dataHcName?: string;
  // Row is fixes to top of table o
  sticky?: boolean;
  // Offset from left if column is sticky
  stickyOffset?: number;
  // Inline styles
  style?: CSSProperties;
  // Height of cell, Overriden by TableRow if height is set on the row.
  height?: number;
  // Class
  className?: string;
  // Cell Content
  children: ReactNode;
  // Whether rows are sortable, renders a placeholder for the sort arrow in TableHeaderCells
  // to prevent the cell from resizing when sorting by the column
  sortable?: boolean;
  onClick?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}

export const TableRow = ({
  dataHcName,
  height,
  sticky,
  stickyOffset,
  className,
  children,
  sortable,
  style = {},
  onClick,
  onMouseEnter,
  onMouseLeave
}: Props): ReactElement => {
  const cells = useMemo(() => {
    const cells: ReactElement[] = [];
    let stickyCellOffset = 0;
    const processChildren = (toProcess: ReactNode) => {
      React.Children.forEach(toProcess, (child, i) => {
        if (!React.isValidElement(child)) {
          throw new Error(
            '[TableRow] Invalid Child. Only TableCell, TableHeaderCell, or Fragment can be a child of TableRow.'
          );
        }
        if (child.type === Fragment) {
          return processChildren(child.props.children);
        } else if (isTableCell(child) || isTableHeaderCell(child)) {
          // Now we know that the child is valid and we can cast a type of it
          if (isTableCell(child)) {
            cells.push(
              cloneElement(child, {
                ...child.props,
                dataHcName: child.props.dataHcName || `${dataHcName}-cell-${i}`,
                stickyRowOffset: stickyOffset,
                stickyOffset: child.props.sticky ? stickyCellOffset : undefined,
                height: height !== undefined ? height : child.props.height
              })
            );
          } else {
            cells.push(
              cloneElement(child, {
                sortable,
                dataHcName: `${dataHcName}-cell-${i}`,
                ...child.props,
                stickyOffset: child.props.sticky ? stickyCellOffset : undefined,
                height: height !== undefined ? height : child.props.height
              })
            );
          }

          if (child.props.width && child.props.sticky) {
            stickyCellOffset += child.props.width;
          }
        } else {
          throw new Error(
            '[TableRow] Invalid Child. Only TableCell, TableHeaderCell, or Fragment can be a child of TableRow.'
          );
        }
      });
    };
    processChildren(children);
    return cells;
  }, [children]);
  return (
    <tr
      style={{
        ...style,
        height: height ? `${height}px` : style.height,
        top:
          sticky && stickyOffset !== undefined ? `${stickyOffset}px` : style.top
      }}
      className={classNames(styles.TableRow, className, {
        [styles.sticky || '']: sticky
      })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-hc-name={dataHcName}
    >
      {cells}
    </tr>
  );
};
