import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentRoleRequired } from '../actions';
import { DocumentBase, DocumentRoles } from '../types';
import { STATUSES } from 'src/constants';
// import { useReportEvents } from './useReportEvents';
import { selDocumentsByRole, selReportApiDocumentRole } from '../selectors';

export const useDocumentRole = <DocType extends DocumentBase>(
  reportId: number,
  documentRole: DocumentRoles
): {
  status?: STATUSES;
  fetching: boolean;
  data: DocType[];
} => {
  const dispatch = useDispatch();
  // useReportEvents(reportId);
  const reportApiDocRole = useSelector(
    selReportApiDocumentRole([reportId, documentRole])
  );
  const status = reportApiDocRole?.status;

  useEffect(() => {
    if (!status) {
      dispatch(documentRoleRequired(reportId, documentRole));
    }
  }, [status, reportId]);
  const documents = useSelector(
    selDocumentsByRole<DocType>([reportId, documentRole])
  );
  return {
    status: status,
    fetching: reportApiDocRole?.fetching || false,
    data: documents
  };
};
