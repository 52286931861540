import React, { ReactNode } from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import { selKeywordSearchInputs } from '../../selectors';
import { findChunks } from '../../utils';
import styles from './KeywordSearchText.css';

const HighlightTag = ({
  children,
  highlightIndex
}: {
  children: ReactNode;
  highlightIndex: number;
}) => {
  return (
    <mark
      className={styles.Keyword}
      data-hc-name={`keyword-search-match-${highlightIndex}`}
    >
      {children}
    </mark>
  );
};

interface Props {
  dataHcName: string;
  className?: string;
  textToHighlight: string;
}
export const KeywordSearchText = ({
  dataHcName,
  className,
  textToHighlight
}: Props) => {
  const keywordSearchInputs = useSelector(selKeywordSearchInputs);
  return (
    <Highlighter
      findChunks={findChunks}
      searchWords={keywordSearchInputs?.keywords || []}
      textToHighlight={textToHighlight}
      data-hc-name={dataHcName}
      className={className}
      highlightTag={HighlightTag}
      autoEscape
    />
  );
};
