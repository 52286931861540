import classNames from 'classnames';
/* To be used only when needing a map marker for display *outside* of the map,
 * as markers within the map are built within the imported hcmaps-leaflet component
 */

import { MapMarker as theme } from 'legacy/css-modules';

export default ({
  label,
  className,
  isHomeStyle,
  isSelectedStyle,
  isCircleStyle,
  noHover,
  large,
  propertyStatus
}) => (
  <div
    className={classNames('map-marker-wrapper', {
      'map-marker-wrapper--home': isHomeStyle,
      'map-marker-wrapper--selected': isSelectedStyle,
      'map-marker-wrapper--circle': isCircleStyle,
      'map-marker-wrapper--large': large,
      'map-marker-wrapper--no-hover': noHover
    })}
  >
    <div className="map-marker">
      <div className="map-marker-icon" />
      <div>
        <div
          className={classNames(theme.Label, {
            [theme.sold]:
              propertyStatus === 'Sold' ||
              propertyStatus === 'Leased' ||
              propertyStatus === 'Listed',
            [theme.active]: propertyStatus === 'Active',
            [theme.pending]: propertyStatus === 'Pending'
          })}
        />
      </div>
      <div className="map-marker-label">
        <span className={isSelectedStyle ? theme.lightLabel : undefined}>
          {label}
        </span>
      </div>
    </div>
  </div>
);
