import {
  PERFORMANCE_REPORT_PULL_START,
  PERFORMANCE_REPORT_PULL_COMPLETE
} from 'actions/performance';

const INITIAL_STATE = {
  timeStart: null,
  timeComplete: null
};

export const performanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PERFORMANCE_REPORT_PULL_START: {
      return {
        timeStart: action.payload.time,
        timeComplete: null
      };
    }

    case PERFORMANCE_REPORT_PULL_COMPLETE: {
      return {
        ...state,
        timeComplete: action.payload.time
      };
    }

    default: {
      return state;
    }
  }
};
