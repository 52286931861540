import get from 'lodash/get';
import {
  EXPECTED_ERROR_MODAL_SHOW,
  ERROR_MODAL_SHOW,
  ERROR_MODAL_HIDE
} from 'actions/error';

import {
  ERROR_ACTION_CLOSE,
  ERROR_ACTION_RELOAD,
  ERROR_TEXT
} from 'legacy/appstore/constants';

import { PROPERTY_SEARCH_FAILURE } from 'actions/property-search';
import { GET_REPORT_HC_VERSION_FAILURE } from 'actions/get-report';

const INITIAL_STATE = {
  isOpen: false,
  message: null,
  messageSecondary: null,
  helpLink: null,
  helpLinkLabel: null,
  actionLabel: null,
  actionType: null,
  dismissible: true
};

export default function errorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EXPECTED_ERROR_MODAL_SHOW:
      return {
        ...state,
        isOpen: true,
        helpLink: 'https://support.housecanary.com',
        helpLinkLabel: 'Contact Us',
        actionLabel: action.payload.actionLabel || 'Reload',
        actionType: action.payload.actionType || ERROR_ACTION_RELOAD,
        message: action.payload.message,
        messageSecondary: action.payload.messageSecondary,
        dismissible:
          action.payload.dismissible !== undefined && action.payload.dismissible
      };

    case ERROR_MODAL_SHOW:
      return {
        ...state,
        isOpen: true,
        helpLink: 'https://support.housecanary.com',
        helpLinkLabel: 'Contact Us',
        actionLabel: 'Reload',
        actionType: ERROR_ACTION_RELOAD,
        message: get(action, ['payload', 'message'], ERROR_TEXT.UNKNOWN),
        messageSecondary: 'We have been alerted about the issue.'
      };

    case ERROR_MODAL_HIDE:
      return {
        ...INITIAL_STATE
      };

    case PROPERTY_SEARCH_FAILURE:
      return {
        isOpen: true,
        message: get(
          action,
          ['payload', 'error', 'message'],
          ERROR_TEXT.ADDRESS_SEARCH.unknownError
        ),
        messageSecondary: null,
        helpLink: get(action, ['payload', 'error', 'helpLink']),
        helpLinkLabel: 'Learn more',
        actionType: get(
          action,
          ['payload', 'error', 'actionType'],
          ERROR_ACTION_CLOSE
        ),
        actionLabel: get(
          action,
          ['payload', 'error', 'actionLabel'],
          'Find another property'
        )
      };
    case GET_REPORT_HC_VERSION_FAILURE:
      return {
        isOpen: true,
        message: get(
          action,
          ['payload', 'error', 'message'],
          ERROR_TEXT.UNKNOWN
        ),
        messageSecondary: null,
        helpLink: get(action, ['payload', 'error', 'helpLink']),
        helpLinkLabel: get(
          action,
          ['payload', 'error', 'helpLinkLabel'],
          'Learn more'
        ),
        actionType: get(
          action,
          ['payload', 'error', 'actionType'],
          ERROR_ACTION_RELOAD
        ),
        actionLabel: get(action, ['payload', 'error', 'actionLabel'], 'Refresh')
      };

    default:
      return state;
  }
}
