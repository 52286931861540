import get from 'lodash/get';

import {
  GET_REPORT_HC_VERSION_SUCCESS,
  GET_REPORT_USER_VERSION_SUCCESS
} from 'actions/get-report';
import { PERSONALIZATION_DATA_UPDATE_SUCCESS } from 'actions/personalization';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EFFECTIVE_DATE_GET_REPORT_SUCCESS: {
      return {
        ...state,
        ...(action.payload.cobranding || {})
      };
    }

    case GET_REPORT_HC_VERSION_SUCCESS:
    case GET_REPORT_USER_VERSION_SUCCESS:
      const cobrandingData = get(
        action,
        ['payload', 'report', 'cobranding'],
        {}
      );
      return {
        ...state,
        ...cobrandingData
      };

    case PERSONALIZATION_DATA_UPDATE_SUCCESS:
      if (action.payload.userIsReportOwner) {
        return {
          ...state,
          ...action.payload.values
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};
