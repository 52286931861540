export const MLS_COVERAGE_REQUIRED = 'MLS_COVERAGE_REQUIRED';
export const MLS_COVERAGE_FETCH = 'MLS_COVERAGE_FETCH';
export const MLS_COVERAGE_FETCH_SUCCESS = 'MLS_COVERAGE_FETCH_SUCCESS';
export const MLS_COVERAGE_FETCH_FAILURE = 'MLS_COVERAGE_FETCH_FAILURE';

export const mlsCoverageRequired = (addressId) => {
  return {
    type: MLS_COVERAGE_REQUIRED,
    payload: { addressId }
  };
};

export const mlsCoverageFetch = (addressId) => {
  return {
    type: MLS_COVERAGE_FETCH,
    payload: { addressId }
  };
};

export const mlsCoverageFetchSuccess = (addressId, mlsCoverage) => {
  return {
    type: MLS_COVERAGE_FETCH_SUCCESS,
    payload: { addressId, mlsCoverage }
  };
};

export const mlsCoverageFetchFailure = (addressId) => {
  return {
    type: MLS_COVERAGE_FETCH_FAILURE,
    payload: { addressId }
  };
};
