export const combineThemes = (themeA, themeB) => {
  const combinedTheme = {};
  for (let key in themeA) {
    if (themeB?.[key]) {
      combinedTheme[key] = `${themeA[key]} ${themeB[key]}`;
      delete themeB[key];
    } else {
      combinedTheme[key] = themeA[key];
    }
  }
  return { ...combinedTheme, ...themeB };
};
