import React from 'react';
import classNames from 'classnames';

import styles from './SimilarityLevel.css';
import { SimilarityLevel as SimilarityLevelType } from 'src/lib/report-api/types/schemas';

const SimilarityLevelDot = () => {
  return (
    <svg height="7" width="7" className={styles.SimilarityLevelDot}>
      <circle cx="3.5" cy="3.5" r="3.5" strokeWidth="0" />
    </svg>
  );
};

interface Props {
  dataHcName: string;
  similarityLevel: SimilarityLevelType;
  className?: string;
}
const SimilarityLevel = ({ dataHcName, similarityLevel, className }: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(
        className,
        styles.SimilarityLevel,
        styles[similarityLevel]
      )}
    >
      <SimilarityLevelDot />
      <SimilarityLevelDot />
      <SimilarityLevelDot />
    </div>
  );
};

export default SimilarityLevel;
