import get from 'lodash/get';

import { TOAST_SHOW, TOAST_HIDE } from 'actions/toast';

const INITIAL_STATE = {
  open: false,
  message: '',
  messageId: null,
  dataHcName: null
};

export default function toastReducer(state = INITIAL_STATE, action) {
  if (get(action, ['meta', 'toast', 'message'], false)) {
    return {
      open: true,
      message: action.meta.toast.message,
      dataHcName: action.meta.toast.dataHcName,
      messageId: new Date().getTime()
    };
  }
  switch (action.type) {
    case TOAST_SHOW:
      return {
        open: true,
        message: action.payload.message,
        messageId: action.payload.messageId,
        dataHcName: action.payload.dataHcName
      };

    case TOAST_HIDE:
      return INITIAL_STATE;

    default:
      return state;
  }
}
