import { createSelector } from 'reselect';

export const getErrorState = (state) => state.error;

export const getErrorModalIsOpen = createSelector(
  getErrorState,
  (errorState) => errorState.isOpen
);

export const getErrorModalMessage = createSelector(
  getErrorState,
  (errorState) => errorState.message
);

export const getErrorModalHelpLink = createSelector(
  getErrorState,
  (errorState) => errorState.helpLink
);

export const getErrorModalHelpLinkLabel = createSelector(
  getErrorState,
  (errorState) => errorState.helpLinkLabel
);

export const getErrorModalMessageSecondary = createSelector(
  getErrorState,
  (errorState) => errorState.messageSecondary
);

export const getErrorModalActionType = createSelector(
  getErrorState,
  (errorState) => errorState.actionType
);

export const getErrorModalActionLabel = createSelector(
  getErrorState,
  (errorState) => errorState.actionLabel
);

export const getErrorModalIsDismissible = createSelector(
  getErrorState,
  (errorState) => errorState.dismissible
);
