import { call } from 'redux-saga/effects';
import graphqlRequest from 'legacy/services/graphql';

import { QUERIES } from 'legacyGraphQL/constants';

export const propertyFeaturesQuery = ({ addressId, source }) => {
  return {
    key: QUERIES.PROPERTY_FEATURES,
    query: `
    propertyFeatures(addressId: ${addressId}, source: ${source}) {
      source
      grossLivingAreaSqft
      siteAreaSqft
      bedrooms
      bathrooms
      stories
      propertyType
      subdivisionName
      isOwnerOccupied
      yearBuilt
      basement
      pool
      garageType
      garageNumCars
      condition
      zoning
      hoaName
      hoaFee
      hoaFeeFrequency
      hoaIncludes
      taxYear
      taxAmount
      daysOnMarket
      cumulativeDaysOnMarket
    }
    `
  };
};

const fetchPropertyFeatures = function* (addressId, source) {
  const query = propertyFeaturesQuery(addressId, source);
  return yield call(graphqlRequest, { source, query });
};

export default fetchPropertyFeatures;
