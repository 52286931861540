import MultiSelect from 'legacy/components/generic/MultiSelect';

const FilterMultiSelect = ({
  absoluteValue,
  relativeValue,
  onChangeAbsolute,
  ...inputProps
}) => {
  return (
    <MultiSelect
      {...inputProps}
      value={absoluteValue}
      onChange={onChangeAbsolute}
    />
  );
};

export default FilterMultiSelect;
