import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './RadioButton.css';

interface Props<T> {
  dataHcName: string;
  label: ReactNode;
  value: T;
  checked: boolean;
  onChange: (value: T) => void;
  disabled?: boolean;
  className?: string;
}
export const RadioButton = <T extends string | number | null>({
  dataHcName,
  disabled,
  label,
  value,
  onChange,
  className,
  checked
}: Props<T>) => {
  return (
    <div
      data-hc-name={dataHcName}
      onClick={disabled ? undefined : () => onChange(value)}
      className={classNames(styles.RadioButton, className, {
        [styles.disabled || '']: disabled
      })}
    >
      <input type="radio" checked={checked} readOnly />
      <div className={styles.Radio}>
        <div />
      </div>
      <span data-hc-name={`${dataHcName}-label`}>{label}</span>
    </div>
  );
};
