import { EVENTS } from 'legacy/appstore/events';

export const CHANGE_GLOSSARY_SELECTED = 'CHANGE_GLOSSARY_SELECTED';
export const SHOW_HINT_TEXT = 'SHOW_HINT_TEXT';
export const SHOW_ONE_TIME_HINT_TEXT = 'SHOW_ONE_TIME_HINT_TEXT';
export const CLEAR_HINT_TEXT = 'CLEAR_HINT_TEXT';

export const changeGlossarySelected = (items) => ({
  type: CHANGE_GLOSSARY_SELECTED,
  payload: { items: items || [] },
  meta:
    items && items.length
      ? {
          analytics: {
            event: EVENTS.GLOSSARY_OPEN,
            props: { glossary_items: items }
          }
        }
      : {}
});

export const showHintText = (hintTextKey) => ({
  type: SHOW_HINT_TEXT,
  payload: { hintTextKey }
});

export const showOneTimeHintText = (hintTextKey) => ({
  type: SHOW_ONE_TIME_HINT_TEXT,
  payload: { hintTextKey }
});

export const clearHintText = () => ({
  type: CLEAR_HINT_TEXT
});
