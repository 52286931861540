import React from 'react';
import { STATUSES } from 'src/constants';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { useDocument } from '../../hooks';
import { CompDocument } from '../../types';
import { CompSchema } from '../../types/schemas';
import { CompPropertyCard } from '../CompPropertyCard';
import styles from './CompCompare.css';

interface Props {
  dataHcName: string;
  reportId: number;
  loading?: boolean;
  compSchema?: CompSchema;
  documentId?: string;
}

const CompCompareCol = ({
  dataHcName,
  compSchema,
  reportId,
  documentId,
  loading
}: Props) => {
  return (
    <div data-hc-name={dataHcName} className={styles.CompareCol}>
      {!loading && compSchema ? (
        <>
          <CompPropertyCard
            dataHcName={`${dataHcName}-property-card`}
            reportId={reportId}
            documentId={documentId}
            compSchema={compSchema}
            clickToCompare={false}
          />
        </>
      ) : (
        <LoadingSpinner dataHcName={`${dataHcName}-loading`} />
      )}
    </div>
  );
};

interface SelectedCompColProps {
  dataHcName: string;
  reportId: number;
  documentId: string;
}
export const SelectedCompCol = ({
  dataHcName,
  reportId,
  documentId
}: SelectedCompColProps) => {
  const { status, data } = useDocument<CompDocument>(reportId, documentId);
  return (
    <CompCompareCol
      reportId={reportId}
      loading={!status || status === STATUSES.LOADING}
      dataHcName={dataHcName}
      documentId={documentId}
      compSchema={data?.data}
    />
  );
};

interface UnselectedCompColProps {
  dataHcName: string;
  reportId: number;
  compSchema: CompSchema;
}
export const UnselectedCompCol = ({
  dataHcName,
  reportId,
  compSchema
}: UnselectedCompColProps) => {
  return (
    <CompCompareCol
      reportId={reportId}
      loading={false}
      dataHcName={dataHcName}
      compSchema={compSchema}
    />
  );
};
