import { createSelector } from 'reselect';

import { getCompsListViewType } from 'selectors/comps';
import { getRentalCompsListViewType } from 'selectors/rental-comps';

export const getRouteQueryCompsPages = createSelector(
  getCompsListViewType,
  (viewType) => {
    return {
      viewType
    };
  }
);

export const getRouteQueryRentalCompsPages = createSelector(
  getRentalCompsListViewType,
  (viewType) => {
    return {
      viewType
    };
  }
);
