import { connect } from 'react-redux';

import { login, googleLogin, googleLoginError } from 'actions/auth';
import { getAuthErrorMessage, getAuthStatus } from 'selectors/auth';

import Login from 'legacy/components/Login';

const mapStateToProps = (state, ownProps) => {
  return {
    error: getAuthErrorMessage(state),
    status: getAuthStatus(state),
    handleSignupClick: ownProps.handleSignupClick,
    insideModal: ownProps.insideModal
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleLogin: (credentials) => {
      dispatch(
        login(
          credentials,
          ownProps.routeAfterLoginSuccess,
          ownProps.afterAuthAction
        )
      );
    },
    handleGoogleLogin: (googleUserIdToken) =>
      dispatch(googleLogin(googleUserIdToken)),
    handleGoogleLoginError: (error) => dispatch(googleLoginError(error))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
