import {
  PERSONALIZATION_DATA_SUCCESS,
  PERSONALIZATION_DATA_FAILURE,
  PERSONALIZATION_DATA_UPDATE_SUCCESS,
  PERSONALIZATION_MODAL_CLOSE
} from 'actions/personalization';

export default (state, action) => {
  // Personalization data can be loaded before the redux-form reducer
  if (!state) {
    return state;
  }
  // This allows values to be loaded asynchronously after form component mounts
  switch (action.type) {
    case PERSONALIZATION_DATA_SUCCESS:
    case PERSONALIZATION_DATA_FAILURE: // failure replaces vals from account when profile doesn't exist
    case PERSONALIZATION_DATA_UPDATE_SUCCESS:
      // Set both values and initial so the form is not dirty when initial values are loaded
      return {
        ...state,
        initial: {
          ...state.initial,
          ...action.payload.values
        },
        values: {
          ...state.values,
          ...action.payload.values
        }
      };

    case PERSONALIZATION_MODAL_CLOSE:
      return {
        ...state,
        initial: {
          ...state.initial
        },
        values: {
          ...state.initial
        }
      };

    default:
      return state;
  }
};
