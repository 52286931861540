import isEmpty from 'lodash/isEmpty';
import Raven from 'raven-js';
import { apply, call, put, select } from 'redux-saga/effects';
import { getAuthClient } from '@hc/authentication-lib/hclib/clients';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { STATUSES } from 'legacy/appstore/constants';
import HC_CONSTANTS from 'HC_CONSTANTS';
import { VIEWS } from 'legacy/routes/constants';

import { watchEvery } from 'legacy/utils/saga';
import { toCamelCase } from 'legacy/utils/transform';

import mixpanelService from 'legacy/services/mixpanel';

import { resetAppState } from 'legacy/appstore/clear-reducer-on-actions';
import { updateUserContextCallback } from 'legacy/utils/refresh-token-callback';
import {
  TOKEN_EXPIRED,
  LOGIN,
  GOOGLE_LOGIN,
  loginError,
  loginPromptHide,
  loginPromptShowSignup,
  loginSuccess,
  authRedirectFromLogin,
  OPTIONAL_SSO
} from 'actions/auth';
import { putAccountDetailsOnState } from 'actions/account-details';
import { fetchRecentReports } from 'actions/recent-reports';
import { personalizationDataRequest } from 'actions/personalization';
import { preferencesFetch } from 'actions/preferences';

import { getIsLoggedIn, getAuthLoginRedirect } from 'selectors/auth';
import { getPreferencesIsLoaded } from 'selectors/preferences';
import {
  getUserIsSelfService,
  getUserIsConfirmed
} from 'selectors/account-details';
import { getFeatureFlagsStatus } from '../selectors/feature-flags.selectors';
import { handleFeatureFlagsFetch } from './feature-flags.saga';

const authUrl = `${HC_CONSTANTS.AUTH_LIB_URL}/`;

export function* tokenExpiredSaga(action) {
  try {
    // NOTE: Do NOT call logout() here as token is inValid it goes into infinite loop
    // TODO: Clear cookies
    yield apply(mixpanelService, mixpanelService.clearCookies);
    yield call([Raven, Raven.setUserContext]);
    yield put(routeChange({ view: VIEWS.LOGIN, options: { inherit: false } }));
    yield put(resetAppState());
  } catch (e) {
    // reload the app and take them to login page
    yield put(routeChange({ view: VIEWS.LOGIN, options: { inherit: false } }));
    yield put(resetAppState());
  }
}

function* _loginSuccess(
  userContext,
  routeAfterLoginSuccess,
  actionAfterLoginSuccess
) {
  yield apply(mixpanelService, mixpanelService.loginCallback, [userContext]);
  yield put(loginSuccess(userContext));
  const featureFlagStatus = yield select(getFeatureFlagsStatus);
  if (featureFlagStatus !== STATUSES.SUCCESS) {
    yield call(handleFeatureFlagsFetch);
  }
  yield call([Raven, Raven.setUserContext], userContext);
  if (actionAfterLoginSuccess) {
    yield put(actionAfterLoginSuccess());
  }

  const loginRedirect = yield select(getAuthLoginRedirect);
  if (!isEmpty(loginRedirect)) {
    yield put(
      authRedirectFromLogin(
        loginRedirect.view,
        loginRedirect.params,
        loginRedirect.query
      )
    );
  } else if (routeAfterLoginSuccess) {
    yield put(
      routeChange({ view: routeAfterLoginSuccess, options: { inherit: false } })
    );
  }
  const preferencesLoaded = yield select(getPreferencesIsLoaded);
  if (!preferencesLoaded) {
    yield put(preferencesFetch());
  }
  yield put(personalizationDataRequest());
  yield put(fetchRecentReports());
  /* Doesn't hurt to do this always, even if we're using the page instead of the modal */
  yield put(loginPromptHide());
}

function* handleLogin(action) {
  const {
    credentials: { username, password },
    routeAfterLoginSuccess,
    actionAfterLoginSuccess
  } = action.payload;
  try {
    const authClient = getAuthClient(
      HC_CONSTANTS.LOGIN_API_URL,
      authUrl,
      updateUserContextCallback
    );

    // auth lib sets logged in user in storage
    const userContext = toCamelCase(
      yield call([authClient, authClient.login], { username, password })
    );
    yield call(
      _loginSuccess,
      userContext,
      routeAfterLoginSuccess,
      actionAfterLoginSuccess
    );
  } catch (e) {
    /** TODO change authClient to give us an error code and report to Sentry if unexpected error code */
    yield put(loginError(e.message || 'Unable to login'));
    console.error(e);
  }
}

function* handleGoogleLogin(action) {
  try {
    const { googleUserIdToken } = action.payload;
    const authClient = getAuthClient(
      HC_CONSTANTS.LOGIN_API_URL,
      authUrl,
      updateUserContextCallback
    );

    const userContext = toCamelCase(
      yield call([authClient, authClient.googleLogin], googleUserIdToken)
    );
    yield call(_loginSuccess, userContext, VIEWS.SEARCH);
  } catch (e) {
    /** TODO change authClient to give us an error code and report to Sentry if unexpected error code */
    yield put(loginError(e.message || 'Unable to login'));
    console.error(e);
  }
}

/**
 * Dispatch a callback action either immediately or, if not logged in, after a successful login
 * @param {string} callbackAction - action to dispatch after confirming user is authenticated
 */
export function* ensureLoggedInThen(callbackAction) {
  const isLoggedIn = yield select(getIsLoggedIn);

  if (!isLoggedIn) {
    yield put(loginPromptShowSignup(callbackAction));
  } else {
    yield put(callbackAction());
  }
}

export function* redirectToPaymentIfNotConfirmed(route) {
  const userIsSelfService = yield select(getUserIsSelfService);
  const userIsConfirmed = yield select(getUserIsConfirmed);
  if (
    ![VIEWS.SELECT_PACKAGE, VIEWS.CONFIRM_USER].includes(route?.view) &&
    userIsSelfService &&
    !userIsConfirmed
  ) {
    yield put(routeChange({ view: VIEWS.SELECT_PACKAGE }));
  }
}

/**
 * Look in redux store to determine if user is logged in already.
 * @throws Error when fails to load user
 */
export function* singleSignOn(route) {
  const featureFlagStatus = yield select(getFeatureFlagsStatus);
  const isLoggedIn = yield select(getIsLoggedIn);
  if (isLoggedIn) {
    yield call(redirectToPaymentIfNotConfirmed, route);
    return true;
  }
  try {
    const authClient = getAuthClient(
      HC_CONSTANTS.LOGIN_API_URL,
      authUrl,
      updateUserContextCallback
    );
    const userContext = toCamelCase(
      yield apply(authClient, authClient.getUserContext)
    );
    if (!userContext || !userContext.hasOwnProperty('user')) {
      console.error('PEXP: userContext was not returned', userContext);
      return false;
    }
    yield put(putAccountDetailsOnState(userContext));
    if (featureFlagStatus !== STATUSES.SUCCESS) {
      yield call(handleFeatureFlagsFetch);
    }
    yield apply(mixpanelService, mixpanelService.loginCallback, [userContext]);
    yield call([Raven, Raven.setUserContext], userContext.user);
    yield call(redirectToPaymentIfNotConfirmed, route);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export function* optionalSingleSignOn(route) {
  const featureFlagStatus = yield select(getFeatureFlagsStatus);
  const isLoggedIn = yield select(getIsLoggedIn);
  if (isLoggedIn) {
    return;
  }
  try {
    const authClient = getAuthClient(
      HC_CONSTANTS.LOGIN_API_URL,
      authUrl,
      updateUserContextCallback
    );
    const userContext = toCamelCase(
      yield apply(authClient, authClient.getUserContext)
    );
    if (!userContext || !userContext.hasOwnProperty('user')) {
      return;
    }
    yield put(putAccountDetailsOnState(userContext));
    if (featureFlagStatus !== STATUSES.SUCCESS) {
      yield call(handleFeatureFlagsFetch);
    }
    yield apply(mixpanelService, mixpanelService.loginCallback, [userContext]);
    yield call([Raven, Raven.setUserContext], userContext.user);
    return;
  } catch (e) {
    console.error(e);
    return;
  }
}

export function registerAuthSaga() {
  watchEvery({
    [TOKEN_EXPIRED]: tokenExpiredSaga,
    [LOGIN]: handleLogin,
    [GOOGLE_LOGIN]: handleGoogleLogin,
    [OPTIONAL_SSO]: optionalSingleSignOn
  });
}
