import { watchEvery } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { call, delay, put, select } from 'redux-saga/effects';
import get from 'lodash/get';

import HC_CONSTANTS from 'HC_CONSTANTS';
import apiUtil from 'legacy/utils/api';

import {
  SUBMIT_FORGOT_PASSWORD,
  VERIFY_RESET_PASSWORD,
  SUBMIT_PASSWORD_RESET,
  submitForgotPasswordError,
  submitForgotPasswordSuccess,
  verifyResetPasswordSuccess,
  verifyResetPasswordFailure,
  verifyResetPasswordExpired,
  submitPasswordResetSuccess,
  submitPasswordResetError
} from 'actions/forgot-password';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { VIEWS } from 'legacy/routes/constants';

const { AUTH_URL, RESET_PASSWORD_URL } = HC_CONSTANTS;

/**
 * Submit forgot password with email
 */
function* handleSubmitForgotPassword(action) {
  try {
    const { email } = action.payload;
    const response = yield call(
      apiUtil.POST,
      `${AUTH_URL}/forgot-password`,
      {
        email,
        resetPasswordUrl: RESET_PASSWORD_URL
      },
      { expect404: true }
    );

    if (response.statusCode === 404) {
      yield put(submitForgotPasswordError('User/Email was not found'));
    } else {
      yield put(submitForgotPasswordSuccess(response.message));
    }
  } catch (e) {
    yield put(
      submitForgotPasswordError(
        get(e, 'messageRaw', 'We could not verify your email')
      )
    );
    // Rethrow for Sentry reporting
    throw e;
  }
}

/**
 * Verify reset password token which is included in the URL
 * of the email generated by the server.
 *
 * Once the token has been verified, set the token on state
 * so the temporary token can be used when submitting new password.
 */
function* handleVerifyResetPassword(action) {
  try {
    const { token } = action.payload;

    if (!token) {
      throw new Error(
        'Token was not supplied. Please make sure you copied the reset password correctly.'
      );
    }

    yield call(apiUtil.POST, `${AUTH_URL}/check-reset-password-token`, {
      token
    });
    yield put(verifyResetPasswordSuccess(token));
  } catch (e) {
    console.error(e);
    const errorMessage = e.message;

    if (errorMessage === 'expired') {
      yield put(
        verifyResetPasswordExpired(
          `You're token to reset your password has expired. Please try again.`
        )
      );
    } else {
      yield put(verifyResetPasswordFailure(errorMessage));
    }
    // Rethrow for Sentry reporting
    throw e;
  }
}

/**
 * Submit new password
 *
 * If temporary token is not found on state, report to sentry
 * and show an error to user.
 */
function* handleSubmitPasswordReset(action) {
  try {
    const { newPassword } = action.payload;
    const temporaryToken = yield select(
      (state) => state.forgotPassword.temporaryToken
    );

    if (!temporaryToken) {
      report('Temporary Token was not found on state', action);
      throw new Error('Unable to reset your password. Please try again.');
    }

    yield call(apiUtil.POST, `${AUTH_URL}/confirm-reset-password`, {
      token: temporaryToken,
      newPassword: newPassword
    });

    yield put(submitPasswordResetSuccess('Password has been updated'));
    yield delay(500);

    yield put(routeChange({ view: VIEWS.LOGIN, options: { inherit: false } }));
  } catch (e) {
    yield put(submitPasswordResetError(e.message));
    // Rethrow for Sentry reporting
    throw e;
  }
}

export default function registerForgotPasswordSaga() {
  watchEvery({
    [SUBMIT_FORGOT_PASSWORD]: handleSubmitForgotPassword,
    [VERIFY_RESET_PASSWORD]: handleVerifyResetPassword,
    [SUBMIT_PASSWORD_RESET]: handleSubmitPasswordReset
  });
}
