import React from 'react';
import SVGInline from 'react-svg-inline';

import MapMarker from 'legacy/components/MapMarker';

import homeIcon from 'legacy/assets/svg-inline/home.svg';

import { SubjectIcon as theme } from 'legacy/css-modules';

const SubjectIcon = () => (
  <div className={theme.SubjectIconWrapper}>
    <MapMarker
      isHomeStyle
      isCircleStyle
      noHover
      label={<SVGInline className={theme.SubjectIcon} svg={homeIcon} />}
    />
  </div>
);

export default SubjectIcon;
