import {
  PROPERTY_SEARCH_REQUEST,
  PROPERTY_SEARCH_SUCCESS,
  PROPERTY_SEARCH_FAILURE,
  TOGGLE_MOBILE_BOTTOM_BAR,
  PROPERTY_SEARCH_HIDE_OVERLAY
} from 'actions/property-search';
import { GET_REPORT_HC_VERSION_SUCCESS } from 'actions/get-report';
import { MLS_LISTINGS_PROPERTY_PREVIEW } from 'actions/mls-listings';
import { LOAD_FULL_REPORT } from 'actions/full-report';
import { STATUSES } from 'legacy/appstore/constants';

const SEARCH_RESULT_INITIAL_STATE = {
  status: STATUSES.INIT,
  isAttemptingLoadFullReport: false
};

export function propertyResultReducer(
  state = SEARCH_RESULT_INITIAL_STATE,
  action
) {
  switch (action.type) {
    case PROPERTY_SEARCH_REQUEST:
      return {
        ...state,
        status:
          state.status === STATUSES.PARTIAL
            ? STATUSES.PARTIAL
            : STATUSES.LOADING
      };
    case PROPERTY_SEARCH_SUCCESS:
      const propertyResult = action.payload;
      return {
        ...propertyResult,
        isSearchResult: true,
        status: STATUSES.SUCCESS,
        isAttemptingLoadFullReport: false
      };
    case PROPERTY_SEARCH_FAILURE:
      return {
        ...state,
        status: STATUSES.ERROR
      };
    case LOAD_FULL_REPORT:
      return {
        ...state,
        isAttemptingLoadFullReport: true
      };

    case GET_REPORT_HC_VERSION_SUCCESS:
      return {
        ...state,
        isAttemptingLoadFullReport: false
      };

    case MLS_LISTINGS_PROPERTY_PREVIEW:
      return {
        ...state,
        ...action.payload.property,
        status: STATUSES.PARTIAL
      };

    default:
      return state;
  }
}

export function mobileBottomBarReducer(state = true, action) {
  switch (action.type) {
    case TOGGLE_MOBILE_BOTTOM_BAR:
      return !state;
    default:
      return state;
  }
}

const LANDING_PAGE_INITIAL_STATE = {
  isShowingOverlay: true
};

export function landingPageReducer(state = LANDING_PAGE_INITIAL_STATE, action) {
  switch (action.type) {
    case PROPERTY_SEARCH_HIDE_OVERLAY:
      return {
        ...state,
        isShowingOverlay: false
      };
    default:
      return state;
  }
}
