import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import { isArray, isObject, isFunc, isString } from 'legacy/utils/utils';
import { toSnakeCase } from 'legacy/utils/transform';

import {
  PERFORMANCE_MEASUREMENT,
  performanceMeasurement
} from 'actions/analytics';
import { COMPS_FARM_UPDATE_SUCCESS } from 'actions/comps';
import {
  GET_REPORT,
  GET_REPORT_HC_VERSION_SUCCESS,
  GET_REPORT_HC_VERSION_FAILURE,
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_USER_VERSION_NOT_FOUND,
  GET_REPORT_RENTAL_SUCCESS,
  GET_REPORT_RENTAL_FAILURE,
  GET_REPORT_FORECAST,
  GET_REPORT_FORECAST_SUCCESS,
  GET_REPORT_FORECAST_FAILURE,
  GET_REPORT_SHARED,
  GET_REPORT_SUBJECT_DETAILS_SUCCESS
} from 'actions/get-report';
import { RENTAL_COMPS_FARM_UPDATE_SUCCESS } from 'actions/rental-comps';
// Dynamic Prop Helpers
const _dynamicPropsRentalReport = (action) =>
  action.type === GET_REPORT_RENTAL_SUCCESS ||
  action.type === RENTAL_COMPS_FARM_UPDATE_SUCCESS
    ? { hasRentalReport: true }
    : { hasRentalReport: false };

const _dynamicPropsNewReport = (action) =>
  action.type === GET_REPORT_USER_VERSION_NOT_FOUND ||
  action.type === COMPS_FARM_UPDATE_SUCCESS
    ? { isNewReport: true }
    : { isNewReport: false };

const _dynamicPropsDelayBeforeRequest = (action, progress) => ({
  delayBeforeRequest: window.performance.now() - progress.startTime
});

const _getActionType = (t) => (isString(t) ? t : t.action);

const _defAvm = (name, desc, start = GET_REPORT) => ({
  name,
  desc,
  start,
  end: [
    [
      // Get HC AVM
      GET_REPORT_HC_VERSION_SUCCESS,
      GET_REPORT_SUBJECT_DETAILS_SUCCESS
    ],
    [
      // Get User AVM
      {
        action: GET_REPORT_USER_VERSION_SUCCESS,
        propCallback: _dynamicPropsNewReport
      },
      {
        action: GET_REPORT_USER_VERSION_NOT_FOUND,
        propCallback: _dynamicPropsNewReport
      }
    ]
  ],
  cancel: [GET_REPORT_HC_VERSION_FAILURE]
});

const _defSubject = (name, desc, start = GET_REPORT) => ({
  name,
  desc,
  start,
  end: [
    [
      // Get HC AVM
      GET_REPORT_HC_VERSION_SUCCESS,
      GET_REPORT_SUBJECT_DETAILS_SUCCESS
    ],
    [
      // Get User AVM
      {
        action: GET_REPORT_USER_VERSION_SUCCESS,
        endOnDispatch: true, // This request has all required data
        propCallback: _dynamicPropsNewReport
      },
      {
        action: GET_REPORT_USER_VERSION_NOT_FOUND,
        propCallback: _dynamicPropsNewReport
      }
    ]
  ],
  cancel: [GET_REPORT_HC_VERSION_FAILURE]
});

const _defComps = (name, desc, start = GET_REPORT) => ({
  name,
  desc,
  start,
  end: [
    [
      {
        action: GET_REPORT_USER_VERSION_SUCCESS,
        endOnDispatch: true, // This request has all required data
        propCallback: _dynamicPropsNewReport
      },
      {
        action: COMPS_FARM_UPDATE_SUCCESS,
        propCallback: _dynamicPropsNewReport
      }
    ]
  ],
  cancel: [GET_REPORT_HC_VERSION_FAILURE]
});

const _defMarketAnalysis = (name, desc, start = GET_REPORT) => ({
  name,
  desc,
  start,
  end: [GET_REPORT_HC_VERSION_SUCCESS],
  cancel: [GET_REPORT_HC_VERSION_FAILURE]
});

const _defRentalAvm = (name, desc, start = GET_REPORT) => ({
  name,
  desc,
  start,
  end: [
    [
      {
        action: GET_REPORT_USER_VERSION_SUCCESS,
        endOnDispatch: true
      },
      GET_REPORT_USER_VERSION_NOT_FOUND
    ],
    [GET_REPORT_RENTAL_SUCCESS, GET_REPORT_RENTAL_FAILURE]
  ],
  cancel: [GET_REPORT_HC_VERSION_FAILURE]
});

const _defRentalComps = (name, desc, start = GET_REPORT) => ({
  name,
  desc,
  start,
  end: [
    [
      {
        action: GET_REPORT_USER_VERSION_SUCCESS,
        endOnDispatch: true
      },
      GET_REPORT_USER_VERSION_NOT_FOUND
    ],
    [GET_REPORT_RENTAL_SUCCESS, RENTAL_COMPS_FARM_UPDATE_SUCCESS]
  ],
  cancel: [GET_REPORT_HC_VERSION_FAILURE]
});

const _defForecast = (name, desc, start = GET_REPORT) => ({
  name,
  desc,
  start,
  end: [
    {
      action: GET_REPORT_FORECAST,
      propCallback: _dynamicPropsDelayBeforeRequest
    },
    GET_REPORT_FORECAST_SUCCESS
  ],
  cancel: [GET_REPORT_FORECAST_FAILURE]
});

const _defFullReport = (name, desc, start = GET_REPORT) => ({
  name,
  desc,
  // Actions that will start measurement
  start,
  // All actions that need to dispatch to end measurement
  end: [
    [GET_REPORT_HC_VERSION_SUCCESS, GET_REPORT_SUBJECT_DETAILS_SUCCESS],
    // Either one of these actions must be dispatched
    [
      {
        action: GET_REPORT_USER_VERSION_SUCCESS,
        // Will add a prop to the event if/when this action is dispatched
        // Should return a prop mapping
        propCallback: _dynamicPropsNewReport
      },
      {
        action: COMPS_FARM_UPDATE_SUCCESS,
        propCallback: _dynamicPropsNewReport
      }
    ],
    [
      {
        action: GET_REPORT_RENTAL_SUCCESS,
        propCallback: _dynamicPropsRentalReport
      },
      {
        action: RENTAL_COMPS_FARM_UPDATE_SUCCESS,
        propCallback: _dynamicPropsRentalReport
      },
      GET_REPORT_USER_VERSION_SUCCESS
    ],
    [GET_REPORT_FORECAST_SUCCESS, GET_REPORT_FORECAST_FAILURE]
  ],
  // Actions that will cancel measurement
  cancel: [GET_REPORT_HC_VERSION_FAILURE]
});

// Defines different measurements, will be transformed to a different format in the middleware
const PERFORMANCE_MEASUREMENTS = [
  _defAvm('avm', 'AVM Value for subject can be shown'),
  _defSubject('subject_details', 'Subject property details can be shown'),
  _defComps('comps', 'Comps can be shown'),
  _defMarketAnalysis('market_analysis', 'Market analysis section can be shown'),
  _defRentalAvm('rental_avm', 'Rental avm value can be shown'),
  _defRentalComps('rental_comps', 'Rental comps can be shown'),
  _defForecast('forecast', 'Forecast chart can be shown'),
  _defFullReport(
    'full_report',
    "All report content loaded (doesn't take photos into account)"
  ),
  // Shared Reports
  _defAvm(
    'avm_shared',
    'AVM Value for subject can be shown on shared reports',
    GET_REPORT_SHARED
  ),
  _defSubject(
    'subject_details_shared',
    'Subject property details can be shown on shared reports',
    GET_REPORT_SHARED
  ),
  _defComps(
    'comps_shared',
    'Comps can be shown on shared reports',
    GET_REPORT_SHARED
  ),
  _defMarketAnalysis(
    'market_analysis_shared',
    'Market analysis section can be shown on shared reports',
    GET_REPORT_SHARED
  ),
  _defRentalAvm(
    'rental_avm_shared',
    'Rental avm value can be shown on shared reports',
    GET_REPORT_SHARED
  ),
  _defComps(
    'rental_comps_shared',
    'Rental comps can be shown on shared reports',
    GET_REPORT_SHARED
  ),
  _defForecast(
    'forecast_shared',
    'Forecast chart can be shown on shared reports',
    GET_REPORT_SHARED
  ),
  _defFullReport(
    'full_report_shared',
    "All report content loaded (doesn't take photos into account) for shared reports",
    GET_REPORT_SHARED
  )
];

const _transformPropCallbacks = (actionTypes) => {
  const propCallbacks = {};
  if (actionTypes) {
    actionTypes.forEach((type) => {
      if (isObject(type) && isFunc(type.propCallback)) {
        propCallbacks[_getActionType(type)] = type.propCallback;
      } else if (isArray(type)) {
        type.forEach((t) => {
          if (isObject(t) && isFunc(t.propCallback)) {
            propCallbacks[_getActionType(t)] = t.propCallback;
          }
        });
      }
    });
  }
  return propCallbacks;
};

const _transformConfigTypeMapping = (actionTypes) => {
  const typeMapping = {};
  if (actionTypes) {
    actionTypes.forEach((type) => {
      if (isString(type)) {
        typeMapping[type] = type;
      } else if (isObject(type)) {
        const actionType = _getActionType(type);
        typeMapping[actionType] = actionType;
      } else if (isArray(type)) {
        type.forEach((t, i) => {
          const actionType = _getActionType(t);
          // Set value to array of all action types not including itself
          typeMapping[actionType] = type
            .slice(0, i)
            .concat(type.slice(i + 1, type.length))
            .map(_getActionType);
        });
      }
    });
  }
  return typeMapping;
};

const _transformConfig = (input) => {
  // Transforms to this format to prevent unnecesary loops:
  // {
  //   GET_REPORT: [{
  //     name: 'full_report_load',
  //     propCallbacks: {},
  //     end: {
  //       GET_REPORT_HC_VERSION_SUCCESS,
  //       GET_REPORT_USER_VERSION_SUCCESS: [GET_REPORT_USER_VERSION_NOT_FOUND],
  //       GET_REPORT_USER_VERSION_NOT_FOUND: [GET_REPORT_USER_VERSION_SUCCESS],
  //       GET_REPORT_RENTAL_SUCCESS: [GET_REPORT_RENTAL_FAILURE],
  //       GET_REPORT_RENTAL_FAILURE: [GET_REPORT_RENTAL_SUCCESS]
  //     },
  //     cancel: {
  //       GET_REPORT_HC_VERSION_FAILURE
  //     }
  //   }]
  // }
  const config = {};
  input.forEach((measurement) => {
    if (measurement.start) {
      if (!config[measurement.start]) {
        config[measurement.start] = [];
      }
      config[measurement.start].push({
        ...measurement,
        propCallbacks: _transformPropCallbacks(measurement.end),
        end: _transformConfigTypeMapping(measurement.end),
        cancel: _transformConfigTypeMapping(measurement.cancel)
      });
    } else {
      console.warn(
        `Performance event ${measurement.name} does not have a properly configured start action.`
      );
    }
  });
  return config;
};

const performanceTrackingMiddleware = () => {
  const CONFIG = _transformConfig(PERFORMANCE_MEASUREMENTS);
  const inProgress = {};
  return (store) => (next) => (action) => {
    if (action && action.type && action.type !== PERFORMANCE_MEASUREMENT) {
      const { type } = action;
      if (!isEmpty(inProgress)) {
        const toDelete = {};
        // Check all running performance measurements
        for (let e in inProgress) {
          // Check if the action should cancel the measurement
          const cancel = inProgress[e].cancel[type];
          // Check if action is one that must be completed
          const end = inProgress[e].end[type];
          let endOnDispatch = false;
          if (cancel) {
            // TODO: Only supports cancelling when it sees any cancel action
            toDelete[e] = true;
          } else if (end) {
            if (isArray(end)) {
              // Remove all related actions from end actions
              end.forEach((d) => {
                delete inProgress[e].end[d];
              });
            }
            // Add dynamicProps
            if (inProgress[e].propCallbacks[type]) {
              inProgress[e].dynamicProps = {
                ...inProgress[e].dynamicProps,
                // Call propCallback with action as argument
                ...inProgress[e].propCallbacks[type](action, inProgress[e])
              };
            }
            if (inProgress[e].end[type].endOnDispatch) {
              endOnDispatch = true;
            }
            // Remove from end actions
            delete inProgress[e].end[type];
          }

          if (endOnDispatch || (!toDelete[e] && isEmpty(inProgress[e].end))) {
            const { name, desc, dynamicProps } = inProgress[e];
            const props = toSnakeCase({
              ...dynamicProps,
              duration: window.performance.now() - inProgress[e].startTime,
              desc
            });
            // This must be deleted before dispatching measurement action to prevent infinite loop
            toDelete[e] = true;
            // Send result to mixpanel and kinesis using the analytics middleware
            store.dispatch(performanceMeasurement(name, props));
          }
        }
        // Delete cancelled and completed events
        Object.keys(toDelete).forEach((e) => {
          delete inProgress[e];
        });
      }
      if (CONFIG[type]) {
        // TODO: Currently only 1 start action can be defined. Need to think about how to support more.
        // Mark tracking as in progress
        CONFIG[type].forEach((a) => {
          inProgress[a.name] = cloneDeep(a);
          inProgress[a.name].startTime = window.performance.now();
          inProgress[a.name].dynamicProps = {};
        });
      }
    }
    return next(action);
  };
};

export default performanceTrackingMiddleware;
