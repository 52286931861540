import { createSelector } from 'reselect';

export const getArchivedReportsState = (state) => state.archivedReports;

export const getArchivedReports = (state) =>
  getArchivedReportsState(state).reports;
export const getArchivedReportsFromReportsState = (reportsState) =>
  reportsState.reports;
export const getArchivedReportsStatus = (state) =>
  getArchivedReportsState(state).status;
export const getArchivedReportsIsOpen = (state) =>
  getArchivedReportsState(state).open;
export const getArchivedReportsFilteredIds = (state) =>
  getArchivedReportsState(state).filteredIds;
export const getArchivedReportsSearchQuery = (state) =>
  getArchivedReportsState(state).searchQuery;
export const getArchivedReportsSearchQueryFromReportsState = (reportsState) =>
  reportsState.searchQuery;

export const getArchivedReportsTableColumns = () => {
  return [
    { label: 'Street Address', key: 'address', width: 180 },
    { label: 'City/Town', key: 'city', width: 120 },
    { label: 'State', key: 'state', width: 40 },
    { label: 'Zip', key: 'zipcode', width: 60 }
  ];
};
export const getArchivedReportsFiltered = createSelector(
  getArchivedReports,
  getArchivedReportsFilteredIds,
  (reports, filteredIds) => filteredIds.map((id) => reports[id])
);
