import { createSelector } from 'reselect';
import { STATUSES } from 'src/constants';

import { createFactorySelector } from 'src/lib/utils';
import { StateWithStreetView, STREET_VIEW_REDUCER_KEY } from '../reducer';
import { createStreetViewIdFromLatLng } from '../utils/streetView.utils';

export const selStreetViewState = (state: StateWithStreetView) =>
  state[STREET_VIEW_REDUCER_KEY];

export const selStreetViewStateForLatLng = createFactorySelector(
  (latLng: [number, number]) =>
    createSelector(selStreetViewState, (streetViewState) => {
      return streetViewState[createStreetViewIdFromLatLng(latLng)];
    })
);

export const selStreetViewMetadata = createFactorySelector(
  (latLng: [number, number]) =>
    createSelector(selStreetViewStateForLatLng(latLng), (streetViewState) => {
      if (streetViewState?.status === STATUSES.SUCCESS) {
        return streetViewState.data;
      } else {
        return undefined;
      }
    })
);

export const selStreetViewStatus = createFactorySelector(
  (latLng: [number, number]) =>
    createSelector(selStreetViewStateForLatLng(latLng), (streetViewState) => {
      return streetViewState?.status;
    })
);
