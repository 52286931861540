import React from 'react';
import { SingleColumnContent } from 'src/pexp/layout';
import { SubjectMapBanner } from 'src/lib/report-api/features/SubjectMapBanner';
import { ReportValue } from '../../features/ReportValueSelector';
import styles from './EffectiveDateReportPage.css';
import { SubjectMainDetails } from 'src/lib/report-api/features';
import { SubjectCommentDialog } from 'src/lib/report-api/features/SubjectComment';
import { ShareReportDialog } from '../../features/ShareReport';
import { CompsSummary } from 'src/pexp/features/CompsSummary';
import { default as LazyRender } from 'react-lazyload';
import { SubjectDetailsSection } from '../../features/SubjectDetailsSection';
// @ts-ignore TODO: Update Global Nav from auth-lib
import LegacyGlobalNav from 'src/legacy/containers/global-nav.container.jsx';
import { VIEWS } from 'src/pexp/routes';
import { SectionDividerHorz } from 'src/lib/components/SectionDivider';
import { SubjectPublicRemarks } from 'src/lib/report-api/features/SubjectPublicRemarks';
import { CompCompareOverlayProvider } from 'src/lib/report-api/hooks/useCompCompareOverlay';
import { CobrandingHeader } from 'src/lib/report-api/features/CobrandingHeader';
interface Props {
  view: VIEWS.EFFECTIVE_DATE_REPORT;
  params: {
    reportId: number;
  };
  query: Record<string, never>;
}

export const EffectiveDateReportPage = ({ params: { reportId } }: Props) => {
  return (
    <CompCompareOverlayProvider reportId={reportId}>
      <LegacyGlobalNav />
      <CobrandingHeader
        reportId={reportId}
        dataHcName="cobranding-header"
        className={styles.CobrandingHeader}
      />
      <div>
        <SubjectMapBanner reportId={reportId} dataHcName="subject-map-banner" />
        <SingleColumnContent className={styles.Main}>
          <div className={styles.ValueSection}>
            <ReportValue reportId={reportId} dataHcName="report-value" />
          </div>
          <div className={styles.SummarySection}>
            <div className={styles.Actions}>
              <SubjectCommentDialog
                reportId={reportId}
                dataHcName="subject-comment"
              />
              <ShareReportDialog dataHcName="share-report" />
            </div>
            <SubjectMainDetails
              reportId={reportId}
              dataHcName="subject-main-details"
            />
            <SubjectPublicRemarks
              reportId={reportId}
              dataHcName="subject-public-remarks"
            />
          </div>
        </SingleColumnContent>
        <SectionDividerHorz dataHcName="divider-0" />
        <LazyRender
          key="subject-details-section"
          scrollContainer="#app-container"
          height={470}
          unmountIfInvisible
          resize
        >
          <SingleColumnContent>
            <SubjectDetailsSection
              reportId={reportId}
              dataHcName="subject-details-section"
            />
          </SingleColumnContent>
        </LazyRender>
        <SectionDividerHorz dataHcName="divider-1" />
        <LazyRender
          key="comp-summary-section"
          scrollContainer="#app-container"
          height={822}
          unmountIfInvisible
          resize
        >
          <SingleColumnContent>
            <CompsSummary reportId={reportId} />
          </SingleColumnContent>
        </LazyRender>
      </div>
    </CompCompareOverlayProvider>
  );
};
