import { Preferences } from '../types';

export const PREFERENCES_REQUIRED = 'huell/PREFERENCES_REQUIRED';
export const PREFERENCES_FETCH = 'huell/PREFERENCES_FETCH';
export const PREFERENCES_FETCH_SUCCESS = 'huell/PREFERENCES_FETCH_SUCCESS';
export const PREFERENCES_FETCH_ERROR = 'huell/PREFERENCES_FETCH_ERROR';

export const PREFERENCES_PATCH = 'PREFERENCES_PATCH';
export const PREFERENCES_PATCH_SUCCESS = 'PREFERENCES_PATCH_SUCCESS';
export const PREFERENCES_PATCH_FAILURE = 'PREFERENCES_PATCH_FAILURE';

export interface PreferencesRequiredAction {
  type: typeof PREFERENCES_REQUIRED;
}
export const preferencesRequired = (): PreferencesRequiredAction => {
  return {
    type: PREFERENCES_REQUIRED
  };
};

export interface PreferencesFetchAction {
  type: typeof PREFERENCES_FETCH;
}
export const preferencesFetch = (): PreferencesFetchAction => {
  return {
    type: PREFERENCES_FETCH
  };
};

export interface PreferencesFetchSuccessAction {
  type: typeof PREFERENCES_FETCH_SUCCESS;
  payload: {
    preferences: Preferences;
  };
}
export const preferencesFetchSuccess = (
  preferences: Preferences
): PreferencesFetchSuccessAction => {
  return {
    type: PREFERENCES_FETCH_SUCCESS,
    payload: { preferences }
  };
};

export interface PreferencesFetchErrorAction {
  type: typeof PREFERENCES_FETCH_ERROR;
}
export const preferencesFetchError = (): PreferencesFetchErrorAction => {
  return {
    type: PREFERENCES_FETCH_ERROR
  };
};

export interface PreferencesPatchAction {
  type: typeof PREFERENCES_PATCH;
  payload: {
    preferences: Preferences;
  };
}
export const preferencesPatch = (
  preferences: Preferences
): PreferencesPatchAction => {
  return {
    type: PREFERENCES_PATCH,
    payload: { preferences }
  };
};

export interface PreferencesPatchSuccessAction {
  type: typeof PREFERENCES_PATCH_SUCCESS;
  payload: {
    preferences: Preferences;
  };
}
export const preferencesPatchSuccess = (
  preferences: Preferences
): PreferencesPatchSuccessAction => {
  return {
    type: PREFERENCES_PATCH_SUCCESS,
    payload: { preferences }
  };
};

export interface PreferencesPatchFailureAction {
  type: typeof PREFERENCES_PATCH_FAILURE;
}
export const preferencesPatchFailure = (): PreferencesPatchFailureAction => {
  return {
    type: PREFERENCES_PATCH_FAILURE
  };
};

export type PreferencesActions =
  | PreferencesRequiredAction
  | PreferencesFetchAction
  | PreferencesFetchSuccessAction
  | PreferencesFetchErrorAction
  | PreferencesPatchAction
  | PreferencesPatchSuccessAction
  | PreferencesPatchFailureAction;
