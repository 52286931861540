// This hook is used to force a rerender based on a dependency array.
// It is useful when a component's logic depends on another component being mounted.
// It's possible that the two components mount simultaneously, requiring the dependent component to rerender
// in order to have access the dom node of the other.
// This feels kind of hacky but I haven't found another way to solve the problem.

import { useEffect, useState } from 'react';
interface Props {
  deps?: unknown[];
  shouldRerender?: boolean;
  max?: number;
}
export const useRerender = ({
  // useEffect dependency array
  deps = [],
  // Optional flag to allow conditional rerenders
  shouldRerender = true,
  // Optional maximum number of rerenders
  max
}: Props) => {
  const [rerenders, setRerenders] = useState(0);
  useEffect(() => {
    if ((max == null || rerenders < max) && shouldRerender) {
      setRerenders(rerenders + 1);
    }
  }, [...deps, shouldRerender, max]);
  return {
    rerenders,
    incRerenders: () => {
      setRerenders(rerenders + 1);
    }
  };
};
