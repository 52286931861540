import {
  CloseButton as CloseButtonCL,
  Button as ButtonCL,
  FlatButton as FlatButtonCL,
  TextButton as TextButtonCL
} from '@hc/component-lib/hclib/components/atoms/button';
import buttonTheme from './Button.css-module.css';
import textButtonTheme from './TextButton.css-module.css';
import flatButtonTheme from './FlatButton.css-module.css';
import classNames from 'classnames';

export const CloseButton = (props) => {
  return <CloseButtonCL {...props} />;
};

export const Button = (props) => {
  return (
    <ButtonCL
      {...props}
      className={classNames(props.className, buttonTheme.HcsButton)}
      theme={buttonTheme}
    />
  );
};

export const FlatButton = (props) => {
  return (
    <FlatButtonCL
      {...props}
      className={classNames(props.className, flatButtonTheme.HcsFlatButton)}
      theme={flatButtonTheme}
    />
  );
};

export const TextButton = (props) => {
  return (
    <TextButtonCL
      {...props}
      className={classNames(props.className, textButtonTheme.HcsTextButton)}
      theme={textButtonTheme}
    />
  );
};
