import { watchLatest } from 'legacy/utils/saga';
import { call, put, select } from 'redux-saga/effects';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { ERROR_TEXT, EDIT_ONLY_TOAST_MSG } from 'legacy/appstore/constants';
import apiUtil from 'legacy/utils/api';

import reportSchemaService from 'legacy/services/report-schema';

import {
  VR_RECOMPUTE,
  saveReport,
  propertyExplorerRecomputeSuccess
} from 'actions/edit-report';
import { toastShow } from 'actions/toast';
import { expectedErrorModalShow, errorModalShow } from 'actions/error';
import { COMPS_REFRESH_READY_FOR_RECOMPUTE } from 'actions/comps';
import { RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE } from 'actions/rental-comps';

import { getUseVRShareEndpoint } from 'selectors/property-explorer';
import { getIsReportSaveable } from 'selectors/report-permissions';
import { getUniqueLinkUrlParams } from 'selectors/share-report';
import { getReportJson } from 'selectors/report-json';
import { getIsAvmHcEmpty } from 'selectors/avms';
import { selectScrubDataArgs } from 'sagas/mls-compliance.saga';

/**
 * Recompute report data and trigger saving of user version if appropriate
 */
function* recomputePropertyExplorer(action) {
  try {
    const useVRShareEndpoint = yield select(getUseVRShareEndpoint);
    const isAvmHcEmpty = yield select(getIsAvmHcEmpty);

    let reportJson = yield select(getReportJson);
    if (action.payload.recomputeHints) {
      reportJson.recomputeHints = action.payload.recomputeHints || {};
    }
    let urlParams;
    const url = useVRShareEndpoint
      ? `${HC_CONSTANTS.VRSHARE_PROXY_URL}/emporium/vrshare/recompute/`
      : `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/recompute/`;

    if (useVRShareEndpoint) {
      urlParams = yield select(getUniqueLinkUrlParams);
    }

    if (!isAvmHcEmpty) {
      const computedVr = yield call(apiUtil.POST, url, reportJson, {
        urlParams
      });
      const scrubDataArgs = yield call(selectScrubDataArgs);
      yield put(
        propertyExplorerRecomputeSuccess(
          reportSchemaService(computedVr, scrubDataArgs),
          {}
        )
      );
    }
    const isSaveable = yield select(getIsReportSaveable);
    if (isSaveable) {
      yield put(saveReport());
    } else {
      yield put(toastShow(EDIT_ONLY_TOAST_MSG));
    }
  } catch (e) {
    if (e.statusCode === 403) {
      yield put(expectedErrorModalShow(ERROR_TEXT.VALUE_REPORT.editDisallowed));
    } else {
      yield put(errorModalShow());
    }
    // Rethrow for Sentry reporting
    throw e;
  }
}

export default function registerEditReportSaga() {
  watchLatest(
    [
      VR_RECOMPUTE,
      COMPS_REFRESH_READY_FOR_RECOMPUTE,
      RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE
    ],
    recomputePropertyExplorer
  );
}
