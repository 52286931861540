import { streetAddressFormatter } from 'legacy/utils/formatters';
import get from 'lodash/get';

export const formattedValue = (propertyDetails, key) => {
  const rawValue = propertyDetails[key];

  // Some formatters require just the raw value, others use multiple values to
  // return a computed value.  Return the raw value if formatter key doesn't exist.
  return get(FORMATTERS, key, (v) => v)(rawValue, propertyDetails);
};

const FORMATTERS = {
  address: (_, property) =>
    streetAddressFormatter(
      property.streetAddress,
      property.unit,
      property.unitDesignator
    )
};
