import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { MapMarker } from './MapMarker';
import styles from './MapMarkers.css';
import { ListingStatusData } from 'src/lib/property-lookup/types';
import { listingStatusNormalized } from 'src/lib/utils/listingStatus';

interface Props {
  dataHcName: string;
  className?: string;
  children: ReactNode;
  listingStatusData: ListingStatusData;
  selected?: boolean;
  onClick?: VoidFunction;
  hoverable?: boolean;
  pulse?: boolean;
}
export const CompMarker = ({
  dataHcName,
  pulse,
  hoverable,
  onClick,
  className,
  selected,
  children,
  listingStatusData
}: Props) => {
  const statusNormalized = listingStatusNormalized(listingStatusData);
  return (
    <MapMarker
      dataHcName={dataHcName}
      className={classNames(className, {
        [styles.selected || '']: selected
      })}
      onClick={onClick}
      hoverable={hoverable}
      pulse={pulse}
    >
      <div
        className={classNames(styles.ListingStatusIndicator, {
          [styles.sold || '']: statusNormalized === 'SOLD',
          [styles.pending || '']: statusNormalized === 'PENDING',
          [styles.active || '']: statusNormalized === 'ACTIVE'
        })}
      />
      <div className={styles.Label}>{children}</div>
    </MapMarker>
  );
};
