import { useSelector } from 'react-redux';
import { selKeywordSearchMatches, selKeywordSearchStatus } from '../selectors';

export const useKeywordSearchMatches = () => {
  const status = useSelector(selKeywordSearchStatus);
  return {
    status,
    data: useSelector(selKeywordSearchMatches)
  };
};
