import { watchLatest } from 'legacy/utils/saga';
import { put, call, select } from 'redux-saga/effects';
import EffectiveDateService from 'legacy/services/effective-date';
import { report } from 'hc-ravenjs-logger';
import apiUtil from 'legacy/utils/api';
import HC_CONSTANTS from 'HC_CONSTANTS';
import {
  ORG_REPORTS_FETCH_PEXP,
  orgReportsFetchPexpFailure,
  orgReportsFetchPexpSuccess,
  ORG_REPORTS_FETCH_EFFECTIVE_DATE,
  orgReportsFetchEffectiveDateFailure,
  orgReportsFetchEffectiveDateSuccess
} from '../actions/org-reports.actions';
import { getPropertyResult } from 'selectors/property-search';

export function* handleOrgReportsFetchPexp(action) {
  try {
    const { streetAddress, zipcode, unit, unitDesignator } = yield select(
      getPropertyResult
    );
    const response = yield call(
      apiUtil.GET,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/shared_reports_by_org/`,
      { streetAddress, zipcode, unit, unitDesignator }
    );
    yield put(orgReportsFetchPexpSuccess(response.result));
  } catch (e) {
    yield put(orgReportsFetchPexpFailure());
    console.error(e);
    report('Org PEXP Reports: Saga Failed to Fetch Data', { e, action });
  }
}

export function* handleOrgReportsFetchEffectiveDate(action) {
  try {
    const results = yield call(EffectiveDateService.searchReports, {
      addressID: action.payload.addressId
    });
    yield put(orgReportsFetchEffectiveDateSuccess(results));
  } catch (e) {
    yield put(orgReportsFetchEffectiveDateFailure());
    console.error(e);
    report('Org Effective Date Reports: Saga Failed to Fetch Data', {
      e,
      action
    });
  }
}

export default function registerOrgReportsSaga() {
  watchLatest({
    [ORG_REPORTS_FETCH_PEXP]: handleOrgReportsFetchPexp,
    [ORG_REPORTS_FETCH_EFFECTIVE_DATE]: handleOrgReportsFetchEffectiveDate
  });
}
