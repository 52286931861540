import React, { KeyboardEvent, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './SearchByMlsNumber.css';
import { Input } from 'src/lib/components/Input';
import { Button } from 'src/lib/components/Button';
import { useDynamicRefs } from 'src/lib/hooks';
import { useSearchByMlsNumber } from '../../hooks/useSearchByMlsNumber';
import { Listing } from 'src/lib/property-lookup/types';
import { formatDateStr, formatFullAddress } from 'src/lib/utils';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { useClickOutsideComponent } from 'src/lib/hooks/useClickOutsideComponent';
import { overflowScrollIntoView } from 'src/lib/utils/dom';

interface Props {
  dataHcName: string;
  className?: string;
  placeholder?: string;
  // Unique query id allowing multiple instances of mls search
  queryId?: string;
  onSelect: (listing: Listing) => void;
  onFocus?: VoidFunction;
  // Render input without border
  noBorder?: boolean;
  theme?: {
    Hits?: string;
    NoResults?: string;
    SearchByMlsNumber?: string;
    Input?: string;
    LoadingContainer?: string;
    Loading?: string;
    Error?: string;
  };
}
export const SearchByMlsNumber = ({
  dataHcName,
  className,
  placeholder = 'Search for an MLS Number',
  queryId = 'default',
  onSelect,
  onFocus,
  noBorder,
  theme
}: Props) => {
  const [value, setValue] = useState('');
  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  // For closing and scrolling results
  const ref = useRef<HTMLDivElement>(null);
  const refHitsContainer = useRef<HTMLDivElement>(null);
  const [getRef, setRef] = useDynamicRefs<HTMLDivElement>();

  const { query, input, onSubmit } = useSearchByMlsNumber({ queryId });
  useClickOutsideComponent(ref, () => {
    setIsFocused(false);
  });

  const handleChange = (newValue: string) => {
    setValue(newValue);
    setIsFocused(true);
    setActiveRow(null);
  };
  const handleSubmit = () => {
    onSubmit(value);
  };
  const handleSelect = (listing: Listing) => {
    onSelect(listing);
  };

  const handleKeyUp = (e: KeyboardEvent<Element>) => {
    const activeListing = activeRow !== null && query.data?.[activeRow];
    // Handles Keyboard Navigation
    if (e.key === 'Enter') {
      if (activeListing) {
        handleSelect(activeListing);
      } else if (value.length) {
        handleSubmit();
      }
    } else if (e.key === 'ArrowUp') {
      if (query.data?.length) {
        const newActiveRow = !activeRow ? query.data.length - 1 : activeRow - 1;
        const refActive = getRef(`hit-${newActiveRow}`);
        if (refActive) {
          overflowScrollIntoView(refActive, refHitsContainer);
        }
        setActiveRow(newActiveRow);
      }
    } else if (e.key === 'ArrowDown') {
      if (query.data?.length) {
        const newActiveRow =
          activeRow === query.data.length - 1 || activeRow === null
            ? 0
            : activeRow + 1;
        const refActive = getRef(`hit-${newActiveRow}`);
        if (refActive) {
          overflowScrollIntoView(refActive, refHitsContainer);
        }
        setActiveRow(newActiveRow);
      }
    }
  };
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(
        styles.SearchByMlsNumber,
        theme?.SearchByMlsNumber,
        className
      )}
      ref={ref}
    >
      <Input
        dataHcName={`${dataHcName}-input`}
        className={classNames(styles.Input, theme?.Input)}
        placeholder={placeholder}
        value={value}
        onFocus={() => {
          onFocus?.();
          setIsFocused(true);
        }}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        noBorder={noBorder}
        noPadding
      >
        {!!value.trim().length && (
          <Button
            dataHcName={`${dataHcName}-button`}
            label="Search"
            onClick={handleSubmit}
            disabled={!value.length}
            primary={false}
            small
          />
        )}
      </Input>
      {isFocused && value && value === input && (
        <div
          className={classNames(styles.Hits, theme?.Hits)}
          ref={refHitsContainer}
          data-hc-name={`${dataHcName}-hits`}
        >
          {query.isFetched &&
            query.data &&
            (query.data.length > 0 ? (
              query.data.map((hit, i) => {
                const key = `mls-number-search-hit-${i}`;
                return (
                  <div
                    ref={setRef(`hit-${i}`)}
                    className={classNames(styles.Hit, {
                      [styles.active || '']: activeRow === i
                    })}
                    key={key}
                    data-hc-name={`${dataHcName}-hit-${i}`}
                    onClick={() => {
                      handleSelect(hit);
                    }}
                    onMouseEnter={() => {
                      setActiveRow(i);
                    }}
                  >
                    <span
                      className={styles.Date}
                      data-hc-name={`${dataHcName}-hit-${i}-date`}
                    >
                      {formatDateStr(hit.listingStatus.listingDate)}
                    </span>
                    <span
                      className={styles.Address}
                      data-hc-name={`${dataHcName}-hit-${i}-address`}
                    >
                      {formatFullAddress(hit.address)}
                    </span>
                  </div>
                );
              })
            ) : (
              <div
                data-hc-name={`${dataHcName}-no-results`}
                className={classNames(styles.NoResults, theme?.NoResults)}
              >
                No results found
              </div>
            ))}
          {query.isFetching && (
            <div
              className={classNames(
                styles.LoadingContainer,
                theme?.LoadingContainer
              )}
            >
              <LoadingSpinner
                className={classNames(styles.Loading, theme?.Loading)}
                dataHcName={`${dataHcName}-skeleton`}
                absoluteCenter={false}
                small
              />
            </div>
          )}
          {query.isError && (
            <div
              className={classNames(styles.Error, theme?.Error)}
              data-hc-name={`${dataHcName}-error`}
            >
              An error occurred and our team has been notified.
            </div>
          )}
        </div>
      )}
    </div>
  );
};
