import React from 'react';

import { streetAddressFormatter } from 'legacy/utils/formatters';

const TableCellAddress = ({ row, col }) => {
  const { streetAddress, unit, unitDesignator, city, state, zipcode } =
    row.data.address;
  const addressLine1 = streetAddressFormatter(
    streetAddress,
    unit,
    unitDesignator
  );
  const addressLine2 = `${city}, ${state} ${zipcode}`;
  return (
    <div>
      <div style={{ fontWeight: 600 }} title={addressLine1}>
        {addressLine1}
      </div>
      <div className="text-ellipsis lesser-text" title={addressLine2}>
        {addressLine2}
      </div>
    </div>
  );
};

export default TableCellAddress;
