import { STATUSES } from 'src/constants';
import {
  HuellActions,
  PERSONALIZATION_FETCH,
  PERSONALIZATION_FETCH_ERROR,
  PERSONALIZATION_FETCH_SUCCESS
} from '../actions';
import { Personalization } from '../types';

interface PersonalizationStateLoading {
  status: STATUSES.LOADING;
  fetching: true;
}

interface PersonalizationStateSuccess {
  status: STATUSES.SUCCESS;
  fetching: boolean;
  data: Personalization | null;
}

interface PersonalizationStateError {
  status: STATUSES.ERROR;
  fetching: boolean;
}

export type PersonalizationState =
  | PersonalizationStateLoading
  | PersonalizationStateSuccess
  | PersonalizationStateError
  | null;

const INITIAL_STATE = null;

export const personalizationReducer = <A>(
  state: PersonalizationState = INITIAL_STATE,
  action: A & HuellActions
): PersonalizationState => {
  switch (action.type) {
    case PERSONALIZATION_FETCH: {
      if (!state) {
        return {
          status: STATUSES.LOADING,
          fetching: true
        };
      } else {
        return {
          ...state,
          fetching: true
        };
      }
    }

    case PERSONALIZATION_FETCH_SUCCESS: {
      return {
        status: STATUSES.SUCCESS,
        fetching: false,
        data: action.payload.personalization
      };
    }

    case PERSONALIZATION_FETCH_ERROR: {
      return {
        status: STATUSES.ERROR,
        fetching: false
      };
    }

    default:
      return state;
  }
};
