import { EVENTS } from 'legacy/appstore/events';

export const ERROR_MODAL_SHOW = 'ERROR_MODAL_SHOW';
export const ERROR_MODAL_HIDE = 'ERROR_MODAL_HIDE';
export const EXPECTED_ERROR_MODAL_SHOW = 'EXPECTED_ERROR_MODAL_SHOW';

export const errorModalShow = (message) => ({
  type: ERROR_MODAL_SHOW,
  payload: { message },
  meta: {
    analytics: {
      event: EVENTS.ERROR_MODAL,
      props: {
        message
      }
    }
  }
});

// A modal without "We've been alerted about this issue"
export const expectedErrorModalShow = (message, messageSecondary) => ({
  type: EXPECTED_ERROR_MODAL_SHOW,
  payload: { message, messageSecondary },
  meta: {
    analytics: {
      event: EVENTS.ERROR_MODAL,
      props: {
        message,
        messageSecondary
      }
    }
  }
});

export const expectedNotDismissibleErrorModalShow = (
  message,
  messageSecondary
) => ({
  type: EXPECTED_ERROR_MODAL_SHOW,
  payload: { message, messageSecondary, dismissible: false },
  meta: {
    analytics: {
      event: EVENTS.ERROR_MODAL,
      props: {
        message,
        messageSecondary
      }
    }
  }
});

export const errorModalHide = () => ({
  type: ERROR_MODAL_HIDE
});
