import { Fragment } from 'react';
import get from 'lodash/get';

import {
  AVM_KEY_HC,
  AVM_KEY_USER,
  AVM_KEY_RENTAL_HC,
  COMP_TYPE_DEFAULT,
  COMP_TYPE_RENTAL,
  QUALITY_KEYS
} from 'legacy/appstore/constants';
import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

import {
  calcRentalCompAdjustedPrice,
  calcCompAdjustedSoldPrice
} from 'legacy/utils/avms';
import {
  streetAddressFormatter,
  wholeNumberFormatter,
  numberFormatter,
  booleanFormatter,
  dollarsFormatter,
  dollarsPerMonthFormatter,
  noFormat
} from 'legacy/utils/formatters';
import { displayDateCompact } from 'legacy/utils/dates';

import ValueTableCell from 'legacy/components/ValueTableCell';
import SimilarityLevelFilterOption from 'legacy/components/SimilarityLevelFilterOption';
import NumberField from 'legacy/components/generic/NumberField';
import SelectFieldPropertyDetails from 'legacy/components/SelectFieldPropertyDetails';
import BathroomsField from 'legacy/components/generic/BathroomsField';
import YearField from 'legacy/components/generic/YearField';
import DatePickerField from 'legacy/components/generic/DatePickerField';
import CurrencyField from 'legacy/components/generic/CurrencyField';
import PriceAdjustmentTooltip from '../components/PriceAdjustmentTooltip';
import BasePriceField from 'legacy/components/BasePriceField';

const { HC, MLS, PR } = PROPERTY_DATA_SOURCES;

export const PROPERTY_TYPE_OPTIONS = [
  {
    label: 'Single Family Detached',
    value: 'Single Family Detached'
  },
  {
    label: 'Condominium',
    value: 'Condominium'
  },
  {
    label: 'Townhouse',
    value: 'Townhouse'
  },
  {
    label: 'Multifamily',
    value: 'Multifamily'
  },
  {
    label: 'Manufactured/Mobile Home',
    value: 'Manufactured/Mobile Home'
  }
];

export const LISTING_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'Active'
  },
  {
    label: 'Pending',
    value: 'Pending'
  },
  {
    label: 'Sold',
    value: 'Sold'
  }
];

export const SIMILARITY_LEVEL_OPTIONS = [
  {
    label: <SimilarityLevelFilterOption similarityLevel="high" />,
    value: 'high'
  },
  {
    label: <SimilarityLevelFilterOption similarityLevel="moderate" />,
    value: 'moderate'
  },
  {
    label: <SimilarityLevelFilterOption similarityLevel="low" />,
    value: 'low'
  }
];

export const LISTING_STATUS_OPTIONS_RENTAL = [
  {
    label: 'Active',
    value: 'Active'
  },
  {
    label: 'Pending',
    value: 'Pending'
  },
  {
    label: 'Leased',
    value: 'Leased'
  }
];

export const CONDITION_LABELS = {
  [QUALITY_KEYS.CON1]: 'Nearly New',
  [QUALITY_KEYS.CON2]: 'Excellent',
  [QUALITY_KEYS.CON3]: 'Well Maintained',
  [QUALITY_KEYS.CON4]: 'Worn but Adequate',
  [QUALITY_KEYS.CON5]: 'Significant Repairs',
  [QUALITY_KEYS.CON6]: 'Uninhabitable'
};

export const CONDITION_OPTIONS = [
  QUALITY_KEYS.CON6,
  QUALITY_KEYS.CON5,
  QUALITY_KEYS.CON4,
  QUALITY_KEYS.CON3,
  QUALITY_KEYS.CON2,
  QUALITY_KEYS.CON1
].map((value) => ({ value, label: CONDITION_LABELS[value] }));

export const getAddress = (property) => {
  if (property) {
    const { city, state, streetAddress, zipcode, unit, unitDesignator } =
      property;

    const fullUnit = unit
      ? unitDesignator
        ? `${unitDesignator} ${unit}`
        : `${unit}`
      : null;

    const fullAddress = fullUnit
      ? `${streetAddress} ${fullUnit}, ${city}, ${state} ${zipcode}`
      : `${streetAddress}, ${city}, ${state} ${zipcode}`;

    return fullAddress;
  } else {
    return '';
  }
};

export const PROPERTY_DETAILS_FIELD_TYPES = {
  NUMBER: 'NUMBER',
  DROPDOWN: 'DROPDOWN',
  BATHROOMS: 'BATHROOMS',
  YEAR: 'YEAR',
  DATE: 'DATE',
  CURRENCY: 'CURRENCY',
  BASE_PRICE: 'BASE_PRICE'
};

export const PROPERTY_DETAILS_FIELD_COMPONENTS = {
  [PROPERTY_DETAILS_FIELD_TYPES.NUMBER]: NumberField,
  [PROPERTY_DETAILS_FIELD_TYPES.DROPDOWN]: SelectFieldPropertyDetails,
  [PROPERTY_DETAILS_FIELD_TYPES.BATHROOMS]: BathroomsField,
  [PROPERTY_DETAILS_FIELD_TYPES.YEAR]: YearField,
  [PROPERTY_DETAILS_FIELD_TYPES.DATE]: DatePickerField,
  [PROPERTY_DETAILS_FIELD_TYPES.CURRENCY]: (props) => (
    <CurrencyField {...props} withPristineError={!!props.validate} />
  ),
  [PROPERTY_DETAILS_FIELD_TYPES.BASE_PRICE]: BasePriceField
};

export const BOOLEAN_DROPDOWN_OPTIONS = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  },
  {
    value: null,
    label: 'Unknown'
  }
];

export const SUBJECT_ADJUSTABLE_FIELDS = {
  grossLivingAreaSqft: true,
  siteAreaSqft: true,
  propertyType: true,
  bedrooms: true,
  bathrooms: true,
  isOwnerOccupied: true,
  yearBuilt: true,
  basement: true,
  pool: true,
  quality: true
};

export const PROPERTY_DETAILS_ATTRIBUTES = {
  grossLivingAreaSqft: {
    label: 'Square Feet',
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.NUMBER,
    fieldProps: { min: 0 },
    format: (v) => `${wholeNumberFormatter(v)} Sq.Ft.`,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  siteAreaSqft: {
    label: 'Lot Size',
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.NUMBER,
    fieldProps: { min: 0 },
    format: (v) => {
      // Convert to acres
      // const acres = v / 43560;
      // return `${numberFormatter(acres, { precision: 2 })} Acre${acres > 1 ? 's' : ''}`;
      return `${wholeNumberFormatter(v)} Sq.Ft.`;
    },
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  propertyType: {
    label: 'Property Type',
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.DROPDOWN,
    fieldProps: {
      options: PROPERTY_TYPE_OPTIONS
    },
    format: noFormat,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  bedrooms: {
    label: 'Beds',
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.NUMBER,
    format: noFormat,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    fieldProps: { min: 0 },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  bathrooms: {
    label: 'Baths',
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.BATHROOMS,
    format: noFormat,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  stories: {
    label: 'Stories',
    format: noFormat,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  isOwnerOccupied: {
    label: 'Owner Occupied',
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.DROPDOWN,
    fieldProps: {
      options: BOOLEAN_DROPDOWN_OPTIONS
    },
    format: booleanFormatter,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  yearBuilt: {
    label: 'Year Built',
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.YEAR,
    format: noFormat,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  basement: {
    label: 'Basement',
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.DROPDOWN,
    fieldProps: {
      options: BOOLEAN_DROPDOWN_OPTIONS
    },
    format: booleanFormatter,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  pool: {
    label: 'Pool',
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.DROPDOWN,
    fieldProps: {
      options: BOOLEAN_DROPDOWN_OPTIONS
    },
    format: booleanFormatter,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  quality: {
    label: 'Condition',
    format: (v) => CONDITION_LABELS[v],
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.DROPDOWN,
    fieldProps: {
      // options populated in getter function due to need to support old mappings
    },
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  subdivisionName: {
    label: 'Subdivision',
    format: noFormat,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  garageType: {
    label: 'Garage Type',
    format: noFormat,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  garageNumCars: {
    label: 'Garage Spaces',
    format: noFormat,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  zoning: {
    label: 'Zoning',
    format: noFormat,
    mainSource: {
      source: HC,
      query: QUERIES.PROPERTY_FEATURES
    },
    queryBySource: {
      [HC]: QUERIES.PROPERTY_FEATURES,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.TAX_HISTORY
    }
  },
  hoaAssociation: {
    label: 'HOA Name',
    format: noFormat,
    mainSource: {
      source: MLS,
      query: QUERIES.LISTING_DETAILS
    },
    queryBySource: {
      [HC]: QUERIES.PROPERTY_FEATURES,
      [MLS]: QUERIES.LISTING_DETAILS,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  hoaFee: {
    label: 'HOA Fee',
    format: (v) => dollarsFormatter(parseFloat(v)),
    mainSource: {
      source: MLS,
      query: QUERIES.LISTING_DETAILS
    },
    queryBySource: {
      [HC]: QUERIES.PROPERTY_FEATURES,
      [MLS]: QUERIES.LISTING_DETAILS,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  hoaFeeFrequency: {
    label: 'HOA Fee Frequency',
    format: noFormat,
    mainSource: {
      source: MLS,
      query: QUERIES.LISTING_DETAILS
    },
    queryBySource: {
      [HC]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES,
      [MLS]: QUERIES.PROPERTY_FEATURES
    }
  },
  hoaFeeIncludes: {
    label: 'HOA Includes',
    format: (v) => (typeof v === 'string' ? v.split(',').join(', ') : v),
    mainSource: {
      source: MLS,
      query: QUERIES.LISTING_DETAILS
    },
    queryBySource: {
      [HC]: QUERIES.PROPERTY_FEATURES,
      [MLS]: QUERIES.LISTING_DETAILS,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  taxYear: {
    label: 'Tax Year',
    format: noFormat,
    mainSource: {
      source: PR,
      query: QUERIES.TAX_HISTORY
    },
    queryBySource: {
      [HC]: QUERIES.PROPERTY_FEATURES,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.TAX_HISTORY
    }
  },
  taxAmount: {
    label: 'Tax Amount',
    format: dollarsFormatter,
    mainSource: {
      source: PR,
      query: QUERIES.TAX_HISTORY
    },
    queryBySource: {
      [HC]: QUERIES.PROPERTY_FEATURES,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.TAX_HISTORY
    }
  },
  cumulativeDaysOnMarket: {
    label: 'Cumulative Days on Market',
    format: numberFormatter,
    mainSource: {
      source: HC,
      query: QUERIES.PROPERTY_FEATURES
    },
    queryBySource: {
      [HC]: QUERIES.PROPERTY_FEATURES,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  lastListDate: {
    label: 'List Date',
    format: displayDateCompact,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.DATE,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  lastListPrice: {
    label: 'List Price',
    format: dollarsFormatter,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.BASE_PRICE,
    fieldProps: {
      min: 0
    },
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  lastListDateRental: {
    label: 'List Date',
    format: displayDateCompact,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.DATE,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  lastListPriceRental: {
    label: 'List Price',
    format: dollarsFormatter,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.BASE_PRICE,
    fieldProps: {
      min: 0
    },
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  salesDate: {
    label: 'Sales Date',
    format: displayDateCompact,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.DATE,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  salesPrice: {
    label: 'Sales Price',
    format: dollarsFormatter,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.BASE_PRICE,
    fieldProps: {
      min: 0
    },
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  userBasePriceAdjustment: {
    label: (
      <Fragment>
        Price Adjustment
        <PriceAdjustmentTooltip
          title={'Price Adjustment'}
          content={
            'Adjustment applied to the Sale or List price depending on the property status.'
          }
          shift="right"
          infoIcon
        />
      </Fragment>
    ),
    format: dollarsFormatter,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.CURRENCY,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  basePriceAdjusted: {
    label: 'Adjusted Price',
    format: dollarsFormatter,
    value: (property, changedValues) =>
      calcCompAdjustedSoldPrice({
        ...property,
        ...changedValues
      }),
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  basePriceAdjustedDiffSqft: {
    label: 'Adjusted Price / Sq.Ft.',
    format: (v) => `${dollarsFormatter(v)}/sq.ft.`,
    value: (property, changedValues) => {
      if (property.grossLivingAreaSqft) {
        return (
          calcCompAdjustedSoldPrice({
            ...property,
            ...changedValues
          }) /
          get(
            changedValues,
            'grossLivingAreaSqft',
            property.grossLivingAreaSqft
          )
        );
      }
      return undefined;
    },
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  leasedDate: {
    label: 'Leased Date',
    format: displayDateCompact,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.DATE,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  leasedPrice: {
    label: 'Leased Price',
    format: dollarsFormatter,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.BASE_PRICE,
    fieldProps: {
      min: 0
    },
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  userRentalBasePriceAdjustment: {
    label: (
      <Fragment>
        Price Adjustment
        <PriceAdjustmentTooltip
          title={'Price Adjustment'}
          content={
            'Adjustment applied to the Leased or List price depending on the property status.'
          }
          shift="right"
          infoIcon
        />
      </Fragment>
    ),
    format: dollarsFormatter,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.CURRENCY,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  rentalBasePriceAdjusted: {
    label: 'Adjusted Price',
    format: dollarsFormatter,
    value: (property, changedValues) =>
      calcRentalCompAdjustedPrice({
        ...property,
        ...changedValues
      }),
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  rentalBasePriceAdjustedDiffSqft: {
    label: 'Adjusted Price / Sq.Ft.',
    format: (v) => `${dollarsFormatter(v)}/sq.ft.`,
    value: (property, changedValues) => {
      if (property.grossLivingAreaSqft) {
        return (
          calcRentalCompAdjustedPrice({
            ...property,
            ...changedValues
          }) /
          get(
            changedValues,
            'grossLivingAreaSqft',
            property.grossLivingAreaSqft
          )
        );
      }
      return undefined;
    },
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  propertyStatus: {
    label: 'Listing Status',
    format: noFormat,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  propertyStatusRental: {
    label: 'Listing Status',
    format: noFormat,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT,
      [MLS]: QUERIES.PROPERTY_FEATURES,
      [PR]: QUERIES.PROPERTY_FEATURES
    }
  },
  currentValue: {
    label: 'Current Value',
    Component: ValueTableCell,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    },
    value: (property) => ({
      currentValue: property.currentValue,
      currentAvmType:
        property.currentAvmType ||
        (property.currentValue !== property.housecanaryValue
          ? AVM_KEY_USER
          : AVM_KEY_HC)
    })
  },
  netDollarAdjustment: {
    label: 'HouseCanary Adjustment',
    format: dollarsFormatter,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  userAdjustment: {
    label: 'User Adjustment to Current Value',
    format: dollarsFormatter,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.CURRENCY,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  userAdjustmentRental: {
    label: 'User Adjustment to Current Value',
    format: dollarsPerMonthFormatter,
    fieldType: PROPERTY_DETAILS_FIELD_TYPES.CURRENCY,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  valueAdjusted: {
    label: 'Adjusted Value',
    format: dollarsFormatter,
    value: (property, changedValues) => {
      return (
        property.currentValue +
        get(
          changedValues,
          'userAdjustment',
          get(property, 'userAdjustment', 0)
        ) +
        get(property, 'netDollarAdjustment', 0)
      );
    },
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  valueAdjustedDiffSqft: {
    label: 'Adjusted Value / Sq.Ft.',
    format: (v) => `${dollarsFormatter(v)}/sq.ft.`,
    value: (property, changedValues) => {
      if (property.grossLivingAreaSqft) {
        return (
          (property.currentValue +
            get(
              changedValues,
              'userAdjustment',
              get(property, 'userAdjustment', 0)
            ) +
            get(property, 'netDollarAdjustment', 0)) /
          get(
            changedValues,
            'grossLivingAreaSqft',
            property.grossLivingAreaSqft
          )
        );
      }
      return undefined;
    },
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  currentRentalValue: {
    label: 'Current Rental Value',
    Component: ValueTableCell,
    value: (property) => {
      return {
        currentValue: get(property, ['rentalAvm', 'priceMean']),
        currentAvmType: get(property, 'currentAvmTypeRental', AVM_KEY_RENTAL_HC)
      };
    },
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  rentalValueAdjusted: {
    label: 'Adjusted Rental Value',
    format: dollarsPerMonthFormatter,
    value: (property, changedValues) =>
      property && property.rentalAvm
        ? get(property, ['rentalAvm', 'priceMean'], 0) +
          get(
            changedValues,
            'userAdjustmentRental',
            get(property, 'userAdjustmentRental', 0)
          )
        : undefined,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  rentalValueAdjustedDiffSqft: {
    label: 'Adjusted Rental Value / Sq.Ft.',
    format: (v) => (v ? `${dollarsFormatter(v, 2)} sqft` : '--'),
    value: (property, changedValues) =>
      property && property.rentalAvm && property.grossLivingAreaSqft
        ? (get(property, ['rentalAvm', 'priceMean'], 0) +
            get(
              changedValues,
              'userAdjustmentRental',
              get(property, 'userAdjustmentRental', 0)
            )) /
          property.grossLivingAreaSqft
        : undefined,
    mainSource: {
      source: HC,
      query: QUERIES.REPORT
    },
    queryBySource: {
      [HC]: QUERIES.REPORT
    }
  },
  publicRemarks: {
    label: 'Listing Remarks',
    format: noFormat,
    mainSource: {
      source: MLS,
      query: QUERIES.LISTING_DETAILS
    },
    queryBySource: {
      [MLS]: QUERIES.LISTING_DETAILS
    }
  }
};

export const getPropertyDetailsAttributeDef = (attrKey, property = {}) => {
  if (attrKey === 'quality') {
    return {
      ...PROPERTY_DETAILS_ATTRIBUTES.quality,
      fieldProps: {
        ...PROPERTY_DETAILS_ATTRIBUTES.quality.fieldProps,
        options: CONDITION_OPTIONS
      }
    };
  } else {
    return PROPERTY_DETAILS_ATTRIBUTES[attrKey];
  }
};

export const PROPERTY_DETAILS_ATTRIBUTE_GROUPS = [
  [
    'grossLivingAreaSqft',
    'siteAreaSqft',
    'bedrooms',
    'bathrooms',
    'stories',
    'propertyType',
    'isOwnerOccupied',
    'yearBuilt',
    'basement',
    'pool',
    'cumulativeDaysOnMarket'
  ],
  [
    'garageType',
    'garageNumCars',
    'quality',
    'subdivisionName',
    'zoning',
    'hoaAssociation',
    'hoaFee',
    'hoaFeeFrequency',
    'hoaFeeIncludes',
    'taxYear',
    'taxAmount'
  ]
];

export const getPropertyDetailsAttributeGroups = (property = {}) => {
  return [...PROPERTY_DETAILS_ATTRIBUTE_GROUPS].map((group) => {
    return group.map((key) =>
      PROPERTY_DETAILS_ATTRIBUTES.hasOwnProperty(key)
        ? { ...getPropertyDetailsAttributeDef(key, property), key }
        : null
    );
  });
};

export const getPropertyCardPriceAndDate = (
  property = {},
  compType = COMP_TYPE_DEFAULT
) => {
  let price = null;
  let priceLabel = null;
  let date = null;
  if (property.isRentalComp || compType === COMP_TYPE_RENTAL) {
    if (property.propertyStatusRental === 'Leased' && property.leasedPrice) {
      price = property.leasedPrice;
      priceLabel = property.propertyStatusRental;
      date = property.leasedDate;
    } else {
      price = property.lastListPriceRental;
      priceLabel =
        property.propertyStatusRental !== 'Leased'
          ? property.propertyStatusRental
          : 'Listed';
      date = property.lastListDateRental;
    }
    price = property.leasedPrice || property.lastListPriceRental;
    // Show HC Rental AVM if no rental data
    if (!price && property.rentalAvm) {
      price = property.rentalAvm.priceMean;
      priceLabel = 'HouseCanary Rental Value';
      date = null;
    }
  } else if (property.isMlsListing) {
    price = property.listPrice;
    priceLabel = 'Active';
    date = property.listDate;
  } else if (
    property.propertyStatus === 'Active' ||
    property.propertyStatus === 'Pending' ||
    property.normalizedListingStatus === 'Active' ||
    property.normalizedListingStatus === 'Pending'
  ) {
    price = property.lastListPrice;
    priceLabel = property.propertyStatus || property.normalizedListingStatus;
    date = property.lastListDate;
  } else if (property.salesPrice) {
    price = property.salesPrice;
    priceLabel = property.propertyStatus;
    date = property.salesDate;
  } else if (property.lastListPrice) {
    priceLabel = 'Listed';
    price = property.lastListPrice;
    date = property.lastListDate;
  } else if (property.isSearchResult) {
    price = null;
    priceLabel = null;
    date = null;
  }
  return {
    date,
    price,
    priceLabel
  };
};

export const getPropertyMarkerLabel = (property) => {
  return getPropertyCardPriceAndDate(property).price;
};

export const LOCATION_UNKNOWN_GEO_PRECISIONS = [
  'zipcode',
  'zip5',
  'zip6',
  'zip7',
  'zip8',
  'zip9',
  'street0',
  'unknown',
  'vendor'
];

export const isLocationUnknown = (geoPrecision) => {
  return LOCATION_UNKNOWN_GEO_PRECISIONS.indexOf(geoPrecision) >= 0;
};

export const isLocationKnown = (geoPrecision) => {
  return LOCATION_UNKNOWN_GEO_PRECISIONS.indexOf(geoPrecision) === -1;
};

/**
 * Given a property object, return a string containing the full address
 * To be used for checking property objects for uniqueness
 * @param  {object} property
 * @return {string} string containing street, city, state, etc.
 */
export const fullAddressStringForProperty = (property) => {
  let o = { ...property };
  if (!o.streetAddress && o.address) {
    o.streetAddress = o.address;
  }
  const streetAddress = streetAddressFormatter(
    o.streetAddress,
    o.unit,
    o.unitDesignator
  );
  return [streetAddress, o.city, [o.state, o.zipcode].join(' ')].join(', ');
};

/**
 * Given a list of properties, find and return an item from the list that matches
 * the given property's street, unit, and zipcode
 * Useful for checking if an address already exists in a list before taking an action
 * @param  {object} property
 * @param {array} list of properties
 * @return {object} found property or undefined
 */
export const findPropertyInList = (property, list) => {
  return list.find((o) => {
    // SECURITY: Cannot use literal. This is safe because it only concatinates property attributes @jnettleman
    // eslint-disable-next-line security/detect-non-literal-regexp
    const regex = new RegExp(fullAddressStringForProperty(property), 'i');
    return regex.test(fullAddressStringForProperty(o));
  });
};
