import sagaMiddleware from 'middleware/saga';

import { registerOrganizationUsageSaga } from 'sagas/organization-usage';
import { registerAuthSaga } from 'sagas/auth';
import { registerMessagesSaga } from 'sagas/messages';
import { registerLogoutSaga } from 'sagas/logout';
import { registerAccountDetailsSaga } from 'sagas/account-details';
import { registerRecentReportsSaga } from 'sagas/recent-reports';
import { registerAddressPartsSaga } from 'sagas/address-parts';
import { registerBrowserSaga } from 'sagas/browser';
import { registerPhotosSaga } from 'sagas/photos';
import { registerClientIdSearchSaga } from 'sagas/clientIdSearch';
import registerSearchByMlsNumberSaga from 'sagas/search-by-mls-number';

import registerGetPropertyExplorerSaga from 'sagas/get-property-explorer';
import registerGetReportPropertyDetails from 'sagas/report-property-details';
import registerSelectAvmSaga from 'sagas/select-avm';
import registerDownloadReportSaga from 'sagas/download-report';
import registerShareReportSaga from 'sagas/share-report';
import registerEditReportSaga from 'sagas/edit-report';
import registerRentalReportSaga from 'sagas/rental-report';
import registerArchivedReportsSaga from 'sagas/archived-reports';
import registerFullReportSaga from 'sagas/full-report';
import registerPropertySearchSaga from 'sagas/property-search';
import registerRecomputeCompAvmSaga from 'sagas/recompute-comp-avm';
import registerAddCompSaga from 'sagas/add-comp';
import registerSignUpSaga from 'sagas/sign-up';
import registerSharedPropertyExplorerSaga from 'sagas/get-property-explorer-shared';
import registerMLSListingsSaga from 'sagas/mls-listings';
import registerJoinOrganizationSaga from 'sagas/join-organization';
import registerForgotPasswordSaga from 'sagas/forgot-password';
import registerPersonalizationSaga from 'sagas/personalization';
import registerPreferencesSaga from 'sagas/preferences';
import registerVrNavigationSaga from 'sagas/navigation';
import registerHcModelsSaga from 'sagas/hcmodels.saga';
import registerRecomputeRentalCompAvmSaga from 'sagas/recompute-rental-comp-avm';
import registerUserEnteredValueSaga from 'sagas/user-entered-value';
import registerSelectRentalAvmSaga from 'sagas/select-rental-avm';
import registerMapDrawSagas from 'sagas/map-draw';
import registerPropertyDetailsSagas from 'sagas/property-details';
import registerGraphQLSaga from 'sagas/graphql';
import registerBrowserNavConfirmSaga from 'sagas/browser-nav-confirm';
import registerSubjectGeoLocationSaga from 'sagas/subject-geo-location';
import registerCompsSaga from 'sagas/comps';
import registerNearbyPropertiesSaga from 'sagas/nearby-properties';
import registerSavedFitlersSaga from 'sagas/saved-filters';
import registerSaveReportSaga from 'sagas/save-report';
import compsFiltersSaga from 'sagas/comps-filters';
import registerPurchasePlanSagas from 'sagas/self-service';
import registerMlsSaga from 'sagas/mls';
import registerListingsSaga from 'sagas/listings';
import registerMlsCoverageSaga from 'sagas/mls-coverage';
import registerKeywordSearchSagas from 'sagas/keyword-search';
import registerFeatureFlagsSaga from 'sagas/feature-flags';
import registerOrgReportsSaga from 'sagas/org-reports';
import registerEffectiveDateSaga from 'sagas/effective-date';
import registerChartDataSagas from 'sagas/chart-data';
import { reportApiSagas } from '../../../lib/report-api/sagas';
import { huellSagas } from '../../../lib/huell/sagas';

import { keywordSearchSagas } from 'src/lib/keyword-search/sagas';

import { streetViewSagas } from '../../../lib/street-view/sagas/sagas';
export default function registerSagas() {
  registerMlsCoverageSaga();
  registerChartDataSagas();
  registerEffectiveDateSaga();
  registerOrgReportsSaga();
  registerFeatureFlagsSaga();
  registerKeywordSearchSagas();
  compsFiltersSaga();
  registerMlsSaga();
  registerVrNavigationSaga();
  registerAuthSaga();
  registerMessagesSaga();
  registerLogoutSaga();
  registerAccountDetailsSaga();
  registerEditReportSaga();
  registerRecentReportsSaga();
  registerAddressPartsSaga();
  registerGetPropertyExplorerSaga();
  registerGetReportPropertyDetails();
  registerSelectAvmSaga();
  registerDownloadReportSaga();
  registerShareReportSaga();
  registerRentalReportSaga();
  registerArchivedReportsSaga();
  registerFullReportSaga();
  registerPropertySearchSaga();
  registerRecomputeCompAvmSaga();
  registerAddCompSaga();
  registerSignUpSaga();
  registerSharedPropertyExplorerSaga();
  registerMLSListingsSaga();
  registerPersonalizationSaga();
  registerOrganizationUsageSaga();
  registerBrowserSaga();
  registerPhotosSaga();
  registerJoinOrganizationSaga();
  registerForgotPasswordSaga();
  registerHcModelsSaga();
  registerRecomputeRentalCompAvmSaga();
  registerUserEnteredValueSaga();
  registerSelectRentalAvmSaga();
  registerMapDrawSagas();
  registerPreferencesSaga();
  registerPropertyDetailsSagas();
  registerGraphQLSaga();
  registerBrowserNavConfirmSaga();
  registerSubjectGeoLocationSaga();
  registerCompsSaga();
  registerNearbyPropertiesSaga();
  registerSavedFitlersSaga();
  registerSaveReportSaga();
  registerPurchasePlanSagas();
  registerListingsSaga();
  registerClientIdSearchSaga();
  registerSearchByMlsNumberSaga();
  sagaMiddleware.run(reportApiSagas);
  sagaMiddleware.run(huellSagas);
  sagaMiddleware.run(keywordSearchSagas);
  sagaMiddleware.run(streetViewSagas);
}
