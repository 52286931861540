import React from 'react';
import classNames from 'classnames';

import { StreetViewButton } from '../../components/StreetViewButton';
import { StreetViewDialog } from '../StreetViewDialog';
import { useDialogActiveState } from 'src/lib/hooks/useDialogActiveState';
import { Address } from 'src/lib/property-lookup/types';
import { useStreetView } from 'src/lib/street-view/hooks/useStreetView';

import styles from './StreetViewLauncher.css';
import { GeoLocation } from 'src/lib/property-lookup/types';
import { STATUSES } from 'src/constants';

interface Props {
  dataHcName: string;
  address: Address;
  geoLocation: GeoLocation;
  theme?: {
    StreetViewLauncherButton?: string;
    StreetViewLauncherDialog?: string;
  };
}

export const StreetViewLauncher = ({
  address,
  geoLocation,
  dataHcName,
  theme
}: Props) => {
  const { active, handleDialogOpen, handleDialogClose } =
    useDialogActiveState();
  const streetView = useStreetView(geoLocation);
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleDialogOpen();
  };
  return (
    <>
      <StreetViewButton
        onClick={handleClick}
        className={classNames(
          styles.StreetViewButton,
          theme?.StreetViewLauncherButton
        )}
        dataHcName={`${dataHcName}-button`}
        disabled={
          !streetView.streetViewExists && streetView.status === STATUSES.SUCCESS
        }
      />
      <StreetViewDialog
        active={active}
        onClose={handleDialogClose}
        address={address}
        geoLocation={geoLocation}
        className={classNames(
          styles.StreetViewLauncherDialog,
          theme?.StreetViewLauncherDialog
        )}
        dataHcName={`${dataHcName}-dialog`}
      />
    </>
  );
};
