import PropTypes from 'prop-types';
import { Button, FlatButton } from 'legacy/components/Button';
import { Dialog } from 'legacy/components/Dialog';

import PersonalizationExampleContainer from 'legacy/containers/personalization-example';

import { PersonalizationPromptModal as theme } from 'legacy/css-modules';

const PersonalizationPromptModal = ({
  active,
  altActionType,
  onClose,
  onAltActionClick,
  onPersonalizationClick
}) => {
  return (
    <Dialog
      title="Did you know you can co-brand your reports?"
      active={active}
      type="large"
      onClose={onClose}
      className={theme.PersonalizationPromptModal}
      theme={theme}
    >
      <PersonalizationExampleContainer />
      <div className={theme.CustomActionWrapper}>
        {altActionType && (
          <FlatButton
            label="Not right now"
            onClick={() => onAltActionClick(altActionType)}
          />
        )}
        <Button onClick={onPersonalizationClick} primary>
          Enter info
        </Button>
      </div>
    </Dialog>
  );
};

PersonalizationPromptModal.defaultProps = {
  active: false
};

PersonalizationPromptModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAltActionClick: PropTypes.func.isRequired,
  onPersonalizationClick: PropTypes.func.isRequired,
  altActionType: PropTypes.string,
  active: PropTypes.bool
};

export default PersonalizationPromptModal;
