import { createSelector } from 'reselect';

export const selClientIdSearchState = (state) => state.clientIdSearch;

export const selClientIdSearchSubmit = createSelector(
  selClientIdSearchState,
  (selClientIdSearchState) => selClientIdSearchState.input
);

export const selClientIdSearchResults = createSelector(
  selClientIdSearchState,
  (selClientIdSearchState) => selClientIdSearchState.results
);

export const selClientIdSearchStatus = createSelector(
  selClientIdSearchState,
  (selClientIdSearchState) => selClientIdSearchState.status
);
