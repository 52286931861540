import {
  COMP_TYPE_DEFAULT,
  COMP_TYPE_RENTAL,
  PROPERTY_TYPE_SUBJECT,
  PROPERTY_TYPE_SEARCH_RESULT,
  PROPERTY_TYPE_MLS_LISTING
} from 'legacy/appstore/constants';

import { getIsRentalContext } from 'selectors/property-explorer';
import { getPropertyResult } from 'selectors/property-search';
import { getMLSListingById } from 'selectors/mls-listings';
import { getSubjectAdjusted } from 'selectors/subject';
import { getCompByAddressId } from 'selectors/comps';
import { getRentalCompByAddressId } from 'selectors/rental-comps';

export const getPropertyBasedOnContext = (
  state,
  propertyContext,
  addressId
) => {
  // This gets a property from the correct part of state. The app needs to be refactored to have a single store for property data
  if (propertyContext === PROPERTY_TYPE_SUBJECT) {
    return getSubjectAdjusted(state);
  } else if (propertyContext === COMP_TYPE_DEFAULT) {
    return getCompByAddressId(addressId)(state);
  } else if (propertyContext === COMP_TYPE_RENTAL) {
    return getRentalCompByAddressId(addressId)(state);
  } else if (propertyContext === PROPERTY_TYPE_SEARCH_RESULT) {
    return getPropertyResult(state);
  } else if (propertyContext === PROPERTY_TYPE_MLS_LISTING) {
    return getMLSListingById(addressId)(state);
  } else {
    return addressId === 'subject'
      ? getSubjectAdjusted(state)
      : getIsRentalContext(state)
      ? getRentalCompByAddressId(addressId)(state)
      : getCompByAddressId(addressId)(state);
  }
};
