import React, { useMemo } from 'react';
import { STATUSES } from 'src/constants';
import { Skeleton } from 'src/lib/components/Skeleton';
import { Popover } from 'src/lib/components/Popover';
import { KeywordSearchByListings } from 'src/lib/keyword-search/features/KeywordSearchByListings';
import { KeywordSearchResults } from 'src/lib/keyword-search/features/KeywordSearchResults/KeywordSearchResults';
import { useKeywordSearchMatchesByEntityId } from 'src/lib/keyword-search/hooks';
import { KeywordListingInput } from 'src/lib/keyword-search/types';
import { kwFormatEntityId } from 'src/lib/keyword-search/utils';
import { formatCityStateZip, formatStreetAddress } from 'src/lib/utils';
import { useDocumentRole } from '../../hooks';
import { useCompCompareOverlay } from '../../hooks/useCompCompareOverlay';
import { useCompFarmListFiltered } from '../../hooks/useCompFarmListFiltered';
import { FarmDocument, DocumentRoles } from '../../types';
import { CompId, CompSchema } from '../../types/schemas';
import styles from './CompKeywordSearch.css';

interface Props {
  reportId: number;
  dataHcName: string;
}
export const CompKeywordSearch = ({ reportId, dataHcName }: Props) => {
  const {
    status: farmStatus,
    data: [farmDocument]
  } = useDocumentRole<FarmDocument>(reportId, DocumentRoles.Farm);
  const { compCompareOpen } = useCompCompareOverlay();
  const { status: keywordSearchStatus, data: keywordSearchMatches } =
    useKeywordSearchMatchesByEntityId();
  const { data: compFarmListFiltered } = useCompFarmListFiltered(reportId);
  const listingsInput = useMemo((): KeywordListingInput[] => {
    // Call the keyword search api for the whole unfiltered farm
    // We only display results from the filtered farm
    const compIds = Object.keys(farmDocument?.data || {}) as CompId[];
    const results: KeywordListingInput[] = [];
    compIds.forEach((compId) => {
      const compSchema = farmDocument?.data[compId];
      if (compSchema) {
        results.push({
          entity_id: kwFormatEntityId(compSchema.latestListing.entityID)
        });
      }
    });
    return results;
  }, [farmDocument]);
  const compSchemaMatches = useMemo(
    () =>
      compFarmListFiltered.filter(
        (compSchema) =>
          keywordSearchMatches[
            kwFormatEntityId(compSchema.latestListing.entityID)
          ]
      ),
    [compFarmListFiltered, keywordSearchMatches]
  );
  const handleResultClick = (compSchema: CompSchema) => {
    compCompareOpen({
      compSchema
    });
  };
  if (farmStatus !== STATUSES.SUCCESS) {
    return (
      <Skeleton
        type="input"
        dataHcName={`${dataHcName}-skeleton`}
        style={{ padding: '0 15px' }}
      />
    );
  }
  return (
    <div data-hc-name={dataHcName} className={styles.CompKeywordSearch}>
      <div className={styles.InputCell}>
        <KeywordSearchByListings
          listingsInput={listingsInput}
          dataHcName={`${dataHcName}-control`}
          noPadding
        />
      </div>
      {keywordSearchStatus && (
        <div className={styles.ResultsCell}>
          {compSchemaMatches.length ? (
            <Popover
              trigger={
                <KeywordSearchResults
                  dataHcName={`${dataHcName}-results-count`}
                  count={compSchemaMatches.length}
                  className={styles.clickable}
                />
              }
              content={compSchemaMatches.map((schema, i) => {
                return (
                  <div
                    key={`${dataHcName}-match-${i}`}
                    data-hc-name={`${dataHcName}-match-${i}`}
                    className={styles.Match}
                    onClick={() => handleResultClick(schema)}
                  >
                    <div className={styles.StreetAddress}>
                      {formatStreetAddress(schema.address)}
                    </div>
                    <div className={styles.CityStateZip}>
                      {formatCityStateZip(schema.address)}
                    </div>
                  </div>
                );
              })}
              contentPadding={0}
              closeOnClick
            />
          ) : (
            <KeywordSearchResults
              dataHcName={`${dataHcName}-results-count`}
              count={compSchemaMatches.length}
            />
          )}
        </div>
      )}
    </div>
  );
};
