export const FEATURE_FLAGS_REQUIRED = 'FEATURE_FLAGS_REQUIRED';
export const FEATURE_FLAGS_FETCH = 'FEATURE_FLAGS_FETCH';
export const FEATURE_FLAGS_FETCH_SUCCESS = 'FEATURE_FLAGS_FETCH_SUCCESS';
export const FEATURE_FLAGS_FETCH_FAILURE = 'FEATURE_FLAGS_FETCH_FAILURE';

export const featureFlagsRequired = () => {
  return {
    type: FEATURE_FLAGS_REQUIRED
  };
};

export const featureFlagsFetch = () => {
  return {
    type: FEATURE_FLAGS_FETCH
  };
};

export const featureFlagsFetchSuccess = (featureFlags) => {
  return {
    type: FEATURE_FLAGS_FETCH_SUCCESS,
    payload: { featureFlags }
  };
};

export const featureFlagsFetchFailure = () => {
  return {
    type: FEATURE_FLAGS_FETCH_FAILURE
  };
};
