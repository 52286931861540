import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { buildFullPath } from '@hc/redux-saga-router-plus/hclib/utils';

import { COMP_TYPE_DEFAULT } from 'legacy/appstore/constants';
import { EVENTS } from 'legacy/appstore/events';
import ROUTES from 'legacy/routes';
import { VIEWS } from 'legacy/routes/constants';

import { analyticsEvent } from 'actions/analytics';
import { propertyQueryDetailsComplete } from 'actions/property';

import { getCompByAddressId } from 'selectors/comps';
import { getRentalCompByAddressId } from 'selectors/rental-comps';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import { getIsReportEditable } from 'selectors/report-permissions';
import { getSubjectAdjusted, getSubjectRental } from 'selectors/subject';

import addressIdRequired from 'legacy/hocs/addressIdRequired';
import dispatchOnMount from 'legacy/hocs/dispatchOnMount';

import { TimelineSubjectDetailsMissingWarning } from 'legacy/containers/timeline-subject-details-missing-warning';
import PropertyCompare from 'legacy/components/PropertyCompare';

const compSelectorsDefault = {
  getSubject: getSubjectAdjusted,
  getProperty: getCompByAddressId
};

const compSelectorsRental = {
  getSubject: getSubjectRental,
  getProperty: getRentalCompByAddressId
};

const mapStateToProps = (state, ownProps) => {
  const { addressId, compType, idx } = ownProps;
  const compSelectors =
    compType === COMP_TYPE_DEFAULT ? compSelectorsDefault : compSelectorsRental;
  const isSubject = addressId === 'subject';
  let property = addressId
    ? addressId === 'subject'
      ? compSelectors.getSubject(state) || {}
      : compSelectors.getProperty(addressId)(state) || {}
    : {};
  return {
    form: `compareForm-${addressId}`,
    initialValues: { ...property },
    showEditControls:
      idx === 0 &&
      property &&
      property.selected &&
      getIsReportEditable(state) &&
      !getIsEffectiveDateReport(state),
    title: isSubject ? (
      <span>
        Subject Property <TimelineSubjectDetailsMissingWarning />
      </span>
    ) : (
      ' '
    ),
    propertyLookupUrl: buildFullPath(
      ROUTES[VIEWS.SEARCH],
      {},
      {
        street_address: property.streetAddress,
        zipcode: property.zipcode,
        unit: property.unit
      }
    ),
    property: { ...property },
    isSubject,
    compType
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { addressId, compType } = ownProps;
  return {
    onMount: () => dispatch(propertyQueryDetailsComplete(addressId)),
    onLookupProperty: (property) =>
      dispatch(
        analyticsEvent(
          compType === COMP_TYPE_DEFAULT
            ? EVENTS.COMPS_LOOKUP_PROPERTY
            : EVENTS.RENTAL_COMPS_LOOKUP_PROPERTY,
          { address_slug: property.addressSlug }
        )
      )
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onLookupProperty: () => dispatchProps.onLookupProperty(stateProps.property)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(reduxForm()(addressIdRequired(dispatchOnMount(PropertyCompare))));
