import { createSelector } from 'reselect';

const getCreateReportState = (state) => state.createReport;

export const getCreateReportClientId = createSelector(
  getCreateReportState,
  (createReportState) => createReportState.clientId
);

export const getCreateReportEffectiveDateState = createSelector(
  getCreateReportState,
  (createReportState) => createReportState.effectiveDate
);
