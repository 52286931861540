import { watchEvery } from 'legacy/utils/saga';
import { put, call, select } from 'redux-saga/effects';

import { getAuthClient } from '@hc/authentication-lib/hclib/clients';
import HC_CONSTANTS from 'HC_CONSTANTS';

import { toCamelCase } from 'legacy/utils/transform';

import {
  PUT_ACCOUNT_DETAILS_ON_STATE,
  SELECT_ORGANIZATION,
  selectOrganizationSuccess,
  selectOrganizationError
} from 'actions/account-details';
import { personalizationDataRequest } from 'actions/personalization';
import { fetchMessages } from 'actions/messages';
import { preferencesFetch } from 'actions/preferences';

import { getToken, getIsTeamSelected } from 'selectors/account-details';
import { getIsLoggedIn } from 'selectors/auth';
import { getPreferencesIsLoaded } from 'selectors/preferences';
import { isPersonalizationDataLoaded } from 'selectors/personalization';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { VIEWS } from 'legacy/routes/constants';
import { updateUserContextCallback } from 'legacy/utils/refresh-token-callback';

const authUrl = `${HC_CONSTANTS.AUTH_LIB_URL}/`;

function* getOtherAcountInformation(action) {
  const isAuthenticated = yield select(getIsLoggedIn);
  const isCurrentTeamSet = yield select(getIsTeamSelected);

  if (isAuthenticated && isCurrentTeamSet) {
    const preferencesLoaded = yield select(getPreferencesIsLoaded);
    if (!preferencesLoaded) {
      yield put(preferencesFetch());
    }
    const personalizationDataLoaded = yield select(isPersonalizationDataLoaded);
    if (!personalizationDataLoaded) {
      yield put(personalizationDataRequest());
    }
    yield put(fetchMessages());
  }
}

function* handleSelectOrganization(action) {
  try {
    const { organizationId } = action.payload;
    const authClient = getAuthClient(
      HC_CONSTANTS.LOGIN_API_URL,
      authUrl,
      updateUserContextCallback
    );
    const token = yield select(getToken);

    const accountDetails = toCamelCase(
      yield call(
        [authClient, authClient.selectOrganization],
        token,
        organizationId
      )
    );
    yield put(selectOrganizationSuccess(accountDetails));
    yield put(routeChange({ view: VIEWS.SEARCH, options: { inherit: false } }));
    yield put(personalizationDataRequest());
  } catch (e) {
    console.error(e);
    yield put(selectOrganizationError(e.message));
  } finally {
    if (action.meta && action.meta.resolved) {
      action.meta.resolved();
    }
  }
}

export function registerAccountDetailsSaga() {
  watchEvery({
    [PUT_ACCOUNT_DETAILS_ON_STATE]: getOtherAcountInformation,
    [SELECT_ORGANIZATION]: handleSelectOrganization
  });
}
