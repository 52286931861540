import {
  TOGGLE_CONTACT_SUPPORT,
  TOGGLE_SHARE_COMPONENT,
  TOGGLE_MOBILE_SEARCH,
  HIDE_MOBILE_SEARCH,
  SHOW_NAVBAR_SEARCH,
  HIDE_NAVBAR_SEARCH
} from '../actions/app-header';

const INITIAL_STATE = {
  isShowingMobileSearch: false,
  showContactSupport: false,
  showShareDropDown: false,
  isShowingNavbarSearch: true,
  isShowingAppHeader: true
};

export function appHeaderReducer(state = INITIAL_STATE, action) {
  let showShareDropDown;
  let isShowingMobileSearch;
  let showContactSupport;
  switch (action.type) {
    case TOGGLE_CONTACT_SUPPORT:
      showContactSupport = !state.showContactSupport;
      return Object.assign({}, state, { showContactSupport });
    case TOGGLE_SHARE_COMPONENT:
      if (state.showShareDropDown) {
        showShareDropDown = false;
      } else {
        if (state.isShowingMobileSearch) {
          isShowingMobileSearch = false;
        }
        showShareDropDown = true;
      }
      return Object.assign({}, state, {
        showShareDropDown,
        isShowingMobileSearch
      });
    case TOGGLE_MOBILE_SEARCH:
      if (state.isShowingMobileSearch) {
        isShowingMobileSearch = false;
      } else {
        if (state.showShareDropDown) {
          showShareDropDown = false;
        }
        isShowingMobileSearch = true;
      }
      return Object.assign({}, state, {
        showShareDropDown,
        isShowingMobileSearch
      });
    case HIDE_MOBILE_SEARCH:
      return {
        ...state,
        isShowingMobileSearch: false
      };
    case SHOW_NAVBAR_SEARCH:
      return {
        ...state,
        isShowingNavbarSearch: true
      };
    case HIDE_NAVBAR_SEARCH:
      return {
        ...state,
        isShowingNavbarSearch: false
      };
    default:
      return state;
  }
}
