import * as ss from 'simple-statistics';
import moment from 'moment';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import * as d3 from 'd3';

export const NEARBY_CHART_ORDER = [
  'sqFt',
  'dolSqFt',
  'siteArea',
  'dolSiteArea',
  'bedrooms',
  'bathrooms',
  'age'
];

export const CHART_DATA_VALUE_FNS = {
  age: {
    fn: (property) => property.age,
    multiplesOf: [5, 10],
    chartTypeLabel: 'Age (Years)',
    labelAbbrv: 'Age'
  },
  bedrooms: {
    fn: (property) => property.bedrooms,
    multiplesOf: [1],
    chartTypeLabel: 'Bedrooms',
    labelAbbrv: 'Beds'
  },
  bathrooms: {
    fn: (property) => property.bathrooms,
    multiplesOf: [1],
    chartTypeLabel: 'Bathrooms',
    labelAbbrv: 'Baths'
  },
  sqFt: {
    fn: (property) => property.grossLivingAreaSqft,
    multiplesOf: [50],
    chartTypeLabel: 'Gross Living Area (SQFT)',
    labelAbbrv: 'SqFt'
  },
  dolSqFt: {
    fn: (property) => property.currentValue / property.grossLivingAreaSqft,
    multiplesOf: [50],
    chartTypeLabel: '$ / Gross Living Area (SQFT)',
    labelAbbrv: '$ / SqFt'
  },
  siteArea: {
    fn: (property) => property.siteAreaSqft,
    multiplesOf: [50],
    chartTypeLabel: 'Lot Size (SQFT)',
    labelAbbrv: 'Lot'
  },
  dolSiteArea: {
    fn: (property) => property.currentValue / property.siteAreaSqft,
    multiplesOf: [50],
    chartTypeLabel: '$ / Lot Size (SQFT)',
    labelAbbrv: '$ / Lot'
  }
};

const _findArrayIndicesMatchingCondition = (arr, conditionFn) => {
  // this method expects arr to be sorted and will return the upper and lower indices
  // of the values that match the passed condition
  let indices = [0, arr.length - 1];
  let foundLower = false;
  let l = 0;
  while (!foundLower && l < arr.length) {
    if (conditionFn(arr[l])) {
      indices[0] = l;
      foundLower = true;
    }
    l++;
  }
  if (foundLower) {
    let foundUpper = false;
    let u = arr.length - 1;
    while (!foundUpper && u >= 0) {
      if (conditionFn(arr[u])) {
        indices[1] = u;
        foundUpper = true;
      }
      u--;
    }
  }
  return indices;
};

const _roundToOneOrMultiplesOf = (v, multiplesOf) => {
  const i = parseInt(Math.ceil(v));
  if (i >= 0 && i <= 1) {
    return 1;
  } else if (multiplesOf && multiplesOf.filter((m) => i % m === 0).length) {
    return i;
  } else {
    return _roundToOneOrMultiplesOf(v + 1, multiplesOf);
  }
};

const _formatBucketKeyAsInt = (key) => parseInt(key.split(',')[0]);

const _bucketFormatToChartFormat = (data) => {
  const sortedKeys = Object.keys(data).sort((a, b) => {
    const aVal = _formatBucketKeyAsInt(a);
    const bVal = _formatBucketKeyAsInt(b);
    return aVal - bVal;
  });
  let preppedData = [];
  for (let i in sortedKeys) {
    if (data[sortedKeys[i]]) {
      preppedData.push({
        y: data[sortedKeys[i]],
        x: sortedKeys[i].split(',').map((v, i) => {
          let value = parseFloat(v);
          if (i === 1) {
            // Prevents a value from falling in 2 buckets
            value = value - value * 0.001;
          }
          return value;
        })
      });
    }
  }
  return preppedData;
};

const _calcE = (s, stepRounded, values) =>
  Math.min(s + stepRounded, values[values.length - 1] + 1);

export const computeChartDataNearbyProperties = (properties, numBins = 10) => {
  // Ported from: https://github.com/housecanary/hcplot/blob/develop/hcplot/algos.py
  // START PREPARE VALUES
  let chartData = {};
  // Extract data as individual arrays, init w/ subject data
  let values = {
    age: [],
    bedrooms: [],
    bathrooms: [],
    sqFt: [],
    dolSqFt: [],
    siteArea: [],
    dolSiteArea: []
  };
  if (properties && properties.length) {
    // Cache sums for mean to avoid an unnecessary iteration of each array
    let sums = {};
    let stdDeviation = {};
    // Populate values and sums from farm list
    for (let i = 0; i < properties.length; i++) {
      for (let chartType in values) {
        // Setup the sums obj
        if (i === 0) {
          // NOTE: Changed this to color the subject data dynamically (should also update the value)
          sums[chartType] = 0;
        }
        const value = CHART_DATA_VALUE_FNS[chartType].fn(properties[i]);
        // Record valid values
        if (value && value !== Infinity) {
          values[chartType].push(value);
          sums[chartType] += value;
        }
      }
    }

    // START BUCKETING OF DATA (this might be better as it's own generic method)
    for (let chartType in values) {
      if (values[chartType].length) {
        values[chartType] = values[chartType].sort((a, b) => a - b);
        stdDeviation[chartType] = ss.standardDeviation(values[chartType]);
        // decentValCheck taken from: https://github.com/housecanary/hcplot/blob/develop/hcplot/algos.py#L67
        const decentValCheck = (v) =>
          Math.abs(v - sums[chartType] / values[chartType].length) <=
          3 * stdDeviation[chartType];
        // Get the indicies of the "decent" values so we only have to loop through part of the value array once
        const decentValsIndices = _findArrayIndicesMatchingCondition(
          values[chartType],
          decentValCheck
        );
        // Use the indicies of the decent values to create our under / decent / over value arrays
        const decentVals = values[chartType].slice(
          decentValsIndices[0],
          decentValsIndices[1] + 1
        );
        const underVals = values[chartType].slice(0, decentValsIndices[0]);
        const overVals = values[chartType].slice(
          decentValsIndices[1] + 1,
          values[chartType].length
        );

        const stepRounded = _roundToOneOrMultiplesOf(
          (decentVals[decentVals.length - 1] - decentVals[0]) / numBins,
          CHART_DATA_VALUE_FNS[chartType].multiplesOf
        );

        let d = {};
        let s = decentVals[0];
        let e = _calcE(s, stepRounded, values[chartType]);
        for (let i = 0; i < decentVals.length; i++) {
          while (decentVals[i] >= e) {
            s = e;
            e = _calcE(e, stepRounded, values[chartType]);
          }
          if (!d[[s, e]]) {
            d[[s, e]] = 0;
          }
          d[[s, e]] += 1;
        }

        if (underVals.length) {
          d[[values[chartType][0], decentVals[0]]] = underVals.length;
        }

        if (overVals.length) {
          let alreadyCovered = [];
          let remaining = [];
          for (let o = 0; o < overVals.length; o++) {
            const x = overVals[o];
            if (s <= x && x < e) {
              alreadyCovered.push(x);
            } else {
              remaining.push(x);
            }
          }
          d[[s, e]] += alreadyCovered.length;
          // +1 so that all values in this bucket are strictly inside the bucket
          d[[e, values[chartType][values[chartType].length - 1] + 1]] =
            overVals.length - alreadyCovered.length;
        }

        const dataKeys = Object.keys(d);
        if (dataKeys.length > 1 || d[dataKeys[0]] > 1) {
          chartData[chartType] = _bucketFormatToChartFormat(d);
        }
      }
    }
  }

  return chartData;
};

const _dateNYearsFromCurrentMonth = (n = 0) =>
  moment().date(1).add(n, 'years').format('YYYY-MM-DD');

const _findDataForMonth = (data, nYears) => {
  const month = _dateNYearsFromCurrentMonth(nYears);
  return data.find((d) => d.month === month);
};

export const computeChartDataHpi = (rawData, avm) => {
  let dataByYear = [];
  let dataFull = [];
  let dataForecast = [];
  let dataCurrent = [];
  if (avm && avm.value && rawData && rawData.length) {
    const referenceValue = _findDataForMonth(rawData);
    if (!referenceValue) {
      // Abort if the hpi data does not contain the reference month
      return {
        dataId: new Date().getTime(),
        full: [],
        byYear: [],
        forecast: [],
        current: []
      };
    }

    const numPoints = rawData.length;
    let dataYearIdx = -1;
    let nextYear = null;
    for (let i = 0; i < numPoints; i++) {
      const d = rawData[i];
      const propertyValue = Math.round(
        (d.hpiValue / referenceValue.hpiValue) * avm.value
      );
      const pctDiff = ((propertyValue - avm.value) / avm.value) * 100;
      const dataPoint = {
        x: d3.timeParse('%Y-%m-%d')(d.month),
        y: propertyValue,
        month: d.month,
        year: dataYearIdx,
        hpiValue: d.hpiValue,
        pctDisplay: `${pctDiff > 0 ? '+' : ''}${pctDiff.toFixed(1)}%`,
        pctDiff
      };
      // Break data up by year so each series can be colored differently
      if (i === 0 || nextYear === d.month || i === numPoints - 1) {
        if (i !== 0) {
          if (dataYearIdx === 0) {
            dataCurrent.push({
              ...dataPoint,
              // Add additional attributes for tooltip
              label: 'Current Value'
            });
          } else {
            dataForecast.push({
              ...dataPoint,
              label: `${dataYearIdx} year forecast`
            });
          }
          // Copy the first point to the last series so the lines will touch
          dataByYear[dataYearIdx].push(dataPoint);
        }
        nextYear = _dateNYearsFromCurrentMonth(dataByYear.length);
        dataByYear.push([]);
        dataYearIdx++;
      }
      dataByYear[dataYearIdx].push(dataPoint);
      dataFull.push(dataPoint);
    }

    return {
      dataId: new Date().getTime(),
      full: dataFull,
      byYear: dataByYear,
      forecast: dataForecast,
      current: dataCurrent
    };
  } else {
    return {
      dataId: new Date().getTime(),
      full: [],
      byYear: [],
      forecast: [],
      current: []
    };
  }
};

export const formatChartDataMarket = (data) =>
  data
    ? data.map((d) => ({
        x: d3.timeParse('%Y-%m-%d')(d.date),
        y: d.value
      }))
    : [];

const _determineThrsholdCrossX = (p1, p2, thresholdValue) => {
  // Calculate the x value of the threshold crossing using y = mx + b
  // Dates are computed to timestamps for easy computation but are returned as strings;
  const x1 = moment(p1.date, 'YYYY-MM-DD').valueOf();
  const y1 = p1.value;
  const x2 = moment(p2.date, 'YYYY-MM-DD').valueOf();
  const y2 = p2.value;
  const xValMs = ((thresholdValue - y1) * (x2 - x1)) / (y2 - y1) + x1;
  return moment(xValMs).format('YYYY-MM-DD HH:mm:ss');
};

const MARKET_INDEX_LEVELS = [
  { lower: 0, upper: 21, indexLevel: 'sBuyer', i: 0 },
  { lower: 21, upper: 41, indexLevel: 'buyer', i: 1 },
  { lower: 41, upper: 61, indexLevel: 'neutral', i: 2 },
  { lower: 61, upper: 81, indexLevel: 'seller', i: 3 },
  { lower: 81, upper: 100, indexLevel: 'sSeller', i: 4 }
];

export const _getMarketIndexLevelDef = (d) => {
  // Get the market index level definition for a given datapoint
  let pointThreshold;
  for (let i = 0; i < MARKET_INDEX_LEVELS.length; i++) {
    const threshold = MARKET_INDEX_LEVELS[i];
    if (d.value <= threshold.upper) {
      pointThreshold = threshold;
      break;
    }
  }
  return pointThreshold;
};

export const computeChartDataMarketIndex = (data) => {
  // Breaks up the market index data into separate arrays by index level and formats the data for charts.
  // Adds interpolated points for joining series lines at index level thresholds and null points to break up the svg path
  let indexData = {
    sSeller: [],
    seller: [],
    neutral: [],
    buyer: [],
    sBuyer: []
  };

  if (!data || !data.length) {
    return indexData;
  }

  let indexLevelDefPrv = {};
  data.forEach((d, i) => {
    let d1 = data[i - 1];
    let d2 = d;
    let indexLevelDefCur = _getMarketIndexLevelDef(d2);
    let indexLevelCur = indexLevelDefCur.indexLevel;
    let indexLevelPrv = indexLevelDefPrv.indexLevel;

    if (indexLevelPrv && indexLevelCur !== indexLevelPrv) {
      // The index level has changed so we need to join the lines at the thresholds
      // by adding mock datapoints that are excluded from the chart tooltip
      const rising = d2.value > d1.value;
      let linesJoined = false;
      // This will continue to add points for every threshold that has been crossed between the two points
      while (!linesJoined) {
        // First determine the next index level the line will cross (it may not be the same as the next point)
        let indexLevelDefNxt =
          MARKET_INDEX_LEVELS[
            rising ? indexLevelDefPrv.i + 1 : indexLevelDefPrv.i - 1
          ];
        let indexLevelNxt = indexLevelDefNxt.indexLevel;

        // Build a mock point at the threshold of the two index levels
        const thresholdValueY = rising
          ? indexLevelDefNxt.lower
          : indexLevelDefNxt.upper;
        const thresholdValueX = _determineThrsholdCrossX(
          d1,
          d2,
          thresholdValueY
        );
        const thresholdPoint = {
          x: d3.timeParse('%Y-%m-%d %H:%M:%S')(thresholdValueX),
          y: thresholdValueY,
          tooltipExclude: true
        };

        // Assign that point to each indexLevel series so they touch at the threshold
        indexData[indexLevelPrv].push(thresholdPoint);
        indexData[indexLevelNxt].push(thresholdPoint);
        // Appending null point breaks the line in the chart series
        indexData[indexLevelPrv].push({
          ...thresholdPoint,
          y: null
        });

        // Figure out what the next index level would be
        indexLevelDefPrv = indexLevelDefNxt;
        indexLevelPrv = indexLevelDefPrv.indexLevel;
        indexLevelDefNxt =
          MARKET_INDEX_LEVELS[
            rising ? indexLevelDefPrv.i + 1 : indexLevelDefPrv.i - 1
          ] || {};
        indexLevelNxt = indexLevelDefNxt.indexLevel;

        // Determine if the original datapoints have been joined
        if (
          !indexLevelNxt ||
          (rising && indexLevelDefNxt.i > indexLevelDefCur.i) ||
          (!rising && indexLevelDefNxt.i < indexLevelDefCur.i)
        ) {
          linesJoined = true;
        }
      }
    }

    // Add the current point to it's index series
    indexData[indexLevelCur].push({
      x: d3.timeParse('%Y-%m-%d')(d2.date),
      y: d2.value,
      indexLevel: indexLevelCur
    });

    indexLevelDefPrv = indexLevelDefCur;
  });

  return indexData;
};

// functions below were brought in to provide data to the insights charts section
export const setSimilarPropertiesPercentileValues = (
  comps,
  isEffectiveDateReport
) => {
  const prices = getUsableCompPrices(comps, isEffectiveDateReport);
  return setPercentileValues(prices);
};

function getUsableCompPrices(comps, isEffectiveDateReport) {
  // list of adjusted prices (sales or list)
  let prices = getCompPrices(comps, isEffectiveDateReport);
  let minComp = prices.length > 0 ? ss.min(prices) : null;
  let maxComp = prices.length > 0 ? ss.max(prices) : null;
  let normalizedComps = map(prices, (p) => (p - minComp) / (maxComp - minComp));
  let zScores = map(normalizedComps, (p) =>
    Math.abs(
      (p - ss.mean(normalizedComps)) / ss.standardDeviation(normalizedComps)
    )
  );
  // filter prices more than 2 standard deviations away from the mean
  return sortBy(prices.filter((c, i) => zScores[i] < 2.0));
}

function calculatePercentile(values, pct) {
  const index = (pct / 100) * (values.length - 1);
  let p;
  if (index % 1 === 0) {
    p = values[index];
  } else {
    let i = Math.floor(index);
    p = values[i] + (values[i + 1] - values[i]) * (index - i);
  }
  return Math.round(p);
}

function setPercentileValues(prices) {
  if (!prices?.length) {
    return {
      percentile90Lower: null,
      percentile90Upper: null
    };
  }
  let percentile90Lower = calculatePercentile(prices, 5);
  let percentile90Upper = calculatePercentile(prices, 95);
  const valueFormatter = d3.format(',');
  return {
    percentile90Lower: valueFormatter(percentile90Lower),
    percentile90Upper: valueFormatter(percentile90Upper)
  };
}

function getCompPrices(comps, isEffectiveDateReport) {
  return comps
    .map((c) => c[isEffectiveDateReport ? 'salesPrice' : 'salesPriceAdjusted'])
    .filter((c) => c);
}
