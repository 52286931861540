import {
  SHOW_DOWNLOAD_PDF_PROGRESS,
  HIDE_DOWNLOAD_PDF_PROGRESS,
  SHOW_DOWNLOAD_EXCEL_PROGRESS,
  HIDE_DOWNLOAD_EXCEL_PROGRESS
} from 'actions/download-report';

const INITIAL_STATE = {
  isDownloadingPDF: false,
  isDownloadingXLS: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_DOWNLOAD_PDF_PROGRESS:
      return {
        ...state,
        isDownloadingPDF: true
      };

    case HIDE_DOWNLOAD_PDF_PROGRESS:
      return {
        ...state,
        isDownloadingPDF: false
      };

    case SHOW_DOWNLOAD_EXCEL_PROGRESS:
      return {
        ...state,
        isDownloadingXLS: true
      };

    case HIDE_DOWNLOAD_EXCEL_PROGRESS:
      return {
        ...state,
        isDownloadingXLS: false
      };

    default:
      return state;
  }
};
