import get from 'lodash/get';
import remove from 'lodash/remove';
import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import {
  COMP_ID_KEY,
  COMP_VIEW_CARD,
  MAP_ID_COMPS,
  MAP_ID_RENTAL_COMPS,
  COMP_VIEW_COLUMNS,
  STATUSES
} from 'legacy/appstore/constants';
import {
  ACTION_PAGE_LOAD_COMPS,
  ACTION_PAGE_LOAD_RENTAL_COMPS
} from 'legacy/routes/constants';

import {
  validateFilters,
  getAbsoluteFilterValueMapping
} from 'legacy/utils/filters';
import { determineShapeTypeFromGeoJson } from 'legacy/utils/maps';
import { isLocationKnown } from 'legacy/utils/property-details';

import {
  GET_REPORT_HC_VERSION_SUCCESS,
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_USER_VERSION_NOT_FOUND,
  GET_REPORT_RENTAL_SUCCESS
} from 'actions/get-report';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';
import {
  COMPS_SORT,
  COMPS_SORT_SELECTED,
  COMPS_CLEAR_SORT,
  COMPS_CLEAR_FILTER,
  COMPS_CLEAR_ALL_FILTERS,
  COMPS_SEARCH,
  COMPS_SELECT_TOGGLE,
  COMPS_FOCUS_TOGGLE,
  COMPS_SET_LIST_SCROLLED_TO_COMP,
  COMPS_UNSELECT_ALL,
  COMPS_FARM_UPDATE_SUCCESS,
  COMPS_FILTER_DISTANCE_CHANGE,
  COMPS_MAP_DRAW_SUBMIT,
  COMPS_ADD_COMP_SUCCESS,
  COMPS_FILTERS_APPLY,
  COMPS_ADJUST_SAVE,
  COMPS_LIST_CHANGE_VIEW,
  COMPS_REFRESH_READY_FOR_RECOMPUTE,
  COMPS_KEYWORD_SEARCH_SUBMIT,
  COMPS_KEYWORD_SEARCH_SUBMIT_SUCCESS,
  COMPS_SHOW_ON_MAP,
  COMPS_SHOW_ON_MAP_CLEAR,
  COMPS_TAG_ADD,
  COMPS_TAG_REMOVE,
  COMPS_TAG_FILTER
} from 'actions/comps';
import { SEARCH_BY_MLS_NUMBER_SELECT_SUCCESS } from 'actions/search-by-mls-number';
import {
  RENTAL_COMPS_SHOW_ON_MAP,
  RENTAL_COMPS_SHOW_ON_MAP_CLEAR,
  RENTAL_COMPS_SORT,
  RENTAL_COMPS_SORT_SELECTED,
  RENTAL_COMPS_CLEAR_SORT,
  RENTAL_COMPS_CLEAR_ALL_FILTERS,
  RENTAL_COMPS_SEARCH,
  RENTAL_COMPS_SELECT_TOGGLE,
  RENTAL_COMPS_FOCUS_TOGGLE,
  RENTAL_COMPS_SET_LIST_SCROLLED_TO_COMP,
  RENTAL_COMPS_UNSELECT_ALL,
  RENTAL_COMPS_CLEAR_FILTER,
  RENTAL_COMPS_FILTER_DISTANCE_CHANGE,
  RENTAL_COMPS_FARM_UPDATE_SUCCESS,
  RENTAL_COMPS_MAP_DRAW_SUBMIT,
  RENTAL_COMPS_FILTERS_APPLY,
  RENTAL_COMPS_ADD_COMP_SUCCESS,
  RENTAL_COMPS_LOAD_FARM_FOR_DEFAULT_DISTANCE,
  RENTAL_COMPS_ADJUST_SAVE,
  RENTAL_COMPS_LIST_CHANGE_VIEW,
  RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE,
  RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT,
  RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT_SUCCESS
} from 'actions/rental-comps';
import { VR_RECOMPUTE_SUCCESS } from 'actions/edit-report';
import { MAP_DRAW_ADD_POLYGONS } from 'actions/map-draw';

import {
  getCompsFarmFromCompsState,
  getCompsSortFromCompsState,
  getCompsFiltersFromCompsState,
  getCompsAvailableIdsFromCompsState
} from 'selectors/comps-state';
import {
  sortComps,
  searchComps,
  filterSearchSortComps
} from 'legacy/services/comps-filters';

export const ACTION_MAPPING_COMPS = {
  TAG_ADD: COMPS_TAG_ADD,
  TAG_REMOVE: COMPS_TAG_REMOVE,
  TAG_FILTER: COMPS_TAG_FILTER,
  USER_VERSION_NOT_FOUND: GET_REPORT_USER_VERSION_NOT_FOUND,
  SHOW_ON_MAP: COMPS_SHOW_ON_MAP,
  SHOW_ON_MAP_CLEAR: COMPS_SHOW_ON_MAP_CLEAR,
  EFFECTIVE_DATE_GET_SUCCESS: EFFECTIVE_DATE_GET_REPORT_SUCCESS,
  COMP_PAGE_LOAD: ACTION_PAGE_LOAD_COMPS,
  GET_REPORT_SUCCESS: GET_REPORT_HC_VERSION_SUCCESS,
  GET_REPORT_USER_VERSION_SUCCESS_R: GET_REPORT_USER_VERSION_SUCCESS,
  REPORT_RECOMPUTE_SUCCESS: VR_RECOMPUTE_SUCCESS,
  COMP_SORT: COMPS_SORT,
  COMP_SORT_SELECTED: COMPS_SORT_SELECTED,
  COMP_CLEAR_SORT: COMPS_CLEAR_SORT,
  COMP_CLEAR_FILTER: COMPS_CLEAR_FILTER,
  COMP_CLEAR_ALL_FILTERS: COMPS_CLEAR_ALL_FILTERS,
  COMP_SEARCH: COMPS_SEARCH,
  COMP_SELECT_TOGGLE: COMPS_SELECT_TOGGLE,
  COMP_FOCUS_TOGGLE: COMPS_FOCUS_TOGGLE,
  COMP_SET_LIST_SCROLLED_TO_COMP: COMPS_SET_LIST_SCROLLED_TO_COMP,
  COMP_UNSELECT_ALL: COMPS_UNSELECT_ALL,
  COMP_AREA_UPDATE: [COMPS_FILTER_DISTANCE_CHANGE, COMPS_MAP_DRAW_SUBMIT],
  COMP_FARM_UPDATE_SUCCESS: COMPS_FARM_UPDATE_SUCCESS,
  COMP_ADD_SUCCESS: COMPS_ADD_COMP_SUCCESS,
  COMP_SEARCH_BY_MLS_NUMBER_SELECT_SUCCESS: SEARCH_BY_MLS_NUMBER_SELECT_SUCCESS,
  COMP_FILTERS_APPLY: COMPS_FILTERS_APPLY,
  UPDATE_SUBJECT_LOCATION_DEFAULT: MAP_DRAW_ADD_POLYGONS,
  COMP_ADJUST_SAVE: COMPS_ADJUST_SAVE,
  COMP_LIST_CHANGE_VIEW_SELECTED: COMPS_LIST_CHANGE_VIEW,
  COMP_FARM_REFRESH_READY_FOR_RECOMPUTE: COMPS_REFRESH_READY_FOR_RECOMPUTE,
  KEYWORD_SEARCH_SUBMIT: COMPS_KEYWORD_SEARCH_SUBMIT,
  KEYWORD_SEARCH_SUBMIT_SUCCESS: COMPS_KEYWORD_SEARCH_SUBMIT_SUCCESS
};

export const ACTION_MAPPING_RENTAL_COMPS = {
  USER_VERSION_NOT_FOUND: GET_REPORT_USER_VERSION_NOT_FOUND,
  SHOW_ON_MAP: RENTAL_COMPS_SHOW_ON_MAP,
  SHOW_ON_MAP_CLEAR: RENTAL_COMPS_SHOW_ON_MAP_CLEAR,
  COMP_PAGE_LOAD: ACTION_PAGE_LOAD_RENTAL_COMPS,
  GET_REPORT_SUCCESS: GET_REPORT_RENTAL_SUCCESS,
  GET_REPORT_USER_VERSION_SUCCSS_RENTAL: GET_REPORT_USER_VERSION_SUCCESS,
  COMP_ADD_SUCCESS: RENTAL_COMPS_ADD_COMP_SUCCESS,
  COMP_SORT: RENTAL_COMPS_SORT,
  COMP_SORT_SELECTED: RENTAL_COMPS_SORT_SELECTED,
  COMP_CLEAR_SORT: RENTAL_COMPS_CLEAR_SORT,
  COMP_CLEAR_ALL_FILTERS: RENTAL_COMPS_CLEAR_ALL_FILTERS,
  COMP_CLEAR_FILTER: RENTAL_COMPS_CLEAR_FILTER,
  COMP_SEARCH: RENTAL_COMPS_SEARCH,
  COMP_SELECT_TOGGLE: RENTAL_COMPS_SELECT_TOGGLE,
  COMP_FOCUS_TOGGLE: RENTAL_COMPS_FOCUS_TOGGLE,
  COMP_SET_LIST_SCROLLED_TO_COMP: RENTAL_COMPS_SET_LIST_SCROLLED_TO_COMP,
  COMP_UNSELECT_ALL: RENTAL_COMPS_UNSELECT_ALL,
  COMP_AREA_UPDATE: [
    RENTAL_COMPS_FILTER_DISTANCE_CHANGE,
    RENTAL_COMPS_MAP_DRAW_SUBMIT,
    RENTAL_COMPS_LOAD_FARM_FOR_DEFAULT_DISTANCE
  ],
  COMP_FARM_UPDATE_SUCCESS: RENTAL_COMPS_FARM_UPDATE_SUCCESS,
  COMP_FILTERS_APPLY: RENTAL_COMPS_FILTERS_APPLY,
  UPDATE_SUBJECT_LOCATION_RENTAL: MAP_DRAW_ADD_POLYGONS,
  COMP_ADJUST_SAVE: RENTAL_COMPS_ADJUST_SAVE,
  COMP_LIST_CHANGE_VIEW_SELECTED: RENTAL_COMPS_LIST_CHANGE_VIEW,
  COMP_FARM_REFRESH_READY_FOR_RECOMPUTE:
    RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE,
  KEYWORD_SEARCH_SUBMIT: RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT,
  KEYWORD_SEARCH_SUBMIT_SUCCESS: RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT_SUCCESS
};

export const INITIAL_SORT = {
  attr: 'similarityScoreAdjusted',
  order: 'desc', // or 'asc',
  clicks: 0 // Clears sort on the 3rd click
};

const _updatedAt = () => moment().format('YYYY-MM-DDTHH:mm:ssz');

const _distanceFilterValue = (polygons, state) => {
  const distance = !(polygons && polygons.length)
    ? state.filters.distance
    : polygons[0].properties.isSubjectCoordinates
    ? polygons[0].properties.radius / 1609.34
    : determineShapeTypeFromGeoJson(polygons[0]);
  return distance ? { distance } : {};
};

const INITIAL_STATE = {
  tagFilter: null,
  tags: {},
  showOnMap: null,
  filtersUpdatedFromUrl: false, // Flag to id when the url query params triggered a filter change
  filtersUpdatedFromUser: false, // Flag to indicate if a user has manually changed filters on the report
  filtersUpdatedFromPreferences: false, // Flag to indicate if filters updated from saved preferences
  farmFromUserVersion: false, // Flag to inform reducer if the user version contained a farm list
  farmUpdatedAt: _updatedAt(), // Used to know when to reposition the comps selction map to fit markers
  // Selection (These change based on user-input)
  focused: null,
  // trigger compare modal
  compare: false,
  compsListViewType: COMP_VIEW_CARD,
  compsListViewSelected: false,
  selected: [], // comps selected by the user
  available: [], // comps that match the filters and sort order
  listScrolledToCompId: null, // id of comp to be scrolled to the top of the comp list
  // Data
  farm: {},
  // UI
  search: {
    current: '',
    previous: '' // This is an optimization so the entire farm list doesn't get checked when a new search builds on a previous one
  },
  filters: {},
  sort: INITIAL_SORT,
  sortSelected: INITIAL_SORT,
  keywordSearch: {
    status: STATUSES.INIT,
    featureSupported: false,
    keywords: [],
    data: {}
  }
};

const _determineUpdatedSort = (action, sortState) => {
  let updatedSort;
  if (action.payload.attr !== sortState.attr) {
    // First click on a column
    updatedSort = {
      attr: action.payload.attr,
      order: action.payload.order,
      clicks: 1
    };
  } else if (action.payload.attr === sortState.attr && sortState.clicks < 2) {
    // Subsequent clicks on a column
    updatedSort = {
      attr: action.payload.attr,
      order: action.payload.order,
      clicks: sortState.clicks + 1
    };
  } else if (action.payload.attr === INITIAL_SORT.attr) {
    // Third click on a column
    updatedSort = {
      ...INITIAL_SORT,
      order: INITIAL_SORT.order === 'desc' ? 'asc' : 'desc',
      clicks: 1
    };
  } else {
    updatedSort = INITIAL_SORT;
  }
  return updatedSort;
};

const _getLatestFarmAndAvailableComps = (hcFarm, farm, selected) => {
  let updatedFarm = { ...hcFarm };
  selected.forEach((s) => {
    updatedFarm[s] = farm[s];
  });
  return {
    farm: updatedFarm,
    farmUpdatedAt: _updatedAt(),
    available: Object.keys(updatedFarm).map((i) => parseInt(i))
  };
};

export default (state = INITIAL_STATE, action) => {
  // vars to make code more readable
  let report, farm, selected, search, polygons;
  switch (action.type) {
    case 'TAG_ADD': {
      const { addressId, tag } = action.payload;
      const updatedState = {
        ...state,
        tags: { ...state.tags }
      };
      if (!updatedState.tags[tag]) {
        updatedState.tags[tag] = {};
      }
      updatedState.tags[tag] = {
        ...updatedState.tags[tag],
        [addressId]: true
      };
      return updatedState;
    }

    case 'TAG_REMOVE': {
      const { addressId, tag } = action.payload;
      const updatedState = {
        ...state,
        tags: { ...state.tags }
      };
      if (updatedState.tags[tag]) {
        updatedState.tags[tag] = { ...updatedState.tags[tag] };
        delete updatedState.tags[tag][addressId];
        if (!Object.keys(updatedState.tags[tag]).length) {
          delete updatedState.tags[tag];
          if (tag === updatedState.tagFilter) {
            updatedState.tagFilter = null;
          }
        }
      }
      return updatedState;
    }

    case 'TAG_FILTER': {
      return {
        ...state,
        tagFilter: action.payload.tag
      };
    }

    case 'USER_VERSION_NOT_FOUND': {
      if (action.payload.fromShared) {
        return {
          ...state,
          available: Object.keys(state.farm).map((v) => parseInt(v))
        };
      } else {
        return state;
      }
    }
    case 'SHOW_ON_MAP': {
      return {
        ...state,
        showOnMap: action.payload
      };
    }

    case 'SHOW_ON_MAP_CLEAR': {
      return {
        ...state,
        showOnMap: null
      };
    }

    case 'COMP_PAGE_LOAD':
      const { query = {} } = action.payload;
      const compsListViewType = query.hasOwnProperty('viewType')
        ? query.viewType
        : state.compsListViewType;
      const compsListViewSelected =
        compsListViewType === COMP_VIEW_COLUMNS
          ? true
          : query.hasOwnProperty('viewSelected')
          ? query.viewSelected === 'true'
          : state.compsListViewSelected;
      return {
        ...state,
        compsListViewType,
        compsListViewSelected,
        tagFilter:
          !compsListViewSelected && state.compsListViewSelected
            ? null
            : state.tagFilter
      };

    case 'GET_REPORT_SUCCESS':
      report = action.payload.report;
      const hcVersionHasFarm =
        report.compsFarmList && report.compsFarmList.length;
      farm =
        hcVersionHasFarm &&
        isLocationKnown(report.subject.geoPrecision) &&
        !state.farmFromUserVersion
          ? keyBy(report.compsFarmList, COMP_ID_KEY)
          : state.farm;
      return {
        ...state,
        farmUpdatedAt: hcVersionHasFarm ? _updatedAt() : state.farmUpdatedAt,
        farm
      };

    case 'GET_REPORT_USER_VERSION_SUCCSS_RENTAL':
      const selectedRentalComps = get(
        action,
        ['payload', 'report', 'metadata', 'selectedRentalComps'],
        {}
      );
      selected = get(
        action,
        ['payload', 'report', 'metadata', 'selectedRentalCompsByAddressId'],
        []
      );
      const rentalFarm = get(
        action,
        ['payload', 'report', 'metadata', 'rentalCompFarm'],
        {}
      );
      if (isEmpty(rentalFarm)) {
        farm = {
          ...state.farm,
          ...selectedRentalComps
        };
      } else {
        farm = {
          ...rentalFarm,
          ...selectedRentalComps
        };
      }
      // Parse geoJSON to determine initial distance filter value
      polygons = get(
        action,
        ['payload', 'report', 'metadata', 'rentalCompFarmAreaPolygons'],
        []
      );
      return {
        ...state,
        filters: {
          ...get(
            action,
            ['payload', 'report', 'metadata', 'activeFiltersRentalComps'],
            {}
          ),
          ..._distanceFilterValue(polygons, state)
        },
        farmFromUserVersion: !isEmpty(rentalFarm),
        farmUpdatedAt: _updatedAt(),
        selected,
        farm,
        filtersUpdatedFromUser: get(
          action,
          ['payload', 'report', 'metadata', 'rentalCompsFiltersUpdatedByUser'],
          false
        ),
        farmRefreshedAt: get(action, [
          'payload',
          'report',
          'metadata',
          'rentalCompFarmRefreshedAt'
        ]),
        keywordSearch: {
          ...state.keywordSearch,
          featureSupported: Object.keys(rentalFarm).some(
            (addressId) => rentalFarm[addressId].cerberusId
          )
        }
      };

    case 'EFFECTIVE_DATE_GET_SUCCESS':
    case 'GET_REPORT_USER_VERSION_SUCCESS_R':
      report = action.payload.report;
      farm = {};
      // Used for old reports that use metadata.selectedComps
      let oldIdMapping = {};
      forEach(report.compsFarmList, (comp) => {
        farm[comp[COMP_ID_KEY]] = comp;
        oldIdMapping[comp.comp] = comp[COMP_ID_KEY];
      });

      selected =
        get(
          report,
          ['metadata', 'selectedCompsByAddressId'],
          (() => {
            let selectedAddressIds = [];
            const oldCompIds = get(report, ['metadata', 'selectedComps'], []);
            if (oldCompIds.length) {
              // Get the new ids
              forEach(oldCompIds, (oldCompId) => {
                const newId = oldIdMapping[oldCompId];
                if (newId) {
                  selectedAddressIds.push(newId);
                }
              });
            }
            return selectedAddressIds;
          })()
        ) || [];
      farm = keyBy(report.compsFarmList, COMP_ID_KEY);
      const filters = {
        ...get(
          action,
          ['payload', 'report', 'metadata', 'activeFiltersComps'],
          {}
        ),
        ..._distanceFilterValue(polygons, state)
      };
      // Parse geoJSON to determine initial distance filter value
      polygons = get(
        action,
        ['payload', 'report', 'metadata', 'compFarmAreaPolygons'],
        []
      );

      const tags = {};
      if (report.metadata.compTags) {
        report.metadata.compTags.forEach(([key, value]) => {
          tags[key] = value;
        });
      }
      return {
        ...state,
        tags,
        filters,
        farmFromUserVersion: true,
        farmUpdatedAt: _updatedAt(),
        filtersUpdatedFromUser: get(
          action,
          ['payload', 'report', 'metadata', 'compsFiltersUpdatedByUser'],
          false
        ),
        farmRefreshedAt: get(action, [
          'payload',
          'report',
          'metadata',
          'compFarmRefreshedAt'
        ]),
        selected,
        farm,
        keywordSearch: {
          ...state.keywordSearch,
          featureSupported: (report.compsFarmList || []).some(
            (comp) => comp.cerberusId
          )
        }
      };

    case 'REPORT_RECOMPUTE_SUCCESS': {
      report = action.payload.report;
      farm = keyBy(report.compsFarmList, COMP_ID_KEY);
      return {
        ...state,
        farmUpdatedAt: _updatedAt(),
        farm
      };
    }

    case 'COMP_SEARCH_BY_MLS_NUMBER_SELECT_SUCCESS': {
      return {
        ...state,
        farm: {
          ...state.farm,
          [action.payload.comp.addressId]: action.payload.comp
        },
        focused: action.payload.comp.addressId,
        farmUpdatedAt: _updatedAt()
      };
    }
    case 'COMP_ADD_SUCCESS': {
      const compToAdd = {
        ...action.payload.comp
      };
      farm = {
        ...state.farm,
        [compToAdd[COMP_ID_KEY]]: compToAdd
      };
      search = {
        ...state.search,
        previous: '' // Reset previous because comps list will be re-filtered
      };
      return {
        ...state,
        farm,
        farmUpdatedAt: _updatedAt()
      };
    }

    case 'COMP_LIST_CHANGE_VIEW':
      return {
        ...state,
        compsListViewSelected: action.payload.viewSelected,
        compsListViewType: action.payload.viewType,
        tagFilter:
          state.compsListViewSelected && !action.payload.viewSelected
            ? null
            : state.tagFilter
      };

    case 'COMP_SORT':
      let updatedSort = _determineUpdatedSort(action, state.sort);
      return {
        ...state,
        sort: updatedSort,
        available: sortComps(
          getCompsAvailableIdsFromCompsState(state),
          getCompsFarmFromCompsState(state),
          updatedSort,
          undefined,
          undefined
        )
      };

    case 'COMP_SORT_SELECTED':
      let updatedSortSelected = _determineUpdatedSort(
        action,
        state.sortSelected
      );
      return {
        ...state,
        sortSelected: updatedSortSelected,
        selected: sortComps(
          state.selected,
          getCompsFarmFromCompsState(state),
          updatedSortSelected,
          undefined,
          undefined
        )
      };

    case 'COMP_CLEAR_SORT':
      return {
        ...state,
        sort: INITIAL_SORT,
        sortSelected: INITIAL_SORT,
        available: sortComps(
          getCompsAvailableIdsFromCompsState(state),
          getCompsFarmFromCompsState(state),
          INITIAL_SORT,
          undefined,
          undefined
        ),
        selected: sortComps(
          state.selected,
          getCompsFarmFromCompsState(state),
          INITIAL_SORT,
          undefined,
          undefined
        )
      };

    // When a user clicks "See n Homes"
    case 'COMP_FILTERS_APPLY':
      return {
        ...state,
        available: action.payload.available,
        // payload is being spread because of a createSelectors issue in getCompsFiltersApplied
        filters: {
          ...action.payload.filters
        },
        filtersUpdatedFromUser: true
      };

    case 'COMP_FILTER_UPDATE':
      search = {
        ...state.search,
        previous: '' // Reset previous because comps list will be re-filtered
      };
      return {
        ...state,
        search: search,
        filters: {
          ...state.filters,
          ...validateFilters(action.payload.filters)
        },
        filtersUpdatedFromPreferences: action.payload.updatedFromPreferences
      };

    case 'COMP_CLEAR_FILTER': {
      const clearedFilters = { ...state.filters };
      delete clearedFilters[action.payload.filterKey];
      return {
        ...state,
        ...// apply updated { farm, farmUpdateAt, available } when 'distance' was cleared
        (action.payload.filterKey === 'distance'
          ? _getLatestFarmAndAvailableComps(
              state.hcFarm,
              state.farm,
              state.selected
            )
          : {}),
        filters: clearedFilters,
        filtersUpdatedFromUser: true
      };
    }

    case 'COMP_CLEAR_ALL_FILTERS': {
      const { farm, farmUpdatedAt } = _getLatestFarmAndAvailableComps(
        state.hcFarm,
        state.farm,
        state.selected
      );
      search = {
        ...state.search,
        previous: '' // Reset previous because comps list will be re-filtered
      };
      return {
        ...state,
        search: search,
        farm,
        farmUpdatedAt,
        filters: {},
        filtersUpdatedFromUser: true
      };
    }

    case 'COMP_SEARCH':
      search = {
        ...state.search,
        previous: '' // Reset previous because comps list will be re-filtered
      };
      return {
        ...state,
        search: {
          current: action.payload.searchStr,
          previous: state.search.current
        },
        available: searchComps(
          getCompsAvailableIdsFromCompsState(state),
          getCompsFarmFromCompsState(state),
          search,
          getCompsFiltersFromCompsState(state),
          getCompsSortFromCompsState(state),
          undefined
        )
      };

    case 'COMP_SELECT_TOGGLE':
      let selectedUpdated = [...state.selected];
      const compId = action.payload.comp[COMP_ID_KEY];
      if (selectedUpdated.indexOf(compId) > -1) {
        remove(selectedUpdated, (id) => id === compId);
      } else {
        selectedUpdated.push(compId);
      }
      // Unset viewSelected if last comp is unselected
      const newViewSelected =
        selectedUpdated.length === 0 ? false : state.compsListViewSelected;
      const newTagFilter =
        selectedUpdated.length === 0 ? null : state.tagFilter;
      const newViewType =
        selectedUpdated.length === 0 &&
        state.compsListViewType === COMP_VIEW_COLUMNS
          ? COMP_VIEW_CARD
          : state.compsListViewType;
      return {
        ...state,
        selected: selectedUpdated,
        compsListViewSelected: newViewSelected,
        tagFilter: newTagFilter,
        compsListViewType: newViewType
      };

    case 'COMP_FOCUS_TOGGLE':
      return {
        ...state,
        focused: state.focused
          ? INITIAL_STATE.focused
          : action.payload.comp[COMP_ID_KEY]
      };

    case 'COMP_SET_LIST_SCROLLED_TO_COMP':
      return {
        ...state,
        listScrolledToCompId: get(action.payload.comp, COMP_ID_KEY, null)
      };

    case 'COMP_UNSELECT_ALL':
      return {
        ...state,
        selected: []
      };

    case 'COMP_AREA_UPDATE':
      return {
        ...state,
        filters: {
          ...state.filters,
          ..._distanceFilterValue(action.payload.polygons, state)
        }
      };

    case 'COMP_FARM_UPDATE_SUCCESS': {
      farm = {};
      state.selected.forEach((s) => {
        if (s !== null && s !== undefined) {
          const selected = state.farm[s];
          farm[selected[COMP_ID_KEY]] = selected;
        }
      });
      action.payload.farm.forEach((comp) => {
        farm[comp[COMP_ID_KEY]] = comp;
        if (action.payload.isRentalComp) {
          farm[comp[COMP_ID_KEY]].isRentalComp = true;
        }
      });
      action.payload.existingAddressIds.forEach((addressId) => {
        const existingComp = get(state, ['farm', addressId]);
        if (existingComp) {
          farm[addressId] = existingComp;
          if (action.payload.isRentalComp) {
            farm[addressId].isRentalComp = true;
          }
        }
      });
      return {
        ...state,
        farmUpdatedAt: _updatedAt(),
        farm,
        keywordSearch: {
          ...state.keywordSearch,
          featureSupported: action.payload.farm.some((comp) => comp.cerberusId)
        }
      };
    }

    case 'UPDATE_SUBJECT_LOCATION_DEFAULT':
      let polygonsDefault = action.payload.polygonsByMapId[MAP_ID_COMPS];
      if (!polygonsDefault || !polygonsDefault.length) {
        return state;
      } else {
        return {
          ...state,
          filters: {
            ...state.filters,
            ..._distanceFilterValue(polygonsDefault, state)
          }
        };
      }

    case 'UPDATE_SUBJECT_LOCATION_RENTAL':
      let polygonsRental = action.payload.polygonsByMapId[MAP_ID_RENTAL_COMPS];
      if (!polygonsRental || !polygonsRental.length) {
        return state;
      } else {
        return {
          ...state,
          filters: {
            ...state.filters,
            ..._distanceFilterValue(polygonsRental, state)
          }
        };
      }

    case 'COMP_ADJUST_SAVE':
      return {
        ...state,
        farmUpdatedAt: _updatedAt()
      };

    case 'COMP_FARM_REFRESH_READY_FOR_RECOMPUTE': {
      const farm = keyBy(action.payload.userCompsFarmList, 'addressId');
      return {
        ...state,
        farmUpdatedAt: _updatedAt(),
        farmRefreshedAt: _updatedAt(),
        available: filterSearchSortComps(
          farm,
          getAbsoluteFilterValueMapping(state.filters),
          state.search,
          state.sort
        ),
        farm,
        keywordSearch: {
          ...state.keywordSearch,
          featureSupported: action.payload.userCompsFarmList.some(
            (comp) => comp.cerberusId
          )
        }
      };
    }

    case 'KEYWORD_SEARCH_SUBMIT': {
      return {
        ...state,
        keywordSearch: {
          ...state.keywordSearch,
          status: STATUSES.LOADING,
          keywords: action.payload.keywords,
          data: {}
        }
      };
    }

    case 'KEYWORD_SEARCH_SUBMIT_SUCCESS': {
      return {
        ...state,
        keywordSearch: {
          ...state.keywordSearch,
          status: STATUSES.SUCCESS,
          data: {
            matchesByAddressId: action.payload.matchesByAddressId
          }
        }
      };
    }

    default:
      return state;
  }
};
