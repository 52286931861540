export const degreesToRadians = (degrees: number | undefined) => {
  if (degrees !== undefined) {
    const radians = degrees % 360;
    return (radians * Math.PI) / 180;
  }
  return undefined;
};

export const radiansToDegrees = (radians: number | undefined) => {
  if (radians !== undefined) {
    const degrees = radians % (2 * Math.PI);
    return (degrees * 180) / Math.PI;
  }
  return undefined;
};

export const isPositive = (value?: number) => value && value >= 0;
