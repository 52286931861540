// Personalization is the personalization data for the logged in user.
// Cobranding is the personalization data for the user who created the report.

import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import forIn from 'lodash/forIn';
import forEach from 'lodash/forEach';

import { FORM_NAME as PERSONALIZATION_FORM_NAME } from 'legacy/forms/personalization-form';
import { STATUSES } from 'legacy/appstore/constants';

/* *********** Shared Logic *********** */

const _showAny = (personalizationData) => {
  let show = false;
  // Check for include_* values. If any fields are included, show personalization
  forIn(personalizationData, (v, k) => {
    if (k.slice(0, 7) === 'include' && v) {
      show = true;
      return false;
    }
  });
  return show;
};

const _showContactInfo = (personalizationData) => {
  let show = false;
  forEach(
    [
      'includeName',
      'includePhone',
      'includeEmail',
      'includeCompanyName',
      'includeLicenseNumber'
    ],
    (k) => {
      if (personalizationData[k]) {
        show = true;
        return false;
      }
    }
  );
  return show;
};

/* *********** Personalization *********** */

export const getPersonalizationState = (state) => state.personalization;

export const getIsFormModalOpen = createSelector(
  getPersonalizationState,
  (personalizationState) => personalizationState.formModalOpen
);

export const getPersonalizationValues = createSelector(
  getPersonalizationState,
  (personalizationState) => personalizationState.values
);

export const getPersonalizationStatus = createSelector(
  getPersonalizationState,
  (personalizationState) => personalizationState.status
);

export const getPersonalizationUploadStatusObj = createSelector(
  getPersonalizationState,
  (personalizationState) => personalizationState.upload
);

export const getPersonalizationFormValues = formValueSelector(
  PERSONALIZATION_FORM_NAME
);

export const isPersonalizationDataLoaded = createSelector(
  getPersonalizationStatus,
  (personalizationStatus) =>
    personalizationStatus === STATUSES.SUCCESS ||
    personalizationStatus === STATUSES.DOES_NOT_EXIST
);

export const hasNeverEnteredPersonalizationData = createSelector(
  getPersonalizationStatus,
  (personalizationStatus) => personalizationStatus === STATUSES.DOES_NOT_EXIST
);

export const showPersonalizationData = createSelector(
  getPersonalizationValues,
  getPersonalizationStatus,
  (values, status) => {
    let show = false;
    if (status !== STATUSES.DOES_NOT_EXIST) {
      show = _showAny(values);
    }
    return show;
  }
);

export const showPersonalizationContactInfo = createSelector(
  getPersonalizationValues,
  (values) => _showContactInfo(values)
);

export const getPersonalizationPromptIsOpen = createSelector(
  getPersonalizationState,
  (personalizationState) => personalizationState.promptIsOpen
);

export const getPersonalizationPromptAltActionType = createSelector(
  getPersonalizationState,
  (personalizationState) => personalizationState.altActionType
);

/* *********** Cobranding *********** */
export const getCobrandingData = (state) => {
  let data = state.cobranding || {};
  let fullName = '';
  if (data.firstName) {
    fullName = `${data.firstName} `;
  }
  if (data.lastName) {
    fullName = `${fullName}${data.lastName}`;
  }
  return {
    ...data,
    fullName: fullName
  };
};

export const getContactButtonLabel = createSelector(
  getCobrandingData,
  (cobrandingData) => `Contact ${cobrandingData.firstName}`
);

export const getContactButtonHRef = createSelector(
  getCobrandingData,
  (cobrandingData) =>
    cobrandingData.email
      ? `mailto:${cobrandingData.email}`
      : `tel:${cobrandingData.phone}`
);

export const showCobrandingData = createSelector(
  getCobrandingData,
  (cobrandingData) => _showAny(cobrandingData)
);

export const showCobrandingContactInfo = createSelector(
  getCobrandingData,
  (cobrandingData) => _showContactInfo(cobrandingData)
);
