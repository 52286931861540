import {
  AVM_KEY_HC,
  AVM_KEY_USER,
  AVM_KEY_COMPS,
  AVM_KEY_COMPS_AVG,
  AVM_KEY_USER_ENTERED,
  CHART_DATA_TYPES
} from 'legacy/appstore/constants';

import {
  computeChartDataNearbyProperties,
  computeChartDataMarketIndex,
  formatChartDataMarket
} from 'legacy/services/chart-data';

import {
  GET_REPORT_HC_VERSION_SUCCESS,
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_NEARBY_PROPERTIES_SUCCESS
} from 'actions/get-report';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';

import {
  CHART_DATA_VALUATION_CHART_TYPE_SELECT,
  CHART_DATA_NEIGHBORHOOD_CHART_TYPE_SELECT,
  CHART_DATA_AVAILABLE_COMPS_RECOMPUTED,
  CHART_DATA_FORECAST_CHART_RECOMPUTED,
  CHART_DATA_NEIGHBORHOOD_CHART_DATA_SELECT
} from 'actions/chart-data';

import { setSimilarPropertiesPercentileValues } from 'legacy/services/chart-data.service';

const INITIAL_STATE = {
  selectedValuationChartType: 'nearby',
  selectedNeighborhoodChartType: 'nearby',
  selectedNeighborhoodChartData: 'sqFt',
  [AVM_KEY_HC]: {},
  [AVM_KEY_USER]: {},
  [AVM_KEY_COMPS]: {},
  [AVM_KEY_COMPS_AVG]: {},
  [AVM_KEY_USER_ENTERED]: {}
};

const _updateBlockHpiDataState = (
  state,
  avmKey,
  computedDataBlock,
  computedDataZip
) => {
  if (computedDataBlock.full.length || computedDataZip.full.length) {
    return {
      ...state[avmKey],
      [CHART_DATA_TYPES.BLOCK_TS]: computedDataBlock,
      [CHART_DATA_TYPES.ZIP_TS]: computedDataZip
    };
  } else {
    return state[avmKey];
  }
};

const _populatePercentileChartData = (comps, isEffectiveDateReport) => {
  return comps
    ? setSimilarPropertiesPercentileValues(comps, isEffectiveDateReport)
    : null;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHART_DATA_VALUATION_CHART_TYPE_SELECT: {
      return {
        ...state,
        selectedValuationChartType: action.payload.chartType
      };
    }

    case CHART_DATA_NEIGHBORHOOD_CHART_TYPE_SELECT: {
      return {
        ...state,
        selectedNeighborhoodChartType: action.payload.chartType
      };
    }

    case CHART_DATA_NEIGHBORHOOD_CHART_DATA_SELECT:
      return {
        ...state,
        selectedNeighborhoodChartData: action.payload.key
      };

    case CHART_DATA_AVAILABLE_COMPS_RECOMPUTED: {
      return {
        ...state,
        availableComps: action.payload.chartData
      };
    }

    case EFFECTIVE_DATE_GET_REPORT_SUCCESS: {
      return {
        ...state,
        [AVM_KEY_HC]: {
          ...state[AVM_KEY_HC],
          ...computeChartDataNearbyProperties(
            action.payload.report.compsFarmList
          ),
          similarProperties: {
            ..._populatePercentileChartData(
              action.payload.report.compsFarmList,
              true
            )
          }
        }
      };
    }

    case GET_REPORT_HC_VERSION_SUCCESS:
      return {
        ...state,
        [AVM_KEY_HC]: {
          ...state[AVM_KEY_HC],
          daysOnMarket: formatChartDataMarket(
            action.payload.report.chartData.marketDaysOnMarketChart
          ),
          monthsOfSupply: formatChartDataMarket(
            action.payload.report.chartData.marketMonthsOfSupplyChart
          ),
          marketIndex: computeChartDataMarketIndex(
            action.payload.report.chartData.marketIndexChart
          ),
          msaDetails: action.payload.report.msaDetails,
          riskOfDecline:
            action.payload.report.chartData.marketRiskOfDeclineChart
        }
      };

    case GET_REPORT_NEARBY_PROPERTIES_SUCCESS:
      return {
        ...state,
        [AVM_KEY_HC]: {
          ...state[AVM_KEY_HC],
          ...computeChartDataNearbyProperties(action.payload.nearbyProperties),
          similarProperties: {
            ..._populatePercentileChartData(action.payload.nearbyProperties)
          }
        }
      };

    case GET_REPORT_USER_VERSION_SUCCESS:
      if (action.payload.report.nearbyProperties) {
        return {
          ...state,
          [AVM_KEY_HC]: {
            ...state[AVM_KEY_HC],
            ...computeChartDataNearbyProperties(
              action.payload.report.nearbyProperties
            ),
            similarProperties: {
              ..._populatePercentileChartData(
                action.payload.report.nearbyProperties
              )
            }
          }
        };
      } else {
        return state;
      }

    case CHART_DATA_FORECAST_CHART_RECOMPUTED: {
      const updatedState = { ...state };
      for (const avmKey in action.payload) {
        const { computedDataBlock, computedDataZip } = action.payload[avmKey];
        updatedState[avmKey] = _updateBlockHpiDataState(
          updatedState,
          avmKey,
          computedDataBlock,
          computedDataZip
        );
      }
      return updatedState;
    }

    default:
      return state;
  }
};
