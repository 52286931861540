import { CompSchema, SubjectSchema } from '../types/schemas';

export const isCompSchema = (
  schema: CompSchema | SubjectSchema
): schema is CompSchema => {
  if ((schema as CompSchema).similarity) {
    return true;
  }
  return false;
};

export const isSubjectSchema = (
  schema: CompSchema | SubjectSchema
): schema is SubjectSchema => !isCompSchema(schema);
