import React from 'react';
import { Link } from '@hc/redux-saga-router-plus';
import { VIEWS } from 'src/pexp/routes';
import classNames from 'classnames';
import { BackButton } from './BackButton';
import styles from './BackToReportLink.css';

interface Props {
  dataHcName: string;
  className?: string;
}
export const BackToReportLink = ({ dataHcName, className }: Props) => {
  return (
    <Link
      data-hc-name={dataHcName}
      className={classNames(styles.BackToReportLink, className)}
      view={VIEWS.EFFECTIVE_DATE_REPORT}
      options={{ inherit: true }}
    >
      <BackButton dataHcName={dataHcName} />
    </Link>
  );
};
