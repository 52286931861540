import accounting from 'accounting';
import Pluralize from 'pluralize';

export const PLACEHOLDER = '--';

export const acresFormatter = (value) => {
  if (!value) return `${PLACEHOLDER} acres`;
  const acres = value / 43560;
  return `${numberFormatter(acres, { precision: 2 })} Acre${
    acres > 1 ? 's' : ''
  }`;
};
export const moneyFormatter = (value, options) =>
  accounting.formatMoney(value, { ...options });
export const dollarsFormatter = (value, precision = 0) =>
  moneyFormatter(value, { precision });
export const abbrDollarsFormatter = (value) => `$${abbrNumberFormatter(value)}`;
export const abbrShortDollarsFormatter = (value) =>
  `${abbrNumberFormatter(value)}`;
export const abbrNumberFormatter = (value) => {
  if (!value && value !== 0) {
    return PLACEHOLDER;
  }
  if (value >= 1000000) {
    return value && `${accounting.toFixed(value / 1000000, 1)}M`;
  } else if (value >= 20000) {
    return value && `${accounting.toFixed(value / 1000, 0)}K`;
  } else {
    return value && `${accounting.toFixed(value / 1000, 1)}K`;
  }
};

export const unformatNumber = (value) => accounting.unformat(value);

export const numberFormatter = (value, options) =>
  accounting.formatNumber(value, { ...options });
export const numberIgnoreEmptyFormatter = (value, options) =>
  value ? numberFormatter(value, options) : value;
export const wholeNumberFormatter = (value) =>
  numberFormatter(value, { precision: 0 });
export const wholeNumberFormatterPlaceholder = (value) =>
  value === 0 || value === null
    ? placeholderFormatter(null)
    : wholeNumberFormatter(value);
export const wholeNumberIgnoreEmptyFormatter = (value, options) =>
  value ? numberIgnoreEmptyFormatter(value, { precision: 0 }) : value;

export const placeholderFormatter2 = (v) =>
  [null, undefined].includes(v) ? PLACEHOLDER : v;
export const placeholderFormatter = (value, placeholder = PLACEHOLDER) =>
  value || placeholder;
export const suffixFormatter = (value, suffix) =>
  value ? `${value} ${suffix}` : value;
export const booleanFormatter = (
  value,
  trueValue = 'Yes',
  falseValue = 'No',
  undefinedValue = 'Unknown'
) =>
  value
    ? trueValue
    : value === null || value === undefined
    ? undefinedValue
    : falseValue;

export const withPlaceholderFormatter =
  (filter) => (value, option, placeholder) =>
    value ? filter(value, option) : placeholder || PLACEHOLDER;
export const suffixWithPlaceholderFormatter =
  withPlaceholderFormatter(suffixFormatter);
export const booleanWithPlaceholderFormatter =
  withPlaceholderFormatter(booleanFormatter);
export const numberWithPlaceholderFormatter = withPlaceholderFormatter(
  numberIgnoreEmptyFormatter
);
export const wholeNumberWithPlaceholderFormatter = withPlaceholderFormatter(
  wholeNumberIgnoreEmptyFormatter
);
export const dollarsWithPlaceholderFormatter =
  withPlaceholderFormatter(dollarsFormatter);

export const withSuffixFormatter = (filter) => (value, suffix) =>
  suffixFormatter(filter(value), suffix);
export const wholeNumberWithSuffixPlaceholderFormatter = withSuffixFormatter(
  wholeNumberWithPlaceholderFormatter
);

export const streetAddressFormatter = (street, unit, unitDesignator) => {
  const unitDisplay = unit
    ? unitDesignator
      ? ` ${unitDesignator} ${unit}`
      : ` ${unit}`
    : '';
  return `${street || ''}${unitDisplay}`;
};

export const pluralize = (string, number) => Pluralize(string, number);

export const monthlyValueFormatter = (val, isMonthlyValue) => {
  const dollars = withPlaceholderFormatter(dollarsFormatter)(val);
  let displayDollars = dollars === PLACEHOLDER ? `$ ${dollars}` : dollars;
  return isMonthlyValue ? `${displayDollars}/month` : displayDollars;
};

export const dollarsPerMonthFormatter = (v) => `${dollarsFormatter(v)}/mo.`;

export const noFormat = (v) =>
  v !== undefined && v !== null && v !== '' ? v : '--';

export const boolYesNo = (rawValue) =>
  rawValue === false ? 'No' : rawValue === true ? 'Yes' : '--';
