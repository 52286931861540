import { EVENTS } from 'legacy/appstore/events';

import { getSubjectPropertyDetailsChanged } from 'selectors/analytics';

export const SAVE_REPORT = 'SAVE_REPORT';
export const SAVE_REPORT_QUEUE = 'SAVE_REPORT_QUEUE';
export const SAVE_REPORT_EXECUTE = 'SAVE_REPORT_EXECUTE';
export const SAVE_REPORT_SUCCESS = 'SAVE_REPORT_SUCCESS';
export const SAVE_REPORT_COMPLETE = 'SAVE_REPORT_COMPLETE';
export const VR_RECOMPUTE = 'VR_RECOMPUTE';
export const VR_RECOMPUTE_SUCCESS = 'VR_RECOMPUTE_SUCCESS';
export const VALUE_REPORT_EDIT_PROPERTY_DETAILS =
  'VALUE_REPORT_EDIT_PROPERTY_DETAILS';
export const VALUE_REPORT_EDIT_PROPERTY_DETAILS_REVERT =
  'VALUE_REPORT_EDIT_PROPERTY_DETAILS_REVERT';
export const VALUE_REPORT_EXECUTE_SAVE = 'VALUE_REPORT_EXECUTE_SAVE';

// This is the only save report action that should be called from anywhere other than save-report sagas
// All save queing and execution logic is handled by the sagas
export const saveReport = (saveOnlyIfLoggedIn) => {
  return {
    type: SAVE_REPORT,
    payload: { saveOnlyIfLoggedIn }
  };
};

// Only call from save-report sagas
export const saveReportQueue = () => {
  return {
    type: SAVE_REPORT_QUEUE
  };
};

// Only call from save-report sagas
export const saveReportExecute = () => {
  return {
    type: SAVE_REPORT_EXECUTE
  };
};

// Only call from save-report sagas
export const saveReportSuccess = (subject, payload) => {
  return {
    type: SAVE_REPORT_SUCCESS,
    payload
  };
};

// Only call from save-report sagas
export const saveReportComplete = () => {
  return {
    type: SAVE_REPORT_COMPLETE,
    meta: {
      toast: {
        message: 'Your custom report has been saved!',
        dataHcName: 'save-message-toast'
      }
    }
  };
};

export const valueReportSubjectAdjustPropertyDetails = (subjectUpdate = {}) => {
  return {
    type: VR_RECOMPUTE,
    payload: { subjectUpdate },
    meta: {
      analytics: {
        event: EVENTS.REPORT_SUBJECT_ADJUST_PROPERTY_DETAILS,
        propsSelector: getSubjectPropertyDetailsChanged
      }
    }
  };
};

export const valueReportAdjustSubjectCondition = (subjectUpdate = {}) => {
  return {
    type: VR_RECOMPUTE,
    payload: { subjectUpdate },
    meta: {
      analytics: {
        event: EVENTS.REPORT_ADJUST_SUBJECT_CONDITION_UPDATE,
        props: {
          condition: subjectUpdate.quality
        }
      }
    }
  };
};

export const vrRecompute = (subjectUpdate = {}, recomputeHints) => ({
  type: VR_RECOMPUTE,
  payload: {
    subjectUpdate,
    recomputeHints
  }
});

export const propertyExplorerRecomputeSuccess = (report, toast = {}) => ({
  type: VR_RECOMPUTE_SUCCESS,
  payload: { report },
  meta: { toast }
});

export const vrEditPropertyDetails = () => ({
  type: VALUE_REPORT_EDIT_PROPERTY_DETAILS
});

export const vrEditPropertyDetailsRevert = () => ({
  type: VALUE_REPORT_EDIT_PROPERTY_DETAILS_REVERT
});
