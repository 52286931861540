import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REPORT_API_REDUCER_KEY } from '../../constants';
import { StateWithReportApi } from '../../reducer';
import { CompFields } from '../../types/CompFields';
import {
  compFilterButtonVisibilityChange,
  compFilterOverflowVisibilityChange,
  compFilterOverflowPortalMounted,
  compFilterOverflowButtonVisibilityChange
} from './compFilterBar.actions';
import { COMP_FILTER_BAR_REDUCER_KEY } from './compFilterBar.reducer';

export const useCompFilterBarState = () => {
  const dispatch = useDispatch();
  const compFilterBarState = useSelector(
    (state: StateWithReportApi) =>
      state[REPORT_API_REDUCER_KEY][COMP_FILTER_BAR_REDUCER_KEY]
  );
  const { overflowCount, overflowVisible } = compFilterBarState;

  // Close the overflow element if all filters are visible
  useEffect(() => {
    if (!overflowCount && overflowVisible) {
      dispatch(compFilterOverflowVisibilityChange(false));
    }
  }, [overflowCount]);

  return {
    compFilterBarState,
    compFilterBarActions: {
      compFilterOverflowPortalMounted: (portalId: string) => {
        dispatch(compFilterOverflowPortalMounted(portalId));
      },
      compFilterOverflowVisibilityChange: (visible: boolean) => {
        dispatch(compFilterOverflowVisibilityChange(visible));
      },
      compFilterOverflowButtonVisibilityChange: (visible: boolean) => {
        dispatch(compFilterOverflowButtonVisibilityChange(visible));
      },
      compFilterButtonVisibilityChange: (
        compField: CompFields,
        visible: boolean
      ) => {
        dispatch(compFilterButtonVisibilityChange(compField, visible));
      }
    }
  };
};
