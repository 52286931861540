export const PERFORMANCE_REPORT_PULL_START = 'PERFORMANCE_REPORT_PULL_START';
export const PERFORMANCE_REPORT_PULL_COMPLETE =
  'PERFORMANCE_REPORT_PULL_COMPLETE';

export const performanceReportPullStart = () => {
  const d = new Date();
  return {
    type: PERFORMANCE_REPORT_PULL_START,
    payload: {
      time: `${d.toTimeString().split(' ')[0]}:${d.getMilliseconds()}`
    }
  };
};

export const performanceReportPullComplete = () => {
  const d = new Date();
  return {
    type: PERFORMANCE_REPORT_PULL_COMPLETE,
    payload: {
      time: `${d.toTimeString().split(' ')[0]}:${d.getMilliseconds()}`
    }
  };
};
