import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextInputPropertyDetails from 'legacy/components/TextInputPropertyDetails';

const YEAR_MAX = new Date().getFullYear();
const YEAR_MIN = 1000;
const formatFunc = (value) => (!value ? '' : value);

const normalizeFunc = (value) => {
  if (value) {
    let val = parseInt(value.replace(/[^\d]/g, ''));
    return val > YEAR_MAX ? YEAR_MAX : val;
  } else {
    return null;
  }
};

const minCheck = (e) => {
  if (e.target) {
    const value = e.target.value;
    if (value < YEAR_MIN) {
      return YEAR_MIN.toString();
    } else {
      return value;
    }
  }
};

const InputComponent = (props) => (
  <TextInputPropertyDetails
    {...props}
    {...props.input}
    onBlur={(value) => props.input.onBlur(minCheck(value))}
  />
);

const YearField = (props) => {
  return (
    <Field
      {...props}
      component={InputComponent}
      // These props should not be overwritten
      onBlur={minCheck}
      format={formatFunc}
      normalize={normalizeFunc}
      maxLength={4}
    />
  );
};

YearField.propTypes = {
  name: PropTypes.string.isRequired
};

export default YearField;
