import { Component } from 'react';
import get from 'lodash/get';

import PropertyCompareContainer from 'legacy/containers/property-compare';

import { PropertyCompareComps as theme } from 'legacy/css-modules';

class PropertyCompareComps extends Component {
  state = {
    editing: false,
    rowHeights: {}
  };
  rowHeightsByAddressId = {};

  static defaultProps = {
    onSubmit: () => {},
    onCancel: () => {}
  };

  handleAdjust = () => {
    this.setState({
      editing: true
    });
  };

  handleCancel = () => {
    this.setState({
      editing: false
    });
    this.props.onCancel();
  };

  handleSubmit = () => {
    this.setState({
      editing: false
    });
    this.props.onSubmit();
  };

  getLargestRowHeightForKey = (key, newHeight) => {
    let largest = 0;
    for (let addressId in this.rowHeightsByAddressId) {
      const v = get(this.rowHeightsByAddressId, [addressId, key]);
      if (v && v > largest) {
        largest = v;
      }
    }
    return newHeight > largest ? newHeight : largest;
  };

  handleRowHeightsUpdated = (rowHeightsUpdated, addressId) => {
    if (!this.rowHeightsByAddressId.hasOwnProperty(addressId)) {
      this.rowHeightsByAddressId[addressId] = {};
    }
    let newRowHeights = { ...this.state.rowHeights };
    let updated = false;
    for (let key in rowHeightsUpdated) {
      this.rowHeightsByAddressId[addressId][key] = rowHeightsUpdated[key];
      const largestRowForKey = this.getLargestRowHeightForKey(
        key,
        rowHeightsUpdated[key]
      );
      if (!newRowHeights[key] || largestRowForKey !== newRowHeights[key]) {
        newRowHeights[key] = largestRowForKey;
        updated = true;
      }
    }
    if (updated) {
      this.setState({ rowHeights: newRowHeights });
    }
  };

  render() {
    const { compType, addressIds } = this.props;
    const { editing, rowHeights } = this.state;
    return (
      <div className={theme.PropertyCompareComps}>
        <div className={theme.SubjectCell}>
          <PropertyCompareContainer
            addressId="subject"
            compType={compType}
            rowHeights={rowHeights}
            onRowHeightsUpdated={this.handleRowHeightsUpdated}
          />
        </div>
        <div className={theme.CompsCell}>
          <div>
            {addressIds.map((addressId, i) => {
              return (
                <PropertyCompareContainer
                  key={`compare-comp-${addressId}`}
                  addressId={addressId}
                  compType={compType}
                  idx={i}
                  editing={editing}
                  rowHeights={rowHeights}
                  onAdjust={this.handleAdjust}
                  onCancel={this.handleCancel}
                  onSubmit={this.handleSubmit}
                  onRowHeightsUpdated={this.handleRowHeightsUpdated}
                  showLookupButton
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default PropertyCompareComps;
