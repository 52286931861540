import { removeNonNumeric } from 'legacy/utils/strings';
import { numberFormatter, unformatNumber } from 'legacy/utils/formatters';

const getLengthForFormat = (str) =>
  str[0] === '-' ? str.length - 1 : str.length;

export function cursorPositionOnChange(e, value, valuePrev) {
  // UX Tweaks for keyboard functionality
  // Adjusts cursor position based on formatting applied to value
  if (this) {
    if (this.input) {
      const formattedValue = this.format()(value);
      if (this.selectionStart) {
        let newSelection = this.selectionStart;
        const valueDiff =
          getLengthForFormat(formattedValue) -
          getLengthForFormat(this.lastValFormatted);
        newSelection += valueDiff;
        this.newSelection = newSelection;
        this.selectionStart = null;
        this.selectionEnd = null;
      }
      this.lastValFormatted = formattedValue;
    }
    if (this.props.onChange && typeof this.props.onChange === 'function') {
      this.props.onChange(e, value, valuePrev);
    }
  }
}

export function formatNumberFieldFactory(dec, format) {
  return function (value) {
    if (format) {
      return format(value);
    } else {
      let formattedValue;
      if (value === '-') {
        return value;
      } else if (value === null || value === undefined || value === '0') {
        formattedValue = '';
      } else {
        formattedValue = numberFormatter(value, dec);
      }
      return formattedValue;
    }
  };
}

// Handles normalization of data, deletion of commas, and cursor position
export function normalizeNumberFieldFactory(normalize, min, max) {
  // You must bind this in the consuming component: `normalizeNumberFieldFactory.bind(this);`
  return function (value) {
    if (normalize) {
      return normalize(value);
    } else {
      // UX Tweaks for keyboard functionality
      // Class properties set here are used in the onChange class method
      if (this && this.input) {
        if (value !== '-') {
          this.lastValFormatted = value;

          let selectionStart = this.input.selectionStart;
          let selectionEnd = this.input.selectionEnd;

          if (
            value &&
            this.lastVal &&
            removeNonNumeric(value) === removeNonNumeric(this.lastVal) &&
            value.length < this.lastVal.length
          ) {
            value = [
              ...value.slice(0, selectionStart - 1),
              ...value.slice(selectionEnd, value.length)
            ].join('');
          }
          this.selectionStart = selectionStart;
        }
        this.lastVal = value;
      }

      let normalizedVal;
      if (value === '-') {
        normalizedVal = '-';
      } else {
        normalizedVal = unformatNumber(value);
      }

      // Adjust for min/max values
      if (
        min !== undefined &&
        (normalizedVal < min || (normalizedVal === '-' && min > -0.000001))
      ) {
        normalizedVal = min;
      }
      if (max !== undefined && normalizedVal > max) {
        normalizedVal = max;
      }

      if (normalizedVal === 0) {
        normalizedVal = null;
      }

      return normalizedVal;
    }
  };
}

export function formatCurrencyFieldFactory(dec, format, currency = '$') {
  return function (value) {
    let formatted = formatNumberFieldFactory(dec, format).call(
      this,
      typeof value === 'string' ? value.replace(currency, '') : value
    );
    let pfxNeg = '';
    if (formatted[0] === '-') {
      formatted = formatted.slice(1, formatted.length);
      pfxNeg = '-';
    }
    return `${pfxNeg}${currency}${formatted}`;
  };
}

export function normalizeCurrencyFieldFactory(normalize, min, max) {
  return function (value) {
    let normalized;
    if (value === '-') {
      normalized = null;
    } else if (['-$', '$-'].indexOf(value) > -1) {
      normalized = '-$';
    } else {
      normalized = normalizeNumberFieldFactory(normalize, min, max).call(
        this,
        value
      );
    }
    return normalized;
  };
}
