// Devtools: https://github.com/gaearon/redux-devtools/blob/master/docs/Walkthrough.md
import { createStore, applyMiddleware, compose, StoreEnhancer } from 'redux';
//@ts-ignore
import RavenMiddleware from 'redux-raven-middleware';
import Raven from 'raven-js';
import initRouter from '@hc/redux-saga-router-plus';

//@ts-ignore
import HC_CONSTANTS from 'HC_CONSTANTS';

//@ts-ignore
import middleware from 'legacy/appstore/middleware';
//@ts-ignore
import sagaMiddleware from 'legacy/appstore/middleware/saga';
//@ts-ignore
import reducers from 'legacy/appstore/reducers';
//@ts-ignore
import runSagas from 'legacy/appstore/sagas';
//@ts-ignore
import ROUTES from 'legacy/routes';
//@ts-ignore
import routerOptions from 'legacy/routes/router-options';
//@ts-ignore
import history from 'legacy/routes/history';

/* Configure Raven - Sentry */
if (HC_CONSTANTS.SENTRY.TOKEN) {
  const { TOKEN } = HC_CONSTANTS.SENTRY;
  // eslint-disable-next-line
  const environment = process.env.NODE_ENV;
  Raven.config(TOKEN, {
    // eslint-disable-next-line
    //@ts-ignore
    release: global.SENTRY_RELEASE,
    environment
  }).install();
}

if (HC_CONSTANTS.SENTRY.TOKEN) {
  middleware.unshift(RavenMiddleware());
}

const enhancers = [applyMiddleware(...middleware)];
// eslint-disable-next-line
if (process.env.NODE_ENV === 'development') {
  // SECURITY: Only accessible in dev environment @jnettleman
  /* eslint-disable scanjs-rules/property_localStorage */
  /* eslint-disable scanjs-rules/identifier_localStorage */
  const VR_ACTIONS_BLACKLIST_KEY = 'vrActionsBlacklist';
  //@ts-ignore
  window.viewBlacklist = () => {
    const blacklist = window.localStorage[VR_ACTIONS_BLACKLIST_KEY]
      ? window.localStorage[VR_ACTIONS_BLACKLIST_KEY].split(',')
      : [];
    console.log('Redux Devtools Blacklist:', blacklist);
  };

  //@ts-ignore
  window.blacklistReduxActions = (actions, replace = false) => {
    if (!replace) {
      const currentBlacklist = window.localStorage[VR_ACTIONS_BLACKLIST_KEY]
        ? window.localStorage[VR_ACTIONS_BLACKLIST_KEY].split(',')
        : [];
      actions = [...currentBlacklist, ...actions];
    }
    const newBlacklist = actions.join(',');
    window.localStorage.setItem(VR_ACTIONS_BLACKLIST_KEY, newBlacklist);
    //@ts-ignore
    window.viewBlacklist();
  };
  // eslint-disable-next-line
  //@ts-ignore
  if (global.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(
      // eslint-disable-next-line
      //@ts-ignore
      global.__REDUX_DEVTOOLS_EXTENSION__({
        actionsBlacklist:
          window.localStorage[VR_ACTIONS_BLACKLIST_KEY] &&
          window.localStorage[VR_ACTIONS_BLACKLIST_KEY].split(',')
      })
    );
  }
  // SECURITY: End dev environment @jnettleman
  /* eslint-ensable scanjs-rules/property_localStorage */
  /* eslint-ensable scanjs-rules/identifier_localStorage */
}

const enhancer = compose(...enhancers);

function configureStore() {
  const store = createStore(reducers, enhancer as StoreEnhancer);

  runSagas();

  initRouter(history, ROUTES, sagaMiddleware, routerOptions);

  return store;
}

const store = configureStore();

export type AppDispatch = typeof store.dispatch;

export default store;
