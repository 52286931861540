import {
  acresFormatter,
  placeholderFormatter,
  pluralize,
  wholeNumberFormatterPlaceholder
} from 'legacy/utils/formatters';

import { COMP_TYPE_DEFAULT, COMP_TYPE_RENTAL } from 'legacy/appstore/constants';

import ListingRemarksPopover from 'legacy/containers/listing-remarks-popover';
import PropertyStatusChip from 'legacy/containers/property-status-chip';
import { EffectiveDateWarning } from 'legacy/containers/effective-date-warning';
import { ExpiredReportDate } from 'legacy/containers/expired-report-date';
import { PropertyHeaderDetails as theme } from 'legacy/css-modules';

const Separator = () => {
  return <div className={theme.Separator}>•</div>;
};

const PropertyHeaderDetails = ({
  dataHcName,
  streetAddress,
  unit,
  unitDesignator,
  city,
  state,
  zipcode,
  propertyType,
  propertyContext,
  bedrooms,
  bathrooms,
  grossLivingAreaSqft,
  isSubject,
  addressId,
  siteAreaSqft,
  listingDetails,
  showReportDates
}) => {
  const compType =
    propertyContext === COMP_TYPE_DEFAULT ||
    propertyContext === COMP_TYPE_RENTAL
      ? propertyContext
      : undefined;
  return (
    <div className={theme.Property}>
      <div className={theme.AddressRow}>
        <h1
          data-hc-name={`${dataHcName}-street-address`}
          className={theme.StreetAddress}
        >
          {streetAddress}{' '}
          {unit && unitDesignator ? `${unitDesignator} ${unit}` : unit}
        </h1>
        {showReportDates && (
          <>
            <ExpiredReportDate className={theme.ReportDate} />
            <EffectiveDateWarning className={theme.ReportDate} />
          </>
        )}
        <PropertyStatusChip
          addressId={isSubject ? 'subject' : addressId}
          propertyContext={propertyContext}
        />
      </div>
      <div className={theme.PropertyDetails}>
        {/* TODO: Make this configurable and saveable to preferences */}
        <div data-hc-name={`${dataHcName}-city-state-zip`}>
          {city}, {state} {zipcode}
        </div>
        <Separator />
        <div data-hc-name={`${dataHcName}-property-type`}>{propertyType}</div>
        <div data-hc-name={`${dataHcName}-beds`}>
          {placeholderFormatter(bedrooms)} {pluralize('Bed', bedrooms)}
        </div>
        <div data-hc-name={`${dataHcName}-baths`}>
          {placeholderFormatter(bathrooms)} {pluralize('Bath', bathrooms)}
        </div>
        <div data-hc-name={`${dataHcName}-gla`}>
          {wholeNumberFormatterPlaceholder(grossLivingAreaSqft)} Sq.Ft.
        </div>
        <div data-hc-name={`${dataHcName}-site-area`}>
          {acresFormatter(siteAreaSqft)}
        </div>
        {listingDetails && listingDetails.publicRemarks && <Separator />}
        <div data-hc-name={`${dataHcName}-view-listing-remarks`}>
          {/* NOTE: Listing remarks loaded by the container */}
          <ListingRemarksPopover
            compType={compType}
            addressId={isSubject ? 'subject' : addressId}
            propertyContext={propertyContext}
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyHeaderDetails;
