import { connect } from 'react-redux';

import { COMP_TYPE_DEFAULT } from 'legacy/appstore/constants';

import { getCompsPropertyDetailsAttributes } from 'selectors/comps';
import { getRentalCompsPropertyDetailsAttributes } from 'selectors/rental-comps';
import { getPropertyDetailsAttributesLoaded } from 'selectors/property';

import PropertyCompareTable from 'legacy/components/PropertyCompareTable';

const compsSelectorsDefault = {
  getAttributes: getCompsPropertyDetailsAttributes
};
const compsSelectorsRental = {
  getAttributes: getRentalCompsPropertyDetailsAttributes
};

const mapStateToProps = (state, ownProps) => {
  const { addressId, compType } = ownProps;
  const compSelectors =
    compType === COMP_TYPE_DEFAULT
      ? compsSelectorsDefault
      : compsSelectorsRental;
  return {
    attributes: compSelectors.getAttributes(state),
    loadedByAttribute: getPropertyDetailsAttributesLoaded(addressId)(state)
  };
};

export default connect(mapStateToProps)(PropertyCompareTable);
