import React from 'react';
import Supercluster from 'supercluster';
import { point } from '@turf/helpers';
import { Children, createElement, useContext, ReactElement } from 'react';

import { MapContext, Marker } from 'react-map-gl';
import { MapCluster } from 'src/lib/components/MapCluster';

interface Props {
  /** Mapbox map object */
  map: mapboxgl.Map;
  /** Minimum zoom level at which clusters are generated */
  minZoom?: number;
  /** Maximum zoom level at which clusters are generated */
  maxZoom?: number;
  /** MappCluster radius; in pixels */
  radius?: number;
  /** (Tiles) Tile extent. Radius is calculated relative to this value */
  extent?: number;
  /** Size of the KD-tree leaf node. Affects performance */
  nodeSize?: number;
  /** Markers as children */
  children: React.ReactElement[];
}

const _Cluster = ({
  map,
  children,
  minZoom = 0,
  maxZoom = 20,
  radius = 20,
  extent = 512,
  nodeSize = 10
}: Props) => {
  const points = Children.map(
    children,
    (child) =>
      child && point([child.props.longitude, child.props.latitude], child)
  );
  const superCluster = new Supercluster({
    minZoom,
    maxZoom,
    radius,
    extent,
    nodeSize
  }).load(points);
  const zoom = map.getZoom();
  const bounds = map.getBounds().toArray();
  if (
    !bounds[0] ||
    !bounds[0][0] ||
    !bounds[0][1] ||
    !bounds[1] ||
    !bounds[1][0] ||
    !bounds[1][1]
  )
    return null;
  const bbox: [number, number, number, number] = [
    bounds[0][0],
    bounds[0][1],
    bounds[1][0],
    bounds[1][1]
  ];
  const clusters = superCluster
    .getClusters(bbox, Math.floor(zoom))
    .map((cluster) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      if (cluster.properties.cluster && longitude && latitude) {
        // return createElement(Marker, {
        //   longitude,
        //   latitude,
        //   offsetLeft: -28 / 2,
        //   offsetTop: -28,
        //   children: createElement(MapCluster, {
        //     // @ts-ignore: Not sure why this is complaining. Will need to dig in later @jnettleman
        //     cluster,
        //     superCluster
        //   }),
        //   key: `cluster-${cluster.properties.cluster_id}`
        // });
        return (
          <Marker
            longitude={longitude}
            latitude={latitude}
            offsetLeft={-28 / 2}
            offsetTop={-28}
            key={`cluster-${cluster.properties.cluster_id}`}
          >
            <MapCluster
              // @ts-ignore: Not sure why this is complaining. Will need to dig in later @jnettleman
              cluster={cluster}
              superCluster={superCluster}
            />
          </Marker>
        );
      }
      const { type, key, props } = cluster.properties;
      return createElement(type, { key, ...props });
    });
  return clusters;
};

interface PublicProps {
  children: ReactElement[];
  /** Minimum zoom level at which clusters are generated */
  minZoom?: number;
  /** Maximum zoom level at which clusters are generated */
  maxZoom?: number;
  /** MappCluster radius; in pixels */
  radius?: number;
  /** (Tiles) Tile extent. Radius is calculated relative to this value */
  extent?: number;
  /** Size of the KD-tree leaf node. Affects performance */
  nodeSize?: number;
}
// Wrapper Component to ensure map is available before rendering cluster
export const Cluster = (props: PublicProps) => {
  const context = useContext(MapContext);
  const map = context.map as mapboxgl.Map | undefined;
  if (!map) return null;
  return <>{_Cluster({ map, ...props })}</>;
};
