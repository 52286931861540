import { GET_REPORT, GET_REPORT_SHARED } from 'actions/get-report';
import { SCREEN_RESIZE } from 'actions/match-media';

export const INITIAL_STATE = {
  initialLoad: true
};

export default function matchMediaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SCREEN_RESIZE:
      return {
        ...state,
        ...action.payload
      };

    case GET_REPORT:
    case GET_REPORT_SHARED:
      return {
        ...state,
        initialLoad: false
      };

    default:
      return state;
  }
}
