/* All .scss styles imported once, here */
import './styles';
import React from 'react';
import { Provider, useSelector } from 'react-redux';

import store from 'legacy/appstore';

import Router from 'legacy/routes/Router';

import App from 'legacy/containers/app';
import { EffectiveDateErrorDialog } from 'legacy/containers/effective-date-error-dialog';
import { PhotosPageProvider } from '../lib/report-api/providers/PhotosPage';

const PerformanceTags = () => {
  const timeStart = useSelector((state) => state.performance.timeStart);
  const timeComplete = useSelector((state) => state.performance.timeComplete);
  const style = { display: 'none' };
  return (
    <>
      <span data-hc-name="report-generate-time-start" style={style}>
        {timeStart}
      </span>
      <span data-hc-name="report-generate-time-complete" style={style}>
        {timeComplete}
      </span>
    </>
  );
};
const Main = () => (
  <Provider store={store}>
    <PhotosPageProvider>
      <App>
        <Router />
        <EffectiveDateErrorDialog />
        <PerformanceTags />
      </App>
    </PhotosPageProvider>
  </Provider>
);

export default Main;
