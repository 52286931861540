// SECURITY: false positive for propertyStatusIndicatorBuilder
import classNames from 'classnames';
import L from 'leaflet';
import SVGInline from 'react-svg-inline';
import get from 'lodash/get';

import { COMP_TYPE_DEFAULT, COMP_TYPE_RENTAL } from 'legacy/appstore/constants';

import DrawControlButton from 'legacy/components/DrawControlButton';

import polygonIcon from 'legacy/assets/svg-inline/map-draw-polygon.svg';
import polygonIconActive from 'legacy/assets/svg-inline/map-draw-polygon-active.svg';
import circleIcon from 'legacy/assets/svg-inline/map-draw-circle.svg';
import circleIconActive from 'legacy/assets/svg-inline/map-draw-circle-active.svg';
import rectangleIcon from 'legacy/assets/svg-inline/map-draw-rectangle.svg';
import rectangleIconActive from 'legacy/assets/svg-inline/map-draw-rectangle-active.svg';
import trashIcon from 'legacy/assets/svg-inline/map-draw-trash.svg';

const ICON_CONFIG_FOR_ICON_SIZE = {
  small: {
    // Based on 30px x 30px icon size, defined in CSS
    iconAnchor: [15, 40],
    popupAnchor: [0, -52]
  },
  large: {
    // Based on 52px x 52px icon size, defined in CSS
    iconAnchor: [26, 62],
    popupAnchor: [0, -75]
  },
  cluster: {
    // Based on 40px x 40px icon size, defined in CSS
    iconAnchor: [20, 20]
  }
};
const MARKER_ICON_CLASSNAME = 'map-marker-icon';

export const propertyStatusIndicatorBuilder = (
  isHomeStyle,
  propertyStatus,
  label,
  propertyStatusRental,
  isSelectedStyle
) => {
  return isHomeStyle || (!propertyStatus && !propertyStatusRental)
    ? `<span>${label}</span>`
    : `<div><div class='${classNames('property-status-indicator', {
        'property-status--sold':
          (propertyStatus || propertyStatusRental) === 'Sold',
        'property-status--leased':
          (propertyStatus || propertyStatusRental) === 'Leased',
        'property-status--active':
          (propertyStatus || propertyStatusRental) === 'Active',
        'property-status--pending':
          (propertyStatus || propertyStatusRental) === 'Pending'
      })}'></div><span class='${classNames({
        'light-label': isSelectedStyle
      })}'>${label}</span></div>`;
};

export const buildMarker = ({
  property,
  label,
  iconSize = 'large',
  isSelectedStyle,
  isHoveredStyle,
  isHomeStyle,
  goToPreviewPageOnClick,
  fitOnClick,
  isSubject,
  excludeFromCluster,
  compType,
  className,
  leafletOptions = {},
  events = {}
}) => {
  const lat =
    property.lat || (property.geoLocation && property.geoLocation.latitude);
  const lon =
    property.lon || (property.geoLocation && property.geoLocation.longitude);
  const dataHcNameMod =
    compType === COMP_TYPE_RENTAL
      ? '-rental-comps'
      : compType === COMP_TYPE_DEFAULT
      ? '-comps'
      : '';
  const dataHcName = isSubject
    ? `map-subject-pin${dataHcNameMod}`
    : isSelectedStyle
    ? `map-comp-selected-pin${dataHcNameMod}`
    : `map-comp-pin${dataHcNameMod}`;
  const marker = {
    uId: `${lat}${lon}${property.addressId || property.id}`,
    // TODO remove `comp` and use only `property` (used in comps marker click callbacks)
    comp: property,
    property: property,
    lat,
    lon,
    label,
    zIndexOffset: isSelectedStyle ? 500 : isHomeStyle ? 1000 : 1,
    iconConfig: {
      html: `<div class='${MARKER_ICON_CLASSNAME}' data-hc-name='${dataHcName}'></div>`,
      className: classNames('map-marker-wrapper', className, {
        'map-marker-wrapper--home': isHomeStyle,
        'map-marker-wrapper--selected': isSelectedStyle,
        'map-marker-wrapper--hovered': isHoveredStyle,
        'map-marker-wrapper--large': iconSize === 'large'
      }),
      ...ICON_CONFIG_FOR_ICON_SIZE[iconSize]
    },
    /* eslint-disable xss/no-mixed-html */
    labelHtml: propertyStatusIndicatorBuilder(
      isHomeStyle,
      property.propertyStatus,
      label,
      property.propertyStatusRental,
      isSelectedStyle
    ),
    /* eslint-enable xss/no-mixed-html */
    isSubject,
    goToPreviewPageOnClick,
    fitOnClick,
    excludeFromCluster,
    // Density at which markers will be combined into a marker cluster
    maxClusterRadius: iconSize === 'large' ? 80 : 40,
    leafletOptions,
    events
  };
  return !!marker.lat && !!marker.lon && marker;
};

/**
 * Create a marker cluster icon for each marker cluster - supplied directly to
 * MarkerCluster plugin's `iconCreateFunction` prop
 * @param  {object} cluster - an instance MarkerCluster
 * @return {object} an icon for the cluster
 */
export const buildClusterIcon = (cluster) => {
  const markerCount = cluster.getChildCount();
  // Whether all markers within the cluster have identical lat, lng properties
  // (indicating a multi-unit building)
  const allMarkersSameLocation = cluster
    .getAllChildMarkers()
    .reduce((mem, curr) => {
      if (!mem) {
        return false;
      }
      const { lat: currLat, lng: currLng } = curr.getLatLng();
      const { lat: memLat, lng: memLng } = mem.getLatLng();
      return currLat === memLat && currLng === memLng && curr;
    });
  return allMarkersSameLocation
    ? L.divIcon({
        // Must be passed to Leaflet as an HTML string :/
        html: `
        <div class='map-marker' data-hc-name='map-marker-unit-cluster'>
          <div class='${MARKER_ICON_CLASSNAME}'>
            <div class='marker-cluster-multi-unit-label'>
              <div class='marker-cluster-multi-unit-count'>${markerCount}</div>
              <div>Units</div>
            </div>
          </div>
        </div>`,
        className:
          'marker-cluster-multi-unit-icon map-marker-wrapper map-marker-wrapper--large',
        iconAnchor: ICON_CONFIG_FOR_ICON_SIZE['large'].iconAnchor
      })
    : L.divIcon({
        html: `<div data-hc-name='map-marker-cluster'><span>${markerCount}</span></div>`,
        /* Match class defined in MarkerCluster plugin's CSS for default styling */
        className: 'marker-cluster',
        iconAnchor: ICON_CONFIG_FOR_ICON_SIZE.cluster.iconAnchor
      });
};

export const getCircleRadiusOriginal = (layer) =>
  get(layer, ['feature', 'properties', 'radius']);

export const isLayerCircle = (layer) =>
  get(layer, ['feature', 'geometry', 'type']) === 'Point' &&
  get(layer, ['feature', 'properties', 'radius']);

export const isCircleRadiusChanged = (layer) =>
  getCircleRadiusOriginal(layer) !== layer.getRadius();

export const buildDrawControlButtons = (theme, activeControlType) => {
  return [
    {
      Icon: ({ className }) => (
        <SVGInline svg={polygonIcon} className={className} />
      ),
      IconActive: ({ className }) => (
        <SVGInline svg={polygonIconActive} className={className} />
      ),
      title: 'Draw',
      type: 'polygon',
      anchorClassName: classNames(theme['a--visible'], theme['a--polygon']),
      isActive: activeControlType === 'polygon'
    },
    {
      Icon: ({ className }) => (
        <SVGInline svg={circleIcon} className={className} />
      ),
      IconActive: ({ className }) => (
        <SVGInline svg={circleIconActive} className={className} />
      ),
      title: 'Radius',
      type: 'circle',
      anchorClassName: classNames(theme['a--visible'], theme['a--circle']),
      isActive: activeControlType === 'circle'
    },
    {
      Icon: ({ className }) => (
        <SVGInline svg={rectangleIcon} className={className} />
      ),
      IconActive: ({ className }) => (
        <SVGInline svg={rectangleIconActive} className={className} />
      ),
      title: 'Rectangle',
      type: 'rectangle',
      anchorClassName: classNames(theme['a--visible'], theme['a--rectangle']),
      isActive: activeControlType === 'rectangle'
    },
    {
      Icon: ({ className }) => (
        <SVGInline svg={trashIcon} className={className} />
      ),
      title: 'Delete',
      type: 'delete',
      anchorClassName: classNames(theme['a--visible'], theme['a--delete'])
    }
  ].map((button) => ({
    ...button,
    component: <DrawControlButton {...button} theme={theme} />
  }));
};

export const determineShapeTypeFromGeoJson = (geoJSON) => {
  if (geoJSON && geoJSON.geometry) {
    const { type, coordinates } = geoJSON.geometry;
    if (type === 'Point') {
      return 'circle';
    } else if (type === 'Polygon') {
      if (
        coordinates.length === 1 &&
        coordinates[0].length === 5 &&
        coordinates[0][0][0] === coordinates[0][1][0] &&
        coordinates[0][2][0] === coordinates[0][3][0] &&
        coordinates[0][0][1] === coordinates[0][3][1] &&
        coordinates[0][1][1] === coordinates[0][2][1]
      ) {
        return 'rectangle';
      } else {
        return 'polygon';
      }
    }
  }
};

export const buildRadiusGeoJson = (
  geoLocation,
  distance,
  isSubjectCoordinates = false
) => {
  return {
    type: 'Feature',
    properties: {
      radius: 1609.34 * distance,
      radius_unit: 'meters',
      isSubjectCoordinates
    },
    geometry: {
      type: 'Point',
      coordinates: [geoLocation.longitude, geoLocation.latitude]
    }
  };
};
