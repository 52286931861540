import React, { createRef } from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import styles from './ResizableFlexColumn.css';
import { ResizerIcon } from 'src/lib/inline-svgs';

/* TODO make this a prop and support right or left position */
const POSITION_OF_HANDLE = 'left';
const DOUBLE_CLICK_TIMEOUT = 300;

interface Props {
  /* Callback executed on resize, with the container width and remaining width arguments */
  onResize?: (columnWidth: number, restWidth: number) => void;
  /* Callback executed on window resize, with the container width and remaining width arguments */
  onResizeWindow?: (columnWidth: number, restWidth: number) => void;
  /* Callback executed when the current resize action is complete */
  onResizeComplete?: (columnWidth: number, restWidth: number) => void;
  /* Disable resize functionality */
  disabled?: boolean;
  /* Force a width */
  width?: string;
  /* Initial percent of parent */
  initialPct?: number;
  initialWidth?: number;
  totalPadding?: number;
  /* class applied to the outer element wrapping both children and the handle */
  classNameOuter?: string;
  /* class applied to the outer element wrapping both children and the handle when resizing */
  classNameResizingOuter?: string;
  /* class applied to the outer element wrapping only children */
  classNameInner?: string;
  /* class applied to the outer element wrapping only children when resizing */
  classNameResizingInner?: string;
  children: React.ReactNode;
  dataHcName: string;
}

interface State {
  isResizing: boolean;
  clickDirection: '+' | '-';
}
/**
 * A component that is able to be resized by clicking and dragging handles placed on the sides
 */
export class ResizableFlexColumn extends React.Component<Props, State> {
  static defaultProps = {
    totalPadding: 0,
    initialPct: 0.5
  };

  componentDidMount() {
    window.addEventListener('resize', this.resizeWindow);
    if (this.componentNode.current?.parentNode instanceof HTMLElement) {
      this.parentNode = this.componentNode.current.parentNode;
      this.parentWidth = this.parentNode.offsetWidth;
    }
    this.debouncedResizeHandler = debounce(this.handleResize, 300);
    this.setInitialWidth();
  }

  componentWillUnmount() {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    window.removeEventListener('resize', this.resizeWindow, false);
  }

  state: State = {
    isResizing: false,
    clickDirection: '+'
  };

  // For tracking double-clicks for 100% resize and differentiating btw clicks and drags
  numClicks = 0;

  clickTimeout = 0;

  resizeTimeout = 0;

  initialMousePosition = 0;

  componentNode = createRef<HTMLDivElement>();

  resizerNode = createRef<HTMLDivElement>();

  parentNode: HTMLElement | null = null;

  debouncedResizeHandler: VoidFunction | null = null;

  amountHandleMoved = 0;

  width = 0;

  parentWidth = 0;

  setInitialWidth = () => {
    const { initialPct = 0.5, initialWidth } = this.props;
    this.width =
      initialWidth !== null && initialWidth !== undefined
        ? initialWidth
        : this.parentWidth * initialPct;
    this.applyWidthToElements();
  };

  initResize = (e: React.MouseEvent) => {
    window.addEventListener('mouseup', this.stopResize);
    window.addEventListener('mousemove', this.resize);
    this.initialMousePosition = e.clientX;
    this.setState({
      isResizing: true
    });
  };

  resize = (e: MouseEvent) => {
    clearTimeout(this.clickTimeout);
    window.requestAnimationFrame(() => {
      // TODO: This logic assumes parentWidth is the full-wdtih of the window
      this.width = this.parentWidth - e.clientX;
      this.applyWidthToElements();
      if (this.debouncedResizeHandler) {
        this.debouncedResizeHandler();
      }
    });
  };

  resizeWindow = () => {
    window.requestAnimationFrame(() => {
      if (this.width > this.parentWidth) {
        this.width = this.parentWidth;
      }
      if (this.parentNode instanceof HTMLElement) {
        this.parentWidth = this.parentNode.offsetWidth;
        this.applyWidthToElements();
        if (typeof this.props.onResizeWindow === 'function') {
          this.props.onResizeWindow(this.width, this.parentWidth - this.width);
        }
      }
    });
  };

  stopResize = () => {
    window.removeEventListener('mouseup', this.stopResize);
    window.removeEventListener('mousemove', this.resize);
    if (typeof this.props.onResizeComplete === 'function') {
      this.props.onResizeComplete(this.width, this.parentWidth - this.width);
    }
    this.amountHandleMoved = 0;
    this.setState({
      isResizing: false
    });
  };

  handleResize = () => {
    if (typeof this.props.onResize === 'function') {
      this.props.onResize(this.width, this.parentWidth - this.width);
    }
  };

  handleClick = (e: React.MouseEvent) => {
    if (e.clientX === this.initialMousePosition) {
      if (this.numClicks) {
        clearTimeout(this.clickTimeout);
        this.fullWidth();
      } else {
        this.clickTimeout = window.setTimeout(
          this.incrementWidth,
          DOUBLE_CLICK_TIMEOUT
        );
        this.numClicks += 1;
      }
    }
  };

  incrementWidth = () => {
    const { clickDirection } = this.state;
    const currentRatio = this.width / this.parentWidth;
    const nextRatio =
      clickDirection === '+'
        ? Math.min(1, Math.floor((currentRatio + 0.25) / 0.25) * 0.25)
        : Math.max(0, Math.ceil((currentRatio - 0.25) / 0.25) * 0.25);

    this.width = this.parentWidth * nextRatio;
    this.applyWidthToElements();
    if (this.debouncedResizeHandler) {
      this.debouncedResizeHandler();
    }
    if (typeof this.props.onResizeComplete === 'function') {
      // Set timeout to account for animation time
      this.resizeTimeout = window.setTimeout(() => {
        if (typeof this.props.onResizeComplete === 'function') {
          this.props.onResizeComplete(
            this.width,
            this.parentWidth - this.width
          );
        }
      }, 300);
    }
  };

  fullWidth = () => {
    const { clickDirection } = this.state;
    if (clickDirection === '+') {
      this.width = this.parentWidth;
    } else if (clickDirection === '-') {
      this.width = 0;
    }
    this.applyWidthToElements();
    if (this.debouncedResizeHandler) {
      this.debouncedResizeHandler();
    }
    if (typeof this.props.onResizeComplete === 'function') {
      // Set timeout to account for animation time
      this.resizeTimeout = window.setTimeout(() => {
        if (typeof this.props.onResizeComplete === 'function') {
          this.props.onResizeComplete(
            this.width,
            this.parentWidth - this.width
          );
        }
      }, 300);
    }
  };

  applyWidthToElements = () => {
    this.numClicks = 0;
    const { width } = this.props;
    if (width === undefined || width === null) {
      if (this.componentNode.current) {
        this.componentNode.current.style.flex = `0 0 ${this.width}px`;
        this.componentNode.current.style.width = `${this.width}px`;
        this.setClickDirection();
      }
    }
  };

  setClickDirection = () => {
    const { clickDirection } = this.state;
    let newClickDirection: null | State['clickDirection'] = null;

    if (clickDirection === '+' && this.width / this.parentWidth >= 0.75) {
      newClickDirection = '-';
    } else if (
      clickDirection === '-' &&
      this.width / this.parentWidth <= 0.25
    ) {
      newClickDirection = '+';
    }

    if (newClickDirection && newClickDirection !== clickDirection) {
      this.setState({ clickDirection: newClickDirection });
    }
  };

  render() {
    const {
      classNameOuter,
      classNameResizingOuter,
      classNameInner,
      classNameResizingInner,
      width,
      disabled,
      children,
      dataHcName
    } = this.props;
    const { clickDirection, isResizing } = this.state;
    return (
      <div
        data-hc-name={dataHcName}
        className={classNames(styles.ResizableContainer, classNameOuter, {
          [styles.resizing || '']: isResizing,
          [classNameResizingOuter || '']: isResizing
        })}
        style={width !== undefined && width !== null ? { width } : {}}
        ref={this.componentNode}
      >
        {!disabled && (
          <div
            data-hc-name={`${dataHcName}-resizer-handle`}
            className={classNames(
              styles.Resizer,
              styles[`positioned-${POSITION_OF_HANDLE}`],
              {
                [styles.resizing || '']: isResizing
              }
            )}
            ref={this.resizerNode}
            onMouseDown={this.initResize}
            onClick={this.handleClick}
          >
            <ResizerIcon
              dataHcName={`${dataHcName}-resizer-icon`}
              className={classNames(styles.ResizerIcon, {
                [styles.right || '']: clickDirection === '-'
              })}
            />
          </div>
        )}
        <div
          className={classNames(classNameInner, {
            [styles.resizing || '']: isResizing,
            [classNameResizingInner || '']: isResizing
          })}
        >
          {children}
        </div>
      </div>
    );
  }
}
