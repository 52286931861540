import { createSelector } from 'reselect';
import get from 'lodash/get';
// NOTE: This file is needed to prevent circular dependencies
import {
  LABEL_FOR_AVM_KEY,
  PROPERTY_TYPE_SUBJECT
} from 'legacy/appstore/constants';

import { checkUrlIsMapTile } from 'legacy/utils/photos';

import { getSubjectAddressId } from 'selectors/subject';
import {
  getPhotosByAddressId,
  getPhotosByMlsNumberAndExternalSourceId
} from 'selectors/photos';
import { getPropertyBasedOnContext } from 'selectors/property-context';
import {
  getPropertyTransaction,
  getPropertyTransactionHistory
} from 'selectors/property';
import { getAvmSelected, getAvmSelectedKey } from 'selectors/avms';

export const getPhotosDialogState = (state) => state.photos.photosDialog;

export const getPhotosDialogPropertyDetails = (state) => {
  let { addressId, propertyType } = getPhotosDialogState(state);
  const subjectAddressId = getSubjectAddressId(state);
  if (addressId === 'subject') {
    addressId = subjectAddressId;
    propertyType = PROPERTY_TYPE_SUBJECT;
  } else if (addressId === subjectAddressId) {
    propertyType = PROPERTY_TYPE_SUBJECT;
  }
  const propertyDetails = getPropertyBasedOnContext(
    state,
    propertyType,
    addressId
  );
  return propertyDetails;
};

export const getPhotosDialogAddressId = createSelector(
  getPhotosDialogState,
  (photosDialogState) => photosDialogState.addressId
);

export const getPhotosDialogPriceId = createSelector(
  getPhotosDialogState,
  (photosDialogState) => photosDialogState.transactionId
);

export const getPhotosDialogPrice = (state) => {
  const addressId = getPhotosDialogAddressId(state);
  const transactionId = getPhotosDialogPriceId(state);
  if (!transactionId) {
    const addressIdSubject = getSubjectAddressId(state);
    if (addressId === addressIdSubject) {
      const avm = getAvmSelected(state);
      const avmSelectedKey = getAvmSelectedKey(state);
      return {
        price: avm.value,
        priceLabel: LABEL_FOR_AVM_KEY[avmSelectedKey],
        date: null
      };
    }
  }
  return null;
};

const _ensurePhotoTransactionIsCorrectType = (
  currentTransactionEvent,
  transactionHistory
) => {
  if (
    currentTransactionEvent &&
    ![
      'listed',
      'sold',
      'pending',
      'rentalListed',
      'rentalPending',
      'rentalLeased'
    ].includes(currentTransactionEvent.event)
  ) {
    const { mlsNumber, listingDate } = currentTransactionEvent;
    // Get listing event if the listing isn't active sold or pending.
    const listedTransactionEvent = transactionHistory.find(
      (t) => t.date === listingDate && t.mlsNumber === mlsNumber
    );
    return listedTransactionEvent;
  }
  return currentTransactionEvent;
};

const _findPhotoTransaction = (photo, transactionHistory) => {
  return _ensurePhotoTransactionIsCorrectType(
    transactionHistory.find((t) => t.date === photo.currentStatusDate),
    transactionHistory
  );
};

export const getPhotosDialogPhotosByListing = (state) => {
  let { addressId, transactionId } = getPhotosDialogState(state);
  // TODO: Eliminate this when photos are part of property data
  const addressIdSubject = getSubjectAddressId(state);
  const addressIdTransaction =
    addressId === addressIdSubject ? 'subject' : addressId;
  const addressIdPhotos =
    addressId === 'subject' ? addressIdSubject : addressId;

  const transactionHistory =
    getPropertyTransactionHistory(addressIdTransaction)(state);
  if (transactionId) {
    const transaction = _ensurePhotoTransactionIsCorrectType(
      getPropertyTransaction(addressIdTransaction, transactionId)(state),
      transactionHistory
    );
    const transactionPhotos = get(
      getPhotosByMlsNumberAndExternalSourceId(addressIdPhotos)(state),
      [transaction.mlsNumber, transaction.hcMlsId]
    );
    return {
      photosByListing: {
        [transaction.date]: {
          photos: transactionPhotos,
          transaction
        }
      },
      fullscreenPhotos: transactionPhotos
    };
  }
  const photos = getPhotosByAddressId(addressIdPhotos)(state);
  const photosByListing = {};
  const fullscreenPhotos = [];
  photos.forEach((photo) => {
    const { httpUrl, currentStatusDate } = photo;
    if (!photosByListing[currentStatusDate]) {
      photosByListing[currentStatusDate] = {
        transaction: _findPhotoTransaction(photo, transactionHistory),
        photos: []
      };
    }
    const processedPhoto = {
      ...photo,
      httpUrl: checkUrlIsMapTile(httpUrl)
        ? `${httpUrl}&cachebust=20180130`
        : httpUrl
    };
    photosByListing[currentStatusDate].photos.push(processedPhoto);
    fullscreenPhotos.push(processedPhoto);
  });
  return { fullscreenPhotos, photosByListing };
};

export const getPhotosDialogTransaction = (state) => {
  const { addressId, transactionId } = getPhotosDialogState(state);
  return getPropertyTransaction(addressId, transactionId)(state);
};
