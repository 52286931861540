import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Paper from 'legacy/components/generic/Paper';
import CobrandingBadge from 'legacy/components/CobrandingBadge';

import { PersonalizationExample as theme } from 'legacy/css-modules';

const PersonalizationExample = ({ mockCobrandingData }) => {
  return (
    <div>
      <p>
        Here is an example of what would appear on a shared co-branded report:
      </p>
      {mockCobrandingData && !isEmpty(mockCobrandingData) && (
        <Paper className={theme['share-report--mock-cobranding-wrapper']}>
          <CobrandingBadge
            cobrandingData={mockCobrandingData}
            showContactInfo
          />
        </Paper>
      )}
    </div>
  );
};

PersonalizationExample.defaultProps = {
  renderTitle: true
};

PersonalizationExample.propTypes = {
  mockCobrandingData: PropTypes.object,
  renderTitle: PropTypes.bool
};

export default PersonalizationExample;
