import { STATUSES } from 'src/constants';

const _checkLoading = (status?: STATUSES) => {
  return status !== STATUSES.SUCCESS && status !== STATUSES.ERROR;
};

export const isLoading = (status?: STATUSES | (STATUSES | undefined)[]) => {
  if (Array.isArray(status)) {
    return status.some(_checkLoading);
  }
  return _checkLoading(status);
};
