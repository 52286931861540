import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RTThemedInput as Input } from '@hc/component-lib/hclib/rt-themed';

import TextInputHc from 'legacy/components/generic/TextInputHc';

import { RTInput as theme } from 'legacy/css-modules/generic';

const RTInput = ({ input, meta, ...props }) => {
  return <Input {...input} {...props} theme={theme} />;
};

const TextField = ({ inputTheme, ...props }) => {
  return (
    <Field
      component={inputTheme === 'material' ? RTInput : TextInputHc}
      {...props}
    />
  );
};

TextField.defaultProps = {
  type: 'text',
  inputTheme: 'material',
  format: (v) => (v === null ? '' : v),
  normalize: (v) => (v === '' ? null : v)
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default TextField;
