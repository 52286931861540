import { STATUSES } from 'src/constants';
import {
  KeywordSearchActions,
  KEYWORD_SEARCH_SUBMIT,
  KEYWORD_SEARCH_SUBMIT_ERROR,
  KEYWORD_SEARCH_SUBMIT_SUCCESS
} from '../actions';
import { KeywordSearchInputs, KeywordSearchResult } from '../types';

interface KeywordSearchStateLoading {
  status: STATUSES.LOADING;
  inputs: KeywordSearchInputs;
}

interface KeywordSearchStateSuccess {
  status: STATUSES.SUCCESS;
  inputs: KeywordSearchInputs;
  data: KeywordSearchResult[];
}

interface KeywordSearchStateError {
  status: STATUSES.ERROR;
  inputs: KeywordSearchInputs;
}

export type KeywordSearchState =
  | null
  | KeywordSearchStateLoading
  | KeywordSearchStateSuccess
  | KeywordSearchStateError;

const INITIAL_STATE = null;

export const keywordSearchReducer = <A>(
  state: KeywordSearchState = INITIAL_STATE,
  action: A & KeywordSearchActions
): KeywordSearchState => {
  switch (action.type) {
    case KEYWORD_SEARCH_SUBMIT: {
      return {
        status: STATUSES.LOADING,
        inputs: action.payload.inputs
      };
    }

    case KEYWORD_SEARCH_SUBMIT_SUCCESS: {
      return {
        status: STATUSES.SUCCESS,
        inputs: action.payload.inputs,
        data: action.payload.data
      };
    }

    case KEYWORD_SEARCH_SUBMIT_ERROR: {
      return {
        status: STATUSES.ERROR,
        inputs: action.payload.inputs
      };
    }

    default:
      return state;
  }
};
