import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './MapMarkers.css';
import { MarkerPulse } from './MarkerPulse';

interface Props {
  dataHcName: string;
  className?: string;
  children?: ReactNode;
  hoverable?: boolean;
  onClick?: VoidFunction;
  pulse?: boolean;
}
export const MapMarker = ({
  className,
  dataHcName,
  children,
  hoverable,
  pulse,
  onClick
}: Props) => {
  return (
    <div style={{ position: 'relative' }}>
      <div
        data-hc-name={dataHcName}
        className={classNames(styles.MapMarker, className, {
          [styles.hoverable || '']: hoverable,
          [styles.clickable || '']: !!onClick
        })}
        onClick={onClick}
      >
        <div className={styles.Content}>{children}</div>
      </div>
      {pulse && <MarkerPulse />}
    </div>
  );
};
