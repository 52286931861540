import React from 'react';
import classNames from 'classnames';
import styles from './CompFilterBar.css';
import {
  CompFilterButtonListDate,
  CompFilterButtonPropertyType,
  CompFilterButtonSaleDate
} from './buttons';
import { MoreFiltersButton } from './MoreFiltersButton';
import { CompFilterButtonBedroom } from './buttons/CompFilterButtonBedroom';
import { CompFilterButtonListPrice } from './buttons/CompFilterButtonListPrice';
import { CompFilterButtonLotSize } from './buttons/CompFilterButtonLotSize';
import { CompFilterButtonSoldPrice } from './buttons/CompFilterButtonSoldPrice';
import { CompFilterButtonBathroom } from './buttons/CompFilterButtonBathroom';
import { CompFilterButtonGrossLivingSquareFeet } from './buttons/CompFilterButtonGrossLivingSquareFeet';
import { CompFilterButtonYearBuilt } from './buttons/CompFilterButtonYearBuilt';
import { CompFilterButtonGarage } from './buttons/CompFilterButtonGarage';
import { CompFilterButtonStories } from './buttons/CompFilterButtonStories';
import { CompFilterButtonPropertyStatus } from './buttons/CompFilterButtonPropertyStatus';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
}
export const CompFilterBar = ({ reportId, dataHcName, className }: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CompFilterBar, className)}
    >
      <div className={styles.FiltersCell}>
        <CompFilterButtonPropertyType
          dataHcName={`${dataHcName}-list-date`}
          reportId={reportId}
        />
        <CompFilterButtonBedroom
          dataHcName={`${dataHcName}-bedroom`}
          reportId={reportId}
        />
        <CompFilterButtonBathroom
          dataHcName={`${dataHcName}-bathroom`}
          reportId={reportId}
        />
        <CompFilterButtonGrossLivingSquareFeet
          dataHcName={`${dataHcName}-gross-living-square-feet`}
          reportId={reportId}
        />
        <CompFilterButtonYearBuilt
          dataHcName={`${dataHcName}-year-built`}
          reportId={reportId}
        />
        <CompFilterButtonListDate
          dataHcName={`${dataHcName}-list-date`}
          reportId={reportId}
        />
        <CompFilterButtonSaleDate
          dataHcName={`${dataHcName}-sold-date`}
          reportId={reportId}
        />
        <CompFilterButtonPropertyStatus
          dataHcName={`${dataHcName}-property-status`}
          reportId={reportId}
        />
        <CompFilterButtonGarage
          dataHcName={`${dataHcName}-garage`}
          reportId={reportId}
        />
        <CompFilterButtonStories
          dataHcName={`${dataHcName}-stories`}
          reportId={reportId}
        />
        <CompFilterButtonListPrice
          dataHcName={`${dataHcName}-list-price`}
          reportId={reportId}
        />
        <CompFilterButtonSoldPrice
          dataHcName={`${dataHcName}-sold-price`}
          reportId={reportId}
        />
        <CompFilterButtonLotSize
          dataHcName={`${dataHcName}-lot-size`}
          reportId={reportId}
        />
      </div>
      <div className={styles.MoreFilters}>
        <MoreFiltersButton dataHcName={`${dataHcName}-more-filters`} />
      </div>
    </div>
  );
};
