import { watchEvery } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { put, select } from 'redux-saga/effects';

import { VIEWS } from 'legacy/routes/constants';
import {
  AVM_KEY_RENTAL_COMPS,
  AVM_KEY_RENTAL_COMPS_AVG
} from 'legacy/appstore/constants';

import {
  AVMS_RENTAL_CHANGE_SELECTED,
  AVMS_RENTAL_CHANGE_SELECTED_SUCCESS,
  avmsRentalChangeSelectedSuccess
} from 'actions/avms';
import { saveReport } from 'actions/edit-report';
import { vrRouteChange } from 'actions/navigation';

import { getAvmRentalCompsExists } from 'selectors/avms';
import { getRentalCompsFarmListRaw } from 'selectors/rental-comps';
import {
  getIsReportEditable,
  getIsReportSaveable
} from 'selectors/report-permissions';

function* changeSelectedRentalAvm(action) {
  try {
    const isAvmChangeable = yield select(getIsReportEditable);
    if (isAvmChangeable) {
      const { selectedRentalAvm } = action.payload;
      if (
        selectedRentalAvm === AVM_KEY_RENTAL_COMPS ||
        selectedRentalAvm === AVM_KEY_RENTAL_COMPS_AVG
      ) {
        const farmList = yield select(getRentalCompsFarmListRaw);
        const hasCompFarm = farmList && farmList.length;
        const hasSelectedRentalComps = yield select(getAvmRentalCompsExists);
        if (!hasCompFarm) {
          yield put(vrRouteChange({ view: VIEWS.RENTAL_COMPS_SELECTION_MAP }));
        } else if (!hasSelectedRentalComps) {
          yield put(vrRouteChange({ view: VIEWS.RENTAL_COMPS_SELECTION }));
        }
      }
      yield put(avmsRentalChangeSelectedSuccess(selectedRentalAvm));
    }
  } catch (e) {
    report(`Change Selected Rental AVM: ${e.message}`, { e, action });
  }
}

function* persistRentalAvmChange(action) {
  const isReportSaveable = yield select(getIsReportSaveable);
  if (isReportSaveable) {
    yield put(saveReport());
  }
}

export default function registerSelectRentalAvmSaga() {
  watchEvery({
    [AVMS_RENTAL_CHANGE_SELECTED]: changeSelectedRentalAvm,
    [AVMS_RENTAL_CHANGE_SELECTED_SUCCESS]: persistRentalAvmChange
  });
}
