import { GraphQLClient } from 'graphql-request';
//TODO Create a Typescript typed version of HC_CONSTANTS
//@ts-ignore
import { PROPERTY_GRAPH_URL } from 'HC_CONSTANTS';
import { Listing } from 'src/lib/property-lookup/types';
import { SearchByMlsNumberInput } from '../types';
// @ts-ignore
import { refreshAccessToken } from 'src/legacy/utils/refresh-token-callback';

let cachedClientToken: string | undefined;
let cachedClient: GraphQLClient | undefined;
const getClient = (token: string) => {
  if (!cachedClient || cachedClientToken !== token) {
    cachedClientToken = token;
    cachedClient = new GraphQLClient(PROPERTY_GRAPH_URL, {
      headers: {
        'HC-Auth-Token': token
      }
    });
  }
  return cachedClient;
};
async function submit(
  {
    token,
    input
  }: {
    token: string;
    input: SearchByMlsNumberInput;
  },
  retries = 0
): Promise<Listing[]> {
  const client = getClient(token);
  try {
    const response = await client.request<{
      searchByMlsNumbers: Listing[];
    }>(
      `query searchByMlsNumbers ($items: [MlsNumberSearchInputItem]!) {
      searchByMlsNumbers(mlsNumbers: {
      items: $items
      }) {
        address {
          hcAddressId
          slug
          streetAddress
          unit
          city
          state
          zipcode
        }
        association {
          hasAssociation
          fees {
            amount
            frequency
            type
          }
        }
        countyLegal {
          apn
          subdivision
        }
        entityID
        listingID
        mlsID
        external {
          parking {
            has
            count
            desc
          }
        }
        site {
          lot {
            areaSqFt
          }
        }
        location {
          latitude
          longitude
          precision
        }
        price
        listingStatus{
          MlsState
          daysOnMarket
          contractDate
          contractPrice
          currentStatus
          currentStatusDate
          listingDate
          listingPrice
          offMarketDate
          onMarketDate
          saleDate
          salePrice
          salePricePerGla
        }
        livingSpace {
          basement {
            has
          }
          bathrooms {
            countTotal
          }
          bedrooms {
            countTotal
          }
          grossLivingArea
        }
        publicRemarks
        structure {
          storiesCount
          yearBuilt
          
        }
        taxSummary {
          amount
          rateValue
          year
        }
      }
    }`,
      input
    );
    return response.searchByMlsNumbers;
  } catch (error) {
    const e = error as { response: { status: number } };
    const statusCode = e?.response?.status;
    if (statusCode === 401) {
      const userContext = await refreshAccessToken();
      if (retries === 0) {
        return submit(
          {
            token: userContext.validity.token,
            input
          },
          retries + 1
        );
      }
    }
    throw e;
  }
}

export const SearchByMlsNumberApi = {
  submit
};
