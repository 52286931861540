import { STATUSES } from 'legacy/appstore/constants';

import {
  LISTINGS_FETCH,
  LISTINGS_FETCH_SUCCESS,
  LISTINGS_FETCH_FAILURE
} from 'actions/listings';

const INITIAL_STATE = {};

export const listingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LISTINGS_FETCH: {
      return {
        ...state,
        [action.payload.addressId]: {
          ...state[action.payload.addressId],
          status: STATUSES.LOADING
        }
      };
    }

    case LISTINGS_FETCH_SUCCESS: {
      return {
        ...state,
        [action.payload.addressId]: {
          data: action.payload.listings,
          status: STATUSES.SUCCESS
        }
      };
    }

    case LISTINGS_FETCH_FAILURE: {
      return {
        ...state,
        [action.payload.addressId]: {
          ...state[action.payload.addressId],
          status: STATUSES.ERROR
        }
      };
    }

    default: {
      return state;
    }
  }
};
