import get from 'lodash/get';

import {
  COMP_TYPE_DEFAULT,
  COMP_TYPE_RENTAL,
  MAP_ID_BY_COMP_TYPE,
  MAP_ID_COMPS,
  MAP_ID_RENTAL_COMPS
} from 'legacy/appstore/constants';

import {
  COMPS_FILTER_DISTANCE_CHANGE,
  COMPS_CLEAR_ALL_FILTERS,
  COMPS_CLEAR_FILTER,
  COMPS_MAP_DRAW_SUBMIT,
  COMPS_MAP_DRAW_DELETE_ALL
} from 'actions/comps';
import {
  RENTAL_COMPS_FILTER_DISTANCE_CHANGE,
  RENTAL_COMPS_LOAD_FARM_FOR_DEFAULT_DISTANCE,
  RENTAL_COMPS_CLEAR_ALL_FILTERS,
  RENTAL_COMPS_CLEAR_FILTER,
  RENTAL_COMPS_MAP_DRAW_SUBMIT,
  RENTAL_COMPS_MAP_DRAW_DELETE_ALL
} from 'actions/rental-comps';
import { GET_REPORT_USER_VERSION_SUCCESS } from 'actions/get-report';
import { MAP_DRAW_ADD_POLYGONS } from 'actions/map-draw';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from '../actions/effective-date.actions';

export const INITIAL_STATE_MAP = {
  polygons: []
};

const INITIAL_STATE = {};

const _getCurrentStateForMap = (state, mapId) => ({
  ...INITIAL_STATE_MAP,
  ...get(state, mapId, {})
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMPS_MAP_DRAW_SUBMIT:
    case COMPS_FILTER_DISTANCE_CHANGE:
    case RENTAL_COMPS_LOAD_FARM_FOR_DEFAULT_DISTANCE:
    case RENTAL_COMPS_MAP_DRAW_SUBMIT:
    case RENTAL_COMPS_FILTER_DISTANCE_CHANGE:
      return {
        ...state,
        [action.payload.mapId]: {
          ..._getCurrentStateForMap(state, action.payload.mapId),
          polygons: action.payload.polygons
        }
      };

    case COMPS_CLEAR_ALL_FILTERS:
    case COMPS_MAP_DRAW_DELETE_ALL:
    case RENTAL_COMPS_CLEAR_ALL_FILTERS:
    case RENTAL_COMPS_MAP_DRAW_DELETE_ALL:
      return {
        ...state,
        [action.payload.mapId]: {
          ..._getCurrentStateForMap(state, action.payload.mapId),
          polygons: []
        }
      };

    case COMPS_CLEAR_FILTER:
    case RENTAL_COMPS_CLEAR_FILTER:
      if (action.payload.filterKey === 'distance') {
        return {
          ...state,
          [action.type === COMPS_CLEAR_FILTER
            ? MAP_ID_COMPS
            : MAP_ID_RENTAL_COMPS]: {
            ..._getCurrentStateForMap(state, action.payload.mapId),
            polygons: []
          }
        };
      } else {
        return state;
      }

    // Add saved polygons to state
    case EFFECTIVE_DATE_GET_REPORT_SUCCESS:
    case GET_REPORT_USER_VERSION_SUCCESS:
      return {
        ...state,
        [MAP_ID_BY_COMP_TYPE[COMP_TYPE_DEFAULT]]: {
          polygons: get(
            action,
            ['payload', 'report', 'metadata', 'compFarmAreaPolygons'],
            []
          )
        },
        [MAP_ID_BY_COMP_TYPE[COMP_TYPE_RENTAL]]: {
          polygons: get(
            action,
            ['payload', 'report', 'metadata', 'rentalCompFarmAreaPolygons'],
            []
          )
        }
      };

    case MAP_DRAW_ADD_POLYGONS:
      const updatedState = { ...state };
      for (let mapId in action.payload.polygonsByMapId) {
        updatedState[mapId] = {
          ...state[mapId],
          polygons: action.payload.polygonsByMapId[mapId]
        };
      }
      return updatedState;

    default:
      return state;
  }
}
