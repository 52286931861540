import { watchEvery } from 'legacy/utils/saga';
import { call, put, select } from 'redux-saga/effects';
import { report } from 'hc-ravenjs-logger';
import apiUtil from 'legacy/utils/api';
import HC_CONSTANTS from 'HC_CONSTANTS';
import { STATUSES } from 'legacy/appstore/constants';
import FileSaver from 'file-saver';
import { fetchRentalReport } from 'sagas/rental-report.saga';
import {
  personalizationPromptModalShow,
  personalizationPromptModalHide
} from 'actions/personalization';
import {
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_REQUEST,
  downloadReportRequest,
  DOWNLOAD_REPORT_SECTION,
  showDownloadExcelProgress,
  showDownloadPdfProgress,
  hideDownloadPdfProgress,
  hideDownloadExcelProgress
} from 'actions/download-report';
import { toggleShareComponent } from 'actions/app-header';

import { getRentalReportStatus } from 'selectors/rental-report';
import { getSubjectSelected } from 'selectors/subject';
import { getToken } from 'selectors/account-details';
import { getUserIsReportOwner } from 'selectors/report-permissions';
import { getShowShareDropDown } from 'selectors/app-header';
import { hasNeverEnteredPersonalizationData } from 'selectors/personalization';
import {
  getUniqueLinkUrlParams,
  getSharedReportUid
} from 'selectors/share-report';
import { getFirstPhotoByAddressId } from 'selectors/photos';
import {
  getReportJson,
  getReportJsonSectionExcel,
  getReportJsonForExcel
} from 'selectors/report-json';
import {
  getCurrentReportParams,
  getIsViewingSharedReport
} from 'selectors/property-explorer';
import { downloadPOST } from 'sagas/download-file';

export function* downloadReportCobrandingCheck(action) {
  try {
    yield call(() => {
      /* TODO remove me, then fix complex texts :( */
    });
    const userIsReportOwner = yield select(getUserIsReportOwner);
    const hasNoPersonalizationData = yield select(
      hasNeverEnteredPersonalizationData
    );
    const showPersonalizationPrompt =
      hasNoPersonalizationData && userIsReportOwner;
    if (
      action.payload.fileType === 'pdf' &&
      showPersonalizationPrompt &&
      !action.payload.bypassPersonalizationPrompt
    ) {
      yield put(personalizationPromptModalShow('DOWNLOAD'));
    } else {
      if (action.payload.fileType === 'pdf') {
        yield put(personalizationPromptModalHide());
      }

      yield put(downloadReportRequest(action.payload.fileType));

      const shareDropdownVisible = yield select(getShowShareDropDown);
      if (shareDropdownVisible) {
        yield put(toggleShareComponent());
      }
    }
  } catch (e) {
    report(`Download Report Cobranding Check: ${e.message}`, { e, action });
  }
}

function* downloadReportApiPdf(fileName, sharedReportUid) {
  const pdfDownloadId = yield select((state) => state.pdfDownloadId);
  const response = yield call(
    apiUtil.GET,
    sharedReportUid
      ? `${HC_CONSTANTS.REPORT_API_URL}-public/reports/external/${sharedReportUid}?output=pdf`
      : `${HC_CONSTANTS.REPORT_API_URL}/reports/external/${pdfDownloadId}?output=pdf`,
    undefined,
    {
      receiveFile: true,
      fullResponse: true
    }
  );
  const responseData = yield call([response, response.blob]);
  FileSaver.saveAs(responseData, fileName);
}

export function* startDownloadReport(action) {
  const isSharedReport = yield select(getIsViewingSharedReport);
  const type = action.payload.fileType;
  const subject = yield select(getSubjectSelected);
  const { streetAddress, zipcode } = subject;
  const uniqueLinkUrlParams = yield select(getUniqueLinkUrlParams);
  const sharedReportUid = yield select(getSharedReportUid);
  const fileName = `${streetAddress}.${type}`;
  const addressId = subject.addressId;
  const photo = yield select(getFirstPhotoByAddressId(addressId));

  let reportJson;
  if (type === 'pdf') {
    yield put(showDownloadPdfProgress(streetAddress, zipcode));
    yield call(
      downloadReportApiPdf,
      fileName,
      isSharedReport && sharedReportUid
    );
    yield put(hideDownloadPdfProgress());
  } else {
    const url = isSharedReport
      ? `${HC_CONSTANTS.VRSHARE_PROXY_URL}/emporium/vrshare/transform/to_excel/`
      : `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/transform/to_excel/`;
    reportJson = yield select(getReportJsonForExcel);
    yield put(showDownloadExcelProgress(streetAddress, zipcode));
    if (!reportJson.metadata) {
      reportJson.metadata = {};
    }
    reportJson.metadata.photos = photo && photo.httpUrl ? [photo.httpUrl] : [];
    const data = {
      name: fileName,
      data: reportJson,
      newSheetPerKey: true,
      isFullReport: true
    };
    yield call(downloadPOST, url, uniqueLinkUrlParams, data);
    yield put(hideDownloadExcelProgress());
  }
}

export function* fetchDownloadReportSection(action) {
  // Get rental report data if it has not been downloaded already
  if (action.payload.section === 'comps') {
    const rentalDataStatus = yield select(getRentalReportStatus);
    if (rentalDataStatus === STATUSES.INIT) {
      const currentParams = yield select(getCurrentReportParams);
      yield* fetchRentalReport({
        payload: {
          requestParams: {
            streetAddress: currentParams.streetAddress || currentParams.address,
            zipcode: currentParams.zipcode,
            unit: currentParams.unit,
            format: 'json',
            productType: 'rental'
          },
          goToRentalState: false
        }
      });
    }
  }
  let url;
  const sectionJson = yield select(
    getReportJsonSectionExcel,
    action.payload.section
  );
  const userIsReportOwner = yield select(getUserIsReportOwner);
  let requestParams = {};
  let headers = {};
  if (!userIsReportOwner) {
    url = `${HC_CONSTANTS.VRSHARE_PROXY_URL}/emporium/vrshare/transform/to_excel/`;
    requestParams = yield select(getUniqueLinkUrlParams);
  } else {
    const token = yield select(getToken);
    headers = { 'HC-Auth-Token': token };
    url = `${HC_CONSTANTS.PINKMAN_PROXY_URL}/transforms/to_excel`;
  }
  yield call(downloadPOST, url, requestParams, sectionJson, headers);
}

export default function registerDownloadReportSaga() {
  watchEvery({
    [DOWNLOAD_REPORT]: downloadReportCobrandingCheck,
    [DOWNLOAD_REPORT_REQUEST]: startDownloadReport,
    [DOWNLOAD_REPORT_SECTION]: fetchDownloadReportSection
  });
}
