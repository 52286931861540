export const TOAST_SHOW = 'TOAST_SHOW';
export const TOAST_HIDE = 'TOAST_HIDE';
// NOTE: This action is dispatched from toast-middleware
//       by including { toast: { message: 'Message' } } in any action meta
export const toastShow = (
  message,
  dataHcName,
  messageId = new Date().getTime()
) => {
  return {
    type: TOAST_SHOW,
    payload: {
      message,
      messageId,
      dataHcName
    }
  };
};

export const toastHide = () => ({
  type: TOAST_HIDE
});
