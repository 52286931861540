/**
 * Render TextField and display errors even when field is still untouched/pristine
 *
 * @param Field {Component}
 */
const fieldWithPristineError = (Field) => (props) => {
  return <Field {...props} errorText={props.meta.error} />;
};

export default fieldWithPristineError;
