import { createSelector } from 'reselect';

export const getSupportState = (state) => state.support;

export const getGlossaryValues = createSelector(
  getSupportState,
  (supportState) => supportState.glossary
);

export const getGlossarySelected = createSelector(
  getSupportState,
  (supportState) => supportState.glossarySelected
);

export const getGlossarySelectedValues = createSelector(
  getGlossarySelected,
  getGlossaryValues,
  (selected, values) => selected.map((k) => values[k])
);

export const getHintTextKey = createSelector(
  getSupportState,
  (supportState) => supportState.hintTextKey
);
