import React from 'react';
import { TableCell, TableHeaderCell } from 'src/lib/components/Table';
import { CompFields } from 'src/lib/report-api/types/CompFields';
import { compFieldGetters } from 'src/lib/report-api/utils/comps.utils';
import { formatCityStateZip, formatStreetAddress } from 'src/lib/utils';
import styles from './addressColumn.css';
import { FarmListCellProps, FarmListHeaderCellProps } from '../types';

// These components cannot be used as a JSX Element inside TableRow
// Example Usage:
// <Table>
//   <TableRow>
//     {addressColumn.content({ reportId, hcAddressId })}
//   </TableRow>
// </Table>

export const addressColumn = {
  header: (props: FarmListHeaderCellProps) => {
    return (
      <TableHeaderCell width={200} {...props} sticky>
        Address
      </TableHeaderCell>
    );
  },
  content: ({ schema }: FarmListCellProps) => {
    const address = compFieldGetters[CompFields.address](schema);
    return (
      <TableCell width={200} sticky>
        <div className={styles.StreetAddress}>
          {formatStreetAddress(address)}
        </div>
        <div className={styles.CityStateZip}>{formatCityStateZip(address)}</div>
      </TableCell>
    );
  }
};
