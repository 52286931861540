import * as React from 'react';
import classNames from 'classnames';

import { PlusIcon, CheckIcon } from '../../inline-svgs';

import styles from './SelectButton.css';

// export interface Theme {
//   SelectButton: string;
//   Icon: string;
//   selected: string;
//   disabled: string;
// }

interface Props {
  dataHcName: string;
  className?: string;
  /** Render in the selected state. Will default to false. */
  selected: boolean;
  /** Disables the button, making it unclickable and grey. */
  disabled?: boolean;
  /** Fires after clicking the button when enabled. */
  onClick: () => void;
}

export const SelectButton = ({
  className,
  dataHcName,
  selected,
  disabled,
  onClick
}: Props) => {
  const Icon = selected ? CheckIcon : PlusIcon;
  return (
    <button
      data-hc-name={dataHcName}
      className={classNames(
        styles.SelectButton,
        {
          [styles.selected || '']: selected,
          [styles.disabled || '']: disabled
        },
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          onClick();
        }
      }}
    >
      <Icon
        dataHcName={`${dataHcName}-icon`}
        className={styles.Icon}
        height="10px"
      />
    </button>
  );
};
