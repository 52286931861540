import React from 'react';
import { formatMonthsAgo } from 'src/lib/utils';
import { isLoading } from 'src/lib/utils/general';
import { useDocumentRole } from '../../../hooks';
import { DocumentRoles, FilterDocument } from '../../../types';
import { CompFields } from '../../../types/CompFields';
import { CompFilterButton } from './CompFilterButton';
import { CompFilterListDate } from '../../CompFilters';
import { Skeleton } from 'src/lib/components';
interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
}
const COMP_FIELD = CompFields.lastListDate;
export const CompFilterButtonListDate = ({
  dataHcName,
  reportId,
  className
}: Props) => {
  const {
    status: filterDocumentStatus,
    data: [filterDocument]
  } = useDocumentRole<FilterDocument>(reportId, DocumentRoles.Filter);
  if (isLoading(filterDocumentStatus) || !filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }
  const filterValue = filterDocument.data.filters[COMP_FIELD];
  const active = !!filterValue;
  return (
    <CompFilterButton
      reportId={reportId}
      label={
        active
          ? formatMonthsAgo(filterValue?.relativeValue, 'Listed')
          : 'List Date'
      }
      dataHcName={dataHcName}
      className={className}
      compField={COMP_FIELD}
      content={
        <CompFilterListDate
          reportId={reportId}
          dataHcName={`${dataHcName}-filter-content`}
        />
      }
      active={active}
    />
  );
};
