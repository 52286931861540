import { STATUSES } from 'legacy/appstore/constants';
// Statuses that indicate that loading is in progress or hasn't started
const LOADING_STATUSES = {
  [STATUSES.INIT]: true,
  [STATUSES.LOADING]: true
};
// Statuses that indicate something required has completed initial loading
const LOADED_STATUSES_INITIAL = {
  [STATUSES.SUCCESS]: true,
  [STATUSES.UPDATING]: true
};
// Statuses that indicate something optional has completed loading
const LOADED_STATUSES_OPTIONAL_INITIAL = {
  [STATUSES.SUCCESS]: true,
  [STATUSES.ERROR]: true,
  [STATUSES.DOES_NOT_EXIST]: true,
  [STATUSES.UPDATING]: true
};
// Statuses that indicate something optional has completed loading or updating
const LOADED_STATUSES = {
  [STATUSES.SUCCESS]: true,
  [STATUSES.ERROR]: true,
  [STATUSES.DOES_NOT_EXIST]: true
};

export const apiHasNotBeenCalled = (status) => status === STATUSES.INIT;
export const isLoading = (status) => !!LOADING_STATUSES[status];
export const isLoadedInitial = (status) => !!LOADED_STATUSES_INITIAL[status];
export const isLoadedInitialOptional = (status) =>
  !!LOADED_STATUSES_OPTIONAL_INITIAL[status];
export const isLoaded = (status) => !!LOADED_STATUSES[status];
