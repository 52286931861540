import React from 'react';
import classNames from 'classnames';
import {
  formatDateStr,
  formatMoney,
  formatMoneyPerMonth
} from 'src//lib/utils/formatters';
import { capitalizeFirstLetter } from 'src/lib/utils/strings';
import styles from './ListingStatusBrief.css';
import { ListingStatusForTransactions, getDate } from './statusMapping';
import { Listing } from 'src/lib/property-lookup/types';

interface Props {
  listing?: Listing;
  className?: string;
  dataHcName: string;
}

interface DisplayDetails {
  price: number | null;
  date: `${number}-${number}-${number}` | null;
  type: ListingStatusForTransactions;
}

export const ListingStatusBrief = ({
  listing,
  dataHcName,
  className
}: Props) => {
  if (!listing) return null;
  const { isListingRental } = listing;
  const mappedStatus = ListingStatusForTransactions[listing.status || ''];
  const saleStatus = mappedStatus || 'UNKNOWN';

  const displayDetails: DisplayDetails = {
    price: listing.price,
    date: getDate(listing),
    type: saleStatus
  };

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ListingStatusBrief, className)}
      data-listing-header={listing.listingID}
    >
      <i
        className={classNames(
          styles.Bullet,
          styles[displayDetails.type.toLowerCase()]
        )}
      />
      <span data-hc-name={`${dataHcName}-listing-status`}>
        {capitalizeFirstLetter(displayDetails.type.toLowerCase())}
      </span>{' '}
      <span data-hc-name={`${dataHcName}-listing-date`}>
        {formatDateStr(displayDetails.date)} for{' '}
      </span>
      <span data-hc-name={`${dataHcName}-listing-amount`}>
        {isListingRental
          ? formatMoneyPerMonth(displayDetails.price)
          : formatMoney(displayDetails.price)}
      </span>
    </div>
  );
};
