import React from 'react';
import { MultiInput } from '../MultiInput';
import { SubjectSearchAddress } from './SubjectSearchAddress';
import { ClientIdSearch } from '../ClientIdSearch';
import { useFeatureFlags } from 'legacy/hocs/useFeatureFlags';
import { SearchByMlsNumber } from 'src/lib/search-by-mls-number/features';
import styles from './SubjectSearch.css-module.css';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { useDispatch } from 'react-redux';
import { VIEWS } from 'legacy/routes/constants';
import { useOrgReportPreferences } from '../../hocs/useOrgReportPreferences';

export const SubjectSearch = ({ onBlur, onFocus, onSelect }) => {
  const orgReportPrefs = useOrgReportPreferences();
  const hasOrgSharing =
    orgReportPrefs?.data?.preferences?.intraOrgReportAccess === 'editable' ||
    orgReportPrefs?.data?.preferences?.intraOrgReportAccess === 'visible';
  const dispatch = useDispatch();
  const featureFlags = useFeatureFlags();
  const options = [
    {
      label: 'Address',
      input: (
        <SubjectSearchAddress
          onBlur={onBlur}
          onFocus={onFocus}
          onSelect={onSelect}
        />
      )
    }
  ];
  // Search by Client ID requires Auto-Org-Sharing
  if (hasOrgSharing) {
    options.push({
      label: 'Client File ID',
      value: 'clientId',
      input: (
        <ClientIdSearch onBlur={onBlur} onFocus={onFocus} onSelect={onSelect} />
      )
    });
  }
  if (featureFlags['subject-search-by-mls-number']) {
    options.push({
      label: 'MLS Number',
      value: 'mlsNumber',
      input: (
        <SearchByMlsNumber
          dataHcName="subject-search-by-mls-number"
          queryId="subject-search"
          theme={styles}
          onFocus={onFocus}
          onSelect={(listing) => {
            const routeSearch = {
              view: VIEWS.SEARCH,
              query: {
                street_address: listing.address.streetAddress || undefined,
                zipcode: listing.address.zipcode || undefined,
                unit: listing.address.unit || undefined,
                address_slug: listing.address.slug || undefined,
                listing_id: listing.listingID || undefined,
                listing_source: listing.mlsID || undefined
              }
            };
            dispatch(routeChange(routeSearch));
          }}
          noBorder
        />
      )
    });
  }

  return (
    <MultiInput
      dataHcName="subject-search"
      inputTypeSelected="Address"
      dropdownWidth={140}
      options={options}
    />
  );
};
