import { watchEvery } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { call, put } from 'redux-saga/effects';
import { toastShow } from 'actions/toast';

import { BROWSER_OPEN, BROWSER_REDIRECT } from 'actions/browser';

export function* browserOpen(url) {
  // SECURITY: false posititive, window.open is not fs.open @jnettleman
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  let newWindow = window.open(url, '_blank');
  if (!newWindow) {
    yield put(toastShow('Please disable any popup blockers and try again.'));
  }
}

export function* browserRedirect(url) {
  // assign will not replace the item in history
  window.location.assign(window.decodeURIComponent(url));
}

export function* open(action) {
  const { url } = action.payload;
  try {
    yield call(browserOpen, url);
  } catch (e) {
    report('Browser Saga: open', { e, action });
  }
}

export function* redirect(action) {
  const { url } = action.payload;
  try {
    yield call(browserRedirect, url);
  } catch (e) {
    report('Browser Saga: redirect', { e, action });
  }
}

export function registerBrowserSaga() {
  watchEvery({
    [BROWSER_OPEN]: open,
    [BROWSER_REDIRECT]: redirect
  });
}
