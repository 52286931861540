import classNames from 'classnames';
import { PropertyAttributeValue as theme } from 'legacy/css-modules';

const PropertyAttributeValue = ({
  attrKey,
  loading,
  displayValue,
  className,
  expandable,
  expanded,
  isComponent,
  onExpandToggle,
  dataHcName
}) => {
  return (
    <span
      data-hc-name={dataHcName}
      className={classNames(className, theme.AttributeValue, {
        [theme.loading]: loading
      })}
    >
      {loading
        ? null
        : expandable && displayValue !== '--'
        ? expanded
          ? [
              <span key="val">{displayValue}</span>,
              <span
                key="more"
                onClick={() => onExpandToggle(attrKey)}
                className={theme.ExpandButton}
              >
                See Less
              </span>
            ]
          : [
              <span key="val">
                {isComponent ? displayValue : displayValue.slice(0, 100)}
              </span>,
              <span key="ell">...</span>,
              <span
                key="more"
                onClick={() => onExpandToggle(attrKey)}
                className={theme.ExpandButton}
              >
                See More
              </span>
            ]
        : displayValue}
    </span>
  );
};

export default PropertyAttributeValue;
