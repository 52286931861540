import { AxiosError } from 'axios';
import { put, select, takeEvery } from 'redux-saga/effects';
import { reportFetchError, documentFetch } from '../actions';
import { DocumentBase } from '../types';
import {
  ReportEventReceivedAction,
  REPORT_EVENT_RECEIVED
} from '../actions/reportEvents.actions';
import { selDocument, selDocumentRoleStatus } from '../selectors';
import { STATUSES } from 'src/constants';

export function* handleReportEventReceived(action: ReportEventReceivedAction) {
  const { reportId, reportEvent } = action.payload;
  try {
    for (let i = 0; i < reportEvent.report.documents.length; i++) {
      const updatedDocumentData = reportEvent.report.documents[i];
      const eventTime = updatedDocumentData?.updatedAt;
      if (updatedDocumentData && eventTime) {
        const documentRoleStatus: STATUSES | undefined = yield select(
          selDocumentRoleStatus([reportId, updatedDocumentData.role])
        );
        const documentOnState: DocumentBase | undefined = yield select(
          selDocument([reportId, updatedDocumentData.documentID])
        );
        if (
          // Document is loaded and out of date
          (documentOnState &&
            documentOnState.updatedAt &&
            documentOnState.updatedAt < eventTime) ||
          // or Document role is loaded but this document didn't exist
          (documentRoleStatus &&
            documentRoleStatus !== STATUSES.LOADING &&
            !documentOnState)
        ) {
          // Document is loaded but out of date
          yield put(documentFetch(reportId, updatedDocumentData.documentID));
        }
      }
    }
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status !== 404) {
        console.log('[TODO] Report Error To Sentry');
      }
      yield put(
        reportFetchError(action.payload.reportId, error.response.data.message)
      );
    } else if (error.request) {
      yield put(
        reportFetchError(action.payload.reportId, error.request.data.message)
      );
    }
  }
}

export function* reportEventsSagas() {
  yield takeEvery(REPORT_EVENT_RECEIVED, handleReportEventReceived);
}
