export const RESET_APP_STATE = 'RESET_APP_STATE';
export const RESET_VR_STATE = 'RESET_VR_STATE';

export const resetAppState = () => ({ type: RESET_APP_STATE });
export const resetVrState = () => ({ type: RESET_VR_STATE });

export const STANDARD_CLEAR_ACTIONS = [RESET_APP_STATE];

// helper to clear reducers on several actions, so we don't need to repeat common clear actions in many reducers
export function clearOnActions(reducer, clearActions) {
  if (!clearActions) {
    throw new Error(
      'clearOnActions needs to be called with an array of actions'
    );
  }
  return (state, action) => {
    if (clearActions.indexOf(action.type) !== -1) {
      return reducer(undefined, action);
    } else {
      return reducer(state, action);
    }
  };
}
