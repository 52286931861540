import { watchEvery } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { put, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

import {
  AVM_KEY_HC,
  AVM_KEY_USER,
  AVM_KEY_COMPS,
  AVM_KEY_COMPS_AVG,
  REPORT_SECTION_EDIT_PROP_DETAILS
} from 'legacy/appstore/constants';
import { VIEWS } from 'legacy/routes/constants';
import { EVENTS } from 'legacy/appstore/events';

import {
  AVMS_CHANGE_SELECTED,
  AVMS_CHANGE_SELECTED_SUCCESS,
  avmsChangeSelectedSuccess
} from 'actions/avms';
import { saveReport, vrEditPropertyDetails } from 'actions/edit-report';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import { analyticsEvent } from 'actions/analytics';
import { reportScrollToSection } from 'actions/property-explorer';
import { vrRouteChange } from 'actions/navigation';

import {
  getAvmUser,
  getAvmComps,
  getAvmCompsExists,
  getAvmCompsAvg
} from 'selectors/avms';
import { getCompsFarmListRaw } from 'selectors/comps';
import {
  getIsReportEditable,
  getIsReportSaveable
} from 'selectors/report-permissions';

function* changeSelectedAvm(action) {
  try {
    const isAvmChangeable = yield select(getIsReportEditable);
    if (isAvmChangeable) {
      const { selectedAvm } = action.payload;
      yield put(avmsChangeSelectedSuccess(selectedAvm));
      switch (selectedAvm) {
        case AVM_KEY_USER:
          const avmUser = yield select(getAvmUser);
          if (!avmUser || !avmUser.value) {
            yield put(vrEditPropertyDetails());
            yield put(reportScrollToSection(REPORT_SECTION_EDIT_PROP_DETAILS));
          }
          break;

        case AVM_KEY_COMPS:
        case AVM_KEY_COMPS_AVG:
          const hasSelectedComps = yield select(getAvmCompsExists);
          const farmList = yield select(getCompsFarmListRaw);
          const isEffectiveDate = yield select(getIsEffectiveDateReport);
          const hasCompFarm = farmList && farmList.length;
          if (!hasCompFarm) {
            yield put(
              vrRouteChange({
                view: isEffectiveDate
                  ? VIEWS.EFFECTIVE_DATE_COMPS_MAP
                  : VIEWS.COMPS_SELECTION_MAP
              })
            );
          } else if (!hasSelectedComps) {
            yield put(
              vrRouteChange({
                view: isEffectiveDate
                  ? VIEWS.EFFECTIVE_DATE_COMPS
                  : VIEWS.COMPS_SELECTION
              })
            );
          }
          break;

        default:
          break;
      }

      if (selectedAvm !== AVM_KEY_HC) {
        const userAvm = yield select(getAvmUser);
        // Dispatch Adjust Details event if the avm doesn't exist
        if (isEmpty(userAvm)) {
          yield put(
            analyticsEvent(
              EVENTS.REPORT_ADJUST_SUBJECT_PROPERTY_DETAILS_START,
              { button: 'avm_selector' }
            )
          );
        }
      }
    }
  } catch (e) {
    console.error(e);
    report(`Change Selected AVM: ${e.message}`, { e, action });
  }
}

function* persistAvmChange(action) {
  const { selectedAvm } = action.payload;
  const avmUser = yield select(getAvmUser);
  const avmComps = yield select(getAvmComps);
  const avmCompsAvg = yield select(getAvmCompsAvg);
  const isReportSaveable = yield select(getIsReportSaveable);

  // Persist AVM change if AVM already exists
  // If AVM doesn't exist, it will be persisted when it's created
  if (
    isReportSaveable &&
    (selectedAvm === AVM_KEY_HC ||
      (selectedAvm === AVM_KEY_USER && !isEmpty(avmUser)) ||
      (selectedAvm === AVM_KEY_COMPS && !isEmpty(avmComps)) ||
      (selectedAvm === AVM_KEY_COMPS_AVG && !isEmpty(avmCompsAvg)))
  ) {
    yield put(saveReport());
  }
}

export default function registerSelectAvmSaga() {
  watchEvery({
    [AVMS_CHANGE_SELECTED]: changeSelectedAvm,
    [AVMS_CHANGE_SELECTED_SUCCESS]: persistAvmChange
  });
}
