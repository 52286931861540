import React, { MouseEvent, useEffect, useState } from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';
import thumbnailPlaceholderImg0 from 'src/lib/components/assets/images/thumbnail-placeholder-0.jpg';
import thumbnailPlaceholderImg1 from 'src/lib/components/assets/images/thumbnail-placeholder-1.png';
import thumbnailPlaceholderImg2 from 'src/lib/components/assets/images/thumbnail-placeholder-2.png';
import thumbnailPlaceholderImg3 from 'src/lib/components/assets/images/thumbnail-placeholder-3.png';
import thumbnailPlaceholderImg4 from 'src/lib/components/assets/images/thumbnail-placeholder-4.png';
import thumbnailPlaceholderImg5 from 'src/lib/components/assets/images/thumbnail-placeholder-5.png';
import { ChevronIcon } from '../../inline-svgs';
import { ThumbnailImage } from './ThumbnailImage';

import styles from './ThumbnailCarousel.css';

type PlaceholderImgs = Array<string>;

// type Theme = {
//   ThumbnailCarousel: string,
//   CurrentImage: string,
//   Controls: string,
//   Arrow: string,
//   right: string,
//   Loading: string,
//   Counter: string,
//   Overlay: string
// }

export type ClickCallback = (e: MouseEvent, index: number) => void;

type Props = {
  /** Class to apply to the root element */
  className?: string;
  /** Array of image URLs to render inside of slider
      optional so you can compose with ThumbnailImage in consuming app */
  urls?: string[];
  /** Whether to always show controls */
  alwaysShowControls?: boolean;
  /** Whether to show controls on hover */
  showControlsOnHover?: boolean;
  /** Placeholder images that are shown while thumbnails are loading */
  placeholderImgs?: Array<string>;
  style?: { [key: string]: string | number };
  onClick?: ClickCallback;
  onMouseLeave?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onClickNext?: ClickCallback;
  onClickPrev?: ClickCallback;
  children?: ReactNode;
  /** String passed for QA automation testing */
  dataHcName: string;
  /** Url for image overlay */
  overlayUrl?: string;
};

type PlaceholderImgMapping = { [key: number]: number };

type ArrowProps = {
  dataHcName: string;
  direction: string;
  onClick: (e: MouseEvent) => void;
};

const Arrow = ({ direction, onClick, dataHcName }: ArrowProps) => {
  return (
    <ChevronIcon
      dataHcName={dataHcName}
      className={classNames(styles.Arrow, styles[direction])}
      onClick={onClick}
    />
  );
};

const _pickRandomPlaceholderImgIdx = (
  placeholderImgs: PlaceholderImgs
): number => {
  if (placeholderImgs && placeholderImgs.length > 1) {
    return Math.floor(Math.random() * (placeholderImgs.length - 1)) + 1;
  } else {
    return 0;
  }
};

const _buildUpdatedPlaceholderImgMapping = (
  index: number,
  placeholderImgs: PlaceholderImgs,
  placeholderImgMapping: PlaceholderImgMapping
): Record<number, number> => {
  if (
    placeholderImgs &&
    placeholderImgs.length &&
    !placeholderImgMapping[index] &&
    placeholderImgMapping[index] !== 0
  ) {
    return {
      ...placeholderImgMapping,
      [index]: _pickRandomPlaceholderImgIdx(placeholderImgs)
    };
  } else {
    return placeholderImgMapping;
  }
};

/**
 * Displays a simple image thumbnail carousel for use in property cards and other
 * list items. We're opting not to use React-slick here since we might have tens
 * or hundreds of these rendered at once, which would dramatically hinder performance.
 */
const DEFAULT_CURRENT_INDEX = 0;
const DEFAULT_THUMBNAIL_PLACEHOLDERS = [
  thumbnailPlaceholderImg0,
  thumbnailPlaceholderImg1,
  thumbnailPlaceholderImg2,
  thumbnailPlaceholderImg3,
  thumbnailPlaceholderImg4,
  thumbnailPlaceholderImg5
];
const emptyPlaceholderImgMapping = {} as PlaceholderImgMapping;
export const ThumbnailCarousel = ({
  urls = [],
  placeholderImgs = DEFAULT_THUMBNAIL_PLACEHOLDERS,
  alwaysShowControls = false,
  showControlsOnHover = true,
  style = {},
  dataHcName = 'thumbnail-carousel',
  className,
  children,
  overlayUrl,
  onClick,
  onClickNext,
  onClickPrev,
  onMouseEnter,
  onMouseLeave
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState(DEFAULT_CURRENT_INDEX);
  const [isShowingControls, setIsShowingControls] = useState(false);
  // Stores placeholders so the same placeholder is always shown for the same index
  const [placeholderImgMapping, setPlaceholderImgMapping] = useState(
    placeholderImgs && placeholderImgs.length
      ? {
          [DEFAULT_CURRENT_INDEX]: _pickRandomPlaceholderImgIdx(placeholderImgs)
        }
      : emptyPlaceholderImgMapping
  );
  const handleMouseEnter = (): void => {
    if (!alwaysShowControls) {
      // No need to maintain this on state when we are always showing controls
      // This will allow us to change whether controls are always shown based on the props
      setIsShowingControls(showControlsOnHover);
    }
    if (onMouseEnter) {
      onMouseEnter();
    }
  };

  const handleMouseLeave = (): void => {
    if (!alwaysShowControls) {
      setIsShowingControls(false);
    }
    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  const handleClickNext = (e: MouseEvent): void => {
    e.stopPropagation();
    const numItemsToRender = children
      ? React.Children.count(children)
      : urls.length;
    const newIndex =
      currentIndex === numItemsToRender - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    setPlaceholderImgMapping(
      _buildUpdatedPlaceholderImgMapping(
        newIndex,
        placeholderImgs,
        placeholderImgMapping
      )
    );
    if (onClickNext) {
      onClickNext(e, newIndex);
    }
  };

  const handleClickPrev = (e: MouseEvent): void => {
    e.stopPropagation();
    const numItemsToRender = children
      ? React.Children.count(children)
      : urls.length;
    const newIndex =
      currentIndex === 0 ? numItemsToRender - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    setPlaceholderImgMapping(
      _buildUpdatedPlaceholderImgMapping(
        newIndex,
        placeholderImgs,
        placeholderImgMapping
      )
    );
    if (onClickPrev) {
      onClickPrev(e, newIndex);
    }
  };

  const handleOnClick = (e: MouseEvent) => {
    if (typeof onClick === 'function') {
      e.stopPropagation();
      onClick(e, currentIndex);
    }
  };

  useEffect(() => {
    const numPhotos = children
      ? React.Children.count(children)
      : (urls && urls.length) || 0;
    const maxIndex = Math.max(0, numPhotos - 1);
    if (currentIndex > maxIndex) {
      setPlaceholderImgMapping(
        _buildUpdatedPlaceholderImgMapping(
          maxIndex,
          placeholderImgs,
          placeholderImgMapping
        )
      );
    }
  }, [placeholderImgs, children, urls]);

  const numItemsToRender = children
    ? React.Children.count(children)
    : urls.length;
  const placeholderImgIdx = placeholderImgMapping[currentIndex];
  const photoUrl = urls[currentIndex];
  return (
    <div
      className={classNames(styles.ThumbnailCarousel, className)}
      style={style}
      data-hc-name={dataHcName}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleOnClick}
    >
      {
        // Render children if provided, otherwise fallback to deprecated urls prop
        children && numItemsToRender > 0 ? (
          <div className={styles.CurrentImage}>
            {React.Children.toArray(children)[currentIndex]}
          </div>
        ) : (
          <div className={styles.CurrentImage}>
            {photoUrl && (
              <ThumbnailImage
                url={photoUrl}
                dataHcName={`${dataHcName}-image-${currentIndex}`}
              />
            )}
          </div>
        )
      }
      {placeholderImgs &&
        placeholderImgs.length > 0 &&
        placeholderImgIdx !== undefined && (
          <div
            className={styles.Loading}
            style={{
              backgroundImage: `url('${placeholderImgs[placeholderImgIdx]}')`
            }}
          />
        )}
      {overlayUrl && (
        <div
          className={styles.Overlay}
          style={{ backgroundImage: `url('${overlayUrl}')` }}
        />
      )}
      {(isShowingControls || alwaysShowControls) && numItemsToRender > 0 && (
        <div className={styles.Controls}>
          {numItemsToRender > 1 && (
            <>
              <Arrow
                dataHcName={`${dataHcName}-button-next`}
                direction="right"
                onClick={handleClickNext}
              />
              <Arrow
                dataHcName={`${dataHcName}-button-next`}
                direction="left"
                onClick={handleClickPrev}
              />
            </>
          )}
          <div className={styles.Counter}>
            {currentIndex + 1} / {numItemsToRender}
          </div>
        </div>
      )}
    </div>
  );
};
