import { dollarsFormatter, noFormat } from 'legacy/utils/formatters';
import { displayDate } from 'legacy/utils/dates';

import TransactionAppreciation from 'legacy/components/TransactionAppreciation';

export const EVENT_DISPLAY_NAMES = {
  listed: 'Listed',
  rentalListed: 'Listed for Rent',
  pending: 'Pending',
  rentalPending: 'Lease Pending',
  sold: 'Sold',
  rentalLeased: 'Leased',
  deleted: 'Deleted',
  rentalDeleted: 'Deleted Rental Listing'
};

export const TRANSACTION_HISTORY_COLUMNS = [
  {
    label: 'Date',
    key: 'date',
    format: displayDate,
    width: 105
  },
  {
    label: 'Event',
    key: 'event',
    format: (e) => EVENT_DISPLAY_NAMES[e] || '--',
    width: 120
  },
  {
    label: 'Type',
    format: noFormat,
    key: 'salesType',
    width: 150
  },
  {
    label: 'Price',
    key: 'price',
    format: (v) => (v ? dollarsFormatter(v) : '--'),
    width: 120
  },
  {
    label: 'Appreciation',
    key: 'appreciation',
    format: (props) => <TransactionAppreciation {...props} />,
    width: 150
  },
  {
    label: 'ADOM',
    tooltipText:
      'Number of active days on market without an accepted offer. This metric is provided by the MLS listing and may not account for temporary status changes.',
    key: 'daysOnMarket',
    format: noFormat,
    width: 60
  },
  {
    label: 'Source',
    key: 'source',
    format: noFormat,
    width: 150
  }
];
