import { call } from 'redux-saga/effects';

import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

import graphqlRequest from 'legacy/services/graphql';

export const transactionHistoryQuery = ({ addressId }) => {
  return {
    key: QUERIES.TRANSACTION_HISTORY,
    query: `
    transactionHistory(addressId: ${addressId}) {
      events {
        transactionId
        daysOnMarket
        isRentalEvent
        mlsNumber
        hcMlsId
        listingDate
        price
        date
        showButtons
        event
        eventIdx
        salesType
        source
        appreciation {
          price
          percent
        }
      }
    }`
  };
};

const fetchTransactionHistory = function* (addressId) {
  const query = transactionHistoryQuery(addressId);
  return yield call(graphqlRequest, {
    source: PROPERTY_DATA_SOURCES.HC,
    query
  });
};

export default fetchTransactionHistory;
