import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react';
import { CompCompareDialog, CompIdentifier } from '../features/CompCompare';
type Context = [
  CompIdentifier | null,
  Dispatch<SetStateAction<CompIdentifier | null>>
];

const CompCompareOverlayContext = createContext<Context>([
  null,
  () => {
    return;
  }
]);

export const CompCompareOverlayProvider = ({
  children,
  reportId
}: {
  reportId: number;
  children: ReactNode;
}) => {
  const [compCompareId, setCompCompareId] = useState<CompIdentifier | null>(
    null
  );
  return (
    <CompCompareOverlayContext.Provider
      value={[compCompareId, setCompCompareId]}
    >
      {children}
      <CompCompareDialog
        dataHcName="comp-compare-dialog"
        reportId={reportId}
        compIdentifier={compCompareId}
        onClose={() => {
          setCompCompareId(null);
        }}
      />
    </CompCompareOverlayContext.Provider>
  );
};

export const useCompCompareOverlay = () => {
  const [compCompareId, setCompCompareId] = useContext(
    CompCompareOverlayContext
  );
  return {
    compCompareId,
    compCompareOpen: (compIdentifier: CompIdentifier) => {
      setCompCompareId(compIdentifier);
    },
    compCompareClose: () => {
      setCompCompareId(null);
    }
  };
};
