import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RTThemedSnackbar as Snackbar } from '@hc/component-lib/hclib/rt-themed';

import { Toast as theme } from 'legacy/css-modules';

const Toast = ({
  open,
  message = '',
  autoHideDuration,
  dataHcName,
  messageId,
  onHide
}) => (
  <Snackbar
    key={messageId}
    label={message}
    active={open}
    timeout={autoHideDuration}
    onTimeout={onHide}
    className={classNames(theme.Toast, `dataHcName--${dataHcName}`)}
  />
);

Toast.defaultProps = {
  autoHideDuration: 6000,
  open: false,
  message: '',
  dataHcName: 'toast'
};

Toast.propTypes = {
  messageId: PropTypes.number, // Allows toast middleware to work
  message: PropTypes.string,
  autoHideDuration: PropTypes.number,
  open: PropTypes.bool,
  getScreenSize: PropTypes.string
};

export default Toast;
