import { ROUTE_UPDATE } from '@hc/redux-saga-router-plus/hclib/actions';
import {
  LOGIN,
  LOGIN_ERROR,
  NOT_AUTHORIZED,
  LOGIN_PROMPT_HIDE,
  LOGIN_PROMPT_SHOW_SIGNUP,
  AUTH_REDIRECT_TO_LOGIN,
  AUTH_REDIRECT_FROM_LOGIN
} from 'actions/auth';
import { STATUSES } from 'legacy/appstore/constants';

import { CREATE_USER_SUCCESS } from 'legacy/appstore/actions/sign-up.actions';
import { LOGIN_SUCCESS } from '../actions/auth.actions';

const INITIAL_STATE = {
  status: STATUSES.INIT,
  errorMessage: null,
  loginPrompt: {
    isOpen: false,
    afterAuthAction: null
  },
  loginRedirect: {}
};

export function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        status: STATUSES.LOADING,
        errorMessage: null
      };

    case LOGIN_ERROR:
    case NOT_AUTHORIZED:
      const errorMessage = action.payload.errorMessage;
      return {
        ...state,
        status: STATUSES.ERROR,
        errorMessage
      };

    case CREATE_USER_SUCCESS:
    case LOGIN_PROMPT_HIDE:
    case ROUTE_UPDATE:
      return {
        ...state,
        loginPrompt: {
          afterAuthAction: null,
          isOpen: false
        }
      };

    case LOGIN_PROMPT_SHOW_SIGNUP:
      return {
        ...state,
        loginPrompt: {
          afterAuthAction: action.payload.afterAuthAction,
          isOpen: true
        }
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        status: STATUSES.INIT,
        errorMessage: null
      };

    case AUTH_REDIRECT_TO_LOGIN:
      return {
        ...state,
        loginRedirect: {
          view: action.payload.view,
          params: action.payload.params,
          query: action.payload.query
        }
      };

    case AUTH_REDIRECT_FROM_LOGIN:
      return {
        ...state,
        loginRedirect: INITIAL_STATE.loginRedirect
      };

    default:
      return state;
  }
}
