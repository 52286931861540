import HC_CONSTANTS from 'HC_CONSTANTS';
import { NON_DISCLOSURE_STATES } from 'src/constants';

const { SCRUB_DATA_ORG_IDS } = HC_CONSTANTS;

export const checkShouldScrub = (state, orgId) => {
  if (
    SCRUB_DATA_ORG_IDS.find((i) => i === orgId) &&
    NON_DISCLOSURE_STATES.find((s) => s === state)
  ) {
    return true;
  }
  return false;
};
