import { Personalization } from 'src/lib/huell/types';

export const COBRANDING_REQUIRED = 'COBRANDING_REQUIRED';
export const COBRANDING_FETCH = 'COBRANDING_FETCH';
export const COBRANDING_FETCH_SUCCESS = 'COBRANDING_FETCH_SUCCESS';
export const COBRANDING_FETCH_ERROR = 'COBRANDING_FETCH_ERROR';

export interface CobrandingRequiredAction {
  type: typeof COBRANDING_REQUIRED;
  payload: {
    id: number;
  };
}
export const cobrandingRequired = (id: number) => {
  return {
    type: COBRANDING_REQUIRED,
    payload: { id }
  };
};

export interface CobrandingFetchAction {
  type: typeof COBRANDING_FETCH;
  payload: {
    id: number;
  };
}
export const cobrandingFetch = (id: number): CobrandingFetchAction => {
  return {
    type: COBRANDING_FETCH,
    payload: { id }
  };
};

export interface CobrandingFetchSuccessAction {
  type: typeof COBRANDING_FETCH_SUCCESS;
  payload: {
    id: number;
    data: Personalization;
  };
}
export const cobrandingFetchSuccess = (
  id: number,
  data: Personalization
): CobrandingFetchSuccessAction => {
  return {
    type: COBRANDING_FETCH_SUCCESS,
    payload: { id, data }
  };
};

export interface CobrandingFetchErrorAction {
  type: typeof COBRANDING_FETCH_ERROR;
  payload: {
    id: number;
    message: string;
  };
}
export const cobrandingFetchError = (
  id: number,
  message: string
): CobrandingFetchErrorAction => {
  return {
    type: COBRANDING_FETCH_ERROR,
    payload: { id, message }
  };
};

export type CobrandingActions =
  | CobrandingRequiredAction
  | CobrandingFetchAction
  | CobrandingFetchSuccessAction
  | CobrandingFetchErrorAction;
