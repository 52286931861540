import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './Toggle.css';
import { useDynamicRefs } from 'src/lib/hooks/useDynamicRefs';

interface Option<T> {
  label: string | number | ReactNode;
  value: T;
  disabled?: boolean;
  /** Selector for automated testing. */
  dataHcName?: string;
}

// export interface Theme {
//   HorizontalToggle: string;
//   Disabled: string;
//   Selected: string;
//   Option: string;
//   SelectedMask: string;
//   disabledOption: string;
// }

export interface Props<T> {
  /** Options for the toggle. Format: { label: <string>, value: <any> }  */
  options: Array<Option<T>>;
  /** Callback executed on select, with the selected option's value as the argument */
  onChange: (value: T) => void;
  /** Whether the control is disabled */
  disabled?: boolean;
  /** The value that should be shown as selected */
  value: T;
  /** Selector for automated testing */
  dataHcName: string;
  /** Class Name */
  className?: string;
  style?: React.CSSProperties;
}

export const Toggle = <T extends string | number | null | boolean>({
  className,
  value,
  options,
  onChange,
  disabled,
  dataHcName,
  style
}: Props<T>) => {
  const [width, setWidth] = useState(0);
  const [setRef, getRef] = useDynamicRefs<HTMLDivElement>();
  const calcWidth = () => {
    let largestOptionsWidth = 0;
    options.forEach((option, i) => {
      const ref = getRef(`option-${i}`);
      if (ref?.current) {
        largestOptionsWidth = Math.max(
          ref.current.offsetWidth,
          largestOptionsWidth
        );
      }
    });
    setWidth(largestOptionsWidth);
  };
  useEffect(() => {
    calcWidth();
  }, [options]);
  let selectedIndex = 0;
  return (
    <div
      style={{ ...style, visibility: width ? 'visible' : 'hidden' }}
      className={classNames(styles.Toggle, className, {
        [styles.disabled || '']: disabled
      })}
      data-hc-name={dataHcName}
    >
      {options.map((option, i) => {
        if (option.value === value) {
          selectedIndex = i;
        }
        return (
          <div
            key={`toggle-${option.value}`}
            ref={setRef(`option-${i}`)}
            style={width ? { width: `${width}px` } : undefined}
            className={classNames(styles.Option, {
              [styles.disabledOption || '']: option.disabled
            })}
            data-hc-name={option.dataHcName || `${dataHcName}-option-${i}`}
            onClick={() => {
              if (!disabled && !option.disabled) {
                onChange(option.value);
              }
            }}
          >
            {option.label || option.value}
          </div>
        );
      })}
      <div
        className={styles.SelectedMask}
        style={{
          width: `${width}px`,
          left: `${width * selectedIndex}px`
        }}
      />
    </div>
  );
};
