import React from 'react';
import classNames from 'classnames';

import { dollarsFormatter } from 'legacy/utils/formatters';

import { Price as theme } from 'legacy/css-modules';

const Price = ({ label, value, date, className, dataHcName }) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(theme.Price, className)}
    >
      {(label || date) && (
        <div>
          {label && (
            <span data-hc-name={`${dataHcName}-label`} className={theme.Label}>
              {label}
            </span>
          )}
          {
            // NOTE: Date styles not provided yet and may not be needed
            date && (
              <span data-hc-name={`${dataHcName}-date`} className={theme.Date}>
                {date}
              </span>
            )
          }
        </div>
      )}
      <div data-hc-name={`${dataHcName}-value`} className={theme.Value}>
        {dollarsFormatter(value)}
      </div>
    </div>
  );
};

Price.defaultProps = {
  dataHcName: 'price'
};

export default Price;
