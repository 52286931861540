import { ReportEvent } from '../types/ReportEvents';

export const REPORT_EVENT_SUBSCRIBED = 'report-api/REPORT_EVENT_SUBSCRIBED';
export const REPORT_EVENT_RECEIVED = 'report-api/REPORT_EVENT_RECEIVED';
export const REPORT_EVENT_ERROR = 'report-api/REPORT_EVENT_ERROR';

export interface ReportEventReceivedAction {
  type: typeof REPORT_EVENT_RECEIVED;
  payload: {
    reportId: number;
    reportEvent: ReportEvent;
  };
}
export const reportEventReceived = (
  reportId: number,
  reportEvent: ReportEvent
): ReportEventReceivedAction => {
  return {
    type: REPORT_EVENT_RECEIVED,
    payload: {
      reportId,
      reportEvent
    }
  };
};

export interface ReportEventSubscribedAction {
  type: typeof REPORT_EVENT_SUBSCRIBED;
  payload: {
    reportId: number;
  };
}
export const reportEventSubscribed = (
  reportId: number
): ReportEventSubscribedAction => {
  return {
    type: REPORT_EVENT_SUBSCRIBED,
    payload: {
      reportId
    }
  };
};

export interface ReportEventErrorAction {
  type: typeof REPORT_EVENT_ERROR;
  payload: {
    reportId: number;
  };
}
export const reportEventError = (reportId: number): ReportEventErrorAction => {
  return {
    type: REPORT_EVENT_ERROR,
    payload: {
      reportId
    }
  };
};

export type ReportEventAction =
  | ReportEventReceivedAction
  | ReportEventSubscribedAction
  | ReportEventErrorAction;
