import { EVENTS } from 'legacy/appstore/events';

export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_REQUEST = 'DOWNLOAD_REPORT_REQUEST';
export const SHOW_DOWNLOAD_PDF_PROGRESS = 'SHOW_DOWNLOAD_PDF_PROGRESS';
export const HIDE_DOWNLOAD_PDF_PROGRESS = 'HIDE_DOWNLOAD_PDF_PROGRESS';
export const SHOW_DOWNLOAD_EXCEL_PROGRESS = 'SHOW_DOWNLOAD_EXCEL_PROGRESS';
export const HIDE_DOWNLOAD_EXCEL_PROGRESS = 'HIDE_DOWNLOAD_EXCEL_PROGRESS';
export const DOWNLOAD_REPORT_SECTION = 'DOWNLOAD_REPORT_SECTION';

export const downloadReport = (
  fileType,
  bypassPersonalizationPrompt = false
) => ({
  type: DOWNLOAD_REPORT,
  payload: {
    fileType,
    bypassPersonalizationPrompt
  },
  meta: {
    toast: {
      message: 'Your download will begin shortly...'
    }
  }
});

export const downloadReportRequest = (fileType) => ({
  type: DOWNLOAD_REPORT_REQUEST,
  payload: {
    fileType
  }
});

export const showDownloadPdfProgress = (
  streetAddress,
  zipcode,
  selectedAvmKey
) => {
  return {
    type: SHOW_DOWNLOAD_PDF_PROGRESS,
    meta: {
      analytics: {
        event: EVENTS.DOWNLOAD_PDF
      }
    }
  };
};

export const hideDownloadPdfProgress = () => ({
  type: HIDE_DOWNLOAD_PDF_PROGRESS,
  meta: {
    toast: {
      message: 'Report PDF Downloaded'
    }
  }
});

export const showDownloadExcelProgress = (
  streetAddress,
  zipcode,
  selectedAvmKey
) => ({
  type: SHOW_DOWNLOAD_EXCEL_PROGRESS,
  meta: {
    analytics: {
      event: EVENTS.DOWNLOAD_EXCEL
    }
  }
});

export const hideDownloadExcelProgress = () => ({
  type: HIDE_DOWNLOAD_EXCEL_PROGRESS,
  meta: {
    toast: {
      message: 'Report Excel File Downloaded'
    }
  }
});

export const downloadReportSection = (section) => ({
  type: DOWNLOAD_REPORT_SECTION,
  payload: { section },
  meta: {
    analytics: {
      event: EVENTS.DOWNLOAD_SECTION_EXCEL,
      props: {
        section
      }
    }
  }
});
