import { createSelector } from 'reselect';
import { HUELL_REDUCER_KEY, StateWithHuell } from './reducer';
import { STATUSES } from 'src/constants';

export const selHuellState = (state: StateWithHuell) =>
  state[HUELL_REDUCER_KEY];

export const selHuellPreferencesState = createSelector(
  selHuellState,
  (huellState) => huellState.preferences
);

export const selPreferencesStatus = createSelector(
  selHuellPreferencesState,
  (preferenceState) => preferenceState?.status
);

export const selPreferencesFetching = createSelector(
  selHuellPreferencesState,
  (preferenceState) => preferenceState?.fetching
);

export const selPreferences = createSelector(
  selHuellPreferencesState,
  (preferenceState) =>
    preferenceState?.status === STATUSES.SUCCESS
      ? preferenceState.data
      : undefined
);

export const selHuellPersonalizationState = createSelector(
  selHuellState,
  (huellState) => huellState.personalization
);
