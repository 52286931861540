import React from 'react';
import { Skeleton } from 'src/lib/components';
import { isLoading } from 'src/lib/utils/general';
import { useDocumentRole } from '../../../hooks';
import { DocumentRoles, FilterDocument } from '../../../types';
import { CompFields } from '../../../types/CompFields';
import { CompFilterStories } from '../../CompFilters/CompFilterStories';
import { CompFilterButton } from './CompFilterButton';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
}
const COMP_FIELD = CompFields.stories;
export const CompFilterButtonStories = ({
  dataHcName,
  reportId,
  className
}: Props) => {
  const {
    status: filterDocumentStatus,
    data: [filterDocument]
  } = useDocumentRole<FilterDocument>(reportId, DocumentRoles.Filter);
  if (isLoading(filterDocumentStatus) || !filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }
  const filterValue = filterDocument.data.filters[COMP_FIELD];
  const active = !!filterValue;
  return (
    <CompFilterButton
      reportId={reportId}
      label={
        active
          ? filterValue?.absoluteValue[0] === 1
            ? 'Single Story'
            : 'Multi Story'
          : 'Stories'
      }
      dataHcName={dataHcName}
      className={className}
      compField={COMP_FIELD}
      content={
        <CompFilterStories
          reportId={reportId}
          dataHcName={`${dataHcName}-content`}
        />
      }
      active={active}
    />
  );
};
