import { useEffect } from 'react';
import { DomInput } from 'src/types';

// For observing size changes on an HTML element
// callback is called when the ref element is resized
export const useResizeObserver = <T extends HTMLElement>(
  { ref, element }: DomInput<T>,
  // Consuming components should use the useCallback hook
  // to prevent creating a new observer on each render
  callback: (v: ResizeObserverEntry) => void
) => {
  const domElement = element || ref?.current;
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        callback(entry);
      });
    });
    if (domElement) {
      observer.observe(domElement);
    }
    return () => {
      observer.disconnect();
    };
  }, [callback, domElement]);
};
