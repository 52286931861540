import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { reset as resetForm, initialize as initForm } from 'redux-form';
import { navigateConfirmHoc } from '@hc/redux-saga-router-plus/hclib/react';

import history from 'legacy/routes/history';

import { COMP_TYPE_DEFAULT } from 'legacy/appstore/constants';

import { compsAdjustSave } from 'actions/comps';
import { rentalCompsAdjustSave } from 'actions/rental-comps';

import { getCompByAddressId } from 'selectors/comps';
import { getRentalCompByAddressId } from 'selectors/rental-comps';

import { getChangedFormValues } from 'selectors/redux-form';

import PropertyCompareComps from 'legacy/components/PropertyCompareComps';

const NavConfirmPropertyCompareComps = navigateConfirmHoc(
  PropertyCompareComps,
  history,
  'dirty',
  'Are you sure you want to leave with unsaved changes?'
);

const compActionsDefault = {
  saveAdjustments: compsAdjustSave
};

const compActionsRental = {
  saveAdjustments: rentalCompsAdjustSave
};

const compSelectorsDefault = {
  getProperty: getCompByAddressId
};

const compSelectorsRental = {
  getProperty: getRentalCompByAddressId
};

const mapStateToProps = (state, ownProps) => {
  const { addressIds, compType } = ownProps;
  const compSelectors =
    compType === COMP_TYPE_DEFAULT ? compSelectorsDefault : compSelectorsRental;
  let changedValues = {};
  let properties = {};
  addressIds.forEach((addressId) => {
    const changedValuesAddress = getChangedFormValues(
      `compareForm-${addressId}`
    )(state);
    if (!isEmpty(changedValuesAddress)) {
      changedValues[addressId] = changedValuesAddress;
    }
    const property = compSelectors.getProperty(addressId)(state);
    if (!isEmpty(property)) {
      properties[addressId] = property;
    }
  });
  return {
    dirty: Object.keys(changedValues).length,
    changedValues,
    properties
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { compType, addressIds } = ownProps;
  const compActions =
    compType === COMP_TYPE_DEFAULT ? compActionsDefault : compActionsRental;
  const _initForms = (adjustments, properties) => {
    Object.keys(properties).forEach((addressId) => {
      const data = {
        ...properties[addressId],
        ...get(adjustments, addressId, {})
      };
      dispatch(initForm(`compareForm-${addressId}`, data));
    });
  };
  const _resetForms = (ids) => {
    ids.forEach((addressId) => {
      dispatch(resetForm(`compareForm-${addressId}`));
    });
  };
  return {
    onSubmit: (adjustments, properties) => {
      dispatch(compActions.saveAdjustments(adjustments));
      _initForms(adjustments, properties);
    },
    onCancel: () => {
      _resetForms(addressIds);
    }
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { changedValues, properties } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onSubmit: () => dispatchProps.onSubmit(changedValues, properties)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NavConfirmPropertyCompareComps);
