import { connect } from 'react-redux';

import {
  PREFERENCES_KEYS,
  PROPERTY_TYPE_SUBJECT
} from 'legacy/appstore/constants';

import { photosDialogHide } from 'actions/photos';
import { preferencesSave } from 'actions/preferences';

import { getIsLoggedIn } from 'selectors/auth';
import {
  getPhotosDialogState,
  getPhotosDialogPropertyDetails,
  getPhotosDialogPrice,
  getPhotosDialogPhotosByListing
} from 'selectors/photos-dialog';
import { getPreferencesPhotoGrid } from 'selectors/preferences';

import PhotosPage from 'legacy/pages/PhotosPage';

const mapStateToProps = (state) => {
  const { addressId, propertyType, active, latestListingOnly, initialIndex } =
    getPhotosDialogState(state);
  const property = getPhotosDialogPropertyDetails(state);
  const size = getPreferencesPhotoGrid(state).size || 4;
  const photosByListingAndFullscreen = getPhotosDialogPhotosByListing(state);
  return {
    price: getPhotosDialogPrice(state),
    isLoggedIn: getIsLoggedIn(state),
    addressId: propertyType === PROPERTY_TYPE_SUBJECT ? 'subject' : addressId,
    propertyContext: propertyType,
    ...photosByListingAndFullscreen,
    active,
    initialIndex,
    property,
    size,
    latestListingOnly
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClickBack: () => dispatch(photosDialogHide()),
    onChangeComplete: (size) =>
      dispatch(
        preferencesSave({
          [PREFERENCES_KEYS.PHOTO_GRID]: { size }
        })
      )
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isLoggedIn } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onChangeComplete: (size) => {
      if (isLoggedIn) {
        dispatchProps.onChangeComplete(size);
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PhotosPage);
