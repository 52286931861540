import Loading from './Loading';

export const PageLoading = (props) => {
  if (props.pastDelay) {
    return <Loading loading />;
  }
  if (props.error) {
    console.log(props.error);
    return <div>The page failed to load. Please refresh your browser.</div>;
  }
  return null;
};

export default PageLoading;
