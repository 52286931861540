import PropTypes from 'prop-types';
// NOTE: When using a redux-form FieldArray, reduxForm() must be called before connect() in the container
import { Field } from 'redux-form';
import { FormError } from '@hc/component-lib/hclib/components/atoms/form-error';

import { validateEmailMulti } from 'legacy/utils/form-validators';

import { MultiEmailTextField as theme } from 'legacy/css-modules/generic';
const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  className
}) => {
  return (
    <div>
      <input className={className} {...input} placeholder={label} type={type} />
      {touched &&
        ((error && <FormError value={error} />) ||
          (warning && <FormError value={warning} />))}
    </div>
  );
};

const MultiEmailTextField = ({ name, label }) => {
  return (
    <div>
      <Field
        name={name}
        className={theme['hc-field-mulit-email-text']}
        label={label}
        validate={validateEmailMulti}
        maxLength={300}
        component={renderField}
        type="text"
      />
    </div>
  );
};

MultiEmailTextField.propTypes = {
  name: PropTypes.string.isRequired,

  label: PropTypes.string
};

export default MultiEmailTextField;
