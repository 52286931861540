import pick from 'lodash/pick';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import {
  FORM_NAME as SHARE_REPORT_FORM_NAME,
  FIELD_NAMES as SHARE_REPORT_FORM_FIELDS
} from 'legacy/forms/share-report';

import { getReportStatusSharedReportConfigDoesNotExist } from 'selectors/report-status';

export const getShareReportState = (state) => state.shareReport;

export const getShareReportOpen = createSelector(
  getShareReportState,
  (shareReportState) => shareReportState.open
);

export const getUniqueLinkId = createSelector(
  getShareReportState,
  (shareReportState) => shareReportState.id
);

export const getAllShareReportFormValues = (state) =>
  _shareReportFormValueSelector(state, ...SHARE_REPORT_FORM_FIELDS);

export const getShareByEmailApiValues = createSelector(
  getAllShareReportFormValues,
  (formValues) => {
    let apiValues = pick(formValues, ['toEmails', 'comment']);
    apiValues.toEmails = apiValues.toEmails
      ? apiValues.toEmails.split(',').map((email) => email.trim())
      : [];
    return apiValues;
  }
);

export const getUniqueLinkApiValues = createSelector(
  getAllShareReportFormValues,
  (formValues) => uniqueLinkFormToApi(formValues.linkConfig)
);

export const getUniqueLinkFormValues = createSelector(
  getAllShareReportFormValues,
  (formValues) => pick(formValues, ['linkConfig'])
);

export const getShareByEmail = (state) => {
  const formValues = _shareReportFormValueSelector(
    state,
    'toEmails',
    'comment'
  );
  return (
    (formValues.toEmails && formValues.toEmails.length) || formValues.comment
  );
};

export const getShareableUrl = createSelector(
  getShareReportState,
  (shareReportState) => shareReportState.url
);

export const getLinkConfigValue = createSelector(
  getShareReportState,
  (shareReportState) => transformUniqueLinkDataApiToForm(shareReportState)
);

export const getShareReportAllowSave = createSelector(
  getShareReportState,
  (shareReportState) => shareReportState.allowSave === true
);

export const getShareReportAllowEdit = createSelector(
  getShareReportState,
  (shareReportState) => shareReportState.allowEdit === true
);

export const checkUniqueLinkConfigChanged = (state) => {
  const oldValue = transformUniqueLinkDataApiToForm(getShareReportState(state));
  const formValues = getUniqueLinkFormValues(state);
  return !(oldValue === formValues.linkConfig);
};

export const checkShareReportByEmail = (state) => {
  const formValues = getShareByEmailApiValues(state);
  return !!(formValues.toEmails && formValues.toEmails.length);
};

const _shareReportFormValueSelector = formValueSelector(SHARE_REPORT_FORM_NAME);

export const uniqueLinkFormToApi = (linkConfig) => {
  switch (linkConfig) {
    case 'save':
      return {
        allowEdit: true,
        allowSave: true
      };
    case 'edit':
      return {
        allowEdit: true,
        allowSave: false
      };

    default:
      return {
        allowEdit: false,
        allowSave: false
      };
  }
};

export const transformUniqueLinkDataApiToForm = (linkData) => {
  if (linkData.allowSave) {
    return 'save';
  }
  if (linkData.allowEdit) {
    return 'edit';
  }
  return 'view';
};

export const getSharedReportUid = createSelector(
  getShareReportState,
  (uniqueLinkData) => uniqueLinkData.uid
);

export const getUniqueLinkUrlParams = createSelector(
  getShareReportState,
  (uniqueLinkData) => uniqueLinkData.urlParams
);

export const getCanSendEmailBeforeLinkGenerated = createSelector(
  getShareByEmail,
  getReportStatusSharedReportConfigDoesNotExist,
  (shareByEmail, uniqueLinkDoesNotExist) =>
    !uniqueLinkDoesNotExist || shareByEmail
);
