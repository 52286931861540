import { createSelector } from 'reselect';

export const getRentalReportState = (state) => state.rentalReport;

export const getRentalReport = createSelector(
  getRentalReportState,
  (rentalReportState) => rentalReportState.values
);

export const getRentalReportStatus = createSelector(
  getRentalReportState,
  (rentalReportState) => rentalReportState.status
);

export const getRentalReportMessage = createSelector(
  getRentalReportState,
  (rentalReportState) => rentalReportState.message
);
