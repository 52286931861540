import { CompFields } from '../report-api/types/CompFields';

export enum PreferencesKeys {
  CompFilters = 'filters_comps',
  CompTableColumns = 'table_cols_comps',
  CompTableWidth = 'table_width_comps',
  RentalCompFilters = 'filters_rental_comps',
  RentalCompTableColumns = 'table_cols_rental_comps',
  PhotoGrid = 'photo_grid',
  Maps = 'maps',
  AckRefreshComps = 'skip_warning_refresh_comps',
  AckRefreshRentalComps = 'skip_warning_refresh_rental_comps'
}

export interface Personalization {
  ack_prompt: boolean;
  address_line1: string | null;
  address_line2: string | null;
  city: string | null;
  company_logo_url: string | null;
  company_name: string | null;
  created_at: null;
  email: string | null;
  first_name: string | null;
  id: number;
  include_address_line1: boolean;
  include_address_line2: boolean;
  include_ask_question: boolean;
  include_city_state_zipcode: boolean;
  include_company_logo: boolean;
  include_company_name: boolean;
  include_email: boolean;
  include_license_number: boolean;
  include_name: boolean;
  include_phone: boolean;
  include_photo: boolean;
  include_website: boolean;
  last_name: string | null;
  license_number: string | null;
  organization_id: number;
  phone: string | null;
  photo_url: string | null;
  state: string | null;
  updated_at: string | null;
  user_email: string | null;
  user_id: number;
  website_url: string | null;
  zipcode: string | null;
}

export interface SavedFilterSet {
  values: {
    // TODO: Type the values once we have the comp filters typed
  };
  label: string;
  created_by: number;
}

export interface SavedFilters {
  active_filter_set: string | null;
  filter_sets: {
    [key: string]: SavedFilterSet;
  };
}

export interface MapPreferences {
  disable_clusters: boolean;
}

export interface PhotoGridPreferences {
  size: Size;
}

type InactiveColumns = {
  [key in CompFields]?: boolean;
};
interface InactiveColumnsSnakeCase {
  address?: boolean;
  similarity?: boolean;
  distance_miles?: boolean;
  bedrooms?: boolean;
  bathrooms?: boolean;
  gross_living_area_sqft?: boolean;
  site_area_sqft?: boolean;
  property_type?: boolean;
  year_built?: boolean;
  property_status?: boolean;
  // propertyStatusRental?: boolean,
  subdivision_name?: boolean;
  sales_date?: boolean;
  sales_price?: boolean;
  price_per_sqft?: boolean;
  // leasedDate?: boolean,
  // leasedPrice?: boolean,
  last_list_date?: boolean;
  last_list_price?: boolean;
  // lastListDateRental?: boolean,
  // lastListPriceRental?: boolean,
  price_per_sqft_non_disclosure?: boolean;
  active_days_on_market?: boolean;
  cumulative_days_on_market?: boolean;
  current_value?: boolean;
  // rentalAvm?: boolean,
  // pricePerSqftRentalLeased?: boolean,
  // pricePerSqftRentalListed?: boolean,
  pool?: boolean;
  garage_num_cars?: boolean;
  stories?: boolean;
  basement?: boolean;
  is_distressed?: boolean;
  flips?: boolean;
}
export interface TablePreferences<
  I extends InactiveColumns | InactiveColumnsSnakeCase
> {
  inactive: I;
  order: CompFields[];
}

// Temporary Type until keys are fixed in v2 endpoint
export interface PreferencesGeneric<I> {
  [PreferencesKeys.CompFilters]?: SavedFilters;
  [PreferencesKeys.CompTableColumns]?: TablePreferences<I>;
  [PreferencesKeys.CompTableWidth]?: number;
  [PreferencesKeys.RentalCompFilters]?: SavedFilters;
  [PreferencesKeys.RentalCompTableColumns]?: TablePreferences;
  [PreferencesKeys.PhotoGrid]?: PhotoGridPreferences;
  [PreferencesKeys.Maps]?: MapPreferences;
  [PreferencesKeys.AckRefreshComps]?: boolean;
  [PreferencesKeys.AckRefreshRentalComps]?: boolean;
}

export type PreferencesFromApi = PreferencesGeneric<InactiveColumnsSnakeCase>;
export type Preferences = PreferencesGeneric<InactiveColumns>;
