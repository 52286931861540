import { connect } from 'react-redux';

import { getReportStatusSharedReportConfigDoesNotExist } from 'selectors/report-status';
import {
  getShareReportOpen,
  getAllShareReportFormValues,
  getShareByEmail
} from 'selectors/share-report';
import { hasNeverEnteredPersonalizationData } from 'selectors/personalization';
import { getIsLessThanSmallMedium } from 'selectors/match-media';

import { shareReportClose } from 'actions/share-report';

import ShareReportModal from 'legacy/components/ShareReportModal';
import modalWithIOSInputFix from 'legacy/hocs/modalWithIOSInputFix';

const mapStateToProps = (state) => {
  return {
    active: getShareReportOpen(state),
    shareByEmail: getShareByEmail(state),
    uniqueLinkDoesNotExist:
      getReportStatusSharedReportConfigDoesNotExist(state),
    showPersonalizationPrompt: hasNeverEnteredPersonalizationData(state),
    fullscreen: getIsLessThanSmallMedium(state),
    // NOTE: Form values are included here so the modal position adjusts as the content scales
    formValues: getAllShareReportFormValues(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(shareReportClose());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(modalWithIOSInputFix(ShareReportModal));
