import store from 'legacy/appstore';
import { screenResize } from 'actions/match-media';

function matchMediaService() {
  let unsubscribeXSmall;
  let unsubscribeSmallXsm;
  let unsubscribeSmall;
  let unsubscribeSmallMed;
  let unsubscribeMedium;
  let unsubscribeLarge;
  let unsubscribeXLarge;

  function subscribe() {
    unsubscribeXSmall = addMq(
      '(min-width: 1px) and (max-width: 400px)',
      'xsmall'
    );
    unsubscribeSmallXsm = addMq(
      '(min-width: 400px) and (max-width: 550px)',
      'smallxsm'
    );
    unsubscribeSmall = addMq(
      '(min-width: 550px) and (max-width: 769px)',
      'small'
    );
    unsubscribeSmallMed = addMq(
      '(min-width: 769px) and (max-width: 850px)',
      'smallmed'
    );
    unsubscribeMedium = addMq(
      '(min-width: 850px) and (max-width: 1024px)',
      'medium'
    );
    unsubscribeLarge = addMq(
      '(min-width: 1024px) and (max-width: 1920px)',
      'large'
    );
    unsubscribeXLarge = addMq('all and (min-width: 1920px)', 'xlarge');
  }

  function unsubscribe() {
    if (unsubscribeXLarge) unsubscribeXLarge();
    if (unsubscribeLarge) unsubscribeLarge();
    if (unsubscribeMedium) unsubscribeMedium();
    if (unsubscribeSmallMed) unsubscribeSmallMed();
    if (unsubscribeSmall) unsubscribeSmall();
    if (unsubscribeSmallXsm) unsubscribeSmallXsm();
    if (unsubscribeXSmall) unsubscribeXSmall();
  }

  function addMq(mq, size) {
    const query = window.matchMedia(mq);
    const handler = handleScreenSizes.bind(null, size);
    query.addListener(handler);
    handler(query);
    return () => {
      query.removeListener(handler);
    };
  }

  function handleScreenSizes(size, mql) {
    if (mql.matches) {
      const props = {
        mediaQuery: mql.media,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        screenSize: size,
        deviceIOS: navigator.userAgent.match(/(iPad|iPhone|iPod)/i),
        deviceAndroid: navigator.userAgent.match(/android/i),
        isInternetExplorer:
          navigator.appName === 'Microsoft Internet Explorer' ||
          !!(
            navigator.userAgent.match(/Trident/) ||
            navigator.userAgent.match(/rv:11/)
          )
      };
      store.dispatch(screenResize(props));
    }
  }

  return {
    subscribe,
    unsubscribe
  };
}

export default matchMediaService();
