import { TextButton } from 'legacy/components/Button';

import Drawer from 'legacy/components/generic/Drawer';

import { Glossary as theme } from 'legacy/css-modules';

const Glossary = ({ open, width, glossaryValues, onClose }) => {
  return (
    <Drawer
      className="right-side-gallery"
      active={open}
      size="medium"
      onOverlayClick={onClose}
      type="right"
    >
      <section className="glossary-section">
        <div className={theme.PanelHeader}>
          <TextButton
            dataHcName="info-panel-close-button"
            label="Close"
            onClick={onClose}
            primary={false}
          />
        </div>
        {glossaryValues.map((item, i) => (
          <div key={`glossary-${i}`} className={theme.GlossaryTerm}>
            {item.title && (
              <div data-hc-name="info-panel-heading" className={theme.Title}>
                {item.title}
              </div>
            )}
            <div
              data-hc-name="info-panel-content"
              className={theme.Description}
            >
              {item.content.join(' ')}
            </div>
            {item.source && (
              <div className={theme.Source}>Source: {item.source}</div>
            )}
          </div>
        ))}
      </section>
    </Drawer>
  );
};

export default Glossary;
