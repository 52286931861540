import React, { ReactNode, useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { CompMarker } from 'src/lib/components/MapMarkers/CompMarker';
import { formatNumberAbbrev } from 'src/lib/utils';
import { getMarketPrice } from 'src/lib/utils/listingStatus';
import { useDocumentRole } from '../../hooks';
import { CompDocument, DocumentRoles } from '../../types';
import { CompSchema } from '../../types/schemas';
import styles from './CompMarkers.css';

interface Props {
  reportId: number;
  dataHcName: string;
  // Render index + 1 instead of price
  indexLabels?: boolean;
  onClickMarker?: (compSchema: CompSchema) => void;
}

export const CompMarkersSelected = ({
  reportId,
  dataHcName,
  indexLabels,
  onClickMarker
}: Props) => {
  const { data: compDocuments } = useDocumentRole<CompDocument>(
    reportId,
    DocumentRoles.Comp
  );
  const markers = useMemo(() => {
    const results: ReactNode[] = [];
    compDocuments.forEach((compDocument, i) => {
      const compSchema = compDocument.data;
      const { latitude, longitude } = compSchema.geoLocation;
      if (latitude && longitude) {
        results.push(
          <Marker
            key={`comp-marker-${i}`}
            longitude={longitude}
            latitude={latitude}
            className={styles.Marker}
          >
            <CompMarker
              dataHcName={`${dataHcName}-${i}`}
              listingStatusData={compSchema.latestListing.listingStatus}
              onClick={() => {
                onClickMarker?.(compSchema);
              }}
              selected
              hoverable
            >
              {indexLabels
                ? i + 1
                : formatNumberAbbrev(
                    getMarketPrice(compSchema.latestListing.listingStatus)
                  )}
            </CompMarker>
          </Marker>
        );
      }
    });
    return results;
  }, [compDocuments]);
  return <>{markers}</>;
};
