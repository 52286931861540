import { createSelector } from 'reselect';
import { getToken } from 'selectors/account-details';

export function getAuth(state) {
  return state.auth || {};
}

export const getAuthErrorMessage = createSelector(
  getAuth,
  (auth) => auth.errorMessage
);

export const getAuthStatus = createSelector(getAuth, (auth) => auth.status);

export const getUnAuthorizedErrorMessage = createSelector(getAuth, (auth) => {
  return auth.errorMessage;
});

export const getLoginPromptState = createSelector(
  getAuth,
  (authState) => authState.loginPrompt
);

/* Get action to dispatch immediately after successful authentication */
export const getLoginPromptAfterAuthAction = createSelector(
  getLoginPromptState,
  (loginPromptState) => loginPromptState.afterAuthAction
);

export const getLoginPromptTitle = createSelector(
  getLoginPromptState,
  (loginPrompt) => {
    switch (loginPrompt.actionType) {
      case 'search':
        return 'Please sign in or register to search for a new property';

      case 'pullReport':
        return 'Please sign in or register to pull the latest report';

      default:
        return 'Please sign in or register to continue';
    }
  }
);

export const getIsLoggedIn = createSelector(getToken, (token) => !!token);

export const getAuthLoginRedirect = createSelector(
  getAuth,
  (authState) => authState.loginRedirect
);
