import queryString from 'query-string';
import { call, put, select } from 'redux-saga/effects';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { APIClientError } from 'legacy/utils/error';
import { toSnakeCase, toCamelCase } from 'legacy/utils/transform';
import { getToken, getAccountDetailsSentry } from 'selectors/account-details';
import { refreshAccessToken } from 'src/legacy/utils/refresh-token-callback';
import { handleUnauthorizedUser, tokenExpired } from 'actions/auth';

const HEADERS = {
  Accept: 'application/json, text/plain, */*'
};

/**
 * Make a request to an external URL and handle the response
 * @param {String} method - get|put|post|delete
 * @param {String} endpoint - URL to request
 * @param {Object} params - parameters to send with request (sent in body when POST, appended to URL when GET)
 * @param {Object} options - customization options and pass-through request init options
 *    @param {Boolean} expect404 - if true, forego throwing an error when a 404 is returned
 *    @param {Boolean} sendAuth - if true, add HC auth header to request
 *    @param {Boolean} urlParams - if included, these parameters are appended to the request url
 *    @param {Boolean} skipTransform - do not transform keys before sending params
 *    @param {Boolean} sendFile - if true, `params` object is sent to server as formData object
 *    @param {Boolean} receiveFile - if true, response is parsed and returned as a Blob object
 *    @param {Boolean} fullResponse - if true, full response object is returned instead of just response JSON
 *    @param {*} additional options are included with the request init config
 * @returns {Object} request response data or error object
 */
const makeRequest = function* (
  method,
  endpoint,
  params = {},
  options = {},
  newToken,
  retries = 0
) {
  const {
    expect404,
    sendAuth,
    urlParams,
    sendFile,
    receiveFile,
    fullResponse,
    skipTransform,
    ...requestOptions
  } = options;
  let formData;
  let responseData = {};
  let token;
  if (newToken) {
    token = newToken;
  } else {
    token = yield select(getToken);
  }
  const mergedUrlParams = !skipTransform
    ? toSnakeCase({
        ...(method === 'GET' || method === 'DELETE' ? params : {}),
        ...(urlParams || {})
      })
    : {
        ...(method === 'GET' || method === 'DELETE' ? params : {}),
        ...(urlParams || {})
      };
  if (sendFile) {
    formData = new window.FormData();
    formData.append('file', params);
  }
  const body =
    method === 'PUT' || method === 'POST' || method === 'PATCH'
      ? formData
        ? formData.get('file')
        : JSON.stringify(params)
      : undefined;
  const defaultOptions = {
    headers: {
      ...HEADERS,
      ...(sendAuth !== false ? { 'HC-Auth-Token': token } : {}),
      ...(method === 'POST' || method === 'PATCH'
        ? { 'content-type': 'application/json' }
        : {}),
      ...(requestOptions.headers || {})
    },
    method,
    body
  };

  const url = !isEmpty(mergedUrlParams)
    ? `${endpoint}?${queryString.stringify(mergedUrlParams)}`
    : endpoint;

  // Extract headers so they do not override headers in defaultOptions
  const { headers, ...restOfRequestOptions } = requestOptions;
  const fetchOptions = {
    ...defaultOptions,
    ...restOfRequestOptions
  };
  const response = yield call(window.fetch, url, fetchOptions);

  // We can only read from the response once, so only read from it if we know we
  // won't want to do it later
  if (!fullResponse) {
    if (receiveFile) {
      responseData = yield call([response, response.blob]);
    } else {
      const responseText = yield call([response, response.text]);
      if (responseText) {
        try {
          responseData = JSON.parse(responseText);
        } catch (e) {
          responseData = responseText;
        }
      }
    }
  }
  if (response.ok) {
    const final = fullResponse
      ? toCamelCase(response)
      : toCamelCase(responseData);
    return final;
  } else {
    let responseMessage =
      typeof responseData === 'string'
        ? responseData
        : get(responseData, 'message', 'Unknown error');

    if (typeof responseMessage !== 'string') {
      responseMessage = JSON.stringify(responseMessage);
    }
    if (response.status === 401) {
      try {
        if (retries === 0) {
          const userContext = yield call(refreshAccessToken);
          const retryResult = yield call(
            makeRequest,
            method,
            endpoint,
            params,
            options,
            userContext.validity.token,
            retries + 1
          );
          return retryResult;
        } else if (requestOptions.redirectToLoginOn401 !== false) {
          yield put(tokenExpired());
        }
      } catch (e) {
        console.error(e);
        if (
          e.response.status !== 404 &&
          requestOptions.redirectToLoginOn401 !== false
        ) {
          yield put(tokenExpired());
        }
      }
    } else if (response.status === 403) {
      const errorMessage =
        response.data && response.data.message
          ? response.data.message
          : 'Unauthorized';
      yield put(handleUnauthorizedUser(errorMessage));
    } else if (response.status === 404 && expect404) {
      // Provide calling saga with something to key on for an expected 404 response
      return { statusCode: 404 };
    }
    const accountDetails = yield select(getAccountDetailsSentry);
    throw new APIClientError(
      `${response.status} ${response.statusText}: ${responseMessage}`,
      {
        statusCode: response.status,
        statusText: response.statusText,
        requestUrl: url,
        requestBody: body,
        responseJSON: !receiveFile && responseData,
        messageRaw: responseMessage,
        accountDetails
      }
    );
  }
};

/**
 * Create generator func for each HTTP method
 * To be used in sagas like:
 *   yield call(apiUtil.GET, 'http://searchstuff.com', { q: 'myquery' })
 *   yield call(apiUtil.POST, 'http://createathing.com', { first: 'foo', second: 'bar' })
 *   yield call(apiUtil.POST, 'http://fileupload.com', file, { sendFile: true })
 */
export default ['GET', 'PUT', 'PATCH', 'POST', 'DELETE'].reduce(
  (util, method) => {
    util[method] = function* (endpoint, params, options) {
      return yield call(
        makeRequest,
        method,
        endpoint,
        options?.skipTransform ? params : toSnakeCase(params),
        options
      );
    };
    return util;
  },
  {}
);
