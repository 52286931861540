import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './SectionHeader.css';
interface Props {
  dataHcName: string;
  children: ReactNode;
  className?: string;
}
export const SectionHeader = ({ children, dataHcName, className }: Props) => {
  return (
    <h3
      className={classNames(styles.SectionHeader, className)}
      data-hc-name={dataHcName}
    >
      {children}
    </h3>
  );
};
