import { call } from 'redux-saga/effects';

import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

import graphqlRequest from 'legacy/services/graphql';

export const mlsHistoryQuery = ({ addressId }) => {
  return {
    key: QUERIES.MLS_HISTORY,
    query: `
      mlsHistory(addressId: ${addressId}) {
        listings {
          currentStatus
          currentSaleStatus
          listingPrice
          currentPrice
          salePrice
          listingDate
          saleDate
          contractDate
          offMarketDate
          currentStatusDate
          mlsNumber
          mlsName
          mlsShortName
          hcMlsId
          isArmLength
          isListingRental
          daysOnMarket
        }
      }
    `
  };
};

const fetchMlsHistory = function* (addressId) {
  const query = mlsHistoryQuery(addressId);
  return yield call(graphqlRequest, {
    source: PROPERTY_DATA_SOURCES.MLS,
    query
  });
};

export default fetchMlsHistory;
