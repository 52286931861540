import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { PREFERENCES_KEYS } from 'legacy/appstore/constants';

const FILTER_KEYS_OLD_TO_NEW = {
  age: 'calculatedFields.age',
  pricePerSqftRentalListed:
    'calculatedFields.livingAreaFieldsRental.currentListingPricePerSqFt',
  rentalMarketPricePerSqFt:
    'calculatedFields.livingAreaFieldsRental.currentPricePerSqFtRental',
  currentValuePerSqftRental:
    'calculatedFields.livingAreaFieldsRental.currentValuePerSqFt',
  pricePerSqftRentalLeased:
    'calculatedFields.livingAreaFieldsRental.lastClosePricePerSqFt',
  listPricePerSqFt:
    'calculatedFields.livingAreaFieldsSale.currentListingPricePerSqFt',
  marketPricePerSqFt:
    'calculatedFields.livingAreaFieldsSale.currentPricePerSqFt',
  currentValuePerSqFt:
    'calculatedFields.livingAreaFieldsSale.currentValuePerSqFt',
  closePricePerSqFt:
    'calculatedFields.livingAreaFieldsSale.lastClosePricePerSqFt',
  listingDateRental: 'complexFieldsRental.currentListDate',
  listPriceRental: 'complexFieldsRental.currentListingPrice',
  rentalMarketPrice: 'complexFieldsRental.currentPrice',
  propertyStatusRental: 'complexFieldsRental.currentStatus',
  rentalMarketPriceDate: 'complexFieldsRental.currentStatusDate',
  closeDateRental: 'complexFieldsRental.lastCloseDate',
  leaseDate: 'complexFieldsRental.lastCloseDate',
  closePriceRental: 'complexFieldsRental.lastClosePrice',
  leasePrice: 'complexFieldsRental.lastClosePrice',
  activeDaysOnMarket: 'complexFieldsSale.currentDaysOnMarketCumulative',
  daysOnMarketActive: 'complexFieldsSale.currentDaysOnMarketCumulative',
  daysOnMarketCumulative: 'complexFieldsSale.currentDaysToCloseCumulative',
  cumulativeDaysOnMarket: 'complexFieldsSale.currentDaysToCloseCumulative',
  isFlip: 'complexFieldsSale.currentFlipYN',
  lastListDate: 'complexFieldsSale.currentListDate',
  lastListPrice: 'complexFieldsSale.currentListingPrice',
  marketPrice: 'complexFieldsSale.currentPrice',
  propertyStatus: 'complexFieldsSale.currentStatus',
  marketPriceDate: 'complexFieldsSale.currentStatusDate',
  lastListDateRental: 'complexFieldsRental.currentListDate',
  isDistressed: 'complexFieldsSale.distressedYN',
  closeDate: 'complexFieldsSale.lastCloseDate',
  closePrice: 'complexFieldsSale.lastClosePrice',
  salesDate: 'complexFieldsSale.lastCloseDate',
  salesPrice: 'complexFieldsSale.lastClosePrice',
  subdivisionName: 'location.subdivision',
  hoaFee: 'propertyDetails.association.fee',
  hoaFeeFrequency: 'propertyDetails.association.frequency',
  hoaName: 'propertyDetails.association.name',
  hoaFeeIncludes: 'propertyDetails.associationFeeIncludes',
  basement: 'propertyDetails.basement.has',
  bathrooms: 'propertyDetails.bathrooms.totalProjected',
  bedrooms: 'propertyDetails.bedrooms',
  livingArea: 'propertyDetails.livingArea',
  lotSize: 'propertyDetails.lotSize',
  garageNumCars: 'propertyDetails.parking.garage',
  garageType: 'propertyDetails.parking.parkingDetails.type',
  pool: 'propertyDetails.poolYN',
  propertyType: 'propertyDetails.propertyType',
  stories: 'propertyDetails.storiesNumber',
  taxAmount: 'propertyDetails.tax.amountAnnual',
  taxYear: 'propertyDetails.tax.year',
  yearBuilt: 'propertyDetails.yearBuilt',
  zoning: 'propertyDetails.zoning.code',
  condition: 'propertyValue.condition',
  currentValue: 'propertyValue.valueMean',
  rentalAvm: 'propertyValueRental.valueMean',
  ownerOccupied: 'taxDetails.ownership.ownerOccupied',
  leasedDate: 'complexFieldsRental.lastCloseDate',
  leasedPrice: 'complexFieldsRental.lastClosePrice',
  lastListPriceRental: 'complexFieldsRental.currentListingPrice'
};

const FILTER_KEYS_NEW_TO_OLD = {
  'calculatedFields.age': 'age',
  'calculatedFields.livingAreaFieldsRental.currentListingPricePerSqFt':
    'pricePerSqftRentalListed',
  'calculatedFields.livingAreaFieldsRental.currentPricePerSqFtRental':
    'rentalMarketPricePerSqFt',
  'calculatedFields.livingAreaFieldsRental.currentValuePerSqFt':
    'currentValuePerSqftRental',
  'calculatedFields.livingAreaFieldsRental.lastClosePricePerSqFt':
    'pricePerSqftRentalLeased',
  'calculatedFields.livingAreaFieldsSale.currentListingPricePerSqFt':
    'listPricePerSqFt',
  'calculatedFields.livingAreaFieldsSale.currentPricePerSqFt':
    'marketPricePerSqFt',
  'calculatedFields.livingAreaFieldsSale.currentValuePerSqFt':
    'currentValuePerSqFt',
  'calculatedFields.livingAreaFieldsSale.lastClosePricePerSqFt':
    'closePricePerSqFt',
  'complexFieldsRental.currentListDate': 'lastListDateRental',
  'complexFieldsRental.currentListingPrice': 'lastListPriceRental',
  'complexFieldsRental.currentPrice': 'rentalMarketPrice',
  'complexFieldsRental.currentStatus': 'propertyStatusRental',
  'complexFieldsRental.currentStatusDate': 'rentalMarketPriceDate',
  'complexFieldsRental.lastCloseDate': 'leasedDate',
  'complexFieldsRental.lastClosePrice': 'leasedPrice',
  'complexFieldsSale.currentDaysOnMarketCumulative': 'daysOnMarketActive',
  'complexFieldsSale.currentDaysToCloseCumulative': 'daysOnMarketCumulative',
  'complexFieldsSale.currentFlipYN': 'isFlip',
  'complexFieldsSale.currentListDate': 'lastListDate',
  'complexFieldsSale.currentListingPrice': 'lastListPrice',
  'complexFieldsSale.currentPrice': 'marketPrice',
  'complexFieldsSale.currentStatus': 'propertyStatus',
  'complexFieldsSale.currentStatusDate': 'marketPriceDate',
  'complexFieldsSale.distressedYN': 'isDistressed',
  'complexFieldsSale.lastCloseDate': 'salesDate',
  'complexFieldsSale.lastClosePrice': 'salesPrice',
  'location.subdivision': 'subdivisionName',
  'propertyDetails.association.fee': 'hoaFee',
  'propertyDetails.association.frequency': 'hoaFeeFrequency',
  'propertyDetails.association.name': 'hoaName',
  'propertyDetails.associationFeeIncludes': 'hoaFeeIncludes',
  'propertyDetails.basement.has': 'basement',
  'propertyDetails.bathrooms.totalProjected': 'bathrooms',
  'propertyDetails.bedrooms': 'bedrooms',
  'propertyDetails.livingArea': 'livingArea',
  'propertyDetails.lotSize': 'lotSize',
  'propertyDetails.parking.garage': 'garageNumCars',
  'propertyDetails.parking.parkingDetails.type': 'garageType',
  'propertyDetails.poolYN': 'pool',
  'propertyDetails.propertyType': 'propertyType',
  'propertyDetails.storiesNumber': 'stories',
  'propertyDetails.tax.amountAnnual': 'taxAmount',
  'propertyDetails.tax.year': 'taxYear',
  'propertyDetails.yearBuilt': 'yearBuilt',
  'propertyDetails.zoning.code': 'zoning',
  'propertyValue.condition': 'condition',
  'propertyValue.valueMean': 'currentValue',
  'propertyValueRental.valueMean': 'rentalAvm',
  'taxDetails.ownership.ownerOccupied': 'ownerOccupied'
};

const PROPERTY_TYPE_NEW_TO_OLD = {
  COMMERCIAL: 'Other',
  CONDO: 'Condominium',
  COOP: 'COOP',
  LAND: 'VACANT_LOT',
  MANUFACTURED: 'Manufactured/Mobile Home',
  MOBILE: 'Manufactured/Mobile Home',
  MULTI_FAMILY: 'Multifamily',
  OTHER: 'Other',
  RENTAL_UNIT: 'Other',
  SFR: 'Single Family Detached',
  TIMESHARE: 'Other',
  TOWNHOUSE: 'Townhouse'
};

const PROPERTY_TYPE_OLD_TO_NEW = {
  Condominium: 'CONDO',
  'Manufactured/Mobile Home': 'MANUFACTURED',
  Multifamily: 'MULTI_FAMILY',
  'Single Family Detached': 'SFR',
  Townhouse: 'TOWNHOUSE',
  Other: 'OTHER'
};

const PROPERTY_STATUS_NEW_TO_OLD = {
  Active: 'Active',
  'Active Under Contract': 'Active',
  Canceled: 'Sold',
  Closed: 'Sold',
  'Coming Soon': 'Active',
  Delete: 'Sold',
  Expired: 'Sold',
  Hold: 'Sold',
  Incomplete: 'Sold',
  Pending: 'Pending',
  Withdrawn: 'Sold'
};

const PROPERTY_STATUS_OLD_TO_NEW = {
  Sold: 'Closed',
  Active: 'Active',
  Pending: 'Pending'
};

const PROPERTY_STATUS_NEW_TO_OLD_RENTAL = {
  ...PROPERTY_STATUS_NEW_TO_OLD,
  Canceled: 'Leased',
  Closed: 'Leased',
  Delete: 'Leased',
  Expired: 'Leased',
  Hold: 'Leased',
  Incomplete: 'Leased',
  Withdrawn: 'Leased'
};

const PROPERTY_STATUS_OLD_TO_NEW_RENTAL = {
  ...PROPERTY_STATUS_OLD_TO_NEW,
  Leased: 'Closed'
};

const ENUM_CONVERSION_NEW_TO_OLD = {
  'propertyDetails.propertyType': (v) =>
    Array.isArray(v)
      ? v.map((pt) => PROPERTY_TYPE_NEW_TO_OLD[pt] || pt)
      : PROPERTY_TYPE_NEW_TO_OLD[v] || v,
  similarity: (v) =>
    Array.isArray(v) ? v.map((v) => v.toLowerCase()) : v.toLowerCase(),
  'complexFieldsRental.currentStatus': (v) =>
    Array.isArray(v)
      ? v.map((ps) => PROPERTY_STATUS_NEW_TO_OLD_RENTAL[ps] || ps)
      : PROPERTY_STATUS_NEW_TO_OLD_RENTAL[v] || v,
  'complexFieldsSale.currentStatus': (v) =>
    Array.isArray(v)
      ? v.map((ps) => PROPERTY_STATUS_NEW_TO_OLD[ps] || ps)
      : PROPERTY_STATUS_NEW_TO_OLD[v] || v
};

const ENUM_CONVERSION_OLD_TO_NEW = {
  propertyType: (v) =>
    Array.isArray(v)
      ? v.map((pt) => PROPERTY_TYPE_OLD_TO_NEW[pt] || pt)
      : PROPERTY_TYPE_OLD_TO_NEW[v] || v,
  similarity: (v) =>
    Array.isArray(v) ? v.map((v) => v.toLowerCase()) : v.toLowerCase(),
  propertyStatus: (v) =>
    Array.isArray(v)
      ? v.map((ps) => PROPERTY_STATUS_OLD_TO_NEW[ps] || ps)
      : PROPERTY_STATUS_OLD_TO_NEW[v] || v,
  propertyStatusRental: (v) =>
    Array.isArray(v)
      ? v.map((ps) => PROPERTY_STATUS_OLD_TO_NEW_RENTAL[ps] || ps)
      : PROPERTY_STATUS_OLD_TO_NEW_RENTAL[v] || v
};

const getFieldLookup = (options) =>
  options?.transformToOld ? FILTER_KEYS_NEW_TO_OLD : FILTER_KEYS_OLD_TO_NEW;

const getFieldValueFn = (key, options) =>
  options?.transformToOld
    ? ENUM_CONVERSION_NEW_TO_OLD[key]
    : ENUM_CONVERSION_OLD_TO_NEW[key];

const transformDataPriority = (dataPriority, options) => {
  const fieldLookup = getFieldLookup(options);
  dataPriority.order = dataPriority.order.map((field) => {
    return fieldLookup[field] || field;
  });
  const newInactive = {};
  Object.keys(dataPriority.inactive).map((field) => {
    newInactive[fieldLookup[field] || field] = dataPriority.inactive[field];
  });
  dataPriority.inactive = newInactive;
  return dataPriority;
};

export const transformUiPreferences = (data, options) => {
  if (data.statusCode === 404) {
    return data;
  } else {
    const transformed = cloneDeep(data);
    delete transformed[PREFERENCES_KEYS.FILTERS_COMPS];
    delete transformed[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS];
    const compsTable = get(transformed, [PREFERENCES_KEYS.TABLE_COLUMNS_COMPS]);
    if (compsTable) {
      transformed[PREFERENCES_KEYS.TABLE_COLUMNS_COMPS] = transformDataPriority(
        compsTable,
        options
      );
    }
    const rentalCompsTable = get(transformed, [
      PREFERENCES_KEYS.TABLE_COLUMNS_RENTAL_COMPS
    ]);
    if (rentalCompsTable) {
      transformed[PREFERENCES_KEYS.TABLE_COLUMNS_RENTAL_COMPS] =
        transformDataPriority(rentalCompsTable, options);
    }
    return transformed;
  }
};

export const transformCompsFilters = (response, options) => {
  if (response.statusCode === 404) {
    return response;
  } else {
    const transformed = cloneDeep(response);
    Object.keys(transformed).forEach((filterSetId) => {
      const filterSet = transformed[filterSetId];
      if (filterSet.values) {
        const newValues = {};
        const fieldLookup = getFieldLookup(options);
        Object.keys(filterSet.values).forEach((field) => {
          const newField = fieldLookup[field] || field;
          newValues[newField] = {
            ...filterSet.values[field],
            field: newField
          };
          const fieldValueFn = getFieldValueFn(field, options);
          if (fieldValueFn) {
            newValues[newField].relativeValue = fieldValueFn(
              newValues[newField].relativeValue
            );
          }
        });
        filterSet.values = newValues;
        transformed[filterSetId] = filterSet;
      }
    });
    return transformed;
  }
};
