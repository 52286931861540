import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fragment } from 'react';
import { NullStateCard } from '@hc/component-lib/hclib/components/molecules/null-state-card';
import { TextButton } from 'legacy/components/Button';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { NULL_STATE_WARNINGS } from 'legacy/appstore/constants';

import Popover from 'legacy/components/generic/Popover';
import Loading from 'legacy/components/generic/Loading';
import { useListings } from 'legacy/hocs/useListings';
import { PropertyTransactionHistory as theme } from 'legacy/css-modules';

const { VRSHARE_PROXY_URL } = HC_CONSTANTS;

const PropertyTransactionHistoryLinks = ({
  addressId,
  isSubject,
  shouldScrubData,
  transaction = {},
  photos,
  header,
  onViewPhotos,
  onViewMlsSheet
}) => {
  const { showButtons, transactionId, mlsNumber, hcMlsId } = transaction;

  const disabled = !photos || !photos.length;
  const ViewPhotosButton = isSubject && (
    <TextButton
      dataHcName="transaction-history-table-row-button-photos"
      onClick={() => onViewPhotos(transactionId)}
      disabled={disabled}
    >
      View Photos
    </TextButton>
  );
  const width = ViewPhotosButton ? 300 : 150;
  const url = `${VRSHARE_PROXY_URL}/emporium/vrshare/mls/listing/${addressId}/${mlsNumber}/${hcMlsId}/MLS Sheet`;
  const viewMlsSheetText = 'View MLS Sheet';
  return (
    <div
      data-hc-name="transaction-history-table-row-buttons"
      style={{
        flex: `0 0 ${width}px`,
        width: `${width}px`
      }}
      className={classNames(theme.Cell, { [theme.HeaderCell]: header })}
    >
      {!header && showButtons ? (
        <Fragment>
          {!shouldScrubData && (
            <a
              onClick={() => onViewMlsSheet(transactionId)}
              href={url}
              type="application/pdf"
              target="_blank"
              rel="noreferrer"
            >
              <TextButton dataHcName="transaction-history-table-row-button-mls-sheet">
                {viewMlsSheetText}
              </TextButton>
            </a>
          )}
          {ViewPhotosButton ? (
            disabled ? (
              <Popover
                popoverAlignment="top"
                Button={ViewPhotosButton}
                fixed
                toggleOnHover
              >
                <div className={theme.HelpText}>
                  Unfortunately, we are unable to find photos for this
                  particular listing.
                </div>
              </Popover>
            ) : (
              ViewPhotosButton
            )
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
};

const PropertyTransactionHistory = ({
  addressId,
  loading,
  columns,
  isSubject,
  photosByMlsNumberAndExternalSourceId,
  onViewPhotos,
  onViewMlsSheet,
  transactionHistory,
  hasPropertyHistory,
  shouldScrubData
}) => {
  const { listings } = useListings(addressId);
  return (
    // TODO: Refactor this history to use listings
    <div
      className={theme.PropertyTransactionHistory}
      data-hc-name="transaction-history-table"
    >
      {hasPropertyHistory ? (
        <Fragment>
          <div className={theme.RowWrapper}>
            <div
              className={classNames(theme.Row, theme.HeaderRow)}
              data-hc-name="transaction-history-table-header"
            >
              {columns.map((column) => {
                const Column = (
                  <div
                    data-hc-name="transaction-history-table-header-label"
                    key={`header-${column.label}`}
                    style={{
                      flex: `0 0 ${column.width}px`,
                      width: `${column.width}px`
                    }}
                    className={classNames(theme.Cell, theme.HeaderCell, {
                      [theme.HelpTooltipAnchor]: column.tooltipText
                    })}
                  >
                    {column.label}
                  </div>
                );
                if (column.tooltipText) {
                  return (
                    <Popover
                      popoverAlignment="bottom"
                      key={`header-${column.label}-popover`}
                      Button={Column}
                      minWidth={150}
                      toggleOnHover
                      caret
                    >
                      <div className={theme.HelpText}>{column.tooltipText}</div>
                    </Popover>
                  );
                } else {
                  return Column;
                }
              })}
              <PropertyTransactionHistoryLinks
                shouldScrubData={shouldScrubData}
                header
              />
            </div>
          </div>
          <div className={theme.Content}>
            <Loading loading={loading}>
              {transactionHistory.map((transaction) => {
                const listing = listings.find((l) => {
                  if (
                    transaction.hcMlsId &&
                    l.listingStatus?.listingDate === transaction.listingDate &&
                    l.mlsID === transaction.hcMlsId.toString()
                  )
                    return true;
                });
                return (
                  <div
                    key={`${transaction.transactionId}`}
                    className={theme.RowWrapper}
                  >
                    <div
                      className={theme.Row}
                      data-hc-name="transaction-history-table-row"
                    >
                      {columns.map((column) => {
                        const columnValue = transaction[column.key];
                        return (
                          <div
                            key={`${transaction.transactionId}-${column.key}`}
                            data-hc-name="transaction-history-table-row-value"
                            style={{
                              flex: `0 0 ${column.width}px`,
                              width: `${column.width}px`
                            }}
                            className={classNames(theme.Cell, {
                              [theme.listed]:
                                column.key === 'event' &&
                                (columnValue === 'listed' ||
                                  columnValue === 'rentalListed'),
                              [theme.pending]:
                                column.key === 'event' &&
                                (columnValue === 'pending' ||
                                  columnValue === 'rentalPending'),
                              [theme.sold]:
                                column.key === 'event' &&
                                (columnValue === 'sold' ||
                                  columnValue === 'rentalLeased'),
                              [theme.deleted]:
                                column.key === 'event' &&
                                (columnValue === 'deleted' ||
                                  columnValue === 'rentalDeleted')
                            })}
                          >
                            {column.format(columnValue)}
                          </div>
                        );
                      })}
                      <PropertyTransactionHistoryLinks
                        isSubject={isSubject}
                        addressId={addressId}
                        onViewPhotos={onViewPhotos}
                        onViewMlsSheet={onViewMlsSheet}
                        photos={listing?.photos || []}
                        transaction={transaction}
                        shouldScrubData={shouldScrubData}
                      />
                    </div>
                  </div>
                );
              })}
            </Loading>
          </div>
        </Fragment>
      ) : (
        <NullStateCard
          theme={theme}
          title={NULL_STATE_WARNINGS.transactionHistory.title}
        >
          {NULL_STATE_WARNINGS.transactionHistory.message}
        </NullStateCard>
      )}
    </div>
  );
};

PropertyTransactionHistory.propTypes = {
  history: PropTypes.array
};

export default PropertyTransactionHistory;
