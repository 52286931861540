import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { SECTION_ID_SHARED_REPORT_FORM } from 'legacy/appstore/constants';
import { EVENTS } from 'legacy/appstore/events';

import { FORM_NAME as SHARE_REPORT_FORM_NAME } from 'legacy/forms/share-report';

import {
  shareReportModalSubmit,
  shareReportClose,
  uniqueLinkConfigure
} from 'actions/share-report';
import { analyticsEvent } from 'actions/analytics';

import {
  getReportStatusSharedReportConfigIsUpdating,
  getReportStatusSharedReportConfigDoesNotExist
} from 'selectors/report-status';
import {
  getShareByEmail,
  getShareableUrl,
  getLinkConfigValue,
  getAllShareReportFormValues,
  getCanSendEmailBeforeLinkGenerated
} from 'selectors/share-report';
import { getMqSmallMediumAndUp } from 'selectors/match-media';
import {
  getIsViewingSharedReport,
  getIsEffectiveDateReport
} from 'selectors/property-explorer';

import loadWhenReady from 'legacy/hocs/loadWhenReady';

import ShareReportForm from 'legacy/components/ShareReportForm';

const mapStateToProps = (state) => {
  const linkConfigValue = getLinkConfigValue(state);
  const isEffectiveDate = getIsEffectiveDateReport(state);
  return {
    allowChangePermissions:
      !getIsViewingSharedReport(state) && !isEffectiveDate,
    shareByEmail: getShareByEmail(state),
    link: isEffectiveDate ? window.location.href : getShareableUrl(state),
    linkConfigOptions: [
      {
        value: 'view',
        label: 'Read Only',
        dataHcName:
          linkConfigValue === 'view' && 'share-permission-toggle-selected'
      },
      {
        value: 'save',
        label: 'Edit & Save',
        dataHcName:
          linkConfigValue === 'save' && 'share-permission-toggle-selected'
      }
    ],
    isUpdating: getReportStatusSharedReportConfigIsUpdating(state),
    uniqueLinkDoesNotExist:
      getReportStatusSharedReportConfigDoesNotExist(state) && !isEffectiveDate,
    initialValues: {
      linkConfig: getLinkConfigValue(state)
    },
    formValues: getAllShareReportFormValues(state),
    mqMediumAndUp: getMqSmallMediumAndUp(state),
    canSendEmailBeforeLinkGenerated: getCanSendEmailBeforeLinkGenerated(state),
    isEffectiveDate
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    dispatch(shareReportModalSubmit());
  },
  onClose: () => {
    dispatch(shareReportClose());
  },
  copyUrlToClipboard: (e, formValues) => {
    const input = document.getElementById('report-link');
    // Hold Cmd key to copy just the path of the report (for dev/qa)
    const startIndex = e.metaKey ? input.value.indexOf('shared') : 0;
    input.focus();
    input.setSelectionRange(startIndex, input.value.length);
    document.execCommand('copy');
    dispatch(
      analyticsEvent(EVENTS.SHARE_LINK_COPY, {
        link_config: formValues.linkConfig
      })
    );
  },
  onGenerateLink: () => {
    dispatch(uniqueLinkConfigure());
  },
  onConfigureLink: (e, val) => {
    dispatch(uniqueLinkConfigure(val));
  }
});

// NOTE: When using a redux-form reduxForm() must be called before connect()
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  loadWhenReady(
    reduxForm({
      form: SHARE_REPORT_FORM_NAME
    })(ShareReportForm),
    SECTION_ID_SHARED_REPORT_FORM
  )
);
