const HOME = 'home';
const LOGIN = 'login';
const LOGOUT = 'logout';
const FORGOT_PASSWORD = 'forgot-password';
const REGISTRATION_EMAIL = 'registration-email';
const SIGN_UP = 'sign-up';
const SELECT_PACKAGE = 'select-package';
const SELECT_ORGANIZATION = 'select-organization';
const JOIN_ORGANIZATION = 'join-organization';
const INVITE_TEAM = 'invite-team';
const CONFIRM_USER = 'confirm-user';
const RESET_PASSWORD = 'reset-password';

const SEARCH = 'search';

const VALUE_REPORT = 'property-explorer';
const VALUE_REPORT_SHARED = 'property-explorer-shared';

const COMPS_SELECTION = 'comps-selection';
const COMPS_SELECTION_SHARED = 'comps-selection-shared';
const COMPS_SELECTION_MAP = 'comps-selection-map';
const COMPS_SELECTION_MAP_SHARED = 'comps-selection-map-shared';

const RENTAL_COMPS_SELECTION = 'rental-comps-selection';
const RENTAL_COMPS_SELECTION_SHARED = 'rental-comps-selection-shared';
const RENTAL_COMPS_SELECTION_MAP = 'rental-comps-selection-map';
const RENTAL_COMPS_SELECTION_MAP_SHARED = 'rental-comps-selection-map-shared';

const FULL_MAP_VALUE_REPORT = 'full-map-property-explorer';
const FULL_MAP_VALUE_REPORT_SHARED = 'full-map-property-explorer-shared';

const LOCATION_MAP = 'location-map';
const LOCATION_MAP_SHARED = 'location-map-shared';

const EFFECTIVE_DATE_ADDRESS = 'effective-date-address';
const EFFECTIVE_DATE_REPORT = 'effective-date-report';
const EFFECTIVE_DATE_COMPS = 'effective-date-comps';
const EFFECTIVE_DATE_COMPS_MAP = 'effective-date-comps-map';

export const VR_REDIRECT_VIEW_MOD = '-vr-redirect';

export const VIEWS = {
  HOME,
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  REGISTRATION_EMAIL,
  SIGN_UP,
  SELECT_PACKAGE,
  SEARCH,
  SELECT_ORGANIZATION,
  JOIN_ORGANIZATION,
  INVITE_TEAM,
  CONFIRM_USER,
  RESET_PASSWORD,
  VALUE_REPORT,
  COMPS_SELECTION,
  FULL_MAP_VALUE_REPORT,
  LOCATION_MAP,
  LOCATION_MAP_SHARED,
  VALUE_REPORT_SHARED,
  COMPS_SELECTION_SHARED,
  FULL_MAP_VALUE_REPORT_SHARED,
  RENTAL_COMPS_SELECTION,
  RENTAL_COMPS_SELECTION_SHARED,
  COMPS_SELECTION_MAP,
  COMPS_SELECTION_MAP_SHARED,
  RENTAL_COMPS_SELECTION_MAP,
  RENTAL_COMPS_SELECTION_MAP_SHARED,
  EFFECTIVE_DATE_ADDRESS,
  EFFECTIVE_DATE_REPORT,
  EFFECTIVE_DATE_COMPS,
  EFFECTIVE_DATE_COMPS_MAP
};

export const EFFECTIVE_DATE_VIEWS = [
  EFFECTIVE_DATE_ADDRESS,
  EFFECTIVE_DATE_REPORT,
  EFFECTIVE_DATE_COMPS,
  EFFECTIVE_DATE_COMPS_MAP
];

export const ROUTES_TO_HIDE_HEADER = [
  COMPS_SELECTION,
  COMPS_SELECTION_SHARED,
  COMPS_SELECTION_MAP,
  COMPS_SELECTION_MAP_SHARED,
  RENTAL_COMPS_SELECTION,
  RENTAL_COMPS_SELECTION_SHARED,
  RENTAL_COMPS_SELECTION_MAP,
  RENTAL_COMPS_SELECTION_MAP_SHARED,
  FULL_MAP_VALUE_REPORT,
  FULL_MAP_VALUE_REPORT_SHARED,
  LOCATION_MAP,
  LOCATION_MAP_SHARED
];

export const VR_VIEWS = [
  VALUE_REPORT,
  FULL_MAP_VALUE_REPORT,
  VALUE_REPORT_SHARED,
  FULL_MAP_VALUE_REPORT_SHARED,
  COMPS_SELECTION,
  COMPS_SELECTION_SHARED,
  COMPS_SELECTION_MAP,
  COMPS_SELECTION_MAP_SHARED,
  RENTAL_COMPS_SELECTION,
  RENTAL_COMPS_SELECTION_SHARED,
  RENTAL_COMPS_SELECTION_MAP,
  RENTAL_COMPS_SELECTION_MAP_SHARED,
  LOCATION_MAP,
  LOCATION_MAP_SHARED
];

export const COMP_SELECTION_VIEWS = [
  COMPS_SELECTION,
  COMPS_SELECTION_SHARED,
  COMPS_SELECTION_MAP,
  COMPS_SELECTION_MAP_SHARED,
  RENTAL_COMPS_SELECTION,
  RENTAL_COMPS_SELECTION_SHARED,
  RENTAL_COMPS_SELECTION_MAP,
  RENTAL_COMPS_SELECTION_MAP_SHARED,
  EFFECTIVE_DATE_COMPS,
  EFFECTIVE_DATE_COMPS_MAP
];

export const COMPS_VIEWS = [
  COMPS_SELECTION,
  COMPS_SELECTION_SHARED,
  COMPS_SELECTION_MAP,
  COMPS_SELECTION_MAP_SHARED,
  EFFECTIVE_DATE_COMPS,
  EFFECTIVE_DATE_COMPS_MAP
];

export const RENTAL_COMPS_VIEWS = [
  RENTAL_COMPS_SELECTION,
  RENTAL_COMPS_SELECTION_SHARED,
  RENTAL_COMPS_SELECTION_MAP,
  RENTAL_COMPS_SELECTION_MAP_SHARED
];

export const COMP_SELECTION_PAGE = [
  COMPS_SELECTION,
  COMPS_SELECTION_SHARED,
  RENTAL_COMPS_SELECTION,
  RENTAL_COMPS_SELECTION_SHARED,
  EFFECTIVE_DATE_COMPS
];

export const COMP_MAP_PAGE = [
  COMPS_SELECTION_MAP,
  COMPS_SELECTION_MAP_SHARED,
  RENTAL_COMPS_SELECTION_MAP,
  RENTAL_COMPS_SELECTION_MAP_SHARED,
  LOCATION_MAP,
  LOCATION_MAP_SHARED,
  EFFECTIVE_DATE_COMPS_MAP
];

export const ACTION_PAGE_LOAD_COMPS = 'ACTION_PAGE_LOAD_COMPS';
export const ACTION_PAGE_LOAD_RENTAL_COMPS = 'ACTION_PAGE_LOAD_RENTAL_COMPS';
