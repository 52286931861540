import { watchLatest } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { call, put, select } from 'redux-saga/effects';
import { getCompsAvailableProperties } from 'selectors/comps';
import { COMPS_FILTERS_APPLY } from 'actions/comps';
import { computeChartDataNearbyProperties } from 'legacy/services/chart-data';
import { chartDataAvailableCompsRecomputed } from 'actions/chart-data';
import { GET_REPORT_USER_VERSION_SUCCESS } from 'actions/get-report';
import { VR_RECOMPUTE_SUCCESS } from 'actions/edit-report';

function* recalculateAvailableCompsCharts() {
  try {
    const comps = yield select(getCompsAvailableProperties);
    const chartData = yield call(computeChartDataNearbyProperties, comps);
    yield put(chartDataAvailableCompsRecomputed(chartData));
  } catch (e) {
    report('Available Comps Chart: Failed to recalculate data');
  }
}

export default function registerChartDataSagas() {
  watchLatest(
    [
      COMPS_FILTERS_APPLY,
      VR_RECOMPUTE_SUCCESS,
      GET_REPORT_USER_VERSION_SUCCESS
    ],
    recalculateAvailableCompsCharts
  );
}
