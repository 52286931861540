import { STATUSES } from '../../../constants';
import {
  ReportApiActions,
  DOCUMENT_ROLE_FETCH,
  DOCUMENT_ROLE_FETCH_SUCCESS
  // DOCUMENT_ROLE_FETCH_ERROR
} from '../actions';
import { DocumentRoles } from '../types';

interface DocumentRoleStateLoading {
  status: STATUSES.LOADING;
  fetching: true;
}

interface DocumentRoleStateSuccess {
  status: STATUSES.SUCCESS;
  fetching: boolean;
}

interface DocumentRoleStateError {
  status: STATUSES.ERROR;
  fetching: boolean;
  message: string;
}

export type DocumentRoleState =
  | DocumentRoleStateLoading
  | DocumentRoleStateSuccess
  | DocumentRoleStateError;

type DocumentRoleReportState = Partial<{
  [documentRole in DocumentRoles]: DocumentRoleState;
}>;

export interface DocumentRolesState {
  [reportId: number]: DocumentRoleReportState | undefined;
}

const INITIAL_STATE = {} as DocumentRolesState;

export const documentRoleReducer = (
  state: DocumentRolesState = INITIAL_STATE,
  action: ReportApiActions
): DocumentRolesState => {
  switch (action.type) {
    case DOCUMENT_ROLE_FETCH: {
      const { reportId, documentRole } = action.payload;
      const updatedState = { ...state };
      // Create State for Report
      if (!updatedState[reportId]) {
        const emptyDocumentRoleState = {} as DocumentRoleReportState;
        updatedState[reportId] = emptyDocumentRoleState;
      }
      const reportState = updatedState[reportId];
      if (reportState) {
        // Create or Update State for DocumentBase Role
        let documentRoleState = reportState[documentRole];
        if (documentRoleState) {
          documentRoleState = {
            ...documentRoleState,
            fetching: true
          };
        } else {
          reportState[documentRole] = {
            status: STATUSES.LOADING,
            fetching: true
          };
        }
      }
      return updatedState;
    }

    case DOCUMENT_ROLE_FETCH_SUCCESS: {
      const { reportId, documentRole } = action.payload;
      return {
        ...state,
        [reportId]: {
          ...state[reportId],
          [documentRole]: {
            status: STATUSES.SUCCESS,
            fetching: false
          }
        }
      };
    }

    // case DOCUMENT_ROLE_FETCH_ERROR: {
    //   return {
    //     ...state,
    //     [action.payload.reportId]: {
    //       status: STATUSES.ERROR,
    //       fetching: false,
    //       message: action.payload.message
    //     }
    //   };
    // }

    default:
      return state;
  }
};
