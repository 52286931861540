import { EVENTS } from 'legacy/appstore/events';

// Adds a page viewed event to all ROUTE_UPDATE actions.
// The event will be dispatched when the action reaches analyticsMiddleware
import { ROUTE_UPDATE } from '@hc/redux-saga-router-plus/hclib/actions';

const analyticsPageViewedMiddleware = (store) => (next) => (action) => {
  // TODO: Remove domain name hack to prevent this event from firing in dev/stage
  //       It was implemented like this since our node env is the same for stage/prod
  if (
    action &&
    action.type === ROUTE_UPDATE &&
    window.location.hostname === 'pexp.housecanary.com'
  ) {
    const actionWithEvent = {
      ...action,
      meta: {
        analytics: { event: EVENTS.PAGE_VIEWED }
      }
    };
    return next(actionWithEvent);
  } else {
    return next(action);
  }
};

export default analyticsPageViewedMiddleware;
