import { Component } from 'react';
const formFieldCursorPosition = (FieldComponent) => {
  return class FormFieldCursorPosition extends Component {
    componentDidUpdate(prevProps) {
      if (
        prevProps.input.value !== this.props.input.value &&
        this.props.newSelection
      ) {
        this.props.inputNode.setSelectionRange(
          this.props.newSelection,
          this.props.newSelection
        );
      }
    }
    render() {
      const { newSelection, inputNode, ...passThroughProps } = this.props;
      return <FieldComponent {...passThroughProps} />;
    }
  };
};

export default formFieldCursorPosition;
