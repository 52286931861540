import React, { Fragment } from 'react';
import moment from 'moment';
import get from 'lodash.get';
import {
  FILTER_SAVE_TYPES,
  HELP_TEXT_SIMILARITY,
  FILTER_MATCH_SUBJECT,
  COMP_TYPE_DEFAULT,
  COMP_TYPE_RENTAL,
  GARAGE_FILTER_OPTIONS,
  STORIES_FILTER_OPTIONS
} from 'legacy/appstore/constants';

import { displayDate } from 'legacy/utils/dates';
import {
  wholeNumberFormatter,
  abbrDollarsFormatter,
  dollarsFormatter,
  numberFormatter
} from 'legacy/utils/formatters';
import { PROPERTY_TYPE_OPTIONS } from 'legacy/utils/property-details';
import { removeNonNumericExceptDec } from 'legacy/utils/strings';
import { isArray } from 'legacy/utils/utils';

import DistanceControl from 'legacy/containers/filter-distance-control';

import FilterMultiSelect from 'legacy/components/FilterMultiSelect';
import FilterRange from 'legacy/components/FilterRange';
import FilterRadioSelect from 'legacy/components/FilterRadioSelect';
import FilterDateAgo from 'legacy/components/FilterDateAgo';
import SimilarityLevelFilterOption from 'legacy/components/SimilarityLevelFilterOption';

export const TRANSFORMED_FILTER_KEYS_SOLD = {
  daysOnMarketActive: 'activeDaysOnMarket',
  daysOnMarketCumulative: 'cumulativeDaysOnMarket',
  distance: 'distanceMiles',
  similarity: 'similarity',
  isFlip: 'flips',
  livingArea: 'grossLivingAreaSqft',
  listPricePerSqFt: 'pricePerSqftNonDisclosure',
  closePricePerSqFt: 'pricePerSqft',
  lotSize: 'siteAreaSqft'
};

export const TRANSFORMED_FILTER_KEYS_SOLD_LEGACY_NEW = {
  activeDaysOnMarket: 'daysOnMarketActive',
  cumulativeDaysOnMarket: 'daysOnMarketCumulative',
  distanceMiles: 'distance',
  similarity: 'similarity',
  flips: 'isFlip',
  grossLivingAreaSqft: 'livingArea',
  pricePerSqftNonDisclosure: 'listPricePerSqFt',
  pricePerSqft: 'closePricePerSqFt',
  siteAreaSqft: 'lotSize'
};

export const TRANSFORMED_FILTER_KEYS_RENTAL = {
  daysOnMarketActive: 'activeDaysOnMarket',
  daysOnMarketCumulative: 'cumulativeDaysOnMarket',
  distance: 'distanceMiles',
  similarity: 'similarity',
  isFlip: 'flips',
  livingArea: 'grossLivingAreaSqft',
  closeDate: 'leasedDate',
  closePrice: 'leasedPrice',
  lotSize: 'siteAreaSqft'
};

export const TRANSFORMED_FILTER_KEYS_RENTAL_LEGACY_NEW = {
  activeDaysOnMarket: 'daysOnMarketActive',
  cumulativeDaysOnMarket: 'daysOnMarketCumulative',
  distanceMiles: 'distance',
  similarity: 'similarity',
  flips: 'isFlip',
  grossLivingAreaSqft: 'livingArea',
  leasedDate: 'closeDate',
  leasedPrice: 'closePrice',
  siteAreaSqft: 'lotSize'
};

export const getSubjectFilterValue = (subject, filterKey) =>
  get(
    subject,
    (TRANSFORMED_FILTER_KEYS_SOLD[filterKey] || filterKey).split('.')
  );

export const getSubjectRentalFilterValue = (subject, filterKey) =>
  get(
    subject,
    (TRANSFORMED_FILTER_KEYS_RENTAL[filterKey] || filterKey).split('.')
  );

export const getAbsoluteFilterValueMapping = (filters) => {
  const absoluteValues = {};
  for (const filterKey in filters) {
    const { absoluteValue } = filters[filterKey];
    if (absoluteValue && !absoluteValue.error) {
      absoluteValues[filterKey] = absoluteValue;
    }
  }
  return absoluteValues;
};

export const FILTERS_ORDER = [
  'propertyType',
  'distance',
  'bedrooms',
  'bathrooms',
  'livingArea',
  'yearBuilt',
  'propertyStatus',
  'propertyStatusRental',
  'lastListDate',
  'lastListDateRental',
  'salesDate',
  'leasedDate',
  'lastListPrice',
  'lastListPriceRental',
  'salesPrice',
  'leasedPrice',
  'lotSize',
  'similarity',
  'rentalAvm.priceMean',
  'pool',
  'garageNumCars',
  'stories',
  'basement'
];
const _nullZeroLabel = (v) =>
  v === null || v === undefined || v === '' ? 'Any' : v;
const displayFormatterSimpleRange = (
  value,
  { label, singleValueFormatter = _nullZeroLabel }
) => {
  if (value[0] && !value[1]) {
    return `${singleValueFormatter(value[0])}+ ${label}`;
  }
  if (value[1] && (!value[0] || (value[0] && value[0] <= 0))) {
    return `Up to ${singleValueFormatter(value[1])} ${label}`;
  }
  if (value[0] && value[1] && value[0] === value[1]) {
    return `${singleValueFormatter(value[1])} ${label}`;
  }
  return `${singleValueFormatter(
    isFilterSet(value[0]) ? Math.max(0, value[0]) : value[0]
  )}-${singleValueFormatter(value[1])} ${label}`;
};

const displayFormatterAgeRange = (value, { label }) => {
  const currentYear = new Date().getFullYear();
  return `${value[0] || 'Up to'} ${value[0] ? '-' : ''} ${
    value[1] && value[1] < currentYear ? value[1] : currentYear
  } ${label}`;
};

const displayFormatterDateAgo = (
  value,
  { labelAbbrev },
  subjectValue,
  isEffectiveDateReport
) => {
  const start = moment(value[0], 'YYYY-MM-DD');
  const end = moment(value[1], 'YYYY-MM-DD');
  const months = Math.round(moment.duration(end.diff(start)).asMonths());
  if (months < 12) {
    if (isEffectiveDateReport) {
      return `${labelAbbrev} ${months} Months Prior`;
    } else {
      return `${labelAbbrev} Last ${months} Months`;
    }
  } else {
    const years = Math.round((months / 12) * 10) / 10;
    if (isEffectiveDateReport) {
      return `${labelAbbrev} ${years} Year${years > 1 ? 's' : ''} Prior`;
    } else {
      return `${labelAbbrev} Last ${years} Year${years > 1 ? 's' : ''}`;
    }
  }
};

const _formatGarageFilterValue = (v) => {
  if (v[0] === 1 && v[1] === null) {
    return 'Yes';
  } else if (v[0] === null && v[1] === 0) {
    return 'No';
  } else {
    return 'Any';
  }
};

const _controlInfoSubjectFormatter = (
  subjectValue,
  { singleValueFormatter = (v) => v }
) =>
  [null, undefined, ''].includes(subjectValue)
    ? 'Missing Subject Value'
    : `Subject: ${singleValueFormatter(subjectValue)}`;

const _isFilterValueSet = (filterValue) => {
  if (isArray(filterValue)) {
    if (!filterValue.length) {
      return false;
    } else {
      let isSet = false;
      filterValue.forEach((v) => {
        if (![null, undefined, ''].includes(v)) {
          isSet = true;
        }
      });
      return isSet;
    }
  } else if (![null, undefined, ''].includes(filterValue)) {
    return true;
  }
  return false;
};

export const isFilterSet = (absoluteValue, relativeValue) =>
  _isFilterValueSet(absoluteValue) || _isFilterValueSet(relativeValue);

export const FILTER_TYPES = {
  MINMAX: 'MINMAX',
  INCLUDE: 'INCLUDE',
  EXCLUDE: 'EXCLUDE',
  BOOLEAN: 'BOOLEAN',
  SEARCH: 'SEARCH',
  SERVER: 'SERVER'
};

export const FILTERS = {
  propertyType: {
    label: 'Property Type',
    type: FILTER_TYPES.INCLUDE,
    quickFilterOptions: [
      {
        label: 'Match the Subject',
        value: FILTER_MATCH_SUBJECT
      }
    ],
    Input: FilterMultiSelect,
    inputProps: {
      options: PROPERTY_TYPE_OPTIONS,
      dataHcNameProps: {
        label: 'property-type-label',
        checkbox: 'property-type-checkbox'
      }
    },
    controlInfo: _controlInfoSubjectFormatter,
    // Display for full filter range
    chipDisplayFormatter: (value) => `${value.join(', ')}`,
    // Formatter for the save modal
    saveType: FILTER_SAVE_TYPES.MATCH_OR_EXACT,
    dataHcName: 'property-type'
  },
  distance: {
    type: FILTER_TYPES.SERVER,
    label: 'Distance',
    controlInfo: '',
    chipDisplayFormatter: (v) => {
      if (typeof v === 'string') {
        return `Custom ${v}`;
      } else {
        const display = v % 1 === 0 ? v : numberFormatter(v, { precision: 2 });
        return `${display} Mile${v > 1 ? 's' : ''}`;
      }
    },
    singleValueFormatter: (v) => {
      if (typeof v === 'string') {
        return `Custom ${v}`;
      } else {
        const display = v % 1 === 0 ? v : numberFormatter(v, { precision: 2 });
        return `${display} Mile${v > 1 ? 's' : ''}`;
      }
    },
    // inputProps are defined in the distance control selector
    Input: DistanceControl,
    saveType: FILTER_SAVE_TYPES.DISTANCE,
    dataHcName: 'distance'
  },
  bedrooms: {
    type: FILTER_TYPES.MINMAX,
    label: 'Beds',
    controlInfo: _controlInfoSubjectFormatter,
    quickFilterOptions: [
      {
        label: 0,
        value: [0, 0]
      },
      {
        label: 1,
        value: [-1, 1]
      },
      {
        label: 2,
        value: [-2, 2]
      },
      {
        label: 3,
        value: [-3, 3]
      }
    ],
    chipDisplayFormatter: displayFormatterSimpleRange,
    Input: FilterRange,
    inputProps: {
      relativeType: FILTER_SAVE_TYPES.REL_EXACT,
      min: 0,
      max: 999,
      dataHcName: 'beds'
    },
    saveType: FILTER_SAVE_TYPES.REL_EXACT,
    dataHcName: 'beds'
  },
  bathrooms: {
    type: FILTER_TYPES.MINMAX,
    label: 'Baths',
    controlInfo: _controlInfoSubjectFormatter,
    chipDisplayFormatter: displayFormatterSimpleRange,
    Input: FilterRange,
    inputProps: {
      relativeType: FILTER_SAVE_TYPES.REL_EXACT,
      min: 0,
      max: 999,
      float: true,
      dataHcName: 'baths',
      formatter: (val) => parseFloat(removeNonNumericExceptDec(val)).toFixed(2)
    },
    saveType: FILTER_SAVE_TYPES.REL_EXACT,
    dataHcName: 'baths'
  },
  livingArea: {
    type: FILTER_TYPES.MINMAX,
    label: 'GLA',
    controlInfo: _controlInfoSubjectFormatter,
    singleValueFormatter: (value) =>
      value ? `${wholeNumberFormatter(value)} SqFt.` : 'Any',
    chipDisplayFormatter: displayFormatterSimpleRange,
    Input: FilterRange,
    inputProps: {
      relativeType: FILTER_SAVE_TYPES.REL_PCT,
      // Format the value in the input field as the user is typing
      formatter: wholeNumberFormatter,
      max: 9999999999,
      min: 0,
      dataHcName: 'gla'
    },
    saveType: FILTER_SAVE_TYPES.REL_PCT,
    dataHcName: 'gla'
  },
  yearBuilt: {
    type: FILTER_TYPES.MINMAX,
    label: 'Year Built',
    controlInfo: _controlInfoSubjectFormatter,
    quickFilterOptions: [
      {
        value: [-5, 5],
        label: '5yrs'
      },
      {
        value: [-15, 15],
        label: '15yrs'
      },
      {
        value: [-25, 25],
        label: '25yrs'
      },
      {
        value: [-35, 35],
        label: '35yrs'
      }
    ],
    chipDisplayFormatter: displayFormatterAgeRange,
    Input: FilterRange,
    inputProps: {
      relativeType: FILTER_SAVE_TYPES.REL_EXACT,
      max: new Date().getFullYear(),
      min: 0,
      dataHcName: 'year-built',
      adjuster: (val, filterId) => {
        const currYear = new Date().getFullYear();
        return filterId === 'year-built' && val > currYear ? currYear : val;
      }
    },
    saveType: FILTER_SAVE_TYPES.REL_EXACT,
    dataHcName: 'year-built'
  },
  propertyStatus: {
    type: FILTER_TYPES.INCLUDE,
    label: 'Status',
    controlInfo: _controlInfoSubjectFormatter,
    Input: FilterMultiSelect,
    inputProps: {
      options: [
        {
          label: 'Active',
          value: 'Active'
        },
        {
          label: 'Pending',
          value: 'Pending'
        },
        {
          label: 'Sold',
          value: 'Sold'
        }
      ],
      dataHcNameProps: {
        label: 'status-label',
        checkbox: 'status-checkbox'
      }
    },
    chipDisplayFormatter: (value) => `${value.join(', ')}`,
    saveType: FILTER_SAVE_TYPES.EXACT,
    compType: COMP_TYPE_DEFAULT,
    dataHcName: 'status'
  },
  propertyStatusRental: {
    type: FILTER_TYPES.INCLUDE,
    label: 'Status',
    controlInfo: _controlInfoSubjectFormatter,
    Input: FilterMultiSelect,
    inputProps: {
      options: [
        {
          label: 'Active',
          value: 'Active'
        },
        {
          label: 'Pending',
          value: 'Pending'
        },
        {
          label: 'Leased',
          value: 'Leased'
        }
      ],
      dataHcNameProps: {
        label: 'status-label',
        checkbox: 'status-checkbox'
      }
    },
    chipDisplayFormatter: (value) => `${value.join(', ')}`,
    saveType: FILTER_SAVE_TYPES.EXACT,
    compType: COMP_TYPE_RENTAL,
    dataHcName: 'status'
  },
  lastListDate: {
    type: FILTER_TYPES.MINMAX,
    label: 'List Date',
    controlInfo: (subjectValue, config) => (
      <Fragment>
        <div>{_controlInfoSubjectFormatter(subjectValue, config)}</div>
        <div>Only applies to "Active" and "Pending" comps.</div>
      </Fragment>
    ),
    Input: FilterDateAgo,
    inputProps: {},
    labelAbbrev: 'Listed',
    singleValueFormatter: displayDate,
    chipDisplayFormatter: displayFormatterDateAgo,
    saveType: FILTER_SAVE_TYPES.DATE_AGO,
    compType: COMP_TYPE_DEFAULT,
    dataHcName: 'list-date'
  },
  lastListDateRental: {
    type: FILTER_TYPES.MINMAX,
    label: 'List Date',
    labelAbbrev: 'Listed',
    controlInfo: (subjectValue, config) => (
      <Fragment>
        <div>{_controlInfoSubjectFormatter(subjectValue, config)}</div>
        <div>Only applies to "Active" and "Pending" rental comps.</div>
      </Fragment>
    ),
    Input: FilterDateAgo,
    inputProps: {},
    singleValueFormatter: displayDate,
    chipDisplayFormatter: displayFormatterDateAgo,
    saveType: FILTER_SAVE_TYPES.DATE_AGO,
    compType: COMP_TYPE_RENTAL,
    dataHcName: 'list-date'
  },
  salesDate: {
    type: FILTER_TYPES.MINMAX,
    label: 'Sold Date',
    labelAbbrev: 'Sold',
    controlInfo: (subjectValue, config) => (
      <Fragment>
        <div>{_controlInfoSubjectFormatter(subjectValue, config)}</div>
        <div>Only applies to "Sold" comps.</div>
      </Fragment>
    ),
    Input: FilterDateAgo,
    inputProps: {},
    singleValueFormatter: displayDate,
    chipDisplayFormatter: displayFormatterDateAgo,
    saveType: FILTER_SAVE_TYPES.DATE_AGO,
    compType: COMP_TYPE_DEFAULT,
    dataHcName: 'sold-date'
  },
  leasedDate: {
    type: FILTER_TYPES.MINMAX,
    label: 'Rental Leased Date',
    controlInfo: (subjectValue, config) => (
      <Fragment>
        <div>{_controlInfoSubjectFormatter(subjectValue, config)}</div>
        <div>Only applies to "Leased" rental comps.</div>
      </Fragment>
    ),
    Input: FilterDateAgo,
    inputProps: {},
    labelAbbrev: 'Leased',
    singleValueFormatter: displayDate,
    chipDisplayFormatter: displayFormatterDateAgo,
    saveType: FILTER_SAVE_TYPES.DATE_AGO,
    compType: COMP_TYPE_RENTAL,
    dataHcName: 'leased-date'
  },
  lastListPrice: {
    type: FILTER_TYPES.MINMAX,
    label: 'List Price',
    controlInfo: _controlInfoSubjectFormatter,
    singleValueFormatter: abbrDollarsFormatter,
    chipDisplayFormatter: (value, { label, singleValueFormatter }) =>
      displayFormatterSimpleRange(value, { label, singleValueFormatter }),
    Input: FilterRange,
    inputProps: {
      relativeType: FILTER_SAVE_TYPES.REL_PCT,
      min: 0,
      formatter: dollarsFormatter
    },
    saveType: FILTER_SAVE_TYPES.REL_PCT,
    compType: COMP_TYPE_DEFAULT,
    dataHcName: 'list-price'
  },
  lastListPriceRental: {
    type: FILTER_TYPES.MINMAX,
    label: 'List Price',
    controlInfo: _controlInfoSubjectFormatter,
    singleValueFormatter: abbrDollarsFormatter,
    chipDisplayFormatter: (value, { label, singleValueFormatter }) =>
      displayFormatterSimpleRange(value, { label, singleValueFormatter }),
    Input: FilterRange,
    inputProps: {
      relativeType: FILTER_SAVE_TYPES.REL_PCT,
      min: 0,
      formatter: dollarsFormatter
    },
    saveType: FILTER_SAVE_TYPES.REL_PCT,
    compType: COMP_TYPE_RENTAL,
    dataHcName: 'list-price'
  },
  salesPrice: {
    type: FILTER_TYPES.MINMAX,
    label: 'Sold Price',
    controlInfo: _controlInfoSubjectFormatter,
    singleValueFormatter: abbrDollarsFormatter,
    chipDisplayFormatter: (value, { label, singleValueFormatter }) =>
      displayFormatterSimpleRange(value, { label, singleValueFormatter }),
    Input: FilterRange,
    inputProps: {
      relativeType: FILTER_SAVE_TYPES.REL_PCT,
      min: 0,
      formatter: dollarsFormatter
    },
    saveType: FILTER_SAVE_TYPES.REL_PCT,
    compType: COMP_TYPE_DEFAULT,
    dataHcName: 'sold-price'
  },
  leasedPrice: {
    type: FILTER_TYPES.MINMAX,
    label: 'Rental Leased Price',
    controlInfo: _controlInfoSubjectFormatter,
    singleValueFormatter: abbrDollarsFormatter,
    chipDisplayFormatter: (value, { label, singleValueFormatter }) =>
      displayFormatterSimpleRange(value, { label, singleValueFormatter }),
    Input: FilterRange,
    inputProps: {
      relativeType: FILTER_SAVE_TYPES.REL_PCT,
      min: 0,
      formatter: dollarsFormatter
    },
    saveType: FILTER_SAVE_TYPES.REL_PCT,
    compType: COMP_TYPE_RENTAL,
    dataHcName: 'leased-price'
  },
  'rentalAvm.priceMean': {
    type: FILTER_TYPES.MINMAX,
    label: 'HouseCanary Rental Value',
    controlInfo: _controlInfoSubjectFormatter,
    singleValueFormatter: abbrDollarsFormatter,
    chipDisplayFormatter: (value, { label, singleValueFormatter }) =>
      displayFormatterSimpleRange(value, { label, singleValueFormatter }),
    Input: FilterRange,
    inputProps: {
      relativeType: FILTER_SAVE_TYPES.REL_PCT,
      min: 0,
      formatter: dollarsFormatter
    },
    saveType: FILTER_SAVE_TYPES.REL_PCT,
    compType: COMP_TYPE_RENTAL,
    dataHcName: 'rental-value'
  },
  lotSize: {
    type: FILTER_TYPES.MINMAX,
    label: 'Lot Size',
    controlInfo: _controlInfoSubjectFormatter,
    singleValueFormatter: (value) =>
      value && value !== 0 ? `${wholeNumberFormatter(value)} SqFt.` : 'Any',
    chipDisplayFormatter: displayFormatterSimpleRange,
    Input: FilterRange,
    inputProps: {
      relativeType: FILTER_SAVE_TYPES.REL_PCT,
      min: 0,
      formatter: wholeNumberFormatter,
      dataHcName: 'lot-size'
    },
    saveType: FILTER_SAVE_TYPES.REL_PCT,
    dataHcName: 'lot-size'
  },
  similarity: {
    type: FILTER_TYPES.INCLUDE,
    label: 'Similarity',
    controlInfo: HELP_TEXT_SIMILARITY,
    Input: FilterMultiSelect,
    inputProps: {
      options: [
        {
          label: <SimilarityLevelFilterOption similarityLevel="high" />,
          value: 'high'
        },
        {
          label: <SimilarityLevelFilterOption similarityLevel="moderate" />,
          value: 'moderate'
        },
        {
          label: <SimilarityLevelFilterOption similarityLevel="low" />,
          value: 'low'
        }
      ],
      dataHcName: 'similarity'
    },
    chipDisplayFormatter: (value) => `${value.join(', ')}`,
    saveType: FILTER_SAVE_TYPES.EXACT,
    dataHcName: 'similarity'
  },
  garageNumCars: {
    type: FILTER_TYPES.MINMAX,
    label: 'Garage',
    controlInfo: _controlInfoSubjectFormatter,
    quickFilterOptions: [
      {
        label: 'Match the Subject',
        value: FILTER_MATCH_SUBJECT
      }
    ],
    Input: FilterRadioSelect,
    inputProps: {
      options: [
        {
          value: undefined,
          label: 'Any'
        },
        {
          value: GARAGE_FILTER_OPTIONS.hasGarage,
          label: 'Yes'
        },
        {
          value: GARAGE_FILTER_OPTIONS.noGarage,
          label: 'No'
        }
      ]
    },
    // Display for full filter range
    chipDisplayFormatter: (value, config, subjectValue) => {
      value =
        value === FILTER_MATCH_SUBJECT
          ? subjectValue > 0
            ? GARAGE_FILTER_OPTIONS.hasGarage
            : GARAGE_FILTER_OPTIONS.noGarage
          : value;
      return _formatGarageFilterValue(value) === 'Yes'
        ? 'Has Garage'
        : 'No Garage';
    },
    // Formatter for the save modal
    singleValueFormatter: (value) =>
      value ? `${value} Spaces` : value === 0 ? 'No Garage' : '--',
    saveFormatter: (v) => {
      const formatted = _formatGarageFilterValue(v);
      return formatted === 'Yes' ? 'Has Garage' : 'No Garage';
    },
    relativeToAbsolute: (filterValue, subjectValue) => {
      return filterValue === FILTER_MATCH_SUBJECT
        ? subjectValue === 0
          ? GARAGE_FILTER_OPTIONS.noGarage
          : subjectValue > 0
          ? GARAGE_FILTER_OPTIONS.hasGarage
          : GARAGE_FILTER_OPTIONS.null
        : filterValue;
    },
    saveType: FILTER_SAVE_TYPES.MATCH_RANGE,
    dataHcName: 'garage'
  },
  pool: {
    type: FILTER_TYPES.BOOLEAN,
    label: 'Pool',
    controlInfo: _controlInfoSubjectFormatter,
    quickFilterOptions: [
      {
        label: 'Match the Subject',
        value: FILTER_MATCH_SUBJECT
      }
    ],
    Input: FilterRadioSelect,
    inputProps: {
      options: [
        {
          value: undefined,
          label: 'Any'
        },
        {
          value: true,
          label: 'Yes'
        },
        {
          value: false,
          label: 'No'
        }
      ]
    },
    // Display for full filter range
    chipDisplayFormatter: (value) => (!value ? 'No Pool' : 'Has Pool'),
    singleValueFormatter: (value) =>
      value ? `Has Pool` : value === 0 || value === false ? 'No Pool' : '--',
    saveFormatter: (value) => (value ? 'Has Pool' : 'No Pool'),
    saveType: FILTER_SAVE_TYPES.MATCH_BOOLEAN,
    dataHcName: 'pool'
  },
  basement: {
    type: FILTER_TYPES.BOOLEAN,
    label: 'Basement',
    controlInfo: _controlInfoSubjectFormatter,
    quickFilterOptions: [
      {
        label: 'Match the Subject',
        value: FILTER_MATCH_SUBJECT
      }
    ],
    Input: FilterRadioSelect,
    inputProps: {
      options: [
        {
          value: undefined,
          label: 'Any'
        },
        {
          value: true,
          label: 'Yes'
        },
        {
          value: false,
          label: 'No'
        }
      ]
    },
    // Display for full filter range
    chipDisplayFormatter: (value) => (!value ? 'No Basement' : 'Has Basement'),
    singleValueFormatter: (value) =>
      value
        ? `Has Basement`
        : value === 0 || value === false
        ? 'No Basement'
        : '--',
    saveFormatter: (value) => (value ? 'Has Basement' : 'No Basement'),
    saveType: FILTER_SAVE_TYPES.MATCH_BOOLEAN,
    dataHcName: 'basement'
  },
  stories: {
    type: FILTER_TYPES.MINMAX,
    label: 'Stories',
    controlInfo: _controlInfoSubjectFormatter,
    quickFilterOptions: [
      {
        label: 'Match the Subject',
        value: FILTER_MATCH_SUBJECT
      }
    ],
    Input: FilterRadioSelect,
    inputProps: {
      options: [
        {
          value: undefined,
          label: 'Any'
        },
        {
          value: STORIES_FILTER_OPTIONS.singleStory,
          label: 'Single Story'
        },
        {
          value: STORIES_FILTER_OPTIONS.multiStory,
          label: 'Multi Story'
        }
      ]
    },
    // Display for full filter range
    chipDisplayFormatter: (value, config, subjectValue) => {
      return value === FILTER_MATCH_SUBJECT
        ? subjectValue === 1
          ? 'Single Story'
          : 'Multi Story'
        : value[0] === 1
        ? 'Single Story'
        : 'Multi Story';
    },
    // Formatter for the save modal
    saveFormatter: (v) => {
      if (v === FILTER_MATCH_SUBJECT) {
        return 'Match to Subject';
      }
      if (v[0] === 1 && v[1] === 1) {
        return 'Single Story';
      } else if (v[0] === 2) {
        return 'Multi Story';
      }
    },
    singleValueFormatter: (value) =>
      value ? (value === 1 ? 'Single Story' : `${value} Stories`) : '--',
    relativeToAbsolute: (filterValue, subjectValue) => {
      return filterValue === FILTER_MATCH_SUBJECT
        ? subjectValue === 1
          ? STORIES_FILTER_OPTIONS.singleStory
          : subjectValue > 1
          ? STORIES_FILTER_OPTIONS.multiStory
          : STORIES_FILTER_OPTIONS.null
        : filterValue;
    },
    saveType: FILTER_SAVE_TYPES.MATCH_RANGE,
    dataHcName: 'stories'
  }
};

const _validateSingleValue = (v, filterConfig) => {
  const { max, min } = filterConfig;
  if (v !== null && !isNaN(v)) {
    if (!isNaN(max) && v > max) {
      return max;
    } else if (!isNaN(min) && v < min) {
      return min;
    }
  }
  return v;
};

export const validateFilters = (filters) => {
  const validatedFilters = {};
  for (let key in filters) {
    if (FILTERS[key]) {
      if (isArray(filters[key])) {
        validatedFilters[key] = filters[key].map((v) =>
          _validateSingleValue(v, FILTERS[key].controlProps)
        );
      } else {
        validatedFilters[key] = _validateSingleValue(
          filters[key],
          FILTERS[key].controlProps
        );
      }
    }
  }
  return validatedFilters;
};
