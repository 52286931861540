import { createSelector } from 'reselect';

export const getUiState = (state) => state.ui;

export const getVisibleAvmDetailsReportType = createSelector(
  getUiState,
  (uiState) => uiState.visibleAvmDetailsReportType
);

export const getOverlayIsShowing = createSelector(
  getUiState,
  (uiState) => uiState.overlayIsOpen
);

export const getOverlayContent = createSelector(
  getUiState,
  (uiState) => uiState.overlayContent
);

export const getLeftSideNavIsOpen = createSelector(
  getUiState,
  (uiState) => uiState.leftSideNavIsOpen
);

export const getUiAppLoadingProgress = createSelector(
  getUiState,
  (uiState) => uiState.appLoading
);

export const getUiCompsMapVisibleControlType = createSelector(
  getUiState,
  (uiState) => uiState.compsMapVisibleControlsType
);

export const getUiCompsFiltersVisible = createSelector(
  getUiState,
  (uiState) => uiState.compsFiltersVisible
);
