import { createSelector } from 'reselect';

export const getFullListingsState = (state) => state.listings;

export const getPropertyListingsState = (addressId) =>
  createSelector(
    getFullListingsState,
    (fullListingsState) => fullListingsState[addressId]
  );

export const getPropertyListingsStatus = (addressId) =>
  createSelector(
    getPropertyListingsState(addressId),
    (propertyListingsState) => propertyListingsState?.status
  );

export const getPropertyListingsData = (addressId) =>
  createSelector(
    getPropertyListingsState(addressId),
    (propertyListingsState) => propertyListingsState?.data
  );
