import { connect } from 'react-redux';

import { toastHide } from 'actions/toast';

import { getToastState } from 'selectors/toast';

import Toast from 'legacy/components/Toast';

const mapStateToProps = (state, ownProps) => {
  return {
    ...getToastState(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onHide: () => {
    dispatch(toastHide());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
