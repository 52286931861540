import { connect, useSelector } from 'react-redux';
import { getPropertyDetailsAttributeDef } from 'legacy/utils/property-details';
import { dollarsFormatter } from 'legacy/utils/formatters';
import { isFunc } from 'legacy/utils/utils';
import { useListings } from 'legacy/hocs/useListings';
import { getPropertySingleAttributeLoadingForSource } from 'selectors/property';
import { getChangedFormValues } from 'selectors/redux-form';
import { getReportDataEffectiveDate } from 'selectors/report-data';

import PropertyAttributeValue from 'legacy/components/PropertyAttributeValue';
import { PublicRemarksKeywords } from 'legacy/containers/public-remarks-keywords';

const getListing = (listings, listingId, effectiveDate) =>
  listings.find((l) => {
    if (listingId) {
      return l.listingStatus.listingID === listingId;
    } else if (effectiveDate) {
      return l.listingStatus.listingDate <= effectiveDate;
    } else {
      return !!l.taxSummary.amount;
    }
  });

const TaxYear = ({ addressId, listingId }) => {
  const { listings } = useListings(addressId);
  const effectiveDate = useSelector(getReportDataEffectiveDate);
  const listing = getListing(listings, listingId, effectiveDate);
  return <span>{listing?.taxSummary?.year || '--'}</span>;
};

const TaxAmount = ({ addressId, listingId }) => {
  const { listings } = useListings(addressId);
  const effectiveDate = useSelector(getReportDataEffectiveDate);
  const listing = getListing(listings, listingId, effectiveDate);
  return <span>{dollarsFormatter(listing?.taxSummary?.amount)}</span>;
};

const mapStateToProps = (state, ownProps) => {
  const { addressId, source, attrKey, expanded, property, compType } = ownProps;
  const changedValues = getChangedFormValues(`compareForm-${addressId}`)(state);
  const attribute = getPropertyDetailsAttributeDef(attrKey, property);
  const value =
    attrKey === 'publicRemarks'
      ? null
      : isFunc(attribute.value)
      ? attribute.value(property, changedValues)
      : property[attrKey];
  const loading = !getPropertySingleAttributeLoadingForSource(
    addressId,
    source,
    attrKey
  )(state);
  const { format } = attribute;
  const Component = attribute.Component;
  const displayValue =
    attrKey === 'publicRemarks' ? (
      <PublicRemarksKeywords
        addressId={addressId}
        compType={compType}
        listingId={addressId !== 'subject' ? property.listingId : undefined}
        maxLength={!expanded && 100}
      />
    ) : attrKey === 'taxYear' && addressId !== 'subject' ? (
      <TaxYear addressId={addressId} listingId={property.listingId} />
    ) : attrKey === 'taxAmount' && addressId !== 'subject' ? (
      <TaxAmount addressId={addressId} listingId={property.listingId} />
    ) : value !== null && value !== undefined ? (
      Component ? (
        <Component
          compType={compType}
          expanded={expanded}
          value={typeof value === 'string' ? value : undefined}
          {...(typeof value === 'string' ? {} : value)}
        />
      ) : (
        format(value)
      )
    ) : (
      '--'
    );
  return {
    isComponent:
      (value !== null && value !== undefined && Component) ||
      attrKey === 'publicRemarks',
    loading,
    displayValue
  };
};

export default connect(mapStateToProps)(PropertyAttributeValue);
