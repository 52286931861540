import { reducer as formReducer } from 'redux-form';

import {
  RESET_APP_STATE,
  RESET_VR_STATE,
  clearOnActions
} from '../../clear-reducer-on-actions';
import { FORM_NAME as PERSONALIZATION_FORM_NAME } from 'legacy/forms/personalization-form';
import { FORM_NAME as SHARE_REPORT_FORM_NAME } from 'legacy/forms/share-report';

import personalizationFormReducer from 'reducers/redux-form/personalization-form';
import shareReportFormReducer from 'reducers/redux-form/share-report-form';

export default formReducer.plugin({
  [PERSONALIZATION_FORM_NAME]: clearOnActions(personalizationFormReducer, [
    RESET_APP_STATE
  ]),
  [SHARE_REPORT_FORM_NAME]: clearOnActions(shareReportFormReducer, [
    RESET_APP_STATE,
    RESET_VR_STATE
  ]),
  'zip-code-level-filters': (state = {}, action) => {
    return state;
  },
  'create-collection': (state = {}, action) => {
    return state;
  }
});
