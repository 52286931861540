import React, { useMemo, useState } from 'react';
import { PhotoGridSizeControl } from 'src/lib/components/PhotoGrid';
import { PhotoGrid } from 'src/lib/components/PhotoGrid';
import { LimitType, useListings } from 'src/lib/property-lookup';
import { PhotoGridThumbnail } from 'src/lib/components/PhotoGrid/PhotoGridThumbnail';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { Listing, Photo } from 'src/lib/property-lookup/types';
import { formatDateLong, formatDateStr } from 'src/lib/utils';
import {
  FullscreenCarousel,
  PhotoFullscreenCarousel
} from 'src/lib/components/FullscreenCarousel';
import styles from './PhotosByListing.css';
import { PreferencesKeys, usePreferences } from 'src/lib/huell';
import classNames from 'classnames';
import { Size } from 'src/lib/components/PhotoGrid/types';
import { isLoading } from 'src/lib/utils/general';
import { ID_FIXED_PORTAL } from 'src/constants';
import { ListingStatusBrief } from '../../components/ListingStatusBrief';
import { useMapTilePhoto } from 'src/lib/property-lookup/hooks/useMapTilePhoto';

interface Props {
  dataHcName: string;
  hcAddressId: number;
  onChangeSizeComplete?: (size: Size) => void;
  limit?: LimitType;
  className?: string;
  includeMapTile?: boolean;
}

interface PhotoWithImage extends Photo {
  imageDate: `${number}-${number}-${number}` | null;
  externalSourceId?: string;
}

export const PhotosByListing = ({
  dataHcName = '',
  hcAddressId,
  onChangeSizeComplete,
  limit,
  includeMapTile = true,
  className
}: Props) => {
  const { loading, data: listingList } = useListings(hcAddressId, limit);
  const { data: mapboxPhoto } = useMapTilePhoto(hcAddressId);
  const [fullscreenIndex, setFullscreenIndex] = useState<number | null>(null);
  const { status: preferenceStatus, data: preferences } = usePreferences();
  const defaultSize: Size =
    preferences && preferences[PreferencesKeys.PhotoGrid]?.size !== undefined
      ? preferences[PreferencesKeys.PhotoGrid]?.size
      : 4;

  const allPhotos: PhotoWithImage[] = useMemo(() => {
    const start: PhotoWithImage[] =
      mapboxPhoto && includeMapTile
        ? [{ ...mapboxPhoto, imageDate: null }]
        : [];
    return (listingList || []).reduce(
      (photos: PhotoWithImage[], listing: Listing) => {
        return photos.concat(
          listing?.photos?.map((photo) => ({
            ...photo,
            imageDate: listing.listingStatus.listingDate,
            externalSourceId: listing.listingID
          })) || []
        );
      },
      start
    );
  }, [listingList, mapboxPhoto]);

  const [size, setSize] = useState(defaultSize);
  if (isLoading(preferenceStatus) || loading || !listingList) {
    return <LoadingSpinner dataHcName={`${dataHcName}-listingStatusSpinner`} />;
  }

  const makePhotoGridThumbnail = (
    key: string,
    photo: Photo,
    listing?: Listing
  ) => (
    <PhotoGridThumbnail
      key={key}
      dataHcName={key}
      size={window.innerWidth <= 800 ? 1 : size}
      fullscreen
      label={
        listing?.listingStatus?.listingDate
          ? formatDateStr(listing.listingStatus.listingDate)
          : ''
      }
      photo={photo.representation}
      onClick={() =>
        setFullscreenIndex(
          allPhotos.findIndex(
            (p) => p.representation.httpUrl === photo.representation.httpUrl
          )
        )
      }
    />
  );

  const photoGrids = listingList.map(
    (listing: Listing, listingListIndex: number) => {
      return listing.photos?.length ? (
        <React.Fragment key={`${listingListIndex}-imgs`}>
          <div className={styles.ListingStatusBriefSeparator}>
            <ListingStatusBrief
              dataHcName={`${dataHcName}-listing-status-brief-${listing.listingStatus.listingDate}-${listing.listingID}`}
              listing={listing}
            />
          </div>
          <PhotoGrid
            key={`photogrid-${listingListIndex}`}
            dataHcName={`photo-grid-${listingListIndex}`}
          >
            {listing?.photos?.map((photo, listingIndex) => {
              return makePhotoGridThumbnail(
                `listing-photo-grid-${listingListIndex}-thumbnail-${listingIndex}`,
                photo,
                listing
              );
            })}
          </PhotoGrid>
        </React.Fragment>
      ) : (
        []
      );
    }
  );

  if (mapboxPhoto !== null && mapboxPhoto !== undefined) {
    photoGrids.push(
      <>
        <div className={styles.ListingStatusBriefSeparator} />
        <PhotoGrid
          key={`photogrid-${listingList.length}`}
          dataHcName={`photo-grid-${listingList.length}`}
        >
          {makePhotoGridThumbnail(
            `listing-photo-grid-${listingList.length}-thumbnail`,
            mapboxPhoto
          )}
        </PhotoGrid>
      </>
    );
  }

  return (
    <>
      <div
        data-hc-name={dataHcName}
        className={classNames(className, styles.Scroll)}
      >
        {allPhotos && allPhotos.length ? (
          <div className={styles.Controls}>
            <PhotoGridSizeControl
              dataHcName={`${dataHcName}-size-control`}
              onChangeComplete={onChangeSizeComplete}
              value={size}
              onChange={setSize}
            />
          </div>
        ) : null}
        {photoGrids}
      </div>
      <FullscreenCarousel
        dataHcName={`${dataHcName}-photos-by-listing-fullscreen-carousel`}
        fullscreenPortalId={ID_FIXED_PORTAL}
        initialActive={fullscreenIndex !== null}
        initialIndex={fullscreenIndex}
        photos={allPhotos.map((photo) => {
          return {
            representation: photo.representation,
            fullscreenMetadataLower:
              photo.imageDate && formatDateLong(photo.imageDate),
            fullscreenMetadataUpper: photo.imageDate ? (
              <ListingStatusBrief
                dataHcName={`${dataHcName}-transaction-details-fullscreen-${photo.imageDate}`}
                listing={listingList.find(
                  (l) => l.listingID === photo.externalSourceId
                )}
              />
            ) : null
          };
        })}
        onClose={() => {
          setFullscreenIndex(null);
        }}
        onChange={(photo?: PhotoFullscreenCarousel, idx?: number) => {
          if (idx !== undefined) {
            setFullscreenIndex(idx);
          }
        }}
      />
    </>
  );
};
