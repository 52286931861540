// Actions for search page
export const CREATE_REPORT_SET_CLIENT_ID = 'CREATE_REPORT_SET_CLIENT_ID';
export const CREATE_REPORT_SET_EFFECTIVE_DATE =
  'CREATE_REPORT_SET_EFFECTIVE_DATE';
export const CREATE_REPORT_SET_EFFECTIVE_DATE_SUCCESS =
  'CREATE_REPORT_SET_EFFECTIVE_DATE_SUCCESS';
export const CREATE_REPORT_SET_EFFECTIVE_DATE_FAILURE =
  'CREATE_REPORT_SET_EFFECTIVE_DATE_FAILURE';

export const createReportSetClientId = (clientId) => {
  return {
    type: CREATE_REPORT_SET_CLIENT_ID,
    payload: {
      clientId
    }
  };
};

export const createReportSetEffectiveDate = (
  addressId,
  addressSlug,
  effectiveDate
) => {
  return {
    type: CREATE_REPORT_SET_EFFECTIVE_DATE,
    payload: { addressId, addressSlug, effectiveDate }
  };
};

export const createReportSetEffectiveDateSuccess = (data) => {
  return {
    type: CREATE_REPORT_SET_EFFECTIVE_DATE_SUCCESS,
    payload: { data }
  };
};

export const createReportSetEffectiveDateFailure = (data) => {
  return {
    type: CREATE_REPORT_SET_EFFECTIVE_DATE_FAILURE
  };
};
