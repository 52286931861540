import React from 'react';
import { CloseIcon } from 'src/lib/inline-svgs';
import { IconButton, Props as IconButtonProps } from '../IconButton';

export const CloseButton = (props: Omit<IconButtonProps, 'icon'>) => {
  return (
    <IconButton
      {...props}
      icon={<CloseIcon dataHcName={`${props.dataHcName}-icon`} height="20px" />}
    />
  );
};
