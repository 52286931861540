import { useEffect } from 'react';

export const useClickOutsideComponent = (ref, callback) => {
  const handleAllClicks = (e) => {
    const clickedOutside = ref.current && !ref.current.contains(e.target);
    if (clickedOutside) {
      callback();
    }
  };
  // Using document-level listener to identify clicks outside of the component
  useEffect(() => {
    document.addEventListener('mousedown', handleAllClicks);
    return () => {
      document.removeEventListener('mousedown', handleAllClicks);
    };
  }, []);
};
