import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './FilterCard.css';

interface Props {
  dataHcName: string;
  className?: string;
  label: string;
  info?: ReactNode;
  error?: string;
  bordered?: boolean;
  children: ReactNode;
  clearFilterButton?: ReactNode;
}
export const FilterCard = ({
  dataHcName,
  className,
  error,
  info,
  label,
  bordered,
  children,
  clearFilterButton
}: Props) => {
  return (
    <div
      className={classNames(styles.FilterCard, className, {
        [styles.bordered || '']: bordered
      })}
      data-hc-name={dataHcName}
    >
      <h4 className={styles.Title}>{label}</h4>
      {(error || info) && (
        <h5
          className={classNames(styles.Info, {
            [styles.error || '']: !!error
          })}
        >
          {error || info}
        </h5>
      )}
      {children}
      {clearFilterButton}
    </div>
  );
};
