import React from 'react';
import classNames from 'classnames';
import { useBrokerageName } from '../hocs/useBrokerageName';
import { BrokerageCredit as styles } from 'legacy/css-modules';

export const BrokerageCredit = ({ addressId, listingId, className }) => {
  const brokerageName = useBrokerageName(addressId, listingId);
  if (!brokerageName) return null;
  return (
    <div className={classNames(styles.BrokerageCredit, className)}>
      {`Courtesy of ${brokerageName || '--'}`}
    </div>
  );
};
