import { readableFileType, humanFileSize } from 'legacy/utils/files';

export const FORM_NAME = 'personalizationForm';
export const INCLUDE_GROUP_NAMES = {
  includeName: ['firstName', 'lastName'],
  includeEmail: ['email'],
  includePhone: ['phone'],
  includePhoto: ['photoUrl'],
  includeLicenseNumber: ['licenseNumber'],
  includeCompanyName: ['companyName'],
  includeAddressLine1: ['addressLine1'],
  includeAddressLine2: ['addressLine2'],
  includeCityStateZipcode: ['city', 'state', 'zipcode'],
  includeCompanyLogo: ['companyLogoUrl'],
  includeWebsite: ['websiteUrl']
};
export const FIELD_NAMES = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'photoUrl',
  'licenseNumber',
  'companyName',
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'zipcode',
  'companyLogoUrl',
  'websiteUrl',
  ...Object.keys(INCLUDE_GROUP_NAMES)
];

export const IMG_HEIGHT_MIN = 240;
export const IMG_SIZE_MAX = 5242880;
export const IMG_FILE_TYPES = ['image/png', 'image/jpeg'];

export const fileTypesToReadableList = (fileTypes) =>
  fileTypes.map(readableFileType).join(', ');

// This is a custom validator that is not integrated with redux-form
// because the form value is the url rather than the file itself
export const validateImg = (file, acceptedTypes, sizeMax, heightMin) => {
  // This validator returns a Promise to support async checks like image height
  return new Promise((resolve, reject) => {
    // Check types
    if (acceptedTypes.indexOf(file.type) === -1) {
      reject(Error(`File type is not supported.`));
    }
    // Check size
    if (file.size > sizeMax) {
      reject(
        Error(
          `Image file size should be no more than ${humanFileSize(sizeMax, 0)}.`
        )
      );
    }

    // Check height
    if (window.URL && window.URL.createObjectURL) {
      let img = document.createElement('img');
      img.onerror = (e) => {
        reject(
          Error(
            `Invalid image file. Please make sure the image is a valid ${fileTypesToReadableList(
              IMG_FILE_TYPES
            )}`
          )
        );
      };
      img.onload = () => {
        const height = img.naturalHeight || img.height;
        if (height && height < heightMin) {
          reject(Error(`Image should be at least ${heightMin}px in height.`));
        } else {
          // This needs to be moved if this is not the final check
          resolve(true);
        }
      };
      // SECURITY: @jnettleman
      // eslint-disable-next-line scanjs-rules/assign_to_src
      img.src = window.URL.createObjectURL(file);
    } else {
      resolve(true);
    }
  });
};
