import { RefObject } from 'react';

export const findOverflowParent = (
  el: HTMLElement | undefined | null
): HTMLElement | null | undefined => {
  if (el?.parentElement) {
    const parentStyle = getComputedStyle(el.parentElement);
    if (
      parentStyle.position !== 'static' &&
      parentStyle.overflow === 'hidden'
    ) {
      return el.parentElement;
    } else {
      return findOverflowParent(el.parentElement);
    }
  }
  return el?.parentElement;
};

export const isElementVisible = (
  el: Element,
  // Returns true if the entire object rect is visible
  fullyVisible?: boolean,
  // Rect passed in recusive calls
  recursiveRect?: DOMRect
): boolean => {
  if (!(el.parentNode instanceof Element)) return true;
  const parentRect = el.parentNode.getBoundingClientRect();
  const rect = recursiveRect || el.getBoundingClientRect();
  if (fullyVisible) {
    return (
      rect.top >= parentRect.top &&
      rect.left >= parentRect.left &&
      rect.bottom <= parentRect.bottom &&
      rect.right <= parentRect.right &&
      isElementVisible(el.parentNode, fullyVisible, rect)
    );
  } else {
    return (
      rect.bottom > parentRect.top &&
      rect.right > parentRect.left &&
      rect.top < parentRect.bottom &&
      rect.left < parentRect.right &&
      isElementVisible(el.parentNode, fullyVisible, rect)
    );
  }
};

export const overflowScrollIntoView = (
  refElement: RefObject<HTMLElement>,
  refContainer: RefObject<HTMLElement>
) => {
  if (
    refElement &&
    refElement.current &&
    refContainer &&
    refContainer.current
  ) {
    // scrolls the container so the element is visible in the overflow container
    const activeOffset = refElement.current.offsetTop;
    const activeHeight = refElement.current.clientHeight;
    const containerScroll = refContainer.current.scrollTop;
    const containerHeight = refContainer.current.clientHeight;
    if (activeOffset + activeHeight > containerScroll + containerHeight) {
      refContainer.current.scrollTop =
        activeOffset + activeHeight - containerHeight;
    } else if (containerScroll > activeOffset) {
      refContainer.current.scrollTop = activeOffset;
    }
  }
};
