import { createSelector } from 'reselect';
import get from 'lodash/get';
import forOwn from 'lodash/forOwn';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getCurrentQuery } from '@hc/redux-saga-router-plus/hclib/selectors';
import { filterSearchSortComps } from 'legacy/services/comps-filters';

import {
  AVM_KEY_RENTAL_HC,
  MAP_ID_BY_COMP_TYPE,
  COMP_ID_KEY,
  COMP_TYPE_DEFAULT,
  COMP_TYPE_RENTAL,
  LABEL_FOR_AVM_KEY,
  NUM_HC_SUGGESTED_COMPS
} from 'legacy/appstore/constants';
import { PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

import { abbrShortDollarsFormatter, pluralize } from 'legacy/utils/formatters';
import { buildMarker } from 'legacy/utils/maps';
import {
  FILTERS,
  isFilterSet,
  getAbsoluteFilterValueMapping
} from 'legacy/utils/filters';
import { filtersSavedThatCannotBeApplied } from 'legacy/utils/saved-filters';
import { isObject } from 'legacy/utils/utils';
import { getPropertyMarkerLabel } from 'legacy/utils/property-details';
import {
  DEFAULT_FIELD_ORDER,
  FIELDS
} from 'legacy/utils/comps-fields.utils.jsx';

import {
  getSubjectSelected,
  getSubjectDefault,
  getSubjectAdjusted,
  getSubjectRental,
  getSubjectLocationUnknown,
  getSubjectMarkerClassNames
} from 'selectors/subject';
import { getIsMobile } from 'selectors/match-media';
import { getPhotosByAddressId } from 'selectors/photos';
import {
  getPropertyDetails,
  getPropertyAllUserEdits
} from 'selectors/property';
import { getFeatureFlagsData } from 'selectors/feature-flags';
import {
  getCompsFiltersFromCompsState,
  getCompsSortFromCompsState,
  getCompsSortSelectedFromCompsState,
  getCompsAvailableIdsFromCompsState
} from 'selectors/comps-state';
import { getIsEffectiveDateReport } from './property-explorer.selectors';
import {
  getAvmSelected,
  getAvmValue,
  getAvmRentalSelected,
  getAvmRentalHc,
  getAvmSelectedKey,
  getAvmRentalSelectedKey
} from 'selectors/avms';
import { getIsReportEditable } from 'selectors/report-permissions';
import { getIsCurrentViewCompsSelectionPage } from 'selectors/navigation';
import {
  getPreferencesFiltersCompsFilterSetDefault,
  getPreferencesFiltersRentalCompsFilterSetDefault,
  getPreferencesTableColumnsRentalComps,
  getPreferencesTableColumnsComps
} from 'selectors/preferences';
import { getMapDrawPolygons } from 'selectors/map-draw';
import { getDateReportCreated } from 'selectors/report-data';

import SubjectIcon from 'legacy/components/SubjectIcon';

import homeIcon from 'legacy/assets/svg-inline/home.svg';

import { CompsTable as compactTableTheme } from 'legacy/css-modules';

const _getStateFn = (compType) =>
  compType === COMP_TYPE_RENTAL
    ? (state) => state.rentalComps
    : (state) => state.comps;

const _idListToObjList = (farm, idList, sortKey, sortOrder = 'desc') => {
  const collection = idList.map((compId) => get(farm, compId, {}));
  if (!sortKey) {
    return collection;
  } else {
    return orderBy(collection, sortKey, sortOrder);
  }
};

const _buildMarkers = ({
  subject,
  comps,
  selectedIds,
  labelKey = 'index',
  labelFormat = (v) => abbrShortDollarsFormatter(v),
  subjectMarkerClassNames,
  compType
}) => {
  const markers = [];
  // If valid subject marker
  if (
    get(subject, ['geoLocation', 'latitude'], null) &&
    get(subject, ['geoLocation', 'longitude'], null)
  ) {
    markers.push(
      buildMarker({
        className: subjectMarkerClassNames,
        property: subject,
        label: homeIcon,
        iconSize: 'small',
        isHomeStyle: true,
        excludeFromCluster: true,
        isSubject: true,
        compType
      })
    );
  }

  if (comps && comps.length) {
    comps.forEach((comp, i) => {
      const isSelectedStyle = selectedIds.indexOf(comp[COMP_ID_KEY]) > -1;
      markers.push(
        buildMarker({
          property: comp,
          isSelectedStyle,
          excludeFromCluster: isSelectedStyle,
          label:
            labelKey === 'index'
              ? i + 1
              : labelFormat(getPropertyMarkerLabel(comp)),
          iconSize: 'small',
          compType
        })
      );
    });
  }
  return markers;
};

export const getSharedCompsTags = (compType) =>
  createSelector(_getStateFn(compType), (compState) => compState.tags);

export const getSharedCompsTagFilter = (compType) =>
  createSelector(_getStateFn(compType), (compState) => compState.tagFilter);

export const getSharedCompsFilters = (compType) =>
  createSelector(_getStateFn(compType), getCompsFiltersFromCompsState);

export const getSharedCompsFiltersUpdatedFromUrl = (compType) =>
  createSelector(
    _getStateFn(compType),
    (compsState) => compsState.filtersUpdatedFromUrl
  );

export const getSharedCompsFiltersUpdatedFromPreferences = (compType) =>
  createSelector(
    _getStateFn(compType),
    (compsState) => compsState.filtersUpdatedFromPreferences
  );

export const getSharedCompsFiltersAppliedForMetadata = (compType) =>
  createSelector(getSharedCompsFilters(compType), (filters) => {
    const appliedFilters = {};
    for (let filterKey in filters) {
      const value = filters[filterKey];
      if (isFilterSet(filterKey, value)) {
        appliedFilters[filterKey] = value;
      }
    }
    return appliedFilters;
  });

export const getSharedCompsNoFiltersApplied = (compType) =>
  createSelector(getSharedCompsFilters(compType), (activeFilters) =>
    isEmpty(activeFilters)
  );

export const getSharedCompsSavedFiltersUnableToApply = (compType) =>
  createSelector(
    compType === COMP_TYPE_DEFAULT
      ? getPreferencesFiltersCompsFilterSetDefault
      : getPreferencesFiltersRentalCompsFilterSetDefault,
    getSubjectAdjusted,
    getSharedCompsFiltersUpdatedFromUser(compType),
    (filtersSaved, subject, adjustedFromUser) =>
      adjustedFromUser
        ? []
        : filtersSavedThatCannotBeApplied(filtersSaved, subject, compType)
  );

export const getSharedCompsFilterKeys = (compType) =>
  createSelector(getSharedCompsFilters(compType), (filters) =>
    Object.keys(filters)
  );

export const getSharedCompsSort = (compType) =>
  createSelector(_getStateFn(compType), getCompsSortFromCompsState);

export const getSharedCompsSortSelection = (compType) =>
  createSelector(_getStateFn(compType), getCompsSortSelectedFromCompsState);

export const getSharedCompsListViewType = (compType) =>
  createSelector(
    _getStateFn(compType),
    (compsState) => compsState.compsListViewType
  );

export const getSharedCompsFarm = (compType) =>
  createSelector(
    _getStateFn(compType),
    getPropertyAllUserEdits,
    (compsState, propertyEdits) => {
      const farm = { ...compsState.farm };
      for (let addressId in propertyEdits) {
        if (farm[addressId]) {
          farm[addressId] = {
            ...farm[addressId],
            ...propertyEdits[addressId]
          };
        }
      }
      return farm;
    }
  );

export const getSharedCompsFarmRaw = (compType) =>
  createSelector(_getStateFn(compType), (compsState) => compsState.farm);

export const getSharedCompsFarmList = (compType) =>
  createSelector(
    // Gets the farm list in the original JSON structure
    getSharedCompsFarm(compType),
    (farm) => _idListToObjList(farm, Object.keys(farm))
  );

export const getSharedCompsFarmExists = (compType) =>
  createSelector(getSharedCompsFarm(compType), (farm) => !isEmpty(farm));

const SIMILARITY_VALUE = {
  low: 1,
  moderate: 2,
  high: 3
};

export const getSharedCompsFarmListRaw = (compType) =>
  createSelector(
    // Gets the farm list in the original JSON structure
    getSharedCompsFarmRaw(compType),
    getAvmValue,
    (farm, avmValue) => {
      const unsorted = _idListToObjList(farm, Object.keys(farm));
      const dateKey =
        compType === COMP_TYPE_RENTAL ? 'leasedDate' : 'salesDate';
      return unsorted &&
        unsorted[0] &&
        unsorted[0].hasOwnProperty('similarityScoreAdjusted')
        ? orderBy(unsorted, ['similarityScoreAdjusted'], ['desc']).map(
            (p, i) => ({
              ...p,
              comp: i + 1
            })
          )
        : orderBy(
            unsorted,
            [
              (c) => SIMILARITY_VALUE[c.similarityLevel],
              dateKey,
              (c) => Math.abs(c.currentValue - avmValue)
            ],
            ['desc', 'desc', 'asc']
          ).map((p, i) => ({
            ...p,
            comp: i + 1
          }));
    }
  );

export const getSharedCompsActiveListingsProperties = (compType) =>
  createSelector(getSharedCompsFarmListRaw(compType), (farmList) =>
    farmList.filter(
      (comp) =>
        comp.propertyStatus === 'Active' || comp.listingStatus === 'Active'
    )
  );

const _filterBySaleLeaseDate = (
  compType,
  farmList,
  dayLimit,
  propertyLimit = 10
) => {
  const today = moment();
  const DATE_FORMAT = 'YYYY-MM-DD';
  return farmList
    .filter((comp) => {
      if (
        compType === COMP_TYPE_RENTAL &&
        comp.leasedDate &&
        today.diff(moment(comp.leasedDate, DATE_FORMAT), 'days') <= dayLimit
      ) {
        return true;
      } else if (
        comp.salesDate &&
        today.diff(moment(comp.salesDate, DATE_FORMAT), 'days') <= dayLimit
      ) {
        return true;
      }
      return false;
    })
    .slice(0, propertyLimit);
};

export const getSharedCompsRecentSimilarProperties = (compType) =>
  createSelector(getSharedCompsFarmListRaw(compType), (farmList) =>
    _filterBySaleLeaseDate(compType, farmList, 365)
  );

export const getSharedCompsHistoricalSimilarProperties = (compType) =>
  createSelector(
    getSharedCompsFarmListRaw(compType),
    (farmList) => _filterBySaleLeaseDate(compType, farmList, 365 * 4 + 1) // +1 for leap year
  );

export const getSharedCompsSearch = (compType) =>
  createSelector(_getStateFn(compType), (compsState) => compsState.search);

export const getSharedCompsAvailableIds = (compType) =>
  createSelector(
    // Gets the list of available comps ids
    _getStateFn(compType),
    getCompsAvailableIdsFromCompsState
  );

export const getSharedCompsAvailableProperties = (compType) =>
  createSelector(
    // Gets the list of available comps objects
    getSharedCompsFarm(compType),
    getSharedCompsAvailableIds(compType),
    (farm, idList) => {
      return _idListToObjList(farm, idList);
    }
  );

export const getSharedCompsSelectedIds = (compType) =>
  createSelector(
    // Gets the list of user selected comps ids
    _getStateFn(compType),
    getSharedCompsFarm(compType),
    (compsState, farm) => compsState.selected.filter((i) => !!farm[i])
  );

export const getSharedHasUserSelectedComps = (compType) =>
  createSelector(
    getSharedCompsSelectedIds(compType),
    (compsIdsUserSelected) => compsIdsUserSelected.length > 0
  );

export const getSharedCompsSuggestedIds = (compType) =>
  createSelector(
    getSubjectAdjusted,
    getSharedCompsFarmListRaw(compType),
    (subject, farmList) => {
      if (farmList && farmList.length) {
        let filteredClosed = farmList.filter((c) => {
          if (c.propertyStatus !== 'Sold' && c.propertyStatus !== 'Leased') {
            return false;
          }
          const today = moment();
          const date = c.isRentalComp ? c.leasedDate : c.salesDate;
          return (
            today.diff(moment(date, 'YYYY-MM-DD'), 'days') <= 6 * 30 &&
            c.propertyType === subject.propertyType &&
            c.state === subject.state
          );
        });

        if (filteredClosed.length < 10) {
          filteredClosed = farmList.filter((c) => {
            if (c.propertyStatus !== 'Sold' && c.propertyStatus !== 'Leased') {
              return false;
            }
            return (
              c.propertyType === subject.propertyType &&
              c.state === subject.state
            );
          });
        }

        let filteredActive = farmList.filter((c) => {
          if (c.propertyStatus === 'Sold' || c.propertyStatus === 'Leased') {
            return false;
          }
          return (
            c.propertyType === subject.propertyType && c.state === subject.state
          );
        });

        const comps = orderBy(
          [...filteredClosed, ...filteredActive],
          ['similarityScoreAdjusted', 'distanceMiles'],
          ['desc', 'asc']
        );

        return comps
          .slice(0, NUM_HC_SUGGESTED_COMPS)
          .map((c) => c[COMP_ID_KEY]);
      }
      return [];
    }
  );

export const getSharedCompsSelectedOrSuggestedIds = (compType) =>
  createSelector(
    getSharedCompsSelectedIds(compType),
    getSharedCompsSuggestedIds(compType),
    (idListSelected, idListSuggested) => {
      return idListSelected && idListSelected.length
        ? idListSelected
        : idListSuggested;
    }
  );

export const getSharedCompsSelectedOrSuggestedProperties = (compType) =>
  createSelector(
    getSharedCompsFarm(compType),
    getSharedCompsSelectedOrSuggestedIds(compType),
    (farm, idList) => {
      return _idListToObjList(
        farm,
        idList,
        ['similarityScoreAdjusted', 'distanceMiles'],
        ['desc', 'asc']
      );
    }
  );

export const getSharedCompsSelectedOrSuggestedIdsOrderedBySimilarity = (
  compType
) =>
  createSelector(
    getSharedCompsSelectedOrSuggestedProperties(compType),
    (collection) => collection.map((c) => c.addressId)
  );

export const getSharedCompsSelected = (compType) =>
  createSelector(
    getSharedCompsFarm(compType),
    getSharedCompsSelectedIds(compType),
    (farm, idList) => _idListToObjList(farm, idList)
  );

export const getSharedCompsSuggestedProperties = (compType) =>
  createSelector(
    getSharedCompsFarm(compType),
    getSharedCompsSuggestedIds(compType),
    (farm, idList) =>
      _idListToObjList(
        farm,
        idList,
        ['similarityScoreAdjusted', 'similarityScore'],
        ['desc', 'desc']
      )
  );

export const getSharedCompsSelectedMapping = (compType) =>
  createSelector(getSharedCompsSelected(compType), (compsSelected) =>
    keyBy(compsSelected, (comp) => comp[COMP_ID_KEY])
  );

export const getSharedCompsFiltersUpdatedFromUser = (compType) =>
  createSelector(
    _getStateFn(compType),
    (compsState) => compsState.filtersUpdatedFromUser
  );

const _getCompsMapMarkerSubject = (subjectVariant) => (state) => {
  let subject;
  let avm =
    subjectVariant === 'rental'
      ? getAvmRentalSelected(state)
      : getAvmSelected(state);
  let avmKeySelected = getAvmSelectedKey(state);
  if (subjectVariant === 'adjusted') {
    subject = getSubjectAdjusted(state);
  } else if (subjectVariant === 'default') {
    subject = getSubjectDefault(state);
  } else if (subjectVariant === 'rental') {
    avmKeySelected = getAvmRentalSelectedKey(state);
    subject = getSubjectRental(state);
    // Fall back to hc value for map markers
    if (avmKeySelected !== AVM_KEY_RENTAL_HC && !avm.value) {
      avm = getAvmRentalHc(state);
      avmKeySelected = AVM_KEY_RENTAL_HC;
    }
  } else {
    subject = getSubjectSelected(state);
  }

  const photos = getPhotosByAddressId(subject.addressId)(state);
  return {
    ...subject,
    thumbnail: photos[0],
    photos: photos,
    valueLabel: LABEL_FOR_AVM_KEY[avmKeySelected]
  };
};

export const getSharedCompsMapMarkersSelected = (compType) => (labelKey) =>
  createSelector(
    _getCompsMapMarkerSubject(
      compType === COMP_TYPE_DEFAULT ? 'selected' : 'rental'
    ),
    getSharedCompsSelectedOrSuggestedIds(compType),
    getSharedCompsSelectedOrSuggestedProperties(compType),
    getSubjectMarkerClassNames,
    (subject, selectedIds, comps, subjectMarkerClassNames) =>
      _buildMarkers({
        subject,
        comps,
        selectedIds,
        subjectMarkerClassNames,
        compType
      })
  );

export const getSharedCompsMapMarkersUserSelected = (compType) => (labelKey) =>
  createSelector(
    _getCompsMapMarkerSubject(
      compType === COMP_TYPE_DEFAULT ? 'adjusted' : 'rental'
    ),
    getSharedCompsSelected(compType),
    getSharedCompsSelectedIds(compType),
    getSubjectMarkerClassNames,
    (subject, comps, selectedIds, subjectMarkerClassNames) =>
      _buildMarkers({
        subject,
        comps,
        selectedIds,
        labelKey,
        subjectMarkerClassNames,
        compType
      })
  );

export const getSharedCompsMapMarkersUserSelectedAndAvailable =
  (compType) => (labelKey) =>
    createSelector(
      _getCompsMapMarkerSubject(
        compType === COMP_TYPE_DEFAULT ? 'adjusted' : 'rental'
      ),
      getSharedCompsSelectedIds(compType),
      getSharedCompsAvailableProperties(compType),
      getSubjectMarkerClassNames,
      (subject, selectedIds, comps, subjectMarkerClassNames) =>
        _buildMarkers({
          subject,
          comps,
          selectedIds,
          labelKey,
          subjectMarkerClassNames,
          compType
        })
    );

export const getSharedFocusedCompId = (compType) =>
  createSelector(_getStateFn(compType), (compsState) => compsState.focused);

const _getSharedCompFromFarm = (compType, addressId, state) => {
  const farm = getSharedCompsFarm(compType)(state);
  let comp = farm[addressId];
  return comp || {};
};

export const getSharedCompByAddressId =
  (compType) => (addressId) => (state) => {
    const selected = getSharedCompsSelectedIds(compType)(state);
    const comp = _getSharedCompFromFarm(compType, addressId, state);
    if (comp) {
      const sourceHC = getPropertyDetails(
        addressId,
        PROPERTY_DATA_SOURCES.HC
      )(state);
      const sourceMLS = getPropertyDetails(
        addressId,
        PROPERTY_DATA_SOURCES.MLS
      )(state);
      const sourcePR = getPropertyDetails(
        addressId,
        PROPERTY_DATA_SOURCES.PR
      )(state);
      // TODO: Remove this when we move to graphql
      const {
        hoaAssociation,
        hoaFee,
        hoaFeeIncludes,
        hoaFeeFrequency,
        cumulativeDaysOnMarket
      } = sourceMLS;
      const { taxHistory, taxYear, taxAmount, zoning } = sourcePR;
      const newData = {
        taxHistory,
        taxYear,
        taxAmount,
        hoaAssociation,
        hoaFee,
        hoaFeeIncludes,
        hoaFeeFrequency,
        zoning,
        cumulativeDaysOnMarket
      };
      return {
        ...sourcePR,
        ...sourceMLS,
        ...sourceHC,
        ...comp,
        ...newData,
        selected: selected.indexOf(addressId) > -1
      };
    }
  };

export const getSharedFocusedComp = (compType) => (state) => {
  const focused = getSharedFocusedCompId(compType)(state);
  if (focused) {
    let comp = getSharedCompByAddressId(compType)(focused)(state);
    return comp || {};
  }
  return {};
};

export const getSharedCompIdToScroll = (compType) =>
  createSelector(
    _getStateFn(compType),
    (compsState) => compsState.listScrolledToCompId
  );

export const getCompsIsAtSelectedLimit = (compType) =>
  createSelector(
    getSharedCompsSelectedIds(compType),
    getFeatureFlagsData,
    (compsSelectedIds, featureFlags) => {
      return compsSelectedIds.length >= featureFlags['increased-comp-limit'];
    }
  );

export const getSharedCompsIsSelectedById = (compType) => (compId) =>
  createSelector(
    getSharedCompsSelectedIds(compType),
    (compsSelectedIds) => compsSelectedIds.indexOf(compId) > -1
  );

export const getSharedCompsIsEditableById = (compType) => (compId) =>
  createSelector(
    getIsReportEditable,
    getSharedCompsIsSelectedById(compType)(compId),
    (isReportEditable, isCompSelected) => isReportEditable && isCompSelected
  );

export const getSharedIsSelectedCompLimitReached = (compType) =>
  createSelector(
    getSharedCompsSelectedIds(compType),
    getFeatureFlagsData,
    (compsSelectedIds, featureFlags) =>
      compsSelectedIds.length >= featureFlags['increased-comp-limit']
  );

export const getSharedCompsAllowSelection = (compType) =>
  createSelector(
    getIsReportEditable,
    getSharedHasUserSelectedComps(compType),
    getIsCurrentViewCompsSelectionPage,
    (isReportEditable, hasUserSelectedComps, isCompsSelectionPage) => {
      return isReportEditable && (hasUserSelectedComps || isCompsSelectionPage);
    }
  );

export const getSharedCompsAddressIdsAll = (compType) =>
  createSelector(getSharedCompsFarm(compType), (farm) => {
    let addressIds = [];
    forOwn(farm, (comp) => {
      addressIds.push(comp.addressId);
    });
    return addressIds;
  });

export const getSharedCompsAddressIdToCerberusIdMapping = (compType) =>
  createSelector(getSharedCompsFarm(compType), (farm) => {
    const mapping = {};
    for (const addressId in farm) {
      if (farm[addressId].cerberusId) {
        mapping[addressId] = farm[addressId].cerberusId.replace('e_', '');
      }
    }
    return mapping;
  });

export const getSharedCompsCerberusIdToAddressIdMapping = (compType) =>
  createSelector(getSharedCompsFarm(compType), (farm) => {
    const mapping = {};
    for (const addressId in farm) {
      if (farm[addressId].cerberusId) {
        mapping[farm[addressId].cerberusId.replace('e_', '')] = addressId;
      }
    }
    return mapping;
  });

export const getSharedCompsGeoLocationAll = (compType) =>
  createSelector(getSharedCompsFarm(compType), (farm) => {
    let geoMapping = {};
    forOwn(farm, (comp) => {
      geoMapping[comp.addressId] = {
        ...comp.geoLocation,
        geoPrecision: comp.geoPrecision
      };
    });
    return geoMapping;
  });

export const getSharedCompsSelectedOrSuggestedMessage = (compType) =>
  createSelector(
    getSharedCompsSelectedOrSuggestedIds(compType),
    getSharedHasUserSelectedComps(compType),
    getSubjectLocationUnknown,
    (addressIds, hasUserSelectedComps, locationUnknown) => {
      const labelModifier = compType === COMP_TYPE_RENTAL ? 'rental ' : '';
      return !hasUserSelectedComps
        ? locationUnknown
          ? null
          : `HouseCanary suggested ${labelModifier}comps`
        : `You have selected ${addressIds.length} ${labelModifier}${pluralize(
            'comps',
            addressIds.length
          )}`;
    }
  );

export const getSharedCompsFarmUpdatedAt = (compType) =>
  createSelector(
    _getStateFn(compType),
    (compsState) => compsState.farmUpdatedAt
  );

export const getSharedCompsFarmRefreshedAt = (compType) =>
  createSelector(
    _getStateFn(compType),
    getDateReportCreated,
    (compsState, dateCreated) => compsState.farmRefreshedAt || dateCreated
  );

export const getSharedCompsFilterControls = (filterControls) => {
  // Accepts a list of filter keys and/or filter control overrides and builds a complete filter control list
  return filterControls.map((filter) => {
    const filterDef = isObject(filter) ? FILTERS[filter.key] : FILTERS[filter];
    if (isObject(filter) && filterDef) {
      let filterModified = { ...filterDef };
      for (let key in filter) {
        if (isObject(filter[key])) {
          filterModified[key] = {
            ...filterModified[key],
            ...filter[key]
          };
        } else {
          filterModified[key] = filter[key];
        }
      }
      return filterModified;
    } else if (filterDef) {
      return filterDef;
    }
  });
};

export const getSharedCompsFiltersToSave = (compType) =>
  createSelector(
    getSharedCompsFilters(compType),
    compType === COMP_TYPE_DEFAULT ? getSubjectAdjusted : getSubjectRental,
    (filterValues, subject) => {
      return [];
    }
  );

export const getSharedCompsNewDefaultFarmDistance = (compType) => {
  return createSelector(
    getSharedCompsFiltersAppliedForMetadata(compType),
    getMapDrawPolygons(MAP_ID_BY_COMP_TYPE[compType]),
    (appliedFilters, polygons) => {
      if (!isNaN(appliedFilters.distance) && (!polygons || !polygons.length)) {
        return appliedFilters.distance;
      } else {
        return false;
      }
    }
  );
};

export const getSharedCompsTableColumnsRaw = (compType) =>
  createSelector(
    getIsMobile,
    compType === COMP_TYPE_RENTAL
      ? getPreferencesTableColumnsRentalComps
      : getPreferencesTableColumnsComps,
    getFeatureFlagsData,
    getIsEffectiveDateReport,
    (isMobile, preferences, featureFlags, isEffectiveDate) => {
      return DEFAULT_FIELD_ORDER.map((attribute) => ({
        ...FIELDS[attribute],
        field: attribute
      })).filter(
        (c) =>
          c &&
          (!c.compType || c.compType === compType) &&
          (!c.featureFlag || featureFlags[c.featureFlag])
      );
    }
  );

export const getSharedCompsDataTableColumns = (compType) =>
  createSelector(
    getIsMobile,
    compType === COMP_TYPE_RENTAL
      ? getPreferencesTableColumnsRentalComps
      : getPreferencesTableColumnsComps,
    (isMobile, preferences) => {
      const columns =
        preferences && preferences.order && preferences.order.length
          ? [
              'address',
              ...preferences.order.filter((p) => FIELDS.hasOwnProperty(p))
            ]
          : DEFAULT_FIELD_ORDER;
      return columns
        .map((attribute) => ({ ...FIELDS[attribute] }))
        .filter((c) => c && (!c.compType || c.compType === compType));
    }
  );

export const getSharedCompSortForDataTable = (compType) =>
  createSelector(
    getSharedCompsSort(compType),
    getSharedCompsSortSelection(compType),
    getCurrentQuery,
    (sort, sortSelected, query) => {
      return query.viewSelected ? sortSelected : sort;
    }
  );

export const getSharedCompsTableColumnsConfigurable = (compType) =>
  createSelector(getSharedCompsTableColumnsRaw(compType), (tableColumns) =>
    tableColumns.filter((c) => c.configurable)
  );

export const getSharedCompsTableColumnsActiveInactive = (
  compType,
  configurable
) =>
  createSelector(
    configurable
      ? getSharedCompsTableColumnsConfigurable(compType)
      : getSharedCompsTableColumnsRaw(compType),
    compType === COMP_TYPE_RENTAL
      ? getPreferencesTableColumnsRentalComps
      : getPreferencesTableColumnsComps,
    (tableColumns, prefs) => {
      const columnMapping = {};
      tableColumns.forEach((c) => {
        columnMapping[c.field] = c;
      });
      const active = [];
      const inactive = [];
      const colsAdded = {};
      const colOrder = get(prefs, 'order', []);
      const colInactive = get(prefs, 'inactive', {});
      colOrder.forEach((attribute) => {
        const column = columnMapping[attribute];
        if (column && !colsAdded[attribute]) {
          if (!colInactive[attribute]) {
            active.push(column);
          } else {
            inactive.push(column);
          }
          colsAdded[attribute] = true;
        }
      });

      tableColumns.forEach((c) => {
        if (!colsAdded[c.field]) {
          if (!colInactive[c.field]) {
            if (c.field === 'address') {
              // Address should always be first
              active.unshift(c);
            } else {
              active.push(c);
            }
          } else {
            inactive.push(c);
          }
          colsAdded[c.field] = true;
        }
      });
      return { active, inactive };
    }
  );

export const getSharedCompsTableColumns = (compType) =>
  createSelector(
    getSharedCompsTableColumnsActiveInactive(compType),
    (tableColumns) => tableColumns.active
  );

export const getSharedCompsDataTableData = (compType) =>
  createSelector(
    compType === COMP_TYPE_RENTAL ? getSubjectRental : getSubjectAdjusted,
    getSharedCompsSelected(compType),
    getSharedCompsAvailableProperties(compType),
    getSharedCompsSelectedMapping(compType),
    getSharedCompsListViewType(compType),
    getCurrentQuery,
    getFeatureFlagsData,
    (
      subject,
      compsSelected,
      compsAvailable,
      selected,
      size,
      query,
      featureFlags
    ) => {
      const isShowingSelected = query.viewSelected;
      const data = [
        {
          key: `table-subject`,
          fixed: true,
          fixedOffset: size === 'compact' ? 38 : 42,
          disabled: true,
          SelectCellContent: SubjectIcon,
          className: compactTableTheme.SubjectRow,
          data: {
            ...subject,
            address: {
              streetAddress: subject.streetAddress,
              unit: subject.unit,
              unitDesignator: subject.unitDesignator,
              city: subject.city,
              state: subject.state,
              zipcode: subject.zipcode
            }
          }
        }
      ].concat(
        (isShowingSelected ? compsSelected : compsAvailable).map((comp, i) => {
          const {
            addressId,
            city,
            similarityLevel,
            similarityLevelAdjusted,
            similarityScore,
            similarityScoreAdjusted,
            state,
            streetAddress,
            unit,
            unitDesignator,
            zipcode
          } = comp;
          return {
            key: `table-${addressId}`,
            selected: !!selected[addressId],
            className: i % 2 ? compactTableTheme.Even : compactTableTheme.Odd,
            disabled:
              !selected[addressId] &&
              compsSelected.length >= featureFlags['increased-comp-limit'],
            data: {
              address: {
                streetAddress,
                unit,
                unitDesignator,
                city,
                state,
                zipcode
              },
              similarity: {
                similarityLevel,
                similarityLevelAdjusted,
                similarityScore,
                similarityScoreAdjusted
              },
              ...comp
            }
          };
        })
      );
      return data;
    }
  );

export const getSharedCompsKeywordSearch = (compType) =>
  createSelector(
    _getStateFn(compType),
    (compsState) => compsState.keywordSearch
  );

export const getSharedCompsKeywordSearchStatus = (compType) =>
  createSelector(
    getSharedCompsKeywordSearch(compType),
    (keywordSearchState) => keywordSearchState.status
  );

export const getSharedCompsKeywordSearchKeywords = (compType) =>
  createSelector(
    _getStateFn(compType),
    (compsState) => compsState.keywordSearch.keywords
  );

export const getSharedCompsKeywordSearchFeatureSupported = (compType) =>
  createSelector(
    _getStateFn(compType),
    (compsState) => compsState.keywordSearch.featureSupported
  );

export const getSharedCompsKeywordMatchesByAddressId = (compType) =>
  createSelector(
    _getStateFn(compType),
    (compsState) => compsState.keywordSearch.data.matchesByAddressId
  );

export const getSharedCompsKeywordMatches = (compType) =>
  createSelector(
    getSharedCompsFarm(compType),
    getSharedCompsKeywordMatchesByAddressId(compType),
    getSharedCompsFilters(compType),
    getSharedCompsSearch(compType),
    getSharedCompsSort(compType),
    (farm, matchesByAddressId, filters, search, sort) => {
      const matches = {};
      Object.keys(matchesByAddressId || {}).forEach((addressId) => {
        if (farm[addressId]) {
          matches[addressId] = farm[addressId];
        }
      });
      return filterSearchSortComps(
        matches,
        getAbsoluteFilterValueMapping(filters),
        search,
        sort
      ).map((addressId) => farm[addressId]);
    }
  );

export const getSharedCompsShowOnMap = (compType) =>
  createSelector(_getStateFn(compType), (compState) => compState.showOnMap);
