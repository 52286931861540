import React, { useState, useEffect } from 'react';

import { AlertIcon } from '../../inline-svgs';
import { formatMoney, unformat } from '../../utils';

import styles from './ValueSelectorInput.css';

interface Props {
  onChange: (inputtedValue: string) => void;
  maxVal: number | null;
  minVal: number | null;
  dataHcName: string;
  userValue: number | null;
}

const EMPTY_PLACEHOLDER = '$';

export const ValueSelectorInput = ({
  onChange,
  minVal,
  maxVal,
  dataHcName,
  userValue
}: Props) => {
  const [value, setValue] = useState(
    userValue ? formatMoney(userValue) : EMPTY_PLACEHOLDER
  );
  const [valueOutsideRange, setValueOutsideRange] = useState(false);

  useEffect(() => {
    const isOutsideRange =
      userValue !== null &&
      maxVal !== null &&
      minVal !== null &&
      unformat(value) === unformat(userValue.toString()) &&
      (userValue > maxVal || userValue < minVal);
    if (isOutsideRange) {
      setValueOutsideRange(true);
    }
  }, [userValue, valueOutsideRange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputtedValue = event.target.value;
    const inputValInt = parseInt(inputtedValue);
    setValue(inputtedValue);
    onChange(inputtedValue);
    const isOutsideRange =
      maxVal !== null &&
      minVal !== null &&
      !isNaN(inputValInt) &&
      (inputValInt > maxVal || inputValInt < minVal);
    const isInsideRange =
      !inputtedValue.length ||
      (maxVal !== null &&
        minVal !== null &&
        inputValInt < maxVal &&
        inputValInt > minVal);

    if (isOutsideRange) {
      setValueOutsideRange(true);
    }
    if (isInsideRange) {
      setValueOutsideRange(false);
    }
  };

  const handleBlur = () => {
    value.length
      ? setValue(formatMoney(unformat(value)))
      : setValue(EMPTY_PLACEHOLDER);
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value.replace('$', ''));
  };

  return (
    <>
      {valueOutsideRange && (
        <AlertIcon
          dataHcName={`${dataHcName}-alert-icon`}
          className={styles.AlertIcon}
        />
      )}
      <input
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={styles.Input}
      />
      {valueOutsideRange && (
        <span
          className={styles.WarningText}
          data-hc-name={`${dataHcName}-outside-range-text`}
        >
          Your price is outside HouseCanary’s predicted value range. Are you
          sure?
        </span>
      )}
    </>
  );
};
