import { connect } from 'react-redux';

import {
  COMP_TYPE_RENTAL,
  PROPERTY_TYPE_MLS_LISTING,
  PROPERTY_TYPE_SEARCH_RESULT
} from 'legacy/appstore/constants';

import { getCompsIsRentalContext } from 'selectors/comps-context';
import { getPropertyBasedOnContext } from 'selectors/property-context';

import PropertyStatusChip from 'legacy/components/PropertyStatusChip';

const mapStateToProps = (state, ownProps) => {
  const { addressId, propertyContext } = ownProps;
  // TODO: replace this with a property selector when state is refactored
  const property = getPropertyBasedOnContext(state, propertyContext, addressId);
  const isRentalContext = getCompsIsRentalContext(state);
  const isSearchProperty =
    propertyContext === PROPERTY_TYPE_MLS_LISTING ||
    propertyContext === PROPERTY_TYPE_SEARCH_RESULT;
  const propertyStatus = isSearchProperty
    ? property.normalizedListingStatus
    : isRentalContext || propertyContext === COMP_TYPE_RENTAL
    ? property.propertyStatusRental
    : property.propertyStatus;
  return {
    price: isSearchProperty
      ? property.lastListPrice || property.listPrice
      : isRentalContext || propertyContext === COMP_TYPE_RENTAL
      ? property.lastListPriceRental
      : property.lastListPrice,
    visible: !propertyStatus || !['Sold', 'Leased'].includes(propertyStatus),
    propertyStatus
  };
};

export default connect(mapStateToProps)(PropertyStatusChip);
