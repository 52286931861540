import { EVENTS } from 'legacy/appstore/events';
import {
  MAP_ID_REPORT,
  MAP_CONTROL_TYPE_HEATMAP
} from 'legacy/appstore/constants';

import { getPropsIsHeaderMapShowingControls } from 'selectors/analytics';

export const REPORT_TOGGLE_HEADER_MAP_CONTROLS =
  'REPORT_TOGGLE_HEADER_MAP_CONTROLS';
export const REPORT_ACTIVE_MAP_LAYERS = 'REPORT_ACTIVE_MAP_LAYERS';
export const REPORT_SCROLL_TO_SECTION = 'REPORT_SCROLL_TO_SECTION';
export const REPORT_CHANGE_FORECAST_AREA_TYPE =
  'REPORT_CHANGE_FORECAST_AREA_TYPE';
export const REPORT_UPDATE_COMMENT = 'REPORT_UPDATE_COMMENT';
export const REPORT_EDIT_SUBJECT = 'REPORT_EDIT_SUBJECT';

export function reportToggleHeaderMapControls() {
  return {
    type: REPORT_TOGGLE_HEADER_MAP_CONTROLS,
    meta: {
      analytics: {
        event: EVENTS.MAP_CONTROL_PANEL_TOGGLE,
        props: {
          map_id: MAP_ID_REPORT,
          control_type: MAP_CONTROL_TYPE_HEATMAP
        },
        propsSelector: getPropsIsHeaderMapShowingControls
      }
    }
  };
}

export function reportActiveMapLayersChanged(layerIds) {
  return {
    type: REPORT_ACTIVE_MAP_LAYERS,
    payload: layerIds,
    meta: {
      analytics: {
        event: EVENTS.MAP_HEATMAP_LAYER,
        props: {
          map_id: MAP_ID_REPORT,
          layer_ids: layerIds
        }
      }
    }
  };
}

export function reportScrollToSection(section) {
  return {
    type: REPORT_SCROLL_TO_SECTION,
    payload: { section }
  };
}

export const reportChangeForecastAreaType = (areaType) => ({
  type: REPORT_CHANGE_FORECAST_AREA_TYPE,
  payload: { areaType },
  meta: {
    analytics: {
      event: EVENTS.REPORT_CHANGE_FORECAST_AREA_TYPE,
      props: {
        area_type: areaType
      }
    }
  }
});

export const reportUpdateComment = (commentValue) => ({
  type: REPORT_UPDATE_COMMENT,
  payload: { commentValue },
  meta: {
    analytics: {
      event: EVENTS.REPORT_UPDATE_COMMENT,
      props: {
        comment: commentValue
      }
    }
  },
  router: {
    options: { inherit: true, replace: true },
    query: { commentModal: undefined }
  }
});
