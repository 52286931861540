import { Personalization } from '../types';

export const PERSONALIZATION_REQUIRED = 'huell/PERSONALIZATION_REQUIRED';
export const PERSONALIZATION_FETCH = 'huell/PERSONALIZATION_FETCH';
export const PERSONALIZATION_FETCH_SUCCESS =
  'huell/PERSONALIZATION_FETCH_SUCCESS';
export const PERSONALIZATION_FETCH_ERROR = 'huell/PERSONALIZATION_FETCH_ERROR';

export interface PersonalizationRequiredAction {
  type: typeof PERSONALIZATION_REQUIRED;
}
export const personalizationRequired = (): PersonalizationRequiredAction => {
  return {
    type: PERSONALIZATION_REQUIRED
  };
};

export interface PersonalizationFetchAction {
  type: typeof PERSONALIZATION_FETCH;
}
export const personalizationFetch = (): PersonalizationFetchAction => {
  return {
    type: PERSONALIZATION_FETCH
  };
};

export interface PersonalizationFetchSuccessAction {
  type: typeof PERSONALIZATION_FETCH_SUCCESS;
  payload: {
    personalization: Personalization | null;
  };
}
export const personalizationFetchSuccess = (
  personalization: Personalization | null
): PersonalizationFetchSuccessAction => {
  return {
    type: PERSONALIZATION_FETCH_SUCCESS,
    payload: { personalization }
  };
};

export interface PersonalizationFetchErrorAction {
  type: typeof PERSONALIZATION_FETCH_ERROR;
}
export const personalizationFetchError =
  (): PersonalizationFetchErrorAction => {
    return {
      type: PERSONALIZATION_FETCH_ERROR
    };
  };

export type PersonalizationActions =
  | PersonalizationRequiredAction
  | PersonalizationFetchAction
  | PersonalizationFetchSuccessAction
  | PersonalizationFetchErrorAction;
