import React from 'react';
import { render } from 'react-dom';
// IMPORTANT: This is the ONLY file outside of "/legacy" that should import ANYTHING from the /legacy directory
// @ts-ignore - Remove this when we aren't importing from legacy
import App from '../legacy/App';
import { ApolloProvider } from '@apollo/client';
import { client } from 'src/lib/property-lookup/api';
import { ReportAPIProviders } from 'src/lib/report-api/providers/ReportAPIProviders';

(() => {
  render(
    <ApolloProvider client={client}>
      <ReportAPIProviders>
        <App />
      </ReportAPIProviders>
    </ApolloProvider>,
    document.getElementById('react-root')
  );
})();
