import { put, select } from 'redux-saga/effects';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { getUserIsConfirmed } from 'selectors/account-details';
import { VIEWS } from 'legacy/routes/constants';

export function* routeSelectPackage() {
  const userIsConfirmed = yield select(getUserIsConfirmed);
  if (userIsConfirmed) {
    yield put(routeChange({ view: VIEWS.SEARCH }));
  }
}
