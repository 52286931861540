import { CompFields } from '../../types/CompFields';

export const BUTTON_VISIBILITY_CHANGE = `CFB_BUTTON_VISIBILITY_CHANGE`;
export const OVERFLOW_VISIBILITY_CHANGE = `CFB_OVERFLOW_VISIBILITY_CHANGE`;
export const OVERFLOW_BUTTON_VISIBILITY_CHANGE = `CFB_OVERFLOW_BUTTON_VISIBILITY_CHANGE`;
export const OVERFLOW_PORTAL_MOUNTED = `CFB_OVERFLOW_PORTAL_MOUNTED`;
// Actions
interface CompFilterButtonVisibilityChangeAction {
  type: typeof BUTTON_VISIBILITY_CHANGE;
  payload: {
    compField: CompFields;
    visible: boolean;
  };
}
export const compFilterButtonVisibilityChange = (
  compField: CompFields,
  visible: boolean
): CompFilterButtonVisibilityChangeAction => {
  return {
    type: BUTTON_VISIBILITY_CHANGE,
    payload: { compField, visible }
  };
};

interface CompFilterOverflowVisibilityChangeAction {
  type: typeof OVERFLOW_VISIBILITY_CHANGE;
  payload: { visible: boolean };
}
export const compFilterOverflowVisibilityChange = (
  visible: boolean
): CompFilterOverflowVisibilityChangeAction => {
  return {
    type: OVERFLOW_VISIBILITY_CHANGE,
    payload: { visible }
  };
};

interface CompFilterOverflowButtonVisibilityChangeAction {
  type: typeof OVERFLOW_BUTTON_VISIBILITY_CHANGE;
  payload: { visible: boolean };
}
export const compFilterOverflowButtonVisibilityChange = (
  visible: boolean
): CompFilterOverflowButtonVisibilityChangeAction => {
  return {
    type: OVERFLOW_BUTTON_VISIBILITY_CHANGE,
    payload: { visible }
  };
};

interface CompFilterOverflowPortalMountedAction {
  type: typeof OVERFLOW_PORTAL_MOUNTED;
  payload: { portalId: string };
}
export const compFilterOverflowPortalMounted = (
  portalId: string
): CompFilterOverflowPortalMountedAction => {
  return {
    type: OVERFLOW_PORTAL_MOUNTED,
    payload: { portalId }
  };
};

export type CompFilterBarActions =
  | CompFilterOverflowButtonVisibilityChangeAction
  | CompFilterButtonVisibilityChangeAction
  | CompFilterOverflowVisibilityChangeAction
  | CompFilterOverflowPortalMountedAction;
