import { CREATE_USER_SUCCESS, CONFIRM_NEW_USER_SUCCESS } from 'actions/sign-up';
import {
  PUT_ACCOUNT_DETAILS_ON_STATE,
  SELECT_ORGANIZATION_SUCCESS
} from 'actions/account-details';
import { LOGIN_SUCCESS, TOKEN_EXPIRED } from 'actions/auth';
import { SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_SUCCESS } from 'actions/self-service';

const INITIAL_STATE = {};

export function accountDetailsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case CREATE_USER_SUCCESS:
    case CONFIRM_NEW_USER_SUCCESS:
    case SELECT_ORGANIZATION_SUCCESS:
    case PUT_ACCOUNT_DETAILS_ON_STATE:
      return { ...action.payload.accountDetails };

    case SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_SUCCESS: {
      // User is confirmed after setting up a valid payment method
      return {
        ...state,
        user: {
          ...state.user
          // confirmed: true
        }
      };
    }

    case TOKEN_EXPIRED:
      return INITIAL_STATE;

    default:
      return state;
  }
}
