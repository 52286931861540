import React from 'react';
import classNames from 'classnames';

import { StreetViewIcon } from '../../../inline-svgs';

import styles from './StreetViewButton.css';

interface Props {
  dataHcName: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  className?: string;
  disabled?: boolean;
}

export const StreetViewButton = ({
  dataHcName,
  onClick,
  className,
  disabled
}: Props) => {
  return (
    <button
      onClick={onClick}
      data-hc-name={dataHcName}
      className={classNames(styles.StreetViewButton, className, {
        [styles.disabled || '']: disabled
      })}
      disabled={disabled}
    >
      <StreetViewIcon
        dataHcName={`${dataHcName}-street-view-icon`}
        height="20px"
        width="20px"
      />
    </button>
  );
};
