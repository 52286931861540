import { connect } from 'react-redux';
import { PRODUCT_SLUGS } from '@hc/authentication-lib/hclib/components/navigation-bar/constants/products.constants';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { EVENTS } from 'legacy/appstore/events';
import { VIEWS } from 'legacy/routes/constants';

import { toSnakeCase } from 'legacy/utils/transform';

import { analyticsEvent } from 'actions/analytics';
import { archivedReportsOpenModal } from 'actions/archived-reports';
import { loginPromptShowSignup } from 'actions/auth';
import { personalizationModalOpen } from 'actions/personalization';

import { getAccountDetails } from 'selectors/account-details';
import { getIsLoggedIn } from 'selectors/auth';
import { getIsMobile } from 'selectors/match-media';
import {
  getIsViewingSharedReport,
  getIsEffectiveDateReport
} from 'selectors/property-explorer';

import GlobalNav from 'legacy/components/GlobalNav';

const { GLOBAL_NAV_ENV } = HC_CONSTANTS;

const mapStateToProps = (state) => {
  const { PROPERTY_EXPLORER } = PRODUCT_SLUGS;
  const isLoggedIn = getIsLoggedIn(state);
  return {
    env: GLOBAL_NAV_ENV,
    productSlug: PROPERTY_EXPLORER,
    userContext: toSnakeCase(getAccountDetails(state)),
    isMobile: getIsMobile(state),
    showLoginButton: getIsViewingSharedReport(state) && !isLoggedIn,
    isLoggedIn,
    getIsEffectiveDateReport: getIsEffectiveDateReport(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenArchivedReports: () => dispatch(archivedReportsOpenModal()),
    onOpenPersonalization: () => dispatch(personalizationModalOpen()),
    onClickLogin: () => dispatch(loginPromptShowSignup()),
    selectOrgOptions: {
      onClick: () => {
        dispatch(analyticsEvent(EVENTS.SELECT_ORGANIZATION));
        dispatch(
          routeChange({
            view: VIEWS.SELECT_ORGANIZATION,
            options: { inherit: false }
          })
        );
      }
    },
    logoutOptions: {
      onClick: () => dispatch(routeChange({ view: VIEWS.LOGOUT }))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalNav);
