import { STATUSES } from 'legacy/appstore/constants';
import {
  PHOTOS_DIALOG_SHOW,
  PHOTOS_DIALOG_HIDE,
  PHOTOS_CHECK_STREET_VIEW_URL,
  PHOTOS_STREET_VIEW_URL_SUCCESS,
  PHOTOS_STREET_VIEW_URL_ERROR
} from 'actions/photos';

const INITIAL_STATE = {
  photosDialog: {
    active: false,
    initialIndex: 0,
    addressId: null,
    transactionId: null,
    propertyType: null
  },
  properties: {}
};
export default function photosReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PHOTOS_CHECK_STREET_VIEW_URL:
      return {
        ...state,
        properties: {
          ...state.properties,
          [action.payload.addressId]: {
            ...state.properties[action.payload.addressId],
            streetViewStatus: STATUSES.LOADING
          }
        }
      };

    case PHOTOS_STREET_VIEW_URL_SUCCESS:
      return {
        ...state,
        properties: {
          ...state.properties,
          [action.payload.addressId]: {
            ...state.properties[action.payload.addressId],
            streetViewStatus: STATUSES.SUCCESS
          }
        }
      };

    case PHOTOS_STREET_VIEW_URL_ERROR:
      return {
        ...state,
        properties: {
          ...state.properties,
          [action.payload.addressId]: {
            ...state.properties[action.payload.addressId],
            streetViewStatus: STATUSES.ERROR
          }
        }
      };

    case PHOTOS_DIALOG_SHOW:
      const {
        addressId,
        transactionId,
        propertyType,
        initialIndex,
        listingId,
        latestListingOnly
      } = action.payload;
      return {
        ...state,
        photosDialog: {
          active: true,
          addressId,
          transactionId,
          initialIndex,
          propertyType,
          latestListingOnly,
          listingId
        }
      };

    case PHOTOS_DIALOG_HIDE:
      return {
        ...state,
        photosDialog: {
          ...state.photosDialog,
          active: false
        }
      };

    default:
      return state;
  }
}
