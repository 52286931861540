import classNames from 'classnames';
import { themr } from 'react-css-themr';

import { PrimaryFilterCheckbox as defaultTheme } from 'legacy/css-modules';

const Unchecked = ({ theme }) => {
  return (
    <svg className={theme.Svg} width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <polygon points="0 0 16 0 16 16 0 16" />
        <path
          className={theme.UncheckedFill}
          d="M14.6666658,6.1599995 L9.87333241,5.74666626 L7.99999952,1.33333325 L6.12666603,5.75333305 L1.33333325,6.1599995 L4.97333303,9.31333231 L3.8799999,13.9999992 L7.99999952,11.5133326 L12.1199993,13.9999992 L11.0333334,9.31333231 L14.6666658,6.1599995 Z M7.99999952,10.2666658 L5.49333287,11.7799993 L6.1599995,8.92666573 L3.9466665,7.00666578 L6.86666639,6.75333239 L7.99999952,4.06666636 L9.13999945,6.75999919 L12.0599993,7.01333258 L9.84666641,8.93333253 L10.513333,11.786666 L7.99999952,10.2666658 Z"
        />
      </g>
    </svg>
  );
};

const Checked = ({ theme }) => {
  return (
    <svg className={theme.Svg} width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <polygon points="0 0 16 0 16 16 0 16" />
        <polygon
          className={theme.CheckedFill}
          points="8 11.513 12.12 14 11.027 9.313 14.667 6.16 9.873 5.753 8 1.333 6.127 5.753 1.333 6.16 4.973 9.313 3.88 14"
        />
      </g>
    </svg>
  );
};

const PrimaryFilterCheckbox = ({ checked, disabled, theme, onChange }) => {
  return (
    <span
      className={classNames(theme.PrimaryFilterCheckbox, {
        [theme.disabled]: disabled
      })}
      onClick={
        !disabled
          ? (e) => {
              e.stopPropagation();
              onChange();
            }
          : undefined
      }
    >
      {checked ? <Checked theme={theme} /> : <Unchecked theme={theme} />}
    </span>
  );
};

export default themr(
  'PrimaryFilterCheckboxTheme',
  defaultTheme
)(PrimaryFilterCheckbox);
