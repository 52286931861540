import React from 'react';
import { CompSchema } from 'src/lib/report-api/types/schemas';
import { Details, Price } from 'src/lib/components/PropertyCard';
import {
  formatMoney,
  formatMissing,
  formatSqFt,
  formatDateStr
} from 'src/lib/utils';
import {
  listingStatusNormalized,
  getMarketPrice,
  getMarketPriceDate
} from 'src/lib/utils/listingStatus';
import { capitalizeFirstLetter } from 'src/lib/utils/strings';
import { CompSelectButton } from 'src/lib/report-api/features/CompSelectButton';
import styles from './CompPropertyCard.css';
import { BrokerageCredit } from '../BrokerageCredit';
import { useKeywordSearchMatchesByEntityId } from 'src/lib/keyword-search';
import { kwFormatEntityId } from 'src/lib/keyword-search/utils';

interface Props {
  dataHcName: string;
  reportId: number;
  documentId?: string;
  compSchema: CompSchema;
  selectButton?: boolean;
}
export const CompPropertyCardContent = ({
  dataHcName,
  reportId,
  documentId,
  compSchema,
  selectButton = true
}: Props) => {
  const statusNormalized = listingStatusNormalized(
    compSchema.latestListing.listingStatus
  );
  const { data: keywordMatches } = useKeywordSearchMatchesByEntityId();
  const hasKeywordMatch =
    keywordMatches[kwFormatEntityId(compSchema.latestListing.entityID)];
  return (
    <>
      <Details
        dataHcName={`${dataHcName}-details`}
        address={compSchema.address}
        attributes={[
          `${formatMissing(compSchema.livingSpace.bedrooms?.count)} Bd`,
          `${formatMissing(compSchema.livingSpace.bathrooms?.summaryCount)} Ba`,
          `${formatSqFt(compSchema.livingSpace.livingArea)}`
        ]}
        hasKeywordMatch={hasKeywordMatch}
      />
      <Price
        dataHcName={`${dataHcName}-price`}
        price={formatMoney(
          getMarketPrice(compSchema.latestListing.listingStatus)
        )}
        date={formatDateStr(
          getMarketPriceDate(compSchema.latestListing.listingStatus)
        )}
        label={capitalizeFirstLetter(statusNormalized)}
        listingStatusColor={statusNormalized}
      />
      {selectButton && (
        <div className={styles.SelectButton}>
          <CompSelectButton
            dataHcName={`comp-card-${documentId}-select-button`}
            reportId={reportId}
            documentId={documentId}
            compId={compSchema.compID}
          />
        </div>
      )}
      <div className={styles.Legal}>
        <BrokerageCredit
          dataHcName={`comp-card-${documentId}-disclaimer`}
          schema={compSchema}
          className={styles.BrokerageCredit}
        />
      </div>
    </>
  );
};
