import get from 'lodash/get';
// Adding this process step to set include_* boolean values to true when a value is present.
// This is needed since check boxes have been removed from the UI and therefore, if a value is
// present, we need to assume the user wants to include it in the report.
export const normalizePersonalizationProperties = (
  initialValues,
  groupNameMap
) => {
  return Object.keys(groupNameMap).reduce(
    (updatedValues, includeKey) => {
      const propertyNamesToCheck = groupNameMap[includeKey];

      const hasValueInGroup = propertyNamesToCheck.reduce(
        (hasValue, propertyName) => {
          return hasValue === true ? hasValue : !!initialValues[propertyName];
        },
        false
      );

      updatedValues[includeKey] = hasValueInGroup;

      return updatedValues;
    },
    { ...initialValues }
  );
};

/**
 * combine the account data and personalization data
 * if the personalization profile does not exist
 */
export const personalizationDataFromAccount = (account) => {
  const user = get(account, 'user', {});
  const team = get(account, 'currentOrganization', {});
  let personalizationData = {};

  if (team) {
    const companyName = get(team, 'name');
    if (companyName) {
      personalizationData.companyName = companyName;
    }
  }

  if (user) {
    const email = get(user, 'email');
    const firstName = get(user, 'firstName');
    const lastName = get(user, 'lastName');

    if (firstName || lastName) {
      personalizationData.firstName = firstName;
      personalizationData.lastName = lastName;
    }

    if (email) {
      personalizationData.email = email;
    }
  }

  return personalizationData;
};
