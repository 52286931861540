import { createSelector } from 'reselect';
import { STATUSES } from 'src/constants';
import { KEYWORD_SEARCH_REDUCER_KEY, StateWithKeywordSearch } from './reducer';

export const selKeywordSearchState = (state: StateWithKeywordSearch) =>
  state[KEYWORD_SEARCH_REDUCER_KEY];

export const selKeywordSearchStatus = createSelector(
  selKeywordSearchState,
  (keywordSearchState) => keywordSearchState?.status
);

export const selKeywordSearchInputs = createSelector(
  selKeywordSearchState,
  (keywordSearchState) => keywordSearchState?.inputs
);

export const selKeywordSearchData = createSelector(
  selKeywordSearchState,
  (keywordSearchState) => {
    if (keywordSearchState?.status === STATUSES.SUCCESS) {
      return keywordSearchState.data;
    } else {
      return undefined;
    }
  }
);

export const selKeywordSearchMatches = createSelector(
  selKeywordSearchData,
  (data) =>
    data?.filter((d) => d.remarks_private_match || d.remarks_public_match)
);

export const selKeywordSearchMatchesPublic = createSelector(
  selKeywordSearchData,
  (data) => data?.filter((d) => d.remarks_public_match)
);

export const selKeywordSearchMatchesPrivate = createSelector(
  selKeywordSearchData,
  (data) => data?.filter((d) => d.remarks_private_match)
);
