import React from 'react';
import remove from 'lodash/remove';

import styles from './MultiSelect.css';
import { Checkbox } from '../Checkbox';

export interface Option<T> {
  label: string;
  value: T;
}
interface OptionProps<T> extends Option<T> {
  checked: boolean;
  onChange?: (optionValue: Option<T>['value']) => void;
  dataHcName: string;
  optionWidth?: number;
}
interface Props<T> {
  dataHcName: string;
  className?: string;
  options: Option<T>[];
  value: T[];
  onChange: (value: T[]) => void;
  label?: string;
  optionWidth?: number;
}
const Option = <T extends number | string | null>({
  label,
  value,
  checked,
  onChange,
  optionWidth,
  dataHcName
}: OptionProps<T>) => {
  return (
    <div
      style={optionWidth ? { width: optionWidth } : undefined}
      className={styles.Option}
      data-hc-name={dataHcName}
    >
      <div className={styles.CheckboxCell}>
        <Checkbox
          dataHcName={`${dataHcName}-checkbox`}
          className={styles.Checkbox}
          checked={checked}
          onChange={() => onChange?.(value)}
        />
      </div>
      <div data-hc-name={`${dataHcName}-label`} className={styles.LabelCell}>
        {label}
      </div>
    </div>
  );
};

export const MultiSelect = <T extends number | string | null>({
  options,
  value,
  label,
  optionWidth,
  onChange,
  dataHcName
}: Props<T>) => {
  const handleChange = (v: T) => {
    const updatedValue = [...value];
    const vIndex = updatedValue.indexOf(v);
    if (vIndex > -1) {
      remove(updatedValue, (i) => i === v);
    } else {
      updatedValue.push(v);
    }
    onChange(updatedValue);
  };
  return (
    <div className={styles.MultiSelect}>
      {label}
      {options.map((option) => (
        <Option
          dataHcName={`${dataHcName}-option-${option.value}`}
          key={`option-${option.value}`}
          {...option}
          optionWidth={optionWidth}
          onChange={handleChange}
          checked={value.indexOf(option.value) > -1}
        />
      ))}
    </div>
  );
};
