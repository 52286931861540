import { call, put, select } from 'redux-saga/effects';
import { watchLatest } from 'legacy/utils/saga';
import EffectiveDateService from 'legacy/services/effective-date';
import { getCurrentUser } from 'selectors/account-details';
import formatDate from 'date-fns/format';
import isToday from 'date-fns/isToday';
import {
  EFFECTIVE_DATE_GET_REPORT,
  effectiveDateGetReportSuccess,
  effectiveDateGetReportFailure,
  effectiveDateCreateReportSuccess,
  effectiveDateCreateReportFailure
} from 'actions/effective-date';
import { REPORT_UPDATE_COMMENT } from 'actions/property-explorer';
import {
  CREATE_REPORT_SET_EFFECTIVE_DATE,
  createReportSetEffectiveDateSuccess,
  createReportSetEffectiveDateFailure
} from 'actions/create-report';
import {
  AVMS_CHANGE_SELECTED_SUCCESS,
  AVMS_UPDATE_USER_ENTERED_VALUE_SUCCESS
} from 'actions/avms';

import {
  COMPS_FILTER_DISTANCE_CHANGE,
  COMPS_MAP_DRAW_SUBMIT,
  COMPS_FITERS_CHANGE_COMPLETE,
  COMPS_SELECT_TOGGLE,
  COMPS_CLEAR_FILTER,
  COMPS_CLEAR_ALL_FILTERS
} from 'actions/comps';
import { getCompsFiltersAppliedForMetadata } from 'selectors/comps';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import { report } from 'hc-ravenjs-logger';

function* reportFetchedSuccessfully(response, showToast = true) {
  const { report, subscriptionType, cobranding } = response;
  const user = yield select(getCurrentUser);
  yield put(
    effectiveDateGetReportSuccess({
      userIsReportOwner: user.id === report.metadata.reportMetadata.ownerId,
      report,
      cobranding,
      subscriptionType,
      showToast
    })
  );
}

export function* effectiveDateSelectCompToggle(comp) {
  const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
  if (!isEffectiveDateReport) return;
  const response = yield call(EffectiveDateService.selectCompToggle, comp);
  yield call(reportFetchedSuccessfully, response);
}

export function* createEffectiveDateReport(params) {
  try {
    const response = yield call(EffectiveDateService.createReport, params);
    yield call(reportFetchedSuccessfully, response, false);
    yield put(effectiveDateCreateReportSuccess());
    return response;
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to Create Report', { e, params });
    yield put(effectiveDateCreateReportFailure());
  }
}

export function* getEffectiveDateReport(reportId) {
  try {
    const response = yield call(EffectiveDateService.fetchReport, reportId);
    yield call(reportFetchedSuccessfully, response, false);
    return response;
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to GET Report', { e, reportId });
    yield put(effectiveDateGetReportFailure(e?.statusCode));
  }
}

export function* searchEffectiveDateReports(params) {
  const results = yield call(EffectiveDateService.searchReports, params);
  return results;
}

export function* handleUpdateComment(action) {
  try {
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    if (!isEffectiveDateReport) return;
    const { commentValue } = action.payload;
    const response = yield call(
      EffectiveDateService.updateComment,
      commentValue
    );
    yield call(reportFetchedSuccessfully, response);
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to Update Comment', { e, action });
    yield put(effectiveDateGetReportFailure());
  }
}

export function* handleCreateReportEffectiveDateSet(action) {
  const { addressId, effectiveDate } = action.payload;
  try {
    if (!isToday(effectiveDate)) {
      const user = yield select(getCurrentUser);
      const params = {
        effectiveDate: formatDate(effectiveDate, 'yyyy-MM-dd'),
        ownerID: user.id,
        addressID: addressId
      };
      const results = yield call(searchEffectiveDateReports, params);
      yield put(createReportSetEffectiveDateSuccess(results));
    }
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to Create Report', { e, action });
    yield put(createReportSetEffectiveDateFailure());
  }
}

export function* handleCompFilterChange(action) {
  try {
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    if (!isEffectiveDateReport) return;
    const originalChange = action.payload;
    for (const filterKey in originalChange.absoluteValues) {
      const absValue = originalChange.absoluteValues[filterKey];
      if (
        (Array.isArray(absValue) &&
          absValue[0] === null &&
          absValue[1] === null) ||
        absValue === null ||
        absValue === undefined
      ) {
        yield call(handleClearCompFilter, {
          type: COMPS_CLEAR_FILTER,
          payload: { filterKey }
        });
        delete originalChange.absoluteValues[filterKey];
      }
    }
    const response = yield call(
      EffectiveDateService.updateCompFilters,
      originalChange
    );
    if (response) {
      yield call(reportFetchedSuccessfully, response);
    }
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to Update Comp Filters', { e, action });
    yield put(effectiveDateGetReportFailure());
  }
}

export function* handleChangeSelectedAvm(action) {
  try {
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    if (!isEffectiveDateReport) return;
    const { selectedAvm } = action.payload;
    const response = yield call(EffectiveDateService.selectAvm, selectedAvm);
    yield call(reportFetchedSuccessfully, response);
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to Change Selected AVM', { e, action });
    yield put(effectiveDateGetReportFailure());
  }
}

export function* handleChangeUserEnteredValue(action) {
  try {
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    if (!isEffectiveDateReport) return;
    const { values } = action.payload;
    const response = yield call(
      EffectiveDateService.updateUserEnteredValue,
      values
    );
    yield call(reportFetchedSuccessfully, response);
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to Update User Entered Value', {
      e,
      action
    });
    yield put(effectiveDateGetReportFailure());
  }
}

export function* handleCompSelectToggle(action) {
  try {
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    if (!isEffectiveDateReport) return;
    const { comp } = action.payload;
    yield call(effectiveDateSelectCompToggle, comp);
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to Toggle Selected Comp', { e, action });
    yield put(effectiveDateGetReportFailure());
  }
}

export function* handleMapPolygonChange(action) {
  try {
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    if (!isEffectiveDateReport) return;
    const { polygons } = action.payload;
    if (polygons.length) {
      const geojson = polygons[0];
      const response = yield call(
        EffectiveDateService.updateCompGeoJson,
        geojson
      );
      yield call(reportFetchedSuccessfully, response);
    }
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to Update Polygon', { e, action });
    yield put(effectiveDateGetReportFailure());
  }
}

export function* handleGetEffectiveDateReport(action) {
  try {
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    if (!isEffectiveDateReport) return;
    const { reportId } = action.payload;
    yield call(getEffectiveDateReport, reportId);
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to Get report', { e, action });
    yield put(effectiveDateGetReportFailure());
  }
}

export function* handleClearCompFilter(action) {
  try {
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    if (!isEffectiveDateReport) return;
    const { filterKey } = action.payload;
    if (filterKey === 'distance') {
      const response = yield call(EffectiveDateService.clearDistanceFilter);
      yield call(reportFetchedSuccessfully, response);
    } else {
      const response = yield call(EffectiveDateService.clearFilter, filterKey);
      yield call(reportFetchedSuccessfully, response);
    }
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to Clear comp filter', { e, action });
    yield put(effectiveDateGetReportFailure());
  }
}

function* handleClearAllFilters(action) {
  try {
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    if (!isEffectiveDateReport) return;
    const response = yield call(EffectiveDateService.clearAllFilters);
    yield call(reportFetchedSuccessfully, response);
  } catch (e) {
    console.error(e);
    report('Effective Data: Failed to Clear all Filters', { e, action });
    yield put(effectiveDateGetReportFailure());
  }
}

export default function registerEffectiveDateSaga() {
  watchLatest({
    [COMPS_CLEAR_ALL_FILTERS]: handleClearAllFilters,
    [COMPS_FILTER_DISTANCE_CHANGE]: handleMapPolygonChange,
    [EFFECTIVE_DATE_GET_REPORT]: getEffectiveDateReport,
    [COMPS_CLEAR_FILTER]: handleClearCompFilter,
    [COMPS_MAP_DRAW_SUBMIT]: handleMapPolygonChange,
    [CREATE_REPORT_SET_EFFECTIVE_DATE]: handleCreateReportEffectiveDateSet,
    [REPORT_UPDATE_COMMENT]: handleUpdateComment,
    [COMPS_FITERS_CHANGE_COMPLETE]: handleCompFilterChange,
    [AVMS_CHANGE_SELECTED_SUCCESS]: handleChangeSelectedAvm,
    [AVMS_UPDATE_USER_ENTERED_VALUE_SUCCESS]: handleChangeUserEnteredValue,
    [COMPS_SELECT_TOGGLE]: handleCompSelectToggle
  });
}
