export const PUT_ACCOUNT_DETAILS_ON_STATE = 'PUT_ACCOUNT_DETAILS_ON_STATE';

export const SELECT_ORGANIZATION = 'SELECT_ORGANIZATION';
export const SELECT_ORGANIZATION_SUCCESS = 'SELECT_ORGANIZATION_SUCCESS';
export const SELECT_ORGANIZATION_ERROR = 'SELECT_ORGANIZATION_ERROR';

export const putAccountDetailsOnState = (accountDetails) => ({
  type: PUT_ACCOUNT_DETAILS_ON_STATE,
  payload: {
    accountDetails
  }
});

export const selectOrganization = (organizationId, resolved) => ({
  type: SELECT_ORGANIZATION,
  payload: { organizationId },
  meta: { resolved }
});
export const selectOrganizationSuccess = (accountDetails) => ({
  type: SELECT_ORGANIZATION_SUCCESS,
  payload: { accountDetails }
});
export const selectOrganizationError = (message) => ({
  type: SELECT_ORGANIZATION_SUCCESS,
  meta: { toast: { message } }
});
