import { useQuery } from '@apollo/client';
import { GET_GEOLOCATION } from '../api/queries';
import { GeoLocation, Photo } from '../types';

// TODO: remove hard coded token
const mapboxToken =
  'pk.eyJ1IjoibWF0dC1oYyIsImEiOiJ4ZHRkby1jIn0.6AE5Ob825Mlcaf_lIU66eA';
export const useMapTilePhoto = (hcAddressId: number) => {
  const { loading, error, data } = useQuery<{
    propertyLookup: {
      geoLocation: GeoLocation;
    };
  }>(GET_GEOLOCATION, {
    variables: { hcAddressId }
  });
  if (
    data?.propertyLookup.geoLocation.latitude &&
    data.propertyLookup.geoLocation.longitude
  ) {
    const { latitude, longitude } = data.propertyLookup.geoLocation;
    const photo: Photo = {
      representation: {
        width: 640,
        height: 480,
        httpUrl: `https://api.tiles.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-m+16B7D5(${longitude},${latitude})/${longitude},${latitude},16/640x480@2x?access_token=${mapboxToken}`
      }
    };
    return { loading: false, error, data: photo };
  }
  return { loading, error, data: undefined };
};
