import classNames from 'classnames';
import React from 'react';
import { STATUSES } from 'src/constants';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { useKeywordSearchMatches } from '../../hooks';

import styles from './KeywordSearchResults.css';

interface Props {
  dataHcName: string;
  className?: string;
  // Allows display of a subset of results
  // Used in PEXP, we call the api for the entire farm
  // and show results based on the filtered list
  // Count defaults to the number of matches if prop isn't passed
  count?: number;
  onClick?: VoidFunction;
}

export const KeywordSearchResults = ({
  dataHcName,
  className,
  count,
  onClick
}: Props) => {
  const { status, data } = useKeywordSearchMatches();
  const numMatches = count !== undefined ? count : data?.length;
  if (!status) {
    return null;
  }
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.KeywordSearchResults, className)}
      onClick={onClick}
    >
      {status === STATUSES.LOADING ? (
        <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} small />
      ) : (
        <div
          data-hc-name={`${dataHcName}-count`}
          className={classNames(styles.Count, {
            [styles.matches || '']: !!numMatches,
            [styles.noMatches || '']: !numMatches
          })}
        >
          {numMatches}
        </div>
      )}
    </div>
  );
};
