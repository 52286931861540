import { EVENTS } from 'legacy/appstore/events';

export const SIGN_UP_LOADED = 'SIGN_UP_LOADED';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const PROCESS_INVITATION_CODE = 'PROCESS_INVITATION_CODE';
export const PROCESS_INVITATION_CODE_SUCCESS =
  'PROCESS_INVITATION_CODE_SUCCESS';
export const PROCESS_INVITATION_CODE_COMPLETE =
  'PROCESS_INVITATION_CODE_COMPLETE';
export const PROCESS_INVITATION_CODE_ERROR = 'PROCESS_INVITATION_CODE_ERROR';
export const JOIN_ORG = 'JOIN_ORG';
export const JOIN_ORG_SUCCESS = 'JOIN_ORG_SUCCESS';
export const JOIN_ORG_ERROR = 'JOIN_ORG_ERROR';

export const CONFIRM_NEW_USER_SUCCESS = 'CONFIRM_NEW_USER_SUCCESS';
export const CONFIRM_NEW_USER_ERROR = 'CONFIRM_NEW_USER_ERROR';
export const CONFIRM_NEW_USER_EXPIRED = 'CONFIRM_NEW_USER_EXPIRED';

export const RESEND_CONFIRM_USER_EMAIL = 'RESEND_CONFIRM_USER_EMAIL';
export const RESEND_CONFIRM_USER_EMAIL_SUCCESS =
  'RESEND_CONFIRM_USER_EMAIL_SUCCESS';
export const INVITE_TEAM_MEMBERS = 'INVITE_TEAM_MEMBERS';
export const INVITE_TEAM_MEMBERS_ERROR = 'INVITE_TEAM_MEMBERS_ERROR';

export const RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN =
  'RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN';
export const RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN_SUCCESS =
  'RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN_SUCCESS';
export const RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN_FAILURE =
  'RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN_FAILURE';
export const signUpLoaded = () => ({ type: SIGN_UP_LOADED });

export const createUser = (
  user,
  routeAfterSignUpSuccess,
  actionAfterSignUpSuccess
) => {
  return {
    type: CREATE_USER,
    payload: { user, routeAfterSignUpSuccess, actionAfterSignUpSuccess }
  };
};
export const createUserSuccess = (accountDetails) => ({
  type: CREATE_USER_SUCCESS,
  payload: { accountDetails },
  meta: {
    analytics: {
      event: EVENTS.AUTH_SIGN_UP_COMPLETE,
      props: {
        first_name: accountDetails.firstName,
        last_name: accountDetails.lastName,
        email: accountDetails.email,
        src: 'property_explorer'
      }
    }
  }
});
export const createUserError = (error) => ({
  type: CREATE_USER_ERROR,
  payload: { error }
});

export const processInvitationCode = (params) => ({
  type: PROCESS_INVITATION_CODE,
  payload: { params }
});
export const processInvitationCodeComplete = (pendingUser) => ({
  type: PROCESS_INVITATION_CODE_COMPLETE,
  payload: { pendingUser }
});
export const processInvitationCodeSuccess = (pendingUser) => ({
  type: PROCESS_INVITATION_CODE_SUCCESS,
  payload: { pendingUser }
});
export const processInvitationCodeError = (error) => ({
  type: PROCESS_INVITATION_CODE_ERROR,
  payload: { error }
});

export const joinOrg = (code) => ({ type: JOIN_ORG, payload: { code } });
export const joinOrgSuccess = () => ({ type: JOIN_ORG_SUCCESS });
export const joinOrgError = (error) => ({
  type: JOIN_ORG_ERROR,
  payload: { error }
});

export const confirmNewUserSuccess = (accountDetails) => ({
  type: CONFIRM_NEW_USER_SUCCESS,
  payload: { accountDetails }
});
export const confirmNewUserError = (error) => ({
  type: CONFIRM_NEW_USER_ERROR,
  payload: { error }
});
export const confirmNewUserExpiredToken = (error, token) => ({
  type: CONFIRM_NEW_USER_EXPIRED,
  payload: { error, token }
});

export const resendConfirmUserEmail = (token) => ({
  type: RESEND_CONFIRM_USER_EMAIL,
  payload: { token }
});
export const resendConfirmUserEmailSuccess = () => ({
  type: RESEND_CONFIRM_USER_EMAIL_SUCCESS
});

export const resendConfirmEmailFromAuthToken = (token) => {
  return {
    type: RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN,
    payload: { token }
  };
};

export const resendConfirmEmailFromAuthTokenSuccess = (token) => {
  return {
    type: RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN_SUCCESS
  };
};

export const resendConfirmEmailFromAuthTokenFailure = (token) => {
  return {
    type: RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN_FAILURE
  };
};

export const inviteTeamMembers = (emails, onSuccess, onError) => ({
  type: INVITE_TEAM_MEMBERS,
  payload: { emails },
  meta: { onSuccess, onError }
});
export const inviteTeamMembersError = (message) => ({
  type: INVITE_TEAM_MEMBERS_ERROR,
  meta: { toast: { message } }
});
