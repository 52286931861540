import React from 'react';
import classNames from 'classnames';
import styles from './CompFarmListTableOptions.css';
import { Draggable } from 'react-beautiful-dnd';
import { Checkbox } from 'src/lib/components/Checkbox';
import { CompFields } from '../../types/CompFields';

interface Props {
  dataHcName: string;
  compField: CompFields;
  index: number;
  active: boolean;
  onToggle: (compField: CompFields, active: boolean) => void;
}

const LABELS = {
  [CompFields.address]: 'Address',
  [CompFields.similarity]: 'Similarity',
  [CompFields.distanceMiles]: 'Distance from Subject',
  [CompFields.bedrooms]: 'Bedrooms',
  [CompFields.bathrooms]: 'Bathrooms',
  [CompFields.grossLivingAreaSqft]: 'Gross Living Area',
  [CompFields.siteAreaSqft]: 'Site Area',
  [CompFields.propertyType]: 'Property Type',
  [CompFields.yearBuilt]: 'Year Built',
  [CompFields.propertyStatus]: 'Property Status',
  // [CompFields.propertyStatusRental]: '',
  [CompFields.subdivisionName]: 'Subdivision',
  [CompFields.salesDate]: 'Sale Date',
  [CompFields.salesPrice]: 'Sale Price',
  [CompFields.pricePerSqft]: 'Sale Price Per Ft²',
  [CompFields.pricePerSqftNonDisclosure]: 'List Price Per Ft²',
  // [CompFields.leasedDate]: '',
  // [CompFields.leasedPrice]: '',
  [CompFields.lastListDate]: 'List Date',
  [CompFields.lastListPrice]: 'List Price',
  // [CompFields.lastListDateRental]: '',
  // [CompFields.lastListPriceRental]: '',
  [CompFields.activeDaysOnMarket]: 'Active Days on Market',
  [CompFields.cumulativeDaysOnMarket]: 'Cumulative Days on Market',
  [CompFields.currentValue]: 'HouseCanary Value',
  // [CompFields.rentalAvm]: '',
  // [CompFields.pricePerSqftRentalLeased]: '',
  // [CompFields.pricePerSqftRentalListed]: '',
  [CompFields.pool]: 'Pool',
  [CompFields.garageNumCars]: 'Garage',
  [CompFields.stories]: 'Stories',
  [CompFields.basement]: 'Basement',
  [CompFields.isDistressed]: 'Distressed',
  [CompFields.flips]: 'Flips'
};

export const CompFarmListTableOption = ({
  dataHcName,
  active,
  compField,
  index,
  onToggle
}: Props) => {
  const content = (
    <>
      <div className={styles.ColumnToggle}>
        <Checkbox
          dataHcName={`${dataHcName}-checkbox`}
          onChange={() => onToggle(compField, !active)}
          className={styles.CheckboxSmall}
          checked={active}
        />
      </div>
      <div className={styles.ColumnLabel}>
        <span data-hc-name="custom-table-modal-label">
          {LABELS[compField] || compField}
        </span>
      </div>
    </>
  );
  return (
    <div
      className={classNames(styles.OrderRow, {
        [styles.OrderRowActive || '']: active,
        [styles.OrderRowInactive || '']: !active
      })}
    >
      <div className={styles.OrderNumber}>{index + 1}</div>
      {active ? (
        <Draggable
          key={`draggable-${compField}`}
          draggableId={`draggable-${compField}`}
          index={index}
        >
          {(provided, snapshot) => (
            <div
              className={classNames(styles.Column, {
                [styles.columnDragging || '']: snapshot.isDragging
              })}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {content}
            </div>
          )}
        </Draggable>
      ) : (
        <div className={styles.Column}>{content}</div>
      )}
      <div className={styles.Spacer} />
    </div>
  );
};
