import accounting, { CurrencySettings, NumberSettings } from 'accounting';
import { DateStr } from 'src/types';
import { format as dateFnsFormat, parse as dateFnsParse } from 'date-fns';
import { NULL_VALUE } from '../../constants';
import { PropertyType, Address, ListingStatus } from '../property-lookup/types';
import { AvmType } from '../report-api';
import { capitalizeFirstLetter } from 'src/lib/utils/strings';
import { listingStatusNormalized } from './listingStatus';
import {
  formatFilterButtonValueExact,
  formatFilterButtonValuePercentage
} from 'src/lib/utils/validators';
import { formatPercentage } from 'src/lib/utils/compFilters';
const ONE_MILLION = 1000000;
const ONE_THOUSAND = 1000;
const TWENTY_THOUSAND = 20000;

export const formatNumber = (
  value: number | null | undefined,
  options: NumberSettings = { precision: 0 }
) =>
  value === null || value === undefined
    ? NULL_VALUE
    : accounting.formatNumber(value, options);

export const placeholderFormatter = (
  value?: string | null | number,
  placeholder = NULL_VALUE
): string => (!value ? placeholder : `${value}`);

export const formatNumberAbbrev = (value: number | null | undefined) => {
  if (value === null || value === undefined) {
    return NULL_VALUE;
  }
  if (value >= ONE_MILLION) {
    return `${accounting.toFixed(value / ONE_MILLION, 1)}M`;
  } else if (value >= TWENTY_THOUSAND) {
    return `${accounting.toFixed(value / ONE_THOUSAND, 0)}K`;
  } else {
    return `${accounting.toFixed(value / ONE_THOUSAND, 1)}K`;
  }
};

export const formatDateLong = (
  date: number | Date | DateStr | null | undefined
): string =>
  !date
    ? NULL_VALUE
    : dateFnsFormat(
        typeof date === 'string'
          ? dateFnsParse(date, 'yyyy-MM-dd', new Date())
          : date,
        'MMM d, yyyy'
      );

export const formatMoney = (
  value: number | null | undefined,
  options: CurrencySettings<'$'> = { precision: 0 }
) =>
  value === null || value === undefined
    ? `$${NULL_VALUE}`
    : accounting.formatMoney(value, options);

export const formatMoneyPerMonth = (v: number | null | undefined) =>
  `${formatMoney(v)}/mo.`;

export const formatMoneyAbbrev = (value: number | null | undefined) =>
  `$${formatNumberAbbrev(value)}`;

export const unformat = (value: string) => accounting.unformat(value);

export const formatSqFt = (value: number | null | undefined) =>
  `${formatNumber(value)} ft²`;

export const buttonLabelFormatter = (isPercentRange: boolean) => {
  return isPercentRange
    ? formatFilterButtonValuePercentage
    : formatFilterButtonValueExact;
};

export const relativeSubjectFormatter = (
  isPercentage: boolean,
  value?: string | null | number
): string => {
  const newVal = !value
    ? undefined
    : isPercentage
    ? formatPercentage(`${value}`)
    : value;
  return `${placeholderFormatter(newVal)} from subject`;
};

export const formatPricePerSqFt = (
  value: number | undefined | null,
  forceRound?: boolean
) => {
  const valueFormatted =
    value && value !== 0
      ? value > 100 || forceRound
        ? formatMoney(Math.round(value))
        : formatMoney(parseFloat(value.toFixed(2)), { precision: 2 })
      : NULL_VALUE;
  return `${valueFormatted} / ft²`;
};

export const formatDistanceMiles = (value: number | null | undefined) =>
  value === null || value === undefined
    ? NULL_VALUE
    : `${formatNumber(value, { precision: 2 })} mi`;

export const formatMissing = (value: number | string | undefined | null) =>
  value === null || value === undefined ? NULL_VALUE : value;

export const formatStreetAddress = ({
  streetAddress,
  unit
}: {
  streetAddress?: string | null;
  unit?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
  slug?: string;
  zipcodePlus4?: string | null;
  fullAddress?: string | null;
  hcAddressId?: number;
}) => {
  if (!streetAddress) return NULL_VALUE;
  const unitDisplay = unit ? ` ${unit}` : '';
  return `${streetAddress || ''}${unitDisplay}`;
};

export const formatCityStateZip = ({
  city,
  state,
  zipcode
}: {
  streetAddress?: string | null;
  unit?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
  slug?: string;
  zipcodePlus4?: string | null;
  fullAddress?: string | null;
  hcAddressId?: number;
}) => `${city || NULL_VALUE}, ${state || NULL_VALUE} ${zipcode || NULL_VALUE}`;

// Formats the typical date string returned from our APIs: YYYY-MM-DD
export const formatDateStr = (str: DateStr | null): string => {
  if (!str) return NULL_VALUE;
  const [year, month, day] = str.split('-') as [string, string, string];
  return `${month}/${day}/${year}`;
};

export const formatAvmLabel = (avmKey: AvmType) => {
  switch (avmKey) {
    case AvmType.HouseCanary:
      return 'HouseCanary Value';
    // TODO: uncomment this for pinkman reports
    // case AvmType.Adjusted:
    //   return 'Adjusted Value';
    case AvmType.CompAvm:
      return 'Comparable Value';
    case AvmType.CompMarket:
      return 'Comparable Average';
    case AvmType.UserEntered:
      return 'User Opinion of Price';
  }
};

export const formatBoolYesNo = (v: boolean | null | undefined): string =>
  v ? 'Yes' : v === false ? 'No' : NULL_VALUE;

const PROPERTY_TYPE_LABELS = {
  [PropertyType.SingleFamilyDetached]: 'Single Family Detached',
  [PropertyType.SingleFamilyAttached]: 'Townhouse',
  [PropertyType.Condo]: 'Condominium',
  [PropertyType.MultiFamily]: 'Multifamily',
  [PropertyType.Manufactured]: 'Manufactured/Mobile Home',
  // Not used in PEXP at this time but needed for typing:
  [PropertyType.Coop]: 'Co-Op',
  [PropertyType.Other]: 'Other',
  [PropertyType.Commerical]: 'Commercial',
  [PropertyType.Timeshare]: 'Timeshare',
  [PropertyType.VacantLot]: 'Vacant Lot'
};
export const formatPropertyType = (propertyType: PropertyType) =>
  propertyType ? PROPERTY_TYPE_LABELS[propertyType] : NULL_VALUE;

export const formatListingStatus = (
  listingStatus: ListingStatus | null | undefined
) =>
  listingStatus
    ? listingStatus
        .split('_')
        .map((w) => capitalizeFirstLetter(w.toLowerCase()))
        .join(' ')
    : NULL_VALUE;

export const formatListingStatusNormalized = (
  listingStatus: ListingStatus | null | undefined
) =>
  listingStatus
    ? formatListingStatus(listingStatusNormalized({ MlsState: listingStatus }))
    : NULL_VALUE;

export const formatFullAddress = (address?: Address) =>
  address
    ? `${formatStreetAddress(address)}, ${formatCityStateZip(address)}`
    : NULL_VALUE;

export const formatRangeYearBuilt = (
  value?: (number | undefined | null)[]
): string => {
  if (
    !value ||
    ((!value?.[0] || value?.[0] === 0) && (!value?.[1] || value?.[1] === 0))
  ) {
    return 'Year Built';
  }
  const currentYear = new Date().getFullYear();
  if (value && value[0] && value[0] !== 0 && (!value[1] || value[1] === 0)) {
    return `${value[0]} - ${currentYear} Year Built`;
  }
  if (value && (!value[0] || value[0] === 0) && value[1] && value[1] !== 0) {
    return `Up to ${value[1]} Year Built`;
  }
  return `${value?.[0]}-${value?.[1]} Year Built`;
};

export const formatStripFormatting = (value: string): string => {
  return value.replace(/(sqft\.|m|k|\$|,)/gi, '').trim();
};

export const formatMonthsAgo = (
  months: number | null | undefined,
  label: string
) => {
  if (months === null || months === undefined) return NULL_VALUE;
  if (months >= 12) {
    const years = Math.round(months / 12);
    return `${capitalizeFirstLetter(label)} prior ${years} year${
      years === 1 ? '' : 's'
    }`;
  } else {
    return `${capitalizeFirstLetter(label)} prior ${months} month${
      months === 1 ? '' : 's'
    }`;
  }
};
