import { EVENTS } from 'legacy/appstore/events';
import {
  AVM_KEY_USER_ENTERED,
  AVM_KEY_RENTAL_USER_ENTERED,
  AVM_KEY_RENTAL_COMPS,
  AVM_KEY_RENTAL_COMPS_AVG
} from 'legacy/appstore/constants';

import { getTriggerAvmChangeSelected } from 'selectors/analytics';

export const AVMS_CHANGE_SELECTED = 'AVMS_CHANGE_SELECTED';
export const AVMS_CHANGE_SELECTED_SUCCESS = 'AVMS_CHANGE_SELECTED_SUCCESS';
export const AVMS_COMP_VALUE_RECOMPUTE = 'AVMS_COMP_VALUE_RECOMPUTE';
export const AVMS_COMP_VALUE_RECOMPUTE_SUCCESS =
  'AVMS_COMP_VALUE_RECOMPUTE_SUCCESS';
export const AVMS_DETAILS_TOGGLE = 'AVMS_DETAILS_TOGGLE';
export const AVMS_DETAILS_TOGGLE_RENTAL = 'AVMS_DETAILS_TOGGLE_RENTAL';
export const AVMS_RENTAL_CHANGE_SELECTED = 'AVMS_RENTAL_CHANGE_SELECTED';
export const AVMS_RENTAL_CHANGE_SELECTED_SUCCESS =
  'AVMS_RENTAL_CHANGE_SELECTED_SUCCESS';
export const AVMS_RENTAL_COMP_VALUE_RECOMPUTE =
  'AVMS_RENTAL_COMP_VALUE_RECOMPUTE';
export const AVMS_RENTAL_COMP_VALUE_RECOMPUTE_SUCCESS =
  'AVMS_RENTAL_COMP_VALUE_RECOMPUTE_SUCCESS';
export const AVMS_RENTAL_DETAILS_TOGGLE = 'AVMS_RENTAL_DETAILS_TOGGLE';
export const AVMS_UPDATE_USER_ENTERED_VALUE = 'AVMS_UPDATE_USER_ENTERED_VALUE';
export const AVMS_UPDATE_USER_ENTERED_VALUE_SUCCESS =
  'AVMS_UPDATE_USER_ENTERED_VALUE_SUCCESS';
export const AVMS_UPDATE_USER_ENTERED_RENTAL_VALUE =
  'AVMS_UPDATE_USER_ENTERED_RENTAL_VALUE';
export const AVMS_UPDATE_USER_ENTERED_RENTAL_VALUE_SUCCESS =
  'AVMS_UPDATE_USER_ENTERED_RENTAL_VALUE_SUCCESS';

export const avmsChangeSelected = (selectedAvm) => ({
  type: AVMS_CHANGE_SELECTED,
  payload: { selectedAvm }
});

export const avmsChangeSelectedSuccess = (selectedAvm) => ({
  type: AVMS_CHANGE_SELECTED_SUCCESS,
  payload: { selectedAvm },
  meta: {
    analytics: {
      event: EVENTS.AVM_CHANGE_SELECTED,
      props: { selected_avm_new: selectedAvm }
    }
  }
});

export const avmsRentalChangeSelected = (selectedRentalAvm) => ({
  type: AVMS_RENTAL_CHANGE_SELECTED,
  payload: { selectedRentalAvm }
});

export const avmsRentalChangeSelectedSuccess = (selectedRentalAvm) => ({
  type: AVMS_RENTAL_CHANGE_SELECTED_SUCCESS,
  payload: { selectedRentalAvm },
  meta: {
    analytics: {
      event: EVENTS.RENTAL_AVM_CHANGE_SELECTED,
      props: { selected_avm_new: selectedRentalAvm }
    }
  }
});

export const avmsCompValueRecompute = (bypassSave) => ({
  type: AVMS_COMP_VALUE_RECOMPUTE,
  payload: { bypassSave }
});

export const avmsCompValueRecomputeSuccess = (compAvms) => ({
  type: AVMS_COMP_VALUE_RECOMPUTE_SUCCESS,
  payload: { ...compAvms }
});

export const avmsRentalCompValueRecompute = (bypassSave) => ({
  type: AVMS_RENTAL_COMP_VALUE_RECOMPUTE,
  payload: { bypassSave }
});

export const avmsRentalCompValueRecomputeSuccess = (avms) => ({
  type: AVMS_RENTAL_COMP_VALUE_RECOMPUTE_SUCCESS,
  payload: {
    [AVM_KEY_RENTAL_COMPS]: avms[AVM_KEY_RENTAL_COMPS],
    [AVM_KEY_RENTAL_COMPS_AVG]: avms[AVM_KEY_RENTAL_COMPS_AVG]
  }
});

export const avmsUpdateUserEnteredValue = (value) => ({
  type: AVMS_UPDATE_USER_ENTERED_VALUE,
  payload: { value }
});

export const avmsUpdateUserEnteredValueSuccess = (values) => ({
  type: AVMS_UPDATE_USER_ENTERED_VALUE_SUCCESS,
  payload: { values },
  meta: {
    analytics: [
      {
        event: EVENTS.AVM_USER_OPINION_UPDATE,
        props: {
          value: values.value
        }
      },
      {
        event: EVENTS.AVM_CHANGE_SELECTED,
        props: { selected_avm_new: AVM_KEY_USER_ENTERED }
      }
    ]
  }
});

export const avmsUpdateUserEnteredRentalValue = (value) => ({
  type: AVMS_UPDATE_USER_ENTERED_RENTAL_VALUE,
  payload: { value }
});

export const avmsUpdateUserEnteredRentalValueSuccess = (values) => ({
  type: AVMS_UPDATE_USER_ENTERED_RENTAL_VALUE_SUCCESS,
  payload: { values },
  meta: {
    analytics: [
      {
        event: EVENTS.RENTAL_AVM_USER_OPINION_UPDATE,
        props: {
          value: values.value
        }
      },
      {
        event: EVENTS.RENTAL_AVM_CHANGE_SELECTED,
        props: { selected_avm_new: AVM_KEY_RENTAL_USER_ENTERED }
      }
    ]
  }
});
