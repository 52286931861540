import { CompFields } from '../report-api/types/CompFields';
// NOTE: Rental fields commented out but left for ease of reference for phase III development
export const COMP_FIELD_ORDER = [
  CompFields.address,
  CompFields.similarity,
  CompFields.distanceMiles,
  CompFields.bedrooms,
  CompFields.bathrooms,
  CompFields.grossLivingAreaSqft,
  CompFields.siteAreaSqft,
  CompFields.propertyType,
  CompFields.yearBuilt,
  CompFields.propertyStatus,
  // CompFields.propertyStatusRental,
  CompFields.subdivisionName,
  CompFields.salesDate,
  CompFields.salesPrice,
  CompFields.pricePerSqft,
  // CompFields.leasedDate,
  // CompFields.leasedPrice,
  CompFields.lastListDate,
  CompFields.lastListPrice,
  // CompFields.lastListDateRental,
  // CompFields.lastListPriceRental,
  CompFields.pricePerSqftNonDisclosure,
  CompFields.activeDaysOnMarket,
  CompFields.cumulativeDaysOnMarket,
  CompFields.currentValue,
  // CompFields.rentalAvm,
  // CompFields.pricePerSqftRentalLeased,
  // CompFields.pricePerSqftRentalListed,
  CompFields.pool,
  CompFields.garageNumCars,
  CompFields.stories,
  CompFields.basement,
  CompFields.isDistressed,
  CompFields.flips
];
