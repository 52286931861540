import HC_CONSTANTS from 'HC_CONSTANTS';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getToken } from 'selectors/account-details';

export const useOrgReportPreferences = () => {
  const token = useSelector(getToken);
  return useQuery('REPORTPREFS', async () => {
    const response = await axios.get(
      `${HC_CONSTANTS.HUELL_PROXY_URL}/organization_report_preferences/`,
      {
        headers: {
          'Hc-Auth-Token': token
        }
      }
    );
    return response.data;
  });
};
