import PropTypes from 'prop-types';

import { Dialog } from 'legacy/components/Dialog';
import ShareReportFormContainer from 'legacy/containers/share-report-form';

import { ShareReportModal as shareModalTheme } from 'legacy/css-modules';

const ShareReportModal = ({
  active,
  uniqueLinkDoesNotExist,
  shareByEmail,
  showPersonalizationPrompt,
  fullscreen,
  onClose,
  ...rest
}) => {
  return (
    <Dialog
      title={'Share This Report'}
      active={active}
      onClose={onClose}
      type="auto"
      // max-width set via important
      className={shareModalTheme.Dialog}
      closeButton
    >
      <ShareReportFormContainer />
    </Dialog>
  );
};

ShareReportModal.defaultProps = {
  open: false,
  showPersonalizationPrompt: false
};

ShareReportModal.propTypes = {
  onClose: PropTypes.func.isRequired,

  open: PropTypes.bool,
  showPersonalizationPrompt: PropTypes.bool
};

export default ShareReportModal;
