import { createSelector } from 'reselect';

import { getCurrentParams } from '@hc/redux-saga-router-plus/hclib/selectors';

export function getOrganizationUsage(state) {
  return state.organizationUsage;
}

export const getOrganizationUsageStatus = createSelector(
  getOrganizationUsage,
  (organizationUsageState) => {
    return organizationUsageState.status;
  }
);

export function getUsageComponent(state) {
  return getOrganizationUsage(state).component || {};
}

export function getApplicationId(state) {
  return getUsageComponent(state).applicationId;
}

export function getSubscriptionId(state) {
  return getOrganizationUsage(state).subscriptionId;
}

// checking response from /check-my-access
export const getTrialEnded = createSelector(
  getOrganizationUsage,
  (organizationUsage) => {
    return organizationUsage.trialEnded;
  }
);

export const getIsUserInTrialMode = createSelector(
  getOrganizationUsage,
  getTrialEnded,
  (organizationUsage, trialEnded) => {
    return organizationUsage.trial && !trialEnded;
  }
);

export const getSelfServiceCounts = createSelector(
  getOrganizationUsage,
  (organizationUsageState) => {
    return organizationUsageState.selfService;
  }
);

export function getTermEndDate(state) {
  return getOrganizationUsage(state).termEndDate;
}

export function getTermStartDate(state) {
  return getOrganizationUsage(state).termStartDate;
}

export function getIncludedUnits(state) {
  return getOrganizationUsage(state).includedUnits;
}

export function getRemainingUnits(state) {
  return getOrganizationUsage(state).remainingUnits;
}

export function getOrganizationUsageCount(state) {
  if (!getIsUserInTrialMode(state)) {
    return getOrganizationUsage(state).orgUsageCount;
  }
}

export const getArePropertyParamsInUrl = createSelector(
  getCurrentParams,
  (currentParams) => !!currentParams.streetAddress
);

export const getUsageCount = createSelector(
  getOrganizationUsage,
  (organizationUsage) => {
    return organizationUsage.usageCount;
  }
);

export function getUsedUnitsCount(state) {
  if (getIsUserInTrialMode(state)) {
    return getIncludedUnits(state) - getRemainingUnits(state);
  } else {
    return getOrganizationUsageCount(state);
  }
}

export function getIsInInactiveTrialMode(state) {
  const isInTrial = getIsUserInTrialMode(state);
  const hasTrialEnded = getTrialEnded(state);
  const endDate = new Date(getTermEndDate(state));
  const today = new Date();
  if (
    hasTrialEnded ||
    (isInTrial &&
      (getRemainingUnits(state) <= 0 ||
        Math.round(calculateDaysDifference(today, endDate)) < 0))
  ) {
    return true;
  } else {
    return false;
  }
}

export const getIsTrialExpiredModalOpen = createSelector(
  getOrganizationUsage,
  getIsInInactiveTrialMode,
  getArePropertyParamsInUrl,
  (organizationUsage, isInInactiveTrial, isResultMode) =>
    isInInactiveTrial &&
    !organizationUsage.isInactiveTrialModalDismissed &&
    isResultMode
);

export function getRemainingTrialDays(state) {
  const endDate = new Date(getTermEndDate(state));
  const today = new Date();
  return Math.ceil(Math.abs(calculateDaysDifference(endDate, today)));
}

function calculateDaysDifference(startDate, endDate) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  return (endDate.getTime() - startDate.getTime()) / oneDay;
}

export function getIsUserInSubscriptionMode(state) {
  return getSubscriptionId(state) && !getIsUserInTrialMode(state);
}

export const getHasAccess = createSelector(
  getOrganizationUsage,
  (organizationUsage) => {
    return organizationUsage.access;
  }
);

export const getHasNoAccess = createSelector(getHasAccess, (hasAccess) => {
  return !hasAccess;
});

export const getHasOrgUsedLessThanIncludedUnits = createSelector(
  getOrganizationUsageCount,
  getIncludedUnits,
  (organizationCount, includedUnits) => organizationCount < includedUnits
);
