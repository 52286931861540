import UTM_CONFIG from 'legacy/utm';

import * as Async from 'legacy/async-pages';

import { handleErrors } from 'legacy/utils/saga';

import { VIEWS } from 'legacy/routes/constants';

import routeHome from 'legacy/routes/home';
import routeLogin from 'legacy/routes/login';
import routeLogout from 'legacy/routes/logout';
import routeConfirmUser from 'legacy/routes/confirm-user';
import forgotPassword from 'legacy/routes/forgot-password';
import routeResetPassword from 'legacy/routes/reset-password';
import routeJoinOrganization from 'legacy/routes/join-organization';
import routeSearch from 'legacy/routes/search';
import { registerEmailRoute, signUpRoute } from 'legacy/routes/sign-up';

import routeDefsReport from 'legacy/routes/route-defs-report';
import routeDefsSharedReport from 'legacy/routes/route-defs-shared-report';
import routeDefsEffectiveDate from 'legacy/routes/route-defs-effective-date';
import { routeSelectPackage } from 'legacy/routes/select-package';
import { newPexpRoutes } from 'src/pexp/routes';
// Sample ROUTES object:
// {
//   <view_id>: {
//     path: '/path/with/:params/:optional?',  = Url w/ params defined (:<name> = required | :<name>? = optional)
//     query: ['accepted', 'query', 'params'], = Accepted query string names
//     loginRequired: bool,                    = Ensure user is logged in before resolving
//     logoutRequired: bool,                   = Ensure user is NOT logged in before resolving
//     saga: <SagaToRunWhenPathIsMatched>      = Saga that is run when the url is visited
//     bypassWrapper: bool,                    = Only run the saga defined in the route, not the saga wrapper
//     component: <ReactComponentToRender>     = The component that is rendered when the route resolves
//   }
// }

export default {
  ...newPexpRoutes,
  ...routeDefsReport,
  ...routeDefsSharedReport,
  ...routeDefsEffectiveDate,
  [VIEWS.HOME]: {
    path: '/',
    saga: handleErrors(routeHome),
    Component: () => null
  },
  [VIEWS.LOGIN]: {
    path: '/login',
    component: Async.Login,
    saga: handleErrors(routeLogin),
    logoutRequired: true
  },
  [VIEWS.FORGOT_PASSWORD]: {
    path: '/forgot-password',
    component: Async.ForgotPassword,
    saga: handleErrors(forgotPassword),
    logoutRequired: true
  },
  [VIEWS.REGISTRATION_EMAIL]: {
    path: '/registration/email',
    query: ['ref', '_ga', 'code'].concat(UTM_CONFIG.UTM_PARAMS),
    saga: handleErrors(registerEmailRoute),
    logoutRequired: true
  },
  [VIEWS.SIGN_UP]: {
    path: '/sign-up',
    query: ['ref', '_ga', 'code'].concat(UTM_CONFIG.UTM_PARAMS),
    saga: handleErrors(signUpRoute),
    component: Async.SignUp,
    logoutRequired: true
  },
  [VIEWS.SELECT_PACKAGE]: {
    path: '/select-package',
    query: ['ref', '_ga', 'code'].concat(UTM_CONFIG.UTM_PARAMS),
    component: Async.SelectPackage,
    saga: handleErrors(routeSelectPackage),
    loginRequired: true
  },
  [VIEWS.LOGOUT]: {
    path: '/logout',
    saga: handleErrors(routeLogout)
  },
  [VIEWS.SEARCH]: {
    path: '/search',
    query: [
      'street_address',
      'zipcode',
      'unit',
      'address_slug',
      'query',
      'listing_id',
      'listing_source'
    ],
    component: Async.Search,
    saga: handleErrors(routeSearch),
    loginRequired: true
  },
  [VIEWS.SELECT_ORGANIZATION]: {
    path: '/select-team',
    component: Async.SelectOrganization,
    loginRequired: true
  },
  [VIEWS.JOIN_ORGANIZATION]: {
    path: '/join',
    query: ['ref', '_ga', 'code'].concat(UTM_CONFIG.UTM_PARAMS),
    component: Async.JoinOrganization,
    saga: handleErrors(routeJoinOrganization),
    loginRequired: true
  },
  [VIEWS.INVITE_TEAM]: {
    path: '/invite-team',
    component: Async.InviteTeam,
    loginRequired: true
  },
  [VIEWS.CONFIRM_USER]: {
    path: '/confirm-user',
    query: ['token'],
    component: Async.ConfirmUser,
    saga: handleErrors(routeConfirmUser)
  },
  [VIEWS.RESET_PASSWORD]: {
    path: '/reset-password',
    query: ['token'],
    component: Async.ResetPassword,
    saga: handleErrors(routeResetPassword)
  }
};
