import { watchLatest } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { call, put, select } from 'redux-saga/effects';
import apiUtil from 'legacy/utils/api';
import HC_CONSTANTS from 'HC_CONSTANTS';

import {
  COMPS_FARM_UPDATE_SUCCESS,
  COMPS_REFRESH_READY_FOR_RECOMPUTE,
  COMPS_KEYWORD_SEARCH_SUBMIT,
  compsKeywordSearchSubmitSuccess,
  compsKeywordSearchSubmitFailure
} from '../actions/comps.actions';
import {
  getCompsAddressIdToCerberusIdMapping,
  getCompsCerberusIdToAddressIdMapping,
  getCompsKeywordSearchKeywords
} from 'selectors/comps';
import { getIsViewingSharedReport } from 'selectors/property-explorer';
import { getSharedReportUid } from 'selectors/share-report';
import {
  RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT,
  RENTAL_COMPS_FARM_UPDATE_SUCCESS,
  RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE,
  rentalCompsKeywordSearchSubmitSuccess,
  rentalCompsKeywordSearchSubmitFailure
} from 'actions/rental-comps';
import {
  getRentalCompsAddressIdToCerberusIdMapping,
  getRentalCompsCerberusIdToAddressIdMapping,
  getRentalCompsKeywordSearchKeywords
} from 'selectors/rental-comps';

const { KEYWORD_SEARCH_SERVICE, VRSHARE_PROXY_URL } = HC_CONSTANTS;

export function* handleKeywordSearchComps(action) {
  try {
    const addressIdsToListingIds = yield select(
      getCompsAddressIdToCerberusIdMapping
    );
    const cerberusIdsToAddressIds = yield select(
      getCompsCerberusIdToAddressIdMapping
    );
    const keywords = yield select(getCompsKeywordSearchKeywords);
    if (keywords.length) {
      const payload = {
        keywords,
        matchRemarksPublic: true,
        matchRemarksPrivate: false,
        returnRemarks: false,
        listings: []
      };
      for (const addressId in addressIdsToListingIds) {
        payload.listings.push({
          cerberusId: addressIdsToListingIds[addressId]
        });
      }
      const isViewingSharedReport = yield select(getIsViewingSharedReport);
      const uid = yield select(getSharedReportUid);
      const results = isViewingSharedReport
        ? yield call(
            apiUtil.POST,
            `${VRSHARE_PROXY_URL}/emporium/vrshare/listing_keyword_search`,
            {
              ...payload,
              uid
            }
          )
        : yield call(
            apiUtil.POST,
            `${KEYWORD_SEARCH_SERVICE}/by_listings`,
            payload
          );
      const matchesByAddressId = {};
      results.forEach(({ cerberusId, remarksPublicMatch }) => {
        if (remarksPublicMatch) {
          matchesByAddressId[cerberusIdsToAddressIds[cerberusId]] = true;
        }
      });
      yield put(compsKeywordSearchSubmitSuccess(matchesByAddressId));
    }
  } catch (e) {
    yield put(compsKeywordSearchSubmitFailure());
    console.error(e);
    report('Keyword Search: Saga Failed to Fetch Data', { e, action });
  }
}

export function* handleKeywordSearchRentalComps(action) {
  try {
    const addressIdsToListingIds = yield select(
      getRentalCompsAddressIdToCerberusIdMapping
    );
    const cerberusIdsToAddressIds = yield select(
      getRentalCompsCerberusIdToAddressIdMapping
    );
    const keywords = yield select(getRentalCompsKeywordSearchKeywords);
    if (keywords.length) {
      const payload = {
        keywords,
        matchRemarksPublic: true,
        matchRemarksPrivate: false,
        returnRemarks: false,
        listings: []
      };
      for (const addressId in addressIdsToListingIds) {
        payload.listings.push({
          cerberusId: addressIdsToListingIds[addressId]
        });
      }
      if (payload.listings.length) {
        const isViewingSharedReport = yield select(getIsViewingSharedReport);
        const uid = yield select(getSharedReportUid);
        const results = isViewingSharedReport
          ? yield call(
              apiUtil.POST,
              `${VRSHARE_PROXY_URL}/emporium/vrshare/listing_keyword_search`,
              {
                ...payload,
                uid
              }
            )
          : yield call(
              apiUtil.POST,
              `${KEYWORD_SEARCH_SERVICE}/by_listings`,
              payload
            );
        const matchesByAddressId = {};
        results.forEach(({ cerberusId, remarksPublicMatch }) => {
          if (remarksPublicMatch) {
            matchesByAddressId[cerberusIdsToAddressIds[cerberusId]] = true;
          }
        });
        yield put(rentalCompsKeywordSearchSubmitSuccess(matchesByAddressId));
      }
    }
  } catch (e) {
    yield put(rentalCompsKeywordSearchSubmitFailure());
    console.error(e);
    report('Rental Keyword Search: Saga Failed to Fetch Data', { e, action });
  }
}

export default function registerKeywordSearchSagas() {
  watchLatest(
    [
      COMPS_KEYWORD_SEARCH_SUBMIT,
      COMPS_FARM_UPDATE_SUCCESS,
      COMPS_REFRESH_READY_FOR_RECOMPUTE
    ],
    handleKeywordSearchComps
  );
  watchLatest(
    [
      RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT,
      RENTAL_COMPS_FARM_UPDATE_SUCCESS,
      RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE
    ],
    handleKeywordSearchRentalComps
  );
}
