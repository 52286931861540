import { STATUSES } from 'legacy/appstore/constants';

import { createSelector } from 'reselect';

export const getRecentReportsState = (state) => state.recentReports;

export const getRecentReports = createSelector(
  getRecentReportsState,
  (reportsState) => reportsState.reports
);

export const getIsRecentReportsStatusInit = createSelector(
  getRecentReportsState,
  (reportsState) => reportsState.status === STATUSES.INIT
);

export const getIsRecentReportsStatusLoaded = createSelector(
  getRecentReportsState,
  (reportsState) => reportsState.status === STATUSES.SUCCESS
);

export const getActiveRecentReports = createSelector(
  getRecentReports,
  (reports) => reports.filter((report) => report.isActive)
);

// Filter and format reports for display in <address-search> dropdown
export const getRecentReportsForAddressSearch = createSelector(
  getActiveRecentReports,
  (activeReports) => {
    return activeReports.map((report) => {
      const fullLine = report.unit
        ? `${report.streetAddress}, ${report.unit}, ${report.city}, ${report.state} ${report.zipcode}`
        : `${report.streetAddress}, ${report.city}, ${report.state} ${report.zipcode}`;

      return {
        id: report.id,
        fullLine,
        report
      };
    });
  }
);
