import { put } from 'redux-saga/effects';
import HC_CONSTANTS from 'HC_CONSTANTS';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { VIEWS } from 'legacy/routes/constants';

export function* registerEmailRoute({ params, query }) {
  yield put(
    routeChange({
      view: VIEWS.SIGN_UP,
      params,
      query,
      options: { inherit: false }
    })
  );
}

export function* signUpRoute({ params, query }) {
  // SECURITY: @jnettleman - Known url for migration redirect
  /* eslint-disable scanjs-rules/assign_to_href */
  /* eslint-disable xss/no-location-href-assign */
  window.location.href = `${HC_CONSTANTS.HCS_PLATFORM_URL}/pexp/sign-up`;
  /* eslint-enable scanjs-rules/assign_to_href */
  /* eslint-enable xss/no-location-href-assign */
}
