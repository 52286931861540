import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'redux-form';

import { RTThemedCheckbox as RTCheckbox } from '@hc/component-lib/hclib/rt-themed';

import { CheckboxField as theme } from 'legacy/css-modules/generic';

const Checkbox = ({ input: { value, ...input }, ...props }) => {
  return <RTCheckbox {...props} {...input} checked={value} theme={theme} />;
};

const HcCheckbox = ({ name, fullWidth, label, ...props }) => {
  return (
    <div className={theme.Container}>
      <div>
        <Field
          className={classNames({ [theme.Checkbox]: !fullWidth })}
          name={name}
          component={Checkbox}
          {...props}
        />
      </div>
      <div className={theme.Label}>{label && label}</div>
    </div>
  );
};

HcCheckbox.defaultProps = {
  fullWidth: false
};

HcCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool
};

export default HcCheckbox;
