import { CompFields } from 'src/lib/report-api/types/CompFields';
import { activeDaysOnMarketColumn } from './activeDaysOnMarketColumn';
import { addressColumn } from './addressColumn';
import { basementColumn } from './basementColumn';
import { bathroomsColumn } from './bathroomsColumn';
import { bedroomsColumn } from './bedroomsColumn';
import { cumulativeDaysOnMarketColumn } from './cumulativeDaysOnMarketColumn';
import { currentValueColumn } from './currentValueColumn';
import { distanceColumn } from './distanceColumn';
import { distressedColumn } from './distressedColumn';
import { flipColumn } from './flipColumn';
import { garageSpacesColumn } from './garageSpacesColumn';
import { grossLivingAreaColumn } from './grossLivingAreaColumn';
import { listDateColumn } from './listDateColumn';
import { listPriceColumn } from './listPriceColumn';
import { listPricePerSqftColumn } from './listPricePerSqftColumn';
import { poolColumn } from './poolColumn';
import { propertyStatusColumn } from './propertyStatusColumn';
import { propertyTypeColumn } from './propertyTypeColumn';
import { salesDateColumn } from './salesDateColumn';
import { salesPriceColumn } from './salesPriceColumn';
import { salesPricePerSqftColumn } from './salesPricePerSqftColumn';
import { similarityColumn } from './similarityColumn';
import { siteAreaColumn } from './siteAreaColumn';
import { storiesColumn } from './storiesColumn';
import { subdivisionColumn } from './subdivisionColumn';
import { yearBuiltColumn } from './yearBuiltColumn';

export const COLS_BY_COMP_FIELD = {
  [CompFields.address]: addressColumn,
  [CompFields.similarity]: similarityColumn,
  [CompFields.distanceMiles]: distanceColumn,
  [CompFields.bedrooms]: bedroomsColumn,
  [CompFields.bathrooms]: bathroomsColumn,
  [CompFields.grossLivingAreaSqft]: grossLivingAreaColumn,
  [CompFields.siteAreaSqft]: siteAreaColumn,
  [CompFields.propertyType]: propertyTypeColumn,
  [CompFields.yearBuilt]: yearBuiltColumn,
  [CompFields.propertyStatus]: propertyStatusColumn,
  [CompFields.subdivisionName]: subdivisionColumn,
  [CompFields.salesDate]: salesDateColumn,
  [CompFields.salesPrice]: salesPriceColumn,
  [CompFields.pricePerSqft]: salesPricePerSqftColumn,
  [CompFields.pricePerSqftNonDisclosure]: listPricePerSqftColumn,
  [CompFields.lastListDate]: listDateColumn,
  [CompFields.lastListPrice]: listPriceColumn,
  [CompFields.activeDaysOnMarket]: activeDaysOnMarketColumn,
  [CompFields.cumulativeDaysOnMarket]: cumulativeDaysOnMarketColumn,
  [CompFields.currentValue]: currentValueColumn,
  [CompFields.pool]: poolColumn,
  [CompFields.garageNumCars]: garageSpacesColumn,
  [CompFields.stories]: storiesColumn,
  [CompFields.basement]: basementColumn,
  [CompFields.isDistressed]: distressedColumn,
  [CompFields.flips]: flipColumn
};
