import get from 'lodash/get';
import forOwn from 'lodash/forOwn';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import { COMP_ID_KEY } from 'legacy/appstore/constants';

import { FILTERS, FILTER_TYPES } from 'legacy/utils/filters';
import { removeSpecialChars } from 'legacy/utils/strings';

const IGNORE_FILTER = {
  // List date filter is only considered for "Active" and "Pending" Comps
  lastListDate: (comp, filters) => comp.propertyStatus === 'Sold',
  // List date filter is only considered for "Active" and "Pending" Comps
  lastListDateRental: (comp, filters) => comp.propertyStatusRental === 'Leased',
  // Sold date filter is only considered for "Sold" Comps
  salesDate: (comp, filters) => {
    return (
      comp.propertyStatus === 'Active' || comp.propertyStatus === 'Pending'
    );
  },
  // Leased date filter is only considered for "Sold" Comps
  leasedDate: (comp, filters) =>
    comp.propertyStatusRental === 'Active' ||
    comp.propertyStatusRental === 'Pending'
};

const _ignoreFilter = (comp, filters, key) =>
  filters &&
  IGNORE_FILTER.hasOwnProperty(key) &&
  IGNORE_FILTER[key](comp, filters);

const COMPUTED_FIELDS = {
  similarity: (comp) => {
    return comp.similarityLevelAdjusted;
  },
  similarityLevel: (comp) => {
    const { similarityLevel: level } = comp;
    if (level === 'low') {
      return 1;
    } else if (level === 'moderate') {
      return 2;
    } else if (level === 'high') {
      return 3;
    }
  },
  similarityScoreAdjusted: (comp) =>
    comp.similarityScoreAdjusted !== null &&
    comp.similarityScoreAdjusted !== undefined
      ? comp.similarityScoreAdjusted
      : comp.similarityScore,
  pricePerSqft: (comp) =>
    comp.salesPrice && comp.grossLivingAreaSqft
      ? comp.salesPrice / comp.grossLivingAreaSqft
      : null,
  closePricePerSqFt: (comp) =>
    comp.salesPrice && comp.grossLivingAreaSqft
      ? comp.salesPrice / comp.grossLivingAreaSqft
      : null,
  listPricePerSqFt: (comp) =>
    comp.lastListPrice && comp.grossLivingAreaSqft
      ? comp.lastListPrice / comp.grossLivingAreaSqft
      : null,
  pricePerSqftRentalListed: (comp) =>
    comp.lastListPriceRental && comp.grossLivingAreaSqft
      ? comp.lastListPriceRental / comp.grossLivingAreaSqft
      : null,
  pricePerSqftRentalLeased: (comp) =>
    comp.leasedPrice && comp.grossLivingAreaSqft
      ? comp.leasedPrice / comp.grossLivingAreaSqft
      : null,
  // List date filter is only considered for "Active" and "Pending" Comps
  lastListDate: (comp, filters) =>
    _ignoreFilter(comp, filters, 'lastListDate') || comp.lastListDate,
  lastListPrice: (comp, filters) => comp.lastListPrice,
  livingArea: (comp, filters) => comp.grossLivingAreaSqft,
  daysOnMarketActive: (comp, filters) => comp.activeDaysOnMarket,
  daysOnMarketCumulative: (comp, filters) => comp.cumulativeDaysOnMarket,
  lotSize: (comp, filters) => comp.siteAreaSqft,
  // List date filter is only considered for "Active" and "Pending" Comps
  lastListDateRental: (comp, filters) =>
    _ignoreFilter(comp, filters, 'lastListDateRental') ||
    comp.lastListDateRental,
  // Sold date filter is only considered for "Sold" Comps
  salesDate: (comp, filters) =>
    _ignoreFilter(comp, filters, 'salesDate') || comp.salesDate,
  salesPrice: (comp, filters) => comp.salesPrice,
  // Leased date filter is only considered for "Leased" Comps
  leasedDate: (comp, filters) =>
    _ignoreFilter(comp, filters, 'leasedDate') || comp.leasedDate,
  rentalAvm: (comp, filters) => get(comp, ['rentalAvm', 'priceMean'])
};

const _minmax = (value, limits, allowNull = false) => {
  const [min, max] = limits;
  if (max === null && min === null) return true;
  const maxSet = max !== null && max !== undefined;
  const minSet = min !== null && min !== undefined;
  if ((maxSet || minSet) && allowNull !== true && value === null) return false;
  // Handle case where one side of range is empty, consider empty infinity
  if (!minSet && maxSet && value <= max) return true;
  if (!maxSet && minSet && value >= min) return true;

  if (maxSet && value > max) return false;
  if (minSet && value < min) return false;
  return true;
};

const _included = (value, includedList) =>
  includedList.length ? includedList.indexOf(value) > -1 : true;

const _excluded = (value, excludedList) =>
  excludedList.length ? excludedList.indexOf(value) === -1 : true;

const _boolean = (value, bool) =>
  bool ? !!value === bool : value === null || bool === null || !!value === bool;

const _search = (comp, words) => {
  const SEARCH_FIELDS = ['streetAddress', 'city', 'state'];
  let match = false;
  forEach(SEARCH_FIELDS, (field) => {
    if (comp[field]) {
      const value = comp[field].toLowerCase();
      forEach(words, (word) => {
        if (word && value.indexOf(word) > -1) {
          match = true;
          return false;
        }
      });
      if (match === true) {
        return false;
      }
    }
  });
  return match;
};

const FILTER_TYPE_METHODS = {
  [FILTER_TYPES.MINMAX]: _minmax,
  [FILTER_TYPES.SEARCH]: _search,
  [FILTER_TYPES.INCLUDE]: _included,
  [FILTER_TYPES.EXCLUDE]: _excluded,
  [FILTER_TYPES.BOOLEAN]: _boolean
};

export const getPropertyFromFilterKey = (key) => {
  const keyParts = key.split('_');
  return keyParts.slice(0, keyParts.length - 1).join('_');
};

export const filterComps = (farm, filters) => {
  let results = [];
  forOwn(farm, (comp, compId) => {
    let addComp = true;
    forOwn(filters, (compare, propertyKey) => {
      const filterConfig = FILTERS[propertyKey];
      if (filterConfig) {
        // Get the appropriate filter method
        const filterMethod = FILTER_TYPE_METHODS[filterConfig.type];
        // Value falls back to the comp object if the attribute does not exist
        // This allows you to write filter methods that depend on multiple attributes
        const value = COMPUTED_FIELDS.hasOwnProperty(propertyKey)
          ? COMPUTED_FIELDS[propertyKey](comp, filters)
          : get(comp, (filterConfig.attribute || propertyKey).split('.'), comp);
        // If the filter returns false, break the loop and do not add the comp
        if (
          filterMethod &&
          filterMethod(
            value,
            compare,
            IGNORE_FILTER.hasOwnProperty(propertyKey) &&
              IGNORE_FILTER[propertyKey](comp, filters)
          ) === false
        ) {
          addComp = false;
          return false;
        }
      }
    });
    if (addComp === true) {
      results.push(comp[COMP_ID_KEY]);
    }
  });
  return results;
};

export const searchComps = (compIdList, farm, search, filters, sort) => {
  let results = [];
  const current = search.current.toLowerCase();
  const previous = search.previous.toLowerCase();
  // Remove special characters, replace with whitespace, and split search string on whitespace characters
  const words = removeSpecialChars(current, ' ').split(/\s+/);
  if (!previous || current.indexOf(previous) > -1) {
    // Search through the previously filtered list if the current search builds on the previous search
    forEach(compIdList, (compId) => {
      const comp = farm[compId];
      if (_search(comp, words) === true) {
        results.push(comp[COMP_ID_KEY]);
      }
    });
  } else {
    // Search through the filtered farm if the previous search isn't contained by the current
    forOwn(filterComps(farm, filters), (compId) => {
      const comp = farm[compId];
      if (_search(comp, words) === true) {
        results.push(comp[COMP_ID_KEY]);
      }
    });
    // Re-sort results because they came from the unsorted farm
    results = sortComps(results, farm, sort);
  }
  return results;
};

const NULL_SORT_VALS = {
  asc: 1e15,
  desc: -1e15
};

const getSortVal = (comp, sort) => {
  if (!comp) return undefined;
  let { attr, order } = sort;
  const val = COMPUTED_FIELDS.hasOwnProperty(attr)
    ? COMPUTED_FIELDS[attr](comp)
    : comp[attr];

  return val === null || val === undefined ? NULL_SORT_VALS[order] : val;
};

export const sortComps = (compIdList, farm, sort, sortSecondary) => {
  const primaryValueFunc = (compId) => getSortVal(farm[compId], sort);
  const salesDateSort = (compId) =>
    getSortVal(farm[compId], { attr: 'salesDate', order: 'desc' });
  const leasedDateSort = (compId) =>
    getSortVal(farm[compId], { attr: 'leasedDate', order: 'desc' });
  if (sortSecondary) {
    const secondaryValueFunc = (compId) =>
      getSortVal(farm[compId], sortSecondary);
    return orderBy(
      compIdList,
      [primaryValueFunc, secondaryValueFunc, salesDateSort, leasedDateSort],
      [sort.order, sortSecondary.order, 'desc', 'desc']
    );
  } else {
    return orderBy(
      compIdList,
      [primaryValueFunc, salesDateSort, leasedDateSort],
      [sort.order, 'desc', 'desc']
    );
  }
};

export const filterSearchSortComps = (farm, filters, search, sort) => {
  // Get ids that match filters
  let compIdList = filterComps(farm, filters, false);
  if (!isEmpty(search) && search.current) {
    // Apply search
    compIdList = searchComps(compIdList, farm, search, filters, sort, false);
  }
  if (!isEmpty(sort) && sort.attr && sort.order) {
    // sort the results
    compIdList = sortComps(compIdList, farm, sort, false);
  }
  return compIdList;
};
