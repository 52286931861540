import React, { FunctionComponent } from 'react';
import { HcsQueryClientProvider } from './HcsQueryClientProvider';
import { SearchByMlsNumberProvider } from 'src/lib/search-by-mls-number/context';

interface Props {
  children: React.ReactNode;
}

export const ReportAPIProviders: FunctionComponent<Props> = ({ children }) => (
  <HcsQueryClientProvider>
    <SearchByMlsNumberProvider>{children}</SearchByMlsNumberProvider>
  </HcsQueryClientProvider>
);
