import React from 'react';
import classNames from 'classnames';
import styles from './CompFarmListTableOptionsLauncher.css';
import { TableOptionsIcon } from 'src/lib/inline-svgs';
import { useDialogActiveState } from 'src/lib/hooks';
import { CompFarmListTableOptionsDialog } from './CompFarmListTableOptionsDialog';

interface Props {
  dataHcName: string;
  className?: string;
}
export const CompFarmListTableOptionsLauncher = ({
  dataHcName,
  className
}: Props) => {
  const { active, handleDialogOpen, handleDialogClose } =
    useDialogActiveState();
  return (
    <>
      <button
        data-hc-name={dataHcName}
        className={classNames(
          styles.CompFarmListTableOptionsLauncher,
          className
        )}
        onClick={handleDialogOpen}
      >
        <TableOptionsIcon dataHcName={`${dataHcName}-options`} height="12px" />
      </button>
      <CompFarmListTableOptionsDialog
        dataHcName={`${dataHcName}-dialog`}
        active={active}
        onClose={handleDialogClose}
      />
    </>
  );
};
