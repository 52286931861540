import React from 'react';
// TODO: Type the router or replace it
import { Link } from '@hc/redux-saga-router-plus';
import {
  CompDocument,
  DocumentRoles,
  FarmDocument,
  useDocumentRole
} from 'src/lib/report-api';
import { SectionHeader } from 'src/pexp/layout/SectionHeader';
import { CompPropertyCard } from 'src/lib/report-api/features/CompPropertyCard';
import { Button } from 'src/lib/components/Button';
import styles from './CompsSummary.css';
import { VIEWS } from 'src/pexp/routes';
import { CompSummaryMap } from 'src/lib/report-api/features/CompSelectionMap/CompSummaryMap';

interface Props {
  reportId: number;
}
export const CompsSummary = ({ reportId }: Props) => {
  const { data: compDocuments } = useDocumentRole<CompDocument>(
    reportId,
    DocumentRoles.Comp
  );
  const {
    data: [farmDocument]
  } = useDocumentRole<FarmDocument>(reportId, DocumentRoles.Farm);
  return (
    <section data-hc-name="comps-section">
      <SectionHeader dataHcName="sold-comps-header-text">
        Sold &amp; Active Comparables
      </SectionHeader>
      <div className={styles.MapContainer}>
        <CompSummaryMap
          reportId={reportId}
          dataHcName="comp-summary-map"
          scrollZoom={false}
        />
      </div>
      <div
        className={styles.CompCardsHorizontal}
        data-hc-name="sold-comps-gallery"
      >
        {compDocuments.length
          ? compDocuments.map((document) => {
              return (
                <CompPropertyCard
                  key={`selected-comp-property-card-${document.documentId}`}
                  dataHcName={`suggested-comp-card-${document.documentId}`}
                  reportId={reportId}
                  documentId={document.documentId}
                  className={styles.PropertyCard}
                />
              );
            })
          : Object.keys(farmDocument?.data || {}).length
          ? Object.keys(farmDocument?.data || {})
              .slice(0, 6)
              .map((compId) => {
                const compSchema = farmDocument?.data[compId];
                if (!compSchema) return null;
                return (
                  <CompPropertyCard
                    key={`suggested-comp-property-card-${compId}`}
                    reportId={reportId}
                    dataHcName={`suggested-comp-card-${compId}`}
                    compSchema={compSchema}
                    className={styles.PropertyCard}
                    selectButton={false}
                  />
                );
              })
          : null}
      </div>
      <div className={styles.ButtonContainer}>
        <Link view={VIEWS.EFFECTIVE_DATE_COMPS} options={{ inherit: true }}>
          <Button dataHcName="select-comps-button" label="Select Comps" />
        </Link>
      </div>
    </section>
  );
};
