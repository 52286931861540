import { combineReducers } from 'redux';

import {
  RESET_APP_STATE,
  RESET_VR_STATE,
  clearOnActions
} from '../clear-reducer-on-actions';
import { SELECT_ORGANIZATION } from 'actions/account-details';
import { appHeaderReducer } from 'reducers/app-header';
import { organizationUsageReducer } from 'reducers/organization-usage';
import { subscriptionReducer } from 'reducers/subscription';
import { accountDetailsReducer } from 'reducers/account-details';
import { messagesReducer } from 'reducers/messages';
import { authReducer } from 'reducers/auth';

import pdfDownloadIdReducer from 'reducers/pdf-download-id';
import personalizationReducer from 'reducers/personalization';
import properties from 'reducers/property';
import toastReducer from 'reducers/toast';
import reduxFormReducer from 'reducers/redux-form';
import errorReducer from 'reducers/error';
import recentReports from 'reducers/recent-reports';
import mapDraw from 'reducers/map-draw';
import reportStatus from 'reducers/report-status';
import avms from 'reducers/avms';
import reportDataByAvm from 'reducers/report-data-by-avm';
import reportDataShared from 'reducers/report-data-shared';
import photos from 'reducers/photos';
import subject from 'reducers/subject';
import rentalReport from 'reducers/rental-report';
import support from 'reducers/support';
import downloadReport from 'reducers/download-report';
import reportPermissions from 'reducers/report-permissions';
import shareReport from 'reducers/share-report';
import ui from 'reducers/ui';
import cobranding from 'reducers/cobranding';
import matchMedia from 'reducers/match-media';
import signUp from 'reducers/sign-up';
import mlsListings from 'reducers/mls-listings';
import propertyExplorer from 'reducers/property-explorer';
import forgotPassword from 'reducers/forgot-password';
import archivedReportsReducer from 'reducers/archived-reports';
import hcModels from 'reducers/hcmodels';
import chartData from 'reducers/chart-data';
import preferences from 'reducers/preferences';
import nearbyProperties from 'reducers/nearby-properties';
import { selfService } from './self-service.reducer';
import { mlsReducer } from './mls.reducer';
import { listingsReducer } from './listings.reducer';
import { mlsCoverageReducer } from './mls-coverage.reducer';
import { featureFlagsReducer } from './feature-flags.reducer';
import { orgReportsReducer } from './org-reports.reducer';
import { createReportReducer } from './create-report.reducer';
import { effectiveDateReducer } from './effective-date.reducer';
import { clientIdSearchReducer } from './clientIdSearch.reducer';
import { performanceReducer } from './performance.reducer';
import { searchByMlsNumberReducer } from './search-by-mls-number.reducer';
import {
  reportApiReducer,
  REPORT_API_REDUCER_KEY
} from '../../../lib/report-api';
import { STREET_VIEW_REDUCER_KEY } from '../../../lib/street-view/reducer';
import { streetViewReducer } from '../../../lib/street-view/reducer/streetView.reducer';
import { huellReducer, HUELL_REDUCER_KEY } from '../../../lib/huell/reducer';
import {
  keywordSearchReducer,
  KEYWORD_SEARCH_REDUCER_KEY
} from 'src/lib/keyword-search/reducer';

import {
  propertyResultReducer,
  mobileBottomBarReducer,
  landingPageReducer
} from 'reducers/property-search';
import router from '@hc/redux-saga-router-plus/hclib/reducer';

// Shared Reducer Proof of Concept
import { sharedReducerHOR } from 'legacy/utils/reducers';
import compsShared, {
  ACTION_MAPPING_RENTAL_COMPS,
  ACTION_MAPPING_COMPS
} from 'reducers/comps-shared';

const compsReducerMain = sharedReducerHOR(compsShared, ACTION_MAPPING_COMPS);
const compsReducerRental = sharedReducerHOR(
  compsShared,
  ACTION_MAPPING_RENTAL_COMPS
);

/** Please keep in alpha order */
export default combineReducers({
  pdfDownloadId: pdfDownloadIdReducer,
  [STREET_VIEW_REDUCER_KEY]: streetViewReducer,
  [REPORT_API_REDUCER_KEY]: reportApiReducer,
  [HUELL_REDUCER_KEY]: huellReducer,
  [KEYWORD_SEARCH_REDUCER_KEY]: keywordSearchReducer,
  performance: clearOnActions(performanceReducer, [
    RESET_APP_STATE,
    RESET_VR_STATE
  ]),
  searchByMlsNumber: clearOnActions(searchByMlsNumberReducer, [
    RESET_APP_STATE,
    RESET_VR_STATE
  ]),
  effectiveDate: clearOnActions(effectiveDateReducer, [
    RESET_APP_STATE,
    RESET_VR_STATE
  ]),
  accountDetails: clearOnActions(accountDetailsReducer, [RESET_APP_STATE]),
  appHeaderToggleComponents: clearOnActions(appHeaderReducer, [
    RESET_APP_STATE
  ]),
  archivedReports: clearOnActions(archivedReportsReducer, [
    RESET_APP_STATE,
    SELECT_ORGANIZATION
  ]),
  auth: clearOnActions(authReducer, [RESET_APP_STATE]),
  avms: clearOnActions(avms, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  browser: matchMedia,
  chartData: clearOnActions(chartData, [RESET_APP_STATE, RESET_VR_STATE]),
  clientIdSearch: clearOnActions(clientIdSearchReducer, [RESET_APP_STATE]),
  createReport: clearOnActions(createReportReducer, [RESET_APP_STATE]),
  cobranding: clearOnActions(cobranding, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  comps: clearOnActions(compsReducerMain, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  downloadReport: clearOnActions(downloadReport, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  error: clearOnActions(errorReducer, [RESET_APP_STATE]),
  featureFlags: clearOnActions(featureFlagsReducer, [RESET_APP_STATE]),
  forgotPassword,
  form: reduxFormReducer,
  hcModels: clearOnActions(hcModels, [RESET_APP_STATE, RESET_VR_STATE]),
  listings: clearOnActions(listingsReducer, [RESET_APP_STATE, RESET_VR_STATE]),
  mlsCoverage: clearOnActions(mlsCoverageReducer, [
    RESET_APP_STATE,
    RESET_VR_STATE
  ]),
  mls: clearOnActions(mlsReducer, [RESET_APP_STATE, RESET_VR_STATE]),
  mapDraw: clearOnActions(mapDraw, [RESET_APP_STATE, RESET_VR_STATE]),
  messages: clearOnActions(messagesReducer, [
    RESET_APP_STATE,
    SELECT_ORGANIZATION
  ]),
  mlsListings: clearOnActions(mlsListings, [RESET_APP_STATE, RESET_VR_STATE]),
  nearbyProperties: clearOnActions(nearbyProperties, [
    RESET_APP_STATE,
    RESET_VR_STATE
  ]),
  organizationUsage: clearOnActions(organizationUsageReducer, [
    RESET_APP_STATE,
    SELECT_ORGANIZATION
  ]),
  orgReports: clearOnActions(orgReportsReducer, [RESET_APP_STATE]),
  personalization: clearOnActions(personalizationReducer, [
    RESET_APP_STATE,
    SELECT_ORGANIZATION
  ]),
  photos: clearOnActions(photos, [RESET_APP_STATE]),
  preferences: clearOnActions(preferences, [RESET_APP_STATE]),
  properties: clearOnActions(properties, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  recentReports: clearOnActions(recentReports, [
    RESET_APP_STATE,
    SELECT_ORGANIZATION
  ]),
  rentalComps: clearOnActions(compsReducerRental, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  rentalReport: clearOnActions(rentalReport, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  reportDataByAvm: clearOnActions(reportDataByAvm, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  reportDataShared: clearOnActions(reportDataShared, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  reportPermissions: clearOnActions(reportPermissions, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  reportStatus: clearOnActions(reportStatus, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  router,
  search: combineReducers({
    propertyResult: clearOnActions(propertyResultReducer, [
      RESET_APP_STATE,
      SELECT_ORGANIZATION
    ]),
    isMobileBottomBarExpanded: clearOnActions(mobileBottomBarReducer, [
      RESET_APP_STATE
    ]),
    landingPage: clearOnActions(landingPageReducer, [RESET_APP_STATE])
  }),
  shareReport: clearOnActions(shareReport, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ]),
  signUp,
  subject: clearOnActions(subject, [RESET_APP_STATE, SELECT_ORGANIZATION]),
  subscriptionDetails: clearOnActions(subscriptionReducer, [
    RESET_APP_STATE,
    SELECT_ORGANIZATION
  ]),
  support: clearOnActions(support, [RESET_APP_STATE]),
  toast: clearOnActions(toastReducer, [RESET_APP_STATE]),
  ui: clearOnActions(ui, [RESET_APP_STATE]),
  selfService: clearOnActions(selfService, [RESET_APP_STATE]),
  propertyExplorer: clearOnActions(propertyExplorer, [
    RESET_APP_STATE,
    RESET_VR_STATE,
    SELECT_ORGANIZATION
  ])
});
