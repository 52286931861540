import { connect } from 'react-redux';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

import { getCurrentReportView } from 'selectors/property-explorer';

import PropertyHeader from 'legacy/components/PropertyHeader';

const mapStateToProps = (state) => {
  return {
    viewReport: getCurrentReportView(state)
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    onClickBack: () => {
      dispatchProps.routeChange({
        view: stateProps.viewReport,
        options: {
          inherit: true
        }
      });
    },
    // Own props should overwrite defaults
    ...ownProps
  };
};

export default connect(
  mapStateToProps,
  { routeChange },
  mergeProps
)(PropertyHeader);
