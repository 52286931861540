export const SCREEN_RESIZE = 'SCREEN_RESIZE';
/**
 *
 * @param payload: Object, with following properties:
 * mediaQuery, screenWidth (window.innerWidth), screenHeight (window.innerHeight), screenSize
 * @returns {{type: string, payload: {}}}
 */
export function screenResize(payload) {
  return { type: SCREEN_RESIZE, payload };
}
