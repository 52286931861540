import { watchEvery } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { select, put } from 'redux-saga/effects';

import { VIEWS } from 'legacy/routes/constants';

import { INITIALIZE_JOIN_ORGANIZATION } from 'actions/join-organization';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

export function* handleInitializeJoinOrganization(action) {
  try {
    const params = action.payload;
    const { code } = params;
    const pendingUser = yield select((state) => state.signUp.pendingUser);
    const pendingUserEmail = pendingUser.email;

    if (!code && !pendingUserEmail) {
      yield put(
        routeChange({ view: VIEWS.SEARCH, options: { inherit: false } })
      );
    } else if (code && !pendingUserEmail) {
      yield put(
        routeChange({
          view: VIEWS.SIGN_UP,
          query: params,
          options: { inherit: false }
        })
      );
    }
  } catch (e) {
    console.error(e);
    report('Initialize Join Organization', { error: e });
  }
}

export default function registerJoinOrganizationSaga() {
  watchEvery({
    [INITIALIZE_JOIN_ORGANIZATION]: handleInitializeJoinOrganization
  });
}
