import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import {
  CompFarmList,
  ListViewType
} from 'src/lib/report-api/features/CompFarmList';
import { ResizableFlexColumn } from 'src/lib/components/ResizableFlexColumn/ResizableFlexColumn';
import styles from './CompSelectionPage.css';
import { CompSelectionMap } from 'src/lib/report-api/features/CompSelectionMap/CompSelectionMap';
import { CompSchema } from 'src/lib/report-api/types/schemas';
import { Marker } from 'react-map-gl';
import { CompMarker } from 'src/lib/components/MapMarkers/CompMarker';
import { formatNumberAbbrev } from 'src/lib/utils';
import { getMarketPrice } from 'src/lib/utils/listingStatus';
import { SubjectDetailsHeader } from 'src/lib/report-api/features/SubjectDetailsHeader';
import { CompSearch } from '../CompSearch';
import { CompControls } from '../CompControls';
import { useCompFilterBarState } from '../CompFilterBar';
import { useComponentDidMount } from 'src/lib/hooks/useComponentDidMount';

interface Props {
  reportId: number;
  dataHcName: string;
  backButton?: ReactNode;
  listViewType?: ListViewType;
  className?: string;
  onChangeListViewType?: (listViewType: ListViewType) => void;
  onChangeSelectedCompsOnly?: (selectedCompsOnly: boolean) => void;
}
interface HoveredCompState {
  compSchema: CompSchema;
  documentId: string | undefined;
}
const OVERFLOW_PORTAL_ID = 'comp-filter-overflow';
export const CompSelectionPage = ({
  reportId,
  dataHcName,
  className,
  listViewType,
  backButton,
  onChangeListViewType,
  onChangeSelectedCompsOnly
}: Props) => {
  const [hoveredComp, setHoveredComp] = useState<HoveredCompState | null>(null);
  const [selectedCompsOnly, setSelectedCompsOnly] = useState<boolean>(false);
  const {
    compFilterBarState,
    compFilterBarActions: { compFilterOverflowPortalMounted }
  } = useCompFilterBarState();
  // Not sure this is the best way to handle this but I want to avoid passing it down as props
  useComponentDidMount(() => {
    compFilterOverflowPortalMounted(OVERFLOW_PORTAL_ID);
  });
  return (
    <div className={classNames(styles.Page, className)}>
      <header className={styles.Header}>
        {backButton && backButton}
        <SubjectDetailsHeader
          reportId={reportId}
          className={styles.SubjectHeader}
          dataHcName={`${dataHcName}-subject-header`}
          contentRight={
            <CompSearch
              dataHcName={`${dataHcName}-comp-search`}
              reportId={reportId}
              className={styles.CompSearch}
            />
          }
        />
      </header>
      <CompControls
        dataHcName={`${dataHcName}-comp-controls`}
        reportId={reportId}
      />
      <main className={styles.Main}>
        {compFilterBarState.overflowVisible && (
          <div id={OVERFLOW_PORTAL_ID} className={styles.MoreFilters} />
        )}
        <section className={styles.Map}>
          <CompSelectionMap
            reportId={reportId}
            dataHcName={`${dataHcName}-map`}
            selectedCompsOnly={selectedCompsOnly}
          >
            {hoveredComp &&
              hoveredComp.compSchema.geoLocation.longitude &&
              hoveredComp.compSchema.geoLocation.latitude && (
                <Marker
                  key={`${hoveredComp.compSchema.compID}-hovered`}
                  longitude={hoveredComp.compSchema.geoLocation.longitude}
                  latitude={hoveredComp.compSchema.geoLocation.latitude}
                  className={styles.HoveredComp}
                >
                  <CompMarker
                    dataHcName={`${dataHcName}-marker-hovered`}
                    listingStatusData={
                      hoveredComp.compSchema.latestListing.listingStatus
                    }
                    selected={!!hoveredComp.documentId}
                    pulse
                  >
                    {formatNumberAbbrev(
                      getMarketPrice(
                        hoveredComp.compSchema.latestListing.listingStatus
                      )
                    )}
                  </CompMarker>
                </Marker>
              )}
          </CompSelectionMap>
        </section>
        <ResizableFlexColumn
          dataHcName={`${dataHcName}-resize`}
          classNameOuter={styles.ListOuter}
          classNameInner={styles.ListInner}
        >
          <CompFarmList
            dataHcName={`${dataHcName}-list`}
            reportId={reportId}
            selectedCompsOnly={selectedCompsOnly}
            listViewType={listViewType}
            onChangeSelectedCompsOnly={(v) => {
              setSelectedCompsOnly(v);
              onChangeSelectedCompsOnly?.(v);
            }}
            onMouseEnter={(hovered: HoveredCompState) => {
              setHoveredComp(hovered);
            }}
            onMouseLeave={() => {
              setHoveredComp(null);
            }}
            onChangeListViewType={onChangeListViewType}
          />
        </ResizableFlexColumn>
      </main>
    </div>
  );
};
