import { Document, DocumentRoles } from '../types';

export const DOCUMENT_ROLE_REQUIRED = 'report-api/DOCUMENT_ROLE_REQUIRED';
export const DOCUMENT_ROLE_FETCH = 'report-api/DOCUMENT_ROLE_FETCH';
export const DOCUMENT_ROLE_FETCH_SUCCESS =
  'report-api/DOCUMENT_ROLE_FETCH_SUCCESS';
export const DOCUMENT_ROLE_FETCH_ERROR = 'report-api/DOCUMENT_ROLE_FETCH_ERROR';

export interface DocumentRoleRequiredAction {
  type: typeof DOCUMENT_ROLE_REQUIRED;
  payload: {
    reportId: number;
    documentRole: DocumentRoles;
  };
}
export const documentRoleRequired = (
  reportId: number,
  documentRole: DocumentRoles
): DocumentRoleRequiredAction => {
  return {
    type: DOCUMENT_ROLE_REQUIRED,
    payload: {
      reportId,
      documentRole
    }
  };
};

export interface DocumentRoleFetchAction {
  type: typeof DOCUMENT_ROLE_FETCH;
  payload: {
    reportId: number;
    documentRole: DocumentRoles;
  };
}
export const documentRoleFetch = (
  reportId: number,
  documentRole: DocumentRoles
): DocumentRoleFetchAction => {
  return {
    type: DOCUMENT_ROLE_FETCH,
    payload: {
      reportId,
      documentRole
    }
  };
};

export interface DocumentRoleFetchSuccessAction {
  type: typeof DOCUMENT_ROLE_FETCH_SUCCESS;
  payload: {
    reportId: number;
    documentRole: DocumentRoles;
    documents: Document[];
  };
}
export const documentRoleFetchSuccess = (
  reportId: number,
  documentRole: DocumentRoles,
  documents: Document[]
): DocumentRoleFetchSuccessAction => {
  return {
    type: DOCUMENT_ROLE_FETCH_SUCCESS,
    payload: {
      reportId,
      documentRole,
      documents
    }
  };
};

export interface DocumentRoleFetchErrorAction {
  type: typeof DOCUMENT_ROLE_FETCH_ERROR;
  payload: {
    reportId: number;
    documentRole: DocumentRoles;
    message: string;
  };
}
export const documentRoleFetchError = (
  reportId: number,
  documentRole: DocumentRoles,
  message: string
): DocumentRoleFetchErrorAction => {
  return {
    type: DOCUMENT_ROLE_FETCH_ERROR,
    payload: {
      reportId,
      documentRole,
      message
    }
  };
};

export type DocumentRoleActions =
  | DocumentRoleFetchAction
  | DocumentRoleFetchSuccessAction
  | DocumentRoleFetchErrorAction;
