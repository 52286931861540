import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './PropertyDetailsHeader.css';
import { formatCityStateZip, formatStreetAddress } from 'src/lib/utils';
import { Popover } from '../Popover';
import { KeywordSearchText } from 'src/lib/keyword-search/features/KeywordSearchText/KeywordSearchText';

interface Props {
  dataHcName: string;
  className?: string;
  address: {
    streetAddress?: string | null;
    unit?: string | null;
    city?: string | null;
    state?: string | null;
    zipcode?: string | null;
    slug?: string;
    zipcodePlus4?: string | null;
    fullAddress?: string | null;
    hcAddressId?: number;
  };
  propertyAttributes: string[];
  publicRemarks?: string | null;
  contentRight?: ReactNode;
  children?: ReactNode;
}
export const PropertyDetailsHeader = ({
  dataHcName,
  address,
  propertyAttributes,
  className,
  publicRemarks,
  contentRight,
  children
}: Props) => {
  return (
    <header
      data-hc-name={dataHcName}
      className={classNames(styles.PropertyDetailsHeader, className)}
    >
      <div className={styles.ContentMain}>
        <div className={styles.LineOne}>
          <h1
            className={styles.StreetAddress}
            data-hc-name={`${dataHcName}-street-address`}
          >
            {formatStreetAddress(address)}
          </h1>
          <div className={styles.Children}>{children}</div>
        </div>
        <div className={styles.LineTwo}>
          <div
            data-hc-name={`${dataHcName}-city-state-zip`}
            className={styles.CityStateZip}
          >
            {formatCityStateZip(address)}
          </div>
          <div className={styles.Divider}>•</div>
          <div
            data-hc-name={`${dataHcName}-property-attributes`}
            className={styles.Details}
          >
            {propertyAttributes.map((attr, i) => (
              <div key={`${attr}-${i}`}>{attr}</div>
            ))}
          </div>
          {publicRemarks && (
            <>
              <div className={styles.Divider}>•</div>
              <div className={styles.PublicRemarks}>
                <Popover
                  trigger={
                    <div
                      className={styles.PublicRemarksButton}
                      data-hc-name={`${dataHcName}-public-remarks-button`}
                    >
                      View Listing Remarks
                    </div>
                  }
                  content={
                    <KeywordSearchText
                      dataHcName={`${dataHcName}-public-remarks-text`}
                      textToHighlight={publicRemarks}
                    />
                  }
                  showOnMouseEnter
                />
              </div>
            </>
          )}
        </div>
      </div>
      {contentRight && (
        <div
          data-hc-name={`${dataHcName}-content-right`}
          className={styles.ContentRight}
        >
          {contentRight}
        </div>
      )}
    </header>
  );
};
