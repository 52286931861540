import React, { ReactNode, CSSProperties } from 'react';
import classNames from 'classnames';
import styles from './Skeleton.css';

type SkeletonTypes = 'button' | 'fill' | 'fixed' | 'input' | 'h1' | 'text';
export interface Props extends CSSProperties {
  dataHcName: string;
  className?: string;
  children?: ReactNode;
  type?: SkeletonTypes;
  style?: CSSProperties;
}
export const Skeleton = ({
  dataHcName,
  className,
  children,
  type = 'fill',
  style = {},
  ...styleProps
}: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.Skeleton, styles[type], className)}
      style={{ ...style, ...styleProps }}
    >
      <div className={styles.Animation}>
        <span className={styles.Children}>{children}</span>
      </div>
    </div>
  );
};
