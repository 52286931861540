import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import scrollIntoView from 'scroll-into-view';
import {
  RTThemedMenu as Menu,
  RTThemedMenuItem as MenuItem
} from '@hc/component-lib/hclib/rt-themed';

import { ID_PORTAL_FIXED } from 'legacy/appstore/constants';

import { capitalizeFirstLetter } from 'legacy/utils/strings';
import { snakeCaseToCamelCase } from 'legacy/utils/transform';
import { isFunc } from 'legacy/utils/utils';

import { Popover as theme } from 'legacy/css-modules/generic';

const DROPDOWN_CONTENT_CLASSNAME = 'custom-dropdown--content';
const OFF_SCREEN_MARGIN = 33;
const TOGGLE_ON_HOVER_ALIGNMENT = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left'
};
const TOGGLE_ON_HOVER_ALIGNMENT_OPP = {
  top: 'left',
  bottom: 'left',
  left: 'top',
  right: 'top'
};

const TOGGLE_LEFT_ADJUSTMENT_BY_ALIGNMENT = {
  bottom: (boundingButton, boundingContent) => {
    const { left: buttonLeft, right: buttonRight } = boundingButton;
    return buttonRight - (buttonRight - buttonLeft) / 2;
  },
  top: (boundingButton, boundingContent) => {
    const { left: buttonLeft, right: buttonRight } = boundingButton;
    return buttonRight - (buttonRight - buttonLeft) / 2;
  },
  left: (boundingButton) => {
    return boundingButton.left;
  },
  right: (boundingButton) => {
    return boundingButton.right;
  }
};

// The button to launch the popover w/ props
class PopoverButton extends Component {
  render() {
    const { button, parentHandleToggle, toggleOnHover } = this.props;
    return React.Children.map(button, (child) => {
      let childProps = {};
      if (toggleOnHover) {
        childProps.onMouseEnter = () => {
          parentHandleToggle(true);
          if (isFunc(this.props.onMouseEnter)) {
            this.props.onMouseEnter();
          }
        };
        childProps.onMouseLeave = () => {
          parentHandleToggle(false);
          if (isFunc(this.props.onMouseLeave)) {
            this.props.onMouseLeave();
          }
        };
      } else {
        childProps.onClick = () => {
          parentHandleToggle();
          if (isFunc(this.props.onClick)) {
            this.props.onClick();
          }
        };
      }
      return React.cloneElement(child, childProps);
    });
  }
}

class PopoverContentPortal extends Component {
  render() {
    if (this.props.fixed) {
      return ReactDOM.createPortal(
        this.props.children,
        document.getElementById(ID_PORTAL_FIXED)
      );
    } else {
      return this.props.children;
    }
  }
}

class Popover extends Component {
  static defaultProps = {
    popoverAlignment: 'left',
    minMarginBottom: 60,
    style: {}
  };

  static propTypes = {
    Button: PropTypes.object,
    ActiveButton: PropTypes.object,
    InactiveButton: PropTypes.object,
    className: PropTypes.string,
    popoverAlignment: PropTypes.string,
    isOpen: PropTypes.bool
  };

  state = {
    isOpen: false,
    adjustmentHorz: 0,
    height: 'auto',
    top: 0,
    left: 0
  };

  toggleTimeout = undefined;

  componentDidUpdate(prevProps, prevState) {
    if (this.props.disabled && this.state.isOpen) {
      this.setState({ isOpen: false });
    }
    if (!prevState.isOpen && this.state.isOpen) {
      if (this.props.fixed) {
        this.adjustPositionFixed();
      } else {
        this.adjustPosition();
      }
    }
  }

  componentDidMount() {
    if (this.props.fixed) {
      this.adjustPositionFixed();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.toggleTimeout);
  }

  // Allow dismissing of the popover externally
  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen && nextProps.isOpen === false) {
      this.setState({ isOpen: false });
    }
  }

  adjustPosition = () => {
    const node = ReactDOM.findDOMNode(this.popoverContent);
    const boundingClientRect = node.getBoundingClientRect();

    // Scroll window if the popover is below the fold
    if (boundingClientRect.top + node.offsetHeight > window.innerHeight) {
      scrollIntoView(node, {
        time: 300,
        align: {
          top: 1,
          // 20px padding between bottom of dropdown and bottom of viewport
          topOffset: -20
        }
      });
    }

    let adjustmentHorz = 0;
    if (boundingClientRect.left < 0) {
      // Off screen left
      adjustmentHorz = boundingClientRect.left * -1 + OFF_SCREEN_MARGIN;
    } else if (boundingClientRect.right > window.innerWidth) {
      // Off screen right
      adjustmentHorz =
        boundingClientRect.right - window.innerWidth + OFF_SCREEN_MARGIN;
    }
    if (adjustmentHorz !== this.state.adjustmentHorz) {
      this.setState({ adjustmentHorz });
    }
  };

  // Calculate position when this.props.fixed === true | Useful for things like popovers in table rows
  adjustPositionFixed = () => {
    const { minMarginBottom, popoverAlignment } = this.props;
    const buttonNode = ReactDOM.findDOMNode(this.popoverButton);
    const boundingButton = buttonNode.getBoundingClientRect();
    const contentNode = ReactDOM.findDOMNode(this.popoverContent);
    const boundingContent = contentNode.getBoundingClientRect();
    const top = boundingButton.top + boundingButton.height + 10;
    const height =
      top + boundingContent.height > window.innerHeight
        ? window.innerHeight - top - minMarginBottom * 2
        : 'auto';
    this.setState({
      left: TOGGLE_LEFT_ADJUSTMENT_BY_ALIGNMENT[popoverAlignment](
        boundingButton,
        boundingContent
      ),
      top,
      height
    });
  };

  togglePopover = (isOpen) => {
    const { disabled } = this.props;
    if (!disabled) {
      const nextIsOpen = isOpen === undefined ? !this.state.isOpen : isOpen;
      if (nextIsOpen !== this.state.isOpen) {
        this.setState({ isOpen: nextIsOpen, adjustmentHorz: 0 });
      }
    }
  };

  handleToggle = (nextIsOpen) => {
    const { showDelay, hideDelay, disabled } = this.props;
    const { isOpen } = this.state;
    nextIsOpen = nextIsOpen === undefined ? !isOpen : nextIsOpen;
    clearTimeout(this.toggleTimeout);
    if (!disabled && nextIsOpen !== isOpen) {
      if (nextIsOpen && showDelay) {
        this.toggleTimeout = setTimeout(
          () => this.togglePopover(nextIsOpen),
          showDelay
        );
      } else if (!nextIsOpen && hideDelay) {
        this.toggleTimeout = setTimeout(
          () => this.togglePopover(nextIsOpen),
          hideDelay
        );
      } else {
        this.togglePopover();
      }
    }
  };

  buildInlineStyles = () => {
    const { fixed, style, minWidth, popoverAlignment, toggleOnHover, caret } =
      this.props;
    const { adjustmentHorz, top, left, height } = this.state;
    if (fixed) {
      return {
        ...style,
        position: 'fixed',
        top: `${top}px`,
        left: `${left}px`,
        transform: 'translateX(-50%)',
        height: height === 'auto' ? height : `${height}px`
      };
    } else if (toggleOnHover) {
      const dimensionStyles =
        popoverAlignment === 'center'
          ? {}
          : {
              transform:
                TOGGLE_ON_HOVER_ALIGNMENT_OPP[popoverAlignment] === 'left' &&
                'translateX(-50%)',
              [TOGGLE_ON_HOVER_ALIGNMENT[popoverAlignment]]: '100%',
              [TOGGLE_ON_HOVER_ALIGNMENT_OPP[popoverAlignment]]: '50%',
              [`margin${capitalizeFirstLetter(
                TOGGLE_ON_HOVER_ALIGNMENT[popoverAlignment]
              )}`]: caret ? '12px' : '6px',
              [`margin${capitalizeFirstLetter(
                popoverAlignment
              )}`]: `${adjustmentHorz}px`
            };
      return {
        ...style,
        minWidth: minWidth || style.minWidth,
        ...dimensionStyles
      };
    } else {
      return {
        ...style,
        minWidth: minWidth || style.minWidth,
        [popoverAlignment]: 0,
        [`margin${capitalizeFirstLetter(
          popoverAlignment
        )}`]: `-${adjustmentHorz}px`
      };
    }
  };

  render() {
    const {
      Button,
      ActiveButton,
      InactiveButton,
      className,
      classNameContent,
      caret,
      children,
      onMenuSelect,
      menuItems,
      closeOnChildClick,
      popoverAlignment,
      fixed,
      toggleOnHover
    } = this.props;
    const { isOpen } = this.state;
    const ButtonComponent =
      ActiveButton && InactiveButton
        ? isOpen
          ? ActiveButton
          : InactiveButton
        : Button;
    const isMenuPopover = menuItems && menuItems.length;
    const styles = this.buildInlineStyles();
    return (
      <div className={classNames(theme.Popover, className)}>
        <PopoverButton
          button={ButtonComponent}
          parentHandleToggle={this.handleToggle}
          ref={(button) => {
            this.popoverButton = button;
          }}
          toggleOnHover={toggleOnHover}
        />
        <PopoverContentPortal fixed={fixed}>
          <div
            className={classNames(
              theme.Dropdown,
              DROPDOWN_CONTENT_CLASSNAME,
              classNameContent,
              {
                [theme.open]: isOpen,
                [theme[
                  snakeCaseToCamelCase(
                    `open_${TOGGLE_ON_HOVER_ALIGNMENT_OPP[popoverAlignment]}`
                  )
                ]]: isOpen,
                [theme.MenuPopover]: isMenuPopover
              }
            )}
            ref={(node) => {
              this.popoverContent = node;
            }}
            style={styles}
          >
            {isOpen && isMenuPopover && (
              <Menu
                theme={theme}
                onSelect={
                  onMenuSelect
                    ? (v) => {
                        if (closeOnChildClick) {
                          this.handleToggle();
                        }
                        onMenuSelect(v);
                      }
                    : undefined
                }
                outline={false}
                rippled={false}
                selectable
              >
                {menuItems.map((item, i) => (
                  <MenuItem
                    key={`popover-${i}-${item.value}`}
                    data-hc-name={item.dataHcName}
                    value={item.value}
                    caption={item.label}
                    onClick={
                      item.onClick
                        ? () => {
                            if (closeOnChildClick) {
                              this.handleToggle();
                            }
                            item.onClick(item.value);
                          }
                        : undefined
                    }
                  />
                ))}
              </Menu>
            )}
            {isOpen && caret && <div className={classNames(theme.Caret)} />}
            {isOpen && children}
          </div>
          {isOpen && !toggleOnHover && (
            <div className={theme.Screen} onClick={this.handleToggle} />
          )}
        </PopoverContentPortal>
      </div>
    );
  }
}

export default Popover;
