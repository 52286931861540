import values from 'lodash/values';
import { call, put, select } from 'redux-saga/effects';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { COMP_ID_KEY } from 'legacy/appstore/constants';

import { watchEvery } from 'legacy/utils/saga';
import apiUtil from 'legacy/utils/api';
import { findPropertyInList } from 'legacy/utils/property-details';

import {
  compsAddCompSuccess,
  compsFocusToggle,
  COMPS_ADD_COMP,
  compsAddComp,
  COMPS_DETERMINE_ADD_COMP
} from 'actions/comps';
import {
  rentalCompsAddCompSuccess,
  rentalCompsFocusToggle,
  RENTAL_COMPS_ADD_COMP,
  rentalCompsAddComp,
  RENTAL_COMPS_DETERMINE_ADD_COMP
} from 'actions/rental-comps';
import { toastShow } from 'actions/toast';
import { scrubDataToComp } from 'sagas/mls-compliance.saga';
import { getCompsFarm } from 'selectors/comps';
import { getRentalCompsFarm } from 'selectors/rental-comps';
import { getSubjectSelected } from 'selectors/subject';
import { getAvmHc } from 'selectors/avms';
import { getUseVRShareEndpoint } from 'selectors/property-explorer';

const compActionsDefault = {
  focusToggle: compsFocusToggle,
  addCompSuccess: compsAddCompSuccess,
  addComp: compsAddComp
};
const compSelectorsDefault = {
  getFarm: getCompsFarm
};

const compActionsRental = {
  focusToggle: rentalCompsFocusToggle,
  addCompSuccess: rentalCompsAddCompSuccess,
  addComp: rentalCompsAddComp
};
const compSelectorsRental = {
  getFarm: getRentalCompsFarm
};

function* determineAddComp(action) {
  let compActions, compSelectors;
  if (action.type === COMPS_DETERMINE_ADD_COMP) {
    compActions = compActionsDefault;
    compSelectors = compSelectorsDefault;
  } else {
    compActions = compActionsRental;
    compSelectors = compSelectorsRental;
  }
  const slug = action.payload.slug;
  // Get property for property slug
  const responseData = yield call(apiUtil.GET, HC_CONSTANTS.ADDRESS_PARTS_URL, {
    slug
  });
  // Success; set on state
  const property = responseData[0];
  // Get cached property list
  const compsFarmList = yield select(compSelectors.getFarm);
  const subject = yield select(getSubjectSelected);
  if (property.address && property.zipcode) {
    const foundComp = findPropertyInList(property, values(compsFarmList));
    // If searched-for comp already exists in farm list
    if (foundComp) {
      yield put(compActions.focusToggle(foundComp));
      // If trying to add the subject property as a comp
    } else if (findPropertyInList(property, [subject])) {
      yield put(
        toastShow('Cannot add subject property as a comparable property')
      );
    } else {
      yield put(compActions.addComp(property));
    }
    // Street address or zipcode is missing from returned property
  } else {
    yield put(toastShow('Unsupported address'));
  }
}

export function* addComp(action) {
  let compActions, compSelectors, isRental;
  if (action.type === COMPS_ADD_COMP) {
    compActions = compActionsDefault;
    compSelectors = compSelectorsDefault;
    isRental = false;
  } else {
    compActions = compActionsRental;
    compSelectors = compSelectorsRental;
    isRental = true;
  }
  const comp = action.payload.comp;

  const subject = yield select(getSubjectSelected);
  const avm = yield select(getAvmHc);
  const useVRShareEndpoint = yield select(getUseVRShareEndpoint);
  const url = useVRShareEndpoint
    ? `${HC_CONSTANTS.VRSHARE_PROXY_URL}/emporium/vrshare/to_comp/`
    : `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/to_comp/`;

  const params = {
    newComp: {
      streetAddress: comp.address,
      zipcode: comp.zipcode,
      unit: comp.unit,
      unitDesignator: comp.unitDesignator,
      isRental
    },
    subject,
    avm
  };

  try {
    const propertyResult = yield call(apiUtil.POST, url, params);
    if (propertyResult && !propertyResult.value) {
      /** TODO log this somewhere? */
    }
    if (propertyResult.isReal) {
      const compToAdd = yield call(scrubDataToComp, {
        ...propertyResult.comp,
        isRentalComp: isRental,
        userAdded: true
      });
      yield put(compActions.addCompSuccess(compToAdd));
      const allComps = yield select(compSelectors.getFarm);
      const ourComp = allComps[compToAdd[COMP_ID_KEY]];
      yield put(compActions.focusToggle(ourComp));
    } else {
      /** TODO log this somewhere? */
      yield put(toastShow('Unsupported address'));
    }
  } catch (e) {
    yield put(toastShow('Unsupported address'));
  }
}

export default function registerAddCompSaga() {
  watchEvery([COMPS_ADD_COMP, RENTAL_COMPS_ADD_COMP], addComp);
  watchEvery(
    [COMPS_DETERMINE_ADD_COMP, RENTAL_COMPS_DETERMINE_ADD_COMP],
    determineAddComp
  );
}
