import HC_CONSTANTS from 'HC_CONSTANTS';

import thumbnailPlaceholderImg0 from 'legacy/assets/images/thumbnail-placeholder-0.jpg';
import thumbnailPlaceholderImg1 from 'legacy/assets/images/thumbnail-placeholder-1.png';
import thumbnailPlaceholderImg2 from 'legacy/assets/images/thumbnail-placeholder-2.png';
import thumbnailPlaceholderImg3 from 'legacy/assets/images/thumbnail-placeholder-3.png';
import thumbnailPlaceholderImg4 from 'legacy/assets/images/thumbnail-placeholder-4.png';
import thumbnailPlaceholderImg5 from 'legacy/assets/images/thumbnail-placeholder-5.png';

const { GOOGLE_STREET_VIEW_URL, GOOGLE_MAPS_KEY, MAPBOX } = HC_CONSTANTS;
const MAPBOX_TILE_DOMAIN = 'https://api.tiles.mapbox.com';

export const THUMBNAIL_PLACEHOLDERS = [
  thumbnailPlaceholderImg0,
  thumbnailPlaceholderImg1,
  thumbnailPlaceholderImg2,
  thumbnailPlaceholderImg3,
  thumbnailPlaceholderImg4,
  thumbnailPlaceholderImg5
];

export const checkUrlIsStreetView = (url) =>
  url && url.indexOf(GOOGLE_STREET_VIEW_URL) > -1;
export const checkUrlIsMapTile = (url) =>
  url && url.indexOf(MAPBOX_TILE_DOMAIN) > -1;

export const getMapTilePhoto = (lat, lng) =>
  `${MAPBOX_TILE_DOMAIN}/styles/v1/mapbox/streets-v11/static/pin-m+16B7D5(${lng},${lat})/` +
  `${lng},${lat},16/640x480@2x?access_token=${MAPBOX.TOKEN}`;

export const buildStreetViewUrl = (
  latitude,
  longitude,
  width = 372,
  height = 300
) =>
  `${GOOGLE_STREET_VIEW_URL}?size=372x300&location=${latitude},${longitude}&key=${GOOGLE_MAPS_KEY}`;
