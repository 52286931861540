import { CompDocument, DocumentRoles, FarmDocument } from '../types';
import { CompId, CompSchema } from '../types/schemas';
import { useDocumentRole } from './useDocumentRole';

// Returns comp schema from the comp document if it exists
// otherwise use the comp schema from the farm list
export const useComp = (
  reportId: number,
  compId: CompId
): {
  compSchema: CompSchema | undefined;
  documentId: string | undefined;
} => {
  const {
    data: [farmDocument]
  } = useDocumentRole<FarmDocument>(reportId, DocumentRoles.Farm);
  const { data: compDocuments } = useDocumentRole<CompDocument>(
    reportId,
    DocumentRoles.Comp
  );
  const compDocument = compDocuments.find((document) => {
    if (document.data.compID === compId) {
      return true;
    } else {
      return false;
    }
  });
  return {
    compSchema: compDocument?.data || farmDocument?.data[compId] || undefined,
    documentId: compDocument?.documentId || undefined
  };
};
