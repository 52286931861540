import React from 'react';
import { useDispatch } from 'react-redux';
import { MultiSelect, Option } from 'src/lib/components';
import { FilterCard } from 'src/lib/components/FilterCard';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { ListingStatus } from 'src/lib/property-lookup/types';
import { isLoading } from 'src/lib/utils/general';
import { ListingStatusNormalized } from 'src/lib/utils/listingStatus';
import { documentPatch } from '../../actions';
import { useDocumentRole, useReport } from '../../hooks';
import { DocumentRoles, FilterDocument, SubjectDocument } from '../../types';
import { CompFields } from '../../types/CompFields';
import { compFieldGetters } from '../../utils/comps.utils';
import { CompFilterClearButton } from './CompFilterClearButton';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
}
const OPTIONS: Option<ListingStatusNormalized>[] = [
  {
    value: ListingStatus.ACTIVE,
    label: 'Active'
  },
  {
    value: ListingStatus.PENDING,
    label: 'Pending'
  },
  {
    value: ListingStatus.SOLD,
    label: 'Sold'
  }
];
const COMP_FIELD = CompFields.propertyStatus;
export const CompFilterPropertyStatus = ({
  reportId,
  dataHcName,
  className
}: Props) => {
  const dispatch = useDispatch();
  const {
    status: filterDocumentStatus,
    data: [filterDocument]
  } = useDocumentRole<FilterDocument>(reportId, DocumentRoles.Filter);
  const {
    status: subjectStatus,
    data: [subjectDocument]
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  const { status: reportStatus, data: report } = useReport(reportId);
  if (
    isLoading([filterDocumentStatus, reportStatus, subjectStatus]) ||
    !filterDocument ||
    !report ||
    !subjectDocument
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const filterValue = filterDocument.data.filters[COMP_FIELD];
  const subjectValue = compFieldGetters[COMP_FIELD](subjectDocument.data);
  return (
    <FilterCard
      label="Status"
      info={`Subject: ${subjectValue}`}
      className={className}
      dataHcName={dataHcName}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compField={COMP_FIELD}
          dataHcName={`${dataHcName}-clear`}
        />
      }
    >
      <MultiSelect
        dataHcName={`${dataHcName}-select`}
        value={filterValue?.absoluteValue || []}
        options={OPTIONS}
        onChange={(value: ListingStatusNormalized[]) => {
          if (value.length === 0) {
            dispatch(
              documentPatch(reportId, filterDocument.documentId, [
                {
                  op: 'remove',
                  path: `/data/filters/${COMP_FIELD}`
                }
              ])
            );
          } else {
            dispatch(
              documentPatch(reportId, filterDocument.documentId, [
                {
                  op: 'add',
                  path: `/data/filters/${COMP_FIELD}/absoluteValue`,
                  value
                }
              ])
            );
          }
        }}
      />
    </FilterCard>
  );
};
