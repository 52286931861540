import classNames from 'classnames';
import React from 'react';
import { CompSchema } from '../../types/schemas';
import styles from './CompCompare.css';
import { SelectedCompCol, UnselectedCompCol } from './CompCompareCol';
import { SubjectCompareCol } from './SubjectCompareCol';

export interface CompIdentifier {
  documentId?: string | null;
  compSchema?: CompSchema | null;
}
interface Props {
  reportId: number;
  dataHcName: string;
  className?: string;
  compIdentifiers: CompIdentifier[];
}
export const CompCompare = ({
  reportId,
  dataHcName,
  compIdentifiers,
  className
}: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CompCompare, className)}
    >
      <SubjectCompareCol
        dataHcName={`${dataHcName}-subject`}
        reportId={reportId}
      />
      {compIdentifiers.map((compIdentifier, i) => {
        if (compIdentifier.documentId) {
          return (
            <SelectedCompCol
              key={`comp-compare-${i}`}
              dataHcName={`${dataHcName}-comp-${i}`}
              reportId={reportId}
              documentId={compIdentifier.documentId}
            />
          );
        } else if (compIdentifier.compSchema) {
          return (
            <UnselectedCompCol
              key={`comp-compare-${i}`}
              dataHcName={`${dataHcName}-comp-${i}`}
              reportId={reportId}
              compSchema={compIdentifier.compSchema}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
