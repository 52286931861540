import React from 'react';

import PopoverEndgame from 'legacy/components/PopoverEndgame';
import TooltipDefaultTrigger from 'legacy/components/generic/TooltipDefaultTrigger';

import { TableHeaderInfo as theme } from 'legacy/css-modules';

const TableHeaderInfo = ({ col }) => {
  return (
    <div className={theme.TableHeaderInfo}>
      <div className={theme.Label}>{col.label}</div>
      {col.info && (
        <span className={theme.Info} onClick={(e) => e.stopPropagation()}>
          <PopoverEndgame
            trigger={<TooltipDefaultTrigger />}
            content={col.info}
          />
        </span>
      )}
    </div>
  );
};

export default TableHeaderInfo;
