import { connect } from 'react-redux';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

import { STATUSES } from 'legacy/appstore/constants';
import { EVENTS } from 'legacy/appstore/events';
import { VIEWS } from 'legacy/routes/constants';

import mixpanelService from 'legacy/services/mixpanel';

import { scrollToTop } from 'legacy/utils/dom';

import {
  fetchArchivedReports,
  archivedReportsCloseModal,
  archivedReportsSearch,
  archivedReportsDownloadReport
} from 'actions/archived-reports';
import { analyticsEvent } from 'actions/analytics';

import {
  getArchivedReportsFiltered,
  getArchivedReportsStatus,
  getArchivedReportsIsOpen,
  getArchivedReportsTableColumns,
  getArchivedReportsSearchQuery
} from 'selectors/archived-reports';
import { getMqSmallMediumAndUp } from 'selectors/match-media';

import ArchivedReportsModal from 'legacy/components/ArchivedReportsModal';

const mapStateToProps = (state) => {
  return {
    fullscreen: !getMqSmallMediumAndUp(state),
    areReportsLoaded: getArchivedReportsStatus(state) === STATUSES.SUCCESS,
    isOpen: getArchivedReportsIsOpen(state),
    properties: getArchivedReportsFiltered(state),
    tableColumns: getArchivedReportsTableColumns(state),
    searchQuery: getArchivedReportsSearchQuery(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(archivedReportsCloseModal()),
  loadArchivedReports: () => dispatch(fetchArchivedReports()),
  onViewOrDownloadReport: (property, report) => {
    if (report.isActive) {
      const routeObj = {
        view: VIEWS.VALUE_REPORT,
        params: {
          streetAddress: property.streetAddress || undefined,
          zipcode: property.zipcode || undefined,
          city: property.city || undefined,
          state: property.state || undefined,
          lat: property.lat || undefined,
          lon: property.lon || undefined
        },
        query: {
          address_slug: property.addressSlug || undefined,
          unit: property.unit || undefined,
          unitDesignator: property.unitDesignator || undefined,
          listing_id: property.listingId || undefined,
          listing_source: property.listingSource || undefined
        },
        options: { inherit: false }
      };
      dispatch(routeChange(routeObj));
      dispatch(archivedReportsCloseModal());
      dispatch(
        analyticsEvent(EVENTS.ARCHIVED_REPORTS_VIEW_ACTIVE, {
          address_slug: property.addressSlug || property.addressKey
        })
      );
      scrollToTop();
    } else {
      dispatch(archivedReportsDownloadReport(property, report));
    }
  },
  onPreviewReport: (property) => {
    dispatch(
      analyticsEvent(EVENTS.ARCHIVED_REPORTS_VIEW_NEW, {
        address_slug: property.addressSlug || property.addressKey
      })
    );
    dispatch(
      analyticsEvent(EVENTS.REPORT_PULL_NEW, {
        address_slug: property.addressSlug || property.addressKey
      })
    );
    mixpanelService.incReportsPulled();
    dispatch(
      routeChange({
        view: VIEWS.SEARCH,
        query: {
          street_address: property.streetAddress || undefined,
          zipcode: property.zipcode || undefined,
          unit: property.unit || undefined
        },
        options: { inherit: false }
      })
    );
    scrollToTop();
    dispatch(archivedReportsCloseModal());
  },
  onSearch: (query) => {
    dispatch(archivedReportsSearch(query));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchivedReportsModal);
