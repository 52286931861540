import { watchLatest } from 'legacy/utils/saga';
import { select, call, put } from 'redux-saga/effects';

import HC_CONSTANTS from 'HC_CONSTANTS';

import {
  CLIENT_ID_SEARCH_SUBMIT,
  clientIdSearchResults
} from '../actions/clientIdSearch.actions';
import { selClientIdSearchSubmit } from '../selectors/clientIdSearch.selectors';
import apiUtil from 'legacy/utils/api';

export function* clientIdSearchSaga() {
  try {
    const input = yield select(selClientIdSearchSubmit);
    const responseData = yield call(
      apiUtil.GET,
      `${HC_CONSTANTS.PROPERTY_LOOKUPS_SEARCH_URL}?partialMatch=true&clientID=${input}`
    );
    yield put(clientIdSearchResults(responseData));
  } catch (e) {
    console.error(e);
  }
}

export function registerClientIdSearchSaga() {
  watchLatest({ [CLIENT_ID_SEARCH_SUBMIT]: clientIdSearchSaga });
}
