import { Listing } from 'src/lib/property-lookup/types';

export enum ListingStatusForTransactions {
  SOLD = 'SOLD',
  PENDING = 'PENDING',
  CONTINGENT = 'PENDING',
  ACTIVE = 'LISTED',
  CLOSED = 'SOLD',
  WITHDRAWN = 'LISTED',
  UNKNOWN = 'UNKNOWN',
  DELETED = 'LISTED',
  LEASED = 'LEASED',
  COMING_SOON = 'LISTED',
  CANCELLED = 'LISTED',
  EXPIRED = 'LISTED'
}

export const getDate = (listing: Listing) => {
  if (ListingStatusForTransactions[listing.status] === 'LISTED') {
    return listing.listingStatus.listingDate;
  } else {
    return listing.statusDate;
  }
};
