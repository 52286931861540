import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// TODO: Remove this import
// @ts-ignore: Leaving this for now until we have a better way to get the token
import store from 'legacy/appstore/index';
//TODO Create a Typescript typed version of HC_CONSTANTS
//@ts-ignore
import HC_CONSTANTS from 'HC_CONSTANTS';
const httpLink = createHttpLink({
  uri: HC_CONSTANTS.PROPERTY_GRAPH_URL
});
const authLink = setContext((_, { headers }) => {
  const state = store.getState();
  // Ignoring because store is untyped right now
  // @ts-ignore
  const token = state.accountDetails.validity.token;
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'HC-Auth-Token': token || ''
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});
