import { PropertyLookupExtended } from 'src/lib/report-api/types/schemas/PropertyLookupExtended';
import { CompId } from './FarmSchema';

export enum SimilarityLevel {
  High = 'high',
  Moderate = 'moderate',
  Low = 'low'
}

interface Similarity {
  level: SimilarityLevel | null | '';
  score: number | null;
  levelAdjusted: SimilarityLevel | null | '';
  scoreAdjusted: number | null;
  salesPriceAdjusted: number | null;
  netDollarAdjustment: number | null;
}

interface TimelineAdjustedAVMValues {
  block: number | null;
  blockgroup: number | null;
  msa: number | null;
  state: number | null;
  zip: number | null;
}

interface AdjustedAVM {
  fromDate: string | null;
  fromValue: number | null;
  toDate: string | null;
  AdjustedBy: TimelineAdjustedAVMValues;
  AdjustedByMax: TimelineAdjustedAVMValues | null;
  AdjustedByMin: TimelineAdjustedAVMValues | null;
}

export interface CompSchema extends PropertyLookupExtended {
  compID: CompId;
  distanceMiles: number | null;
  AdjustedAVM: AdjustedAVM;
  similarity: Similarity;
}
