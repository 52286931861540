import { createSelector } from 'reselect';
import { getCurrentQuery } from '@hc/redux-saga-router-plus/hclib/selectors';

import homeIcon from 'legacy/assets/svg-inline/home.svg';
import { getIsEnterpriseOrganization } from 'selectors/account-details';
import { getActiveRecentReports } from 'selectors/recent-reports';
import {
  getMLSPropertyList,
  getMLSListingsShowOnMap
} from 'selectors/mls-listings';

import {
  getIsInInactiveTrialMode,
  getIsUserInSubscriptionMode,
  getHasOrgUsedLessThanIncludedUnits,
  getIsUserInTrialMode,
  getTrialEnded,
  getSelfServiceCounts
} from 'selectors/organization-usage';

import {
  getIsModelPerUnit,
  getIsNewPropertyExplorerPricingModel
} from 'selectors/subscription';

import { buildMarker } from 'legacy/utils/maps';
import { abbrDollarsFormatter } from 'legacy/utils/formatters';
import { isLocationUnknown } from 'legacy/utils/property-details';

import { STATUSES } from 'legacy/appstore/constants';

import { Maps as mapsTheme } from 'legacy/css-modules';

// Limit to restrict number of 'previous report' markers on the map
const MAP_MARKER_LIMIT = 600;

export function getSearchState(state) {
  return state.search;
}

export const getLandingPageState = createSelector(
  getSearchState,
  (search) => search.landingPage
);

export const getPropertySearchIsShowingOverlay = createSelector(
  getLandingPageState,
  (landingPage) => landingPage.isShowingOverlay
);

export const getPropertyResult = createSelector(getSearchState, (search) => {
  const { propertyResult } = search;
  return propertyResult || {};
});

export const getIsAttemptingLoadFullReport = createSelector(
  getPropertyResult,
  (propertyResult) => propertyResult.isAttemptingLoadFullReport
);

export const getHasSearchResultLoaded = createSelector(
  getPropertyResult,
  (propertyResult) =>
    propertyResult.status === STATUSES.SUCCESS ||
    propertyResult.status === STATUSES.PARTIAL
);

export const getHasSearchResultLoadedFully = createSelector(
  getPropertyResult,
  (propertyResult) => propertyResult.status === STATUSES.SUCCESS
);

export const getIsSearchResultLoading = createSelector(
  getPropertyResult,
  (propertyResult) => propertyResult.status === STATUSES.LOADING
);

export const getPropertyResultStreetAddress = createSelector(
  getPropertyResult,
  (propertyResult) => propertyResult.streetAddress
);

export const getReportLifeCycle = createSelector(
  getPropertyResult,
  (propertyResult) => ({
    lifecycleDays: propertyResult.lifecycleDays,
    lifecycleStartDate: propertyResult.lifecycleStartDate,
    lifecycleEndDate: propertyResult.lifecycleEndDate
  })
);

export const getReportGenerationError = (state) =>
  getPropertyResult(state).error || {};

export const getErrorMessage = (state) =>
  getReportGenerationError(state).message;

export const getErrorHelpLink = (state) => {
  const error = getReportGenerationError(state);
  const helpLink = error.helpLink || 'https://support.housecanary.com';
  return helpLink;
};

export const getIsPropertyResultActive = createSelector(
  getPropertyResult,
  (propertyResult) => propertyResult.isActive
);

export const getPropertyResultValue = createSelector(
  getPropertyResult,
  (propertyResult) => propertyResult.value
);

export const getIsPropertyResultAvmHcEmpty = createSelector(
  getPropertyResultValue,
  (propertyResultValue) => !propertyResultValue
);

export const getIsPropertyResultOwned = createSelector(
  getPropertyResult,
  (propertyResult) => propertyResult.isOwned
);

export const getIsPropertyIsNotOwnedOrActive = createSelector(
  getIsPropertyResultOwned,
  getIsPropertyResultActive,
  (isPropertyOwned, isPropertyActive) => !isPropertyOwned && !isPropertyActive
);

export const getPropertyResultHasInsufficientData = createSelector(
  getPropertyResultValue,
  getHasSearchResultLoaded,
  (propertyResultValue, hasLoaded) => propertyResultValue === null && hasLoaded
);

export const getArePropertyParamsInUrl = createSelector(
  getCurrentQuery,
  (currentQuery) => !!currentQuery.street_address
);

export const getShowPurchaseText = createSelector(
  getIsEnterpriseOrganization,
  getPropertyResult,
  getIsInInactiveTrialMode,
  getIsUserInSubscriptionMode,
  getArePropertyParamsInUrl,
  getHasOrgUsedLessThanIncludedUnits,
  (
    isEnterpriseOrganization,
    subject,
    inactiveTrial,
    activeSubscription,
    isResultMode,
    hasOrgUsedLessThanIncludedUnits
  ) => {
    return (
      !isEnterpriseOrganization &&
      !subject.isOwned &&
      ((activeSubscription && !hasOrgUsedLessThanIncludedUnits) ||
        inactiveTrial)
    );
  }
);

export const getPropertyResultLocationUnknown = createSelector(
  getPropertyResult,
  (propertyResult) => isLocationUnknown(propertyResult.geoPrecision)
);

export const getSearchMapMarkers = createSelector(
  getPropertyResult,
  getActiveRecentReports,
  getHasSearchResultLoaded,
  getArePropertyParamsInUrl,
  getMLSPropertyList,
  getMLSListingsShowOnMap,
  getPropertyResultLocationUnknown,
  (
    propertyResult,
    activeRecentReports,
    hasPropertyResultLoaded,
    isResultMode,
    mlsProperties,
    showMLSListingsOnMap,
    propertyResultLocationUnknown
  ) => {
    const isShowingMLSListingsOnSearchPage =
      !isResultMode && showMLSListingsOnMap;
    const properties =
      isResultMode && hasPropertyResultLoaded
        ? [propertyResult]
        : showMLSListingsOnMap
        ? mlsProperties
        : activeRecentReports.slice(0, MAP_MARKER_LIMIT);
    return properties.map((property) =>
      buildMarker({
        property,
        className:
          isResultMode &&
          hasPropertyResultLoaded &&
          isLocationUnknown(propertyResult.geoPrecision) &&
          mapsTheme.locationUnknown,
        isHomeStyle: !isShowingMLSListingsOnSearchPage,
        label: isShowingMLSListingsOnSearchPage
          ? abbrDollarsFormatter(property.listPrice)
          : homeIcon,
        goToPreviewPageOnClick: isShowingMLSListingsOnSearchPage,
        fitOnClick: true
      })
    );
  }
);

export const getPropertySearchIsMobileBottomBarExpanded = createSelector(
  getSearchState,
  (search) => search.isMobileBottomBarExpanded
);
