const PROPERTY_TYPE_LEGACY_TO_NEW = {
  Condominium: 'CONDO',
  'Manufactured/Mobile Home': 'MANUFACTURED_HOME',
  Multifamily: 'MULTI_FAMILY',
  Townhouse: 'SINGLE_FAMILY_ATTACHED',
  'Single Family Detached': 'SINGLE_FAMILY_DETACHED',
  'Co-Op': 'COOP',
  Other: 'OTHER',
  Commercial: 'COMMERCIAL',
  Timeshare: 'TIMESHARE',
  'Vacant Lot': 'VACANT_LOT'
};

const PROPERTY_TYPE_NEW_TO_LEGACY = {
  CONDO: 'Condominium',
  MANUFACTURED_HOME: 'Manufactured/Mobile Home',
  MULTI_FAMILY: 'Multifamily',
  'Single Family Attached': 'Townhouse',
  SINGLE_FAMILY_ATTACHED: 'Townhouse',
  SINGLE_FAMILY_DETACHED: 'Single Family Detached',
  COOP: 'Co-Op',
  OTHER: 'Other',
  COMMERCIAL: 'Commercial',
  TIMESHARE: 'Timeshare',
  VACANT_LOT: 'Vacant Lot'
};

export const convertPropertyTypeLegacyToNew = (propertyType) =>
  PROPERTY_TYPE_LEGACY_TO_NEW[propertyType] || propertyType;

export const convertPropertyTypeNewToLegacy = (propertyType) =>
  PROPERTY_TYPE_NEW_TO_LEGACY[propertyType] || propertyType;
