import React from 'react';
import { PhotosPage } from 'src/lib/report-api/features/PhotosPage';
import { usePhotosPage } from 'src/lib/report-api/hooks/usePhotosPage';
import { CompDetailsHeader } from 'src/lib/report-api/components/CompDetailsHeader';

interface Props {
  dataHcName: string;
  className?: string;
}

export const CompPhotoPage = ({ dataHcName, className }: Props) => {
  const {
    state: { active, compId, reportId, hcAddressId, onClose }
  } = usePhotosPage();

  if (reportId == null || hcAddressId == null) {
    return null;
  }

  return (
    <PhotosPage
      active={active}
      dataHcName={dataHcName}
      className={className}
      reportId={reportId}
      header={
        !compId ? null : (
          <CompDetailsHeader
            compId={compId}
            reportId={reportId}
            dataHcName={`${dataHcName}-comp-details-header`}
          />
        )
      }
      hcAddressId={hcAddressId}
      onClose={onClose}
    />
  );
};
