import { STATUSES } from 'legacy/appstore/constants';

import {
  INIT_FORGOT_PASSWORD,
  SUBMIT_FORGOT_PASSWORD,
  SUBMIT_FORGOT_PASSWORD_SUCCESS,
  SUBMIT_FORGOT_PASSWORD_ERROR,
  VERIFY_RESET_PASSWORD_SUCCESS,
  VERIFY_RESET_PASSWORD_FAILURE,
  VERIFY_RESET_PASSWORD_EXPIRED
} from 'actions/forgot-password';

const defaultState = {
  status: STATUSES.INIT,
  error: null,
  temporaryToken: null
};

export default function forgotPasswordReducer(state = defaultState, action) {
  switch (action.type) {
    case INIT_FORGOT_PASSWORD: {
      return {
        status: STATUSES.INIT,
        error: null,
        temporaryToken: null
      };
    }

    case SUBMIT_FORGOT_PASSWORD: {
      return {
        status: STATUSES.LOADING,
        error: null,
        temporaryToken: null
      };
    }

    case SUBMIT_FORGOT_PASSWORD_SUCCESS: {
      return {
        status: STATUSES.SUCCESS,
        error: null,
        temporaryToken: null
      };
    }

    case VERIFY_RESET_PASSWORD_SUCCESS: {
      return {
        status: STATUSES.SUCCESS,
        error: null,
        temporaryToken: action.payload.temporaryToken
      };
    }

    case VERIFY_RESET_PASSWORD_FAILURE:
    case SUBMIT_FORGOT_PASSWORD_ERROR: {
      return {
        status: STATUSES.ERROR,
        error: action.payload.error,
        temporaryToken: null
      };
    }

    case VERIFY_RESET_PASSWORD_EXPIRED: {
      return {
        status: 'expired',
        error: action.payload.error,
        temporaryToken: null
      };
    }

    default:
      return state;
  }
}
