import { EVENTS } from 'legacy/appstore/events';

import { AVM_KEY_USER } from 'legacy/appstore/constants';

import {
  determineShapeTypeFromGeoJson,
  buildRadiusGeoJson
} from 'legacy/utils/maps';

export const COMPS_SHOW_ON_MAP = 'COMPS_SHOW_ON_MAP';
export const COMPS_SHOW_ON_MAP_CLEAR = 'COMPS_SHOW_ON_MAP_CLEAR';
export const COMPS_SORT = 'COMPS_SORT';
export const COMPS_CLEAR_SORT = 'COMPS_CLEAR_SORT';
export const COMPS_FILTERS_APPLY = 'COMPS_FILTERS_APPLY';
export const COMPS_FILTER_DISTANCE_CHANGE = 'COMPS_FILTER_DISTANCE_CHANGE';
export const COMPS_FILTERS_CHANGE_ABSOLUTE = 'COMPS_FILTERS_CHANGE_ABSOLUTE';
export const COMPS_FILTERS_CHANGE_RELATIVE = 'COMPS_FILTERS_CHANGE_RELATIVE';
export const COMPS_FILTERS_SAVE = 'COMPS_FILTERS_SAVE';
export const COMPS_DELETE_SAVED_FILTER = 'COMPS_DELETE_SAVED_FILTER';
export const COMPS_CLEAR_ALL_FILTERS = 'COMPS_CLEAR_ALL_FILTERS';
export const COMPS_CLEAR_FILTER = 'COMPS_CLEAR_FILTER';
export const COMPS_SEARCH = 'COMPS_SEARCH';
export const COMPS_SELECT_TOGGLE = 'COMPS_SELECT_TOGGLE';
export const COMPS_FOCUS_TOGGLE = 'COMPS_FOCUS_TOGGLE';
export const COMPS_ADJUST_SAVE = 'COMPS_ADJUST_SAVE';
export const COMPS_AFTER_LIST_SCROLL = 'COMPS_AFTER_LIST_SCROLL';
export const COMPS_SET_LIST_SCROLLED_TO_COMP =
  'COMPS_SET_LIST_SCROLLED_TO_COMP';
export const COMPS_DETERMINE_ADD_COMP = 'COMPS_DETERMINE_ADD_COMP';
export const COMPS_ADD_COMP = 'COMPS_ADD_COMP';
export const COMPS_ADD_COMP_SUCCESS = 'COMPS_ADD_COMP_SUCCESS';
export const COMPS_UNSELECT_ALL = 'COMPS_UNSELECT_ALL';
export const COMPS_FARM_UPDATE_SUCCESS = 'COMPS_FARM_UPDATE_SUCCESS';
export const COMPS_FARM_UPDATE_FAILURE = 'COMPS_FARM_UPDATE_FAILURE';
export const COMPS_SORT_SELECTED = 'COMPS_SORT_SELECTED';
export const COMPS_MAP_DRAW_SUBMIT = 'COMPS_MAP_DRAW_SUBMIT';
export const COMPS_MAP_DRAW_DELETE_ALL = 'COMPS_MAP_DRAW_DELETE_ALL';
export const COMPS_LIST_CHANGE_VIEW = 'COMPS_LIST_CHANGE_VIEW';
export const COMPS_CHANGE_FILTER_SET_ACTIVE = 'COMPS_CHANGE_FILTER_SET_ACTIVE';
export const COMPS_CHANGE_FILTER_SET_PRIMARY =
  'COMPS_CHANGE_FILTER_SET_PRIMARY';
export const COMPS_REFRESH = 'COMPS_REFRESH';
export const COMPS_REFRESH_READY_FOR_RECOMPUTE =
  'COMPS_REFRESH_READY_FOR_RECOMPUTE';
export const COMPS_REFRESH_CLEAR_VALUE_CHANGE_WARNING =
  'COMPS_REFRESH_CLEAR_VALUE_CHANGE_WARNING';
export const COMPS_KEYWORD_SEARCH_SUBMIT = 'COMPS_KEYWORD_SEARCH_SUBMIT';
export const COMPS_KEYWORD_SEARCH_SUBMIT_SUCCESS =
  'COMPS_KEYWORD_SEARCH_SUBMIT_SUCCESS';
export const COMPS_KEYWORD_SEARCH_SUBMIT_FAILURE =
  'COMPS_KEYWORD_SEARCH_SUBMIT_FAILURE';

export const COMPS_TAG_ADD = 'COMPS_TAG_ADD';
export const COMPS_TAG_REMOVE = 'COMPS_TAG_REMOVE';
export const COMPS_TAG_FILTER = 'COMPS_TAG_FILTER';

// Used only for effective date reports to differentiate changed filters from applied on load
export const COMPS_FITERS_CHANGE_COMPLETE = 'COMPS_FITERS_CHANGE_COMPLETE';
export const compsFiltersChangeComplete = (originalChange) => {
  return {
    type: COMPS_FITERS_CHANGE_COMPLETE,
    payload: originalChange
  };
};

export const compsTagAdd = (addressId, tag) => {
  return {
    type: COMPS_TAG_ADD,
    payload: { addressId, tag },
    meta: {
      analytics: {
        event: EVENTS.COMPS_TAG_ADD,
        props: {
          addressId,
          tag
        }
      }
    }
  };
};

export const compsTagRemove = (addressId, tag) => {
  return {
    type: COMPS_TAG_REMOVE,
    payload: { addressId, tag },
    meta: {
      analytics: {
        event: EVENTS.COMPS_TAG_REMOVE,
        props: {
          addressId,
          tag
        }
      }
    }
  };
};

export const compsTagFilter = (tag) => {
  return {
    type: COMPS_TAG_FILTER,
    payload: { tag },
    meta: {
      analytics: {
        event: EVENTS.COMPS_TAG_FILTER,
        props: {
          tag
        }
      }
    }
  };
};

export const compsShowOnMap = (comp, pinOnly) => {
  return {
    type: COMPS_SHOW_ON_MAP,
    payload: { comp, pinOnly }
  };
};

export const compsShowOnMapClear = () => {
  return {
    type: COMPS_SHOW_ON_MAP_CLEAR
  };
};

export const compsKeywordSearchSubmit = (keywords) => {
  return {
    type: COMPS_KEYWORD_SEARCH_SUBMIT,
    payload: { keywords }
  };
};

export const compsKeywordSearchSubmitSuccess = (matchesByAddressId) => {
  return {
    type: COMPS_KEYWORD_SEARCH_SUBMIT_SUCCESS,
    payload: { matchesByAddressId }
  };
};

export const compsKeywordSearchSubmitFailure = () => {
  return {
    type: COMPS_KEYWORD_SEARCH_SUBMIT_FAILURE
  };
};

export const compsRefresh = () => {
  return {
    type: COMPS_REFRESH
  };
};

export const compsRefreshReadyForRecompute = ({
  hcCompsFarmList,
  userCompsFarmList,
  previousCompAddressIds
}) => {
  return {
    type: COMPS_REFRESH_READY_FOR_RECOMPUTE,
    payload: {
      hcCompsFarmList,
      userCompsFarmList,
      previousCompAddressIds,
      recomputeHints: {
        action: 'selectComp'
      }
    }
  };
};

export const compsRefreshClearValueChangeWarning = () => {
  return {
    type: COMPS_REFRESH_CLEAR_VALUE_CHANGE_WARNING
  };
};

export const compsListChangeView = ({ viewSelected, viewType }) => {
  return {
    type: COMPS_LIST_CHANGE_VIEW,
    payload: { viewSelected, viewType },
    meta: {
      analytics: {
        event: EVENTS.COMPS_LIST_VIEW_CHANGE,
        props: {
          view_type: viewType,
          view_selected: viewSelected
        }
      },
      router: {
        query: { viewSelected, viewType },
        options: {
          inherit: true
        }
      }
    }
  };
};

export const compsDetermineAddComp = (slug) => ({
  type: COMPS_DETERMINE_ADD_COMP,
  payload: { slug },
  meta: {
    analytics: {
      event: EVENTS.COMPS_ADD_SEARCH,
      props: {
        address_slug: slug
      }
    }
  }
});

export const compsAddComp = (comp) => ({
  type: COMPS_ADD_COMP,
  payload: { comp }
});

export const compsAddCompSuccess = (comp) => ({
  type: COMPS_ADD_COMP_SUCCESS,
  payload: { comp }
});

export const compsSort = (attr, order) => ({
  type: COMPS_SORT,
  payload: {
    attr,
    order
  },
  meta: {
    analytics: {
      event: EVENTS.COMPS_SORT,
      props: {
        is_viewing_selected: false,
        column: attr,
        order
      }
    }
  }
});

export const compsSortSelected = (attr, order) => ({
  type: COMPS_SORT_SELECTED,
  payload: {
    attr,
    order
  },
  meta: {
    analytics: {
      event: EVENTS.COMPS_SORT,
      props: {
        is_viewing_selected: true,
        column: attr,
        order
      }
    }
  }
});

export const compsClearSort = () => ({
  type: COMPS_CLEAR_SORT
});

export const compsFiltersApply = (filters, available, farm) => {
  return {
    type: COMPS_FILTERS_APPLY,
    payload: { filters, available, farm }
  };
};

export const compsFilterDistanceChange = (mapId, distance, geoLocation) => ({
  type: COMPS_FILTER_DISTANCE_CHANGE,
  payload: {
    mapId,
    polygons: [buildRadiusGeoJson(geoLocation, distance, true)]
  },
  meta: {
    analytics: {
      event: EVENTS.COMPS_FILTER_CHANGE_DISTANCE,
      props: {
        map_id: mapId,
        distance
      }
    }
  }
});

export const compsFiltersChangeRelative = (relativeValues, filterSetId) => {
  return {
    type: COMPS_FILTERS_CHANGE_RELATIVE,
    payload: { relativeValues, filterSetId },
    meta: {
      analytics: {
        event: EVENTS.COMPS_FILTER_CHANGE_RELATIVE,
        props: relativeValues
      }
    }
  };
};

export const compsFiltersChangeAbsolute = (absoluteValues) => {
  return {
    type: COMPS_FILTERS_CHANGE_ABSOLUTE,
    payload: { absoluteValues },
    meta: {
      analytics: {
        event: EVENTS.COMPS_FILTER_CHANGE_ABSOLUTE,
        props: absoluteValues
      }
    }
  };
};

export const compsFiltersSave = ({
  filterSetId,
  filterSetName,
  makeDefault
}) => {
  return {
    type: COMPS_FILTERS_SAVE,
    payload: { filterSetId, filterSetName, makeDefault }
  };
};

export const compsClearFilter = (filterKey, mapId) => ({
  type: COMPS_CLEAR_FILTER,
  payload: { filterKey, mapId }
});

export const compsClearAllFilters = (mapId) => ({
  type: COMPS_CLEAR_ALL_FILTERS,
  payload: { mapId },
  meta: {
    analytics: { event: EVENTS.COMPS_FILTER_CLEAR }
  }
});

export const compsSearch = (searchStr) => ({
  type: COMPS_SEARCH,
  payload: { searchStr }
});

export const compsSelectToggle = (comp) => ({
  type: COMPS_SELECT_TOGGLE,
  payload: {
    comp,
    recomputeHints: {
      action: 'selectComp'
    }
  }
});

export const compsFocusToggle = (comp) => ({
  type: COMPS_FOCUS_TOGGLE,
  payload: { comp },
  meta: {
    analytics: comp && {
      event: EVENTS.COMPS_VIEW_PROPERTY_DETAILS,
      props: {
        address_slug: comp.addressSlug
      }
    }
  }
});

export const compsAdjustSave = (adjustments) => {
  return {
    type: COMPS_ADJUST_SAVE,
    payload: {
      adjustments,
      recomputeHints: {
        subjectModified: false,
        modifiedCompsAddressIds: Object.keys(adjustments).map(parseInt),
        skip: [AVM_KEY_USER]
      }
    },
    meta: {
      analytics: {
        event: EVENTS.COMPS_ADJUST_SAVE,
        props: { adjustments: adjustments }
      }
    }
  };
};

export const compsUnselectAll = () => ({
  type: COMPS_UNSELECT_ALL,
  payload: {
    recomputeHints: {
      action: 'selectComp'
    }
  }
});

export const compsAfterListScroll = (compIds) => ({
  type: COMPS_AFTER_LIST_SCROLL,
  payload: {
    compIds
  }
});

export const compsSetListScrolledToComp = (comp) => ({
  type: COMPS_SET_LIST_SCROLLED_TO_COMP,
  payload: {
    comp
  }
});

export const compsFarmUpdateSuccess = (
  farm,
  existingAddressIds = [],
  confidence
) => ({
  type: COMPS_FARM_UPDATE_SUCCESS,
  payload: { farm, existingAddressIds, confidence }
});

export const compsFarmUpdateFailure = () => ({
  type: COMPS_FARM_UPDATE_FAILURE
});

export const compsMapDrawSubmit = (mapId, polygons, nextView) => ({
  type: COMPS_MAP_DRAW_SUBMIT,
  payload: { mapId, polygons },
  meta: {
    analytics: {
      event: EVENTS.MAP_DRAW_SUBMIT,
      props: {
        map_id: mapId,
        shape: determineShapeTypeFromGeoJson(polygons[0])
      }
    }
  }
});

export const compsMapDrawDeleteAll = (mapId, nextView) => ({
  type: COMPS_MAP_DRAW_DELETE_ALL,
  payload: { mapId },
  meta: {
    analytics: {
      event: EVENTS.MAP_DRAW_DELETE,
      props: {
        map_id: mapId
      }
    }
  }
});

export const compsDeleteSavedFilter = (filterSetId) => {
  return {
    type: COMPS_DELETE_SAVED_FILTER,
    payload: { filterSetId },
    meta: {
      analytics: {
        event: EVENTS.COMPS_DELETE_FILTER_SET,
        props: { filter_set_id: filterSetId }
      }
    }
  };
};

export const compsChangeFilterSetActive = (filterSetId) => {
  return {
    type: COMPS_CHANGE_FILTER_SET_ACTIVE,
    payload: { filterSetId },
    meta: {
      analytics: {
        event: EVENTS.COMPS_CHANGE_FILTER_SET_ACTIVE,
        props: { filter_set_id: filterSetId }
      }
    }
  };
};

export const compsChangeFilterSetPrimary = (filterSetId) => {
  return {
    type: COMPS_CHANGE_FILTER_SET_PRIMARY,
    payload: { filterSetId },
    meta: {
      analytics: {
        event: EVENTS.COMPS_CHANGE_FILTER_SET_PRIMARY,
        props: { filter_set_id: filterSetId }
      }
    }
  };
};
