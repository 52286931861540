import { connect } from 'react-redux';
import { getCurrentView } from '@hc/redux-saga-router-plus/hclib/selectors';

import {
  COMP_ID_KEY,
  COMP_TYPE_DEFAULT,
  COMP_TYPE_RENTAL,
  PROPERTY_TYPE_SUBJECT,
  PROPERTY_TYPE_MLS_LISTING
} from 'legacy/appstore/constants';
import { COMP_SELECTION_VIEWS } from 'legacy/routes/constants';

import { getPropertyCardPriceAndDate } from 'legacy/utils/property-details';

import { compsSelectToggle, compsFocusToggle } from 'actions/comps';
import {
  rentalCompsSelectToggle,
  rentalCompsFocusToggle
} from 'actions/rental-comps';
import { photosDialogShow } from 'actions/photos';

import {
  getCompsSelectedIds,
  getCompByAddressId,
  getCompsKeywordMatchesByAddressId
} from 'selectors/comps';
import {
  getRentalCompsSelectedIds,
  getRentalCompByAddressId,
  getRentalCompsKeywordMatchesByAddressId
} from 'selectors/rental-comps';
import { getIsLessThanSmallMedium } from 'selectors/match-media';
import { getMLSListingById } from 'selectors/mls-listings';
import { getPhotosByAddressId } from 'selectors/photos';
import { getSubjectAdjusted } from 'selectors/subject';
import { getIsReportEditable } from 'selectors/report-permissions';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import { getReportStatusEffectiveDateIsLoading } from 'selectors/report-status';

import PropertyCard from 'legacy/components/PropertyCard';
import { getFeatureFlagsData } from '../appstore/selectors/feature-flags.selectors';

const compActionsDefault = {
  selectComp: compsSelectToggle,
  focusComp: compsFocusToggle
};

const compSelectorsDefault = {
  getCompsSelectedIds: getCompsSelectedIds,
  getProperty: getCompByAddressId,
  getKeywordMatches: getCompsKeywordMatchesByAddressId
};

const compActionsRental = {
  selectComp: rentalCompsSelectToggle,
  focusComp: rentalCompsFocusToggle
};

const compSelectorsRental = {
  getCompsSelectedIds: getRentalCompsSelectedIds,
  getProperty: getRentalCompByAddressId,
  getKeywordMatches: getRentalCompsKeywordMatchesByAddressId
};

const mapStateToProps = (state, ownProps) => {
  const { addressId, isSubject, compType, hideSelectControl } = ownProps;
  const featureFlags = getFeatureFlagsData(state);
  const compSelectors =
    compType === COMP_TYPE_DEFAULT ? compSelectorsDefault : compSelectorsRental;
  const property =
    compType === PROPERTY_TYPE_MLS_LISTING
      ? getMLSListingById(addressId)(state)
      : isSubject
      ? getSubjectAdjusted(state)
      : compSelectors.getProperty(addressId)(state);
  const compsSelectedIds = compSelectors.getCompsSelectedIds(state);
  const isSelected = compsSelectedIds.indexOf(property[COMP_ID_KEY]) > -1;
  const reachSelectedLimit =
    compsSelectedIds &&
    compsSelectedIds.length >= featureFlags['increased-comp-limit'];
  const keywordMatches = compSelectors.getKeywordMatches(state) || {};
  const isEditable = getIsReportEditable(state);
  const isEffectiveDateReport = getIsEffectiveDateReport(state);
  const isEffectiveDateLoading = getReportStatusEffectiveDateIsLoading(state);
  return {
    isEffectiveDateReport,
    hideSelectControl:
      hideSelectControl ||
      (COMP_SELECTION_VIEWS.indexOf(getCurrentView(state)) === -1 &&
        compsSelectedIds.length === 0),
    selectDisabled:
      (!isSelected && reachSelectedLimit) ||
      !isEditable ||
      (isEffectiveDateReport && isEffectiveDateLoading),
    alwaysShowThumbnailSliderControls: getIsLessThanSmallMedium(state),
    photos: getPhotosByAddressId(addressId)(state),
    ...getPropertyCardPriceAndDate(property, compType),
    isKeywordMatch: keywordMatches[addressId],
    property:
      compType === PROPERTY_TYPE_MLS_LISTING
        ? {
            ...property,
            geoLocation: {
              latitude: property.lat,
              longitude: property.lon
            }
          }
        : property,
    addressId,
    isSelected
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { addressId, compType, isSubject, noOnClick, onClickAction } = ownProps;
  const compActions =
    compType === COMP_TYPE_DEFAULT ? compActionsDefault : compActionsRental;
  return {
    showPhotosDialog: (currentIndex, listingId) =>
      dispatch(
        photosDialogShow(addressId, {
          propertyType: isSubject ? PROPERTY_TYPE_SUBJECT : compType,
          currentIndex,
          listingId
        })
      ),
    onClick: (property) => {
      if (onClickAction) {
        dispatch(onClickAction(property));
      } else if (compType && !noOnClick) {
        dispatch(compActions.focusComp(property));
      }
    },
    onSelectToggle: (property) => {
      if (compType === COMP_TYPE_DEFAULT || compType === COMP_TYPE_RENTAL) {
        dispatch(compActions.selectComp(property));
      }
    }
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { addressId } = ownProps;
  const { property } = stateProps;
  const { showPhotosDialog, PhotosService, ...restDispatchActions } =
    dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    ...restDispatchActions,
    queuePhotos: () => PhotosService.queue(addressId),
    dequeuePhotos: () => PhotosService.dequeue(addressId),
    onClick: () => {
      dispatchProps.onClick(property);
    },
    onSelectToggle: () => dispatchProps.onSelectToggle(property)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PropertyCard);
