import { KeywordSearchInputs, KeywordSearchResult } from './types';

export const KEYWORD_SEARCH_SUBMIT = 'keyword-search/SUBMIT';
export const KEYWORD_SEARCH_SUBMIT_SUCCESS = 'keyword-search/SUBMIT_SUCCESS';
export const KEYWORD_SEARCH_SUBMIT_ERROR = 'keyword-search/SUBMIT_ERROR';

export interface KeywordSearchSubmitAction {
  type: typeof KEYWORD_SEARCH_SUBMIT;
  payload: { inputs: KeywordSearchInputs };
}
export const keywordSearchSubmit = (
  inputs: KeywordSearchInputs
): KeywordSearchSubmitAction => {
  return {
    type: KEYWORD_SEARCH_SUBMIT,
    payload: {
      inputs
    }
  };
};

export interface KeywordSearchSubmitSuccessAction {
  type: typeof KEYWORD_SEARCH_SUBMIT_SUCCESS;
  payload: {
    inputs: KeywordSearchInputs;
    data: KeywordSearchResult[];
  };
}
export const keywordSearchSubmitSuccess = (
  inputs: KeywordSearchInputs,
  data: KeywordSearchResult[]
): KeywordSearchSubmitSuccessAction => {
  return {
    type: KEYWORD_SEARCH_SUBMIT_SUCCESS,
    payload: { inputs, data }
  };
};

export interface KeywordSearchSubmitErrorAction {
  type: typeof KEYWORD_SEARCH_SUBMIT_ERROR;
  payload: { inputs: KeywordSearchInputs };
}
export const keywordSearchSubmitError = (
  inputs: KeywordSearchInputs
): KeywordSearchSubmitErrorAction => {
  return {
    type: KEYWORD_SEARCH_SUBMIT_ERROR,
    payload: { inputs }
  };
};

export type KeywordSearchActions =
  | KeywordSearchSubmitAction
  | KeywordSearchSubmitSuccessAction
  | KeywordSearchSubmitErrorAction;
