import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { STATUSES } from 'legacy/appstore/constants';
import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

import { avmToRentalAvm } from 'legacy/utils/avms';
import {
  PROPERTY_DETAILS_ATTRIBUTES,
  SUBJECT_ADJUSTABLE_FIELDS,
  isLocationUnknown
} from 'legacy/utils/property-details';

import { FORM_NAME } from 'legacy/forms/property-explorer-property-details.formconfig';

import {
  getAvmSelectedKey,
  getAvmSelected,
  getAvmRentalSelectedKey,
  getAvmRentalSelected
} from 'selectors/avms';
import {
  getPropertyAttributesLoaded,
  getPropertyDetails,
  getPropertySourceStatusForQuery,
  getPropertyEdits
} from 'selectors/property';
import { getChangedFormValues } from 'selectors/redux-form';

import { Maps as mapsTheme } from 'legacy/css-modules';
import { getIsEffectiveDateReport } from './property-explorer.selectors';

const { HC, MLS, PR } = PROPERTY_DATA_SOURCES;

export const getSubjectState = (state) => state.subject;

export const getSubjectDefault = createSelector(
  getSubjectState,
  getPropertyDetails('subject', PROPERTY_DATA_SOURCES.HC),
  getPropertyDetails('subject', PROPERTY_DATA_SOURCES.MLS),
  getPropertyDetails('subject', PROPERTY_DATA_SOURCES.PR),
  getIsEffectiveDateReport,
  (subjectState, sourceHC, sourceMLS, sourcePR, isEffectiveDateReport) => {
    // TODO: Remove this when we move to graphql
    const { hoaAssociation, hoaFee, hoaFeeIncludes, hoaFeeFrequency } =
      sourceMLS;
    const { taxHistory, taxYear, taxAmount, zoning } = sourcePR;
    const newData = {
      taxHistory,
      taxYear,
      taxAmount,
      hoaAssociation,
      hoaFee,
      hoaFeeIncludes,
      hoaFeeFrequency,
      zoning
    };
    if (isEffectiveDateReport) {
      return {
        ...subjectState.default,
        hoaAssociation,
        hoaFee,
        hoaFeeIncludes,
        hoaFeeFrequency,
        isSubject: true
      };
    } else {
      return {
        ...sourcePR,
        ...sourceMLS,
        ...sourceHC,
        ...subjectState.default,
        ...newData,
        isSubject: true
      };
    }
  }
);

export const getSubjectAdjusted = (state) => {
  const subjectDefault = getSubjectDefault(state);
  const subjectEdits = getPropertyEdits('subject')(state);
  const avmKey = getAvmSelectedKey(state);
  const avm = getAvmSelected(state);
  return {
    ...subjectDefault,
    ...subjectEdits,
    currentValue: avm.value,
    currentAvmType: avmKey
  };
};

export const getSubjectAddressSlug = createSelector(
  getSubjectDefault,
  (subject) => subject.addressSlug
);

export const getSubjectFormValuesChanged = createSelector(
  getChangedFormValues(FORM_NAME),
  (values) => {
    // Handle bathroom type because of input formatting
    if (
      values.hasOwnProperty('bathrooms') &&
      typeof values.bathrooms === 'string'
    ) {
      values.bathrooms = parseFloat(values.bathrooms);
    }
    return values;
  }
);

export const getSubjectSelected = createSelector(
  getSubjectAdjusted,
  (subjectAdjusted) => subjectAdjusted
);

export const getSubjectRental = createSelector(
  getSubjectState,
  getSubjectAdjusted,
  getAvmRentalSelectedKey,
  getAvmRentalSelected,
  (subjectState, subjectAdjusted, rentalAvmKey, rentalAvm) => {
    return {
      ...subjectState.rental,
      ...subjectAdjusted,
      currentAvmTypeRental: rentalAvmKey,
      rentalAvm: avmToRentalAvm(rentalAvm)
    };
  }
);

export const getSubjectAddressId = createSelector(
  getSubjectSelected,
  (subject) => subject.addressId
);

export const getSubjectIsNonDisclosureState = createSelector(
  getSubjectSelected,
  (subject) => subject.isInNonDisclosureState
);

export const getSubjectIsActiveListing = createSelector(
  getSubjectSelected,
  (subject) =>
    (subject.listingStatus &&
      subject.listingStatus.toLowerCase() === 'active') ||
    subject.lastListIsActive
);

export const getSubjectZipcode = createSelector(
  getSubjectSelected,
  (subject) => subject.zipcode
);

export const getSubjectPriceKey = createSelector(
  getSubjectIsNonDisclosureState,
  (isNonDisclosureState) =>
    isNonDisclosureState ? 'lastListPrice' : 'salesPrice'
);

export const getSubjectAdjustments = (state) => {
  const subjectDefault = getSubjectDefault(state);
  const subjectEdits = getPropertyEdits('subject')(state);
  const updatedValues = getSubjectFormValuesChanged(state);
  let adjustedAttrs = {};
  // Combines the saved edits and unsaved edits
  const editsCombined = {
    ...subjectEdits,
    ...updatedValues
  };
  const attrs = Object.keys(editsCombined);
  for (let i = 0; i < attrs.length; i++) {
    const attr = attrs[i];
    if (
      SUBJECT_ADJUSTABLE_FIELDS[attr] &&
      subjectDefault[attr] !== get(editsCombined, attr)
    ) {
      const { label, format } = PROPERTY_DETAILS_ATTRIBUTES[attr];
      adjustedAttrs[attr] = {
        original: subjectDefault[attr],
        adjusted: editsCombined[attr],
        originalDisplay: format(subjectDefault[attr]),
        adjustedDisplay: format(editsCombined[attr]),
        label
      };
    }
  }
  return adjustedAttrs;
};

export const getSubjectHasAdjustments = createSelector(
  getSubjectAdjustments,
  (adjustments) => !isEmpty(adjustments)
);

export const getSubjectAttributeStatuses = createSelector(
  getPropertyAttributesLoaded('subject'),
  (statuses) => statuses
);

export const getSubjectShouldLoadExtendedDetails = createSelector(
  getPropertySourceStatusForQuery('subject', HC, QUERIES.TAX_HISTORY),
  getPropertySourceStatusForQuery('subject', HC, QUERIES.LISTING_DETAILS),
  getSubjectAddressId,
  (taxHistoryStatus, listingDetailsStatus, addressIdSubject) => {
    return (
      addressIdSubject &&
      (taxHistoryStatus === STATUSES.INIT ||
        listingDetailsStatus === STATUSES.INIT)
    );
  }
);

export const getSubjectShouldLoadPropertyDetailsSources = createSelector(
  getSubjectShouldLoadExtendedDetails,
  getPropertySourceStatusForQuery('subject', MLS, QUERIES.PROPERTY_FEATURES),
  getPropertySourceStatusForQuery('subject', PR, QUERIES.PROPERTY_FEATURES),
  (shouldLoadExtended, dataStatusMLS, dataStatusPR) =>
    shouldLoadExtended ||
    !dataStatusMLS ||
    !dataStatusPR ||
    dataStatusMLS === STATUSES.INIT ||
    dataStatusPR === STATUSES.INIT
);

export const getSubjectGeoPrecision = createSelector(
  getSubjectAdjusted,
  (subject) => subject.geoPrecision
);

export const getSubjectLocationUnknown = createSelector(
  getSubjectGeoPrecision,
  (geoPrecision) => isLocationUnknown(geoPrecision)
);

export const getSubjectLocationUserDefined = createSelector(
  getSubjectGeoPrecision,
  (geoPrecision) => geoPrecision === 'user'
);

export const getSubjectMarkerClassNames = createSelector(
  getSubjectLocationUnknown,
  getSubjectLocationUserDefined,
  (locationUnknown, userDefinedLocation) =>
    locationUnknown
      ? mapsTheme.locationUnknown
      : userDefinedLocation
      ? mapsTheme.userDefinedLocation
      : ''
);

export const getSubjectLocationChangable = createSelector(
  getSubjectLocationUnknown,
  getSubjectLocationUserDefined,
  (locationUnknown, userDefinedLocation) =>
    locationUnknown || userDefinedLocation
);
