import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

export function getAccountDetails(state) {
  return state.accountDetails || {};
}

export const getCurrentUser = createSelector(getAccountDetails, (account) => {
  return account.user || {};
});

export const getUserIsSelfService = createSelector(getCurrentUser, (user) => {
  return !!user.stripeCustomerId;
});

export const getUserIsConfirmed = createSelector(getCurrentUser, (user) => {
  return !!user.confirmed;
});

export const getUserIsSelfServiceAndConfirmed = createSelector(
  getUserIsSelfService,
  getUserIsConfirmed,
  (isSelfService, isConfirmed) => {
    return isSelfService && isConfirmed;
  }
);

export const getCurrentTeam = createSelector(getAccountDetails, (account) => {
  return account.currentOrganization || {};
});

export const getIsTeamSelected = createSelector(
  getCurrentTeam,
  (currentTeam) => currentTeam && !isEmpty(currentTeam)
);

export const getAvailableTeams = createSelector(
  getCurrentTeam,
  (account, currentTeam) => {
    return currentTeam ? [currentTeam] : [];
  }
);

export const getIsAccountWithMultipleOrgs = createSelector(
  getAvailableTeams,
  (availableTeams) => {
    return availableTeams.length > 1;
  }
);

export const getValidity = createSelector(getAccountDetails, (account) => {
  return account.validity || {};
});

export const getToken = createSelector(getValidity, (validity) => {
  return validity.token;
});

export const getUserRoles = createSelector(getCurrentUser, (user) => {
  return user.roles || [];
});

export const getIsAdmin = createSelector(getUserRoles, (roles) => {
  for (let i = 0; i < roles.length; i++) {
    if (roles[i].name === 'Administrator' || roles[i].name === 'Power User') {
      return true;
    }
  }
  return false;
});

export const getHasAgileOpsRole = createSelector(getUserRoles, (roles) => {
  for (let i = 0; i < roles.length; i++) {
    if (roles[i].name === 'Agile Ops') {
      return true;
    }
  }
  return false;
});

export const getIsEnterpriseOrganization = createSelector(
  getCurrentTeam,
  (currentTeam) => {
    return currentTeam.enterprise;
  }
);

export const getUserApplications = createSelector(
  getCurrentUser,
  (currentUser) => {
    return currentUser.userApplications || [];
  }
);

export const getOrgApplications = createSelector(
  getAccountDetails,
  (account) => {
    return account.applications;
  }
);

export const getAdminEmailId = createSelector(
  getUserApplications,
  (userApplications) => {
    if (userApplications.length) {
      return userApplications[0].createdBy;
    }
  }
);

export const getShowViewPlans = createSelector(
  getIsAdmin,
  getIsEnterpriseOrganization,
  (isAdmin, isEnterpriseOrganization) => {
    return !!(isAdmin && !isEnterpriseOrganization);
  }
);

export const getAccountDetailsSentry = createSelector(
  getAccountDetails,
  (accountDetails) => {
    let accountDetailsSentry = { ...accountDetails };
    if (accountDetails && accountDetails.validity) {
      // replace token w/ boolean for security but will let us know if token is populated
      accountDetailsSentry.validity.token_type =
        typeof accountDetailsSentry.validity.token;
    }
    return accountDetailsSentry;
  }
);
