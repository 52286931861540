import React from 'react';
import classNames from 'classnames';
import {
  DocumentRoles,
  SubjectDocument,
  useDocumentRole
} from 'src/lib/report-api';
import { PhotoCarousel } from 'src/lib/property-lookup/features/PhotosCarousel';
import styles from './SubjectPhotoCard.css';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { SubjectSchema } from 'src/lib/report-api/types/schemas';
import { isLoading } from 'src/lib/utils/general';
import { StreetViewLauncher } from 'src/lib/street-view/features/StreetViewLauncher';
import { usePhotosPage } from 'src/lib/report-api/hooks/usePhotosPage';
import { setSubjectPhotoPageAction } from 'src/lib/report-api/providers/PhotosPage/photosPage.actions';

interface Props {
  reportId: number;
  dataHcName: string;
  className?: string;
}

export const SubjectPhotoCard = ({
  className,
  dataHcName,
  reportId
}: Props) => {
  const { dispatch } = usePhotosPage();
  const {
    data: [subjectDocument],
    status: subjectStatus
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  const subjectSchema: SubjectSchema | undefined = subjectDocument?.data;
  if (
    isLoading(subjectStatus) ||
    subjectDocument == null ||
    subjectSchema == null
  ) {
    return (
      <LoadingSpinner
        dataHcName={`${dataHcName}-skeleton`}
        className={styles.LoadingSpinner}
      />
    );
  }
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.SubjectPhotoCard, className)}
    >
      <div className={styles.Photos}>
        {subjectSchema.address.hcAddressId != null ? (
          <PhotoCarousel
            hcAddressId={subjectSchema.address.hcAddressId}
            dataHcName={`${dataHcName}-photo-carousel`}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                setSubjectPhotoPageAction(
                  subjectSchema.address.hcAddressId,
                  reportId
                )
              );
            }}
            includeMapTile
            includeStreetView
          />
        ) : (
          <div>No photos</div>
        )}
      </div>

      <StreetViewLauncher
        address={subjectSchema.address}
        geoLocation={subjectSchema.geoLocation}
        dataHcName={`${dataHcName}-street-view-launcher`}
        theme={{ StreetViewLauncherButton: styles.StreetViewLauncherButton }}
      />
    </div>
  );
};
