import { EVENTS } from 'legacy/appstore/events';

import { transformUniqueLinkDataApiToForm } from 'selectors/share-report';

export const SHARE_REPORT = 'SHARE_REPORT';
export const SHARE_REPORT_OPEN = 'SHARE_REPORT_OPEN';
export const SHARE_REPORT_CLOSE = 'SHARE_REPORT_CLOSE';
export const SHARE_REPORT_MODAL_SUBMIT = 'SHARE_REPORT_MODAL_SUBMIT';
export const SHARE_REPORT_SUBMIT = 'SHARE_REPORT_SUBMIT';
export const SHARE_REPORT_SUBMIT_SUCCESS = 'SHARE_REPORT_SUBMIT_SUCCESS';
export const SHARE_REPORT_SUBMIT_FAILURE = 'SHARE_REPORT_SUBMIT_FAILURE';
export const UNIQUE_LINK_FETCH = 'UNIQUE_LINK_FETCH';
export const UNIQUE_LINK_FETCH_SUCCESS = 'UNIQUE_LINK_FETCH_SUCCESS';
export const UNIQUE_LINK_FETCH_DOES_NOT_EXIST =
  'UNIQUE_LINK_FETCH_DOES_NOT_EXIST';
export const UNIQUE_LINK_CONFIGURE = 'UNIQUE_LINK_CONFIGURE';
export const UNIQUE_LINK_CONFIGURE_SUCCESS = 'UNIQUE_LINK_CONFIGURE_SUCCESS';
export const UNIQUE_LINK_CONFIGURE_FAILURE = 'UNIQUE_LINK_CONFIGURE_FAILURE';

export const shareReport = (bypassPersonalizationPrompt = false) => ({
  type: SHARE_REPORT,
  payload: {
    bypassPersonalizationPrompt
  }
});

export const shareReportOpen = () => ({
  type: SHARE_REPORT_OPEN,
  meta: {
    analytics: {
      event: EVENTS.SHARE_MODAL_OPEN
    }
  }
});

export const shareReportClose = () => ({
  type: SHARE_REPORT_CLOSE
});

export const shareReportModalSubmit = () => ({
  type: SHARE_REPORT_MODAL_SUBMIT
});

export const shareReportSubmit = (formValues) => ({
  type: SHARE_REPORT_SUBMIT,
  payload: {
    formValues
  }
});

export const shareReportSubmitSuccess = (formValues, selectedAvmKey) => ({
  type: SHARE_REPORT_SUBMIT_SUCCESS,
  meta: {
    toast: {
      message:
        formValues.toEmails.length === 1
          ? `Report sent to ${formValues.toEmails.length} email address.`
          : `Report sent to ${formValues.toEmails.length} email addresses.`
    },
    analytics: {
      event: EVENTS.SHARE_SEND,
      props: {
        emails: formValues.toEmails,
        comment: formValues.comment
      }
    }
  }
});

export const shareReportSubmitFailure = (message) => ({
  type: SHARE_REPORT_SUBMIT_FAILURE,
  meta: {
    toast: {
      message
    }
  }
});

export const uniqueLinkFetch = () => ({
  type: UNIQUE_LINK_FETCH
});

export const uniqueLinkFetchSuccess = (uniqueLinkData) => ({
  type: UNIQUE_LINK_FETCH_SUCCESS,
  payload: {
    value: uniqueLinkData,
    formValues: {
      linkConfig: transformUniqueLinkDataApiToForm(uniqueLinkData)
    }
  }
});

export const uniqueLinkFetchDoesNotExist = () => ({
  type: UNIQUE_LINK_FETCH_DOES_NOT_EXIST
});

export const uniqueLinkConfigure = (linkConfig) => ({
  type: UNIQUE_LINK_CONFIGURE,
  payload: { linkConfig }
});

export const uniqueLinkConfigureSuccess = (configType, uniqueLinkData) => {
  const linkConfig = transformUniqueLinkDataApiToForm(uniqueLinkData);
  return {
    type: UNIQUE_LINK_CONFIGURE_SUCCESS,
    payload: {
      value: uniqueLinkData,
      formValues: {
        linkConfig: linkConfig
      }
    },
    meta: {
      toast: {
        message:
          configType === 'CREATE'
            ? 'Shareable Link Created'
            : 'Shareable Link Configuration Updated'
      },
      analytics: {
        event:
          configType === 'CREATE'
            ? EVENTS.SHARE_LINK_CREATE
            : EVENTS.SHARE_LINK_CONFIGURE,
        props: {
          link_config: linkConfig
        }
      }
    }
  };
};

export const uniqueLinkConfigureFailure = (message) => ({
  type: UNIQUE_LINK_CONFIGURE_FAILURE,
  payload: {
    message
  }
});
