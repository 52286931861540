import React from 'react';

import { ChevronDownIcon } from '../../inline-svgs';

import styles from './ValueSelector.css';

interface Props {
  dataHcName: string;
  onClick: VoidFunction;
}

export const ValueSelector = ({ dataHcName, onClick }: Props) => {
  return (
    <a onClick={onClick} className={styles.Link} data-hc-name={dataHcName}>
      Choose value
      <ChevronDownIcon
        dataHcName={`${dataHcName}-chevron-down-icon`}
        className={styles.Chevron}
      />
    </a>
  );
};
