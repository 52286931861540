import React, { useEffect, CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Table.css';

export interface Props {
  // dataHcName not required because it will be passed down from Table
  dataHcName?: string;
  // Offset from top if row is sticky
  stickyRowOffset?: number;
  // Cell is for a sticky column
  sticky?: boolean;
  // Width is required for a sticky column
  width?: number;
  // Offset from left if column is sticky
  stickyOffset?: number;
  // Inline styles
  style?: CSSProperties;
  // Height of cell, Overriden by TableRow if height is set on the row.
  height?: number;
  // Class
  className?: string;
  // Cell Content
  children: ReactNode;
  // How to align cell text
  align?: 'left' | 'right' | 'center';
}

export const TableCell = ({
  dataHcName,
  align = 'left',
  stickyRowOffset,
  sticky,
  stickyOffset,
  height,
  width,
  className,
  style = {},
  children
}: Props) => {
  useEffect(() => {
    if (sticky && width === undefined) {
      console.warn('[Table] sticky is true but a width is not defined');
    }
  }, []);
  return (
    <td
      data-hc-name={dataHcName}
      width={width !== undefined ? width : style.width}
      className={classNames(styles.TableCell, className, {
        [styles.sticky || '']: sticky,
        [styles.left || '']: align === 'left',
        [styles.right || '']: align === 'right',
        [styles.center || '']: align === 'center'
      })}
      style={{
        ...style,
        height: height !== undefined ? `${height}px` : style.height,
        width: width !== undefined ? `${width}px` : style.width,
        top: stickyRowOffset !== undefined ? `${stickyRowOffset}px` : style.top,
        left: stickyOffset !== undefined ? `${stickyOffset}px` : style.left
      }}
    >
      {children}
    </td>
  );
};
