import {
  AvmSchema,
  CompSchema,
  SubjectSchema,
  Schema,
  FarmSchema,
  FilterSchema,
  FilteredSchema
} from './schemas';

export enum DocumentRoles {
  Avm = 'avm',
  Comp = 'comp',
  Farm = 'farm',
  Filter = 'filter',
  Filtered = 'filtered',
  Subject = 'subject'
}

export enum SchemaIds {
  Avm = 'avm',
  Comp = 'comp',
  Farm = 'farm',
  Filter = 'filter',
  Filtered = 'filtered',
  Listing = 'listing',
  Subject = 'subject'
}

export interface DocumentBase {
  createdAt: string;
  deletedAt: string | null;
  documentId: string;
  id: number;
  role: DocumentRoles;
  schemaId: SchemaIds;
  updatedAt: string | null;
  dataRetrievedAt: string;
  data: Schema;
}

export interface SubjectDocument extends DocumentBase {
  hcAddressId: number;
  // Subject
  role: DocumentRoles.Subject;
  schemaId: SchemaIds.Subject;
  data: SubjectSchema;
}

export interface AvmDocument extends DocumentBase {
  role: DocumentRoles.Avm;
  schemaId: SchemaIds.Avm;
  data: AvmSchema;
}

export interface FarmDocument extends DocumentBase {
  role: DocumentRoles.Farm;
  schemaId: SchemaIds.Farm;
  data: FarmSchema;
}

export interface FilterDocument extends DocumentBase {
  role: DocumentRoles.Filter;
  schemaId: SchemaIds.Filter;
  data: FilterSchema;
}

export interface FilteredDocument extends DocumentBase {
  role: DocumentRoles.Filtered;
  schemaId: SchemaIds.Filtered;
  data: FilteredSchema;
}

export interface CompDocument extends DocumentBase {
  hcAddressId: number;
  role: DocumentRoles.Comp;
  schemaId: SchemaIds.Comp;
  data: CompSchema;
}

export type Document =
  | SubjectDocument
  | AvmDocument
  | CompFarmListDocument
  | CompDocument
  | FarmDocument
  | FilterDocument
  | FilteredDocument;
