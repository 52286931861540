/** Pls keep in alpha order within respective categories  */

import React, { MouseEvent } from 'react';
import Svg, { SVGInlineProps } from 'react-svg-inline';

/* Import SVG elements */
import alert from './alert.svg';
import agileAppraisal from './product-icons/agile-appraisal.svg';
import back from './back.svg';
import inspections from './product-icons/inspections.svg';
import agileCertified from './product-icons/agile-certified.svg';
import check from './check.svg';
import chevron from './chevron.svg';
import chevronDark from './chevron-dark.svg';
import chevronDown from './chevron-down.svg';
import close from './close.svg';
import clock from './clock.svg';
import clockHalfFilled from './clock-half-filled.svg';
import clockWithAlert from './clock-alert.svg';
import clockWithCheck from './clock-checked.svg';
import data from './product-icons/data.svg';
import agileEvaluation from './product-icons/agile-evaluation.svg';
import agileEvaluationCertified from './product-icons/agile-evaluation-certified.svg';
import greenAlert from './green-alert.svg';
import info from './info.svg';
import link from './link.svg';
import noImage from './no-image.svg';
import plus from './plus.svg';
import priceTag from './price-tag.svg';
import agileInsights from './product-icons/agile-insights.svg';
import share from './share.svg';
import whitePin from './white-pin.svg';
import download from './download.svg';
import caret from './caret.svg';
import cancelled from './activity/cancelled.svg';
import circle from './circle.svg';
import comment from './comment.svg';
import complete from './activity/complete.svg';
import created from './activity/created.svg';
import inInspection from './activity/in_inspection.svg';
import inspectionCorrectionReview from './activity/inspection_correction_review.svg';
import inspectionNote from './activity/inspection_note.svg';
import inspectionReview from './activity/inspection_review.svg';
import inspectionRevision from './activity/inspection_revision.svg';
import internalNote from './activity/internal_note.svg';
import onHold from './activity/on_hold.svg';
import reportModified from './activity/report_modified.svg';
import revisionRequest from './activity/revision_request.svg';
import userAssigned from './activity/user_assigned.svg';
import valuationReview from './activity/valuation_review.svg';
import circleCheck from './circle-check.svg';
import circleX from './circle-x.svg';
import resizer from './resizer-icon.svg';
import contract from './contract.svg';
import expand from './expand.svg';
import copyExact from './copy-exact.svg';
import menuMore from './menu-more.svg';
import viewHistory from './view-history.svg';
import more from './more.svg';
import home from './home.svg';
import compareView from './list-types/compare.svg';
import cardView from './list-types/card.svg';
import tableView from './list-types/table.svg';
import tableOptions from './table-options.svg';
import compactView from './list-types/compact.svg';
import streetViewIcon from './street-view-icon.svg';
import search from './search.svg';
import mapPinSelectedSold from './map-pins/selected-sold.svg';
import mapPinSelectedActive from './map-pins/selected-active.svg';
import mapPinSelectedPending from './map-pins/selected-pending.svg';
import mapPinUnselectedSold from './map-pins/unselected-sold.svg';
import mapPinUnselectedActive from './map-pins/unselected-active.svg';
import mapPinUnselectedPending from './map-pins/unselected-pending.svg';
import mapPinMultiUnit from './map-pins/multiunit.svg';

export interface SVGInlinePropsExtended extends Omit<SVGInlineProps, 'svg'> {
  onClick?: (e: MouseEvent) => void;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  dataHcName: string;
}

export const useSvg = (
  src: string,
  { dataHcName, ...props }: SVGInlinePropsExtended
) => {
  const height = props.height ? props.height : props.width ? 'auto' : '30px';
  const width = props.width || 'auto';
  return (
    <Svg
      data-hc-name={dataHcName}
      {...props}
      height={height}
      width={width}
      svg={src}
    />
  );
};

/* Export React components */
export const AlertIcon = (props: SVGInlinePropsExtended) =>
  useSvg(alert, props);
export const AgileAppraisalIcon = (props: SVGInlinePropsExtended) =>
  useSvg(agileAppraisal, props);
export const BackIcon = (props: SVGInlinePropsExtended) => useSvg(back, props);
export const ChevronIcon = (props: SVGInlinePropsExtended) =>
  useSvg(chevron, props);
export const ChevronDarkIcon = (props: SVGInlinePropsExtended) =>
  useSvg(chevronDark, props);
export const ClockAlertIcon = (props: SVGInlinePropsExtended) =>
  useSvg(clockWithAlert, props);
export const ClockCheckedIcon = (props: SVGInlinePropsExtended) =>
  useSvg(clockWithCheck, props);
export const ClockHalfIcon = (props: SVGInlinePropsExtended) =>
  useSvg(clockHalfFilled, props);
export const CheckIcon = (props: SVGInlinePropsExtended) =>
  useSvg(check, props);
export const ClockIcon = (props: SVGInlinePropsExtended) =>
  useSvg(clock, props);
export const CloseIcon = (props: SVGInlinePropsExtended) =>
  useSvg(close, props);
export const CommentIcon = (props: SVGInlinePropsExtended) =>
  useSvg(comment, props);
export const InspectionNoteIcon = (props: SVGInlinePropsExtended) =>
  useSvg(inspectionNote, props);
export const DataIcon = (props: SVGInlinePropsExtended) => useSvg(data, props);
export const DownloadIcon = (props: SVGInlinePropsExtended) =>
  useSvg(download, props);
export const HomeIcon = (props: SVGInlinePropsExtended) => useSvg(home, props);
export const AgileEvaluationIcon = (props: SVGInlinePropsExtended) =>
  useSvg(agileEvaluation, props);
export const AgileEvaluationCertifiedIcon = (props: SVGInlinePropsExtended) =>
  useSvg(agileEvaluationCertified, props);
export const GreenAlertIcon = (props: SVGInlinePropsExtended) =>
  useSvg(greenAlert, props);
export const InfoIcon = (props: SVGInlinePropsExtended) => useSvg(info, props);
export const InspectionsIcon = (props: SVGInlinePropsExtended) =>
  useSvg(inspections, props);
export const LinkIcon = (props: SVGInlinePropsExtended) => useSvg(link, props);
export const ContractIcon = (props: SVGInlinePropsExtended) =>
  useSvg(contract, props);
export const ExpandIcon = (props: SVGInlinePropsExtended) =>
  useSvg(expand, props);
export const NoImageIcon = (props: SVGInlinePropsExtended) =>
  useSvg(noImage, props);
export const PlusIcon = (props: SVGInlinePropsExtended) => useSvg(plus, props);
export const PriceTagIcon = (props: SVGInlinePropsExtended) =>
  useSvg(priceTag, props);
export const AgileInsightsIcon = (props: SVGInlinePropsExtended) =>
  useSvg(agileInsights, props);
export const ShareIcon = (props: SVGInlinePropsExtended) =>
  useSvg(share, props);
export const AgileCertifiedIcon = (props: SVGInlinePropsExtended) =>
  useSvg(agileCertified, props);
export const WhitePinIcon = (props: SVGInlinePropsExtended) =>
  useSvg(whitePin, props);
export const CaretIcon = (props: SVGInlinePropsExtended) =>
  useSvg(caret, props);
export const CancelledIcon = (props: SVGInlinePropsExtended) =>
  useSvg(cancelled, props);
export const CircleIcon = (props: SVGInlinePropsExtended) =>
  useSvg(circle, props);
export const CompleteIcon = (props: SVGInlinePropsExtended) =>
  useSvg(complete, props);
export const CreatedIcon = (props: SVGInlinePropsExtended) =>
  useSvg(created, props);
export const InInspectionIcon = (props: SVGInlinePropsExtended) =>
  useSvg(inInspection, props);
export const InspectionCorrectionReviewIcon = (props: SVGInlinePropsExtended) =>
  useSvg(inspectionCorrectionReview, props);
export const InspectionReviewIcon = (props: SVGInlinePropsExtended) =>
  useSvg(inspectionReview, props);
export const InspectionRevisionIcon = (props: SVGInlinePropsExtended) =>
  useSvg(inspectionRevision, props);
export const InternalNoteIcon = (props: SVGInlinePropsExtended) =>
  useSvg(internalNote, props);
export const OnHoldIcon = (props: SVGInlinePropsExtended) =>
  useSvg(onHold, props);
export const ReportModifiedIcon = (props: SVGInlinePropsExtended) =>
  useSvg(reportModified, props);
export const RevisionRequestIcon = (props: SVGInlinePropsExtended) =>
  useSvg(revisionRequest, props);
export const UserAssignedIcon = (props: SVGInlinePropsExtended) =>
  useSvg(userAssigned, props);
export const ValuationReviewIcon = (props: SVGInlinePropsExtended) =>
  useSvg(valuationReview, props);
export const CircleCheckIcon = (props: SVGInlinePropsExtended) =>
  useSvg(circleCheck, props);
export const CircleXIcon = (props: SVGInlinePropsExtended) =>
  useSvg(circleX, props);
export const CopyExactIcon = (props: SVGInlinePropsExtended) =>
  useSvg(copyExact, props);
export const MenuMoreIcon = (props: SVGInlinePropsExtended) =>
  useSvg(menuMore, props);
export const ViewHistoryIcon = (props: SVGInlinePropsExtended) =>
  useSvg(viewHistory, props);
export const MoreIcon = (props: SVGInlinePropsExtended) => useSvg(more, props);
export const ResizerIcon = (props: SVGInlinePropsExtended) =>
  useSvg(resizer, props);
export const CompareViewIcon = (props: SVGInlinePropsExtended) =>
  useSvg(compareView, props);
export const CardViewIcon = (props: SVGInlinePropsExtended) =>
  useSvg(cardView, props);
export const TableViewIcon = (props: SVGInlinePropsExtended) =>
  useSvg(tableView, props);
export const CompactViewIcon = (props: SVGInlinePropsExtended) =>
  useSvg(compactView, props);
export const MapPinSelectedSold = (props: SVGInlinePropsExtended) =>
  useSvg(mapPinSelectedSold, props);
export const MapPinSelectedActive = (props: SVGInlinePropsExtended) =>
  useSvg(mapPinSelectedActive, props);
export const MapPinSelectedPending = (props: SVGInlinePropsExtended) =>
  useSvg(mapPinSelectedPending, props);
export const MapPinUnselectedSold = (props: SVGInlinePropsExtended) =>
  useSvg(mapPinUnselectedSold, props);
export const MapPinUnselectedActive = (props: SVGInlinePropsExtended) =>
  useSvg(mapPinUnselectedActive, props);
export const MapPinUnselectedPending = (props: SVGInlinePropsExtended) =>
  useSvg(mapPinUnselectedPending, props);
export const MapPinMultiUnit = (props: SVGInlinePropsExtended) =>
  useSvg(mapPinMultiUnit, props);
export const ChevronDownIcon = (props: SVGInlinePropsExtended) =>
  useSvg(chevronDown, { height: '8px', ...props });
export const StreetViewIcon = (props: SVGInlinePropsExtended) =>
  useSvg(streetViewIcon, props);
export const SearchIcon = (props: SVGInlinePropsExtended) =>
  useSvg(search, props);
export const TableOptionsIcon = (props: SVGInlinePropsExtended) =>
  useSvg(tableOptions, props);
