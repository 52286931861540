import { call } from 'redux-saga/effects';
import graphqlRequest from 'legacy/services/graphql';

import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

export const listingDetailsQuery = ({ addressId }) => {
  return {
    key: QUERIES.LISTING_DETAILS,
    query: `
      listingDetails (addressId: ${addressId}) {
        hoaFee
        hoaAssociation
        hoaFeeIncludes,
        publicRemarks,
        listingDate
      }
    `
  };
};

const fetchListingDetails = function* (addressId) {
  const query = listingDetailsQuery(addressId);
  return yield call(graphqlRequest, {
    source: PROPERTY_DATA_SOURCES.MLS,
    query
  });
};

export default fetchListingDetails;
