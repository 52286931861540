import RadioSelect from 'legacy/components/RadioSelect';

const FilterRadioSelect = ({
  absoluteValue,
  subjectValue,
  relativeValue,
  filterKey,
  onChangeAbsolute,
  ...inputProps
}) => {
  return (
    <RadioSelect
      {...inputProps}
      value={absoluteValue}
      subjectValue={subjectValue}
      onChange={onChangeAbsolute}
      filterKey={filterKey}
    />
  );
};

export default FilterRadioSelect;
