import { FETCH_MESSAGES, FETCH_MESSAGES_SUCCESS } from 'actions/messages';

const INITIAL_STATE = {
  sync: {
    status: 'MODEL_NOT_LOADED',
    message: null
  },
  value: []
};

export function messagesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MESSAGES:
      return {
        ...state,
        sync: {
          ...state.sync,
          status: 'MODEL_LOADING'
        }
      };

    case FETCH_MESSAGES_SUCCESS:
      return {
        sync: {
          status: 'MODEL_LOADED'
        },
        value: action.payload.messages
      };

    default:
      return state;
  }
}
