// This file contains selectors that indicate if/when sections of the report should fetch their own data
import { createSelector } from 'reselect';

import { apiHasNotBeenCalled } from 'legacy/utils/status';

import { getPreferenesIsLoadedInitial } from 'selectors/preferences';
import {
  getReportStatusSubjectDetailsIsLoadedInitial,
  getReportStatusHcVersionIsLoadedInitial,
  getReportStatusUserVersionIsLoadedInitial,
  getReportStatusUserVersionIsLoadedInitialAndExists,
  getReportStatusUserVersionDoesNotExist,
  getReportStatusComps,
  getReportStatusRentalReport,
  getReportStatusForecast,
  getReportStatusSharedReportConfig,
  getReportStatusNearbyProperties
} from 'selectors/report-status';
import { getIsViewingSharedReport } from 'selectors/property-explorer';

// Load comps farm list and take user-defined area into account
export const getShouldLoadComps = createSelector(
  getReportStatusSubjectDetailsIsLoadedInitial,
  getReportStatusHcVersionIsLoadedInitial,
  getReportStatusUserVersionDoesNotExist,
  getPreferenesIsLoadedInitial,
  getReportStatusComps,
  getIsViewingSharedReport,
  (
    subjectDetailsLoaded,
    hcVersionLoaded,
    userVersionDoesNotExist,
    preferencesLoaded,
    statusComps,
    isViewingSharedReport
  ) => {
    return (
      apiHasNotBeenCalled(statusComps) &&
      !isViewingSharedReport &&
      userVersionDoesNotExist &&
      preferencesLoaded && // Preferences need to be loaded to apply distance filter
      (subjectDetailsLoaded || hcVersionLoaded)
    );
  }
);

// Load an unaltered farm list used for sections that should not be affected by user-defined comp areas
// export const getShouldLoadHcProperties = createSelector();

export const getShouldLoadRentalReport = createSelector(
  getReportStatusHcVersionIsLoadedInitial,
  getReportStatusUserVersionIsLoadedInitialAndExists,
  getReportStatusRentalReport,
  (hcVersionLoaded, userVersionLoadedAndExists, statusRentalReport) => {
    // Api response w/ addressId + addressSlug must have completed successfully
    return (
      apiHasNotBeenCalled(statusRentalReport) &&
      (hcVersionLoaded || userVersionLoadedAndExists)
    );
  }
);

export const getShouldLoadForecast = createSelector(
  getReportStatusSubjectDetailsIsLoadedInitial,
  getReportStatusHcVersionIsLoadedInitial,
  getReportStatusUserVersionIsLoadedInitialAndExists,
  getReportStatusForecast,
  (
    subjectDetailsLoaded,
    hcVersionLoaded,
    userVersionLoadedAndExists,
    statusForecast
  ) => {
    // Api response w/ addressId + addressSlug must have completed successfully
    return (
      apiHasNotBeenCalled(statusForecast) &&
      (subjectDetailsLoaded || hcVersionLoaded || userVersionLoadedAndExists)
    );
  }
);

export const getShouldLoadSharedReportConfig = createSelector(
  getReportStatusHcVersionIsLoadedInitial,
  getReportStatusUserVersionIsLoadedInitialAndExists,
  getReportStatusSharedReportConfig,
  (hcVersionLoaded, userVersionLoadedAndExists, statusSharedReportConfig) => {
    // Api response w/ propertyLookup.id must have completed successfully
    return (
      apiHasNotBeenCalled(statusSharedReportConfig) &&
      (hcVersionLoaded || userVersionLoadedAndExists)
    );
  }
);

export const getShouldLoadNearbyProperties = createSelector(
  getReportStatusSubjectDetailsIsLoadedInitial,
  getReportStatusHcVersionIsLoadedInitial,
  getReportStatusUserVersionIsLoadedInitial,
  getReportStatusNearbyProperties,
  getIsViewingSharedReport,
  (
    subjectDetailsLoaded,
    hcVersionLoaded,
    userVersionLoadedInitial,
    statusNearbyProperties,
    isViewingSharedReport
  ) => {
    // Api response w/ addressId + addressSlug must have completed successfully
    return (
      apiHasNotBeenCalled(statusNearbyProperties) &&
      !isViewingSharedReport &&
      userVersionLoadedInitial &&
      (subjectDetailsLoaded || hcVersionLoaded)
    );
  }
);
