import React from 'react';
import classNames from 'classnames';
import styles from './CompSearch.css';
import { SearchByMlsNumber } from 'src/lib/search-by-mls-number';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { useDocumentRole, useReport } from '../../hooks';
import { DocumentRoles, FarmDocument, SubjectDocument } from '../../types';
import { isLoading } from 'src/lib/utils/general';
import { SearchIcon } from 'src/lib/inline-svgs';
import { Popover } from 'src/lib/components/Popover';
import { useScreenSize } from 'src/lib/hooks/useScreenSize';

export const COMP_SEARCH_QUERY_ID = 'comp-search';
interface Props {
  reportId: number;
  dataHcName: string;
  className?: string;
}
export const CompSearch = ({ dataHcName, className, reportId }: Props) => {
  const { status: reportStatus, data: report } = useReport(reportId);
  const { isMobile } = useScreenSize();
  const {
    status: subjectStatus,
    data: [subjectDocument]
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  const {
    status: farmStatus,
    data: [farmDocument]
  } = useDocumentRole<FarmDocument>(reportId, DocumentRoles.Farm);
  if (
    isLoading([reportStatus, subjectStatus, farmStatus]) ||
    !report ||
    !subjectDocument ||
    !farmDocument
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const content = (
    <div className={classNames(styles.CompSearch, className)}>
      <SearchByMlsNumber
        dataHcName={`${dataHcName}-search-by-mls-number`}
        queryId={COMP_SEARCH_QUERY_ID}
        onSelect={async () => {
          // TODO: Finish this feature
          // const { data: comp } = await ReportApi.listingToComp(
          //   token,
          //   report.reportType,
          //   report.effectiveDate,
          //   listing,
          //   subjectDocument.data
          // );
        }}
      />
    </div>
  );
  if (isMobile) {
    return (
      <Popover
        trigger={<SearchIcon dataHcName={`${dataHcName}-mobile-icon`} />}
        content={content}
      />
    );
  } else {
    return content;
  }
};
