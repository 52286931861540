import { ListingStatus, ListingStatusData } from '../property-lookup/types';

export type ListingStatusNormalized =
  | ListingStatus.ACTIVE
  | ListingStatus.PENDING
  | ListingStatus.SOLD
  | ListingStatus.LEASED;

export const LISTING_STATUSES_ACTIVE: ListingStatus[] = [
  ListingStatus.ACTIVE,
  ListingStatus.COMING_SOON
];
export const LISTING_STATUSES_PENDING: ListingStatus[] = [
  ListingStatus.PENDING,
  ListingStatus.CONTINGENT
];
export const LISTING_STATUSES_SOLD: ListingStatus[] = [ListingStatus.SOLD];
export const LISTING_STATUSES_LEASED: ListingStatus[] = [ListingStatus.LEASED];

export const LISTING_STATUSES_BY_NORMALIZED_STATUS: {
  SOLD: ListingStatus[];
  PENDING: ListingStatus[];
  ACTIVE: ListingStatus[];
  LEASED: ListingStatus[];
} = {
  SOLD: LISTING_STATUSES_SOLD,
  PENDING: LISTING_STATUSES_PENDING,
  ACTIVE: LISTING_STATUSES_ACTIVE,
  LEASED: LISTING_STATUSES_LEASED
};

export const lookupListingStatusByNormalizedStatus = (
  listingStatusNormalized:
    | ListingStatus.ACTIVE
    | ListingStatus.PENDING
    | ListingStatus.SOLD
    | ListingStatus.LEASED
): ListingStatus[] =>
  LISTING_STATUSES_BY_NORMALIZED_STATUS[listingStatusNormalized];

export const listingStatusNormalized = (
  listingStatusData: Pick<ListingStatusData, 'MlsState'>
): ListingStatusNormalized =>
  listingStatusData.MlsState
    ? LISTING_STATUSES_ACTIVE.includes(listingStatusData.MlsState)
      ? ListingStatus.ACTIVE
      : LISTING_STATUSES_PENDING.includes(listingStatusData.MlsState)
      ? ListingStatus.PENDING
      : ListingStatus.SOLD
    : ListingStatus.SOLD;

export const rentalListingStatusNormalized = (
  listingStatusData: Pick<ListingStatusData, 'MlsState'>
): ListingStatusNormalized =>
  listingStatusData.MlsState
    ? LISTING_STATUSES_ACTIVE.includes(listingStatusData.MlsState)
      ? ListingStatus.ACTIVE
      : LISTING_STATUSES_PENDING.includes(listingStatusData.MlsState)
      ? ListingStatus.PENDING
      : ListingStatus.LEASED
    : ListingStatus.LEASED;

// Determine main date to display based on the listing status
export const getMarketPriceDate = (
  listingStatusData: Pick<
    ListingStatusData,
    'MlsState' | 'listingDate' | 'saleDate'
  >
) => {
  const status = listingStatusData?.MlsState;
  return status &&
    [ListingStatus.ACTIVE, ListingStatus.PENDING].includes(
      listingStatusNormalized(listingStatusData)
    )
    ? listingStatusData.listingDate
    : listingStatusData.saleDate;
};

// Determine main price to display based on the listing status
export const getMarketPrice = (
  listingStatusData: Pick<
    ListingStatusData,
    'MlsState' | 'listingPrice' | 'salePrice'
  >
) => {
  return [ListingStatus.ACTIVE, ListingStatus.PENDING].includes(
    listingStatusNormalized(listingStatusData)
  )
    ? listingStatusData.listingPrice
    : listingStatusData.salePrice;
};

// Determine main date to display based on the listing status
// not sure what date to use
// export const propertyRentalListingStatusDate = (listingStatusData: Pick<ListingStatusData, 'MlsState' | 'listingDate' | 'saleDate' | ''>): string | null => {
//   [ListingStatus.ACTIVE, ListingStatus.PENDING].includes(propertyListingStatusNormalized(listingStatusData))
// )
//   ? rentalListDate
//   : leaseDate;

// Not sure what price to use
// export const propertyRentalListingStatusPrice = (status?: ListingStatus | null, rentalListPrice?: number | null, leasePrice?: number | null) => (
//   [ListingStatus.ACTIVE, ListingStatus.PENDING].includes(propertyListingStatusNormalized(status)
//   )
//     ? rentalListPrice
//     : leasePrice || rentalListPrice
// );
