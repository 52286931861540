import { createSelector } from 'reselect';

const HANDHELD = {
  xsmall: true,
  smallxsm: true
};

const lessThanXLarge = {
  xsmall: true,
  smallxsm: true,
  small: true,
  smallmed: true,
  medium: true,
  large: true
};

const lessThanLarge = {
  xsmall: true,
  smallxsm: true,
  small: true,
  smallmed: true,
  medium: true
};

const lessThanMedium = {
  xsmall: true,
  smallxsm: true,
  small: true
};

const lessThanSmallMedium = {
  xsmall: true,
  smallxsm: true,
  small: true,
  smallmed: true
};

const mqLargeAndUp = {
  large: true,
  xlarge: true
};

const mqSmallMediumAndUp = {
  smallmed: true,
  medium: true,
  large: true,
  xlarge: true
};

const mqSmallAndUp = {
  small: true,
  smallmed: true,
  medium: true,
  large: true,
  xlarge: true
};

export const getBrowserDetails = (state) => state.browser;

export const getBrowserIsInitialLoad = createSelector(
  getBrowserDetails,
  (browserState) => browserState.initialLoad
);

export const getIsIOSDevice = (state) => getBrowserDetails(state).deviceIOS;

export const getIsAndroidDevice = (state) =>
  getBrowserDetails(state).deviceAndroid;

export const getScreenSize = (state) => getBrowserDetails(state).screenSize;

export const getScreenWidth = (state) => getBrowserDetails(state).screenWidth;

export const getMqXSmallOnly = (state) => getScreenSize(state) === 'xsmall';

export const getMqSmallOnly = (state) => getScreenSize(state) === 'small';

export const getMqMediumOnly = (state) => getScreenSize(state) === 'medium';

export const getMqLargeOnly = (state) => getScreenSize(state) === 'large';

export const getMqXLargeOnly = (state) => getScreenSize(state) === 'xlarge';

export const getMqSmallAndUp = (state) =>
  mqSmallAndUp.hasOwnProperty(getScreenSize(state));

export const getMqSmallMediumAndUp = (state) =>
  mqSmallMediumAndUp.hasOwnProperty(getScreenSize(state));

export const getMqLargeAndUp = (state) =>
  mqLargeAndUp.hasOwnProperty(getScreenSize(state));

export const getIsLessThanSmallMedium = (state) =>
  lessThanSmallMedium.hasOwnProperty(getScreenSize(state));

export const getIsLessThanMedium = (state) =>
  lessThanMedium.hasOwnProperty(getScreenSize(state));

export const getIsLessThanLarge = (state) =>
  lessThanLarge.hasOwnProperty(getScreenSize(state));

export const getIsLessThanXLarge = (state) =>
  lessThanXLarge.hasOwnProperty(getScreenSize(state));

export const getIsMobile = createSelector(
  getMqSmallMediumAndUp,
  (notMobile) => !notMobile
);

export const getIsInternetExplorer = createSelector(
  getBrowserDetails,
  (browserDetails) => browserDetails.isInternetExplorer
);

export const getIsHandheld = (state) =>
  HANDHELD.hasOwnProperty(getScreenSize(state));
