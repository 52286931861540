import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import { getReportDataEffectiveDate } from 'selectors/report-data';
import { useListings } from 'legacy/hocs/useListings';

export const useListing = (addressIdInput, { listingId } = {}) => {
  const { status, listings } = useListings(addressIdInput);
  const isEffectiveDateReport = useSelector(getIsEffectiveDateReport);
  const effectiveDate = useSelector(getReportDataEffectiveDate);
  const listing = useMemo(() => {
    let match;
    listings.forEach((l) => {
      if (listingId) {
        // Get specific listing
        if (listingId === l.listingID) {
          match = l;
        }
      } else if (isEffectiveDateReport) {
        // Get first listing preceding effective date
        // If listing is before effective date
        if (effectiveDate && l.statusDate && effectiveDate > l.statusDate) {
          // If listing is more recent than current match
          if (!match || match.effectiveDate < l.statusDate) {
            match = l;
          }
        }
      } else if (!match || match.statusDate < l.statusDate) {
        // Get most recent listing
        match = l;
      }
    });
    return match;
  }, [addressIdInput, listings, listingId, effectiveDate]);
  return { status, listing };
};
