import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import {
  Table,
  TableCell,
  TableHeader,
  TableRow
} from 'src/lib/components/Table';
import { Props as TableRowProps } from 'src/lib/components/Table/TableRow';
import { Props as TableCellProps } from 'src/lib/components/Table/TableCell';
import { Props as TableHeaderCellProps } from 'src/lib/components/Table/TableHeaderCell';
import { useComp, useDocumentRole } from '../../../hooks';
import classNames from 'classnames';
import {
  CompDocument,
  DocumentRoles,
  FarmDocument,
  FilteredDocument,
  FilterDocument,
  SubjectDocument
} from '../../../types';
import { TableOptionsHeaderCell } from './TableOptionsHeaderCell';
import { CompId, CompSchema } from '../../../types/schemas';
import styles from './CompFarmListTable.css';
import { SubjectIcon } from 'src/lib/components/SubjectIcon';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { STATUSES } from 'src/constants';
import { isLoading } from 'src/lib/utils/general';
import { useCompCompareOverlay } from 'src/lib/report-api/hooks/useCompCompareOverlay';
import { CompFields } from 'src/lib/report-api/types/CompFields';
import { COLS_BY_COMP_FIELD } from './columns';
import { SelectCell } from './SelectCell';
import { useCompFields } from 'src/lib/report-api/hooks/useCompFields';
import { documentPatch } from 'src/lib/report-api/actions';

type OnMouseEnter = (hovered: {
  compSchema: CompSchema;
  documentId: string | undefined;
}) => void;

interface Props {
  dataHcName: string;
  reportId: number;
  selectedCompsOnly?: boolean;
  compact?: boolean;
  onMouseEnter?: OnMouseEnter;
  onMouseLeave?: VoidFunction;
}

const headerCells = (
  columnIds: CompFields[],
  reportId: number
): ReactElement<TableHeaderCellProps> => {
  const dispatch = useDispatch();
  const {
    data: [filterDocument]
  } = useDocumentRole<FilterDocument>(reportId, DocumentRoles.Filter);

  return (
    <>
      {columnIds.map((columnId) => {
        const sort =
          filterDocument?.data.sort?.field === columnId
            ? filterDocument.data.sort.order
            : undefined;
        const nextSort = !sort ? 'ASC' : sort === 'ASC' ? 'DESC' : undefined;
        return COLS_BY_COMP_FIELD[columnId].header({
          onClick: () => {
            if (filterDocument?.documentId) {
              if (nextSort !== undefined) {
                dispatch(
                  documentPatch(reportId, filterDocument.documentId, [
                    {
                      op: 'add',
                      path: '/data/sort',
                      value: {
                        field: columnId,
                        order: nextSort
                      }
                    }
                  ])
                );
              } else {
                dispatch(
                  documentPatch(reportId, filterDocument.documentId, [
                    {
                      op: 'remove',
                      path: '/data/sort'
                    }
                  ])
                );
              }
            }
          },
          sort
        });
      })}
    </>
  );
};

const subjectCells = (
  reportId: number,
  columnIds: CompFields[]
): ReactElement<TableCellProps>[] => {
  const results: ReactElement<TableCellProps>[] = [
    <TableCell key="subject-icon-cell" width={50} sticky>
      <SubjectIcon dataHcName="subject-icon" />
    </TableCell>
  ];
  const {
    data: [subjectDocument],
    status: subjectDocStatus
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  if (subjectDocStatus === STATUSES.SUCCESS && subjectDocument) {
    columnIds.forEach((columnId) => {
      results.push(
        COLS_BY_COMP_FIELD[columnId].content({ schema: subjectDocument.data })
      );
    });
  }

  return results;
};

const cells = ({
  reportId,
  schema,
  columnIds
}: {
  reportId: number;
  schema: CompSchema;
  columnIds: CompFields[];
}): ReactElement<TableCellProps> => {
  return (
    <>
      {SelectCell({ reportId, compId: schema.compID })}
      {columnIds.map((columnId) => {
        return COLS_BY_COMP_FIELD[columnId].content({ schema });
      })}
    </>
  );
};

const row = ({
  compId,
  onMouseEnter,
  onMouseLeave,
  ...props
}: { compId: CompId; columnIds: CompFields[]; height: number } & Props) => {
  const { reportId, height } = props;
  const { compSchema, documentId } = useComp(reportId, compId);
  const { compCompareOpen } = useCompCompareOverlay();
  if (!compSchema) return null;
  return (
    <TableRow
      key={`comp-row-${compId}`}
      onClick={() => compCompareOpen({ compSchema, documentId })}
      onMouseEnter={() => {
        onMouseEnter?.({ compSchema, documentId });
      }}
      onMouseLeave={onMouseLeave}
      height={height}
    >
      {/* // Cells cannot be rendered as JSX so child type-guards work. */}
      {cells({ schema: compSchema, ...props })}
    </TableRow>
  );
};

const rows = ({
  compIds,
  ...props
}: {
  compIds: CompId[];
  columnIds: CompFields[];
  height: number;
} & Props): ReactElement<TableRowProps>[] => {
  const results: ReactElement<TableRowProps>[] = [];
  compIds.forEach((compId) => {
    const rowElm = row({ compId, ...props });
    if (rowElm) {
      results.push(rowElm);
    }
  });
  return results;
};

// Needs to be split into separate component to allow hooks to function properly
export const TableContent = (props: { compIds: CompId[] } & Props) => {
  const { order } = useCompFields();
  const columnIds = order.filter((columnId) => {
    return !!COLS_BY_COMP_FIELD[columnId];
  });

  const { compact, reportId } = props;
  const rowHeight = compact ? 37 : 60;
  return (
    <Table
      dataHcName="table-test"
      className={classNames(styles.CompFarmListTable, {
        [styles.compact || '']: compact
      })}
    >
      <TableHeader height={compact ? 50 : 56} sticky>
        {TableOptionsHeaderCell()}
        {headerCells(columnIds, reportId)}
      </TableHeader>
      <TableRow height={rowHeight} sticky>
        {subjectCells(reportId, columnIds)}
      </TableRow>
      {rows({ columnIds, height: rowHeight, ...props })}
    </Table>
  );
};

export const CompFarmListTable = ({
  dataHcName,
  reportId,
  selectedCompsOnly,
  ...props
}: Props) => {
  const {
    data: [filteredDocument],
    status: filteredStatus
  } = useDocumentRole<FilteredDocument>(reportId, DocumentRoles.Filtered);
  const { status: farmStatus } = useDocumentRole<FarmDocument>(
    reportId,
    DocumentRoles.Farm
  );
  const { status: compStatus, data: compDocuments } =
    useDocumentRole<CompDocument>(reportId, DocumentRoles.Comp);

  if (isLoading([filteredStatus, farmStatus, compStatus])) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const compIds: CompId[] = [];
  if (selectedCompsOnly) {
    compDocuments.forEach((compDocument) => {
      compIds.push(compDocument.data.compID);
    });
  } else {
    filteredDocument?.data.forEach((compId) => {
      compIds.push(compId);
    });
  }
  return (
    <TableContent
      {...props}
      dataHcName={dataHcName}
      key={`table-content-${compIds.length}`}
      reportId={reportId}
      compIds={compIds}
    />
  );
};
