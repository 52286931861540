import { connect } from 'react-redux';

import {
  ERROR_ACTION_CLOSE,
  ERROR_ACTION_RELOAD
} from 'legacy/appstore/constants';

import {
  getErrorModalIsOpen,
  getErrorModalMessage,
  getErrorModalMessageSecondary,
  getErrorModalHelpLink,
  getErrorModalHelpLinkLabel,
  getErrorModalActionType,
  getErrorModalActionLabel,
  getErrorModalIsDismissible
} from 'selectors/error';

import { errorModalHide } from 'actions/error';

import ErrorModal from 'legacy/components/ErrorModal';

const mapStateToProps = (state) => ({
  open: getErrorModalIsOpen(state),
  message: getErrorModalMessage(state),
  messageSecondary: getErrorModalMessageSecondary(state),
  helpLink: getErrorModalHelpLink(state),
  helpLinkLabel: getErrorModalHelpLinkLabel(state),
  actionType: getErrorModalActionType(state),
  actionLabel: getErrorModalActionLabel(state),
  isDismissible: getErrorModalIsDismissible(state)
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(errorModalHide());
  },
  onReload: () => {
    window.location.reload();
  },
  onErrorActionClick: (actionType) => {
    switch (actionType) {
      case ERROR_ACTION_CLOSE:
        return dispatch(errorModalHide());

      case ERROR_ACTION_RELOAD:
        return window.location.reload();

      default:
        return window.location.reload();
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
