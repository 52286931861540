import glossary from 'legacy/glossary';
import uniq from 'lodash/uniq';

import {
  CHANGE_GLOSSARY_SELECTED,
  SHOW_HINT_TEXT,
  SHOW_ONE_TIME_HINT_TEXT,
  CLEAR_HINT_TEXT
} from 'actions/support';

const INITIAL_STATE = {
  glossarySelected: [],
  glossary,
  hintTextKey: null,
  alreadyDisplayedHintTextKeys: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_GLOSSARY_SELECTED:
      return {
        ...state,
        glossarySelected: action.payload.items
      };

    case SHOW_HINT_TEXT:
      return {
        ...state,
        hintTextKey: action.payload.hintTextKey,
        alreadyDisplayedHintTextKeys: uniq([
          ...state.alreadyDisplayedHintTextKeys,
          action.payload.hintTextKey
        ])
      };

    case SHOW_ONE_TIME_HINT_TEXT:
      return {
        ...state,
        hintTextKey:
          state.alreadyDisplayedHintTextKeys.indexOf(
            action.payload.hintTextKey
          ) === -1
            ? action.payload.hintTextKey
            : null,
        alreadyDisplayedHintTextKeys: uniq([
          ...state.alreadyDisplayedHintTextKeys,
          action.payload.hintTextKey
        ])
      };

    case CLEAR_HINT_TEXT:
      return {
        ...state,
        hintTextKey: null
      };

    default:
      return state;
  }
};
