import { Report } from '../types';

export const REPORT_REQUIRED = 'report-api/REPORT_REQUIRED';
export const REPORT_FETCH = 'report-api/REPORT_FETCH';
export const REPORT_FETCH_SUCCESS = 'report-api/REPORT_FETCH_SUCCESS';
export const REPORT_FETCH_ERROR = 'report-api/REPORT_FETCH_ERROR';

export interface ReportRequiredAction {
  type: typeof REPORT_REQUIRED;
  payload: {
    reportId: number;
  };
}
export const reportRequired = (reportId: number) => {
  return {
    type: REPORT_REQUIRED,
    payload: { reportId }
  };
};

export interface ReportFetchAction {
  type: typeof REPORT_FETCH;
  payload: {
    reportId: number;
  };
}
export const reportFetch = (reportId: number): ReportFetchAction => {
  return {
    type: REPORT_FETCH,
    payload: { reportId }
  };
};

export interface ReportFetchSuccessAction {
  type: typeof REPORT_FETCH_SUCCESS;
  payload: {
    report: Report;
  };
}
export const reportFetchSuccess = (
  report: Report
): ReportFetchSuccessAction => {
  return {
    type: REPORT_FETCH_SUCCESS,
    payload: { report }
  };
};

export interface ReportFetchErrorAction {
  type: typeof REPORT_FETCH_ERROR;
  payload: {
    reportId: number;
    message: string;
  };
}
export const reportFetchError = (
  reportId: number,
  message: string
): ReportFetchErrorAction => {
  return {
    type: REPORT_FETCH_ERROR,
    payload: { reportId, message }
  };
};

export type ReportActions =
  | ReportRequiredAction
  | ReportFetchAction
  | ReportFetchSuccessAction
  | ReportFetchErrorAction;
