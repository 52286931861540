import { call, all, put, select } from 'redux-saga/effects';

import HC_CONSTANTS from 'HC_CONSTANTS';

import apiUtil from 'legacy/utils/api';
import { watchEvery } from 'legacy/utils/saga';

import { PUT_ACCOUNT_DETAILS_ON_STATE } from 'actions/account-details';
import {
  ORGANIZATION_USAGE_FETCH_REQUEST,
  organizationUsageSuccess,
  organizationUsageAccessSuccess,
  organizationUsageFailure
} from 'actions/organization-usage';
import { subscriptionDetailsSuccess } from 'actions/subscription';

import { getIsLoggedIn } from 'selectors/auth';
import { getIsUserInSubscriptionMode } from 'selectors/organization-usage';

export function* checkAccess() {
  try {
    const params = { componentName: HC_CONSTANTS.APPLICATION_NAME };
    const access = yield call(
      apiUtil.GET,
      `${HC_CONSTANTS.CHECK_ACCESS_URL}`,
      params
    );
    yield put(
      organizationUsageAccessSuccess({
        access: access.access,
        trialEnded: access.accessDeniedReason === 'TrialEnded',
        selfService: access.selfService
      })
    );
  } catch (e) {
    yield put(organizationUsageFailure(e.messageRaw));
  }
}

export function* downloadOrganizationUsage() {
  try {
    const params = { applicationName: HC_CONSTANTS.APPLICATION_NAME };
    const orgUsageData = yield call(
      apiUtil.GET,
      `${HC_CONSTANTS.USAGE_URL}/my-usage`,
      params
    );
    const orgUsage = orgUsageData[0];
    yield put(organizationUsageSuccess(orgUsage));
    // If user is in subscription mode fetch subscription details
    const isUserInSubscriptionMode = yield select(getIsUserInSubscriptionMode);
    if (isUserInSubscriptionMode) {
      const subscriptionDetails = yield call(fetchSubscriptionDetails);
      yield put(subscriptionDetailsSuccess(subscriptionDetails));
    }
  } catch (e) {
    yield put(organizationUsageFailure(e.messageRaw));
  }
}

export function* fetchSubscriptionDetails() {
  return yield call(
    apiUtil.GET,
    `${HC_CONSTANTS.SUBSCRIPTION_URL}/component/Value Report`
  );
}

export function* fetchOrganizationUsage() {
  const isLoggedIn = yield select(getIsLoggedIn);
  if (!isLoggedIn) return false;
  yield all([call(downloadOrganizationUsage), call(checkAccess)]);
}

export function registerOrganizationUsageSaga() {
  watchEvery({
    [ORGANIZATION_USAGE_FETCH_REQUEST]: fetchOrganizationUsage,
    [PUT_ACCOUNT_DETAILS_ON_STATE]: fetchOrganizationUsage
  });
}
