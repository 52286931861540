import { createSelector } from 'reselect';

export const getMLSListingsState = (state) => state.mlsListings;

export const getMLSProperties = createSelector(
  getMLSListingsState,
  (listingsState) => listingsState.properties
);

export const getMLSListingsStatus = createSelector(
  getMLSListingsState,
  (listingsState) => listingsState.status
);

export const getMLSListingIdsWithinViewport = createSelector(
  getMLSListingsState,
  (listingsState) => listingsState.inListViewport
);

export const getMLSPropertyList = createSelector(
  getMLSProperties,
  (properties) => {
    let propertyList = [];
    for (let addressId in properties) {
      propertyList.push(properties[addressId]);
    }
    return propertyList;
  }
);

export const getMLSPropertiesIds = createSelector(
  getMLSPropertyList,
  (properties) => {
    return properties.map((property) => property.addressId);
  }
);

export const getMLSListingById = (addressId) =>
  createSelector(getMLSProperties, (properties) => properties[addressId] || {});

export const getMLSHoveredPropertyId = createSelector(
  getMLSListingsState,
  (listingsState) => listingsState.hoveredPropertyId
);

export const getMLSListingsShowOnMap = createSelector(
  getMLSListingsState,
  (listingsState) => listingsState.showOnMap
);
