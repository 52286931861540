export const sharedReducerHOR = (reducer, actionMapping) => {
  const transformedMapping = (() => {
    const mapping = {};
    for (let type in actionMapping) {
      if (typeof actionMapping[type] === 'string') {
        mapping[actionMapping[type]] = type;
      } else {
        for (let i in actionMapping[type]) {
          mapping[actionMapping[type][i]] = type;
        }
      }
    }
    return mapping;
  })();

  return (state, action) => {
    return reducer(state, {
      ...action,
      sharedReducerOriginalAction: action.type,
      type: transformedMapping[action.type] || action.type
    });
  };
};
