import React from 'react';
import { TableCell } from 'src/lib/components/Table';
import { CompId } from 'src/lib/report-api/types/schemas';
import { useComp } from '../../../hooks';
import { CompSelectButton } from '../../CompSelectButton';
import styles from './SelectCell.css';

interface Props {
  reportId: number;
  compId: CompId;
  dataHcName?: string;
}
// This component cannot be used as a JSX Element inside TableRow
// Example Usage:
// <Table>
//   <TableRow>
//     {SelectCell({ reportId, hcAddressId })}
//   </TableRow>
// </Table>
export const SelectCell = ({ reportId, compId, dataHcName }: Props) => {
  const { documentId } = useComp(reportId, compId);
  return (
    <TableCell width={50} align="center" sticky>
      <CompSelectButton
        reportId={reportId}
        documentId={documentId}
        compId={compId}
        dataHcName={`${dataHcName}-select-button`}
        className={styles.CompSelectButton}
      />
    </TableCell>
  );
};
