import get from 'lodash/get';

import {
  AVM_KEY_HC,
  AVM_KEY_USER,
  AVM_KEY_COMPS,
  AVM_KEY_COMPS_AVG,
  AVM_KEY_RENTAL_HC,
  AVM_KEY_RENTAL_COMPS,
  AVM_KEY_RENTAL_COMPS_AVG,
  AVM_KEY_USER_ENTERED,
  AVM_KEY_RENTAL_USER_ENTERED
} from 'legacy/appstore/constants';

import { rentalAvmToAvm } from 'legacy/utils/avms';
import { snakeCaseToCamelCase } from 'legacy/utils/transform';

import {
  GET_REPORT,
  GET_REPORT_USER_VERSION,
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_HC_VERSION_SUCCESS,
  GET_REPORT_SUBJECT_DETAILS_SUCCESS
} from 'actions/get-report';
import { VR_RECOMPUTE_SUCCESS } from 'actions/edit-report';
import {
  AVMS_CHANGE_SELECTED_SUCCESS,
  AVMS_COMP_VALUE_RECOMPUTE_SUCCESS,
  AVMS_DETAILS_TOGGLE,
  AVMS_DETAILS_TOGGLE_RENTAL,
  AVMS_RENTAL_COMP_VALUE_RECOMPUTE_SUCCESS,
  AVMS_UPDATE_USER_ENTERED_VALUE_SUCCESS,
  AVMS_UPDATE_USER_ENTERED_RENTAL_VALUE_SUCCESS,
  AVMS_RENTAL_CHANGE_SELECTED_SUCCESS
} from 'actions/avms';
import { RECENT_REPORTS_FETCH_SUCCESS } from 'actions/recent-reports';
import { ARCHIVED_REPORTS_FETCH_SUCCESS } from 'actions/archived-reports';
import { LOAD_FULL_REPORT } from 'actions/full-report';
import { FEATURE_FLAG_DEFAULT_VALUE } from 'actions/feature-flag';
import {
  COMPS_FARM_UPDATE_SUCCESS,
  COMPS_REFRESH_READY_FOR_RECOMPUTE,
  COMPS_REFRESH_CLEAR_VALUE_CHANGE_WARNING,
  COMPS_SELECT_TOGGLE,
  COMPS_ADJUST_SAVE,
  COMPS_REFRESH
} from 'actions/comps';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';
import {
  RENTAL_COMPS_FARM_UPDATE_SUCCESS,
  RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE,
  RENTAL_COMPS_SELECT_TOGGLE,
  RENTAL_COMPS_ADJUST_SAVE,
  RENTAL_COMPS_REFRESH,
  RENTAL_COMPS_REFRESH_CLEAR_VALUE_CHANGE_WARNING
} from 'actions/rental-comps';

const INITIAL_STATE = {
  isPreloadedForNewReport: false,
  selected: AVM_KEY_HC,
  selectedRental: AVM_KEY_RENTAL_HC,
  lastAvmRunTime: null,
  avmDetailsExpanded: false,
  avmDetailsRentalExpanded: false,
  // Flag used to determine when the selectedRental is from the user report
  useRentalAvmFromUserReport: false,
  compAvgPriorToRefresh: null,
  compValuePriorToRefresh: null,
  rentalCompAvgPriorToRefresh: null,
  rentalCompValuePriorToRefresh: null,
  [AVM_KEY_HC]: {},
  [AVM_KEY_USER]: {},
  [AVM_KEY_COMPS]: {},
  [AVM_KEY_COMPS_AVG]: {},
  [AVM_KEY_USER_ENTERED]: {},
  [AVM_KEY_RENTAL_HC]: {},
  [AVM_KEY_RENTAL_COMPS]: {},
  [AVM_KEY_RENTAL_COMPS_AVG]: {},
  [AVM_KEY_RENTAL_USER_ENTERED]: {}
};

const _mappedRentalAvm = (action) => {
  return rentalAvmToAvm(
    get(action, ['payload', 'report', 'subject', 'rentalAvm'], {})
  );
};

const _avmFromSearchResult = (property) => {
  return {
    value: property.value,
    maxVal: property.valueHigh,
    maxValPerSqft: property.valueHigh / property.grossLivingAreaSqft,
    minVal: property.valueLow,
    minValPerSqft: property.valueLow / property.grossLivingAreaSqft,
    valuationSuitabilityScore: property.valuationSuitabilityScore,
    valuationSuitabilityScoreDesc: property.valuationSuitabilityScoreDesc
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EFFECTIVE_DATE_GET_REPORT_SUCCESS: {
      const report = action.payload.report;
      return {
        ...state,
        selected: action.payload.report.metadata.selectedAvm,
        [AVM_KEY_HC]: report[AVM_KEY_HC],
        [AVM_KEY_COMPS]: report[AVM_KEY_COMPS],
        [AVM_KEY_COMPS_AVG]: report[AVM_KEY_COMPS_AVG],
        [AVM_KEY_USER_ENTERED]: report[AVM_KEY_USER_ENTERED]
      };
    }
    // TODO: Remove Feature Flag
    case FEATURE_FLAG_DEFAULT_VALUE:
      return {
        ...state,
        ...action.payload,
        useRentalAvmFromUserReport: action.payload.selectedRental
          ? true
          : state.useRentalAvmFromUserReport
      };

    case GET_REPORT:
      return {
        ...INITIAL_STATE,
        [AVM_KEY_HC]: state.isPreloadedForNewReport
          ? state[AVM_KEY_HC]
          : INITIAL_STATE[AVM_KEY_HC]
      };

    case GET_REPORT_USER_VERSION:
      return {
        ...state,
        [AVM_KEY_USER]: INITIAL_STATE[AVM_KEY_USER],
        [AVM_KEY_COMPS]: INITIAL_STATE[AVM_KEY_COMPS],
        [AVM_KEY_COMPS_AVG]: INITIAL_STATE[AVM_KEY_COMPS_AVG],
        [AVM_KEY_RENTAL_COMPS]: INITIAL_STATE[AVM_KEY_RENTAL_COMPS],
        [AVM_KEY_RENTAL_COMPS_AVG]: INITIAL_STATE[AVM_KEY_RENTAL_COMPS_AVG],
        [AVM_KEY_USER_ENTERED]: INITIAL_STATE[AVM_KEY_USER_ENTERED],
        [AVM_KEY_RENTAL_USER_ENTERED]:
          INITIAL_STATE[AVM_KEY_RENTAL_USER_ENTERED]
      };

    case GET_REPORT_SUBJECT_DETAILS_SUCCESS:
      return {
        ...state,
        [AVM_KEY_HC]: {
          ...state[AVM_KEY_HC],
          ...action.payload.avm
        }
      };

    case GET_REPORT_HC_VERSION_SUCCESS:
      const rentalAvmFromHcReport = _mappedRentalAvm(action);
      let selectedRental = state.selectedRental;
      // Use rental avm is no hc avm exists and the user hasn't selected a rental avm
      if (!state.useRentalAvmFromUserReport) {
        selectedRental =
          rentalAvmFromHcReport && rentalAvmFromHcReport.value
            ? AVM_KEY_RENTAL_HC
            : AVM_KEY_RENTAL_COMPS;
      }

      return {
        ...state,
        isPreloadedForNewReport: false,
        [AVM_KEY_HC]: {
          ...get(action, ['payload', 'report', AVM_KEY_HC], {})
        },
        [AVM_KEY_RENTAL_HC]: rentalAvmFromHcReport,
        selectedRental
      };

    case LOAD_FULL_REPORT:
      return {
        ...INITIAL_STATE,
        isPreloadedForNewReport: true,
        [AVM_KEY_HC]: _avmFromSearchResult(action.payload.property)
      };

    case VR_RECOMPUTE_SUCCESS:
      return {
        ...state,
        [AVM_KEY_USER]: {
          ...state[AVM_KEY_USER],
          ...get(action, ['payload', 'report', AVM_KEY_USER], {})
        },
        [AVM_KEY_COMPS]: {
          ...state[AVM_KEY_COMPS],
          ...get(action, ['payload', 'report', AVM_KEY_COMPS], {})
        },
        [AVM_KEY_COMPS_AVG]: {
          ...state[AVM_KEY_COMPS_AVG],
          ...get(action, ['payload', 'report', AVM_KEY_COMPS_AVG], {})
        },
        [AVM_KEY_RENTAL_COMPS]: {
          ...state[AVM_KEY_RENTAL_COMPS],
          ...get(action, ['payload', 'report', AVM_KEY_RENTAL_COMPS], {})
        },
        [AVM_KEY_RENTAL_COMPS_AVG]: {
          ...state[AVM_KEY_RENTAL_COMPS_AVG],
          ...get(action, ['payload', 'report', AVM_KEY_RENTAL_COMPS_AVG], {})
        }
      };

    case GET_REPORT_USER_VERSION_SUCCESS:
      let selectedRentalUserReport = snakeCaseToCamelCase(
        get(action, ['payload', 'report', 'metadata', 'selectedRentalAvm'])
      );
      return {
        ...state,
        [AVM_KEY_USER]: {
          ...state[AVM_KEY_USER],
          ...get(action, ['payload', 'report', AVM_KEY_USER], {})
        },
        [AVM_KEY_COMPS]: {
          ...state[AVM_KEY_COMPS],
          ...get(action, ['payload', 'report', AVM_KEY_COMPS], {})
        },
        [AVM_KEY_COMPS_AVG]: {
          ...state[AVM_KEY_COMPS_AVG],
          ...get(action, ['payload', 'report', AVM_KEY_COMPS_AVG], {})
        },
        [AVM_KEY_RENTAL_COMPS]: {
          ...state[AVM_KEY_RENTAL_COMPS],
          ...get(action, ['payload', 'report', AVM_KEY_RENTAL_COMPS], {})
        },
        [AVM_KEY_RENTAL_COMPS_AVG]: {
          ...state[AVM_KEY_RENTAL_COMPS_AVG],
          ...get(action, ['payload', 'report', AVM_KEY_RENTAL_COMPS_AVG], {})
        },
        [AVM_KEY_USER_ENTERED]: {
          ...state[AVM_KEY_USER_ENTERED],
          ...get(action, ['payload', 'report', AVM_KEY_USER_ENTERED], {})
        },
        [AVM_KEY_RENTAL_USER_ENTERED]: {
          ...state[AVM_KEY_RENTAL_USER_ENTERED],
          ...get(action, ['payload', 'report', AVM_KEY_RENTAL_USER_ENTERED], {})
        },
        selected: snakeCaseToCamelCase(
          get(
            action,
            ['payload', 'report', 'metadata', 'selectedAvm'],
            AVM_KEY_HC
          )
        ),
        selectedRental: selectedRentalUserReport || state.selectedRental,
        useRentalAvmFromUserReport: !!selectedRentalUserReport
      };

    case AVMS_CHANGE_SELECTED_SUCCESS:
      return {
        ...state,
        selected: action.payload.selectedAvm
      };

    case AVMS_RENTAL_CHANGE_SELECTED_SUCCESS:
      return {
        ...state,
        selectedRental: action.payload.selectedRentalAvm
      };

    case AVMS_COMP_VALUE_RECOMPUTE_SUCCESS:
      return {
        ...state,
        [AVM_KEY_COMPS]: {
          ...state[AVM_KEY_COMPS],
          ...(action.payload[AVM_KEY_COMPS] || state[AVM_KEY_COMPS])
        },
        [AVM_KEY_COMPS_AVG]: {
          ...state[AVM_KEY_COMPS_AVG],
          ...(action.payload[AVM_KEY_COMPS_AVG] || state[AVM_KEY_COMPS_AVG])
        }
      };

    case AVMS_RENTAL_COMP_VALUE_RECOMPUTE_SUCCESS:
      return {
        ...state,
        [AVM_KEY_RENTAL_COMPS]: {
          ...state[AVM_KEY_RENTAL_COMPS],
          ...action.payload[AVM_KEY_RENTAL_COMPS]
        },
        [AVM_KEY_RENTAL_COMPS_AVG]: {
          ...state[AVM_KEY_RENTAL_COMPS_AVG],
          ...action.payload[AVM_KEY_RENTAL_COMPS_AVG]
        }
      };

    case ARCHIVED_REPORTS_FETCH_SUCCESS:
    case RECENT_REPORTS_FETCH_SUCCESS:
      return {
        ...state,
        lastAvmRunTime: action.payload.lastAvmRunAt
      };

    case AVMS_DETAILS_TOGGLE:
      return {
        ...state,
        avmDetailsExpanded: !state.avmDetailsExpanded
      };

    case AVMS_DETAILS_TOGGLE_RENTAL:
      return {
        ...state,
        avmDetailsRentalExpanded: !state.avmDetailsRentalExpanded
      };

    case AVMS_UPDATE_USER_ENTERED_VALUE_SUCCESS:
      return {
        ...state,
        selected: AVM_KEY_USER_ENTERED,
        [AVM_KEY_USER_ENTERED]: {
          ...action.payload.values
        }
      };

    case AVMS_UPDATE_USER_ENTERED_RENTAL_VALUE_SUCCESS:
      return {
        ...state,
        selectedRental: AVM_KEY_RENTAL_USER_ENTERED,
        [AVM_KEY_RENTAL_USER_ENTERED]: {
          ...action.payload.values
        }
      };

    case COMPS_FARM_UPDATE_SUCCESS:
      return {
        ...state,
        [AVM_KEY_COMPS]: {
          ...state[AVM_KEY_COMPS],
          ...action.payload.confidence
        }
      };

    case RENTAL_COMPS_FARM_UPDATE_SUCCESS:
      return {
        ...state,
        [AVM_KEY_RENTAL_COMPS]: {
          ...state[AVM_KEY_RENTAL_COMPS],
          ...action.payload.confidence
        }
      };

    case COMPS_REFRESH_READY_FOR_RECOMPUTE: {
      return {
        ...state,
        compAvgPriorToRefresh: { ...state[AVM_KEY_COMPS_AVG] },
        compValuePriorToRefresh: { ...state[AVM_KEY_COMPS] }
      };
    }

    case COMPS_SELECT_TOGGLE:
    case COMPS_ADJUST_SAVE:
    case COMPS_REFRESH:
    case COMPS_REFRESH_CLEAR_VALUE_CHANGE_WARNING: {
      return {
        ...state,
        compAvgPriorToRefresh: null,
        compValuePriorToRefresh: null
      };
    }

    case RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE: {
      return {
        ...state,
        rentalCompAvgPriorToRefresh: { ...state[AVM_KEY_RENTAL_COMPS_AVG] },
        rentalCompValuePriorToRefresh: { ...state[AVM_KEY_RENTAL_COMPS] }
      };
    }

    case RENTAL_COMPS_SELECT_TOGGLE:
    case RENTAL_COMPS_ADJUST_SAVE:
    case RENTAL_COMPS_REFRESH:
    case RENTAL_COMPS_REFRESH_CLEAR_VALUE_CHANGE_WARNING: {
      return {
        ...state,
        rentalCompAvgPriorToRefresh: null,
        rentalCompValuePriorToRefresh: null
      };
    }

    default:
      return state;
  }
};
