import { Dropdown as DropdownCL } from '@hc/component-lib/hclib/components/atoms/dropdown';
import classNames from 'classnames';
import dropdownTheme from './Dropdown.css-module.css';
import { combineThemes } from 'legacy/utils/theme.utils.js';

export const Dropdown = (props) => (
  <DropdownCL
    {...props}
    className={classNames(props.className, dropdownTheme.HcsDropdown)}
    theme={combineThemes(dropdownTheme, props.theme)}
  />
);
