import React from 'react';
import classNames from 'classnames';

import styles from './ThumbnailImage.css';

// interface Theme {
//   ThumbnailImage: string;
// }
interface Props {
  /** The url of the image to display */
  url: string;
  /** Classname to apply to the root element */
  className?: string;
  /** String passed for QA automation testing */
  dataHcName?: string;
}

export const ThumbnailImage = ({ url, className, dataHcName }: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ThumbnailImage, className)}
      style={{ backgroundImage: `url('${url}')` }}
    />
  );
};
