// NOTE: This file is necessary to prevent circular dependencies. I'd like to move this to the proeprty
import { createSelector } from 'reselect';

import { getCompsFarm } from 'selectors/comps';
import { getMLSProperties } from 'selectors/mls-listings';
import { getPropertyResult } from 'selectors/property-search';
import { getRentalCompsFarm } from 'selectors/rental-comps';
import { getSubjectAdjusted } from 'selectors/subject';

export const getGeoLocationMappings = (addressIds) =>
  createSelector(
    getCompsFarm,
    getRentalCompsFarm,
    getSubjectAdjusted,
    getMLSProperties,
    getPropertyResult,
    (compsFarm, rentalCompsFarm, subject, mlsProperties, searchResult = {}) => {
      const geoLocationMapping = {};
      addressIds.forEach((addressId) => {
        if (compsFarm[addressId]) {
          geoLocationMapping[addressId] = {
            ...compsFarm[addressId].geoLocation,
            geoPrecision: compsFarm[addressId].geoPrecision
          };
        } else if (rentalCompsFarm[addressId]) {
          geoLocationMapping[addressId] = {
            ...rentalCompsFarm[addressId].geoLocation,
            geoPrecision: rentalCompsFarm[addressId].geoPrecision
          };
        } else if (mlsProperties[addressId]) {
          geoLocationMapping[addressId] = {
            latitude: mlsProperties[addressId].mlsLat,
            longitude: mlsProperties[addressId].mlsLon,
            geoPrecision: mlsProperties[addressId].geoPrecision
          };
        } else if (addressId === subject.addressId) {
          geoLocationMapping[addressId] = {
            ...subject.geoLocation,
            geoPrecision: subject.geoPrecision
          };
        } else if (addressId === searchResult.addressId) {
          geoLocationMapping[addressId] = {
            latitude: searchResult.lat,
            longitude: searchResult.lon,
            geoPrecision: searchResult.geoPrecision
          };
        }
      });
      return geoLocationMapping;
    }
  );
