export const REPORT_API_REDUCER_KEY = 'reportApi';

export enum AvmType {
  HouseCanary = 'avm',
  // Adjusted = 'adjustedAvm',
  CompAvm = 'compBasedAvm',
  CompMarket = 'adjustedCompBasedAvg',
  UserEntered = 'userValue'
}

export enum AvmTypeLabel {
  avm = 'HouseCanary Value',
  // adjustedAvm = 'Adjusted Value',
  compBasedAvm = 'Comparable Value',
  adjustedCompBasedAvg = 'Comparable Average',
  userValue = 'User Opinion of Price'
}
