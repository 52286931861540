import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { STATUSES } from 'legacy/appstore/constants';

import { isLoadedInitial } from 'legacy/utils/status';

import {
  COMPS_MAP_DRAW_SUBMIT,
  COMPS_FARM_UPDATE_SUCCESS,
  COMPS_FARM_UPDATE_FAILURE,
  COMPS_FILTER_DISTANCE_CHANGE,
  COMPS_REFRESH,
  COMPS_FITERS_CHANGE_COMPLETE,
  COMPS_SELECT_TOGGLE,
  COMPS_CLEAR_FILTER,
  COMPS_CLEAR_ALL_FILTERS
} from 'actions/comps';
import {
  VR_RECOMPUTE,
  VR_RECOMPUTE_SUCCESS,
  SAVE_REPORT_QUEUE,
  SAVE_REPORT_EXECUTE,
  SAVE_REPORT_SUCCESS
} from 'actions/edit-report';
import {
  GET_REPORT,
  GET_REPORT_SUBJECT_DETAILS_SUCCESS,
  GET_REPORT_SUBJECT_DETAILS_FAILURE,
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_USER_VERSION_NOT_FOUND,
  GET_REPORT_HC_VERSION_SUCCESS,
  GET_REPORT_HC_VERSION_FAILURE,
  GET_REPORT_COMPS,
  GET_REPORT_FORECAST,
  GET_REPORT_FORECAST_SUCCESS,
  GET_REPORT_FORECAST_FAILURE,
  GET_REPORT_RENTAL,
  GET_REPORT_RENTAL_SUCCESS,
  GET_REPORT_RENTAL_FAILURE,
  GET_REPORT_NEARBY_PROPERTIES,
  GET_REPORT_NEARBY_PROPERTIES_SUCCESS,
  GET_REPORT_NEARBY_PROPERTIES_FAILURE
} from 'actions/get-report';
import {
  RENTAL_COMPS_MAP_DRAW_SUBMIT,
  RENTAL_COMPS_FILTER_DISTANCE_CHANGE,
  RENTAL_COMPS_FARM_UPDATE_SUCCESS,
  RENTAL_COMPS_REFRESH
} from 'actions/rental-comps';
import {
  UNIQUE_LINK_FETCH,
  UNIQUE_LINK_FETCH_SUCCESS,
  UNIQUE_LINK_FETCH_DOES_NOT_EXIST,
  UNIQUE_LINK_CONFIGURE,
  UNIQUE_LINK_CONFIGURE_SUCCESS,
  UNIQUE_LINK_CONFIGURE_FAILURE
} from 'actions/share-report';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';
import { REPORT_UPDATE_COMMENT } from 'actions/property-explorer';
import {
  AVMS_CHANGE_SELECTED_SUCCESS,
  AVMS_UPDATE_USER_ENTERED_VALUE_SUCCESS
} from 'actions/avms';

const INITIAL_STATE = {
  saveInProgress: false,
  saveIsQueued: false,
  errorMessage: null,
  subjectDetails: STATUSES.INIT,
  hcVersion: STATUSES.INIT,
  userVersion: STATUSES.INIT,
  comps: STATUSES.INIT,
  rentalComps: STATUSES.INIT,
  rentalReport: STATUSES.INIT,
  forecast: STATUSES.INIT,
  sharedReportConfig: STATUSES.INIT,
  nearbyProperties: STATUSES.INIT
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REPORT_UPDATE_COMMENT:
    case AVMS_CHANGE_SELECTED_SUCCESS:
    case AVMS_UPDATE_USER_ENTERED_VALUE_SUCCESS:
    case COMPS_FITERS_CHANGE_COMPLETE:
    case COMPS_SELECT_TOGGLE:
    case COMPS_CLEAR_FILTER:
    case COMPS_CLEAR_ALL_FILTERS: {
      return {
        ...state,
        effectiveDate: STATUSES.LOADING
      };
    }

    case EFFECTIVE_DATE_GET_REPORT_SUCCESS: {
      return {
        ...state,
        effectiveDate: STATUSES.SUCCESS,
        subjectDetails: STATUSES.SUCCESS,
        hcVersion: STATUSES.SUCCESS,
        userVersion: STATUSES.SUCCESS,
        comps: STATUSES.SUCCESS,
        nearbyProperties: STATUSES.SUCCESS
      };
    }

    case SAVE_REPORT_QUEUE:
      return {
        ...state,
        saveIsQueued: true
      };

    case SAVE_REPORT_EXECUTE:
      return {
        ...state,
        saveIsQueued: false,
        saveInProgress: true
      };

    case GET_REPORT:
      return {
        ...state,
        subjectDetails: STATUSES.LOADING,
        hcVersion: STATUSES.LOADING,
        userVersion: STATUSES.LOADING
      };

    case GET_REPORT_HC_VERSION_SUCCESS:
      return {
        ...state,
        hcVersion: STATUSES.SUCCESS
      };

    case GET_REPORT_HC_VERSION_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        hcVersion: STATUSES.ERROR
      };

    case GET_REPORT_SUBJECT_DETAILS_SUCCESS:
      return {
        ...state,
        subjectDetails: STATUSES.SUCCESS
      };

    case GET_REPORT_SUBJECT_DETAILS_FAILURE:
      return {
        ...state,
        subjectDetails: STATUSES.ERROR
      };

    // handle when user version does not exist
    // then user selects comps which triggers /user_version to be saved
    // this catches the success and updates the status
    case SAVE_REPORT_SUCCESS:
      return {
        ...state,
        saveInProgress: false,
        // Comps status is success because the farm list is saved on the user report
        comps:
          isLoadedInitial(state.userVersion) && isLoadedInitial(state.comps)
            ? STATUSES.SUCCESS
            : state.comps,
        // Nearby properties may be saved on user report
        nearbyProperties: get(
          action,
          ['payload', 'report', 'nearbyProperties'],
          []
        ).length
          ? STATUSES.SUCCESS
          : state.nearbyProperties,
        // Rental comps may be saved on user report
        rentalComps: !isEmpty(
          get(action, ['payload', 'report', 'metadata', 'rentalCompFarm'], {})
        )
          ? STATUSES.SUCCESS
          : state.rentalComps
      };

    case GET_REPORT_USER_VERSION_SUCCESS:
      return {
        ...state,
        saveInProgress: false,
        userVersion: STATUSES.SUCCESS,
        // Comps status is success because the farm list is saved on the user report
        comps: STATUSES.SUCCESS,
        // Nearby properties may be saved on user report
        nearbyProperties: get(
          action,
          ['payload', 'report', 'nearbyProperties'],
          []
        ).length
          ? STATUSES.SUCCESS
          : state.nearbyProperties,
        // Rental comps may be saved on user report
        rentalComps: !isEmpty(
          get(action, ['payload', 'report', 'metadata', 'rentalCompFarm'], {})
        )
          ? STATUSES.SUCCESS
          : state.rentalComps
      };

    case GET_REPORT_USER_VERSION_NOT_FOUND:
      return {
        ...state,
        comps: action.payload.fromShared ? STATUSES.SUCCESS : state.comps,
        userVersion: STATUSES.DOES_NOT_EXIST
      };

    case GET_REPORT_COMPS:
      return {
        ...state,
        comps: STATUSES.LOADING
      };

    case COMPS_FILTER_DISTANCE_CHANGE:
    case COMPS_MAP_DRAW_SUBMIT:
      return {
        ...state,
        effectiveDate: STATUSES.LOADING,
        comps: STATUSES.UPDATING
      };

    case COMPS_FARM_UPDATE_SUCCESS:
      return {
        ...state,
        comps: STATUSES.SUCCESS
      };

    case COMPS_FARM_UPDATE_FAILURE:
      return {
        ...state,
        comps: STATUSES.ERROR
      };

    case GET_REPORT_RENTAL:
      return {
        ...state,
        rentalReport: STATUSES.LOADING,
        rentalComps: state.userVersion !== STATUSES.LOADING
      };

    case GET_REPORT_RENTAL_SUCCESS:
      return {
        ...state,
        rentalReport: STATUSES.SUCCESS,
        rentalComps: STATUSES.SUCCESS
      };

    case GET_REPORT_RENTAL_FAILURE:
      return {
        ...state,
        rentalReport: STATUSES.ERROR,
        rentalComps: STATUSES.ERROR
      };

    case RENTAL_COMPS_MAP_DRAW_SUBMIT:
    case RENTAL_COMPS_FILTER_DISTANCE_CHANGE:
      return {
        ...state,
        rentalComps: STATUSES.UPDATING
      };

    case RENTAL_COMPS_FARM_UPDATE_SUCCESS:
      return {
        ...state,
        rentalComps: STATUSES.SUCCESS
      };

    case GET_REPORT_FORECAST:
      return {
        ...state,
        forecast: STATUSES.LOADING
      };

    case GET_REPORT_FORECAST_SUCCESS:
      return {
        ...state,
        forecast: STATUSES.SUCCESS
      };

    case GET_REPORT_FORECAST_FAILURE:
      return {
        ...state,
        forecast: STATUSES.ERROR
      };

    case GET_REPORT_NEARBY_PROPERTIES:
      return {
        ...state,
        nearbyProperties: STATUSES.LOADING
      };

    case GET_REPORT_NEARBY_PROPERTIES_SUCCESS:
      return {
        ...state,
        nearbyProperties: STATUSES.SUCCESS
      };

    case GET_REPORT_NEARBY_PROPERTIES_FAILURE:
      return {
        ...state,
        nearbyProperties: STATUSES.ERROR
      };

    case UNIQUE_LINK_FETCH:
      return {
        ...state,
        sharedReportConfig: STATUSES.LOADING
      };

    case UNIQUE_LINK_FETCH_SUCCESS:
      return {
        ...state,
        sharedReportConfig: STATUSES.SUCCESS
      };

    case UNIQUE_LINK_FETCH_DOES_NOT_EXIST:
      return {
        ...state,
        sharedReportConfig: STATUSES.DOES_NOT_EXIST
      };

    case UNIQUE_LINK_CONFIGURE:
      return {
        ...state,
        sharedReportConfig: STATUSES.UPDATING
      };

    case UNIQUE_LINK_CONFIGURE_SUCCESS:
      return {
        ...state,
        sharedReportConfig: STATUSES.SUCCESS
      };

    case UNIQUE_LINK_CONFIGURE_FAILURE:
      return {
        ...state,
        sharedReportConfig: STATUSES.ERROR
      };

    case VR_RECOMPUTE:
      return {
        ...state,
        userVersion: STATUSES.UPDATING
      };

    case VR_RECOMPUTE_SUCCESS:
      return {
        ...state,
        userVersion: STATUSES.SUCCESS,
        comps: STATUSES.SUCCESS,
        rentalComps: STATUSES.SUCCESS
      };

    case COMPS_REFRESH:
      return {
        ...state,
        comps: STATUSES.UPDATING
      };

    case RENTAL_COMPS_REFRESH:
      return {
        ...state,
        rentalComps: STATUSES.UPDATING
      };

    default:
      return state;
  }
};
