import SaveFiltersForm from 'legacy/containers/save-filters-form';

import { Dialog } from 'legacy/components/Dialog';

import { SaveFiltersModal as theme } from 'legacy/css-modules';

const SaveFiltersModal = ({ active, onClose }) => {
  return (
    <Dialog
      dataHcName="save-filter-modal"
      title="Save Filters"
      active={active}
      className={theme.SaveFiltersModal}
      onClose={onClose}
      closeButton={false}
    >
      <SaveFiltersForm />
    </Dialog>
  );
};

export default SaveFiltersModal;
