import { watchEvery } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { change } from 'redux-form';
import forEach from 'lodash/forEach';
import { call, put, select } from 'redux-saga/effects';

import HC_CONSTANTS from 'HC_CONSTANTS';

import { STATUSES } from 'legacy/appstore/constants';

import {
  normalizePersonalizationProperties,
  personalizationDataFromAccount
} from 'legacy/utils/personalization';

import {
  PERSONALIZATION_DATA_REQUEST,
  personalizationDataLoading,
  personalizationDataSuccess,
  personalizationDataFailure,
  PERSONALIZATION_UPLOAD_IMG,
  personalizationUploadImgSuccess,
  personalizationUploadImgFailure,
  PERSONALIZATION_DATA_UPDATE,
  personalizationDataUpdateSuccess,
  personalizationDataUpdateFailure,
  PERSONALIZATION_ACK_PROMPT
} from 'actions/personalization';

import { getPersonalizationStatus } from 'selectors/personalization';
import { getAccountDetails } from 'selectors/account-details';
import { getUserIsReportOwner } from 'selectors/report-permissions';

import {
  FORM_NAME as PERSONALIZATION_FORM_NAME,
  FIELD_NAMES as PERSONALIZATION_FIELD_NAMES,
  IMG_HEIGHT_MIN,
  IMG_SIZE_MAX,
  IMG_FILE_TYPES,
  INCLUDE_GROUP_NAMES,
  validateImg
} from 'legacy/forms/personalization-form';
import apiUtil from 'legacy/utils/api';

const URL_PERSONALIZATION = `${HC_CONSTANTS.HUELL_PROXY_URL}/personalization/`;
const UPLOAD_IMG_URLS = {
  photoUrl: `${HC_CONSTANTS.HUELL_PROXY_URL}/personalization/user_photo/`,
  companyLogoUrl: `${HC_CONSTANTS.HUELL_PROXY_URL}/personalization/company_logo/`
};

export function* fetchPersonalizationData(action) {
  const personalizationStatus = yield select(getPersonalizationStatus);
  if (
    [STATUSES.SUCCESS, STATUSES.LOADING, STATUSES.DOES_NOT_EXIST].indexOf(
      personalizationStatus
    ) > -1
  ) {
    return true;
  }
  yield put(personalizationDataLoading());
  let data = yield call(
    apiUtil.GET,
    URL_PERSONALIZATION,
    {},
    { expect404: true }
  );
  if (data.statusCode === 404) {
    const account = yield select(getAccountDetails);
    yield put(
      personalizationDataFailure(
        404,
        'Not Found',
        personalizationDataFromAccount(account)
      )
    );
  } else {
    if (_checkForUseDataFromAccount(data)) {
      const account = yield select(getAccountDetails);
      data = personalizationDataFromAccount(account);
    }
    yield put(personalizationDataSuccess(data));
  }
}

export function* uploadImg(action) {
  const { groupName, fieldName, img } = action.payload;
  const url = UPLOAD_IMG_URLS[fieldName];
  let isValid = false;
  // Check for validation errors, notify user if so
  try {
    yield call(validateImg, img, IMG_FILE_TYPES, IMG_SIZE_MAX, IMG_HEIGHT_MIN);
    isValid = true;
  } catch (e) {
    let errorMessage = 'Error uploading image';
    if (e && e.message) errorMessage = e.message;
    yield put(personalizationUploadImgFailure(fieldName, errorMessage));
  }

  if (isValid) {
    const responseData = yield call(apiUtil.POST, url, img, {
      sendFile: true,
      headers: {
        'content-type': img.type
      }
    });
    // Change the value of the redux-form field to the s3 url
    yield put(
      change(PERSONALIZATION_FORM_NAME, fieldName, responseData.photoUrl, true)
    );
    yield put(change(PERSONALIZATION_FORM_NAME, groupName, true));
    yield put(personalizationUploadImgSuccess(fieldName));
  }
}

export function* updatePersonalizationData(action) {
  try {
    const normalizedValues = normalizePersonalizationProperties(
      action.payload.values,
      INCLUDE_GROUP_NAMES
    );
    const responseData = yield call(
      apiUtil.POST,
      URL_PERSONALIZATION,
      normalizedValues
    );
    const userIsReportOwner = yield select(getUserIsReportOwner);
    yield put(
      personalizationDataUpdateSuccess(
        _replaceHttpInUrl(responseData),
        userIsReportOwner
      )
    );
  } catch (e) {
    yield put(personalizationDataUpdateFailure(e));
    report(`Personalization Data Update: ${e.message}`, { e, action });
  }
}

function* ackPersonalizationPrompt(action) {
  try {
    yield call(apiUtil.POST, URL_PERSONALIZATION, { ackPrompt: true });
  } catch (e) {
    yield put(personalizationDataUpdateFailure(e));
    report(`Personalization Ack Prompt: ${e.message}`, { e, action });
  }
}

const _replaceHttpInUrl = (personalizationData) => {
  if (personalizationData) {
    const attrs = ['photoUrl', 'companyLogoUrl'];
    forEach(attrs, (attr) => {
      if (
        personalizationData[attr] &&
        personalizationData[attr].slice(0, 5) === 'http:'
      ) {
        personalizationData[attr] = personalizationData[attr].replace(
          'http:',
          'https:'
        );
      }
    });
  }

  return personalizationData;
};

const _checkForUseDataFromAccount = (responseData) => {
  let useAccount = true;
  forEach(PERSONALIZATION_FIELD_NAMES, (fieldName) => {
    if (responseData[fieldName]) {
      useAccount = false;
      return false;
    }
  });
  return useAccount;
};

export default function registerSagas() {
  watchEvery({
    [PERSONALIZATION_DATA_REQUEST]: fetchPersonalizationData,
    [PERSONALIZATION_UPLOAD_IMG]: uploadImg,
    [PERSONALIZATION_DATA_UPDATE]: updatePersonalizationData,
    [PERSONALIZATION_ACK_PROMPT]: ackPersonalizationPrompt
  });
}
