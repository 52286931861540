const FILE_SIZE_UNITS = ['B', 'kB', 'MB', 'GB', 'TB'];

export const readableFileType = (fileType) =>
  fileType.split('/')[1].toUpperCase();

export const convertBytes = (bytes, unit) => {
  const unitIndex = FILE_SIZE_UNITS.indexOf(unit);
  if (unitIndex === -1) {
    console.error(`File size unit ${unit} not supported`);
    return null;
  }
  return bytes / Math.pow(1024, unitIndex);
};

export const humanFileSize = (bytes, dec = 1) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, i)).toFixed(dec) + ' ' + FILE_SIZE_UNITS[i];
};

export const roughSizeOfObject = (obj) => {
  let objectList = [];
  let stack = [obj];
  let bytes = 0;

  while (stack.length) {
    const value = stack.pop();
    const type = typeof value;
    if (type === 'boolean') {
      bytes += 4;
    } else if (type === 'string') {
      bytes += value.length * 2;
    } else if (type === 'number') {
      bytes += 8;
    } else if (type === 'object' && objectList.indexOf(value) === -1) {
      objectList.push(value);

      for (let i in value) {
        stack.push(value[i]);
      }
    }
  }
  return bytes;
};
