import { Component } from 'react';
import PropTypes from 'prop-types';
import { BlueDialogHeader } from '@hc/component-lib/hclib/components/organisms/dialog';
import { Dialog } from 'legacy/components/Dialog';
import LoginContainer from 'legacy/containers/login';

import { LoginPromptModal as theme } from 'legacy/css-modules';

const LOGIN_STEP = 'login';

const HEADER_TEXT_FOR_STEP = {
  [LOGIN_STEP]: {
    title: 'Please log in to continue'
  }
};

export default class LoginPromptModal extends Component {
  static defaultProps = {
    open: false
  };
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    open: PropTypes.bool,
    /* An action to dispatch immediately after successful authentication */
    afterAuthAction: PropTypes.func
  };

  state = {
    step: LOGIN_STEP
  };

  handleTransitionToLogin = () => {
    this.setState({ step: LOGIN_STEP });
  };

  render() {
    const { open, fullscreen, onClose, afterAuthAction } = this.props;
    const { step } = this.state;

    return (
      <Dialog
        active={open}
        type={fullscreen ? 'fullscreen' : 'normal'}
        title={
          <BlueDialogHeader
            title={HEADER_TEXT_FOR_STEP[step].title}
            subtitle={HEADER_TEXT_FOR_STEP[step].subtitle}
            theme={theme}
          />
        }
        onClose={onClose}
        className={theme.LoginPromptModal}
      >
        <div>
          <LoginContainer afterAuthAction={afterAuthAction} insideModal />
        </div>
      </Dialog>
    );
  }
}
