export const EFFECTIVE_DATE_GET_REPORT = 'EFFECTIVE_DATE_GET_REPORT';
export const EFFECTIVE_DATE_GET_REPORT_SUCCESS =
  'EFFECTIVE_DATE_GET_REPORT_SUCCESS';
export const EFFECTIVE_DATE_GET_REPORT_FAILURE =
  'EFFECTIVE_DATE_GET_REPORT_FAILURE';
export const EFFECTIVE_DATE_CREATE_REPORT_SUCCESS =
  'EFFECTIVE_DATE_CREATE_REPORT_SUCCESS';
export const EFFECTIVE_DATE_CREATE_REPORT_FAILURE =
  'EFFECTIVE_DATE_CREATE_REPORT_FAILURE';
export const EFFECTIVE_DATE_ERROR_CTA = 'EFFECTIVE_DATE_ERROR_CTA';

export const effectiveDateGetReport = ({ addressId, effectiveDate }) => {
  return {
    type: EFFECTIVE_DATE_GET_REPORT,
    payload: { addressId, effectiveDate }
  };
};

export const effectiveDateGetReportSuccess = (payload) => {
  return {
    type: EFFECTIVE_DATE_GET_REPORT_SUCCESS,
    payload,
    meta: payload.showToast && {
      toast: {
        message: 'Your custom report has been saved!',
        dataHcName: 'save-message-toast'
      }
    }
  };
};

export const effectiveDateGetReportFailure = (statusCode) => {
  return {
    type: EFFECTIVE_DATE_GET_REPORT_FAILURE,
    payload: { statusCode }
  };
};

export const effectiveDateCreateReportSuccess = () => {
  return {
    type: EFFECTIVE_DATE_CREATE_REPORT_SUCCESS
  };
};

export const effectiveDateCreateReportFailure = () => {
  return {
    type: EFFECTIVE_DATE_CREATE_REPORT_FAILURE
  };
};

export const effectiveDateErrorCta = (route) => {
  return {
    type: EFFECTIVE_DATE_ERROR_CTA,
    meta: {
      router: route
    }
  };
};
