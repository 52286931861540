import { routerMiddleware } from '@hc/redux-saga-router-plus';

import ROUTES from 'legacy/routes';
import history from 'legacy/routes/history';

import sagaMiddleware from 'middleware/saga';
import analyticsMiddleware from 'middleware/analytics';
import analyticsPageViewedMiddleware from 'middleware/analytics-page-viewed';
import performanceTrackingMiddleware from 'middleware/performance-tracking';

export default [
  sagaMiddleware,
  performanceTrackingMiddleware(), // NOTE: This must be applied before analyticsMiddleware
  analyticsPageViewedMiddleware, // NOTE: This must be applied before analyticsMiddleware
  analyticsMiddleware,
  routerMiddleware(history, ROUTES, sagaMiddleware)
];
