import classNames from 'classnames';
import React from 'react';
import { FilterButton } from 'src/lib/components/FilterButton';
import { FilterIcon } from 'src/lib/components/FilterIcon';
import { useCompFilterBarState } from './useCompFilterBarState';
import styles from './MoreFiltersButton.css';
import { useEffect } from 'react';

interface Props {
  dataHcName: string;
  className?: string;
}
export const MoreFiltersButton = ({ dataHcName, className }: Props) => {
  const {
    compFilterBarState: {
      overflowVisible,
      overflowCount,
      overflowButtonVisible
    },
    compFilterBarActions: {
      compFilterOverflowVisibilityChange,
      compFilterOverflowButtonVisibilityChange
    }
  } = useCompFilterBarState();

  useEffect(() => {
    if (!!overflowCount !== overflowButtonVisible) {
      compFilterOverflowButtonVisibilityChange(!!overflowCount);
    }
  }, [overflowCount, overflowButtonVisible]);
  if (!overflowCount) {
    return null;
  }
  return (
    <FilterButton
      dataHcName={dataHcName}
      active={overflowVisible}
      className={classNames(styles.MoreFiltersButton, className, {
        [styles.active || '']: overflowVisible
      })}
      onClick={() => {
        compFilterOverflowVisibilityChange(!overflowVisible);
      }}
    >
      <FilterIcon
        active={overflowVisible}
        dataHcName={`${dataHcName}-more-filters-icon`}
      />{' '}
      More Filters{overflowCount > 0 && ` (${overflowCount})`}
    </FilterButton>
  );
};
