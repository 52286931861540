import { DateStr } from 'src/types';
import { DocumentBase } from './Document';

export enum ReportTypes {
  EffectiveDate = 'effectivedate'
}

export interface Report {
  addressID: number;
  city: string;
  clientID: string | null;
  createdAt: string;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  deletedAt: string | null;
  documents: DocumentBase[];
  effectiveDate: DateStr;
  id: number;
  ownerId: number;
  ownerOrganizationId: number;
  reportType: ReportTypes;
  slug: string;
  state: string;
  streetAddress: string;
  unit: string;
  unitDesignator: string;
  updatedAt: string | null;
  zipcode: string;
}
