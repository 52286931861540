import React, { MouseEvent, useRef } from 'react';
import classNames from 'classnames';

import styles from './CopyInput.css';

interface Props {
  dataHcName: string;
  value: string;
  className?: string;
  onCopy?: VoidFunction;
  onSelectAll?: (e: MouseEvent<HTMLInputElement>) => void;
}

export const CopyInput = ({
  dataHcName,
  value,
  className,
  onCopy,
  onSelectAll
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleSelectAll = (e: MouseEvent<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.setSelectionRange(0, value.length);
    }
    onSelectAll?.(e);
  };
  const handleCopy = (e: MouseEvent<HTMLButtonElement>) => {
    if (ref.current) {
      // Hold Cmd key to copy just the path of the report (for dev/qa)
      const startIndex = e.metaKey ? ref.current.value.indexOf('shared') : 0;
      ref.current.focus();
      ref.current.setSelectionRange(startIndex, ref.current.value.length);
      document.execCommand('copy');
    }
    onCopy?.();
  };
  return (
    <div className={classNames(styles.CopyInput, className)}>
      <div className={styles.InputCell}>
        <input
          ref={ref}
          type="text"
          data-hc-name={dataHcName}
          className={styles.Input}
          value={value || ''}
          // Select text on click
          onClick={handleSelectAll}
          // Repress React warning from absence of readOnly attr, needed to
          // allow mobile users to choose "Select All" if desired
          onChange={() => {
            return null;
          }}
        />
      </div>
      <div className={styles.CopyCell}>
        <button className={styles.Button} onClick={handleCopy}>
          Copy Link
        </button>
      </div>
    </div>
  );
};
