import classNames from 'classnames';
import { noop } from 'legacy/utils/utils';
import { DrawControlButton as buttonTheme } from 'legacy/css-modules';

const DrawControlButton = ({
  type,
  Icon,
  IconActive,
  title,
  isActive,
  className,
  children,
  onClick,
  ...props
}) => {
  const theme = {
    ...buttonTheme,
    ...props.theme
  };
  return (
    <div
      onClick={onClick}
      className={classNames(theme.DrawControlButton, className, {
        [theme.isActive]: isActive
      })}
    >
      <div className={classNames(theme.IconContainer)}>
        {isActive ? (
          <IconActive className={theme.IconActive} />
        ) : (
          <Icon className={theme.Icon} />
        )}
      </div>
      <label className={theme.Label}>{title}</label>
      {children}
    </div>
  );
};

DrawControlButton.defaultProps = {
  onClick: noop
};

export default DrawControlButton;
