import classNames from 'classnames';

import { TextInput } from '@hc/component-lib/hclib/components/atoms/text-input';
import { FormError } from '@hc/component-lib/hclib/components/atoms/form-error';
import { ValidatingIcon } from '@hc/component-lib/hclib/components/atoms/icons';

import { TextInputHc as theme } from 'legacy/css-modules/generic';

const TextInputHc = ({
  input,
  meta = {},
  label,
  fullWidth = false,
  maxLength,
  type = 'text',
  placeholder = '',
  errorText = '',
  isValidating = false,
  className,
  ...props
}) => {
  const error = meta.error && meta.touched ? meta.error : errorText;
  const ErrorComponent = error ? (
    <div
      className={maxLength ? theme['errorWrap--maxlength'] : theme.errorWrap}
    >
      <FormError data-hc-name={`${input.name}-error-text`} value={error} />
    </div>
  ) : null;
  let { value, ...inputRest } = input;
  if (value === undefined) {
    value = '';
  }
  return (
    <div
      className={classNames(className, theme['hc-input-text--container'], {
        [theme['hc-input-text--container--full-width']]: fullWidth
      })}
    >
      {isValidating && (
        <ValidatingIcon style={{ position: 'absolute', right: 0 }} />
      )}
      <TextInput
        label={label}
        type={type}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        error={ErrorComponent}
        {...inputRest}
      />
    </div>
  );
};

export default TextInputHc;
