import { watchEvery, watchLatest } from 'legacy/utils/saga';
import { call, put, select } from 'redux-saga/effects';
import get from 'lodash.get';
import { FILTER_MATCH_SUBJECT } from 'legacy/appstore/constants';
import { isPopulated } from 'legacy/utils/utils';
import {
  FILTERS,
  isFilterSet,
  getSubjectFilterValue,
  getSubjectRentalFilterValue
} from 'legacy/utils/filters';
import { FILTER_SAVE_METHODS } from 'legacy/utils/saved-filters';

import { filterSearchSortComps } from 'legacy/services/comps-filters';

import {
  GET_REPORT_RENTAL_SUCCESS,
  GET_REPORT_USER_VERSION_SUCCESS
} from 'actions/get-report';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';
import { VR_RECOMPUTE_SUCCESS } from 'actions/edit-report';
import {
  COMPS_FILTERS_CHANGE_ABSOLUTE,
  COMPS_FILTERS_CHANGE_RELATIVE,
  COMPS_ADD_COMP_SUCCESS,
  COMPS_SORT,
  COMPS_CLEAR_FILTER,
  COMPS_CLEAR_ALL_FILTERS,
  COMPS_SEARCH,
  COMPS_FARM_UPDATE_SUCCESS,
  COMPS_ADJUST_SAVE,
  compsFiltersApply,
  compsFiltersChangeComplete
} from 'actions/comps';
import {
  RENTAL_COMPS_FILTERS_CHANGE_RELATIVE,
  RENTAL_COMPS_FILTERS_CHANGE_ABSOLUTE,
  RENTAL_COMPS_ADD_COMP_SUCCESS,
  RENTAL_COMPS_SORT,
  RENTAL_COMPS_CLEAR_FILTER,
  RENTAL_COMPS_CLEAR_ALL_FILTERS,
  RENTAL_COMPS_SEARCH,
  RENTAL_COMPS_FARM_UPDATE_SUCCESS,
  RENTAL_COMPS_ADJUST_SAVE,
  rentalCompsFiltersApply
} from 'actions/rental-comps';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import { getReportDataEffectiveDate } from 'selectors/report-data';
import {
  getCompsFarm,
  getCompsSearch,
  getCompsSort,
  getCompsFilters
} from 'selectors/comps';
import {
  getRentalCompsFarm,
  getRentalCompsSearch,
  getRentalCompsSort,
  getRentalCompsFilters
} from 'selectors/rental-comps';

import { getSubjectAdjusted, getSubjectRental } from 'selectors/subject';

// NOTE: I'm running out of time so I completely separeted the rental and default comps logic in these sagas
function* applyFiltersComps(filters) {
  const farm = yield select(getCompsFarm);
  const search = yield select(getCompsSearch);
  const sort = yield select(getCompsSort);
  const subject = yield select(getSubjectAdjusted);
  const filterValues = {};
  const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
  const effectiveDate = yield select(getReportDataEffectiveDate);
  Object.entries(filters).forEach(([filterKey, values]) => {
    if (values) {
      const config = FILTERS[filterKey];
      const { absoluteValue, relativeValue } = values;
      const subjectValue = getSubjectFilterValue(subject, filterKey);
      if (isEffectiveDateReport && !absoluteValue?.error) {
        filterValues[filterKey] = absoluteValue;
      }
      // Attempt to apply filter as relative value
      else if (
        isFilterSet(relativeValue) &&
        !relativeValue.error &&
        isPopulated(subjectValue)
      ) {
        const newAbsoluteValue = FILTER_SAVE_METHODS[config.saveType].apply(
          relativeValue,
          subjectValue,
          config,
          isEffectiveDateReport && effectiveDate
        );
        if (!newAbsoluteValue.error) {
          filterValues[filterKey] = newAbsoluteValue;
        }
        filters[filterKey].absoluteValue = newAbsoluteValue;
      } else if (!absoluteValue || !absoluteValue.error) {
        filterValues[filterKey] = absoluteValue;
      }
    }
  });

  const available = filterSearchSortComps(
    farm,
    filterValues,
    search,
    sort,
    subject
  );
  yield put(compsFiltersApply(filters, available));
}

function* applyFiltersRentalComps(filters) {
  const farm = yield select(getRentalCompsFarm);
  const search = yield select(getRentalCompsSearch);
  const sort = yield select(getRentalCompsSort);
  const subject = yield select(getSubjectRental);
  const filterValues = {};
  const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
  const effectiveDate = yield select(getReportDataEffectiveDate);
  Object.entries(filters).forEach(([filterKey, values]) => {
    if (values) {
      const config = FILTERS[filterKey];
      const { absoluteValue, relativeValue } = values;
      const subjectValue = getSubjectRentalFilterValue(subject, filterKey);
      // Attempt to apply filter as relative value
      if (
        config &&
        isFilterSet(relativeValue) &&
        !relativeValue.error &&
        isPopulated(subjectValue)
      ) {
        const newAbsoluteValue = FILTER_SAVE_METHODS[config.saveType].apply(
          relativeValue,
          subjectValue,
          config,
          isEffectiveDateReport && effectiveDate
        );
        if (!newAbsoluteValue.error) {
          filterValues[filterKey] = newAbsoluteValue;
        }
        filters[filterKey].absoluteValue = newAbsoluteValue;
      } else if (!absoluteValue || !absoluteValue.error) {
        filterValues[filterKey] = absoluteValue;
      }
    }
  });

  const available = filterSearchSortComps(
    farm,
    filterValues,
    search,
    sort,
    subject
  );
  yield put(rentalCompsFiltersApply(filters, available));
}

function* handleFilterFarm(action) {
  const filters = yield select(getCompsFilters);
  yield call(applyFiltersComps, filters);
}

function* handleChangeAbsolute(action) {
  // Calculate relative value, update filters object then apply
  const { absoluteValues } = action.payload;
  const filters = yield select(getCompsFilters);
  const subject = yield select(getSubjectAdjusted);
  const newFilters = { ...filters };
  for (let filterKey in absoluteValues) {
    const config = FILTERS[filterKey];
    const absoluteValue = absoluteValues[filterKey];
    const filterIsSet = yield call(isFilterSet, absoluteValue);
    if (filterIsSet) {
      const relativeValue = FILTER_SAVE_METHODS[config.saveType].save(
        absoluteValue,
        getSubjectFilterValue(subject, filterKey)
      );
      newFilters[filterKey] = { relativeValue, absoluteValue };
    } else {
      delete newFilters[filterKey];
    }
  }
  yield call(applyFiltersComps, newFilters);
  yield put(compsFiltersChangeComplete({ absoluteValues }));
}

function* handleChangeRelative(action) {
  // Calculate absolute value, update filters object then apply
  const { relativeValues, filterSetId } = action.payload;
  const subject = yield select(getSubjectAdjusted);
  const filters = filterSetId ? {} : yield select(getCompsFilters);
  const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
  const effectiveDate = yield select(getReportDataEffectiveDate);
  // Only send absolute values from huell-frontend to avoid rounding bugs, unless it's a match subject relative value
  const absoluteValues = {};
  const relativeValuesForReportApi = {};
  for (let filterKey in relativeValues) {
    const config = FILTERS[filterKey];
    const relativeValue = relativeValues[filterKey];
    const subjectValue = getSubjectFilterValue(subject, filterKey);
    const absoluteValue = FILTER_SAVE_METHODS[config.saveType].apply(
      relativeValue,
      subjectValue,
      config,
      isEffectiveDateReport && effectiveDate
    );
    filters[filterKey] = { relativeValue, absoluteValue };
    if (relativeValue === FILTER_MATCH_SUBJECT) {
      relativeValuesForReportApi[filterKey] = relativeValue;
    } else {
      absoluteValues[filterKey] = absoluteValue;
    }
  }
  yield call(applyFiltersComps, filters);
  yield put(
    compsFiltersChangeComplete({
      absoluteValues,
      relativeValues: relativeValuesForReportApi,
      filterSetId
    })
  );
}

function* handleFilterRentalFarm(action) {
  const filters = yield select(getRentalCompsFilters);
  yield call(applyFiltersRentalComps, filters, action);
}

function* handleChangeAbsoluteRental(action) {
  // Calculate relative value, update filters object then apply
  const { absoluteValues } = action.payload;
  const subject = yield select(getSubjectRental);
  const filters = yield select(getRentalCompsFilters);
  const newFilters = { ...filters };
  for (let filterKey in absoluteValues) {
    const absoluteValue = absoluteValues[filterKey];
    const config = FILTERS[filterKey];
    const relativeValue = FILTER_SAVE_METHODS[config.saveType].save(
      absoluteValue,
      getSubjectRentalFilterValue(subject, filterKey)
    );
    const filterIsSet = yield call(isFilterSet, absoluteValue);
    if (filterIsSet) {
      newFilters[filterKey] = { relativeValue, absoluteValue };
    } else {
      delete newFilters[filterKey];
    }
    yield call(applyFiltersRentalComps, newFilters);
  }
}

function* handleChangeRelativeRental(action) {
  // Calculate absolute value, update filters object then apply
  const { relativeValues, overwriteAll } = action.payload;
  const subject = yield select(getSubjectRental);
  const filters = overwriteAll ? {} : yield select(getRentalCompsFilters);
  const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
  const effectiveDate = yield select(getReportDataEffectiveDate);
  for (let filterKey in relativeValues) {
    const config = FILTERS[filterKey];
    const relativeValue = relativeValues[filterKey];
    const subjectValue = getSubjectRentalFilterValue(subject, filterKey);
    const absoluteValue = FILTER_SAVE_METHODS[config.saveType].apply(
      relativeValue,
      subjectValue,
      config,
      isEffectiveDateReport && effectiveDate
    );
    filters[filterKey] = { relativeValue, absoluteValue };
  }
  yield call(applyFiltersRentalComps, filters);
}

export default () => {
  watchLatest(
    [
      VR_RECOMPUTE_SUCCESS,
      COMPS_ADD_COMP_SUCCESS,
      COMPS_SORT,
      COMPS_CLEAR_FILTER,
      COMPS_CLEAR_ALL_FILTERS,
      COMPS_SEARCH,
      COMPS_FARM_UPDATE_SUCCESS,
      COMPS_ADJUST_SAVE,
      GET_REPORT_USER_VERSION_SUCCESS,
      EFFECTIVE_DATE_GET_REPORT_SUCCESS
    ],
    handleFilterFarm
  );

  watchLatest(
    [
      VR_RECOMPUTE_SUCCESS,
      GET_REPORT_RENTAL_SUCCESS,
      RENTAL_COMPS_ADD_COMP_SUCCESS,
      RENTAL_COMPS_SORT,
      RENTAL_COMPS_CLEAR_FILTER,
      RENTAL_COMPS_CLEAR_ALL_FILTERS,
      RENTAL_COMPS_SEARCH,
      RENTAL_COMPS_FARM_UPDATE_SUCCESS,
      RENTAL_COMPS_ADJUST_SAVE,
      GET_REPORT_USER_VERSION_SUCCESS
    ],
    handleFilterRentalFarm
  );

  watchEvery([COMPS_FILTERS_CHANGE_ABSOLUTE], handleChangeAbsolute);
  watchEvery([COMPS_FILTERS_CHANGE_RELATIVE], handleChangeRelative);
  watchEvery(
    [RENTAL_COMPS_FILTERS_CHANGE_ABSOLUTE],
    handleChangeAbsoluteRental
  );
  watchEvery(
    [RENTAL_COMPS_FILTERS_CHANGE_RELATIVE],
    handleChangeRelativeRental
  );
};
