import { useEffect, useState } from 'react';
import { DomInput } from 'src/types';
import { useRerender } from '../report-api/hooks/useRerender';
import { useResizeObserver } from './useResizeObserver';
import { useWindowResize } from './useWindowResize';

export const useParentDimensions = <T extends HTMLElement>({
  ref,
  element
}: DomInput<T>) => {
  const domElement = element || ref?.current;
  const parentElement = domElement?.parentElement;
  const [parentDimensions, setParentDimensions] = useState<DOMRect | undefined>(
    parentElement?.getBoundingClientRect()
  );
  const updateParentDimensions = () => {
    if (parentElement) {
      const newParentDimensions = parentElement.getBoundingClientRect();
      if (
        newParentDimensions.height !== parentDimensions?.height ||
        newParentDimensions.width !== parentDimensions?.width
      ) {
        setParentDimensions(newParentDimensions);
      }
    }
  };
  // Rerender once if the parent is mounted simultaneously
  const { rerenders } = useRerender({
    deps: [parentElement],
    shouldRerender: !!parentElement,
    max: 1
  });
  // Listen to window resizes
  useWindowResize(updateParentDimensions);
  // Listen to parent size changes
  useResizeObserver({ element: parentElement }, updateParentDimensions);
  // Update if manual rerender is triggered or domElement changes
  useEffect(updateParentDimensions, [domElement, rerenders]);

  return parentDimensions;
};
