import { put, call, delay, all, select } from 'redux-saga/effects';
import HC_CONSTANTS from 'HC_CONSTANTS';
import { watchEvery } from 'legacy/utils/saga';
import apiUtil from 'legacy/utils/api';
import { report } from 'hc-ravenjs-logger';
import { toCamelCase } from 'legacy/utils/transform';
import { STATUSES } from 'legacy/appstore/constants';
import {
  SELF_SERVICE_CHECKOUT_SETUP,
  SELF_SERVICE_PACKAGES_FETCH,
  SELF_SERVICE_PAYMENT_METHODS_FETCH,
  selfServicePackagesFetchSuccess,
  selfServicePackagesFetchError,
  selfServicePaymentMethodsFetchSuccess,
  selfServicePaymentMethodsFetchError,
  SELF_SERVICE_SETUP_INTENT,
  SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK,
  SELF_SERVICE_SETUP_INTENT_CHARGE_CARD,
  selfServiceSetupIntentSuccess,
  selfServiceSetupIntentError,
  selfServiceSetupIntentCheckWebhookSuccess,
  selfServiceSetupIntentCheckWebhookError,
  selfServiceSetupIntentChargeCard,
  SELF_SERVICE_PAYMENT_INTENT,
  SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK,
  selfServicePaymentIntentSuccess,
  selfServicePaymentIntentError,
  selfServicePaymentIntentAuthenticationRequired,
  selfServicePaymentIntentCheckWebhookSuccess,
  selfServicePaymentIntentCheckWebhookError
} from 'actions/self-service';
import {
  getSelfServiceSelectedPackageId,
  getSelfServicePackagesStatus,
  getSelfServicePaymentMethodsStatus,
  getSelfServiceSetupIntentClientSecret,
  getSelfServicePaymentIntentClientSecret,
  getSelfServiceSetupIntentIsFirstTimeSetup
} from 'selectors/self-service';
import { getUserIsSelfServiceAndConfirmed } from 'selectors/account-details';
import { fetchOrganizationUsage } from 'sagas/organization-usage';

const { SELF_SERVICE_URL } = HC_CONSTANTS;

/*
 **************** SETUP FLOW ****************
 */
function* handleSelfServiceSetupIntent(action) {
  try {
    const packageId = yield select(getSelfServiceSelectedPackageId);
    const { clientSecret, publishableKey } = yield call(
      apiUtil.POST,
      `${SELF_SERVICE_URL}/setup-intent`,
      { packageId }
    );
    yield put(selfServiceSetupIntentSuccess({ clientSecret, publishableKey }));
    // We don't call the webhook yet because the user has not entered their CC yet.
  } catch (e) {
    yield put(selfServiceSetupIntentError());
  }
}

function* handleSelfServiceSetupIntentCheckWebhook(action) {
  try {
    const clientSecret = yield select(getSelfServiceSetupIntentClientSecret);
    const url = `${SELF_SERVICE_URL}/setup-intent/${clientSecret}`;
    const response = yield call(apiUtil.GET, url);
    // Select if user is confirmed prior to dispatching selfServiceSetupIntentCheckWebhookSuccess
    // since the action will update confirmed = true for the user on state;
    const userIsConfirmed = yield select(getUserIsSelfServiceAndConfirmed);
    if (response.status !== 'succeeded') {
      yield delay(1500);
      yield call(handleSelfServiceSetupIntentCheckWebhook, action);
    } else {
      yield call(fetchOrganizationUsage);
      yield put(
        selfServiceSetupIntentCheckWebhookSuccess(response.freeItemsAdded)
      );
      if (userIsConfirmed) {
        const isFirstTimeSetup = yield select(
          getSelfServiceSetupIntentIsFirstTimeSetup
        );
        if (!isFirstTimeSetup && !response.freeItemsAdded) {
          yield put(selfServiceSetupIntentChargeCard());
        }
      }
    }
  } catch (e) {
    console.error(e);
    report('Setup Intent Webhook Error', { e, action });
    yield put(selfServiceSetupIntentCheckWebhookError());
  }
}

/*
 **************** PAYMENT FLOW ****************
 */
function* handleSelfServicePaymentIntent(action) {
  try {
    const packageId = yield select(getSelfServiceSelectedPackageId);
    const response = yield call(
      apiUtil.POST,
      `${SELF_SERVICE_URL}/payment-intent`,
      { packageId }
    );
    yield call(fetchOrganizationUsage);
    yield put(
      selfServicePaymentIntentSuccess({
        clientSecret: response.clientSecret,
        paymentMethodId: response.paymentMethodId
      })
    );
  } catch (e) {
    if (e.responseJSON) {
      const responseJSON = toCamelCase(e.responseJSON);
      if (responseJSON.declineCode === 'authentication_required') {
        yield put(
          selfServicePaymentIntentAuthenticationRequired({
            clientSecret: responseJSON.paymentIntent.clientSecret,
            paymentMethodId: responseJSON.paymentMethod.id
          })
        );
        return;
      } else {
        yield put(
          selfServicePaymentIntentError({
            errorCode: responseJSON.code,
            errorMessage: responseJSON.message
          })
        );
        return;
      }
    }
    report('Payment Intent Error', { e, action });
    yield put(selfServicePaymentIntentError());
  }
}

function* handleSelfServicePaymentIntentCheckWebhook(action) {
  try {
    const clientSecret = yield select(getSelfServicePaymentIntentClientSecret);
    const url = `${SELF_SERVICE_URL}/payment-intent/${clientSecret}`;
    const response = yield call(apiUtil.GET, url);
    if (response.status !== 'succeeded') {
      yield delay(1500);
      yield call(handleSelfServicePaymentIntentCheckWebhook, action);
    } else {
      yield call(fetchOrganizationUsage);
      yield put(selfServicePaymentIntentCheckWebhookSuccess());
    }
  } catch (e) {
    console.error(e);
    report('Payment Intent Webhook Error', { e, action });
    yield put(selfServicePaymentIntentCheckWebhookError());
  }
}

/*
 **************** CHECKOUT LIFECYCLE ****************
 */
function* handleSelfServiceCheckoutSetup(action) {
  const setupEffects = [];
  const packagesStatus = yield select(getSelfServicePackagesStatus);
  const paymentMethodsStatus = yield select(getSelfServicePaymentMethodsStatus);
  if (packagesStatus === STATUSES.INIT) {
    setupEffects.push(call(handleSelfServicePackagesFetch));
  }
  if (paymentMethodsStatus === STATUSES.INIT) {
    setupEffects.push(call(handleSelfServicePaymentMethodsFetch));
  }
  if (setupEffects.length) {
    yield all(setupEffects);
  }
}

function* handleSelfServicePaymentMethodsFetch(action) {
  try {
    const paymentMethods = yield call(
      apiUtil.GET,
      `${SELF_SERVICE_URL}/payment-method`
    );
    yield put(selfServicePaymentMethodsFetchSuccess(paymentMethods));
    return paymentMethods;
  } catch (e) {
    yield put(selfServicePaymentMethodsFetchError());
  }
}

function* handleSelfServicePackagesFetch(action) {
  try {
    const packages = yield call(apiUtil.GET, `${SELF_SERVICE_URL}/packages`);
    yield put(selfServicePackagesFetchSuccess(packages));
    return packages;
  } catch (e) {
    yield put(selfServicePackagesFetchError());
  }
}

export default () => {
  watchEvery({
    [SELF_SERVICE_SETUP_INTENT]: handleSelfServiceSetupIntent,
    [SELF_SERVICE_PAYMENT_INTENT]: handleSelfServicePaymentIntent,
    [SELF_SERVICE_PAYMENT_METHODS_FETCH]: handleSelfServicePaymentMethodsFetch,
    [SELF_SERVICE_CHECKOUT_SETUP]: handleSelfServiceCheckoutSetup,
    [SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK]:
      handleSelfServiceSetupIntentCheckWebhook,
    [SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK]:
      handleSelfServicePaymentIntentCheckWebhook,
    [SELF_SERVICE_SETUP_INTENT_CHARGE_CARD]: handleSelfServicePaymentIntent,
    [SELF_SERVICE_PACKAGES_FETCH]: handleSelfServicePackagesFetch
  });
};
