import classNames from 'classnames';

import Info from 'legacy/components/icons/Info';

import { Tooltip as theme } from 'legacy/css-modules/generic';

const TooltipDefaultTrigger = ({ className, dataHcName, ...props }) => {
  return (
    <Info
      className={classNames(className, theme['TriggerIcon'])}
      data-hc-name={dataHcName}
      {...props}
    />
  );
};

export default TooltipDefaultTrigger;
