import { watchLatest } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { call, put, select } from 'redux-saga/effects';

import { MAP_ID_COMPS, MAP_ID_RENTAL_COMPS } from 'legacy/appstore/constants';

import { buildRadiusGeoJson } from 'legacy/utils/maps';

import { handlePolygonChange } from 'sagas/map-draw';

import { mapDrawAddPolygons } from 'actions/map-draw';
import { SUBJECT_UPDATE_GEO_LOCATION } from 'actions/subject';

import { getPreferencesSavedDistanceFilterByMapId } from 'selectors/preferences';
import { getMapDrawPolygons } from 'selectors/map-draw';

const DEFAULT_COMP_DISTANCE_MILES = 1;

function* handleSubjectGeoLocationChange(action) {
  try {
    const { geoLocation, polygons, mapId } = action.payload;
    const polygonsToBuild = {
      [MAP_ID_COMPS]: true,
      [MAP_ID_RENTAL_COMPS]: true
    };
    const polygonsToAdd = {};
    if (polygons && polygons.length && mapId) {
      // User placed pin AND drew a comp area so call the mapDraw saga w/ the polygon
      polygonsToAdd[mapId] = polygons;
      delete polygonsToBuild[mapId];
    }

    for (let m in polygonsToBuild) {
      if (polygonsToBuild[m]) {
        const savedDistance = yield select(
          getPreferencesSavedDistanceFilterByMapId(m)
        );
        const existingPolygons = yield select(getMapDrawPolygons(m));
        // Use existing polygons if they exist. Re-query comps w/ new subject location
        polygonsToAdd[m] =
          existingPolygons &&
          existingPolygons.length &&
          !existingPolygons[0].properties.isSubjectCoordinates
            ? existingPolygons
            : [
                buildRadiusGeoJson(
                  geoLocation,
                  savedDistance || DEFAULT_COMP_DISTANCE_MILES,
                  true
                )
              ];
      }
    }

    // Update state w/ polygon shapes
    yield put(mapDrawAddPolygons(polygonsToAdd));

    // Call map-draw saga for each mapId
    for (let m2 in polygonsToAdd) {
      yield call(handlePolygonChange, {
        payload: {
          mapId: m2,
          polygons: polygonsToAdd[m2]
        }
      });
    }
  } catch (e) {
    console.error(e);
    report(`Update Subject GeoLocation: ${e.message}`, { e, action });
  }
}

export default function registerSubjectGeoLocationSaga() {
  watchLatest({
    [SUBJECT_UPDATE_GEO_LOCATION]: handleSubjectGeoLocationChange
  });
}
