import React from 'react';
import classNames from 'classnames';
import { PropertyCard } from '@hc/component-lib/hclib/components/molecules/property-card';
import { ThumbnailImage } from '@hc/component-lib/hclib/components/atoms/thumbnail-image';
import { useDispatch } from 'react-redux';
import { THUMBNAIL_PLACEHOLDERS } from 'legacy/utils/photos';
import {
  PROPERTY_TYPE_SUBJECT,
  COMP_TYPE_DEFAULT
} from 'legacy/appstore/constants';
import { photosDialogShow } from 'actions/photos';
import SimilarityScoreTableCell from 'legacy/components/SimilarityScoreTableCell';
import MapMarker from 'legacy/components/MapMarker';
import { BrokerageCredit } from 'legacy/containers/brokerage-credit';
import { PropertyCard as theme } from 'legacy/css-modules';
import { usePhotos } from 'legacy/hocs/usePhotos';
import { PhotoDate } from 'legacy/components/PhotoDate';
import styleVars from '../styles/common/variables.css-module.css';
import { displayDateCompact } from 'legacy/utils/dates';
import { PropertyStreetViewDialog } from 'legacy/containers/property-street-view';
import { CompTags } from 'legacy/components/CompTags';

// This is needed so the llKey prop doesn't get added to a dom element
const LazyWrapper = ({ children }) => <div>{children}</div>;
const PropertyCardExtended = ({
  addressId,
  property = {},
  price,
  priceLabel,
  date,
  disableFullscreenImages,
  layout = 'vertical',
  bordered = true,
  PinLabel,
  hidePrice = false,
  // hideDate not supported by PropertyCard
  hideDate = false,
  hideSimilarity = false,
  smallThumbnail,
  isKeywordMatch = false,
  isSubject,
  isSelected,
  selectDisabled,
  hideSelectControl,
  alwaysShowThumbnailSliderControls,

  onSelectToggle,
  onClick,
  onClickThumbnailCarousel,
  dataHcName = '',
  className,
  PriceComponent,
  nonLazy,
  compType,
  photosLatestListingOnly,
  isEffectiveDateReport,
  onMouseEnter,
  onMouseLeave
}) => {
  const dispatch = useDispatch();
  const { photos, firstPrecedingEffectiveDate } = usePhotos(addressId, {
    property,
    latestListingOnly: true
  });
  const handleClickThumbnailCarousel = (e, currentIndex) => {
    // Call passed callback if there is one
    if (typeof onClickThumbnailCarousel === 'function') {
      onClick(property, currentIndex);
    }
    // Open fullscreen photos if enabled
    if (photos && photos.length && !disableFullscreenImages) {
      dispatch(
        photosDialogShow(addressId, {
          propertyType: isSubject ? PROPERTY_TYPE_SUBJECT : compType,
          currentIndex,
          latestListingOnly: photosLatestListingOnly
        })
      );
    }
  };

  const renderedPropertyCard = (
    <PropertyCard
      {...(dataHcName ? { 'data-hc-name': dataHcName } : {})}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classNames(
        {
          [theme.hasKeywordMatch]: isKeywordMatch,
          [theme.active]: priceLabel === 'Active',
          [theme.sold]: priceLabel === 'Sold',
          [theme.pending]: priceLabel === 'Pending',
          [theme.clickable]: !!onClick,
          [theme.largeValue]:
            (!hideSelectControl &&
              !hidePrice &&
              price &&
              price.toString().length >= 8) ||
            smallThumbnail,
          [theme.smallThumbnail]: smallThumbnail,
          [theme.selectCellNoPrice]: !hideSelectControl && hidePrice && hideDate
        },
        className,
        theme.PropertyCardVr
      )}
      theme={theme}
      streetAddress={property.streetAddress}
      city={property.city}
      state={property.state}
      zipcode={property.zipcode}
      unit={property.unit}
      unitDesignator={property.unitDesignator}
      labels={{
        price: { text: !hidePrice && priceLabel },
        similarity: {
          text: !hideSimilarity && (
            <SimilarityScoreTableCell
              className={theme.SimilarityScoreTableCell}
              similarityLevel={
                property.similarityLevelAdjusted || property.similarityLevel
              }
              similarityScore={
                property.similarityScoreAdjusted || property.similarityScore
              }
            />
          ),
          color: styleVars.colorText
        },

        bathrooms: 'Ba',
        bedrooms: 'Bd',
        distance: 'mi',
        sqFt: (
          <span>
            ft<sup className={theme.Sup}>2</sup>
          </span>
        )
      }}
      PriceComponent={PriceComponent}
      price={!hidePrice && price}
      sqFt={property.grossLivingAreaSqft}
      bathrooms={property.bathrooms}
      bedrooms={property.bedrooms}
      distance={property.distanceMiles === 0 ? '0.00' : property.distanceMiles}
      soldDate={hideDate ? null : displayDateCompact(date)}
      selected={isSelected}
      onSelectToggle={onSelectToggle}
      selectable={!hideSelectControl}
      disabled={selectDisabled}
      bordered={bordered}
      onClick={onClick}
      thumbnailCarouselProps={{
        className: classNames({
          [theme.FullscreenThumbnail]:
            !disableFullscreenImages &&
            onClickThumbnailCarousel &&
            theme.FullscreenThumbnail,
          [theme.NoPhotos]: !photos || !photos.length
        }),
        placeholderImgs: THUMBNAIL_PLACEHOLDERS,
        alwaysShowControls: alwaysShowThumbnailSliderControls,
        showControlsOnHover: true,
        initialIndex: firstPrecedingEffectiveDate,
        children:
          photos && photos.length
            ? photos.map((photo, i) => {
                return (
                  <ThumbnailImage
                    key={`${i}-${photo.httpUrl}`}
                    url={photo.httpUrl}
                  >
                    {photo.imageDate && <PhotoDate date={photo.imageDate} />}
                  </ThumbnailImage>
                );
              })
            : [],
        onClick: handleClickThumbnailCarousel
      }}
      layout={layout}
      width="100%"
      Pin={
        PinLabel ? (
          <MapMarker
            isSelectedStyle
            label={PinLabel}
            propertyStatus={priceLabel}
          />
        ) : null
      }
      children={
        <>
          <BrokerageCredit
            className={theme.BrokerageCredit}
            addressId={property.addressId}
            listingId={property.listingId}
          />
          {isSelected &&
            compType === COMP_TYPE_DEFAULT &&
            !isEffectiveDateReport && (
              <CompTags
                hcAddressId={property.addressId}
                classNameTrigger={theme.CompTags}
              />
            )}
          <PropertyStreetViewDialog
            property={property}
            classNameTrigger={theme.StreetViewIcon}
          />
        </>
      }
    />
  );
  if (nonLazy) return renderedPropertyCard;
  return (
    <LazyWrapper llKey={property.addressId || property.streetAddress}>
      {renderedPropertyCard}
    </LazyWrapper>
  );
};

export default PropertyCardExtended;
