import React, { useState } from 'react';
import { findDOMNode } from 'react-dom';
import HC_CONSTANTS from 'HC_CONSTANTS';
import StreetViewIcon from 'legacy/assets/svg-inline/street-view-icon.svg';
import { NullStateCard } from '@hc/component-lib/hclib/components/molecules/null-state-card';
import { PropertyStreetView as styles } from 'legacy/css-modules';
import classNames from 'classnames';
import { Dialog } from 'legacy/components/Dialog';
import {
  fullAddressStringForProperty,
  isLocationUnknown
} from 'legacy/utils/property-details';
import IconButton from 'legacy/components/generic/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { STATUSES } from 'legacy/appstore/constants';
import { photosCheckStreetViewUrl } from 'actions/photos';
import { getPhotosStreetViewStatus } from 'selectors/photos';
import { buildStreetViewUrl } from 'legacy/utils/photos';
import { isLoading } from 'legacy/utils/status';
import SimpleLoader from 'legacy/components/generic/SimpleLoader';

const { GOOGLE_MAPS_KEY } = HC_CONSTANTS;

let googleScriptAdded = false;
let googleLoadedCallback;
const googlePromise = new Promise((resolve) => {
  googleLoadedCallback = () => resolve(null);
});
// eslint-disable-next-line
global.googleMapsApiInit = () => {
  googleLoadedCallback();
};

/**
 * Convert degrees to radians
 */
export const degreesToRadians = (degrees) => {
  const radians = degrees % 360;
  return (radians * Math.PI) / 180;
};

/**
 * Convert radians to degrees
 */
export const radiansToDegrees = (radians) => {
  const degrees = radians % (2 * Math.PI);
  return (degrees * 180) / Math.PI;
};

const getBearing = (start, end) => {
  const lng1 = degreesToRadians(start.lng);
  const lng2 = degreesToRadians(end.lng);
  const lat1 = degreesToRadians(start.lat);
  const lat2 = degreesToRadians(end.lat);
  const a = Math.sin(lng2 - lng1) * Math.cos(lat2);
  const b =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);

  return radiansToDegrees(Math.atan2(a, b));
};

function initGoogleAPI() {
  if (googleScriptAdded) {
    return;
  }
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  // secscan ignore: we're setting the src to a hardcoded value, not another the user could somehow enter
  // eslint-disable-next-line
  // eslint-disable-next-line scanjs-rules/assign_to_src
  script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&callback=googleMapsApiInit`;
  head.appendChild(script);
  googleScriptAdded = true;
}

class GoogleStreetView extends React.Component {
  panorama = null;

  mounted = false;

  rootElement = null;

  recordRootElement = (ele) => {
    if (ele) {
      this.rootElement = findDOMNode(ele);
    }
  };

  processStreetViewData = (data, status) => {
    const { propertyLocation } = this.props;
    if (
      status === window.google.maps.StreetViewStatus.OK &&
      propertyLocation?.lat &&
      propertyLocation?.lng
    ) {
      const streetViewLocation = {
        lat: data.location.latLng.lat(),
        lng: data.location.latLng.lng()
      };

      const heading = getBearing(streetViewLocation, propertyLocation);
      if (this.panorama) {
        this.panorama.setPano(data.location.pano);
        this.panorama.setPov({
          heading,
          pitch: 0
        });
        this.panorama.setVisible(true);
      }
    } else {
      // eslint-disable-next-line no-unused-expressions
      this.props.onFailCallback?.();
    }
  };

  /* Prevent `google not found` error (likely due to ad blockers blocking a script) from breaking page */
  // componentDidCatch (error, errorInfo) {
  //   reportToSentry(error, {
  //     errorInfo,
  //     originalError: error
  //   });
  // }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    initGoogleAPI();
    const { controlOptions, propertyLocation } = this.props;
    this.mounted = true;
    googlePromise.then(() => {
      if (!this.mounted) {
        return;
      }
      /* Global var `google` set on window via script in HTML */
      /* One way to check if google is available on window  */
      if (
        typeof window.google === 'object' &&
        typeof window.google.maps === 'object' &&
        propertyLocation?.lat &&
        propertyLocation?.lng &&
        this.rootElement
      ) {
        const streetViewService = new window.google.maps.StreetViewService();

        const options = controlOptions || {
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.LEFT_BOTTOM
          },
          panControlOptions: {
            position: window.google.maps.ControlPosition.LEFT_BOTTOM
          },
          motionTracking: false
        };

        /* Init the Street View panorama on our DOM element */
        this.panorama = new window.google.maps.StreetViewPanorama(
          this.rootElement,
          options
        );

        /* Retrieve Street View data and run a callback method */
        streetViewService.getPanorama(
          {
            location: propertyLocation,
            radius: 50,
            source: window.google.maps.StreetViewSource.OUTDOOR
          },
          this.processStreetViewData
        );
      }
    });
  }

  render() {
    const { theme } = this.props;
    const themedStyles = { ...styles, ...theme };
    return (
      <div
        data-hc-name={this.props.dataHcName}
        className={themedStyles.StreetView}
        ref={this.recordRootElement}
        aria-label="Google Street View"
      />
    );
  }
}

export const StreetView = ({ addressId, ...props }) => {
  const dispatch = useDispatch();
  const streetViewStatus = useSelector(getPhotosStreetViewStatus(addressId));
  const streetViewUrl = buildStreetViewUrl(
    props.propertyLocation.lat,
    props.propertyLocation.lng
  );
  if (!streetViewStatus) {
    dispatch(photosCheckStreetViewUrl(addressId, streetViewUrl));
  }
  if (streetViewStatus === STATUSES.SUCCESS) {
    return <GoogleStreetView {...props} />;
  } else if (isLoading(streetViewStatus)) {
    return <SimpleLoader className={styles.LoadingSpinner} size="large" />;
  } else {
    return (
      <NullStateCard
        theme={styles}
        className={styles.NullStateCard}
        title="Street View Unavailable at this Location"
      />
    );
  }
};

export const PropertyStreetViewDialog = ({ classNameTrigger, property }) => {
  const [active, setActive] = useState(false);
  const handleClick = (e) => {
    setActive(true);
  };
  const streetViewStatus = useSelector(
    getPhotosStreetViewStatus(property?.addressId)
  );
  if (
    !property?.geoLocation?.latitude ||
    !property?.geoLocation?.longitude ||
    isLocationUnknown(property?.geoPrecision)
  ) {
    return null;
  } else {
    return (
      <>
        <IconButton
          dataHcName="street-view-button"
          onClick={handleClick}
          icon={StreetViewIcon}
          className={classNames(styles.StreetViewIcon, classNameTrigger)}
          disabled={streetViewStatus === STATUSES.ERROR}
          title={
            streetViewStatus === STATUSES.ERROR
              ? 'Street View Unavailable at this Location'
              : undefined
          }
        />
        <Dialog
          title={fullAddressStringForProperty(property)}
          dataHcName="street-view-dialog"
          active={active}
          onClose={() => setActive(false)}
          className={styles.Dialog}
          theme={styles}
          type="large"
        >
          {active && (
            <StreetView
              addressId={property.addressId}
              dataHcName="street-view"
              propertyLocation={{
                lat: property.geoLocation.latitude,
                lng: property.geoLocation.longitude
              }}
            />
          )}
        </Dialog>
      </>
    );
  }
};
