import Highlighter from 'react-highlight-words';
import { COMP_TYPE_DEFAULT, COMP_TYPE_RENTAL } from 'legacy/appstore/constants';
import { useSelector } from 'react-redux';
import { getRentalCompsKeywordSearchKeywords } from 'selectors/rental-comps';
import { PublicRemarksKeywords as styles } from 'legacy/css-modules';
import { getCompsKeywordSearchKeywords } from 'selectors/comps';
import { useListing } from 'legacy/hocs/useListing';
import { displayDate } from 'legacy/utils/dates';

const VARS_BY_COMP_TYPE = {
  [COMP_TYPE_DEFAULT]: {
    getKeywords: getCompsKeywordSearchKeywords
  },
  [COMP_TYPE_RENTAL]: {
    getKeywords: getRentalCompsKeywordSearchKeywords
  }
};
const escapeRegExpFn = (string) =>
  // Disabling because this regex was copied from the react-highlight-words package
  // eslint-disable-next-line no-useless-escape
  string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

const defaultSanitize = (string) => string;

const customFindWord = ({
  autoEscape,
  caseSensitive,
  sanitize = defaultSanitize,
  searchWords,
  textToHighlight
}) => {
  textToHighlight = sanitize(textToHighlight);

  return searchWords
    .filter((searchWord) => searchWord) // Remove empty words
    .reduce((chunks, searchWord) => {
      searchWord = sanitize(searchWord);

      if (autoEscape) {
        searchWord = escapeRegExpFn(searchWord);
      }
      // The \\b is the only change from the default implementation
      // It forces a whole-word match like the API does
      // SECURITY: @jnettleman - Ignoring the secscan rule because according to the docs it is intended to prevent
      // a DOS attack but this code will never run server-side. Docs: https://github.com/nodesecurity/eslint-plugin-security/blob/master/README.md#detect-non-literal-regexp
      // eslint-disable-next-line security/detect-non-literal-regexp
      const regex = new RegExp(
        '\\b' + searchWord + '\\b',
        caseSensitive ? 'g' : 'gi'
      );

      let match;
      // SECURITY: @jnettleman - Intentional according to the library
      // eslint-disable-next-line scanjs-rules/accidental_assignment
      while ((match = regex.exec(textToHighlight))) {
        let start = match.index;
        let end = regex.lastIndex;
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ highlight: false, start, end });
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++;
        }
      }

      return chunks;
    }, []);
};

const HighlightTag = ({ children, highlightIndex }) => {
  return (
    <mark
      className={styles.Keyword}
      data-hc-name={`keyword-search-match-${highlightIndex}`}
    >
      {children}
    </mark>
  );
};

export const PublicRemarksKeywords = ({
  addressId,
  listingId,
  compType,
  maxLength
}) => {
  let keywords = [];
  if (compType) {
    const { getKeywords } = VARS_BY_COMP_TYPE[compType];
    keywords = useSelector(getKeywords);
  }
  const { listing } = useListing(addressId, { listingId });
  const text = listing?.publicRemarks
    ? maxLength
      ? listing.publicRemarks.slice(0, maxLength)
      : listing.publicRemarks
    : '';
  return (
    <div>
      {keywords.length && compType ? (
        <Highlighter
          findChunks={customFindWord}
          searchWords={keywords}
          textToHighlight={text}
          data-hc-name="keyword-search-match"
          highlightTag={HighlightTag}
          autoEscape
        />
      ) : (
        text
      )}
      {listing?.statusDate && (
        <div className={styles.Date}>{displayDate(listing.statusDate)}</div>
      )}
    </div>
  );
};
