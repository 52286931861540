import { connect } from 'react-redux';

import PersonalizationExample from 'legacy/components/PersonalizationExample';

import mockCobrandingPhoto from 'legacy/assets/images/mock_user_photo.jpg';
import mockCobrandingCompanyLogo from 'legacy/assets/images/mock_company_logo.png';

const MOCK_COBRANDING_DATA = {
  includeName: true,
  includeEmail: true,
  includePhoto: true,
  includeLicenseNumber: true,
  includeCompanyName: true,
  includeCompanyLogo: true,
  firstName: 'John',
  lastName: 'Doe',
  email: 'johndoe@canaryrealestate.com',
  licenseNumber: '#919191919',
  companyName: 'Canary Real Estate, Inc',
  photoUrl: mockCobrandingPhoto,
  companyLogoUrl: mockCobrandingCompanyLogo
};

const mapStateToProps = (state, ownProps) => {
  return {
    mockCobrandingData: MOCK_COBRANDING_DATA
  };
};

export default connect(mapStateToProps)(PersonalizationExample);
