import React from 'react';
import classNames from 'classnames';

import { displayDateCompact } from 'legacy/utils/dates';
import {
  dollarsFormatter,
  dollarsPerMonthFormatter
} from 'legacy/utils/formatters';
import { capitalizeFirstLetter } from 'legacy/utils/strings';

import { TransactionDetails as theme } from 'legacy/css-modules';

const STATUS_MAPPING = {
  SOLD: 'sold',
  PENDING: 'pending',
  CONTINGENT: 'pending',
  ACTIVE: 'listed',
  CLOSED: 'sold',
  WITHDRAWN: 'listed',
  UNKNOWN: 'unknown',
  DELETED: 'listed',
  LEASED: 'leased',
  COMING_SOON: 'listed',
  CANCELLED: 'listed',
  EXPIRED: 'listed'
};

const getDate = (listing) => {
  if (STATUS_MAPPING[listing.status] === 'listed') {
    return listing.listingStatus.listingDate;
  } else {
    return listing.statusDate;
  }
};

const TransactionDetails = ({ listing }) => {
  if (!listing) return null;
  const { status, price, isListingRental } = listing;
  const normalizedStatus = STATUS_MAPPING[status];
  return (
    <div
      className={theme.TransactionDetails}
      data-listing-header={listing.listingID}
    >
      <i className={classNames(theme.Bullet, theme[normalizedStatus])} />
      <span>{capitalizeFirstLetter(normalizedStatus)}</span>{' '}
      {displayDateCompact(getDate(listing))} for{' '}
      <span>
        {price
          ? isListingRental
            ? dollarsPerMonthFormatter(price)
            : dollarsFormatter(price)
          : '--'}
      </span>
    </div>
  );
};

export default TransactionDetails;
