export const RECENT_REPORTS_FETCH_REQUEST = 'RECENT_REPORTS_FETCH_REQUEST';
export const RECENT_REPORTS_FETCH_SUCCESS = 'RECENT_REPORTS_FETCH_SUCCESS';
export const RECENT_REPORTS_START_REQUEST = 'RECENT_REPORTS_START_REQUEST';

/**
 * action to fetch recently purchased reports
 * @param deferred {Deferred}
 */
export const fetchRecentReports = (deferred) => ({
  type: RECENT_REPORTS_FETCH_REQUEST,
  payload: {
    deferred
  }
});

export const recentReportsStartRequest = () => ({
  type: RECENT_REPORTS_START_REQUEST
});

export const fetchRecentReportsSuccess = (recentReports) => ({
  type: RECENT_REPORTS_FETCH_SUCCESS,
  payload: {
    ...recentReports
  }
});
