import React from 'react';
import classNames from 'classnames';
import styles from './ZoomControl.css';

interface Props {
  dataHcName: string;
  className?: string;
  zoom: number; // the current zoom value
  onChange: (zoom: number) => void;
}

export const ZoomControl = ({
  dataHcName,
  className,
  zoom,
  onChange
}: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ZoomControl, className)}
    >
      <button
        data-hc-name={`${dataHcName}-zoom-in`}
        className={classNames(styles.Button, styles.zoomIn)}
        onClick={() => {
          onChange(zoom + 1);
        }}
      >
        +
      </button>
      <button
        data-hc-name={`${dataHcName}-zoom-in`}
        className={classNames(styles.Button, styles.zoomOut)}
        onClick={() => {
          onChange(zoom - 1);
        }}
      >
        -
      </button>
    </div>
  );
};
