import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { SubjectSearch } from './SubjectSearch';
import { getMqSmallMediumAndUp } from 'selectors/match-media';
import styles from './SubjectHeaderSearch.css-module.css';
import IconButton from 'legacy/components/generic/IconButton';
import searchIcon from 'legacy/assets/svg-inline/search.svg';
import { useClickOutsideComponent } from 'legacy/hocs/useClickOutsideComponent.js';

const STYLES_UNFOCUSED = { width: '40%' };
const STYLES_FOCUSED = { width: '100%' };

export const SearchInput = ({ onBlur }) => {
  const ref = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const isMobile = !useSelector(getMqSmallMediumAndUp);
  // Using a click here instead of blur so users can change the search type dropdown
  // without affecting the size of the search input
  useClickOutsideComponent(ref, () => {
    setIsFocused(false);
    if (onBlur) {
      onBlur();
    }
  });
  return (
    <>
      <div
        ref={ref}
        className={styles.SearchInputWrapper}
        style={isFocused || isMobile ? STYLES_FOCUSED : STYLES_UNFOCUSED}
      >
        <SubjectSearch
          onSelect={() => {
            setIsFocused(false);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
        />
      </div>
      {(isFocused || isMobile) && <div className={styles.Overlay} />}
    </>
  );
};

const MobileSearchInput = () => {
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  return (
    <>
      <IconButton
        className={styles.MobileIcon}
        icon={searchIcon}
        onClick={() => {
          setMobileSearchOpen(true);
        }}
      />
      {mobileSearchOpen && (
        <div className={styles.MobileInput}>
          <SearchInput
            onBlur={() => {
              setMobileSearchOpen(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export const SubjectHeaderSearch = () => {
  const isMobile = !useSelector(getMqSmallMediumAndUp);
  return (
    <div className={styles.SubjectHeaderSearch}>
      {isMobile ? <MobileSearchInput /> : <SearchInput />}
    </div>
  );
};
