import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classNames from 'classnames';
import { Button } from 'legacy/components/Button';

import CobrandingImgUser from 'legacy/components/CobrandingImgUser';
import CobrandingImgCompany from 'legacy/components/CobrandingImgCompany';

import { FileField as theme } from 'legacy/css-modules/generic';

const CLASSNAME_DEFAULT = 'hc-field-file';

const FileInputComponent = ({
  className = CLASSNAME_DEFAULT,
  style = {},
  accept,
  avatar,
  label,
  buttonLabel,
  buttonLabelReplace,
  hintText,
  loading,
  onUpload,
  onDelete,
  status,
  input,
  dataHcNameButton,
  deleteLabel = 'Delete',
  meta,
  ...rest
}) => {
  return (
    <div
      className={classNames(theme['hc-field-file'], {
        [className]: className
      })}
      style={style}
    >
      <label className={theme.Label}>{label}</label>
      <div className={theme.FileFieldContainer}>
        {avatar ? (
          <div className={theme.FileFieldAvatarImg}>
            <CobrandingImgUser
              url={status.status !== 'UPLOADING' && input.value}
            />
          </div>
        ) : (
          <div className={theme.FileFieldCompanyImg}>
            <CobrandingImgCompany
              url={status.status !== 'UPLOADING' && input.value}
            />
          </div>
        )}

        <div className={theme['hc-file-field--button-wrapper']}>
          <div>
            <Button
              dataHcName={dataHcNameButton}
              label={input.value ? buttonLabelReplace : buttonLabel}
              className={theme['hc-field-file--button']}
              mini
            />

            <div>
              {input.value && onDelete && (
                <a
                  className={theme.FileFieldDeleteButton}
                  onClick={() => onDelete(input.name)}
                >
                  {deleteLabel}
                </a>
              )}
            </div>
          </div>
          <input
            name={input.name}
            accept={accept}
            type="file"
            className={theme['hc-field-file--input']}
            onChange={(e) => onUpload(e, input.name)}
          />
        </div>
      </div>
      <div className={theme.FileName}>{status.filename && status.filename}</div>
      {status.status === 'ERROR' && (
        <div className={theme['upload-error']}>{status.message}</div>
      )}
    </div>
  );
};

const HcFileField = ({ name, onUpload, ...rest }) => {
  return (
    <Field
      component={FileInputComponent}
      onUpload={onUpload}
      name={name}
      {...rest}
    />
  );
};

HcFileField.defaultProps = {
  buttonLabel: 'Upload',
  buttonLabelReplace: 'Replace',
  dataHcNameButton: 'upload-button'
};

HcFileField.propTypes = {
  name: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,

  label: PropTypes.string,
  buttonLabel: PropTypes.string
};

export default HcFileField;
