import { connect } from 'react-redux';
import HC_CONSTANTS from 'HC_CONSTANTS';

import TrialExpiredModal from 'legacy/components/TrialExpiredModal';

import { organizationUsageCloseTrialExpiredModal } from 'actions/organization-usage';
import { browserOpen } from 'actions/browser';

import { getIsAdmin, getAdminEmailId } from 'selectors/account-details';
import { getIsTrialExpiredModalOpen } from 'selectors/organization-usage';

const { ACCOUNTS_URL, APPLICATION_NAME } = HC_CONSTANTS;
const mapStateToProps = (state) => {
  return {
    isOpen: getIsTrialExpiredModalOpen(state),
    isAdmin: getIsAdmin(state),
    adminEmailId: getAdminEmailId(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleClose: () => dispatch(organizationUsageCloseTrialExpiredModal()),
  handleViewPlans: () =>
    dispatch(
      browserOpen(
        `${ACCOUNTS_URL}/select-plan?application_name=${APPLICATION_NAME}`
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(TrialExpiredModal);
