import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Dropdown as CLDropdown } from 'legacy/components/Dropdown';

import { isObject } from 'legacy/utils/utils';

const Dropdown = ({ input, options, ...props }) => {
  return (
    <CLDropdown
      // Supports a simple array of strings or collection of value, label objects
      source={
        isObject(options[0])
          ? options
          : options.map((o) => ({
              label: o,
              value: o
            }))
      }
      {...input}
      {...props}
    />
  );
};

const HcSelectField = (props) => {
  return <Field component={Dropdown} {...props} />;
};

HcSelectField.defaultProps = {};

// NOTE: Values can be an array of objects ({ value: 1, label: 'One' })
//       or a simple value array
HcSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default HcSelectField;
