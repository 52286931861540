import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import {
  getPreviousView,
  getPreviousParams,
  getCurrentParams,
  getPreviousQuery,
  getCurrentQuery
} from '@hc/redux-saga-router-plus/hclib/selectors';

import { STATUSES } from 'legacy/appstore/constants';
import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';
import { VR_REDIRECT_VIEW_MOD } from 'legacy/routes/constants';

import {
  apiHasNotBeenCalled,
  isLoading,
  isLoadedInitial,
  isLoadedInitialOptional,
  isLoaded
} from 'legacy/utils/status';

export const getReportStatusState = (state) => state.reportStatus;

// SAVE CYCLE
export const getReportStatusSaveInProgress = createSelector(
  getReportStatusState,
  (reportStatusState) => reportStatusState.saveInProgress
);

export const getReportStatusSaveIsQueued = createSelector(
  getReportStatusState,
  (reportStatusState) => reportStatusState.saveIsQueued
);

// SUBJECT DETAILS
export const getReportStatusSubjectDetails = createSelector(
  getReportStatusState,
  (reportStatusState) => reportStatusState.subjectDetails
);

export const getReportStatusSubjectDetailsIsLoadedInitial = createSelector(
  getReportStatusSubjectDetails,
  isLoadedInitial
);

// HC VERSION
export const getReportStatusHcVersion = createSelector(
  getReportStatusState,
  (reportStatusState) => reportStatusState.hcVersion
);

export const getReportStatusHcVersionIsLoadingInitial = createSelector(
  getReportStatusHcVersion,
  isLoading
);

export const getReportStatusHcVersionIsLoadedInitial = createSelector(
  getReportStatusHcVersion,
  isLoadedInitial
);

// USER VERSION
export const getReportStatusUserVersion = createSelector(
  getReportStatusState,
  (reportStatusState) => reportStatusState.userVersion
);

export const getReportStatusUserVersionIsLoadingInitial = createSelector(
  getReportStatusUserVersion,
  isLoading
);

export const getReportStatusUserVersionIsLoadedInitial = createSelector(
  getReportStatusUserVersion,
  isLoadedInitialOptional
);

export const getReportStatusUserVersionIsLoadedInitialAndExists =
  createSelector(getReportStatusUserVersion, isLoadedInitial);

export const getReportStatusUserVersionDoesNotExist = createSelector(
  getReportStatusUserVersion,
  (status) => status === STATUSES.DOES_NOT_EXIST
);

export const getReportStatusIsRecomputing = createSelector(
  getReportStatusUserVersion,
  (status) => status === STATUSES.UPDATING
);

export const getReportStatusIsReadyForSave = createSelector(
  getReportStatusHcVersionIsLoadedInitial,
  getReportStatusUserVersionIsLoadedInitial,
  (hcVersionLoaded, userVersionLoaded) => hcVersionLoaded && userVersionLoaded
);

// COMPS
export const getReportStatusComps = createSelector(
  getReportStatusState,
  (reportStatusState) => reportStatusState.comps
);

export const getReportStatusCompsIsLoadedInitial = createSelector(
  getReportStatusComps,
  isLoadedInitial
);

export const getReportStatusCompsIsLoaded = createSelector(
  getReportStatusComps,
  isLoaded
);

export const getReportStatusCompsIsError = createSelector(
  getReportStatusComps,
  (status) => status === STATUSES.ERROR
);

// FORECAST
export const getReportStatusForecast = createSelector(
  getReportStatusState,
  (reportStatusState) => reportStatusState.forecast
);

export const getReportStatusForecastIsLoadedInitial = createSelector(
  getReportStatusForecast,
  isLoadedInitialOptional
);

// RENTAL REPORT
export const getReportStatusRentalReport = createSelector(
  getReportStatusState,
  (reportStatusState) => reportStatusState.rentalReport
);

export const getReportStatusRentalReportIsLoadedInitial = createSelector(
  getReportStatusRentalReport,
  isLoadedInitialOptional
);

export const getReportStatusRentalReportIsLoadingInitial = createSelector(
  getReportStatusRentalReport,
  isLoading
);

// RENTAL COMPS
export const getReportStatusRentalComps = createSelector(
  getReportStatusState,
  (reportStatusState) => reportStatusState.rentalComps
);

export const getReportStatusRentalCompsIsLoaded = createSelector(
  getReportStatusRentalComps,
  isLoaded
);

export const getReportStatusRentalCompsIsLoadedInitial = createSelector(
  getReportStatusRentalComps,
  isLoadedInitial
);

// SHARED REPORT CONFIG
export const getReportStatusSharedReportConfig = createSelector(
  getReportStatusState,
  (reportStatusState) => reportStatusState.sharedReportConfig
);

export const getReportStatusSharedReportConfigIsLoadedInitial = createSelector(
  getReportStatusSharedReportConfig,
  isLoadedInitialOptional
);

export const getReportStatusSharedReportConfigIsUpdating = createSelector(
  getReportStatusSharedReportConfig,
  (sharedReportConfigStatus) => sharedReportConfigStatus === STATUSES.UPDATING
);

export const getReportStatusSharedReportConfigDoesNotExist = createSelector(
  getReportStatusSharedReportConfig,
  (sharedReportConfigStatus) =>
    sharedReportConfigStatus === STATUSES.DOES_NOT_EXIST
);

// NEARBY PROPERTIES
export const getReportStatusNearbyProperties = createSelector(
  getReportStatusState,
  (reportStatusState) => reportStatusState.nearbyProperties
);

export const getReportStatusNearbyPropertiesIsLoadingInitial = createSelector(
  getReportStatusNearbyProperties,
  isLoading
);

export const getReportStatusNearbyPropertiesIsLoadedInitial = createSelector(
  getReportStatusNearbyProperties,
  isLoadedInitialOptional
);

// OTHER
export const getReportStatusIsNewSubject = createSelector(
  getReportStatusHcVersion,
  getPreviousView,
  getCurrentParams,
  getPreviousParams,
  getCurrentQuery,
  getPreviousQuery,
  (
    statusHcVersion,
    previousView,
    currentParams,
    previousParams,
    currentQuery,
    previousQuery
  ) => {
    const compareParams = [
      'streetAddress',
      'city',
      'lat',
      'lon',
      'state',
      'zipcode',
      'unit'
    ];
    const current = { ...currentParams, ...currentQuery };
    const previous = { ...previousParams, ...previousQuery };
    // consider it a new subject if redirected from an old vr url
    return (
      apiHasNotBeenCalled(statusHcVersion) ||
      previousView.indexOf(VR_REDIRECT_VIEW_MOD) > -1 ||
      !isEqual(pick(current, compareParams), pick(previous, compareParams))
    );
  }
);

// TODO: This selector should be replaced
export const getReportStatusInitialLoadComplete = createSelector(
  getReportStatusHcVersionIsLoadedInitial,
  getReportStatusUserVersionIsLoadedInitial,
  (statusHc, statusUser) => statusUser && statusHc
);

// TODO: This selector needs to know about all apis
export const getReportStatusIsLoadedByApi = createSelector(
  getReportStatusInitialLoadComplete,
  (reportLoaded, taxLoaded, hoaLoaded, mlsLoaded, prLoaded) => {
    return {
      [PROPERTY_DATA_SOURCES.HC]: {
        [QUERIES.REPORT]: reportLoaded,
        [QUERIES.TAX_HISTORY]: taxLoaded,
        [QUERIES.LISTING_DETAILS]: hoaLoaded
      },
      [PROPERTY_DATA_SOURCES.MLS]: {
        [QUERIES.REPORT]: mlsLoaded,
        [QUERIES.TAX_HISTORY]: mlsLoaded,
        [QUERIES.LISTING_DETAILS]: mlsLoaded
      },
      [PROPERTY_DATA_SOURCES.PR]: {
        [QUERIES.REPORT]: prLoaded,
        [QUERIES.TAX_HISTORY]: prLoaded,
        [QUERIES.LISTING_DETAILS]: prLoaded
      }
    };
  }
);

export const getReportStatusEffectiveDateIsLoading = createSelector(
  getReportStatusState,
  (reportStatusState) => isLoading(reportStatusState.effectiveDate)
);
