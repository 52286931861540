// STATUSES
// Data has never been loaded
export const INIT = 'INIT';
// Action has been fired, request has not
export const PENDING = 'PENDING';
// Data is currently Loading
export const LOADING = 'LOADING';
// Waiting for an update API call
export const UPDATING = 'UPDATING';
// Data is loaded successfully
export const SUCCESS = 'SUCCESS';
// 404 response from server
export const DOES_NOT_EXIST = 'DOES_NOT_EXIST';
// User PENDING
export const DISMISSED = 'DISMISSED';
// Resource has expired, normally a token
export const EXPIRED = 'EXPIRED';
// Error Occurred
export const ERROR = 'ERROR';
// Data carried over from another part of state
export const PARTIAL = 'PARTIAL';

export const STATUSES = {
  INIT,
  PENDING,
  LOADING,
  UPDATING,
  SUCCESS,
  DOES_NOT_EXIST,
  DISMISSED,
  ERROR,
  EXPIRED,
  PARTIAL
};

export const NEARBY_CHART_TYPE_TOGGLE_OPTIONS = [
  {
    label: 'Nearby Properties',
    value: 'nearby'
  },
  {
    label: 'Filtered Comparables',
    value: 'availableComps'
  }
];

export const VALUATION_CHART_TYPE_TOGGLE_OPTIONS = [
  {
    label: 'Similar Properties',
    value: 'nearby'
  },
  {
    label: 'Filtered Comparables',
    value: 'availableComps'
  }
];

// Property Explorer page sections - used for scrolling within page
export const REPORT_SECTION_EDIT_PROP_DETAILS = 'EDIT_PROP_DETAILS';
export const REPORT_SECTION_RENTAL = 'REPORT_SECTION_RENTAL';

export const EDIT_ONLY_TOAST_MSG = 'Report updated but not saved';

// avms
export const AVM_KEY_HC = 'avm';
export const AVM_KEY_USER = 'adjustedAvm';
export const AVM_KEY_COMPS = 'compBasedAvm';
export const AVM_KEY_COMPS_AVG = 'adjustedCompBasedAvg';
export const AVM_KEY_USER_ENTERED = 'userValue';
export const AVM_KEY_RENTAL_HC = 'rentalAvm';
export const AVM_KEY_RENTAL_ADJUSTED = 'rentalAvmAdjusted';
export const AVM_KEY_RENTAL_COMPS = 'rentalCompAvm';
export const AVM_KEY_RENTAL_COMPS_AVG = 'adjustedRentalCompBasedAvg';
export const AVM_KEY_RENTAL_USER_ENTERED = 'userRentalValue';

export const LABEL_FOR_AVM_KEY = {
  [AVM_KEY_HC]: 'HouseCanary Value',
  [AVM_KEY_USER]: 'Adjusted Value',
  [AVM_KEY_COMPS]: 'Comparable Value',
  [AVM_KEY_COMPS_AVG]: 'Comparable Average',
  [AVM_KEY_USER_ENTERED]: 'User Opinion of Price',
  [AVM_KEY_RENTAL_HC]: 'HouseCanary Rental Value',
  [AVM_KEY_RENTAL_ADJUSTED]: 'Adjusted Rental Value',
  [AVM_KEY_RENTAL_COMPS]: 'Rental Comparable Value',
  [AVM_KEY_RENTAL_COMPS_AVG]: 'Rental Comparable Average',
  [AVM_KEY_RENTAL_USER_ENTERED]: 'User Opinion of Rental Price'
};

export const LABEL_FOR_AVM_KEY_MOBILE = {
  [AVM_KEY_HC]: 'HouseCanary Value',
  [AVM_KEY_USER]: 'Adjusted Value',
  [AVM_KEY_COMPS]: 'Comp. Value',
  [AVM_KEY_COMPS_AVG]: 'Comp. Average',
  [AVM_KEY_USER_ENTERED]: 'User Opinion of Price',
  [AVM_KEY_RENTAL_HC]: 'HouseCanary Rental Value',
  [AVM_KEY_RENTAL_ADJUSTED]: 'Adjusted Rental Value',
  [AVM_KEY_RENTAL_COMPS]: 'Comp. Rental Value',
  [AVM_KEY_RENTAL_COMPS_AVG]: 'Rental Comp. Average',
  [AVM_KEY_RENTAL_USER_ENTERED]: 'User Opinion of Rental Price'
};

export const AVM_TYPE_DESCRIPTION = {
  [AVM_KEY_HC]: 'HouseCanary’s Estimated Value',
  [AVM_KEY_USER]:
    'The value with your adjustments to condition and property details',
  [AVM_KEY_COMPS]:
    "Adjusted average of comparables' HouseCanary Estimated Value",
  [AVM_KEY_COMPS_AVG]:
    "Average of all the selected comparables (based on sales or most recent list price) plus the user's price adjustment for each comparable.",
  [AVM_KEY_USER_ENTERED]: "The report owner's opinion of the value.",
  [AVM_KEY_RENTAL_HC]: "HouseCanary's Estimated Rental Value",
  [AVM_KEY_RENTAL_ADJUSTED]:
    'The rental value with your adjustments to condition and property details',
  [AVM_KEY_RENTAL_COMPS]: "Adjusted average of comparables' rental value.",
  [AVM_KEY_RENTAL_COMPS_AVG]:
    "Average of all the selected comparables (based on leased or most recent rental list price) plus the user's price adjustment for each comparable.",
  [AVM_KEY_RENTAL_USER_ENTERED]:
    "The report owner's opinion of the rental value."
};

export const AVM_TYPE_ALT_TEXT = {
  [AVM_KEY_COMPS]:
    'The adjusted average HouseCanary Estimated Value of the comparables you select.',
  [AVM_KEY_COMPS_AVG]: AVM_TYPE_DESCRIPTION[AVM_KEY_COMPS_AVG],
  [AVM_KEY_USER]:
    'The HouseCanary value including your adjustments to condition and property details. Click below to make adjustments.',
  [AVM_KEY_RENTAL_COMPS]:
    'The adjusted average rental value of the comparables you select.',
  [AVM_KEY_RENTAL_COMPS_AVG]: AVM_TYPE_DESCRIPTION[AVM_KEY_RENTAL_COMPS_AVG]
};

export const TIMELINE_SUBJECT_DETAILS_MISSING_MSG =
  'We could not find a listing for the subject for the effective date. The subject property details are as of today.';

// subject
export const SUBJECT_TYPE_HC = 'default';
export const SUBJECT_TYPE_USER = 'adjusted';

export const COMP_ID_KEY = 'addressId';

// errors
export const ERROR_ACTION_CLOSE = 'CLOSE';
export const ERROR_ACTION_RELOAD = 'RELOAD';

// error text
export const ERROR_TEXT = {
  UNKNOWN: `We're sorry, we are experiencing issues at this time.  Please refresh the page to continue.`,
  ADDRESS_SEARCH: {
    addressNotSupported:
      'Sorry, we could not find that. Please try again and ensure it is a residential address.',
    addressNotSupplied: 'Please enter an address.',
    unknownError:
      "We're sorry, an unknown error has occurred. Please try again later."
  },
  VALUE_REPORT: {
    notFound: `We're sorry, we can't find the report you're looking for.`,
    notAuthorized:
      'You need to add addtional reports to your plan in order to access this page.',
    refreshRequired: `Whoops, we got our wires crossed.  Please refresh the page to view your report.`,
    editDisallowed:
      "We're sorry, editing this report has been disallowed. Please contact the report owner to enable this permission.",
    expiredVersion:
      'Looks like someone else is contributing to this report. Refresh to see what’s new!'
  }
};

export const COMP_TYPE_RENTAL = 'RENTAL';
export const COMP_TYPE_DEFAULT = 'COMP';

export const VALUE_TYPE_RENTAL = 'RENTAL';
export const VALUE_TYPE_DEFAULT = 'DEFAULT';

export const CHART_DATA_TYPES = {
  BLOCK_TS: 'blockTs',
  ZIP_TS: 'zipTs',
  SUBJECT_SALES: 'subjectSales'
};

export const CONFIDENCE_LEVEL_HIGH = 'high';
export const CONFIDENCE_LEVEL_MED = 'average';
export const CONFIDENCE_LEVEL_LOW = 'low';

export const COMP_VALUE_TOOLTIP_TEXT = {
  [AVM_KEY_COMPS]:
    'The “Comparable Value” is an average of the adjusted HouseCanary Value of your selected comparables. We automatically adjust each comparable property’s current HouseCanary Value by including a number of factors, such as locational differences, square feet, bedrooms, bathrooms, lot-size, pools, basements, and many other possible fields.',
  [AVM_KEY_RENTAL_COMPS]:
    'We calculate the rental comparable value by averaging the rental value of selected rental comparables’. Our HouseCanary rental values account for recent and past market shifts including time, locational differences, and many other factors.',
  [AVM_KEY_COMPS_AVG]: AVM_TYPE_DESCRIPTION[AVM_KEY_COMPS_AVG],
  [AVM_KEY_RENTAL_COMPS_AVG]: AVM_TYPE_DESCRIPTION[AVM_KEY_RENTAL_COMPS_AVG]
};

export const MAP_CONTROL_TYPE_HEATMAP = 'heatmap';
export const MAP_CONTROL_TYPE_DRAW = 'draw';

export const RENTAL_COMP_EXCEL_LIMIT = 50;

export const NULL_STATE_WARNINGS = {
  similarHomesChart: {
    title: 'Similar Homes Unavailable',
    message:
      'Sorry! There aren’t enough nearby similar homes to generate this chart - but our data improves everyday. Please check again later!'
  },
  locationUnknownSearchMap: {
    title: "Looks like we can't geolocate this property",
    message: 'View the report to manually update the location.'
  },
  locationUnknownLocationMap: {
    title: "Looks like we can't geolocate this property",
    message:
      "Please update the subject's location to access more accurate comparables."
  },
  locationUnknown: {
    title: "Looks like we can't geolocate this property",
    message: 'Please draw a comp area to access more accurate comps.'
  },
  rentalReport: {
    title: 'HouseCanary Rental Details Unavailable',
    message:
      'Sorry! We don’t have the details about this property that would allow us to generate a rental value or rental comps - but our data improves everyday. Please check again later!'
  },
  limitedReport: {
    title: 'Limited Report',
    message:
      'We don’t have sufficient data about this home to provide a HouseCanary value. You can still add more information about the home or select comps in the Property Explorer.'
  },
  transactionHistory: {
    title: 'Transaction History',
    message:
      "Looks like we don't have sufficient data to display the history for this property right now."
  },
  noAvmHc: {
    title: 'HouseCanary Value is Unavailable',
    message:
      'HouseCanary data unavailable for this property at this time. We are working to improve our data each day. Check back later.'
  },
  noAvmAdjusted: {
    title: 'Adjusted Value is Unavailable',
    message:
      'HouseCanary data unavailable for this property at this time. We are working to improve our data each day. Check back later.'
  },
  noAvmRental: {
    title: 'HouseCanary Rental Value Unavailable',
    message:
      'Sorry! There isn’t enough information about this home to generate a reliable rental value - but our data improves everyday. Please check again later!'
  },
  rentalComps: {
    title: 'Rental Comps Unavailable',
    message:
      'Sorry! There aren’t enough nearby homes that match this property to generate a list of rental comps - but our data improves everyday. Please check again later!'
  },
  comps: {
    title: 'Comps Unavailable',
    message:
      'Sorry! There aren’t enough nearby homes that match this property to generate a list of comps - but our data improves everyday. Please check again later!'
  },
  nearbyProperties: {
    title: 'Nearby Properties Unavailable',
    message:
      'Sorry! There aren’t enough nearby homes to generate this chart - but our data improves everyday. Please check again later!'
  },
  marketCharts: {
    title: 'Market Charts Unavailable',
    message:
      'Sorry! There is not enough data to generate these charts - but our data improves everyday. Please check again later!'
  },
  marketIndexChart: {
    title: 'Market Index Chart Unavailable',
    message:
      'Sorry! There is not enough data to generate this chart - but our data improves everyday. Please check again later!'
  },
  daysOnMarketChart: {
    title: 'Days on Market Chart Unavailable',
    message:
      'Sorry! There is not enough data to generate this chart - but our data improves everyday. Please check again later!'
  },
  monthsOfSupplyChart: {
    title: 'Months of Supply Chart Unavailable',
    message:
      'Sorry! There is not enough data to generate this chart - but our data improves everyday. Please check again later!'
  },
  riskOfDeclineChart: {
    title: 'Risk of Decline Unavailable',
    message:
      'Sorry! There is not enough data to generate this chart - but our data improves everyday. Please check again later!'
  },
  forecastSection: {
    title: 'Forecast Unavailable',
    message:
      'Sorry! There is not enough data to generate the forecast - but our data improves everyday. Please check again later!'
  },
  forecastChartZip: {
    title: 'Zip-Code-level Forecast Unavailable',
    message:
      'Sorry! There is not enough data to generate this chart - but our data improves everyday. Please check again later!'
  },
  forecastChartBlock: {
    title: 'Block-level Forecast Unavailable',
    message:
      'Sorry! There is not enough data to generate this chart - but our data improves everyday. Please check again later!'
  }
};

export const MARKET_INDEX_LABELS = {
  sSeller: "Strong Seller's",
  seller: "Seller's",
  neutral: 'Neutral',
  buyer: "Buyer's",
  sBuyer: "Strong Buyer's"
};

// Map IDs used for differentiating which map analytics events correspond to
export const MAP_ID_SEARCH = 'map-search';
export const MAP_ID_REPORT = 'map-report';
export const MAP_ID_LOCATION = 'map-location';
export const MAP_ID_COMPS = 'map-comps';
export const MAP_ID_RENTAL_COMPS = 'map-rental-comps';

export const MAP_ID_BY_COMP_TYPE = {
  [COMP_TYPE_DEFAULT]: MAP_ID_COMPS,
  [COMP_TYPE_RENTAL]: MAP_ID_RENTAL_COMPS
};

export const COMP_TYPE_BY_MAP_ID = {
  [MAP_ID_COMPS]: COMP_TYPE_DEFAULT,
  [MAP_ID_RENTAL_COMPS]: COMP_TYPE_RENTAL
};

// Used to help determine where to select property data from on state
export const PROPERTY_TYPE_SUBJECT = 'SUBJECT';
export const PROPERTY_TYPE_MLS_LISTING = 'MLS_LISTING';
export const PROPERTY_TYPE_SEARCH_RESULT = 'SEARCH_RESULT';

// ID of dom element for rendering fixed position portals
export const ID_PORTAL_FIXED = 'react-fixed-portal';

// Filter control type ids
const MULTI_SELECT = 'MULTI_SELECT';
const NUMBER_RANGE = 'NUMBER_RANGE';
const DATE = 'DATE';
const DISTANCE = 'DISTANCE';
const HORIZONTAL_SELECTOR = 'HORIZONTAL_SELECTOR';
export const FILTER_CONTROL_TYPES = {
  MULTI_SELECT,
  NUMBER_RANGE,
  DATE,
  DISTANCE,
  HORIZONTAL_SELECTOR
};

// Key to skip clear-reducer-on-actions
export const BYPASS_CLEAR_REDUCER = 'bypassClearReducer';

// IDs for CompsList views
export const COMP_VIEW_CARD = 'card';
export const COMP_VIEW_TABLE = 'table';
export const COMP_VIEW_COMPACT = 'compact';
export const COMP_VIEW_COLUMNS = 'columns';
export const COMP_LIST_VIEW_TYPES = [
  COMP_VIEW_CARD,
  COMP_VIEW_TABLE,
  COMP_VIEW_COMPACT,
  COMP_VIEW_COLUMNS
];

// Constant to use when the applied filter value should be the value of the subject
export const FILTER_MATCH_SUBJECT = 'FILTER_MATCH_SUBJECT';
export const FILTER_SAVE_TYPES = {
  REL_PCT: 'REL_PERCENT', // Covert to % relative to subject
  REL_EXACT: 'REL_EXACT', // Exact values relative to subject
  EXACT: 'EXACT', // Matches filter value exactly
  MATCH_OR_EXACT: 'MATCH_OR_EXACT', // Matches subject if filter value == subject value when saved
  DATE_AGO: 'DATE_AGO', // Matches for x months from the current date
  DISTANCE: 'DISTANCE', // Special case for distance filters in order to handle custom shape errors,
  MATCH_RANGE: 'MATCH_RANGE', // Matches subject if subject value is within range, otherwise saves exact value,
  MATCH_BOOLEAN: 'MATCH_BOOLEAN' // Matches subject boolean value otherwise uses exact value
};

export const PREFERENCES_KEYS = {
  FILTERS_COMPS: 'filtersComps',
  FILTERS_RENTAL_COMPS: 'filtersRentalComps',
  TABLE_COLUMNS_COMPS: 'tableColsComps',
  TABLE_WIDTH_COMPS: 'tableWidthComps',
  TABLE_COLUMNS_RENTAL_COMPS: 'tableColsRentalComps',
  PHOTO_GRID: 'photoGrid',
  MAPS: 'maps',
  SKIP_WARNING_REFRESH_COMPS: 'skipWarningRefreshComps',
  SKIP_WARNING_REFRESH_RENTAL_COMPS: 'skipWarningRefreshRentalComps'
};

export const PROPERTY_DETAILS_TYPE_GENERAL = 'GENERAL';
export const PROPERTY_DETAILS_TYPE_SOURCES = 'SOURCES';

export const NAV_CONFIRM_MSG_SAVING =
  'Your report is still saving. Are you sure you want to leave?';

export const PHOTO_PLACEHOLDER_TEXT = 'Sorry -- no photo available';

// SECTION IDs
export const SECTION_ID_COMPS = 'comps';
export const SECTION_ID_RENTAL_REPORT = 'rentalReport';
export const SECTION_ID_FORECAST = 'forecast';
export const SECTION_ID_SHARED_REPORT_FORM = 'sharedReportForm';
export const SECTION_ID_NEARBY_PROPERTIES = 'nearbyProperties';

export const NUM_HC_SUGGESTED_COMPS = 8;

export const ID_PORTAL_LAYER_CONTROLS = 'portal-heatmap-layer-controls';
export const ID_PORTAL_MORE_FILTERS = 'portal-more-filters';

// Help text
export const HELP_TEXT_SIMILARITY =
  "HouseCanary's Similarity Score uses Data Points including bed, bath, GLA, and other property details along with distance and time to create a regression model for the subject property and nearby comparable properties. The location based model provides a numerical score for each possible comparable, ranked from 1-100 in similarity.";
export const HELP_TEXT_FLIP =
  'Property is a flip. This metric is calculated by HouseCanary and is determined by whether a property that has been sold has another status change within 12 months.';

// Mapbox tiles only render up to a zoom level of 18
export const MAPS_DEFAULT_ZOOM_MAX = 18;
export const MAPS_DEFAULT_ZOOM_MULT = 2.75;

export const SIMILARITY_LEVEL_RANGES = {
  high: [85, 100],
  moderate: [75, 84],
  low: [0, 74]
};

export const QUALITY_KEYS = {
  CON1: 'CON1',
  CON2: 'CON2',
  CON3: 'CON3',
  CON4: 'CON4',
  CON5: 'CON5',
  CON6: 'CON6'
};

export const GARAGE_FILTER_OPTIONS = {
  noGarage: [null, 0],
  hasGarage: [1, null],
  null: [null, null]
};

export const STORIES_FILTER_OPTIONS = {
  singleStory: [1, 1],
  multiStory: [2, null],
  null: [null, null]
};

export const QUALITY_DESCRIPTIONS = {
  [QUALITY_KEYS.CON6]: {
    description:
      'The improvements have substantial damage or deferred maintenance. Deficiencies or defects are severe enough to affect the safety, soundness, or structural integrity of the improvements. The improvements are in need of substantial repairs and rehabilitation, including many or most major components.',
    source: 'Fannie Mae, HouseCanary'
  },
  [QUALITY_KEYS.CON5]: {
    description:
      'The improvements feature obvious deferred maintenance and are in need of some significant repairs. Some building components need repairs, rehabilitation, or updating. The functional utility and overall livability are somewhat diminished due to condition, but the dwelling remains usable and functional as a residence.',
    source: 'Fannie Mae, HouseCanary'
  },
  [QUALITY_KEYS.CON4]: {
    description:
      'The improvements feature some minor deferred maintenance and physical deterioration due to normal wear and tear. The dwelling has been sufficiently maintained and requires only minimal repairs to building components/mechanical systems and cosmetic repairs. All major building components have been adequately maintained and are functionally adequate. Materials and finishes are basic.',
    source: 'Fannie Mae, HouseCanary'
  },
  [QUALITY_KEYS.CON3]: {
    description:
      'The improvements are well-maintained and feature limited physical depreciation due to normal wear and tear. Some components, but not every major building component, may be updated or recently rehabilitated. The structure has been well-maintained. Any outdated materials and finishes have been upgraded to meet current standards.',
    source: 'Fannie Mae, HouseCanary'
  },
  [QUALITY_KEYS.CON2]: {
    description:
      'Improvements show little physical depreciation and require no repairs. Virtually all building components are new or have been recently repaired, refinished, or rehabilitated. Materials, finishes, and appliances are updated and high end.',
    source: 'Fannie Mae, HouseCanary'
  },
  [QUALITY_KEYS.CON1]: {
    description:
      'The entire structure and all components are pristine. The dwelling does not exhibit any physical depreciation. Materials, finishes, and appliances are like-new and high end.',
    source: 'Fannie Mae, HouseCanary'
  }
};

// Maxiumum number of saved comp filter sets (arbitrary limit for UX)
export const MAX_SAVED_FILTER_SETS = 10;
