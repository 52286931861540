import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import {
  KEYWORD_SEARCH_SUBMIT,
  KeywordSearchSubmitAction,
  keywordSearchSubmitError,
  keywordSearchSubmitSuccess
} from './actions';
import { KeywordSearchResult } from './types';
// TODO: REMOVE THIS IMPORT
// @ts-ignore: Importing from untyped legacy directory
import { getToken } from 'selectors/account-details';
import { KeywordSearch } from './api';

function* handleKeywordSearchSubmit(action: KeywordSearchSubmitAction) {
  const { inputs } = action.payload;
  try {
    const token: string = yield select(getToken);
    const response: AxiosResponse<KeywordSearchResult[]> = yield call(
      KeywordSearch.fetchKeywordsByListing,
      token,
      inputs
    );
    yield put(keywordSearchSubmitSuccess(inputs, response.data));
  } catch (e) {
    yield put(keywordSearchSubmitError(inputs));
  }
}
export function* keywordSearchSagas() {
  yield takeLatest(KEYWORD_SEARCH_SUBMIT, handleKeywordSearchSubmit);
}
