import {
  STREET_VIEW_METADATA_FETCH,
  STREET_VIEW_METADATA_FETCH_SUCCESS,
  STREET_VIEW_METADATA_FETCH_ERROR,
  StreetViewActions
} from '../actions/streetView.actions';
import { STATUSES } from '../../../constants';
import { GeoLocation } from 'src/lib/property-lookup/types';
import { createStreetViewId } from 'src/lib/street-view/utils/streetView.utils';
import { StreetViewMetadataResponse } from '../types';

interface StreetViewStateForGeoLocationLoading {
  geoLocation: GeoLocation;
  status: STATUSES.LOADING;
}

interface StreetViewStateForGeoLocationSuccess {
  geoLocation: GeoLocation;
  status: STATUSES.SUCCESS;
  data: StreetViewMetadataResponse;
}

interface StreetViewStateForGeoLocationError {
  geoLocation: GeoLocation;
  status: STATUSES.ERROR;
}

export type StreetViewStateForGeoLocation =
  | StreetViewStateForGeoLocationLoading
  | StreetViewStateForGeoLocationSuccess
  | StreetViewStateForGeoLocationError;

export type StreetViewState = Record<string, StreetViewStateForGeoLocation>;

const INITIAL_STATE = {} as StreetViewState;

export const streetViewReducer = (
  state: StreetViewState = INITIAL_STATE,
  action: StreetViewActions
): StreetViewState => {
  switch (action.type) {
    case STREET_VIEW_METADATA_FETCH: {
      const { geoLocation } = action.payload;
      return {
        ...state,
        [createStreetViewId(geoLocation)]: {
          geoLocation,
          status: STATUSES.LOADING
        }
      };
    }
    case STREET_VIEW_METADATA_FETCH_SUCCESS: {
      const { geoLocation, data } = action.payload;
      return {
        ...state,
        [createStreetViewId(geoLocation)]: {
          geoLocation,
          data,
          status: STATUSES.SUCCESS
        }
      };
    }
    case STREET_VIEW_METADATA_FETCH_ERROR: {
      const { geoLocation } = action.payload;
      return {
        ...state,
        [createStreetViewId(geoLocation)]: {
          geoLocation,
          status: STATUSES.ERROR
        }
      };
    }
    default:
      return state;
  }
};
