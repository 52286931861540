import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LoginForm } from '@hc/authentication-lib/hclib/components';

import { STATUSES } from 'legacy/appstore/constants';
import { Login as theme } from 'legacy/css-modules';

import { clickInterceptHoc } from '@hc/redux-saga-router-plus/hclib/react';

import history from 'legacy/routes/history';

const ClickInterceptLoginForm = clickInterceptHoc(LoginForm, history);

const Login = (props) => {
  const { status, error, handleLogin, insideModal } = props;

  return (
    <div
      className={classNames(theme['login-container'], {
        [theme['login-container--inside-modal']]: insideModal
      })}
    >
      <ClickInterceptLoginForm
        isLoading={status === STATUSES.LOADING}
        errorMessage={error}
        onLogin={handleLogin}
        forgotPasswordUrl="/forgot-password"
        theme={theme}
      />
    </div>
  );
};

Login.propTypes = {
  /** Whether this form is being rendered inside of a modal */
  insideModal: PropTypes.bool
};

export default Login;
