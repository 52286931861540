import { useEffect } from 'react';

export const useWindowResize = (callback: VoidFunction) => {
  useEffect(() => {
    window.addEventListener('resize', callback);
    return () => {
      window.removeEventListener('resize', callback);
    };
  }, [callback]);
};
