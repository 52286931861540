import { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { Field } from 'redux-form';

import {
  formatCurrencyFieldFactory,
  normalizeCurrencyFieldFactory,
  cursorPositionOnChange
} from 'legacy/utils/forms';

import fieldWithPristineError from 'legacy/hocs/fieldWithPristineError';
import formFieldCursorPosition from 'legacy/hocs/formFieldCursorPosition';

import TextInputPropertyDetails from 'legacy/components/TextInputPropertyDetails';

const RTInput = ({ input, meta, ...props }) => {
  return <TextInputPropertyDetails {...input} {...props} />;
};

const TextFieldWithPristineError = fieldWithPristineError(
  formFieldCursorPosition(RTInput)
);
const TextFieldWithoutPristineError = formFieldCursorPosition(RTInput);

class CurrencyField extends Component {
  lastVal = null;
  lastValFormatted = null;
  input;
  newSelection; // Used for repositioning the cursor after a format update

  constructor() {
    super();
    this.onChange = cursorPositionOnChange.bind(this);
  }
  componentDidMount() {
    this.input = findDOMNode(this.inputRef).getElementsByTagName('input')[0];
  }

  format = () => {
    const { dec = 1, format } = this.props;
    const formatter = formatCurrencyFieldFactory(dec, format);
    return formatter.bind(this);
  };

  normalize = () => {
    const { normalize, min, max } = this.props;
    const normalizer = normalizeCurrencyFieldFactory(normalize, min, max);
    return normalizer.bind(this);
  };

  render() {
    const { withPristineError, format, normalize, min, max, ...props } =
      this.props;
    return (
      <Field
        component={
          withPristineError
            ? TextFieldWithPristineError
            : TextFieldWithoutPristineError
        }
        format={this.format()}
        normalize={this.normalize()}
        ref={(input) => {
          this.inputRef = input;
        }}
        maxLength={12}
        newSelection={this.newSelection}
        inputNode={this.input}
        {...props}
        onChange={this.onChange}
      />
    );
  }
}

CurrencyField.defaultProps = {
  type: 'text'
};

CurrencyField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  withPristineError: PropTypes.bool
};

export default CurrencyField;
