import { watchLatest } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { put, select } from 'redux-saga/effects';

import {
  SUBJECT_TYPE_USER,
  COMP_ID_KEY,
  AVM_KEY_RENTAL_COMPS,
  AVM_KEY_RENTAL_COMPS_AVG
} from 'legacy/appstore/constants';

import {
  RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE,
  RENTAL_COMPS_SELECT_TOGGLE,
  RENTAL_COMPS_UNSELECT_ALL,
  RENTAL_COMPS_ADJUST_SAVE
} from 'actions/rental-comps';
import { vrRecompute } from 'actions/edit-report';
import {
  avmsRentalCompValueRecomputeSuccess,
  AVMS_RENTAL_COMP_VALUE_RECOMPUTE
} from 'actions/avms';
import { toastShow } from 'actions/toast';

import {
  calcRentalCompAvm,
  calcRentalCompAvg,
  getRentalCompsSelectedIds
} from 'selectors/rental-comps';
import { getIsReportEditable } from 'selectors/report-permissions';
import { getFeatureFlagsData } from 'selectors/feature-flags';
function* recomputeCompAvm(action) {
  try {
    let recompute = true;
    if (action.type === RENTAL_COMPS_SELECT_TOGGLE) {
      const selectedCompIds = yield select(getRentalCompsSelectedIds);
      const { comp } = action.payload;
      const featureFlags = yield select(getFeatureFlagsData);
      // Show toast if selected comp limit reached
      if (
        selectedCompIds.length === featureFlags['increased-comp-limit'] &&
        selectedCompIds.indexOf(comp[COMP_ID_KEY]) === -1
      ) {
        yield put(
          toastShow(
            `You have already reached the maximum of ${featureFlags['increased-comp-limit']} selected comps`
          )
        );
        // No need to recompute comp avm
        recompute = false;
      }
    }
    if (recompute) {
      // Comp AVM is always based on the user-adjusted property details
      const avmRentalComps = yield select(calcRentalCompAvm(SUBJECT_TYPE_USER));
      const avmRentalCompsAvg = yield select(
        calcRentalCompAvg(SUBJECT_TYPE_USER)
      );
      yield put(
        avmsRentalCompValueRecomputeSuccess({
          [AVM_KEY_RENTAL_COMPS]: avmRentalComps,
          [AVM_KEY_RENTAL_COMPS_AVG]: avmRentalCompsAvg
        })
      );
      const isReportEditable = yield select(getIsReportEditable);
      if (isReportEditable) {
        yield put(vrRecompute({}, action.payload.recomputeHints));
      }
    }
  } catch (e) {
    console.error(e);
    report(`Recompute Rental Comp Avm: ${e.message}`, { e, action });
  }
}

export default function registerRecomputeRentalCompAvmSaga() {
  watchLatest(
    [
      AVMS_RENTAL_COMP_VALUE_RECOMPUTE,
      RENTAL_COMPS_ADJUST_SAVE,
      RENTAL_COMPS_SELECT_TOGGLE,
      RENTAL_COMPS_UNSELECT_ALL,
      RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE
    ],
    recomputeCompAvm
  );
}
