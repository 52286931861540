import moment from 'moment';
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getIsReportActive } from 'selectors/report-permissions';
import { getDateReportExpired } from 'selectors/report-data';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import { getEffectiveDateMetadata } from 'selectors/effective-date';
import { displayDateCompactFullYear } from 'legacy/utils/dates';
import { EffectiveDateWarning as styles } from 'legacy/css-modules';

export const ExpiredReportDate = ({
  dataHcName = 'expired-report-date',
  className
}) => {
  const isActive = useSelector(getIsReportActive);
  const dateExpired = useSelector(getDateReportExpired);
  const isEffectiveDateReport = useSelector(getIsEffectiveDateReport);
  const effectiveDateMetadata = useSelector(getEffectiveDateMetadata);
  if (isActive) return null;
  const date = isEffectiveDateReport
    ? moment(effectiveDateMetadata.createdAt).add(30, 'days')
    : dateExpired;
  return (
    <div
      className={classNames(styles.EffectiveDateWarning, className)}
      data-hc-name={dataHcName}
    >
      <label data-hc-name={`${dataHcName}-label`} className={styles.Title}>
        Archived Report:
      </label>
      <span data-hc-name={`${dataHcName}-value`} className={styles.Value}>
        {displayDateCompactFullYear(date)}
      </span>
    </div>
  );
};
