import { EVENTS } from 'legacy/appstore/events';
import { COMP_TYPE_DEFAULT } from 'legacy/appstore/constants';

export const PREFERENCES_FETCH = 'PREFERENCES_FETCH';
export const PREFERENCES_FETCH_SUCCESS = 'PREFERENCES_FETCH_SUCCESS';
export const PREFERENCES_FETCH_FAILURE = 'PREFERENCES_FETCH_FAILURE';
export const PREFERENCES_SAVE = 'PREFERENCES_SAVE';
export const PREFERENCES_SAVE_SUCCESS = 'PREFERENCES_SAVE_SUCCESS';
export const PREFERENCES_SAVE_FAILURE = 'PREFERENCES_SAVE_FAILURE';

export const PREFERENCES_SAVE_FILTERS_MODAL_TOGGLE =
  'PREFERENCES_SAVE_FILTERS_MODAL_TOGGLE';
export const PREFERENCES_COMP_TABLE_OPTIONS_MODAL_TOGGLE =
  'PREFERENCES_COMP_TABLE_OPTIONS_MODAL_TOGGLE';

export const preferencesFetch = () => ({
  type: PREFERENCES_FETCH
});

export const preferencesFetchSuccess = (values) => ({
  type: PREFERENCES_FETCH_SUCCESS,
  payload: { values }
});

export const preferencesFetchFailure = (errorCode) => ({
  type: PREFERENCES_FETCH_FAILURE,
  payload: { errorCode }
});

export const preferencesSave = (values, noToast) => ({
  type: PREFERENCES_SAVE,
  payload: { values, noToast }
});

export const preferencesSaveSuccess = (values, noToast) => ({
  type: PREFERENCES_SAVE_SUCCESS,
  payload: { values },
  meta: {
    toast: !noToast && {
      message: 'Preferences Updated Successfully',
      dataHcName: 'save-preferences-toast'
    }
  }
});

export const preferencesSaveFailure = (errorCode) => ({
  type: PREFERENCES_SAVE_FAILURE,
  payload: { errorCode }
});

export const preferencesSaveFiltersModalToggle = (modalType, compType) => ({
  type: PREFERENCES_SAVE_FILTERS_MODAL_TOGGLE,
  payload: { modalType },
  meta: modalType &&
    compType && {
      analytics: {
        event:
          modalType === 'save'
            ? compType === COMP_TYPE_DEFAULT
              ? EVENTS.COMPS_SAVE_FITLERS_MODAL_OPEN
              : EVENTS.RENTAL_COMPS_SAVE_FITLERS_MODAL_OPEN
            : compType === COMP_TYPE_DEFAULT
            ? EVENTS.COMPS_CLEAR_SAVED_FITLERS_MODAL_OPEN
            : EVENTS.RENTAL_COMPS_CLEAR_SAVED_FITLERS_MODAL_OPEN
      }
    }
});

export const preferencesCompTableOptionsDialogToggle = (compType) => {
  return {
    type: PREFERENCES_COMP_TABLE_OPTIONS_MODAL_TOGGLE,
    payload: { compType }
  };
};
