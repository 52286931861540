export default {
  CONDITION: {
    title: 'Condition',
    content: [
      'Adjusting the Condition will allow you to adjust the value of a property based',
      'on the overall physical condition of the property.  Below are suggestions for selecting the',
      'property condition. The suggestions below were written to be in alignment with definitions',
      'used by appraisers completing a UAD compliant appraisal.'
    ]
  },
  EXCELLENT: {
    content: [
      "'Excellent' - This property has been very recently constructed and may not have previously",
      'been occupied. The entire structure and all components are new and the dwelling features',
      'no physical depreciation.'
    ]
  },
  GOOD: {
    content: [
      "'Good' - The property has little or no physical depreciation, requires no repairs, and is",
      'similar in condition to new construction. Virtually all building components are new or',
      'have been recently repaired, refinished, or rehabilitated. All outdated components and',
      'finishes have been updated and/or replaced with components that meet current standards.'
    ]
  },
  FAIR: {
    content: [
      "'Fair' - The property has been maintained and features physical depreciation due to normal",
      'wear and tear. Some components, but not every major building component, may be updated',
      'or recently rehabilitated. All major building components have been adequately maintained',
      'and are functionally adequate.'
    ]
  },
  SUBPAR: {
    content: [
      "'Subpar' - The property features obvious deferred maintenance and is in need of",
      'some significant repairs. Some building components require repairs, rehabilitation, or',
      'updating. The functional utility and overall livability are somewhat diminished due to',
      'condition, but the dwelling remains usable and functional as a residence.'
    ]
  },
  POOR: {
    content: [
      "'Poor' - The property has substantial damage or deferred maintenance with deficiencies",
      'or defects that are severe enough to affect the safety, soundness, or structural integrity ',
      'of the improvements. The improvements are in need of substantial repairs and',
      'rehabilitation, including many or most major components.'
    ],
    source: 'HouseCanary analysis, Fannie Mae UAD Definitions for Condition'
  },
  VALUE_FORECAST: {
    title: 'Value Forecast',
    content: [
      'Future Value of the subject property obtained by applying HouseCanary’s',
      'proprietary Zip-Code level price appreciation forecast to the current value estimate',
      'of the subject property.'
    ],
    source:
      'HouseCanary Automated Valuation Model, HouseCanary Home Price Index'
  },
  MONTHS_OF_SUPPLY: {
    title: 'Months of Supply',
    content: [
      'The months of supply is a metric to reflect the pace at which listing',
      'inventory is turning over in the local market. The calculation reflects the total listings',
      'on the  market divided by the 3-month rolling average of sales volume. Generally, less than 5',
      'months of supply is considered inflationary due to the constrained nature of listings',
      'available for sale. A value greater than 7 months of supply is typically considered',
      'oversupplied and deflationary.'
    ],
    source: 'Local MLS, HouseCanary analysis'
  },
  RISK_OF_DECLINE: {
    title: 'MSA 1yr risk of decline',
    content: [
      'The one year risk of decline is a proprietary HouseCanary metric that measures ',
      "the probability that this market's median home prices will be lower 12 months from now than",
      'the current market median price. This one-year chance of loss is derived through ',
      "HouseCanary's  multivariate time series models using a combination of fundamental and",
      'technical indicators.'
    ],
    source: 'Local MLS, HouseCanary analysis'
  },
  NEAR_BY_PROPERTIES: {
    title: 'Nearby Properties',
    content: [
      'All Properties and associated attributes closest to the subject property. This chart allows for comparison of the subject property attributes with nearby',
      'properties.'
    ],
    source: 'Public Record, HouseCanary analysis'
  },
  DAYS_ON_MARKET: {
    title: 'Days on Market',
    content: [
      'The current days on market is the average number of days since listing for all',
      'current listings on the market for the given geography. The calculation represents',
      'a 13-week rolling average to minimize rapid swings in the data.'
    ],
    source: 'Local MLS, HouseCanary analysis'
  },
  MARKET_INDEX: {
    title: 'Market Index',
    content: [
      'The market index is designed to measure supply versus demand at a local Zip-Code',
      'level. The index ranges from 0-100 where values of 41-60 indicate a market in equilibrium',
      '(neutral). Values above 61 indicate that demand exceeds supply, and that the local',
      "area is a seller's market. Values below 41 indicate that supply exceeds demand, and",
      "that the local area is a buyer's market. Demand is measured using indicators such as",
      'sales volume, changes in listing prices, and days on market. Supply is measured using',
      'indicators such as inventory and the number of new listings.'
    ],
    source: 'Local MLS, HouseCanary analysis'
  },
  MARKET_STATUS: {
    title: 'Market Status',
    content: [
      'The market status is the summary conclusion on the market index.',
      "Specifically whether the market is currently classified as a buyer's market, seller's",
      'market or neutral. For more details see market index definition.'
    ],
    source: 'Local MLS, HouseCanary analysis'
  },
  OWNER_OCCUPANCY: {
    title: 'Owner Occupancy',
    content: [
      'Owner occupancy indicates whether the owner of the home is the primary resident.'
    ],
    source: 'Public Record'
  },
  PROPERTY_TYPE: {
    title: 'Property Type',
    content: [
      'Property Type indicates the classification of the building based upon public',
      'record information. HouseCanary has normalized property type information into three',
      'groupings: Single Family Detached, Condominium, Townhouse. Note that buildings that',
      'do not fall into these categories, i.e. apartment houses, highrise apartments, etc. will',
      'not be mapped into one of these categories.'
    ],
    source: 'Public Record'
  },
  COMPARABLE_VALUE: {
    title: 'Comparable Value',
    content: [
      'The “Comparable Value” is calculated by averaging selected comparables’',
      'adjusted sales price. We automatically adjust each comparable’s sale value by comparing',
      'each comparable to the subject property. These factors include but are not limited to:',
      'locational differences, square feet, bedrooms, bathrooms, lotsize,',
      'pools, basements, and many other possible fields.'
    ],
    source: 'HouseCanary analysis'
  },
  RENTAL_COMPS: {
    title: 'Rental Comparable Properties',
    content: [
      'Rental Comparable Properties are properties with similar features to the subject property that are currently being rented or have recently been listed for rent. Our rental values adjust for locational differences, square footage, bedrooms, bathrooms, lot size, pools, basements, and many other possible factors.'
    ]
  }
};
