import React, { Dispatch, useEffect } from 'react';
import { ChevronDarkIcon } from '../../inline-svgs';
import { createPortal } from 'react-dom';
import { Photo } from 'src/lib/property-lookup/types';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { CloseButton } from 'src/lib/components/CloseButton';

import styles from './FullscreenCarousel.css';
import { useComponentDidMount } from 'src/lib/hooks/useComponentDidMount';
import { usePrevious } from 'src/lib/hooks/usePrevious';

export interface PhotoFullscreenCarousel extends Photo {
  fullscreenMetadataLower: string | null;
  fullscreenMetadataUpper: React.ReactNode | null;
}

interface PortalProps {
  // Id of the portal target where the carousel is rendered
  fullscreenPortalId: string;
  // If the fullscreen carousel is open
  active: boolean;
  // Index of active photo
  index: number;
  // Optional className
  className?: string;
  // Array of photo data
  photos: PhotoFullscreenCarousel[];
  // Callback when clicking on a close area (anywhere but the image, metadata, or carousel buttons)
  onClose: (event?: KeyboardEvent | React.MouseEvent) => void;
  // Callback when clicking the left carousel button
  onClickDec: MouseEventHandler<HTMLDivElement>;
  // Callback when clicking the right carousel button
  onClickInc: MouseEventHandler<HTMLDivElement>;
  // QA selector
  dataHcName: string;
}

const FullscreenCarouselComponent = ({
  dataHcName,
  fullscreenPortalId,
  active,
  index,
  photos,
  className,
  onClose,
  onClickDec,
  onClickInc
}: PortalProps) => {
  const target = document.getElementById(fullscreenPortalId);
  const photo = index !== null && index !== undefined && photos[index];
  if (target && photo) {
    const {
      fullscreenMetadataLower,
      fullscreenMetadataUpper,
      representation: { httpUrl }
    } = photo;
    const isCarousel = photos.length > 1;
    return createPortal(
      active && (
        <div
          data-hc-name={dataHcName}
          className={classNames(className, styles.Fullscreen)}
        >
          <div
            className={styles.FullscreenCarousel}
            data-hc-name={`${dataHcName}-clickable`}
            onClick={onClose}
          >
            <div
              className={styles.FullscreenContent}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className={classNames(styles.FullscreenMetadata, styles.upper)}
              >
                <div
                  data-hc-name={`${dataHcName}-metadata-upper`}
                  className={styles.FullscreenMetadataContentUpper}
                >
                  {fullscreenMetadataUpper || null}
                </div>
                <div>
                  <CloseButton
                    dataHcName={`${dataHcName}-close-button`}
                    onClick={onClose}
                  />
                </div>
              </div>
              <div
                data-hc-name={`${dataHcName}-carousel`}
                className={styles.Carousel}
              >
                {isCarousel && (
                  <div
                    data-hc-name={`${dataHcName}-btn-left`}
                    className={classNames(
                      styles.CarouselButton,
                      styles.btnLeft
                    )}
                    onClick={onClickDec}
                  >
                    <ChevronDarkIcon
                      dataHcName={`${dataHcName}-chevron-dark-icon`}
                    />
                  </div>
                )}
                <div className={styles.CarouselImg}>
                  <img
                    alt="Photo link"
                    data-hc-name={`${dataHcName}-image`}
                    src={httpUrl}
                    onClick={(e) => e && e.stopPropagation()}
                  />
                </div>
                {isCarousel && (
                  <div
                    data-hc-name={`${dataHcName}-btn-right`}
                    className={classNames(
                      styles.CarouselButton,
                      styles.btnRight
                    )}
                    onClick={onClickInc}
                  >
                    <ChevronDarkIcon
                      dataHcName={`${dataHcName}-chevron-dark-icon`}
                    />
                  </div>
                )}
              </div>
              <div className={styles.FullscreenMetadata}>
                <div
                  data-hc-name={`${dataHcName}-metadata-lower`}
                  className={styles.FullscreenMetadataContentLower}
                >
                  {fullscreenMetadataLower || null}
                </div>
                <div
                  className={styles.Counter}
                  data-hc-name={`${dataHcName}-counter`}
                >
                  <span data-hc-name={`${dataHcName}-counter-current`}>
                    {index + 1}
                  </span>{' '}
                  of{' '}
                  <span data-hc-name={`${dataHcName}-counter-total`}>
                    {photos.length}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            data-hc-name={`${dataHcName}-overlay`}
            className={styles.FullscreenOverlay}
            onClick={(e) => onClose(e)}
          />
        </div>
      ),
      target
    );
  } else {
    return null;
  }
};

interface Props {
  photos: PhotoFullscreenCarousel[];
  initialIndex: number | null;
  dataHcName: string;
  fullscreenPortalId: string;
  onChange: (photo?: PhotoFullscreenCarousel, index?: number) => void;
  initialActive: boolean;
  onClose: (event?: KeyboardEvent | React.MouseEvent) => void;
  className?: string;
}

enum CAROUSEL_STATES {
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT',
  SET_INDEX = 'SET_INDEX'
}

type CarouselAction = {
  type: CAROUSEL_STATES;
  payload: number;
};

type PrevVars = {
  index: number;
  active: boolean;
};

type CarouselState = {
  index: number;
};

const carouselReducer = (
  state: CarouselState,
  action: CarouselAction
): CarouselState => {
  switch (action.type) {
    case CAROUSEL_STATES.INCREMENT:
      return {
        ...state,
        index: state.index === action.payload - 1 ? 0 : state.index + 1
      };
    case CAROUSEL_STATES.DECREMENT:
      return {
        ...state,
        index: state.index === 0 ? action.payload - 1 : state.index - 1
      };
    case CAROUSEL_STATES.SET_INDEX:
      return {
        ...state,
        index: action.payload
      };
    default:
      return state;
  }
};

const incrementAction = (numPhotos: number): CarouselAction => {
  return {
    type: CAROUSEL_STATES.INCREMENT,
    payload: numPhotos
  };
};

const decrementAction = (numPhotos: number): CarouselAction => {
  return {
    type: CAROUSEL_STATES.INCREMENT,
    payload: numPhotos
  };
};

export const FullscreenCarousel = ({
  photos,
  initialIndex,
  dataHcName,
  onChange,
  className,
  onClose,
  initialActive,
  fullscreenPortalId
}: Props) => {
  const initialState: CarouselState = {
    index: initialIndex || 0
  };

  const [carouselState, dispatch]: [CarouselState, Dispatch<CarouselAction>] =
    React.useReducer(carouselReducer, initialState);

  const prevIndexActivePhotos = usePrevious<PrevVars>({
    index: carouselState.index,
    active: initialActive
  });

  const [portalReady, setPortalReady] = React.useState(
    !!fullscreenPortalId && !!document.getElementById(fullscreenPortalId)
  );

  const keyboardNavigationListener = (event: KeyboardEvent) => {
    const { keyCode } = event;

    switch (keyCode) {
      case 39:
        return dispatch(incrementAction(photos.length));
      case 37:
        return dispatch(decrementAction(photos.length));
      case 27:
        return onClose(event);
    }
  };

  useComponentDidMount(() => {
    if (fullscreenPortalId) {
      setPortalReady(!!document.getElementById(fullscreenPortalId));
    }

    // On unmount remove listener
    return () => {
      window.removeEventListener('keyup', keyboardNavigationListener);
    };
  });

  useEffect(() => {
    if (prevIndexActivePhotos?.active !== initialActive && initialActive) {
      window.addEventListener('keyup', keyboardNavigationListener);
    }

    if (
      prevIndexActivePhotos?.index !== carouselState.index &&
      initialIndex != null &&
      initialIndex !== carouselState.index
    ) {
      // index changed, let parent know
      onChange(photos[carouselState.index], carouselState.index);
      //check for parent index change
    } else if (initialIndex != null && carouselState.index !== initialIndex) {
      dispatch({
        type: CAROUSEL_STATES.SET_INDEX,
        payload: initialIndex
      });
    }
  }, [initialActive, carouselState, prevIndexActivePhotos]);

  return portalReady ? (
    <FullscreenCarouselComponent
      dataHcName={dataHcName}
      className={className}
      fullscreenPortalId={fullscreenPortalId}
      active={initialActive}
      onClickDec={() => dispatch(decrementAction(photos.length))}
      onClickInc={() => dispatch(incrementAction(photos.length))}
      onClose={onClose}
      index={carouselState.index}
      photos={photos}
    />
  ) : null;
};
