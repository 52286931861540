export default {
  UTM_PARAMS: [
    'utm_adgroup',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_region',
    'utm_source',
    'utm_term',
    'utm_firstname',
    'utm_lastname',
    'utm_email',
    'utm_address',
    'utm_zip',
    'utm_city',
    'utm_state'
  ]
};
