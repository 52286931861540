import get from 'lodash/get';
import { ROUTE_UPDATE } from '@hc/redux-saga-router-plus/hclib/actions';

import {
  STATUSES,
  PROPERTY_DETAILS_TYPE_GENERAL
} from 'legacy/appstore/constants';
import { PROPERTY_DATA_SOURCES, QUERIES } from 'legacyGraphQL/constants';

import { isArray, isString } from 'legacy/utils/utils';

import {
  COMPS_ADJUST_SAVE,
  COMPS_ADD_COMP_SUCCESS,
  COMPS_FARM_UPDATE_SUCCESS,
  COMPS_REFRESH_READY_FOR_RECOMPUTE
} from 'actions/comps';
import {
  RENTAL_COMPS_ADJUST_SAVE,
  RENTAL_COMPS_ADD_COMP_SUCCESS,
  RENTAL_COMPS_FARM_UPDATE_SUCCESS,
  RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE
} from 'actions/rental-comps';
import { SUBJECT_UPDATE_GEO_LOCATION } from 'actions/subject';
import {
  VR_RECOMPUTE,
  VALUE_REPORT_EDIT_PROPERTY_DETAILS
} from 'actions/edit-report';
import {
  GET_REPORT,
  GET_REPORT_HC_VERSION_SUCCESS,
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_HC_VERSION_FAILURE,
  GET_REPORT_RENTAL_SUCCESS
} from 'actions/get-report';
import { SEARCH_BY_MLS_NUMBER_SELECT_SUCCESS } from 'actions/search-by-mls-number';
import {
  PROPERTY_QUERY_DETAILS_COMPLETE,
  PROPERTY_QUERY_FEATURES_FOR_SOURCE,
  PROPERTY_QUERY_DETAILS_EXTENDED,
  PROPERTY_QUERY_REQUEST,
  PROPERTY_QUERY_SUCCESS
} from 'actions/property';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';

const { HC, MLS, PR } = PROPERTY_DATA_SOURCES;

const INITIAL_STATE_SOURCE = (() => {
  let state = {
    statusByQuery: {},
    data: {}
  };
  return state;
})();

const INITIAL_STATE_META = {
  visiblePropertyDetailsType: PROPERTY_DETAILS_TYPE_GENERAL
};

const INITIAL_STATE_PROPERTY = {
  [HC]: { ...INITIAL_STATE_SOURCE },
  [MLS]: { ...INITIAL_STATE_SOURCE },
  [PR]: { ...INITIAL_STATE_SOURCE },
  meta: { ...INITIAL_STATE_META },
  edits: {}
};

const INITIAL_STATE = {
  subject: { ...INITIAL_STATE_PROPERTY }
};

const _reduceRouteUpdate = (state, action) => {
  const query = get(action, ['payload', 'query'], {});
  const { addressIdPropertyDetails: addressId, visiblePropertyDetailsType } =
    query;
  if (!addressId || !visiblePropertyDetailsType) {
    return state;
  }
  const propertyState = get(state, addressId, INITIAL_STATE_PROPERTY);
  return {
    ...state,
    [addressId]: {
      ...propertyState,
      meta: {
        ...propertyState.meta,
        visiblePropertyDetailsType
      }
    }
  };
};

const _updatePropertyEdits = (state, edits) => {
  let updated = { ...state };
  for (let addressId in edits) {
    if (!updated.hasOwnProperty(addressId)) {
      updated[addressId] = { ...INITIAL_STATE_PROPERTY };
    }
    updated[addressId].edits = {
      ...edits[addressId]
    };
    if (edits[addressId].bathrooms && isString(edits[addressId].bathrooms)) {
      updated[addressId].edits.bathrooms = parseFloat(
        edits[addressId].bathrooms
      );
    }
  }
  return updated;
};

const _updateQueryStatus = (
  state,
  addressId,
  source,
  queries,
  status,
  updateOnlyIfStatus
) => {
  let statusByQuery = {
    ...get(state, [addressId, source, 'statusByQuery'], {})
  };
  if (!isArray(queries)) {
    queries = [queries];
  }
  queries.forEach((q) => {
    // Update only if the status is a match to updateOnlyIfStatus
    if (
      !updateOnlyIfStatus ||
      (isArray(updateOnlyIfStatus) &&
        updateOnlyIfStatus.indexOf(statusByQuery[q]) > -1) ||
      updateOnlyIfStatus === statusByQuery[q]
    ) {
      statusByQuery[q] = status;
    }
  });
  return {
    ...state,
    [addressId]: {
      ...get(state, addressId, INITIAL_STATE_PROPERTY),
      [source]: {
        ...get(state, [addressId, source], INITIAL_STATE_SOURCE),
        statusByQuery
      }
    }
  };
};

const _updateMultipleQueryStatuses = (
  state,
  queryDefs,
  status,
  updateOnlyIfStatus
) => {
  let updated = { ...state };
  queryDefs.forEach(({ addressId, source, queries }) => {
    updated = _updateQueryStatus(
      updated,
      addressId,
      source,
      queries,
      status,
      updateOnlyIfStatus
    );
  });
  return updated;
};

const _updateFromGraphQLResponse = (
  state,
  addressId,
  { source, queries, response }
) => {
  // Mark all queries statuses as SUCCESS
  let statusByQuery = {
    ...get(state, [addressId, source, 'statusByQuery'], {})
  };
  queries.forEach((q) => {
    statusByQuery[q] = STATUSES.SUCCESS;
  });

  return {
    ...state,
    [addressId]: {
      ...get(state, addressId, {}),
      [source]: {
        ...get(state, [addressId, source], {}),
        data: {
          ...get(state, [addressId, source, 'data'], {}),
          ...response
        },
        statusByQuery
      }
    }
  };
};

const _updatePropertyEditsFromMapping = (state, adjustments) => {
  let updated = { ...state };
  for (let addressId in adjustments) {
    updated[addressId].edits = {
      ...state[addressId].edits,
      ...adjustments[addressId]
    };
    if (
      adjustments[addressId].bathrooms &&
      isString(adjustments[addressId].bathrooms)
    ) {
      updated[addressId].edits.bathrooms = parseFloat(
        adjustments[addressId].bathrooms
      );
    }
  }
  return updated;
};

export const _updateQueryStatusForMultipleProperties = (
  state,
  addressIds,
  source,
  queries,
  status
) => {
  let updated = { ...state };
  addressIds.forEach((addressId) => {
    updated = _updateQueryStatus(updated, addressId, source, queries, status);
  });
  return updated;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPORT:
      return _updateQueryStatus(
        state,
        'subject',
        HC,
        QUERIES.REPORT,
        STATUSES.LOADING
      );

    case GET_REPORT_HC_VERSION_SUCCESS:
      return _updateQueryStatusForMultipleProperties(
        state,
        ['subject'].concat(
          get(action, ['payload', 'report', 'compsFarmList'], []).map(
            (c) => c.addressId
          )
        ),
        HC,
        QUERIES.REPORT,
        STATUSES.SUCCESS
      );

    case GET_REPORT_RENTAL_SUCCESS:
      return _updateQueryStatusForMultipleProperties(
        state,
        ['subject'].concat(
          get(action, ['payload', 'report', 'compsFarmList'], []).map(
            (c) => c.addressId
          )
        ),
        HC,
        [QUERIES.REPORT, QUERIES.RENTAL],
        STATUSES.SUCCESS
      );

    case EFFECTIVE_DATE_GET_REPORT_SUCCESS:
    case GET_REPORT_USER_VERSION_SUCCESS:
      return _updateQueryStatusForMultipleProperties(
        _updatePropertyEdits(
          _updateQueryStatusForMultipleProperties(
            state,
            ['subject'].concat(
              Object.keys(
                get(
                  action,
                  ['payload', 'report', 'metadata', 'rentalCompFarm'],
                  {}
                )
              )
            ),
            HC,
            QUERIES.REPORT,
            STATUSES.SUCCESS
          ),
          get(action, ['payload', 'report', 'metadata', 'propertyEdits'])
        ),
        get(action, ['payload', 'report', 'compsFarmList'], [])?.map(
          (c) => c.addressId
        ) || [],
        HC,
        QUERIES.REPORT,
        STATUSES.SUCCESS
      );

    case COMPS_FARM_UPDATE_SUCCESS:
    case RENTAL_COMPS_FARM_UPDATE_SUCCESS:
      return _updateQueryStatusForMultipleProperties(
        state,
        get(action, ['payload', 'farm'], []).map((c) => c.addressId),
        HC,
        QUERIES.REPORT,
        STATUSES.SUCCESS
      );

    case GET_REPORT_HC_VERSION_FAILURE:
      return _updateQueryStatus(
        state,
        'subject',
        HC,
        QUERIES.REPORT,
        STATUSES.ERROR
      );

    case SEARCH_BY_MLS_NUMBER_SELECT_SUCCESS: {
      const { addressId, publicRemarks, taxAmount, taxYear, zoning } =
        action.payload.comp;
      const updatedState = _updateQueryStatus(
        state,
        addressId,
        HC,
        QUERIES.REPORT,
        STATUSES.SUCCESS
      );
      updatedState[addressId] = {
        ...updatedState[addressId],
        [MLS]: {
          statusByQuery: {
            [QUERIES.LISTING_DETAILS]: STATUSES.SUCCESS
          },
          data: {
            // Missing:
            // hoaAssociation
            // hoaFee
            // hoaFeeFrequency
            // hoaFeeIncludes
            publicRemarks,
            taxAmount,
            taxYear,
            zoning
          }
        }
      };

      return updatedState;
    }

    case COMPS_ADD_COMP_SUCCESS:
    case RENTAL_COMPS_ADD_COMP_SUCCESS:
      return _updateQueryStatus(
        state,
        action.payload.comp.addressId,
        HC,
        QUERIES.REPORT,
        STATUSES.SUCCESS
      );

    case PROPERTY_QUERY_DETAILS_EXTENDED:
      return _updateMultipleQueryStatuses(
        state,
        [
          {
            source: HC,
            queries: QUERIES.PROPERTY_FEATURES,
            addressId: action.payload.addressId
          },
          {
            source: MLS,
            queries: QUERIES.LISTING_DETAILS,
            addressId: action.payload.addressId
          },
          {
            source: PR,
            queries: QUERIES.TAX_HISTORY,
            addressId: action.payload.addressId
          }
        ],
        STATUSES.PENDING, // Set status to pending if status === INIT
        [undefined, null, '', STATUSES.INIT]
      );

    case PROPERTY_QUERY_FEATURES_FOR_SOURCE:
      return _updateQueryStatus(
        state,
        action.payload.addressId,
        action.payload.source,
        [QUERIES.PROPERTY_FEATURES],
        STATUSES.LOADING
      );

    case PROPERTY_QUERY_DETAILS_COMPLETE:
      return _updateMultipleQueryStatuses(
        state,
        [
          {
            source: HC,
            queries: [QUERIES.PROPERTY_FEATURES],
            addressId: action.payload.addressId
          },
          {
            source: MLS,
            queries: [QUERIES.LISTING_DETAILS, QUERIES.PROPERTY_FEATURES],
            addressId: action.payload.addressId
          },
          {
            source: PR,
            queries: [QUERIES.TAX_HISTORY, QUERIES.PROPERTY_FEATURES],
            addressId: action.payload.addressId
          }
        ],
        STATUSES.PENDING, // Set status to pending if status === INIT
        [undefined, null, '', STATUSES.INIT]
      );

    case PROPERTY_QUERY_REQUEST:
      return isArray(action.payload.requests)
        ? _updateMultipleQueryStatuses(
            state,
            action.payload.requests.map((r) => {
              return {
                ...r,
                source: r.source,
                queries: r.query,
                addressId: action.payload.addressId
              };
            }),
            STATUSES.LOADING
          )
        : _updateQueryStatus(
            state,
            action.payload.addressId,
            action.payload.requests.source || HC,
            action.payload.requests.query,
            STATUSES.LOADING
          );

    case PROPERTY_QUERY_SUCCESS:
      return _updateFromGraphQLResponse(
        state,
        action.payload.addressId,
        action.payload.graphQLResponse
      );

    case VR_RECOMPUTE:
      return _updatePropertyEditsFromMapping(state, {
        subject: action.payload.subjectUpdate
      });

    case COMPS_ADJUST_SAVE:
    case RENTAL_COMPS_ADJUST_SAVE:
      return _updatePropertyEditsFromMapping(state, action.payload.adjustments);

    case VALUE_REPORT_EDIT_PROPERTY_DETAILS:
      return {
        ...state,
        subject: {
          ...get(state, 'subject', {}),
          meta: {
            ...get(state, ['subject', 'meta'], {}),
            visiblePropertyDetailsType: PROPERTY_DETAILS_TYPE_GENERAL
          }
        }
      };

    case SUBJECT_UPDATE_GEO_LOCATION:
      return {
        ...state,
        subject: {
          ...state.subject,
          edits: {
            ...state.subject.edits,
            geoLocation: action.payload.geoLocation,
            geoPrecision: action.payload.geoPrecision
          }
        }
      };

    case RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE: {
      // Update report status for new farm lists
      const updatedState = {
        ..._updateQueryStatusForMultipleProperties(
          state,
          action.payload.userCompsFarmList.map((comp) => comp.addressId),
          HC,
          QUERIES.REPORT,
          STATUSES.SUCCESS
        )
      };
      // Clear any edits for the properties in the old farm list
      action.payload.previousCompAddressIds.forEach((addressId) => {
        if (updatedState[addressId]) {
          updatedState[addressId].edits = {};
        }
      });
      return updatedState;
    }

    case COMPS_REFRESH_READY_FOR_RECOMPUTE: {
      // Update report status for new farm lists
      const updatedState = {
        ..._updateQueryStatusForMultipleProperties(
          state,
          action.payload.hcCompsFarmList.map((comp) => comp.addressId),
          HC,
          QUERIES.REPORT,
          STATUSES.SUCCESS
        ),
        ..._updateQueryStatusForMultipleProperties(
          state,
          action.payload.userCompsFarmList.map((comp) => comp.addressId),
          HC,
          QUERIES.REPORT,
          STATUSES.SUCCESS
        )
      };
      // Clear any edits for the properties in the old farm list
      action.payload.previousCompAddressIds.forEach((addressId) => {
        if (updatedState[addressId]) {
          updatedState[addressId].edits = {};
        }
      });
      return updatedState;
    }

    case ROUTE_UPDATE:
      return _reduceRouteUpdate(state, action);

    default:
      return state;
  }
};
