import { watchLatest } from 'legacy/utils/saga';
import { all, call, put, select } from 'redux-saga/effects';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { VIEWS } from 'legacy/routes/constants';

import apiUtil from 'legacy/utils/api';

import reportSchemaService from 'legacy/services/report-schema';

import { fetchSubjectDetails } from 'sagas/report-property-details';
import {
  AVM_KEY_USER,
  AVM_KEY_COMPS,
  AVM_KEY_COMPS_AVG,
  AVM_KEY_USER_ENTERED,
  AVM_KEY_RENTAL_ADJUSTED,
  AVM_KEY_RENTAL_COMPS,
  AVM_KEY_RENTAL_COMPS_AVG,
  AVM_KEY_RENTAL_USER_ENTERED,
  ERROR_TEXT
} from 'legacy/appstore/constants';
import { selectScrubDataArgs } from './mls-compliance.saga';
import {
  GET_REPORT,
  GET_REPORT_USER_VERSION,
  getReportHcVersionSuccess,
  getReportHcVersionFailure,
  getReportUserVersionSuccess,
  getReportUserVersionNotFound
} from 'actions/get-report';
import { featureFlagDefaultValue } from 'actions/feature-flag';

import { getCurrentReportParams } from 'selectors/property-explorer';
import { getCreateReportClientId } from 'selectors/create-report';
import { performanceReportPullComplete } from 'actions/performance.actions';

export function* fetchHcVersion() {
  const reportParams = yield select(getCurrentReportParams);
  const clientId = yield select(getCreateReportClientId);
  const params = {
    format: 'json',
    reportType: 'full',
    addressId: reportParams.addressId || reportParams.address_id,
    addressSlug: reportParams.addressSlug || reportParams.address_slug,
    streetAddress: reportParams.streetAddress,
    unit: reportParams.unit,
    zipcode: reportParams.zipcode,
    city: reportParams.city,
    state: reportParams.state,
    lat: reportParams.lat,
    lon: reportParams.lon,
    listingId: reportParams.listingId || reportParams.listing_id,
    listingSource: reportParams.listingSource || reportParams.listing_source,
    skipComps: true,
    clientId
  };
  try {
    const scrubDataArgs = yield call(selectScrubDataArgs);
    const report = reportSchemaService(
      yield call(
        apiUtil.GET,
        `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/property_lookup/`,
        params
      ),
      scrubDataArgs
    );
    const isNewReport = yield select((state) => state.performance.timeStart);
    if (isNewReport) {
      yield put(performanceReportPullComplete());
    }
    yield put(getReportHcVersionSuccess(report, false, true));
  } catch (e) {
    if (e.statusCode === 404) {
      yield put(
        routeChange({ view: VIEWS.SEARCH, options: { inherit: false } })
      );
      yield put(
        getReportHcVersionFailure({ message: ERROR_TEXT.VALUE_REPORT.notFound })
      );
      // Redis lock error: prompt user to refresh the page
    } else if (e.statusCode === 409) {
      yield put(
        getReportHcVersionFailure({
          message: ERROR_TEXT.VALUE_REPORT.refreshRequired
        })
      );
    } else if (e.statusCode === 403) {
      // Attempting to access a report without access, redirect to search page
      yield put(
        routeChange({
          view: VIEWS.SEARCH,
          query: {
            address_slug: reportParams.addressSlug || reportParams.address_slug,
            street_address: reportParams.streetAddress,
            unit: reportParams.unit,
            zipcode: reportParams.zipcode
          }
        })
      );
    } else {
      yield put(getReportHcVersionFailure());
    }
    // Rethrow for Sentry reporting
    // Should we *not* report the above failure cases to Sentry since they're somewhat expected?
    throw e;
  }
}

// Select the new averages by default.
function* defaultSelectedAvmFeatureFlag() {
  try {
    const orgPreferences = yield call(
      apiUtil.GET,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/organization_report_preferences/`,
      {},
      { expect404: true }
    );
    const payload = {};
    if (orgPreferences?.preferences?.pexpDefaultSelectedValue) {
      if (
        orgPreferences.preferences.pexpDefaultSelectedValue.type ===
        'comparableValue'
      ) {
        if (
          orgPreferences.preferences.pexpDefaultSelectedValue.hcAdjustments
            .includeHcHpiAdjustment &&
          orgPreferences.preferences.pexpDefaultSelectedValue.hcAdjustments
            .includeHcPhysicalAdjustment
        ) {
          payload.selected = AVM_KEY_COMPS;
        } else {
          payload.selected = AVM_KEY_COMPS_AVG;
        }
      } else if (
        orgPreferences.preferences.pexpDefaultSelectedValue.type === 'userValue'
      ) {
        payload.selected = AVM_KEY_USER_ENTERED;
      } else if (
        orgPreferences.pexpDefaultSelectedValue.type === 'avmAdjusted'
      ) {
        payload.selected = AVM_KEY_USER;
      }
    }
    if (orgPreferences?.preferences?.pexpDefaultSelectedValueRental) {
      if (
        orgPreferences.preferences.pexpDefaultSelectedValueRental.type ===
        'comparableValue'
      ) {
        if (
          orgPreferences.preferences.pexpDefaultSelectedValueRental
            .hcAdjustments.includeHcHpiAdjustment &&
          orgPreferences.preferences.pexpDefaultSelectedValueRental
            .hcAdjustments.includeHcPhysicalAdjustment
        ) {
          payload.selectedRental = AVM_KEY_RENTAL_COMPS;
        } else {
          payload.selectedRental = AVM_KEY_RENTAL_COMPS_AVG;
        }
      } else if (
        orgPreferences.preferences.pexpDefaultSelectedValueRental.type ===
        'userValue'
      ) {
        payload.selectedRental = AVM_KEY_RENTAL_USER_ENTERED;
      } else if (
        orgPreferences.preferences.pexpDefaultSelectedValueRental.type ===
        'avmAdjusted'
      ) {
        payload.selectedRental = AVM_KEY_RENTAL_ADJUSTED;
      }
    }
    if (payload.selected || payload.selectedRental) {
      yield put(featureFlagDefaultValue(payload));
    }
    return;
  } catch (e) {
    return;
  }
}

export function* fetchUserVersion() {
  const scrubDataArgs = yield call(selectScrubDataArgs);
  const reportParams = yield select(getCurrentReportParams);
  const report = reportSchemaService(
    yield call(
      apiUtil.GET,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/user_version/`,
      reportParams,
      { expect404: true }
    ),
    scrubDataArgs
  );
  if (report.statusCode === 404) {
    yield call(defaultSelectedAvmFeatureFlag);
    yield put(getReportUserVersionNotFound());
  } else {
    yield put(getReportUserVersionSuccess(report));
  }
}

export function* handleGetPropertyExplorer(action) {
  // This will concurrently fetch all report data that isn't reliant on addressId or addressSlug
  yield all([
    call(fetchSubjectDetails),
    call(fetchUserVersion),
    call(fetchHcVersion)
  ]);
}

export function* handleGetReportUserVersion(action) {
  yield call(fetchUserVersion);
}

export default function registerGetReportSaga() {
  watchLatest(GET_REPORT, handleGetPropertyExplorer);
  watchLatest(GET_REPORT_USER_VERSION, handleGetReportUserVersion);
}
