import { watchEvery } from 'legacy/utils/saga';
import { put, call } from 'redux-saga/effects';

import apiUtil from 'legacy/utils/api';

import { ADDRESS_PARTS_REQUEST } from 'actions/address-parts';
import { toastShow } from 'actions/toast';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { VIEWS } from 'legacy/routes/constants';

import HC_CONSTANTS from 'HC_CONSTANTS';

export function* getAddressParts(action) {
  const slug = action.payload.slug || undefined;
  const response = yield call(apiUtil.GET, HC_CONSTANTS.ADDRESS_PARTS_URL, {
    slug
  });
  const result = response[0];

  if (result.address && result.zipcode) {
    yield put(
      routeChange({
        view: VIEWS.SEARCH,
        query: {
          street_address: result.address || undefined,
          zipcode: result.zipcode || undefined,
          unit: result.unit || undefined,
          address_slug: slug
        },
        options: { inherit: false }
      })
    );
  } else {
    /** TODO log this somewhere? */
    yield put(toastShow('Unsupported address'));
  }
}

export function registerAddressPartsSaga() {
  watchEvery({
    [ADDRESS_PARTS_REQUEST]: getAddressParts
  });
}
