import { useListings, LimitListing, LimitLatestListing } from './useListings';

export const useBrokerageName = (
  hcAddressId: number,
  limit?: LimitListing | LimitLatestListing
) => {
  const { loading, error, data } = useListings(hcAddressId, limit);
  return {
    loading,
    error,
    data: data?.[0]?.listingOfficeName
  };
};
