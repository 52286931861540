import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ValueSelectorItem } from 'src/lib/components/ValueSelector/ValueSelectorItem';
import { ValueSelectorButton } from 'src/lib/components/ValueSelector/ValueSelectorButton';
import { ValueSelectorInput } from 'src/lib/components/ValueSelector/ValueSelectorInput';
import { documentPatch } from 'src/lib/report-api/actions';

import styles from './ReportValueSelector.css';

interface Props {
  dataHcName: string;
  maxVal: number | null;
  minVal: number | null;
  reportId: number;
  documentId: string;
  value: number | null;
  avmSelected?: string;
  onClose: VoidFunction;
}

export const UserValueSelectorItem = ({
  dataHcName,
  maxVal,
  minVal,
  reportId,
  documentId,
  value,
  avmSelected,
  onClose
}: Props) => {
  const [inputValue, setInputValue] = useState(value ? value.toString() : '');
  const dispatch = useDispatch();
  const matchedValue = inputValue?.match(/\d/g)?.join('');

  const handleSelect = () => {
    dispatch(
      documentPatch(reportId, documentId, [
        { op: 'replace', path: '/data/selected', value: 'userValue' },
        { op: 'replace', path: '/data/userValue/value', value: inputValue }
      ])
    );
    onClose();
  };
  return (
    <ValueSelectorItem
      title={'Your Opinion of Price:'}
      dataHcName={dataHcName}
      className={styles.AVMSelectionItem}
    >
      <ValueSelectorInput
        maxVal={maxVal}
        minVal={minVal}
        onChange={(val) => setInputValue(val)}
        dataHcName={`${dataHcName}-value-selector-input`}
        userValue={value}
      />
      <ValueSelectorButton
        dataHcName={`${dataHcName}-value-selector-button`}
        onClick={handleSelect}
        disabled={
          avmSelected === 'userValue' && value?.toString() === matchedValue
        }
      >
        Use for report
      </ValueSelectorButton>
    </ValueSelectorItem>
  );
};
