import forEach from 'lodash/forEach';

export const INVALID_URL_MSG = 'Please enter a valid URL';

// This validation function is for a redux-form field. It should be included on the validate prop.
export const validateEmail = (email) =>
  // SECURITY: we control the length of input @jnettleman
  // eslint-disable-next-line security/detect-unsafe-regex
  /^([a-zA-Z0-9+_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,7})+$/.test(email)
    ? undefined
    : 'Please provide a valid email address.';

// Value must be valid email or empty
export const validateEmailOrEmpty = (email) => {
  if (!email) return undefined;
  return validateEmail(email);
};

// Validates a text input of comma-separated emails
export const validateEmailMulti = (emails) => {
  let result;
  if (emails) {
    forEach(emails.split(','), (email) => {
      result = validateEmail(email.trim());
      if (result !== undefined) {
        result = 'Please provide valid email addresses, separated by commas';
        return false;
      }
    });
  }
  return result;
};
// Validates that a boolean redux-form input (toggle, checkbox) evaluates to true
export const validateBoolTrue = (val) => !val;
// Validates that a boolean redux-form input (toggle, checkbox) evaluates to false
export const validateBoolFalse = (val) => !!val;

export const validatePassword = (password) => {
  if (!password) {
    return 'Please enter a password';
  } else if (password.length < 8) {
    return 'Password must be at least 8 characters.';
  } else if (!/(?=.*[a-zA-Z]{1})(?=.*\d{1})/.test(password)) {
    return 'Password must contain at least one number and one letter.';
  }
  return undefined;
};

export const validateNotNullZeroOrEmpty = (value) => {
  const valid = [undefined, null, 0, '', '0'].indexOf(value) === -1;
  return valid ? undefined : 'Please enter a value';
};

export const validateValueNotInList = (value, invalidValues) => {
  const valid = invalidValues.indexOf(value) === -1;
  return valid ? undefined : 'Please enter a value';
};

export const validateNotEmpty = (value) =>
  validateValueNotInList(value, [undefined, '']);
