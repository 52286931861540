import React, { CSSProperties, ReactElement } from 'react';
import classNames from 'classnames';
import { Props as TableHeaderCellProps } from './TableHeaderCell';
import styles from './Table.css';
import { TableRow } from './TableRow';

export interface Props {
  // dataHcName not required because it will be passed down from Table
  dataHcName?: string;
  // Header is sticky on scroll
  sticky?: boolean;
  // Inline styles
  style?: CSSProperties;
  // Height of the Header
  height?: number;
  // Class
  className?: string;
  // TableHeaderCells
  children:
    | ReactElement<TableHeaderCellProps>
    | ReactElement<TableHeaderCellProps>[];
}

export const TableHeader = ({
  dataHcName,
  children,
  sticky,
  height,
  ...tableRowProps
}: Props) => {
  return (
    <thead
      data-hc-name={dataHcName}
      className={classNames(styles.TableHeader, {
        [styles.sticky || '']: sticky
      })}
    >
      <TableRow
        dataHcName={`${dataHcName}-row`}
        {...tableRowProps}
        height={height}
      >
        {children}
      </TableRow>
    </thead>
  );
};
