import { STATUSES } from 'legacy/appstore/constants';
import {
  CREATE_REPORT_SET_CLIENT_ID,
  CREATE_REPORT_SET_EFFECTIVE_DATE,
  CREATE_REPORT_SET_EFFECTIVE_DATE_SUCCESS
} from 'actions/create-report';
import {
  GET_REPORT_HC_VERSION_SUCCESS,
  GET_REPORT_HC_VERSION_FAILURE,
  GET_REPORT_USER_VERSION,
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_USER_VERSION_NOT_FOUND
} from 'actions/get-report';
import { ADDRESS_PARTS_REQUEST } from 'actions/address-parts';
import { PROPERTY_SEARCH_REQUEST } from 'actions/property-search';
import isDateToday from 'date-fns/isToday';

const INITIAL_STATE = {
  clientId: '',
  effectiveDate: {
    status: STATUSES.INIT,
    addressId: null,
    addressSlug: null,
    value: new Date(),
    isToday: true,
    data: {}
  }
};

export const createReportReducer = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
    case CREATE_REPORT_SET_CLIENT_ID: {
      return {
        ...state,
        clientId: action.payload.clientId
      };
    }

    case ADDRESS_PARTS_REQUEST:
    case PROPERTY_SEARCH_REQUEST:
    case GET_REPORT_HC_VERSION_SUCCESS:
    case GET_REPORT_HC_VERSION_FAILURE:
    case GET_REPORT_USER_VERSION:
    case GET_REPORT_USER_VERSION_SUCCESS:
    case GET_REPORT_USER_VERSION_NOT_FOUND: {
      return { ...INITIAL_STATE };
    }

    case CREATE_REPORT_SET_EFFECTIVE_DATE: {
      return {
        ...state,
        clientId: '',
        effectiveDate: {
          status: STATUSES.INIT,
          addressId: action.payload.addressId,
          addressSlug: action.payload.addressSlug,
          value: action.payload.effectiveDate,
          isToday: isDateToday(action.payload.effectiveDate),
          data: {}
        }
      };
    }

    case CREATE_REPORT_SET_EFFECTIVE_DATE_SUCCESS: {
      return {
        ...state,
        effectiveDate: {
          ...state.effectiveDate,
          status: STATUSES.SUCCESS,
          data: action.payload.data
        }
      };
    }

    default: {
      return state;
    }
  }
};
