import {
  FilterRangeType,
  FORMAT_TYPES,
  RANGE_TYPES
} from 'src/lib/components/FilterRange/types';
import { FILTER_TYPE } from 'src/lib/report-api/types/Filters';
import {
  formatMoney,
  formatNumber,
  formatNumberAbbrev,
  formatStripFormatting,
  relativeSubjectFormatter
} from 'src/lib/utils/formatters';
import { undefinedNullOrEmptyString } from 'src/lib/utils/strings';
import {
  documentPatch,
  DocumentPatchAction,
  FilterDocument
} from 'src/lib/report-api';
import { Dispatch } from 'redux';
import { CompFields } from 'src/lib/report-api/types/CompFields';
import accounting from 'accounting';

export const formatOrUndefined = (
  formatter: (value: string, options?: accounting.NumberSettings) => string,
  value: FilterRangeType,
  options?: accounting.NumberSettings
): FilterRangeType => {
  return {
    [RANGE_TYPES.MIN]: undefinedNullOrEmptyString(value?.[RANGE_TYPES.MIN])
      ? undefined
      : formatter(value?.[RANGE_TYPES.MIN] || '', options),
    [RANGE_TYPES.MAX]: undefinedNullOrEmptyString(value?.[RANGE_TYPES.MAX])
      ? undefined
      : formatter(value?.[RANGE_TYPES.MAX] || '', options)
  };
};

export const subjectDisplay = (
  isPercentage: boolean,
  value?: FilterRangeType
): FilterRangeType => {
  return {
    [RANGE_TYPES.MIN]: relativeSubjectFormatter(
      isPercentage,
      value?.[RANGE_TYPES.MIN]
    ),
    [RANGE_TYPES.MAX]: relativeSubjectFormatter(
      isPercentage,
      value?.[RANGE_TYPES.MAX]
    )
  };
};

export const getFilterLabel = ({
  type,
  value
}: {
  type?: FILTER_TYPE;
  value?: FilterRangeType;
}) => {
  if (
    value &&
    !undefinedNullOrEmptyString(value?.[RANGE_TYPES.MIN]) &&
    undefinedNullOrEmptyString(value?.[RANGE_TYPES.MAX])
  ) {
    return `${value?.[RANGE_TYPES.MIN]}+ ${type}`;
  }
  if (
    value &&
    undefinedNullOrEmptyString(value?.[RANGE_TYPES.MIN]) &&
    !undefinedNullOrEmptyString(value?.[RANGE_TYPES.MAX])
  ) {
    return `Up to ${value?.[RANGE_TYPES.MAX]} ${type}`;
  }
  if (
    !value ||
    (undefinedNullOrEmptyString(value?.[RANGE_TYPES.MIN]) &&
      undefinedNullOrEmptyString(value?.[RANGE_TYPES.MAX]))
  ) {
    return `${type}`;
  }
  return `${value?.[RANGE_TYPES.MIN]}-${value?.[RANGE_TYPES.MAX]} ${type}`;
};

export const convertNumArrayToFilterRange = (
  val?: (null | number)[]
): FilterRangeType => {
  return {
    [RANGE_TYPES.MIN]:
      val?.[0] === undefined || val?.[0] === null ? undefined : `${val?.[0]}`,
    [RANGE_TYPES.MAX]:
      val?.[1] === undefined || val?.[1] === null ? undefined : `${val?.[1]}`
  };
};

//Simple wrapper for formatNumber to fullfill formatter contract
const formatComma = (
  value?: string,
  options?: accounting.NumberSettings
): string => {
  return formatNumber(Number(value), options);
};

export const formatPercentage = (value?: string): string => {
  return `%${value}`;
};

export const formatMoneyStr = (
  value: string,
  options?: accounting.NumberSettings
): string => {
  return formatMoney(Number(value), options);
};

export const formatMoneyAbbrStr = (value: string): string => {
  return `$${formatNumberAbbrev(Number(formatStripFormatting(value)))}`;
};

export const formatSqrFt = (
  value?: string,
  options?: accounting.NumberSettings
): string => {
  return `${formatComma(value, options)} SqFt.`;
};

const formatDecimalNumber = (value: string | null) => {
  return `${Number(value)?.toFixed(2)}`;
};

export const formatValue = (
  value: FilterRangeType,
  decimal: boolean,
  formatType: FORMAT_TYPES
): FilterRangeType => {
  const cleanValue: FilterRangeType = formatOrUndefined(
    formatStripFormatting,
    value
  );
  const options = decimal ? { precision: 2 } : { precision: 0 };
  switch (formatType) {
    case FORMAT_TYPES.COMMA:
      return formatOrUndefined(formatComma, cleanValue, options);
    case FORMAT_TYPES.DECIMAL_NUMBER:
      return formatOrUndefined(formatDecimalNumber, cleanValue);
    case FORMAT_TYPES.MONEY:
      return formatOrUndefined(formatMoneyStr, cleanValue, options);
    default: {
      // number type return as is
      return value;
    }
  }
};

export const onBlurFilter = (
  value: FilterRangeType,
  dispatch: Dispatch<DocumentPatchAction>,
  reportId: number,
  filterDocument: FilterDocument,
  COMP_FIELD: CompFields
) => {
  if (
    undefinedNullOrEmptyString(value[RANGE_TYPES.MIN]) &&
    undefinedNullOrEmptyString(value[RANGE_TYPES.MAX]) // If we have empty value remove filter
  ) {
    dispatch(
      documentPatch(reportId, filterDocument.documentId, [
        {
          op: 'remove',
          path: `/data/filters/${COMP_FIELD}`
        }
      ])
    );
  } else {
    const min = undefinedNullOrEmptyString(value[RANGE_TYPES.MIN])
      ? null
      : Number(value[RANGE_TYPES.MIN]);
    const max = undefinedNullOrEmptyString(value[RANGE_TYPES.MAX])
      ? null
      : Number(value[RANGE_TYPES.MAX]);
    dispatch(
      documentPatch(reportId, filterDocument.documentId, [
        {
          op: 'add',
          path: `/data/filters/${COMP_FIELD}/absoluteValue`,
          value: [min, max]
        }
      ])
    );
  }
};
