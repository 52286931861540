import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cobrandingRequired } from '../actions/cobranding.actions';
import {
  selCobrandingStatus,
  selCobrandingData
} from '../selectors/cobranding.selectors';

export const useCobranding = (id: number) => {
  const dispatch = useDispatch();
  const status = useSelector(selCobrandingStatus(id));
  useEffect(() => {
    if (!status) {
      dispatch(cobrandingRequired(id));
    }
  }, [status, id]);
  return { status, data: useSelector(selCobrandingData(id)) };
};
