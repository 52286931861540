import { all, call } from '@redux-saga/core/effects';

import { cobrandingSagas } from './cobranding.sagas';
import { documentSagas } from './document.sagas';
import { reportSagas } from './report.sagas';
import { reportEventsSagas } from './reportEvents.sagas';

export * from './cobranding.sagas';
export * from './document.sagas';
export * from './report.sagas';
export * from './reportEvents.sagas';

export function* reportApiSagas() {
  yield all([
    call(documentSagas),
    call(cobrandingSagas),
    call(reportSagas),
    call(reportEventsSagas)
  ]);
}
