import { createSelector } from 'reselect';
import { getCurrentView } from '@hc/redux-saga-router-plus/hclib/selectors';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import {
  COMP_TYPE_DEFAULT,
  COMP_TYPE_RENTAL,
  MAP_ID_BY_COMP_TYPE,
  FILTER_SAVE_TYPES
} from 'legacy/appstore/constants';
import { VIEWS, RENTAL_COMPS_VIEWS } from 'legacy/routes/constants';

import {
  FIELDS,
  DEFAULT_FIELD_ORDER,
  formatCompListTableDatum
} from 'legacy/utils/comps-fields.utils.jsx';
import { toIdLookup } from 'legacy/utils/utils';
import { FILTERS, FILTERS_ORDER } from 'legacy/utils/filters';
import { FILTER_SAVE_METHODS } from 'legacy/utils/saved-filters';

import {
  getAvmCompValueConfidence,
  getAvmRentalCompValueConfidence,
  getAvmKeyForCompSelectionPage,
  getAvmKeyForRentalCompSelectionPage,
  getAvmForCompSelectionPage,
  getAvmForRentalCompSelectionPage
} from 'selectors/avms';
import { getFeatureFlagsData } from 'selectors/feature-flags';
import {
  getReportStatusEffectiveDateIsLoading,
  getReportStatusCompsIsLoaded,
  getReportStatusRentalCompsIsLoaded
} from 'selectors/report-status';
import { getPropertyAllUserEdits } from 'selectors/property';
import {
  getPreferencesTableColumnsComps,
  getPreferencesTableColumnsRentalComps,
  getPreferencesFiltersComps,
  getPreferencesFiltersRentalComps,
  getPreferencesFiltersCompsFilterSetDefault,
  getPreferencesFiltersRentalCompsFilterSetDefault
} from 'selectors/preferences';
import { getPropertyLookupId } from 'selectors/report-data';
import { getIsReportEditable } from 'selectors/report-permissions';
import { getSubjectAdjusted, getSubjectRental } from 'selectors/subject';
import {
  getIsEffectiveDateReport,
  getIsRentalContext,
  getIsViewingSharedReport
} from './property-explorer.selectors';

export const getCompsIsRentalContext = createSelector(getCurrentView, (view) =>
  RENTAL_COMPS_VIEWS.includes(view)
);

// TODO: This viewType shouldn't be in comp state

// TODO: Get away from using compType everywhere
export const getCompType = createSelector(
  getCompsIsRentalContext,
  (isRentalContext) => (isRentalContext ? COMP_TYPE_RENTAL : COMP_TYPE_DEFAULT)
);

export const getCompsMapId = createSelector(
  getCompType,
  (compType) => MAP_ID_BY_COMP_TYPE[compType]
);

// This can probably be handled more elegantly now the the router has a better api
export const getCompsViews = createSelector(
  getIsRentalContext,
  getIsViewingSharedReport,
  getIsEffectiveDateReport,
  (isRentalContext, isViewingSharedReport, isEffectiveDateReport) => {
    if (isEffectiveDateReport) {
      return {
        viewSelection: VIEWS.EFFECTIVE_DATE_COMPS,
        viewReport: VIEWS.EFFECTIVE_DATE_REPORT,
        viewMap: VIEWS.EFFECTIVE_DATE_COMPS_MAP
      };
    } else if (isViewingSharedReport) {
      if (isRentalContext) {
        return {
          viewSelection: VIEWS.RENTAL_COMPS_SELECTION_SHARED,
          viewReport: VIEWS.VALUE_REPORT_SHARED,
          viewMap: VIEWS.RENTAL_COMPS_SELECTION_MAP_SHARED
        };
      } else {
        return {
          viewSelection: VIEWS.COMPS_SELECTION_SHARED,
          viewReport: VIEWS.VALUE_REPORT_SHARED,
          viewMap: VIEWS.COMPS_SELECTION_MAP_SHARED
        };
      }
    } else {
      if (isRentalContext) {
        return {
          viewSelection: VIEWS.RENTAL_COMPS_SELECTION,
          viewReport: VIEWS.VALUE_REPORT,
          viewMap: VIEWS.RENTAL_COMPS_SELECTION_MAP
        };
      } else {
        return {
          viewSelection: VIEWS.COMPS_SELECTION,
          viewReport: VIEWS.VALUE_REPORT,
          viewMap: VIEWS.COMPS_SELECTION_MAP
        };
      }
    }
  }
);

const _getCompsState = (state) =>
  getCompsIsRentalContext(state) ? state.rentalComps : state.comps;

// TODO: this shouldn't be needed when state is refactored
export const getCompsSubject = createSelector(
  getCompsIsRentalContext,
  getSubjectAdjusted,
  getSubjectRental,
  (isRentalContext, subject, subjectRental) =>
    isRentalContext ? subjectRental : subject
);

export const getCompsAreLoaded = createSelector(
  getReportStatusCompsIsLoaded,
  getReportStatusRentalCompsIsLoaded,
  getCompsIsRentalContext,
  (compsAreLoaded, rentalCompsAreLoaded, isRentalContext) =>
    isRentalContext ? rentalCompsAreLoaded : compsAreLoaded
);

export const getCompsFarmRaw = createSelector(
  _getCompsState,
  (compsState) => compsState.farm
);

export const getCompsFiltersUpdatedFromUser = createSelector(
  _getCompsState,
  (compsState) => compsState.filtersUpdatedFromUser
);

export const getCompsSort = createSelector(
  _getCompsState,
  (compsState) => compsState.sort
);

export const getCompsSortSelected = createSelector(
  _getCompsState,
  (compsState) => compsState.sortSelected
);

export const getCompsAvailableIds = createSelector(
  _getCompsState,
  getCompsFarmRaw,
  (compsState, farm) => compsState.available.filter((i) => !!farm[i])
);

export const getCompsTags = createSelector(
  _getCompsState,
  (compsState) => compsState.tags
);

export const getCompsTagFilter = createSelector(
  _getCompsState,
  (compsState) => compsState.tagFilter
);

export const getCompsSelectedIds = createSelector(
  _getCompsState,
  getCompsFarmRaw,
  (compsState, farm) => {
    return compsState.selected.filter((i) => !!farm[i]);
  }
);

export const getCompsFilters = createSelector(
  _getCompsState,
  (compsState) => compsState.filters
);

export const getCompsSelectedIdsLookup = createSelector(
  getCompsSelectedIds,
  toIdLookup
);

export const getCompsFarm = createSelector(
  getCompsFarmRaw,
  getCompsSelectedIdsLookup,
  getPropertyAllUserEdits,
  (farmRaw, selectedIdLookup, edits) => {
    const farm = { ...farmRaw };
    Object.keys(edits).forEach((addressId) => {
      if (farm[addressId]) {
        farm[addressId] = {
          ...farm[addressId],
          ...edits[addressId],
          selected: !!selectedIdLookup[addressId]
        };
      }
    });
    return farm;
  }
);

export const getCompsIsAvailableEmpty = createSelector(
  getCompsAvailableIds,
  (availableIds) => !availableIds || !availableIds.length
);

export const getCompsListViewType = createSelector(
  _getCompsState,
  (compsState) => compsState.compsListViewType
);

export const getCompsListViewSelected = createSelector(
  _getCompsState,
  (compsState) => compsState.compsListViewSelected
);

export const getCompsKeywordMatchesByAddressId = createSelector(
  _getCompsState,
  (compsState) => compsState.keywordSearch.data.matchesByAddressId
);

export const getCompsListIds = createSelector(
  getCompsListViewSelected,
  getCompsAvailableIds,
  getCompsSelectedIds,
  getCompsTags,
  getCompsTagFilter,
  (viewSelected, availableIds, selectedIds, tags, tagFilter) => {
    if (viewSelected) {
      if (tagFilter) {
        return selectedIds.filter((i) => {
          return tags[tagFilter]?.[i];
        });
      } else {
        return selectedIds;
      }
    } else {
      return availableIds;
    }
  }
);

export const getCompsListComps = createSelector(
  getCompsListIds,
  getCompsFarm,
  (addressIds, farm) => {
    return addressIds.map((addressId) => farm[addressId]);
  }
);

export const getAvailableComps = createSelector(
  getCompsAvailableIds,
  getCompsFarm,
  (addressIds, farm) => {
    return addressIds.map((addressId) => farm[addressId]);
  }
);

export const getCompsListSort = createSelector(
  getCompsListViewSelected,
  getCompsSort,
  getCompsSortSelected,
  (viewSelected, sort, sortSelected) => (viewSelected ? sortSelected : sort)
);

export const getCompsListTableData = createSelector(
  getCompsListViewType,
  getCompsListIds,
  getCompsFarm,
  getCompsSubject,
  getCompsSelectedIdsLookup,
  getCompsKeywordMatchesByAddressId,
  getIsReportEditable,
  getIsEffectiveDateReport,
  getReportStatusEffectiveDateIsLoading,
  getFeatureFlagsData,
  (
    viewType,
    addressIds,
    farm,
    subject,
    selectedIdsLookup,
    keywordMatches,
    isEditable,
    isEffectiveDateReport,
    isEffectiveDateLoading,
    featureFlags
  ) => {
    const filteredIds = [];
    addressIds.forEach((id) => {
      if (farm[id]) {
        filteredIds.push(id);
      }
    });
    return [formatCompListTableDatum(subject, viewType)].concat(
      filteredIds.map((addressId, i) => {
        return formatCompListTableDatum(
          farm[addressId],
          viewType,
          i,
          selectedIdsLookup,
          keywordMatches?.[addressId],
          isEditable,
          isEffectiveDateReport && isEffectiveDateLoading,
          featureFlags['increased-comp-limit']
        );
      })
    );
  }
);

export const getCompsTablePreferences = createSelector(
  getIsRentalContext,
  getPreferencesTableColumnsComps,
  getPreferencesTableColumnsRentalComps,
  (isRentalContext, preferencesComps, preferencesRentalComps) =>
    isRentalContext ? preferencesRentalComps : preferencesComps
);

export const getCompsListTableColumns = createSelector(
  getCompType,
  getCompsTablePreferences,
  getIsEffectiveDateReport,
  getFeatureFlagsData,
  (compType, preferences, isEffectiveDate, featureFlags) => {
    const columns =
      preferences && preferences.order && preferences.order.length
        ? [
            'address',
            ...preferences.order.filter((p) => FIELDS.hasOwnProperty(p)),
            ...DEFAULT_FIELD_ORDER.filter(
              (f) =>
                FIELDS[f]?.configurable &&
                !preferences.inactive[f] &&
                preferences.order.indexOf(f) === -1
            )
          ]
        : DEFAULT_FIELD_ORDER;
    return columns
      .map((attribute) => ({ ...FIELDS[attribute] }))
      .filter(
        (c) =>
          c &&
          (!c.compType || c.compType === compType) &&
          (!c.featureFlag || featureFlags[c.featureFlag])
      );
  }
);

export const getCompByAddressId = (addressId) =>
  createSelector(getCompsFarm, (farm) => farm[addressId]);

export const getCompAvmForCompsSelectionPage = createSelector(
  getIsRentalContext,
  getAvmForCompSelectionPage,
  getAvmForRentalCompSelectionPage,
  (isRentalContext, avmComps, avmRentalComps) =>
    isRentalContext ? avmRentalComps : avmComps
);

export const getCompValueConfidence = createSelector(
  getIsRentalContext,
  getAvmCompValueConfidence,
  getAvmRentalCompValueConfidence,
  (isRentalContext, compConfidence, rentalCompConfidence) =>
    isRentalContext ? rentalCompConfidence : compConfidence
);

export const getCompSelectionPageAvmKey = createSelector(
  getIsRentalContext,
  getAvmKeyForCompSelectionPage,
  getAvmKeyForRentalCompSelectionPage,
  (isRentalContext, avmKey, avmKeyRental) =>
    isRentalContext ? avmKeyRental : avmKey
);

export const getIsFsdNotAvailable = createSelector(
  getCompsSelectedIds,
  getCompValueConfidence,
  (selectedCompIds, compValueConfidence) => {
    return selectedCompIds.length > 0 && !compValueConfidence.fsd;
  }
);

export const getCompsFiltersOrder = createSelector(getCompType, (compType) =>
  FILTERS_ORDER.filter((attribute) => {
    if (!FILTERS[attribute].compType) {
      // Filters that work for both do not have a compType
      return true;
    } else if (FILTERS[attribute].compType === compType) {
      // Includes filters for current compType
      return true;
    } else {
      // Exclude filters for other compType
      return false;
    }
  })
);

export const getCompsDistanceFilterValue = createSelector(
  getCompsFilters,
  (filters) => filters.distance
);

export const getCompsFiltersApplied = createSelector(
  getCompsFilters,
  (filters) => Object.keys(filters)
);

export const getCompsFiltersForSaveModal = createSelector(
  getCompsFilters,
  (filters) => {
    return Object.entries(filters).map(([filterKey, filterValues]) => {
      const config = FILTERS[filterKey];
      const relativeMethod = FILTER_SAVE_METHODS[config.saveType];
      const value =
        filterKey === 'distance' ? filterValues : filterValues.absoluteValue;
      const saveValue =
        filterKey === 'distance'
          ? relativeMethod.save(value)
          : filterValues.relativeValue;
      const displayValue = relativeMethod.display(filterValues, config);
      const displayModifier =
        [FILTER_SAVE_TYPES.REL_PCT, FILTER_SAVE_TYPES.REL_EXACT].includes(
          config.saveType
        ) && displayValue.slice(0, 1) !== 'M'
          ? ' from Subject'
          : '';
      return {
        label: config.label,
        saveType: config.saveType,
        displayValue: `${displayValue}${displayModifier}`,
        filterKey,
        saveValue
      };
    });
  }
);

export const getCompsSavedFilters = createSelector(
  getIsRentalContext,
  getPreferencesFiltersComps,
  getPreferencesFiltersRentalComps,
  (isRentalContext, savedFiltersComps, savedFiltersRentalComps) =>
    isRentalContext ? savedFiltersRentalComps : savedFiltersComps
);

export const getCompsSavedFiltersDefault = createSelector(
  getIsRentalContext,
  getPreferencesFiltersCompsFilterSetDefault,
  getPreferencesFiltersRentalCompsFilterSetDefault,
  (isRentalContext, savedFiltersComps, savedFiltersRentalComps) =>
    isRentalContext ? savedFiltersRentalComps : savedFiltersComps
);

export const getCompsSavedFiltersDefaultKey = createSelector(
  getIsRentalContext,
  getPreferencesFiltersComps,
  getPreferencesFiltersRentalComps,
  (isRentalContext, savedFiltersComps = {}, savedFiltersRentalComps = {}) =>
    isRentalContext
      ? savedFiltersRentalComps.activeFilterSet
      : savedFiltersComps.activeFilterSet
);

export const getCompsSavedFiltersExist = (state) => {
  const savedFilters = getCompsSavedFilters(state);
  return savedFilters?.filterSets && !isEmpty(savedFilters.filterSets);
};

export const _isSavedFilterApplied = (
  filtersApplied,
  filtersSaved,
  propertyLookupId
) => {
  if (
    !filtersApplied ||
    !filtersSaved ||
    isEmpty(filtersSaved.values) ||
    isEmpty(filtersApplied)
  ) {
    return false;
  }
  const savedFilterMap = new Map(Object.entries(filtersSaved.values));
  for (let filterKey in filtersApplied) {
    const savedFilter = savedFilterMap.get(filterKey);
    if (filterKey !== 'distance') {
      const { relativeValue } = filtersApplied[filterKey];
      if (
        savedFilter &&
        // Saved filter exists and is set to the same value as applied
        (isEqual(savedFilter.value, relativeValue) ||
          // Filter could not be applied because subject is missing required data
          relativeValue?.error)
      ) {
        savedFilterMap.delete(filterKey);
      } else {
        return false;
      }
    } else {
      const distanceValue = filtersApplied[filterKey];
      if (
        (typeof distanceValue === 'string' &&
          propertyLookupId === filtersSaved.createdBy) ||
        (savedFilter && isEqual(savedFilter.value, distanceValue))
      ) {
        savedFilterMap.delete(filterKey);
      } else {
        return false;
      }
    }
  }
  return !savedFilterMap.size;
};
export const getCompsAreSavedFiltersCurrentlyApplied = createSelector(
  getCompsFilters,
  getCompsSavedFilters,
  getPropertyLookupId,
  (filtersApplied, filtersSaved, propertyLookupId) => {
    // Saved not applied or Filters not applied
    if (filtersSaved && filtersSaved.filterSets) {
      for (let filterSetId in filtersSaved.filterSets) {
        if (
          _isSavedFilterApplied(
            filtersApplied,
            filtersSaved.filterSets[filterSetId],
            propertyLookupId
          )
        ) {
          return filterSetId;
        }
      }
    }
    return false;
  }
);

export const getCompsSavedFilterSetById = (filterSetId) =>
  createSelector(getCompsSavedFilters, (filtersSaved) => {
    return (
      filtersSaved &&
      filtersSaved.filterSets &&
      filtersSaved.filterSets[filterSetId]
    );
  });

export const getCompsSavedFilterSetDropdownOptions = createSelector(
  getCompsSavedFilters,
  (filtersSaved) => {
    return filtersSaved && filtersSaved.filterSets
      ? Object.entries(filtersSaved.filterSets)
          .map(([filterSetId, filterSet]) => {
            return {
              label: filterSet.label,
              value: filterSetId,
              primary: filterSetId === filtersSaved.activeFilterSet
            };
          })
          .sort((a, b) => (a.label > b.label ? 1 : -1))
      : [];
  }
);
