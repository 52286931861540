import React from 'react';
import { isLoading } from 'src/lib/utils/general';
import { useDocumentRole } from '../../../hooks';
import { DocumentRoles, FilterDocument } from '../../../types';
import { CompFields } from '../../../types/CompFields';
import { CompFilterButton } from './CompFilterButton';
import { capitalizeFirstLetter, trimWithEllipsis } from 'src/lib/utils/strings';
import { CompFilterPropertyStatus } from '../../CompFilters/CompFilterPropertyStatus';
import { Skeleton } from 'src/lib/components';
interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
}
const COMP_FIELD = CompFields.propertyStatus;
export const CompFilterButtonPropertyStatus = ({
  dataHcName,
  reportId,
  className
}: Props) => {
  const {
    status: filterDocumentStatus,
    data: [filterDocument]
  } = useDocumentRole<FilterDocument>(reportId, DocumentRoles.Filter);
  if (isLoading(filterDocumentStatus) || !filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }
  const filterValue = filterDocument.data.filters[COMP_FIELD];
  const active = !!filterValue;
  return (
    <CompFilterButton
      reportId={reportId}
      label={
        filterValue?.absoluteValue
          ? trimWithEllipsis(
              filterValue.absoluteValue.map(capitalizeFirstLetter).join(', '),
              30
            )
          : 'Status'
      }
      dataHcName={dataHcName}
      className={className}
      compField={COMP_FIELD}
      content={
        <CompFilterPropertyStatus
          reportId={reportId}
          dataHcName={`${dataHcName}-content`}
        />
      }
      active={active}
    />
  );
};
