import { useEffect, useState } from 'react';

interface Props {
  // Set intial active state and let the component handle it internally after mount
  activeInitial?: boolean;
  // Allow parent to control active state
  active?: boolean;
  // Modify browser history to close with back button
  // pushState?: boolean;
}
export const useDialogActiveState = (options: Props | undefined = {}) => {
  const { active, activeInitial = false } = options;
  const [activeInternal, setActiveInternal] = useState<boolean>(
    activeInitial === undefined
      ? active === undefined
        ? false
        : active
      : activeInitial
  );
  useEffect(() => {
    if (active !== undefined && active !== activeInternal) {
      setActiveInternal(active);
    }
  }, [active]);
  const setDialogActiveState = (a: boolean) => {
    if (active === undefined) {
      setActiveInternal(a);
    }
  };
  const handleDialogOpen = () => {
    setDialogActiveState(true);
  };
  const handleDialogClose = () => {
    setDialogActiveState(false);
  };
  // Commenting this out for now.
  // I think we should implement this functionality
  // with the router
  // useEffect(() => {
  //   if (pushState) {
  //     if (active) {
  //       window.history.pushState({}, '', window.location.pathname);
  //     } else {
  //       // TODO: PopState would go here.
  //     }
  //   }
  // }, [active]);
  return {
    active: activeInternal,
    setDialogActiveState,
    handleDialogOpen,
    handleDialogClose
  };
};
