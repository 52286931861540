export const isFunc = (v) => typeof v === 'function';
export const isArray = (v) => v && v.constructor === Array;
export const isObject = (v) => v && v.constructor === Object;
export const isString = (v) => typeof v === 'string';
export const isPopulated = (v) => ![undefined, null, ''].includes(v);
// SECURITY: false posititive, this function does not return html
// eslint-disable-next-line xss/no-mixed-html
export const isSvg = (v) => typeof v === 'string' && v.slice(0, 4) === '<svg';
export const noop = () => {};

// Takes list of ids and outputs: { [id]: true }
export const toIdLookup = (ids) => {
  const value =
    ids && ids.length
      ? ids.length === 1
        ? { [ids[0]]: true }
        : ids.reduce((acc, cv) => {
            if (!isObject(acc)) {
              return {
                [acc]: true,
                [cv]: true
              };
            } else {
              return {
                ...acc,
                [cv]: true
              };
            }
          })
      : {};
  return value;
};
