import { GraphQLClient } from 'graphql-request';
import { call, select, put } from 'redux-saga/effects';
import get from 'lodash/get';

import HC_CONSTANTS from 'HC_CONSTANTS';

import { tokenExpired } from 'actions/auth';
import { APIClientError } from 'legacy/utils/error';
import { getToken } from 'selectors/account-details';
import { refreshAccessToken } from 'src/legacy/utils/refresh-token-callback';

const { PROPERTY_GRAPH_URL } = HC_CONSTANTS;

// let client;
const graphqlRequest = function* (query, variables, newToken, retries = 0) {
  let token;
  if (newToken) {
    token = newToken;
  } else {
    token = yield select(getToken);
  }
  const client = new GraphQLClient(PROPERTY_GRAPH_URL, {
    headers: {
      'HC-Auth-Token': token
    }
  });

  try {
    const response = yield call([client, client.request], query, variables);
    return response;
  } catch (error) {
    const statusCode = get(error, ['response', 'status']);
    if (statusCode === 401) {
      if (retries === 0) {
        try {
          const userContext = yield call(refreshAccessToken);
          const retryResponse = yield call(
            graphqlRequest,
            query,
            variables,
            userContext.validity.token,
            retries + 1
          );
          return retryResponse;
        } catch (e) {
          console.error(e);
          yield put(tokenExpired());
        }
      }
    } else {
      throw new APIClientError(`${statusCode} Error: ${error.message}`, {
        statusCode,
        statusText: statusCode && statusCode.toString(),
        requestUrl: PROPERTY_GRAPH_URL,
        requestBody: query,
        responseJSON: error.response,
        messageRaw: error.message
      });
    }
  }
};

export default {
  fetchAddress: (propertyIdentifier) =>
    graphqlRequest(
      `
    query {
      propertyLookup(id: $id) {
        address {
          streetAddress
          city
          state
          zipcode
          unit
          slug
          hcAddressId
        }
      }
    }
  `,
      { id: propertyIdentifier }
    ),
  fetchMlsCoverage: (hcAddressId) =>
    graphqlRequest(`
    query {
      propertyLookup(id: { hcAddressId: ${hcAddressId} }) {
        mlsCoverage {
          covered
          boards {
            name
            covered
            reciprocity
          }
        }
      }
    }
  `),
  // There is more listing data in property-graph but this is all we need for now
  fetchListings: (addressId) =>
    graphqlRequest(`
    query {
      propertyLookup(id: { addressId: ${addressId} }) {
        listings {
          entityID
          listingID
          listingOfficeName
          price
          status
          statusDate
          publicRemarks
          isListingRental
          mlsID
          association {
            hasAssociation
            fees {
              amount
              frequency
              type
            }
          }
          listingStatus {
            contractPrice
            currentPrice
            currentSaleStatus
            currentStatus
            currentStatusDate
            daysOnMarket
            firstAppearanceDate
            listingDate
            listingPrice
            listingPriceRange
            offMarketDate
            onMarketDate
            originalListingPrice
            saleDate
            salePrice
            salePricePerGla
          }
          photos {
            representation {
              height
              httpUrl
              width
            }
          }
          taxSummary {
            amount
            year
          }
        }
      }
    }
  `),
  fetchMls: (mlsId) =>
    graphqlRequest(`
    query {
      mlsLookup(id: { id: ${mlsId} }) {
        abbreviation
        fips {
          id
        }
        lastRefreshed
        mlsID
        name
        regulations {
          active
          augmenting
          cancelled
          closed
          closedLoginPrice
          comingSoon
          comingling
          contingent
          contingentWithKickOut
          cooperatingBrokerage
          copyrightStatement
          deleted
          disclaimer
          expired
          leased
          logo
          logoOverlay
          pending
          photosClosedAll
          photosClosedFirstOnly
          photosClosedHistory
          photosClosedLogin
          photosLogoOverlay
          sold
          unknown
          withdrawn
        }
      }
    }
  `),
  searchByMlsNumber: (items) => {
    return graphqlRequest(
      `
      query searchByMlsNumbers ($items: [MlsNumberSearchInputItem]!) {
        searchByMlsNumbers(mlsNumbers: {
        items: $items
        }) {
          address {
            hcAddressId
            slug
            streetAddress
            unit
            city
            state
            zipcode
          }
          association {
            hasAssociation
            fees {
              amount
              frequency
              type
            }
          }
          countyLegal {
            apn
            subdivision
          }
          entityID
          listingID
          mlsID
          external {
            parking {
              has
              count
              desc
            }
          }
          site {
            lot {
              areaSqFt
            }
          }
          location {
            latitude
            longitude
            precision
          }
          price
          listingStatus{
            MlsState
            daysOnMarket
            contractDate
            contractPrice
            currentStatus
            currentStatusDate
            listingDate
            listingPrice
            offMarketDate
            onMarketDate
            saleDate
            salePrice
            salePricePerGla
          }
          livingSpace {
            basement {
              has
            }
            bathrooms {
              countTotal
            }
            bedrooms {
              countTotal
            }
            grossLivingArea
          }
          publicRemarks
          structure {
            storiesCount
            yearBuilt
            
          }
          taxSummary {
            amount
            rateValue
            year
          }
        }
      }
    `,
      { items }
    );
  }
};
