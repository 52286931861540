import get from 'lodash/get';
import classNames from 'classnames';

import {
  COMP_TYPE_DEFAULT,
  COMP_TYPE_RENTAL,
  HELP_TEXT_SIMILARITY,
  HELP_TEXT_FLIP
} from 'legacy/appstore/constants';

import {
  PLACEHOLDER,
  streetAddressFormatter,
  suffixWithPlaceholderFormatter,
  dollarsFormatter,
  wholeNumberFormatter,
  boolYesNo,
  placeholderFormatter2
} from 'legacy/utils/formatters';
import { displayDateCompact } from 'legacy/utils/dates';
import { isPopulated } from 'legacy/utils/utils';
import { isLocationUnknown } from 'legacy/utils/property-details';

import SimilarityScoreTableCell from 'legacy/components/SimilarityScoreTableCell';
import TableCellAddress from 'legacy/components/TableCellAddress';
import TableCellSimilarity from 'legacy/components/TableCellSimilarity';
import TableHeaderInfo from 'legacy/components/TableHeaderInfo';
import SubjectIcon from 'legacy/components/SubjectIcon';

import { CompsTable as compsTableTheme } from 'legacy/css-modules';

export const computedValueForField = (propertyDetails, key, subject) => {
  const rawValue = propertyDetails[key];
  // Some formatters require just the raw value, others use multiple values to
  // return a computed value.  Return the raw value if formatter key doesn't exist.
  return (
    get(FORMATTERS, key, (v) => v)(rawValue, propertyDetails, subject, key) ||
    PLACEHOLDER
  );
};

export const AddressCell = ({
  streetAddress,
  unit,
  unitDesignator,
  city,
  state,
  zipcode
}) => {
  const addressLine1 = streetAddressFormatter(
    streetAddress,
    unit,
    unitDesignator
  );
  const addressLine2 = `${city}, ${state} ${zipcode}`;
  return (
    <div>
      <div className="text-ellipsis" title={addressLine1}>
        {addressLine1}
      </div>
      <div className="text-ellipsis lesser-text" title={addressLine2}>
        {addressLine2}
      </div>
    </div>
  );
};

const similarityLabelForLevel = (level, property = {}, key) => {
  const {
    similarityLevel,
    similarityScore,
    similarityScoreAdjusted,
    similarityLevelAdjusted
  } = property;
  return (
    <SimilarityScoreTableCell
      similarityLevel={
        key === 'similarityScore'
          ? similarityLevel
          : isPopulated(similarityLevelAdjusted)
          ? similarityLevelAdjusted
          : similarityLevel
      }
      similarityScore={
        key === 'similarityScore'
          ? similarityScore
          : isPopulated(similarityScoreAdjusted)
          ? similarityScoreAdjusted
          : similarityScore
      }
    />
  );
};

const FORMATTERS = {
  address: (_, property) => <AddressCell {...property} />,
  distance: (rawValue, comp, subject) => {
    if (isLocationUnknown(get(subject, 'geoPrecision'))) {
      return PLACEHOLDER;
    }
    const intermediate = typeof rawValue === 'number' && rawValue.toFixed(2);
    return suffixWithPlaceholderFormatter(intermediate, 'mi', PLACEHOLDER);
  },
  similarityScore: (rawValue, property, subject, key) =>
    similarityLabelForLevel(rawValue, property, key),
  similarityScoreAdjusted: (rawValue, property, subject, key) =>
    similarityLabelForLevel(rawValue, property, key),
  salesDate: (rawValue) => rawValue && displayDateCompact(rawValue),
  salesPrice: (rawValue) => rawValue && dollarsFormatter(rawValue),
  userBasePriceAdjustment: (rawValue) => rawValue && dollarsFormatter(rawValue),
  lastListDate: (rawValue) => rawValue && displayDateCompact(rawValue),
  lastListDateRental: (rawValue) => rawValue && displayDateCompact(rawValue),
  leasedDate: (rawValue) => rawValue && displayDateCompact(rawValue),
  listingStatus: (rawValue) => rawValue,
  lastListPrice: (rawValue) => rawValue && dollarsFormatter(rawValue),
  lastListPriceRental: (rawValue) => rawValue && dollarsFormatter(rawValue),
  leasedPrice: (rawValue) => rawValue && dollarsFormatter(rawValue),
  currentValue: (rawValue) => rawValue && dollarsFormatter(rawValue),
  livingArea: (rawValue) => rawValue && `${wholeNumberFormatter(rawValue)}`,
  daysOnMarket: (rawValue) => rawValue && `${wholeNumberFormatter(rawValue)}`,
  daysOnMarketActive: (rawValue) =>
    rawValue && `${wholeNumberFormatter(rawValue)}`,
  daysOnMarketCumulative: (rawValue) =>
    rawValue && `${wholeNumberFormatter(rawValue)}`,
  lotSize: (rawValue) => rawValue && `${wholeNumberFormatter(rawValue)}`,
  closePricePerSqFt: (_, property) => {
    return property.salesPrice && property.grossLivingAreaSqft
      ? dollarsFormatter(property.salesPrice / property.grossLivingAreaSqft)
      : PLACEHOLDER;
  },
  listPricePerSqFt: (_, property) => {
    return property.lastListPrice && property.grossLivingAreaSqft
      ? dollarsFormatter(property.lastListPrice / property.grossLivingAreaSqft)
      : PLACEHOLDER;
  },
  rentalAvm: (_, property) =>
    dollarsFormatter(get(property, ['rentalAvm', 'priceMean'])),
  userAdjustment: (rawValue) => rawValue && dollarsFormatter(rawValue),
  userAdjustmentRental: (rawValue) => rawValue && dollarsFormatter(rawValue),
  pricePerSqftRentalListed: (_, property) => {
    return property.lastListPriceRental && property.grossLivingAreaSqft
      ? dollarsFormatter(
          property.lastListPriceRental / property.grossLivingAreaSqft,
          2
        )
      : PLACEHOLDER;
  },
  pricePerSqftRentalLeased: (_, property) => {
    return property.leasedPrice && property.grossLivingAreaSqft
      ? dollarsFormatter(property.leasedPrice / property.grossLivingAreaSqft, 2)
      : PLACEHOLDER;
  },
  hcRent: (_, property) =>
    dollarsFormatter(property.rentalAvm && property.rentalAvm.priceMean),
  pool: boolYesNo,
  basement: boolYesNo,
  isDistressed: boolYesNo,
  isFlip: boolYesNo
};

export const DEFAULT_FIELD_ORDER = [
  'address',
  'similarity',
  'distance',
  'bedrooms',
  'bathrooms',
  'livingArea',
  'lotSize',
  'propertyType',
  'yearBuilt',
  'propertyStatus',
  'propertyStatusRental',
  'subdivisionName',
  'salesDate',
  'salesPrice',
  'closePricePerSqFt',
  'leasedDate',
  'leasedPrice',
  'lastListDate',
  'lastListPrice',
  'lastListDateRental',
  'lastListPriceRental',
  'listPricePerSqFt',
  'daysOnMarketActive',
  'daysOnMarketCumulative',
  'currentValue',
  'rentalAvm',
  'pricePerSqftRentalLeased',
  'pricePerSqftRentalListed',
  'pool',
  'garageNumCars',
  'stories',
  'basement',
  'isDistressed',
  'isFlip'
];

export const FIELDS = {
  address: {
    label: 'Address',
    attribute: 'address',
    sortAttribute: 'streetAddress',
    sortable: true,
    CellContent: TableCellAddress,
    fixed: true,
    fixedOffset: 99
  },
  daysOnMarketActive: {
    label: 'ADOM',
    attribute: 'activeDaysOnMarket',
    compType: COMP_TYPE_DEFAULT,
    title: 'Active Days On Market',
    sortable: true,
    align: 'right',
    formatter: (v) => (isPopulated(v) ? wholeNumberFormatter(v) : PLACEHOLDER),
    configurable: true,
    HeaderCellContent: TableHeaderInfo,
    info: 'Number of active days on market without an accepted offer. This metric is calculated by HouseCanary and measures the number of days between the listing date and when an offer has been accepted by the seller.',
    featureFlag: 'deprecated'
  },
  daysOnMarketCumulative: {
    label: 'CDOM',
    attribute: 'cumulativeDaysOnMarket',
    compType: COMP_TYPE_DEFAULT,
    title: 'Cumulative Days On Market',
    sortable: true,
    align: 'right',
    formatter: (v) => (isPopulated(v) ? wholeNumberFormatter(v) : PLACEHOLDER),
    configurable: true,
    HeaderCellContent: TableHeaderInfo,
    info: 'Cumulative number of days on market. This metric is calculated by HouseCanary and measures the number of days between the listing date and when a transaction is officially closed.',
    featureFlag: 'deprecated'
  },
  similarity: {
    configurable: true,
    sortAttribute: 'similarityScoreAdjusted',
    label: 'Similarity',
    attribute: 'similarity',
    HeaderCellContent: TableHeaderInfo,
    CellContent: TableCellSimilarity,
    cellClassName: compsTableTheme.Similarity,
    sortable: true,
    align: 'center',
    info: HELP_TEXT_SIMILARITY
  },
  distance: {
    configurable: true,
    label: 'Distance',
    attribute: 'distanceMiles',
    HeaderCellContent: TableHeaderInfo,
    title: 'Distance from subject property',
    sortable: true,
    align: 'right',
    formatter: (v) =>
      suffixWithPlaceholderFormatter(
        typeof v === 'number' && v.toFixed(2),
        'mi',
        PLACEHOLDER
      )
  },
  bedrooms: {
    configurable: true,
    label: 'Beds',
    attribute: 'bedrooms',
    HeaderCellContent: TableHeaderInfo,
    sortable: true,
    align: 'right',
    formatter: placeholderFormatter2
  },
  bathrooms: {
    configurable: true,
    label: 'Baths',
    attribute: 'bathrooms',
    HeaderCellContent: TableHeaderInfo,
    sortable: true,
    align: 'right',
    formatter: placeholderFormatter2
  },
  livingArea: {
    configurable: true,
    label: 'SQFT',
    attribute: 'grossLivingAreaSqft',
    HeaderCellContent: TableHeaderInfo,
    title: 'Gross living area square feet',
    sortable: true,
    align: 'right',
    formatter: (v) => (isPopulated(v) ? wholeNumberFormatter(v) : PLACEHOLDER)
  },
  lotSize: {
    configurable: true,
    label: 'Lot SQFT',
    attribute: 'siteAreaSqft',
    HeaderCellContent: TableHeaderInfo,
    title: 'Site area square feet',
    sortable: true,
    align: 'right',
    formatter: (v) => (isPopulated(v) ? wholeNumberFormatter(v) : PLACEHOLDER)
  },
  propertyType: {
    HeaderCellContent: TableHeaderInfo,
    configurable: true,
    label: 'Property Type',
    sortable: true,
    attribute: 'propertyType',
    width: 150,
    title: 'Property type',
    align: 'left',
    formatter: placeholderFormatter2
  },
  yearBuilt: {
    configurable: true,
    label: 'Year Built',
    sortable: true,
    attribute: 'yearBuilt',
    HeaderCellContent: TableHeaderInfo,
    title: 'Year Built',
    align: 'left',
    formatter: placeholderFormatter2
  },
  propertyStatus: {
    compType: COMP_TYPE_DEFAULT,
    configurable: true,
    label: 'Listing Status',
    attribute: 'propertyStatus',
    HeaderCellContent: TableHeaderInfo,
    title: 'Current listing status',
    sortable: true,
    align: 'left',
    formatter: placeholderFormatter2
  },
  propertyStatusRental: {
    compType: COMP_TYPE_RENTAL,
    configurable: true,
    label: 'Listing Status',
    attribute: 'propertyStatusRental',
    HeaderCellContent: TableHeaderInfo,
    title: 'Current listing status',
    sortable: true,
    align: 'left',
    formatter: placeholderFormatter2
  },
  subdivisionName: {
    configurable: true,
    label: 'Subdivision',
    attribute: 'subdivisionName',
    HeaderCellContent: TableHeaderInfo,
    title: 'Subdivision',
    sortable: true,
    align: 'left',
    formatter: placeholderFormatter2
  },
  salesDate: {
    compType: COMP_TYPE_DEFAULT,
    configurable: true,
    label: 'Sold Date',
    attribute: 'salesDate',
    HeaderCellContent: TableHeaderInfo,
    title: 'Date Sold',
    sortable: true,
    formatter: displayDateCompact
  },
  salesPrice: {
    compType: COMP_TYPE_DEFAULT,
    configurable: true,
    label: 'Sold Price',
    attribute: 'salesPrice',
    HeaderCellContent: TableHeaderInfo,
    sortable: true,
    align: 'right',
    formatter: (v) => (isPopulated(v) ? dollarsFormatter(v) : PLACEHOLDER)
  },
  closePricePerSqFt: {
    compType: COMP_TYPE_DEFAULT,
    configurable: true,
    label: 'Sold $/SQFT',
    attribute: 'pricePerSqft',
    HeaderCellContent: TableHeaderInfo,
    title: 'Price per square foot',
    sortable: true,
    align: 'right',
    formatter: (_, row) => {
      const salesPrice = get(row, ['data', 'salesPrice']);
      const gla = get(row, ['data', 'grossLivingAreaSqft']);
      return salesPrice && gla
        ? dollarsFormatter(salesPrice / gla)
        : PLACEHOLDER;
    }
  },
  leasedDate: {
    compType: COMP_TYPE_RENTAL,
    sortable: true,
    configurable: true,
    label: 'Leased Date',
    attribute: 'leasedDate',
    HeaderCellContent: TableHeaderInfo,
    title: 'Date leased',
    formatter: (v) => (v ? displayDateCompact(v) : PLACEHOLDER)
  },
  leasedPrice: {
    compType: COMP_TYPE_RENTAL,
    sortable: true,
    configurable: true,
    label: 'Leased Price',
    attribute: 'leasedPrice',
    HeaderCellContent: TableHeaderInfo,
    align: 'right',
    formatter: (v) => (v ? dollarsFormatter(v) : PLACEHOLDER)
  },
  lastListDate: {
    compType: COMP_TYPE_DEFAULT,
    configurable: true,
    label: 'List Date',
    attribute: 'lastListDate',
    HeaderCellContent: TableHeaderInfo,
    title: 'Date listed',
    sortable: true,
    formatter: (v) => (v ? displayDateCompact(v) : PLACEHOLDER)
  },
  lastListPrice: {
    compType: COMP_TYPE_DEFAULT,
    configurable: true,
    label: 'List Price',
    attribute: 'lastListPrice',
    HeaderCellContent: TableHeaderInfo,
    sortable: true,
    formatter: (v) => (v ? dollarsFormatter(v) : PLACEHOLDER)
  },
  lastListDateRental: {
    compType: COMP_TYPE_RENTAL,
    configurable: true,
    label: 'List Date',
    attribute: 'lastListDateRental',
    HeaderCellContent: TableHeaderInfo,
    formatter: (v) => (v ? displayDateCompact(v) : PLACEHOLDER),
    title: 'Date listed',
    sortable: true
  },
  lastListPriceRental: {
    compType: COMP_TYPE_RENTAL,
    configurable: true,
    label: 'List Price',
    attribute: 'lastListPriceRental',
    HeaderCellContent: TableHeaderInfo,
    sortable: true,
    formatter: (v) => (v ? dollarsFormatter(v) : PLACEHOLDER)
  },
  listPricePerSqFt: {
    compType: COMP_TYPE_DEFAULT,
    configurable: true,
    label: 'List $/SQFT',
    attribute: 'listPricePerSqFt',
    HeaderCellContent: TableHeaderInfo,
    title: 'Price per square foot',
    sortable: true,
    align: 'right',
    formatter: (_, row) => {
      const price = get(row, ['data', 'lastListPrice']);
      const gla = get(row, ['data', 'grossLivingAreaSqft']);
      return price && gla ? dollarsFormatter(price / gla) : PLACEHOLDER;
    }
  },
  currentValue: {
    compType: COMP_TYPE_DEFAULT,
    configurable: true,
    label: 'HouseCanary Value',
    attribute: 'currentValue',
    HeaderCellContent: TableHeaderInfo,
    title: 'HouseCanary value',
    sortable: true,
    align: 'right',
    formatter: (v) => (v ? dollarsFormatter(v) : PLACEHOLDER)
  },
  rentalAvm: {
    compType: COMP_TYPE_RENTAL,
    sortable: true,
    configurable: true,
    label: 'HouseCanary Rental Value',
    attribute: 'rentalAvm',
    HeaderCellContent: TableHeaderInfo,
    title: 'HouseCanary rental value',
    align: 'right',
    formatter: (v) => {
      const value = get(v, 'priceMean');
      return value ? dollarsFormatter(value) : PLACEHOLDER;
    }
  },
  pricePerSqftRentalLeased: {
    compType: COMP_TYPE_RENTAL,
    sortable: true,
    configurable: true,
    label: 'Leased $/SQFT',
    attribute: 'pricePerSqftRentalLeased',
    HeaderCellContent: TableHeaderInfo,
    title: 'Price per square foot (Leased)',
    align: 'right',
    formatter: (_, row) => {
      const price = get(row, ['data', 'leasedPrice']);
      const gla = get(row, ['data', 'grossLivingAreaSqft']);
      return price && gla ? dollarsFormatter(price / gla, 2) : PLACEHOLDER;
    }
  },
  pricePerSqftRentalListed: {
    compType: COMP_TYPE_RENTAL,
    sortable: true,
    configurable: true,
    label: 'List $/SQFT',
    attribute: 'pricePerSqftRentalListed',
    HeaderCellContent: TableHeaderInfo,
    title: 'Price per square foot (Listed)',
    align: 'right',
    formatter: (_, row) => {
      const price = get(row, ['data', 'lastListPriceRental']);
      const gla = get(row, ['data', 'grossLivingAreaSqft']);
      return price && gla ? dollarsFormatter(price / gla, 2) : PLACEHOLDER;
    }
  },
  pool: {
    configurable: true,
    label: 'Pool',
    sortable: true,
    attribute: 'pool',
    HeaderCellContent: TableHeaderInfo,
    title: 'Pool',
    align: 'left',
    formatter: boolYesNo
  },
  garageNumCars: {
    configurable: true,
    label: 'Garage Spaces',
    sortable: true,
    attribute: 'garageNumCars',
    HeaderCellContent: TableHeaderInfo,
    title: 'Garage Spaces',
    align: 'right',
    formatter: placeholderFormatter2
  },
  stories: {
    configurable: true,
    label: 'Stories',
    sortable: true,
    attribute: 'stories',
    HeaderCellContent: TableHeaderInfo,
    title: 'Stories',
    align: 'right',
    formatter: placeholderFormatter2
  },
  basement: {
    configurable: true,
    label: 'Basement',
    sortable: true,
    attribute: 'basement',
    HeaderCellContent: TableHeaderInfo,
    title: 'Basement',
    align: 'left',
    formatter: boolYesNo
  },
  isDistressed: {
    compType: COMP_TYPE_DEFAULT,
    configurable: true,
    label: 'Distressed',
    sortable: true,
    attribute: 'isDistressed',
    title: 'Distressed',
    align: 'left',
    formatter: boolYesNo
  },
  isFlip: {
    compType: COMP_TYPE_DEFAULT,
    configurable: true,
    label: 'Flip',
    sortable: true,
    attribute: 'flips',
    HeaderCellContent: TableHeaderInfo,
    title: 'Flip',
    align: 'left',
    formatter: boolYesNo,
    featureFlag: 'deprecated',
    info: HELP_TEXT_FLIP
  }
};

export const formatCompListTableDatum = (
  property,
  viewType,
  i = 1,
  selectedIdsLookup = {},
  isKeywordMatch,
  isEditable,
  forceDisable,
  selectedCompsLimit
) => {
  const {
    addressId,
    city,
    similarityLevel,
    similarityLevelAdjusted,
    similarityScore,
    similarityScoreAdjusted,
    state,
    streetAddress,
    unit,
    unitDesignator,
    zipcode,
    isSubject
  } = property;
  return {
    key: `table-${addressId}`,
    className: isSubject
      ? compsTableTheme.SubjectRow
      : i % 2
      ? classNames(compsTableTheme.Even, {
          [compsTableTheme.isKeywordMatch]: isKeywordMatch
        })
      : classNames(compsTableTheme.Odd, {
          [compsTableTheme.isKeywordMatch]: isKeywordMatch
        }),
    disabled:
      forceDisable ||
      isSubject ||
      (!selectedIdsLookup[addressId] &&
        Object.keys(selectedIdsLookup).length >= selectedCompsLimit) ||
      !isEditable,
    SelectCellContent: isSubject ? SubjectIcon : undefined,
    selected: selectedIdsLookup[addressId],
    fixed: isSubject,
    fixedOffset: isSubject ? (viewType === 'compact' ? 38 : 42) : undefined,
    data: {
      address: {
        streetAddress,
        unit,
        unitDesignator,
        city,
        state,
        zipcode
      },
      similarity: !isSubject && {
        similarityLevel,
        similarityLevelAdjusted,
        similarityScore,
        similarityScoreAdjusted
      },
      ...property
    }
  };
};
