import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './SingleColumnContent.css';

interface Props {
  children: ReactNode;
  className?: string;
}
export const SingleColumnContent = ({ children, className }: Props) => {
  return (
    <div className={classNames(styles.SingleColumnContent, className)}>
      {children}
    </div>
  );
};
