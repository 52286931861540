/**
 * Extend Error to create our own error constructors with custom properties
 * @param {String} name - name of the custom error class
 * @param {Object} customPropKeys - properties to assign to the custom error class
 */
const createNewErrorType = (name, customPropKeys = []) => {
  CustomError.prototype = Object.create(Error.prototype);
  CustomError.prototype.constructor = CustomError;
  CustomError.prototype.name = name;

  /**
   * The new Error contstructor with custom properties assigned
   * @param {String} message - error message
   * @param {Number} customProps - custom properties for checking in catch blocks
   */
  function CustomError(message, customProps) {
    this.message = message;
    this.stack = Error().stack;
    // Custom properties
    customPropKeys.forEach((key) => {
      this[key] = customProps[key];
    });
  }
  return CustomError;
};

export const APIClientError = createNewErrorType('APIClientError', [
  'statusCode',
  'statusText',
  'messageRaw',
  'requestUrl',
  'requestBody',
  'responseJSON'
]);
