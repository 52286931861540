import { call, select } from 'redux-saga/effects';
import { getPropertyLookup } from 'selectors/report-data.selectors';
import { getSubjectDefault } from 'selectors/subject';
import { checkShouldScrub } from 'legacy/utils/mls-compliance.utils';

export function* selectScrubDataArgs() {
  const subject = yield select(getSubjectDefault);
  const propertyLookup = yield select(getPropertyLookup);
  return {
    state: subject.state,
    orgId: propertyLookup.organizationId
  };
}
function* shouldScrubData() {
  const { state, orgId } = yield call(selectScrubDataArgs);
  return checkShouldScrub(state, orgId);
}

export function* scrubDataTransactionHistory(transactionHistoryResponse) {
  const shouldScrub = yield call(shouldScrubData);
  if (shouldScrub) {
    transactionHistoryResponse?.response?.transactionHistory?.events?.map(
      (e) => {
        e.appreciation = null;
        if (e.event === 'sold' || e.event === 'rentalLeased') {
          e.price = null;
        }
        return e;
      }
    );
    return transactionHistoryResponse;
  } else {
    return transactionHistoryResponse;
  }
}

export function* scrubDataListings(propertyLookupListings) {
  const shouldScrub = yield call(shouldScrubData);
  if (shouldScrub) {
    propertyLookupListings.listings = propertyLookupListings.listings.map(
      (listing) => {
        if (listing?.status === 'SOLD' || listing?.status === 'LEASED') {
          listing.price = null;
        }
        if (listing?.listingStatus) {
          listing.listingStatus.contractPrice = null;
          listing.listingStatus.salePrice = null;
          listing.listingStatus.salePricePerGla = null;
          if (listing?.status === 'SOLD' || listing?.status === 'LEASED') {
            listing.listingStatus.currentPrice = null;
          }
        }
        return listing;
      }
    );
    return propertyLookupListings;
  } else {
    return propertyLookupListings;
  }
}

export function* scrubDataToComp(comp) {
  const shouldScrub = yield call(shouldScrubData);
  if (shouldScrub) {
    comp.salesPrice = null;
    comp.salesPriceAdjusted = null;
    comp.leasedPrice = null;
    return comp;
  } else {
    return comp;
  }
}
