import React, { useRef } from 'react';
import { PhotoCarousel } from 'src/lib/property-lookup';
import { CompPropertyCardContent } from 'src/lib/report-api/features/CompPropertyCard';
import { CompSchema } from '../../types/schemas';
import classNames from 'classnames';
import styles from './CompMarkerPopup.css';
import { Popup } from 'react-map-gl';
import { useClickOutsideComponent } from 'src/lib/hooks/useClickOutsideComponent';
import { useCompCompareOverlay } from '../../hooks/useCompCompareOverlay';
import { usePhotosPage } from 'src/lib/report-api/hooks/usePhotosPage';
import { setCompPhotoPageAction } from 'src/lib/report-api/providers/PhotosPage/photosPage.actions';

interface Props {
  dataHcName: string;
  className?: string;
  reportId: number;
  documentId?: string;
  compSchema?: CompSchema;
  onClose: VoidFunction;
}
export const CompMarkerPopup = ({
  dataHcName,
  reportId,
  compSchema,
  className,
  documentId,
  onClose
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { dispatch } = usePhotosPage();
  const localCompId = compSchema?.compID;
  useClickOutsideComponent(ref, onClose);
  const { compCompareOpen } = useCompCompareOverlay();
  const { latitude, longitude } = compSchema?.geoLocation || {};
  if (!compSchema || !latitude || !longitude) {
    return null;
  }
  return (
    <Popup
      className={styles.CompMarkerPopup}
      longitude={longitude}
      latitude={latitude}
      closeButton={false}
      closeOnClick={false}
      onClose={onClose}
      anchor="bottom"
      offsetTop={-35}
      offsetLeft={2}
    >
      <div
        ref={ref}
        data-hc-name={dataHcName}
        className={classNames(styles.CompMarkerPopupContent, className)}
      >
        <div className={styles.Photos}>
          <PhotoCarousel
            hcAddressId={compSchema.address.hcAddressId}
            dataHcName={`${dataHcName}-thumbnail-carousel`}
            onClick={(e) => {
              e.stopPropagation();
              if (localCompId != null) {
                dispatch(
                  setCompPhotoPageAction(
                    localCompId,
                    reportId,
                    compSchema.address.hcAddressId
                  )
                );
              }
            }}
          />
        </div>
        <div
          className={styles.Content}
          onClick={() => compCompareOpen({ documentId, compSchema })}
        >
          <CompPropertyCardContent
            dataHcName={`${dataHcName}-content`}
            reportId={reportId}
            documentId={documentId}
            compSchema={compSchema}
          />
        </div>
      </div>
    </Popup>
  );
};
