import {
  actionChannel,
  all,
  put,
  select,
  takeLatest,
  take,
  call
} from '@redux-saga/core/effects';
import {
  personalizationFetch,
  personalizationFetchError,
  personalizationFetchSuccess,
  PersonalizationRequiredAction,
  PERSONALIZATION_FETCH,
  PERSONALIZATION_REQUIRED,
  preferencesFetch,
  preferencesFetchError,
  preferencesFetchSuccess,
  PreferencesPatchAction,
  preferencesPatchFailure,
  preferencesPatchSuccess,
  PreferencesRequiredAction,
  PREFERENCES_FETCH,
  PREFERENCES_PATCH,
  PREFERENCES_REQUIRED
} from './actions';
import { PreferencesState } from './reducer/preferences';
import {
  selHuellPersonalizationState,
  selHuellPreferencesState,
  selPreferences
} from './selectors';
// TODO: REMOVE THIS IMPORT
// @ts-ignore: Importing from untyped legacy directory
import { getToken } from 'selectors/account-details';
import { Personalization, Preferences } from './types';
import { AxiosError, AxiosResponse } from 'axios';
import { Huell } from './api';
import { PersonalizationState } from './reducer/personalization';

function* handlePreferencesFetch() {
  try {
    const token: string = yield select(getToken);
    const response: AxiosResponse<Preferences> = yield call(
      Huell.fetchPreferences,
      token
    );
    yield put(preferencesFetchSuccess(response.data));
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status === 404) {
        // Preferences may not exist yet
        const emptyPreferences: Preferences = {};
        yield put(preferencesFetchSuccess(emptyPreferences));
      } else {
        console.log('[TODO] Report Error To Sentry');
        yield put(preferencesFetchError());
      }
    } else if (error.request) {
      console.log('[TODO] Report Error To Sentry');
      yield put(preferencesFetchError());
    }
  }
}

function* handlePersonalizationFetch() {
  try {
    const token: string = yield select(getToken);
    const response: AxiosResponse<Personalization> = yield call(
      Huell.fetchPersonalization,
      token
    );
    yield put(personalizationFetchSuccess(response.data));
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status === 404) {
        // Personalization may not exist yet
        yield put(personalizationFetchSuccess(null));
      } else {
        console.log('[TODO] Report Error To Sentry');
        yield put(personalizationFetchError());
      }
    } else if (error.request) {
      console.log('[TODO] Report Error To Sentry');
      yield put(personalizationFetchError());
    }
  }
}

function* takePreferencesRequired() {
  const requiredChannel: typeof PREFERENCES_REQUIRED = yield actionChannel(
    PREFERENCES_REQUIRED
  );
  while (true) {
    yield take<PreferencesRequiredAction>(requiredChannel);
    const preferencesState: PreferencesState = yield select(
      selHuellPreferencesState
    );
    if (!preferencesState?.status) {
      yield put(preferencesFetch());
    }
  }
}

function* handlePreferencesPatch(action: PreferencesPatchAction) {
  try {
    const oldPreferences: Preferences | undefined = yield select(
      selPreferences
    );
    const token: string = yield select(getToken);
    const response: AxiosResponse<Preferences> = yield call(
      Huell.patchPreferences,
      token,
      { ...oldPreferences, ...action.payload.preferences }
    );
    yield put(preferencesPatchSuccess(response.data));
  } catch (e) {
    yield put(preferencesPatchFailure());
  }
}

function* takePersonalizationRequired() {
  const requiredChannel: typeof PERSONALIZATION_REQUIRED = yield actionChannel(
    PERSONALIZATION_REQUIRED
  );
  while (true) {
    yield take<PersonalizationRequiredAction>(requiredChannel);
    const personalizationState: PersonalizationState = yield select(
      selHuellPersonalizationState
    );
    if (!personalizationState?.status) {
      yield put(personalizationFetch());
    }
  }
}

export function* huellSagas() {
  yield takeLatest(PREFERENCES_FETCH, handlePreferencesFetch);
  yield takeLatest(PERSONALIZATION_FETCH, handlePersonalizationFetch);
  yield takeLatest(PREFERENCES_PATCH, handlePreferencesPatch);
  yield all([call(takePreferencesRequired), call(takePersonalizationRequired)]);
}
