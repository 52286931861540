import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { call, put } from 'redux-saga/effects';
import get from 'lodash/get';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { VIEWS } from 'legacy/routes/constants';

import apiUtil from 'legacy/utils/api';
import { watchEvery } from 'legacy/utils/saga';

import {
  PROPERTY_SEARCH_REQUEST,
  searchPropertySuccess,
  searchPropertyFailure
} from 'actions/property-search';
import { photosFetchSearchResult } from 'actions/photos';

export function* searchProperty(action) {
  const { address } = action.payload;

  try {
    const params = { ...address, addListingRecord: true };
    const propertyResult = yield call(
      apiUtil.GET,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/subject_details/`,
      params
    );
    propertyResult.addressSlug =
      propertyResult.addressSlug || address.addressSlug;
    const { lastListPrice, lastListIsActive, ...propertyDetailsOnly } =
      propertyResult;
    const property = lastListIsActive ? propertyResult : propertyDetailsOnly;

    yield put(searchPropertySuccess(property));
    yield put(photosFetchSearchResult());
  } catch (e) {
    yield put(routeChange({ view: VIEWS.SEARCH, options: { inherit: false } }));
    yield put(searchPropertyFailure(get(e.responseJSON, 'error')));
    // Rethrow for Sentry reporting
    throw e;
  }
}

export default function registerPropertySearchSaga() {
  watchEvery({
    [PROPERTY_SEARCH_REQUEST]: searchProperty
  });
}
