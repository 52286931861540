import React from 'react';
import { SubjectDetailsTable } from '../../../lib/report-api/features/SubjectDetailsTable';
import { SectionHeader } from '../../layout/SectionHeader';
interface Props {
  reportId: number;
  dataHcName: string;
}

export const SubjectDetailsSection = ({ reportId, dataHcName }: Props) => {
  return (
    <section data-hc-name={dataHcName}>
      <SectionHeader dataHcName={`${dataHcName}-header`}>
        Property Details
      </SectionHeader>
      <SubjectDetailsTable
        reportId={reportId}
        dataHcName={`${dataHcName}-table`}
      />
    </section>
  );
};
