import classNames from 'classnames';
import React from 'react';
import { Dialog } from 'src/lib/components/Dialog';
import { CompIdentifier, CompCompare } from './CompCompare';
import styles from './CompCompareDialog.css';

interface Props {
  dataHcName: string;
  className?: string;
  onClose: VoidFunction;
  reportId: number;
  compIdentifier: CompIdentifier | null;
}
export const CompCompareDialog = ({
  dataHcName,
  className,
  reportId,
  compIdentifier,
  onClose
}: Props) => {
  return (
    <Dialog
      dataHcName={dataHcName}
      active={!!compIdentifier}
      title="Subject & Comp Comparison"
      className={classNames(styles.CompCompareDialog, className)}
      onClose={onClose}
    >
      {compIdentifier && (
        <CompCompare
          dataHcName={`${dataHcName}-compare`}
          reportId={reportId}
          compIdentifiers={[compIdentifier]}
        />
      )}
    </Dialog>
  );
};
