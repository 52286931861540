import React from 'react';

import { PersonalizationHeader } from 'src/lib/huell/components/PersonalizationHeader';
import { useCobranding } from 'src/lib/report-api/hooks/useCobranding';
import { hasDefaultCobrandingData } from 'src/lib/report-api/utils/cobranding.utils';

interface Props {
  reportId: number;
  dataHcName: string;
  className?: string;
}

export const CobrandingHeader = ({
  reportId,
  dataHcName,
  className
}: Props) => {
  const cobranding = useCobranding(reportId);
  const defaultCobrandingData =
    cobranding.data && hasDefaultCobrandingData(cobranding.data);
  return (
    <>
      {cobranding.data && defaultCobrandingData && (
        <PersonalizationHeader
          personalization={cobranding.data}
          dataHcName={dataHcName}
          className={className}
        />
      )}
    </>
  );
};
