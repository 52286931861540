import { connect } from 'react-redux';

import { STATUSES, PROPERTY_TYPE_SUBJECT } from 'legacy/appstore/constants';
import { PROPERTY_DATA_SOURCES, QUERIES } from 'legacyGraphQL/constants';
import { EVENTS } from 'legacy/appstore/events';
import { getPropertyLookup } from 'selectors/report-data.selectors';
import { getSubjectDefault } from 'selectors/subject';
import { isLoading } from 'legacy/utils/status';
import { TRANSACTION_HISTORY_COLUMNS } from 'legacy/utils/transaction-history';

import {
  getReportStatusHcVersionIsLoadedInitial,
  getReportStatusUserVersionIsLoadedInitial
} from 'selectors/report-status';
import { analyticsEvent } from 'actions/analytics';
import { photosDialogShow } from 'actions/photos';
import { propertyQueryTransactionHistory } from 'actions/property';
import { checkShouldScrub } from 'legacy/utils/mls-compliance.utils';
import { getAnalyticsSubjectAddressSlug } from 'selectors/analytics';
import { getSubjectAddressId } from 'selectors/subject';
import { getPhotosByMlsNumberAndExternalSourceId } from 'selectors/photos';
import {
  getPropertyTransactionHistory,
  getPropertySourceStatusForQuery
} from 'selectors/property';

import conditionallyDispatchAction from 'legacy/hocs/conditionallyDispatchAction';

import PropertyTransactionHistory from 'legacy/components/PropertyTransactionHistory';

const mapStateToProps = (state, ownProps) => {
  const { addressId } = ownProps;
  const subject = getSubjectDefault(state);
  const propertyLookup = getPropertyLookup(state);
  const ownerOrgId = propertyLookup.organizationId;
  const isSubject = addressId === 'subject';
  const transactionHistory = getPropertyTransactionHistory(addressId)(state);
  const photosByMlsNumberAndExternalSourceId =
    getPhotosByMlsNumberAndExternalSourceId(
      isSubject ? getSubjectAddressId(state) : addressId
    )(state);
  const status = getPropertySourceStatusForQuery(
    addressId,
    PROPERTY_DATA_SOURCES.HC,
    QUERIES.TRANSACTION_HISTORY
  )(state);
  const loading = isLoading(status);
  const subjectAddressId = getSubjectAddressId(state);
  return {
    hasPropertyHistory:
      loading || (transactionHistory && transactionHistory.length),
    columns: TRANSACTION_HISTORY_COLUMNS,
    addressId: addressId === 'subject' ? subjectAddressId : addressId,
    transactionHistory,
    loading,
    isSubject,
    shouldScrubData: checkShouldScrub(subject.state, ownerOrgId),
    shouldDispatchAction:
      !!subjectAddressId &&
      !!(
        getReportStatusHcVersionIsLoadedInitial(state) ||
        getReportStatusUserVersionIsLoadedInitial(state)
      ) &&
      [null, undefined, STATUSES.INIT].indexOf(status) > -1,
    photosByMlsNumberAndExternalSourceId
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { addressId } = ownProps;
  return {
    onShouldDispatch: () =>
      dispatch(propertyQueryTransactionHistory(addressId)),
    onViewPhotos: (transactionId) =>
      dispatch(
        photosDialogShow(addressId, {
          transactionId,
          fromTransactionHistory: true,
          propertyType: PROPERTY_TYPE_SUBJECT,
          currentIndex: 0
        })
      ),
    onViewMlsSheet: (transactionId) =>
      dispatch(
        analyticsEvent(
          EVENTS.MLS_SHEET_VIEW,
          { transaction_id: transactionId },
          { propsSelector: getAnalyticsSubjectAddressSlug }
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(conditionallyDispatchAction(PropertyTransactionHistory));
