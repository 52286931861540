import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUSES } from 'legacy/appstore/constants';
import { photosCheckStreetViewUrl } from 'actions/photos';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import { getReportDataEffectiveDate } from 'selectors/report-data';
import { getPhotosStreetViewStatus } from 'selectors/photos';
import { useListings } from 'legacy/hocs/useListings';
import { useAddressId } from 'legacy/hocs/useAddressId';
import { getMapTilePhoto, buildStreetViewUrl } from 'legacy/utils/photos';
import { isLoading } from 'legacy/utils/status';

export const usePhotos = (
  addressIdInput,
  { listingId, property, latestListingOnly } = {}
) => {
  const dispatch = useDispatch();
  const addressId = useAddressId(addressIdInput);
  let { status, listings } = useListings(addressIdInput);
  if (latestListingOnly && !listingId) {
    const latestWithPhotos = listings.find((l) => l.photos?.length);
    listings = latestWithPhotos ? [latestWithPhotos] : [];
  }
  const isEffectiveDateReport = useSelector(getIsEffectiveDateReport);
  const effectiveDate = useSelector(getReportDataEffectiveDate);
  const streetViewStatus = useSelector(getPhotosStreetViewStatus(addressId));
  useEffect(() => {
    if (property?.geoLocation?.latitude && property?.geoLocation?.longitude) {
      const { latitude, longitude } = property.geoLocation;
      const streetViewUrl = buildStreetViewUrl(latitude, longitude);
      if (!streetViewStatus) {
        dispatch(photosCheckStreetViewUrl(addressId, streetViewUrl));
      }
    }
  }, [streetViewStatus, property]);
  const { photos, firstPrecedingEffectiveDate } = useMemo(() => {
    let allPhotos = [];
    listings.forEach((listing) => {
      allPhotos = allPhotos.concat(listing.photos || []);
    });
    let firstPrecedingEffectiveDate = 0;
    if (isEffectiveDateReport && allPhotos.length) {
      firstPrecedingEffectiveDate = allPhotos.findIndex(
        (p) => p.listingDate < effectiveDate
      );
      if (firstPrecedingEffectiveDate === -1) {
        const oldestListingWithPhotos = listings
          .reverse()
          .findIndex((l) => !!l.photos.length);
        firstPrecedingEffectiveDate = allPhotos.findIndex(
          (p) =>
            p.externalSourceId === listings[oldestListingWithPhotos].listingID
        );
      }
    }
    if (listingId) {
      allPhotos = allPhotos.filter((p) => p.externalSourceId === listingId);
    }
    if (
      !isLoading(status) &&
      property?.geoLocation?.latitude &&
      property?.geoLocation?.longitude
    ) {
      const { latitude, longitude } = property.geoLocation;
      const streetViewUrl = buildStreetViewUrl(latitude, longitude);
      if (streetViewStatus === STATUSES.SUCCESS) {
        allPhotos.push({ httpUrl: streetViewUrl });
      }
      allPhotos.push({ httpUrl: getMapTilePhoto(latitude, longitude) });
    }
    return {
      firstPrecedingEffectiveDate,
      photos: allPhotos
    };
  }, [
    addressIdInput,
    latestListingOnly,
    listings,
    listingId,
    effectiveDate,
    isEffectiveDateReport
  ]);
  return { status, photos, firstPrecedingEffectiveDate };
};
