import {
  call,
  put,
  takeLatest,
  actionChannel,
  take,
  select
} from 'redux-saga/effects';
import { report } from 'hc-ravenjs-logger';
import apiUtil from 'legacy/utils/api';
import HC_CONSTANTS from 'HC_CONSTANTS';
import {
  featureFlagsFetchFailure,
  featureFlagsFetchSuccess,
  FEATURE_FLAGS_REQUIRED,
  featureFlagsFetch,
  FEATURE_FLAGS_FETCH
} from '../actions/feature-flags.actions';
import { getFeatureFlagsStatus } from 'selectors/feature-flags';
import sagaMiddleware from 'middleware/saga';
import { STATUSES } from 'legacy/appstore/constants';
import { getHasAgileOpsRole } from '../selectors/account-details.selectors';

export function* handleFeatureFlagsFetch(action) {
  try {
    // const featureFlags = {
    //   'auto-org-sharing': false,
    //   'effective-date-reports': false
    // };
    const featureFlags = yield call(
      apiUtil.GET,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/feature_flag/user_flags/`
    );
    if (featureFlags['report-api']) {
      const isAgileOpsUser = yield select(getHasAgileOpsRole);
      // SECURITY: @jnettleman - Known url for migration redirect
      /* eslint-disable scanjs-rules/assign_to_href */
      /* eslint-disable xss/no-location-href-assign */
      if (!isAgileOpsUser) {
        window.location.href = `${HC_CONSTANTS.HCS_PLATFORM_URL}/pexp`;
      }
      /* eslint-enable scanjs-rules/assign_to_href */
      /* eslint-enable xss/no-location-href-assign */
    }
    yield put(featureFlagsFetchSuccess(featureFlags));
  } catch (e) {
    yield put(featureFlagsFetchFailure());
    console.error(e);
    report('Feature Flags: Saga Failed to Fetch Data', { e, action });
  }
}

function* takeFeatureFlagsRequired() {
  const requiredChannel = yield actionChannel(FEATURE_FLAGS_REQUIRED);
  while (true) {
    yield take(requiredChannel);
    const status = yield select(getFeatureFlagsStatus);
    if (!status || status === STATUSES.INIT) {
      yield put(featureFlagsFetch());
    }
  }
}

function* runSagas() {
  yield takeLatest(FEATURE_FLAGS_FETCH, handleFeatureFlagsFetch);
  yield call(takeFeatureFlagsRequired);
}

export default function registerFeatureFlagsSaga() {
  sagaMiddleware.run(runSagas);
}
