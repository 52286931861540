import React from 'react';
import { useDocumentRole } from '../../hooks';
import { DocumentRoles, SubjectDocument } from '../../types';
import { STATUSES } from 'src/constants';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { KeywordSearchText } from 'src/lib/keyword-search/features/KeywordSearchText/KeywordSearchText';
import styles from './SubjectPublicRemarks.css';
import { formatDateLong } from 'src/lib/utils/formatters';

interface Props {
  reportId: number;
  dataHcName: string;
  className?: string;
}
export const SubjectPublicRemarks = ({
  reportId,
  dataHcName,
  className
}: Props) => {
  const {
    status: subjectDocStatus,
    data: [subjectDocument]
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  if (subjectDocStatus === STATUSES.LOADING || !subjectDocument) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const { publicRemarks, statusDate } = subjectDocument.data.latestListing;

  return (
    <div data-hc-name={dataHcName} className={className}>
      <div className={styles.SubjectPublicRemarks}>
        <KeywordSearchText
          dataHcName={`${dataHcName}-text`}
          textToHighlight={publicRemarks || ''}
        />
      </div>
      <div className={styles.Date} data-hc-name={`${dataHcName}-date`}>
        {formatDateLong(statusDate)}
      </div>
    </div>
  );
};
