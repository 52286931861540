import { connect } from 'react-redux';

import {
  getPersonalizationPromptIsOpen,
  getPersonalizationPromptAltActionType
} from 'selectors/personalization';
import {
  personalizationModalOpen,
  personalizationPromptModalHide,
  personalizationAckPrompt
} from 'actions/personalization';
import PersonalizationPromptModal from 'legacy/components/PersonalizationPromptModal';

import { downloadReport } from 'actions/download-report';
import { shareReport } from 'actions/share-report';

const mapStateToProps = (state) => {
  return {
    active: getPersonalizationPromptIsOpen(state),
    altActionType: getPersonalizationPromptAltActionType(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(personalizationPromptModalHide());
  },
  onAltActionClick: (altActionType) => {
    dispatch(personalizationAckPrompt());
    if (altActionType === 'DOWNLOAD') {
      dispatch(downloadReport('pdf', true));
    } else {
      dispatch(shareReport(true));
    }
  },
  onPersonalizationClick: () => {
    dispatch(personalizationPromptModalHide());
    dispatch(personalizationModalOpen());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalizationPromptModal);
