import { EVENTS } from 'legacy/appstore/events';

export const ARCHIVED_REPORTS_FETCH_REQUEST = 'ARCHIVED_REPORTS_FETCH_REQUEST';
export const ARCHIVED_REPORTS_FETCH_SUCCESS = 'ARCHIVED_REPORTS_FETCH_SUCCESS';
export const ARCHIVED_REPORTS_SEARCH = 'ARCHIVED_REPORTS_SEARCH';
export const ARCHIVED_REPORTS_OPEN_MODAL = 'ARCHIVED_REPORTS_OPEN_MODAL';
export const ARCHIVED_REPORTS_CLOSE_MODAL = 'ARCHIVED_REPORTS_CLOSE_MODAL';
export const ARCHIVED_REPORTS_DOWNLOAD_REPORT =
  'ARCHIVED_REPORTS_DOWNLOAD_REPORT';

export const archivedReportsSearch = (query) => ({
  type: ARCHIVED_REPORTS_SEARCH,
  payload: { query }
});

export const archivedReportsOpenModal = (property) => ({
  type: ARCHIVED_REPORTS_OPEN_MODAL,
  payload: { property },
  meta: {
    analytics: {
      event: EVENTS.ARCHIVED_REPORTS_MODAL_OPEN
    }
  }
});

export const archivedReportsCloseModal = () => ({
  type: ARCHIVED_REPORTS_CLOSE_MODAL,
  payload: {}
});

export const archivedReportsDownloadReport = (property, report) => ({
  type: ARCHIVED_REPORTS_DOWNLOAD_REPORT,
  payload: {
    property,
    report
  },
  meta: {
    analytics: {
      event: EVENTS.ARCHIVED_REPORTS_DOWNLOAD_EXPIRED,
      props: {
        address_slug: property.addressSlug || property.addressKey
      }
    }
  }
});

export const fetchArchivedReports = () => ({
  type: ARCHIVED_REPORTS_FETCH_REQUEST,
  payload: {}
});

export const fetchArchivedReportsSuccess = (archivedReports) => ({
  type: ARCHIVED_REPORTS_FETCH_SUCCESS,
  payload: {
    ...archivedReports
  }
});
