import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUSES } from 'src/constants';

import { GeoLocation } from 'src/lib/property-lookup/types';
import { streetViewMetadataFetch } from 'src/lib/street-view/actions/streetView.actions';
import { selStreetViewStateForLatLng } from 'src/lib/street-view/selectors/streetView.selectors';
import { buildStreetViewImgUrl } from 'src/lib/utils/streetView';

export const useStreetView = ({ latitude, longitude }: GeoLocation) => {
  const dispatch = useDispatch();
  const streetViewState = useSelector(
    selStreetViewStateForLatLng([latitude, longitude])
  );
  useEffect(() => {
    if (!streetViewState?.status) {
      dispatch(streetViewMetadataFetch({ latitude, longitude }));
    }
  }, [streetViewState]);
  return {
    status: streetViewState?.status,
    url: buildStreetViewImgUrl(latitude, longitude),
    streetViewExists:
      streetViewState?.status === STATUSES.SUCCESS
        ? streetViewState.data.status === 'OK'
        : undefined
  };
};
