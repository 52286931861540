import { formatNumber } from 'accounting';
import React from 'react';
import Supercluster, { ClusterFeature } from 'supercluster';
import styles from './MapCluster.css';

interface Props {
  cluster: ClusterFeature<Supercluster.ClusterProperties>;
  superCluster: Supercluster;
}
export const MapCluster = ({ cluster }: Props) => {
  return (
    <div className={styles.MapCluster}>
      <div className={styles.Inner}>
        <span className={styles.Label}>
          {formatNumber(cluster.properties.point_count)}
        </span>
      </div>
    </div>
  );
};
