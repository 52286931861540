import { GraphQLClient } from 'graphql-request';
import { apply, put, call, select } from 'redux-saga/effects';
import get from 'lodash/get';

import HC_CONSTANTS from 'HC_CONSTANTS';

import { APIClientError } from 'legacy/utils/error';
import { isArray } from 'legacy/utils/utils';
import { tokenExpired } from 'actions/auth';
import { getToken } from 'selectors/account-details';
import { getIsViewingSharedReport } from 'selectors/property-explorer';
import { refreshAccessToken } from 'src/legacy/utils/refresh-token-callback';

const graphqlRequest = function* ({
  url,
  source,
  headers = {},
  query = {},
  newToken,
  retries = 0
} = {}) {
  let token;
  if (newToken) {
    token = newToken;
  } else {
    token = yield select(getToken);
  }
  const isSharedReport = yield select(getIsViewingSharedReport);
  const graphqlUrl =
    url ||
    (isSharedReport
      ? HC_CONSTANTS.SHARED_GRAPHQL_URL
      : HC_CONSTANTS.GRAPHQL_URL);
  const client = new GraphQLClient(graphqlUrl, {
    headers: {
      'HC-Auth-Token': token,
      ...headers
    }
  });

  const queries = [];
  const queriesFetched = [];
  // Helper to populate queries and queriesFetched
  const _update = (qry) => {
    queries.push(qry.query);
    queriesFetched.push(qry.key);
  };

  if (isArray(query)) {
    query.forEach((q) => {
      _update(q);
    });
  } else {
    _update(query);
  }
  const graphqlQuery = `
    query {
      ${queries.join('\n')}
    }
  `;

  try {
    const response = yield call([client, client.request], graphqlQuery);
    return { response, source, queries: queriesFetched };
  } catch (error) {
    const statusCode = get(error, ['response', 'status']);
    if (statusCode === 401) {
      if (retries === 0) {
        try {
          const userContext = yield call(refreshAccessToken);
          const retryResponse = yield call(graphqlRequest, {
            url,
            source,
            headers,
            query,
            newToken: userContext.validity.token,
            retries: retries + 1
          });
          return retryResponse;
        } catch (e) {
          console.error(e);
          yield put(tokenExpired());
        }
      }
    } else {
      throw new APIClientError(`${statusCode} Error: ${error.message}`, {
        statusCode,
        statusText: statusCode && statusCode.toString(),
        requestUrl: url,
        requestBody: graphqlQuery,
        responseJSON: error.response,
        messageRaw: error.message
      });
    }
  }
};

export default graphqlRequest;
