import { createSelector } from 'reselect';
import { getCurrentView } from '@hc/redux-saga-router-plus/hclib/selectors';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { AVM_KEY_USER, MAP_ID_SEARCH } from 'legacy/appstore/constants';
import { FORM_NAME as PROPERTY_DETAILS_FORM_NAME } from 'legacy/forms/property-explorer-property-details';

import {
  getAvmSelectedKey,
  getAvmRentalSelectedKey,
  getAvmByKey,
  getAvmUser
} from 'selectors/avms';
import { getAccountDetails } from 'selectors/account-details';
import { getSharedReportUid } from 'selectors/share-report';
import { getSubjectAddressSlug } from 'selectors/subject';
import { getChangedFormValues } from 'selectors/redux-form';
import { getMLSListingsShowOnMap } from 'selectors/mls-listings';
import { getReportIsShowingHeaderMapControls } from 'selectors/property-explorer';
import { getUiCompsMapVisibleControlType } from 'selectors/ui';

export const getAnalyticsGlobalProps = createSelector(
  getCurrentView,
  (view) => {
    return {
      view
    };
  }
);

export const getAccountDetailsAnalytics = createSelector(
  getAccountDetails,
  (accountDetails) => ({
    auth_user_id: get(accountDetails, ['user', 'id']),
    email: get(accountDetails, ['user', 'email']),
    auth_organization_slug: get(accountDetails, [
      'currentOrganization',
      'slug'
    ]),
    auth_organization_id: get(accountDetails, ['currentOrganization', 'id'])
  })
);

export const getAnalyticsGlobalPropertyExplorerProps = createSelector(
  getSubjectAddressSlug,
  getAvmSelectedKey,
  getAvmRentalSelectedKey,
  getSharedReportUid,
  (addressSlug, selectedAvmKey, selectedRentalAvmKey, reportUid) => {
    return {
      address_slug_subject: addressSlug,
      report_uid: reportUid,
      selected_avm: selectedAvmKey,
      selected_rental_avm: selectedRentalAvmKey
    };
  }
);

export const getTriggerAdjustSubjectPropertyDetailsStart = (
  state,
  props,
  action
) => action.payload.selectedAvm === AVM_KEY_USER && isEmpty(getAvmUser(state));

export const getTriggerAvmChangeSelectedWhenSavingEmptyUserAvm = (
  state,
  props
) => {
  // if user avm is selected but empty the user clicked on adjust details from the avm selector
  // the avm change is persisted on save
  const avmKey = getAvmSelectedKey(state);
  if (avmKey === AVM_KEY_USER) {
    return isEmpty(getAvmUser(state));
  } else {
    return false;
  }
};

export const getSubjectPropertyDetailsChanged = (state) => ({
  adjustments: getChangedFormValues(PROPERTY_DETAILS_FORM_NAME)(state)
});

export const getTypeOfMapPinsShownOnSearchPage = (state) => ({
  pin_type: getMLSListingsShowOnMap(state) ? 'recent_reports' : 'mls_listings',
  map_id: MAP_ID_SEARCH
});

export const getPropsIsHeaderMapShowingControls = (state) => ({
  enabled: !getReportIsShowingHeaderMapControls(state)
});

export const getPropsIsCompsMapShowingControls = (state, props) => ({
  enabled: !(getUiCompsMapVisibleControlType(state) === props.control_type)
});

export const getAnalyticsSubjectAddressSlug = createSelector(
  getSubjectAddressSlug,
  (slug) => ({ address_slug: slug })
);
