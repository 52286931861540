import { CompFields } from '../../types/CompFields';
import {
  BUTTON_VISIBILITY_CHANGE,
  CompFilterBarActions,
  OVERFLOW_VISIBILITY_CHANGE,
  OVERFLOW_BUTTON_VISIBILITY_CHANGE,
  OVERFLOW_PORTAL_MOUNTED
} from './compFilterBar.actions';

export const COMP_FILTER_BAR_REDUCER_KEY = 'compFilterBar';

export interface CompFilterBarState {
  buttonsVisible: { [key in CompFields]?: boolean };
  overflowVisible: boolean;
  overflowButtonVisible: boolean;
  overflowPortalId: string | null;
  overflowCount: number;
}
const INITIAL_STATE: CompFilterBarState = {
  buttonsVisible: {},
  overflowVisible: false,
  overflowButtonVisible: false,
  overflowPortalId: null,
  overflowCount: 0
};

export const compFiltersReducer = (
  state: CompFilterBarState = INITIAL_STATE,
  action: CompFilterBarActions
): CompFilterBarState => {
  switch (action.type) {
    case BUTTON_VISIBILITY_CHANGE: {
      const buttonsVisible = {
        ...state.buttonsVisible,
        [action.payload.compField]: action.payload.visible
      };
      return {
        ...state,
        buttonsVisible,
        overflowCount: (() => {
          let value = 0;
          Object.entries(buttonsVisible).map((entry) => {
            const visible = entry[1];
            if (!visible) {
              value++;
            }
          });
          return value;
        })()
      };
    }

    case OVERFLOW_VISIBILITY_CHANGE: {
      return {
        ...state,
        overflowVisible: action.payload.visible
      };
    }

    case OVERFLOW_BUTTON_VISIBILITY_CHANGE: {
      return {
        ...state,
        overflowButtonVisible: action.payload.visible
      };
    }

    case OVERFLOW_PORTAL_MOUNTED: {
      return {
        ...state,
        overflowPortalId: action.payload.portalId
      };
    }

    default: {
      return state;
    }
  }
};
