import { Component, Fragment } from 'react';
import SVGInline from 'react-svg-inline';
import classNames from 'classnames';
import {
  NavigationBar,
  MenuItem
} from '@hc/authentication-lib/hclib/components';
import { TextButton } from 'legacy/components/Button';
import { InternetExplorerWarning } from '@hc/component-lib/hclib/components/atoms/internet-explorer-warning/InternetExplorerWarning';
import { Link } from '@hc/redux-saga-router-plus';

import { VIEWS } from 'legacy/routes/constants';

import { ExpiredReportDate } from 'legacy/containers/expired-report-date';

import archivedReportsIcon from 'legacy/assets/svg-inline/archived-reports.svg';
import personalizeReportsIcon from 'legacy/assets/svg-inline/personalize.svg';
import { EffectiveDateWarning } from 'legacy/containers/effective-date-warning';
import { SubjectHeaderSearch } from 'legacy/components/SubjectSearch';
import { GlobalNav as theme } from 'legacy/css-modules';
import { useIsInternetExplorer } from '@hc/component-lib/hclib/components/atoms/internet-explorer-warning/useIsInternetExplorer';

const renderLink = ({ label, className, onClick }) => (
  <span className={className} onClick={onClick}>
    {label}
  </span>
);

const TitleLink = ({ isLoggedIn, className, children }) => {
  return (
    <Link
      className={className}
      view={isLoggedIn ? VIEWS.SEARCH : VIEWS.LOGIN}
      options={{ inherit: false }}
    >
      {children}
    </Link>
  );
};

class GlobalNav extends Component {
  render() {
    const {
      env,
      isMobile,
      productSlug,
      userContext,
      propertySearch,
      selectOrgOptions,
      logoutOptions,
      showLoginButton,
      isLoggedIn,
      notificationsOptions,
      onOpenArchivedReports,
      onOpenPersonalization,
      onClickLogin,
      getIsEffectiveDateReport
    } = this.props;

    const isIe = useIsInternetExplorer();

    return (
      <Fragment>
        <NavigationBar
          env={env}
          productSlug={productSlug}
          userContext={userContext}
          selectOrgOptions={selectOrgOptions}
          logoutOptions={logoutOptions}
          mobile={isMobile}
          notificationsOptions={notificationsOptions}
          skipUserContextRefresh
          supportUrl="https://support.housecanary.com"
          renderCustomAccountMenuContent={() => (
            <Fragment>
              <MenuItem
                className={theme.MenuItem}
                label="Personalize Reports"
                icon={<SVGInline svg={personalizeReportsIcon} />}
                onClick={onOpenPersonalization}
                renderLink={renderLink}
                dataHcName="personalize-reports"
              />
              <MenuItem
                className={theme.MenuItem}
                label="Archived Reports"
                icon={
                  <SVGInline
                    className={theme.ArchivedReportsIcon}
                    svg={archivedReportsIcon}
                  />
                }
                onClick={onOpenArchivedReports}
                renderLink={renderLink}
                dataHcName="archived-reports"
              />
            </Fragment>
          )}
          ProductClickAreaComponent={(props) => (
            <TitleLink {...props} isLoggedIn={isLoggedIn} />
          )}
        >
          <div
            className={
              getIsEffectiveDateReport ? theme.WarningsContainer : null
            }
          >
            <EffectiveDateWarning className={theme.EffectiveDate} />
            <ExpiredReportDate className={theme.EffectiveDate} />
          </div>
          {showLoginButton && (
            <TextButton
              className={theme.LoginButton}
              label="Log In"
              onClick={onClickLogin}
              dataHcName="login-link"
            />
          )}
          {propertySearch && !showLoginButton && <SubjectHeaderSearch />}
        </NavigationBar>
        <div className={classNames(theme.Spacer, { [theme.ie]: isIe })} />
        <InternetExplorerWarning className={theme.InternetExplorerWarning} />
      </Fragment>
    );
  }
}

export default GlobalNav;
