import React from 'react';
import { useDispatch } from 'react-redux';
import { FilterCard } from 'src/lib/components/FilterCard';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { isLoading } from 'src/lib/utils/general';
import { useDocumentRole, useReport } from '../../hooks';
import { DocumentRoles, FilterDocument, SubjectDocument } from '../../types';
import { CompFields } from '../../types/CompFields';
import { compFieldGetters } from '../../utils/comps.utils';
import { CompFilterClearButton } from './CompFilterClearButton';
import { FilterRange } from 'src/lib/components/FilterRange';
import {
  FilterRangeType,
  FORMAT_TYPES
} from 'src/lib/components/FilterRange/types';
import {
  convertNumArrayToFilterRange,
  formatOrUndefined,
  onBlurFilter,
  subjectDisplay
} from 'src/lib/utils/compFilters';
import { FILTER_TYPE } from 'src/lib/report-api/types/Filters';
import { formatStripFormatting } from 'src/lib/utils';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
}

const COMP_FIELD = CompFields.siteAreaSqft;

export const CompFilterLotSize = ({
  reportId,
  dataHcName,
  className
}: Props) => {
  const dispatch = useDispatch();
  const {
    status: filterDocumentStatus,
    data: [filterDocument]
  } = useDocumentRole<FilterDocument>(reportId, DocumentRoles.Filter);
  const {
    status: subjectStatus,
    data: [subjectDocument]
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  const { status: reportStatus, data: report } = useReport(reportId);
  if (
    isLoading([filterDocumentStatus, reportStatus, subjectStatus]) ||
    !filterDocument ||
    !report ||
    !subjectDocument
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const filterValue = filterDocument.data.filters[COMP_FIELD];
  const subjectValue = compFieldGetters[COMP_FIELD](subjectDocument.data);
  return (
    <FilterCard
      label={FILTER_TYPE.LOT_SIZE}
      info={`Subject: ${
        subjectValue === null ? 'Any' : `${subjectValue} SqFt`
      }`}
      dataHcName={dataHcName}
      className={className}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compField={COMP_FIELD}
          dataHcName={`${dataHcName}-clear`}
        />
      }
    >
      <FilterRange
        initialValue={convertNumArrayToFilterRange(filterValue?.absoluteValue)}
        subjectDisplay={subjectDisplay(
          true,
          convertNumArrayToFilterRange(filterValue?.relativeValue)
        )}
        formatType={FORMAT_TYPES.COMMA}
        onBlur={(value: FilterRangeType) =>
          onBlurFilter(
            formatOrUndefined(formatStripFormatting, value),
            dispatch,
            reportId,
            filterDocument,
            COMP_FIELD
          )
        }
        dataHcName={`${dataHcName}-lot-size`}
      />
    </FilterCard>
  );
};
