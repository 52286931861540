import { CompId } from '../types/schemas';

export const COMP_SELECT = 'COMP_SELECT';

export interface CompSelectAction {
  type: typeof COMP_SELECT;
  payload: {
    reportId: number;
    compId: CompId;
  };
}
export const compSelect = (
  reportId: number,
  compId: CompId
): CompSelectAction => {
  return {
    type: COMP_SELECT,
    payload: { reportId, compId }
  };
};

export type ReportFeatureActions = CompSelectAction;
