import { watchLatest } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { call, put, select } from 'redux-saga/effects';

import {
  COMP_TYPE_BY_MAP_ID,
  COMP_TYPE_RENTAL
} from 'legacy/appstore/constants';

import { fetchComps } from 'sagas/comps';

import {
  COMPS_FILTER_DISTANCE_CHANGE,
  COMPS_CLEAR_ALL_FILTERS,
  COMPS_CLEAR_FILTER,
  COMPS_MAP_DRAW_SUBMIT,
  COMPS_MAP_DRAW_DELETE_ALL,
  compsFarmUpdateSuccess
} from 'actions/comps';
import {
  RENTAL_COMPS_FILTER_DISTANCE_CHANGE,
  RENTAL_COMPS_CLEAR_ALL_FILTERS,
  RENTAL_COMPS_LOAD_FARM_FOR_DEFAULT_DISTANCE,
  RENTAL_COMPS_CLEAR_FILTER,
  RENTAL_COMPS_MAP_DRAW_SUBMIT,
  RENTAL_COMPS_MAP_DRAW_DELETE_ALL,
  rentalCompsFarmUpdateSuccess
} from 'actions/rental-comps';

import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import {
  getNearbyProperties,
  getNearbyPropertiesRental
} from 'selectors/nearby-properties';

const storeMethodsDefault = {
  farmUpdateSuccess: compsFarmUpdateSuccess,
  getNearbyProperties: getNearbyProperties
};

const storeMethodsRental = {
  farmUpdateSuccess: rentalCompsFarmUpdateSuccess,
  getNearbyProperties: getNearbyPropertiesRental
};

function* deletePolygon(mapId) {
  try {
    const compType = COMP_TYPE_BY_MAP_ID[mapId];
    const storeMethods =
      compType === COMP_TYPE_RENTAL ? storeMethodsRental : storeMethodsDefault;
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    // Use nearby properties if they exist since this is the same as the HC verison farm.
    const nearbyProperties = yield select(storeMethods.getNearbyProperties);
    if (nearbyProperties && nearbyProperties.length) {
      yield put(storeMethods.farmUpdateSuccess(nearbyProperties));
      return true;
    } else if (!isEffectiveDateReport) {
      yield call(fetchComps, compType);
    }
  } catch (e) {
    console.error(e);
    report(`Delete Polygon: ${e.message}`, { e, mapId });
  }
}

export function* handlePolygonChange(action) {
  try {
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    if (isEffectiveDateReport) {
      // Handled in effective-date sagas
      return;
    }
    const { mapId } = action.payload;
    const compType = COMP_TYPE_BY_MAP_ID[mapId];
    const geojson = action.payload.polygons[0];
    yield call(fetchComps, compType, geojson);
  } catch (e) {
    console.error(e);
    report(`Handle Polygon Change: ${e.message}`, { e, action });
  }
}

function* handlePolygonDelete(action) {
  const { mapId } = action.payload;
  yield call(deletePolygon, mapId);
}

function* handleClearFilter(action) {
  const { filterKey, mapId } = action.payload;
  if (filterKey === 'distance' && mapId) {
    yield call(deletePolygon, mapId);
  }
}

export default function registerMapDrawSagas() {
  watchLatest({
    [COMPS_MAP_DRAW_SUBMIT]: handlePolygonChange,
    [COMPS_FILTER_DISTANCE_CHANGE]: handlePolygonChange,
    [COMPS_MAP_DRAW_DELETE_ALL]: handlePolygonDelete,
    [COMPS_CLEAR_ALL_FILTERS]: handlePolygonDelete,
    [COMPS_CLEAR_FILTER]: handleClearFilter,
    [RENTAL_COMPS_MAP_DRAW_SUBMIT]: handlePolygonChange,
    [RENTAL_COMPS_FILTER_DISTANCE_CHANGE]: handlePolygonChange,
    [RENTAL_COMPS_LOAD_FARM_FOR_DEFAULT_DISTANCE]: handlePolygonChange,
    [RENTAL_COMPS_MAP_DRAW_DELETE_ALL]: handlePolygonDelete,
    [RENTAL_COMPS_CLEAR_ALL_FILTERS]: handlePolygonDelete,
    [RENTAL_COMPS_CLEAR_FILTER]: handleClearFilter
  });
}
