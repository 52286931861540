import { Component } from 'react';
import remove from 'lodash/remove';
import { Checkbox } from '@hc/component-lib/hclib/components/atoms/checkbox';

import { MultiSelect as theme } from 'legacy/css-modules/generic';

const Option = ({
  label,
  value,
  disabled,
  isChecked,
  onChange,
  optionWidth,
  dataHcNameProps = {}
}) => {
  return (
    <div style={optionWidth && { width: optionWidth }} className={theme.Option}>
      <div
        className={theme.CheckboxCell}
        data-hc-name={dataHcNameProps.checkbox}
      >
        <Checkbox
          theme={theme}
          disabled={disabled}
          className={theme.Checkbox}
          checked={isChecked}
          onChange={() => onChange(value)}
        />
      </div>
      <div data-hc-name={dataHcNameProps.label} className={theme.LabelCell}>
        {label}
      </div>
    </div>
  );
};

class MultiSelect extends Component {
  static defaultProps = {
    value: []
  };

  handleChange = (v) => {
    const { onChange, value } = this.props;
    let updatedValue = [...value];
    if (onChange) {
      const vIndex = updatedValue.indexOf(v);
      if (vIndex > -1) {
        remove(updatedValue, (i) => i === v);
      } else {
        updatedValue.push(v);
      }
      onChange(updatedValue);
    }
  };

  render() {
    const { options, value, label, optionWidth, disabled, dataHcNameProps } =
      this.props;
    return (
      <div className={theme.MultiSelect}>
        {label}
        {options.map((option) => (
          <Option
            key={option.value}
            {...option}
            disabled={disabled}
            dataHcNameProps={dataHcNameProps}
            optionWidth={optionWidth}
            onChange={this.handleChange}
            isChecked={value.indexOf(option.value) > -1}
          />
        ))}
      </div>
    );
  }
}

export default MultiSelect;
