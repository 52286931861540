import get from 'lodash/get';
import { put, call, select } from 'redux-saga/effects';

import { STATUSES } from 'legacy/appstore/constants';
import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

import { watchEvery } from 'legacy/utils/saga';

import fetchProperty from 'legacyGraphQL/property';

import {
  PROPERTY_QUERY_DETAILS_BASIC,
  PROPERTY_QUERY_DETAILS_EXTENDED,
  PROPERTY_QUERY_FEATURES_FOR_SOURCE,
  PROPERTY_QUERY_DETAILS_COMPLETE,
  PROPERTY_QUERY_MLS_HISTORY,
  PROPERTY_QUERY_TRANSACTION_HISTORY,
  propertyQueryRequest,
  propertyQueryDetailsExtended,
  propertyQueryFeaturesForSource,
  propertyQuerySuccess
} from 'actions/property';

import { getPropertySourceStatusForQuery } from 'selectors/property';

const { HC, MLS, PR } = PROPERTY_DATA_SOURCES;

function* _shouldSendRequest(addressId, source, query) {
  const status = yield select(
    getPropertySourceStatusForQuery(addressId, source, query)
  );
  return (
    [STATUSES.INIT, STATUSES.PENDING, undefined, null].indexOf(status) > -1
  );
}

export function* handleQueryPropertyDetailsBasic(action) {
  let addressId = action.payload.addressId;
  if (!addressId || _shouldSendRequest(addressId, HC, QUERIES.REPORT)) {
    const graphQLResponse = yield call(fetchProperty, action.payload);
    addressId =
      addressId || get(graphQLResponse, ['response', 'property', 'addressId']);
    yield put(propertyQuerySuccess(addressId, graphQLResponse));
  }
  if (action.payload.queryFeatures) {
    yield call(
      handleQueryPropertyFeaturesForSource,
      propertyQueryFeaturesForSource(addressId, HC)
    );
  }
}

export function* handleQueryPropertyDetailsExtended(action) {
  const { addressId } = action.payload;
  const requests = [
    {
      source: HC,
      query: QUERIES.PROPERTY_FEATURES
    },
    {
      source: MLS,
      query: QUERIES.LISTING_DETAILS
    },
    {
      source: PR,
      query: QUERIES.TAX_HISTORY
    }
  ];

  const requestsToSend = [];
  for (let r = 0; r < requests.length; r++) {
    const request = requests[r];
    const shouldSendRequest = yield call(
      _shouldSendRequest,
      addressId,
      request.source,
      request.query
    );
    if (shouldSendRequest) {
      requestsToSend.push(request);
    }
  }

  yield put(propertyQueryRequest(addressId, requestsToSend));
}

export function* handleQueryPropertyFeaturesForSource(action) {
  const { addressId, source } = action.payload;
  const query = QUERIES.PROPERTY_FEATURES;
  const shouldSendRequest = yield call(
    _shouldSendRequest,
    addressId,
    source,
    query
  );
  if (shouldSendRequest) {
    yield put(propertyQueryRequest(addressId, { source, query }));
  }
}

export function* handleQueryPropertyDetailsComplete(action) {
  const { addressId } = action.payload;
  yield call(
    handleQueryPropertyDetailsExtended,
    propertyQueryDetailsExtended(addressId)
  );
  yield call(
    handleQueryPropertyFeaturesForSource,
    propertyQueryFeaturesForSource(addressId, HC)
  );
  yield call(
    handleQueryPropertyFeaturesForSource,
    propertyQueryFeaturesForSource(addressId, MLS)
  );
  yield call(
    handleQueryPropertyFeaturesForSource,
    propertyQueryFeaturesForSource(addressId, PR)
  );
}

export function* handleQueryPropertyMlsHistory(action) {
  const { addressId } = action.payload;
  const query = QUERIES.MLS_HISTORY;
  const sendRequest = yield call(_shouldSendRequest, addressId, HC, query);
  if (sendRequest) {
    yield put(propertyQueryRequest(addressId, { source: HC, query }));
  }
}

export function* handleQueryPropertyTransactionHistory(action) {
  const { addressId } = action.payload;
  const query = QUERIES.TRANSACTION_HISTORY;
  const sendRequest = yield call(_shouldSendRequest, addressId, HC, query);
  if (sendRequest) {
    yield put(propertyQueryRequest(addressId, { source: HC, query }));
  }
}

export default () => {
  watchEvery({
    [PROPERTY_QUERY_DETAILS_BASIC]: handleQueryPropertyDetailsBasic,
    [PROPERTY_QUERY_DETAILS_EXTENDED]: handleQueryPropertyDetailsExtended,
    [PROPERTY_QUERY_FEATURES_FOR_SOURCE]: handleQueryPropertyFeaturesForSource,
    [PROPERTY_QUERY_DETAILS_COMPLETE]: handleQueryPropertyDetailsComplete,
    [PROPERTY_QUERY_MLS_HISTORY]: handleQueryPropertyMlsHistory,
    [PROPERTY_QUERY_TRANSACTION_HISTORY]: handleQueryPropertyTransactionHistory
  });
};
