import Raven from 'raven-js';
import { report, reportWarning } from 'hc-ravenjs-logger';
import { call, put, select, apply } from 'redux-saga/effects';
import { getAuthClient } from '@hc/authentication-lib/hclib/clients';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { getCurrentParams } from '@hc/redux-saga-router-plus/hclib/selectors';
import { updateUserContextCallback } from 'legacy/utils/refresh-token-callback';
import HC_CONSTANTS from 'HC_CONSTANTS';
import { VIEWS } from 'legacy/routes/constants';

import mixpanelService from 'legacy/services/mixpanel';

import apiUtil from 'legacy/utils/api';
import { watchEvery } from 'legacy/utils/saga';
import { toCamelCase, toSnakeCase } from 'legacy/utils/transform';
import { personalizationDataFromAccount } from 'legacy/utils/personalization';

import {
  CREATE_USER,
  createUserError,
  createUserSuccess,
  PROCESS_INVITATION_CODE,
  processInvitationCodeSuccess,
  processInvitationCodeComplete,
  processInvitationCodeError,
  JOIN_ORG,
  joinOrgSuccess,
  joinOrgError,
  INVITE_TEAM_MEMBERS,
  inviteTeamMembersError,
  RESEND_CONFIRM_USER_EMAIL,
  resendConfirmUserEmailSuccess,
  RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN,
  resendConfirmEmailFromAuthTokenSuccess,
  resendConfirmEmailFromAuthTokenFailure
} from 'actions/sign-up';
import { personalizationDataFailure } from 'actions/personalization';
import { loginPromptHide } from 'actions/auth';

const authLibUrl = `${HC_CONSTANTS.AUTH_LIB_URL}/`;
const confirmUrl = HC_CONSTANTS.CONFIRM_URL;
const invitationUrl = HC_CONSTANTS.INVITATION_URL;

/**
 * Handle Create User
 * Unpack action and select code and organization from state to create a user.
 * @param {Object} action
 * @param {String} action.payload.user.firstName
 * @param {String} action.payload.user.lastName
 * @param {String} action.payload.user.password
 * @param {String} action.payload.user.email
 */
export function* handleCreateUser(action) {
  const { user, routeAfterSignUpSuccess, actionAfterSignUpSuccess } =
    action.payload;
  const { code } = yield select(getCurrentParams);
  const organization = yield select(
    (state) => state.signUp.pendingUser.companyName
  );
  try {
    const accountDetails = yield call(
      createUser,
      toSnakeCase(user),
      code,
      organization
    );
    yield apply(mixpanelService, mixpanelService.loginCallback, [
      accountDetails
    ]);
    yield apply(mixpanelService, mixpanelService.alias, [
      accountDetails.user.email
    ]);
    yield call([Raven, Raven.setUserContext], accountDetails);
    // put accountDetails on state
    yield put(createUserSuccess(accountDetails));
    const personalizationData = personalizationDataFromAccount(accountDetails);
    yield put(personalizationDataFailure(404, '', personalizationData));
    if (actionAfterSignUpSuccess) {
      yield put(actionAfterSignUpSuccess());
    }
    if (routeAfterSignUpSuccess) {
      yield put(
        routeChange({ view: VIEWS.SELECT_PACKAGE, options: { inherit: false } })
      );
    }
    /* Doesn't hurt to do this always, even if we're using the page instead of the modal */
    yield put(loginPromptHide());
  } catch (e) {
    report('Create User Saga: failed to create user', { e, action });
    yield put(createUserError(e.message));
  }
}

/**
 * Interface with authClient.createUser method
 * @param {Object} user
 * @param {String} user.firstName
 * @param {String} user.lastName
 * @param {String} user.password
 * @param {String} user.email
 * @param {String} code
 * @param {String} organization
 */
export function* createUser(user, code, organization) {
  const authClient = getAuthClient(
    HC_CONSTANTS.LOGIN_API_URL,
    authLibUrl,
    updateUserContextCallback
  );
  const companyName = organization || user.company_name;
  const params = { code, application: 'Value Report' };
  if (companyName) {
    params.company_name = companyName;
  }
  if (user.hasOwnProperty('company_name') && !user.company_name) {
    delete user.company_name;
  }
  return toCamelCase(
    yield call(
      [authClient, authClient.createUser],
      user,
      confirmUrl,
      HC_CONSTANTS.APPLICATION_NAME,
      params
    )
  );
}

/**
 * Handle Process Invitation Code
 * Select query params used to obtain code and process
 * to determine if code is valid and load user associated to code.
 */
export function* handleProcessInvitationCode(action) {
  const { params } = action.payload;
  const { code } = params;
  try {
    if (!code) {
      yield put(
        routeChange({ view: VIEWS.LOGIN, options: { inherit: false } })
      );
      reportWarning('Sign Up: Attempted to access signup without code');
      return;
    }

    const pendingUser = yield call(
      apiUtil.GET,
      `${invitationUrl}/code/${code}`
    );
    if (pendingUser.isExistingUser) {
      yield put(processInvitationCodeComplete(pendingUser));
      yield put(
        routeChange({
          view: VIEWS.JOIN_ORGANIZATION,
          query: params,
          options: { inherit: false }
        })
      );
    } else {
      yield put(processInvitationCodeSuccess(pendingUser));
    }
  } catch (e) {
    const message = e.message;
    yield put(processInvitationCodeError(message));
    // Rethrow for Sentry reporting
    throw e;
  }
}

/**
 * Handle Join Organization
 * Select email and code for adding user to an organization
 */
export function* handleJoinOrg(action) {
  const email = yield select((state) => state.signUp.pendingUser.email);
  const { code } = yield select(getCurrentParams);
  try {
    const { message, ...accountDetails } = yield call(
      apiUtil.POST,
      `${HC_CONSTANTS.AUTH_URL}/register`,
      { code, email }
    );

    yield put(joinOrgSuccess(accountDetails));

    yield put(routeChange({ view: VIEWS.SEARCH, options: { inherit: false } }));
  } catch (e) {
    yield put(joinOrgError(e.message));
    // Rethrow for Sentry reporting
    throw e;
  }
}

function* handleInviteTeamMembers(action) {
  try {
    const { emails } = action.payload;
    const { onSuccess } = action.meta;
    const packet = {
      invitations: emails.map((email) => ({ email: email.email })),
      sendEmail: true
    };

    yield call(apiUtil.POST, `${invitationUrl}/bulk`, packet, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    console.error(e);
    report('Failed to invite team members', { e, action });
    yield put(inviteTeamMembersError(e.message));
  }
}

function* handleResendConfirmUserEmail(action) {
  const { token } = action.payload;
  yield call(
    apiUtil.POST,
    `${HC_CONSTANTS.AUTH_URL}/request-new-confirmation-token/${token}`,
    {
      confirmUrl: HC_CONSTANTS.CONFIRM_URL
    }
  );

  yield put(resendConfirmUserEmailSuccess());
}

function* handleResendConfirmEmailFromAuthToken(action) {
  try {
    const { token } = action.payload;
    yield call(apiUtil.POST, `${HC_CONSTANTS.AUTH_URL}/reconfirm/${token}`, {
      confirmUrl: HC_CONSTANTS.CONFIRM_URL
    });

    yield put(resendConfirmEmailFromAuthTokenSuccess());
  } catch (e) {
    yield put(resendConfirmEmailFromAuthTokenFailure());
  }
}

export default function registerSignUpSaga() {
  watchEvery({
    [CREATE_USER]: handleCreateUser,
    [PROCESS_INVITATION_CODE]: handleProcessInvitationCode,
    [JOIN_ORG]: handleJoinOrg,
    [INVITE_TEAM_MEMBERS]: handleInviteTeamMembers,
    [RESEND_CONFIRM_USER_EMAIL]: handleResendConfirmUserEmail,
    [RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN]:
      handleResendConfirmEmailFromAuthToken
  });
}
