import React from 'react';
import { useDispatch } from 'react-redux';
import { FilterCard } from 'src/lib/components/FilterCard';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { RadioOption, RadioSelect } from 'src/lib/components/RadioSelect';
import { isLoading } from 'src/lib/utils/general';
import { documentPatch } from '../../actions';
import { useDocumentRole, useReport } from '../../hooks';
import { DocumentRoles, FilterDocument, SubjectDocument } from '../../types';
import { CompFields } from '../../types/CompFields';
import { compFieldGetters } from '../../utils/comps.utils';
import { CompFilterClearButton } from './CompFilterClearButton';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
}
const COMP_FIELD = CompFields.stories;
const OPTIONS: RadioOption<null | 'Single Story' | 'Multi Story'>[] = [
  {
    label: 'Any',
    value: null
  },
  {
    label: 'Single Story',
    value: 'Single Story'
  },
  {
    label: 'Multi Story',
    value: 'Multi Story'
  }
];

export const CompFilterStories = ({
  reportId,
  dataHcName,
  className
}: Props) => {
  const dispatch = useDispatch();
  const {
    status: filterDocumentStatus,
    data: [filterDocument]
  } = useDocumentRole<FilterDocument>(reportId, DocumentRoles.Filter);
  const {
    status: subjectStatus,
    data: [subjectDocument]
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  const { status: reportStatus, data: report } = useReport(reportId);
  if (
    isLoading([filterDocumentStatus, reportStatus, subjectStatus]) ||
    !filterDocument ||
    !report ||
    !subjectDocument
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const filterValue = filterDocument.data.filters[COMP_FIELD];
  const subjectValue = compFieldGetters[COMP_FIELD](subjectDocument.data);
  return (
    <FilterCard
      label="Stories"
      info={`Subject: ${
        subjectValue === null
          ? 'Unknown'
          : subjectValue
          ? 'Single Story'
          : 'Multi Story'
      }`}
      dataHcName={dataHcName}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compField={COMP_FIELD}
          dataHcName={`${dataHcName}-clear`}
        />
      }
    >
      <RadioSelect
        dataHcName={`${dataHcName}-select`}
        value={
          filterValue?.absoluteValue
            ? filterValue.absoluteValue[0] === 1
              ? 'Single Story'
              : 'Multi Story'
            : null
        }
        options={OPTIONS}
        className={className}
        onChange={(value) => {
          if (value === null) {
            dispatch(
              documentPatch(reportId, filterDocument.documentId, [
                {
                  op: 'remove',
                  path: `/data/filters/${COMP_FIELD}`
                }
              ])
            );
          } else {
            dispatch(
              documentPatch(reportId, filterDocument.documentId, [
                {
                  op: 'add',
                  path: `/data/filters/${COMP_FIELD}/absoluteValue`,
                  value: value === 'Single Story' ? [1, null] : [2, null]
                }
              ])
            );
          }
        }}
      />
    </FilterCard>
  );
};
