import classNames from 'classnames';
import React from 'react';
import { useBrokerageName } from 'src/lib/property-lookup/hooks/useBrokerageName';
import { CompSchema, SubjectSchema } from '../../types/schemas';
import styles from './BrokerageCredit.css';

interface Props {
  dataHcName: string;
  className?: string;
  schema: CompSchema | SubjectSchema;
}
export const BrokerageCredit = ({ dataHcName, schema, className }: Props) => {
  const { data } = useBrokerageName(
    schema.address.hcAddressId,
    schema.latestListing.listingID
      ? {
          type: 'listing',
          listingID: schema.latestListing.listingID
        }
      : undefined
  );
  if (!data) return null;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.BrokerageCredit, className)}
    >
      {`Courtesy of ${data}`}
    </div>
  );
};
