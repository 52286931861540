import { CompFields } from '../types/CompFields';
import { CompId, CompSchema, SubjectSchema } from '../types/schemas';
import { isCompSchema } from './propertyLookupExtended.utils';

export const createCompId = (compSchema: CompSchema): CompId => {
  const id = `_${compSchema.address.hcAddressId}_${
    compSchema.latestListing.mlsID
  }_${compSchema.latestListing.listingID || ''}` as CompId;
  return id;
};

export const compFieldGetters = {
  [CompFields.address]: (schema: CompSchema | SubjectSchema) => schema.address,
  [CompFields.similarity]: (schema: CompSchema | SubjectSchema) =>
    (isCompSchema(schema) && schema.similarity) || undefined,
  [CompFields.distanceMiles]: (schema: CompSchema | SubjectSchema) =>
    (isCompSchema(schema) && schema.distanceMiles) || undefined,
  [CompFields.bedrooms]: (schema: CompSchema | SubjectSchema) =>
    schema.livingSpace.bedrooms?.count,
  [CompFields.bathrooms]: (schema: CompSchema | SubjectSchema) =>
    schema.livingSpace.bathrooms?.summaryCount,
  [CompFields.grossLivingAreaSqft]: (schema: CompSchema | SubjectSchema) =>
    schema.livingSpace.livingArea,
  [CompFields.siteAreaSqft]: (schema: CompSchema | SubjectSchema) =>
    schema.site.area,
  [CompFields.propertyType]: (schema: CompSchema | SubjectSchema) =>
    schema.propertyType,
  [CompFields.yearBuilt]: (schema: CompSchema | SubjectSchema) =>
    schema.structure.yearBuilt,
  [CompFields.propertyStatus]: (schema: CompSchema | SubjectSchema) =>
    schema.latestListing.listingStatus.MlsState,
  [CompFields.subdivisionName]: (schema: CompSchema | SubjectSchema) =>
    schema.countyLegal.subdivision,
  [CompFields.salesDate]: (schema: CompSchema | SubjectSchema) =>
    schema.saleDate,
  [CompFields.salesPrice]: (schema: CompSchema | SubjectSchema) =>
    schema.salePrice,
  [CompFields.pricePerSqft]: (schema: CompSchema | SubjectSchema) =>
    schema.salePrice && schema.livingSpace.livingArea
      ? schema.salePrice / schema.livingSpace.livingArea
      : undefined,
  [CompFields.pricePerSqftNonDisclosure]: (
    schema: CompSchema | SubjectSchema
  ) =>
    schema.listPrice && schema.livingSpace.livingArea
      ? schema.listPrice / schema.livingSpace.livingArea
      : undefined,
  [CompFields.activeDaysOnMarket]: (schema: CompSchema | SubjectSchema) =>
    schema.latestListing.listingStatus.daysOnMarket,
  [CompFields.cumulativeDaysOnMarket]: (schema: CompSchema | SubjectSchema) =>
    schema.latestListing.listingStatus.cumulativeDaysOnMarket,
  [CompFields.currentValue]: (schema: CompSchema | SubjectSchema) =>
    schema.avm.priceMean,
  [CompFields.pool]: (schema: CompSchema | SubjectSchema) =>
    schema.latestListing.external.pool,
  [CompFields.garageNumCars]: (schema: CompSchema | SubjectSchema) =>
    schema.externalFeatures?.parking.garageExperimental?.spaces,
  [CompFields.stories]: (schema: CompSchema | SubjectSchema) =>
    schema.structure.stories,
  [CompFields.basement]: (schema: CompSchema | SubjectSchema) =>
    schema.latestListing.livingSpace.basement.has,
  // @jnettleman - This field isn't in the schema yet
  // eslint-disable-next-line
  [CompFields.isDistressed]: (schema: CompSchema | SubjectSchema) => null,
  [CompFields.flips]: (schema: CompSchema | SubjectSchema) =>
    schema.latestListing.listingStatus.flips,
  [CompFields.lastListDate]: (schema: CompSchema | SubjectSchema) =>
    schema.listDate,
  [CompFields.lastListPrice]: (schema: CompSchema | SubjectSchema) =>
    schema.listPrice
};
