import moment from 'moment';
import isEmpty from 'lodash.isempty';
import { watchEvery } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { call, put, select } from 'redux-saga/effects';

import { MAP_ID_COMPS, MAP_ID_RENTAL_COMPS } from 'legacy/appstore/constants';

import { filtersSavedToRelativeValues } from 'legacy/utils/saved-filters';
import { normalizeDate } from 'legacy/utils/dates';

import {
  COMPS_CHANGE_FILTER_SET_ACTIVE,
  compsClearFilter,
  compsFiltersChangeAbsolute,
  compsFiltersChangeRelative,
  compsFilterDistanceChange
} from 'actions/comps';
import {
  RENTAL_COMPS_CHANGE_FILTER_SET_ACTIVE,
  rentalCompsClearFilter,
  rentalCompsFilterDistanceChange,
  rentalCompsFiltersChangeAbsolute,
  rentalCompsFiltersChangeRelative
} from 'actions/rental-comps';
import {
  GET_REPORT_HC_VERSION_SUCCESS,
  GET_REPORT_USER_VERSION_NOT_FOUND,
  GET_REPORT_RENTAL_SUCCESS,
  GET_REPORT_SUBJECT_DETAILS_SUCCESS
} from 'actions/get-report';
import { PREFERENCES_FETCH_SUCCESS } from 'actions/preferences';
import { SUBJECT_UPDATE_GEO_LOCATION } from 'actions/subject';
import { EFFECTIVE_DATE_CREATE_REPORT_SUCCESS } from 'actions/effective-date';

import {
  getCompsFiltersUpdatedFromPreferences,
  getCompsFilters
} from 'selectors/comps';
import { getRentalCompsFilters } from 'selectors/rental-comps';
import {
  getReportStatusUserVersionDoesNotExist,
  getReportStatusRentalReportIsLoadedInitial,
  getReportStatusSubjectDetailsIsLoadedInitial,
  getReportStatusHcVersionIsLoadedInitial,
  getReportStatusUserVersionIsLoadedInitialAndExists
} from 'selectors/report-status';
import {
  getPreferenesIsLoadedInitial,
  getPreferencesFiltersCompsFilterSetDefault,
  getPreferencesFiltersRentalCompsFilterSetDefault,
  getPreferencesFiltersCompsFilterSetById,
  getPreferencesFiltersRentalCompsFilterSetById
} from 'selectors/preferences';
import {
  getIsViewingSharedReport,
  getIsEffectiveDateReport
} from 'selectors/property-explorer';
import {
  getSubjectLocationUnknown,
  getSubjectRental,
  getSubjectAdjusted
} from 'selectors/subject';

const _getLast2YearsDefaultValues = () => [
  normalizeDate(moment().subtract(2, 'years')),
  normalizeDate(moment())
];

function* applySavedFiltersComps(filterSetId) {
  try {
    const locationUnknown = yield select(getSubjectLocationUnknown);
    if (locationUnknown) return;
    const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
    const filtersSaved = filterSetId
      ? yield select(getPreferencesFiltersCompsFilterSetById(filterSetId))
      : yield select(getPreferencesFiltersCompsFilterSetDefault);
    if (filtersSaved && !isEmpty(filtersSaved.values)) {
      const subject = yield select(getSubjectAdjusted);
      const filterValues = filtersSavedToRelativeValues(filtersSaved, subject);
      if (!isEmpty(filterValues)) {
        yield put(compsFiltersChangeRelative(filterValues, filterSetId));
      }
      if (filtersSaved.values && filtersSaved.values.distance) {
        yield put(
          compsFilterDistanceChange(
            MAP_ID_COMPS,
            filtersSaved.values.distance.value,
            subject.geoLocation
          )
        );
      } else {
        // Check if distance is currently applied but missing in the saved filter set and remove it.
        const currentFilters = yield select(getCompsFilters);
        if (currentFilters.distance) {
          yield put(compsClearFilter('distance', MAP_ID_COMPS));
        }
      }
    } else if (!filterSetId && !isEffectiveDateReport) {
      yield put(
        compsFiltersChangeAbsolute({
          salesDate: _getLast2YearsDefaultValues()
        })
      );
    }
  } catch (e) {
    console.error(e);
    report('Saga: applySavedFiltersComps', { error: e });
  }
}

function* applySavedFiltersRentalComps(filterSetId) {
  try {
    const locationUnknown = yield select(getSubjectLocationUnknown);
    if (locationUnknown) return;
    const filtersSaved = filterSetId
      ? yield select(getPreferencesFiltersRentalCompsFilterSetById(filterSetId))
      : yield select(getPreferencesFiltersRentalCompsFilterSetDefault);

    if (filtersSaved && !isEmpty(filtersSaved.values)) {
      const subject = yield select(getSubjectRental);
      const filterValues = filtersSavedToRelativeValues(filtersSaved, subject);
      if (!isEmpty(filterValues)) {
        yield put(rentalCompsFiltersChangeRelative(filterValues, filterSetId));
      }
      if (filtersSaved.values && filtersSaved.values.distance) {
        yield put(
          rentalCompsFilterDistanceChange(
            MAP_ID_RENTAL_COMPS,
            filtersSaved.values.distance.value,
            subject.geoLocation
          )
        );
      } else {
        // Check if distance is currently applied but missing in the saved filter set and remove it.
        const currentFilters = yield select(getRentalCompsFilters);
        if (currentFilters.distance) {
          yield put(rentalCompsClearFilter('distance', MAP_ID_RENTAL_COMPS));
        }
      }
    } else if (!filterSetId) {
      yield put(
        rentalCompsFiltersChangeAbsolute({
          leasedDate: _getLast2YearsDefaultValues()
        })
      );
    }
  } catch (e) {
    console.error(e);
    report('Saga: applySavedFiltersRentalComps', { error: e });
  }
}

function* handleNewReport(action) {
  // Handles case where user version does not exist and subject is loaded and preferences are loaded
  const preferencesLoaded = yield select(getPreferenesIsLoadedInitial);
  const isSharedReport = yield select(getIsViewingSharedReport);
  const subjectDetailsIsLoaded = yield select(
    getReportStatusSubjectDetailsIsLoadedInitial
  );
  const hcVersionIsLoaded = yield select(
    getReportStatusHcVersionIsLoadedInitial
  );
  // Preferences and Subject must be loaded on unshared report
  if (
    preferencesLoaded &&
    !isSharedReport &&
    (subjectDetailsIsLoaded || hcVersionIsLoaded)
  ) {
    yield call(applySavedFiltersComps);
  }
}

function* handleSubjectLoaded(action) {
  // Handles case where subject loaded after user version returns 404 and preferences loaded
  const preferencesLoaded = yield select(getPreferenesIsLoadedInitial);
  const isNewReport = yield select(getReportStatusUserVersionDoesNotExist);
  const isSharedReport = yield select(getIsViewingSharedReport);
  const filtersAlreadyUpdated = yield select(
    getCompsFiltersUpdatedFromPreferences
  );
  const hcVersionLoaded = yield select(getReportStatusHcVersionIsLoadedInitial);
  const userVersionLoaded = yield select(
    getReportStatusUserVersionIsLoadedInitialAndExists
  );
  if (
    isNewReport &&
    (hcVersionLoaded || userVersionLoaded) &&
    preferencesLoaded &&
    !isSharedReport &&
    !filtersAlreadyUpdated
  ) {
    yield call(applySavedFiltersComps);
  }
}

function* handleRentalReportLoaded(action) {
  // Handles case when rental report and preferences and loaded
  const isNewReport = yield select(getReportStatusUserVersionDoesNotExist);
  const isSharedReport = yield select(getIsViewingSharedReport);
  const preferencesLoaded = yield select(getPreferenesIsLoadedInitial);
  if (isNewReport && !isSharedReport && preferencesLoaded) {
    yield call(applySavedFiltersRentalComps);
  }
}

function* handlePreferencesLoaded(action) {
  // Handles case where preferences respond after report is loaded (unlikely)
  const isNewReport = yield select(getReportStatusUserVersionDoesNotExist);
  const isSharedReport = yield select(getIsViewingSharedReport);
  const subjectDetailsIsLoaded = yield select(
    getReportStatusSubjectDetailsIsLoadedInitial
  );
  const hcVersionIsLoaded = yield select(
    getReportStatusHcVersionIsLoadedInitial
  );
  // Subject must be loaded on new unshared report
  if (isNewReport && !isSharedReport) {
    if (subjectDetailsIsLoaded || hcVersionIsLoaded) {
      yield call(applySavedFiltersComps);
    }
    const rentalReportLoaded = yield select(
      getReportStatusRentalReportIsLoadedInitial
    );
    if (rentalReportLoaded) {
      yield call(applySavedFiltersRentalComps);
    }
  }
}

function* handleGeoLocationChange(action) {
  const { locationUnknown } = action.payload;
  if (locationUnknown) {
    yield call(applySavedFiltersComps);
    yield call(applySavedFiltersRentalComps);
  }
}

function* handleFilterSetChange(action) {
  const { filterSetId } = action.payload;
  if (action.type === RENTAL_COMPS_CHANGE_FILTER_SET_ACTIVE) {
    yield call(applySavedFiltersRentalComps, filterSetId);
  } else {
    yield call(applySavedFiltersComps, filterSetId);
  }
}

export default function registerSagas() {
  watchEvery({
    [GET_REPORT_HC_VERSION_SUCCESS]: handleSubjectLoaded,
    [GET_REPORT_SUBJECT_DETAILS_SUCCESS]: handleSubjectLoaded,
    [GET_REPORT_USER_VERSION_NOT_FOUND]: handleNewReport,
    [GET_REPORT_RENTAL_SUCCESS]: handleRentalReportLoaded,
    [PREFERENCES_FETCH_SUCCESS]: handlePreferencesLoaded,
    [SUBJECT_UPDATE_GEO_LOCATION]: handleGeoLocationChange,
    [COMPS_CHANGE_FILTER_SET_ACTIVE]: handleFilterSetChange,
    [RENTAL_COMPS_CHANGE_FILTER_SET_ACTIVE]: handleFilterSetChange,
    [EFFECTIVE_DATE_CREATE_REPORT_SUCCESS]: handleNewReport
  });
}
