import {
  call,
  put,
  select,
  actionChannel,
  take,
  takeEvery
} from 'redux-saga/effects';
import { report } from 'hc-ravenjs-logger';
import PropertyGraph from 'legacy/services/property-graph';
import { getSharedReportUid } from 'selectors/share-report';
import { getIsViewingSharedReport } from 'selectors/property-explorer';
import apiUtil from 'legacy/utils/api';
import HC_CONSTANTS from 'HC_CONSTANTS';
import {
  listingsFetch,
  LISTINGS_REQUIRED,
  listingsFetchFailure,
  listingsFetchSuccess,
  LISTINGS_FETCH
} from '../actions/listings.actions';
import { getPropertyListingsStatus } from 'selectors/listings';
import { STATUSES } from 'legacy/appstore/constants';
import sagaMiddleware from 'middleware/saga';
import { scrubDataListings } from 'sagas/mls-compliance.saga';

const { VRSHARE_PROXY_URL } = HC_CONSTANTS;
export function* handleListingsFetch(action) {
  const { addressId } = action.payload;
  try {
    const isViewingSharedReport = yield select(getIsViewingSharedReport);
    let propertyLookup;
    if (isViewingSharedReport) {
      const uid = yield select(getSharedReportUid);
      const response = yield call(
        apiUtil.GET,
        `${VRSHARE_PROXY_URL}/emporium/vrshare/listing_status`,
        {
          addressId,
          uid
        }
      );
      propertyLookup = yield call(
        scrubDataListings,
        response.data.propertyLookup
      );
    } else {
      const response = yield call(PropertyGraph.fetchListings, addressId);
      propertyLookup = yield call(scrubDataListings, response.propertyLookup);
    }
    yield put(listingsFetchSuccess(addressId, propertyLookup.listings || []));
  } catch (e) {
    yield put(listingsFetchFailure(addressId));
    console.error(e);
    report('Listings: Saga Failed to Fetch Data', { e, action });
  }
}

function* takeListingsRequired() {
  const requiredChannel = yield actionChannel(LISTINGS_REQUIRED);
  while (true) {
    const action = yield take(requiredChannel);
    const status = yield select(
      getPropertyListingsStatus(action.payload.addressId)
    );
    if (!status || status === STATUSES.INIT) {
      yield put(listingsFetch(action.payload.addressId));
    }
  }
}

function* runSagas() {
  yield takeEvery(LISTINGS_FETCH, handleListingsFetch);
  yield call(takeListingsRequired);
}

export default function registerListingsSaga() {
  sagaMiddleware.run(runSagas);
}
