import { createSelector } from 'reselect';
import { STATUSES } from '../../constants';
import { REPORT_API_REDUCER_KEY } from './constants';
import { StateWithReportApi } from './reducer';
import { DocumentBase, DocumentRoles } from './types';
import { createFactorySelector } from '../utils/redux';

export const selReportApiState = (state: StateWithReportApi) =>
  state[REPORT_API_REDUCER_KEY];

export const selReportApiReportsState = createSelector(
  selReportApiState,
  (reportApiState) => {
    return reportApiState.reports;
  }
);

export const selReportState = createFactorySelector((reportId: number) =>
  createSelector(selReportApiReportsState, (reportsState) => {
    return reportsState[reportId];
  })
);

export const selReportStatus = createFactorySelector((reportId: number) =>
  createSelector(selReportState(reportId), (reportState) => reportState?.status)
);

export const selReportData = createFactorySelector((reportId: number) =>
  createSelector(selReportState(reportId), (reportState) => {
    if (reportState) {
      if (reportState.status === STATUSES.SUCCESS) {
        return reportState.data;
      }
    }
    return undefined;
  })
);

export const selDocumentRolesState = createSelector(
  selReportApiState,
  (reportApiState) => {
    return reportApiState.documentRoles;
  }
);

export const selDocumentRoleForReport = createFactorySelector(
  (reportId: number) =>
    createSelector(
      selDocumentRolesState,
      (documentRolesState) => documentRolesState[reportId]
    )
);

export const selReportApiDocumentRole = createFactorySelector(
  ([reportId, documentRole]: [reportId: number, documentRole: DocumentRoles]) =>
    createSelector(
      selDocumentRoleForReport(reportId),
      (documentRoleReportState) => documentRoleReportState?.[documentRole]
    )
);

export const selDocumentRoleStatus = createFactorySelector(
  ([reportId, documentRole]: [reportId: number, documentRole: DocumentRoles]) =>
    createSelector(
      selReportApiDocumentRole([reportId, documentRole]),
      (documentRole) => documentRole?.status
    )
);

export const selDocumentState = createSelector(
  selReportApiState,
  (reportApiState) => reportApiState.documents
);

export const selDocumentsByReportId = createFactorySelector(
  (reportId: number) =>
    createSelector(selDocumentState, (documentState) => documentState[reportId])
);

export const selDocumentsByRole = createFactorySelector(
  <DocType extends DocumentBase>([reportId, documentRole]: [
    reportId: number,
    documentRole: DocumentRoles
  ]) =>
    createSelector(selDocumentsByReportId(reportId), (reportDocuments) => {
      const results: DocType[] = [];
      if (reportDocuments) {
        Object.keys(reportDocuments).forEach((documentID) => {
          const documentState = reportDocuments[documentID];
          if (
            documentState?.status === STATUSES.SUCCESS &&
            documentState.data.role === documentRole
          ) {
            const document = documentState.data as DocType;
            results.push(document);
          }
        });
      }
      return results;
    })
);

export const selDocumentStateById = createFactorySelector(
  ([reportId, documentId]: [number, string]) =>
    createSelector(selDocumentsByReportId(reportId), (documents) => {
      return documents?.[documentId];
    })
);

export const selDocumentStatus = createFactorySelector(
  ([reportId, documentId]: [number, string]) =>
    createSelector(
      selDocumentStateById([reportId, documentId]),
      (documentState) => documentState?.status
    )
);

export const selDocument = createFactorySelector(
  <DocType extends DocumentBase>([reportId, documentId]: [number, string]) =>
    createSelector(
      selDocumentStateById([reportId, documentId]),
      (documentState) =>
        documentState?.status === STATUSES.SUCCESS
          ? (documentState.data as DocType)
          : undefined
    )
);
