import { watchEvery } from 'legacy/utils/saga';
import { put, call, select } from 'redux-saga/effects';

import HC_CONSTANTS from 'HC_CONSTANTS';

import apiUtil from 'legacy/utils/api';
import { selectScrubDataArgs } from './mls-compliance.saga';
import { rentalReportSchemaService } from 'legacy/services/report-schema';

import {
  GET_REPORT_RENTAL,
  getReportRentalSuccess,
  getReportRentalFailure
} from 'actions/get-report';

import {
  getCurrentReportParams,
  getUseVRShareEndpoint
} from 'selectors/property-explorer';
import { getSubjectDefault } from 'selectors/subject';

export function* fetchRentalReport() {
  const reportParams = yield select(getCurrentReportParams);
  const useVRShareEndpoint = yield select(getUseVRShareEndpoint);
  const subject = yield select(getSubjectDefault);
  const url = useVRShareEndpoint
    ? `${HC_CONSTANTS.VRSHARE_PROXY_URL}/emporium/vrshare/rental_report`
    : `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/rental_report/`;

  const responseData = yield call(
    apiUtil.GET,
    url,
    {
      ...reportParams,
      address: reportParams.streetAddress,
      unit: subject.unit || reportParams.unit,
      addressSlug: subject.addressSlug || reportParams.addressSlug,
      format: 'json',
      productType: 'rental'
    },
    { expect404: true }
  );

  if (responseData.statusCode !== 404) {
    const scrubDataArgs = yield call(selectScrubDataArgs);
    const responseDataTransformed = rentalReportSchemaService(
      responseData,
      scrubDataArgs
    );
    yield put(getReportRentalSuccess(responseDataTransformed));
  } else {
    yield put(getReportRentalFailure(responseData.messageRaw));
  }
}

function* handleGetReportRental(action) {
  yield call(fetchRentalReport);
}

export default function registerRentalReportSaga() {
  watchEvery({
    [GET_REPORT_RENTAL]: handleGetReportRental
  });
}
