import SVGInline from 'react-svg-inline';
import classNames from 'classnames';

import backIcon from 'legacy/assets/svg-inline/back.svg';

import { BackIcon as theme } from 'legacy/css-modules/generic';

const BackIcon = (props) => {
  return (
    <SVGInline
      {...props}
      className={classNames(theme.BackIcon, props.className)}
      svg={backIcon}
    />
  );
};

export default BackIcon;
