import { createSelector } from 'reselect';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';

import { AVM_KEY_HC, AVM_KEY_USER } from 'legacy/appstore/constants';
import {
  CHART_DATA_VALUE_FNS,
  NEARBY_CHART_ORDER
} from 'legacy/services/chart-data';

import { getAvmSelectedKey, getAvmSelected } from 'selectors/avms';
import { getSubjectSelected } from 'selectors/subject';
import { getForecastChartAreaType } from 'selectors/property-explorer';
import { getNearbyProperties } from 'selectors/nearby-properties';
import { getCompsAvailableProperties } from 'selectors/comps';

const getChartDataState = (state) => state.chartData;

const getChartDataAvmKey = createSelector(getAvmSelectedKey, (avmKey) =>
  avmKey === AVM_KEY_HC ? AVM_KEY_HC : AVM_KEY_USER
);

export const getChartDataSelectedValuationChartType = createSelector(
  getChartDataState,
  (chartDataState) => chartDataState.selectedValuationChartType
);

export const getChartDataSelectedNeighborhoodChartType = createSelector(
  getChartDataState,
  (chartDataState) => chartDataState.selectedNeighborhoodChartType
);

export const getChartDataSelectedNeighborhoodDataKey = createSelector(
  getChartDataState,
  (chartDataState) => chartDataState.selectedNeighborhoodChartData
);

export const getChartDataNeighborhoodSelected = createSelector(
  getChartDataState,
  getChartDataAvmKey,
  (chartDataState, chartAvmKey) => {
    if (chartDataState.selectedNeighborhoodChartType === 'nearby') {
      let data = pick(chartDataState[chartAvmKey], NEARBY_CHART_ORDER);
      if (isEmpty(data) && chartAvmKey !== AVM_KEY_HC) {
        data = pick(chartDataState[AVM_KEY_HC], NEARBY_CHART_ORDER);
      }
      return data;
    } else if (
      chartDataState.selectedNeighborhoodChartType === 'availableComps'
    ) {
      return chartDataState.availableComps;
    }
  }
);

export const getChartDataSelectedSubjectValue = createSelector(
  getChartDataSelectedNeighborhoodDataKey,
  getSubjectSelected,
  getAvmSelected,
  (chartDataSelectedKey, subject, avm) =>
    CHART_DATA_VALUE_FNS[chartDataSelectedKey].fn({
      ...subject,
      currentValue: avm.value
    })
);

export const getForecastChartDataSelected = createSelector(
  getChartDataState,
  getAvmSelectedKey,
  getSubjectSelected,
  getForecastChartAreaType,
  (chartDataState, avmKey, subject, areaType) => {
    return get(chartDataState, [avmKey, `${areaType}Ts`], {});
  }
);

export const getForecastChartHasBlockData = createSelector(
  getChartDataState,
  getAvmSelectedKey,
  getSubjectSelected,
  (chartDataState, avmKey, subject) => {
    return !!get(chartDataState, [avmKey, 'blockTs', 'full'], []).length;
  }
);

export const getForecastChartHasZipData = createSelector(
  getChartDataState,
  getAvmSelectedKey,
  getSubjectSelected,
  (chartDataState, avmKey, subject) => {
    return !!get(chartDataState, [avmKey, 'zipTs', 'full'], []).length;
  }
);

export const getChartDataMonthsOfSupply = createSelector(
  getChartDataState,
  (chartDataState) => chartDataState[AVM_KEY_HC].monthsOfSupply
);

export const getChartDataDaysOnMarket = createSelector(
  getChartDataState,
  (chartDataState) => chartDataState[AVM_KEY_HC].daysOnMarket
);

export const getChartDataMSA = createSelector(
  getChartDataState,
  (chartDataState) => {
    return chartDataState[AVM_KEY_HC].msaDetails;
  }
);

export const getChartDataMarketIndex = createSelector(
  getChartDataState,
  (chartDataState) => chartDataState[AVM_KEY_HC].marketIndex
);

export const getChartDataRiskOfDecline = createSelector(
  getChartDataState,
  (chartDataState) => chartDataState[AVM_KEY_HC].riskOfDecline
);

export const getForecastChartDataForAvm = createSelector(
  getChartDataState,
  getAvmSelectedKey,
  (chartDataState, avmKey) => {
    return chartDataState[avmKey];
  }
);

export const getSimilarPropertyLowerPercentileData = createSelector(
  getChartDataState,
  (chartDataState) => {
    return get(
      chartDataState,
      [AVM_KEY_HC, 'similarProperties', 'percentile90Lower'],
      null
    );
  }
);

export const getSimilarPropertyUpperPercentileData = createSelector(
  getChartDataState,
  (chartDataState) => {
    return get(
      chartDataState,
      [AVM_KEY_HC, 'similarProperties', 'percentile90Upper'],
      null
    );
  }
);

export const getOneYearForecastData = createSelector(
  getForecastChartDataForAvm,
  (chartDataStateByAvm) => {
    return {
      zipTsOneYear: get(
        chartDataStateByAvm,
        ['zipTs', 'forecast', 0, 'pctDisplay'],
        null
      ),
      blockTsOneYear: get(
        chartDataStateByAvm,
        ['blockTs', 'forecast', 0, 'pctDisplay'],
        null
      )
    };
  }
);

export const getThreeYearForecastData = createSelector(
  getForecastChartDataForAvm,
  (chartDataStateByAvm) => {
    return {
      zipTsOneYear: get(
        chartDataStateByAvm,
        ['zipTs', 'forecast', 2, 'pctDisplay'],
        null
      )
    };
  }
);

export const getChartDataRiskOfDeclinePercentValue = createSelector(
  getChartDataRiskOfDecline,
  (riskOfDeclineValue) => get(riskOfDeclineValue, ['value'], null)
);

export const getPropertiesForValuationChart = createSelector(
  getChartDataSelectedValuationChartType,
  getNearbyProperties,
  getCompsAvailableProperties,
  (chartType, nearby, available) => {
    return (chartType === 'availableComps' ? available : nearby).filter((c) => {
      return chartType === 'availableComps'
        ? c && c.similarityLevelAdjusted === 'high'
        : c && c.similarityLevel === 'high';
    });
  }
);

const NEARBY_CHART_KEY_MAPPING = {
  sqFt: 'nearbyGrossLivingAreaChart',
  dolSqFt: 'nearbyDollarPerGrossLivingAreaChart',
  siteArea: 'nearbySiteAreaChart',
  dolSiteArea: 'nearbyDollarPerSiteAreaChart',
  bedrooms: 'nearbyBedroomsChart',
  bathrooms: 'nearbyBathroomsChart',
  age: 'nearbyAgesChart'
};

// Replaces the report JSON chart data w/ the nearby data computed client-side
export const getReportChartDataNearbyProperties = createSelector(
  getChartDataNeighborhoodSelected,
  getSubjectSelected,
  getAvmSelected,
  (chartData, subject, avm) => {
    let formatted = {};

    for (let chartType in NEARBY_CHART_KEY_MAPPING) {
      let thisChartData = {
        nearbyProperties: {},
        subject: CHART_DATA_VALUE_FNS[chartType].fn({
          ...subject,
          currentValue: avm.value
        })
      };
      for (let i in chartData[chartType]) {
        const rangeKey = `${chartData[chartType][i].x[0]}-${chartData[chartType][i].x[1]}`;
        thisChartData.nearbyProperties[rangeKey] = chartData[chartType][i].y;
      }
      formatted[NEARBY_CHART_KEY_MAPPING[chartType]] = thisChartData;
    }
    return formatted;
  }
);
