import { createSelector } from 'reselect';

export const getFullMlsState = (state) => state.mls;

export const getMlsIds = createSelector(getFullMlsState, (fullMlsState) =>
  Object.keys(fullMlsState).map((id) => Number(id))
);

export const getMlsState = (mlsId) =>
  createSelector(getFullMlsState, (fullMlsState) => fullMlsState[mlsId]);

export const getMlsStatus = (mlsId) =>
  createSelector(getMlsState(mlsId), (mlsState) => mlsState.status);

export const getMlsData = (mlsId) =>
  createSelector(getMlsState(mlsId), (mlsState) => mlsState.data);
