import { useSelector } from 'react-redux';
import { selKeywordSearchMatches, selKeywordSearchStatus } from '../selectors';

export const useKeywordSearchMatchesByEntityId = () => {
  const status = useSelector(selKeywordSearchStatus);
  const matches = useSelector(selKeywordSearchMatches);
  const data: Record<string, boolean> = {};
  matches?.forEach((m) => {
    data[m.entity_id] = true;
  });
  return {
    status,
    data
  };
};
