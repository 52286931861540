import { STATUSES } from 'src/constants';
import {
  CobrandingActions,
  COBRANDING_FETCH,
  COBRANDING_FETCH_SUCCESS,
  COBRANDING_FETCH_ERROR
} from '../actions';
import { Personalization } from '../../huell/types';

interface CobrandingStateLoading {
  status: STATUSES.LOADING;
  fetching: true;
}

interface CobrandingStateSuccess {
  status: STATUSES.SUCCESS;
  fetching: boolean;
  data: Personalization;
}

interface CobrandingStateError {
  status: STATUSES.ERROR;
  fetching: boolean;
  message: string;
}

export type CobrandingState =
  | CobrandingStateLoading
  | CobrandingStateSuccess
  | CobrandingStateError;

type CobrandingsState = Record<number, CobrandingState>;

const INITIAL_STATE = {} as CobrandingsState;

export const COBRANDING_REDUCER_KEY = 'cobranding';

export const cobrandingReducer = <A>(
  state: CobrandingsState = INITIAL_STATE,
  action: A & CobrandingActions
): CobrandingsState => {
  switch (action.type) {
    case COBRANDING_FETCH: {
      const { id } = action.payload;
      const updatedState = { ...state };
      const cobrandingState = updatedState[id];
      if (cobrandingState) {
        updatedState[id] = {
          ...cobrandingState,
          fetching: true
        };
      } else {
        updatedState[id] = {
          status: STATUSES.LOADING,
          fetching: true
        };
      }
      return updatedState;
    }

    case COBRANDING_FETCH_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          status: STATUSES.SUCCESS,
          fetching: false,
          data: action.payload.data
        }
      };
    }

    case COBRANDING_FETCH_ERROR: {
      return {
        ...state,
        [action.payload.id]: {
          status: STATUSES.ERROR,
          fetching: false,
          message: action.payload.message
        }
      };
    }

    default:
      return state;
  }
};
