export const CHART_DATA_VALUATION_CHART_TYPE_SELECT =
  'CHART_DATA_VALUATION_CHART_TYPE_SELECT';
export const CHART_DATA_NEIGHBORHOOD_CHART_TYPE_SELECT =
  'CHART_DATA_NEIGHBORHOOD_CHART_TYPE_SELECT';
export const CHART_DATA_NEIGHBORHOOD_CHART_DATA_SELECT =
  'CHART_DATA_NEIGHBORHOOD_CHART_DATA_SELECT';
export const CHART_DATA_AVAILABLE_COMPS_RECOMPUTED =
  'CHART_DATA_AVAILABLE_COMPS_RECOMPUTED';
export const CHART_DATA_FORECAST_CHART_RECOMPUTED =
  'CHART_DATA_FORECAST_CHART_RECOMPUTED';

export const chartDataValuationChartTypeSelect = (chartType) => {
  return {
    type: CHART_DATA_VALUATION_CHART_TYPE_SELECT,
    payload: { chartType }
  };
};

export const chartDataNeighborhoodChartTypeSelect = (chartType) => {
  return {
    type: CHART_DATA_NEIGHBORHOOD_CHART_TYPE_SELECT,
    payload: { chartType }
  };
};

export const chartDataAvailableCompsRecomputed = (chartData) => {
  return {
    type: CHART_DATA_AVAILABLE_COMPS_RECOMPUTED,
    payload: { chartData }
  };
};

export const chartDataNeighborhoodChartDataSelect = (key) => ({
  type: CHART_DATA_NEIGHBORHOOD_CHART_DATA_SELECT,
  payload: { key }
});

export const chartDataForecastChartRecomputed = (payload) => ({
  type: CHART_DATA_FORECAST_CHART_RECOMPUTED,
  payload
});
