import { watchLatest } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { put, select } from 'redux-saga/effects';

import {
  AVM_KEY_COMPS,
  AVM_KEY_COMPS_AVG,
  SUBJECT_TYPE_USER,
  COMP_ID_KEY
} from 'legacy/appstore/constants';
import { getFeatureFlagsData } from 'selectors/feature-flags';
import {
  COMPS_ADJUST_SAVE,
  COMPS_SELECT_TOGGLE,
  COMPS_UNSELECT_ALL
} from 'actions/comps';
import { vrRecompute } from 'actions/edit-report';
import {
  AVMS_COMP_VALUE_RECOMPUTE,
  avmsCompValueRecomputeSuccess
} from 'actions/avms';
import { toastShow } from 'actions/toast';

import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import {
  calcCompAvm,
  getCompsSelectedIds,
  calcSoldCompAvg
} from 'selectors/comps';
import { getIsReportEditable } from 'selectors/report-permissions';

function* recomputeCompAvm(action) {
  try {
    const isEffectiveDate = yield select(getIsEffectiveDateReport);
    // Let the effective date sagas handle these actions for effective date reports
    if (isEffectiveDate) {
      return;
    }
    let recompute = true;
    if (action.type === COMPS_SELECT_TOGGLE) {
      const selectedCompIds = yield select(getCompsSelectedIds);
      const { comp } = action.payload;
      const featureFlags = yield select(getFeatureFlagsData);
      // Show toast if selected comp limit reached
      if (
        selectedCompIds.length === featureFlags['increased-comp-limit'] &&
        selectedCompIds.indexOf(comp[COMP_ID_KEY]) === -1
      ) {
        yield put(
          toastShow(
            `You have already reached the maximum of ${featureFlags['increased-comp-limit']} selected comps`
          )
        );
        // No need to recompute comp avm
        recompute = false;
      }
    }
    if (recompute) {
      // Comp AVM is always based on the user-adjusted property details
      const compBasedAvm = yield select(calcCompAvm(SUBJECT_TYPE_USER));
      const soldCompBasedAvg = yield select(calcSoldCompAvg);
      yield put(
        avmsCompValueRecomputeSuccess({
          [AVM_KEY_COMPS]: compBasedAvm,
          [AVM_KEY_COMPS_AVG]: soldCompBasedAvg
        })
      );

      const isReportEditable = yield select(getIsReportEditable);
      if (isReportEditable) {
        yield put(vrRecompute({}, action.payload.recomputeHints));
      }
    }
  } catch (e) {
    console.error(e);
    report(`Recompute Comp Avm: ${e.message}`, { e, action });
  }
}

export default function registerRecomputeCompAvmSaga() {
  watchLatest(
    [
      AVMS_COMP_VALUE_RECOMPUTE,
      COMPS_ADJUST_SAVE,
      COMPS_SELECT_TOGGLE,
      COMPS_UNSELECT_ALL
    ],
    recomputeCompAvm
  );
}
