export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_HC_VERSION_SUCCESS = 'GET_REPORT_HC_VERSION_SUCCESS';
export const GET_REPORT_HC_VERSION_FAILURE = 'GET_REPORT_HC_VERSION_FAILURE';
export const GET_REPORT_USER_VERSION = 'GET_REPORT_USER_VERSION'; // Primarily called by GET_REPORT
export const GET_REPORT_USER_VERSION_SUCCESS =
  'GET_REPORT_USER_VERSION_SUCCESS';
export const GET_REPORT_USER_VERSION_NOT_FOUND =
  'GET_REPORT_USER_VERSION_NOT_FOUND';
export const GET_REPORT_RENTAL = 'GET_REPORT_RENTAL';
export const GET_REPORT_RENTAL_SUCCESS = 'GET_REPORT_RENTAL_SUCCESS';
export const GET_REPORT_RENTAL_FAILURE = 'GET_REPORT_RENTAL_FAILURE';
export const GET_REPORT_SUBJECT_DETAILS = 'GET_REPORT_SUBJECT_DETAILS'; // Primarily called by GET_REPORT
export const GET_REPORT_SUBJECT_DETAILS_SUCCESS =
  'GET_REPORT_SUBJECT_DETAILS_SUCCESS';
export const GET_REPORT_SUBJECT_DETAILS_FAILURE =
  'GET_REPORT_SUBJECT_DETAILS_FAILURE';
export const GET_REPORT_COMPS = 'GET_REPORT_COMPS';
export const GET_REPORT_FORECAST = 'GET_REPORT_FORECAST';
export const GET_REPORT_FORECAST_SUCCESS = 'GET_REPORT_FORECAST_SUCCESS';
export const GET_REPORT_FORECAST_FAILURE = 'GET_REPORT_FORECAST_FAILURE';
export const GET_REPORT_NEARBY_PROPERTIES = 'GET_REPORT_NEARBY_PROPERTIES';
export const GET_REPORT_SHARED = 'GET_REPORT_SHARED';
export const GET_REPORT_NEARBY_PROPERTIES_SUCCESS =
  'GET_REPORT_NEARBY_PROPERTIES_SUCCESS';
export const GET_REPORT_NEARBY_PROPERTIES_FAILURE =
  'GET_REPORT_NEARBY_PROPERTIES_FAILURE';

export const getReport = (reportParams) => ({
  type: GET_REPORT,
  payload: { reportParams }
});

export const getReportHcVersionSuccess = (
  report,
  isSharedReport,
  isReportOwner,
  subscriptionType
) => ({
  type: GET_REPORT_HC_VERSION_SUCCESS,
  payload: { report, isSharedReport, isReportOwner, subscriptionType }
});

export const getReportHcVersionFailure = (error) => ({
  type: GET_REPORT_HC_VERSION_FAILURE,
  payload: { error }
});

export const getReportUserVersion = () => ({
  type: GET_REPORT_USER_VERSION
});

export const getReportUserVersionSuccess = (report) => ({
  type: GET_REPORT_USER_VERSION_SUCCESS,
  payload: { report }
});

export const getReportUserVersionNotFound = (fromShared) => ({
  type: GET_REPORT_USER_VERSION_NOT_FOUND,
  payload: { fromShared }
});

export const getReportRental = () => ({
  type: GET_REPORT_RENTAL
});

export const getReportRentalSuccess = (report) => ({
  type: GET_REPORT_RENTAL_SUCCESS,
  payload: { report }
});

export const getReportRentalFailure = (message) => ({
  type: GET_REPORT_RENTAL_FAILURE,
  payload: { message }
});

export const getReportShared = (params) => ({
  type: GET_REPORT_SHARED,
  payload: { params }
});

export const getReportSubjectDetails = () => ({
  type: GET_REPORT_SUBJECT_DETAILS
});

export const getReportSubjectDetailsSuccess = (subject, avm) => ({
  type: GET_REPORT_SUBJECT_DETAILS_SUCCESS,
  payload: { subject, avm }
});

export const getReportSubjectDetailsFailure = () => ({
  type: GET_REPORT_SUBJECT_DETAILS_FAILURE
});

export const getReportComps = () => ({
  type: GET_REPORT_COMPS
});

export const getReportForecast = () => {
  return {
    type: GET_REPORT_FORECAST
  };
};

export const getReportForecastSuccess = (hpi) => {
  return {
    type: GET_REPORT_FORECAST_SUCCESS,
    payload: { hpi }
  };
};

export const getReportForecastFailure = () => {
  return {
    type: GET_REPORT_FORECAST_FAILURE
  };
};

export const getReportNearbyProperties = () => {
  return {
    type: GET_REPORT_NEARBY_PROPERTIES
  };
};

export const getReportNearbyPropertiesSuccess = (nearbyProperties) => {
  return {
    type: GET_REPORT_NEARBY_PROPERTIES_SUCCESS,
    payload: { nearbyProperties }
  };
};

export const getReportNearbyPropertiesFailure = () => {
  return {
    type: GET_REPORT_NEARBY_PROPERTIES_FAILURE
  };
};
