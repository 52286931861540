import { RefObject, useEffect } from 'react';

export const useClickOutsideComponent = <T extends HTMLElement>(
  ref: RefObject<T> | null,
  callback: VoidFunction
) => {
  const handleAllClicks = (e: MouseEvent) => {
    if (ref) {
      const target = e.target as Node;
      const clickedOutside = ref.current && !ref.current.contains(target);
      if (clickedOutside) {
        callback();
      }
    }
  };
  // Using document-level listener to identify clicks outside of the component
  useEffect(() => {
    document.addEventListener('mousedown', handleAllClicks);
    return () => {
      document.removeEventListener('mousedown', handleAllClicks);
    };
  }, []);
};
