import classNames from 'classnames';
import { PhotoDate as styles } from 'legacy/css-modules';
import { displayDateCompact } from 'legacy/utils/dates';

export const PhotoDate = ({ date, className }) => {
  return (
    <div className={classNames(styles.PhotoDate, className)}>
      {date ? displayDateCompact(date) : '--'}
    </div>
  );
};
