import React from 'react';

import { Button } from '../../components/Button';

import styles from './ValueSelectorButton.css';

interface Props {
  dataHcName: string;
  children: React.ReactNode;
  onClick: VoidFunction;
  disabled?: boolean;
}

export const ValueSelectorButton = ({
  children,
  dataHcName,
  onClick,
  disabled
}: Props) => {
  return (
    <span className={styles.Button}>
      <Button dataHcName={dataHcName} onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    </span>
  );
};
