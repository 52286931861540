import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CompPropertyCard } from '../CompPropertyCard';
import { useDocumentRole } from '../../hooks';
import {
  CompDocument,
  DocumentRoles,
  FarmDocument,
  FilteredDocument
} from '../../types';
import { default as LazyRender } from 'react-lazyload';
import styles from './CompFarmListCards.css';
import classNames from 'classnames';
import { CompSchema } from '../../types/schemas';
import { useResizeObserver } from 'src/lib/hooks';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { forceCheck } from 'react-lazyload';
import { isLoading } from 'src/lib/utils/general';
import { useCompDocIdsByCompId } from '../../hooks/useCompDocIdsByCompId';
import { useCompFarmListFiltered } from '../../hooks/useCompFarmListFiltered';

interface Props {
  reportId: number;
  dataHcName: string;
  className?: string;
  // Target width in pixels for the cards
  cardWidthTarget?: number;
  // Card padding for width calcs
  cardWidthPadding?: number;
  // Show only selected comps
  selectedCompsOnly?: boolean;
  onMouseEnter?: (hovered: {
    compSchema: CompSchema;
    documentId: string | undefined;
  }) => void;
  onMouseLeave?: VoidFunction;
}
export const CompFarmListCards = ({
  dataHcName,
  reportId,
  className,
  cardWidthTarget = 240,
  cardWidthPadding = 20,
  selectedCompsOnly = false,
  onMouseEnter,
  onMouseLeave
}: Props) => {
  const scrollContainerId = `${dataHcName}-scrollContainer`;
  const {
    data: [farmDocument],
    status: farmStatus
  } = useDocumentRole<FarmDocument>(reportId, DocumentRoles.Farm);
  const {
    data: [filteredDocument],
    status: filteredStatus
  } = useDocumentRole<FilteredDocument>(reportId, DocumentRoles.Filtered);
  const [cardsPerRow, setCardsPerRow] = useState<number | undefined>(undefined);
  const { data: compDocuments } = useDocumentRole<CompDocument>(
    reportId,
    DocumentRoles.Comp
  );
  const compFarmListFiltered = useCompFarmListFiltered(reportId);
  const listRef = useRef<HTMLDivElement>(null);
  const calcCardWidth = (containerWidth: number) => {
    const newCardsPerRow = Math.max(
      1,
      Math.floor((containerWidth - cardWidthPadding) / cardWidthTarget)
    );
    if (newCardsPerRow !== cardsPerRow) {
      setCardsPerRow(newCardsPerRow);
    }
    // Check if lazy-rendered components should be shown after resize
    forceCheck();
  };
  // Update card width when the scroll container is resized
  useResizeObserver(
    { ref: listRef },
    useCallback(
      (entry) => {
        calcCardWidth(entry.contentRect.width);
      },
      [cardsPerRow]
    )
  );
  // Component is now mounted and ready to calculate width
  useEffect(() => {
    if (listRef.current && !cardsPerRow) {
      calcCardWidth(listRef.current.clientWidth);
    }
  }, [filteredDocument, farmDocument, listRef.current]);
  // Create map of selected comps to prevent unnecessary iterations
  const selectCompsByAddressId = useCompDocIdsByCompId(reportId);
  if (
    isLoading(farmStatus) ||
    farmDocument == null ||
    isLoading(filteredStatus) ||
    !filteredDocument
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const compSchemasToRender = selectedCompsOnly
    ? compDocuments.map((compDocument) => compDocument.data)
    : compFarmListFiltered.data;
  return (
    <div
      data-hc-name={`${dataHcName}-main`}
      // react-lazyload has a bug that doesn't support passing the Element
      // MR is open on the project
      id={scrollContainerId}
      ref={listRef}
      className={classNames(styles.CompFarmListCards, className)}
    >
      {cardsPerRow &&
        compSchemasToRender.map((compSchema, i) => {
          const compId = compSchema.compID;
          const documentId = selectCompsByAddressId[compId];
          return (
            <LazyRender
              key={`comp-card-${i}`}
              scrollContainer={`#${scrollContainerId}`}
              height={331}
              style={{
                width: `calc(${100 / cardsPerRow}% - ${cardWidthPadding}px)`
              }}
              // @ts-ignore: className is passed to the element but not documented on the lib's prop types
              className={styles.PropertyCardWrapper}
              unmountIfInvisible
              overflow
              scroll
              resize
            >
              <CompPropertyCard
                reportId={reportId}
                documentId={documentId}
                dataHcName={`comp-property-card-${i}`}
                compSchema={compSchema}
                onMouseEnter={() => {
                  onMouseEnter?.({
                    compSchema,
                    documentId
                  });
                }}
                onMouseLeave={onMouseLeave}
              />
            </LazyRender>
          );
        })}
    </div>
  );
};
