import { CompId } from 'src/lib/report-api/types/schemas';
import {
  PHOTO_PAGE_STATES,
  PhotoPageAction
} from 'src/lib/report-api/providers/PhotosPage/PhotosPage.reducer';

//openPageAction will be same as this except active: true
export const closePageAction: PhotoPageAction = {
  type: PHOTO_PAGE_STATES.OPEN_CLOSE_PAGE,
  payload: { active: false }
};

export const setSubjectPhotoPageAction = (
  hcAddressId: number, // This is mandetory for our PhotosPage component
  reportId: number
) => {
  return {
    type: PHOTO_PAGE_STATES.SET_SUBJECT_PHOTO_PAGE,
    payload: { hcAddressId, reportId }
  };
};

export const setCompPhotoPageAction = (
  compId: CompId,
  reportId: number,
  hcAddressId: number
) => {
  return {
    type: PHOTO_PAGE_STATES.SET_COMP_PHOTO_PAGE,
    payload: { compId, hcAddressId, reportId }
  };
};
