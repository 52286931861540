import { PureComponent } from 'react';
import { Column, Cell } from 'fixed-data-table-2';
import { TextButton } from 'legacy/components/Button';
import { Dialog } from 'legacy/components/Dialog';

import { displayDate } from 'legacy/utils/dates';
import { formattedValue } from 'legacy/utils/archived-reports';

import { ResponsiveFixedDataTable } from 'legacy/components/generic/ResponsiveFixedDataTable';
import Popover from 'legacy/components/generic/Popover';
import TextInputHc from 'legacy/components/generic/TextInputHc';
import Loading from 'legacy/components/generic/Loading';

import { ArchivedReportsModal as theme } from 'legacy/css-modules';

const ACTION_CELL_KEY = 'action';

export default class ArchivedReportsModal extends PureComponent {
  componentDidUpdate(prevProps) {
    if (
      !this.props.areReportsLoaded &&
      !prevProps.isOpen &&
      this.props.isOpen
    ) {
      this.props.loadArchivedReports();
    }
  }
  /**
   * A dropdown for viewing reports for a property
   * @param {array} reports for the property
   * @return {component}
   */
  ActionDropdown = ({ reports, label, onSelect, itemsDataHcName, ...rest }) => (
    <Popover
      fixed
      className={theme.ReportsDropdown}
      closeOnChildClick
      Button={<TextButton label={label} micro {...rest} />}
      popoverAlignment="bottom"
      onMenuSelect={(reportId) =>
        onSelect(reports.find((o) => o.id === reportId))
      }
      menuItems={reports.map((report) => ({
        value: report.id,
        label: displayDate(report.purchaseDate),
        dataHcName: itemsDataHcName
      }))}
    />
  );

  render() {
    const {
      areReportsLoaded,
      isOpen,
      properties,
      onClose,
      tableColumns,
      onSearch,
      searchQuery,
      onViewOrDownloadReport,
      onPreviewReport,
      fullscreen
    } = this.props;
    const ActionDropdown = this.ActionDropdown;
    const columns = [
      ...tableColumns,
      <Column
        key={ACTION_CELL_KEY}
        cellClassName="archived-reports-modal--action-cell"
        cell={(props) => {
          const property = properties[props.rowIndex];
          const activeReports = property.reports.filter((o) => o.isActive);
          const inactiveReports = property.reports.filter((o) => !o.isActive);
          return (
            <Cell {...props}>
              {activeReports.length === 0 && (
                <TextButton
                  label="View New Report"
                  dataHcName="archived-reports-view-new-report-link"
                  onClick={() => {
                    onPreviewReport(property);
                  }}
                />
              )}
              {activeReports.length > 0 && (
                <ActionDropdown
                  label="Active Reports"
                  dataHcName="archived-reports-active-reports-link"
                  reports={activeReports}
                  itemsDataHcName="archived-reports-active-reports-link-selection"
                  onSelect={(report) =>
                    onViewOrDownloadReport(property, report)
                  }
                />
              )}
              {inactiveReports.length > 0 && (
                <ActionDropdown
                  label="Expired Reports"
                  dataHcName="archived-reports-expired-reports-link"
                  reports={inactiveReports}
                  onSelect={(report) =>
                    onViewOrDownloadReport(property, report)
                  }
                />
              )}
            </Cell>
          );
        }}
        width={400}
      />
    ];
    return (
      <Dialog
        type={fullscreen ? 'fullscreen' : 'large'}
        theme={theme}
        active={isOpen}
        title={
          <div
            className={theme.Title}
            data-hc-name="archived-reports-header-text"
          >
            {areReportsLoaded
              ? `Archived Reports (${properties.length} properties)`
              : 'Archived Reports'}
          </div>
        }
        dataHcName="archived-reports-modal"
        className={theme.ArchivedReportsModal}
        onClose={onClose}
        closeButton
      >
        <Loading
          loading={!areReportsLoaded}
          spinnerDataHcName="archived-reports-loading-spinner"
        >
          <TextInputHc
            placeholder="Search reports"
            className={theme.SearchInput}
            input={{
              onChange: onSearch,
              value: searchQuery,
              'data-hc-name': 'archived-reports-filter-field'
            }}
          />
          {areReportsLoaded &&
            (properties.length ? (
              <ResponsiveFixedDataTable
                containerStyle={{ flex: '1 1 500px' }}
                data={properties}
                columns={columns}
                cellValueFormatter={formattedValue}
              />
            ) : (
              <div
                className={theme.NoReportsMessage}
                data-hc-name="archived-reports-no-reports-text"
              >
                No archived reports found
              </div>
            ))}
        </Loading>
      </Dialog>
    );
  }
}
