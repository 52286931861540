import classNames from 'classnames';
import { Button, TextButton } from 'legacy/components/Button';

import PropertyCompareTableContainer from 'legacy/containers/property-compare-table';
import PropertyCardContainer from 'legacy/containers/property-card';

import TransactionHistoryModal from 'legacy/components/TransactionHistoryModal';

import { PropertyCompare as theme } from 'legacy/css-modules';

const PropertyCompare = ({
  addressId,
  compType,
  property,
  showEditControls,
  isSubject,
  showLookupButton,
  editing,
  title,
  rowHeights,
  onRowHeightsUpdated,
  propertyLookupUrl,
  onLookupProperty,
  onAdjust,
  onCancel,
  onSubmit
}) => {
  if (!property) return null;
  return (
    <div
      className={classNames(theme.PropertyCompare, {
        [theme.Subject]: isSubject,
        [theme.Comp]: !isSubject
      })}
    >
      <h3 className={theme.Heading}>{title}</h3>
      <div className={theme.PropertyCard}>
        <PropertyCardContainer
          nonLazy
          addressId={isSubject ? property.addressId : addressId}
          dataHcName={
            isSubject ? 'property-card-subject' : 'property-card-comp'
          }
          isSubject={isSubject}
          bordered={!isSubject}
          compType={compType}
          layout="vertical"
          hideSelectControl={isSubject || editing}
          hideSimilarity={isSubject}
          hidePrice
          hideDate
          noOnClick
        />
      </div>
      <div className={theme.AdjustRow}>
        {showEditControls ? (
          editing ? (
            <div>
              <TextButton
                dataHcName="cancel-button"
                className={classNames(theme.TextButton, theme.TextButtonCancel)}
                onClick={onCancel}
              >
                Cancel
              </TextButton>
              <TextButton
                dataHcName="save-button"
                className={theme.TextButton}
                onClick={onSubmit}
              >
                Save
              </TextButton>
            </div>
          ) : (
            <TextButton
              dataHcName="adjust-button"
              className={theme.TextButton}
              onClick={onAdjust}
            >
              Adjust
            </TextButton>
          )
        ) : null}
      </div>
      <PropertyCompareTableContainer
        addressId={addressId}
        compType={compType}
        property={property}
        showLabels={isSubject}
        editing={editing}
        rowHeights={rowHeights}
        onRowHeightsUpdated={onRowHeightsUpdated}
      />
      <TransactionHistoryModal addressId={addressId} compType={compType} />
      {showLookupButton && (
        <div className={theme.PropertyLookup}>
          <a
            href={propertyLookupUrl}
            target="_blank"
            rel="noreferrer"
            onClick={onLookupProperty}
          >
            <Button dataHcName="lookup-button" primary={false} mini>
              Lookup Property
            </Button>
          </a>
        </div>
      )}
    </div>
  );
};

export default PropertyCompare;
