import { gql } from '@apollo/client';

export const GET_GEOLOCATION = gql`
  query GetListings($hcAddressId: Number!) {
    propertyLookup(id: { hcAddressId: $hcAddressId }) {
      geoLocation {
        latitude
        longitude
        precision
      }
    }
  }
`;
export const GET_LISTINGS = gql`
  query GetListings($hcAddressId: Number!) {
    propertyLookup(id: { hcAddressId: $hcAddressId }) {
      listings {
        entityID
        listingID
        listingOfficeName
        price
        status
        statusDate
        publicRemarks
        isListingRental
        mlsID
        listingStatus {
          contractPrice
          currentPrice
          currentSaleStatus
          currentStatus
          currentStatusDate
          daysOnMarket
          firstAppearanceDate
          listingDate
          listingPrice
          listingPriceRange
          MlsState
          offMarketDate
          onMarketDate
          originalListingPrice
          saleDate
          salePrice
          salePricePerGla
        }
        photos {
          representation {
            height
            httpUrl
            width
          }
        }
        taxSummary {
          amount
          year
        }
      }
    }
  }
`;
