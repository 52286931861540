import Loadable from 'react-loadable';
import PageLoading from 'legacy/components/generic/PageLoading';

const load = (fn) =>
  Loadable({
    loader: fn,
    loading: PageLoading
  });

export const CompMap = load(() =>
  import(/* webpackChunkName: "CompMap" */ 'legacy/containers/maps-comps')
);
export const Login = load(() =>
  import(/* webpackChunkName: "LoginPage" */ 'legacy/pages/LoginPage')
);
export const Search = load(() =>
  import(/* webpackChunkName: "SearchPage" */ 'legacy/containers/search-page')
);
export const JoinOrganization = load(() =>
  import(
    /* webpackChunkName: "JoinOrganization" */ 'legacy/pages/JoinOrganizationPage'
  )
);
export const SelectOrganization = load(() =>
  import(
    /* webpackChunkName: "SelectOrganization" */ 'legacy/containers/select-organization'
  )
);
export const InviteTeam = load(() =>
  import(/* webpackChunkName: "InviteTeam" */ 'legacy/containers/invite-team')
);
export const ForgotPassword = load(() =>
  import(
    /* webpackChunkName: "ForgotPassword" */ 'legacy/containers/forgot-password'
  )
);
export const SignUp = load(() =>
  import(/* webpackChunkName: "SignUp" */ 'legacy/pages/SignUpPage')
);
export const SelectPackage = load(() =>
  import(
    /* webpackChunkName: "SelectPackage" */ 'legacy/pages/SelfServiceCheckoutPage'
  )
);
export const ResetPassword = load(() =>
  import(
    /* webpackChunkName: "ResetPassword" */ 'legacy/containers/reset-password'
  )
);
export const ConfirmUser = load(() =>
  import(
    /* webpackChunkName: "ConfirmUser" */ 'legacy/containers/confirm-user-error'
  )
);
export const CompsSelectionMap = load(() =>
  import(
    /* webpackChunkName: "CompsSelectionMap" */ 'legacy/containers/comps-selection-map'
  )
);
export const CompsPage = load(() =>
  import(/* webpackChunkName: "CompsPage" */ 'legacy/containers/comps-page')
);
export const CompsList = load(() =>
  import(/* webpackChunkName: "CompsList" */ 'legacy/containers/comps-list')
);
export const CompsCompare = load(() =>
  import(
    /* webpackChunkName: "CompsCompare" */ 'legacy/containers/comps-compare-selected'
  )
);
export const PropertyExplorerFullMap = load(() =>
  import(
    /* webpackChunkName: "PropertyExplorerFullMap" */ 'legacy/containers/property-explorer-full-map-page'
  )
);
export const PropertyExplorer = load(() =>
  import(
    /* webpackChunkName: "PropertyExplorer" */ 'legacy/containers/property-explorer-page'
  )
);
