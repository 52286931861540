import { STATUSES } from 'legacy/appstore/constants';

import {
  SIGN_UP_LOADED,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  PROCESS_INVITATION_CODE,
  PROCESS_INVITATION_CODE_SUCCESS,
  PROCESS_INVITATION_CODE_COMPLETE,
  PROCESS_INVITATION_CODE_ERROR,
  CONFIRM_NEW_USER_ERROR,
  CONFIRM_NEW_USER_EXPIRED,
  RESEND_CONFIRM_USER_EMAIL_SUCCESS,
  RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN,
  RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN_SUCCESS,
  RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN_FAILURE
} from 'actions/sign-up';

import { LOGIN_PROMPT_SHOW_SIGNUP } from 'actions/auth';

const defaultState = {
  status: STATUSES.INIT,
  loading: false,
  error: null,
  pendingUser: {},
  token: null,
  resendConfirmEmailStatus: null
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN: {
      return {
        ...state,
        resendConfirmEmailStatus: STATUSES.LOADING
      };
    }

    case RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN_SUCCESS: {
      return {
        ...state,
        resendConfirmEmailStatus: STATUSES.SUCCESS
      };
    }

    case RESEND_CONFIRM_EMAIL_FROM_AUTH_TOKEN_FAILURE: {
      return {
        ...state,
        resendConfirmEmailStatus: STATUSES.ERROR
      };
    }

    case LOGIN_PROMPT_SHOW_SIGNUP:
    case SIGN_UP_LOADED:
      return {
        ...state,
        status: STATUSES.SUCCESS
      };

    case CREATE_USER: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...defaultState
      };
    }
    case CREATE_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    }
    case PROCESS_INVITATION_CODE: {
      return {
        ...state,
        pendingUser: {}
      };
    }
    case PROCESS_INVITATION_CODE_COMPLETE: {
      return {
        ...state,
        status: STATUSES.INIT,
        pendingUser: action.payload.pendingUser
      };
    }
    case PROCESS_INVITATION_CODE_SUCCESS: {
      return {
        ...state,
        status: STATUSES.SUCCESS,
        pendingUser: action.payload.pendingUser
      };
    }

    case CONFIRM_NEW_USER_ERROR:
    case PROCESS_INVITATION_CODE_ERROR: {
      return {
        ...state,
        status: STATUSES.ERROR,
        error: action.payload.error
      };
    }

    case CONFIRM_NEW_USER_EXPIRED: {
      return {
        ...state,
        status: STATUSES.EXPIRED,
        error: action.payload.error,
        token: action.payload.token
      };
    }

    case RESEND_CONFIRM_USER_EMAIL_SUCCESS: {
      return {
        ...state,
        error: 'A new confirmation email has been sent to your inbox.'
      };
    }

    default: {
      return state;
    }
  }
}
