import { select, call, apply, put } from 'redux-saga/effects';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

import { VIEWS } from 'legacy/routes/constants';
import { EVENTS } from 'legacy/appstore/events';

import mixpanelService from 'legacy/services/mixpanel';

import { watchEvery } from 'legacy/utils/saga';

import {
  LOAD_FULL_REPORT,
  LOAD_FULL_REPORT_EXISTING
} from 'actions/full-report';
import { analyticsEvent } from 'actions/analytics';

import { getPropertyResult } from 'selectors/property-search';

export function* loadFullReport(property) {
  yield put(
    analyticsEvent(EVENTS.REPORT_PULL_NEW, {
      address_slug: property.addressSlug
    })
  );
  yield apply(mixpanelService, mixpanelService.incReportsPulled);
  yield put(
    routeChange({
      view: VIEWS.VALUE_REPORT,
      params: {
        streetAddress: property.streetAddress,
        zipcode: property.zipcode,
        city: property.city,
        state: property.state,
        lat: property.lat,
        lon: property.lon
      },
      query: {
        address_id: property.addressId || undefined,
        address_slug: property.addressSlug || undefined,
        unit: property.unit || undefined,
        unit_designator: property.unitDesignator || undefined,
        listing_id: property.listingId || undefined,
        listing_source: property.listingSource || undefined
      },
      options: { inherit: false }
    })
  );
}

function* handleLoadFullReport(action) {
  const previousReportProperty = action.payload.property;
  const listingParams = action.payload.listingParams || {};
  const searchResultProperty = yield select(getPropertyResult);
  const { listingId, listingSource } = listingParams;
  const property = previousReportProperty || searchResultProperty;
  yield call(loadFullReport, { ...property, listingId, listingSource });
}

export default function registerFullReportSaga() {
  watchEvery(
    [LOAD_FULL_REPORT, LOAD_FULL_REPORT_EXISTING],
    handleLoadFullReport
  );
}
