import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { findDOMNode } from 'react-dom';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { Table, Column, ColumnGroup, Cell } from 'fixed-data-table-2';

import PopoverEndgame from 'legacy/components/PopoverEndgame';
import TooltipDefaultTrigger from 'legacy/components/generic/TooltipDefaultTrigger';

import { ResponsiveFixedDataTable as theme } from 'legacy/css-modules';

const initialPixels = 1;
export const ROW_HEIGHT = 60;
export const HEADER_HEIGHT = 30;

/*
 * Original source: https://github.com/vaiRk/responsive-fixed-data-table/blob/master/src/responsive-fixed-data-table.js
 *
 * We need to copy/modify this component to support `fixed-data-table-2`, as
 * Facebook's `fixed-data-table` has been deprecated
 */
const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc'
};

const reverseSortDirection = (dir) =>
  dir === SORT_TYPES.ASC ? SORT_TYPES.DESC : SORT_TYPES.ASC;

const SortHeaderCell = ({
  column,
  sortOrder,
  children,
  onSortChange,
  columnKey,
  popoverReposition,
  ...props
}) => {
  return (
    <Cell {...props}>
      <div className={theme.SortHeaderCell}>
        <div
          onClick={(e) => {
            e.preventDefault();
            onSortChange(columnKey, reverseSortDirection(sortOrder));
          }}
          data-hc-name="data-table-header"
          className={theme.SortHeaderCellLabel}
        >
          {children}
        </div>
        <div className={theme.SortHeaderCellArrow}>
          {sortOrder ? (sortOrder === SORT_TYPES.DESC ? '↓' : '↑') : ''}
        </div>
        {column.info && (
          <div className={theme.HeaderCellInfoIcon}>
            <PopoverEndgame
              repositionTrigger={popoverReposition}
              trigger={<TooltipDefaultTrigger className={theme.InfoTrigger} />}
              content={column.info}
            />
          </div>
        )}
      </div>
    </Cell>
  );
};

/* Returns either the standard column or a column group when seeking to
 * render a fixed first row (the first row is actually a column group)
 */
const GroupedColumn = ({
  key,
  fixed,
  TableColumn,
  HeaderCell,
  FixedFirstRowCell
}) => {
  if (FixedFirstRowCell) {
    const TableColumnWithHeaderCell = React.cloneElement(TableColumn, {
      key,
      header: FixedFirstRowCell
    });
    return (
      <ColumnGroup key={key} columnKey={key} header={HeaderCell} fixed={fixed}>
        {TableColumnWithHeaderCell}
      </ColumnGroup>
    );
  } else {
    return React.cloneElement(TableColumn, {
      key,
      header: HeaderCell
    });
  }
};

export class ResponsiveFixedDataTable extends Component {
  static propTypes = {
    containerStyle: PropTypes.object,
    refreshRate: PropTypes.number
  };

  static defaultProps = {
    containerStyle: {},
    refreshRate: 250, // ms
    touchScrollEnabled: true
  };

  state = {
    popoverReposition: 0,
    gridWidth: initialPixels,
    gridHeight: initialPixels
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentDidMount() {
    this.__isMounted = true;
    this._setDimensionsOnState();
    window.addEventListener(
      'resize',
      this._setDimensionsOnStateDebounced,
      false
    );
    window.matchMedia('print').addListener(this._setDimensionsOnState);
  }

  componentWillMount() {
    const { refreshRate } = this.props;
    this._setDimensionsOnStateDebounced = debounce(
      this._setDimensionsOnState,
      refreshRate
    );
  }

  componentDidUpdate() {
    this._setDimensionsOnStateDebounced();
  }

  componentWillUnmount() {
    this.__isMounted = false;
    window.removeEventListener('resize', this._setDimensionsOnStateDebounced);
    window.matchMedia('print').removeListener(this._setDimensionsOnState);
  }

  // Use matchMedia listener to trigger dimension setting before print
  setDimensionsBeforePrint = (mql) => {
    if (mql.matches) {
      this._setDimensionsOnState();
    }
  };

  handleScrollEnd = (popoverReposition) => {
    this.setState({
      popoverReposition: this.state.popoverReposition + 1
    });
  };

  _setDimensionsOnState = () => {
    if (this.__isMounted) {
      const { offsetWidth, offsetHeight } = findDOMNode(this);
      this.setState({
        gridWidth: offsetWidth || initialPixels,
        gridHeight: offsetHeight || initialPixels
      });
    }
  };

  _getStyle() {
    return {
      width: '100%',
      height: '100%',
      ...this.props.containerStyle
    };
  }

  render() {
    const { gridWidth, gridHeight, popoverReposition } = this.state;
    const {
      data,
      columns,
      sortAttr,
      sortOrder,
      handleSortChange,
      handleCellClick,
      handleCellHover,
      cellValueFormatter,
      cellValueFormatterData,
      fixedFirstRowData,
      dataHcName,
      ...props
    } = this.props;
    return (
      <div style={this._getStyle()} data-hc-name={dataHcName}>
        <Table
          ref="table"
          width={gridWidth}
          height={gridHeight}
          rowHeight={ROW_HEIGHT}
          groupHeaderHeight={HEADER_HEIGHT}
          headerHeight={fixedFirstRowData ? ROW_HEIGHT : HEADER_HEIGHT}
          rowsCount={data.length}
          onScrollEnd={this.handleScrollEnd}
          {...props}
        >
          {columns.map((column) => {
            const HeaderCell = column.sortable ? (
              <SortHeaderCell
                popoverReposition={popoverReposition}
                onSortChange={handleSortChange}
                title={column.title}
                align={column.align || 'left'}
                width={column.width}
                column={column}
                sortOrder={sortAttr === column.key && sortOrder}
              >
                {column.label}
              </SortHeaderCell>
            ) : (
              <Cell
                className={!column.clickable && 'no-click'}
                title={column.title}
                width={column.width}
                data-hc-name="data-table-header"
              >
                {column.label}
              </Cell>
            );

            const TableColumn = column.$$typeof ? (
              column
            ) : (
              <Column
                data-hc-name="FINDME"
                columnKey={column.key}
                flexGrow={1}
                fixed={column.fixed}
                align={column.align || 'left'}
                cellClassName={classNames(
                  {
                    'fixed-data-table--with-fixed-first-row--cell':
                      !!fixedFirstRowData
                  },
                  column.cellClassNames
                )}
                cell={
                  column.cell
                    ? column.cell
                    : (props) => {
                        const propertyData = data[props.rowIndex];
                        return (
                          <Cell
                            onClick={() => {
                              handleCellClick && handleCellClick(propertyData);
                            }}
                            onMouseEnter={() => {
                              handleCellHover && handleCellHover(propertyData);
                            }}
                            onMouseLeave={() => {
                              handleCellHover && handleCellHover();
                            }}
                            data-hc-name="data-table-cell"
                            align={column.align || 'left'}
                            {...props}
                          >
                            {cellValueFormatter
                              ? cellValueFormatter(
                                  propertyData,
                                  column.key,
                                  cellValueFormatterData
                                )
                              : propertyData[column.key]}
                          </Cell>
                        );
                      }
                }
                width={column.width}
              />
            );

            const FixedFirstRowCell =
              fixedFirstRowData &&
              (() => (
                <Cell align={column.align || 'left'} className="no-click">
                  {cellValueFormatter
                    ? cellValueFormatter(fixedFirstRowData, column.key)
                    : fixedFirstRowData[column.key]}
                </Cell>
              ));
            return GroupedColumn({
              key: column.key,
              fixed: column.fixed,
              TableColumn,
              HeaderCell,
              FixedFirstRowCell
            });
          })}
        </Table>
      </div>
    );
  }
}

export default ResponsiveFixedDataTable;
