import uniqBy from 'lodash/uniqBy';

import {
  RECENT_REPORTS_FETCH_SUCCESS,
  RECENT_REPORTS_START_REQUEST
} from 'actions/recent-reports';
import { STATUSES } from 'legacy/appstore/constants';

import { GET_REPORT_HC_VERSION_SUCCESS } from 'actions/get-report';

const INITIAL_STATE = {
  status: STATUSES.INIT,
  reports: []
};

// Create a consistent object from various API response objects
const recentReportModel = ({
  id,
  addressSlug,
  streetAddress,
  unit,
  zipcode,
  city,
  state,
  lat,
  lon,
  isActive,
  listingId,
  listingSource
}) => ({
  streetAddress,
  isActive,
  addressSlug,
  id,
  unit,
  zipcode,
  city,
  state,
  lat,
  lon,
  listingId,
  listingSource
});

const marshallRawReports = (rawReports) =>
  rawReports.map((report) => recentReportModel(report));

export default function recentReportsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RECENT_REPORTS_START_REQUEST:
      return {
        ...state,
        status: STATUSES.LOADING
      };

    case RECENT_REPORTS_FETCH_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        // Fetching reports from API should always overwrite existing reports
        reports: [...marshallRawReports(action.payload.lookups)]
      };
    // Add current report to `reports` list
    case GET_REPORT_HC_VERSION_SUCCESS:
      // Don't add shared reports to reports list
      if (action.payload.isSharedReport) {
        return state;
      }
      // We don't want to add the propertyLookup unless it actually exists
      // (for a shared report link, propertyLookup is returned as object without id)
      if (action.payload.report.propertyLookup.id) {
        const reportData = {
          ...action.payload.report.propertyLookup,
          isActive: true
        };
        return {
          ...state,
          reports: uniqBy(
            [recentReportModel(reportData), ...state.reports],
            'id'
          )
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}
