import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDynamicRefs from 'use-dynamic-refs';
import classNames from 'classnames';
import { STATUSES } from 'legacy/appstore/constants';
import { fullAddressStringForProperty } from 'legacy/utils/property-details';
import { overflowScrollIntoView } from 'legacy/utils/dom';

import {
  clientIdSearchSelect,
  clientIdSearchSubmit,
  clientIdSearchInputChange
} from 'actions/clientIdSearch';
import { useClickOutsideComponent } from 'legacy/hocs/useClickOutsideComponent';
import {
  selClientIdSearchResults,
  selClientIdSearchStatus,
  selClientIdSearchSubmit
} from 'selectors/clientIdSearch';

import styles from '../css-modules/ClientIdSearch.css-module.css';

export const ClientIdSearch = ({ onBlur, onFocus, onSelect }) => {
  const dispatch = useDispatch();
  const [activeRow, setActiveRow] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const hits = useSelector(selClientIdSearchResults);
  const status = useSelector(selClientIdSearchStatus);
  const value = useSelector(selClientIdSearchSubmit);
  const ref = useRef();
  const refHitsContainer = useRef();
  const [getRef, setRef] = useDynamicRefs();

  const handleChange = (e) => {
    e.target.value.length > 2
      ? dispatch(clientIdSearchSubmit(e.target.value))
      : dispatch(clientIdSearchInputChange(e.target.value));
    setIsFocused(true);
    setActiveRow(null);
  };

  const handleSelect = (address) => {
    if (onSelect) {
      onSelect();
    }
    setIsFocused(null);
    setActiveRow(null);
    dispatch(clientIdSearchSelect(address));
  };

  const handleKeyUp = (e) => {
    // Handles Keyboard Navigation
    if (e.key === 'Enter') {
      if (activeRow !== null && hits.length) {
        handleSelect(hits[activeRow[0]].addresses[activeRow[1]]);
      } else {
        dispatch(clientIdSearchSubmit(value));
      }
    } else if (e.key === 'ArrowUp') {
      if (hits.length) {
        let newActiveRow = null;
        if (!activeRow || (activeRow[0] === 0 && activeRow[1] === 0)) {
          // Start over at the end of the list
          const lastHitIdx = hits.length - 1;
          const lastHit = hits[lastHitIdx];
          newActiveRow = [lastHitIdx, lastHit.addresses.length - 1];
        } else {
          newActiveRow =
            activeRow[1] === 0
              ? // Last address of previous hit
                [activeRow[0] - 1, hits[activeRow[0] - 1].addresses.length - 1]
              : // Same hit, previous address
                [activeRow[0], activeRow[1] - 1];
        }
        const refActive = getRef(`${newActiveRow[0]}-${newActiveRow[1]}`);
        overflowScrollIntoView(refActive, refHitsContainer);
        setActiveRow(newActiveRow);
      }
    } else if (e.key === 'ArrowDown') {
      if (hits.length) {
        let newActiveRow = null;
        const lastHitIdx = hits.length - 1;
        const lastHit = hits[lastHitIdx];
        if (
          !activeRow ||
          (activeRow[0] === hits.length - 1 &&
            activeRow[1] === lastHit.addresses.length - 1)
        ) {
          // Start over at the end of the list
          newActiveRow = [0, 0];
        } else {
          // If last address for current hit
          newActiveRow =
            activeRow[1] === hits[activeRow[0]].addresses.length - 1
              ? // Go to next hit
                [activeRow[0] + 1, 0]
              : // Go to next address
                [activeRow[0], activeRow[1] + 1];
        }
        const refActive = getRef(`${newActiveRow[0]}-${newActiveRow[1]}`);
        overflowScrollIntoView(refActive, refHitsContainer);
        setActiveRow(newActiveRow);
      }
    }
  };

  useClickOutsideComponent(ref, () => {
    setIsFocused(false);
  });

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
    setIsFocused(true);
  };

  return (
    <div
      ref={ref}
      className={styles.ClientIdSearch}
      data-hc-name="client-id-search"
    >
      <input
        placeholder="Search by Client File ID"
        value={value}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        data-hc-name="client-id-search-input"
        className={styles.Input}
      />
      {isFocused && hits.length > 0 && (
        <div
          className={styles.Hits}
          data-hc-name="client-id-search-hits"
          ref={refHitsContainer}
        >
          {hits.map((hit, i) => {
            const key = `client-id-search-hit-${i}`;
            return (
              <div
                className={classNames(styles.Hit)}
                key={key}
                data-hc-name={key}
              >
                <div
                  data-hc-name={`client-id-search-hit-label-${i}`}
                  className={styles.HitLabel}
                >
                  {hit.clientID}
                </div>
                {hit.addresses.map((address, j) => {
                  return (
                    <div
                      key={`client-id-search-hit-address-${i}-${j}`}
                      data-hc-name={`client-id-search-hit-address-${i}-${j}`}
                      onClick={() => handleSelect(address)}
                      className={classNames(styles.Address, {
                        [styles.active]:
                          activeRow && activeRow[0] === i && activeRow[1] === j
                      })}
                      onMouseEnter={() => {
                        setActiveRow([i, j]);
                      }}
                      ref={setRef(`${i}-${j}`)}
                    >
                      {fullAddressStringForProperty(address)}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      {isFocused &&
        hits.length === 0 &&
        value.length > 0 &&
        status === STATUSES.SUCCESS && (
          <div
            className={classNames(styles.noResults, styles.Hits)}
            data-hc-name="client-id-search-no-results"
          >
            No results found
          </div>
        )}
    </div>
  );
};
