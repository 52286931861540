import PropTypes from 'prop-types';
import { Button, TextButton, FlatButton } from 'legacy/components/Button';
import classNames from 'classnames';

import ToggleField from 'legacy/components/generic/ToggleField';
import MultiEmailTextField from 'legacy/components/generic/MultiEmailTextField';
import TextAreaBoxField from 'legacy/components/generic/TextAreaBoxField';
import Loading from 'legacy/components/generic/Loading';
import FontIcon from 'legacy/components/generic/FontIcon';

import { ShareReportForm as theme } from 'legacy/css-modules';

const ShareReportForm = ({
  link,
  linkConfigOptions,
  shareByEmail,
  copyUrlToClipboard,
  allowChangePermissions,
  onSubmit,
  onClose,
  isUpdating,
  array,
  dirty,
  invalid,
  formValues,
  uniqueLinkDoesNotExist,
  onGenerateLink,
  canSendEmailBeforeLinkGenerated,
  onConfigureLink,
  mqMediumAndUp,
  isEffectiveDate,
  ...rest
}) => {
  const submitDisabled =
    isUpdating ||
    !dirty ||
    invalid ||
    !canSendEmailBeforeLinkGenerated ||
    (formValues.comment && !formValues.toEmails) ||
    (formValues.comment && formValues.toEmails && !formValues.toEmails.length);
  return (
    <form onSubmit={onSubmit}>
      <section className={theme['share-report--section']}>
        <div className={theme['share-report--row']}>
          {allowChangePermissions && (
            <div
              className={classNames(
                theme['share-report--link-config-row'],
                theme.PermissionRow
              )}
            >
              <span className={theme.PermissionToggleLabel}>
                Anyone with this link can
              </span>
              <ToggleField
                options={linkConfigOptions}
                name="linkConfig"
                dataHcName="share-permission-toggle"
                className={theme.PermissionsToggle}
                onChange={onConfigureLink}
              />
            </div>
          )}
          {uniqueLinkDoesNotExist && (
            <div className={theme.GenerateButtonRow}>
              <FlatButton
                onClick={onGenerateLink}
                dataHcName="share-generate-link-button"
                className={theme['share-report--generate-button']}
              >
                Generate shareable link
              </FlatButton>
            </div>
          )}
          {isEffectiveDate && (
            <p>
              Effective date reports can be shared with people in your org. Copy
              the link below to share this report.
            </p>
          )}
          {!uniqueLinkDoesNotExist && (
            <div className={theme['share-report--link-config']}>
              <div className={theme['share-report--link-config-row']}>
                <div className={theme['share-report--url-cell']}>
                  <input
                    type="text"
                    id="report-link"
                    className={theme['share-report--link']}
                    value={link || ''}
                    // Select text on click
                    onClick={(e) => {
                      e.target.setSelectionRange(0, e.target.value.length);
                    }}
                    // Repress React warning from absence of readOnly attr, needed to
                    // allow mobile users to choose "Select All" if desired
                    onChange={(e) => {}}
                  />
                </div>
                {mqMediumAndUp && (
                  <div className={theme['share-report--copy-cell']}>
                    <Loading
                      loading={isUpdating}
                      size="xsmall"
                      spinnerDataHcName="share-generate-link-spinner"
                    >
                      <div
                        dataHcName="share-copy-link-button"
                        className={theme.copyLinkButton}
                        onClick={(e) => copyUrlToClipboard(e, formValues)}
                      >
                        <div>
                          <FontIcon
                            icon="link"
                            className={theme.copyLinkIcon}
                          />
                          <span className={theme.copyLinkText}>Copy Link</span>
                        </div>
                      </div>
                    </Loading>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      {isEffectiveDate ? null : (
        <>
          <section className={theme['share-report--section']}>
            <div className={theme['share-report--section-title']}>People</div>
            <div className={theme['share-report--row']}>
              <MultiEmailTextField
                name="toEmails"
                label="Enter comma-separated email addresses"
              />
            </div>
            {shareByEmail && (
              <div className={theme['share-report--row']}>
                <TextAreaBoxField
                  name="comment"
                  label="Add Comment"
                  maxLength={2000}
                  value={formValues['comment']}
                />
                <div className={theme['share-report--sub-text']}>
                  Comments will be included in your email
                </div>
              </div>
            )}
          </section>

          <div className={theme['share-report--buttons']}>
            <FlatButton
              dataHcName="share-modal-cancel-button"
              className={theme.cancelButton}
              onClick={onClose}
              disabled={isUpdating}
              secondary
            >
              Cancel
            </FlatButton>
            <Button
              onClick={() => {
                !submitDisabled && onSubmit();
              }}
              disabled={submitDisabled}
              dataHcName="share-modal-send-button"
              primary
            >
              Send
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

ShareReportForm.defaultProps = {
  allowChangePermissions: false
};

ShareReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  allowChangePermissions: PropTypes.bool
};

export default ShareReportForm;
