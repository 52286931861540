// @flow
import React, { ChangeEvent, KeyboardEvent, ReactNode, useState } from 'react';
import classNames from 'classnames';
import styles from './Input.css';
import { AlertIcon, CloseIcon } from 'src/lib/inline-svgs';

type ValueType = number | string;

type LabelProps = {
  dataHcName: string;
  floating?: boolean;
  label: string;
  required?: boolean;
  className: string;
  children?: ReactNode;
};

const isValuePresent = (value: ValueType) => {
  return (
    value !== null &&
    value !== undefined &&
    value !== '' &&
    !(typeof value === 'number' && Number.isNaN(value))
  );
};

const Label = ({
  dataHcName,
  className,
  label,
  required,
  children
}: LabelProps) => {
  return (
    <label data-hc-name={`${dataHcName}-label`} className={className}>
      {children}
      {label}
      {required ? (
        <span data-hc-name={`${dataHcName}-label`} className={styles.required}>
          {' '}
          *{' '}
        </span>
      ) : null}
    </label>
  );
};

interface Props {
  children?: ReactNode;
  dataHcName: string;
  value: string;
  className?: string;
  disabled?: boolean;
  error?: string;
  floating?: boolean;
  hint?: string;
  label?: string;
  maxLength?: number;
  placeholder?: string;
  required?: boolean;
  role?: string;
  mini?: boolean;
  type?: string;
  onBlur?: () => void;
  onChange: (v: string) => void;
  onKeyUp?: (e: KeyboardEvent) => void;
  onFocus?: () => void;
  canClear?: boolean;
  noBorder?: boolean;
  noPadding?: boolean;
}
export const Input = ({
  dataHcName,
  className,
  value,
  label,
  disabled,
  error,
  type,
  mini,
  canClear,
  placeholder,
  role,
  required,
  maxLength,
  hint,
  onBlur,
  children,
  floating,
  noBorder,
  noPadding,
  onChange,
  onKeyUp,
  onFocus
}: Props) => {
  const [initialValue] = useState(value);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const value = event.target.value || '';
      onChange(value);
    }
  };

  const handleClear = () => {
    onChange('');
  };
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(
        styles.Input,
        {
          [styles.disabled || '']: disabled,
          [styles.errored || '']: error,
          [styles.hidden || '']: type === 'hidden',
          [styles.noPadding || '']: noPadding
        },
        className
      )}
    >
      <div
        className={classNames(styles.InputBorder, {
          [styles.noBorder || '']: noBorder
        })}
      >
        <input
          data-hc-name={`${dataHcName}-input`}
          className={classNames(styles.InputElement, {
            [styles.filled || '']: isValuePresent(value),
            [styles.initialValue || '']: value && value === initialValue,
            [styles.mini || '']: mini,
            [styles.visiblePlaceholder || '']: !label && placeholder
          })}
          onChange={handleChange}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          role={role}
          disabled={disabled}
          required={required}
          type={type}
          maxLength={maxLength}
          value={value}
        />
        {canClear && value && !disabled && (
          <CloseIcon
            className={styles.ClearButton}
            onClick={handleClear}
            dataHcName={`${dataHcName}-close-button`}
          />
        )}
        {error && !canClear ? (
          <AlertIcon
            dataHcName={`${dataHcName}-error-icon`}
            className={styles.AlertIcon}
          />
        ) : null}
        {children}
      </div>
      {label ? (
        <>
          <Label
            dataHcName={dataHcName}
            className={classNames(styles.Label, {
              [styles.fixed || '']: !floating
            })}
            floating={floating}
            label={label}
            required={required}
          />
          <Label
            dataHcName={`${dataHcName}-label-animation-mask`}
            className={classNames(styles.LabelMaskOuter, {
              [styles.fixed || '']: !floating
            })}
            floating={floating}
            label={label}
            required={required}
          >
            <div className={styles.LabelMask} />
          </Label>
        </>
      ) : null}
      {hint && !error ? (
        <span data-hc-name={`${dataHcName}-hint`} className={styles.hint}>
          {hint}
        </span>
      ) : null}
      {error ? (
        <span data-hc-name={`${dataHcName}-error`} className={styles.error}>
          {error}
        </span>
      ) : null}
      {maxLength ? (
        <span className={styles.counter} data-hc-name={`${dataHcName}-counter`}>
          {`${maxLength && value ? String(value).length : 0}/${maxLength}`}
        </span>
      ) : null}
    </div>
  );
};
