import { default as axios } from 'axios';
import {
  InactiveColumnsSnakeCase,
  Personalization,
  Preferences,
  PreferencesFromApi,
  PreferencesKeys
} from './types';

//TODO Create a Typescript typed version of HC_CONSTANTS
//@ts-ignore
import HC_CONSTANTS from 'HC_CONSTANTS';
import { CompFields } from '../report-api/types/CompFields';

// Temporary util to fix comp field casing
// util the v2 endpoint is setup
const _isSnakeCase = (
  preferences: PreferencesFromApi | Preferences
): preferences is PreferencesFromApi => {
  const inactive = preferences?.[PreferencesKeys.CompTableColumns]?.inactive as
    | InactiveColumnsSnakeCase
    | undefined;
  if (
    inactive?.gross_living_area_sqft ||
    inactive?.site_area_sqft ||
    inactive?.property_type ||
    inactive?.year_built ||
    inactive?.property_status ||
    inactive?.subdivision_name ||
    inactive?.sales_date ||
    inactive?.sales_price ||
    inactive?.price_per_sqft ||
    inactive?.last_list_date ||
    inactive?.last_list_price ||
    inactive?.price_per_sqft_non_disclosure ||
    inactive?.active_days_on_market ||
    inactive?.cumulative_days_on_market ||
    inactive?.current_value ||
    inactive?.garage_num_cars ||
    inactive?.is_distressed
  ) {
    return true;
  } else {
    return false;
  }
};
const _transformPreferencesKeys = (
  preferences: PreferencesFromApi
): Preferences => {
  const compTablePreferences = preferences[PreferencesKeys.CompTableColumns];
  if (_isSnakeCase(preferences) && compTablePreferences) {
    const transformed: Preferences[PreferencesKeys.CompTableColumns] = {
      ...compTablePreferences,
      inactive: {
        [CompFields.address]: compTablePreferences.inactive.address,
        [CompFields.similarity]: compTablePreferences.inactive.similarity,
        // [CompFields.distanceMiles]: compTablePreferences.inactive.distance_miles,
        [CompFields.bedrooms]: compTablePreferences.inactive.bedrooms,
        [CompFields.bathrooms]: compTablePreferences.inactive.bathrooms,
        [CompFields.grossLivingAreaSqft]:
          compTablePreferences.inactive.gross_living_area_sqft,
        [CompFields.siteAreaSqft]: compTablePreferences.inactive.site_area_sqft,
        [CompFields.propertyType]: compTablePreferences.inactive.property_type,
        [CompFields.yearBuilt]: compTablePreferences.inactive.year_built,
        [CompFields.propertyStatus]:
          compTablePreferences.inactive.property_status,
        // [CompFields.propertyStatusRental]: compTablePreferences.inactive.propertyStatusRental,
        [CompFields.subdivisionName]:
          compTablePreferences.inactive.subdivision_name,
        [CompFields.salesDate]: compTablePreferences.inactive.sales_date,
        [CompFields.salesPrice]: compTablePreferences.inactive.sales_price,
        [CompFields.pricePerSqft]: compTablePreferences.inactive.price_per_sqft,
        // [CompFields.leasedDate]: compTablePreferences.inactive.leasedDate,
        // [CompFields.leasedPrice]: compTablePreferences.inactive.leasedPrice,
        [CompFields.lastListDate]: compTablePreferences.inactive.last_list_date,
        [CompFields.lastListPrice]:
          compTablePreferences.inactive.last_list_price,
        // [CompFields.lastListDateRental]: compTablePreferences.inactive.lastListDateRental,
        // [CompFields.lastListPriceRental]: compTablePreferences.inactive.lastListPriceRental,
        [CompFields.pricePerSqftNonDisclosure]:
          compTablePreferences.inactive.price_per_sqft_non_disclosure,
        [CompFields.activeDaysOnMarket]:
          compTablePreferences.inactive.active_days_on_market,
        [CompFields.cumulativeDaysOnMarket]:
          compTablePreferences.inactive.cumulative_days_on_market,
        [CompFields.currentValue]: compTablePreferences.inactive.current_value,
        // [CompFields.rentalAvm]: compTablePreferences.inactive.rentalAvm,
        // [CompFields.pricePerSqftRentalLeased]: compTablePreferences.inactive.pricePerSqftRentalLeased,
        // [CompFields.pricePerSqftRentalListed]: compTablePreferences.inactive.pricePerSqftRentalListed,
        [CompFields.pool]: compTablePreferences.inactive.pool,
        [CompFields.garageNumCars]:
          compTablePreferences.inactive.garage_num_cars,
        [CompFields.stories]: compTablePreferences.inactive.stories,
        [CompFields.basement]: compTablePreferences.inactive.basement,
        [CompFields.isDistressed]: compTablePreferences.inactive.is_distressed,
        [CompFields.flips]: compTablePreferences.inactive.flips
      }
    };
    preferences[PreferencesKeys.CompTableColumns] = transformed;
    return preferences as Preferences;
  }
  return preferences;
};
// End temporary util
async function fetchPersonalization(token: string) {
  const response = await axios.get<Personalization>(
    `${HC_CONSTANTS.HUELL_PROXY_URL}/personalization/`,
    {
      headers: { 'HC-Auth-Token': token }
    }
  );
  return response;
}

async function fetchPreferences(token: string) {
  const response = await axios.get<Preferences>(
    `${HC_CONSTANTS.HUELL_PROXY_URL}/preferences/`,
    {
      headers: { 'HC-Auth-Token': token }
    }
  );
  return {
    ...response,
    // Transforming keys for now since they were saved as snake_case
    // There are tickets created to fix this
    data: _transformPreferencesKeys(response.data)
  };
}

async function patchPreferences(token: string, preferences: Preferences) {
  const response = await axios.post<Preferences>(
    `${HC_CONSTANTS.HUELL_PROXY_URL}/preferences/`,
    preferences,
    {
      headers: { 'HC-Auth-Token': token }
    }
  );
  return response;
}

export const Huell = {
  fetchPersonalization,
  fetchPreferences,
  patchPreferences
};
