import React, { CSSProperties } from 'react';
import { CompDocument, DocumentRoles } from 'src/lib/report-api/types/Document';
import { useDocument } from 'src/lib/report-api/hooks';
import { CompSchema } from 'src/lib/report-api/types/schemas';
import { PhotoCarousel } from 'src/lib/property-lookup';
import { PropertyCard } from 'src/lib/components/PropertyCard';
import { CompPropertyCardContent } from '.';
import { StreetViewLauncher } from 'src/lib/street-view/features/StreetViewLauncher';
import styles from './CompPropertyCard.css';
import { useCompCompareOverlay } from '../../hooks/useCompCompareOverlay';
import { usePhotosPage } from 'src/lib/report-api/hooks/usePhotosPage';
import { setCompPhotoPageAction } from 'src/lib/report-api/providers/PhotosPage/photosPage.actions';

interface PropsCompPropertyCardComponent {
  dataHcName: string;
  reportId: number;
  compSchema: CompSchema;
  documentId?: string;
  hcAddressId: number;
  className?: string;
  selectButton?: boolean;
  style?: CSSProperties;
  onClick: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}
const CompPropertyCardComponent = ({
  dataHcName,
  compSchema,
  reportId,
  documentId,
  hcAddressId,
  className,
  style,
  selectButton = true,
  onClick,
  onMouseEnter,
  onMouseLeave
}: PropsCompPropertyCardComponent) => {
  const { dispatch } = usePhotosPage();
  const localCompId = compSchema.compID;
  return (
    <PropertyCard
      dataHcName={dataHcName}
      className={className}
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      photos={
        <>
          <PhotoCarousel
            hcAddressId={hcAddressId}
            dataHcName={`thumbnail-carousel-${hcAddressId}`}
            onClick={(e) => {
              e.stopPropagation();
              //NOTE: When the use clicks the back button the modal closes but we stay on the current page
              dispatch(
                setCompPhotoPageAction(
                  localCompId,
                  reportId,
                  compSchema.address.hcAddressId
                )
              );
            }}
          />
          <StreetViewLauncher
            address={compSchema.address}
            geoLocation={compSchema.geoLocation}
            dataHcName={`${dataHcName}-street-view-launcher`}
            theme={{
              StreetViewLauncherButton: styles.StreetViewLauncherButton
            }}
          />
        </>
      }
      content={
        <CompPropertyCardContent
          dataHcName={`comp-card-${documentId}-content`}
          reportId={reportId}
          documentId={documentId}
          compSchema={compSchema}
          selectButton={selectButton}
        />
      }
    />
  );
};

interface PropsCompPropertyCardSelected {
  reportId: number;
  documentId: string;
  className?: string;
  selectButton?: boolean;
  onClick: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}
export const CompPropertyCardSelected = ({
  reportId,
  documentId,
  className,
  selectButton = true,
  onClick,
  onMouseEnter,
  onMouseLeave
}: PropsCompPropertyCardSelected) => {
  const { data: compDocument } = useDocument<CompDocument>(
    reportId,
    documentId
  );
  if (compDocument?.role !== DocumentRoles.Comp) {
    return <div>Loading...</div>;
  }
  return (
    <CompPropertyCardComponent
      dataHcName={`comp-card-selected-${documentId}`}
      className={className}
      reportId={reportId}
      documentId={documentId}
      hcAddressId={compDocument.hcAddressId}
      compSchema={compDocument.data}
      selectButton={selectButton}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

interface PropsCompPropertyCardUnSelected {
  reportId: number;
  compSchema: CompSchema;
  hcAddressId: number;
  className?: string;
  selectButton?: boolean;
  onClick: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}
export const CompPropertyUnselected = ({
  reportId,
  hcAddressId,
  compSchema,
  className,
  selectButton = true,
  onClick,
  onMouseEnter,
  onMouseLeave
}: PropsCompPropertyCardUnSelected) => {
  return (
    <CompPropertyCardComponent
      dataHcName={`comp-card-unselected-${hcAddressId}`}
      className={className}
      reportId={reportId}
      hcAddressId={hcAddressId}
      compSchema={compSchema}
      selectButton={selectButton}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

interface PropsCompPropertyCard {
  dataHcName: string;
  reportId: number;
  // Pass documentId for selected comp
  documentId?: string;
  // Pass comp schema to render comp from farm list
  compSchema?: CompSchema;
  className?: string;
  selectButton?: boolean;
  clickToCompare?: boolean;
  onClick?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}

// Entrypoint component that determines how data should be loaded.
// Selected Comps have individual documents, Unselected are schemas on the farm list
export const CompPropertyCard = ({
  reportId,
  documentId,
  compSchema,
  selectButton = true,
  clickToCompare = true,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave
}: PropsCompPropertyCard) => {
  const { compCompareOpen } = useCompCompareOverlay();
  const handleClick = () => {
    if (clickToCompare) {
      compCompareOpen({ documentId, compSchema });
    }
    onClick?.();
  };
  if (documentId) {
    return (
      <CompPropertyCardSelected
        className={className}
        reportId={reportId}
        documentId={documentId}
        selectButton={selectButton}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    );
  } else if (compSchema) {
    return (
      <CompPropertyUnselected
        reportId={reportId}
        hcAddressId={compSchema.address.hcAddressId}
        compSchema={compSchema}
        selectButton={selectButton}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
      />
    );
  } else {
    return null;
  }
};
