import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { routeBack } from '@hc/redux-saga-router-plus/hclib/actions';
import { navigateConfirmHoc } from '@hc/redux-saga-router-plus/hclib/react';

import history from 'legacy/routes/history';
import { EVENTS } from 'legacy/appstore/events';
import { FORM_NAME, FIELD_NAMES } from 'legacy/forms/personalization-form';

import { validateEmailOrEmpty } from 'legacy/utils/form-validators';

import {
  personalizationUploadImg,
  personalizationDataUpdate,
  personalizationModalClose
} from 'actions/personalization';
import { analyticsEvent } from 'actions/analytics';

import {
  getPersonalizationValues,
  getPersonalizationUploadStatusObj,
  getPersonalizationStatus,
  getPersonalizationFormValues,
  getIsFormModalOpen
} from 'selectors/personalization';

import PersonalizationModal from 'legacy/components/PersonalizationModal';

import modalWithIOSInputFix from 'legacy/hocs/modalWithIOSInputFix';

const NAV_CONFIRM_MSG = 'Are you sure you want to leave with unsaved changes?';

const PersonalizationModalWithInputFix =
  modalWithIOSInputFix(PersonalizationModal);
const NavConfirmPersonalizationModal = navigateConfirmHoc(
  PersonalizationModalWithInputFix,
  history,
  'dirty',
  NAV_CONFIRM_MSG
);

const mapStateToProps = (state, ownProps) => {
  const personalizationData = getPersonalizationValues(state);
  return {
    isOpen: getIsFormModalOpen(state),
    initialValues: !isEmpty(personalizationData) ? personalizationData : null,
    formValues: getPersonalizationFormValues(state, ...FIELD_NAMES),
    status: getPersonalizationStatus(state),
    uploadStatus: getPersonalizationUploadStatusObj(state),
    validateEmail: validateEmailOrEmpty
  };
};

// Checks the 'include' checkbox associated with the row when a value is changed
const includeOnChange = (dispatch, groupName, value) => {
  dispatch(change(FORM_NAME, groupName, value || false, true));
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (formValues, e) => {
    e.preventDefault();
    dispatch(personalizationDataUpdate(formValues));
  },
  onUpload: (e, fieldName, groupName) => {
    e.preventDefault();
    dispatch(personalizationUploadImg(groupName, fieldName, e.target.files[0]));
  },
  onIncludeOnChange: (groupName, e, value) => {
    includeOnChange(dispatch, groupName, value);
  },
  routeBack: () => {
    dispatch(routeBack());
  },
  onDeleteImg: (name) => {
    dispatch(change(FORM_NAME, name, ''));
  },
  onChangeName: () => {},
  onClose: (dirty) => {
    let leaveUnsaved = true;
    if (dirty) {
      leaveUnsaved = window.confirm(NAV_CONFIRM_MSG);
    }
    if (leaveUnsaved) {
      dispatch(personalizationModalClose());
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_NAME
  })(NavConfirmPersonalizationModal)
);
