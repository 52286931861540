import { report } from 'hc-ravenjs-logger';
import { call, delay, put, select } from 'redux-saga/effects';
import difference from 'lodash/difference';
import chunk from 'lodash/chunk';

import HC_CONSTANTS from 'HC_CONSTANTS';

import apiUtil from 'legacy/utils/api';
import { watchEvery } from 'legacy/utils/saga';
import { buildStreetViewUrl } from 'legacy/utils/photos';
import { isLocationKnown } from 'legacy/utils/property-details';

import { getGeoLocationMappings } from 'selectors/geolocation';
import { getUseVRShareEndpoint } from 'selectors/property-explorer';
import { getAllAddressIdsWithPhotos } from 'selectors/photos';
import { getPropertyResult } from 'selectors/property-search';
import { getSubjectAddressId } from 'selectors/subject';

import {
  PHOTOS_FETCH,
  PHOTOS_FETCH_ALL,
  PHOTOS_FETCH_SUBJECT,
  PHOTOS_FETCH_SEARCH_RESULT,
  PHOTOS_CHECK_STREET_VIEW_URL,
  photosFetchAll,
  photosFetchSuccess,
  photosStreetViewUrlSuccess,
  photosStreetViewUrlError,
  photosCheckStreetViewUrl
} from 'actions/photos';

const { HUELL_PROXY_URL, VRSHARE_PROXY_URL, GOOGLE_STREET_VIEW_URL } =
  HC_CONSTANTS;
const PHOTO_REQUEST_DELAY = 1000;
const PHOTO_REQUEST_LIMIT = 100;

function* fetchPhotosLatest(addressIds) {
  const useVRShareEndpoint = yield select(getUseVRShareEndpoint);
  const url = useVRShareEndpoint
    ? `${VRSHARE_PROXY_URL}/emporium/vrshare/photos/?details=True`
    : `${HUELL_PROXY_URL}/emporium/photos/?details=True`;

  return yield call(apiUtil.POST, url, addressIds);
}

function* fetchPhotosAll(addressId) {
  const useVRShareEndpoint = yield select(getUseVRShareEndpoint);
  const url = useVRShareEndpoint
    ? `${VRSHARE_PROXY_URL}/emporium/vrshare/listing_photos/${addressId}`
    : `${HUELL_PROXY_URL}/emporium/listing_photos/${addressId}`;
  return yield call(apiUtil.GET, url);
}

function* handlePhotosFetch(action) {
  try {
    const { addressIds } = action.payload;
    const addressIdsWithPhotosLoaded = yield select(getAllAddressIdsWithPhotos);
    const addressIdsWithoutPhotos = difference(
      addressIds,
      addressIdsWithPhotosLoaded
    ).map((aId) => parseInt(aId));
    const addressChunks = chunk(addressIdsWithoutPhotos, PHOTO_REQUEST_LIMIT);
    const geoLocationMapping = yield select(
      getGeoLocationMappings(addressIdsWithoutPhotos)
    );
    for (let c = 0; c < addressChunks.length; c++) {
      let response;
      if (action.type === PHOTOS_FETCH_ALL) {
        // Currently only called for single address
        response = yield call(fetchPhotosAll, addressChunks[c][0]);
      } else {
        response = yield call(fetchPhotosLatest, addressChunks[c]);
      }
      yield put(photosFetchSuccess(response, geoLocationMapping));
      for (let i = 0; i < addressChunks[c].length; i++) {
        const addressId = addressChunks[c][i];
        const geoLocation = geoLocationMapping[addressId] || {};
        if (
          !response[addressId].length &&
          geoLocation.latitude &&
          geoLocation.longitude &&
          isLocationKnown(geoLocation.geoPrecision)
        ) {
          yield put(
            photosCheckStreetViewUrl(
              addressId,
              buildStreetViewUrl(geoLocation.latitude, geoLocation.longitude)
            )
          );
        }
      }
      if (c !== addressChunks.length - 1) {
        yield delay(PHOTO_REQUEST_DELAY);
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function* handlePhotosFetchSubject(action) {
  const addressId = yield select(getSubjectAddressId);
  yield put(photosFetchAll(addressId));
}

function* handlePhotosFetchSearchResult(action) {
  const searchResult = yield select(getPropertyResult);
  // NOTE: Decided not to refactor the photos saga yet am leaving this for when we do
  yield put(photosFetchAll(searchResult.addressId));
}

export function* handlePhotosCheckStreetViewUrl(action) {
  const { addressId, url } = action.payload;
  const params = url.split('?')[1];
  const response = yield call(
    apiUtil.GET,
    `${GOOGLE_STREET_VIEW_URL}/metadata?${params}`,
    null,
    { sendAuth: false }
  );
  if (response.status === 'OK') {
    yield put(photosStreetViewUrlSuccess(addressId, url));
  } else {
    yield put(photosStreetViewUrlError(addressId, url));
    // Check for error response specific to this endpoint
    if (response.status === 'REQUEST_DENIED') {
      report(`${response.status}: ${response.error_message}`);
      console.error(response);
    }
  }
}

export function registerPhotosSaga() {
  watchEvery({
    [PHOTOS_FETCH]: handlePhotosFetch,
    [PHOTOS_FETCH_ALL]: handlePhotosFetch,
    [PHOTOS_FETCH_SUBJECT]: handlePhotosFetchSubject,
    [PHOTOS_FETCH_SEARCH_RESULT]: handlePhotosFetchSearchResult,
    [PHOTOS_CHECK_STREET_VIEW_URL]: handlePhotosCheckStreetViewUrl
  });
}
