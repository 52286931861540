import classNames from 'classnames';

import { dollarsFormatter } from 'legacy/utils/formatters';

import { PropertyStatusChip as theme } from 'legacy/css-modules';

const PropertyStatusChip = ({ propertyStatus, price, visible }) => {
  if (!propertyStatus || !visible) return null;
  return (
    <div className={theme.PropertyStatusChip}>
      <div
        className={classNames(theme.Icon, {
          [theme.active]: propertyStatus === 'Active',
          [theme.pending]: propertyStatus === 'Pending'
        })}
      >
        $
      </div>
      <div
        className={classNames(theme.Status, {
          [theme.active]: propertyStatus === 'Active',
          [theme.pending]: propertyStatus === 'Pending'
        })}
      >
        {propertyStatus}
      </div>
      <div className={theme.Price}>{dollarsFormatter(price)}</div>
    </div>
  );
};

export default PropertyStatusChip;
