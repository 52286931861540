import { EVENTS } from 'legacy/appstore/events';

import {
  determineShapeTypeFromGeoJson,
  buildRadiusGeoJson
} from 'legacy/utils/maps';

export const RENTAL_COMPS_SHOW_ON_MAP = 'RENTAL_COMPS_SHOW_ON_MAP';
export const RENTAL_COMPS_SHOW_ON_MAP_CLEAR = 'RENTAL_COMPS_SHOW_ON_MAP_CLEAR';
export const RENTAL_COMPS_SORT = 'RENTAL_COMPS_SORT';
export const RENTAL_COMPS_SORT_SELECTED = 'RENTAL_COMPS_SORT_SELECTED';
export const RENTAL_COMPS_CLEAR_SORT = 'RENTAL_COMPS_CLEAR_SORT';
export const RENTAL_COMPS_FILTERS_APPLY = 'RENTAL_COMPS_FILTERS_APPLY';
export const RENTAL_COMPS_FILTERS_SAVE = 'RENTAL_COMPS_FILTERS_SAVE';
export const RENTAL_COMPS_CLEAR_FILTER = 'RENTAL_COMPS_CLEAR_FILTER';
export const RENTAL_COMPS_CLEAR_ALL_FILTERS = 'RENTAL_COMPS_CLEAR_ALL_FILTERS';
export const RENTAL_COMPS_SEARCH = 'RENTAL_COMPS_SEARCH';
export const RENTAL_COMPS_SELECT_TOGGLE = 'RENTAL_COMPS_SELECT_TOGGLE';
export const RENTAL_COMPS_FOCUS_TOGGLE = 'RENTAL_COMPS_FOCUS_TOGGLE';
export const RENTAL_COMPS_ADJUST_SAVE = 'RENTAL_COMPS_ADJUST_SAVE';
export const RENTAL_COMPS_AFTER_LIST_SCROLL = 'RENTAL_COMPS_AFTER_LIST_SCROLL';
export const RENTAL_COMPS_SET_LIST_SCROLLED_TO_COMP =
  'RENTAL_COMPS_SET_LIST_SCROLLED_TO_COMP';
export const RENTAL_COMPS_DETERMINE_ADD_COMP =
  'RENTAL_COMPS_DETERMINE_ADD_COMP';
export const RENTAL_COMPS_ADD_COMP = 'RENTAL_COMPS_ADD_COMP';
export const RENTAL_COMPS_ADD_COMP_SUCCESS = 'RENTAL_COMPS_ADD_COMP_SUCCESS';
export const RENTAL_COMPS_UNSELECT_ALL = 'RENTAL_COMPS_UNSELECT_ALL';
export const RENTAL_COMPS_FILTER_DISTANCE_CHANGE =
  'RENTAL_COMPS_FILTER_DISTANCE_CHANGE';
export const RENTAL_COMPS_FARM_UPDATE_SUCCESS =
  'RENTAL_COMPS_FARM_UPDATE_SUCCESS';
export const RENTAL_COMPS_FARM_UPDATE_FAILURE =
  'RENTAL_COMPS_FARM_UPDATE_FAILURE';
export const RENTAL_COMPS_MAP_DRAW_SUBMIT = 'RENTAL_COMPS_MAP_DRAW_SUBMIT';
export const RENTAL_COMPS_MAP_DRAW_DELETE_ALL =
  'RENTAL_COMPS_MAP_DRAW_DELETE_ALL';
export const RENTAL_COMPS_LOAD_FARM_FOR_DEFAULT_DISTANCE =
  'RENTAL_COMPS_LOAD_FARM_FOR_DEFAULT_DISTANCE';
export const RENTAL_COMPS_LIST_CHANGE_VIEW = 'RENTAL_COMPS_LIST_CHANGE_VIEW';
export const RENTAL_COMPS_FILTERS_CHANGE_RELATIVE =
  'RENTAL_COMPS_FILTERS_CHANGE_RELATIVE';
export const RENTAL_COMPS_FILTERS_CHANGE_ABSOLUTE =
  'RENTAL_COMPS_FILTERS_CHANGE_ABSOLUTE';
export const RENTAL_COMPS_DELETE_SAVED_FILTER =
  'RENTAL_COMPS_DELETE_SAVED_FILTER';
export const RENTAL_COMPS_CHANGE_FILTER_SET_ACTIVE =
  'RENTAL_COMPS_CHANGE_FILTER_SET_ACTIVE';
export const RENTAL_COMPS_CHANGE_FILTER_SET_PRIMARY =
  'RENTAL_COMPS_CHANGE_FILTER_SET_PRIMARY';
export const RENTAL_COMPS_REFRESH = 'RENTAL_COMPS_REFRESH';
export const RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE =
  'RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE';
export const RENTAL_COMPS_REFRESH_CLEAR_VALUE_CHANGE_WARNING =
  'RENTAL_COMPS_REFRESH_CLEAR_VALUE_CHANGE_WARNING';
export const RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT =
  'RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT';
export const RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT_SUCCESS =
  'RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT_SUCCESS';
export const RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT_FAILURE =
  'RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT_FAILURE';

export const rentalCompsShowOnMap = (comp, pinOnly) => {
  return {
    type: RENTAL_COMPS_SHOW_ON_MAP,
    payload: { comp, pinOnly }
  };
};

export const rentalCompsShowOnMapClear = () => {
  return {
    type: RENTAL_COMPS_SHOW_ON_MAP_CLEAR
  };
};

export const rentalCompsKeywordSearchSubmit = (keywords) => {
  return {
    type: RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT,
    payload: { keywords }
  };
};

export const rentalCompsKeywordSearchSubmitSuccess = (matchesByAddressId) => {
  return {
    type: RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT_SUCCESS,
    payload: { matchesByAddressId }
  };
};

export const rentalCompsKeywordSearchSubmitFailure = () => {
  return {
    type: RENTAL_COMPS_KEYWORD_SEARCH_SUBMIT_FAILURE
  };
};
export const rentalCompsRefresh = () => {
  return {
    type: RENTAL_COMPS_REFRESH
  };
};

export const rentalCompsRefreshReadyForRecompute = ({
  hcCompsFarmList,
  userCompsFarmList,
  previousCompAddressIds
}) => {
  return {
    type: RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE,
    payload: {
      hcCompsFarmList,
      userCompsFarmList,
      previousCompAddressIds,
      recomputeHints: {
        action: 'selectRentalComp'
      }
    }
  };
};

export const rentalCompsRefreshClearValueChangeWarning = () => {
  return {
    type: RENTAL_COMPS_REFRESH_CLEAR_VALUE_CHANGE_WARNING
  };
};

export const rentalCompsFiltersChangeRelative = (
  relativeValues,
  overwriteAll = false
) => {
  return {
    type: RENTAL_COMPS_FILTERS_CHANGE_RELATIVE,
    payload: { relativeValues, overwriteAll },
    meta: {
      analytics: {
        event: EVENTS.RENTAL_COMPS_FILTER_CHANGE_RELATIVE,
        props: relativeValues
      }
    }
  };
};

export const rentalCompsFiltersChangeAbsolute = (absoluteValues) => {
  return {
    type: RENTAL_COMPS_FILTERS_CHANGE_ABSOLUTE,
    payload: { absoluteValues },
    meta: {
      analytics: {
        event: EVENTS.RENTAL_COMPS_FILTER_CHANGE_ABSOLUTE,
        props: absoluteValues
      }
    }
  };
};

export const rentalCompsListChangeView = ({ viewSelected, viewType }) => {
  return {
    type: RENTAL_COMPS_LIST_CHANGE_VIEW,
    payload: { viewSelected, viewType },
    meta: {
      analytics: {
        event: EVENTS.RENTAL_COMPS_LIST_VIEW_CHANGE,
        props: {
          view_type: viewType,
          view_selected: viewSelected
        }
      },
      router: {
        query: { viewSelected, viewType },
        options: {
          inherit: true
        }
      }
    }
  };
};

export const rentalCompsDetermineAddComp = (slug) => ({
  type: RENTAL_COMPS_DETERMINE_ADD_COMP,
  payload: { slug },
  meta: {
    analytics: {
      event: EVENTS.RENTAL_COMPS_ADD_SEARCH,
      props: {
        address_slug: slug
      }
    }
  }
});

export const rentalCompsAddComp = (comp) => ({
  type: RENTAL_COMPS_ADD_COMP,
  payload: { comp }
});

export const rentalCompsAddCompSuccess = (comp) => ({
  type: RENTAL_COMPS_ADD_COMP_SUCCESS,
  payload: { comp }
});

export const rentalCompsSort = (attr, order) => ({
  type: RENTAL_COMPS_SORT,
  payload: {
    attr,
    order
  },
  meta: {
    analytics: {
      event: EVENTS.RENTAL_COMPS_SORT,
      props: {
        is_viewing_selected: false,
        column: attr,
        order
      }
    }
  }
});

export const rentalCompsSortSelected = (attr, order) => ({
  type: RENTAL_COMPS_SORT_SELECTED,
  payload: {
    attr,
    order
  },
  meta: {
    analytics: {
      event: EVENTS.RENTAL_COMPS_SORT,
      props: {
        is_viewing_selected: true,
        column: attr,
        order
      }
    }
  }
});

export const rentalCompsFiltersSave = ({
  filterSetId,
  filterSetName,
  makeDefault
}) => {
  return {
    type: RENTAL_COMPS_FILTERS_SAVE,
    payload: { filterSetId, filterSetName, makeDefault }
  };
};

export const rentalCompsClearSort = () => ({
  type: RENTAL_COMPS_CLEAR_SORT
});

export const rentalCompsFiltersApply = (filters, available) => {
  return {
    type: RENTAL_COMPS_FILTERS_APPLY,
    payload: { filters, available }
  };
};

export const rentalCompsClearFilter = (filterKey, mapId) => ({
  type: RENTAL_COMPS_CLEAR_FILTER,
  payload: { filterKey, mapId },
  meta: {
    router: {
      options: { inherit: true, replace: true, queryRemove: [filterKey] }
    }
  }
});

export const rentalCompsClearAllFilters = (mapId) => ({
  type: RENTAL_COMPS_CLEAR_ALL_FILTERS,
  payload: { mapId },
  meta: {
    analytics: { event: EVENTS.RENTAL_COMPS_FILTER_CLEAR }
  }
});

export const rentalCompsSearch = (searchStr) => ({
  type: RENTAL_COMPS_SEARCH,
  payload: { searchStr }
});

export const rentalCompsSelectToggle = (comp) => ({
  type: RENTAL_COMPS_SELECT_TOGGLE,
  payload: {
    comp,
    recomputeHints: {
      action: 'selectRentalComp'
    }
  }
});

export const rentalCompsFocusToggle = (comp) => ({
  type: RENTAL_COMPS_FOCUS_TOGGLE,
  payload: { comp }
});

export const rentalCompsAdjustSave = (adjustments) => {
  return {
    type: RENTAL_COMPS_ADJUST_SAVE,
    payload: {
      adjustments,
      recomputeHints: {
        action: 'selectRentalComp'
      }
    },
    meta: {
      analytics: {
        event: EVENTS.RENTAL_COMPS_ADJUST_SAVE,
        props: { adjustments: adjustments }
      }
    }
  };
};

export const rentalCompsUnselectAll = () => ({
  type: RENTAL_COMPS_UNSELECT_ALL,
  payload: {
    recomputeHints: {
      action: 'selectRentalComp'
    }
  }
});

export const rentalCompsAfterListScroll = (compIds) => ({
  type: RENTAL_COMPS_AFTER_LIST_SCROLL,
  payload: {
    compIds
  }
});

export const rentalCompsSetListScrolledToComp = (comp) => ({
  type: RENTAL_COMPS_SET_LIST_SCROLLED_TO_COMP,
  payload: {
    comp
  }
});

export const rentalCompsFilterDistanceChange = (
  mapId,
  distance,
  geoLocation
) => ({
  type: RENTAL_COMPS_FILTER_DISTANCE_CHANGE,
  payload: {
    mapId,
    polygons: [buildRadiusGeoJson(geoLocation, distance, true)]
  },
  meta: {
    analytics: {
      event: EVENTS.COMPS_FILTER_CHANGE_DISTANCE,
      props: {
        map_id: mapId,
        distance
      }
    },
    router: {
      query: { distance },
      options: { inherit: true }
    }
  }
});

export const rentalCompsFarmUpdateSuccess = (
  farm,
  existingAddressIds = [],
  confidence
) => ({
  type: RENTAL_COMPS_FARM_UPDATE_SUCCESS,
  payload: { farm, existingAddressIds, confidence, isRentalComp: true }
});

export const rentalCompsFarmUpdateFailure = () => {
  return {
    type: RENTAL_COMPS_FARM_UPDATE_FAILURE
  };
};

export const rentalCompsMapDrawSubmit = (mapId, polygons, nextView) => ({
  type: RENTAL_COMPS_MAP_DRAW_SUBMIT,
  payload: { mapId, polygons },
  meta: {
    analytics: {
      event: EVENTS.MAP_DRAW_SUBMIT,
      props: {
        map_id: mapId,
        shape: determineShapeTypeFromGeoJson(polygons[0])
      }
    }
  }
});

export const rentalCompsMapDrawDeleteAll = (mapId, nextView) => ({
  type: RENTAL_COMPS_MAP_DRAW_DELETE_ALL,
  payload: { mapId },
  meta: {
    analytics: {
      event: EVENTS.MAP_DRAW_DELETE,
      props: {
        map_id: mapId
      }
    }
  }
});

export const rentalCompsLoadFarmForDefaultDistance = (
  mapId,
  distance,
  geoLocation
) => ({
  type: RENTAL_COMPS_LOAD_FARM_FOR_DEFAULT_DISTANCE,
  payload: {
    mapId,
    polygons: [buildRadiusGeoJson(geoLocation, distance, true)]
  }
});

export const rentalCompsDeleteSavedFilter = (filterSetId) => {
  return {
    type: RENTAL_COMPS_DELETE_SAVED_FILTER,
    payload: { filterSetId },
    meta: {
      analytics: {
        event: EVENTS.RENTAL_COMPS_DELETE_FILTER_SET,
        props: { filter_set_id: filterSetId }
      }
    }
  };
};

export const rentalCompsChangeFilterSetActive = (filterSetId) => {
  return {
    type: RENTAL_COMPS_CHANGE_FILTER_SET_ACTIVE,
    payload: { filterSetId },
    meta: {
      analytics: {
        event: EVENTS.RENTAL_COMPS_CHANGE_FILTER_SET_ACTIVE,
        props: { filter_set_id: filterSetId }
      }
    }
  };
};

export const rentalCompsChangeFilterSetPrimary = (filterSetId) => {
  return {
    type: RENTAL_COMPS_CHANGE_FILTER_SET_PRIMARY,
    payload: { filterSetId },
    meta: {
      analytics: {
        event: EVENTS.RENTAL_COMPS_CHANGE_FILTER_SET_PRIMARY,
        props: { filter_set_id: filterSetId }
      }
    }
  };
};
