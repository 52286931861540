import React from 'react';
import { isLoading } from 'src/lib/utils/general';
import { useDocumentRole } from '../../../hooks';
import { DocumentRoles, FilterDocument } from '../../../types';
import { CompFields } from '../../../types/CompFields';
import { CompFilterButton } from './CompFilterButton';
import { Skeleton } from 'src/lib/components';
import { FILTER_TYPE } from 'src/lib/report-api/types/Filters';
import {
  convertNumArrayToFilterRange,
  getFilterLabel
} from 'src/lib/utils/compFilters';
import { CompFilterBathroom } from 'src/lib/report-api/features/CompFilters/CompFilterBathroom';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
}
const COMP_FIELD = CompFields.bathrooms;
export const CompFilterButtonBathroom = ({
  dataHcName,
  reportId,
  className
}: Props) => {
  const {
    status: filterDocumentStatus,
    data: [filterDocument]
  } = useDocumentRole<FilterDocument>(reportId, DocumentRoles.Filter);
  if (isLoading(filterDocumentStatus) || !filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }

  const filterValue = filterDocument.data.filters[COMP_FIELD];

  const active = !!filterValue;
  return (
    <CompFilterButton
      reportId={reportId}
      label={
        active
          ? getFilterLabel({
              type: FILTER_TYPE.BATHS,
              value: convertNumArrayToFilterRange(filterValue?.absoluteValue)
            })
          : FILTER_TYPE.BATHS
      }
      dataHcName={dataHcName}
      className={className}
      compField={COMP_FIELD}
      content={
        <CompFilterBathroom
          reportId={reportId}
          dataHcName={`${dataHcName}-filter-content`}
        />
      }
      active={active}
    />
  );
};
