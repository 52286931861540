import { Personalization } from 'src/lib/huell';

export const hasDefaultCobrandingData = (data: Personalization) => {
  return (
    (data.photo_url && data.include_photo) ||
    ((data.first_name || data.last_name) && data.include_name) ||
    (data.company_name && data.include_company_name) ||
    (data.include_company_logo && data.company_logo_url)
  );
};

export const hasCobrandingData = (data: Personalization) => {
  return Object.keys(data).filter((value) => {
    if (value?.split('_')[0] === 'include') {
      return data[value as keyof Personalization];
    } else {
      return false;
    }
  }).length >= 1
    ? true
    : false;
};
