import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { STATUSES } from 'legacy/appstore/constants';
import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

import { PROPERTY_DETAILS_ATTRIBUTES } from 'legacy/utils/property-details';

const { HC, MLS, PR, ANY } = PROPERTY_DATA_SOURCES;
const LOADING_STATUSES = [
  undefined,
  null,
  '',
  STATUSES.INIT,
  STATUSES.PENDING,
  STATUSES.LOADING
];

const getPropertyState = (state) => state.properties;

export const getPropertyByAddressId = (addressId) =>
  createSelector(getPropertyState, (propertyState) => {
    return propertyState[addressId] || {};
  });

export const getPropertyMeta = (addressId) =>
  createSelector(
    getPropertyByAddressId(addressId),
    (property) => property.meta
  );

export const getPropertyEdits = (addressId) =>
  createSelector(getPropertyByAddressId(addressId), (property) => {
    return property.edits;
  });

export const getPropertyVisibleDetailsType = (addressId) =>
  createSelector(
    getPropertyMeta(addressId),
    (meta) => meta.visiblePropertyDetailsType
  );

export const getPropertyStatuses = (addressId) =>
  createSelector(getPropertyByAddressId(addressId), (property) => ({
    [HC]: get(property, [HC, 'statusByQuery'], {}),
    [MLS]: get(property, [MLS, 'statusByQuery'], {}),
    [PR]: get(property, [PR, 'statusByQuery'], {})
  }));

export const getPropertyAttributesLoaded = (addressId) =>
  createSelector(getPropertyStatuses(addressId), (statusesBySource) => {
    let statuses = {};
    const sources = Object.keys(statusesBySource);
    const attributes = Object.keys(PROPERTY_DETAILS_ATTRIBUTES);
    sources.forEach((source) => {
      if (!statuses.hasOwnProperty(source)) {
        statuses[source] = {};
      }
      attributes.forEach((attr) => {
        const status = get(statusesBySource, [
          source,
          get(PROPERTY_DETAILS_ATTRIBUTES, [attr, 'queryBySource', source])
        ]);
        statuses[source][attr] = LOADING_STATUSES.indexOf(status) === -1;
      });
    });
    return statuses;
  });

export const getPropertySingleAttributeLoadedBySource = (addressId, attrKey) =>
  createSelector(getPropertyStatuses(addressId), (statusesBySource) => {
    const sources = Object.keys(statusesBySource);
    const loaded = {};
    sources.forEach((source) => {
      loaded[source] =
        LOADING_STATUSES.indexOf(
          get(statusesBySource, [
            source,
            get(PROPERTY_DETAILS_ATTRIBUTES, [attrKey, 'queryBySource', source])
          ])
        ) === -1;
    });
    return loaded;
  });

export const getPropertyAttributesLoadedForSource = (addressId, source) =>
  createSelector(
    getPropertyAttributesLoaded(addressId),
    (attributeStatuses) => {
      let loadingStatuses = {};
      if (source === ANY) {
        for (let s in attributeStatuses) {
          for (let attr in attributeStatuses[s]) {
            if (
              !loadingStatuses.hasOwnProperty(attr) ||
              (!loadingStatuses[attr] && attributeStatuses[s][attr])
            ) {
              loadingStatuses[attr] = attributeStatuses[s][attr];
            }
          }
        }
        return loadingStatuses;
      } else {
        return get(attributeStatuses, source, {});
      }
    }
  );

export const getPropertySingleAttributeLoadingForSource = (
  addressId,
  source,
  attrKey
) =>
  createSelector(
    getPropertySingleAttributeLoadedBySource(addressId, attrKey),
    (loadedBySource) => {
      if (source === ANY) {
        for (let s in loadedBySource) {
          if (loadedBySource[s]) {
            return true;
          }
        }
      } else {
        return get(loadedBySource, source, false);
      }
      return false;
    }
  );

export const getPropertyDetailsAttributesLoaded = (addressId) =>
  createSelector(getPropertyStatuses(addressId), (statusesBySource) => {
    let loadedByAttr = {};
    for (let attrKey in PROPERTY_DETAILS_ATTRIBUTES) {
      const attributeSources = get(PROPERTY_DETAILS_ATTRIBUTES, [
        attrKey,
        'mainSource'
      ]);
      const { source, query } = attributeSources;
      const status = get(statusesBySource, [source, query]);
      loadedByAttr[attrKey] = LOADING_STATUSES.indexOf(status) === -1;
    }
    return loadedByAttr;
  });

export const getPropertySource = (addressId, src) =>
  createSelector(
    getPropertyByAddressId(addressId),
    (property) => property[src]
  );

export const getPropertySourceStatusByQuery = (addressId, src) =>
  createSelector(getPropertySource(addressId, src), (source) =>
    source && source.statusByQuery ? source.statusByQuery : {}
  );

export const getPropertySourceStatusForQuery = (addressId, src, query) =>
  createSelector(
    getPropertySourceStatusByQuery(addressId, src),
    (statusByQuery) => statusByQuery[query]
  );

export const getPropertySourceForQueryLoading = (addressId, src, query) =>
  createSelector(
    getPropertySourceStatusByQuery(addressId, src),
    (statusByQuery) =>
      [
        undefined,
        null,
        '',
        STATUSES.INIT,
        STATUSES.LOADING,
        STATUSES.PENDING
      ].indexOf(statusByQuery[query]) > -1
  );

export const getPropertyDataForSource = (addressId, src) =>
  createSelector(getPropertySource(addressId, src), (source) =>
    get(source, 'data', {})
  );

export const getPropertySourceDataForQuery = (addressId, src, query) =>
  createSelector(
    getPropertyDataForSource(addressId, src),
    (data) => data[query] || {}
  );

export const getPropertyShouldLoadExtendedDetails = (addressId) =>
  createSelector(
    getPropertySourceStatusForQuery(addressId, PR, QUERIES.TAX_HISTORY),
    getPropertySourceStatusForQuery(addressId, MLS, QUERIES.LISTING_DETAILS),
    (taxHistoryStatus, listingDetailsStatus) =>
      !taxHistoryStatus ||
      !listingDetailsStatus ||
      taxHistoryStatus === STATUSES.INIT ||
      listingDetailsStatus === STATUSES.INIT
  );

const _onlyPropertyDetails = (data) => {
  let inPropDetails = {};
  for (let a in data) {
    if (PROPERTY_DETAILS_ATTRIBUTES.hasOwnProperty(a)) {
      inPropDetails[a] = data[a];
    }
  }
  return inPropDetails;
};

const _taxHistoryDataToPropertyDetails = (data, dataForPropertyDetails) => {
  const latestTaxData = data.taxHistory.history.reduce((a, c) =>
    a && a.taxYear && a.taxYear > c.taxYear ? a : c
  );
  return {
    ...dataForPropertyDetails,
    ..._onlyPropertyDetails(latestTaxData)
  };
};

const _srcDataToPropertyDetails = (data) => {
  let dataForPropertyDetails = {};
  if (get(data, ['propertyFeatures'])) {
    dataForPropertyDetails = {
      ...dataForPropertyDetails,
      ..._onlyPropertyDetails(data.propertyFeatures)
    };
  }
  if (get(data, ['taxHistory', 'history', 'length'])) {
    dataForPropertyDetails = {
      ...dataForPropertyDetails,
      ..._taxHistoryDataToPropertyDetails(data, dataForPropertyDetails)
    };
  }
  if (get(data, ['listingDetails'])) {
    dataForPropertyDetails = {
      ...dataForPropertyDetails,
      ..._onlyPropertyDetails(data.listingDetails)
    };
  }
  if (get(data, ['property'])) {
    dataForPropertyDetails = {
      ...dataForPropertyDetails,
      ..._onlyPropertyDetails(data.property)
    };
  }
  return dataForPropertyDetails;
};

export const getPropertyDetails = (addressId, src) =>
  createSelector(getPropertyByAddressId(addressId), (property) => {
    if (src === ANY) {
      const sourceHC = _srcDataToPropertyDetails(
        get(property, [HC, 'data'], {})
      );
      const sourceMLS = _srcDataToPropertyDetails(
        get(property, [MLS, 'data'], {})
      );
      const sourcePR = _srcDataToPropertyDetails(
        get(property, [PR, 'data'], {})
      );
      // TODO: Remove this when we move to graphql
      const { hoaAssociation, hoaFee, hoaFeeIncludes, hoaFeeFrequency } =
        sourceMLS;
      const { taxHistory, taxYear, taxAmount, zoning } = sourcePR;
      const newData = {
        taxHistory,
        taxYear,
        taxAmount,
        hoaAssociation,
        hoaFee,
        hoaFeeIncludes,
        hoaFeeFrequency,
        zoning
      };
      return {
        ...sourcePR,
        ...sourceMLS,
        ...sourceHC,
        ...newData
      };
    } else {
      return _srcDataToPropertyDetails(get(property, [src, 'data'], {}));
    }
  });

export const getPropertyTransactionHistory = (addressId) =>
  createSelector(
    getPropertySourceDataForQuery(addressId, HC, QUERIES.TRANSACTION_HISTORY),
    (transactionHistory) => {
      return transactionHistory.events || [];
    }
  );

export const getPropertyTransaction = (addressId, transactionId) =>
  createSelector(
    getPropertyTransactionHistory(addressId),
    (transactionHistory) => {
      for (let i = 0; i < transactionHistory.length; i++) {
        if (transactionHistory[i].transactionId === transactionId) {
          return transactionHistory[i];
        }
      }
      return {};
    }
  );

export const getPropertyShouldLoadPropertyFeatures = (addressId, source) =>
  createSelector(
    getPropertySourceStatusForQuery(
      addressId,
      source,
      QUERIES.PROPERTY_FEATURES
    ),
    (queryStatus) => !queryStatus || queryStatus === STATUSES.INIT
  );

export const getPropertyAllUserEdits = createSelector(
  getPropertyState,
  (propertyState) => {
    let edits = {};
    for (let addressId in propertyState) {
      if (!isEmpty(propertyState[addressId].edits)) {
        edits[addressId] = propertyState[addressId].edits;
      }
    }
    return edits;
  }
);
