import {
  COMP_TYPE_RENTAL,
  SECTION_ID_RENTAL_REPORT,
  SECTION_ID_COMPS
} from 'legacy/appstore/constants';

import { CompsPage } from 'legacy/async-pages';

import loadWhenReady from 'legacy/hocs/loadWhenReady';

export default (compType) =>
  loadWhenReady(
    CompsPage,
    compType === COMP_TYPE_RENTAL ? SECTION_ID_RENTAL_REPORT : SECTION_ID_COMPS
  );
