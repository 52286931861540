import React, { useState } from 'react';
import classNames from 'classnames';

import styles from './PersonalizationHeader.css';
import { Personalization } from '../types';

interface Props {
  personalization: Personalization;
  dataHcName: string;
  className?: string;
}

export const PersonalizationHeader = ({
  personalization,
  dataHcName,
  className
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.PersonalizationHeader, className)}
    >
      {personalization.photo_url && personalization.include_photo && (
        <div
          className={styles.UserSection}
          data-hc-name={`${dataHcName}-cobrand-user-photo`}
        >
          <div
            style={{ backgroundImage: `url(${personalization.photo_url})` }}
            className={styles.UserPhoto}
          />
        </div>
      )}
      <div className={styles.InfoSection}>
        {(personalization.first_name || personalization.last_name) &&
          personalization.include_name && (
            <div
              data-hc-name={`${dataHcName}-cobrand-fullname`}
              className={styles.UserName}
            >{`${personalization.first_name} ${personalization.last_name}`}</div>
          )}
        {personalization.company_name && personalization.include_company_name && (
          <div
            className={styles.CompanyName}
            data-hc-name={`${dataHcName}-cobrand-companyname`}
          >
            {personalization.company_name}
          </div>
        )}
        {!expanded && (
          <div
            className={styles.ExpandButton}
            onClick={() => setExpanded(!expanded)}
            data-hc-name={`${dataHcName}-cobrand-expand-button`}
          >
            <div className={styles.ExpandDot} />
            <div className={styles.ExpandDot} />
            <div className={styles.ExpandDot} />
          </div>
        )}
        {expanded && (
          <div
            className={styles.ExtendedInfo}
            data-hc-name={`${dataHcName}-cobrand-expanded-section`}
          >
            {personalization.address_line1 &&
              personalization.include_address_line1 && (
                <div data-hc-name={`${dataHcName}-cobrand-address-line1`}>
                  {personalization.address_line1}
                </div>
              )}
            {personalization.address_line2 &&
              personalization.include_address_line2 && (
                <div data-hc-name={`${dataHcName}-cobrand-address-line2`}>
                  {personalization.address_line2}
                </div>
              )}
            {personalization.city &&
              personalization.state &&
              personalization.zipcode &&
              personalization.include_city_state_zipcode && (
                <div
                  data-hc-name={`${dataHcName}-cobrand-city-state-zip`}
                >{`${personalization.city} ${personalization.state} ${personalization.zipcode}`}</div>
              )}

            {((personalization.phone && personalization.include_phone) ||
              (personalization.email && personalization.include_email)) && (
              <div className={styles.ContactInfo}>
                {personalization.phone && (
                  <span
                    className={styles.Phone}
                    data-hc-name={`${dataHcName}-cobrand-phone`}
                  >
                    {personalization.phone}
                  </span>
                )}
                {personalization.phone && personalization.email && (
                  <span
                    data-hc-name={`${dataHcName}-cobrand-seperator`}
                    className={styles.Separator}
                  >
                    |
                  </span>
                )}
                {personalization.email && (
                  <span
                    className={styles.Email}
                    data-hc-name={`${dataHcName}-cobrand-email-text`}
                  >
                    {personalization.email}
                  </span>
                )}
              </div>
            )}

            {personalization.website_url && personalization.include_website && (
              <div data-hc-name={`${dataHcName}-cobrand-website-url`}>
                {personalization.website_url}
              </div>
            )}
            {personalization.license_number &&
              personalization.include_license_number && (
                <div data-hc-name={`${dataHcName}-cobrand-license-number`}>
                  License Number: {personalization.license_number}
                </div>
              )}
          </div>
        )}
      </div>
      <div className={classNames(styles.Section, 'text-align-right')}>
        {personalization.include_company_logo &&
          personalization.company_logo_url && (
            <img
              className={styles.CompanyLogo}
              src={personalization.company_logo_url}
              data-hc-name={`${dataHcName}-cobrand-company-logo`}
            />
          )}
      </div>
    </div>
  );
};
