import { createSelector } from 'reselect';

import { SUBJECT_TYPE_USER, COMP_TYPE_RENTAL } from 'legacy/appstore/constants';
import { VIEWS } from 'legacy/routes/constants';

import {
  calcCompValue,
  calcRentalCompAdjustedValue,
  calcRentalCompAdjustedPrice
} from 'legacy/utils/avms';
import {
  LISTING_STATUS_OPTIONS_RENTAL,
  PROPERTY_DETAILS_ATTRIBUTES,
  getPropertyDetailsAttributeDef
} from 'legacy/utils/property-details';

import {
  getSharedCompsFilters,
  getSharedCompsNoFiltersApplied,
  getSharedCompsFilterKeys,
  getSharedCompsSort,
  getSharedCompsSortSelection,
  getSharedCompsListViewType,
  getSharedCompsFarm,
  getSharedCompsFarmList,
  getSharedCompsFarmListRaw,
  getSharedCompsSearch,
  getSharedCompsAvailableIds,
  getSharedCompsAvailableProperties,
  getSharedCompsSelectedIds,
  getSharedHasUserSelectedComps,
  getSharedCompsSelectedOrSuggestedIds,
  getSharedCompsSelectedOrSuggestedIdsOrderedBySimilarity,
  getSharedCompsSelected,
  getSharedCompsSelectedMapping,
  getSharedCompsMapMarkersSelected,
  getSharedCompsMapMarkersUserSelected,
  getSharedCompsMapMarkersUserSelectedAndAvailable,
  getSharedCompsActiveListingsProperties,
  getSharedFocusedCompId,
  getSharedFocusedComp,
  getSharedCompByAddressId,
  getSharedCompIdToScroll,
  getSharedCompsIsSelectedById,
  getSharedCompsIsEditableById,
  getSharedIsSelectedCompLimitReached,
  getSharedCompsAllowSelection,
  getSharedCompsAddressIdsAll,
  getSharedCompsGeoLocationAll,
  getSharedCompsSelectedOrSuggestedMessage,
  getSharedCompsSuggestedProperties,
  getSharedCompsFarmExists,
  getSharedCompsFarmUpdatedAt,
  getSharedCompsFilterControls,
  getSharedCompsFiltersAppliedForMetadata,
  getSharedCompsRecentSimilarProperties,
  getSharedCompsFiltersUpdatedFromUrl,
  getSharedCompsFiltersToSave,
  getSharedCompsSavedFiltersUnableToApply,
  getSharedCompsFiltersUpdatedFromUser,
  getSharedCompsDataTableColumns,
  getSharedCompsDataTableData,
  getSharedCompsTableColumnsRaw,
  getSharedCompsTableColumnsConfigurable,
  getSharedCompsTableColumnsActiveInactive,
  getSharedCompsNewDefaultFarmDistance,
  getSharedCompSortForDataTable,
  getSharedCompsFarmRefreshedAt,
  getSharedCompsKeywordSearchKeywords,
  getSharedCompsKeywordSearchStatus,
  getSharedCompsKeywordMatchesByAddressId,
  getSharedCompsKeywordMatches,
  getSharedCompsAddressIdToCerberusIdMapping,
  getSharedCompsCerberusIdToAddressIdMapping,
  getSharedCompsKeywordSearchFeatureSupported,
  getSharedCompsShowOnMap
} from 'selectors/comps-shared';
import {
  getSubjectAdjusted,
  getSubjectDefault,
  getSubjectSelected,
  getSubjectLocationUnknown
} from 'selectors/subject';
import { getIsLoggedIn } from 'selectors/auth';
import { getIsViewingSharedReport } from 'selectors/property-explorer';
import { getIsMobile } from 'selectors/match-media';

const _replaceModifiedRentalKeys = (c) => {
  const updated = { ...c };
  updated.lastListPrice = updated.lastListPriceRental || null;
  updated.lastListDate = updated.lastListDateRental || null;
  delete updated.lastListPriceRental;
  delete updated.lastListDateRental;
  return updated;
};

export const getRentalCompsShowOnMap =
  getSharedCompsShowOnMap(COMP_TYPE_RENTAL);
export const getRentalCompsKeywordSearchFeatureSupported =
  getSharedCompsKeywordSearchFeatureSupported(COMP_TYPE_RENTAL);
export const getRentalCompsCerberusIdToAddressIdMapping =
  getSharedCompsCerberusIdToAddressIdMapping(COMP_TYPE_RENTAL);
export const getRentalCompsAddressIdToCerberusIdMapping =
  getSharedCompsAddressIdToCerberusIdMapping(COMP_TYPE_RENTAL);
export const getRentalCompsKeywordMatches =
  getSharedCompsKeywordMatches(COMP_TYPE_RENTAL);
export const getRentalCompsKeywordSearchStatus =
  getSharedCompsKeywordSearchStatus(COMP_TYPE_RENTAL);
export const getRentalCompsKeywordMatchesByAddressId =
  getSharedCompsKeywordMatchesByAddressId(COMP_TYPE_RENTAL);
export const getRentalCompsKeywordSearchKeywords =
  getSharedCompsKeywordSearchKeywords(COMP_TYPE_RENTAL);
export const getRentalCompsFarmRefreshedAt =
  getSharedCompsFarmRefreshedAt(COMP_TYPE_RENTAL);
export const getRentalCompsFilters = getSharedCompsFilters(COMP_TYPE_RENTAL);
export const getRentalCompsFiltersAppliedForMetadata =
  getSharedCompsFiltersAppliedForMetadata(COMP_TYPE_RENTAL);
export const getRentalCompsNoFiltersApplied =
  getSharedCompsNoFiltersApplied(COMP_TYPE_RENTAL);
export const getRentalCompsFilterKeys =
  getSharedCompsFilterKeys(COMP_TYPE_RENTAL);
export const getRentalCompsSort = getSharedCompsSort(COMP_TYPE_RENTAL);
export const getRentalCompsSortSelected =
  getSharedCompsSortSelection(COMP_TYPE_RENTAL);
export const getRentalCompsListViewType =
  getSharedCompsListViewType(COMP_TYPE_RENTAL);
export const getRentalCompsFarm = getSharedCompsFarm(COMP_TYPE_RENTAL);
export const getRentalCompsFarmList = getSharedCompsFarmList(COMP_TYPE_RENTAL);
export const getRentalCompsFarmExists =
  getSharedCompsFarmExists(COMP_TYPE_RENTAL);
export const getRentalCompsSearch = getSharedCompsSearch(COMP_TYPE_RENTAL);
export const getRentalCompsAvailableIds =
  getSharedCompsAvailableIds(COMP_TYPE_RENTAL);
export const getRentalCompsAvailableProperties =
  getSharedCompsAvailableProperties(COMP_TYPE_RENTAL);
export const getRentalCompsActiveListingsProperties =
  getSharedCompsActiveListingsProperties(COMP_TYPE_RENTAL);
export const getRentalCompsSelectedIds =
  getSharedCompsSelectedIds(COMP_TYPE_RENTAL);
export const getRentalCompsHasUserSelectedComps =
  getSharedHasUserSelectedComps(COMP_TYPE_RENTAL);
export const getRentalCompsSelectedOrSuggestedIds =
  getSharedCompsSelectedOrSuggestedIds(COMP_TYPE_RENTAL);
export const getRentalCompsSelectedOrSuggestedIdsOrderedBySimilarity =
  getSharedCompsSelectedOrSuggestedIdsOrderedBySimilarity(COMP_TYPE_RENTAL);
export const getRentalCompsSuggestedProperties =
  getSharedCompsSuggestedProperties(COMP_TYPE_RENTAL);
export const getRentalCompsRecentSimilarProperties =
  getSharedCompsRecentSimilarProperties(COMP_TYPE_RENTAL);
export const getRentalCompsSelected = getSharedCompsSelected(COMP_TYPE_RENTAL);
export const getRentalCompsSelectedMapping =
  getSharedCompsSelectedMapping(COMP_TYPE_RENTAL);
export const getRentalCompsMapMarkersSelected =
  getSharedCompsMapMarkersSelected(COMP_TYPE_RENTAL);
export const getRentalCompsMapMarkersUserSelected =
  getSharedCompsMapMarkersUserSelected(COMP_TYPE_RENTAL);
export const getRentalCompsMapMarkersUserSelectedAndAvailable =
  getSharedCompsMapMarkersUserSelectedAndAvailable(COMP_TYPE_RENTAL);
export const getRentalFocusedCompId = getSharedFocusedCompId(COMP_TYPE_RENTAL);
export const getRentalFocusedComp = getSharedFocusedComp(COMP_TYPE_RENTAL);
export const getRentalCompByAddressId =
  getSharedCompByAddressId(COMP_TYPE_RENTAL);
export const getRentalCompIdToScroll =
  getSharedCompIdToScroll(COMP_TYPE_RENTAL);
export const getRentalCompsIsSelectedById =
  getSharedCompsIsSelectedById(COMP_TYPE_RENTAL);
export const getRentalCompsIsEditableById =
  getSharedCompsIsEditableById(COMP_TYPE_RENTAL);
export const getRentalIsSelectedCompLimitReached =
  getSharedIsSelectedCompLimitReached(COMP_TYPE_RENTAL);
export const getRentalCompsAllowSelection =
  getSharedCompsAllowSelection(COMP_TYPE_RENTAL);
export const getRentalCompsAddressIdsAll =
  getSharedCompsAddressIdsAll(COMP_TYPE_RENTAL);
export const getRentalCompsGeoLocationAll =
  getSharedCompsGeoLocationAll(COMP_TYPE_RENTAL);
export const getRentalCompsSelectedOrSuggestedMessage =
  getSharedCompsSelectedOrSuggestedMessage(COMP_TYPE_RENTAL);
export const getRentalCompsFarmUpdatedAt =
  getSharedCompsFarmUpdatedAt(COMP_TYPE_RENTAL);
export const getRentalCompsFiltersUpdatedFromUrl =
  getSharedCompsFiltersUpdatedFromUrl(COMP_TYPE_RENTAL);
export const getRentalCompsFiltersToSave =
  getSharedCompsFiltersToSave(COMP_TYPE_RENTAL);
export const getRentalCompsSavedFiltersUnableToApply =
  getSharedCompsSavedFiltersUnableToApply(COMP_TYPE_RENTAL);
export const getRentalCompsFiltersUpdatedFromUser =
  getSharedCompsFiltersUpdatedFromUser(COMP_TYPE_RENTAL);
export const getRentalCompsShouldLoadNewDefaultFarm =
  getSharedCompsNewDefaultFarmDistance(COMP_TYPE_RENTAL);
export const getRentalCompsTableColumnsRaw =
  getSharedCompsTableColumnsRaw(COMP_TYPE_RENTAL);
export const getRentalCompsTableColumnsConfigurable =
  getSharedCompsTableColumnsConfigurable(COMP_TYPE_RENTAL);
export const getRentalCompsTableColumnsActiveInactive =
  getSharedCompsTableColumnsActiveInactive(COMP_TYPE_RENTAL);
export const getRentalCompsTableColumnsActiveInactiveForOptions =
  getSharedCompsTableColumnsActiveInactive(COMP_TYPE_RENTAL, true);
export const getRentalCompsTableColumns =
  getSharedCompsDataTableColumns(COMP_TYPE_RENTAL);
export const getRentalCompSortForDataTable =
  getSharedCompSortForDataTable(COMP_TYPE_RENTAL);
export const getRentalCompsDataTableData =
  getSharedCompsDataTableData(COMP_TYPE_RENTAL);
// Replace modified keys
export const getRentalCompsFarmListRaw = (state) =>
  getSharedCompsFarmListRaw(COMP_TYPE_RENTAL)(state).map((c) =>
    _replaceModifiedRentalKeys(c)
  );
export const getRentalCompsSelectedRaw = (state) =>
  getSharedCompsSelected(COMP_TYPE_RENTAL)(state).map((c) =>
    _replaceModifiedRentalKeys(c)
  );
export const getRentalCompsSelectedMappingRaw = (state) => {
  const mapping = getSharedCompsSelectedMapping(COMP_TYPE_RENTAL)(state);
  for (let addressId in mapping) {
    mapping[addressId] = _replaceModifiedRentalKeys(mapping[addressId]);
  }
  return mapping;
};

export const getRentalCompsSelectionButtonView = createSelector(
  getRentalCompsFarmListRaw,
  getIsViewingSharedReport,
  (farm, isViewingShared) => {
    const viewCompSelection = isViewingShared
      ? VIEWS.RENTAL_COMPS_SELECTION_SHARED
      : VIEWS.RENTAL_COMPS_SELECTION;
    const viewCompSelectionMap = isViewingShared
      ? VIEWS.RENTAL_COMPS_SELECTION_MAP_SHARED
      : VIEWS.RENTAL_COMPS_SELECTION_MAP;
    return farm.length ? viewCompSelection : viewCompSelectionMap;
  }
);

export const getRentalCompsHideGallery = createSelector(
  getRentalCompsFarmListRaw,
  getIsMobile,
  getSubjectLocationUnknown,
  (farmList, isMobile, locationUnknown) => {
    return isMobile && (!farmList || !farmList.length) && locationUnknown;
  }
);

export const getRentalCompsSelectionButtonLabel = createSelector(
  getRentalCompsSelectionButtonView,
  (view) => {
    return [
      VIEWS.RENTAL_COMPS_SELECTION_SHARED,
      VIEWS.RENTAL_COMPS_SELECTION
    ].indexOf(view) > -1
      ? 'Select Rental Comps'
      : 'Draw Rental Comp Area';
  }
);

export const getRentalCompsPropertyDetailsAttributes = (state) =>
  [
    'propertyStatusRental',
    'lastListDateRental',
    'lastListPriceRental',
    'leasedDate',
    'leasedPrice',
    'userRentalBasePriceAdjustment',
    'rentalBasePriceAdjusted',
    'rentalBasePriceAdjustedDiffSqft',
    'currentRentalValue',
    'userAdjustmentRental',
    'rentalValueAdjusted',
    'rentalValueAdjustedDiffSqft',
    'bedrooms',
    'bathrooms',
    'grossLivingAreaSqft',
    'siteAreaSqft',
    'propertyType',
    'yearBuilt',
    'garageType',
    'garageNumCars',
    'stories',
    'basement',
    'pool',
    'hoaAssociation',
    'hoaFee',
    'hoaFeeFrequency',
    'hoaFeeIncludes',
    'taxYear',
    'taxAmount',
    'subdivisionName',
    'zoning',
    'publicRemarks'
  ].map((key) =>
    PROPERTY_DETAILS_ATTRIBUTES.hasOwnProperty(key)
      ? { ...getPropertyDetailsAttributeDef(key), key }
      : null
  );

export const getRentalCompsFilterControls = (isMobile) =>
  createSelector(getSubjectSelected, getIsLoggedIn, (subject, isLoggedIn) => {
    // List of filter control keys or objects w/ filter control modifications
    let filterControls = [
      'propertyType_included',
      ...(isMobile || !isLoggedIn
        ? []
        : [
            {
              key: 'distance',
              controlProps: {
                geoLocationRef: subject.geoLocation
              }
            }
          ]),
      'bedrooms_minmax',
      'bathrooms_minmax',
      'grossLivingAreaSqft_minmax',
      'yearBuilt_minmax',
      {
        label: 'Rental Listing Status',
        key: 'propertyStatusRental_included',
        controlProps: {
          options: LISTING_STATUS_OPTIONS_RENTAL
        }
      },
      {
        label: 'Rental List Date',
        key: 'lastListDateRental_minmax'
      },
      'leasedDate_minmax',
      {
        label: 'Rental List Price',
        key: 'lastListPriceRental_minmax'
      },
      'leasedPrice_minmax',
      'rentalAvm.priceMean_minmax',
      'siteAreaSqft_minmax',
      'similarityLevelAdjusted_included',
      'pool_boolean',
      'garageNumCars_minmax',
      'stories_minmax',
      'basement_boolean'
    ];
    return getSharedCompsFilterControls(filterControls);
  });

export const calcRentalCompAvm =
  (subjectType = SUBJECT_TYPE_USER) =>
  (state) => {
    const compsSelected = getRentalCompsSelected(state);

    let subject;
    if (subjectType === SUBJECT_TYPE_USER) {
      subject = getSubjectAdjusted(state);
    } else {
      subject = getSubjectDefault(state);
    }
    const avm = calcCompValue(
      compsSelected,
      subject,
      calcRentalCompAdjustedValue
    );
    return avm;
  };

export const calcRentalCompAvg =
  (subjectType = SUBJECT_TYPE_USER) =>
  (state) => {
    const compsSelected = getRentalCompsSelected(state);

    let subject;
    if (subjectType === SUBJECT_TYPE_USER) {
      subject = getSubjectAdjusted(state);
    } else {
      subject = getSubjectDefault(state);
    }
    return calcCompValue(compsSelected, subject, calcRentalCompAdjustedPrice);
  };
