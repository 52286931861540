import { GeoLocation } from 'src/lib/property-lookup/types';
import { StreetViewMetadataResponse } from '../types';

export const STREET_VIEW_METADATA_FETCH =
  'street-view/STREET_VIEW_METADATA_FETCH';

export const STREET_VIEW_METADATA_FETCH_SUCCESS =
  'street-view/STREET_VIEW_METADATA_FETCH_SUCCESS';

export const STREET_VIEW_METADATA_FETCH_ERROR =
  'street-view/STREET_VIEW_METADATA_FETCH_ERROR';

export interface StreetViewMetadataFetchAction {
  type: typeof STREET_VIEW_METADATA_FETCH;
  payload: {
    geoLocation: GeoLocation;
  };
}

export const streetViewMetadataFetch = (
  geoLocation: GeoLocation
): StreetViewMetadataFetchAction => {
  return {
    type: STREET_VIEW_METADATA_FETCH,
    payload: { geoLocation }
  };
};

export interface StreetViewMetadataFetchSuccessAction {
  type: typeof STREET_VIEW_METADATA_FETCH_SUCCESS;
  payload: { geoLocation: GeoLocation; data: StreetViewMetadataResponse };
}

export const streetViewMetadataFetchSuccess = (
  geoLocation: GeoLocation,
  data: StreetViewMetadataResponse
): StreetViewMetadataFetchSuccessAction => {
  return {
    type: STREET_VIEW_METADATA_FETCH_SUCCESS,
    payload: { geoLocation, data }
  };
};

export interface StreetViewMetadataFetchErrorAction {
  type: typeof STREET_VIEW_METADATA_FETCH_ERROR;
  payload: { geoLocation: GeoLocation };
}

export const streetViewMetadataFetchError = (
  geoLocation: GeoLocation
): StreetViewMetadataFetchErrorAction => {
  return {
    type: STREET_VIEW_METADATA_FETCH_ERROR,
    payload: { geoLocation }
  };
};

export type StreetViewActions =
  | StreetViewMetadataFetchAction
  | StreetViewMetadataFetchSuccessAction
  | StreetViewMetadataFetchErrorAction;
