import { isObject, isArray } from 'legacy/utils/utils';

// TODO: Can remove after 1/30/2020
const OLD_FILTERS = {
  grossLivingAreaSqft_minmax: [null, null],
  distance: null,
  siteAreaSqft_minmax: [null, null],
  bedrooms_minmax: [null, null],
  bathrooms_minmax: [null, null],
  salesPrice_minmax: [null, null],
  salesDate_minmax: [null, null],
  lastListPrice_minmax: [null, null],
  lastListDate_minmax: [null, null],
  lastListPriceRental_minmax: [null, null],
  lastListDateRental_minmax: [null, null],
  propertyType_included: [],
  propertyStatus_included: [],
  propertyStatusRental_included: [],
  similarityLevelAdjusted_included: [],
  yearBuilt_minmax: [null, null],
  leasedPrice_minmax: [null, null],
  leasedDate_minmax: [null, null],
  'rentalAvm.priceMean_minmax': [null, null],
  stories_minmax: [null, null],
  garageNumCars_minmax: [null, null],
  pool_boolean: null, // Can be null, true, or false
  basement_boolean: null
};

export function camelCaseToSnakeCase(str) {
  if (!str) return str;
  if (OLD_FILTERS.hasOwnProperty(camelCaseFilterKey(str))) {
    return snakeCaseFilterKey(str);
  }
  let upperChars = str.match(/([A-Z])/g);
  let lowerChars = str.match(/([a-z])/g);
  if (!upperChars || !lowerChars) {
    return str;
  }

  upperChars.forEach((char) => {
    str = str.replace(char, '_' + char.toLowerCase());
  });

  if (str.slice(0, 1) === '_') {
    str = str.slice(1);
  }

  return str;
}

export function snakeCaseToCamelCase(str) {
  if (!str) return str;
  const filterKey = camelCaseFilterKey(str);
  if (OLD_FILTERS.hasOwnProperty(filterKey)) {
    return filterKey;
  }
  const parts = str.split('_');
  if (parts.length > 1) {
    let result = `${parts[0].toLowerCase()}`;
    for (let i = 1; i < parts.length; i++) {
      const firstChar = parts[i].charAt(0).toUpperCase();
      const rest = parts[i].slice(1).toLowerCase();
      result = `${result}${firstChar}${rest}`;
    }
    return result;
  } else {
    return str;
  }
}

export function collectionKeysToCamelCase(collection) {
  return (
    collection &&
    collection.map((v) =>
      isObject(v)
        ? objectKeysToCamelCase(v)
        : isArray(v)
        ? collectionKeysToCamelCase(v)
        : v
    )
  );
}

export function collectionKeysToSnakeCase(collection) {
  return (
    collection &&
    collection.map((v) =>
      isObject(v)
        ? objectKeysToSnakeCase(v)
        : isArray(v)
        ? collectionKeysToSnakeCase(v)
        : v
    )
  );
}

export function objectKeysToCamelCase(obj) {
  let transformed = {};
  for (const k in obj) {
    const value = isArray(obj[k])
      ? collectionKeysToCamelCase(obj[k])
      : isObject(obj[k])
      ? objectKeysToCamelCase(obj[k])
      : obj[k];
    transformed[snakeCaseToCamelCase(k)] = value;
  }
  return transformed;
}

export const objectKeysToSnakeCase = (obj) => {
  let transformed = {};
  for (const k in obj) {
    const value = isObject(obj[k])
      ? objectKeysToSnakeCase(obj[k])
      : isArray(obj[k])
      ? collectionKeysToSnakeCase(obj[k])
      : obj[k];
    transformed[camelCaseToSnakeCase(k)] = value;
  }
  return transformed;
};

export const snakeCaseFilterKey = (key) => {
  const kParts = key ? key.split('_') : [];
  if (kParts.length > 1) {
    return `${camelCaseToSnakeCase(kParts.slice(0, -1).join('_'))}_${
      kParts[kParts.length - 1]
    }`;
  } else {
    return key;
  }
};

export function camelCaseFilterKey(key) {
  const kParts = key ? key.split('_') : [];
  if (kParts.length > 1) {
    return `${snakeCaseToCamelCase(kParts.slice(0, -1).join('_'))}_${
      kParts[kParts.length - 1]
    }`;
  } else {
    return key;
  }
}

export const oldFilterKeysToCamelCase = (obj) => {
  // Converts old filter keys to the new camel case format
  let transformed = {};
  for (const k in obj) {
    const value = isObject(obj[k])
      ? oldFilterKeysToCamelCase(obj[k])
      : isArray(obj[k])
      ? collectionKeysToCamelCase(obj[k])
      : obj[k];

    const kParts = k.split('_');
    if (kParts.length > 1) {
      transformed[
        `${snakeCaseToCamelCase(kParts.slice(0, -1).join('_'))}_${
          kParts[kParts.length - 1]
        }`
      ] = value;
    } else {
      transformed[k] = value;
    }
  }
  return transformed;
};

export const toCamelCase = (obj) => {
  if (isObject(obj)) {
    return objectKeysToCamelCase(obj);
  } else if (isArray(obj)) {
    return collectionKeysToCamelCase(obj);
  } else {
    return obj;
  }
};

export const toSnakeCase = (obj) => {
  if (isObject(obj)) {
    return objectKeysToSnakeCase(obj);
  } else if (isArray(obj)) {
    return collectionKeysToSnakeCase(obj);
  } else {
    return obj;
  }
};
