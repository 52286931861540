import {
  MLS_LISTINGS_GET_REQUEST,
  MLS_LISTINGS_GET_SUCCESS,
  MLS_LISTINGS_HOVER,
  MLS_LISTINGS_TOGGLE_SHOW_ON_MAP
} from 'actions/mls-listings';
import { STATUSES } from 'legacy/appstore/constants';

const INITIAL_STATE = {
  status: STATUSES.INIT,
  properties: {}, // all properties returned by API
  hoveredPropertyId: null,
  showOnMap: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MLS_LISTINGS_GET_REQUEST:
      return {
        ...state,
        status: STATUSES.LOADING
      };
    case MLS_LISTINGS_GET_SUCCESS:
      let properties = {};
      action.payload.propertyList.forEach((property) => {
        if (property.listingStatus === 'Active') {
          properties[property.addressId] = {
            ...property,
            isMlsListing: true
          };
        }
      });
      return {
        ...state,
        properties,
        status: STATUSES.SUCCESS
      };
    case MLS_LISTINGS_HOVER:
      return {
        ...state,
        hoveredPropertyId: action.payload.propertyId
      };
    case MLS_LISTINGS_TOGGLE_SHOW_ON_MAP:
      return {
        ...state,
        showOnMap: !state.showOnMap
      };
    default:
      return state;
  }
};
