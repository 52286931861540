import { STATUSES } from 'legacy/appstore/constants';
import {
  COMPS_FARM_UPDATE_SUCCESS,
  COMPS_REFRESH_READY_FOR_RECOMPUTE
} from 'actions/comps';
import { MLS_FETCH, MLS_FETCH_SUCCESS, MLS_FETCH_FAILURE } from 'actions/mls';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';
import { GET_REPORT_USER_VERSION_SUCCESS } from 'actions/get-report';

const INITIAL_STATE = {};
const INITIAL_STATE_MLS = {
  status: STATUSES.INIT
};

const _updateStateFromFarm = (state, farm) => {
  const updatedState = {};
  (farm || []).forEach((comp, i) => {
    const { hcMlsId } = comp;
    if (hcMlsId) {
      if (!updatedState[hcMlsId]) {
        if (state[hcMlsId]) {
          updatedState[hcMlsId] = { ...state[hcMlsId] };
        } else {
          updatedState[hcMlsId] = { ...INITIAL_STATE_MLS };
        }
      }
    }
  });
  return updatedState;
};

export const mlsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EFFECTIVE_DATE_GET_REPORT_SUCCESS:
    case GET_REPORT_USER_VERSION_SUCCESS: {
      return _updateStateFromFarm(state, action.payload.report.compsFarmList);
    }

    case COMPS_FARM_UPDATE_SUCCESS: {
      return _updateStateFromFarm(state, action.payload.farm);
    }

    case COMPS_REFRESH_READY_FOR_RECOMPUTE: {
      return _updateStateFromFarm(state, action.payload.userCompsFarmList);
    }

    case MLS_FETCH: {
      return {
        ...state,
        [action.payload.mlsId]: {
          ...state[action.payload.mlsId],
          status: STATUSES.LOADING
        }
      };
    }

    case MLS_FETCH_SUCCESS: {
      return {
        ...state,
        [action.payload.mlsId]: {
          data: action.payload.mlsLookup,
          status: STATUSES.SUCCESS
        }
      };
    }

    case MLS_FETCH_FAILURE: {
      return {
        ...state,
        [action.payload.mlsId]: {
          ...state[action.payload.mlsId],
          status: STATUSES.ERROR
        }
      };
    }

    default: {
      return state;
    }
  }
};
