import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './PropertyCard.css';

interface Props {
  dataHcName: string;
  photos: ReactNode;
  content: ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}
export const PropertyCard = ({
  dataHcName,
  photos,
  content,
  className,
  style,
  onClick,
  onMouseEnter,
  onMouseLeave
}: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.PropertyCard, className)}
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div data-hc-name={`${dataHcName}-photos`} className={styles.Photos}>
        {photos}
      </div>
      <div data-hc-name={`${dataHcName}-content`} className={styles.Content}>
        {content}
      </div>
    </div>
  );
};
