import React from 'react';
import { formatMoney } from 'src/lib/utils';

import styles from './ValueSelectorItem.css';

interface Props {
  title: string;
  children?: React.ReactNode;
  className?: string;
  dataHcName: string;
  value?: number | null;
}

export const ValueSelectorItem = ({
  title,
  dataHcName,
  value,
  children,
  className
}: Props) => {
  return (
    <div data-hc-name={dataHcName} className={className}>
      <div className={styles.Title} data-hc-name={`${dataHcName}-title`}>
        {title}
      </div>
      <div className={styles.Content}>
        {value && (
          <div className={styles.Value} data-hc-name={`${dataHcName}-value`}>
            {formatMoney(value)}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
