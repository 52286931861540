import { createSelector } from 'reselect';

export function getSubscriptionDetails(state) {
  return state.subscriptionDetails || {};
}

export function getSubscription(state) {
  return getSubscriptionDetails(state).subscription || {};
}

export function getZuoraData(state) {
  return getSubscription(state).zuoraData || {};
}

export function getProductRatePlanId(state) {
  return getZuoraData(state).productRatePlanId;
}

export function getRatePlanName(state) {
  return getZuoraData(state).ratePlanName;
}

export function getIsModelPerUnit(state) {
  return getZuoraData(state).model === 'PerUnit';
}

export const getProductName = createSelector(getZuoraData, (zuoraData) => {
  return zuoraData.productName;
});

export const getIsNewPropertyExplorerPricingModel = createSelector(
  getProductName,
  (productName) => {
    return productName === 'HouseCanary Dynamic Web Value Report';
  }
);
