import { call } from 'redux-saga/effects';
import graphqlRequest from 'legacy/services/graphql';

import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

export const propertyQuery = ({
  addressSlug,
  streetAddress,
  zipcode,
  unit
}) => {
  const queryArgs = [];
  if (addressSlug) {
    queryArgs.push(`addressSlug: "${addressSlug}"`);
  }
  if (streetAddress) {
    queryArgs.push(`streetAddress: "${streetAddress}"`);
  }
  if (zipcode) {
    queryArgs.push(`zipcode: "${zipcode}"`);
  }
  if (unit) {
    queryArgs.push(`unit: "${unit}"`);
  }
  return {
    key: QUERIES.REPORT,
    query: `
      property(${queryArgs.join(', ')}) {
        streetAddress
        zipcode
        unit
        unitDesignator
        addressSlug
        isReal
        isComplete
        isVerified
        apn
        city
        state
        countyFips
        grossLivingAreaSqft
        siteAreaSqft
        yearBuilt
        age
        bedrooms
        bathrooms
        propertyType
      }
    `
  };
};

const fetchProperty = function* ({ streetAddress, zipcode, unit }) {
  const query = propertyQuery({ streetAddress, zipcode, unit });
  return yield call(graphqlRequest, {
    source: PROPERTY_DATA_SOURCES.HC,
    query
  });
};

export default fetchProperty;
