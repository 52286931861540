import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import {
  AVM_KEY_HC,
  AVM_KEY_USER,
  AVM_KEY_COMPS,
  AVM_KEY_COMPS_AVG,
  AVM_KEY_RENTAL_COMPS,
  AVM_KEY_RENTAL_COMPS_AVG,
  AVM_KEY_USER_ENTERED,
  AVM_KEY_RENTAL_USER_ENTERED,
  COMP_TYPE_DEFAULT,
  COMP_TYPE_RENTAL,
  MAP_ID_BY_COMP_TYPE,
  RENTAL_COMP_EXCEL_LIMIT
} from 'legacy/appstore/constants';

import {
  getAvmSelectedKeyForJson,
  getAvmHc,
  getAvmUser,
  getAvmComps,
  getAvmCompsAvg,
  getAvmRentalComps,
  getAvmRentalCompsAvg,
  getAvmUserEntered,
  getAvmRentalUserEntered,
  getAvmRentalSelectedKeyForJson,
  getAvmSelectedConfidenceScoreKeyForJson
} from 'selectors/avms';
import {
  getCompsFarmListRaw,
  getCompsSelectedIds,
  getCompsSelected,
  getCompsFiltersAppliedForMetadata,
  getCompsFiltersUpdatedFromUser,
  getCompsActiveListingsProperties,
  getCompsSuggestedProperties,
  getCompsRecentSimilarProperties,
  getCompsHistoricalSimilarProperties,
  getCompsFarmRefreshedAt,
  getCompsTags
} from 'selectors/comps';
import { getNearbyProperties } from 'selectors/nearby-properties';
import { getSubjectDefault } from 'selectors/subject';
import {
  getReportActiveMapLayerIds,
  getForecastChartAreaType,
  getReportComments
} from 'selectors/property-explorer';
import {
  getReportChartDataMarketAnalysis,
  getReportUserRevisionId,
  getReportDataSelected
} from 'selectors/report-data';
import {
  getRentalCompsSelectedIds,
  getRentalCompsFarmListRaw,
  getRentalCompsSelectedRaw,
  getRentalCompsSelectedMappingRaw,
  getRentalCompsFarm,
  getRentalCompsFiltersAppliedForMetadata,
  getRentalCompsFiltersUpdatedFromUser,
  getRentalCompsActiveListingsProperties,
  getRentalCompsSelected,
  getRentalCompsSuggestedProperties,
  getRentalCompsRecentSimilarProperties,
  getRentalCompsFarmRefreshedAt
} from 'selectors/rental-comps';
import { getMapDrawPolygons } from 'selectors/map-draw';
import { getPropertyAllUserEdits } from 'selectors/property';
import { getReportChartDataNearbyProperties } from 'selectors/chart-data';

// Keys to omit from the API request payload
const FRONT_END_KEYS = ['userRevisionId'];

export const getReportJson = createSelector(
  getAvmSelectedKeyForJson,
  getAvmHc,
  getAvmUser,
  getAvmComps,
  getAvmCompsAvg,
  getAvmUserEntered,
  getAvmRentalComps,
  getAvmRentalCompsAvg,
  getAvmRentalUserEntered,
  getSubjectDefault,
  getCompsFarmListRaw,
  getReportUserRevisionId,
  getReportDataSelected,
  // metadata
  getCompsSelectedIds,
  getAvmSelectedConfidenceScoreKeyForJson,
  getRentalCompsSelectedIds,
  getRentalCompsSelectedMappingRaw,
  getForecastChartAreaType,
  getAvmRentalSelectedKeyForJson,
  getReportComments,
  getReportActiveMapLayerIds,
  getMapDrawPolygons(MAP_ID_BY_COMP_TYPE[COMP_TYPE_DEFAULT]),
  getMapDrawPolygons(MAP_ID_BY_COMP_TYPE[COMP_TYPE_RENTAL]),
  getCompsFiltersAppliedForMetadata,
  getRentalCompsFiltersAppliedForMetadata,
  getCompsFiltersUpdatedFromUser,
  getRentalCompsFiltersUpdatedFromUser,
  getRentalCompsFarm,
  getPropertyAllUserEdits,
  getNearbyProperties,
  getCompsRecentSimilarProperties,
  getCompsHistoricalSimilarProperties,
  getCompsActiveListingsProperties,
  getCompsFarmRefreshedAt,
  getRentalCompsFarmRefreshedAt,
  getCompsTags,
  (
    avmSelectedKey,
    avmHc,
    avmUser,
    avmComps,
    avmCompsAvg,
    avmUserEntered,
    avmRentalComps,
    avmRentalCompsAvg,
    avmRentalUserEntered,
    subject,
    compsFarmList,
    revisionId,
    reportDataShared,
    // metadata
    selectedCompsByAddressId,
    selectedConfidenceScore,
    selectedRentalCompsByAddressId,
    selectedRentalComps,
    forecastChartAreaType,
    selectedRentalAvm,
    comments,
    activeMapLayers,
    compFarmAreaPolygons,
    rentalCompFarmAreaPolygons,
    activeFiltersComps,
    activeFiltersRentalComps,
    compsFiltersUpdatedByUser,
    rentalCompsFiltersUpdatedByUser,
    rentalCompFarm,
    propertyEdits,
    nearbyProperties,
    recentSimilarComps,
    historicalSimilarComps,
    activeListings,
    compFarmRefreshedAt,
    rentalCompFarmRefreshedAt,
    compTags
  ) => {
    const { productName, productBrandImages, ...reportData } = reportDataShared;
    const reportJson = {
      [AVM_KEY_HC]: avmHc,
      [AVM_KEY_USER]: avmUser,
      [AVM_KEY_COMPS]: avmComps,
      [AVM_KEY_COMPS_AVG]: avmCompsAvg,
      [AVM_KEY_RENTAL_COMPS]: avmRentalComps,
      [AVM_KEY_RENTAL_COMPS_AVG]: avmRentalCompsAvg,
      [AVM_KEY_USER_ENTERED]: avmUserEntered,
      [AVM_KEY_RENTAL_USER_ENTERED]: avmRentalUserEntered,
      subject,
      compsFarmList,
      revisionId,
      activeListings,
      nearbyProperties,
      recentSimilarComps,
      historicalSimilarComps,
      ...reportData,
      metadata: {
        compTags: Object.entries(compTags),
        productName,
        productBrandImages,
        selectedAvm: avmSelectedKey,
        selectedConfidenceScore,
        selectedCompsByAddressId,
        selectedRentalCompsByAddressId,
        selectedRentalComps,
        forecastChartAreaType,
        selectedRentalAvm,
        comments,
        activeMapLayers,
        compFarmAreaPolygons,
        activeFiltersComps,
        activeFiltersRentalComps,
        compsFiltersUpdatedByUser,
        rentalCompsFiltersUpdatedByUser,
        rentalCompFarmAreaPolygons,
        rentalCompFarm,
        propertyEdits,
        compFarmRefreshedAt,
        rentalCompFarmRefreshedAt
      }
    };

    if (isEmpty(reportJson[AVM_KEY_USER])) {
      delete reportJson[AVM_KEY_USER];
    }
    if (isEmpty(reportJson[AVM_KEY_COMPS])) {
      delete reportJson[AVM_KEY_COMPS];
    }

    return omit(reportJson, FRONT_END_KEYS);
  }
);

// Replaces similarity attributes w/ new adjusted values
const _replaceSimilarityExcel = (compFull) => {
  const {
    similarityLevel,
    similarityScore,
    similarityLevelAdjusted,
    similarityScoreAdjusted,
    ...comp
  } = compFull;
  return {
    ...comp,
    similarityLevel: similarityLevelAdjusted || similarityLevel,
    similarityScore: similarityScoreAdjusted || similarityScore
  };
};

const _deleteFields = (comp, fields) => {
  let formatted = {};
  for (let field in comp) {
    if (!fields[field]) {
      formatted[field] = comp[field];
    }
  }
  return formatted;
};

const _formatCompForExcel = (comp) => {
  const FIELDS_TO_DELETE = {
    thumbnail: true
  };
  return _replaceSimilarityExcel(_deleteFields(comp, FIELDS_TO_DELETE));
};

const _formatRentalCompForExcel = (comp) => {
  comp.askingRent = comp.lastListPrice;
  const FIELDS_TO_DELETE = {
    currentValue: true,
    salesPrice: true,
    salesDate: true,
    salesPriceAdjusted: true,
    lastListPrice: true,
    lastListPriceRental: true,
    isRentalComp: true,
    uiPrice: true,
    uiPriceLabel: true,
    thumbnail: true
  };
  return _replaceSimilarityExcel(_deleteFields(comp, FIELDS_TO_DELETE));
};

export const getReportJsonForExcel = (state) => {
  let reportJson = getReportJson(state);
  const userOpinionOfPriceKeysToDelete = [
    'valuationSuitabilityScore',
    'valuationSuitabilityScoreDesc'
  ];
  let keysToDelete = [
    'userVersionUpdatedAt',
    'userRevisionId',
    'revisionId',
    'metadata'
  ];

  reportJson.selectedComps = getCompsSelected(state).map((c) =>
    _formatCompForExcel(c)
  );

  const rentalCompsFarmList = getRentalCompsFarmListRaw(state);
  if (rentalCompsFarmList && rentalCompsFarmList.length) {
    reportJson.rentalCompFarmList = rentalCompsFarmList
      .slice(0, RENTAL_COMP_EXCEL_LIMIT)
      .map((c) => _formatRentalCompForExcel(c));
  }
  const rentalCompsSelected = getRentalCompsSelectedRaw(state);
  if (rentalCompsSelected && rentalCompsSelected.length) {
    reportJson.selectedRentalComps = rentalCompsSelected.map((c) =>
      _formatRentalCompForExcel(c)
    );
  }
  // Find empty keys
  for (let key in reportJson) {
    if (isEmpty(reportJson[key])) {
      keysToDelete.push(key);
    }
  }
  // Delete empty keys
  keysToDelete.forEach((key) => {
    delete reportJson[key];
  });

  if (reportJson.compsFarmList) {
    reportJson.compsFarmList = reportJson.compsFarmList.map(
      _replaceSimilarityExcel
    );
  }

  if (reportJson.recentSimilarComps) {
    reportJson.recentSimilarComps = reportJson.recentSimilarComps.map(
      _replaceSimilarityExcel
    );
  }

  if (reportJson.historicalSimilarComps) {
    reportJson.historicalSimilarComps = reportJson.historicalSimilarComps.map(
      _replaceSimilarityExcel
    );
  }

  // Rename keys
  if (reportJson[AVM_KEY_RENTAL_USER_ENTERED]) {
    reportJson.userOpinionOfRentalPrice =
      reportJson[AVM_KEY_RENTAL_USER_ENTERED];
    delete reportJson[AVM_KEY_RENTAL_USER_ENTERED];
  }
  if (reportJson[AVM_KEY_USER_ENTERED]) {
    reportJson.userOpinionOfPrice = reportJson[AVM_KEY_USER_ENTERED];
    userOpinionOfPriceKeysToDelete.forEach((key) => {
      if (reportJson.userOpinionOfPrice) {
        delete reportJson.userOpinionOfPrice[key];
      }
      if (reportJson.userOpinionOfRentalPrice) {
        delete reportJson.userOpinionOfRentalPrice[key];
      }
    });
    delete reportJson[AVM_KEY_USER_ENTERED];
  }

  // adding comments to the root level of the object only for excel
  const comments = getReportComments(state);
  if (comments) {
    reportJson.comments = comments;
  }

  const chartData = getReportChartDataNearbyProperties(state);
  return {
    ...reportJson,
    ...chartData
  };
};

export const getReportJsonSectionExcel = (state, sectionName) => {
  let jsonBlob;
  switch (sectionName) {
    case 'comps':
      const selectedComps = getCompsSelected(state).map(
        _replaceSimilarityExcel
      );
      const selectedRentalComps = getRentalCompsSelected(state).map(
        _replaceSimilarityExcel
      );
      jsonBlob = {
        name: 'Comparable Properties.xlsx',
        newSheetPerKey: true,
        data: {
          ...(selectedComps.length > 0 && { selectedComps: selectedComps }),
          suggestedComps: getCompsSuggestedProperties(state).map(
            _replaceSimilarityExcel
          ),
          activeListings: getCompsActiveListingsProperties(state).map(
            _replaceSimilarityExcel
          ),
          recentSimilarSales: getCompsRecentSimilarProperties(state).map(
            _replaceSimilarityExcel
          ),
          ...(selectedRentalComps.length > 0 && {
            selectedRentalComps: selectedRentalComps
          }),
          suggestedRentalComps: getRentalCompsSuggestedProperties(state).map(
            _replaceSimilarityExcel
          ),
          activeRentalListings: getRentalCompsActiveListingsProperties(
            state
          ).map(_replaceSimilarityExcel),
          recentSimilarLeases: getRentalCompsRecentSimilarProperties(state).map(
            _replaceSimilarityExcel
          )
        }
      };
      return jsonBlob;

    case 'marketAnalysis':
      jsonBlob = {
        name: 'Market Analysis.xlsx',
        newSheetPerKey: true,
        data: getReportChartDataMarketAnalysis(state)
      };
      return jsonBlob;
    default:
      return {};
  }
};
