export const LOAD_FULL_REPORT = 'LOAD_FULL_REPORT';
export const LOAD_FULL_REPORT_EXISTING = 'LOAD_FULL_REPORT_EXISTING';

export const loadFullReport = (property = {}, listingParams = {}) => ({
  type: LOAD_FULL_REPORT,
  payload: { property, listingParams }
});

export const loadFullReportExisting = (property = {}) => ({
  type: LOAD_FULL_REPORT_EXISTING,
  payload: { property }
});
