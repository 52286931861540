import { call, put } from 'redux-saga/effects';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

import { VIEWS } from 'legacy/routes/constants';
import { EVENTS } from 'legacy/appstore/events';

import { singleSignOn } from 'sagas/auth';

import { analyticsEvent } from 'actions/analytics';

export default function* routeHome({ view, params, query }) {
  if (query && query.signUpSrc) {
    put(
      analyticsEvent(EVENTS.AUTH_SIGN_UP_COMPLETE, {
        src: query.signUpSrc
      })
    );
  } else if (query && query.loginSrc) {
    put(
      analyticsEvent(EVENTS.AUTH_LOGIN, {
        src: query.loginSrc
      })
    );
  }
  const isLoggedIn = yield call(singleSignOn);
  if (isLoggedIn) {
    yield put(routeChange({ view: VIEWS.SEARCH, options: { inherit: false } }));
  } else {
    yield put(routeChange({ view: VIEWS.LOGIN, options: { inherit: false } }));
  }
}
