import classNames from 'classnames';
import { dollarsFormatter } from 'legacy/utils/formatters';

import { TransactionAppreciation as theme } from 'legacy/css-modules';

const NULL_VALS = [undefined, null, ''];

const TransactionAppreciation = ({ price, percent }) => {
  if (NULL_VALS.indexOf(price) === -1 && NULL_VALS.indexOf(percent) === -1) {
    return [
      <span
        key="pct"
        className={classNames(theme.Percent, {
          [theme.positive]: price > 0,
          [theme.negative]: price < 0
        })}
      >
        {Math.round(percent * 100)}%
      </span>,
      <span key="price" className={theme.Price}>
        ({dollarsFormatter(price)})
      </span>
    ];
  } else {
    return '--';
  }
};

export default TransactionAppreciation;
