import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getMqSmallMediumAndUp } from 'selectors/match-media';

const mapStateToProps = (state, ownProps) => ({
  isMobile: !getMqSmallMediumAndUp(state)
});

const modalWithIOSInputFix = (ModalComponent) => {
  class ModalComponentWithInputFix extends PureComponent {
    componentWillReceiveProps(nextProps) {
      // TODO: Remove this dirty hack when ios fixes the cursor position in fixed modal bug.
      if (
        this.props.isMobile &&
        ((!this.props.open && nextProps.open) ||
          (!this.props.isOpen && nextProps.isOpen))
      ) {
        document.getElementsByTagName('body')[0].style.position = 'fixed';
      } else if (
        (this.props.open && !nextProps.open) ||
        (this.props.isOpen && !nextProps.isOpen)
      ) {
        document.getElementsByTagName('body')[0].style.position = 'static';
      }
    }

    render() {
      return <ModalComponent {...this.props} />;
    }
  }
  return connect(mapStateToProps)(ModalComponentWithInputFix);
};

export default modalWithIOSInputFix;
