import { useSelector } from 'react-redux';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import { getReportDataEffectiveDate } from 'selectors/report-data';
import RadioSelect from 'legacy/components/RadioSelect';
import { getDatesAgo } from 'legacy/utils/dates';

const FilterDateAgo = ({
  absoluteValue,
  subjectValue,
  relativeValue,
  filterKey,
  onChangeAbsolute,
  ...inputProps
}) => {
  const isEffectiveDateReport = useSelector(getIsEffectiveDateReport);
  const effectiveDate = useSelector(getReportDataEffectiveDate);
  const options = isEffectiveDateReport
    ? getDatesAgo(2, effectiveDate)
    : getDatesAgo(2);
  return (
    <RadioSelect
      {...inputProps}
      options={options}
      value={absoluteValue}
      subjectValue={subjectValue}
      onChange={onChangeAbsolute}
      filterKey={filterKey}
    />
  );
};

export default FilterDateAgo;
