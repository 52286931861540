import isEmpty from 'lodash/isEmpty';
import { all, call, put, select } from 'redux-saga/effects';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

import { ERROR_TEXT } from 'legacy/appstore/constants';
import HC_CONSTANTS from 'HC_CONSTANTS';
import { VIEWS } from 'legacy/routes/constants';

import { fetchSubjectDetails } from 'sagas/report-property-details';

import reportSchemaService from 'legacy/services/report-schema';

import apiUtil from 'legacy/utils/api';
import { watchLatest } from 'legacy/utils/saga';

import {
  GET_REPORT_SHARED,
  getReportHcVersionSuccess,
  getReportUserVersionSuccess,
  getReportUserVersionNotFound,
  getReportHcVersionFailure,
  getReportNearbyPropertiesSuccess
} from 'actions/get-report';
import { uniqueLinkFetchSuccess } from 'actions/share-report';
import { getCurrentUser } from 'selectors/account-details';
import { getCurrentReportParams } from 'selectors/property-explorer';

export function* downloadSharedPropertyExplorer(reportParams) {
  return yield call(
    apiUtil.GET,
    `${HC_CONSTANTS.VRSHARE_PROXY_URL}/emporium/vrshare`,
    reportParams
  );
}

export function* fetchSharedReport() {
  let sharedPropertyExplorer;
  const params = yield select(getCurrentReportParams);
  let { publicKey, referrer, token, ...reportParams } = params;
  // Change `streetAddress` to `address` to suppor the shared report api
  // TODO: Make the api accept either param
  reportParams.address = reportParams.streetAddress;
  try {
    sharedPropertyExplorer = yield call(
      downloadSharedPropertyExplorer,
      reportParams
    );
    const scrubDataArgs = {
      state: sharedPropertyExplorer?.hcVersion?.subject?.state,
      orgId: sharedPropertyExplorer?.propertyLookup?.organizationId
    };
    // Run reports through the schema service to update old reports
    sharedPropertyExplorer = {
      ...sharedPropertyExplorer,
      userVersion: reportSchemaService(
        sharedPropertyExplorer.userVersion,
        scrubDataArgs
      ),
      hcVersion: reportSchemaService(
        sharedPropertyExplorer.hcVersion,
        scrubDataArgs
      )
    };
  } catch (e) {
    if (e.statusCode === 404) {
      yield put(
        routeChange({ view: VIEWS.SEARCH, options: { inherit: false } })
      );
      yield put(
        getReportHcVersionFailure({ message: ERROR_TEXT.VALUE_REPORT.notFound })
      );
      // Redis lock error: prompt user to refresh the page
    } else if (e.statusCode === 409) {
      yield put(
        getReportHcVersionFailure({
          message: ERROR_TEXT.VALUE_REPORT.refreshRequired
        })
      );
    } else {
      yield put(getReportHcVersionFailure());
    }
    // Rethrow for Sentry reporting
    // Should we *not* report the above failure cases to Sentry since they're somewhat expected?
    throw e;
  }

  const {
    uniqueLink: uniqueLinkData,
    userVersion,
    propertyLookup,
    cobranding,
    subscriptionType
  } = sharedPropertyExplorer;

  const hcVersion = {
    ...sharedPropertyExplorer.hcVersion,
    propertyLookup: {
      ...propertyLookup
    },
    cobranding: {
      ...cobranding
    }
  };

  const user = yield select(getCurrentUser);
  yield put(
    getReportHcVersionSuccess(
      hcVersion,
      true,
      user.email && user.email === propertyLookup.userEmail,
      subscriptionType
    )
  );

  if (uniqueLinkData && !isEmpty(uniqueLinkData)) {
    yield put(uniqueLinkFetchSuccess(uniqueLinkData));
  }

  if (userVersion && !isEmpty(userVersion)) {
    yield put(getReportUserVersionSuccess(userVersion));
  } else {
    yield put(getReportUserVersionNotFound(true));
  }

  if (hcVersion.compsFarmList) {
    yield put(getReportNearbyPropertiesSuccess(hcVersion.compsFarmList));
  }
}

function* handleGetReportShared(action) {
  yield all([call(fetchSubjectDetails), call(fetchSharedReport)]);
}

export default function registerSharedPropertyExplorerSaga() {
  watchLatest(GET_REPORT_SHARED, handleGetReportShared);
}
