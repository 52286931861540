import { VIEWS } from 'legacy/routes/constants';
import loginRequiredFn from 'legacy/routes/not-authorized';
import { singleSignOn } from 'sagas/auth';
import Loading from 'legacy/components/generic/Loading';

export default {
  error404Redirect: { view: VIEWS.HOME },
  logoutRequiredRedirect: { view: VIEWS.SEARCH },
  isLoggedInFn: singleSignOn,
  ComponentLoading: () => <Loading loading fullPage />,
  loginRequiredFn
};
