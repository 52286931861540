import classNames from 'classnames';

import { capitalizeFirstLetter } from 'legacy/utils/strings';

import SimilarityLevel from 'legacy/components/SimilarityLevel';

import { SimilarityScoreTableCell as theme } from 'legacy/css-modules';

const SimilarityScoreTableCell = ({
  similarityLevel,
  similarityScore,
  className,
  levelOnly
}) => {
  if (levelOnly && similarityLevel) {
    return <span>{capitalizeFirstLetter(similarityLevel)}</span>;
  }
  if (similarityLevel) {
    return (
      <div className={classNames(theme.SimilarityScoreTableCell, className)}>
        <div className={theme.SimilarityScore}>{similarityScore}</div>
        <SimilarityLevel similarityLevel={similarityLevel} />
      </div>
    );
  } else {
    return '--';
  }
};

export default SimilarityScoreTableCell;
