import React from 'react';
import classNames from 'classnames';
import styles from './Details.css';
import { formatCityStateZip, formatStreetAddress } from 'src/lib/utils';

interface PartialAddress {
  // This is the PropertyLookup.Address type with some specific fields optional
  streetAddress: string | null;
  unit: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  slug?: string;
  zipcodePlus4?: string | null;
  fullAddress?: string | null;
  hcAddressId?: number;
}
interface Props {
  dataHcName: string;
  address: PartialAddress;
  attributes?: string[];
  hasKeywordMatch?: boolean;
}

export const Details = ({
  dataHcName,
  address,
  attributes,
  hasKeywordMatch
}: Props) => {
  return (
    <div data-hc-name={dataHcName} className={classNames(styles.Details)}>
      <div
        className={classNames(styles.Address, {
          [styles.keywordMatch || '']: hasKeywordMatch
        })}
      >
        <div data-hc-name={`${dataHcName}-address`}>
          {formatStreetAddress(address)}
        </div>
        <div data-hc-name={`${dataHcName}-citystatezip`}>
          {!!address.city && formatCityStateZip(address)}
        </div>
      </div>
      {attributes && (
        <div data-hc-name={dataHcName} className={styles.Attributes}>
          {attributes.map((attribute, i) => (
            <div
              key={`${dataHcName}-${i}`}
              data-hc-name={`${dataHcName}-${i}`}
              className={styles.Attribute}
            >
              {attribute}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
