import { connect } from 'react-redux';

import { getFocusedCompId } from 'selectors/comps';
import { compsFocusToggle } from 'actions/comps';

import { rentalCompsFocusToggle } from 'actions/rental-comps';
import { getRentalFocusedCompId } from 'selectors/rental-comps';

import { COMP_TYPE_DEFAULT } from 'legacy/appstore/constants';

import PropertyDetailsCompDialog from 'legacy/components/PropertyDetailsCompDialog';
import modalWithIOSInputFix from 'legacy/hocs/modalWithIOSInputFix';

const compSelectorsDefault = {
  getFocused: getFocusedCompId,
  getTitle: () => 'Subject & Comp Comparison'
};

const compActionsDefault = {
  focusToggle: compsFocusToggle
};

const compSelectorsRental = {
  getFocused: getRentalFocusedCompId,
  getTitle: () => 'Subject & Rental Comp Comparison'
};

const compActionsRental = {
  focusToggle: rentalCompsFocusToggle
};

const mapStateToProps = (state, ownProps) => {
  const { compType } = ownProps;
  const compSelectors =
    compType === COMP_TYPE_DEFAULT ? compSelectorsDefault : compSelectorsRental;
  return {
    active: !!compSelectors.getFocused(state),
    title: compSelectors.getTitle(),
    compType
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { compType = COMP_TYPE_DEFAULT } = ownProps;
  const compActions =
    compType === COMP_TYPE_DEFAULT ? compActionsDefault : compActionsRental;
  return {
    onClose: () => dispatch(compActions.focusToggle())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(modalWithIOSInputFix(PropertyDetailsCompDialog));
