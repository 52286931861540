import PropTypes from 'prop-types';
import NumberField from 'legacy/components/generic/NumberField';

const formatFunc = (value) => (!value ? '' : value);

const normalizeFunc = (value) => {
  if (value && value !== '0') {
    let formatted = value.replace(/[^\d.]/g, '');
    const decIndex = formatted.indexOf('.');
    if (decIndex > -1 && decIndex + 1 !== formatted.length) {
      // Ensure value is whole or half number only
      const valFloat = parseFloat(formatted);
      formatted = valFloat.toString();
      if (!(valFloat % 1)) {
        formatted += '.0';
      }
    }
    return formatted;
  } else {
    return null;
  }
};

const BathroomsField = (props) => {
  return (
    <NumberField
      {...props}
      format={formatFunc}
      normalize={normalizeFunc}
      maxLength={4}
      type="text"
    />
  );
};

BathroomsField.propTypes = {
  name: PropTypes.string.isRequired
};

export default BathroomsField;
