import React from 'react';

import { PLACEHOLDER } from 'legacy/utils/formatters.utils';

import SimilarityLevel from 'legacy/components/SimilarityLevel';

import { SimilarityScoreTableCell as theme } from 'legacy/css-modules';

const TableCellSimilarity = (props) => {
  const { row } = props;
  if (!row || !row.data || !row.data.similarity) {
    return PLACEHOLDER;
  }
  const { similarityScoreAdjusted, similarityLevelAdjusted } =
    row.data.similarity;
  return (
    <div className={theme.SimilarityScoreTableCell}>
      <SimilarityLevel similarityLevel={similarityLevelAdjusted} />
      <div className={theme.SimilarityScore}>{similarityScoreAdjusted}</div>
    </div>
  );
};

export default TableCellSimilarity;
