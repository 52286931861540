import { createSelector } from 'reselect';
import { getCurrentView } from '@hc/redux-saga-router-plus/hclib/selectors';

import { COMP_SELECTION_VIEWS } from 'legacy/routes/constants';

import { isVrView } from 'legacy/utils/routes';

export const getIsCurrentViewVr = createSelector(
  getCurrentView,
  (currentView) => isVrView(currentView)
);

export const getIsCurrentViewCompsSelectionPage = createSelector(
  getCurrentView,
  (currentView) => COMP_SELECTION_VIEWS.indexOf(currentView) > -1
);
