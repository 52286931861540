import { GET_REPORT_FORECAST_SUCCESS } from 'actions/get-report';

const INITIAL_STATE = {
  hpi: {
    blockTs: [],
    zipTs: []
  }
};

export default function hcModelsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_REPORT_FORECAST_SUCCESS: {
      const { hpi } = action.payload;
      return {
        ...state,
        hpi: {
          blockTs: hpi.blockTs || [],
          zipTs: hpi.zipTs || []
        }
      };
    }

    default:
      return state;
  }
}
