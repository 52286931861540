import get from 'lodash/get';
import forEach from 'lodash/forEach';

import {
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_HC_VERSION_SUCCESS
} from 'actions/get-report';
import { SAVE_REPORT_SUCCESS } from 'actions/edit-report';

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_HC_VERSION_SUCCESS:
      return action.payload.report.id;

    case GET_REPORT_USER_VERSION_SUCCESS:
      return action.payload.report.id;

    case SAVE_REPORT_SUCCESS:
      return action.payload.id;

    default:
      return state;
  }
};
