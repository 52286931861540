import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { ID_FIXED_PORTAL } from 'src/constants';
import { AvmSelectorItem } from './AvmSelectorItem';
import { CompBasedAvmSelectorItem } from './CompBasedAvmSelectorItem';
import { AdjustedCompBasedAvgSelectorItem } from './AdjustedCompBasedAvgSelectorItem';
import { UserValueSelectorItem } from './UserValueSelectorItem';
import { useDocumentRole } from '../../../report-api';
import { AvmDocument, DocumentRoles } from '../../../report-api/types';
import { useClickOutsideComponent } from '../../../hooks/useClickOutsideComponent';
import { ValueSelector } from '../../../components/ValueSelector/ValueSelector';
import { AvmType } from '../../../report-api/constants';

import styles from './ReportValueSelector.css';

interface Props {
  reportId: number;
  dataHcName: string;
  onClickSelectComps: VoidFunction;
}

export const ReportValueSelector = ({
  reportId,
  dataHcName,
  onClickSelectComps
}: Props) => {
  const [open, setOpen] = useState(false);
  const {
    data: [avmDocument]
  } = useDocumentRole<AvmDocument>(reportId, DocumentRoles.Avm);
  const refContainer = useRef(null);
  const elm = document.getElementById(ID_FIXED_PORTAL);

  if (avmDocument?.role !== DocumentRoles.Avm) {
    return <div>Loading...</div>;
  }
  const selected = avmDocument.data.selected;

  useClickOutsideComponent(refContainer, () => setOpen(false));
  if (!elm) return null;

  return (
    <div data-hc-name={dataHcName}>
      <ValueSelector
        dataHcName={`${dataHcName}-value-selector`}
        onClick={() => setOpen(!open)}
      />
      {open &&
        createPortal(
          <div
            className={styles.Popover}
            ref={refContainer}
            data-hc-name={`${dataHcName}-popover`}
          >
            <div className={styles.PopoverAccent} />
            <div className={styles.AVMSelectorItem}>
              {selected !== AvmType.HouseCanary && (
                <AvmSelectorItem
                  dataHcName={`${dataHcName}-avm-selector`}
                  reportId={reportId}
                  value={avmDocument.data.avm.priceMean}
                  onClose={() => setOpen(false)}
                />
              )}
              {selected !== AvmType.CompAvm && (
                <CompBasedAvmSelectorItem
                  dataHcName={`${dataHcName}-comp-selector`}
                  reportId={reportId}
                  value={avmDocument.data.compBasedAvm.priceMean}
                  onClose={() => setOpen(false)}
                  onClickSelectComps={onClickSelectComps}
                />
              )}
              {selected !== AvmType.CompMarket && (
                <AdjustedCompBasedAvgSelectorItem
                  dataHcName={`${dataHcName}-comp-avg-selector`}
                  reportId={reportId}
                  value={avmDocument.data.adjustedCompBasedAvg.priceMean}
                  onClose={() => setOpen(false)}
                  onClickSelectComps={onClickSelectComps}
                />
              )}
              <UserValueSelectorItem
                dataHcName={`${dataHcName}-user-value-selector`}
                maxVal={avmDocument.data.avm.priceUpper}
                minVal={avmDocument.data.avm.priceLower}
                reportId={reportId}
                documentId={avmDocument.documentId}
                value={avmDocument.data.userValue.priceMean}
                onClose={() => setOpen(false)}
                avmSelected={selected}
              />
            </div>
          </div>,
          elm
        )}
    </div>
  );
};
