import { takeEvery, call, put } from '@redux-saga/core/effects';
import { AxiosError, AxiosResponse } from 'axios';

import { StreetViewApi } from '../api';

import {
  StreetViewMetadataFetchAction,
  streetViewMetadataFetchSuccess,
  streetViewMetadataFetchError,
  STREET_VIEW_METADATA_FETCH
} from '../actions/streetView.actions';

function* handleStreetViewFetch(action: StreetViewMetadataFetchAction) {
  const { geoLocation } = action.payload;
  try {
    const response: AxiosResponse = yield call(
      StreetViewApi.fetchStreetView,
      geoLocation
    );
    yield put(streetViewMetadataFetchSuccess(geoLocation, response.data));
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status !== 404) {
        console.log('[TODO Report Error To Sentry');
      }
      yield put(streetViewMetadataFetchError(geoLocation));
    }
  }
}

export function* streetViewSagas() {
  yield takeEvery(STREET_VIEW_METADATA_FETCH, handleStreetViewFetch);
}
