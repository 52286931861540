import React, { ReactElement, useMemo, useState } from 'react';
import { Marker } from 'react-map-gl';
import { CompMarker } from 'src/lib/components/MapMarkers/CompMarker';
import { Toggle } from 'src/lib/components/Toggle';
import { formatNumberAbbrev } from 'src/lib/utils';
import { getMarketPrice } from 'src/lib/utils/listingStatus';
import { useDocumentRole } from '../../hooks';
import { FilteredDocument, FarmDocument, DocumentRoles } from '../../types';
import { CompSchema } from '../../types/schemas';
import { Cluster } from './Cluster';
import styles from './CompMarkers.css';
import { useCompDocIdsByCompId } from 'src/lib/report-api/hooks/useCompDocIdsByCompId';

interface Props {
  reportId: number;
  clustersEnabled: boolean;
  dataHcName: string;
  onClickMarker?: (compSchema: CompSchema) => void;
  onChangeClusterMarkers?: (v: boolean) => void;
}

export const CompMarkers = ({
  reportId,
  dataHcName,
  onClickMarker,
  clustersEnabled,
  onChangeClusterMarkers
}: Props) => {
  const [clustersEnabledLocal, setClustersEnabledLocal] =
    useState(clustersEnabled);

  const compDocIdsByCompId = useCompDocIdsByCompId(reportId);
  const {
    data: [filteredDocument]
  } = useDocumentRole<FilteredDocument>(reportId, DocumentRoles.Filtered);

  const {
    data: [farmDocument]
  } = useDocumentRole<FarmDocument>(reportId, DocumentRoles.Farm);

  const markers = useMemo(() => {
    const results: ReactElement[] = [];
    filteredDocument?.data.forEach((compId, i) => {
      const compSchema = farmDocument?.data[compId];
      const { latitude, longitude } = compSchema?.geoLocation || {};
      if (compSchema && latitude && longitude) {
        const selected = !!compDocIdsByCompId[compSchema.compID];
        if (!selected) {
          results.push(
            <Marker
              key={`comp-marker-${i}`}
              longitude={longitude}
              latitude={latitude}
              className={styles.Marker}
            >
              <CompMarker
                dataHcName={`${dataHcName}-${i}`}
                listingStatusData={compSchema.latestListing.listingStatus}
                selected={selected}
                onClick={() => {
                  onClickMarker?.(compSchema);
                }}
                hoverable
              >
                {formatNumberAbbrev(
                  getMarketPrice(compSchema.latestListing.listingStatus)
                )}
              </CompMarker>
            </Marker>
          );
        }
      }
    });
    return results;
  }, [compDocIdsByCompId, filteredDocument]);
  return (
    <>
      {clustersEnabledLocal ? <Cluster>{markers}</Cluster> : markers}
      <Toggle
        dataHcName={`${dataHcName}-cluster-toggle`}
        className={styles.ClusterToggle}
        options={[
          {
            label: 'Cluster On',
            value: true
          },
          {
            label: 'Cluster Off',
            value: false
          }
        ]}
        value={clustersEnabledLocal}
        onChange={(v) => {
          setClustersEnabledLocal(v);
          onChangeClusterMarkers?.(v);
        }}
      />
    </>
  );
};
