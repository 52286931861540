import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import styles from './PhotosPage.css';
import classNames from 'classnames';
import { PhotosByListing } from 'src/lib/property-lookup/features/PhotosByListing';
import { Size } from 'src/lib/components/PhotoGrid/types';
import { useComponentDidMount } from 'src/lib/hooks/useComponentDidMount';
import { BackButton } from 'src/pexp/features/BackToReportLink/BackButton';
import { LimitType } from 'src/lib/property-lookup';

interface Props {
  hcAddressId: number;
  reportId: number;
  header: React.ReactNode;
  onClose?: VoidFunction;
  active?: boolean;
  className?: string;
  limit?: LimitType;
  dataHcName: string;
  onChangeSizeComplete?: (size: Size) => void;
}

export const PhotosPage = ({
  className,
  active,
  onClose,
  dataHcName,
  limit,
  header,
  hcAddressId,
  onChangeSizeComplete
}: Props) => {
  const leaveAppWithBackButtonListener = () => {
    onClose?.();
    return null;
  };

  const browserNavigationButtonListener = () => {
    onClose?.();
  };

  useComponentDidMount(() => {
    window.addEventListener('popstate', browserNavigationButtonListener);
    window.addEventListener('beforeunload', leaveAppWithBackButtonListener);
    console.log('listener');
    // On unmount remove listener
    return () => {
      window.removeEventListener('popstate', browserNavigationButtonListener);
      window.removeEventListener(
        'beforeunload',
        leaveAppWithBackButtonListener
      );
    };
  });

  const closePhotoPage: React.MouseEventHandler<HTMLDivElement> = (event) => {
    onClose?.();
    event.stopPropagation();
    window.history.back();
  };

  return (
    <ReactCSSTransitionGroup
      transitionName={{
        enter: styles.enter || '',
        enterActive: styles.enterActive || '',
        leave: styles.leave || '',
        leaveActive: styles.leaveActive
      }}
      transitionEnterTimeout={500}
      transitionLeaveTimeout={400}
      transitionEnter
      transitionLeave
    >
      <>
        {active === true && (
          <div
            data-hc-name={`${dataHcName}-photos-page`}
            className={classNames(className, styles.PhotosPage)}
          >
            <header className={styles.Header}>
              <div onClick={closePhotoPage}>
                <BackButton
                  dataHcName={`${dataHcName}-back`}
                  className={styles.BackButton}
                />
              </div>
              {header}
            </header>
            <PhotosByListing
              onChangeSizeComplete={onChangeSizeComplete}
              dataHcName={`${dataHcName}-listing`}
              hcAddressId={hcAddressId}
              limit={limit}
            />
          </div>
        )}
      </>
    </ReactCSSTransitionGroup>
  );
};
