import React from 'react';
import { Dialog } from 'src/lib/components/Dialog';
import { SubjectComment, SubjectCommentIcon } from '.';
import { IconButton } from 'src/lib/components/IconButton';
import { useDialogActiveState } from 'src/lib/hooks/useDialogActiveState';

interface Props {
  reportId: number;
  dataHcName: string;
}
export const SubjectCommentDialog = ({ reportId, dataHcName }: Props) => {
  const { active, handleDialogOpen, handleDialogClose } =
    useDialogActiveState();
  return (
    <>
      <IconButton
        dataHcName={`${dataHcName}-button`}
        onClick={handleDialogOpen}
        icon={<SubjectCommentIcon reportId={reportId} />}
        label="Comment"
      />
      <Dialog
        dataHcName={`${dataHcName}-dialog`}
        active={active}
        onClose={handleDialogClose}
        title="Comment on this report"
      >
        <SubjectComment
          reportId={reportId}
          dataHcName={`${dataHcName}-comment`}
          onSubmit={handleDialogClose}
        />
      </Dialog>
    </>
  );
};
