import moment from 'moment';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';

import {
  ARCHIVED_REPORTS_FETCH_REQUEST,
  ARCHIVED_REPORTS_FETCH_SUCCESS,
  ARCHIVED_REPORTS_SEARCH,
  ARCHIVED_REPORTS_OPEN_MODAL,
  ARCHIVED_REPORTS_CLOSE_MODAL
} from 'actions/archived-reports';
import { GET_REPORT_HC_VERSION_SUCCESS } from 'actions/get-report';
import {
  getArchivedReportsFromReportsState,
  getArchivedReportsSearchQueryFromReportsState
} from 'selectors/archived-reports';
import { STATUSES } from 'legacy/appstore/constants';
import {
  fullAddressStringForProperty,
  findPropertyInList
} from 'legacy/utils/property-details';

const INITIAL_STATE = {
  reports: {},
  filteredIds: [],
  status: STATUSES.INIT,
  searchQuery: '',
  open: false
};

const sortFunc = (lookup) => lookup.purchaseDatetime;
const sortOrder = 'desc';

/**
 * Perform filtering, searching, and sorting of property lookup objects
 * @param  {object} property lookups returned by API
 * @param  {query} search query input by user
 * @return {array} ids representing property lookups to display to user
 */
const filterSearchSort = (lookups, query) => {
  const filtered = values(lookups).filter((o) => {
    // SECURITY: Cannot use literal. This is safe because it only concatinates property attributes @jnettleman
    // eslint-disable-next-line security/detect-non-literal-regexp
    const regex = new RegExp(query, 'i');
    return regex.test(fullAddressStringForProperty(o));
  });
  const sorted = orderBy(filtered, sortFunc, sortOrder);
  return sorted.map((o) => o.id);
};

export default (state = INITIAL_STATE, action) => {
  let searchQuery;
  switch (action.type) {
    case ARCHIVED_REPORTS_OPEN_MODAL:
      // Support opening the modal with a property pre-filtered
      searchQuery = action.payload.property
        ? fullAddressStringForProperty(action.payload.property)
        : INITIAL_STATE.searchQuery;
      return {
        ...state,
        searchQuery,
        open: true,
        filteredIds: filterSearchSort(
          getArchivedReportsFromReportsState(state),
          searchQuery
        )
      };
    case ARCHIVED_REPORTS_FETCH_REQUEST:
      return {
        ...state,
        status: STATUSES.LOADING
      };
    case ARCHIVED_REPORTS_FETCH_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        reports: action.payload.lookups
          ? keyBy(action.payload.lookups, 'id')
          : INITIAL_STATE.reports,
        filteredIds: action.payload.lookups
          ? filterSearchSort(
              action.payload.lookups,
              getArchivedReportsSearchQueryFromReportsState(state)
            )
          : INITIAL_STATE.filteredIds
      };
    case ARCHIVED_REPORTS_SEARCH:
      return {
        ...state,
        searchQuery: action.payload.query,
        filteredIds: filterSearchSort(
          getArchivedReportsFromReportsState(state),
          action.payload.query
        )
      };
    case ARCHIVED_REPORTS_CLOSE_MODAL:
      return {
        ...state,
        open: INITIAL_STATE.open,
        searchQuery: INITIAL_STATE.searchQuery
      };

    case GET_REPORT_HC_VERSION_SUCCESS:
      // Don't add shared reports to reports list
      if (action.payload.isSharedReport) {
        return state;
      }
      const updatedReports = { ...state.reports };
      const newPropertyLookup = action.payload.report.propertyLookup;
      const newEffectiveDate = action.payload.report.effectiveDate;
      const existingProperty = findPropertyInList(
        newPropertyLookup,
        values(state.reports)
      );
      const newReport = {
        id: newPropertyLookup.id,
        purchaseDate: newEffectiveDate,
        validUntilDate: moment(newEffectiveDate)
          .add(30, 'd')
          .format('YYYY-MM-DD'),
        downloadId: newPropertyLookup.downloadId,
        isActive: true
      };

      let isNewReport = true;
      get(existingProperty, 'reports', []).forEach((report) => {
        if (report.downloadId === newReport.downloadId) {
          isNewReport = false;
        }
      });

      if (!isNewReport) {
        return state;
      }

      // If no matching property exists in the list, add the new property and add the report
      if (!existingProperty) {
        updatedReports[newPropertyLookup.id] = {
          ...newPropertyLookup,
          isActive: true,
          reports: [newReport]
        };
        // Otherwise, add the new report to the existing property and update the dates of the lookup
      } else {
        updatedReports[existingProperty.id] = {
          ...updatedReports[existingProperty.id],
          isActive: true,
          expirationDatetime: newPropertyLookup.expirationDatetime,
          purchaseDatetime: newPropertyLookup.purchaseDatetime,
          reports: [...updatedReports[existingProperty.id].reports, newReport]
        };
      }
      return {
        ...state,
        reports: updatedReports,
        filteredIds: filterSearchSort(updatedReports, INITIAL_STATE.searchQuery)
      };

    default:
      return state;
  }
};
