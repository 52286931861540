import React from 'react';
import { useDispatch } from 'react-redux';
import { SelectButton } from 'src/lib/components/SelectButton';
import { documentDelete } from '../../actions';
import { compSelect } from '../../actions/reportActions';
import { CompId } from '../../types/schemas';

interface Props {
  dataHcName: string;
  reportId: number;
  // One of documentId or hcAddressId is required
  documentId?: string;
  compId?: CompId;
  className?: string;
  onClick?: VoidFunction;
}

export const CompSelectButton = ({
  dataHcName,
  reportId,
  documentId,
  compId,
  className,
  onClick
}: Props) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (documentId) {
      dispatch(documentDelete(reportId, documentId));
    } else if (compId) {
      dispatch(compSelect(reportId, compId));
    }
    onClick?.();
  };
  return (
    <SelectButton
      className={className}
      selected={!!documentId}
      dataHcName={dataHcName}
      onClick={handleClick}
    />
  );
};
