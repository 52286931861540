import { select } from 'redux-saga/effects';

import { watchEvery } from 'legacy/utils/saga';

import history from 'legacy/routes/history';

import { NAV_CONFIRM_MSG_SAVING } from 'legacy/appstore/constants';

import {
  BROWSER_NAV_CONFIRM_ADD,
  BROWSER_NAV_CONFIRM_REMOVE
} from 'actions/browser';
import {
  SAVE_REPORT,
  VR_RECOMPUTE,
  VALUE_REPORT_EXECUTE_SAVE,
  SAVE_REPORT_COMPLETE
} from 'actions/edit-report';
import {
  COMPS_ADJUST_SAVE,
  COMPS_SORT_SELECTED,
  COMPS_CLEAR_ALL_FILTERS,
  COMPS_CLEAR_FILTER,
  COMPS_FILTER_DISTANCE_CHANGE,
  COMPS_MAP_DRAW_SUBMIT
} from 'actions/comps';
import {
  RENTAL_COMPS_ADJUST_SAVE,
  RENTAL_COMPS_SORT_SELECTED,
  RENTAL_COMPS_CLEAR_ALL_FILTERS,
  RENTAL_COMPS_CLEAR_FILTER,
  RENTAL_COMPS_FILTER_DISTANCE_CHANGE,
  RENTAL_COMPS_MAP_DRAW_SUBMIT,
  RENTAL_COMPS_LOAD_FARM_FOR_DEFAULT_DISTANCE
} from 'actions/rental-comps';
import { SUBJECT_UPDATE_GEO_LOCATION } from 'actions/subject';
import {
  EFFECTIVE_DATE_GET_REPORT_SUCCESS,
  EFFECTIVE_DATE_CREATE_REPORT_FAILURE,
  EFFECTIVE_DATE_ERROR_CTA,
  EFFECTIVE_DATE_GET_REPORT_FAILURE
} from 'actions/effective-date';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import { getIsReportSaveable } from 'selectors/report-permissions';

let unblock;

function navConfirmCallback(e) {
  // Note that some browsers show their own text in the modal in lieu of this text
  if (e) {
    e.returnValue = NAV_CONFIRM_MSG_SAVING;
  }
}

export function* navConfirmAdd(action) {
  const isEffectiveDateReport = yield select(getIsEffectiveDateReport);
  const reportIsSavable = yield select(getIsReportSaveable);
  if (!isEffectiveDateReport && !unblock && reportIsSavable) {
    window.addEventListener('beforeunload', navConfirmCallback);
    unblock = history.block(() => navConfirmCallback());
  }
}

// window.removeEventListener('beforeunload', this.onBeforeUnload);

export function* navConfirmRemove(action) {
  window.removeEventListener('beforeunload', navConfirmCallback);
  if (unblock) {
    unblock();
    unblock = undefined;
  }
}

export default function registerBrowserNavConfirmSaga() {
  watchEvery(
    [
      SAVE_REPORT,
      BROWSER_NAV_CONFIRM_ADD,
      VR_RECOMPUTE,
      COMPS_ADJUST_SAVE,
      RENTAL_COMPS_ADJUST_SAVE,
      COMPS_SORT_SELECTED,
      RENTAL_COMPS_SORT_SELECTED,
      COMPS_CLEAR_ALL_FILTERS,
      COMPS_CLEAR_FILTER,
      COMPS_FILTER_DISTANCE_CHANGE,
      COMPS_MAP_DRAW_SUBMIT,
      RENTAL_COMPS_FILTER_DISTANCE_CHANGE,
      RENTAL_COMPS_MAP_DRAW_SUBMIT,
      RENTAL_COMPS_LOAD_FARM_FOR_DEFAULT_DISTANCE,
      RENTAL_COMPS_CLEAR_ALL_FILTERS,
      RENTAL_COMPS_CLEAR_FILTER,
      VALUE_REPORT_EXECUTE_SAVE,
      SUBJECT_UPDATE_GEO_LOCATION
    ],
    navConfirmAdd
  );
  watchEvery(
    [
      EFFECTIVE_DATE_GET_REPORT_SUCCESS,
      EFFECTIVE_DATE_GET_REPORT_FAILURE,
      EFFECTIVE_DATE_CREATE_REPORT_FAILURE,
      EFFECTIVE_DATE_ERROR_CTA,
      BROWSER_NAV_CONFIRM_REMOVE,
      SAVE_REPORT_COMPLETE
    ],
    navConfirmRemove
  );
}
