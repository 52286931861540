import {
  PERSONALIZATION_DATA_LOADING,
  PERSONALIZATION_DATA_SUCCESS,
  PERSONALIZATION_DATA_FAILURE,
  PERSONALIZATION_UPLOAD_IMG,
  PERSONALIZATION_UPLOAD_IMG_SUCCESS,
  PERSONALIZATION_UPLOAD_IMG_FAILURE,
  PERSONALIZATION_DATA_UPDATE,
  PERSONALIZATION_DATA_UPDATE_SUCCESS,
  PERSONALIZATION_PROMPT_MODAL_SHOW,
  PERSONALIZATION_PROMPT_MODAL_HIDE,
  PERSONALIZATION_MODAL_OPEN,
  PERSONALIZATION_MODAL_CLOSE
} from 'actions/personalization';

import { STATUSES } from 'legacy/appstore/constants';

const INITIAL_STATE = {
  status: STATUSES.INIT,
  message: null,
  promptIsOpen: false,
  formModalOpen: false,
  altActionType: null,
  upload: {},
  values: {}
};

export default function personalizationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PERSONALIZATION_DATA_LOADING:
      return {
        ...state,
        status: STATUSES.LOADING
      };

    case PERSONALIZATION_DATA_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        values: {
          ...state.values,
          ...action.payload.values
        }
      };

    case PERSONALIZATION_DATA_FAILURE:
      return {
        ...state,
        status: action.payload.doesNotExist
          ? STATUSES.DOES_NOT_EXIST
          : STATUSES.ERROR,
        message: action.payload.message,
        values: {
          ...state.values,
          ...action.payload.values
        }
      };

    case PERSONALIZATION_UPLOAD_IMG:
      return {
        ...state,
        upload: {
          ...state.upload,
          [action.payload.fieldName]: {
            status: STATUSES.LOADING,
            filename: action.payload.img.name,
            filesize: action.payload.img.size,
            filetype: action.payload.img.type
          }
        }
      };

    case PERSONALIZATION_UPLOAD_IMG_SUCCESS:
      return {
        ...state,
        upload: {
          ...state.upload,
          [action.payload.fieldName]: {
            ...state.upload[action.payload.fieldName],
            status: STATUSES.SUCCESS,
            message: null
          }
        }
      };

    case PERSONALIZATION_UPLOAD_IMG_FAILURE:
      return {
        ...state,
        upload: {
          ...state.upload,
          [action.payload.fieldName]: {
            ...state.upload[action.payload.fieldName],
            status: STATUSES.ERROR,
            message: action.payload.errorMessage
          }
        }
      };

    case PERSONALIZATION_DATA_UPDATE:
      return {
        ...state,
        status: STATUSES.LOADING
      };

    case PERSONALIZATION_DATA_UPDATE_SUCCESS:
      return {
        status: STATUSES.SUCCESS,
        formModalOpen: false,
        values: {
          ...action.payload.values
        }
      };

    case PERSONALIZATION_PROMPT_MODAL_SHOW:
      return {
        ...state,
        promptIsOpen: true,
        altActionType: action.payload.altActionType
      };

    case PERSONALIZATION_PROMPT_MODAL_HIDE:
      return {
        ...state,
        promptIsOpen: false,
        altActionType: null
      };

    case PERSONALIZATION_MODAL_OPEN:
      return {
        ...state,
        formModalOpen: true
      };

    case PERSONALIZATION_MODAL_CLOSE:
      return {
        ...state,
        formModalOpen: false
      };

    default:
      return state;
  }
}
