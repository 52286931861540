import { useListings, LimitType } from './useListings';
export type { LimitType } from './useListings';

export const usePhotos = (hcAddressId: number, limit?: LimitType) => {
  const { loading, error, data } = useListings(hcAddressId, limit);
  return {
    loading,
    error,
    data: data?.map((listing) => listing.photos) || []
  };
};
