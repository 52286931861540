import { Fragment } from 'react';
// import { Link } from '@hc/redux-saga-router-plus';
import { Button } from 'legacy/components/Button';

import { noop } from 'legacy/utils/utils';

import RadioSelect from 'legacy/components/RadioSelect';

import { DistanceControl as theme } from 'legacy/css-modules';

// {'type':'Feature','properties':{'radius':941.3085551804318,'radius_unit':'meters'},'geometry':{'type':'Point','coordinates':[-118.366379,33.797472]}}

const DistanceControl = ({
  options,
  disabled,
  drawOnly,
  value,
  onChange,
  onClickDraw
}) => {
  let isOptionValue = false;
  let isCustomRadiusValue = false;

  // Check if value, 0.5, 1, 2, etc matches a dropdown options (it may be a custom circle)
  if (options.find((o) => o.value === value)) {
    isOptionValue = true;
  } else if (typeof value === 'number') {
    isCustomRadiusValue = true;
  }

  const displayValue = isCustomRadiusValue ? 'Circle' : value;

  return (
    <div className={theme.DistanceControl} data-hc-name="distance-control">
      {!drawOnly && (
        <Fragment>
          <RadioSelect
            disabled={disabled}
            value={value}
            options={options}
            onChange={onChange}
          />
          <div className={theme.Or}>or</div>
        </Fragment>
      )}
      <Button
        disabled={disabled}
        onClick={onClickDraw}
        className={theme.Button}
        primary={false}
        dataHcName="distance-draw-button"
      >
        {isOptionValue ? 'Draw Custom Area' : `Edit Custom ${displayValue}`}
      </Button>
    </div>
  );
};

DistanceControl.defaultProps = {
  onChange: noop
};

export default DistanceControl;
