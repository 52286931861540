import { daysBetweenDates } from 'legacy/utils/dates';

/**
 * Determines if the given listings are equal. Listings are considered
 * equal if the events (listed, sold, etc) are the same, the listing dates
 * are equal (see listDateIsEqual), and the listing prices are equal (see listPriceIsEqual).
 * Note: logic behind this code has been borrowed from the Appraisal app.
 * @param listing1
 * @param listing2
 * @returns {boolean}
 */
export const listingsAreEqualish = (listing1, listing2) => {
  return (
    listEventIsEqualish(listing1['event'], listing2['event']) &&
    listDateIsEqualish(
      listing1['dateUnformatted'],
      listing2['dateUnformatted']
    ) &&
    listPriceIsEqualish(
      listing1['priceUnformatted'],
      listing2['priceUnformatted']
    )
  );
};

/**
 * Determines if the given listing events are equal.
 * @param listEvent1
 * @param listEvent2
 * @returns {boolean}
 */
const listEventIsEqualish = (listEvent1, listEvent2) => {
  return listEvent1.toLowerCase() === listEvent2.toLowerCase();
};

/**
 * Determines if the given listing dates are equal. List dates are considered
 * equal if they are within 30 days of each other or both None.
 * @param listDate1
 * @param listDate2
 * @returns {boolean}
 */
const listDateIsEqualish = (listDate1, listDate2) => {
  return listDate1 && listDate2
    ? daysBetweenDates(listDate1, listDate2) < 30
    : !(listDate1 || listDate2);
};

/**
 * Determines if the given listing prices are equal. List prices are considered
 * equal if they are within 1% of each other or both None.
 * @param listPrice1
 * @param listPrice2
 * @returns {boolean}
 */
const listPriceIsEqualish = (listPrice1, listPrice2) => {
  return listPrice1 && listPrice2
    ? Math.abs(listPrice1 - listPrice2) <=
        Math.min(listPrice1, listPrice2) * 0.01
    : !(listPrice1 || listPrice2);
};
