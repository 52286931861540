import {
  SEARCH_BY_MLS_NUMBER_SUBMIT,
  SEARCH_BY_MLS_NUMBER_SELECT_DUPLICATE_ADDRESS,
  SEARCH_BY_MLS_NUMBER_SELECT_CANCEL,
  SEARCH_BY_MLS_NUMBER_SUCCESS,
  SEARCH_BY_MLS_NUMBER_FAILURE,
  SEARCH_BY_MLS_NUMBER_SELECT_SUCCESS,
  SEARCH_BY_MLS_NUMBER_SELECT_FAILURE
} from 'actions/search-by-mls-number';
import { STATUSES } from 'legacy/appstore/constants';

const INITIAL_STATE = {
  input: null,
  status: STATUSES.INIT,
  data: null,
  listingToConfirm: null
};

export function searchByMlsNumberReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEARCH_BY_MLS_NUMBER_SUBMIT: {
      return {
        ...state,
        status: STATUSES.LOADING
      };
    }

    case SEARCH_BY_MLS_NUMBER_SUCCESS: {
      return {
        ...state,
        status: STATUSES.SUCCESS,
        input: action.payload.input,
        data: action.payload.data
      };
    }

    case SEARCH_BY_MLS_NUMBER_FAILURE: {
      return {
        ...state,
        status: STATUSES.ERROR,
        input: null,
        data: null,
        listingToConfirm: null
      };
    }

    case SEARCH_BY_MLS_NUMBER_SELECT_DUPLICATE_ADDRESS: {
      return {
        ...state,
        listingToConfirm: action.payload.listing
      };
    }

    case SEARCH_BY_MLS_NUMBER_SELECT_CANCEL: {
      return {
        ...state,
        listingToConfirm: null
      };
    }

    case SEARCH_BY_MLS_NUMBER_SELECT_SUCCESS:
    case SEARCH_BY_MLS_NUMBER_SELECT_FAILURE: {
      // A toast is shown to the user from action.meta.toast
      return {
        ...state,
        status: STATUSES.INIT,
        input: null,
        data: null,
        listingToConfirm: null
      };
    }

    default:
      return state;
  }
}
