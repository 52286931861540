import { isEqual } from 'lodash';
import React, { KeyboardEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'src/lib/components/Input/Input';
import { keywordSearchSubmit } from '../../actions';
import { selKeywordSearchInputs } from '../../selectors';
import { KeywordListingInput } from '../../types';

interface Props {
  dataHcName: string;
  listingsInput: KeywordListingInput[];
  className?: string;
  noPadding?: boolean;
}

const _formatValue = (value: string) => {
  const words: string[] = [];
  value.split(',').forEach((w) => {
    // Done this way to prevent empty strings
    // and to avoid multiple loops
    const word = w.trim();
    if (word) {
      words.push(word);
    }
  });
  return words;
};

export const KeywordSearchByListings = ({
  listingsInput,
  dataHcName,
  className,
  noPadding
}: Props) => {
  const dispatch = useDispatch();
  const appliedInputs = useSelector(selKeywordSearchInputs);
  const [value, setValue] = useState(appliedInputs?.keywords.join(', ') || '');
  const handleSubmit = () => {
    const newKeywords = _formatValue(value);
    if (!isEqual(appliedInputs?.keywords, newKeywords)) {
      dispatch(
        keywordSearchSubmit({
          keywords: newKeywords,
          listings: listingsInput,
          match_remarks_public: true,
          match_remarks_private: false,
          return_remarks: false
        })
      );
    }
  };
  const onKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  return (
    <Input
      dataHcName={dataHcName}
      className={className}
      value={value}
      onChange={setValue}
      placeholder="Keyword Search"
      onKeyUp={onKeyUp}
      noPadding={noPadding}
    />
  );
};
