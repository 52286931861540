import { STATUSES } from 'legacy/appstore/constants';
import {
  ORG_REPORTS_FETCH_PEXP,
  ORG_REPORTS_FETCH_PEXP_SUCCESS,
  ORG_REPORTS_FETCH_PEXP_FAILURE,
  ORG_REPORTS_FETCH_EFFECTIVE_DATE,
  ORG_REPORTS_FETCH_EFFECTIVE_DATE_SUCCESS,
  ORG_REPORTS_FETCH_EFFECTIVE_DATE_FAILURE
} from 'actions/org-reports';
import { PROPERTY_SEARCH_SUCCESS } from 'actions/property-search';

const INITIAL_STATE = {
  pexp: {
    status: STATUSES.INIT,
    addressId: null,
    data: []
  },
  effectiveDate: {
    status: STATUSES.INIT,
    addressId: null,
    data: []
  }
};

export const orgReportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ORG_REPORTS_FETCH_PEXP: {
      return {
        ...state,
        pexp: {
          status: STATUSES.LOADING,
          addressId: action.payload.addressId,
          data: []
        }
      };
    }

    case ORG_REPORTS_FETCH_PEXP_SUCCESS: {
      return {
        ...state,
        pexp: {
          ...state.pexp,
          status: STATUSES.SUCCESS,
          data: action.payload.reports
        }
      };
    }

    case ORG_REPORTS_FETCH_PEXP_FAILURE: {
      return {
        ...state,
        pexp: {
          ...state.pexp,
          status: STATUSES.ERROR
        }
      };
    }

    case ORG_REPORTS_FETCH_EFFECTIVE_DATE: {
      return {
        ...state,
        effectiveDate: {
          status: STATUSES.LOADING,
          addressId: action.payload.addressId,
          data: []
        }
      };
    }

    case ORG_REPORTS_FETCH_EFFECTIVE_DATE_SUCCESS: {
      return {
        ...state,
        effectiveDate: {
          ...state.effectiveDate,
          status: STATUSES.SUCCESS,
          data: action.payload.reports
        }
      };
    }

    case ORG_REPORTS_FETCH_EFFECTIVE_DATE_FAILURE: {
      return {
        ...state,
        effectiveDate: {
          ...state.effectiveDate,
          status: STATUSES.ERROR
        }
      };
    }

    case PROPERTY_SEARCH_SUCCESS: {
      return { ...INITIAL_STATE };
    }

    default: {
      return state;
    }
  }
};
