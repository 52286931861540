import { createSelector } from 'reselect';
import { getCurrentView } from '@hc/redux-saga-router-plus/hclib/selectors';
import { ROUTES_TO_HIDE_HEADER } from 'legacy/routes/constants';

export function getAppClickEvent(state) {
  return state.appHeaderToggleComponents;
}

export function getIsShowingMobileSearch(state) {
  return getAppClickEvent(state).isShowingMobileSearch;
}

export function getIsShowingNavbarSearch(state) {
  return getAppClickEvent(state).isShowingNavbarSearch;
}

export function getShowContactSupport(state) {
  return getAppClickEvent(state).showContactSupport;
}

export function getShowShareDropDown(state) {
  return getAppClickEvent(state).showShareDropDown;
}

export const getIsShowingAppHeader = createSelector(
  getCurrentView,
  (currentView) => ROUTES_TO_HIDE_HEADER.indexOf(currentView) === -1
);
