import { connect } from 'react-redux';

import { getFocusedCompId } from 'selectors/comps';
import { getRentalFocusedCompId } from 'selectors/rental-comps';

import { COMP_TYPE_DEFAULT } from 'legacy/appstore/constants';

import PropertyCompareCompsContainer from 'legacy/containers/property-compare-comps';

const compSelectorsDefault = {
  getFocused: getFocusedCompId
};

const compSelectorsRental = {
  getFocused: getRentalFocusedCompId
};

const mapStateToProps = (state, ownProps) => {
  const { compType } = ownProps;
  const compSelectors =
    compType === COMP_TYPE_DEFAULT ? compSelectorsDefault : compSelectorsRental;
  return {
    addressIds: [compSelectors.getFocused(state)],
    compType
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyCompareCompsContainer);
