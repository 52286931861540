import { AxiosResponse, AxiosError } from 'axios';
import {
  all,
  actionChannel,
  take,
  put,
  call,
  select,
  takeEvery
} from 'redux-saga/effects';
import { CompDocument, Document } from '../types';
import {
  DocumentRoleFetchAction,
  documentRoleFetchSuccess,
  DOCUMENT_ROLE_FETCH,
  DOCUMENT_PATCH,
  DocumentPatchAction,
  documentPatchSuccess,
  DOCUMENT_ROLE_REQUIRED,
  DocumentRoleRequiredAction,
  documentRoleFetch,
  DocumentRequiredAction,
  documentFetch,
  DOCUMENT_FETCH,
  DocumentFetchAction,
  documentFetchSuccess,
  DOCUMENT_REQUIRED,
  DOCUMENT_DELETE,
  DocumentDeleteAction,
  documentDeleteSuccess,
  documentsCreateSuccess
} from '../actions';
import { ReportApi } from '../api';
// TODO: REMOVE THIS IMPORT
// @ts-ignore: Importing from untyped legacy directory
import { getToken } from 'selectors/account-details';
import { selDocumentRoleStatus, selDocumentStatus } from '../selectors';
import { STATUSES } from 'src/constants';
import { CompSelectAction, COMP_SELECT } from '../actions/reportActions';

export function* handleDocumentRoleFetch(action: DocumentRoleFetchAction) {
  const { reportId, documentRole } = action.payload;
  try {
    const token: string = yield select(getToken);
    const response: AxiosResponse<Document[]> = yield call(
      ReportApi.fetchDocumentRole,
      token,
      reportId,
      documentRole
    );
    yield put(documentRoleFetchSuccess(reportId, documentRole, response.data));
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status !== 404) {
        console.log('[TODO] Report Error To Sentry');
      }
      // yield put(reportFetchError(action.payload.reportId, error.response.data.message));
    } else if (error.request) {
      // yield put(reportFetchError(action.payload.reportId, error.request.data.message));
    }
  }
}

export function* handleDocumentFetch(action: DocumentFetchAction) {
  const { reportId, documentId } = action.payload;
  try {
    const token: string = yield select(getToken);
    const response: AxiosResponse<Document> = yield call(
      ReportApi.fetchDocument,
      token,
      reportId,
      documentId
    );
    yield put(documentFetchSuccess(reportId, documentId, response.data));
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status !== 404) {
        console.log('[TODO] Report Error To Sentry');
      }
      // yield put(reportFetchError(action.payload.reportId, error.response.data.message));
    } else if (error.request) {
      // yield put(reportFetchError(action.payload.reportId, error.request.data.message));
    }
  }
}

export function* handleDocumentPatch(action: DocumentPatchAction) {
  const { reportId, documentId, operations } = action.payload;
  try {
    const token: string = yield select(getToken);
    const response: AxiosResponse<Document[]> = yield call(
      ReportApi.patchDocument,
      token,
      reportId,
      documentId,
      operations
    );
    yield put(documentPatchSuccess(reportId, documentId, response.data));
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status !== 404) {
        console.log('[TODO] Report Error To Sentry');
      }
      // yield put(reportFetchError(action.payload.reportId, error.response.data.message));
    } else if (error.request) {
      // yield put(reportFetchError(action.payload.reportId, error.request.data.message));
    }
  }
}

function* handleDocumentDelete(action: DocumentDeleteAction) {
  const { reportId, documentId } = action.payload;
  try {
    const token: string = yield select(getToken);
    const response: AxiosResponse<Document> = yield call(
      ReportApi.deleteDocument,
      token,
      reportId,
      documentId
    );
    if (response.status === 204) {
      yield put(documentDeleteSuccess(reportId, documentId));
    }
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status !== 404) {
        console.log('[TODO] Report Error To Sentry');
      }
      // yield put(reportFetchError(action.payload.reportId, error.response.data.message));
    } else if (error.request) {
      // yield put(reportFetchError(action.payload.reportId, error.request.data.message));
    }
  }
}

function* handleCompSelect(action: CompSelectAction) {
  const { reportId, compId } = action.payload;
  try {
    const token: string = yield select(getToken);
    const response: AxiosResponse<CompDocument[]> = yield call(
      ReportApi.selectComps,
      token,
      reportId,
      [compId]
    );
    yield put(documentsCreateSuccess(reportId, response.data));
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status !== 404) {
        console.log('[TODO] Report Error To Sentry');
      }
      // yield put(reportFetchError(action.payload.reportId, error.response.data.message));
    } else if (error.request) {
      // yield put(reportFetchError(action.payload.reportId, error.request.data.message));
    }
  }
}

function* takeDocumentRoleRequired() {
  const requiredChannel: typeof DOCUMENT_ROLE_REQUIRED = yield actionChannel(
    DOCUMENT_ROLE_REQUIRED
  );
  while (true) {
    const action: DocumentRoleRequiredAction =
      yield take<DocumentRoleRequiredAction>(requiredChannel);
    const { reportId, documentRole } = action.payload;
    const status: STATUSES | undefined = yield select(
      selDocumentRoleStatus([reportId, documentRole])
    );
    if (!status) {
      yield put(documentRoleFetch(reportId, documentRole));
    }
  }
}

function* takeDocumentRequired() {
  const requiredChannel: typeof DOCUMENT_REQUIRED = yield actionChannel(
    DOCUMENT_REQUIRED
  );
  while (true) {
    const action: DocumentRequiredAction = yield take<DocumentRequiredAction>(
      requiredChannel
    );
    const { reportId, documentId } = action.payload;
    const status: STATUSES | undefined = yield select(
      selDocumentStatus([reportId, documentId])
    );
    if (!status) {
      yield put(documentFetch(reportId, documentId));
    }
  }
}

export function* documentSagas() {
  yield takeEvery(DOCUMENT_ROLE_FETCH, handleDocumentRoleFetch);
  yield takeEvery(DOCUMENT_FETCH, handleDocumentFetch);
  yield takeEvery(DOCUMENT_PATCH, handleDocumentPatch);
  yield takeEvery(DOCUMENT_DELETE, handleDocumentDelete);
  yield takeEvery(COMP_SELECT, handleCompSelect);
  yield all([call(takeDocumentRoleRequired), call(takeDocumentRequired)]);
}
