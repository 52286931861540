import React from 'react';
import { useDispatch } from 'react-redux';
import { ValueSelectorItem } from 'src/lib/components/ValueSelector/ValueSelectorItem';
import { ValueSelectorButton } from 'src/lib/components/ValueSelector/ValueSelectorButton';
import { documentPatch } from 'src/lib/report-api/actions';
import { useDocumentRole } from '../../hooks';
import { AvmDocument, DocumentRoles } from '../../types';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { AvmTypeLabel } from '../../constants';

import styles from './ReportValueSelector.css';

interface Props {
  dataHcName: string;
  reportId: number;
  value: number | null;
  onClose: VoidFunction;
}

export const AvmSelectorItem = ({
  dataHcName,
  reportId,
  value,
  onClose
}: Props) => {
  const dispatch = useDispatch();
  const {
    data: [avmDocument]
  } = useDocumentRole<AvmDocument>(reportId, DocumentRoles.Avm);

  if (avmDocument?.role !== DocumentRoles.Avm) {
    return <LoadingSpinner dataHcName={dataHcName} containerHeight="380px" />;
  }

  const handleSelect = () => {
    dispatch(
      documentPatch(reportId, avmDocument.documentId, [
        { op: 'replace', path: '/data/selected', value: 'avm' }
      ])
    );
    onClose();
  };

  return (
    <ValueSelectorItem
      title={AvmTypeLabel.avm}
      dataHcName={dataHcName}
      value={value}
      className={styles.AVMSelectionItem}
    >
      HouseCanary’s Estimated Value
      <ValueSelectorButton
        dataHcName={`${dataHcName}-value-selector-button`}
        onClick={handleSelect}
      >
        Use For Report
      </ValueSelectorButton>
    </ValueSelectorItem>
  );
};
