import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getReportDataEffectiveDate } from 'selectors/report-data';
import { displayDateCompactFullYear } from 'legacy/utils/dates';

import { EffectiveDateWarning as styles } from 'legacy/css-modules';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';

export const EffectiveDateWarning = ({
  dataHcName = 'effective-date-tag',
  className,
  today
}) => {
  const effectiveDate = useSelector(getReportDataEffectiveDate);
  const isEffectiveDate = useSelector(getIsEffectiveDateReport);
  if (!isEffectiveDate) return null;
  return (
    <div
      className={classNames(styles.EffectiveDateWarning, className)}
      data-hc-name={dataHcName}
    >
      <label data-hc-name={`${dataHcName}-label`} className={styles.Title}>
        Effective Date:
      </label>
      <span data-hc-name={`${dataHcName}-value`} className={styles.Value}>
        {today ? 'Today' : displayDateCompactFullYear(effectiveDate)}
      </span>
    </div>
  );
};
