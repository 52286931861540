import { report } from 'hc-ravenjs-logger';
import { call, takeEvery, takeLatest } from 'redux-saga/effects';
import forIn from 'lodash/forIn';

import sagaMiddleware from 'middleware/saga';

import { isObject } from 'legacy/utils/utils';

// TODO: Move to router
export const processQuery = (saga, fn) => {
  return function* (...args) {
    const query = args[2];
    yield call(fn, query);
    yield call(saga, ...args);
  };
};
/** Catch and report all errors within sagas
 * Errors within React components are caught and reported in `app.container.jsx` */
export const handleErrors = (saga) => {
  return function* (...args) {
    try {
      yield call(saga, ...args);
    } catch (originialError) {
      try {
        // Pull argument objects up one level.
        // Sentry does not parse deeply nested
        //   extra params values well.
        const sagaArgs = args.reduce((accum, arg, i) => {
          accum[`sagaArg[${i}]`] = arg;
          return accum;
        }, {});
        yield call(report, originialError, {
          ...sagaArgs,
          fullError: originialError,
          errorLogSrc: 'saga.utils.js -> handleErrors'
        });
      } catch (e) {
        yield call(report, originialError, {
          sagaArgs: 'Too long to report',
          fullError: originialError,
          errorLogSrc: 'saga.utils.js -> handleErrors'
        });
      }
      yield call(console.error, originialError);
    }
  };
};

const _handleActionMapping = (actionMapping, effect) => {
  forIn(actionMapping, (saga, action) => {
    function* watchAction() {
      yield effect(action, handleErrors(saga));
    }
    sagaMiddleware.run(watchAction);
  });
};

const _handleActionList = (actions, saga, effect) => {
  function* watchAction() {
    yield effect(actions, handleErrors(saga));
  }
  sagaMiddleware.run(watchAction);
};

export const watchEvery = (actions, saga) => {
  if (!saga && isObject(actions)) {
    _handleActionMapping(actions, takeEvery);
  } else {
    _handleActionList(actions, saga, takeEvery);
  }
};

export const watchLatest = (actions, saga) => {
  if (!saga && isObject(actions)) {
    _handleActionMapping(actions, takeLatest);
  } else {
    _handleActionList(actions, saga, takeLatest);
  }
};
