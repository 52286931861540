import React from 'react';
import { TableCell, TableHeaderCell } from 'src/lib/components/Table';
import { CompFields } from 'src/lib/report-api/types/CompFields';
import { compFieldGetters } from 'src/lib/report-api/utils/comps.utils';
import { formatDateStr } from 'src/lib/utils';
import { FarmListCellProps, FarmListHeaderCellProps } from '../types';

// These components cannot be used as a JSX Element inside TableRow
// Example Usage:
// <Table>
//   <TableRow>
//     {yearBuiltColumn.content({ reportId, hcAddressId })}
//   </TableRow>
// </Table>

export const salesDateColumn = {
  header: (props: FarmListHeaderCellProps) => {
    return (
      <TableHeaderCell {...props} align="left">
        Sale Date
      </TableHeaderCell>
    );
  },
  content: ({ schema }: FarmListCellProps) => {
    return (
      <TableCell align="left">
        {formatDateStr(compFieldGetters[CompFields.salesDate](schema))}
      </TableCell>
    );
  }
};
