//@ts-ignore
import HC_CONSTANTS from 'HC_CONSTANTS';

const { GOOGLE_STREET_VIEW_URL, GOOGLE_MAPS_KEY } = HC_CONSTANTS;

export const buildStreetViewMetadataUrl = (
  latitude: number,
  longitude: number,
  width = 372,
  height = 300
) =>
  `${GOOGLE_STREET_VIEW_URL}/metadata?size=${width}x${height}&location=${latitude},${longitude}&source=outdoor&key=${GOOGLE_MAPS_KEY}`;

export const buildStreetViewImgUrl = (
  latitude: number,
  longitude: number,
  width = 372,
  height = 300
) =>
  `${GOOGLE_STREET_VIEW_URL}/size=${width}x${height}&location=${latitude},${longitude}&source=outdoor&key=${GOOGLE_MAPS_KEY}`;
