import { QUERIES } from 'legacyGraphQL/constants';

import { propertyFeaturesQuery } from 'legacyGraphQL/property-features';
import { mlsHistoryQuery } from 'legacyGraphQL/mls-history';
import { transactionHistoryQuery } from 'legacyGraphQL/transaction-history';
import { taxHistoryQuery } from 'legacyGraphQL/tax-history';
import { listingDetailsQuery } from 'legacyGraphQL/listing-details';

export default {
  [QUERIES.REPORT]: () =>
    console.warn('The REPORT query is a stub for the full VR'),
  [QUERIES.MLS_HISTORY]: mlsHistoryQuery,
  [QUERIES.LISTING_DETAILS]: listingDetailsQuery,
  [QUERIES.TAX_HISTORY]: taxHistoryQuery,
  [QUERIES.PROPERTY_FEATURES]: propertyFeaturesQuery,
  [QUERIES.TRANSACTION_HISTORY]: transactionHistoryQuery
};
