import { SUBSCRIPTION_SUCCESS } from '../actions/subscription';

const INITIAL_STATE = {};

export function subscriptionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SUBSCRIPTION_SUCCESS:
      const subscriptionDetails = action.payload.subscriptionDetails;
      return { ...subscriptionDetails };
    default:
      return state;
  }
}
