import { Input } from 'legacy/components/Input';

import { TextInputPropertyDetails as theme } from 'legacy/css-modules';

const TextInputPropertyDetails = (props) => {
  return (
    <Input
      mini
      theme={theme}
      className={theme.TextInputPropertyDetails}
      {...props}
    />
  );
};

export default TextInputPropertyDetails;
