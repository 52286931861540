import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import styles from './LoadingSpinner.css';

interface Props {
  dataHcName: string;
  small?: boolean;
  large?: boolean;
  className?: string;
  // Positions content in the center of the nearest non-static parent
  absoluteCenter?: boolean;
  // Renders inline
  inline?: boolean;
  containerHeight?: string;
  containerWidth?: string;
}
export const LoadingSpinner = ({
  dataHcName,
  small = false,
  large = true,
  absoluteCenter = true,
  inline = false,
  className,
  containerHeight,
  containerWidth
}: Props) => {
  const spinner = (
    <div
      className={classNames(styles.LoadingSpinner, className, {
        [styles.large || '']: large,
        [styles.small || '']: small,
        [styles.absoluteCenter || '']: absoluteCenter && !inline
      })}
      data-hc-name={dataHcName}
    >
      <svg className={styles.Svg} x="0px" y="0px" viewBox="0 0 150 150">
        <circle className={styles.Circle} cx="75" cy="75" r="60" />
      </svg>
    </div>
  );
  if (absoluteCenter) {
    const inlineStyles: CSSProperties = {};
    if (containerHeight) {
      styles.height = containerHeight;
    }
    if (containerWidth) {
      styles.width = containerWidth;
    }
    return (
      <div style={inlineStyles} className={styles.AbsoluteCenterContainer}>
        {spinner}
      </div>
    );
  } else {
    return spinner;
  }
};
