import { Component, Fragment } from 'react';
import classNames from 'classnames';
import get from 'lodash.get';
import { Button } from 'legacy/components/Button';
import { Dropdown } from 'legacy/components/Dropdown';
import { Input } from 'legacy/components/Input';
import { AlertIcon } from '@hc/component-lib/hclib/assets/inline-svgs';
import { FormError } from '@hc/component-lib/hclib/components/atoms/form-error';

import PrimaryFilterCheckbox from 'legacy/components/PrimaryFilterCheckbox';
import RadioButton from 'legacy/components/RadioButton';

import { SaveFiltersForm as theme } from 'legacy/css-modules';

const SaveFiltersSimpleList = ({ filtersToSave }) => {
  return (
    <ul className={theme.List}>
      {filtersToSave.map((filter, i) => (
        <li
          className={theme.ListItem}
          key={`filterToSave${i}`}
          data-hc-name="save-filter-modal-item"
        >
          <div className={theme.ListItemLabel}>
            {filter.saveValue.error && (
              <AlertIcon
                height="20px"
                width="20px"
                className={theme.AlertIcon}
              />
            )}
            {filter.label}
          </div>
          <div className={theme.ListItemValue}>
            {filter.saveValue.error ? (
              <span className={theme.ErrorMsg}>{filter.saveValue.error}</span>
            ) : (
              filter.displayValue
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

const SaveFilterRadio = ({
  checked,
  disabled,
  radioType,
  onChange,
  label,
  children,
  dataHcName
}) => {
  return (
    <div className={theme.ControlCell}>
      <div className={theme.RadioControl}>
        <div className={theme.RadioCell}>
          <RadioButton
            onChange={() => onChange(radioType)}
            checked={checked}
            disabled={disabled}
            dataHcName={dataHcName}
          />
        </div>
        <div
          className={classNames(theme.ControlLabel, {
            [theme.disabled]: disabled
          })}
        >
          {label}
        </div>
      </div>
      <div className={theme.Control}>{children}</div>
    </div>
  );
};

class SaveFiltersForm extends Component {
  constructor(props) {
    super(props);
    const now = Date.now().toString();
    const { existingOptions, maxReached } = props;
    const existingFilterSetIdDefault = get(existingOptions, [0, 'value'], null);
    this.state = {
      // Id for new filter set if it's created
      newFilterSetId: now,
      // Id for the filter select currently selected in the dropdown
      existingFilterSetId: existingFilterSetIdDefault,
      // Is default if this is the first saved filter
      makeDefault: existingOptions && existingOptions.length ? null : true,
      newFilterSetName: '',
      submitDisabled: !maxReached,
      selectedFilterSetId: maxReached ? existingFilterSetIdDefault : now
    };
  }

  handleChangeName = (newFilterSetName) => {
    const { existingOptions } = this.props;
    const error =
      existingOptions &&
      existingOptions.length &&
      existingOptions.find((o) => o.label === newFilterSetName)
        ? 'Please give this filter set a unique name'
        : undefined;
    this.setState({
      newFilterSetName,
      submitDisabled: !newFilterSetName || !!error,
      error
    });
  };

  handleRadioChange = (radioType) => {
    const { newFilterSetId, newFilterSetName, existingFilterSetId } =
      this.state;
    const { defaultFilterSetId, existingOptions } = this.props;
    if (radioType === 'new') {
      this.setState({
        submitDisabled: !newFilterSetName,
        selectedFilterSetId: newFilterSetId,
        makeDefault: existingOptions.length ? null : true
      });
    } else if (radioType === 'existing') {
      this.setState({
        submitDisabled: false,
        selectedFilterSetId: existingFilterSetId,
        makeDefault: existingFilterSetId === defaultFilterSetId
      });
    }
  };

  handleChangeExisting = (existingFilterSetId) => {
    this.setState({
      selectedFilterSetId: existingFilterSetId,
      makeDefault: existingFilterSetId === this.props.defaultFilterSetId,
      existingFilterSetId
    });
  };

  handleSubmit = () => {
    const {
      selectedFilterSetId,
      newFilterSetId,
      newFilterSetName,
      makeDefault
    } = this.state;
    const { filterSets, onSubmit } = this.props;
    onSubmit({
      filterSetId: selectedFilterSetId,
      filterSetName:
        selectedFilterSetId === newFilterSetId
          ? newFilterSetName
          : filterSets[selectedFilterSetId].label,
      makeDefault
    });
  };

  handleChangeDefault = () => {
    this.setState({
      makeDefault: !this.state.makeDefault
    });
  };

  render() {
    const { filtersToSave, existingOptions, maxReached } = this.props;
    const {
      existingFilterSetId,
      makeDefault,
      newFilterSetId,
      newFilterSetName,
      selectedFilterSetId,
      error,
      submitDisabled
    } = this.state;
    return (
      <Fragment>
        {maxReached && (
          <div className={theme.MaxReachedMessage}>
            <AlertIcon height="20px" width="20px" />
            <FormError value="You have reached your maximum amount of saved filters." />
          </div>
        )}
        <div className={theme.Info}>
          Save these filters for future reports. All filters are saved relative
          (e.g. +/- 1 bed) to the subject.
        </div>
        <div className={theme.ControlContainer}>
          <SaveFilterRadio
            radioType="new"
            dataHcName="new-filter-radio"
            label="Create New"
            disabled={maxReached}
            checked={selectedFilterSetId === newFilterSetId}
            onChange={this.handleRadioChange}
          >
            <Input
              dataHcName="new-filter-control"
              theme={theme}
              maxLength={25}
              error={error}
              className={theme.Input}
              value={newFilterSetName}
              placeholder="Enter name for saved filters"
              disabled={selectedFilterSetId !== newFilterSetId}
              onChange={this.handleChangeName}
            />
          </SaveFilterRadio>
          {existingOptions && existingOptions.length ? (
            <SaveFilterRadio
              radioType="existing"
              dataHcName="existing-filter-radio"
              label="Update Existing"
              checked={selectedFilterSetId !== newFilterSetId}
              onChange={this.handleRadioChange}
            >
              <Dropdown
                className={theme.Input}
                dataHcName="existing-filter-control"
                theme={theme}
                value={existingFilterSetId}
                source={existingOptions}
                onChange={this.handleChangeExisting}
                disabled={selectedFilterSetId === newFilterSetId}
              />
            </SaveFilterRadio>
          ) : null}
        </div>
        <SaveFiltersSimpleList filtersToSave={filtersToSave} />
        <div className={theme.Actions}>
          <div className={theme.DefaultCell}>
            <PrimaryFilterCheckbox
              checked={makeDefault}
              onChange={this.handleChangeDefault}
            />{' '}
            This is my primary filter set
          </div>
          <Button
            dataHcName="filter-save-save-button"
            label="Save"
            disabled={submitDisabled}
            onClick={this.handleSubmit}
          />
        </div>
      </Fragment>
    );
  }
}

export default SaveFiltersForm;
