import React, { useState } from 'react';
import { MapContext } from 'react-map-gl';
import { Toggle } from 'src/lib/components/Toggle';
import { ControlPosition } from '../types';
import styles from './SatelliteToggle.css';
import classNames from 'classnames';

export enum MAP_STYLES_BY_NAME {
  Satellite = 'mapbox://styles/matt-hc/cj6fc91dr1sma2smhcq9qckg8?optimize=true',
  'hc-muted' = 'mapbox://styles/matt-hc/cjnute79e53qp2sp99szmei5u?optimize=true',
  Default = 'mapbox://styles/mapbox/light-v10'
}

interface Props {
  dataHcName: string;
  position?: ControlPosition;
}

export const SatelliteToggle = ({
  dataHcName,
  position = ControlPosition.BOTTOM_LEFT
}: Props) => {
  const [baseLayerStyle, setBaseLayerStyle] = useState(
    MAP_STYLES_BY_NAME['hc-muted']
  );
  const context = React.useContext(MapContext);
  const map = context.map as mapboxgl.Map | undefined;
  const handleChange = (mapStyle: MAP_STYLES_BY_NAME) => {
    if (map) {
      map.setStyle(mapStyle);
      setBaseLayerStyle(mapStyle);
    }
  };
  if (map) {
    return (
      <Toggle
        className={classNames(styles.SatelliteToggle, styles[position])}
        dataHcName={dataHcName}
        value={baseLayerStyle}
        options={[
          {
            label: 'Map',
            value: MAP_STYLES_BY_NAME.Default
          },
          {
            label: 'Satellite',
            value: MAP_STYLES_BY_NAME.Satellite
          }
        ]}
        onChange={handleChange}
      />
    );
  } else {
    return null;
  }
};
