import { PureComponent } from 'react';
const conditionallyDispatchAction = (Component) => {
  return class ConditionallyDispatchAction extends PureComponent {
    componentWillMount() {
      this.loadDataIfNeeded();
    }

    componentDidUpdate(prevProps) {
      this.loadDataIfNeeded();
    }

    loadDataIfNeeded = (props) =>
      this.props.shouldDispatchAction && this.props.onShouldDispatch();

    render() {
      return <Component {...this.props} />;
    }
  };
};

export default conditionallyDispatchAction;
