import React from 'react';
import { TableHeaderCell } from 'src/lib/components/Table';
import { CompFarmListTableOptionsLauncher } from '../../CompFarmListTableOptions';

export const TableOptionsHeaderCell = () => {
  return (
    <TableHeaderCell align="center" width={50} sticky>
      <CompFarmListTableOptionsLauncher dataHcName="comp-farm-list-table" />
    </TableHeaderCell>
  );
};
