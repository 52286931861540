import React from 'react';

import { ShareReport } from '.';
import { Dialog } from 'src/lib/components/Dialog';
import { IconButton } from 'src/lib/components/IconButton';
import { ShareIcon } from 'src/lib/inline-svgs';
import { useDialogActiveState } from 'src/lib/hooks/useDialogActiveState';

interface Props {
  dataHcName: string;
  className?: string;
}
export const ShareReportDialog = ({ dataHcName, className }: Props) => {
  const { active, handleDialogOpen, handleDialogClose } =
    useDialogActiveState();
  return (
    <>
      <IconButton
        dataHcName={`${dataHcName}-button`}
        label="Share"
        className={className}
        icon={
          <ShareIcon dataHcName={`${dataHcName}-button-icon`} height="27px" />
        }
        onClick={handleDialogOpen}
      />
      <Dialog
        dataHcName={`${dataHcName}-dialog`}
        active={active}
        title="Share This Report"
        onClose={handleDialogClose}
        type="auto"
      >
        <ShareReport />
      </Dialog>
    </>
  );
};
