import { createSelector } from 'reselect';

export const getEffectiveDateState = (state) => state.effectiveDate;

export const getEffectiveDateReportId = createSelector(
  getEffectiveDateState,
  (effectiveDateState) => effectiveDateState.reportId
);

export const getEffectiveDateReportStatus = createSelector(
  getEffectiveDateState,
  (effectiveDateState) => effectiveDateState.status
);

export const getEffectiveDateReportErrorType = createSelector(
  getEffectiveDateState,
  (effectiveDateState) => effectiveDateState.errorType
);

export const getEffectiveDateMetadata = createSelector(
  getEffectiveDateState,
  (effectiveDateState) => effectiveDateState.data
);

export const getEffectiveDateDocuments = createSelector(
  getEffectiveDateMetadata,
  (reportMetadata) => reportMetadata.documents
);

export const getEffecteDateSubjectDocument = createSelector(
  getEffectiveDateDocuments,
  (documents) => documents.find((d) => d.role === 'subject')
);

export const getEffecteDateCompDocuments = createSelector(
  getEffectiveDateDocuments,
  (documents) => documents.filter((d) => d.role === 'comp')
);

export const getEffecteDateAvmDocument = createSelector(
  getEffectiveDateDocuments,
  (documents) => documents.find((d) => d.role === 'avm')
);

export const getEffecteDateFilterDocument = createSelector(
  getEffectiveDateDocuments,
  (documents) => documents.find((d) => d.role === 'filter')
);

export const getEffectiveDateTimelineSubjectDetailsMissing = createSelector(
  getEffectiveDateState,
  (effectiveDateState) => effectiveDateState.timelineSubjectDetailsMissing
);
