import React, { ReactNode } from 'react';
import { PropertyDetailsHeader } from 'src/lib/components/PropertyDetailsHeader';
import { useDocumentRole } from '../../hooks';
import { DocumentRoles, SubjectDocument } from '../../types';
import {
  formatMissing,
  formatNumber,
  formatPropertyType,
  formatSqFt
} from 'src/lib/utils';
import { isLoading } from 'src/lib/utils/general';
import { Skeleton, SkeletonFlex } from 'src/lib/components';

interface Props {
  reportId: number;
  dataHcName: string;
  className?: string;
  contentRight?: ReactNode;
  children?: ReactNode;
}
export const SubjectDetailsHeader = ({
  reportId,
  dataHcName,
  className,
  contentRight,
  children
}: Props) => {
  const {
    data: [subjectDocument],
    status: subjectDocStatus
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  if (isLoading(subjectDocStatus) || subjectDocument == null) {
    return (
      <div
        data-hc-name={`${dataHcName}-skeleton`}
        style={{ minHeight: '85px', width: '100%' }}
      >
        <Skeleton
          dataHcName={`${dataHcName}-skeleton-h1`}
          type="h1"
          style={{ padding: '15px' }}
        />
        <SkeletonFlex
          dataHcName={`${dataHcName}-skeleton-details`}
          numCells={3}
          justifyContent="flex-start"
          cellWidth="150px"
        />
      </div>
    );
  }
  return (
    <PropertyDetailsHeader
      dataHcName={dataHcName}
      address={{
        streetAddress: subjectDocument.data.address.streetAddress,
        unit: subjectDocument.data.address.unit,
        city: subjectDocument.data.address.city,
        state: subjectDocument.data.address.state,
        zipcode: subjectDocument.data.address.zipcode
      }}
      propertyAttributes={[
        `${formatPropertyType(subjectDocument.data.propertyType)}`,
        `${formatNumber(
          subjectDocument.data.livingSpace.bedrooms?.count
        )} Beds`,
        `${formatMissing(
          subjectDocument.data.livingSpace.bathrooms?.summaryCount
        )} Baths`,
        `${formatSqFt(subjectDocument.data.livingSpace.livingArea)}`,
        `${formatSqFt(
          subjectDocument.data.latestListing.site.lot?.areaSqFt
        )} Lot`
      ]}
      publicRemarks={subjectDocument.data.latestListing.publicRemarks}
      className={className}
      contentRight={contentRight}
    >
      {children}
    </PropertyDetailsHeader>
  );
};
