import { createSelector } from 'reselect';

export const selSearchByMlsNumberState = (state) => state.searchByMlsNumber;

export const selSearchByMlsNumberSubmit = createSelector(
  selSearchByMlsNumberState,
  (searchByMlsNumberState) => searchByMlsNumberState.input
);

export const selSearchByMlsNumberData = createSelector(
  selSearchByMlsNumberState,
  (searchByMlsNumberState) => searchByMlsNumberState.data
);

export const selSearchByMlsNumberStatus = createSelector(
  selSearchByMlsNumberState,
  (searchByMlsNumberState) => searchByMlsNumberState.status
);

export const selSearchByMlsNumberListingToConfirm = createSelector(
  selSearchByMlsNumberState,
  (searchByMlsNumberState) => searchByMlsNumberState.listingToConfirm
);
