import { STATUSES } from '../../../constants';
import {
  ReportApiActions,
  REPORT_FETCH,
  REPORT_FETCH_SUCCESS,
  REPORT_FETCH_ERROR,
  DOCUMENT_DELETE_SUCCESS
} from '../actions';
import { Report } from '../types';

interface ReportStateLoading {
  status: STATUSES.LOADING;
  fetching: true;
}

interface ReportStateSuccess {
  status: STATUSES.SUCCESS;
  fetching: boolean;
  data: Report;
}

interface ReportStateError {
  status: STATUSES.ERROR;
  fetching: boolean;
  message: string;
}

export type ReportState =
  | ReportStateLoading
  | ReportStateSuccess
  | ReportStateError;

type ReportsState = Record<number, ReportState>;

const INITIAL_STATE = {} as ReportsState;

export const reportReducer = <A>(
  state: ReportsState = INITIAL_STATE,
  action: A & ReportApiActions
): ReportsState => {
  switch (action.type) {
    case REPORT_FETCH: {
      const { reportId } = action.payload;
      const updatedState = { ...state };
      const reportState = updatedState[reportId];
      if (reportState) {
        updatedState[reportId] = {
          ...reportState,
          fetching: true
        };
      } else {
        updatedState[reportId] = {
          status: STATUSES.LOADING,
          fetching: true
        };
      }
      return updatedState;
    }

    case REPORT_FETCH_SUCCESS: {
      return {
        ...state,
        [action.payload.report.id]: {
          status: STATUSES.SUCCESS,
          fetching: false,
          data: action.payload.report
        }
      };
    }

    case REPORT_FETCH_ERROR: {
      return {
        ...state,
        [action.payload.reportId]: {
          status: STATUSES.ERROR,
          fetching: false,
          message: action.payload.message
        }
      };
    }

    case DOCUMENT_DELETE_SUCCESS: {
      const { reportId, documentId } = action.payload;
      const updatedState = { ...state };
      const reportState = updatedState[reportId];
      if (reportState?.status === STATUSES.SUCCESS) {
        updatedState[reportId] = {
          ...reportState,
          data: {
            ...reportState.data,
            documents: reportState.data.documents.filter(
              (d) => d.documentId !== documentId
            )
          }
        };
      }
      return updatedState;
    }

    default:
      return state;
  }
};
