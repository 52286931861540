import { useQuery } from 'react-query';
import { SearchByMlsNumberApi } from '../api';
// TODO: REMOVE THIS IMPORT
// @ts-ignore: Importing from untyped legacy directory
import { getToken } from 'selectors/account-details';
import { useSelector } from 'react-redux';
import { SearchByMlsNumberQueryKey } from '../types';
import { useContext } from 'react';
import { SearchByMlsNumberContext } from '../context';

interface Props {
  // ID of Search instance (e.g. 'comps' or 'rental-comps')
  queryId: string;
}
export const useSearchByMlsNumber = ({ queryId }: Props) => {
  const { state, onSubmit } = useContext(SearchByMlsNumberContext);
  const token: string = useSelector(getToken);
  const queryKey: SearchByMlsNumberQueryKey = ['search-by-mls-number', queryId];
  const mlsNumber = state[queryId] || '';
  const query = useQuery(
    [...queryKey, mlsNumber],
    async function () {
      if (mlsNumber) {
        return await SearchByMlsNumberApi.submit({
          token,
          input: {
            items: [
              {
                mlsNumber
              }
            ]
          }
        });
      }
      return;
    },
    { enabled: !!mlsNumber }
  );
  return {
    onSubmit: (value: string) => {
      onSubmit(queryId, value);
    },
    input: mlsNumber,
    query
  };
};
