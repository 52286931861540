import get from 'lodash/get';
import forEach from 'lodash/forEach';

import {
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_HC_VERSION_SUCCESS
} from 'actions/get-report';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';
import { SAVE_REPORT_SUCCESS } from 'actions/edit-report';

const INITIAL_STATE = {
  marketStats: {},
  subjectListingHistory: [],
  subjectPurchaseHistory: [],
  completenessMatrix: {},
  propertyLookup: {},
  effectiveDate: '',
  reportType: '',
  productType: '',
  userVersionUpdatedAt: '',
  version: '',
  link: '',
  productName: 'Property Explorer',
  productBrandImages: {
    executiveSummary: 'executive_summary_pexp.png',
    poweredBy: 'powered_by_pexp.png'
  }
};

const _buildUpdatedState = (report) => {
  let updatedState = {};
  forEach(Object.keys(INITIAL_STATE), (key) => {
    const updatedVal = get(report, key);
    if (updatedVal) {
      updatedState[key] = updatedVal;
    }
  });
  return updatedState;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_HC_VERSION_SUCCESS:
      return {
        ...state,
        ..._buildUpdatedState(action.payload.report),
        productName: get(
          action.payload.report,
          ['metadata', 'productName'],
          state.productName
        ),
        productBrandImages: get(
          action.payload.report,
          ['metadata', 'productBrandImages'],
          state.productBrandImages
        ),
        reportApiReportId: !state.reportApiReportId
          ? action.payload.report.id
          : state.reportApiReportId
      };

    case GET_REPORT_USER_VERSION_SUCCESS:
      return {
        ...state,
        ..._buildUpdatedState(action.payload.report),
        // Do not overwrite property lookup from user version
        propertyLookup: state.propertyLookup,
        userRevisionId: action.payload.report.revisionId,
        productName: get(
          action.payload.report,
          ['metadata', 'productName'],
          state.productName
        ),
        productBrandImages: get(
          action.payload.report,
          ['metadata', 'productBrandImages'],
          state.productBrandImages
        ),
        msaDetails: action.payload.report.msaDetails,
        reportApiReportId: action.payload.report.reportApiReportId
          ? action.payload.report.reportApiReportId
          : action.payload.report.id
      };

    case EFFECTIVE_DATE_GET_REPORT_SUCCESS: {
      const { report } = action.payload;
      return {
        ...state,
        effectiveDate: report.effectiveDate,
        propertyLookup: {
          id: report.metadata.reportMetadata.id,
          organizationId: report.metadata.reportMetadata.ownerOrganizationId,
          userId: report.metadata.reportMetadata.ownerId,
          streetAddress: report.metadata.reportMetadata.streetAddress,
          zipcode: report.metadata.reportMetadata.zipcode,
          unit: report.metadata.reportMetadata.unit,
          unitDesignator: report.metadata.reportMetadata.unitDesignator,
          addressSlug: report.metadata.reportMetadata.slug,
          clientId: report.metadata.reportMetadata.clientID,
          createdAt: report.metadata.reportMetadata.createdAt,
          updatedAt: report.metadata.reportMetadata.updatedAt,
          purchaseDatetime: report.metadata.reportMetadata.createdAt,
          property: report.metadata.reportMetadata.addressID
          // Not in report-api (don't think it's needed)
          // userEmail,
          // city,
          // state,
          // lat,
          // lon,
          // addressKey,
          // status,
          // downloadId,
          // expirationDatetime,
          // lastListPrice,
          // lastListDate,
          // propertyStatus,
          // yearBuilt
        }
      };
    }

    case SAVE_REPORT_SUCCESS:
      return {
        ...state,
        userRevisionId: action.payload.revisionId
      };

    default:
      return state;
  }
};
