// PROPERTY DATA SOURCES
export const HC = 'HC';
export const MLS = 'MLS';
export const PR = 'PR';
export const ANY = 'ANY';
export const ALL = 'ALL';
export const PROPERTY_DATA_SOURCES = { HC, MLS, PR, ANY, ALL };
// QUERIES
export const QUERIES = {
  REPORT: 'mainReport',
  RENTAL: 'rentalReport',
  MLS_HISTORY: 'mlsHistory',
  TRANSACTION_HISTORY: 'transactionHistory',
  TAX_HISTORY: 'taxHistory',
  LISTING_DETAILS: 'listingDetails',
  PROPERTY_FEATURES: 'propertyFeatures'
};
