import { EVENTS } from 'legacy/appstore/events';
import { VIEWS } from 'legacy/routes/constants';

export const CLIENT_ID_SEARCH_RESULTS = ' CLIENT_ID_SEARCH_RESULTS';
export const CLIENT_ID_SEARCH_SUBMIT = ' CLIENT_ID_SEARCH_SUBMIT';
export const CLIENT_ID_INPUT_CHANGE = 'CLIENT_ID_INPUT_CHANGE';
export const CLIENT_ID_SEARCH_SELECT = 'CLIENT_ID_SEARCH_SELECT';

export const clientIdSearchSubmit = (input) => ({
  type: CLIENT_ID_SEARCH_SUBMIT,
  payload: {
    input
  }
});

export const clientIdSearchResults = (results) => ({
  type: CLIENT_ID_SEARCH_RESULTS,
  payload: {
    results
  }
});

export const clientIdSearchInputChange = (input) => ({
  type: CLIENT_ID_INPUT_CHANGE,
  payload: {
    input
  }
});

export const clientIdSearchSelect = (address) => {
  return {
    type: CLIENT_ID_SEARCH_SELECT,
    meta: {
      router: {
        view: VIEWS.SEARCH,
        query: {
          street_address: address.streetAddress,
          zipcode: address.zipcode,
          unit: address.unit,
          slug: address.slug,
          listing_id: address.listingId || undefined,
          listing_source: address.listingSource || undefined
        }
      },
      analytics: {
        event: EVENTS.CLIENT_ID_SEARCH_SELECT,
        props: {
          ...address
        }
      }
    }
  };
};
