import React, { CSSProperties } from 'react';
import { HomeIcon } from 'src/lib/inline-svgs';
import styles from './SubjectIcon.css';

interface Props {
  dataHcName: string;
  size?: number;
  style?: CSSProperties;
  shadow?: boolean;
}
export const SubjectIcon = ({
  dataHcName,
  size = 25,
  style = {},
  shadow = true
}: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={styles.SubjectIcon}
      style={{
        ...style,
        height: `${size}px` || style.height,
        width: `${size}px` || style.width
      }}
    >
      <HomeIcon
        dataHcName={`${dataHcName}-icon`}
        className={styles.HomeIcon}
        height={`${Math.ceil(size / 2)}px`}
        width={`${Math.ceil(size / 2)}px`}
      />
      {shadow && (
        <div style={{ width: `${size / 2}px` }} className={styles.Shadow} />
      )}
    </div>
  );
};
