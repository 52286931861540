import {
  CompDocument,
  DocumentRoles,
  useDocumentRole
} from 'src/lib/report-api';
import { useMemo } from 'react';

export const useCompDocIdsByCompId = (reportId: number) => {
  const { data: compDocuments } = useDocumentRole<CompDocument>(
    reportId,
    DocumentRoles.Comp
  );
  return useMemo(() => {
    const results: { [key: string]: string } = {};
    compDocuments.forEach((compDocument) => {
      results[compDocument.data.compID] = compDocument.documentId;
    });
    return results;
  }, [compDocuments]);
};
