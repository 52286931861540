import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { preferencesRequired } from '../actions';
import {
  selPreferences,
  selPreferencesFetching,
  selPreferencesStatus
} from '../selectors';
import { STATUSES } from 'src/constants';
import { Preferences } from 'src/lib/huell';

export const usePreferences = (): {
  status: STATUSES | undefined;
  fetching: boolean | undefined;
  data: Preferences | undefined;
} => {
  const dispatch = useDispatch();
  const status = useSelector(selPreferencesStatus);
  const data = useSelector(selPreferences);
  const fetching = useSelector(selPreferencesFetching);

  useEffect(() => {
    if (!status) {
      dispatch(preferencesRequired());
    }
  }, [status]);
  return {
    status,
    fetching,
    data
  };
};
