export const createFactorySelector =
  <I, S>(factorySelector: (i: I) => S) =>
  (i: I) => {
    // This is a util that allows us to utilize reselect to memoize selector factories.
    // Example:
    // You need to write a selector to get data for a specific Comp based on hcAddressId.
    // The selector involves expensive computation so you only want it to be called when
    // an input changes. The factory-pattern (which is the best we've found so far) breaks
    // reselect because it creates a new selector each time the factory is called.
    // This util will cache the created selectors which will allow 'createSelector'
    // to do it's intended job.
    const cache: {
      [key: string]: S;
    } = {};
    // TypeScript requires only strings and numbers for indices.
    // As a work-around, factories with multiple arguments need to be passed as an array
    // and the key for the cache is converted to a string.
    let tsIndex: undefined | string;
    if (typeof i === 'string') {
      tsIndex = i;
    } else if (typeof i === 'number') {
      tsIndex = i.toString();
    } else if (Array.isArray(i)) {
      tsIndex = i.map((j) => j.toString()).join('-');
    } else {
      throw new Error('Index must be string, number, or array');
    }
    // Now that we have a safe key, check the cache and populate it if it's empty
    if (!cache[tsIndex]) {
      cache[tsIndex] = factorySelector(i);
    }
    // Return the cached selector
    const selector = cache[tsIndex];
    if (selector) {
      return selector;
    } else {
      throw new Error('Corrupted Selector Cache');
    }
  };
