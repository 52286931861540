import classNames from 'classnames';
import { RTThemedDrawer } from '@hc/component-lib/hclib/rt-themed';

import { Drawer as theme } from 'legacy/css-modules/generic';

const Drawer = ({
  children,
  className,
  active,
  dataHcName,
  onOverlayClick,
  width,
  type,
  size = 'medium'
}) => {
  return (
    <RTThemedDrawer
      className={classNames(
        theme.Drawer,
        className,
        theme[size],
        `dataHcName--${dataHcName}`
      )}
      active={active}
      onOverlayClick={onOverlayClick}
      type={type}
      theme={theme}
    >
      {children}
    </RTThemedDrawer>
  );
};

Drawer.defaultProps = {
  dataHcName: 'drawer'
};

export default Drawer;
