import { watchEvery } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { call, put, select } from 'redux-saga/effects';
import PropertyGraph from 'legacy/services/property-graph';
import { getSharedReportUid } from 'selectors/share-report';
import { getIsViewingSharedReport } from 'selectors/property-explorer';
import apiUtil from 'legacy/utils/api';
import HC_CONSTANTS from 'HC_CONSTANTS';
import {
  mlsFetchFailure,
  mlsFetchSuccess,
  MLS_FETCH
} from '../actions/mls.actions';

const { VRSHARE_PROXY_URL } = HC_CONSTANTS;

export function* handleMlsFetch(action) {
  const { mlsId } = action.payload;
  try {
    const isViewingSharedReport = yield select(getIsViewingSharedReport);
    let mlsLookup;
    if (isViewingSharedReport) {
      const uid = yield select(getSharedReportUid);
      const response = yield call(
        apiUtil.GET,
        `${VRSHARE_PROXY_URL}/emporium/vrshare/mls_regulations`,
        {
          mlsId,
          uid
        }
      );
      mlsLookup = response.data.mlsLookup;
    } else {
      const response = yield call(PropertyGraph.fetchMls, mlsId);
      mlsLookup = response.mlsLookup;
    }
    yield put(mlsFetchSuccess(mlsId, mlsLookup));
  } catch (e) {
    yield put(mlsFetchFailure(mlsId));
    console.error(e);
    report('MLS Dislcaimer: Saga Failed to Fetch Data', { e, action });
  }
}

export default function registerMlsSaga() {
  watchEvery({
    [MLS_FETCH]: handleMlsFetch
  });
}
