import { connect } from 'react-redux';

import { MAX_SAVED_FILTER_SETS } from 'legacy/appstore/constants';

import { compsFiltersSave } from 'actions/comps';
import { rentalCompsFiltersSave } from 'actions/rental-comps';

import { getIsRentalContext } from 'selectors/property-explorer';
import {
  getCompsFiltersForSaveModal,
  getCompsSavedFilters,
  getCompsSavedFilterSetDropdownOptions,
  getCompsSavedFiltersDefaultKey
} from 'selectors/comps-context';

import SaveFiltersForm from 'legacy/components/SaveFiltersForm';

const mapStateToProps = (state) => {
  const allSavedFilters = getCompsSavedFilters(state);
  const existingOptions = getCompsSavedFilterSetDropdownOptions(state);
  return {
    filterSets: allSavedFilters ? allSavedFilters.filterSets : {},
    filtersToSave: getCompsFiltersForSaveModal(state),
    maxReached: existingOptions.length >= MAX_SAVED_FILTER_SETS,
    isRentalContext: getIsRentalContext(state),
    defaultFilterSetId: getCompsSavedFiltersDefaultKey(state),
    existingOptions
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onSubmit: ({ filterSetId, filterSetName, makeDefault }) =>
      stateProps.isRentalContext
        ? dispatchProps.rentalCompsFiltersSave({
            filterSetId,
            filterSetName,
            makeDefault
          })
        : dispatchProps.compsFiltersSave({
            filterSetId,
            filterSetName,
            makeDefault
          })
  };
};

export default connect(
  mapStateToProps,
  {
    compsFiltersSave,
    rentalCompsFiltersSave
  },
  mergeProps
)(SaveFiltersForm);
