import React, { ReactNode } from 'react';
import {
  ThumbnailCarousel,
  ClickCallback
} from '../../../components/ThumbnailCarousel';
import { useMapTilePhoto } from '../../hooks/useMapTilePhoto';
import { LimitType, usePhotos } from '../../hooks/usePhotos';
import { LoadingSpinner } from '../../../components/LoadingSpinner';

interface Props {
  dataHcName: string;
  hcAddressId: number;
  includeMapTile?: boolean;
  includeStreetView?: boolean;
  limit?: LimitType;
  className?: string;
  children?: ReactNode;
  onClick?: ClickCallback;
}
export const PhotoCarousel = ({
  dataHcName,
  hcAddressId,
  limit,
  children,
  className,
  includeMapTile = true,
  includeStreetView = true,
  onClick
}: Props) => {
  const { loading, error, data } = usePhotos(hcAddressId, limit);
  const { data: mapboxPhoto } = useMapTilePhoto(hcAddressId);

  if (loading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  } else if (error) {
    return <div>Error</div>;
  }
  const urls = [];
  data.forEach((listingPhotos) => {
    listingPhotos?.forEach((photo) => {
      if (photo.representation.httpUrl) {
        urls.push(photo.representation.httpUrl);
      }
    });
  });
  // TODO: Add street view photo
  if (includeMapTile && mapboxPhoto?.representation.httpUrl) {
    urls.push(mapboxPhoto?.representation.httpUrl);
  }
  if (includeStreetView) {
    console.warn('[PhotoCarousel] Implement Street View Thumbnail');
  }
  return (
    <ThumbnailCarousel
      dataHcName={dataHcName}
      urls={urls}
      className={className}
      onClick={onClick}
    >
      {children}
    </ThumbnailCarousel>
  );
};
