import { fetchUserLocationViaIP } from 'hcmaps-leaflet';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { put, call, select } from 'redux-saga/effects';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { STATUSES } from 'legacy/appstore/constants';
import { VIEWS } from 'legacy/routes/constants';

import apiUtil from 'legacy/utils/api';
import { watchEvery } from 'legacy/utils/saga';

import {
  MLS_LISTINGS_GET_REQUEST,
  MLS_LISTINGS_PROPERTY_PREVIEW,
  mlsListingsGetSuccess
} from 'actions/mls-listings';

import { getActiveRecentReports } from 'selectors/recent-reports';
import { getMLSListingsStatus } from 'selectors/mls-listings';
import { getToken } from 'selectors/account-details';

// San Francisco
const DEFAULT_ZIPCODE = 94105;

export function* fetchMLSListings(action) {
  const loadedStatus = yield select(getMLSListingsStatus);
  if (loadedStatus !== STATUSES.SUCCESS) {
    let listingsUrl = `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/new_listings/?zipcode=`;
    // Get recent reports
    const recentReports = yield select(getActiveRecentReports);
    // Use zipcode of either the most recent viewed report or the user's current location
    if (recentReports.length) {
      listingsUrl += recentReports[0].zipcode;
    } else {
      const token = yield select(getToken);
      try {
        const locationResponse = yield call(
          fetchUserLocationViaIP,
          HC_CONSTANTS.GEO_LOCATION_URL,
          {
            'hc-auth-token': token
          }
        );
        const zipcode =
          (locationResponse && locationResponse.zip) || DEFAULT_ZIPCODE;
        listingsUrl += zipcode;
      } catch (e) {
        // Fall back to default zipcode if location service fails
        listingsUrl += DEFAULT_ZIPCODE;
      }
    }
    // Get listings
    let listingsData = yield call(apiUtil.GET, listingsUrl);
    // Listings may return empty in development environments
    if (listingsData.length) {
      yield put(mlsListingsGetSuccess(listingsData));
    } else {
      yield put(mlsListingsGetSuccess([]));
    }
  }
}

function* fetchMlsListingPropertyPreview(action) {
  const { property } = action.payload;
  yield put(
    routeChange({
      view: VIEWS.SEARCH,
      query: {
        street_address: property.streetAddress || undefined,
        zipcode: property.zipcode || undefined,
        unit: property.unit || undefined
      },
      options: { inherit: false }
    })
  );
}

export default function registerCompsPhotosSaga() {
  watchEvery({
    [MLS_LISTINGS_GET_REQUEST]: fetchMLSListings,
    [MLS_LISTINGS_PROPERTY_PREVIEW]: fetchMlsListingPropertyPreview
  });
}
