import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  SECTION_ID_COMPS,
  SECTION_ID_RENTAL_REPORT,
  SECTION_ID_FORECAST,
  SECTION_ID_SHARED_REPORT_FORM,
  SECTION_ID_NEARBY_PROPERTIES
} from 'legacy/appstore/constants';

import {
  getReportComps,
  getReportRental,
  getReportForecast,
  getReportNearbyProperties
} from 'actions/get-report';
import { uniqueLinkFetch } from 'actions/share-report';

import {
  getShouldLoadComps,
  getShouldLoadRentalReport,
  getShouldLoadForecast,
  getShouldLoadSharedReportConfig,
  getShouldLoadNearbyProperties
} from 'selectors/should-load';

const SECTIONS = {
  [SECTION_ID_COMPS]: {
    readyToLoad: getShouldLoadComps,
    onReadyToLoad: getReportComps
  },
  [SECTION_ID_FORECAST]: {
    readyToLoad: getShouldLoadForecast,
    onReadyToLoad: getReportForecast
  },
  [SECTION_ID_RENTAL_REPORT]: {
    readyToLoad: getShouldLoadRentalReport,
    onReadyToLoad: getReportRental
  },
  [SECTION_ID_SHARED_REPORT_FORM]: {
    readyToLoad: getShouldLoadSharedReportConfig,
    onReadyToLoad: uniqueLinkFetch
  },
  [SECTION_ID_NEARBY_PROPERTIES]: {
    readyToLoad: getShouldLoadNearbyProperties,
    onReadyToLoad: getReportNearbyProperties
  }
};

const loadWhenReady = (SectionComponent, sectionId) => {
  const storeMethods = SECTIONS[sectionId];
  if (!storeMethods) {
    console.warn(`Unknown sectionId "${sectionId}" in readyToLoad HOC.`);
  }

  const mapStateToProps = (state, ownProps) => ({
    readyToLoad: storeMethods.readyToLoad(state)
  });

  const mapDispatchToProps = (dispatch, ownProps) => ({
    onReadyToLoad: () => {
      dispatch(storeMethods.onReadyToLoad());
    }
  });

  class LoadWhenReady extends PureComponent {
    componentWillMount() {
      this.loadIfReady(this.props);
    }

    componentWillReceiveProps(nextProps) {
      this.loadIfReady(nextProps);
    }

    loadIfReady = (props) => {
      if (props.readyToLoad) {
        props.onReadyToLoad();
      }
    };

    render() {
      return <SectionComponent {...this.props} />;
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(LoadWhenReady);
};

export default loadWhenReady;
