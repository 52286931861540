import { EVENTS } from 'legacy/appstore/events';

export const SEARCH_BY_MLS_NUMBER_SELECT = 'SEARCH_BY_MLS_NUMBER_SELECT';
export const SEARCH_BY_MLS_NUMBER_SELECT_DUPLICATE_ADDRESS =
  'SEARCH_BY_MLS_NUMBER_SELECT_DUPLICATE_ADDRESS';
export const SEARCH_BY_MLS_NUMBER_SELECT_CONFIRM =
  'SEARCH_BY_MLS_NUMBER_SELECT_CONFIRM';
export const SEARCH_BY_MLS_NUMBER_SELECT_CANCEL =
  'SEARCH_BY_MLS_NUMBER_SELECT_CANCEL';
export const SEARCH_BY_MLS_NUMBER_SELECT_SUCCESS =
  'SEARCH_BY_MLS_NUMBER_SELECT_SUCCESS';
export const SEARCH_BY_MLS_NUMBER_SELECT_FAILURE =
  'SEARCH_BY_MLS_NUMBER_SELECT_FAILURE';
export const SEARCH_BY_MLS_NUMBER_SUBMIT = 'SEARCH_BY_MLS_NUMBER_SUBMIT';
export const SEARCH_BY_MLS_NUMBER_SUCCESS = 'SEARCH_BY_MLS_NUMBER_SUCCESS';
export const SEARCH_BY_MLS_NUMBER_FAILURE = 'SEARCH_BY_MLS_NUMBER_FAILURE';

export const searchByMlsNumberSelect = (listing) => {
  return {
    type: SEARCH_BY_MLS_NUMBER_SELECT,
    payload: {
      listing
    },
    meta: {
      analytics: {
        event: EVENTS.COMPS_SEARCH_BY_MLS_NUMBER_SELECT
      }
    }
  };
};

export const searchByMlsNumberSelectDuplicateAddress = (listing) => {
  return {
    type: SEARCH_BY_MLS_NUMBER_SELECT_DUPLICATE_ADDRESS,
    payload: { listing }
  };
};

export const searchByMlsNumberSelectConfirm = () => {
  return {
    type: SEARCH_BY_MLS_NUMBER_SELECT_CONFIRM,
    meta: {
      analytics: {
        event: EVENTS.COMPS_SEARCH_BY_MLS_NUMBER_DUPLICATE_ADDRESS_CONFIRM
      }
    }
  };
};

export const searchByMlsNumberSelectCancel = () => {
  return {
    type: SEARCH_BY_MLS_NUMBER_SELECT_CANCEL,
    meta: {
      analytics: {
        event: EVENTS.COMPS_SEARCH_BY_MLS_NUMBER_DUPLICATE_ADDRESS_CANCEL
      }
    }
  };
};

export const searchByMlsNumberSubmit = (mlsNumber) => {
  return {
    type: SEARCH_BY_MLS_NUMBER_SUBMIT,
    payload: {
      mlsNumber
    },
    meta: {
      analytics: {
        event: EVENTS.COMPS_SEARCH_BY_MLS_NUMBER,
        props: {
          mlsNumber
        }
      }
    }
  };
};

export const searchByMlsNumberSuccess = (input, data) => {
  return {
    type: SEARCH_BY_MLS_NUMBER_SUCCESS,
    payload: {
      input,
      data
    }
  };
};

export const searchByMlsNumberFailure = (mlsNumber) => {
  return {
    type: SEARCH_BY_MLS_NUMBER_FAILURE
  };
};

export const searchByMlsNumberSelectSuccess = (comp) => {
  return {
    type: SEARCH_BY_MLS_NUMBER_SELECT_SUCCESS,
    payload: { comp },
    meta: {
      toast: {
        message: `Listing ${comp.listingId} has been added to the list of comps.`
      }
    }
  };
};

export const searchByMlsNumberSelectFailure = (listing) => {
  return {
    type: SEARCH_BY_MLS_NUMBER_SELECT_FAILURE,
    meta: {
      toast: {
        message: `Listing ${listing.listingID} could not be added as a comp. We have been alerted about this issue.`
      }
    }
  };
};
