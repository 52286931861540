import UTM_CONFIG from 'legacy/utm';

import { COMP_TYPE_DEFAULT, COMP_TYPE_RENTAL } from 'legacy/appstore/constants';
import {
  VIEWS,
  ACTION_PAGE_LOAD_COMPS,
  ACTION_PAGE_LOAD_RENTAL_COMPS
} from 'legacy/routes/constants';

import { handleErrors } from 'legacy/utils/saga';
import { initPropertyExplorer } from 'sagas/init-property-explorer';

import {
  getRouteQueryCompsPages,
  getRouteQueryRentalCompsPages
} from 'selectors/routes';

import * as Async from 'legacy/async-pages';

import CompsPageWrapper from 'legacy/pages/CompsPageWrapper';

const routesSharedReport = {
  [VIEWS.VALUE_REPORT_SHARED]: {
    path: '/shared-report/:streetAddress/:city/:state/:zipcode/:uid',
    query: [
      'unit',
      'unit_designator',
      'address_slug',
      'ref',
      '_ga',
      'publicKey',
      'referer',
      'token',
      'visiblePropertyDetailsType',
      'addressId',
      'addressIdPropertyDetails',
      'chartModal',
      'commentModal'
    ].concat(UTM_CONFIG.UTM_PARAMS),
    saga: handleErrors(initPropertyExplorer),
    component: Async.PropertyExplorer
  },
  [VIEWS.COMPS_SELECTION_SHARED]: {
    path: '/shared-report/:streetAddress/:city/:state/:zipcode/:uid/comps',
    query: [
      'unit',
      'unit_designator',
      'address_slug',
      'viewType',
      'viewSelected'
    ],
    saga: handleErrors(initPropertyExplorer),
    querySelector: getRouteQueryCompsPages,
    actionType: ACTION_PAGE_LOAD_COMPS,
    component: CompsPageWrapper(COMP_TYPE_DEFAULT),
    template: {
      LeftPane: Async.CompMap,
      RightPane: Async.CompsList
    },
    props: {
      compType: COMP_TYPE_DEFAULT,
      viewBack: VIEWS.VALUE_REPORT_SHARED,
      viewSelection: VIEWS.COMPS_SELECTION_SHARED,
      viewMap: VIEWS.COMPS_SELECTION_MAP_SHARED,
      mobileFullWidth: 'RightPane'
    }
  },
  [VIEWS.COMPS_SELECTION_MAP_SHARED]: {
    path: '/shared-report/:streetAddress/:city/:state/:zipcode/:uid/comps/map',
    query: ['unit', 'unit_designator', 'address_slug'],
    saga: handleErrors(initPropertyExplorer),
    component: Async.CompsSelectionMap,
    props: {
      compType: COMP_TYPE_DEFAULT,
      viewSelection: VIEWS.COMPS_SELECTION_SHARED,
      viewReport: VIEWS.VALUE_REPORT_SHARED
    }
  },
  [VIEWS.RENTAL_COMPS_SELECTION_MAP_SHARED]: {
    path: '/shared-report/:streetAddress/:city/:state/:zipcode/:uid/rental-comps/map',
    query: ['unit', 'unit_designator', 'address_slug'],
    saga: handleErrors(initPropertyExplorer),
    component: Async.CompsSelectionMap,
    props: {
      compType: COMP_TYPE_RENTAL,
      viewSelection: VIEWS.RENTAL_COMPS_SELECTION_SHARED,
      viewReport: VIEWS.VALUE_REPORT_SHARED
    }
  },
  [VIEWS.RENTAL_COMPS_SELECTION_SHARED]: {
    path: '/shared-report/:streetAddress/:city/:state/:zipcode/:uid/rental-comps',
    query: [
      'unit',
      'unit_designator',
      'address_slug',
      'viewType',
      'viewSelected'
    ],
    saga: handleErrors(initPropertyExplorer),
    actionType: ACTION_PAGE_LOAD_RENTAL_COMPS,
    querySelector: getRouteQueryRentalCompsPages,
    component: CompsPageWrapper(COMP_TYPE_RENTAL),
    template: {
      LeftPane: Async.CompMap,
      RightPane: Async.CompsList
    },
    props: {
      compType: COMP_TYPE_RENTAL,
      viewBack: VIEWS.VALUE_REPORT_SHARED,
      viewSelection: VIEWS.RENTAL_COMPS_SELECTION_SHARED,
      viewMap: VIEWS.RENTAL_COMPS_SELECTION_MAP_SHARED,
      mobileFullWidth: 'RightPane'
    }
  },
  [VIEWS.FULL_MAP_VALUE_REPORT_SHARED]: {
    path: '/shared-report/:streetAddress/:city/:state/:zipcode/:uid/:maptype/full-map',
    query: ['unit', 'unit_designator', 'address_slug'],
    component: Async.PropertyExplorerFullMap,
    saga: handleErrors(initPropertyExplorer)
  },
  [VIEWS.LOCATION_MAP_SHARED]: {
    path: '/shared-report/:streetAddress/:city/:state/:zipcode/:uid/location/map',
    query: ['unit', 'unit_designator', 'address_slug'],
    saga: handleErrors(initPropertyExplorer),
    component: Async.CompsSelectionMap,
    props: {
      locationOnly: true,
      viewReport: VIEWS.VALUE_REPORT_SHARED
    }
  }
};

export default routesSharedReport;
