import { useDispatch, useSelector } from 'react-redux';
import { STATUSES } from 'legacy/appstore/constants';
import { getIsEffectiveDateReport } from 'selectors/property-explorer';
import { getReportDataEffectiveDate } from 'selectors/report-data';
import { getReportStatusHcVersionIsLoadedInitial } from 'selectors/report-status';
import { listingsRequired } from 'actions/listings';
import { useAddressId } from 'legacy/hocs/useAddressId';
import {
  getPropertyListingsStatus,
  getPropertyListingsData
} from 'selectors/listings';
import { useEffect } from 'react';

// Util to transform property-graph response into the pinkman photos contract
const _toPinkmanContract = (photo, listing, precedesEffectiveDate) => {
  return {
    isListingRental: listing.isListingRental,
    currentStatusDate: listing.statusDate,
    externalSourceId: listing.listingID,
    httpUrl: photo.representation.httpUrl,
    imageDate: listing.listingStatus.listingDate,
    listingDate: listing.listingStatus.listingDate,
    mlsNumber: listing.mlsID, // This isn't the mlsNumber
    precedesEffectiveDate
  };
};

export const useListings = (addressIdInput) => {
  const addressId = useAddressId(addressIdInput);
  const dispatch = useDispatch();
  const hcReportLoaded = useSelector(getReportStatusHcVersionIsLoadedInitial);
  const status = useSelector(getPropertyListingsStatus(addressId));
  const listings = useSelector(getPropertyListingsData(addressId)) || [];
  const isEffectiveDateReport = useSelector(getIsEffectiveDateReport);
  const effectiveDate = useSelector(getReportDataEffectiveDate);
  useEffect(() => {
    if (addressId && hcReportLoaded && (!status || status === STATUSES.INIT)) {
      dispatch(listingsRequired(addressId));
    }
  }, [addressId, status, hcReportLoaded]);
  return {
    status,
    listings: listings.map((listing) => ({
      ...listing,
      photos: listing.photos?.map((p) =>
        _toPinkmanContract(
          p,
          listing,
          isEffectiveDateReport
            ? listing.statusDate < effectiveDate // TODO: replace with listingDate
            : true
        )
      )
    }))
  };
};
