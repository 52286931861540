import { connect } from 'react-redux';

import { loginPromptHide } from 'actions/auth';
import modalWithIOSInputFix from 'legacy/hocs/modalWithIOSInputFix';

import {
  getLoginPromptState,
  getLoginPromptTitle,
  getLoginPromptAfterAuthAction
} from 'selectors/auth';
import { getIsLessThanSmallMedium } from 'selectors/match-media';

import LoginPromptModal from 'legacy/components/LoginPromptModal';

const mapStateToProps = (state) => {
  const loginPromptState = getLoginPromptState(state);
  return {
    open: loginPromptState.isOpen,
    actionType: loginPromptState.actionType,
    showSignUp: loginPromptState.showSignUp,
    title: getLoginPromptTitle(state),
    fullscreen: getIsLessThanSmallMedium(state),
    afterAuthAction: getLoginPromptAfterAuthAction(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(loginPromptHide());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(modalWithIOSInputFix(LoginPromptModal));
