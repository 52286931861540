import React from 'react';
import { Dialog } from 'legacy/components/Dialog';

const TrialExpiredModal = ({
  isOpen,
  isAdmin,
  adminEmailId,
  onClose,
  onViewPlans
}) => {
  return (
    <Dialog
      active={isOpen}
      actions={
        isAdmin ? [{ label: 'Purchase plan', onClick: onViewPlans }] : []
      }
      title="Trial Expired"
      onClose={onClose}
    >
      <p>
        Oh no! It looks like your free trial has expired. We hope you enjoyed
        your 10 free reports.{' '}
      </p>
      <p>
        {isAdmin
          ? 'If you would like to continue, purchase a plan here'
          : `If you would like to continue access please contact your administrator ${
              adminEmailId ? `: ${adminEmailId}.` : '.'
            }`}
      </p>
    </Dialog>
  );
};

export default TrialExpiredModal;
