import { AxiosResponse, default as axios } from 'axios';
import { Operation } from 'fast-json-patch';
import {
  CompDocument,
  Document,
  DocumentRoles,
  Report,
  ReportTypes
} from '../types';

//TODO Create a Typescript typed version of HC_CONSTANTS
//@ts-ignore
import HC_CONSTANTS from 'HC_CONSTANTS';
import { Personalization } from '../../huell';
import { CompId, CompSchema, SubjectSchema } from '../types/schemas';
import { Listing } from 'src/lib/property-lookup/types';
import { DateStr } from 'src/types';

async function fetchReport(token: string, reportId: number) {
  const response = await axios.get<Report>(
    `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}`,
    {
      headers: { 'HC-Auth-Token': token }
    }
  );
  return response;
}

async function fetchDocumentRole(
  token: string,
  reportId: number,
  documentRole: DocumentRoles
): Promise<AxiosResponse<Document[]>> {
  const response = await axios.get<Document[]>(
    `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/`,
    {
      params: { role: documentRole },
      headers: { 'HC-Auth-Token': token }
    }
  );
  return response;
}

async function fetchDocument(
  token: string,
  reportId: number,
  documentId: string
): Promise<AxiosResponse<Document>> {
  const response = await axios.get<Document>(
    `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
    {
      headers: { 'HC-Auth-Token': token }
    }
  );
  return response;
}

async function fetchCobranding(
  token: string,
  reportId: number
): Promise<AxiosResponse<Personalization>> {
  const response = await axios.get<Personalization>(
    `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/cobranding`,
    {
      headers: { 'HC-Auth-Token': token }
    }
  );
  return response;
}

async function patchDocument(
  token: string,
  reportId: number,
  documentId: string,
  operations: Operation[]
): Promise<AxiosResponse<Document>> {
  const response = await axios.patch<Document>(
    `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
    operations,
    {
      headers: { 'HC-Auth-Token': token }
    }
  );
  return response;
}

async function deleteDocument(
  token: string,
  reportId: number,
  documentId: string
): Promise<AxiosResponse<''>> {
  const response = await axios.delete<''>(
    `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
    {
      headers: { 'HC-Auth-Token': token }
    }
  );
  return response;
}

async function selectComps(
  token: string,
  reportId: number,
  compIds: CompId[]
): Promise<AxiosResponse<CompDocument[]>> {
  const response = await axios.post<CompDocument[]>(
    `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/actions/comps`,
    compIds,
    {
      headers: { 'HC-Auth-Token': token }
    }
  );
  return response;
}

async function listingToComp(
  token: string,
  reportType: ReportTypes,
  effectiveDate: DateStr,
  // Listing comes from property-graph so we don't have the extended ListingSchema
  listing: Listing,
  subject: SubjectSchema
): Promise<AxiosResponse<CompSchema>> {
  const payload = {
    mlsID: listing.mlsID,
    mlsNumber: listing.listingID,
    subjectDetails: {
      blockID: subject.block.id,
      buildingArea: subject.livingSpace.livingArea,
      pool: subject.latestListing.external.pool,
      basement: subject.latestListing.livingSpace.basement.has,
      propertyType: subject.propertyType,
      lotSize: subject.site.area,
      yearBuilt: subject.structure.yearBuilt,
      beds: subject.livingSpace.bedrooms?.count,
      baths: subject.livingSpace.bathrooms?.summaryCount
    },
    subjectLocation: {
      latitude: subject.geoLocation.latitude,
      longitude: subject.geoLocation.longitude
    },
    effectiveDate
  };
  const response = await axios.post<CompSchema>(
    `${HC_CONSTANTS.REPORT_API_URL}/reporttypes/${reportType}/to_comp`,
    payload,
    {
      headers: { 'HC-Auth-Token': token }
    }
  );
  return response;
}

export const ReportApi = {
  fetchReport,
  fetchDocument,
  fetchDocumentRole,
  fetchCobranding,
  patchDocument,
  deleteDocument,
  selectComps,
  listingToComp
};
