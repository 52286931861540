import { watchLatest } from 'legacy/utils/saga';
import { call, put, select } from 'redux-saga/effects';
import { report } from 'hc-ravenjs-logger';
// import apiUtil from 'legacy/utils/api';
import {
  SEARCH_BY_MLS_NUMBER_SUBMIT,
  SEARCH_BY_MLS_NUMBER_SELECT_CONFIRM,
  SEARCH_BY_MLS_NUMBER_SELECT,
  searchByMlsNumberSuccess,
  searchByMlsNumberFailure,
  searchByMlsNumberSelectDuplicateAddress,
  searchByMlsNumberSelectSuccess,
  searchByMlsNumberSelectFailure
} from 'actions/search-by-mls-number';
import PropertyGraph from 'legacy/services/property-graph';
import { getSubjectAdjusted } from 'selectors/subject';
import { getCompsSelectedIds } from 'selectors/comps';
import { getCompType } from 'selectors/comps-context';
import { getMlsIds } from 'selectors/mls';
import { selSearchByMlsNumberListingToConfirm } from 'selectors/search-by-mls-number';
import EffectiveDateService from 'legacy/services/effective-date';
import { toastShow } from 'actions/toast';

export function* handleSearchByMlsNumber(action) {
  try {
    const { mlsNumber } = action.payload;
    const mlsIds = yield select(getMlsIds);
    const pgPayload = mlsIds.map((mlsID) => ({ mlsID, mlsNumber }));
    // Call property-graph
    const response = yield call(PropertyGraph.searchByMlsNumber, pgPayload);
    // TODO: Remove Duplicates from Response
    // Call Success Action
    yield put(searchByMlsNumberSuccess(pgPayload, response.searchByMlsNumbers));
  } catch (e) {
    yield put(searchByMlsNumberFailure());
    console.error(e);
    report('Search By Mls Number: Saga Failed to Fetch Data', { e, action });
  }
}

function* addListingAsComp(listing) {
  const compType = yield select(getCompType);
  const subject = yield select(getSubjectAdjusted);
  const comp = yield call(
    EffectiveDateService.listingToComp,
    listing,
    subject,
    compType
  );
  yield put(
    searchByMlsNumberSelectSuccess({
      ...comp,
      userAdded: true,
      listingIdentifier: {
        mlsID: listing.mlsID,
        mlsNumber: listing.listingID
      }
    })
  );
}

function* handleAddCompByListing(action) {
  const { listing } = action.payload;
  try {
    if (!listing.address.hcAddressId) {
      throw new Error('Cannot Add Comp: Missing Address Id');
    }
    const selectedIds = yield select(getCompsSelectedIds);
    const subject = yield select(getSubjectAdjusted);
    if (selectedIds.includes(listing.address.hcAddressId)) {
      return yield put(searchByMlsNumberSelectDuplicateAddress(listing));
    } else if (listing.address.hcAddressId === subject.addressId) {
      return yield put(
        toastShow('Cannot add subject property as a comparable property')
      );
    }
    yield call(addListingAsComp, listing);
  } catch (e) {
    console.error(e);
    yield put(searchByMlsNumberSelectFailure(listing));
    report('Add Comp By Listing: Saga Failed to Fetch Data', { e, action });
  }
}

function* handleAddCompConfirm(action) {
  const listing = yield select(selSearchByMlsNumberListingToConfirm);
  try {
    yield call(addListingAsComp, listing);
  } catch (e) {
    console.error(e);
    report('Add Comp By Listing Confirm: Saga Failed to Fetch Data', {
      e,
      action
    });
  }
}

export default function registerSearchByMlsNumberSaga() {
  watchLatest({
    [SEARCH_BY_MLS_NUMBER_SUBMIT]: handleSearchByMlsNumber,
    [SEARCH_BY_MLS_NUMBER_SELECT]: handleAddCompByListing,
    [SEARCH_BY_MLS_NUMBER_SELECT_CONFIRM]: handleAddCompConfirm
  });
}
