import classNames from 'classnames';
import SVGInline from 'react-svg-inline';

import { isSvg } from 'legacy/utils/utils';

import { IconButton as theme } from 'legacy/css-modules/generic';

const IconButton = ({
  title,
  dataHcName,
  onClick,
  className,
  icon,
  disabled,
  height,
  width,
  label,
  children
}) => {
  const isSvgAsset = isSvg(icon);
  const handleClick = (e) => {
    e.stopPropagation();
    if (!disabled && onClick) {
      onClick(e);
    }
  };
  return (
    <span
      title={title}
      data-hc-name={dataHcName}
      onClick={handleClick}
      className={classNames(theme.IconButton, className, {
        [theme.disabled]: disabled,
        [theme.svgIcon]: isSvgAsset
      })}
    >
      {isSvgAsset ? (
        <SVGInline height={height} width={width} svg={icon} />
      ) : (
        icon
      )}
      {label && <span className={theme.IconButtonText}>{label}</span>}
      {children}
    </span>
  );
};

IconButton.defaultProps = {
  onClick: () => {},
  dataHcName: 'icon-button'
};

export default IconButton;
