import { useMemo } from 'react';
import { PreferencesKeys, usePreferences } from 'src/lib/huell';
import { COMP_FIELD_ORDER } from 'src/lib/utils/comp-fields.utils';
import { isLoading } from 'src/lib/utils/general';
import { CompFields } from '../types/CompFields';

// A Hook to return comp fields based on user preferences and defaults
export const useCompFields = () => {
  const { status, data } = usePreferences();
  const preferredOrder = data?.[PreferencesKeys.CompTableColumns]?.order;
  const preferredInactive = data?.[PreferencesKeys.CompTableColumns]?.inactive;
  return useMemo(() => {
    const order: CompFields[] = [CompFields.address];
    const active: { [key in CompFields]?: boolean } = {
      [CompFields.address]: true
    };
    const inactive: { [key in CompFields]?: boolean } = preferredInactive || {};
    // Columns that were added to the product after the user set their preferences
    // This feature is unused now but could be used to highlight new features for users.
    const newCols: { [key in CompFields]?: boolean } = {};

    if (!isLoading(status)) {
      preferredOrder?.forEach((compField) => {
        order.push(compField);
        active[compField] = true;
      });
      // Include any columns that were added to the product after preferences were set
      COMP_FIELD_ORDER.forEach((compField) => {
        if (!active[compField] && !inactive[compField]) {
          order.push(compField);
          newCols[compField] = true;
          active[compField] = true;
        }
      });
    }
    return {
      status,
      order,
      active,
      inactive,
      newCols
    };
  }, [preferredOrder, preferredInactive]);
};
