import React from 'react';
import styles from './SubjectDetailsTable.css';
import {
  DocumentRoles,
  SubjectDocument,
  useDocumentRole
} from '../../../../../src/lib/report-api';

import {
  formatBoolYesNo,
  formatMissing,
  formatMoney,
  formatNumber,
  formatPropertyType,
  formatSqFt
} from '../../../utils/formatters';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { isLoading } from 'src/lib/utils/general';
import { TwoColumnTable } from 'src/lib/components/TwoColumnTable';

interface Props {
  reportId: number;
  dataHcName: string;
}

export const SubjectDetailsTable = ({ reportId, dataHcName }: Props) => {
  const {
    data: [subjectDocument],
    status: subjectDocStatus
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);

  if (isLoading(subjectDocStatus) || subjectDocument == null) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const subjectData = subjectDocument.data;

  return (
    <div className={styles.SubjectDetailsTable} data-hc-name={dataHcName}>
      <div className={styles.LeftColumn}>
        <TwoColumnTable
          dataHcName={`${dataHcName}-left`}
          rows={[
            {
              label: 'Square Feet',
              value: formatSqFt(subjectData.livingSpace.livingArea)
            },
            {
              label: 'Lot Size',
              value: formatMissing(subjectData.site.areaDescription)
            },
            {
              label: 'Beds',
              value: formatNumber(subjectData.livingSpace.bedrooms?.count)
            },
            {
              label: 'Baths',
              value: formatNumber(
                subjectData.livingSpace.bathrooms?.summaryCount
              )
            },
            {
              label: 'Stories',
              value: formatNumber(subjectData.structure.stories)
            },
            {
              label: 'Property Type',
              value: formatPropertyType(subjectData.propertyType)
            },
            {
              label: 'Owner Occupied',
              value: formatBoolYesNo(subjectData.ownership?.ownerOccupied)
            },
            {
              label: 'Year Built',
              value: formatMissing(subjectData.structure.yearBuilt)
            },
            {
              label: 'Basement',
              value: formatBoolYesNo(
                subjectData.latestListing.livingSpace.basement.has
              )
            },
            {
              label: 'Pool',
              value: formatBoolYesNo(subjectData.latestListing.external.pool)
            },
            {
              label: 'Cumulative Days on Market',
              value: formatMissing(
                subjectData.latestListing.listingStatus.cumulativeDaysOnMarket
              )
            }
          ]}
        />
      </div>
      <div className={styles.RightColumn}>
        <TwoColumnTable
          dataHcName={`${dataHcName}-right`}
          rows={[
            {
              label: 'Garage Type',
              value: formatMissing(
                subjectData.externalFeatures?.parking.garageExperimental?.type
              )
            },
            {
              label: 'Garage Spaces',
              value: formatMissing(
                subjectData.externalFeatures?.parking.garageExperimental?.spaces
              )
            },
            {
              label: 'Condition',
              value: formatMissing(subjectData.condition?.rating)
            },
            {
              label: 'Subdivision',
              value: formatMissing(subjectData.countyLegal.subdivision)
            },
            {
              label: 'Zoning',
              value: formatMissing(subjectData.site.zoning?.code)
            },
            {
              label: 'HOA Name',
              value: formatMissing(subjectData.latestListing.association.name)
            },
            {
              label: 'HOA Fee',
              value: formatMoney(
                subjectData.latestListing.association.fees?.[0]?.amount
              )
            },
            {
              label: 'HOA Fee Frequency',
              value: formatMissing(
                subjectData?.latestListing.association.fees?.[0]?.frequency
              )
            },
            { label: 'HOA Includes', value: 'where do I find this' },
            {
              label: 'Tax Year',
              value: formatNumber(subjectData.latestListing.taxSummary.year)
            },
            {
              label: 'Tax Amount',
              value: formatMoney(subjectData.latestListing.taxSummary.amount)
            }
          ]}
        />
      </div>
    </div>
  );
};
