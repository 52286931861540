import { createSelector } from 'reselect';

export const getFullMlsCoverageState = (state) => state.mlsCoverage;

export const getPropertyMlsCoverageState = (addressId) =>
  createSelector(
    getFullMlsCoverageState,
    (fullMlsCoverageState) => fullMlsCoverageState[addressId]
  );

export const getPropertyMlsCoverageStatus = (addressId) =>
  createSelector(
    getPropertyMlsCoverageState(addressId),
    (propertyMlsCoverageState) => propertyMlsCoverageState?.status
  );

export const getPropertyMlsCoverageData = (addressId) =>
  createSelector(
    getPropertyMlsCoverageState(addressId),
    (propertyMlsCoverageState) => propertyMlsCoverageState?.data
  );
