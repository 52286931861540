import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import moment from 'moment';
import { RTThemedDatePicker } from '@hc/component-lib/hclib/rt-themed';

import { strToDateObj, normalizeDate } from 'legacy/utils/dates';

import { RTDatePicker as theme } from 'legacy/css-modules/generic';

const RTDatePicker = ({ input, meta, ...props }) => {
  return (
    <RTThemedDatePicker
      {...input}
      {...props}
      inputFormat={(d) => {
        const formatted = moment(d).format('MM/DD/YY');
        return !formatted || formatted === 'Invalid date' ? '' : formatted;
      }}
      inputClassName={theme.Input}
      className={theme.DatePicker}
      theme={theme}
      sundayFirstDayOfWeek
    />
  );
};

const HcDatePicker = ({ allowFuture = false, ...props }) => {
  return (
    <Field
      component={RTDatePicker}
      maxDate={!allowFuture && new Date()}
      {...props}
    />
  );
};

HcDatePicker.defaultProps = {
  type: 'text',
  format: (v) => (v ? strToDateObj(v) : null),
  normalize: normalizeDate
};

HcDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default HcDatePicker;
