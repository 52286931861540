import React from 'react';
import { CopyInput } from 'src/lib/components/CopyInput';
import styles from './ShareReport.css';

export const ShareReport = () => {
  return (
    <div data-hc-name="share-report" className={styles.ShareReport}>
      <p data-hc-name="share-report-message">
        Reports can be shared with people in your org. Copy the link below to
        share this report.
      </p>
      <CopyInput dataHcName="share-report-url" value={window.location.href} />
    </div>
  );
};
