export enum STATUSES {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export const NON_DISCLOSURE_STATES = [
  'AK',
  'ID',
  'KS',
  'LA',
  'MS',
  'MO',
  'MT',
  'NM',
  'ND',
  'TX',
  'UT',
  'WY'
];

export const NULL_VALUE = '––';

export const ID_FIXED_PORTAL = 'react-fixed-portal';
