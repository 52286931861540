import { call, put } from 'redux-saga/effects';

import HC_CONSTANTS from 'HC_CONSTANTS';

import apiUtil from 'legacy/utils/api';
import { watchEvery } from 'legacy/utils/saga';

import { downloadFile } from 'sagas/download-file';

import {
  ARCHIVED_REPORTS_FETCH_REQUEST,
  ARCHIVED_REPORTS_DOWNLOAD_REPORT,
  fetchArchivedReportsSuccess
} from 'actions/archived-reports';
import { toastShow } from 'actions/toast';

export function* fetchArchivedReports() {
  const reports = yield call(
    apiUtil.GET,
    `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/property_lookups/`
  );
  yield put(
    fetchArchivedReportsSuccess({
      ...reports,
      lookups: reports.lookups && reports.lookups.length ? reports.lookups : []
    })
  );
}

export function* downloadExpiredReport(action) {
  const { property, report } = action.payload;
  const fileName = `${
    property.streetAddress || property.addressSlug || property.addressId
  }-${report.validUntilDate || report.orderDate}.pdf`.replace(/\s/g, '-');
  const baseUrl = `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/download`;
  const url = `${baseUrl}/?download_id=${report.downloadId}`;
  yield put(toastShow('Your download will begin shortly...'));
  yield call(downloadFile, fileName, url);
}

export default function registerArchivedReportsSaga() {
  watchEvery({
    [ARCHIVED_REPORTS_FETCH_REQUEST]: fetchArchivedReports,
    [ARCHIVED_REPORTS_DOWNLOAD_REPORT]: downloadExpiredReport
  });
}
