import {
  SHARE_REPORT_OPEN,
  SHARE_REPORT_CLOSE,
  UNIQUE_LINK_FETCH_SUCCESS,
  UNIQUE_LINK_CONFIGURE_SUCCESS
} from 'actions/share-report';
import { GET_REPORT_SHARED } from 'actions/get-report';

const INITIAL_STATE = {
  open: false,
  uid: null,
  urlParams: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHARE_REPORT_OPEN:
      return {
        ...state,
        open: true
      };

    case SHARE_REPORT_CLOSE:
      return {
        ...state,
        open: false
      };

    case UNIQUE_LINK_FETCH_SUCCESS:
    case UNIQUE_LINK_CONFIGURE_SUCCESS:
      const urlParts =
        action.payload.value.url && action.payload.value.url.split('/');
      return {
        ...state,
        ...action.payload.value,
        uid: urlParts && urlParts[urlParts.length - 1].split('?')[0]
      };

    case GET_REPORT_SHARED:
      return {
        ...state,
        uid: action.payload.params.uid,
        urlParams: action.payload.params
      };

    default:
      return state;
  }
};
