import { GET_REPORT, GET_REPORT_HC_VERSION_SUCCESS } from 'actions/get-report';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';

const INITIAL_STATE = {
  userIsReportOwner: false,
  subscriptionType: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EFFECTIVE_DATE_GET_REPORT_SUCCESS: {
      return {
        ...state,
        userIsReportOwner: action.payload.userIsReportOwner,
        subscriptionType: action.payload.subscriptionType
      };
    }

    case GET_REPORT:
      return {
        ...state,
        userIsReportOwner: true
      };

    case GET_REPORT_HC_VERSION_SUCCESS:
      return {
        ...state,
        userIsReportOwner: !!action.payload.isReportOwner,
        subscriptionType: action.payload.subscriptionType
      };

    default:
      return state;
  }
};
