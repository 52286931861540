import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './TwoColumnTable.css';

interface Props {
  labelWidth?: string;
  rows: Array<{
    label: string | number | ReactNode | null;
    value: string | number | ReactNode | null;
  }>;
  className?: string;
  dataHcName: string;
}

export const TwoColumnTable = ({
  labelWidth = '150px',
  className,
  rows,
  dataHcName
}: Props) => {
  return (
    <div data-hc-name={dataHcName}>
      {rows.map((column, index) => {
        return (
          <div key={index} className={classNames(styles.Row, className)}>
            <div
              style={{ flex: `0 0 ${labelWidth}` }}
              className={styles.Label}
              data-hc-name={`${dataHcName}-label-${index}`}
            >
              {column.label}
            </div>
            <div
              className={styles.Value}
              data-hc-name={`${dataHcName}-value-${index}`}
            >
              {column.value}
            </div>
          </div>
        );
      })}
    </div>
  );
};
