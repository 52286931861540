import { createSelector } from 'reselect';

const getNearbyPropertiesState = (state) => state.nearbyProperties;

export const getNearbyProperties = createSelector(
  getNearbyPropertiesState,
  (nearbyPropertiesState) => nearbyPropertiesState.nearbyProperties
);

export const getNearbyPropertiesForValuationChart = createSelector(
  getNearbyProperties,
  (nearbyProperties) =>
    nearbyProperties.filter((c) => c && c.similarityLevel === 'high')
);

export const getNearbyPropertiesRental = createSelector(
  getNearbyPropertiesState,
  (nearbyPropertiesState) => nearbyPropertiesState.nearbyPropertiesRental
);
