import { call, put, select } from 'redux-saga/effects';
import { report as reportSentry } from 'hc-ravenjs-logger';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { EVENTS } from 'legacy/appstore/events';
import { ERROR_TEXT } from 'legacy/appstore/constants';

import apiUtil from 'legacy/utils/api';
import { watchEvery } from 'legacy/utils/saga';

import { ensureLoggedInThen } from 'sagas/auth';

import {
  COMPS_SORT_SELECTED,
  COMPS_CLEAR_FILTER,
  COMPS_FARM_UPDATE_SUCCESS,
  COMPS_FILTERS_CHANGE_ABSOLUTE,
  COMPS_FILTERS_CHANGE_RELATIVE,
  COMPS_TAG_ADD,
  COMPS_TAG_REMOVE
} from 'actions/comps';
import {
  SAVE_REPORT,
  SAVE_REPORT_EXECUTE,
  SAVE_REPORT_SUCCESS,
  saveReport,
  saveReportQueue,
  saveReportExecute,
  saveReportSuccess,
  saveReportComplete
} from 'actions/edit-report';
import { GET_REPORT_NEARBY_PROPERTIES_SUCCESS } from 'actions/get-report';
import {
  expectedErrorModalShow,
  errorModalShow,
  expectedNotDismissibleErrorModalShow
} from 'actions/error';
import {
  RENTAL_COMPS_SORT_SELECTED,
  RENTAL_COMPS_CLEAR_FILTER,
  RENTAL_COMPS_FARM_UPDATE_SUCCESS,
  RENTAL_COMPS_FILTERS_CHANGE_ABSOLUTE,
  RENTAL_COMPS_FILTERS_CHANGE_RELATIVE
} from 'actions/rental-comps';

import { getIsLoggedIn } from 'selectors/auth';
import {
  getIsViewingSharedReport,
  getUseVRShareEndpoint
} from 'selectors/property-explorer';
import { getReportJson } from 'selectors/report-json';
import { getIsReportSaveable } from 'selectors/report-permissions';
import {
  getReportStatusIsReadyForSave,
  getReportStatusSaveInProgress,
  getReportStatusSaveIsQueued
} from 'selectors/report-status';
import { getUniqueLinkUrlParams } from 'selectors/share-report';
import { getSubjectDefault } from 'selectors/subject';
import { getIsEffectiveDateReport } from '../selectors/property-explorer.selectors';

function* handleSaveReportSideEffect(action) {
  const isEffectiveDate = yield select(getIsEffectiveDateReport);
  if (isEffectiveDate) return;
  if (
    (action.type === COMPS_CLEAR_FILTER ||
      action.type === RENTAL_COMPS_CLEAR_FILTER) &&
    action.payload.filterKey === 'distance'
  ) {
    // Do not save if the distance filter is being cleared. The comps saga will call save
  } else {
    yield put(saveReport());
  }
}

function* handleSaveReportSideEffectOnlyIfLoggedIn() {
  yield call(handleSaveReport, {
    payload: {
      saveOnlyIfLoggedIn: true
    }
  });
}

function* handleSaveReport(action) {
  const isEffectiveDate = yield select(getIsEffectiveDateReport);
  if (isEffectiveDate) return;
  const { saveOnlyIfLoggedIn } = action.payload;
  const isSaveable = yield select(getIsReportSaveable);
  const isLoaded = yield select(getReportStatusIsReadyForSave);
  const isSharedReport = yield select(getIsViewingSharedReport);
  const isLoggedIn = yield select(getIsLoggedIn);
  if (
    isLoaded &&
    isSaveable &&
    (!saveOnlyIfLoggedIn || (saveOnlyIfLoggedIn && isLoggedIn))
  ) {
    const isSaving = yield select(getReportStatusSaveInProgress);
    const nextAction = isSaving ? saveReportQueue : saveReportExecute;

    if (isSharedReport && !isLoggedIn) {
      yield call(ensureLoggedInThen, nextAction);
    } else {
      yield put(nextAction());
    }
  }
}

function* handleSaveReportSuccess(action) {
  // Whether to send the save request again, with an updated `revisionId`
  const saveIsQueued = yield select(getReportStatusSaveIsQueued);
  if (saveIsQueued) {
    yield put(saveReport());
  } else {
    // Show a success toast to the user and clear navigation confirm popup
    yield put(saveReportComplete());
  }
}

function* executeSave(action) {
  const isEffectiveDate = yield select(getIsEffectiveDateReport);
  if (isEffectiveDate) return;
  const report = yield select(getReportJson);
  const subject = yield select(getSubjectDefault);
  const useVRShareEndpoint = yield select(getUseVRShareEndpoint);
  const data = {
    data: { ...report },
    streetAddress: subject.streetAddress,
    zipcode: subject.zipcode,
    unit: subject.unit,
    revisionId: report.revisionId
  };
  const url = useVRShareEndpoint
    ? // VRSHARE_PROXY_URL bypasses authentication in ehrmantraut auth handlers therefore,
      // intentionally using HUELL_PROXY_URL to restrict save to only logged in users.
      `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/vrshare/save`
    : `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/user_version/`;
  const urlParams = useVRShareEndpoint
    ? yield select(getUniqueLinkUrlParams)
    : {};

  const reportIsSaveable = yield select(getIsReportSaveable);
  if (reportIsSaveable) {
    try {
      const responseData = yield call(apiUtil.POST, url, data, {
        urlParams,
        expect404: true
      });
      yield put(saveReportSuccess(subject, responseData));
    } catch (e) {
      if (e.statusCode === 409) {
        yield put(
          expectedNotDismissibleErrorModalShow(
            ERROR_TEXT.VALUE_REPORT.expiredVersion,
            null,
            {
              analytics: {
                event: EVENTS.REPORT_SIMULTANEOUS_EDIT_ERROR
              }
            }
          )
        );
      } else if (e.statusCode === 403) {
        yield put(
          expectedErrorModalShow(ERROR_TEXT.VALUE_REPORT.editDisallowed)
        );
      } else {
        // Rethrow for Sentry reporting
        throw e;
      }
    }
  } else {
    // Report does not have save access.  We should never get here since the UI
    // for saving should be disabled in this case.
    reportSentry(
      'Share Report Save Error: Attempted to save without save access'
    );
    yield put(errorModalShow());
  }
}

export default () => {
  watchEvery([SAVE_REPORT], handleSaveReport);

  watchEvery(
    [
      COMPS_SORT_SELECTED,
      RENTAL_COMPS_SORT_SELECTED,
      COMPS_CLEAR_FILTER,
      RENTAL_COMPS_CLEAR_FILTER,
      COMPS_FILTERS_CHANGE_ABSOLUTE,
      COMPS_FILTERS_CHANGE_RELATIVE,
      RENTAL_COMPS_FILTERS_CHANGE_ABSOLUTE,
      RENTAL_COMPS_FILTERS_CHANGE_RELATIVE,
      COMPS_TAG_ADD,
      COMPS_TAG_REMOVE
    ],
    handleSaveReportSideEffect
  );

  watchEvery(
    [
      COMPS_FARM_UPDATE_SUCCESS,
      RENTAL_COMPS_FARM_UPDATE_SUCCESS,
      GET_REPORT_NEARBY_PROPERTIES_SUCCESS
    ],
    handleSaveReportSideEffectOnlyIfLoggedIn
  );

  watchEvery([SAVE_REPORT_EXECUTE], executeSave);
  watchEvery([SAVE_REPORT_SUCCESS], handleSaveReportSuccess);
};
