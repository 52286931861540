import { connect } from 'react-redux';

import { COMP_TYPE_RENTAL, COMP_TYPE_DEFAULT } from 'legacy/appstore/constants';
import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

import { propertyQueryDetailsComplete } from 'actions/property';

import { getPropertyListingsData } from 'selectors/listings';
import { getPropertySourceDataForQuery } from 'selectors/property';

import addressIdRequired from 'legacy/hocs/addressIdRequired';
import dispatchOnMount from 'legacy/hocs/dispatchOnMount';

import { PublicRemarksKeywords } from 'legacy/containers/public-remarks-keywords';
import PopoverEndgame from 'legacy/components/PopoverEndgame';

import { ViewListingRemarks as theme } from 'legacy/css-modules';
import { getRentalCompByAddressId } from 'selectors/rental-comps';
import { getCompByAddressId } from 'selectors/comps';
import { useListing } from 'legacy/hocs/useListing';

const { MLS } = PROPERTY_DATA_SOURCES;

const mapStateToProps = (state, ownProps) => {
  const { addressId, compType } = ownProps;
  let listingDetails;
  if (addressId === 'subject' || !compType) {
    listingDetails = getPropertySourceDataForQuery(
      addressId,
      MLS,
      QUERIES.LISTING_DETAILS
    )(state);
  } else {
    const listings = getPropertyListingsData(addressId)(state);
    const comp =
      compType === COMP_TYPE_RENTAL
        ? getRentalCompByAddressId(addressId)(state)
        : getCompByAddressId(addressId)(state);
    listingDetails = listings?.find((l) => {
      if (
        (compType === COMP_TYPE_RENTAL &&
          l.isListingRental &&
          !!l.publicRemarks) ||
        (compType === COMP_TYPE_DEFAULT &&
          !l.isListingRental &&
          !!l.publicRemarks)
      ) {
        if (comp.listingId) {
          if (comp.listingId === l.listingID) {
            return true;
          }
        } else {
          return true;
        }
      }
    });
  }

  return {
    compType,
    listingDetails
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { addressId } = ownProps;
  return {
    onMount: () => {
      dispatch(propertyQueryDetailsComplete(addressId));
    }
  };
};

const ListingRemarksPopover = ({
  addressId,
  listingId,
  listingDetails,
  compType,
  ...props
}) => {
  const { listing } = useListing(addressId, { listingId });
  if (listing?.publicRemarks) {
    return (
      <PopoverEndgame
        trigger={
          <span className={theme.ViewListingRemarks}>View Listing Remarks</span>
        }
        content={
          <div className={theme.ListingRemarksContent}>
            <h3 className={theme.Title}>Listing Remarks</h3>
            <PublicRemarksKeywords
              addressId={addressId}
              compType={compType}
              value={listingDetails.publicRemarks}
              expanded
            />
          </div>
        }
        showOnMouseEnter
      />
    );
  } else {
    return null;
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(addressIdRequired(dispatchOnMount(ListingRemarksPopover)));
