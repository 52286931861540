import { STATUSES } from 'legacy/appstore/constants';

import {
  PREFERENCES_FETCH,
  PREFERENCES_FETCH_SUCCESS,
  PREFERENCES_FETCH_FAILURE,
  PREFERENCES_SAVE,
  PREFERENCES_SAVE_SUCCESS,
  PREFERENCES_SAVE_FAILURE,
  PREFERENCES_SAVE_FILTERS_MODAL_TOGGLE,
  PREFERENCES_COMP_TABLE_OPTIONS_MODAL_TOGGLE
} from 'actions/preferences';

const INITIAL_STATE_COMP_TABLE_OPTIONS_MODAL = { active: false };

// Values Example
// {
//   [FILTERS_COMPS/FITLTERS_RENTAL_COMPS]: {
//     active_filter_set: filter_set_id,
//     filter_sets: {
//       [filter_set_id]: {
//         values: {
//           [filter_key]: {
//             // This is nested to support multiple ways of saving a particular filter
//             // This example shows a filter value range based on a % of subject value
//             save_type: 'REL_PCT', // key for determining how to calculate and apply saved filter values
//             value: [-0.1, 0.2], // filters for properies that are -10% to +20% of the subject value
//           },
//           ...other_filter_values
//         },
//         label: 'Label for filter set',
//           created_by: report_id
//       }
//     }
//   },
//   [TABLE_COLUMNS_COMPS/TABLE_COLUMNS_RENTAL_COMPS]: {
//     inactive: {
//       [column_key]: true
//     }
//     order: [column_key1, column_key2]
//   }
// }

const INITIAL_STATE = {
  status: STATUSES.INIT,
  saveFiltersModalActive: false,
  compTableOptionsDialog: { ...INITIAL_STATE_COMP_TABLE_OPTIONS_MODAL },
  values: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PREFERENCES_FETCH:
      return {
        ...state,
        status: STATUSES.LOADING
      };

    case PREFERENCES_FETCH_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        values: action.payload.values
      };

    case PREFERENCES_FETCH_FAILURE:
      return {
        ...state,
        status:
          action.payload.errorCode === 404
            ? STATUSES.DOES_NOT_EXIST
            : STATUSES.LOADING
      };

    case PREFERENCES_SAVE:
      return {
        ...state,
        status: STATUSES.UPDATING
      };

    case PREFERENCES_SAVE_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        values: action.payload.values,
        saveFiltersModalActive: false,
        compTableOptionsDialog: {
          ...state.compTableOptionsDialog,
          active: false
        }
      };

    case PREFERENCES_SAVE_FAILURE:
      return {
        ...state,
        status: STATUSES.ERROR
      };

    case PREFERENCES_SAVE_FILTERS_MODAL_TOGGLE:
      return {
        ...state,
        saveFiltersModalActive: state.saveFiltersModalActive
          ? false
          : action.payload.modalType
      };

    case PREFERENCES_COMP_TABLE_OPTIONS_MODAL_TOGGLE:
      return {
        ...state,
        compTableOptionsDialog: {
          ...state.compTableOptionsDialog,
          active: !state.compTableOptionsDialog.active
        }
      };

    default:
      return state;
  }
};
