const Info = (props) => {
  return (
    <svg
      width="15px"
      height="15px"
      viewBox="0 0 15 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="VR_Input_Value_Form_Complete-Copy-9"
          transform="translate(-413.000000, -745.000000)"
          fill="#4A4A4A"
          fillRule="nonzero"
        >
          <g id="icon-info" transform="translate(413.000000, 745.000000)">
            <path
              d="M7.5,0 C3.36,0 0,3.36 0,7.5 C0,11.64 3.36,15 7.5,15 C11.64,15 15,11.64 15,7.5 C15,3.36 11.64,0 7.5,0 Z M8.25,11.25 L6.75,11.25 L6.75,6.75 L8.25,6.75 L8.25,11.25 Z M8.25,5.25 L6.75,5.25 L6.75,3.75 L8.25,3.75 L8.25,5.25 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Info;
