import get from 'lodash/get';
import forOwn from 'lodash/forOwn';

export const getAllFormValues = (formName) => (state) => {
  const formState = get(state, ['form', formName], {});
  return {
    ...formState.initial,
    ...formState.values
  };
};

export const getChangedFormValues = (formName) => (state) => {
  const formState = get(state, ['form', formName], {});
  let results = {};
  forOwn(formState.values, (value, field) => {
    const valueInitial = get(formState, ['initial', field], null);
    // Handle cases where the intended select value is null
    if (value === 'null') {
      value = null;
    }
    if (
      formState.fields &&
      formState.fields[field] &&
      (formState.fields[field].touched || formState.fields[field].visited) &&
      value !== valueInitial
    ) {
      results[field] = value;
    }
  });
  return results;
};
