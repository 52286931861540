export const INIT_FORGOT_PASSWORD = 'INIT_FORGOT_PASSWORD';
export const SUBMIT_FORGOT_PASSWORD = 'SUBMIT_FORGOT_PASSWORD';
export const SUBMIT_FORGOT_PASSWORD_SUCCESS = 'SUBMIT_FORGOT_PASSWORD_SUCCESS';
export const SUBMIT_FORGOT_PASSWORD_ERROR = 'SUBMIT_FORGOT_PASSWORD_ERROR';

export const VERIFY_RESET_PASSWORD = 'VERIFY_RESET_PASSWORD';
export const VERIFY_RESET_PASSWORD_SUCCESS = 'VERIFY_RESET_PASSWORD_SUCCESS';
export const VERIFY_RESET_PASSWORD_FAILURE = 'VERIFY_RESET_PASSWORD_FAILURE';
export const VERIFY_RESET_PASSWORD_EXPIRED = 'VERIFY_RESET_PASSWORD_EXPIRED';

export const SUBMIT_PASSWORD_RESET = 'SUBMIT_PASSWORD_RESET';
export const SUBMIT_PASSWORD_RESET_SUCCESS = 'SUBMIT_PASSWORD_RESET_SUCCESS';
export const SUBMIT_PASSWORD_RESET_ERROR = 'SUBMIT_PASSWORD_RESET_ERROR';

export const initForgotPassword = () => ({ type: INIT_FORGOT_PASSWORD });

export const submitForgotPassword = (email, onSuccess, onFailure) => ({
  type: SUBMIT_FORGOT_PASSWORD,
  payload: { email }
});
export const submitForgotPasswordSuccess = (message) => ({
  type: SUBMIT_FORGOT_PASSWORD_SUCCESS,
  meta: { toast: { message } }
});
export const submitForgotPasswordError = (error) => ({
  type: SUBMIT_FORGOT_PASSWORD_ERROR,
  payload: { error }
});

export const verifyResetPassword = (token) => ({
  type: VERIFY_RESET_PASSWORD,
  payload: { token }
});
export const verifyResetPasswordSuccess = (temporaryToken) => ({
  type: VERIFY_RESET_PASSWORD_SUCCESS,
  payload: { temporaryToken }
});
export const verifyResetPasswordFailure = (error) => ({
  type: VERIFY_RESET_PASSWORD_FAILURE,
  payload: { error }
});
export const verifyResetPasswordExpired = (error) => ({
  type: VERIFY_RESET_PASSWORD_EXPIRED,
  payload: { error }
});

export const submitPasswordReset = (newPassword) => ({
  type: SUBMIT_PASSWORD_RESET,
  payload: { newPassword }
});
export const submitPasswordResetSuccess = (message) => ({
  type: SUBMIT_PASSWORD_RESET_SUCCESS,
  meta: { toast: { message } }
});
export const submitPasswordResetError = () => ({
  type: SUBMIT_PASSWORD_RESET_ERROR
});
