import React, { useState } from 'react';
import styles from './CompFarmList.css';
import classNames from 'classnames';
import { CompFarmListHeader, ListViewType } from './CompFarmListHeader';
import { CompFarmListCards } from './CompFarmListCards';
import { CompFarmListTable } from './CompFarmListTable';
import { CompSchema } from '../../types/schemas';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
  selectedCompsOnly?: boolean;
  listViewType?: ListViewType;
  onMouseEnter?: (hovered: {
    compSchema: CompSchema;
    documentId: string | undefined;
  }) => void;
  onMouseLeave?: VoidFunction;
  onChangeListViewType?: (listViewType: ListViewType) => void;
  onChangeSelectedCompsOnly?: (selectedCompsOnly: boolean) => void;
}
export const CompFarmList = ({
  dataHcName,
  reportId,
  className,
  listViewType = ListViewType.Card,
  selectedCompsOnly = false,
  onChangeListViewType,
  onChangeSelectedCompsOnly,
  onMouseEnter,
  onMouseLeave
}: Props) => {
  const [activeListViewType, setActiveListViewType] =
    useState<ListViewType>(listViewType);
  return (
    <section
      data-hc-name={dataHcName}
      className={classNames(styles.CompFarmList, className)}
    >
      <CompFarmListHeader
        reportId={reportId}
        dataHcName={`${dataHcName}-header`}
        listViewType={activeListViewType}
        selectedCompsOnly={selectedCompsOnly}
        onChangeListViewType={(newListViewType) => {
          setActiveListViewType(newListViewType);
          onChangeListViewType?.(newListViewType);
        }}
        onChangeSelectedCompsOnly={(newSelectedCompsOnly) => {
          onChangeSelectedCompsOnly?.(newSelectedCompsOnly);
        }}
      />
      {activeListViewType === ListViewType.Card && (
        <CompFarmListCards
          reportId={reportId}
          dataHcName={`${dataHcName}-cards`}
          selectedCompsOnly={selectedCompsOnly}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )}
      {(activeListViewType === ListViewType.Table ||
        activeListViewType === ListViewType.Compact) && (
        <CompFarmListTable
          reportId={reportId}
          dataHcName={`${dataHcName}-table`}
          selectedCompsOnly={selectedCompsOnly}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          compact={activeListViewType === ListViewType.Compact}
        />
      )}
    </section>
  );
};
