import { Operation } from 'fast-json-patch';
import { Document } from '../types';

export const DOCUMENT_REQUIRED = 'report-api/DOCUMENT_REQUIRED';
export const DOCUMENT_FETCH = 'report-api/DOCUMENT_FETCH';
export const DOCUMENT_FETCH_SUCCESS = 'report-api/DOCUMENT_FETCH_SUCCESS';
export const DOCUMENT_FETCH_ERROR = 'report-api/DOCUMENT_FETCH_ERROR';

export const DOCUMENT_PATCH = 'report-api/DOCUMENT_PATCH';
export const DOCUMENT_PATCH_SUCCESS = 'report-api/DOCUMENT_PATCH_SUCCESS';
export const DOCUMENT_PATCH_ERROR = 'report-api/DOCUMENT_PATCH_ERROR';

export const DOCUMENT_DELETE = 'report-api/DOCUMENT_DELETE';
export const DOCUMENT_DELETE_SUCCESS = 'report-api/DOCUMENT_DELETE_SUCCESS';
export const DOCUMENT_DELETE_ERROR = 'report-api/DOCUMENT_DELETE_ERROR';

// Currently only dispatched when actions are successful
export const DOCUMENTS_CREATE_SUCCESS = 'report-api/DOCUMENTS_CREATE_SUCCESS';

export interface DocumentRequiredAction {
  type: typeof DOCUMENT_REQUIRED;
  payload: {
    reportId: number;
    documentId: string;
  };
}
export const documentRequired = (
  reportId: number,
  documentId: string
): DocumentRequiredAction => {
  return {
    type: DOCUMENT_REQUIRED,
    payload: {
      reportId,
      documentId
    }
  };
};

export interface DocumentFetchAction {
  type: typeof DOCUMENT_FETCH;
  payload: {
    reportId: number;
    documentId: string;
  };
}
export const documentFetch = (
  reportId: number,
  documentId: string
): DocumentFetchAction => {
  return {
    type: DOCUMENT_FETCH,
    payload: {
      reportId,
      documentId
    }
  };
};

export interface DocumentFetchSuccessAction {
  type: typeof DOCUMENT_FETCH_SUCCESS;
  payload: {
    reportId: number;
    documentId: string;
    document: Document;
  };
}
export const documentFetchSuccess = (
  reportId: number,
  documentId: string,
  document: Document
): DocumentFetchSuccessAction => {
  return {
    type: DOCUMENT_FETCH_SUCCESS,
    payload: {
      reportId,
      documentId,
      document
    }
  };
};

export interface DocumentFetchErrorAction {
  type: typeof DOCUMENT_FETCH_ERROR;
  payload: {
    reportId: number;
    documentId: string;
    message: string;
  };
}
export const documentFetchError = (
  reportId: number,
  documentId: string,
  message: string
): DocumentFetchErrorAction => {
  return {
    type: DOCUMENT_FETCH_ERROR,
    payload: {
      reportId,
      documentId,
      message
    }
  };
};

export interface DocumentPatchAction {
  type: typeof DOCUMENT_PATCH;
  payload: {
    reportId: number;
    documentId: string;
    operations: Operation[];
  };
}
export const documentPatch = (
  reportId: number,
  documentId: string,
  operations: Operation[]
): DocumentPatchAction => {
  return {
    type: DOCUMENT_PATCH,
    payload: { reportId, documentId, operations }
  };
};

export interface DocumentPatchSuccessAction {
  type: typeof DOCUMENT_PATCH_SUCCESS;
  payload: {
    reportId: number;
    documentId: string;
    documents: Document[]; // NOTE: this may change after talking to eugene
  };
}
export const documentPatchSuccess = (
  reportId: number,
  documentId: string,
  documents: Document[]
): DocumentPatchSuccessAction => {
  return {
    type: DOCUMENT_PATCH_SUCCESS,
    payload: {
      reportId,
      documentId,
      documents
    }
  };
};

export interface DocumentDeleteAction {
  type: typeof DOCUMENT_DELETE;
  payload: {
    reportId: number;
    documentId: string;
  };
}
export const documentDelete = (
  reportId: number,
  documentId: string
): DocumentDeleteAction => {
  return {
    type: DOCUMENT_DELETE,
    payload: { reportId, documentId }
  };
};

export interface DocumentDeleteSuccessAction {
  type: typeof DOCUMENT_DELETE_SUCCESS;
  payload: {
    reportId: number;
    documentId: string;
  };
}
export const documentDeleteSuccess = (
  reportId: number,
  documentId: string
): DocumentDeleteSuccessAction => {
  return {
    type: DOCUMENT_DELETE_SUCCESS,
    payload: { reportId, documentId }
  };
};

export interface DocumentsCreateSuccessAction {
  type: typeof DOCUMENTS_CREATE_SUCCESS;
  payload: {
    reportId: number;
    documents: Document[];
  };
}
export const documentsCreateSuccess = (
  reportId: number,
  documents: Document[]
): DocumentsCreateSuccessAction => {
  return {
    type: DOCUMENTS_CREATE_SUCCESS,
    payload: { reportId, documents }
  };
};

export type DocumentActions =
  | DocumentRequiredAction
  | DocumentFetchAction
  | DocumentFetchSuccessAction
  | DocumentFetchErrorAction
  | DocumentPatchAction
  | DocumentPatchSuccessAction
  | DocumentDeleteAction
  | DocumentDeleteSuccessAction
  | DocumentsCreateSuccessAction;
