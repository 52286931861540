import { isString } from 'legacy/utils/utils';

export const removeSpecialChars = (str, replace = '') =>
  str
    .replace(/(?!\w|\s)./g, replace) // Remove any character that is not a word or whitespace
    .replace(/\s+/g, ' ') // Find any appearance of 1 or more whitespaces and replace it with one single white space
    .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2'); // Trim the string to remove any whitespace at the beginning or the end.

export const strToInt = (v) => {
  if (!isString(v)) return v;
  const num = parseInt(v.replace(/[^\d.]/g, ''));
  return isNaN(num) ? '' : num;
};

export const capitalizeFirstLetter = (str) => {
  str = str.split(',');
  return str
    .map((s) => {
      s = s.trim();
      return s.charAt(0).toUpperCase() + s.slice(1);
    })
    .join(',');
};

export const removeNonNumeric = (str) => str.replace(/\D/g, '');
export const removeNonNumericExceptDec = (str) =>
  isString(str) ? str.replace(/[^0-9.]/g, '') : str;

// Counts the number of commas in a string
export const numCommas = (v) => (v.match(/,/g) || []).length;
