import { watchEvery } from 'legacy/utils/saga';
import { call, put, select } from 'redux-saga/effects';

import HC_CONSTANTS from 'HC_CONSTANTS';

import apiUtil from 'legacy/utils/api';

import { PUT_ACCOUNT_DETAILS_ON_STATE } from 'actions/account-details';
import {
  RECENT_REPORTS_FETCH_REQUEST,
  fetchRecentReportsSuccess,
  recentReportsStartRequest
} from 'actions/recent-reports';

import { getIsRecentReportsStatusInit } from 'selectors/recent-reports';
import { getIsLoggedIn } from 'selectors/auth';

const RESULT_LIMIT = 1000;

export function* fetchRecentReports(action) {
  const limit = action.payload.limit || RESULT_LIMIT;
  const isAuthenticated = yield select(getIsLoggedIn);
  const hasRecentReportsStatusInit = yield select(getIsRecentReportsStatusInit);
  let recentReports = { lookups: [] };

  if (isAuthenticated && hasRecentReportsStatusInit) {
    yield put(recentReportsStartRequest());
    const params = {
      limit,
      active: true
    };
    recentReports = yield call(
      apiUtil.GET,
      `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/property_lookups/`,
      params
    );
    yield put(fetchRecentReportsSuccess(recentReports));
  }
  return recentReports.lookups;
}

export function registerRecentReportsSaga() {
  watchEvery({
    [RECENT_REPORTS_FETCH_REQUEST]: fetchRecentReports,
    [PUT_ACCOUNT_DETAILS_ON_STATE]: fetchRecentReports
  });
}
