import {
  ORGANIZATION_USAGE_FETCH_REQUEST,
  ORGANIZATION_USAGE_SUCCESS,
  ORGANIZATION_USAGE_ACCESS_SUCCESS,
  ORGANIZATION_USAGE_CLOSE_TRIAL_EXPIRED_MODAL
} from '../actions/organization-usage';

import { STATUSES } from 'legacy/appstore/constants';

const INITIAL_STATE = {
  status: STATUSES.INIT,
  // Modal is opened automatically based on returned trial usage, then must be closed by user
  isInactiveTrialModalDismissed: false
};

export function organizationUsageReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ORGANIZATION_USAGE_FETCH_REQUEST:
      return {
        ...state,
        status: STATUSES.LOADING
      };
    case ORGANIZATION_USAGE_SUCCESS:
      const { orgUsage } = action.payload;
      return {
        ...state,
        ...orgUsage,
        status: STATUSES.SUCCESS
      };

    case ORGANIZATION_USAGE_ACCESS_SUCCESS:
      const { access } = action.payload;
      return {
        ...state,
        ...access,
        status: STATUSES.SUCCESS
      };

    case ORGANIZATION_USAGE_CLOSE_TRIAL_EXPIRED_MODAL:
      return {
        ...state,
        isInactiveTrialModalDismissed: true
      };
    default:
      return state;
  }
}
