import get from 'lodash/get';
import { ROUTE_UPDATE } from '@hc/redux-saga-router-plus/hclib/actions';

import { PROPERTY_DETAILS_TYPE_SOURCES } from 'legacy/appstore/constants';

import {
  REPORT_TOGGLE_HEADER_MAP_CONTROLS,
  REPORT_ACTIVE_MAP_LAYERS,
  REPORT_SCROLL_TO_SECTION,
  REPORT_CHANGE_FORECAST_AREA_TYPE,
  REPORT_UPDATE_COMMENT
} from 'actions/property-explorer';
import {
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_USER_VERSION_NOT_FOUND,
  GET_REPORT_FORECAST_SUCCESS
} from 'actions/get-report';
import {
  VALUE_REPORT_EDIT_PROPERTY_DETAILS,
  VALUE_REPORT_EDIT_PROPERTY_DETAILS_REVERT,
  VR_RECOMPUTE
} from 'actions/edit-report';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';

const DEFAULT_ACTIVE_MAP_LAYERS = ['median'];

const INITIAL_STATE = {
  isEditingSubject: false,
  isShowingHeaderMapControls: false,
  activeMapLayerIds: null,
  scrollToSection: null,
  forecastChartAreaType: 'zip',
  forecastChartDataMissing: false,
  comments: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REPORT_TOGGLE_HEADER_MAP_CONTROLS:
      return {
        ...state,
        isShowingHeaderMapControls: !state.isShowingHeaderMapControls
      };
    case REPORT_ACTIVE_MAP_LAYERS:
      return {
        ...state,
        activeMapLayerIds: action.payload
      };
    // Used both for navigating between pages and scrolling within a page
    case REPORT_SCROLL_TO_SECTION:
      return {
        ...state,
        scrollToSection: action.payload.section
      };

    case EFFECTIVE_DATE_GET_REPORT_SUCCESS:
    case GET_REPORT_USER_VERSION_SUCCESS:
      const activeMapLayerIds = get(
        action.payload,
        ['report', 'metadata', 'activeMapLayers'],
        []
      );
      return {
        ...state,
        activeMapLayerIds,
        isShowingHeaderMapControls:
          activeMapLayerIds && activeMapLayerIds.length,
        forecastChartAreaType: state.forecastChartDataMissing
          ? state.forecastChartAreaType
          : get(
              action.payload,
              ['report', 'metadata', 'forecastChartAreaType'],
              INITIAL_STATE.forecastChartAreaType
            ),
        comments: get(
          action.payload,
          ['report', 'metadata', 'comments'],
          INITIAL_STATE.comments
        )
      };

    // When no user version exists, activate fallback/default map layers
    case GET_REPORT_USER_VERSION_NOT_FOUND:
      return {
        ...state,
        activeMapLayerIds: DEFAULT_ACTIVE_MAP_LAYERS,
        isShowingHeaderMapControls:
          DEFAULT_ACTIVE_MAP_LAYERS && DEFAULT_ACTIVE_MAP_LAYERS.length
      };

    case REPORT_CHANGE_FORECAST_AREA_TYPE: {
      return {
        ...state,
        forecastChartAreaType: action.payload.areaType
      };
    }

    case GET_REPORT_FORECAST_SUCCESS:
      // Handle cases where one of the data sets is missing
      const { hpi } = action.payload;
      let initialForecastChartAreaType = state.forecastChartAreaType;
      let forecastChartDataMissing = false;
      if (
        hpi.blockTs &&
        hpi.blockTs.length &&
        (!hpi.zipTs || !hpi.zipTs.length)
      ) {
        // Default to block if there is block data but no zip data
        initialForecastChartAreaType = 'block';
        forecastChartDataMissing = true;
      } else if (
        hpi.zipTs &&
        hpi.zipTs.length &&
        (!hpi.blockTs || !hpi.blockTs.length)
      ) {
        // Default to zip if there is zip data but no block data
        initialForecastChartAreaType = 'zip';
        forecastChartDataMissing = true;
      }
      return {
        ...state,
        forecastChartAreaType: initialForecastChartAreaType,
        forecastChartDataMissing
      };

    case REPORT_UPDATE_COMMENT: {
      return {
        ...state,
        comments: action.payload.commentValue
      };
    }

    case VALUE_REPORT_EDIT_PROPERTY_DETAILS:
      return {
        ...state,
        isEditingSubject: true
      };

    case VR_RECOMPUTE:
    case VALUE_REPORT_EDIT_PROPERTY_DETAILS_REVERT:
      return {
        ...state,
        isEditingSubject: false
      };

    case ROUTE_UPDATE:
      if (
        get(action, ['payload', 'query', 'visiblePropertyDetailsType']) ===
        PROPERTY_DETAILS_TYPE_SOURCES
      ) {
        return {
          ...state,
          isEditingSubject: false
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};
