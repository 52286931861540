import { createSelector } from 'reselect';

const getSelfServiceState = (state) => state.selfService;

export const getSelfServiceIntentType = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.intentType
);

// PACKAGES
export const getSelfServicePackagesStatus = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.packages.status
);

export const getSelfServicePackages = createSelector(
  getSelfServiceState,
  (selfServiceState) => {
    return selfServiceState.packages.data.packages;
  }
);

export const getSelfServiceSelectedPackageId = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.packages.data.selectedPackageId
);

export const getSelfServiceSelectedPackage = createSelector(
  getSelfServiceSelectedPackageId,
  getSelfServicePackages,
  (selectedPackageId, packages) => {
    return packages?.find?.((ssPackage) => ssPackage.id === selectedPackageId);
  }
);

// PAYMENT METHODS
export const getSelfServicePaymentMethodsStatus = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.paymentMethods.status
);

export const getSelfServicePaymentMethods = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.paymentMethods.data
);

// SETUP INTENT
export const getSelfServiceSetupIntentStatus = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.setupIntent.status
);

export const getSelfServiceSetupIntentWebhookStatus = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.setupIntent.webhookStatus
);

export const getSelfServiceSetupIntentClientSecret = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.setupIntent.data.clientSecret
);

export const getSelfServiceSetupIntentFreeItemsAdded = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.setupIntent.data.freeItemsAdded
);

export const getSelfServiceSetupIntentIsFirstTimeSetup = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.setupIntent.data.isFirstTimeSetup
);

// PAYMENT INTENT
export const getSelfServicePaymentIntentStatus = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.paymentIntent.status
);

export const getSelfServicePaymentIntentWebhookStatus = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.paymentIntent.webhookStatus
);

export const getSelfServicePaymentIntentClientSecret = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.paymentIntent.data.clientSecret
);

export const getSelfServicePaymentIntentAuthenticationRequired = createSelector(
  getSelfServiceState,
  (selfServiceState) =>
    selfServiceState.paymentIntent.data.authenticationRequired
);

export const getSelfServicePaymentIntentPaymentMethodId = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.paymentIntent.data.paymentMethodId
);

export const getSelfServicePaymentIntentErrorCode = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.paymentIntent.errorCode
);

export const getSelfServicePaymentIntentErrorMessage = createSelector(
  getSelfServiceState,
  (selfServiceState) => selfServiceState.paymentIntent.errorMessage
);
