import get from 'lodash/get';
import sum from 'lodash/sum';

import { AVM_KEY_HC, AVM_KEY_RENTAL_HC } from 'legacy/appstore/constants';

import {
  dollarsFormatter,
  monthlyValueFormatter
} from 'legacy/utils/formatters';

export const subAvmValues = (template, avm) =>
  template
    .replace(/_val_/, dollarsFormatter(avm.value))
    .replace(/_valMin_/, dollarsFormatter(avm.minVal))
    .replace(/_valMax_/, dollarsFormatter(avm.maxVal));

export const getAvmTooltipText = (selectedAvmKey, selectedAvm) => {
  const TOOLTIP_TEXTS = {
    [AVM_KEY_HC]: `
      We value this property at ${dollarsFormatter(selectedAvm.value)}.
      The sales price will likely fall between ${dollarsFormatter(
        selectedAvm.minVal
      )} and ${dollarsFormatter(selectedAvm.maxVal)}.
      This is HouseCanary’s estimated market value for this home.
      It is not a formal appraisal.
      This estimate is based on our market knowledge, and it should be used as a starting point to determine a home’s value.
    `,
    [AVM_KEY_RENTAL_HC]: `
      We estimate rent for this property at ${monthlyValueFormatter(
        selectedAvm.value,
        true
      )}.
      The rental price will likely fall between ${monthlyValueFormatter(
        selectedAvm.minVal,
        true
      )} and ${monthlyValueFormatter(selectedAvm.maxVal, true)}.
      This is HouseCanary’s estimated market rental value for this home.
      It is not a formal appraisal.
      This estimate is based on our market knowledge, and it should be used as a starting point to determine a home’s rental value.
    `
  };
  return TOOLTIP_TEXTS[selectedAvmKey];
};

export const calcCompValue = (comps, subject = {}, baseValueFn) => {
  const subjectSqft = subject.grossLivingAreaSqft;
  // Iterate through comps list once and find the max and min value comps, plus the total value sum
  let resultHighest = null;
  let resultLowest = null;
  let valSum = 0;
  let numVals = comps.length;
  comps.forEach((comp) => {
    const val = baseValueFn(comp);
    if (val) {
      valSum += val;
      if (!resultHighest || resultHighest < val) {
        resultHighest = val;
      }
      if (!resultLowest || resultLowest > val) {
        resultLowest = val;
      }
    } else {
      // Don't count values that are null/undefined
      numVals--;
    }
  });

  if (resultHighest) {
    return {
      maxVal: resultHighest,
      maxValPerSqft: subjectSqft ? resultHighest / subjectSqft : null,
      minVal: resultLowest,
      minValPerSqft: subjectSqft ? resultLowest / subjectSqft : null,
      valPerSqft: subjectSqft ? valSum / numVals / subjectSqft : null,
      value: valSum / numVals
    };
  } else {
    return {
      maxVal: null,
      maxValPerSqft: null,
      minVal: null,
      minValPerSqft: null,
      valPerSqft: null,
      value: null
    };
  }
};

// Rental avms and avms have different keys.
export const avmToRentalAvm = (avm = {}) => {
  return avm
    ? {
        priceUpr: avm.priceUpr || avm.maxVal,
        pricePerSqftUpr: avm.pricePerSqftUpr || avm.maxValPerSqft,
        priceLwr: avm.priceLwr || avm.minVal,
        pricePerSqftLwr: avm.pricePerSqftLwr || avm.minValPerSqft,
        pricePerSqftMean: avm.pricePerSqftMean || avm.valPerSqft,
        priceMean: avm.priceMean || avm.value,
        fsd: avm.fsd,
        valuationSuitabilityScore: avm.valuationSuitabilityScore,
        valuationSuitabilityScoreDesc: avm.valuationSuitabilityScoreDesc
      }
    : {};
};
export const rentalAvmToAvm = (rentalAvm = {}) => {
  return rentalAvm
    ? {
        maxVal: rentalAvm.maxVal || rentalAvm.priceUpr,
        maxValPerSqft: rentalAvm.maxValPerSqft || rentalAvm.pricePerSqftUpr,
        minVal: rentalAvm.minVal || rentalAvm.priceLwr,
        minValPerSqft: rentalAvm.minValPerSqft || rentalAvm.pricePerSqftLwr,
        valPerSqft: rentalAvm.valPerSqft || rentalAvm.pricePerSqftMean,
        value: rentalAvm.value || rentalAvm.priceMean,
        fsd: rentalAvm.fsd,
        valuationSuitabilityScore: rentalAvm.valuationSuitabilityScore,
        valuationSuitabilityScoreDesc: rentalAvm.valuationSuitabilityScoreDesc
      }
    : {};
};

// accepts a comp object and sums the values for comp adjusted value
export const calcCompAdjustedValue = (comp) => {
  const userAdjustment = isNaN(parseInt(comp.userAdjustment))
    ? 0
    : parseInt(comp.userAdjustment);
  return (
    sum([comp.currentValue, comp.netDollarAdjustment, userAdjustment]) || 0
  );
};

export const calcCompAdjustedSoldPrice = (comp) => {
  const userBasePriceAdjustment = isNaN(parseInt(comp.userBasePriceAdjustment))
    ? 0
    : parseInt(comp.userBasePriceAdjustment);
  const basePrice =
    comp.propertyStatus === 'Sold'
      ? comp.salesPrice || comp.lastListPrice
      : comp.lastListPrice;
  return basePrice && (sum([basePrice, userBasePriceAdjustment]) || 0);
};

// accepts a comp object and sums the values for comp adjusted value
export const calcRentalCompAdjustedValue = (comp) => {
  const avmPrice = get(comp, ['rentalAvm', 'priceMean'], 0);
  const userAdjustment = get(comp, 'userAdjustmentRental', 0);
  return avmPrice + userAdjustment;
};

export const calcRentalCompAdjustedPrice = (comp) => {
  const basePrice =
    comp.propertyStatusRental === 'Leased'
      ? get(comp, ['leasedPrice'], get(comp, ['lastListPriceRental'], 0))
      : get(comp, ['lastListPriceRental'], 0);
  const userAdjustment = get(comp, 'userRentalBasePriceAdjustment', 0);
  return basePrice && basePrice + userAdjustment;
};
