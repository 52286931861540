import React from 'react';
import { useDispatch } from 'react-redux';
import { FilterCard } from 'src/lib/components/FilterCard';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { MonthsAgoSelect } from 'src/lib/components/MonthsAgoSelect';
import { formatDateLong } from 'src/lib/utils';
import { isLoading } from 'src/lib/utils/general';
import { documentPatch } from '../../actions';
import { useDocumentRole, useReport } from '../../hooks';
import { DocumentRoles, FilterDocument, SubjectDocument } from '../../types';
import { CompFields } from '../../types/CompFields';
import { compFieldGetters } from '../../utils/comps.utils';
import { CompFilterClearButton } from './CompFilterClearButton';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
}
export const CompFilterSaleDate = ({
  reportId,
  dataHcName,
  className
}: Props) => {
  const dispatch = useDispatch();
  const {
    status: filterDocumentStatus,
    data: [filterDocument]
  } = useDocumentRole<FilterDocument>(reportId, DocumentRoles.Filter);
  const {
    status: subjectStatus,
    data: [subjectDocument]
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  const { status: reportStatus, data: report } = useReport(reportId);
  if (
    isLoading([filterDocumentStatus, reportStatus, subjectStatus]) ||
    !filterDocument ||
    !report ||
    !subjectDocument
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const filterValue = filterDocument.data.filters[CompFields.salesDate];
  const subjectValue = compFieldGetters[CompFields.salesDate](
    subjectDocument.data
  );
  return (
    <FilterCard
      label="Sale Date"
      info={
        <>
          <div>{`Subject: ${formatDateLong(subjectValue)}`}</div>
          <div>Only applies to &quot;Sold&quot; comps</div>
        </>
      }
      dataHcName={dataHcName}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compField={CompFields.salesDate}
          dataHcName={`${dataHcName}-clear`}
        />
      }
    >
      <MonthsAgoSelect
        dataHcName={`${dataHcName}-select`}
        value={filterValue?.relativeValue || null}
        className={className}
        onChange={(value) => {
          if (value === null) {
            dispatch(
              documentPatch(reportId, filterDocument.documentId, [
                {
                  op: 'remove',
                  path: `/data/filters/${CompFields.salesDate}`
                }
              ])
            );
          } else {
            dispatch(
              documentPatch(reportId, filterDocument.documentId, [
                {
                  op: 'add',
                  path: `/data/filters/${CompFields.salesDate}/relativeValue`,
                  value
                }
              ])
            );
          }
        }}
      />
    </FilterCard>
  );
};
