import classNames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { Dialog } from 'src/lib/components/Dialog';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { useParentDimensions } from 'src/lib/hooks/useParentDimensions';
import {
  InactiveColumns,
  PreferencesKeys,
  preferencesPatch,
  TablePreferences,
  usePreferences
} from 'src/lib/huell';
import { COMP_FIELD_ORDER } from 'src/lib/utils/comp-fields.utils';
import { isLoading } from 'src/lib/utils/general';
import { useRerender } from '../../hooks/useRerender';
import { CompFields } from '../../types/CompFields';
import { CompFarmListTableOption } from './CompFarmListTableOption';
import styles from './CompFarmListTableOptions.css';

interface Props {
  dataHcName: string;
  className?: string;
  active: boolean;
  onClose: VoidFunction;
}
const COL_WIDTH = 350;
export const CompFarmListTableOptionsDialog = ({
  dataHcName,
  className,
  active: dialogActive,
  onClose
}: Props) => {
  const dispatch = useDispatch();
  const {
    status,
    fetching: preferencesFetching,
    data: preferences
  } = usePreferences();
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [active, setActive] = useState<CompFields[]>(COMP_FIELD_ORDER);
  const [inactive, setInactive] = useState<CompFields[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const parentDimensions = useParentDimensions({ ref });
  useEffect(() => {
    if (!preferencesFetching && updateInProgress) {
      setUpdateInProgress(false);
      onClose();
    }
  }, [updateInProgress, preferencesFetching]);
  // Rerender when dialog opens so we can get an accurate parent height for column calcs
  useRerender({
    deps: [dialogActive],
    shouldRerender: dialogActive
  });
  useEffect(() => {
    const tableOptions = preferences?.[PreferencesKeys.CompTableColumns];
    if (tableOptions) {
      // Keep track of the options added from 'order' so we can add newly created filters
      const added: { [key in CompFields]?: boolean } = {};
      const newActive: CompFields[] = [];
      const newInactive: CompFields[] = [];
      tableOptions.order.forEach((compField) => {
        if (compField !== CompFields.address) {
          // TODO: compField is actually snake_case here
          if (tableOptions.inactive[compField]) {
            newInactive.push(compField);
          } else {
            newActive.push(compField);
          }
        }
        added[compField] = true;
      });
      if (COMP_FIELD_ORDER.length > newActive.length + newInactive.length) {
        COMP_FIELD_ORDER.forEach((compField) => {
          if (!added[compField] && compField !== CompFields.address) {
            if (tableOptions.inactive[compField]) {
              newInactive.push(compField);
            } else {
              newActive.push(compField);
            }
            added[compField] = true;
          }
        });
      }
      setActive(newActive);
      setInactive(newInactive);
    }
  }, [preferences]);
  const handleDragEnd = ({ source, destination }: DropResult) => {
    // destination will be null if dropped outside of the container
    if (source && destination) {
      const column = active[source.index];
      if (column) {
        // Remove dragged column from the list
        const newOrder = active
          .slice(0, source.index)
          .concat(active.slice(source.index + 1, active.length));
        // Add the dragged column in the dropped position
        newOrder.splice(destination.index, 0, column);
        setActive(newOrder);
      }
    }
  };

  if (isLoading(status)) {
    return <LoadingSpinner dataHcName={`${dataHcName}-loading`} />;
  }

  const handleToggleColumn = (compField: CompFields, isActive: boolean) => {
    const nextActive = [...active].filter((c) => c !== compField);
    const nextInactive = [...inactive].filter((c) => c !== compField);
    if (isActive) {
      nextActive.push(compField);
    } else {
      nextInactive.unshift(compField);
    }
    setActive(nextActive);
    setInactive(nextInactive);
  };

  const handleSave = () => {
    const updated: TablePreferences<InactiveColumns> = {
      order: active,
      inactive: {}
    };
    inactive.forEach((compField) => {
      if (compField !== CompFields.address) {
        updated.inactive[compField] = true;
      }
    });
    setUpdateInProgress(true);
    dispatch(preferencesPatch({ [PreferencesKeys.CompTableColumns]: updated }));
  };
  const parentHeight = parentDimensions?.height;
  const parentWidth = window.innerWidth * 0.9;
  const cols: CompFields[][] = [];
  let numFieldsPerCol = 1;
  if (parentHeight && parentWidth) {
    const numCols = Math.min(
      Math.ceil((COMP_FIELD_ORDER.length * 45) / parentHeight),
      Math.max(Math.floor(parentWidth / COL_WIDTH), 1)
    );
    numFieldsPerCol = Math.ceil(COMP_FIELD_ORDER.length / numCols);
    for (let i = 0; i < numCols; i++) {
      const colsRendered = i * numFieldsPerCol;
      const colsRenderedAfter = (i + 1) * numFieldsPerCol;
      // If column is all active
      if (active.length >= colsRenderedAfter) {
        cols.push(active.slice(colsRendered, colsRenderedAfter));
      }
      // If this column starts w/ active and ends with inactive
      else if (active.length >= colsRendered) {
        cols.push([
          ...active.slice(colsRendered),
          ...inactive.slice(0, colsRenderedAfter - active.length)
        ]);
      }
      // Column is entirely inactive fields
      else {
        cols.push(
          inactive.slice(
            colsRendered - active.length,
            colsRenderedAfter - active.length
          )
        );
      }
    }
  }
  return (
    <Dialog
      dataHcName={dataHcName}
      title="Customize Comp Table Columns"
      subtitle="Drag and drop the column labels to reorder the table columns. Uncheck a column to hide it.
      "
      className={classNames(styles.CompTableOptionsDialog, className)}
      active={dialogActive}
      onClose={onClose}
      type="auto"
      width={`${cols.length * COL_WIDTH}px`}
      actions={[
        {
          dataHcName: `${dataHcName}-cancel-button`,
          label: 'Cancel',
          onClick: onClose,
          small: true,
          textButton: true
        },
        {
          dataHcName: `${dataHcName}-submit-button`,
          label: 'Save & Apply',
          onClick: handleSave,
          small: true
        }
      ]}
    >
      <div ref={ref} className={styles.DragDrop}>
        <DragDropContext onDragEnd={handleDragEnd}>
          {cols.map((col, i) => {
            return (
              <div
                className={styles.DragDropColumn}
                data-hc-name={`${dataHcName}-col-${i}`}
                key={`col-${i}`}
                style={{ flex: `0 0 ${100 / cols.length}%` }}
              >
                <Droppable droppableId={`table-col-${i}`} type="COLUMN">
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={classNames({
                          [styles.dragging || '']: snapshot.isDraggingOver
                        })}
                      >
                        {col.map((compField, j) => {
                          return (
                            <CompFarmListTableOption
                              key={`option-${compField}`}
                              dataHcName={`${dataHcName}-${compField}`}
                              compField={compField}
                              index={i * numFieldsPerCol + j}
                              onToggle={handleToggleColumn}
                              active={!inactive.includes(compField)}
                            />
                          );
                        })}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </Dialog>
  );
};
