import React from 'react';
import SimilarityLevel from 'src/lib/components/SimilarityLevel/SimilarityLevel';
import { TableCell, TableHeaderCell } from 'src/lib/components/Table';
import { CompFields } from 'src/lib/report-api/types/CompFields';
import { compFieldGetters } from 'src/lib/report-api/utils/comps.utils';
import { formatMissing } from 'src/lib/utils';
import { FarmListCellProps, FarmListHeaderCellProps } from '../types';
import styles from './similarityColumn.css';
// These components cannot be used as a JSX Element inside TableRow
// Example Usage:
// <Table>
//   <TableRow>
//     {grossLivingAreaColumn.content({ reportId, hcAddressId })}
//   </TableRow>
// </Table>

export const similarityColumn = {
  header: (props: FarmListHeaderCellProps) => {
    return (
      <TableHeaderCell {...props} align="center">
        Similarity
      </TableHeaderCell>
    );
  },
  content: ({ schema }: FarmListCellProps) => {
    const similarity = compFieldGetters[CompFields.similarity](schema);
    return (
      <TableCell align="center">
        <div className={styles.SimilarityCell}>
          {similarity?.levelAdjusted && (
            <SimilarityLevel
              dataHcName={'dasdf'}
              similarityLevel={similarity.levelAdjusted}
            />
          )}
          <div className={styles.Score}>
            {formatMissing(similarity?.scoreAdjusted)}
          </div>
        </div>
      </TableCell>
    );
  }
};
