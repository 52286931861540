import React, { MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './TextButton.css';

interface Props {
  /** Data attribute for automated testing */
  dataHcName: string;
  /** Class to apply to the root element */
  className?: string;
  /** Label to render in place of children */
  label?: ReactNode;
  /** Whether the button is clickable */
  disabled?: boolean;
  /** Callback when button is clicked */
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  /** Use primary styles */
  primary?: boolean;
  children?: ReactNode;
}

export const TextButton = (props: Props) => {
  const {
    children,
    className,
    label,
    disabled,
    onClick,
    dataHcName,
    primary = true
  } = props;
  return (
    <button
      className={classNames(styles.TextButton, className, {
        [styles.secondary || '']: !primary,
        [styles.disabled || '']: disabled
      })}
      data-hc-name={dataHcName}
      onClick={!disabled ? onClick : undefined}
    >
      {children || label}
    </button>
  );
};
