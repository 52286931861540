import { createSelector } from 'reselect';

import {
  SUBJECT_TYPE_USER,
  MAP_ID_COMPS,
  COMP_TYPE_DEFAULT
} from 'legacy/appstore/constants';
import { VIEWS } from 'legacy/routes/constants';

import {
  calcCompValue,
  calcCompAdjustedValue,
  calcCompAdjustedSoldPrice
} from 'legacy/utils/avms';
import {
  PROPERTY_DETAILS_ATTRIBUTES,
  getPropertyDetailsAttributeDef
} from 'legacy/utils/property-details';
import { determineShapeTypeFromGeoJson } from 'legacy/utils/maps';

import {
  getSharedCompsFilters,
  getSharedCompsNoFiltersApplied,
  getSharedCompsFilterKeys,
  getSharedCompsSort,
  getSharedCompsSortSelection,
  getSharedCompsListViewType,
  getSharedCompsFarm,
  getSharedCompsFarmList,
  getSharedCompsFarmListRaw,
  getSharedCompsActiveListingsProperties,
  getSharedCompsRecentSimilarProperties,
  getSharedCompsHistoricalSimilarProperties,
  getSharedCompsSuggestedProperties,
  getSharedCompsSearch,
  getSharedCompsAvailableIds,
  getSharedCompsAvailableProperties,
  getSharedCompsSelectedIds,
  getSharedHasUserSelectedComps,
  getSharedCompsSelectedOrSuggestedIds,
  getSharedCompsSelectedOrSuggestedIdsOrderedBySimilarity,
  getSharedCompsSelected,
  getSharedCompsMapMarkersSelected,
  getSharedCompsMapMarkersUserSelected,
  getSharedCompsMapMarkersUserSelectedAndAvailable,
  getSharedFocusedCompId,
  getSharedFocusedComp,
  getSharedCompByAddressId,
  getSharedCompIdToScroll,
  getSharedCompsIsSelectedById,
  getSharedCompsIsEditableById,
  getSharedIsSelectedCompLimitReached,
  getSharedCompsAllowSelection,
  getSharedCompsAddressIdsAll,
  getSharedCompsGeoLocationAll,
  getSharedCompsSelectedOrSuggestedMessage,
  getSharedCompsFarmUpdatedAt,
  getSharedCompsFilterControls,
  getSharedCompsFiltersAppliedForMetadata,
  getSharedCompsFiltersToSave,
  getSharedCompsFiltersUpdatedFromUrl,
  getSharedCompsFiltersUpdatedFromPreferences,
  getSharedCompsNewDefaultFarmDistance,
  getSharedCompsSavedFiltersUnableToApply,
  getSharedCompsDataTableColumns,
  getSharedCompsTableColumnsRaw,
  getSharedCompsDataTableData,
  getSharedCompsTableColumnsConfigurable,
  getSharedCompsTableColumnsActiveInactive,
  getSharedCompSortForDataTable,
  getSharedCompsFiltersUpdatedFromUser,
  getSharedCompsFarmRefreshedAt,
  getSharedCompsAddressIdToCerberusIdMapping,
  getSharedCompsCerberusIdToAddressIdMapping,
  getSharedCompsKeywordSearchKeywords,
  getSharedCompsKeywordMatchesByAddressId,
  getSharedCompsKeywordSearchStatus,
  getSharedCompsKeywordMatches,
  getSharedCompsKeywordSearchFeatureSupported,
  getSharedCompsShowOnMap,
  getSharedCompsTags,
  getSharedCompsTagFilter
} from 'selectors/comps-shared';
import {
  getSubjectDefault,
  getSubjectAdjusted,
  getSubjectSelected,
  getSubjectLocationUnknown
} from 'selectors/subject';
import { getMapDrawPolygons } from 'selectors/map-draw';
import { getIsMobile } from 'selectors/match-media';
import {
  getIsViewingSharedReport,
  getIsEffectiveDateReport
} from 'selectors/property-explorer';
import { getIsLoggedIn } from 'selectors/auth';
import { getReportStatusCompsIsLoadedInitial } from 'selectors/report-status';

export const getCompsFilters = createSelector(
  getSharedCompsFilters(COMP_TYPE_DEFAULT),
  getMapDrawPolygons(MAP_ID_COMPS),
  getSubjectSelected,
  (filters, polygons, subject) => {
    let distance;
    if (polygons && polygons.length) {
      const polygon = polygons[0];
      const { type, coordinates } = polygon.geometry || {};
      const { geoLocation = {} } = subject;
      const { latitude, longitude } = geoLocation;
      if (
        latitude &&
        longitude &&
        type === 'Point' &&
        coordinates[0] === longitude &&
        coordinates[1] === latitude
      ) {
        distance = polygon.properties.radius / 1609.34;
      } else {
        distance = determineShapeTypeFromGeoJson(polygon);
      }
    }
    return {
      ...filters,
      ...(distance ? { distance } : {})
    };
  }
);

export const getCompsTags = getSharedCompsTags(COMP_TYPE_DEFAULT);
export const getCompsTagFilter = getSharedCompsTagFilter(COMP_TYPE_DEFAULT);
export const getCompsShowOnMap = getSharedCompsShowOnMap(COMP_TYPE_DEFAULT);
export const getCompsKeywordSearchFeatureSupported =
  getSharedCompsKeywordSearchFeatureSupported(COMP_TYPE_DEFAULT);
export const getCompsKeywordMatches =
  getSharedCompsKeywordMatches(COMP_TYPE_DEFAULT);
export const getCompsKeywordSearchStatus =
  getSharedCompsKeywordSearchStatus(COMP_TYPE_DEFAULT);
export const getCompsKeywordMatchesByAddressId =
  getSharedCompsKeywordMatchesByAddressId(COMP_TYPE_DEFAULT);
export const getCompsKeywordSearchKeywords =
  getSharedCompsKeywordSearchKeywords(COMP_TYPE_DEFAULT);
export const getCompsAddressIdToCerberusIdMapping =
  getSharedCompsAddressIdToCerberusIdMapping(COMP_TYPE_DEFAULT);
export const getCompsCerberusIdToAddressIdMapping =
  getSharedCompsCerberusIdToAddressIdMapping(COMP_TYPE_DEFAULT);
export const getCompsFarmRefreshedAt =
  getSharedCompsFarmRefreshedAt(COMP_TYPE_DEFAULT);
export const getCompsFiltersAppliedForMetadata =
  getSharedCompsFiltersAppliedForMetadata(COMP_TYPE_DEFAULT);
export const getCompsNoFiltersApplied =
  getSharedCompsNoFiltersApplied(COMP_TYPE_DEFAULT);
export const getCompsFilterKeys = getSharedCompsFilterKeys(COMP_TYPE_DEFAULT);
export const getCompsSort = getSharedCompsSort(COMP_TYPE_DEFAULT);
export const getCompsSortForDataTable =
  getSharedCompSortForDataTable(COMP_TYPE_DEFAULT);
export const getCompsSortSelected =
  getSharedCompsSortSelection(COMP_TYPE_DEFAULT);
export const getCompsListViewType =
  getSharedCompsListViewType(COMP_TYPE_DEFAULT);
export const getCompsFarm = getSharedCompsFarm(COMP_TYPE_DEFAULT);
export const getCompsFarmList = getSharedCompsFarmList(COMP_TYPE_DEFAULT);
export const getCompsActiveListingsProperties =
  getSharedCompsActiveListingsProperties(COMP_TYPE_DEFAULT);
export const getCompsRecentSimilarProperties =
  getSharedCompsRecentSimilarProperties(COMP_TYPE_DEFAULT);
export const getCompsHistoricalSimilarProperties =
  getSharedCompsHistoricalSimilarProperties(COMP_TYPE_DEFAULT);
export const getCompsFarmListRaw = getSharedCompsFarmListRaw(COMP_TYPE_DEFAULT);
export const getCompsSearch = getSharedCompsSearch(COMP_TYPE_DEFAULT);
export const getCompsAvailableIds =
  getSharedCompsAvailableIds(COMP_TYPE_DEFAULT);
export const getCompsAvailableProperties =
  getSharedCompsAvailableProperties(COMP_TYPE_DEFAULT);
export const getCompsSelectedIds = getSharedCompsSelectedIds(COMP_TYPE_DEFAULT);
export const getHasUserSelectedComps =
  getSharedHasUserSelectedComps(COMP_TYPE_DEFAULT);
export const getCompsSelectedOrSuggestedIds =
  getSharedCompsSelectedOrSuggestedIds(COMP_TYPE_DEFAULT);
export const getCompsSelectedOrSuggestedIdsOrderedBySimilarity =
  getSharedCompsSelectedOrSuggestedIdsOrderedBySimilarity(COMP_TYPE_DEFAULT);
export const getCompsSuggestedProperties =
  getSharedCompsSuggestedProperties(COMP_TYPE_DEFAULT);
export const getCompsSelected = getSharedCompsSelected(COMP_TYPE_DEFAULT);
export const getCompsMapMarkersSelected =
  getSharedCompsMapMarkersSelected(COMP_TYPE_DEFAULT);
export const getCompsMapMarkersUserSelected =
  getSharedCompsMapMarkersUserSelected(COMP_TYPE_DEFAULT);
export const getCompsMapMarkersUserSelectedAndAvailable =
  getSharedCompsMapMarkersUserSelectedAndAvailable(COMP_TYPE_DEFAULT);
export const getFocusedCompId = getSharedFocusedCompId(COMP_TYPE_DEFAULT);
export const getFocusedComp = getSharedFocusedComp(COMP_TYPE_DEFAULT);
export const getCompByAddressId = getSharedCompByAddressId(COMP_TYPE_DEFAULT);
export const getCompIdToScroll = getSharedCompIdToScroll(COMP_TYPE_DEFAULT);
export const getCompsIsSelectedById =
  getSharedCompsIsSelectedById(COMP_TYPE_DEFAULT);
export const getCompsIsEditableById =
  getSharedCompsIsEditableById(COMP_TYPE_DEFAULT);
export const getIsSelectedCompLimitReached =
  getSharedIsSelectedCompLimitReached(COMP_TYPE_DEFAULT);
export const getCompsAllowSelection =
  getSharedCompsAllowSelection(COMP_TYPE_DEFAULT);
export const getCompsAddressIdsAll =
  getSharedCompsAddressIdsAll(COMP_TYPE_DEFAULT);
export const getCompsGeoLocationAll =
  getSharedCompsGeoLocationAll(COMP_TYPE_DEFAULT);
export const getCompsSelectedOrSuggestedMessage =
  getSharedCompsSelectedOrSuggestedMessage(COMP_TYPE_DEFAULT);
export const getCompsFarmUpdatedAt =
  getSharedCompsFarmUpdatedAt(COMP_TYPE_DEFAULT);
export const getCompsFiltersUpdatedFromUrl =
  getSharedCompsFiltersUpdatedFromUrl(COMP_TYPE_DEFAULT);
export const getCompsFiltersUpdatedFromPreferences =
  getSharedCompsFiltersUpdatedFromPreferences(COMP_TYPE_DEFAULT);
export const getCompsFiltersToSave =
  getSharedCompsFiltersToSave(COMP_TYPE_DEFAULT);
export const getCompsShouldLoadNewDefaultFarm =
  getSharedCompsNewDefaultFarmDistance(COMP_TYPE_DEFAULT);
export const getCompsSavedFiltersUnableToApply =
  getSharedCompsSavedFiltersUnableToApply(COMP_TYPE_DEFAULT);
export const getCompsFiltersUpdatedFromUser =
  getSharedCompsFiltersUpdatedFromUser(COMP_TYPE_DEFAULT);
export const getCompsTableColumnsRaw =
  getSharedCompsTableColumnsRaw(COMP_TYPE_DEFAULT);
export const getCompsDataTableColumns =
  getSharedCompsDataTableColumns(COMP_TYPE_DEFAULT);
export const getCompsTableColumnsConfigurable =
  getSharedCompsTableColumnsConfigurable(COMP_TYPE_DEFAULT);
export const getCompsTableColumnsActiveInactive =
  getSharedCompsTableColumnsActiveInactive(COMP_TYPE_DEFAULT);
export const getCompsTableColumnsActiveInactiveForOptions =
  getSharedCompsTableColumnsActiveInactive(COMP_TYPE_DEFAULT, true);
export const getCompsDataTableData =
  getSharedCompsDataTableData(COMP_TYPE_DEFAULT);

export const getCompsSelectionButtonView = createSelector(
  getCompsFarmListRaw,
  getIsViewingSharedReport,
  getIsEffectiveDateReport,
  (farm, isViewingShared, isEffectiveDate) => {
    const viewCompSelection = isEffectiveDate
      ? VIEWS.EFFECTIVE_DATE_COMPS
      : isViewingShared
      ? VIEWS.COMPS_SELECTION_SHARED
      : VIEWS.COMPS_SELECTION;
    const viewCompSelectionMap = isEffectiveDate
      ? VIEWS.EFFECTIVE_DATE_COMPS_MAP
      : isViewingShared
      ? VIEWS.COMPS_SELECTION_MAP_SHARED
      : VIEWS.COMPS_SELECTION_MAP;
    return farm.length ? viewCompSelection : viewCompSelectionMap;
  }
);

export const getCompsSelectionButtonHide = createSelector(
  getCompsSelectionButtonView,
  getIsMobile,
  (view, isMobile) =>
    isMobile &&
    [VIEWS.COMPS_SELECTION_MAP_SHARED, VIEWS.COMPS_SELECTION_MAP].indexOf(
      view
    ) > -1
);

export const getCompsSelectionButtonLabel = createSelector(
  getCompsSelectionButtonView,
  getSubjectLocationUnknown,
  getReportStatusCompsIsLoadedInitial,
  (view, locationUnknown, isLoadedInitial) => {
    return [VIEWS.COMPS_SELECTION_SHARED, VIEWS.COMPS_SELECTION].indexOf(view) >
      -1 && isLoadedInitial
      ? 'Select Comps'
      : !locationUnknown
      ? 'Select Comps'
      : 'Draw Comp Area';
  }
);

export const getCompsPropertyDetailsAttributes = (state) =>
  [
    'propertyStatus',
    'lastListDate',
    'lastListPrice',
    'salesDate',
    'salesPrice',
    'userBasePriceAdjustment',
    'basePriceAdjusted',
    'basePriceAdjustedDiffSqft',
    'currentValue',
    'netDollarAdjustment',
    'userAdjustment',
    'valueAdjusted',
    'valueAdjustedDiffSqft',
    'bedrooms',
    'bathrooms',
    'grossLivingAreaSqft',
    'siteAreaSqft',
    'propertyType',
    'yearBuilt',
    'garageType',
    'garageNumCars',
    'stories',
    'basement',
    'pool',
    'hoaAssociation',
    'hoaFee',
    'hoaFeeFrequency',
    'hoaFeeIncludes',
    'taxYear',
    'taxAmount',
    'subdivisionName',
    'zoning',
    'cumulativeDaysOnMarket',
    'publicRemarks'
  ].map((key) =>
    PROPERTY_DETAILS_ATTRIBUTES.hasOwnProperty(key)
      ? { ...getPropertyDetailsAttributeDef(key), key }
      : null
  );

export const getCompsFilterControls = (isMobile) =>
  createSelector(getSubjectSelected, getIsLoggedIn, (subject, isLoggedIn) => {
    // List of filter control keys or objects w/ filter control modifications
    let filterControls = [
      'propertyType_included',
      ...(isMobile || !isLoggedIn
        ? []
        : [
            {
              key: 'distance',
              controlProps: {
                geoLocationRef: subject.geoLocation
              }
            }
          ]),
      'bedrooms_minmax',
      'bathrooms_minmax',
      'grossLivingAreaSqft_minmax',
      'yearBuilt_minmax',
      'propertyStatus_included',
      'lastListDate_minmax',
      'salesDate_minmax',
      'lastListPrice_minmax',
      'salesPrice_minmax',
      'siteAreaSqft_minmax',
      'similarityLevelAdjusted_included',
      'pool_boolean',
      'garageNumCars_minmax',
      'stories_minmax',
      'basement_boolean'
    ];
    return getSharedCompsFilterControls(filterControls);
  });

// TODO: Where should selectors like this live? I think they should be separate from selectors that just get something off of state
export const calcSoldCompAvg = createSelector(
  getCompsSelected,
  getSubjectAdjusted,
  (comps, subject) => calcCompValue(comps, subject, calcCompAdjustedSoldPrice)
);
export const calcCompAvm =
  (subjectType = SUBJECT_TYPE_USER) =>
  (state) => {
    let subject;
    if (subjectType === SUBJECT_TYPE_USER) {
      subject = getSubjectAdjusted(state);
    } else {
      subject = getSubjectDefault(state);
    }
    return calcCompValue(
      getCompsSelected(state),
      subject,
      calcCompAdjustedValue
    );
  };
