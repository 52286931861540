import moment from 'moment';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import {
  FILTER_SAVE_TYPES,
  FILTER_MATCH_SUBJECT,
  COMP_TYPE_RENTAL
} from 'legacy/appstore/constants';

import {
  FILTERS,
  FILTERS_ORDER,
  isFilterSet,
  getSubjectRentalFilterValue,
  getSubjectFilterValue
} from 'legacy/utils/filters';
import { isString, isArray, isPopulated } from 'legacy/utils/utils';

// Helper utils for FILTER_SAVE_METHODS
const _checkTypeAndCallFn = (absoluteValues, subjectValue, saveTypeFn) => {
  if (isArray(absoluteValues)) {
    const relativeValues = [];
    for (let f = 0; f < absoluteValues.length; f++) {
      // Attempt to calculate relative value
      const r = saveTypeFn(absoluteValues[f], subjectValue);
      if (r && r.error) {
        // Break loop and return error if error
        return r;
      } else {
        relativeValues.push(r);
      }
    }
    return relativeValues;
  } else {
    return saveTypeFn(absoluteValues, subjectValue);
  }
};
const _formatPctDisplay = (v, plusMinus = true) =>
  v === null
    ? 'Any'
    : `${plusMinus ? (v > 0 ? '+' : v === 0 ? '' : '-') : ''}${Math.round(
        Math.abs(v) * 100
      )}%`;
const _formatNumDisplay = (v, plusMinus = true) =>
  v === null
    ? 'Any'
    : `${plusMinus ? (v > 0 ? '+' : v === 0 ? '' : '-') : ''}${Math.abs(v)}`;

// Convert applied values to the values needed for save filter
export const FILTER_SAVE_METHODS = {
  [FILTER_SAVE_TYPES.REL_PCT]: {
    singleValueFormatter: _formatPctDisplay,
    save: (filterValue, subjectValue) =>
      _checkTypeAndCallFn(
        filterValue,
        subjectValue,
        // Calculate the relative difference as a % of subject value
        (f, s) => {
          if (!isPopulated(s)) {
            return {
              error: 'Cannot save filter because Subject is missing value'
            };
          } else {
            return isPopulated(f) ? (f - s) / s : null;
          }
        }
      ),
    apply: (filterValue, subjectValue) =>
      _checkTypeAndCallFn(
        filterValue,
        subjectValue,
        // Calculate the % of Subject value and add it to Subject
        (f, s) =>
          isPopulated(f)
            ? isPopulated(s)
              ? Math.round(s + f * s)
              : {
                  error:
                    'Cannot apply the saved filter because the Subject value is missing.'
                }
            : null
      ),
    display: ({ relativeValue }, { label }, forModal) => {
      if (!relativeValue) return '';
      if (
        (relativeValue[0] && relativeValue[0].error) ||
        (relativeValue[1] && relativeValue[1].error)
      ) {
        return relativeValue[0] && relativeValue[0].error
          ? relativeValue[0]
          : relativeValue[1];
      }
      if (relativeValue[0] === 0 && relativeValue[1] === 0) {
        // Filters equal subject value
        return `Match Subject ${label}`;
      } else if (
        relativeValue[0] !== relativeValue[1] &&
        Math.abs(relativeValue[0]) === Math.abs(relativeValue[1])
      ) {
        // +/- values absolute values are equal (positive and negative from subject)
        return `+/- ${_formatPctDisplay(relativeValue[0], false)} ${label}${
          forModal ? ' from Subject' : ''
        }`;
      } else if (relativeValue[0] === relativeValue[1]) {
        // +/- values are equal
        return `${_formatPctDisplay(relativeValue[0])} ${label}${
          forModal ? ' from Subject' : ''
        }`;
      } else {
        // max/min values are different
        return `${
          relativeValue[0] === 0
            ? 'Match Subject'
            : _formatPctDisplay(relativeValue[0])
        } to ${
          relativeValue[1] === 0
            ? 'Match Subject'
            : _formatPctDisplay(relativeValue[1])
        } ${label}${forModal ? ' from Subject' : ''}`;
      }
    },
    quickFilterOptions: [
      {
        label: '5%',
        value: [-0.05, 0.05]
      },
      {
        label: '10%',
        value: [-0.1, 0.1]
      },
      {
        label: '15%',
        value: [-0.15, 0.15]
      },
      {
        label: '20%',
        value: [-0.2, 0.2]
      }
    ]
  },
  [FILTER_SAVE_TYPES.REL_EXACT]: {
    singleValueFormatter: _formatNumDisplay,
    save: (filterValue, subjectValue) =>
      _checkTypeAndCallFn(
        filterValue,
        subjectValue,
        // Calculate the relative difference from subject value
        (f, s) => {
          if (!isPopulated(s)) {
            return {
              error: 'Cannot save filter because Subject is missing value'
            };
          } else {
            return isPopulated(f) ? f - s : null;
          }
        }
      ),
    apply: (filterValue, subjectValue) =>
      _checkTypeAndCallFn(
        filterValue,
        subjectValue,
        // Add filter value to subject
        (f, s) =>
          isPopulated(f)
            ? isPopulated(s)
              ? s + f
              : {
                  error:
                    'Cannot apply the saved filter because the Subject value is missing.'
                }
            : null
      ),
    display: ({ relativeValue }, { label, key }, forModal) => {
      if (!relativeValue) return '';
      if (
        (relativeValue[0] && relativeValue[0].error) ||
        (relativeValue[1] && relativeValue[1].error)
      ) {
        return relativeValue[0] && relativeValue[0].error
          ? relativeValue[0]
          : relativeValue[1];
      }
      if (relativeValue[0] === 0 && relativeValue[1] === 0) {
        // Filters equal subject value
        return `Match Subject ${label}`;
      } else if (
        relativeValue[0] !== relativeValue[1] &&
        Math.abs(relativeValue[0]) === Math.abs(relativeValue[1])
      ) {
        // +/- values absolute values are equal (positive and negative from subject)
        return `+/- ${_formatNumDisplay(relativeValue[0], false)} ${label}${
          forModal ? ' from Subject' : ''
        }`;
      } else if (relativeValue[0] === relativeValue[1]) {
        // +/- values are equal
        return `${_formatNumDisplay(relativeValue[0])} ${label}${
          forModal ? ' from Subject' : ''
        }`;
      } else {
        // max/min values are different
        return `${
          relativeValue[0] === 0
            ? 'Match Subject'
            : _formatNumDisplay(relativeValue[0])
        } to ${
          relativeValue[1] === 0
            ? 'Match Subject'
            : _formatNumDisplay(relativeValue[1])
        } ${label}${forModal ? ' from Subject' : ''}`;
      }
    },
    quickFilterOptions: [
      {
        label: 0,
        value: [0, 0]
      },
      {
        label: 1,
        value: [-1, 1]
      },
      {
        label: 2,
        value: [-2, 2]
      },
      {
        label: 3,
        value: [-3, 3]
      }
    ]
  },
  [FILTER_SAVE_TYPES.MATCH_OR_EXACT]: {
    save: (filterValue, subjectValue) => {
      const valueForCompare =
        isArray(filterValue) &&
        !isArray(subjectValue) &&
        filterValue.length === 1
          ? filterValue[0]
          : filterValue;
      return isEqual(valueForCompare, subjectValue)
        ? FILTER_MATCH_SUBJECT
        : filterValue;
    },
    apply: (filterValue, subjectValue) =>
      filterValue === FILTER_MATCH_SUBJECT
        ? isPopulated(subjectValue)
          ? [subjectValue]
          : {
              error:
                'Cannot apply the saved filter because the Subject value is missing.'
            }
        : filterValue,
    display: ({ relativeValue }, { label }) => {
      if (!relativeValue) return '';
      if (relativeValue === FILTER_MATCH_SUBJECT) {
        return `Match Subject ${label}`;
      } else if (isArray(relativeValue)) {
        return relativeValue
          .map((f) => (f ? f.charAt(0).toUpperCase() + f.slice(1) : ''))
          .join(', ');
      } else {
        return relativeValue;
      }
    },
    quickFilterOptions: [
      {
        label: 'Match the Subect',
        value: FILTER_MATCH_SUBJECT
      }
    ]
  },
  [FILTER_SAVE_TYPES.EXACT]: {
    save: (filterValue, subjectValue) =>
      _checkTypeAndCallFn(
        filterValue,
        subjectValue,
        // Return the same value since filter saved exactly as configured
        (f, s) => f
      ),
    apply: (filterValue, subjectValue) =>
      _checkTypeAndCallFn(
        filterValue,
        subjectValue,
        // Use Exact Filter value
        (f, s) => f
      ),
    display: ({ relativeValue }, { label }) => {
      if (!relativeValue) return '';
      return isArray(relativeValue) &&
        relativeValue.length &&
        isString(relativeValue[0])
        ? relativeValue
            .map((f) => f.charAt(0).toUpperCase() + f.slice(1))
            .join(', ')
        : relativeValue;
    }
  },
  [FILTER_SAVE_TYPES.DATE_AGO]: {
    save: (filterValue) =>
      !filterValue
        ? 'Any'
        : Math.round(
            moment(filterValue[1]).diff(moment(filterValue[0]), 'months', true)
          ),
    apply: (filterValue, subjectValue, config, effectiveDate) => {
      if (effectiveDate) {
        return [
          moment(effectiveDate)
            .subtract(filterValue, 'months')
            .format('YYYY-MM-DD'),
          moment(effectiveDate).format('YYYY-MM-DD')
        ];
      } else {
        return [
          moment().subtract(filterValue, 'months').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ];
      }
    },
    display: ({ relativeValue }, { label, labelAbbrev }) => {
      if (!relativeValue) return '';
      if (relativeValue >= 12) {
        const years = Math.round(relativeValue / 12);
        return `${labelAbbrev || label} Last ${years} year${
          years === 1 ? '' : 's'
        }`;
      } else {
        return `${labelAbbrev || label} Last ${relativeValue} month${
          relativeValue === 1 ? '' : 's'
        }`;
      }
    }
  },
  [FILTER_SAVE_TYPES.DISTANCE]: {
    save: (filterValue, subjectValue) =>
      _checkTypeAndCallFn(
        filterValue,
        subjectValue,
        // Return the same value since filter saved exactly as configured
        (f, s) =>
          isNaN(f) || [0.5, 1, 2, 3].indexOf(f) === -1
            ? { error: 'Custom shapes cannot be saved' }
            : f
      ),
    apply: (filterValue, subjectValue) =>
      _checkTypeAndCallFn(
        filterValue,
        subjectValue,
        // Use Exact Filter value
        (f, s) => f
      ),
    display: (value) => {
      return value && typeof value === 'string'
        ? `Custom ${value}`
        : `${value} Mile${value <= 1 ? '' : 's'} from Subject`;
    }
  },
  [FILTER_SAVE_TYPES.MATCH_RANGE]: {
    save: (filterValue, subjectValue) => {
      return subjectValue !== null &&
        subjectValue !== undefined &&
        filterValue &&
        (filterValue[0] === null || subjectValue >= filterValue[0]) &&
        (filterValue[1] === null || subjectValue <= filterValue[1])
        ? FILTER_MATCH_SUBJECT
        : filterValue;
    },
    apply: (filterValue, subjectValue, filterConfig = {}) => {
      if (filterValue === FILTER_MATCH_SUBJECT) {
        if (isPopulated(subjectValue)) {
          return filterConfig.relativeToAbsolute(filterValue, subjectValue);
        } else {
          return {
            error:
              'Cannot apply the saved filter because the Subject value is missing.'
          };
        }
      } else {
        return filterValue;
      }
    },
    display: ({ relativeValue }, { label, saveFormatter = (v) => v }) => {
      if (!relativeValue) return '';
      return relativeValue && relativeValue.error
        ? relativeValue
        : relativeValue === FILTER_MATCH_SUBJECT
        ? `Match Subject ${label}`
        : saveFormatter(relativeValue);
    },
    cannotBeApplied: (filterValues, { label }) => `${label} Match Subject`,
    quickFilterOptions: [
      {
        label: 'Match the Subect',
        value: FILTER_MATCH_SUBJECT
      }
    ]
  },
  [FILTER_SAVE_TYPES.MATCH_BOOLEAN]: {
    save: (filterValue, subjectValue) => {
      return subjectValue !== null &&
        subjectValue !== undefined &&
        !!subjectValue === !!filterValue
        ? FILTER_MATCH_SUBJECT
        : filterValue;
    },
    apply: (filterValue, subjectValue) => {
      const applied =
        filterValue === FILTER_MATCH_SUBJECT
          ? isPopulated(subjectValue)
            ? subjectValue
            : {
                error:
                  'Cannot apply the saved filter because the Subject value is missing.'
              }
          : filterValue;
      return applied;
    },
    display: ({ relativeValue }, { label, saveFormatter = (v) => v }) => {
      return relativeValue && relativeValue.error
        ? relativeValue
        : relativeValue === FILTER_MATCH_SUBJECT
        ? `Match Subject ${label}`
        : saveFormatter(relativeValue);
    },
    cannotBeApplied: (filterValues, { label }) => `${label} Match Subject`,
    quickFilterOptions: [
      {
        label: 'Match the Subect',
        value: FILTER_MATCH_SUBJECT
      }
    ]
  }
};

export const filtersSavedToRelativeValues = (filtersSaved) => {
  const filterValues = {};
  Object.entries(filtersSaved.values).forEach(([filterKey, filterSaved]) => {
    if (filterKey !== 'distance') {
      filterValues[filterKey] = filterSaved.value;
    }
  });
  return filterValues;
};

export const filtersSavedThatCannotBeApplied = (
  filtersSaved,
  subject,
  compType
) => {
  const notApplied = [];
  if (filtersSaved) {
    for (let key in filtersSaved.values) {
      const filter = filtersSaved.values[key];
      const subjectValue =
        compType === COMP_TYPE_RENTAL
          ? getSubjectRentalFilterValue
          : getSubjectFilterValue;
      const subjectIsUndefined =
        [null, undefined, ''].indexOf(subjectValue) > -1;
      if (
        ([FILTER_SAVE_TYPES.REL_PCT, FILTER_SAVE_TYPES.REL_EXACT].indexOf(
          filter.saveType
        ) > -1 &&
          subjectIsUndefined) ||
        ([
          FILTER_SAVE_TYPES.MATCH_RANGE,
          FILTER_SAVE_TYPES.MATCH_BOOLEAN
        ].indexOf(filter.saveType) > -1 &&
          filter.value === FILTER_MATCH_SUBJECT &&
          subjectIsUndefined)
      ) {
        notApplied.push({
          displayError: FILTER_SAVE_METHODS[filter.saveType].cannotBeApplied
            ? FILTER_SAVE_METHODS[filter.saveType].cannotBeApplied(
                filter.value,
                FILTERS[key]
              )
            : FILTER_SAVE_METHODS[filter.saveType].display(
                filter.value,
                FILTERS[key]
              ),
          key
        });
      }
    }
  }
  return notApplied;
};

export const canDistanceFilterBeSaved = (v) => [0.5, 1, 2, 3].includes(v);

export const prepFiltersToSaveForApi = (
  filters,
  filterSetId,
  filterSetName,
  reportId,
  makeDefault,
  currentPreferences = {}
) => {
  // Using a structure that will support saving multiple filter sets in the future:
  // {
  //   activeFilterSet: filter_set_id,
  //   filterSets: {
  //     [filter_set_id]: {
  //       values: {
  //         [filter_key]: {
  //           // This is nested to support multiple ways of saving a particular filter
  //           // This example shows a filter value range based on a % of subject value
  //           saveType: 'REL_PCT', // key for determining how to calculate and apply saved filter values
  //           value: [-0.1, 0.2], // filters for properies that are -10% to +20% of the subject value
  //         },
  //         ...other_filter_values
  //       },
  //       label: 'Label for filter set',
  //         createdBy: report_id
  //     }
  //   }
  // }

  let formatted = {
    activeFilterSet: makeDefault
      ? filterSetId
      : !makeDefault && filterSetId === currentPreferences.activeFilterSet
      ? // Allows default to be cleared
        null
      : currentPreferences.activeFilterSet,
    filterSets: {
      ...(currentPreferences.filterSets || {}),
      [filterSetId]: {
        label: filterSetName,
        values: {},
        createdBy: reportId
      }
    }
  };

  Object.entries(filters).forEach(([filterKey, filterValues]) => {
    let saveFilter = true;
    let value;
    const config = FILTERS[filterKey];
    if (filterKey === 'distance') {
      if (canDistanceFilterBeSaved(filterValues)) {
        value = filterValues;
      } else {
        saveFilter = false;
      }
    } else {
      value = filterValues.relativeValue;
      if (filterValues.relativeValue.error) {
        saveFilter = false;
      }
    }
    // Do not save filters w/ errors
    if (saveFilter) {
      formatted.filterSets[filterSetId].values[filterKey] = {
        saveType: config.saveType,
        value
      };
    }
  });

  return formatted;
};

export const deleteSavedFilterSet = (filterSetId, filtersSaved = {}) => {
  if (
    filtersSaved &&
    filtersSaved.filterSets &&
    Object.keys(filtersSaved.filterSets).length === 1
  ) {
    return null;
  }
  const updatedFiltersSaved = { ...filtersSaved };
  if (filtersSaved.activeFilterSet === filterSetId) {
    updatedFiltersSaved.activeFilterSet = null;
  }
  delete updatedFiltersSaved.filterSets[filterSetId];
  return updatedFiltersSaved;
};
