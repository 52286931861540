import React from 'react';
import { RadioOption, RadioSelect } from '../RadioSelect';

type MonthsAgoOption = RadioOption<number | null>;
interface Props {
  dataHcName: string;
  className?: string;
  value: number | null;
  onChange: (value: number | null) => void;
}

const OPTIONS: MonthsAgoOption[] = [
  {
    label: 'Any',
    value: null
  },
  {
    label: 'Prior 3 Months',
    value: 3
  },
  {
    label: 'Prior 6 Months',
    value: 6
  },
  {
    label: 'Prior 9 Months',
    value: 9
  },
  {
    label: 'Prior Year',
    value: 12
  },
  {
    label: 'Prior 2 Years',
    value: 24
  }
];
export const MonthsAgoSelect = ({
  dataHcName,
  className,
  value,
  onChange
}: Props) => {
  return (
    <RadioSelect
      dataHcName={dataHcName}
      className={className}
      value={value}
      options={OPTIONS}
      onChange={onChange}
    />
  );
};
