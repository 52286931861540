import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import AddressAutoComplete from 'address-autocomplete/public/autocomplete-react';
import SVGInline from 'react-svg-inline';

import { VIEWS } from 'legacy/routes/constants';

import { getAddressPartsAction } from 'actions/address-parts';
import { analyticsEvent } from 'actions/analytics';
import { hideMobileSearch } from 'actions/app-header';
import { archivedReportsOpenModal } from 'actions/archived-reports';
import { propertySearchHideOverlay } from 'actions/property-search';
import { toastShow } from 'actions/toast';

import { getToken } from 'selectors/account-details';
import { getMqSmallMediumAndUp } from 'selectors/match-media';
import { getRecentReportsForAddressSearch } from 'selectors/recent-reports';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { ERROR_TEXT } from 'legacy/appstore/constants';
import { EVENTS } from 'legacy/appstore/events';

import searchIcon from 'legacy/assets/svg-inline/search.svg';

import { AddressSearch as theme } from 'legacy/css-modules';

const MESSAGE_FOR_ERROR = ERROR_TEXT.ADDRESS_SEARCH;

// type Props = {
//   onFocus: VoidFunction;
// }
export const SubjectSearchAddress = ({ onBlur, onSelect, onFocus }) => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const recentReports = useSelector(getRecentReportsForAddressSearch);
  const isMobile = !useSelector(getMqSmallMediumAndUp);
  const [notFoundQuery, setNotFoundQuery] = useState('');
  const [isFocused, setIsFocused] = useState('');

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
    setIsFocused(false);
  };
  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
    setIsFocused(true);
  };
  return (
    <div className={classNames({ [theme.focused]: isFocused })}>
      <AddressAutoComplete
        token={token}
        minCharsStart={1}
        searchEndpoint={HC_CONSTANTS.ADDRESS_SEARCH_URL}
        searchGroupByBuildingEndpoint={HC_CONSTANTS.ADDRESS_SEARCH_URL_BY_BLDG}
        searchQueryParamKey="q"
        placeholder="Search for an Address"
        searchButtonIcon={<SVGInline svg={searchIcon} />}
        hasAvm={1}
        prefilledSuggestionsLabel="Reports within the last 30 days"
        prefilledSuggestions={recentReports || []}
        addressClickCallback={(selectedObj) => {
          let report = selectedObj.report;
          // Clicking a previous report from the dropdown
          if (selectedObj.isPrefilledSuggestion) {
            if (!report) {
              throw new Error('Expected previous report data not provided');
            }
            const {
              unit,
              unitDesignator,
              addressSlug,
              listingId,
              listingSource
            } = report;
            const query = {
              address_slug: addressSlug || undefined,
              unit_designator: unitDesignator || undefined,
              unit: unit || undefined,
              listing_id: listingId || undefined,
              listing_source: listingSource || undefined
            };
            dispatch(
              routeChange({
                view: VIEWS.VALUE_REPORT,
                params: report,
                query,
                options: { inherit: false }
              })
            );
            dispatch(propertySearchHideOverlay());
            // Clicking an autocomplete result
          } else {
            dispatch(getAddressPartsAction(selectedObj.slug));
            dispatch(propertySearchHideOverlay());
          }
          dispatch(hideMobileSearch());
          if (onSelect) {
            onSelect(selectedObj.slug);
          }
        }}
        // When the user clicks "Can't find your address" or "Why isn't my address appearing"
        addressNotFoundClickCallback={() => {
          analyticsEvent(EVENTS.SEARCH_NO_RESULT_HELP_CLICK, notFoundQuery);
          window.open('https://support.housecanary.com');
        }}
        prefilledSuggestionsViewAllClickCallback={() => {
          dispatch(hideMobileSearch());
          dispatch(archivedReportsOpenModal());
        }}
        // When the user hits enter or clicks the "search" button when the search field
        // either contains no text or contains an unsupported address
        onErrorCallback={({ message }) => {
          dispatch(
            toastShow(
              MESSAGE_FOR_ERROR[message] || MESSAGE_FOR_ERROR.unknownError
            )
          );
        }}
        // Limit results length in mobile to cause not to be blocked by keyboard
        resultsLimit={isMobile ? 3 : 10}
        noResultsCallback={(query) => {
          setNotFoundQuery(query);
          dispatch(analyticsEvent(EVENTS.SEARCH_NO_RESULT, query));
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        clearInputOnSelect
        theme={theme}
      />
    </div>
  );
};
