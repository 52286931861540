import React, { useState, useEffect, ReactNode } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { CloseButton } from 'src/lib/components/CloseButton';
import { ID_FIXED_PORTAL } from 'src/constants';
import { Button, Props as ButtonProps } from '../Button';

import styles from './Dialog.css';
import { TextButton } from '../TextButton';

// interface Theme {
//   DialogWrapper: string;
//   Dialog: string;
//   normal: string;
//   auto: string;
//   large: string;
//   fullscreen: string;
//   Header: string;
//   CloseButton: string;
//   DialogContent: string;
//   Actions: string;
//   active: string;
//   visibleOverflow: string;
//   DialogOverlay: string;
// }

export type DialogTypes = 'normal' | 'auto' | 'large' | 'fullscreen' | 'small';

interface DialogAction extends ButtonProps {
  textButton?: boolean;
}
interface Props {
  dataHcName: string;
  children: ReactNode;
  active?: boolean;
  onClose: VoidFunction;
  title?: string;
  subtitle?: string;
  className?: string;
  actions?: DialogAction[];
  visibleOverflow?: boolean;
  width?: number | string;
  type?: DialogTypes;
  noContentPadding?: boolean;
}

export const Dialog = ({
  dataHcName,
  className,
  active,
  title,
  onClose,
  actions,
  visibleOverflow,
  subtitle,
  children,
  width,
  noContentPadding,
  type = 'normal'
}: Props) => {
  const elm = document.getElementById(ID_FIXED_PORTAL);
  const [activeWithTransition, setActiveWithTransition] = useState(false);
  useEffect(() => {
    setActiveWithTransition(!!active);
  }, [active]);
  if (!elm) return null;
  return createPortal(
    <>
      {active && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          data-hc-name={`${dataHcName}-wrapper`}
          className={classNames(styles.DialogWrapper, {
            [styles.active || '']: activeWithTransition,
            [styles.visibleOverflow || '']: visibleOverflow
          })}
        >
          <div
            data-hc-name={dataHcName}
            className={classNames(styles.Dialog, className, {
              [styles[type] || '']: type
            })}
            style={width ? { width } : undefined}
          >
            {onClose && (
              <CloseButton
                dataHcName={`${dataHcName}-closeButton`}
                className={styles.CloseButton}
                onClick={onClose}
              />
            )}
            {title && (
              <div className={styles.Header}>
                <h1 data-hc-name={`${dataHcName}-title`}>{title}</h1>
                {subtitle && (
                  <h2 data-hc-name={`${dataHcName}-title`}>{subtitle}</h2>
                )}
              </div>
            )}
            <div
              data-hc-name={`${dataHcName}-content`}
              className={classNames(styles.DialogContent, {
                [styles.noPadding || '']: noContentPadding
              })}
            >
              {children}
            </div>
            {actions && actions.length && (
              <div
                data-hc-name={`${dataHcName}-actions`}
                className={styles.Actions}
              >
                {actions.map(({ textButton, ...action }, i) => {
                  if (textButton) {
                    return (
                      <TextButton
                        key={`${dataHcName}-action-${i}`}
                        {...action}
                      />
                    );
                  } else {
                    return (
                      <Button key={`${dataHcName}-action-${i}`} {...action} />
                    );
                  }
                })}
              </div>
            )}
          </div>
          <div
            data-hc-name={`${dataHcName}-overlay`}
            className={styles.DialogOverlay}
            onClick={onClose || undefined}
          />
        </div>
      )}
    </>,
    elm
  );
};
