import React, { FocusEvent, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Button.css';

export interface Props {
  dataHcName: string;
  children?: ReactNode;
  label?: string | ReactNode;
  primary?: boolean;
  disabled?: boolean;
  className?: string;
  small?: boolean;
  secondary?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (e: MouseEvent<HTMLButtonElement>) => void;
  onFocus?: (e: FocusEvent<HTMLButtonElement>) => void;
  onBlur?: (e: FocusEvent<HTMLButtonElement>) => void;
}
export const Button = ({
  dataHcName,
  primary = true,
  secondary = false,
  label,
  children,
  disabled,
  className,
  small,
  ...mouseEvents
}: Props) => {
  return (
    <button
      data-hc-name={dataHcName}
      className={classNames(styles.Button, className, {
        [styles.secondary || '']: secondary || !primary,
        [styles.small || '']: small,
        [styles.disabled || '']: disabled
      })}
      {...(disabled ? {} : mouseEvents)}
    >
      {label} {children}
    </button>
  );
};
