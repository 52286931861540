import { createSelector } from 'reselect';

import { daysBetweenDates } from 'legacy/utils/dates';

import {
  getShareReportAllowSave,
  getShareReportAllowEdit
} from 'selectors/share-report';
import { getReportActiveStatus } from 'selectors/report-data';
import {
  getIsViewingSharedReport,
  getIsEffectiveDateReport
} from 'selectors/property-explorer';
import { getUserIsSelfService } from 'selectors/account-details';
import { getEffectiveDateMetadata } from 'selectors/effective-date';

export const getReportPermissionsState = (state) => state.reportPermissions;

export const getUserIsReportOwner = createSelector(
  getReportPermissionsState,
  (reportPermissions) => reportPermissions.userIsReportOwner
);

export const getSubscriptionType = createSelector(
  getReportPermissionsState,
  (reportPermissions) => reportPermissions.subscriptionType
);

export const getIsReportActive = createSelector(
  getReportActiveStatus,
  getIsEffectiveDateReport,
  getEffectiveDateMetadata,
  (activeStatus, isEffectiveDateReport, effectiveDateMetadata) => {
    if (isEffectiveDateReport) {
      return (
        daysBetweenDates(effectiveDateMetadata.createdAt, new Date()) <= 30
      );
    } else {
      return activeStatus !== 'expired';
    }
  }
);

export const getIsReportEditable = createSelector(
  getIsReportActive,
  getIsViewingSharedReport,
  getShareReportAllowEdit,
  getShareReportAllowSave,
  getUserIsReportOwner,
  getIsEffectiveDateReport,
  (
    isActive,
    isViewingSharedReport,
    shareReportAllowEdit,
    shareReportAllowSave,
    userIsReportOwner,
    isEffectiveDateReport
  ) => {
    return (
      isActive &&
      (isEffectiveDateReport
        ? userIsReportOwner
        : (isViewingSharedReport && shareReportAllowEdit) ||
          (isViewingSharedReport && shareReportAllowSave) ||
          (!isViewingSharedReport && userIsReportOwner))
    );
  }
);
export const getIsReportSaveable = createSelector(
  getReportActiveStatus,
  getIsViewingSharedReport,
  getShareReportAllowSave,
  getUserIsReportOwner,
  (
    reportActiveStatus,
    isViewingSharedReport,
    shareReportAllowSave,
    userIsReportOwner
  ) =>
    reportActiveStatus !== 'expired' &&
    ((isViewingSharedReport && shareReportAllowSave) ||
      (!isViewingSharedReport && userIsReportOwner))
);

export const getIsExcelDownloadAllowed = createSelector(
  getIsViewingSharedReport,
  getUserIsSelfService,
  getSubscriptionType,
  (isViewingSharedReport, isSelfServiceUser, subscriptionType) => {
    return isViewingSharedReport
      ? subscriptionType !== 'self-service'
      : !isSelfServiceUser;
  }
);
