import React from 'react';
import { TableCell, TableHeaderCell } from 'src/lib/components/Table';
import { formatNumber } from 'src/lib/utils';
import { FarmListCellProps, FarmListHeaderCellProps } from '../types';

// These components cannot be used as a JSX Element inside TableRow
// Example Usage:
// <Table>
//   <TableRow>
//     {bedroomsColumn.content({ reportId, hcAddressId })}
//   </TableRow>
// </Table>

export const bedroomsColumn = {
  header: (props: FarmListHeaderCellProps) => {
    return (
      <TableHeaderCell {...props} align="right">
        Beds
      </TableHeaderCell>
    );
  },
  content: ({ schema }: FarmListCellProps) => {
    return (
      <TableCell align="right">
        {formatNumber(schema.livingSpace.bedrooms?.count)}
      </TableCell>
    );
  }
};
