import { connect } from 'react-redux';

import { QUERIES, PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

import { getPropertySourceDataForQuery } from 'selectors/property';
import { getPropertyBasedOnContext } from 'selectors/property-context';

import PropertyHeaderDetails from 'legacy/components/PropertyHeaderDetails';

const { MLS } = PROPERTY_DATA_SOURCES;

const mapStateToProps = (state, ownProps) => {
  const { addressId, propertyContext, compType } = ownProps;
  return {
    compType,
    ...getPropertyBasedOnContext(state, propertyContext, addressId),
    listingDetails: getPropertySourceDataForQuery(
      addressId,
      MLS,
      QUERIES.LISTING_DETAILS
    )(state)
  };
};

export default connect(mapStateToProps)(PropertyHeaderDetails);
