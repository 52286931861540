import { isPositive } from 'src/lib/utils/math';
import {
  FilterRangeType,
  RANGE_TYPES
} from 'src/lib/components/FilterRange/types';
import { capitalizeFirstLetter } from 'src/lib/utils/strings';

export const validateRange = (value: FilterRangeType): string | undefined => {
  const types: RANGE_TYPES[] = [RANGE_TYPES.MAX, RANGE_TYPES.MIN];
  for (const type in types) {
    if (value[type as RANGE_TYPES] && isNaN(Number(value[type as RANGE_TYPES])))
      return `${capitalizeFirstLetter(type as string)} must be a number`;
    if (
      value[type as RANGE_TYPES] &&
      !isPositive(Number(value[type as RANGE_TYPES]))
    )
      return `${capitalizeFirstLetter(type as string)} must be positive`;
  }
  if (Number(value[RANGE_TYPES.MIN]) > Number(value[RANGE_TYPES.MAX])) {
    return 'There is a problem with this filter! The minimum value is greater than the maximum value.';
  }

  return undefined;
};

export const isNumberOrDecimalPoint = (
  val: string,
  decimalAllowed: boolean
) => {
  val = val.replace(/,/g, ' ').replace(/\$/g, '');
  if ((val.match(/\./g) || []).length > (decimalAllowed ? 1 : 0)) {
    return false; //too many decimal points
  }
  if (decimalAllowed && val.length > 0 && val.charAt(val.length - 1) === '.') {
    val = val.slice(0, val.length - 1);
  }
  return !isNaN(Number(val));
};

export const formatFilterButtonValuePercentage = (
  v: null | undefined | number
) =>
  v === null || v === undefined
    ? '-'
    : `${v > 0 ? '+' : v === 0 ? '' : '-'}${Math.round(100 * Math.abs(v))}%`;

export const formatFilterButtonValueExact = (
  v: null | undefined | number,
  plusMinus = true
) =>
  v === null || v === undefined
    ? '-'
    : `${plusMinus ? (v > 0 ? '+' : v === 0 ? '' : '-') : ''}${Math.abs(v)}`;
