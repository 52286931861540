import type { Size } from './types';

import React, { MouseEvent, ReactNode } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';

import { FullscreenButton } from 'src/lib/components/FullscreenButton';

import styles from './PhotoGridThumbnail.css';
import { PhotoRepresentation } from 'src/lib/property-lookup/types';
import { EmptyObject } from 'redux';
import { isHTMLImageElement } from 'src/lib/utils/photos';

export type PhotoGridThumbnailProps = {
  // Size of the thumbnail, indicates how many images per row at full-width (1 = largest, 7 = smallest)
  size: Size;
  // Photo data (url and thumbnail/fullscreen metadata)
  photo: PhotoRepresentation;
  // Flag to show fullscreen button hover effects on thumbnail
  fullscreen: boolean;
  // Label or overlay for photo
  label: string;
  // QA Selector
  dataHcName: string;
  // Generic ReactNode we don't know what will be children here
  children?: ReactNode;
  className?: string;
  // Callback when the thumbnail is clicked
  onClick: (event: MouseEvent<HTMLElement>) => void;
};

type ThumbDimensionDict = {
  [size in Size]: number; // eslint-disable-line
};

// Constants to define thumbnail sizes
const MAX_WIDTH = 1460;
const THUMB_RATIO = 0.7627118644067796;
const THUMB_WIDTH: ThumbDimensionDict = {
  1: MAX_WIDTH / 1,
  2: MAX_WIDTH / 2,
  3: MAX_WIDTH / 3,
  4: MAX_WIDTH / 4,
  5: MAX_WIDTH / 5,
  6: MAX_WIDTH / 6,
  7: MAX_WIDTH / 7
};

const THUMB_HEIGHT: ThumbDimensionDict = {
  1: THUMB_WIDTH[1] * THUMB_RATIO,
  2: THUMB_WIDTH[2] * THUMB_RATIO,
  3: THUMB_WIDTH[3] * THUMB_RATIO,
  4: THUMB_WIDTH[4] * THUMB_RATIO,
  5: THUMB_WIDTH[5] * THUMB_RATIO,
  6: THUMB_WIDTH[6] * THUMB_RATIO,
  7: THUMB_WIDTH[7] * THUMB_RATIO
};

type Dimensions = {
  width: number;
  height: number;
};

export const PhotoGridThumbnail = ({
  dataHcName,
  size = 4,
  label,
  fullscreen = false,
  photo,
  children,
  className,
  onClick
}: PhotoGridThumbnailProps) => {
  const { httpUrl, width, height } = photo;
  const [loadedDimensions, setLoadedDimensions] = React.useState<Dimensions>({
    width: 0,
    height: 0
  });

  const [blurStyle, setBlurStyle] = React.useState<
    EmptyObject | { filter: string }
  >({ filter: 'blur(15px)' });

  const maxDimensions: Dimensions =
    height != null
      ? {
          height,
          width
        }
      : loadedDimensions.width != null
      ? loadedDimensions
      : {
          width: THUMB_WIDTH[size],
          height: THUMB_HEIGHT[size]
        };

  const dimensions =
    THUMB_HEIGHT[size] > maxDimensions.height ||
    THUMB_WIDTH[size] > maxDimensions.width
      ? {
          height: maxDimensions.height,
          width: maxDimensions.width
        }
      : {
          height: THUMB_HEIGHT[size],
          width: THUMB_WIDTH[size]
        };

  const thumbStyle =
    size === 1
      ? { ...blurStyle, width: `${maxDimensions.width}` }
      : {
          ...blurStyle,
          height: `${dimensions.height}px`,
          flex: `0 0 ${dimensions.width}px`
        };

  return (
    <div
      className={classNames(className, styles.Thumbnail)}
      style={thumbStyle}
      data-hc-name={`${dataHcName}`}
    >
      {label && (
        <div
          className={styles.ThumbnailOverlay}
          data-hc-name={`${dataHcName}-thumbnail-overlay`}
        >
          {label}
        </div>
      )}
      {fullscreen && (
        <FullscreenButton
          theme={styles}
          onClick={onClick}
          dataHcName={`${dataHcName}-fullscreen-button`}
        />
      )}
      <LazyLoadImage
        wrapperClassName={classNames(styles.LazyLoadImage, {
          [styles.clickable as string]: !!onClick
        })}
        style={thumbStyle}
        alt={httpUrl}
        data-hc-name={`${dataHcName}-image`}
        onLoad={(imageEvent) => {
          if (isHTMLImageElement(imageEvent.target)) {
            setLoadedDimensions({
              width: imageEvent.target.naturalWidth,
              height: imageEvent.target.naturalHeight
            });
          } // NOTE: Using onLoad breaks the effects so setting a style and clearing it
          // fixes this issue
          setBlurStyle({});
        }}
        src={httpUrl}
        onClick={onClick}
      />
      {children}
    </div>
  );
};
