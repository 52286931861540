import classNames from 'classnames';

import Tooltip from 'legacy/components/generic/Tooltip';

import { PriceAdjustmentTooltip as theme } from 'legacy/css-modules';

const Icon = ({ inContent }) => {
  return (
    <div
      className={classNames(theme.Icon, {
        [theme.inContent]: inContent
      })}
    >
      <div className={theme.IconText}>$</div>
    </div>
  );
};

const PriceAdjustmentTooltip = ({ infoIcon, title, content }) => {
  return (
    <Tooltip
      theme={theme}
      className={classNames({
        [theme.Info]: infoIcon
      })}
      trigger={infoIcon ? undefined : <Icon />}
      content={
        <div className={theme.Content}>
          <Icon inContent />
          <h3 className={theme.Title}>{title}</h3>
          <p className={theme.Copy}>{content}</p>
        </div>
      }
      shift={infoIcon ? 'right' : 'left'}
      transparentScreen
    />
  );
};

export default PriceAdjustmentTooltip;
