export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const overflowScrollIntoView = (refElement, refContainer) => {
  // scrolls the container so the element is visible in the overflow container
  const activeOffset = refElement.current.offsetTop;
  const activeHeight = refElement.current.clientHeight;
  const containerScroll = refContainer.current.scrollTop;
  const containerHeight = refContainer.current.clientHeight;
  if (activeOffset + activeHeight > containerScroll + containerHeight) {
    refContainer.current.scrollTop =
      activeOffset + activeHeight - containerHeight;
  } else if (containerScroll > activeOffset) {
    refContainer.current.scrollTop = activeOffset;
  }
};
