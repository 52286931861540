import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { TextAreaBoxField as theme } from 'legacy/css-modules/generic';

const TextAreaBoxField = ({ name, label, maxLength, value }) => {
  return (
    <div className={theme['hc-field-textarea-box']}>
      <Field
        name={name}
        className={theme['hc-field-textarea-box--field']}
        placeholder={label}
        maxLength={maxLength}
        component="textarea"
        type="text"
      />
      {maxLength && (
        <div className={theme['hc-field-textarea-box--maxlength']}>
          {value ? value.length : 0} / {maxLength}
        </div>
      )}
    </div>
  );
};

TextAreaBoxField.propTypes = {
  name: PropTypes.string.isRequired,

  label: PropTypes.string,
  maxLength: PropTypes.number
};

export default TextAreaBoxField;
