import { Input as InputCL } from '@hc/component-lib/hclib/components/atoms/input';
import classNames from 'classnames';
import inputTheme from './Input.css-module.css';
import { combineThemes } from 'legacy/utils/theme.utils.js';

export const Input = (props) => (
  <InputCL
    {...props}
    className={classNames(props.className, inputTheme.HcsInput)}
    theme={combineThemes(inputTheme, props.theme)}
  />
);
