import { watchLatest } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { put, select, call } from 'redux-saga/effects';

import { EDIT_ONLY_TOAST_MSG } from 'legacy/appstore/constants';

import { saveReport } from 'actions/edit-report';
import {
  AVMS_UPDATE_USER_ENTERED_VALUE,
  avmsUpdateUserEnteredValueSuccess,
  AVMS_UPDATE_USER_ENTERED_RENTAL_VALUE,
  avmsUpdateUserEnteredRentalValueSuccess
} from 'actions/avms';
import { toastShow } from 'actions/toast';

import { getSubjectAdjusted } from 'selectors/subject';
import { getIsReportSaveable } from 'selectors/report-permissions';

function* updateUserEnteredAvm(value, successAction) {
  const subject = yield select(getSubjectAdjusted);
  const valueSqft = value / subject.grossLivingAreaSqft;
  const userEnteredAvm = {
    maxVal: value,
    maxValPerSqft: valueSqft,
    minVal: value,
    minValPerSqft: valueSqft,
    valPerSqft: valueSqft,
    valuationSuitabilityScore: null,
    valuationSuitabilityScoreDesc: null,
    value
  };
  yield put(successAction(userEnteredAvm));
  const reportIsSavable = yield select(getIsReportSaveable);
  if (reportIsSavable) {
    yield put(saveReport());
  } else {
    yield put(toastShow(EDIT_ONLY_TOAST_MSG));
  }
}

function* updateUserEnteredAvmDefault(action) {
  try {
    const { value } = action.payload;
    yield call(updateUserEnteredAvm, value, avmsUpdateUserEnteredValueSuccess);
  } catch (e) {
    console.error(e);
    report(`Update User Entered Value Avm: ${e.message}`, { e, action });
  }
}

function* updateUserEnteredAvmRental(action) {
  try {
    const { value } = action.payload;
    yield call(
      updateUserEnteredAvm,
      value,
      avmsUpdateUserEnteredRentalValueSuccess
    );
  } catch (e) {
    console.error(e);
    report(`Update User Entered Value Avm: ${e.message}`, { e, action });
  }
}

export default function registerUserEnteredValueSaga() {
  watchLatest({
    [AVMS_UPDATE_USER_ENTERED_VALUE]: updateUserEnteredAvmDefault
  });
  watchLatest({
    [AVMS_UPDATE_USER_ENTERED_RENTAL_VALUE]: updateUserEnteredAvmRental
  });
}
