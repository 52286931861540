// This feature controls route changes when no comps are selected
import React from 'react';
import { useDispatch } from 'react-redux';
import { ReportValue as ReportValueContainer } from '../../../lib/report-api/features/ReportValue/ReportValue';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { VIEWS } from 'src/pexp/routes';

interface Props {
  reportId: number;
  dataHcName: string;
}

export const ReportValue = ({ reportId, dataHcName }: Props) => {
  const dispatch = useDispatch();
  const handleSelect = () => {
    dispatch(
      routeChange({
        view: VIEWS.EFFECTIVE_DATE_COMPS,
        options: { inherit: true }
      })
    );
  };
  return (
    <ReportValueContainer
      reportId={reportId}
      dataHcName={dataHcName}
      onClickSelectComps={handleSelect}
    />
  );
};
