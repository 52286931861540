export const SUBJECT_UPDATE_GEO_LOCATION = 'SUBJECT_UPDATE_GEO_LOCATION';

export const subjectUpdateGeoLocation = (
  geoLocation,
  geoPrecision = 'user',
  polygons,
  mapId,
  nextView,
  locationUnknown
) => {
  return {
    type: SUBJECT_UPDATE_GEO_LOCATION,
    payload: { geoLocation, geoPrecision, polygons, mapId, locationUnknown },
    meta: {
      router: {
        view: nextView,
        options: { inherit: true }
      }
    }
  };
};
