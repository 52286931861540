import { COMP_TYPE_DEFAULT, COMP_TYPE_RENTAL } from 'legacy/appstore/constants';

import {
  VIEWS,
  VR_REDIRECT_VIEW_MOD,
  ACTION_PAGE_LOAD_COMPS,
  ACTION_PAGE_LOAD_RENTAL_COMPS
} from 'legacy/routes/constants';

import { handleErrors } from 'legacy/utils/saga';

import { initPropertyExplorer } from 'sagas/init-property-explorer';

import routeCompsCompare from 'legacy/routes/comps-compare';
import routeCompsPages from 'legacy/routes/comps-pages';
import routeVrRedirect from 'legacy/routes/vr-redirect';

import {
  getRouteQueryCompsPages,
  getRouteQueryRentalCompsPages
} from 'selectors/routes';

import * as Async from 'legacy/async-pages';
import CompsPageWrapper from 'legacy/pages/CompsPageWrapper';

const routesReport = {
  [VIEWS.VALUE_REPORT]: {
    path: '/property-explorer/:streetAddress/:zipcode/:city/:state/:lat/:lon',
    query: [
      'unit',
      'unit_designator',
      'address_slug',
      'visiblePropertyDetailsType',
      'addressId',
      'addressIdPropertyDetails',
      'listing_id',
      'listing_source',
      'chartModal',
      'commentModal',
      'clientId'
    ],
    component: Async.PropertyExplorer,
    saga: handleErrors(initPropertyExplorer),
    loginRequired: true
  },
  [VIEWS.COMPS_SELECTION]: {
    path: '/property-explorer/:streetAddress/:zipcode/:city/:state/:lat/:lon/comps',
    query: [
      'unit',
      'unit_designator',
      'address_slug',
      'viewType',
      'viewSelected'
    ],
    saga: handleErrors(routeCompsPages),
    loginRequired: true,
    querySelector: getRouteQueryCompsPages,
    component: CompsPageWrapper(COMP_TYPE_DEFAULT),
    actionType: ACTION_PAGE_LOAD_COMPS,
    template: {
      LeftPane: Async.CompMap,
      RightPane: Async.CompsList
    },
    props: {
      compType: COMP_TYPE_DEFAULT,
      viewBack: VIEWS.VALUE_REPORT,
      viewSelection: VIEWS.COMPS_SELECTION,
      viewMap: VIEWS.COMPS_SELECTION_MAP,
      mobileFullWidth: 'RightPane'
    }
  },
  [VIEWS.COMPS_SELECTION_MAP]: {
    path: '/property-explorer/:streetAddress/:zipcode/:city/:state/:lat/:lon/comps/map',
    query: ['unit', 'unit_designator', 'address_slug'],
    saga: handleErrors(routeCompsCompare),
    loginRequired: true,
    component: Async.CompsSelectionMap,
    props: {
      compType: COMP_TYPE_DEFAULT,
      viewReport: VIEWS.VALUE_REPORT,
      viewSelection: VIEWS.COMPS_SELECTION
    }
  },
  [VIEWS.RENTAL_COMPS_SELECTION_MAP]: {
    path: '/property-explorer/:streetAddress/:zipcode/:city/:state/:lat/:lon/rental-comps/map',
    query: ['unit', 'unit_designator', 'address_slug'],
    saga: handleErrors(routeCompsCompare),
    loginRequired: true,
    component: Async.CompsSelectionMap,
    props: {
      compType: COMP_TYPE_RENTAL,
      viewReport: VIEWS.VALUE_REPORT,
      viewSelection: VIEWS.RENTAL_COMPS_SELECTION
    }
  },
  [VIEWS.RENTAL_COMPS_SELECTION]: {
    path: '/property-explorer/:streetAddress/:zipcode/:city/:state/:lat/:lon/rental-comps',
    query: [
      'unit',
      'unit_designator',
      'address_slug',
      'viewType',
      'viewSelected'
    ],
    saga: handleErrors(routeCompsPages),
    loginRequired: true,
    querySelector: getRouteQueryRentalCompsPages,
    component: CompsPageWrapper(COMP_TYPE_RENTAL),
    actionType: ACTION_PAGE_LOAD_RENTAL_COMPS,
    template: {
      LeftPane: Async.CompMap,
      RightPane: Async.CompsList
    },
    props: {
      compType: COMP_TYPE_RENTAL,
      viewBack: VIEWS.VALUE_REPORT,
      viewSelection: VIEWS.RENTAL_COMPS_SELECTION,
      viewMap: VIEWS.RENTAL_COMPS_SELECTION_MAP,
      mobileFullWidth: 'RightPane'
    }
  },
  [VIEWS.FULL_MAP_VALUE_REPORT]: {
    path: '/property-explorer/:streetAddress/:zipcode/:city/:state/:lat/:lon/:maptype/full-map',
    query: ['unit', 'unit_designator', 'address_slug'],
    component: Async.PropertyExplorerFullMap,
    saga: handleErrors(initPropertyExplorer),
    loginRequired: true
  },
  [VIEWS.LOCATION_MAP]: {
    path: '/property-explorer/:streetAddress/:zipcode/:city/:state/:lat/:lon/location/map',
    query: ['unit', 'unit_designator', 'address_slug'],
    saga: handleErrors(routeCompsCompare),
    loginRequired: true,
    component: Async.CompsSelectionMap,
    props: {
      locationOnly: true,
      viewReport: VIEWS.VALUE_REPORT
    }
  }
};

const routes = { ...routesReport };

// setup redirect for old value-report urls
for (let view in routesReport) {
  routes[`${view}${VR_REDIRECT_VIEW_MOD}`] = {
    ...routesReport[view],
    path: routesReport[view].path.replace('property-explorer', 'value-report'),
    saga: routeVrRedirect
  };
}

export default routes;
