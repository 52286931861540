export enum CompFields {
  address = 'address',
  similarity = 'similarity',
  distanceMiles = 'distanceMiles',
  bedrooms = 'bedrooms',
  bathrooms = 'bathrooms',
  grossLivingAreaSqft = 'grossLivingAreaSqft',
  siteAreaSqft = 'siteAreaSqft',
  propertyType = 'propertyType',
  yearBuilt = 'yearBuilt',
  propertyStatus = 'propertyStatus',
  subdivisionName = 'subdivisionName',
  salesDate = 'salesDate',
  salesPrice = 'salesPrice',
  pricePerSqft = 'pricePerSqft',
  pricePerSqftNonDisclosure = 'pricePerSqftNonDisclosure',
  lastListDate = 'lastListDate',
  lastListPrice = 'lastListPrice',
  activeDaysOnMarket = 'activeDaysOnMarket',
  cumulativeDaysOnMarket = 'cumulativeDaysOnMarket',
  currentValue = 'currentValue',
  pool = 'pool',
  garageNumCars = 'garageNumCars',
  stories = 'stories',
  basement = 'basement',
  isDistressed = 'isDistressed',
  flips = 'flips'
}
