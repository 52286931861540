import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUSES } from 'src/constants';
import { documentRequired } from '../actions';
import { selDocument, selDocumentStatus } from '../selectors';
import { DocumentBase } from '../types';
// import { useReportEvents } from './useReportEvents';

export const useDocument = <DocType extends DocumentBase>(
  reportId: number,
  documentId: string
): { status: STATUSES | undefined; data: DocType | undefined } => {
  const dispatch = useDispatch();
  // useReportEvents(reportId);
  const status = useSelector(selDocumentStatus([reportId, documentId]));
  const data = useSelector(selDocument<DocType>([reportId, documentId]));
  useEffect(() => {
    if (!status) {
      dispatch(documentRequired(reportId, documentId));
    }
  }, [status, reportId, documentId]);
  return {
    status,
    data
  };
};
