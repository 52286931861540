export const BROWSER_OPEN = 'BROWSER_OPEN';
export const BROWSER_REDIRECT = 'BROWSER_REDIRECT';
export const BROWSER_NAV_CONFIRM_ADD = 'BROWSER_NAV_CONFIRM_ADD';
export const BROWSER_NAV_CONFIRM_ADDED = 'BROWSER_NAV_CONFIRM_ADDED';
export const BROWSER_NAV_CONFIRM_REMOVE = 'BROWSER_NAV_CONFIRM_REMOVE';

export const browserOpen = (absoluteUrl) => ({
  type: BROWSER_OPEN,
  payload: { url: absoluteUrl }
});
export const browserRedirect = (absoluteUrl) => ({
  type: BROWSER_REDIRECT,
  payload: { url: absoluteUrl }
});

export const browserNavConfirmAdd = (message) => ({
  type: BROWSER_NAV_CONFIRM_ADD,
  payload: { message }
});
export const browserNavConfirmAdded = (payload) => ({
  type: BROWSER_NAV_CONFIRM_ADDED,
  payload
});
export const browserNavConfirmRemove = () => ({
  type: BROWSER_NAV_CONFIRM_REMOVE
});
