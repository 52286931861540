import { watchEvery } from 'legacy/utils/saga';
import { call, apply, put } from 'redux-saga/effects';
import Raven from 'raven-js';
import { getAuthClient } from '@hc/authentication-lib/hclib/clients';
import { updateUserContextCallback } from 'legacy/utils/refresh-token-callback';
import HC_CONSTANTS from 'HC_CONSTANTS';
import { LOG_USER_OUT } from 'actions/auth';
import { resetAppState } from 'legacy/appstore/clear-reducer-on-actions';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { toastShow } from 'actions/toast';
import apiUtil from 'legacy/utils/api';
import mixpanelService from 'legacy/services/mixpanel';

import { VIEWS } from 'legacy/routes/constants';

const authUrl = `${HC_CONSTANTS.AUTH_LIB_URL}/`;

const authClient = getAuthClient(
  HC_CONSTANTS.LOGIN_API_URL,
  authUrl,
  updateUserContextCallback
);

export function* logUserOut(action) {
  let auth2;
  try {
    yield apply(authClient, authClient.logout);

    if (window.gapi && window.gapi.auth2) {
      auth2 = yield apply(window.gapi.auth2, window.gapi.auth2.getAuthInstance);
      if (auth2 && auth2.isSignedIn && auth2.isSignedIn.hg) {
        yield apply(auth2, auth2.signOut);
      }
    }
    yield call([Raven, Raven.setUserContext]);
    yield put(resetAppState());
    yield put(routeChange({ view: VIEWS.LOGIN, options: { inherit: false } }));

    yield apply(mixpanelService, mixpanelService.clearCookies);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(toastShow(error.data.message));
    }
    // reload the app and take them to login page
    yield put(routeChange({ view: VIEWS.LOGIN, options: { inherit: false } }));
    // Rethrow for Sentry reporting
    throw error;
  }
}

export function registerLogoutSaga() {
  watchEvery({
    [LOG_USER_OUT]: logUserOut
  });
}
