import React, { ChangeEvent, FocusEvent } from 'react';
import { FormError } from '../FormError';
import styles from './TextArea.css';
import classNames from 'classnames';

interface Props {
  value: string;
  dataHcName: string;
  className?: string;
  label?: string;
  name?: string;
  maxLength?: number;
  error?: string;
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: FocusEvent) => void;
  onBlur?: (e: FocusEvent) => void;
}

export const TextArea = ({
  value,
  label,
  maxLength,
  className,
  dataHcName,
  name,
  error,
  placeholder,
  onChange,
  onFocus,
  onBlur
}: Props) => {
  return (
    <div
      className={classNames(styles.TextArea, className)}
      data-hc-name={dataHcName}
    >
      {label && (
        <label data-hc-name={`${dataHcName}-label`} className={styles.Label}>
          {label}
        </label>
      )}
      <textarea
        className={styles.Input}
        value={value}
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        data-hc-name={`${dataHcName}-input`}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <div className={styles.Meta}>
        {error && (
          <FormError dataHcName={`${dataHcName}-error`} value={error} />
        )}
        {maxLength && (
          <div
            className={styles.ChartCount}
            data-hc-name={`${dataHcName}-char-count`}
          >
            {`${value ? value.length : 0} / ${maxLength}`}
          </div>
        )}
      </div>
    </div>
  );
};
