import React from 'react';
import classNames from 'classnames';
import { MapMarker } from './MapMarker';
import styles from './MapMarkers.css';
import { HomeIcon } from 'src/lib/inline-svgs';

interface Props {
  dataHcName: string;
  className?: string;
}
export const SubjectMarker = ({ dataHcName, className }: Props) => {
  return (
    <MapMarker
      dataHcName={dataHcName}
      className={classNames(styles.subject, className)}
    >
      <HomeIcon
        height="15px"
        dataHcName={`${dataHcName}-icon`}
        className={styles.HomeIcon}
      />
    </MapMarker>
  );
};
