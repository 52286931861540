import { AxiosResponse, default as axios } from 'axios';
import { buildStreetViewMetadataUrl } from 'src/lib/utils/streetView';
import { GeoLocation } from '../property-lookup/types';

async function fetchStreetView({
  latitude,
  longitude
}: GeoLocation): Promise<AxiosResponse> {
  const response = await axios.get(
    buildStreetViewMetadataUrl(latitude, longitude)
  );
  return response;
}

export const StreetViewApi = {
  fetchStreetView
};
