import React, {
  cloneElement,
  CSSProperties,
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import styles from './Table.css';
import { TableHeader, Props as TableHeaderProps } from './TableHeader';
import { TableRow, Props as TableRowProps } from './TableRow';

const isTableRow = (
  child: ReactElement<TableHeaderProps> | ReactElement<TableRowProps>
): child is ReactElement<TableRowProps> => {
  return child.type === TableRow;
};

const isTableHeader = (
  child: ReactElement<TableHeaderProps> | ReactElement<TableRowProps>
): child is ReactElement<TableHeaderProps> => {
  return child.type === TableHeader;
};

interface Props {
  dataHcName: string;
  className?: string;
  // Defines a static height for each row. Enables lazy rendering of table rows.
  rowHeight?: number;
  // Defines a static height for the header. Defaults to rowHeight if set.
  headerHeight?: number;
  // Inline Styles
  style?: CSSProperties;
  // TableHeader + TableRows
  children: ReactNode;
  // Apply white-space: nowrap to cells, true by default
  noWrap?: boolean;
  // Buffer in Pixels to render rows outside visible scroll window
  lazyRenderBuffer?: number;
}
export const Table = ({
  dataHcName,
  className,
  style = {},
  children,
  noWrap = true,
  lazyRenderBuffer = 200
}: Props) => {
  const [scrollInfo, setScrollInfo] = useState({
    scrollTop: 0,
    scrollLeft: 0,
    offsetHeight: 0
  });
  const scrollContainer = useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    if (scrollContainer?.current) {
      setScrollInfo({
        scrollTop: scrollContainer.current.scrollTop,
        scrollLeft: scrollContainer.current.scrollLeft,
        offsetHeight: scrollContainer.current.offsetHeight
      });
    }
  };
  useEffect(() => {
    if (scrollContainer?.current) {
      scrollContainer.current.addEventListener('scroll', handleScroll);
      setScrollInfo({
        scrollTop: scrollContainer.current.scrollTop,
        scrollLeft: scrollContainer.current.scrollLeft,
        offsetHeight: scrollContainer.current.offsetHeight
      });
    }
    return () => {
      scrollContainer?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollContainer]);
  const { headerToRender, rowsToRender, spacerHeightAbove, spacerHeightBelow } =
    useMemo(() => {
      const header: ReactElement[] = [];
      const rows: ReactElement[] = [];
      // Amount to offset sticky rows
      let stickyOffset = 0;
      // Offset of row from top of table, used for lazy rendering
      let totalOffset = 0;
      // Track height of unrendered rows for scrollbar consistency
      let heightAbove = 0;
      let heightBelow = 0;
      const processChildren = (toProcess: ReactNode) => {
        React.Children.forEach(toProcess, (child, i) => {
          if (!React.isValidElement(child)) {
            throw new Error(
              '[Table] Invalid Child. Only TableRow, TableHeader, or Fragment can be a child of Table.'
            );
          }
          if (!isTableRow(child) && !isTableHeader(child)) {
            if (child.type === Fragment) {
              return processChildren(child.props.children);
            }
            throw new Error(
              '[Table] Invalid Child. Only TableRow, TableHeader, or Fragment can be a child of Table.'
            );
          }
          // Determine whether the child is in the visible window or above/below it.
          const visibilityStatus =
            child.props.height === undefined
              ? 'visible'
              : totalOffset >
                scrollInfo.scrollTop +
                  scrollInfo.offsetHeight +
                  lazyRenderBuffer
              ? 'below'
              : totalOffset + child.props.height <
                scrollInfo.scrollTop - lazyRenderBuffer
              ? 'above'
              : 'visible';

          if (
            visibilityStatus !== 'visible' &&
            child.props.height &&
            !child.props.sticky
          ) {
            // Keep track of the height of this unrendered child so we can set the correct spacer height for a natural scrollbar
            if (visibilityStatus === 'above') {
              heightAbove += child.props.height;
            } else if (visibilityStatus === 'below') {
              heightBelow += child.props.height;
            }
          } else {
            // Augment visible children props
            if (isTableHeader(child)) {
              // Specify the exact type of child
              header.push(
                cloneElement(child, {
                  ...child.props,
                  key: `table-row-${i}`,
                  dataHcName: child.props.dataHcName || `${dataHcName}-header`
                })
              );
            } else {
              // Specify the exact type of child
              rows.push(
                cloneElement(child, {
                  ...child.props,
                  key: `table-row-${i}`,
                  className: classNames(child.props.className, {
                    [styles.even || '']: i % 2,
                    [styles.odd || '']: !(i % 2)
                  }),
                  stickyOffset: child.props.sticky ? stickyOffset : undefined,
                  dataHcName: child.props.dataHcName || `${dataHcName}-row-${i}`
                })
              );
            }
          }
          if (child.props.height) {
            totalOffset += child.props.height;
            if (child.props.sticky) {
              stickyOffset += child.props.height;
            }
          }
        });
      };

      processChildren(children);
      return {
        rowsToRender: rows,
        headerToRender: header,
        spacerHeightAbove: heightAbove,
        spacerHeightBelow: heightBelow
      };
    }, [children, scrollInfo]);

  return (
    <div
      ref={scrollContainer}
      data-hc-name={dataHcName}
      style={style}
      className={classNames(styles.Table, className)}
    >
      <table
        className={classNames({
          [styles.noWrap || '']: noWrap,
          [styles.scrolledHorz || '']: scrollInfo.scrollLeft > 0
        })}
      >
        {headerToRender}
        <tbody>
          <tr
            className={styles.LazySpacer}
            style={{ height: `${spacerHeightAbove}px` }}
          />
          {rowsToRender}
          <tr
            className={styles.LazySpacer}
            style={{ height: `${spacerHeightBelow}px` }}
          />
        </tbody>
      </table>
    </div>
  );
};
