import get from 'lodash/get';
import { createSelector } from 'reselect';

const getMapDrawState = (state) => state.mapDraw;

const getMapDrawStateForMapId = (mapId) =>
  createSelector(getMapDrawState, (mapDrawState) =>
    get(mapDrawState, mapId, {
      polygons: []
    })
  );

export const getMapDrawPolygons = (mapId) =>
  createSelector(
    getMapDrawStateForMapId(mapId),
    (mapDrawState) => mapDrawState.polygons || []
  );

export const getMapDrawActiveControl = (mapId) =>
  createSelector(
    getMapDrawStateForMapId(mapId),
    (mapDrawState) => mapDrawState.activeControl
  );

export const getMapDrawLayerCenter = (mapId) =>
  createSelector(getMapDrawPolygons(mapId), (polygons) => {
    if (polygons && polygons.length) {
      // Currently only 1 polygon supported
      const geometry = get(polygons, [0, 'geometry'], { coordinates: [] });
      if (geometry.type === 'Point') {
        return {
          latitude: geometry.coordinates[1],
          longitude: geometry.coordinates[0]
        };
      } else {
        let latMax;
        let latMin;
        let lonMax;
        let lonMin;
        geometry.coordinates[0].forEach((coord) => {
          if (latMax === undefined || coord[1] > latMax) {
            latMax = coord[1];
          }
          if (latMin === undefined || coord[1] < latMin) {
            latMin = coord[1];
          }
          if (lonMax === undefined || coord[0] > lonMax) {
            lonMax = coord[0];
          }
          if (lonMin === undefined || coord[0] < lonMin) {
            lonMin = coord[0];
          }
        });
        return {
          latitude: latMin + (latMax - latMin) / 2,
          longitude: lonMin + (lonMax - lonMin) / 2
        };
      }
    }
  });
