import { combineReducers } from 'redux';
import { personalizationReducer } from './personalization';
import { preferencesReducer } from './preferences';

export const huellReducer = combineReducers({
  preferences: preferencesReducer,
  personalization: personalizationReducer
});

export const HUELL_REDUCER_KEY = 'huell';

export type HuellState = ReturnType<typeof huellReducer>;
export interface StateWithHuell {
  [HUELL_REDUCER_KEY]: HuellState;
}
