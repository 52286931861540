export const PROPERTY_SEARCH_REQUEST = 'PROPERTY_SEARCH_REQUEST';
export const PROPERTY_SEARCH_SUCCESS = 'PROPERTY_SEARCH_SUCCESS';
export const PROPERTY_SEARCH_FAILURE = 'PROPERTY_SEARCH_FAILURE';
export const TOGGLE_MOBILE_BOTTOM_BAR = 'TOGGLE_MOBILE_BOTTOM_BAR';
export const PROPERTY_SEARCH_HIDE_OVERLAY = 'PROPERTY_SEARCH_HIDE_OVERLAY';

export const searchProperty = (address) => ({
  type: PROPERTY_SEARCH_REQUEST,
  payload: {
    address
  }
});

export const searchPropertySuccess = (property) => ({
  type: PROPERTY_SEARCH_SUCCESS,
  payload: property
});

export const searchPropertyFailure = (error) => ({
  type: PROPERTY_SEARCH_FAILURE,
  payload: { error }
});

export const toggleMobileBottomBar = () => ({
  type: TOGGLE_MOBILE_BOTTOM_BAR
});

export const propertySearchHideOverlay = () => ({
  type: PROPERTY_SEARCH_HIDE_OVERLAY
});
