import React, { useState } from 'react';
import { Input } from '../Input';
import classNames from 'classnames';
import { FilterRangeType, FORMAT_TYPES, RANGE_TYPES } from './types';
import styles from './FilterRange.css';

import { isNumberOrDecimalPoint, validateRange } from '../../utils/validators';

import { formatOrUndefined, formatValue } from 'src/lib/utils/compFilters';
import {
  testForCommaLastChar,
  undefinedNullOrEmptyString
} from 'src/lib/utils/strings';
import { useComponentDidUpdate } from 'src/lib/hooks/useComponentDidUpdate';
import { usePrevious } from 'src/lib/hooks/usePrevious';
import { formatStripFormatting } from 'src/lib/utils';

interface FilterRangeProps {
  initialValue?: FilterRangeType;
  subjectDisplay: FilterRangeType;
  decimal?: boolean;
  dataHcName: string;
  formatType?: FORMAT_TYPES;
  onBlur?: (value: FilterRangeType) => void;
  extraValidators?: ((v: FilterRangeType) => string | undefined)[];
}

const defaultValidators = [validateRange];

export const FilterRange = ({
  initialValue = { [RANGE_TYPES.MIN]: undefined, [RANGE_TYPES.MAX]: undefined },
  onBlur,
  decimal = false,
  formatType = FORMAT_TYPES.NUMBER,
  extraValidators = [],
  subjectDisplay,
  dataHcName
}: FilterRangeProps) => {
  const formattedValue = formatValue(initialValue, decimal, formatType);
  const [value, setValue] = useState<FilterRangeType>(formattedValue);
  const prevInitialValue = usePrevious<FilterRangeType>(formattedValue);

  const [error, setError] = useState<string>('');
  const validators = [...defaultValidators, ...extraValidators];

  useComponentDidUpdate(() => {
    // If we pass in a new initial value then update
    if (
      JSON.stringify(value) !== JSON.stringify(initialValue) &&
      JSON.stringify(value) === JSON.stringify(prevInitialValue) // make sure parent has been updated before setting new value
    ) {
      setValue(formatValue(initialValue, decimal, formatType));
    }
  }, [initialValue]);

  const checkValidity = (): boolean => {
    const isValid = validators.every((validator) => {
      const validationResult = validator(
        formatOrUndefined(formatStripFormatting, value)
      );
      if (validationResult) {
        setError(validationResult);
      }
      return !validationResult;
    });
    if (isValid) setError('');
    return isValid;
  };

  const handleChange = (newValue: FilterRangeType) => {
    // If the last character is a comma it must have been typed
    // so we reject it, we only want to have comma's in the formatted value
    if (testForCommaLastChar(newValue)) {
      return;
    }
    const currentVal = { ...value, ...newValue };
    const primVal =
      RANGE_TYPES.MIN in newValue
        ? newValue[RANGE_TYPES.MIN]
        : newValue[RANGE_TYPES.MAX];

    if (
      primVal === undefined ||
      primVal.length === 0 ||
      // if decimal numbers are allowed allow 1 decimal point else 0
      (primVal &&
        isNumberOrDecimalPoint(formatStripFormatting(primVal), decimal))
    ) {
      setValue(currentVal);
    }
  };

  const handleBlur = () => {
    const newVal = formatValue(value, decimal, formatType);
    setValue(newVal);
    const isValid = checkValidity();
    if (onBlur && isValid) {
      onBlur(formatOrUndefined(formatStripFormatting, newVal));
    }
  };

  const setToNum = (type: RANGE_TYPES) => {
    const cleanValue = formatOrUndefined(formatStripFormatting, value);
    if (!undefinedNullOrEmptyString(value?.[type])) {
      const newVal = { ...cleanValue, [type]: `${Number(cleanValue?.[type])}` };
      setValue(newVal);
    }
  };

  return (
    <>
      <div className={styles.inputContainer}>
        <div className={styles.InputCell}>
          <Input
            className={classNames(styles.rangeInput, styles.minInput)}
            dataHcName={`${dataHcName}-min-input`}
            placeholder={'Min'}
            value={value?.[RANGE_TYPES.MIN] || ''}
            onFocus={() => {
              checkValidity();
              setValue({
                [RANGE_TYPES.MIN]: formatStripFormatting(
                  value?.[RANGE_TYPES.MIN] || ''
                ),
                [RANGE_TYPES.MAX]: value?.[RANGE_TYPES.MAX]
              });

              if (formatType === FORMAT_TYPES.DECIMAL_NUMBER) {
                setToNum(RANGE_TYPES.MIN);
              }
            }}
            onChange={(newVal: string) =>
              handleChange({
                [RANGE_TYPES.MIN]: newVal
              })
            }
            onBlur={handleBlur}
          />
          <div className={styles.RelativeValue}>
            {subjectDisplay[RANGE_TYPES.MIN]}
          </div>
        </div>
        <span className={styles.rangeDelimiter}>—</span>
        <div className={styles.InputCell}>
          <Input
            className={classNames(styles.rangeInput, styles.maxInput)}
            dataHcName={`${dataHcName}-max-output`}
            placeholder={'Max'}
            value={
              value[RANGE_TYPES.MAX] !== undefined
                ? `${value[RANGE_TYPES.MAX]}`
                : ''
            }
            onChange={(newVal: string) =>
              handleChange({
                [RANGE_TYPES.MAX]: newVal
              })
            }
            onFocus={() => {
              checkValidity();
              setValue({
                [RANGE_TYPES.MIN]: value?.[RANGE_TYPES.MIN],
                [RANGE_TYPES.MAX]: formatStripFormatting(
                  value?.[RANGE_TYPES.MAX] || ''
                )
              });
              if (formatType === FORMAT_TYPES.DECIMAL_NUMBER) {
                setToNum(RANGE_TYPES.MAX);
              }
            }}
            onBlur={handleBlur}
          />
          <div className={styles.RelativeValue}>
            {subjectDisplay[RANGE_TYPES.MAX]}
          </div>
        </div>
      </div>
      {error && (
        <div
          data-hc-name={`${dataHcName}-error`}
          className={styles.rangeInputError}
        >
          {error}
        </div>
      )}
    </>
  );
};
