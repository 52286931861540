import classNames from 'classnames';
import React from 'react';
import styles from './SectionDividerHorz.css';

interface Props {
  dataHcName: string;
  className?: string;
}

export const SectionDividerHorz = ({ dataHcName, className }: Props) => {
  return (
    <hr
      data-hc-name={dataHcName}
      className={classNames(styles.SectionDividerHorz, className)}
    />
  );
};
