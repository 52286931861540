import { default as axios } from 'axios';
//TODO Create a Typescript typed version of HC_CONSTANTS
//@ts-ignore
import { KEYWORD_SEARCH_SERVICE } from 'HC_CONSTANTS';
import { KeywordSearchInputs, KeywordSearchResult } from './types';

async function fetchKeywordsByListing(
  token: string,
  inputs: KeywordSearchInputs
) {
  const response = await axios.post<KeywordSearchResult[]>(
    `${KEYWORD_SEARCH_SERVICE}/by_listings`,
    inputs,
    {
      headers: { 'HC-Auth-Token': token }
    }
  );
  return response;
}

export const KeywordSearch = {
  fetchKeywordsByListing
};
