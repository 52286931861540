// This file contains all types for a Property and PropertySummary object in property-graph
// https://property-graph.dev.hc.housecanary.net/graphql

import { DateStr } from 'src/types';

// These should go in another file
export type ORDER = 'ASCENDING' | 'DESCENDING';
export type GeolocationFormat = 'GEOJSON' | 'DICT';
export type AreaUnit = 'ACRE' | 'SQFT';

// Start Property Types from Property Graph
export enum PropertyType {
  Coop = 'COOP',
  Other = 'OTHER',
  MultiFamily = 'MULTI_FAMILY', // This is ugly, I know, but it's consistent
  Commerical = 'COMMERCIAL',
  Timeshare = 'TIMESHARE',
  Manufactured = 'MANUFACTURED_HOME',
  SingleFamilyDetached = 'SINGLE_FAMILY_DETACHED',
  SingleFamilyAttached = 'SINGLE_FAMILY_ATTACHED',
  Condo = 'CONDO',
  VacantLot = 'VACANT_LOT'
}

export type ConditionRating =
  | 'CON0'
  | 'CON1'
  | 'CON2'
  | 'CON3'
  | 'CON4'
  | 'CON5';

// NOTE: The types in property-graph are wrong. These are lowercase
export type GeoPrecision =
  | 'ZIP' // Inexact geocode. Zip level accuracy.
  | 'ZIP5'
  | 'ROOFTOP' // Exact geocode of the house within the parcel
  | 'PARCEL' // Exact geocode of the center of the parcel
  | 'STREET0'
  | 'VENDOR'
  | 'ZIP9' // Inexact geocode. Zip+4 level accuracy.
  | 'ZIP7' // Inexact geocode. Zip+2 level accuracy.
  | 'MANUAL' // Geocode supplied by a user. Accuracy unknown.
  | 'UNKNOWN'
  | 'ZIP8' // Inexact geocode. Zip+3 level accuracy.
  | 'ZIP6'; // Inexact geocode. Zip+1 level accuracy.

export enum ListingStatus {
  SOLD = 'SOLD',
  PENDING = 'PENDING',
  LEASED = 'LEASED',
  UNKNOWN = 'UNKNOWN',
  CONTINGENT = 'CONTINGENT',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  WITHDRAWN = 'WITHDRAWN',
  DELETED = 'DELETED',
  COMING_SOON = 'COMING_SOON',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED'
}

export type LenderType =
  | 'insurance'
  | 'lending_institution'
  | 'subprime_lender'
  | 'credit_union'
  | 'individual_private_party'
  | 'government'
  | 'internet'
  | 'mortgage_company'
  | 'other_company'
  | 'reo_foreclosure_company'
  | 'seller'
  | 'bank'
  | 'finance_company';

export type LienType =
  | 'commercial'
  | 'land_contract'
  | 'open_end'
  | 'seller_take_back'
  | 'conventional'
  | 'fha'
  | 'revolving_credit_line'
  | 'stand_alone_second'
  | 'va'
  | 'stand_alone_first'
  | 'stand_alone_refi'
  | 'state_veterans'
  | 'usda'
  | 'arm'
  | 'construction'
  | 'fannie_mae_freddie_mac'
  | 'farmers_home_administration'
  | 'second_to_cover_down_payment';

export type MarketStatus =
  | 'STRONG_SELLERS'
  | 'SELLERS'
  | 'NEUTRAL'
  | 'BUYERS'
  | 'STRONG_BUYERS';

export type MlsStateGroup = 'OFF_MARKET' | 'ACTIVE' | 'PENDING' | 'CONTINGENT';

export type SchoolLevel = 'HIGH' | 'MIDDLE' | 'ELEMENTARY';

export type TransferFilter =
  | 'ARMS_LENGTH_PURCHASE'
  | 'UNRELEATED_PARTY'
  | 'ALL';

export type TransactionDocumentType =
  | 'SATISFACTION_OF_LAND_CONTRACT'
  | 'SURVIVORSHIP_DEED'
  | 'TRUSTEES_DEED'
  | 'JOINT_TENANCY_DEED'
  | 'PARTNERSHIP_DEED'
  | 'PERSONAL_REPRESENTATIVES_DEED'
  | 'QUIT_CLAIM_DEED'
  | 'FORECLOSURE'
  | 'GRANT_DEED'
  | 'LEGAL_ACTION_OR_COURT_ORDER'
  | 'SPECIAL_MASTER_DEED'
  | 'AGREEMENT_OF_SALE'
  | 'ASSIGNMENT_DEED'
  | 'BARGAIN_AND_SALE_DEED'
  | 'DEED_OF_TRUST'
  | 'VENDORS_LIEN'
  | 'DEED_OF_GUARDIAN'
  | 'AFFADAVIT_OF_DEATH_OF_JOINT_TENANT'
  | 'RERECORDED_DOCUMENT'
  | 'CASH_SALE_DEED'
  | 'CONSERVATORS_DEED'
  | 'CORPORATION_DEED'
  | 'CORRECTION_DEED'
  | 'DEED_IN_LIEU_OF_FORECLOSURE'
  | 'DISTRESS_SALE'
  | 'GROUND_LEASE'
  | 'WARRANTY_DEED'
  | 'BENEFICIARY_DEED'
  | 'CONDOMINIUM_DEED'
  | 'DECLARATION'
  | 'TRANSFER_ON_DEATH_DEED'
  | 'NOTICE_OF_DEFAULT'
  | 'CONTRACT_OF_SALE'
  | 'EXECUTORS_DEED'
  | 'LAND_CONTRACT'
  | 'LIMITED_WARRANTY_DEED'
  | 'INTRAFAMILY_TRANSFER_AND_DISSOLUTION'
  | 'PUBLIC_ACTION'
  | 'TRANSACTION_HISTORY_RECORD'
  | 'AFFADAVIT'
  | 'DEED_OF_DISTRIBUTION'
  | 'EXCHANGE'
  | 'GIFT_DEED'
  | 'AFFADAVIT_OF_TRUST'
  | 'CERTIFICATE_OF_TRANSFER'
  | 'FIDUCIARY_DEED'
  | 'REDEMPTION_DEED'
  | 'ADMINISTRATORS_DEED'
  | 'INDIVIDUAL_DEED'
  | 'RECEIVERS_DEED'
  | 'SPECIAL_WARRANTY_DEED';

export type TransferEventType =
  | 'ADJUSTMENT'
  | 'LEASE'
  | 'FORECLOSURE'
  | 'REFINANCE'
  | 'OTHER'
  | 'DISTRESSED'
  | 'PURCHASE'
  | 'TRANSFER';

export type MarketGrade = 'A' | 'B' | 'C' | 'D' | 'F';

export interface AddressInput {
  streetAddress: string;
  city: string;
  state: string;
  unit: string;
  zipcode: string;
}
export interface PropertyIdentifierInput {
  address?: AddressInput;
  hcAddressId?: number;
  slug?: string;
}

export interface Address {
  slug: string;
  streetAddress: string | null;
  unit: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  zipcodePlus4: string | null;
  fullAddress: string | null; // Full human readable address
  hcAddressId: number;
}

export type AssociationFrequency =
  | 'QUARTERLY'
  | 'SEMIANNUALLY'
  | 'SEMIMONTHLY'
  | 'WEEKLY'
  | 'ANNUALLY'
  | 'BIWEEKLY'
  | 'DAILY'
  | 'MONTHLY';
export interface AssociationFee {
  amount: number;
  amountPer: number; // (frequency: AssociationFrequency)
  notes: string;
  frequency: AssociationFrequency;
}
export interface Association {
  name: string;
  fees: AssociationFee[]; // Not 100% sure about this type
}

export type AvmQuality = 'HIGH' | 'MEDIUM' | 'LOW';
export type AvmQualityMethod = 'ENTERPRISE' | 'CONSUMER';

export interface Avm {
  priceMean: number | null;
  priceLower: number | null;
  priceUpper: number | null;
  fsd: number | null;
  quality: AvmQuality | null;
}

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface AvmFactors {
  // NOTE: Not entirely sure how to type this one w/o seeing examples
}

export type PhotoOrderBy = 'HC' | 'MLS';
export interface PhotoRepresentation {
  height: number;
  httpUrl: string;
  width: number;
}
export interface Photo {
  // confidence: number;
  // id: number;
  // prediction: string;
  representation: PhotoRepresentation; // (size: ImageSize)
}
export type BestPhotos = Photo[]; // (filterInactive: boolean = false, limit: number, orderby: PhotoOrderBy)

export interface CrimeStats {
  countyPercentile: number;
  incidents: number;
  nationPercentile: number;
}
export interface Crime {
  all: CrimeStats;
  other: CrimeStats;
  property: CrimeStats;
  violent: CrimeStats;
}
export type RebinMethod = 'TAILS' | 'EQUAL_WIDTH'; // Default = 'EQUAL_WIDTH'
export interface RebinInput {
  bins: number;
  method: RebinMethod;
}
export interface HistogramBin {
  count: number | null;
  end: number;
  start: number;
}
export interface Histograms {
  age: HistogramBin[]; // (rebin: RebinInput)
  baths: HistogramBin[]; // (rebin: RebinInput)
  beds: HistogramBin[]; // (rebin: RebinInput)
  buildingArea: HistogramBin[]; // (rebin: RebinInput)
  lotSize: HistogramBin[]; // (rebin: RebinInput)
  rentalValue: HistogramBin[]; // (rebin: RebinInput)
  rentalValuePerSqft: HistogramBin[]; // (rebin: RebinInput)
  value: HistogramBin[]; // (rebin: RebinInput)
  valuePerSqft: HistogramBin[]; // (rebin: RebinInput)
}
export interface ValueDistributionStats {
  count: number | null;
  max: number;
  mean: number;
  min: number;
  standardDeviation: number;
  percentile5: number;
  percentile25: number;
  percentile50: number;
  percentile75: number;
  percentile95: number;
}
export interface ValueDistribution {
  propertyType: PropertyType;
  value: ValueDistributionStats;
  valuePerSqft: ValueDistributionStats;
}
export interface ValueTimeSeriesElement {
  adjustedValue: number;
  month: string;
  valueMedian: number;
  valueSqftMedian: number;
}
export interface ValueTimeSeriesSummaryElement {
  percent: number;
  value: number;
  year: number;
}
export interface Block {
  id: string;
  crime: Crime;
  histograms: Histograms;
  rentalValueDistribution: ValueDistribution;
  valueDistribution: ValueDistribution;
  valueTs: ValueTimeSeriesElement[]; // (start: Date, end: Date, limit: number, order: Order)
  valueTsForecast: ValueTimeSeriesElement[]; // (start: Date, end: Date, limit: number, order: Order)
  valueTsForecastSummary: ValueTimeSeriesSummaryElement[];
  valueTsHistorical: ValueTimeSeriesSummaryElement[]; // (start: Date, end: Date, limit: number, order: Order)
}

export type BuisinessSummary = string;

export interface ScoreDetails {
  dollars: number;
  level: number;
  relative: number;
  similarity: number;
}
export interface Score {
  default: ScoreDetails;
  gross: ScoreDetails;
  net: ScoreDetails;
  predictedGross: ScoreDetails;
}

export interface DynamicAvmInput {
  bathroomCount: number;
  bedroomCount: number;
  buildingArea: number;
  siteArea: number;
  yearBuilt: number;
}

export interface Condition {
  con0: number | null;
  con1: number | null;
  con2: number | null;
  con3: number | null;
  con4: number | null;
  con5: number | null;
  rating: ConditionRating | null;
}

export interface County {
  fips: string | null;
  name: string | null;
}

export interface CountyLegal {
  apn: string | null;
  subdivision: string | null;
}

export interface GarageExperimental {
  spaces: number;
  type: string;
}
export interface Parking {
  garageExperimental: GarageExperimental | null;
}
export interface ExternalFeatures {
  parking: Parking;
}

export interface FloodZone {
  effectiveDate: DateStr;
  floodRisk: string;
  floodRiskDisplay: string;
  panelNumber: string;
  zone: string;
}

export interface GeoLocation {
  latitude: number;
  longitude: number;
  precision?: GeoPrecision;
}

export interface Improvement {
  additionalSqFt: number;
  cost: number;
  value: number;
}
export interface Improvements {
  // Value for adding an addition to the home
  addition: Improvement; // (additionalSqft: number = 0, propertyDetails: DynamicAvmInput, minQuality: AvmQuality, qualityMethod: AvmQualityMethod)
  bath: Improvement; // (additionalSqft: number = 0, propertyDetails: DynamicAvmInput, minQuality: AvmQuality, qualityMethod: AvmQualityMethod)
  beds: Improvement; // (additionalSqft: number = 0, propertyDetails: DynamicAvmInput, minQuality: AvmQuality, qualityMethod: AvmQualityMethod)
  maxValue: Improvement; // (propertyDetails: DynamicAvmInput, minQuality: AvmQuality, qualityMethod: AvmQualityMethod)
  pool: Improvement; // (minQuality: AvmQuality, qualityMethod: AvmQualityMethod)
}

export interface TaxSummary {
  amount: number;
  year: number;
}
export interface Assessment {
  taxSummary: TaxSummary;
}

export interface CommercialSpace {
  desc: string;
  has: boolean;
  percent: number;
}
export interface CommonElements {
  completed: boolean;
  desc: string;
  leaseTerms: string;
  leased: boolean;
  status: string;
}
export interface ControllingParty {
  desc: string;
  has: boolean;
}
export interface Conversion {
  date: DateStr;
  has: boolean;
  originalUse: string;
}
export interface UtilityChargeDetail {
  included: boolean;
  type: string;
}
export interface UtilityCharge {
  details: UtilityChargeDetail[];
  has: boolean;
}
export interface ListingAssociationFee {
  amount?: number;
  frequency?: string;
  notes?: string[];
  perSqFtAmount?: number | null;
  type?: string | null;
  utilityCharges?: UtilityCharge[];
}
export interface Management {
  agent: string;
  type: string;
}
export interface ListingAssociationParking {
  details: string;
  guestSpaces: number;
  isAdequate: DateStr;
  spacesUnitRation: number;
  totalSpaces: number;
}
export interface Units {
  completed: number;
  forSale: number;
  ownerOccupied: number;
  planned: number;
  rented: number;
  sold: number;
}
export interface PojectPhases {
  currentPhase: string;
  subjectPhaseUnits: Units;
  totalPhases: number;
}
export interface ListingAssociation {
  additionalFacilitiesFee?: string | null;
  classification?: string | null;
  commercialSpace?: CommercialSpace;
  commonElements?: CommonElements;
  conditionAndQuality?: string | null;
  controllingParty?: ControllingParty;
  conversion?: Conversion;
  design?: string | null;
  developerControlled?: boolean | null;
  developmentComplete?: boolean | null;
  elevatorCount?: number | null;
  fees?: ListingAssociationFee[];
  hasAssociation?: boolean | null;
  management?: Management;
  multiResidence?: boolean | null;
  name?: string | null;
  parking?: ListingAssociationParking;
  phone?: string | null;
  primaryOccupancy?: string | null;
  projectPhases?: PojectPhases;
  recFacilitiesDesc?: string | null;
  units?: Units;
}
export interface Amenities {
  count: number | null;
  desc: string | null;
  has: boolean | null;
  subType: string | null;
  type: string | null;
}
export interface LocationDataItem {
  assignment: string | null;
  attachment: string | null;
  condition: string | null;
  count: number | null;
  has: boolean | null;
  identifier: string | null;
  surface: string | null;
  type: string | null;
}

export interface ParkingData {
  count: number | null;
  desc: string | null;
  has: boolean | null;
  hasOnStreet: boolean | null;
  locations: LocationDataItem[] | null;
  notes: string[] | null;
}

export interface External {
  amenities: Amenities[] | null;
  parking: ParkingData | null;
}

export interface ListingStatusData {
  agentEntryDate: DateStr | null;
  concessionAmount: number | null;
  contractDate: DateStr | null;
  contractDateUnknown: boolean | null;
  contractPrice: number | null;
  currentPrice: number | null;
  currentSaleStatus: string | null;
  currentStatus: string | null; // do not use this. Use MlsState instead since it is normalized
  currentStatusDate: DateStr | null;
  daysOnMarket: number | null;
  firstAppearanceDate: DateStr | null;
  listingDate: DateStr | null;
  listingPrice: number | null | null;
  listingPriceRange: string | null;
  MlsState: ListingStatus | null;
  offMarketDate: DateStr | null;
  onMarketDate: DateStr | null;
  originalListingPrice: number | null;
  saleDate: DateStr | null;
  salePrice: number | null;
  salePricePerGla: number | null;
}
export interface AtticDetails {
  desc: string;
  type: string;
}
export interface Attic {
  desc: string | null;
  details: AtticDetails[] | null;
  has: boolean | null;
}
export interface BasementDetails {
  count: number | null;
  desc: string;
  type: string;
}
export interface Basement {
  desc: string | null;
  details: BasementDetails[] | null;
  has: boolean | null;
  isFinished: boolean | null;
  notes: string[] | null;
  sqftFinished: number | null;
  sqftFinishedPercent: number | null;
  sqftTotal: number | null;
  sqftUnfinished: number | null;
  type: string | null;
}
export interface BathroomDetails {
  count: number | null;
  type: string;
}
export interface LivingSpaceBathrooms {
  details: BathroomDetails[] | null;
  countTotal: number | null;
  countAboveFull: number | null;
  countAboveTenth: string | null;
  countAbovePartial: number | null;
}
export interface BedroomDetails {
  count: number | null;
  isAbove: boolean;
  type: string;
}
export interface LivingSpaceBedrooms {
  countTotal: number | null;
  details: BedroomDetails[] | null;
}
export interface CoolingDetails {
  desc: string;
  energySource: string;
  type: string;
}
export interface Cooling {
  countTotal: number | null;
  details: CoolingDetails[] | null;
  has: boolean | null;
  notes: string[] | null;
}
export interface Fireplace {
  count: number | null;
  has: boolean | null;
  notes: string[] | null;
  types: string[] | null;
}
export interface HeatingDetails {
  desc: string;
  energySource: string;
  type: string;
}
export interface Heating {
  countTotal: number | null;
  details: HeatingDetails[] | null;
  has: boolean | null;
  notes: string[] | null;
}
export interface ImprovementsDetails {
  approximateYear: string;
  areaType: string;
  desc: string;
}
export interface LivingSpaceImprovements {
  details: ImprovementsDetails[] | null;
  has: boolean | null;
  kitchenOrBathroomUpdatedInLast15Years: boolean | null;
}
export interface InteriorDescriptions {
  count: number | null;
  desc: string | null;
  has: boolean | null;
  type: string | null;
}
export interface RoomsDetails {
  count: number | null;
  type: string | null;
  desc: string | null;
  isAbove: boolean | null;
}
export interface Rooms {
  countTotal: number | null;
  details: RoomsDetails[] | null;
}
export interface View {
  prominentViewFactorLocation: string | null;
  rating: string | null;
  types: string[] | null;
}
export interface ListingLivingSpace {
  aboveGradeArea: number | null;
  appliances: string[] | null;
  attic: Attic;
  basement: Basement;
  bathrooms: LivingSpaceBathrooms;
  bedrooms: LivingSpaceBedrooms;
  belowGradeArea: number | null;
  cooling: Cooling;
  fireplace: Fireplace;
  grossLivingArea: number | null;
  heating: Heating;
  improvements: LivingSpaceImprovements;
  interiorDescriptions: InteriorDescriptions[] | null;
  interiorStoriesCount: number | null;
  rooms: Rooms;
  view: View;
}
export type OpenHouseStatus =
  | 'NOT_AVAILABLE_FOR_SHOWING'
  | 'CANCELED'
  | 'EXPIRED'
  | 'ACTIVE';
export interface OpenHouse {
  appointmentRequired: boolean;
  date: DateStr;
  description: string | null;
  directions: string | null;
  end: string | null; // NOTE: Not sure how to type this. It's LocalTime in the propertyGraph docs
  start: string | null; // NOTE: Not sure how to type this. It's LocalTime in the propertyGraph docs
  remarks: string | null;
  status: OpenHouseStatus;
}
export interface RightsFlags {
  doNotShowAddress: boolean | null;
  doNotShowAvm: boolean | null;
  doNotShowComments: boolean | null;
  doNotShowPublicly: boolean | null;
}
export interface SchoolRatings {
  bestHighSchoolPercentile: number;
  bestMiddleSchoolPercentile: number;
  bestPrimarySchoolPercentile: number;
}
export interface BestUse {
  desc: string;
  indicator: boolean;
}
export interface SiteLocation {
  comment: string | null;
  rating: string | null;
  types: string[];
}
export interface Lot {
  areaDesc: string | null;
  areaSqFt: number | null;
  dimensions: string | null;
}
export interface OffSiteImprovement {
  desc: string;
  has: boolean;
  ownershipType: string;
  type: string;
}
export interface SiteDescription {
  desc: string;
  type: string;
}
export interface SiteDisclosure {
  desc: string;
  type: string;
}
export interface ElectricityDetail {
  desc: string;
  provider: string;
  type: string;
}
export interface Electricity {
  desc: string;
  details: ElectricityDetail[];
  has: boolean;
  isWorking: boolean;
}
export interface GasDetail {
  desc: string;
  provider: string;
  type: string;
}
export interface Gas {
  desc: string;
  details: GasDetail[];
  has: boolean;
  isWorking: boolean;
}
export interface OtherDetail {
  desc: string;
  provider: string;
  type: string;
}
export interface Other {
  desc: string;
  details: OtherDetail[];
  has: boolean;
}
export interface SanitarySewerDetail {
  desc: string | null;
  provider: string | null;
  type: string | null;
  sewerSystem: string | null;
}
export interface SanitarySewer {
  desc: string;
  details: SanitarySewerDetail[];
  has: boolean;
}
export interface StormSewerDetail {
  desc: string;
  provider: string;
  type: string;
}
export interface StormSewer {
  desc: string;
  details: StormSewerDetail[];
  has: boolean;
}
export interface WaterDetail {
  desc: string;
  provider: string;
  type: string;
}
export interface Water {
  desc: string;
  details: WaterDetail[];
  has: boolean;
  isWorking: boolean | null;
}
export interface Utilities {
  electricity: Electricity | null;
  gas: Gas | null;
  other: Other | null;
  sanitarySewer: SanitarySewer | null;
  stormSewer: StormSewer | null;
  water: Water | null;
}
export interface ListingSite {
  bestUse: BestUse[] | null;
  buildingCount: number | null;
  currentUse: string[] | null;
  location: SiteLocation | null;
  lot: Lot | null;
  offSiteImprovements: OffSiteImprovement[] | null;
  otherFlags: string[] | null;
  possibleUse: string[] | null;
  siteDescriptions: SiteDescription[] | null;
  siteDisclosures: SiteDisclosure[] | null;
  utilities: Utilities | null;
}
export interface Building {
  area: number;
  desc: string;
  dimensions: string;
  type: string;
}
export interface ConditionDisclosure {
  desc: string;
  type: string;
}
export interface ExteriorDescription {
  desc: string;
  type: string;
  condition: string;
  count: number | null;
  has: boolean;
  material: string;
}
export interface FoundationConditions {
  desc: string;
  has: boolean;
  type: string;
}
export interface FoundationFeature {
  desc: string;
  type: string;
  has: boolean;
}
export interface FoundationDetail {
  conditions: FoundationConditions[];
  features: FoundationFeature[];
  type: string;
}
export interface Foundation {
  details: FoundationDetail[];
  has: boolean;
  notes: string[] | null;
}
export interface Utility {
  desc: string;
  type: string;
  has: boolean;
}
export interface ListingStructureData {
  attachmentType: string | null;
  builderName: string | null;
  buildings: Building[] | null;
  buildingsTotalArea: number | null;
  condition: number | null;
  conditionAppraisal: string | null;
  conditionDesc: string | null;
  conditionDisclosures: ConditionDisclosure[] | null;
  constructionMaterials: string[] | null;
  designs: string[] | null;
  exteriorDescriptions: ExteriorDescription[] | null;
  floorNumber: number | null;
  foundation: Foundation | null;
  isNewConstruction: boolean | null;
  quality: number | null;
  qualityAppraisal: string | null;
  shapes: string[] | null;
  status: string | null;
  storiesCount: number | null;
  unitCount: number | null;
  utilitiesMeteredSeparately: Utility[] | null;
  yearBuilt: number | null;
  yearBuiltEffective: number | null;
  yearBuiltIsEstimated: boolean | null;
}
export interface TaxSummaryExemption {
  amount: number;
  desc: string;
  type: string;
}
export interface ListingTaxSummary {
  amount: number | null;
  delinquencyYear: string | null;
  exemptions?: TaxSummaryExemption[];
  notes?: string[] | null;
  rateCode: string | null;
  rateValue: number | null;
  specialAmount: number | null;
  taxFileDate: DateStr | null;
  year: number | null;
}

export interface ListingEvent {
  eventDate: DateStr;
  eventPrice: number | null;
  eventStatus: string | null; // TODO: Get Eugene to explain this, and add new type for options
  eventSaleStatus: string | null; // TODO: ^^^ ditto
}

export interface Listing {
  address?: Address;
  agentEmail: string | null;
  agentLicense: string | null;
  agentName: string | null;
  agentPhone: string | null;
  association: ListingAssociation;
  countyLegal: CountyLegal;
  entityID: string;
  external: External | null;
  fullTimeline: ListingEvent[];
  isListingRental: boolean | null;
  listingID: string;
  listingOfficeName: string | null;
  listingStatus: ListingStatusData;
  livingSpace: ListingLivingSpace;
  location?: GeoLocation;
  mlsID: string;
  mlsName: string;
  openHouse?: OpenHouse[]; // (status: OpenHouseStatus = ACTIVE)
  price: number | null;
  publicRemarks: string | null;
  rightsFlags: RightsFlags;
  schoolRatings?: SchoolRatings;
  site: ListingSite;
  status: ListingStatus;
  statusDate: DateStr;
  structure: ListingStructureData;
  taxSummary: ListingTaxSummary;
  photos?: Photo[];
}

export interface Lien {
  dueDate: DateStr;
  lenderType: LenderType;
  lienAmount: number;
  lienType: LienType;
  mortgageYears: number;
  rate: DateStr;
  recordDate: DateStr;
}

export interface PhotoSet {
  aggregator: string;
  date: DateStr;
  externalSourceId: string;
  mlsNumber: string;
  photos: Photo[]; // (orderBy: PhotoOrderBy)
}

export interface Parcel {
  geometry: GeoJSON.Geometry;
  id: string;
}

export interface Bathrooms {
  full: number | null;
  partial: number | null;
  summaryCount: number | null;
}

export interface Bedrooms {
  count: number | null;
}

export interface LivingSpace {
  bathrooms: Bathrooms | null;
  bedrooms: Bedrooms | null;
  livingArea: number | null;
  numberOfRooms: number | null;
}

export interface MarketSnapshot {
  date: DateStr;
  daysOnMarketMean: number;
  daysOnMarketMedian: number;
  estHomesSold: number;
  estlistings: number; // this is the correct casing according
  inventoryTotal: number;
  marketStatus: MarketStatus;
  monthsInventoryMedian: number;
  period: number;
  priceMean: number;
  priceMedian: number;
}

export interface Fips {
  id: string;
  name: string;
}
export interface MlsRegulations {
  active: boolean;
  augmenting: boolean;
  cancelled: boolean;
  closed: boolean;
  closedLoginPrice: boolean;
  comingSoon: boolean;
  comingling: boolean;
  contingent: boolean;
  contingentWithKickOut: boolean;
  cooperatingBrokerage: boolean;
  copyrightStatement: string;
  deleted: boolean;
  disclaimer: string;
  expired: boolean;
  leased: boolean;
  logo: string;
  logoOverlay: string;
  pending: boolean;
  photosClosedAll: boolean;
  photosClosedFirstOnly: boolean;
  photosClosedHistory: boolean;
  photosClosedLogin: boolean;
  photosLogoOverlay: boolean;
  sold: boolean;
  unknown: boolean;
  withdrawn: boolean;
}
export interface Mls {
  abbreviation: string;
  fips: Fips[];
  lastRefresh: string;
  mlsId: number;
  name: string;
  regulations: MlsRegulations;
}

export interface Ownership {
  ownerOccupied: boolean | null;
  propertyType: PropertyType | null;
}

export interface PaymentEstimate {
  associationFee: number;
  loan: number;
  pmi: number;
  tax: number;
  total: number;
}

export interface RegressionBase {
  displayName: string;
}

export interface RegressionElement {
  x: number;
  y: number;
}
export interface LineRegression {
  line: RegressionElement[];
}
export interface RegressionLotSize extends RegressionBase {
  areaMedian: number;
  regression: LineRegression;
  thisProperty: number;
}
export interface RegressionBedrooms extends RegressionBase {
  areaMedian: number;
  regression: LineRegression;
  thisProperty: number;
}
export interface RegressionLivingArea extends RegressionBase {
  areaMedian: number;
  regression: LineRegression;
  thisProperty: number;
}
export interface RegressionPool extends RegressionBase {
  areaPercent: number;
}
export interface RegressionAge extends RegressionBase {
  areaMedian: number;
  regression: LineRegression;
  thisProperty: number;
}
export interface RegressionBasement extends RegressionBase {
  areaPercent: number;
}
export interface RegressionLocation extends RegressionBase {
  areaMedian: number;
  thisProperty: number;
}
export interface RegressionBathrooms extends RegressionBase {
  areaMedian: number;
  regression: LineRegression;
  thisProperty: number;
}
export type Regression =
  | RegressionLotSize
  | RegressionBedrooms
  | RegressionLivingArea
  | RegressionPool
  | RegressionAge
  | RegressionBasement
  | RegressionLocation
  | RegressionBathrooms;

export interface School {
  assessmentYear: number;
  attendanceGeometry: GeoJSON.GeoJSON;
  city: string;
  distanceMiles: number;
  geolocation: GeoJSON.GeoJSON; // (format: GeolocationFormat = GEOJSON)
  id: string;
  levels: SchoolLevel[];
  name: string;
  phone: string;
  score: number;
  state: DateStr;
  streetAddress: string;
  zipcode: string;
}
export interface Zoning {
  code: string | null;
}
export interface Site {
  area: number | null; // (units: AreaUnit = ACRE)
  areaDescription: string | null;
  zoning: Zoning | null;
}

export interface Structure {
  constructionType: string | null;
  numberOfUnits: number | null;
  stories: number | null;
  yearBuilt: number | null;
}

export interface TractStats {
  count: number | null;
}
export interface TitleHolder {
  forenames: string;
  name: string;
}
export interface DeedReference {
  docDate: DateStr;
  docNum: string;
  docType: TransactionDocumentType;
  grantees: TitleHolder[];
  grantors: TitleHolder[];
}
export interface ListingReference {
  hcMlsId: number;
  mlsName: string;
  mlsNumber: string;
}
export interface Transfer {
  contractDate: DateStr;
  contractPrice: number;
  deeds: DeedReference[];
  eventType: TransferEventType;
  isArmsLength: boolean;
  isForeclosure: boolean;
  isIntraFamily: boolean;
  isMultiparcel: boolean;
  isPartialInterest: boolean;
  isRefinance: boolean;
  isRental: boolean;
  isReo: boolean;
  listingDate: DateStr;
  listingPrice: number;
  listings: ListingReference[];
  transferDate: DateStr;
  transferPrice: number;
}

export interface HpiElement {
  adjustedValue: number;
  distance: number;
  month: string;
  real: number;
  trend: number;
  value: number;
}
export interface HpiSummaryElement {
  percent: number;
  value: number;
  year: number;
}
export interface Zip {
  hpi: HpiElement[]; // (start: Date, end:Date, limit: number; order: Order)
  hpiForecast: HpiElement[]; // (start: Date, end:Date, limit: number; order: Order)
  hpiForecastSummary: HpiSummaryElement[];
  hpiHistorical: HpiElement[]; // (start: Date, end:Date, limit: number; order: Order)
  marketGrade: MarketGrade;
  risk12MoLoss: number;
}

export interface MlsBoard {
  name: string;
  covered: boolean;
  reciprocity: boolean;
}

export interface MlsCoverage {
  boards: MlsBoard[];
  covered: boolean;
}

export interface PropertyLookup {
  address: Address;
  association: Association;
  avm: Avm; // (minQuality: AvmQuality, qualityMethod: AvmQualityMethod)
  avmFactors?: AvmFactors;
  bestPhotos?: BestPhotos;
  block: Block;
  businessSummary?: BuisinessSummary;
  comps?: PropertyLookup[]; // (limit: number = 5, minScore: number = 85, maxAgeDays: number, includeActive: boolean = true)
  condition: Condition | null; // (propertyDetails: DynamicAvmInput, minQuality: AvmQuality, qualityMethod: AvmQualityMethod)
  county: County;
  countyLegal: CountyLegal;
  dynamicAvm?: Avm; // (propertyDetails: DynamicAvmInput, minQuality: AvmQuality, qualityMethod: AvmQualityMethod)
  externalFeatures?: ExternalFeatures | null;
  floodZone?: FloodZone;
  geoLocation: GeoLocation; // (minPrecision: GeoPrecision)
  hcBuildingId?: number;
  improvements?: Improvements;
  landAvm?: Avm; // (minQuality: AvmQuality, qualityMethod: AvmQualityMethod)
  latestAssessment?: Assessment;
  latestListing: Listing;
  listings: Listing[] | null;
  lien?: Lien;
  listDate: DateStr | null;
  listPrice: number | null;
  livingSpace: LivingSpace;
  marketStats?: MarketSnapshot[];
  mls?: Mls;
  mlsState: ListingStatus | null;
  mlsStateDate: DateStr | null;
  mlsStateGroup?: MlsStateGroup;
  nearbyListings?: []; // (limit: number = 10, minScore: number = 85)
  ownership: Ownership | null;
  parcel?: Parcel;
  paymentEstimate?: PaymentEstimate; // (rate: DateStr = 4, term: number = 30, percentDown: number = 20)
  photoSets?: PhotoSet[]; // (start: Date, end: Date, limit: number, order: Order)
  propertyType: PropertyType;
  regressions?: Regression; // (propertyDetails: DynamicAvmInput, minQuality: AvmQuality, qualityMethod: AvmQualityMethod)
  rentalAvm: Avm; // (minQuality: AvmQuality, qualityMethod: AvmQualityMethod)
  rentalListDate?: DateStr | null;
  rentalListPrice?: number | null;
  rentalMlsState?: ListingStatus | null;
  rentalMlsStateDate?: DateStr | null;
  leaseDate?: DateStr | null;
  leasePrice?: number | null;
  mlsCoverage?: MlsCoverage;
  rentalYield: number | null;
  saleDate: DateStr | null;
  salePrice: number | null;
  schools?: School[]; // (schoolLevel: SchoolLevel, maxDistance: number = 15)
  schoolsSummary?: string; // (maxDistance: number = 15)
  similarityScores?: Score[]; // (copms: IdentifierInput[])
  site: Site;
  structure: Structure;
  tractStats?: TractStats;
  transfers?: Transfer[]; // (start: Date, end: Date, limit: number, order: Order, filter: TransferFilter = ALL)
  zip?: Zip;
}
