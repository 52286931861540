import { call, select } from 'redux-saga/effects';
import { getCurrentParams } from '@hc/redux-saga-router-plus/hclib/selectors';
import HC_CONSTANTS from 'HC_CONSTANTS';
import apiUtil from 'legacy/utils/api';
import {
  getEffectiveDateReportId,
  getEffecteDateCompDocuments,
  getEffecteDateSubjectDocument,
  getEffecteDateAvmDocument,
  getEffecteDateFilterDocument
} from 'selectors/effective-date';
import {
  AVM_KEY_USER_ENTERED,
  AVM_KEY_COMPS_AVG,
  COMP_TYPE_RENTAL,
  AVM_KEY_COMPS
} from 'legacy/appstore/constants';
import { determineShapeTypeFromGeoJson } from 'legacy/utils/maps.utils';
import { convertPropertyTypeNewToLegacy } from 'legacy/utils/effective-date.utils';
const REPORT_TYPE = 'effectivedate';
import { isObject } from 'legacy/utils/utils';
import { get } from 'lodash';
import { TRANSFORMED_FILTER_KEYS_SOLD_LEGACY_NEW } from 'legacy/utils/filters.utils';

const CERBERUS_PROPERTY_TYPE = {
  COMMERCIAL: 'Income Generating Property',
  CONDO: 'Condominium',
  MANUFACTURED: 'Manufactured/Mobile Home',
  MOBILE: 'Manufactured/Mobile Home',
  MULTI_FAMILY: 'Multifamily',
  RENTAL_UNIT: 'Income Generating Property',
  SFR: 'Single Family Detached',
  TOWNHOUSE: 'Townhouse'
};

const CERBERUS_LISTING_STATUS_SALE = {
  Active: 'Active',
  'Active Under Contract': 'Pending',
  Canceled: 'Sold',
  Closed: 'Sold',
  'Coming Soon': 'Active',
  Delete: 'Sold',
  Expired: 'Sold',
  Hold: 'Sold',
  Incomplete: 'Sold',
  Pending: 'Pending',
  Withdrawn: 'Sold'
};

const CERBERUS_LISTING_STATUS_RENTAL = {
  Active: 'Active',
  'Active Under Contract': 'Pending',
  Canceled: 'Leased',
  Closed: 'Leased',
  'Coming Soon': 'Active',
  Delete: 'Leased',
  Expired: 'Leased',
  Hold: 'Leased',
  Incomplete: 'Leased',
  Pending: 'Pending',
  Withdrawn: 'Leased'
};

const convertRectangleToNew = (legacyGeojson) => {
  return {
    type: 'Feature',
    properties: {
      shape: 'Rectangle',
      drawType: 'custom'
    },
    geometry: legacyGeojson.geometry
  };
};

const convertRectangleToLegacy = (newGeoJson) => {
  return {
    type: 'Feature',
    properties: {
      isSubjectCoordinates: false
    },
    geometry: newGeoJson.geometry
  };
};

const convertPolygonToNew = (legacyGeoJson) => {
  return {
    type: 'Feature',
    properties: {
      shape: 'Polygon',
      drawType: 'custom'
    },
    geometry: legacyGeoJson.geometry
  };
};

const convertPolygonToLegacy = (newGeoJson) => {
  return {
    type: 'Feature',
    properties: {
      isSubjectCoordinates: false
    },
    geometry: newGeoJson.geometry
  };
};

const convertCircleToNew = (legacyGeoJson) => {
  return {
    type: 'Feature',
    properties: {
      shape: 'Circle',
      drawType: 'custom',
      radius: legacyGeoJson.properties.radius,
      radius_unit: legacyGeoJson.properties.radius_unit
    },
    geometry: legacyGeoJson.geometry
  };
};

const convertCircleToLegacy = (newGeoJson) => {
  return {
    type: 'Feature',
    properties: {
      radius: newGeoJson.properties.radius,
      radius_unit: newGeoJson.properties.radius_unit,
      isSubjectCoordinates: false
    },
    geometry: newGeoJson.geometry
  };
};

const convertToNewGeojson = (legacyGeoJson) => {
  const shape = determineShapeTypeFromGeoJson(legacyGeoJson);
  if (shape === 'circle') {
    return convertCircleToNew(legacyGeoJson);
  } else if (shape === 'rectangle') {
    return convertRectangleToNew(legacyGeoJson);
  } else if (shape === 'polygon') {
    return convertPolygonToNew(legacyGeoJson);
  }
};
// Util to handle transformations that report-api doesn't bother with (geojson distance filter)
const updatePinkmanJson = (pinkmanJson) => {
  if (pinkmanJson?.report?.metadata?.compFarmAreaPolygons) {
    pinkmanJson.report.metadata.compFarmAreaPolygons =
      pinkmanJson.report.metadata.compFarmAreaPolygons.map((geojsonNew) => {
        if (geojsonNew.properties.shape === 'Circle') {
          return convertCircleToLegacy(geojsonNew);
        } else if (geojsonNew.properties.shape === 'Polygon') {
          return convertPolygonToLegacy(geojsonNew);
        } else if (geojsonNew.properties.shape === 'Rectangle') {
          return convertRectangleToLegacy(geojsonNew);
        } else {
          return geojsonNew;
        }
      });
  }

  if (pinkmanJson?.report?.metadata?.activeFiltersComps?.propertyType) {
    pinkmanJson.report.metadata.activeFiltersComps.propertyType.absoluteValue =
      Array.isArray(
        pinkmanJson.report.metadata.activeFiltersComps.propertyType
          .absoluteValue
      )
        ? pinkmanJson.report.metadata.activeFiltersComps.propertyType.absoluteValue.map(
            convertPropertyTypeNewToLegacy
          )
        : pinkmanJson.report.metadata.activeFiltersComps.propertyType
            .absoluteValue;
    pinkmanJson.report.metadata.activeFiltersComps.propertyType.relativeValue =
      Array.isArray(
        pinkmanJson.report.metadata.activeFiltersComps.propertyType
          .relativeValue
      )
        ? pinkmanJson.report.metadata.activeFiltersComps.propertyType.relativeValue.map(
            convertPropertyTypeNewToLegacy
          )
        : pinkmanJson.report.metadata.activeFiltersComps.propertyType
            .relativeValue;
  }

  if (pinkmanJson?.report?.metadata?.activeFiltersComps) {
    Object.keys(pinkmanJson?.report?.metadata?.activeFiltersComps).forEach(
      (key) => {
        const newField = TRANSFORMED_FILTER_KEYS_SOLD_LEGACY_NEW[key];
        if (key === 'distanceMiles') {
          pinkmanJson.report.metadata.activeFiltersComps.distance =
            pinkmanJson.report.metadata.activeFiltersComps.distanceMiles.relativeValue;
          delete pinkmanJson.report.metadata.activeFiltersComps.distanceMiles;
        } else if (newField) {
          pinkmanJson.report.metadata.activeFiltersComps[newField] = {
            ...pinkmanJson.report.metadata.activeFiltersComps[key],
            field: newField
          };
          if (newField !== key) {
            delete pinkmanJson.report.metadata.activeFiltersComps[key];
          }
        }
      }
    );
  }
  return pinkmanJson;
};

export default {
  searchReports: function* (params) {
    // Ex params:
    // {
    //   ownerID: number,
    //   addressID: number,
    //   effectiveDate: '2012-12-30'
    // }
    const reports = yield call(
      apiUtil.GET,
      `${HC_CONSTANTS.REPORT_API_URL}/reports`,
      {
        ...params,
        type: REPORT_TYPE
      },
      { skipTransform: true } // Api doesn't expect snake_case
    );
    return reports;
  },
  createReport: function* (params) {
    // Ex params:
    // {
    //   addressID: number,
    //   clientID: string,
    //   effectiveDate: '2012-12-30'
    // }
    const response = yield call(
      apiUtil.POST,
      `${HC_CONSTANTS.REPORT_API_URL}/reports/`,
      {
        reportType: 'effectivedate',
        ...params
      },
      {
        skipTransform: true, // Api doesn't expect snake_case
        urlParams: {
          output: 'pinkman'
        }
      }
    );
    return updatePinkmanJson(response);
  },
  fetchReport: function* () {
    const { reportId } = yield select(getCurrentParams);
    const response = yield call(
      apiUtil.GET,
      `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}`,
      {
        output: 'pinkman'
      }
    );
    return updatePinkmanJson(response);
  },
  selectAvm: function* (avmKey) {
    const reportId = yield select(getEffectiveDateReportId);
    const document = yield select(getEffecteDateAvmDocument);
    const { documentId } = document;
    if (avmKey === AVM_KEY_COMPS || avmKey === AVM_KEY_COMPS_AVG) {
      const response = yield call(
        apiUtil.PATCH,
        `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
        [
          {
            op: 'replace',
            path: '/data/selectedValue',
            value: 'comparableValue'
          },
          {
            op: 'add',
            path: '/data/comparableValue/hcAdjustments/includeHcHpiAdjustment',
            value: avmKey === AVM_KEY_COMPS
          },
          {
            op: 'add',
            path: '/data/comparableValue/hcAdjustments/includeHcPhysicalAdjustment',
            value: avmKey === AVM_KEY_COMPS
          }
        ],
        {
          skipTransform: true, // Api doesn't expect snake_case
          urlParams: {
            output: 'pinkman'
          }
        }
      );
      return updatePinkmanJson(response);
    } else {
      const response = yield call(
        apiUtil.PATCH,
        `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
        [{ op: 'replace', path: '/data/selectedValue', value: avmKey }],
        {
          skipTransform: true, // Api doesn't expect snake_case
          urlParams: {
            output: 'pinkman'
          }
        }
      );
      return updatePinkmanJson(response);
    }
  },
  updateUserEnteredValue: function* (values) {
    const reportId = yield select(getEffectiveDateReportId);
    const document = yield select(getEffecteDateAvmDocument);
    const { documentId } = document;
    const response = yield call(
      apiUtil.PATCH,
      `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
      [
        {
          op: 'replace',
          path: `/data/${AVM_KEY_USER_ENTERED}/priceMean`,
          value: values.value
        },
        {
          op: 'replace',
          path: `/data/${AVM_KEY_USER_ENTERED}/priceLower`,
          value: values.minVal
        },
        {
          op: 'replace',
          path: `/data/${AVM_KEY_USER_ENTERED}/priceUpper`,
          value: values.maxVal
        },
        {
          op: 'replace',
          path: `/data/${AVM_KEY_USER_ENTERED}/pricePerSqFt`,
          value: values.valPerSqft
        },
        {
          op: 'replace',
          path: `/data/${AVM_KEY_USER_ENTERED}/pricePerSqFtUpper`,
          value: values.maxValPerSqft
        },
        {
          op: 'replace',
          path: `/data/${AVM_KEY_USER_ENTERED}/pricePerSqFtLower`,
          value: values.minValPerSqft
        },
        {
          op: 'replace',
          path: '/data/selectedValue',
          value: AVM_KEY_USER_ENTERED
        }
      ],
      {
        skipTransform: true, // Api doesn't expect snake_case
        urlParams: {
          output: 'pinkman'
        }
      }
    );
    return updatePinkmanJson(response);
  },
  selectCompToggle: function* (comp) {
    const reportId = yield select(getEffectiveDateReportId);
    const compDocuments = yield select(getEffecteDateCompDocuments);
    const document = compDocuments.find(
      (d) => d.document.hcAddressId === comp.addressId
    );
    const response = document
      ? yield call(
          apiUtil.DELETE,
          `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${document.documentId}`,
          {
            output: 'pinkman'
          },
          {
            skipTransform: true // Api doesn't expect snake_case
          }
        )
      : comp.listingIdentifier
      ? yield call(
          apiUtil.POST,
          `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/actions/comps_by_mls_id`,
          [comp.listingIdentifier],
          {
            skipTransform: true, // Api doesn't expect snake_case
            urlParams: {
              output: 'pinkman'
            }
          }
        )
      : yield call(
          apiUtil.POST,
          `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/actions/comps`,
          [comp.addressId],
          {
            skipTransform: true, // Api doesn't expect snake_case
            urlParams: {
              output: 'pinkman'
            }
          }
        );
    return updatePinkmanJson(response);
  },
  updateComment: function* (comments) {
    const reportId = yield select(getEffectiveDateReportId);
    const document = yield select(getEffecteDateSubjectDocument);
    const { documentId } = document;
    const response = yield call(
      apiUtil.PATCH,
      `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
      [{ op: 'replace', path: '/data/comments', value: comments }],
      {
        skipTransform: true, // Api doesn't expect snake_case
        urlParams: {
          output: 'pinkman'
        }
      }
    );
    return updatePinkmanJson(response);
  },
  updateCompGeoJson: function* (geojson) {
    const reportId = yield select(getEffectiveDateReportId);
    const document = yield select(getEffecteDateFilterDocument);
    const { documentId } = document;
    const response = yield call(
      apiUtil.PATCH,
      `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
      [
        {
          op: 'add',
          path: '/data/filters/distance/absoluteValue',
          value: convertToNewGeojson(geojson)
        }
      ],
      {
        skipTransform: true, // Api doesn't expect snake_case
        urlParams: {
          output: 'pinkman'
        }
      }
    );
    return updatePinkmanJson(response);
  },
  updateCompFilters: function* ({
    absoluteValues,
    relativeValues,
    filterSetId
  }) {
    const reportId = yield select(getEffectiveDateReportId);
    const document = yield select(getEffecteDateFilterDocument);
    const { documentId } = document;
    const absolutePatches =
      !filterSetId && absoluteValues
        ? Object.keys(absoluteValues)
            .filter((filterKey) => {
              return isObject(absoluteValues[filterKey])
                ? !absoluteValues[filterKey]?.error
                : true;
            })
            .map((filterKey) => {
              return {
                op: 'add',
                path: `/data/filters/${filterKey}/absoluteValue`,
                value: absoluteValues[filterKey]
              };
            })
        : [];
    const relativePatches =
      !filterSetId && relativeValues
        ? Object.keys(relativeValues)
            .filter((filterKey) => {
              return isObject(relativeValues[filterKey])
                ? !relativeValues[filterKey]?.error
                : true;
            })
            .map((filterKey) => {
              return {
                op: 'add',
                path: `/data/filters/${filterKey}/relativeValue`,
                value: relativeValues[filterKey]
              };
            })
        : [];

    const filterSetPatch = filterSetId
      ? [
          {
            op: 'add',
            path: '/appliedFilterSetId',
            value: filterSetId
          }
        ]
      : [];
    const patches = [...absolutePatches, ...relativePatches, ...filterSetPatch];
    if (patches.length) {
      const response = yield call(
        apiUtil.PATCH,
        `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
        patches,
        {
          skipTransform: true, // Api doesn't expect snake_case
          urlParams: {
            output: 'pinkman'
          }
        }
      );
      return updatePinkmanJson(response);
    }
  },
  clearDistanceFilter: function* () {
    const reportId = yield select(getEffectiveDateReportId);
    const document = yield select(getEffecteDateFilterDocument);
    const { documentId } = document;
    const response = yield call(
      apiUtil.PATCH,
      `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
      [
        {
          op: 'remove',
          path: '/data/filters/distance'
        }
      ],
      {
        skipTransform: true, // Api doesn't expect snake_case
        urlParams: {
          output: 'pinkman'
        }
      }
    );
    return updatePinkmanJson(response);
  },
  clearFilter: function* (filterKey) {
    const reportId = yield select(getEffectiveDateReportId);
    const document = yield select(getEffecteDateFilterDocument);
    const { documentId } = document;
    const response = yield call(
      apiUtil.PATCH,
      `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
      [
        {
          op: 'remove',
          path: `/data/filters/${filterKey}`
        }
      ],
      {
        skipTransform: true, // Api doesn't expect snake_case
        urlParams: {
          output: 'pinkman'
        }
      }
    );
    return updatePinkmanJson(response);
  },
  clearAllFilters: function* () {
    const reportId = yield select(getEffectiveDateReportId);
    const document = yield select(getEffecteDateFilterDocument);
    const { documentId } = document;
    const response = yield call(
      apiUtil.PATCH,
      `${HC_CONSTANTS.REPORT_API_URL}/reports/${reportId}/documents/${documentId}`,
      [
        {
          op: 'remove',
          path: '/data/filters'
        }
      ],
      {
        skipTransform: true, // Api doesn't expect snake_case
        urlParams: {
          output: 'pinkman'
        }
      }
    );
    return updatePinkmanJson(response);
  },
  listingToComp: function* (listing, subject, compType) {
    const payload = {
      mlsID: listing.mlsID,
      mlsNumber: listing.listingID,
      subjectDetails: {
        blockID: subject.tabBlockId,
        buildingArea: subject.grossLivingAreaSqft,
        pool: subject.pool,
        basement: subject.basement,
        propertyType: subject.propertyType,
        lotSize: subject.siteAreaSqft,
        yearBuilt: subject.yearBuilt,
        beds: subject.bedrooms,
        baths: subject.bathrooms
      },
      subjectLocation: {
        latitude: subject.geoLocation.latitude,
        longitude: subject.geoLocation.longitude
      }
    };
    const response = yield call(
      apiUtil.POST,
      `${HC_CONSTANTS.REPORT_API_URL}/reporttypes/value/to_comp`,
      payload,
      {
        skipTransform: true // Api doesn't expect snake_case
      }
    );

    // Convert to Pinkman Schema
    const CERBERUS_LISTING_STATUS =
      compType === COMP_TYPE_RENTAL
        ? CERBERUS_LISTING_STATUS_RENTAL
        : CERBERUS_LISTING_STATUS_SALE;
    const complexFields =
      compType === COMP_TYPE_RENTAL
        ? get(response, 'propertyState.complexFieldsRental')
        : get(response, 'propertyState.complexFieldsSale');
    const listingDetails =
      compType === COMP_TYPE_RENTAL
        ? get(response, 'propertyState.listingDetailsRental')
        : get(response, 'propertyState.listingDetailsSale');
    const propertyValue =
      compType === COMP_TYPE_RENTAL
        ? get(response, 'propertyState.propertyValueRental')
        : get(response, 'propertyState.propertyValue');
    const netDollarAdjustment =
      get(response, 'hcAdjustments.hcAdjustmentDate', 0) +
      get(response, 'hcAdjustments.hcAdjustmentPropertyDetails', 0);
    const pinkmanComp = {
      activeDaysOnMarket: get(complexFields, 'currentDaysOnMarketCumulative'),
      addressId: get(response, 'propertyState.hcAddressId'),
      addressSlug: get(response, 'propertyState.location.addressSlug'),
      age: get(response, 'propertyState.propertyDetails.yearBuilt')
        ? new Date().getFullYear() -
          get(response, 'propertyState.propertyDetails.yearBuilt')
        : null,
      apn: null, // Not in to_comp response
      basement: get(response, 'propertyState.propertyDetails.basement.has'),
      bathrooms: get(
        response,
        'propertyState.propertyDetails.bathrooms.totalProjected'
      ),
      bedrooms: get(response, 'propertyState.propertyDetails.bedrooms'),
      bgId: get(response, 'propertyState.location.blockGroupId'),
      cerberusId: get(listingDetails, 'entityid'),
      city: get(response, 'propertyState.location.city'),
      comp: get(response, 'compID'),
      constructionType: null, // Not in to_comp response and not shown in ui
      countyFips: get(response, 'propertyState.location.fips'),
      cumulativeDaysOnMarket: get(
        complexFields,
        'currentDaysToCloseCumulative'
      ),
      currentValue: get(propertyValue, 'value'),
      distanceMiles: get(response, 'distance'),
      flips: get(complexFields, 'currentFlipYN'),
      garageNumCars: get(
        response,
        'propertyState.propertyDetails.parking.total'
      ),
      garageType: get(response, 'propertyState.propertyDetails.garage'),
      geoLocation: {
        latitude: get(response, 'propertyState.location.latitude'),
        longitude: get(response, 'propertyState.location.longitude')
      },
      grossLivingAreaSqft: get(
        response,
        'propertyState.propertyDetails.livingArea'
      ),
      hcMlsId: get(listingDetails, 'hcMlsId'),
      housecanaryValue: get(propertyValue, 'value'),
      isDistressed: get(complexFields, 'currentDistressed'),
      isInNonDisclosureState: false,
      lastListDate: get(complexFields, 'currentListDate'),
      lastListPrice: get(complexFields, 'currentListingPrice'),
      listingEntityId: get(listingDetails, 'entityId'),
      netDollarAdjustment,
      pool: get(response, 'propertyState.location.propertyDetails.poolYN'),
      propertyStatus: get(complexFields, 'currentStatus')
        ? CERBERUS_LISTING_STATUS[get(complexFields, 'currentStatus')]
        : null, // CONVERT
      propertyType: get(response, 'propertyState.propertyDetails.propertyType')
        ? CERBERUS_PROPERTY_TYPE[
            get(response, 'propertyState.propertyDetails.propertyType')
          ]
        : null, // CONVERT
      salesDate: get(complexFields, 'lastCloseDate'),
      salesPrice: get(complexFields, 'lastClosePrice'),
      salesPriceAdjusted: get(complexFields, 'lastClosePrice')
        ? get(complexFields, 'lastClosePrice') + netDollarAdjustment
        : null,
      similarityLevel: get(response, 'similarity.level'),
      similarityLevelAdjusted: get(response, 'similarity.levelAdjusted'),
      similarityScore: get(response, 'similarity.score'),
      similarityScoreAdjusted: get(response, 'similarity.scoreAdjusted'),
      siteAreaSqft: get(response, 'propertyState.propertyDetails.lotSize'),
      state: get(response, 'propertyState.location.state'),
      stories: get(response, 'propertyState.propertyDetails.storiesNumber'),
      streetAddress: get(response, 'propertyState.location.address'),
      subdivisionName: get(response, 'propertyState.location.subdivision'),
      tabBlockId: get(response, 'propertyState.location.blockId'),
      tractId: get(response, 'propertyState.location.tractId'),
      unit: get(response, 'propertyState.location.unit'),
      unitDesignator: null, // Not in cerberus
      yearBuilt: get(response, 'propertyState.propertyDetails.yearBuilt'),
      zipcode: get(response, 'propertyState.location.zipcode')
    };
    return pinkmanComp;
  }
};
