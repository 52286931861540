import React, { forwardRef } from 'react';
import classNames from 'classnames';

import styles from './FilterButton.css';

interface Props {
  active: boolean;
  label?: string;
  children?: React.ReactNode;
  className?: string;
  dataHcName: string;
  error?: boolean;
  onClick?: VoidFunction;
}
export const FilterButton = forwardRef<HTMLButtonElement, Props>(
  ({ active, label, children, className, dataHcName, error, onClick }, ref) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        className={classNames(styles.FilterButton, className, {
          [styles.active || '']: active,
          [styles.error || '']: error
        })}
        data-hc-name={dataHcName}
      >
        {label || children}
      </button>
    );
  }
);
