import { Dialog } from 'legacy/components/Dialog';

import PropertyDetailsCompContainer from 'legacy/containers/property-details-comp';

import { PropertyDetailsCompDialog as theme } from 'legacy/css-modules';

const PropertyDetailsCompDialog = ({ active, title, compType, onClose }) => {
  return (
    <Dialog
      active={active}
      title={title}
      onClose={onClose}
      theme={theme}
      dataHcName="comp-detail-panel"
      closeButton
      type="auto"
    >
      <PropertyDetailsCompContainer compType={compType} />
    </Dialog>
  );
};

export default PropertyDetailsCompDialog;
