import {
  analyticsMiddleware,
  mixpanelTracker,
  kinesisTracker
} from '@hc/analytics-redux-middleware';
import UTM_CONFIG from 'legacy/utm';
import HC_CONSTANTS from 'HC_CONSTANTS';

import { EVENT_TYPES, VR_EVENT_PREFIX } from 'legacy/appstore/events';

import { isVrView } from 'legacy/utils/routes';
import { isFunc, isArray } from 'legacy/utils/utils';
import { toSnakeCase } from 'legacy/utils/transform';

import {
  getAccountDetailsAnalytics,
  getAnalyticsGlobalProps,
  getAnalyticsGlobalPropertyExplorerProps
} from 'selectors/analytics';

const { APPLICATION_NAME_ANALYTICS, MIXPANEL } = HC_CONSTANTS;

// Create a combined tracker so 'analytics' events are sent to both mixpanel and kinesis
const combinedTrackerMixpanelKinesis = () => {
  /* Mixpanel Tracker */
  const trackerObjMixpanel =
    MIXPANEL && MIXPANEL.TOKEN && mixpanelTracker(MIXPANEL.TOKEN);
  /* Kinesis Tracker */
  const trackerObjKinesis = kinesisTracker(
    'valuereport',
    HC_CONSTANTS.BEACON_HOSTNAME,
    getAccountDetailsAnalytics,
    UTM_CONFIG.UTM_PARAMS
  );

  const processEventDefinition = (def, globalProps, state, action, store) => {
    // Executes selectors and fires mixpanel and kineses trackers with combined action
    const {
      props = {},
      conditionSelector,
      propsSelector,
      prefix = VR_EVENT_PREFIX
    } = def;

    let combinedProps = { ...globalProps, ...props };
    // TODO: Should propsSelector props be passed to the conditionSelector or run after?
    if (
      isFunc(conditionSelector)
        ? conditionSelector(state, combinedProps, action)
        : true
    ) {
      // Adding propsSelector props on when event will fire for now. May need these props passed to condition selector
      if (isFunc(propsSelector)) {
        combinedProps = {
          ...combinedProps,
          ...propsSelector(state, combinedProps, action)
        };
      }
      combinedProps = toSnakeCase(combinedProps);
      const event = `${prefix}${def.event}`;
      const eventType = EVENT_TYPES[def.event];
      // Build an action object that will be passed to both trackers
      const combinedAction = {
        ...action,
        meta: {
          ...action.meta,
          mixpanel: {
            ...def,
            props: {
              eventType,
              ...combinedProps
            },
            event
          },
          kinesis: {
            ...def,
            pageGroup: combinedProps.view,
            props: {
              ...combinedProps,
              app: APPLICATION_NAME_ANALYTICS,
              report_uid: globalProps.report_uid
            },
            event,
            eventType
          }
        }
      };
      // Call individual trackers
      if (trackerObjMixpanel) {
        trackerObjMixpanel.fn(combinedAction, store);
      }
      trackerObjKinesis.fn(combinedAction, store);
    }
  };

  return {
    key: 'analytics',
    fn: (action, store) => {
      // Add kinesis and mixpanel keys to the action
      if (action.meta && action.meta.analytics) {
        const state = store.getState();
        const globalProps = getAnalyticsGlobalProps(state);
        const globalVrProps = isVrView(globalProps.view)
          ? getAnalyticsGlobalPropertyExplorerProps(state)
          : {};
        // Allow multiple events to be defined on a single action
        if (isArray(action.meta.analytics)) {
          action.meta.analytics.forEach((eventDef) =>
            processEventDefinition(
              eventDef,
              { ...globalProps, ...globalVrProps },
              state,
              action,
              store
            )
          );
        } else {
          processEventDefinition(
            action.meta.analytics,
            { ...globalProps, ...globalVrProps },
            state,
            action,
            store
          );
        }
      }
    }
  };
};

export default analyticsMiddleware([combinedTrackerMixpanelKinesis()]);
