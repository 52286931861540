import { select, put } from 'redux-saga/effects';

import { getIsLoggedIn } from 'selectors/auth';

import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { VIEWS } from 'legacy/routes/constants';
import HC_CONSTANTS from 'HC_CONSTANTS';

export default function* routeLogin() {
  const isLoggedIn = yield select(getIsLoggedIn);
  if (isLoggedIn) {
    yield put(routeChange({ view: VIEWS.SEARCH, options: { inherit: false } }));
  } else {
    window.location = `${HC_CONSTANTS.HCS_PLATFORM_URL}/pexp`
  }
}
