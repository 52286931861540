import React from 'react';
import { CompPhotoPage } from 'src/lib/report-api/features/CompPhotoPage';
import { SubjectPhotoPage } from 'src/lib/report-api/features/SubjectPhotoPage';
import { PhotosPageContext } from 'src/lib/report-api/providers/PhotosPage/';

export enum PHOTO_PAGE_STATES {
  SET_COMP_PHOTO_PAGE = 'SET_COMP_PHOTO_PAGE',
  SET_SUBJECT_PHOTO_PAGE = 'SET_SUBJECT_PHOTO_PAGE',
  OPEN_CLOSE_PAGE = 'OPEN_CLOSE_PAGE'
}

export type PhotoPageAction = {
  type: PHOTO_PAGE_STATES;
  payload: PhotosPageContext;
};

export const photosPageReducer = (
  state: PhotosPageContext,
  action: PhotoPageAction
) => {
  switch (action.type) {
    case PHOTO_PAGE_STATES.SET_COMP_PHOTO_PAGE:
      return {
        ...state,
        active: true,
        compId: action.payload.compId,
        reportId: action.payload.reportId,
        hcAddressId: action.payload.hcAddressId,
        photoPageElement: <CompPhotoPage dataHcName="comp-photos-page" />
      };
    case PHOTO_PAGE_STATES.SET_SUBJECT_PHOTO_PAGE:
      return {
        ...state,
        active: true,
        reportId: action.payload.reportId,
        hcAddressId: action.payload.hcAddressId,
        photoPageElement: <SubjectPhotoPage dataHcName="subject-photos-page" />
      };
    case PHOTO_PAGE_STATES.OPEN_CLOSE_PAGE:
      return {
        ...state,
        active: action.payload.active
      };
    default:
      return state;
  }
};
