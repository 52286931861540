export const ANALYTICS_EVENT = 'ANALYTICS_EVENT';
export const PERFORMANCE_MEASUREMENT = 'PERFORMANCE_MEASUREMENT';

export const analyticsEvent = (event, props = {}, other = {}) => ({
  // Other is an object w/ conditionSelector and/or propsSelector
  type: ANALYTICS_EVENT,
  meta: {
    analytics: {
      event,
      props,
      ...other
    }
  }
});

export const performanceMeasurement = (event, props = {}, other = {}) => {
  return {
    // Other is an object w/ conditionSelector and/or propsSelector
    type: PERFORMANCE_MEASUREMENT,
    meta: {
      analytics: {
        prefix: 'performance2_',
        event,
        props,
        ...other
      }
    }
  };
};
