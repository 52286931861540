import { STATUSES } from 'legacy/appstore/constants';
import {
  FEATURE_FLAGS_FETCH,
  FEATURE_FLAGS_FETCH_SUCCESS,
  FEATURE_FLAGS_FETCH_FAILURE
} from 'actions/feature-flags';
const INITIAL_STATE = {
  status: STATUSES.INIT,
  data: {}
};

export const featureFlagsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FEATURE_FLAGS_FETCH: {
      return {
        ...state,
        status: STATUSES.LOADING
      };
    }

    case FEATURE_FLAGS_FETCH_SUCCESS: {
      return {
        ...state,
        status: STATUSES.SUCCESS,
        data: action.payload.featureFlags
      };
    }

    case FEATURE_FLAGS_FETCH_FAILURE: {
      return {
        ...state,
        status: STATUSES.ERROR
      };
    }

    default: {
      return state;
    }
  }
};
