import { EVENTS } from 'legacy/appstore/events';

export const PHOTOS_DIALOG_SHOW = 'PHOTOS_DIALOG_SHOW';
export const PHOTOS_DIALOG_HIDE = 'PHOTOS_DIALOG_HIDE';
export const PHOTOS_FETCH = 'PHOTOS_FETCH';
export const PHOTOS_FETCH_ALL = 'PHOTOS_FETCH_ALL';
export const PHOTOS_FETCH_SUCCESS = 'PHOTOS_FETCH_SUCCESS';
export const PHOTOS_CHECK_STREET_VIEW_URL = 'PHOTOS_CHECK_STREET_VIEW_URL';
export const PHOTOS_STREET_VIEW_URL_SUCCESS = 'PHOTOS_STREET_VIEW_URL_SUCCESS';
export const PHOTOS_STREET_VIEW_URL_ERROR = 'PHOTOS_STREET_VIEW_URL_ERROR';
export const PHOTOS_FETCH_SUBJECT = 'PHOTOS_FETCH_SUBJECT';
export const PHOTOS_FETCH_SEARCH_RESULT = 'PHOTOS_FETCH_SEARCH_RESULT';

export const photosDialogShow = (
  addressId,
  {
    transactionId,
    latestListingOnly,
    propertyType,
    initialIndex,
    fromTransactionHistory
  }
) => ({
  type: PHOTOS_DIALOG_SHOW,
  payload: {
    addressId,
    transactionId,
    propertyType,
    initialIndex,
    latestListingOnly
  },
  meta: {
    analytics: fromTransactionHistory
      ? {
          event: EVENTS.PHOTOS_FULLSCREEN_OPEN_FOR_TRANSACTION,
          props: {
            transaction_id: transactionId,
            propertyType: propertyType,
            initial_index: initialIndex
          }
        }
      : {
          event: EVENTS.PHOTOS_FULLSCREEN_OPEN,
          props: {
            transaction_id: transactionId,
            propertyType: propertyType,
            initial_index: initialIndex
          }
        }
  }
});
export const photosDialogHide = () => ({ type: PHOTOS_DIALOG_HIDE });

export const photosFetch = (addressIds) => ({
  type: PHOTOS_FETCH,
  payload: { addressIds }
});

export const photosFetchSubject = () => ({
  type: 'PHOTOS_FETCH_SUBJECTdd'
});

export const photosFetchSearchResult = () => ({
  type: PHOTOS_FETCH_SEARCH_RESULT
});

export const photosFetchAll = (addressId) => ({
  type: PHOTOS_FETCH_ALL,
  payload: { addressIds: [addressId] }
});

export const photosFetchSuccess = (photos, geoLocationMapping) => ({
  type: PHOTOS_FETCH_SUCCESS,
  payload: { photos, geoLocationMapping }
});

// TODO: Remove this after image carousel is updated
export const photosCheckStreetViewUrl = (addressId, url) => ({
  type: PHOTOS_CHECK_STREET_VIEW_URL,
  payload: { addressId, url }
});

export const photosStreetViewUrlSuccess = (addressId, url) => ({
  type: PHOTOS_STREET_VIEW_URL_SUCCESS,
  payload: { addressId, url }
});

export const photosStreetViewUrlError = (addressId, url) => ({
  type: PHOTOS_STREET_VIEW_URL_ERROR,
  payload: { addressId, url }
});
