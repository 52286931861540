import moment from 'moment';
window.moment = moment;

// Set global moment formatting
moment.updateLocale('en', {
  relativeTime: {
    past: 'Last %s',
    M: 'Month',
    MM: '%d Months',
    y: 'Year',
    yy: '%d Years'
  }
});

const F_DATE = 'MMM D, YYYY';
const F_DATE_COMPACT = 'MM/DD/YY';
const MONTH_YEAR = 'MMM, YY';

export const displayDate = (dateStr) => moment(dateStr).format(F_DATE);
export const displayDateCompact = (dateStr) => {
  const formatted = moment(dateStr).format(F_DATE_COMPACT);
  return !dateStr || !formatted || formatted === 'Invalid date'
    ? '--'
    : formatted;
};
export const displayDateCompactFullYear = (dateStr) => {
  const formatted = moment(dateStr).format('MM/DD/YYYY');
  return !dateStr || !formatted || formatted === 'Invalid date'
    ? '--'
    : formatted;
};
export const displayDateTime = (dateStr) => {
  const momentDate = moment(dateStr);
  return `${momentDate.format(F_DATE_COMPACT)} at ${momentDate.format(
    'hh:mma'
  )}`;
};
export const monthYear = (dateStr) => moment(dateStr).format(MONTH_YEAR);
export const ageToYear = (age) =>
  moment().subtract(age, 'years').format('YYYY');
export const yearToAge = (year) => moment().year() - year;
export const strToDateObj = (dateStr) => moment(dateStr).toDate();
export const normalizeDate = (dateObj) => {
  const d = moment(dateObj).format('YYYY-MM-DD');
  return d === 'Invalid date' ? null : d;
};
export const strToDateByFullDateFormat = (dateStr) =>
  moment(dateStr, F_DATE).toDate();

// Returns array of objects representing date periods in the past
export const getDatesAgo = (yearCount = 10, effectiveDate) => {
  let options = [
    {
      value: undefined,
      label: 'Any'
    }
  ];
  const now = moment(effectiveDate);
  // Counting backwards, every 3 months until a year ago, then every year until the set limit
  for (let i = 1; i <= 3 + yearCount; i++) {
    const value =
      i < 4
        ? moment(now).subtract(i * 3, 'months')
        : moment(now).subtract(i - 3, 'years');
    options.push({
      value: [normalizeDate(value), normalizeDate(now)],
      label: effectiveDate
        ? i < 4
          ? `${i * 3} Months Prior`
          : `${i - 3} Years Prior`
        : value.fromNow()
    });
  }
  return options;
};

/**
 * Returns the absolute days between given dates.
 * @param date1
 * @param date2
 * @returns {number}
 */
export const daysBetweenDates = (date1, date2) => {
  let d1 = moment(date1);
  let d2 = moment(date2);
  return Math.abs(d1.diff(d2, 'days'));
};
