import React from 'react';
import { Marker } from 'react-map-gl';
import { useDocumentRole } from '../../hooks';
import { DocumentRoles, SubjectDocument } from '../../types';
import { SubjectMarker as SubjectMarkerComponent } from 'src/lib/components/MapMarkers/SubjectMarker';
import styles from './SubjectMarker.css';
import { isLoading } from 'src/lib/utils/general';

interface Props {
  dataHcName: string;
  reportId: number;
}
export const SubjectMarker = ({ reportId, dataHcName }: Props) => {
  const {
    data: [subjectDocument],
    status: subjectDocStatus
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  if (isLoading(subjectDocStatus) || subjectDocument == null) {
    return null;
  }
  return (
    <Marker
      key="subject"
      className={styles.SubjectMarker}
      longitude={subjectDocument.data.geoLocation.longitude}
      latitude={subjectDocument.data.geoLocation.latitude}
    >
      <SubjectMarkerComponent dataHcName={dataHcName} />
    </Marker>
  );
};
