import React, {
  forwardRef,
  Children,
  useEffect,
  useState,
  useRef,
  ReactNode
} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import styles from './Popover.css';
import { ID_FIXED_PORTAL } from 'src/constants';
import { isElementVisible } from 'src/lib/utils/dom';
import {
  CaretPosition,
  ContentPosition,
  ButtonProps,
  ContentProps
} from './types';
import { useDialogActiveState } from 'src/lib/hooks';

interface Props {
  trigger: React.ReactNode; // trigger must accept an onClick prop
  content: React.ReactNode;
  theme?: {
    Button?: string;
    Content?: string;
  };
  closeOnClick?: boolean; // Close popover when content is clicked
  pagePadding?: number; // Padding between PopupContent and the edge of the window
  showOnMouseEnter?: boolean; // Show popover onMouseEnter
  overlay?: boolean;
  onClose?: VoidFunction;
  // Whether to style the content component using the default tooltip styles
  // Also determines whether to render the caret
  styleContentWrapper?: boolean;
  contentPadding?: number;
  // Allows parent to close popup
  active?: boolean;
  // Parent defines initial state, then component takes over
  activeInitial?: boolean;
}

const CARET_WIDTH = 14;
const CARET_HEIGHT = 16;

// Renders Popover outside of current DOM tree
const PopoverContentPortal = ({ children }: { children: ReactNode }) => {
  const elm = document.getElementById(ID_FIXED_PORTAL);
  return !elm ? null : createPortal(children, elm);
};

const PopoverButton = forwardRef(
  (props: ButtonProps, ref: React.Ref<HTMLSpanElement>) => {
    const { button, className, onClick, onMouseEnter } = props;
    return (
      <>
        {Children.map(button, (child) => {
          // If the button is component we need to wrap it in a span to access the ClientBoundingRect when positioning
          if (React.isValidElement(child)) {
            // const isComponent = child && typeof child.type === 'function';
            const handleMouseEnter = () => {
              onMouseEnter?.();
              child.props.onMouseEnter?.();
            };
            const handleOnClick = () => {
              onClick?.();
              child.props.onClick?.();
            };
            // const cloned = cloneElement(child, {
            //   ...child.props,
            //   ...{
            //     ref: isComponent ? child.props.ref : ref,
            //     onClick: handleOnClick,
            //     onMouseEnter: handleMouseEnter
            //   }
            // });
            // if (isComponent) {
            return (
              <span
                ref={ref}
                className={classNames(styles.RefWrap, className)}
                onMouseEnter={handleMouseEnter}
                onClick={handleOnClick}
              >
                {child}
              </span>
            );
            // } else {
            //   return cloned;
            // }
          }
          return null;
        })}
      </>
    );
  }
);

const PopoverContent = forwardRef(
  (props: ContentProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      className,
      caretPosition,
      content,
      contentPosition: { anchor, ...contentPosition },
      overlay,
      handleCloseOverlay,
      styleContentWrapper,
      contentPadding = 15
    } = props;
    const contentToRender = styleContentWrapper ? (
      <div
        className={classNames(styles.PopoverContent, className, styles[anchor])}
        style={{ padding: `${contentPadding}px` }}
      >
        {content}
        <div className={styles.PopoverCaret}>
          <div style={caretPosition} />
        </div>
      </div>
    ) : (
      content
    );
    return (
      <PopoverContentPortal>
        <div
          ref={ref}
          className={classNames(
            styles.PopoverContentPosition,
            className,
            styles[anchor]
          )}
          style={{ ...contentPosition }}
        >
          {contentToRender}
        </div>
        {overlay && (
          <div className={styles.Overlay} onClick={handleCloseOverlay} />
        )}
      </PopoverContentPortal>
    );
  }
);

export const Popover = ({
  content,
  trigger,
  theme,
  onClose,
  pagePadding = 15,
  closeOnClick = false,
  contentPadding,
  showOnMouseEnter = false,
  overlay = false,
  styleContentWrapper = true,
  // NOTE: mounting with active: true currently doesn't work properly
  active: activeProp
}: Props) => {
  const Content = useRef<HTMLDivElement>(null);
  const Button = useRef<HTMLSpanElement>(null);

  const { active, setDialogActiveState } = useDialogActiveState({
    active: activeProp
  });
  const [caretPosition, setCaretPosition] = useState<CaretPosition>({
    left: 0
  });
  const [contentPosition, setContentPosition] = useState<ContentPosition>({
    width: 'auto',
    anchor: 'bottom',
    left: 0,
    top: 0
  });
  useEffect(() => {
    if (activeProp !== undefined && activeProp !== active) {
      setDialogActiveState(activeProp);
    }
  }, [activeProp]);
  const handleToggle = () => {
    setDialogActiveState(!active);
    onClose?.();
  };

  const handleMouseEnter = () => {
    setDialogActiveState(true);
  };

  const handleCloseOverlay = () => {
    setDialogActiveState(false);
    onClose?.();
  };

  useEffect(() => {
    const handleClose = (e?: MouseEvent) => {
      if (!active || (overlay && !showOnMouseEnter)) return;
      if (e && Content?.current?.contains(e.target as Node) && !closeOnClick)
        return;

      const isWithinBounds = (
        pos: { x: number; y: number },
        bounds: DOMRect
      ) => {
        const x1 = bounds.x;
        const x2 = x1 + bounds.width;
        const y1 = bounds.y;
        const y2 = y1 + bounds.height;
        return pos.x >= x1 && pos.x <= x2 && pos.y >= y1 && pos.y <= y2;
      };

      const isEventOutsideBoundsOfComponent = (e?: MouseEvent) => {
        if (!e || !Button || !Content) return true;
        if (showOnMouseEnter) {
          const pos = {
            x: e.clientX,
            y: e.clientY
          };
          const inBoundsButton =
            Button &&
            Button.current &&
            isWithinBounds(pos, Button.current.getBoundingClientRect());
          const inBoundsContent =
            Content &&
            Content.current &&
            isWithinBounds(pos, Content.current.getBoundingClientRect());
          return (
            (!inBoundsButton && !inBoundsContent) || !Button || !Button.current
          );
        } else if (
          !overlay &&
          Button.current &&
          Content.current &&
          e.target instanceof Node
        ) {
          return (
            !Button.current.contains(e.target) &&
            !Content.current.contains(e.target)
          );
        }
        return true;
      };

      if (isEventOutsideBoundsOfComponent(e) || closeOnClick) {
        setDialogActiveState(false);
        onClose?.();
      }
    };

    const positionContent = () => {
      if (!Button.current || !Content.current) return;
      const ButtonElm = Button.current;
      const ContentElm = Content.current;
      // Close the popover if the trigger is not visible
      if (!isElementVisible(ButtonElm, false)) {
        setDialogActiveState(false);
        return;
      }
      const button = ButtonElm.getBoundingClientRect();
      const content = ContentElm.getBoundingClientRect();
      const width = Math.min(
        content.width,
        window.innerWidth - pagePadding * 2
      );
      const height = Math.min(
        content.height,
        window.innerHeight - pagePadding * 2
      );
      const defaultTop = Math.max(
        pagePadding,
        button.top + button.height - pagePadding
      );
      const anchor =
        defaultTop + height > window.innerHeight ? 'top' : 'bottom';
      const buttonCenter = button.left + button.width / 2;
      const top =
        anchor === 'top'
          ? Math.max(pagePadding, button.top - height) - CARET_HEIGHT
          : Math.max(pagePadding, button.top + button.height) + CARET_HEIGHT;
      const left = Math.max(
        Math.min(
          buttonCenter - width / 2,
          window.innerWidth - width - pagePadding * 2
        ),
        pagePadding
      );

      setCaretPosition({
        left: Math.min(
          buttonCenter - left - CARET_WIDTH / 2,
          window.innerWidth - CARET_WIDTH - pagePadding * 2
        )
      });
      setContentPosition({
        width: `${width}px`,
        left,
        top,
        anchor
      });
    };

    const handlePositionChange = () => {
      window.requestAnimationFrame(positionContent);
    };

    positionContent();
    window.addEventListener('resize', handlePositionChange);
    window.addEventListener('scroll', handlePositionChange, true);
    !showOnMouseEnter && window.addEventListener('click', handleClose);
    showOnMouseEnter &&
      active &&
      window.addEventListener('mousemove', handleClose);

    return () => {
      window.removeEventListener('resize', handlePositionChange);
      window.removeEventListener('scroll', handlePositionChange, true);
      window.removeEventListener('click', handleClose);
      window.removeEventListener('mousemove', handleClose);
    };
  }, [pagePadding, onClose, overlay, showOnMouseEnter, active]);

  return (
    <>
      <PopoverButton
        ref={Button}
        button={trigger}
        className={theme?.Button}
        onClick={!showOnMouseEnter ? handleToggle : undefined}
        onMouseEnter={showOnMouseEnter ? handleMouseEnter : undefined}
      />
      {active ? (
        <PopoverContent
          ref={Content}
          content={content}
          className={theme?.Content}
          contentPadding={contentPadding}
          styleContentWrapper={styleContentWrapper}
          caretPosition={caretPosition}
          contentPosition={contentPosition}
          overlay={overlay}
          handleCloseOverlay={handleCloseOverlay}
        />
      ) : null}
    </>
  );
};
