import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './FormError.css';

// interface Theme {
//   FormError: string
// }

interface Props {
  /** The text content of the error */
  value: ReactNode;
  dataHcName: string;
  className?: string;
}

/**
 *  Form success validation text to be positioned under fields that pass validation.
 */

export const FormError = ({ value, className, dataHcName }: Props) => {
  return (
    <div
      className={classNames(styles.FormError, className)}
      data-hc-name={dataHcName}
    >
      {value}
    </div>
  );
};
