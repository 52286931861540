import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { TextArea } from 'src/lib/components/TextArea';
import { Button } from 'src/lib/components/Button';
import { useDocumentRole } from '../../hooks';
import { DocumentRoles, SubjectDocument } from '../../types';
import styles from './SubjectComment.css';
import { useDispatch } from 'react-redux';
import { documentPatch } from '../../actions';
import { isLoading } from 'src/lib/utils/general';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { SubjectSchema } from '../../types/schemas';

interface Props {
  reportId: number;
  dataHcName: string;
  isEditable?: boolean;
  className?: string;
  onSubmit?: (value: string) => void;
}
export const SubjectComment = ({
  reportId,
  dataHcName,
  className,
  onSubmit,
  isEditable = true
}: Props) => {
  const dispatch = useDispatch();
  const {
    data: [subjectDocument],
    status: subjectDocStatus
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  const [value, setValue] = useState('');
  const subjectSchema: SubjectSchema | undefined = subjectDocument?.data;
  // return loading if we are loading or no doc or schema this reduces checks later

  useEffect(() => {
    if (subjectSchema?.comments) {
      // comments might be null
      setValue(subjectSchema?.comments);
    }
  }, [subjectSchema]);

  if (
    isLoading(subjectDocStatus) ||
    subjectDocument == null ||
    subjectSchema == null
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  if (isEditable) {
    return (
      <div data-hc-name={dataHcName} className={styles.SubjectComment}>
        <TextArea
          className={className}
          dataHcName="comments-text-area"
          placeholder="Type your comments here"
          maxLength={2550}
          value={value}
          onChange={(e) => {
            setValue(e.target.value || '');
          }}
        />
        <Button
          dataHcName="required"
          disabled={value === subjectSchema.comments}
          onClick={() => {
            dispatch(
              documentPatch(reportId, subjectDocument.documentId, [
                { op: 'replace', path: '/data/comments', value }
              ])
            );
            if (onSubmit) {
              onSubmit(value);
            }
          }}
        >
          Save
        </Button>
      </div>
    );
  } else {
    return (
      <div
        className={classNames(styles.ReadOnly, className)}
        data-hc-name={dataHcName}
      >
        {value || <span className={styles.Placeholder}>No comments</span>}
      </div>
    );
  }
};
