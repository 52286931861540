import { call, put, select } from 'redux-saga/effects';
import { watchLatest } from 'legacy/utils/saga';
import apiUtil from 'legacy/utils/api';
import HC_CONSTANTS from 'HC_CONSTANTS';

import {
  GET_REPORT_SUBJECT_DETAILS,
  getReportSubjectDetailsSuccess,
  getReportSubjectDetailsFailure
} from 'actions/get-report';

import { getCurrentReportParams } from 'selectors/property-explorer';

export function* fetchSubjectDetails() {
  try {
    const reportParams = yield select(getCurrentReportParams);
    const {
      uid,
      streetAddress,
      city,
      state,
      zipcode,
      unit,
      unitDesignator,
      addressId,
      addressSlug
    } = reportParams;
    const url = uid
      ? `${HC_CONSTANTS.VRSHARE_PROXY_URL}/emporium/vrshare/subject_details/${uid}`
      : `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/subject_details/`;
    const response = yield call(apiUtil.GET, url, {
      streetAddress: streetAddress.split('-').join(' '),
      unitDesignator: unitDesignator,
      city,
      state,
      zipcode,
      unit,
      addressId,
      addressSlug
    });

    const {
      archivedReports,
      fips,
      grossLivingAreaSqft,
      isComplete,
      isOwned,
      isOwnerOccupied,
      isReal,
      isVerified,
      lat,
      lifecycleDays,
      lifecycleEndDate,
      lifecycleStartDate,
      lon,
      normalizedListingStatus,
      valuationSuitabilityScore,
      valuationSuitabilityScoreDesc,
      value,
      valueHigh,
      valueLow,
      ...subjectRaw
    } = response;

    // Format to look like report.subject
    const subject = {
      ...subjectRaw,
      currentValue: value,
      geoLocation: {
        latitude: lat,
        longitude: lon
      },
      grossLivingAreaSqft
    };
    // // Format to look like report.avm
    const avm = {
      maxVal: valueHigh,
      minVal: valueLow,
      maxValPerSqft: grossLivingAreaSqft
        ? valueHigh / grossLivingAreaSqft
        : null,
      minValPerSqft: grossLivingAreaSqft
        ? valueLow / grossLivingAreaSqft
        : null,
      valPerSqft: grossLivingAreaSqft ? value / grossLivingAreaSqft : null,
      valuationSuitabilityScore,
      valuationSuitabilityScoreDesc,
      value
    };
    yield put(getReportSubjectDetailsSuccess(subject, avm));
  } catch (e) {
    yield put(getReportSubjectDetailsFailure());
  }
}

function* handleGetReportSubjectDetails(action) {
  yield call(fetchSubjectDetails, action.payload.params);
}

export default function registerGetReportPropertyDetails() {
  watchLatest(GET_REPORT_SUBJECT_DETAILS, handleGetReportSubjectDetails);
}
