import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getSubjectAddressId } from 'selectors/subject';

const mapStateToProps = (state, ownProps) => ({
  addressIdSubject: getSubjectAddressId(state)
});

const addressIdRequired = (Component, LoadingComponent) => {
  class AddressIdRequired extends PureComponent {
    render() {
      const { addressId, addressIdSubject } = this.props;
      if (addressId && (addressId !== 'subject' || addressIdSubject)) {
        return <Component {...this.props} />;
      } else if (LoadingComponent) {
        return <LoadingComponent {...this.props} />;
      } else {
        return null;
      }
    }
  }
  return connect(mapStateToProps)(AddressIdRequired);
};

export default addressIdRequired;
