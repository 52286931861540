import get from 'lodash/get';
import { ROUTE_UPDATE } from '@hc/redux-saga-router-plus/hclib/actions';

import {
  GET_REPORT,
  GET_REPORT_HC_VERSION_SUCCESS,
  GET_REPORT_SUBJECT_DETAILS_SUCCESS,
  GET_REPORT_RENTAL_SUCCESS
} from 'actions/get-report';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';
import {
  LOAD_FULL_REPORT,
  LOAD_FULL_REPORT_EXISTING
} from 'actions/full-report';

import { RESET_VR_STATE } from 'legacy/appstore/clear-reducer-on-actions';

const INITIAL_STATE = {
  default: {},
  rental: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EFFECTIVE_DATE_GET_REPORT_SUCCESS: {
      return {
        default: action.payload.report.subject
      };
    }

    case GET_REPORT:
      const { reportParams } = action.payload;
      return {
        ...state,
        default: {
          ...state.default,
          streetAddress: reportParams.streetAddress,
          addressSlug: reportParams.address_slug || null,
          unit: reportParams.unit || null,
          city: reportParams.city,
          state: reportParams.state,
          zipcode: reportParams.zipcode,
          geoLocation: {
            latitude: parseFloat(reportParams.lat),
            longitude: parseFloat(reportParams.lon)
          }
        }
      };

    case GET_REPORT_SUBJECT_DETAILS_SUCCESS:
      return {
        ...state,
        default: {
          ...state.default,
          ...action.payload.subject
        }
      };

    case GET_REPORT_HC_VERSION_SUCCESS:
      return {
        ...state,
        default: action.payload.report.subject
      };

    case GET_REPORT_RENTAL_SUCCESS:
      return {
        ...state,
        rental: {
          addressId: state.default.addressId,
          ...action.payload.report.subject,
          isRentalComp: true
        }
      };

    case LOAD_FULL_REPORT:
    case LOAD_FULL_REPORT_EXISTING:
      return {
        ...state,
        default: {
          ...action.payload.property,
          geoLocation: {
            latitude: action.payload.property.lat,
            longitude: action.payload.property.lon
          }
        }
      };

    case RESET_VR_STATE:
      return {
        ...INITIAL_STATE,

        // NOTE: status should not actually be on this part of state yet.
        //       status is added here when navigating from the search-result page
        //       this hack works for now but we should have individual status for subject
        //       when we switch the api to use graphQL
        default: state.default.status ? state.default : INITIAL_STATE.default
      };

    case ROUTE_UPDATE:
      return {
        ...state,
        default: {
          ...state.default,
          addressId: get(
            action,
            ['payload', 'query', 'address_id'],
            state.default.addressId
          )
        }
      };

    default:
      return state;
  }
};
