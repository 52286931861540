import { put, call, select } from 'redux-saga/effects';

import { watchEvery } from 'legacy/utils/saga';
import { isArray } from 'legacy/utils/utils';

import queries from 'legacy/graphql';

import graphqlRequest from 'legacy/services/graphql';

import { PROPERTY_QUERY_REQUEST, propertyQuerySuccess } from 'actions/property';
import { scrubDataTransactionHistory } from 'sagas/mls-compliance.saga';
import { getSubjectAddressId } from 'selectors/subject';

function* _addressIdForQueries(addressId) {
  return addressId === 'subject'
    ? yield select(getSubjectAddressId)
    : addressId;
}

// TODO: Should only need addressId when subject becomes ref to property
export function* makeRequest(addressId, addressIdForQueries, request) {
  const queryArgs = { ...request, addressId: addressIdForQueries };
  const graphQLResponse = yield call(graphqlRequest, {
    ...request,
    query: isArray(request.query)
      ? request.query.map((q) => queries[q](queryArgs))
      : queries[request.query](queryArgs)
  });
  if (graphQLResponse.queries?.find((q) => q === 'transactionHistory')) {
    const scrubbedData = yield call(
      scrubDataTransactionHistory,
      graphQLResponse
    );
    yield put(propertyQuerySuccess(addressId, scrubbedData));
    return;
  }
  yield put(propertyQuerySuccess(addressId, graphQLResponse));
}

export function* handlePropertyQueryRequest(action) {
  const { addressId, requests } = action.payload;
  const addressIdForQueries = yield call(_addressIdForQueries, addressId);
  if (addressIdForQueries) {
    const requestList = isArray(requests) ? requests : [requests];
    for (let r = 0; r < requestList.length; r++) {
      yield call(makeRequest, addressId, addressIdForQueries, requestList[r]);
    }
  }
}

export default () => {
  watchEvery({
    [PROPERTY_QUERY_REQUEST]: handlePropertyQueryRequest
  });
};
