import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Dropdown } from 'legacy/components/Dropdown';

import { SelectFieldPropertyDetails as theme } from 'legacy/css-modules';

// Dropdown doesnt handle boolean or null values gracefully
export const NULL = 'NULL';
export const TRUE = 'TRUE';
export const FALSE = 'FALSE';

const normalize = (v) => {
  if (v === TRUE) {
    return true;
  } else if (v === FALSE) {
    return false;
  } else if (v === NULL) {
    return null;
  } else {
    return v;
  }
};

const format = (v) => {
  if (v === true) {
    return TRUE;
  } else if (v === false) {
    return FALSE;
  } else if (v === null || v === undefined || v === '' || v === 'null') {
    return NULL;
  } else {
    return v;
  }
};

class SelectInputPropertyDetails extends Component {
  handleBlur = () => {}; // prevent update on blur

  render() {
    const { children, input, options, ...props } = this.props;
    return (
      <Dropdown
        {...input}
        {...props}
        value={format(input.value)}
        onBlur={this.onBlur}
        source={options}
        className={theme.SelectFieldPropertyDetails}
        theme={theme}
        mini
      >
        {children}
      </Dropdown>
    );
  }
}

const SelectFieldPropertyDetails = ({ options, value, ...props }) => {
  const normalizedOptions = options.map((o) => ({
    ...o,
    value: format(o.value)
  }));
  return (
    <Field
      component={SelectInputPropertyDetails}
      normalize={normalize}
      format={format}
      {...props}
      options={normalizedOptions}
      value={format(value)}
    />
  );
};

SelectFieldPropertyDetails.defaultProps = {};

SelectFieldPropertyDetails.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default SelectFieldPropertyDetails;
