import { SIMILARITY_LEVEL_RANGES } from 'legacy/appstore/constants';

import { capitalizeFirstLetter } from 'legacy/utils/strings';

import SimilarityLevel from 'legacy/components/SimilarityLevel';

import { SimilarityLevelFilterOption as theme } from 'legacy/css-modules';

const SimilarityLevelFilterOption = ({ similarityLevel }) => {
  const range = SIMILARITY_LEVEL_RANGES[similarityLevel];
  return (
    <div className={theme.SimilarityLevelFilterOption}>
      <div className={theme.Cell}>
        <SimilarityLevel similarityLevel={similarityLevel} />
      </div>
      <div className={theme.Cell}>{capitalizeFirstLetter(similarityLevel)}</div>
      <div className={theme.Cell}>{`(${range[0]} - ${range[1]})`}</div>
    </div>
  );
};

export default SimilarityLevelFilterOption;
