import { ROUTE_CHANGE } from '@hc/redux-saga-router-plus/hclib/actions';
import get from 'lodash/get';

import { VIEWS } from 'legacy/routes/constants';

import {
  UI_AVM_DETAILS_SHOW,
  UI_AVM_DETAILS_HIDE,
  SHOW_OVERLAY,
  HIDE_OVERLAY,
  SHOW_LEFT_SIDE_NAV,
  HIDE_LEFT_SIDE_NAV,
  UI_UPDATE_LOADING_PROGRESS,
  UI_COMPS_MAP_CONTROLS_TOGGLE,
  UI_COMPS_MAP_CONTROLS_HIDE,
  UI_COMPS_FILTERS_TOGGLE
} from 'actions/ui';
import { PERSONALIZATION_MODAL_OPEN } from 'actions/personalization';

const INITIAL_STATE = {
  visibleAvmDetailsReportType: null,
  overlayIsOpen: false,
  overlayContent: null,
  leftSideNavIsOpen: false,
  compsMapVisibleControlsType: null,
  compsFiltersVisible: false,
  appLoading: {
    step: 0,
    total: 0
  }
};

const _determineNextProgressState = (current, update) => {
  let nextStep;
  let nextTotal;
  if (current.step >= current.total) {
    nextStep = update.step + 1;
    nextTotal = update.total + 1;
  } else {
    nextStep = current.step + update.step;
    nextTotal = current.total + update.total;
    const currentPct = current.step / current.total;
    const updatePct = nextStep / nextTotal;

    if (currentPct >= updatePct) {
      // Preserve the # of steps remaining but ensure % complete doesn't decrease
      const stepsToAdd =
        Math.ceil(nextTotal * currentPct) + nextTotal - nextStep + 1;

      nextStep += stepsToAdd;
      nextTotal += stepsToAdd;
    }
  }

  return {
    step: nextStep,
    total: nextTotal
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UI_UPDATE_LOADING_PROGRESS:
      const { progress } = action.payload;
      return {
        ...state,
        appLoading: {
          ...state.appLoading,
          ..._determineNextProgressState(state.appLoading, progress)
        }
      };

    case UI_AVM_DETAILS_SHOW:
      return {
        ...state,
        visibleAvmDetailsReportType: action.payload.reportType
      };

    case UI_AVM_DETAILS_HIDE:
      return {
        ...state,
        visibleAvmDetailsReportType: null
      };

    case SHOW_OVERLAY:
      return {
        ...state,
        overlayIsOpen: true,
        overlayContent: action.payload.content
      };

    case HIDE_OVERLAY:
      return {
        ...state,
        overlayIsOpen: INITIAL_STATE.overlayIsOpen,
        overlayContent: INITIAL_STATE.overlayContent
      };

    case SHOW_LEFT_SIDE_NAV:
      return {
        ...state,
        leftSideNavIsOpen: true
      };

    case ROUTE_CHANGE:
    case PERSONALIZATION_MODAL_OPEN:
    case HIDE_LEFT_SIDE_NAV:
      const view = get(action, ['payload', 'view']);
      return {
        ...state,
        leftSideNavIsOpen: false,
        compsMapVisibleControlsType:
          view === VIEWS.VALUE_REPORT || view === VIEWS.VALUE_REPORT_SHARED
            ? INITIAL_STATE.compsMapVisibleControlsType
            : state.compsMapVisibleControlsType
      };

    case UI_COMPS_MAP_CONTROLS_TOGGLE:
      return {
        ...state,
        compsMapVisibleControlsType:
          action.payload.controlType === state.compsMapVisibleControlsType
            ? null
            : action.payload.controlType
      };

    case UI_COMPS_MAP_CONTROLS_HIDE:
      return {
        ...state,
        compsMapVisibleControlsType: null
      };

    case UI_COMPS_FILTERS_TOGGLE:
      return {
        ...state,
        compsFiltersVisible: !state.compsFiltersVisible
      };

    default:
      return state;
  }
};
