import {
  Avatar,
  UserPlaceholder
} from '@hc/component-lib/hclib/components/atoms/avatar';

import { CobrandingImgUser as theme } from 'legacy/css-modules';

const CobrandingImgUser = ({ url }) => {
  return (
    <Avatar>
      {url ? (
        <span
          className={theme.CobrandingImgUser}
          style={{ backgroundImage: `url(${url})` }}
        />
      ) : (
        <UserPlaceholder />
      )}
    </Avatar>
  );
};

export default CobrandingImgUser;
