import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUSES } from 'legacy/appstore/constants';

import { featureFlagsRequired } from 'actions/feature-flags';
import {
  getFeatureFlagsStatus,
  getFeatureFlagsData
} from 'selectors/feature-flags';
import { getIsLoggedIn } from 'selectors/auth';

export const useFeatureFlags = () => {
  const dispatch = useDispatch();
  const status = useSelector(getFeatureFlagsStatus);
  const isLoggedIn = useSelector(getIsLoggedIn);
  useEffect(() => {
    if (isLoggedIn && (!status || status === STATUSES.INIT)) {
      dispatch(featureFlagsRequired());
    }
  }, [status, isLoggedIn]);
  return useSelector(getFeatureFlagsData) || {};
};
