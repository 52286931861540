import { put, call } from 'redux-saga/effects';
import { report } from 'hc-ravenjs-logger';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { VIEWS } from 'legacy/routes/constants';

import apiUtil from 'legacy/utils/api';

import {
  confirmNewUserSuccess,
  confirmNewUserError,
  confirmNewUserExpiredToken
} from 'actions/sign-up';
import { personalizationDataRequest } from 'actions/personalization';

const MULTIPLE_OPTIONS_CODE = 300;
const authUrl = `${HC_CONSTANTS.AUTH_URL}/`;

export default function* routeConfirmUser({ view, params, query }) {
  const { token } = query;
  try {
    if (!token) {
      report('Token was not provided for confirm user');
      throw new Error('Unable to confirm the user without a token');
    }
    const accountDetails = yield call(
      apiUtil.GET,
      `${authUrl}confirm/${token}`,
      {},
      { redirectToLoginOn401: false }
    );
    yield put(confirmNewUserSuccess(accountDetails));
    yield put(personalizationDataRequest());
    yield put(
      routeChange({ view: VIEWS.INVITE_TEAM, options: { inherit: false } })
    );
  } catch (e) {
    if (e.statusCode === MULTIPLE_OPTIONS_CODE) {
      // redirect to select org
      yield put(
        routeChange({ view: VIEWS.INVITE_TEAM, options: { inherit: false } })
      );
    } else {
      if (e.statusCode === 401) {
        yield put(
          confirmNewUserExpiredToken(
            'Your confirmation email has expired.',
            token
          )
        );
      } else {
        if (
          e.statusCode === 400 &&
          e.message.indexOf('already confirmed') > -1
        ) {
          yield put(
            routeChange({ view: VIEWS.HOME, options: { inherit: false } })
          );
        }
        yield put(confirmNewUserError(e.message));
      }
    }
  }
}
