export const FEATURE_FLAG_DEFAULT_VALUE = 'FEATURE_FLAG_DEFAULT_VALUE';

// Payload is:
// {
//   selected,
//   selectedRental
// }
export const featureFlagDefaultValue = (payload) => {
  return {
    type: FEATURE_FLAG_DEFAULT_VALUE,
    payload
  };
};
