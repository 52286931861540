import React, { MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './IconButton.css';

export interface Props {
  dataHcName: string;
  icon: ReactNode;
  onClick: (event?: MouseEvent) => void;
  className?: string;
  disabled?: boolean;
  label?: string;
  title?: string;
}
export const IconButton = ({
  title,
  dataHcName,
  onClick,
  className,
  icon,
  disabled,
  label
}: Props) => {
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <button
      title={title}
      data-hc-name={dataHcName}
      onClick={handleClick}
      className={classNames(
        styles.IconButton,
        {
          [styles.disabled || '']: disabled
        },
        className
      )}
    >
      <div>{icon}</div>
      {label && <span className={styles.IconButtonText}>{label}</span>}
    </button>
  );
};
