import { STATUSES } from 'legacy/appstore/constants';
import {
  SELF_SERVICE_CHECKOUT_COMPLETE,
  SELF_SERVICE_PACKAGES_FETCH,
  SELF_SERVICE_PACKAGES_FETCH_SUCCESS,
  SELF_SERVICE_PACKAGES_FETCH_ERROR,
  SELF_SERVICE_PAYMENT_METHODS_FETCH,
  SELF_SERVICE_PAYMENT_METHODS_FETCH_SUCCESS,
  SELF_SERVICE_PAYMENT_METHODS_FETCH_ERROR,
  SELF_SERVICE_SETUP_INTENT,
  SELF_SERVICE_SETUP_INTENT_SUCCESS,
  SELF_SERVICE_SETUP_INTENT_ERROR,
  SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK,
  SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_SUCCESS,
  SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_ERROR,
  SELF_SERVICE_SETUP_INTENT_CHARGE_CARD,
  SELF_SERVICE_PAYMENT_INTENT,
  SELF_SERVICE_PAYMENT_INTENT_SUCCESS,
  SELF_SERVICE_PAYMENT_INTENT_ERROR,
  SELF_SERVICE_PAYMENT_INTENT_AUTHENTICATION_REQUIRED,
  SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK,
  SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK_SUCCESS,
  SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK_ERROR
} from 'actions/self-service';
import { CREATE_USER_SUCCESS, CONFIRM_NEW_USER_SUCCESS } from 'actions/sign-up';
import { LOGIN_SUCCESS } from 'actions/auth';
import { PUT_ACCOUNT_DETAILS_ON_STATE } from 'actions/account-details';

const INITIAL_STATE = {
  intentType: null,
  packages: {
    status: STATUSES.INIT,
    data: {
      packages: [],
      selectedPackageId: null
    }
  },
  paymentMethods: {
    status: STATUSES.INIT,
    data: []
  },
  setupIntent: {
    status: STATUSES.INIT,
    webhookStatus: STATUSES.INIT,
    data: {
      isFirstTimeSetup: false,
      clientSecret: null,
      publishableKey: null,
      freeItemsAdded: false
    }
  },
  paymentIntent: {
    status: STATUSES.INIT,
    webhookStatus: STATUSES.INIT,
    data: {
      clientSecret: null,
      authenticationRequired: false,
      paymentMethodId: null
    }
  }
};

export function selfService(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Login + Signup Setup
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        intentType: 'setup',
        setupIntent: {
          ...state.setupIntent,
          data: {
            ...state.setupIntent.data,
            isFirstTimeSetup: true
          }
        }
      };
    }

    case LOGIN_SUCCESS:
    case PUT_ACCOUNT_DETAILS_ON_STATE: {
      const userIsConfirmed = action.payload.accountDetails.user.confirmed;
      return {
        ...state,
        intentType: userIsConfirmed ? 'payment' : 'setup',
        setupIntent: {
          ...state.setupIntent,
          data: {
            ...state.setupIntent.data,
            isFirstTimeSetup: !userIsConfirmed
          }
        }
      };
    }

    case CONFIRM_NEW_USER_SUCCESS: {
      return {
        ...state,
        intentType: 'payment'
      };
    }

    // Checkout Lifecycle
    case SELF_SERVICE_PAYMENT_METHODS_FETCH: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          status: STATUSES.LOADING
        }
      };
    }

    case SELF_SERVICE_PAYMENT_METHODS_FETCH_SUCCESS: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          status: STATUSES.SUCCESS,
          data: action.payload.paymentMethods
        }
      };
    }

    case SELF_SERVICE_PAYMENT_METHODS_FETCH_ERROR: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          status: STATUSES.ERROR
        }
      };
    }

    case SELF_SERVICE_PACKAGES_FETCH: {
      return {
        ...state,
        packages: {
          ...state.packages,
          status: STATUSES.LOADING
        }
      };
    }

    case SELF_SERVICE_PACKAGES_FETCH_SUCCESS: {
      return {
        ...state,
        packages: {
          status: STATUSES.SUCCESS,
          data: {
            ...state.packages.data,
            packages: action.payload.packages,
            selectedPackageId:
              action.payload.packages.length === 1
                ? action.payload.packages[0].id
                : state.packages.data.selectedPackageId
          }
        }
      };
    }

    case SELF_SERVICE_PACKAGES_FETCH_ERROR: {
      return {
        ...state,
        packages: {
          ...state.packages,
          status: STATUSES.ERROR
        }
      };
    }

    case SELF_SERVICE_CHECKOUT_COMPLETE: {
      // Reset state when checkout is complete, but the next intent type will be 'payment'
      return {
        ...INITIAL_STATE,
        intentType: 'payment'
      };
    }

    // Setup Intent
    case SELF_SERVICE_SETUP_INTENT: {
      return {
        ...state,
        intentType: 'setup',
        setupIntent: {
          ...state.setupIntent,
          status: STATUSES.LOADING,
          webhookStatus: STATUSES.INIT
        }
      };
    }

    case SELF_SERVICE_SETUP_INTENT_SUCCESS: {
      return {
        ...state,
        setupIntent: {
          ...state.setupIntent,
          status: STATUSES.SUCCESS,
          data: action.payload
        }
      };
    }

    case SELF_SERVICE_SETUP_INTENT_ERROR: {
      return {
        ...state,
        setupIntent: {
          ...state.setupIntent,
          status: STATUSES.ERROR
        }
      };
    }

    case SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK: {
      return {
        ...state,
        setupIntent: {
          ...state.setupIntent,
          webhookStatus: STATUSES.LOADING
        }
      };
    }

    case SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_SUCCESS: {
      return {
        ...state,
        setupIntent: {
          ...state.setupIntent,
          webhookStatus: STATUSES.SUCCESS,
          data: {
            ...state.setupIntent.data,
            freeItemsAdded: action.payload.freeItemsAdded
          }
        }
      };
    }

    case SELF_SERVICE_SETUP_INTENT_CHECK_WEBHOOK_ERROR: {
      return {
        ...state,
        setupIntent: {
          ...state.setupIntent,
          webhookStatus: STATUSES.ERROR
        }
      };
    }

    case SELF_SERVICE_SETUP_INTENT_CHARGE_CARD: {
      return {
        ...state,
        intentType: 'payment',
        paymentIntent: {
          ...INITIAL_STATE.paymentIntent,
          status: STATUSES.LOADING,
          webhookStatus: STATUSES.INIT
        }
      };
    }

    // Payment Intent
    case SELF_SERVICE_PAYMENT_INTENT: {
      return {
        ...state,
        paymentIntent: {
          ...state.paymentIntent,
          status: STATUSES.LOADING,
          webhookStatus: STATUSES.INIT
        }
      };
    }

    case SELF_SERVICE_PAYMENT_INTENT_SUCCESS: {
      return {
        ...state,
        paymentIntent: {
          ...state.paymentIntent,
          status: STATUSES.SUCCESS,
          webhookStatus: STATUSES.SUCCESS,
          data: {
            ...state.paymentIntent.data,
            authenticationRequired: false,
            paymentMethodId: action.payload.payloadMethodId,
            clientSecret: action.payload.clientSecret
          }
        }
      };
    }

    case SELF_SERVICE_PAYMENT_INTENT_ERROR: {
      return {
        ...state,
        intentType: 'setup',
        setupIntent: {
          ...INITIAL_STATE.setupIntent
        },
        paymentIntent: {
          ...state.paymentIntent,
          status: STATUSES.ERROR,
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage
        }
      };
    }

    case SELF_SERVICE_PAYMENT_INTENT_AUTHENTICATION_REQUIRED: {
      return {
        ...state,
        paymentIntent: {
          ...state.paymentIntent,
          status: STATUSES.ERROR,
          data: {
            clientSecret: action.payload.clientSecret,
            paymentMethodId: action.payload.paymentMethodId,
            authenticationRequired: true
          }
        }
      };
    }

    case SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK: {
      return {
        ...state,
        paymentIntent: {
          ...state.paymentIntent,
          webhookStatus: STATUSES.LOADING
        }
      };
    }

    case SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK_SUCCESS: {
      return {
        ...state,
        paymentIntent: {
          ...state.paymentIntent,
          status: STATUSES.SUCCESS,
          webhookStatus: STATUSES.SUCCESS
        }
      };
    }

    case SELF_SERVICE_PAYMENT_INTENT_CHECK_WEBHOOK_ERROR: {
      return {
        ...state,
        paymentIntent: {
          ...state.paymentIntent,
          webhookStatus: STATUSES.ERROR
        }
      };
    }

    default:
      return state;
  }
}
