import { report } from 'hc-ravenjs-logger';
import {
  call,
  apply,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import sagaMiddleware from 'middleware/saga';

import {
  AVM_KEY_HC,
  AVM_KEY_USER,
  AVM_KEY_COMPS,
  AVM_KEY_COMPS_AVG,
  AVM_KEY_USER_ENTERED
} from 'legacy/appstore/constants';
import HC_CONSTANTS from 'HC_CONSTANTS';
import { getHcModelsHpi } from 'selectors/hcmodels';
import { getUseVRShareEndpoint } from 'selectors/property-explorer';
import { getSubjectDefault } from 'selectors/subject';
import {
  AVMS_COMP_VALUE_RECOMPUTE_SUCCESS,
  AVMS_UPDATE_USER_ENTERED_VALUE_SUCCESS
} from 'actions/avms';
import { getAvmByKeyWithFallback } from 'selectors/avms';
import { VR_RECOMPUTE_SUCCESS } from 'actions/edit-report';
import { computeChartDataHpi } from 'legacy/services/chart-data';
import {
  GET_REPORT_FORECAST,
  GET_REPORT_FORECAST_SUCCESS,
  getReportForecastSuccess,
  getReportForecastFailure
} from 'actions/get-report';
import { chartDataForecastChartRecomputed } from 'actions/chart-data';

import apiUtil from 'legacy/utils/api';

const { HUELL_PROXY_URL, VRSHARE_PROXY_URL } = HC_CONSTANTS;

export function* fetchHpiData() {
  const subject = yield select(getSubjectDefault);
  const { propertyType, zipcode, tabBlockId } = subject;
  if (tabBlockId) {
    try {
      const useVRShareEndpoint = yield select(getUseVRShareEndpoint);
      const url = useVRShareEndpoint
        ? `${VRSHARE_PROXY_URL}/emporium/vrshare/hc_models/hpi/${tabBlockId}/${zipcode}?propertyType=${propertyType}`
        : `${HUELL_PROXY_URL}/emporium/hc_models/hpi/${tabBlockId}/${zipcode}?propertyType=${propertyType}`;

      const hpi = yield apply(apiUtil, apiUtil.GET, [url]);
      yield put(getReportForecastSuccess(hpi));
    } catch (e) {
      console.error(e);
      yield put(getReportForecastFailure(tabBlockId, propertyType));
      report('HcModels Saga: fetchHpiData', e);
    }
  } else {
    yield put(getReportForecastFailure(tabBlockId, propertyType));
  }
}

export function* handleHpiDataFetch(action) {
  yield call(fetchHpiData);
}

function* handleRecomputeChartData(action) {
  const avmstoCompute = [
    AVM_KEY_HC,
    AVM_KEY_USER,
    AVM_KEY_COMPS,
    AVM_KEY_COMPS_AVG,
    AVM_KEY_USER_ENTERED
  ];
  const results = {};
  const hpi = yield select(getHcModelsHpi);
  for (let i = 0; i < avmstoCompute.length; i++) {
    const avmKey = avmstoCompute[i];
    const avm = yield select(getAvmByKeyWithFallback(avmKey));
    const computedDataBlock = computeChartDataHpi(hpi.blockTs, avm);
    const computedDataZip = computeChartDataHpi(hpi.zipTs, avm);
    results[avmKey] = {
      computedDataBlock,
      computedDataZip
    };
  }
  yield put(chartDataForecastChartRecomputed(results));
}

export default function registerHcModelsSaga() {
  function* watchHcModelsResult() {
    yield takeEvery(GET_REPORT_FORECAST, handleHpiDataFetch);
    yield takeLatest(
      AVMS_COMP_VALUE_RECOMPUTE_SUCCESS,
      handleRecomputeChartData
    );
    yield takeLatest(VR_RECOMPUTE_SUCCESS, handleRecomputeChartData);
    yield takeLatest(
      AVMS_UPDATE_USER_ENTERED_VALUE_SUCCESS,
      handleRecomputeChartData
    );
    yield takeLatest(GET_REPORT_FORECAST_SUCCESS, handleRecomputeChartData);
  }

  sagaMiddleware.run(watchHcModelsResult);
}
