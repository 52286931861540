import { PureComponent } from 'react';
import classNames from 'classnames';
import { themr } from 'react-css-themr';
import { LoadingIconSimple } from '@hc/component-lib/hclib/components/atoms/icons';

import { SimpleLoader as defaultTheme } from 'legacy/css-modules/generic';

const SIZES = ['small', 'medium', 'large'];

class SimpleLoader extends PureComponent {
  render() {
    const { size, theme, className, ...rest } = this.props;
    return (
      <LoadingIconSimple
        className={classNames(
          className,
          theme['simple-loader'],
          theme[`simple-loader-${size}`]
        )}
        {...rest}
      />
    );
  }
}

SimpleLoader.propTypes = {
  size: (props, propName, componentName) => {
    if (SIZES.indexOf(props[propName]) === -1) {
      return new Error(
        `${componentName} ${propName} prop must be one of: [${SIZES.join(
          ', '
        )}]`
      );
    }
  }
};

SimpleLoader.defaultProps = {
  size: 'small'
};

export default themr('SimpleLoaderTheme', defaultTheme)(SimpleLoader);
