import { PureComponent } from 'react';
const conditionallyDispatchAction = (Component) => {
  return class ConditionallyDispatchAction extends PureComponent {
    static defaultProps = {
      onMount: () => {}
    };

    componentDidMount() {
      this.props.onMount();
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};

export default conditionallyDispatchAction;
