import { put, select } from 'redux-saga/effects';

import { resetVrState } from 'legacy/appstore/clear-reducer-on-actions';

import { getReport, getReportShared } from 'actions/get-report';

import { getReportStatusIsNewSubject } from 'selectors/report-status';
import { getCurrentReportParams } from 'selectors/property-explorer';
import { getBrowserIsInitialLoad } from 'selectors/match-media';

export function* initPropertyExplorer() {
  const reportParamsChanged = yield select(getReportStatusIsNewSubject);
  if (!reportParamsChanged) {
    return;
  }
  const routeParams = yield select(getCurrentReportParams);
  const { uid } = routeParams;
  // NOTE: Marketo Report tracking params were removed from here
  // TODO: replace w/ salesforce?
  const isInitialLoad = yield select(getBrowserIsInitialLoad);

  if (!isInitialLoad && reportParamsChanged) {
    yield put(resetVrState());
  }
  routeParams.addressSlug = routeParams.addressSlug || routeParams.address_slug;
  if (!uid) {
    yield put(getReport(routeParams));
  } else {
    yield put(getReportShared(routeParams));
  }
}
