import { mixpanel } from '@hc/analytics-redux-middleware';

import { MIXPANEL } from 'HC_CONSTANTS';

function incReportsPulled() {
  if (!mixpanel || !MIXPANEL || !MIXPANEL.TOKEN) {
    return false;
  }
  mixpanel.people.increment('Value Reports Pulled', 1);
}

function alias(email) {
  if (!mixpanel || !MIXPANEL || !MIXPANEL.TOKEN) {
    return false;
  }
  // This should only be called once the first time that a user signs up
  // Alias will link the anonymous events from before account creation
  // with the new account
  mixpanel.alias(email);
}

function setPersona(email, persona) {
  if (!mixpanel || !MIXPANEL || !MIXPANEL.TOKEN) {
    return false;
  }
  mixpanel.people.set({ persona: persona });
  mixpanel.identify(email);
}

function loginCallback(userContext) {
  if (!mixpanel || !MIXPANEL || !MIXPANEL.TOKEN) {
    return false;
  }
  const email = userContext.user.email;
  const slug = userContext.currentOrganization.slug;
  const now = new Date().toISOString();
  let superProperties = {};

  if (email) {
    // eslint-disable-next-line
    if (process.env.NODE_ENV === 'production') {
      // use regex on email to determine if user is from '@housecanary.com' email domain
      if (/(@housecanary.com)/i.test(email)) {
        superProperties['$ignore'] = 'true'; // disable tracking
      }
    }

    superProperties = {
      ...superProperties,
      $email: email,
      $last_login: now
    };

    mixpanel.identify(email);
    mixpanel.people.set({
      $first_name: userContext.user.firstName,
      $last_name: userContext.user.lastName,
      $email: userContext.user.email,
      $last_login: now
    });
    mixpanel.people.set_once({
      'First Login Date': now
    });
  }

  if (slug) {
    superProperties['Slug'] = slug;
  }

  mixpanel.register_once({
    'First Login Date': now
  });
  mixpanel.register(superProperties);
}

function clearCookies() {
  if (!mixpanel || !MIXPANEL || !MIXPANEL.TOKEN) {
    return false;
  }
  mixpanel.reset();
}

function userExists() {
  if (!mixpanel || !MIXPANEL || !MIXPANEL.TOKEN) {
    return false;
  }
  const email = mixpanel.get_property('$email');
  return !!email;
}

export default {
  alias,
  clearCookies,
  loginCallback,
  setPersona,
  userExists,
  incReportsPulled
};
