import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { themr } from 'react-css-themr';
import { Button, CloseButton } from 'legacy/components/Button';
import { ID_PORTAL_FIXED } from 'legacy/appstore/constants';

import { Dialog as styles } from 'legacy/css-modules';

const DialogComponent = ({
  dataHcName = 'dialog',
  className,
  active,
  title,
  onClose,
  theme = {},
  actions,
  visibleOverflow,
  children,
  width,
  type
}) => {
  const elm = document.getElementById(ID_PORTAL_FIXED);
  const [activeWithTransition, setActiveWithTransition] = useState(false);
  useEffect(() => {
    setActiveWithTransition(!!active);
  }, [active]);
  if (!elm) return null;
  return createPortal(
    <>
      {active && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          data-hc-name={`${dataHcName}-wrapper`}
          className={classNames(theme.DialogWrapper, {
            [theme.active]: activeWithTransition,
            [theme.visibleOverflow]: visibleOverflow
          })}
        >
          <div
            data-hc-name={dataHcName}
            className={classNames(theme.Dialog, className, {
              [theme[type]]: type
            })}
            style={width ? { width } : undefined}
          >
            {onClose && (
              <CloseButton
                dataHcName={`${dataHcName}-closeButton`}
                className={theme.CloseButton}
                onClick={onClose}
              />
            )}
            {title && (
              <div className={theme.Header}>
                <h1 data-hc-name={`${dataHcName}-title`}>{title}</h1>
              </div>
            )}
            <div
              data-hc-name={`${dataHcName}-content`}
              className={theme.DialogContent}
            >
              {children}
            </div>
            {actions && actions.length && (
              <div
                data-hc-name={`${dataHcName}-actions`}
                className={theme.Actions}
              >
                {actions.map((action, i) => {
                  return (
                    <Button key={`${dataHcName}-action-${i}`} {...action} />
                  );
                })}
              </div>
            )}
          </div>
          <div
            data-hc-name={`${dataHcName}-overlay`}
            className={theme.DialogOverlay}
            onClick={onClose || undefined}
          />
        </div>
      )}
    </>,
    elm
  );
};

export const Dialog = themr('Dialog', styles)(DialogComponent);
