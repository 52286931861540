import React, { CSSProperties } from 'react';
import {
  DocumentRoles,
  SubjectDocument
} from 'src/lib/report-api/types/Document';
import { useDocumentRole } from 'src/lib/report-api/hooks';
import { PhotoCarousel } from 'src/lib/property-lookup';
import { Details, Price, PropertyCard } from 'src/lib/components/PropertyCard';
import {
  formatDateStr,
  formatMissing,
  formatMoney,
  formatSqFt
} from 'src/lib/utils';
import {
  getMarketPrice,
  getMarketPriceDate,
  listingStatusNormalized
} from 'src/lib/utils/listingStatus';
import { capitalizeFirstLetter } from 'src/lib/utils/strings';
import { STATUSES } from 'src/constants';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import styles from './SubjectPropertyCard.css';
import { BrokerageCredit } from '../BrokerageCredit';
import { usePhotosPage } from 'src/lib/report-api/hooks/usePhotosPage';
import { setSubjectPhotoPageAction } from 'src/lib/report-api/providers/PhotosPage/photosPage.actions';

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
  showMarketPrice?: boolean;
  style?: CSSProperties;
  onClick?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}
export const SubjectPropertyCard = ({
  reportId,
  className,
  dataHcName,
  showMarketPrice,
  style,
  onClick,
  onMouseEnter,
  onMouseLeave
}: Props) => {
  const {
    status,
    data: [subjectDocument]
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  const { dispatch } = usePhotosPage();
  if (status !== STATUSES.SUCCESS || !subjectDocument) {
    return <LoadingSpinner dataHcName={dataHcName} />;
  }
  const subjectSchema = subjectDocument.data;
  const statusNormalized = listingStatusNormalized(
    subjectSchema.latestListing.listingStatus
  );
  return (
    <PropertyCard
      dataHcName={dataHcName}
      className={className}
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      photos={
        <PhotoCarousel
          hcAddressId={subjectSchema.address.hcAddressId}
          dataHcName={`${dataHcName}-photo-carousel`}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              setSubjectPhotoPageAction(
                subjectSchema.address.hcAddressId,
                reportId
              )
            );
          }}
        />
      }
      content={
        <>
          <Details
            dataHcName={`${dataHcName}-details`}
            address={subjectSchema.address}
            attributes={[
              `${formatMissing(subjectSchema.livingSpace.bedrooms?.count)} Bd`,
              `${formatMissing(
                subjectSchema.livingSpace.bathrooms?.summaryCount
              )} Ba`,
              `${formatSqFt(subjectSchema.livingSpace.livingArea)}`
            ]}
          />
          {showMarketPrice && (
            <Price
              dataHcName={`${dataHcName}-price`}
              price={formatMoney(
                getMarketPrice(subjectSchema.latestListing.listingStatus)
              )}
              date={formatDateStr(
                getMarketPriceDate(subjectSchema.latestListing.listingStatus)
              )}
              label={capitalizeFirstLetter(statusNormalized)}
              listingStatusColor={statusNormalized}
            />
          )}
          <div className={styles.Legal}>
            <BrokerageCredit
              dataHcName={`${dataHcName}-disclaimer`}
              schema={subjectSchema}
              className={styles.BrokerageCredit}
            />
          </div>
        </>
      }
    />
  );
};
