import { Fragment } from 'react';

import CurrencyField from 'legacy/components/generic/CurrencyField';
import PriceAdjustmentTooltip from 'legacy/components/PriceAdjustmentTooltip';

const _tooltipContent = (attr, property) => {
  // SOLD
  if (attr === 'salesPrice' && property.propertyStatus === 'Sold') {
    return 'Adjustment is applied to the sales price since this is a closed sale.';
  } else if (attr === 'lastListPrice' && property.propertyStatus === 'Active') {
    return 'Adjustment is applied to the list price since this is an active listing.';
  } else if (
    attr === 'lastListPrice' &&
    property.propertyStatus === 'Pending'
  ) {
    return 'Adjustment is applied to the list price since this is a pending sale.';
  }
  // RENTAL
  if (attr === 'leasedPrice' && property.propertyStatusRental === 'Leased') {
    return 'Adjustment is applied to the leased price since this is a closed lease.';
  } else if (
    attr === 'lastListPriceRental' &&
    property.propertyStatusRental === 'Active'
  ) {
    return 'Adjustment is applied to the list price since this is an active listing.';
  } else if (
    attr === 'lastListPriceRental' &&
    property.propertyStatusRental === 'Pending'
  ) {
    return 'Adjustment is applied to the list price since this is a pending rental.';
  }
};

const BasePriceField = (props) => {
  const tooltipContent = _tooltipContent(props.name, props.property);
  return (
    <Fragment>
      <CurrencyField {...props} withPristineError={!!props.validate} />
      {tooltipContent && (
        <PriceAdjustmentTooltip
          title="Price Adjustment"
          content={tooltipContent}
        />
      )}
    </Fragment>
  );
};

export default BasePriceField;
