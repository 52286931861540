import { DocumentRoles } from './Document';
import { ReportMetadata } from './Report';
import { Schema } from './schemas';

export enum ReportEventTypes {
  DocumentsUpdated = 'DocumentsUpdated',
  ReportCreated = 'ReportCreated'
}

interface UpdatedDocumentData {
  documentID: string;
  role: DocumentRoles;
  schema: Schema;
  updatedAt: string;
  createdAt: string;
}

interface ReportEventData extends ReportMetadata {
  documents: UpdatedDocumentData[];
}
interface ReportEventBase {
  timestamp: number;
  report: ReportEventData;
}
export interface ReportCreatedEvent extends ReportEventBase {
  type: ReportEventTypes.ReportCreated;
}

export interface DocumentUpdatedEvent extends ReportEventBase {
  type: ReportEventTypes.DocumentsUpdated;
}

export type ReportEvent = ReportCreatedEvent | DocumentUpdatedEvent;
