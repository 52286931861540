import React from 'react';
import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';
import { ListViewType } from 'src/lib/report-api/features/CompFarmList';
import { VIEWS } from 'src/pexp/routes';
import { CompSelectionPage } from 'src/lib/report-api/features/CompSelectionPage';
import { useDispatch } from 'react-redux';
import { BackToReportLink } from 'src/pexp/features/BackToReportLink';
import styles from './PexpCompSelectionPage.css';
import { CompCompareOverlayProvider } from 'src/lib/report-api/hooks/useCompCompareOverlay';
// Props come from `@hc/redux-saga-router-plus`
interface Props {
  view: typeof VIEWS.EFFECTIVE_DATE_COMPS;
  params: {
    reportId: number;
  };
  query: {
    listViewType: ListViewType;
  };
}

const dataHcName = 'comp-selection-page';
export const PexpCompSelectionPage = ({
  params: { reportId },
  query: { listViewType }
}: Props) => {
  const dispatch = useDispatch();
  return (
    <CompCompareOverlayProvider reportId={reportId}>
      <CompSelectionPage
        reportId={reportId}
        dataHcName={dataHcName}
        backButton={
          <BackToReportLink
            dataHcName={`${dataHcName}-back`}
            className={styles.BackButton}
          />
        }
        listViewType={listViewType}
        onChangeListViewType={(newListViewType) => {
          dispatch(
            routeChange({
              query: {
                listViewType: newListViewType
              },
              options: {
                inherit: true
              }
            })
          );
        }}
      />
    </CompCompareOverlayProvider>
  );
};
