import React from 'react';
import { PhotosPage } from 'src/lib/report-api/features/PhotosPage';
import { usePhotosPage } from 'src/lib/report-api/hooks/usePhotosPage';
import { SubjectDetailsHeader } from 'src/lib/report-api/features/SubjectDetailsHeader';
interface Props {
  dataHcName: string;
  className?: string;
}
export const SubjectPhotoPage = ({ dataHcName, className }: Props) => {
  const {
    state: { active, reportId, hcAddressId, onClose }
  } = usePhotosPage();

  if (reportId == null || hcAddressId == null) {
    return null;
  }

  return (
    <PhotosPage
      active={active}
      dataHcName={dataHcName}
      reportId={reportId}
      className={className}
      header={
        reportId == null ? null : (
          <SubjectDetailsHeader
            reportId={reportId}
            dataHcName={`${dataHcName}-subject-photos-page-subject-header`}
          />
        )
      }
      hcAddressId={hcAddressId}
      onClose={onClose}
    />
  );
};
