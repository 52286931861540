import React, { ReactNode, useRef } from 'react';
import classNames from 'classnames';
import styles from './ExpandableIcon.css';
import { useDialogActiveState } from 'src/lib/hooks';
import { useClickOutsideComponent } from 'src/lib/hooks/useClickOutsideComponent';

interface Props {
  dataHcName: string;
  className?: string;
  icon: ReactNode;
  children: ReactNode;
}
// A Component to render a clickable icon instead of the full content
// when the screen is smaller than 1024px
export const ExpandableIcon = ({
  dataHcName,
  className,
  icon,
  children
}: Props) => {
  const { active, handleDialogOpen, handleDialogClose } =
    useDialogActiveState();
  const ref = useRef(null);
  useClickOutsideComponent(ref, handleDialogClose);
  return (
    <span
      ref={ref}
      data-hc-name={dataHcName}
      className={classNames(
        styles.ExpandableIcon,
        {
          [styles.active || '']: active
        },
        className
      )}
    >
      <button
        data-hc-name={`${dataHcName}-expand-button`}
        className={styles.Icon}
        onClick={handleDialogOpen}
      >
        {icon}
      </button>
      <span className={styles.Content}>{children}</span>
    </span>
  );
};
