import React from 'react';
import { EffectiveDateReportPage, PexpCompSelectionPage } from '../pages';
import { CompFilterBar } from 'src/lib/report-api/features/CompFilterBar';

export enum VIEWS {
  EFFECTIVE_DATE_ADDRESS = 'effective-date-address-new',
  EFFECTIVE_DATE_REPORT = 'effective-date-report-new',
  EFFECTIVE_DATE_COMPS = 'effective-date-comps-new',
  EFFECTIVE_DATE_COMPS_MAP = 'effective-date-comps-map-new',
  SANDBOX = 'sandbox'
}

interface ReportIdRouteProps {
  view: VIEWS.SANDBOX;
  params: {
    reportId: number;
  };
  query: Record<string, string>;
}
export const newPexpRoutes = {
  [VIEWS.SANDBOX]: {
    path: '/sandbox/:reportId',
    loginRequired: true,
    component: ({ params: { reportId } }: ReportIdRouteProps) => {
      const dataHcName = 'test-bar';
      return <CompFilterBar dataHcName={dataHcName} reportId={reportId} />;
    }
  },
  [VIEWS.EFFECTIVE_DATE_REPORT]: {
    path: '/effective-date-new/report/:reportId',
    component: EffectiveDateReportPage,
    loginRequired: true
  },
  [VIEWS.EFFECTIVE_DATE_COMPS]: {
    path: '/effective-date-new/report/:reportId/comps',
    query: ['listViewType', 'viewSelected'],
    loginRequired: true,
    component: PexpCompSelectionPage
  }
};
