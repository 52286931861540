import { Size } from './types';
import React from 'react';
import classNames from 'classnames';

import styles from './PhotoGridSizeControl.css';

import { RangeSlider } from '../RangeSlider';

interface Props {
  // Current size value
  value: Size;
  // Whether size control is hidden
  hidden?: boolean;
  // Callback when the slider is moved to new position
  onChange: (size: Size) => void;
  // Callback on mouseup after a change
  onChangeComplete?: (size: Size) => void;
  // QA selector
  dataHcName: string;
}

const MIN_VALUE: Size = 1;
const MAX_VALUE: Size = 7;
const STEP = 1;

export const PhotoGridSizeControl = ({
  dataHcName,
  onChange,
  onChangeComplete,
  value,
  hidden = false
}: Props) => {
  const hiddenStyle = classNames(styles.SizeToggle, {
    [styles.hidden || '']: hidden
  });
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.SizeToggle, hiddenStyle)}
    >
      <div className={styles.SizeToggleCell}>
        <div className={styles.ToggleIcon}>
          <div className={classNames(styles.ToggleIconBlock, styles.sm)} />
          <div className={classNames(styles.ToggleIconBlock, styles.sm)} />
          <div className={classNames(styles.ToggleIconBlock, styles.sm)} />
          <div className={classNames(styles.ToggleIconBlock, styles.sm)} />
          <div className={classNames(styles.ToggleIconBlock, styles.sm)} />
          <div className={classNames(styles.ToggleIconBlock, styles.sm)} />
          <div className={classNames(styles.ToggleIconBlock, styles.sm)} />
          <div className={classNames(styles.ToggleIconBlock, styles.sm)} />
          <div className={classNames(styles.ToggleIconBlock, styles.sm)} />
        </div>
      </div>
      <div
        className={classNames(styles.SizeToggleCell, styles.SliderCell)}
        data-hc-name={`${dataHcName}-slider-cell`}
      >
        <RangeSlider
          initialValue={(MAX_VALUE - (value as number) + MIN_VALUE) as Size}
          dataHcName={`${dataHcName}-range-slider`}
          className={styles.RangeSlider}
          theme={{
            activeTrack: styles.activeTrack,
            slider: styles.slider,
            track: styles.track
          }}
          minValue={MIN_VALUE}
          maxValue={MAX_VALUE}
          step={STEP}
          onChange={(v) =>
            onChange?.((MAX_VALUE - (v as number) + MIN_VALUE) as Size)
          }
          onChangeComplete={(v) =>
            onChangeComplete?.((MAX_VALUE - (v as number) + MIN_VALUE) as Size)
          }
        />
      </div>
      <div className={styles.SizeToggleCell}>
        <div className={styles.ToggleIcon}>
          <div className={classNames(styles.ToggleIconBlock, styles.lg)} />
        </div>
      </div>
    </div>
  );
};
