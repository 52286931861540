import { EVENTS } from 'legacy/appstore/events';
import { VIEWS } from 'legacy/routes/constants';

export const OPTIONAL_SSO = 'OPTIONAL_SSO';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const GOOGLE_LOGIN_ERROR = 'GOOGLE_LOGIN_ERROR';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';

export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const LOG_USER_OUT = 'LOG_USER_OUT';
export const NOT_AUTHORIZED = 'NOT_AUTHORIZED';
export const LOGIN_PROMPT_HIDE = 'LOGIN_PROMPT_HIDE';
export const LOGIN_PROMPT_SHOW_SIGNUP = 'LOGIN_PROMPT_SHOW_SIGNUP';

export const AUTH_REDIRECT_TO_LOGIN = 'AUTH_REDIRECT_TO_LOGIN';
export const AUTH_REDIRECT_FROM_LOGIN = 'AUTH_REDIRECT_FROM_LOGIN';

export const optionalSingleSignOn = () => {
  return { type: OPTIONAL_SSO };
};
export const login = (
  credentials,
  routeAfterLoginSuccess,
  actionAfterLoginSuccess
) => {
  return {
    type: LOGIN,
    payload: { credentials, routeAfterLoginSuccess, actionAfterLoginSuccess }
  };
};
export const loginError = (errorMessage) => ({
  type: LOGIN_ERROR,
  payload: { errorMessage }
});
export const loginSuccess = (accountDetails) => ({
  type: LOGIN_SUCCESS,
  payload: {
    accountDetails
  },
  meta: {
    analytics: {
      event: EVENTS.AUTH_LOGIN,
      src: 'property_explorer'
    }
  }
});
export const googleLogin = (googleUserIdToken) => ({
  type: GOOGLE_LOGIN,
  payload: { googleUserIdToken }
});
export const googleLoginError = (error) => ({
  type: GOOGLE_LOGIN_ERROR,
  payload: { error }
});
export const googleLoginSuccess = () => ({ type: GOOGLE_LOGIN_SUCCESS });

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const logOut = () => ({
  type: LOG_USER_OUT,
  meta: {
    analytics: {
      event: EVENTS.AUTH_LOGOUT
    }
  }
});

export const handleUnauthorizedUser = (errorMessage) => ({
  type: NOT_AUTHORIZED,
  payload: {
    errorMessage
  }
});

export const loginPromptHide = () => ({
  type: LOGIN_PROMPT_HIDE
});

/* Show signup/login modal and pass action to dispatch immediately after success authentication */
export const loginPromptShowSignup = (afterAuthAction) => ({
  type: LOGIN_PROMPT_SHOW_SIGNUP,
  payload: {
    params: { referrer: 'shared-report' },
    afterAuthAction
  },
  meta: {
    analytics: {
      event: EVENTS.AUTH_SIGN_UP_MODAL_SHOWN,
      props: {
        action_type: afterAuthAction
      }
    }
  }
});

export const authRedirectToLogin = (view, params, query) => {
  return {
    type: AUTH_REDIRECT_TO_LOGIN,
    payload: { view, params, query },
    meta: {
      router: {
        view: VIEWS.LOGIN
      }
    }
  };
};

export const authRedirectFromLogin = (view, params, query) => {
  return {
    type: AUTH_REDIRECT_FROM_LOGIN,
    meta: {
      router: {
        view,
        params,
        query
      }
    }
  };
};
