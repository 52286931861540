import { Field } from 'redux-form';

import { HorizontalToggle } from '@hc/component-lib/hclib/components/atoms/horizontal-toggle';

const ToggleComponent = (props) => {
  return (
    <HorizontalToggle
      options={props.options}
      value={props.input.value}
      onSelect={props.input.onChange}
      className={props.className}
      dataHcName={props.dataHcName}
    />
  );
};

const ToggleField = (props) => {
  return <Field component={ToggleComponent} {...props} />;
};

export default ToggleField;
