import { watchEvery } from 'legacy/utils/saga';
import { select, put } from 'redux-saga/effects';

import { VR_ROUTE_CHANGE } from 'actions/navigation';
import { getIsViewingSharedReport } from 'selectors/property-explorer';

import { routeChange } from '@hc/redux-saga-router-plus/hclib/actions';

export function* goToVrRoute(action) {
  const { view, params, query, options } = action.payload;
  const isViewingSharedReport = yield select(getIsViewingSharedReport);
  const viewSuffix = isViewingSharedReport ? '-shared' : '';

  yield put(
    routeChange({
      view: `${view}${viewSuffix}`,
      params,
      query,
      options: {
        ...options,
        inherit: true
      }
    })
  );
}

export default function registerVrNavigationSaga() {
  watchEvery({
    [VR_ROUTE_CHANGE]: goToVrRoute
  });
}
