import { formatPropertyType, formatSqFt } from 'src/lib/utils';
import Pluralize from 'pluralize';
import React from 'react';
import { DocumentRoles, SubjectDocument } from 'src/lib/report-api';
import { useDocumentRole } from 'src/lib/report-api/hooks';
import { formatMissing } from 'src/lib/utils';
import styles from './SubjectMainDetails.css';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { isLoading } from 'src/lib/utils/general';
import { SubjectSchema } from 'src/lib/report-api/types/schemas';

interface Props {
  reportId: number;
  dataHcName: string;
}

export const SubjectMainDetails = ({ reportId, dataHcName }: Props) => {
  const {
    data: [subjectDocument],
    status: subjectDocStatus
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  const subjectSchema: SubjectSchema | undefined = subjectDocument?.data;
  if (
    isLoading(subjectDocStatus) ||
    subjectDocument == null ||
    subjectSchema == null
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const {
    address: { streetAddress, city, state, zipcode, unit },
    propertyType,
    livingSpace: { bedrooms, bathrooms, livingArea },
    structure: { yearBuilt }
  } = subjectSchema;
  return (
    <div>
      <div
        className={styles.StreetAddress}
        data-hc-name="subject-address-street-text"
      >
        {streetAddress} {unit}
      </div>
      <div
        className={styles.CityStateZip}
        data-hc-name="subject-address-city-text"
      >
        {city}, {state} {zipcode}
      </div>
      <div className={styles.Details}>
        <span
          className={styles.Detail}
          data-hc-name="subject-property-type-text"
        >
          {formatPropertyType(propertyType)}
        </span>
        <span className={styles.Detail} data-hc-name="subject-property-beds">
          {formatMissing(bedrooms?.count)}{' '}
          {Pluralize('Bed', bedrooms?.count || 0)}
        </span>
        <span className={styles.Detail} data-hc-name="subject-property-baths">
          {formatMissing(bathrooms?.summaryCount)}{' '}
          {Pluralize('Bath', bathrooms?.summaryCount || 0)}
        </span>
        <span className={styles.Detail} data-hc-name="subject-property-sqft">
          {formatSqFt(livingArea)}
        </span>
        <span
          className={styles.Detail}
          data-hc-name="subject-property-year-built"
        >
          Built {yearBuilt}
        </span>
      </div>
    </div>
  );
};
