import { EVENTS } from 'legacy/appstore/events';

export const PERSONALIZATION_DATA_REQUEST = 'PERSONALIZATION_DATA_REQUEST';
export const PERSONALIZATION_DATA_LOADING = 'PERSONALIZATION_DATA_LOADING';
export const PERSONALIZATION_DATA_SUCCESS = 'PERSONALIZATION_DATA_SUCCESS';
export const PERSONALIZATION_DATA_FAILURE = 'PERSONALIZATION_DATA_FAILURE';
export const PERSONALIZATION_DATA_UPDATE = 'PERSONALIZATION_DATA_UPDATE';
export const PERSONALIZATION_DATA_UPDATE_SUCCESS =
  'PERSONALIZATION_DATA_UPDATE_SUCCESS';
export const PERSONALIZATION_DATA_UPDATE_FAILURE =
  'PERSONALIZATION_DATA_UPDATE_FAILURE';
export const PERSONALIZATION_UPLOAD_IMG = 'PERSONALIZATION_UPLOAD_IMG';
export const PERSONALIZATION_UPLOAD_IMG_SUCCESS =
  'PERSONALIZATION_UPLOAD_IMG_SUCCESS';
export const PERSONALIZATION_UPLOAD_IMG_FAILURE =
  'PERSONALIZATION_UPLOAD_IMG_FAILURE';
export const PERSONALIZATION_ACK_PROMPT = 'PERSONALIZATION_ACK_PROMPT';
export const PERSONALIZATION_PROMPT_MODAL_SHOW =
  'PERSONALIZATION_PROMPT_MODAL_SHOW';
export const PERSONALIZATION_PROMPT_MODAL_HIDE =
  'PERSONALIZATION_PROMPT_MODAL_HIDE';
export const PERSONALIZATION_MODAL_OPEN = 'PERSONALIZATION_MODAL_OPEN';
export const PERSONALIZATION_MODAL_CLOSE = 'PERSONALIZATION_MODAL_CLOSE';

export const personalizationModalOpen = () => ({
  type: PERSONALIZATION_MODAL_OPEN,
  meta: {
    analytics: {
      event: EVENTS.COBRANDING_GO_TO_EDIT
    }
  }
});
export const personalizationModalClose = () => ({
  type: PERSONALIZATION_MODAL_CLOSE
});

export const personalizationDataRequest = () => {
  return {
    type: PERSONALIZATION_DATA_REQUEST
  };
};

export const personalizationDataLoading = () => ({
  type: PERSONALIZATION_DATA_LOADING
});

export const personalizationDataSuccess = (values) => ({
  type: PERSONALIZATION_DATA_SUCCESS,
  payload: { values }
});

export const personalizationDataFailure = (
  errorStatus,
  errorMessage,
  values = {}
) => {
  return {
    type: PERSONALIZATION_DATA_FAILURE,
    payload: {
      doesNotExist: errorStatus === 404,
      message: errorMessage,
      values
    }
  };
};

export const personalizationDataUpdate = (values) => ({
  type: PERSONALIZATION_DATA_UPDATE,
  payload: { values },
  meta: {
    analytics: {
      event: EVENTS.COBRANDING_UPDATE,
      props: {
        fields_enabled: Object.keys(values).filter(
          (name) => name.slice(0, 7) === 'include' && values[name]
        )
      }
    }
  }
});

export const personalizationDataUpdateSuccess = (
  values,
  userIsReportOwner
) => ({
  type: PERSONALIZATION_DATA_UPDATE_SUCCESS,
  payload: { values, userIsReportOwner },
  meta: {
    toast: {
      message: 'Personalization information has been updated'
    }
  }
});

export const personalizationDataUpdateFailure = (error) => ({
  type: PERSONALIZATION_DATA_UPDATE_FAILURE,
  payload: { message: error.data.message }
});

export const personalizationUploadImg = (groupName, fieldName, img) => ({
  type: PERSONALIZATION_UPLOAD_IMG,
  payload: {
    groupName,
    fieldName,
    img
  }
});

export const personalizationUploadImgSuccess = (fieldName) => ({
  type: PERSONALIZATION_UPLOAD_IMG_SUCCESS,
  payload: { fieldName }
});

export const personalizationUploadImgFailure = (fieldName, errorMessage) => ({
  type: PERSONALIZATION_UPLOAD_IMG_FAILURE,
  payload: {
    fieldName,
    errorMessage
  }
});

export const personalizationAckPrompt = () => ({
  type: PERSONALIZATION_ACK_PROMPT
});

export const personalizationPromptModalShow = (altActionType) => ({
  type: PERSONALIZATION_PROMPT_MODAL_SHOW,
  payload: { altActionType }
});

export const personalizationPromptModalHide = () => ({
  type: PERSONALIZATION_PROMPT_MODAL_HIDE
});
