import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  STATUSES,
  MAP_ID_COMPS,
  MAP_ID_RENTAL_COMPS,
  PREFERENCES_KEYS
} from 'legacy/appstore/constants';

import { isLoadedInitial, isLoadedInitialOptional } from 'legacy/utils/status';

const getPreferencesState = (state) => state.preferences;

export const getPreferencesStatus = createSelector(
  getPreferencesState,
  (preferencesState) => preferencesState.status
);

export const getPreferenesIsLoadedSuccess = createSelector(
  getPreferencesStatus,
  isLoadedInitial
);

export const getPreferenesIsLoadedInitial = createSelector(
  getPreferencesStatus,
  isLoadedInitialOptional
);

export const getPreferencesValues = createSelector(
  getPreferencesState,
  (preferencesState) => preferencesState.values
);

export const getPreferencesSaveFiltersModalActive = createSelector(
  getPreferencesState,
  (preferencesState) => preferencesState.saveFiltersModalActive
);

export const getPreferencesFiltersComps = createSelector(
  getPreferencesValues,
  (preferences) => preferences[PREFERENCES_KEYS.FILTERS_COMPS]
);

export const getPreferencesFiltersCompsFilterSetDefault = (state) => {
  const savedFilters = getPreferencesFiltersComps(state);
  if (savedFilters) {
    const activeId = savedFilters.activeFilterSet;
    if (activeId) {
      return savedFilters.filterSets[activeId];
    }
  }
};

export const getPreferencesFiltersCompsFilterSetById = (filterSetId) =>
  createSelector(getPreferencesFiltersComps, (savedFilters) => {
    if (savedFilters) {
      return savedFilters.filterSets[filterSetId];
    }
  });

export const getPreferencesFiltersRentalComps = createSelector(
  getPreferencesValues,
  (preferences) => preferences[PREFERENCES_KEYS.FILTERS_RENTAL_COMPS] || {}
);

export const getPreferencesPhotoGrid = createSelector(
  getPreferencesValues,
  (preferences) => preferences[PREFERENCES_KEYS.PHOTO_GRID] || {}
);

export const getPreferencesFiltersRentalCompsFilterSetDefault = createSelector(
  getPreferencesFiltersRentalComps,
  (savedFilters) => {
    if (savedFilters) {
      const activeId = savedFilters.activeFilterSet;
      if (activeId) {
        return savedFilters.filterSets[activeId];
      }
    }
  }
);

export const getPreferencesFiltersRentalCompsFilterSetById = (filterSetId) =>
  createSelector(getPreferencesFiltersRentalComps, (savedFilters) => {
    if (savedFilters) {
      return savedFilters.filterSets[filterSetId];
    }
  });

export const getAreThereSavedRentalFilters = createSelector(
  getPreferencesFiltersRentalComps,
  (rentalFilterComps) => {
    const filterSet = rentalFilterComps ? rentalFilterComps.filterSets : {};
    const defaultSet = filterSet ? filterSet.default : {};
    const filterValues = defaultSet ? defaultSet.values : {};
    return !isEmpty(filterValues);
  }
);

export const getPreferencesMaps = createSelector(
  getPreferencesValues,
  (preferences) => preferences[PREFERENCES_KEYS.MAPS] || {}
);

export const getPreferencesIsLoaded = createSelector(
  getPreferencesStatus,
  (status) =>
    [
      STATUSES.LOADING,
      STATUSES.UPDATING,
      STATUSES.SUCCESS,
      STATUSES.DOES_NOT_EXIST
    ].indexOf(status) > -1
);

export const getPreferencesSavedDistanceFilterByMapId = (mapId) =>
  createSelector(
    mapId === MAP_ID_COMPS
      ? getPreferencesFiltersCompsFilterSetDefault
      : mapId === MAP_ID_RENTAL_COMPS
      ? getPreferencesFiltersRentalCompsFilterSetDefault
      : () => ({}),
    (savedFilters) => get(savedFilters, ['values', 'distance', 'value'])
  );

export const getPreferenecesCompTableOptionsDialog = createSelector(
  getPreferencesState,
  (preferencesState) => preferencesState.compTableOptionsDialog
);

export const getPreferenecesCompTableOptionsDialogActive = createSelector(
  getPreferenecesCompTableOptionsDialog,
  (compTableOptionsDialog) => compTableOptionsDialog.active
);

export const getPreferenecesCompTableOptionsDialogCompType = createSelector(
  getPreferenecesCompTableOptionsDialog,
  (compTableOptionsDialog) => compTableOptionsDialog.compType
);

export const getPreferencesTableColumnsComps = createSelector(
  getPreferencesValues,
  (preferences) => preferences[PREFERENCES_KEYS.TABLE_COLUMNS_COMPS]
);

export const getPreferencesTableColumnsRentalComps = createSelector(
  getPreferencesValues,
  (preferences) => preferences[PREFERENCES_KEYS.TABLE_COLUMNS_RENTAL_COMPS]
);

export const getPreferencesTableWidthComps = createSelector(
  getPreferencesValues,
  (preferences) => preferences[PREFERENCES_KEYS.TABLE_WIDTH_COMPS]
);
