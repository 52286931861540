import React, { ReactNode, useEffect, useState } from 'react';
import ReactMapGL from 'react-map-gl';
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner';
import { SubjectMarker } from './SubjectMarker';
import { useDocumentRole } from '../../hooks';
import { DocumentRoles, SubjectDocument } from '../../types';
import { CompSchema } from '../../types/schemas';
import { ViewportProps } from 'react-map-gl';
import { CompMarkerPopup } from './CompMarkerPopup';
import { SatelliteToggle } from 'src/lib/maps/SatelliteToggle/SatelliteToggle';
import { ZoomControl } from 'src/lib/maps/ZoomControl/ZoomControl';
import { CompMarkersSelected } from './CompMarkersSelected';
import { useCompDocIdsByCompId } from 'src/lib/report-api/hooks/useCompDocIdsByCompId';

const INITIAL_ZOOM = 14;

interface Props {
  dataHcName: string;
  reportId: number;
  className?: string;
  children?: ReactNode;
  scrollZoom?: boolean;
}

export const CompSummaryMap = ({
  dataHcName,
  reportId,
  children,
  scrollZoom = true
}: Props) => {
  const compDocumentIdsByAddressId = useCompDocIdsByCompId(reportId);
  const {
    data: [subjectDocument]
  } = useDocumentRole<SubjectDocument>(reportId, DocumentRoles.Subject);
  const { latitude, longitude } = subjectDocument?.data.geoLocation || {};
  const [viewport, setViewport] = useState<{
    latitude: number;
    longitude: number;
    transitionDuration?: number;
    zoom: number;
  }>({
    latitude: latitude || 0,
    longitude: longitude || 0,
    zoom: INITIAL_ZOOM
  });
  const [openMarkerPopup, setOpenMarkerPopup] = useState<CompSchema | null>(
    null
  );
  useEffect(() => {
    if (subjectDocument?.role === DocumentRoles.Subject) {
      if (latitude && longitude) {
        setViewport({
          ...viewport,
          latitude,
          longitude
        });
      }
    }
  }, [subjectDocument]);

  if (subjectDocument?.role !== DocumentRoles.Subject) {
    return <LoadingSpinner dataHcName={`${dataHcName}-spinner`} />;
  }

  if (!latitude || !longitude) {
    return <div>Error: Missing subject coordinates</div>;
  }

  return (
    <ReactMapGL
      {...viewport}
      scrollZoom={scrollZoom}
      data-hc-name={dataHcName}
      width="100%"
      height="100%"
      mapStyle="mapbox://styles/mapbox/light-v10"
      mapboxApiAccessToken="pk.eyJ1IjoibWF0dC1oYyIsImEiOiJ4ZHRkby1jIn0.6AE5Ob825Mlcaf_lIU66eA"
      onViewportChange={({
        latitude,
        longitude,
        zoom,
        transitionDuration
      }: ViewportProps) => {
        if (latitude && longitude && zoom) {
          setViewport({ latitude, longitude, zoom, transitionDuration });
        }
      }}
      attributionControl={false}
    >
      <CompMarkersSelected
        reportId={reportId}
        dataHcName={`${dataHcName}-marker-selected`}
        onClickMarker={setOpenMarkerPopup}
        indexLabels
      />
      <SubjectMarker
        dataHcName={`${dataHcName}-subject-marker`}
        reportId={reportId}
      />
      <ZoomControl
        dataHcName={`${dataHcName}-zoom-control`}
        zoom={viewport.zoom}
        onChange={(zoom: number) => {
          setViewport({
            ...viewport,
            transitionDuration: 300,
            zoom
          });
        }}
      />
      <SatelliteToggle dataHcName={`${dataHcName}-satellite-toggle`} />
      {children}
      {openMarkerPopup &&
        openMarkerPopup.geoLocation.longitude &&
        openMarkerPopup.geoLocation.latitude && (
          <CompMarkerPopup
            compSchema={openMarkerPopup}
            reportId={reportId}
            onClose={() => {
              setOpenMarkerPopup(null);
            }}
            dataHcName={`${dataHcName}-marker-popup-content`}
            documentId={compDocumentIdsByAddressId[openMarkerPopup.compID]}
          />
        )}
    </ReactMapGL>
  );
};
