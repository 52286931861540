import { PureComponent } from 'react';
import { COMP_TYPE_DEFAULT, COMP_TYPE_RENTAL } from 'legacy/appstore/constants';

import ShareReportModalContainer from 'legacy/containers/share-report-modal';
import PersonalizationPromptContainer from 'legacy/containers/personalization-prompt-modal';
import PersonalizationModalContainer from 'legacy/containers/personalization-modal';
import LoginPromptModalContainer from 'legacy/containers/login-prompt-modal';
import ErrorModalContainer from 'legacy/containers/error-modal';
import PhotosDialogContainer from 'legacy/containers/photos-dialog';
import ToastContainer from 'legacy/containers/toast';
import PropertyDetailsCompDialogContainer from 'legacy/containers/property-details-comp-dialog';
import GlossaryContainer from 'legacy/containers/glossary';
import TrialExpiredModalContainer from 'legacy/containers/trial-expired-modal';
import ArchivedReportsModalContainer from 'legacy/containers/archived-reports-modal';
import SaveFiltersModalContainer from 'legacy/containers/save-filters-modal';

export default class Modals extends PureComponent {
  render() {
    return (
      <div>
        <PersonalizationModalContainer />
        <ShareReportModalContainer />
        <PersonalizationPromptContainer />
        <LoginPromptModalContainer />
        <ErrorModalContainer />
        <PropertyDetailsCompDialogContainer compType={COMP_TYPE_DEFAULT} />
        <PropertyDetailsCompDialogContainer compType={COMP_TYPE_RENTAL} />
        <GlossaryContainer />
        <PhotosDialogContainer />
        <ToastContainer />
        <TrialExpiredModalContainer />
        <ArchivedReportsModalContainer />
        <SaveFiltersModalContainer />
      </div>
    );
  }
}
