// Use this for imports from reportDataByAvm and reportDataShared
import { createSelector } from 'reselect';
import moment from 'moment';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import get from 'lodash/get';

import { AVM_KEY_HC } from 'legacy/appstore/constants';

import { getAvmSelectedKey } from 'selectors/avms';

import { displayDate } from 'legacy/utils/dates';
import { dollarsWithPlaceholderFormatter } from 'legacy/utils/formatters';
import { listingsAreEqualish } from 'legacy/utils/listing-history';

export const getReportDataSharedState = (state) => state.reportDataShared;

export const getReportDataByAvmState = (state) => state.reportDataByAvm;

export const getReportDataForAvm = (selectedAvm) =>
  createSelector(
    getReportDataByAvmState,
    (dataByAvm) => dataByAvm[selectedAvm]
  );

export const getReportDataEffectiveDate = createSelector(
  getReportDataSharedState,
  (dataShared) => dataShared.effectiveDate
);

export const getReportDataByAvm = (selectedAvmKey) =>
  createSelector(
    getReportDataSharedState,
    getReportDataForAvm(selectedAvmKey),
    getReportDataForAvm(AVM_KEY_HC),
    (dataShared, dataByAvm, dataHc) => {
      return {
        ...dataHc,
        ...dataShared,
        ...dataByAvm
      };
    }
  );

export const getReportDataSelected = (state) => {
  const selectedAvm = getAvmSelectedKey(state);
  return getReportDataByAvm(selectedAvm)(state);
};

export const getSubjectPurchaseHistory = createSelector(
  getReportDataSelected,
  (reportData) => reportData.subjectPurchaseHistory
);

export const getSubjectListingHistory = createSelector(
  getReportDataSelected,
  (reportData) => reportData.subjectListingHistory
);

export const getReportUserRevisionId = createSelector(
  getReportDataSharedState,
  (sharedReport) => sharedReport.userRevisionId
);

// Merge list events with sales events
export const getSubjectHistory = createSelector(
  getSubjectListingHistory,
  getSubjectPurchaseHistory,
  (listingHistory, purchaseHistory) => {
    let items = [
      ...listingHistory.map((o) => ({
        ...o,
        isListingHistory: true,
        event: o.event || 'listed',
        date: displayDate(o.listDate),
        dateUnformatted: o.listDate,
        price: dollarsWithPlaceholderFormatter(o.listPrice),
        priceUnformatted: o.listPrice,
        source: [o.source || 'MLS']
      })),
      ...purchaseHistory.map((o) => ({
        ...o,
        isPurchaseHistory: true,
        event: o.event || 'sold',
        date: displayDate(o.salesDate),
        dateUnformatted: o.salesDate,
        price: dollarsWithPlaceholderFormatter(o.salesPrice),
        priceUnformatted: o.salesPrice,
        source: [o.source || 'Public Records']
      }))
    ];

    // so we get the first  occurrence of the event
    items = orderBy(items, 'dateUnformatted');

    let mergedHistory = [];
    if (items.length > 0) {
      mergedHistory.push(items[0]);
      let seen, equalish;
      items.slice(1).forEach((listing) => {
        seen = find(mergedHistory, (mListing) => {
          equalish = listingsAreEqualish(listing, mListing);
          if (
            equalish &&
            mListing['source'].indexOf(listing['source'][0]) === -1
          ) {
            mListing['source'].push(listing['source'][0]);
          }
          return equalish;
        });
        if (!seen) {
          mergedHistory.push(listing);
        }
      });
    }
    return orderBy(mergedHistory, 'dateUnformatted', 'desc');
  }
);

export const getForecastModels = createSelector(
  getReportDataSelected,
  (reportData) => reportData.forecastModels
);

export const getMarketRiskOfDecline = createSelector(
  getForecastModels,
  (forcastModels) => ({
    thisMonthLastYear: forcastModels.riskLastYr + '%',
    thisMonth: forcastModels.riskCurrent + '%',
    riskScore: forcastModels.riskScore
  })
);

export const getMarketStats = createSelector(
  getReportDataSelected,
  (reportData) => reportData.marketStats
);

export const getPropertyLookup = createSelector(
  getReportDataSelected,
  (reportData) => reportData.propertyLookup
);

export const getClientId = createSelector(
  getPropertyLookup,
  (propertyLookup) => propertyLookup.clientId
);

export const getPropertyLookupId = createSelector(
  getPropertyLookup,
  (propertyLookup) => propertyLookup.id
);

export const getDateReportCreated = createSelector(
  getPropertyLookup,
  (propertyLookup) => propertyLookup.createdAt
);

export const getDateReportUpdated = createSelector(
  getPropertyLookup,
  (propertyLookup) => propertyLookup.updatedAt
);

export const getDateReportExpired = createSelector(
  getPropertyLookup,
  (propertyLookup) => propertyLookup.expirationDatetime
);

export const getReportActiveStatus = createSelector(
  getPropertyLookup,
  (propertyLookup) => propertyLookup.status
);

export const getReportChartData = createSelector(
  getReportDataSelected,
  (reportData) => reportData.chartData
);

export const getReportCreatedToday = createSelector(
  getDateReportCreated,
  (createdAt) => moment().diff(moment(createdAt, moment.ISO_8601), 'days') <= 1
);

export const getReportChartDataMarketAnalysis = createSelector(
  getReportChartData,
  (chartData) =>
    pick(chartData, [
      'marketDaysOnMarketChart',
      'marketMonthsOfSupplyChart',
      'marketIndexChart',
      'marketRiskOfDeclineChart'
    ])
);

export const getReportDataHasSubjectHistory = createSelector(
  getSubjectHistory,
  (history) => !!history.length
);

export const getReportDataProductName = createSelector(
  getReportDataSharedState,
  (reportDataSharedState) => reportDataSharedState.productName
);

export const getOneYearMarketRiskOfDecline = createSelector(
  getForecastModels,
  (forcastModels) => get(forcastModels, ['riskScore'], null)
);

export const getMarketStatus = createSelector(
  getMarketStats,
  (marketStats) => marketStats.marketStatus
);
