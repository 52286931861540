import { CobrandingImgCompany as theme } from 'legacy/css-modules';
import classNames from 'classnames';

const CobrandingImgCompany = ({ url }) => {
  return (
    <div
      className={classNames(theme.CobrandingImgCompany, {
        [theme.CobrandingImgCompanyWithImg]: url
      })}
    >
      {url ? (
        <img className={theme.CobrandingImgCompanyImage} src={url} />
      ) : (
        <span>Image</span>
      )}
    </div>
  );
};

export default CobrandingImgCompany;
