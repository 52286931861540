import { themr } from 'react-css-themr';

import PropertyHeaderDetails from 'legacy/containers/property-header-details';

import BackIcon from 'legacy/components/generic/BackIcon';

import { PropertyHeader as defaultTheme } from 'legacy/css-modules';

const PropertyHeader = ({
  showReportDates,
  addressId,
  dataHcName,
  children,
  propertyContext,
  theme,
  onClickBack
}) => {
  return (
    <header className={theme.PropertyHeader} data-hc-name={dataHcName}>
      <div className={theme.Back}>
        <BackIcon
          onClick={onClickBack}
          className={theme.BackIcon}
          data-hc-name={`${dataHcName}-back-arrow`}
        />
      </div>

      <PropertyHeaderDetails
        showReportDates={showReportDates}
        addressId={addressId}
        propertyContext={propertyContext}
        dataHcName={dataHcName}
      />
      <div className={theme.Aside}>{children}</div>
    </header>
  );
};

PropertyHeader.defaultProps = {
  property: {},
  dataHcName: 'property-header'
};

export default themr('PropertyHeader', defaultTheme)(PropertyHeader);
