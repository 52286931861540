import React from 'react';
import { SingleColumnContent } from 'src/pexp/layout';
import { SubjectMap } from '../CompSelectionMap/SubjectMap';
import { SubjectPhotoCard } from '../SubjectPhotoCard';

import styles from './SubjectMapBanner.css';

interface Props {
  reportId: number;
  dataHcName: string;
}

export const SubjectMapBanner = ({ reportId, dataHcName }: Props) => {
  return (
    <div data-hc-name={dataHcName} className={styles.SubjectMapBanner}>
      <SingleColumnContent className={styles.Content}>
        <SubjectPhotoCard
          reportId={reportId}
          dataHcName={`${dataHcName}-photo-card`}
          className={styles.SubjectPhotoCard}
        />
      </SingleColumnContent>
      <div className={styles.Map}>
        <SubjectMap
          dataHcName={`${dataHcName}-map`}
          reportId={reportId}
          scrollZoom={false}
          satelliteToggle={false}
        />
      </div>
    </div>
  );
};
