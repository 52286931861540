import { watchEvery } from 'legacy/utils/saga';
import { report } from 'hc-ravenjs-logger';
import { call, put, select } from 'redux-saga/effects';
import HC_CONSTANTS from 'HC_CONSTANTS';
import get from 'lodash/get';

import { AVM_KEY_HC } from 'legacy/appstore/constants';

import { getSubjectSelected } from 'selectors/subject';
import { getPropertyLookupId } from 'selectors/report-data';
import { getReportStatusSharedReportConfigDoesNotExist } from 'selectors/report-status';
import { getAvmSelectedKey, getAvmSelected } from 'selectors/avms';
import {
  getShareByEmailApiValues,
  getUniqueLinkApiValues,
  checkShareReportByEmail,
  getUniqueLinkId,
  getUniqueLinkUrlParams,
  getShareableUrl,
  uniqueLinkFormToApi
} from 'selectors/share-report';
import { hasNeverEnteredPersonalizationData } from 'selectors/personalization';
import { getUseVRShareEndpoint } from 'selectors/property-explorer';
import { getFirstPhotoByAddressId } from 'selectors/photos';
import { getUserIsReportOwner } from 'selectors/report-permissions';

import {
  SHARE_REPORT,
  SHARE_REPORT_MODAL_SUBMIT,
  SHARE_REPORT_SUBMIT,
  UNIQUE_LINK_FETCH,
  UNIQUE_LINK_CONFIGURE,
  uniqueLinkFetchSuccess,
  uniqueLinkFetchDoesNotExist,
  uniqueLinkConfigureSuccess,
  uniqueLinkConfigureFailure,
  shareReportOpen,
  shareReportClose,
  shareReportSubmit,
  shareReportSubmitSuccess,
  shareReportSubmitFailure
} from 'actions/share-report';
import {
  personalizationPromptModalShow,
  personalizationPromptModalHide
} from 'actions/personalization';
import { toastShow } from 'actions/toast';
import apiUtil from 'legacy/utils/api';

export function* fetchSharedReportConfig(action) {
  const propertyLookupId = yield select(getPropertyLookupId);
  const url = `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/lookup/${propertyLookupId}/unique_link/`;
  const responseData = yield call(apiUtil.GET, url, null, { expect404: true });
  if (responseData.statusCode === 404) {
    yield put(uniqueLinkFetchDoesNotExist());
  } else {
    yield put(uniqueLinkFetchSuccess(responseData));
  }
}

export function* handleUniqueLinkFetch(action) {
  yield call(fetchSharedReportConfig);
}

export function* shareReport(action) {
  try {
    const userIsReportOwner = yield select(getUserIsReportOwner);
    const hasNoPersonalizationData = yield select(
      hasNeverEnteredPersonalizationData
    );
    const showPersonalizationPrompt =
      hasNoPersonalizationData && userIsReportOwner;
    if (
      showPersonalizationPrompt &&
      !action.payload.bypassPersonalizationPrompt
    ) {
      yield put(personalizationPromptModalShow('EMAIL'));
      return false;
    } else {
      yield put(personalizationPromptModalHide());
      yield put(shareReportOpen());
    }
  } catch (e) {
    report(`Share Report: ${e.message}`, { e, action });
  }
}

export function* submitShareReport(action) {
  try {
    const { formValues } = action.payload;
    const subjectData = yield select(getSubjectSelected);
    const selectedAvmKey = yield select(getAvmSelectedKey);
    const useVRShareEndpoint = yield select(getUseVRShareEndpoint);
    const shareableUrl = yield select(getShareableUrl);
    const addressId = subjectData.addressId;
    const photo = yield select(getFirstPhotoByAddressId(addressId));
    const avm = yield select(getAvmSelected);
    const photos = photo && photo.httpUrl ? [photo.httpUrl] : [];
    let url, apiData, urlParams;
    if (useVRShareEndpoint) {
      url = `${HC_CONSTANTS.VRSHARE_PROXY_URL}/emporium/vrshare/email/`;
      apiData = {
        toEmails: formValues.toEmails,
        comment: formValues.comment || '',
        link: shareableUrl,
        photos: photos
      };
      urlParams = yield select(getUniqueLinkUrlParams);
      urlParams.format = 'pdf';
    } else {
      url = `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/share/`;
      apiData = {
        toEmails: formValues.toEmails,
        comment: formValues.comment || '',
        streetAddress: subjectData.streetAddress,
        zipcode: subjectData.zipcode,
        unit: subjectData.unit,
        selectedAvm: selectedAvmKey,
        link: shareableUrl,
        // If 'user' AVM is selected, but no value exists yet, share 'HC' AVM
        version: selectedAvmKey === AVM_KEY_HC || !avm.value ? 'hc' : 'user',
        format: 'pdf',
        reportType: 'full',
        photos: photos
      };
    }
    yield put(toastShow('Sending report, please wait...'));
    yield call(apiUtil.POST, url, apiData, { urlParams });

    yield put(shareReportSubmitSuccess(formValues, selectedAvmKey));
  } catch (e) {
    yield put(shareReportSubmitFailure(e.messageRaw));
    report(`Share Report Submit: ${e.message}`, { e, action });
  }
}

export function* submitConfigureUniqueLink(action) {
  try {
    const linkConfig = get(action, ['payload', 'linkConfig'], null);
    const lookupId = yield select(getPropertyLookupId);
    const apiDataUniqueLink = !linkConfig
      ? yield select(getUniqueLinkApiValues)
      : uniqueLinkFormToApi(linkConfig);
    let url = `${HC_CONSTANTS.HUELL_PROXY_URL}/emporium/lookup/${lookupId}/unique_link/`;
    const uniqueLinkId = yield select(getUniqueLinkId);
    if (uniqueLinkId) {
      url = `${url}${uniqueLinkId}`;
    }
    const responseData = yield call(apiUtil.POST, url, apiDataUniqueLink);
    yield put(
      uniqueLinkConfigureSuccess(
        uniqueLinkId ? 'UPDATE' : 'CREATE',
        responseData
      )
    );
  } catch (e) {
    console.error(e);
    yield put(uniqueLinkConfigureFailure(e.message));
    report(`Share Report Configure Link: ${e.message}`, { e, action });
  }
}

export function* submitShareReportModal(action) {
  // Delegates additional action dispatches based on form data entry.
  try {
    // NOTE: Submit unique link first to make sure that the link has changed before sending email.
    const uniqueLinkDoesNotExist = yield select(
      getReportStatusSharedReportConfigDoesNotExist
    );
    if (uniqueLinkDoesNotExist) {
      // Call unique link configure as a blocking saga so the link is returned before sending the email
      yield call(submitConfigureUniqueLink);
    }

    const sendShareEmail = yield select(checkShareReportByEmail);
    if (sendShareEmail) {
      const apiDataShareByEmail = yield select(getShareByEmailApiValues);
      yield put(shareReportSubmit(apiDataShareByEmail));
    }

    yield put(shareReportClose());
  } catch (e) {
    report(`Share Report Submit: ${e.message}`, { e, action });
  }
}

export default function registerShareReportSaga() {
  watchEvery({
    [SHARE_REPORT]: shareReport,
    [SHARE_REPORT_MODAL_SUBMIT]: submitShareReportModal,
    [SHARE_REPORT_SUBMIT]: submitShareReport,
    [UNIQUE_LINK_FETCH]: handleUniqueLinkFetch,
    [UNIQUE_LINK_CONFIGURE]: submitConfigureUniqueLink
  });
}
