export const MLS_FETCH = 'MLS_FETCH';
export const MLS_FETCH_SUCCESS = 'MLS_FETCH_SUCCESS';
export const MLS_FETCH_FAILURE = 'MLS_FETCH_FAILURE';

export const mlsFetch = (mlsId) => {
  return {
    type: MLS_FETCH,
    payload: { mlsId }
  };
};

export const mlsFetchSuccess = (mlsId, mlsLookup) => {
  return {
    type: MLS_FETCH_SUCCESS,
    payload: { mlsId, mlsLookup }
  };
};

export const mlsFetchFailure = (mlsId) => {
  return {
    type: MLS_FETCH_FAILURE,
    payload: { mlsId }
  };
};
