import React, { createContext } from 'react';
import { CompId } from 'src/lib/report-api/types/schemas';
import { usePrevious } from 'src/lib/hooks/usePrevious';
import { PhotoPageAction, photosPageReducer } from './PhotosPage.reducer';
import { closePageAction } from './photosPage.actions';

export interface PhotosPageContext {
  reportId?: number;
  photoPageElement?: React.ReactNode;
  hcAddressId?: number;
  onClose?: VoidFunction;
  active?: boolean;
  compId?: CompId;
}

const initialState: PhotosPageContext = {
  active: false
};

export const PhotosPageContext = createContext<{
  state: PhotosPageContext;
  dispatch: React.Dispatch<PhotoPageAction>;
}>({
  state: initialState,
  dispatch: () => null
});

interface Props {
  children?: React.ReactNode;
}

export const PhotosPageProvider = ({ children }: Props) => {
  const [state, dispatch] = React.useReducer(photosPageReducer, {
    ...initialState,
    onClose: () => dispatch(closePageAction)
  });

  const prevActive = usePrevious<boolean | undefined>(state?.active);

  React.useEffect(() => {
    if (prevActive !== state.active && state.active) {
      window.history.pushState({}, '', window.location.pathname);
    }
  }, [prevActive, state]);

  const { photoPageElement } = state;

  return (
    <PhotosPageContext.Provider value={{ state, dispatch }}>
      {children}
      {photoPageElement}
    </PhotosPageContext.Provider>
  );
};
