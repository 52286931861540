import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LoadingIconSimple } from '@hc/component-lib/hclib/components/atoms/icons';

import { Loading as theme } from 'legacy/css-modules/generic';

const SIZES = {
  xs: 'xsmall',
  xsmall: 'xsmall',

  sm: 'small',
  small: 'small',

  m: 'medium',
  medium: 'medium',

  lg: 'large',
  large: 'large',

  xl: 'xlarge',
  xlarge: 'xlarge'
};

class SpinnerPosition extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.loading !== this.props.loading;
  }
  render() {
    const { size, spinnerClassName, spinnerDataHcName, children } = this.props;
    const sizeVal = SIZES[size];
    const spinnerClasses = classNames(theme.SpinnerPosition, spinnerClassName, {
      [theme.xsmall]: sizeVal === 'xsmall',
      [theme.small]: sizeVal === 'small',
      [theme.medium]: sizeVal === 'medium',
      [theme.large]: sizeVal === 'large',
      [theme.xlarge]: sizeVal === 'xlarge'
    });
    return (
      <div className={spinnerClasses} data-hc-name={spinnerDataHcName}>
        {children || <LoadingIconSimple theme={theme} />}
      </div>
    );
  }
}

const Loading = ({
  loading,
  children,
  size,
  overlay,
  fullPage,
  overlayColor,
  Spinner,
  message,
  className,
  dataHcName,
  spinnerClassName,
  spinnerDataHcName,
  ...rest
}) => {
  if (overlay) {
    return (
      <div className={className} {...rest} data-hc-name={dataHcName}>
        {loading && (
          <div
            className={classNames(
              theme['loading-spinner--overlay'],
              theme[overlayColor],
              {
                [theme['loading-spinner--full-page']]: fullPage
              }
            )}
          >
            <SpinnerPosition
              size={size}
              loading={loading}
              spinnerClassName={spinnerClassName}
              spinnerDataHcName={spinnerDataHcName}
            >
              {Spinner ? <Spinner /> : undefined}
            </SpinnerPosition>
            {message && message}
          </div>
        )}
        {children}
      </div>
    );
  } else if (loading) {
    return (
      <span className={className} {...rest} data-hc-name={dataHcName}>
        <SpinnerPosition
          size={size}
          loading={loading}
          spinnerClassName={spinnerClassName}
          spinnerDataHcName={spinnerDataHcName}
        >
          {Spinner ? <Spinner /> : undefined}
        </SpinnerPosition>
        {message && message}
      </span>
    );
  } else {
    return children;
  }
};

Loading.defaultProps = {
  size: 'medium',
  overlay: false,
  fullPage: false,
  overlayColor: 'light'
};

Loading.propTypes = {
  loading: PropTypes.bool,
  overlay: PropTypes.bool, // Renders children with overlay
  fullPage: PropTypes.bool,

  overlayColor: PropTypes.string
};

export default Loading;
