import { watchEvery } from 'legacy/utils/saga';
import { call, put, select } from 'redux-saga/effects';

import HC_CONSTANTS from 'HC_CONSTANTS';
import { getApplicationId } from 'selectors/organization-usage';
import { FETCH_MESSAGES, fetchMessagesSuccess } from 'actions/messages';
import apiUtil from 'legacy/utils/api';

function* fetchMessages(action) {
  const appId = yield select(getApplicationId);
  const responseData = yield call(apiUtil.GET, HC_CONSTANTS.MESSAGE_URL, {
    applicationId: appId
  });
  yield put(fetchMessagesSuccess(responseData));
}

export function registerMessagesSaga() {
  watchEvery({
    [FETCH_MESSAGES]: fetchMessages
  });
}
