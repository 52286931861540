import React from 'react';
import isEqual from 'lodash.isequal';
import get from 'lodash/get';
import {
  FILTER_MATCH_SUBJECT,
  GARAGE_FILTER_OPTIONS,
  STORIES_FILTER_OPTIONS
} from 'legacy/appstore/constants';

import RadioButton from 'legacy/components/RadioButton';

import { RadioSelect as theme } from 'legacy/css-modules';

const getFilterValueMapping = (subjectValue, filterKey) => {
  // fixes issue when subject > 2 stories and garage > 1. reduces subject value back to 2 or 1 respectively
  subjectValue =
    filterKey === 'garageNumCars' && subjectValue > 1 ? 1 : subjectValue;
  subjectValue = filterKey === 'stories' && subjectValue > 2 ? 2 : subjectValue;
  const subjectValueToFilterValue = {
    stories: {
      1: STORIES_FILTER_OPTIONS.singleStory,
      2: STORIES_FILTER_OPTIONS.multiStory
    },
    garageNumCars: {
      0: GARAGE_FILTER_OPTIONS.noGarage,
      1: GARAGE_FILTER_OPTIONS.hasGarage
    }
  };
  const filterKeyMap = get(subjectValueToFilterValue, filterKey, {});
  return get(filterKeyMap, subjectValue, {});
};

const RadioSelect = ({
  options,
  disabled,
  subjectValue,
  filterKey,
  value,
  label,
  dataHcName,
  onChange
}) => {
  const mapping = getFilterValueMapping(subjectValue, filterKey);
  return (
    <div data-hc-name={dataHcName} className={theme.RadioSelect}>
      {options.map((option, i) => (
        <RadioButton
          key={`${dataHcName}-${label}-${option.value}-${i}`}
          {...option}
          disabled={disabled}
          className={theme.RadioButton}
          onChange={(v) => onChange(v)}
          checked={
            value === FILTER_MATCH_SUBJECT
              ? isEqual(mapping, option.value)
              : isEqual(value, option.value)
          }
        />
      ))}
    </div>
  );
};

export default RadioSelect;
