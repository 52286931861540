import { createSelector } from 'reselect';
import {
  getCurrentParams,
  getCurrentQuery,
  getCurrentView
} from '@hc/redux-saga-router-plus/hclib/selectors';

import {
  COMP_TYPE_DEFAULT,
  MAP_ID_REPORT,
  MAP_ID_RENTAL_COMPS
} from 'legacy/appstore/constants';
import {
  VIEWS,
  EFFECTIVE_DATE_VIEWS,
  COMPS_VIEWS,
  RENTAL_COMPS_VIEWS
} from 'legacy/routes/constants';
import { COMP_TYPE_RENTAL } from '../constants';

export const getReportState = (state) => state.propertyExplorer;

export const getReportIsShowingHeaderMapControls = createSelector(
  getReportState,
  (reportState) => {
    return reportState.isShowingHeaderMapControls;
  }
);

export const getReportActiveMapLayerIds = createSelector(
  getReportState,
  (reportState) => reportState.activeMapLayerIds
);

export const getReportScrollToSection = createSelector(
  getReportState,
  (reportState) => reportState.scrollToSection
);

export const getReportSaveUserVersionAgain = createSelector(
  getReportState,
  (reportState) => reportState.saveUserVersionAgain
);

export const getCurrentReportParams = createSelector(
  getCurrentParams,
  getCurrentQuery,
  (params, queryParams) => {
    const combinedParams = {
      ...params,
      ...queryParams
    };
    return {
      ...combinedParams,
      streetAddress: window.decodeURIComponent(combinedParams.streetAddress)
    };
  }
);

export const getIsViewingSharedReport = createSelector(
  getCurrentReportParams,
  (reportParams) => !!reportParams.uid
);

export const getIsEffectiveDateReport = createSelector(getCurrentView, (view) =>
  EFFECTIVE_DATE_VIEWS.includes(view)
);

// Keeping this selector separate from the one above since the logic around
// this could change
export const getUseVRShareEndpoint = createSelector(
  getCurrentReportParams,
  (reportParams) => !!reportParams.uid
);

export const getForecastChartAreaType = createSelector(
  getReportState,
  (reportState) => reportState.forecastChartAreaType
);

export const getReportComments = createSelector(
  getReportState,
  (reportState) => reportState.comments
);

export const getNextViewAfterSaveFilters = (compType) =>
  createSelector(getIsViewingSharedReport, (isViewingShared) => {
    if (isViewingShared) {
      return compType === COMP_TYPE_DEFAULT
        ? VIEWS.COMPS_SELECTION_SHARED
        : VIEWS.RENTAL_COMPS_SELECTION_SHARED;
    } else {
      return compType === COMP_TYPE_DEFAULT
        ? VIEWS.COMPS_SELECTION
        : VIEWS.RENTAL_COMPS_SELECTION;
    }
  });

export const getIsEditingSubject = createSelector(
  getReportState,
  (reportState) => reportState.isEditingSubject
);

export const getLocationUnknownView = (mapId) =>
  createSelector(getIsViewingSharedReport, (isViewingSharedReport) => {
    if (isViewingSharedReport) {
      return mapId === MAP_ID_REPORT
        ? VIEWS.LOCATION_MAP_SHARED
        : mapId === MAP_ID_RENTAL_COMPS
        ? VIEWS.RENTAL_COMPS_SELECTION_MAP_SHARED
        : VIEWS.COMPS_SELECTION_MAP_SHARED;
    } else {
      return mapId === MAP_ID_REPORT
        ? VIEWS.LOCATION_MAP
        : mapId === MAP_ID_RENTAL_COMPS
        ? VIEWS.RENTAL_COMPS_SELECTION_MAP
        : VIEWS.COMPS_SELECTION_MAP;
    }
  });

export const getIsRentalContext = createSelector(getCurrentView, (view) => {
  if (RENTAL_COMPS_VIEWS.includes(view)) {
    return true;
  } else {
    return false;
  }
});

export const getCurrentCompType = createSelector(
  getIsRentalContext,
  (isRentalContext) => (isRentalContext ? COMP_TYPE_RENTAL : COMP_TYPE_DEFAULT)
);

export const getCurrentCompMapView = createSelector(
  getIsViewingSharedReport,
  getCurrentView,
  (isShared, view) => {
    if (isShared) {
      if (COMPS_VIEWS.includes(view)) {
        return VIEWS.COMPS_SELECTION_MAP_SHARED;
      }
      if (RENTAL_COMPS_VIEWS.includes(view)) {
        return VIEWS.RENTAL_COMPS_SELECTION_MAP_SHARED;
      }
    } else {
      if (COMPS_VIEWS.includes(view)) {
        return VIEWS.COMPS_SELECTION_MAP;
      }
      if (RENTAL_COMPS_VIEWS.includes(view)) {
        return VIEWS.RENTAL_COMPS_SELECTION_MAP;
      }
    }
  }
);

export const getCurrentReportView = createSelector(
  getIsViewingSharedReport,
  getIsEffectiveDateReport,
  (isShared, isEffectiveDate) => {
    if (isEffectiveDate) {
      return VIEWS.EFFECTIVE_DATE_REPORT;
    } else if (isShared) {
      return VIEWS.VALUE_REPORT_SHARED;
    } else {
      return VIEWS.VALUE_REPORT;
    }
  }
);
