import { createSelector } from 'reselect';
import get from 'lodash/get';

import { STATUSES } from 'legacy/appstore/constants';

import { checkUrlIsMapTile, checkUrlIsStreetView } from 'legacy/utils/photos';
import { isLoading } from 'legacy/utils/status';

export const getPhotosState = (state) => state.photos;

export const getAllPropertyPhotos = createSelector(
  getPhotosState,
  (photosState) => photosState.properties
);

export const getAllAddressIdsWithPhotos = createSelector(
  getAllPropertyPhotos,
  (propertyPhotoState) => {
    let addressIds = [];
    for (let addressId in propertyPhotoState) {
      const status = get(propertyPhotoState, [addressId, 'status']);
      if (status === 'SUCCESS' || status === 'ERROR') {
        addressIds.push(parseInt(addressId));
      }
    }
    return addressIds;
  }
);

export const getPhotosStateByAddressId = (addressId) =>
  createSelector(
    getAllPropertyPhotos,
    (properties) => properties[addressId] || { photos: [] }
  );

export const getPhotosStatusByAddressId = (addressId) =>
  createSelector(
    getPhotosStateByAddressId(addressId),
    (photosModel) => photosModel.status || STATUSES.INIT
  );

export const getPhotosLoadingByAddressId = (addressId) =>
  createSelector(getPhotosStatusByAddressId(addressId), isLoading);

export const getPhotosByAddressId = (addressId) =>
  createSelector(
    getPhotosStateByAddressId(addressId),
    (photosModel) => photosModel.photos || []
  );

export const getPhotosUrlsByAddressId = (addressId) =>
  createSelector(getPhotosByAddressId(addressId), (photos) =>
    photos.map((p) => p.httpUrl)
  );

export const getPhotosByAddressIdExludingMapTile = (addressId) =>
  createSelector(getPhotosByAddressId(addressId), (photos) =>
    photos.filter((photo) => !checkUrlIsMapTile(photo.httpUrl))
  );

export const getFirstPhotoByAddressId = (addressId) =>
  createSelector(
    getPhotosByAddressId(addressId),
    (photos) => photos && photos.length && photos[0]
  );

export const getHasPhotosByAddressId = (addressId) =>
  createSelector(
    getPhotosByAddressId(addressId),
    (photos) => photos && photos.length > 0
  );

export const getPhotosByMlsNumberAndExternalSourceId = (addressId) =>
  createSelector(getPhotosByAddressId(addressId), (photos) => {
    const photosByListing = {};
    photos.forEach((photo) => {
      if (photo.mlsNumber) {
        if (!photosByListing.hasOwnProperty(photo.mlsNumber)) {
          photosByListing[photo.mlsNumber] = {};
        }
        if (
          !photosByListing[photo.mlsNumber].hasOwnProperty(
            photo.externalSourceId
          )
        ) {
          photosByListing[photo.mlsNumber][photo.externalSourceId] = [];
        }
        photosByListing[photo.mlsNumber][photo.externalSourceId].push(photo);
      }
    });
    return photosByListing;
  });

export const getPhotosStreetViewUrlByAddressId = (addressId) =>
  createSelector(getPhotosByAddressId(addressId), (photos) => {
    // eslint-disable-next-line
    for (let i = photos.length - 1; i < 0; i--) {
      if (checkUrlIsStreetView(photos[i].httpUrl)) {
        return photos[i].httpUrl;
      }
    }
    return null;
  });

export const getAllPhotos = createSelector(
  getAllPropertyPhotos,
  (propertyPhotoState) => {
    let photoMapping = {};
    for (let addressId in propertyPhotoState) {
      photoMapping[addressId] = propertyPhotoState[addressId].photos;
    }
    return photoMapping;
  }
);

export const getPhotosStreetViewStatusMapping = createSelector(
  getAllPropertyPhotos,
  (propertyPhotoState) => {
    let streetViewStatusMapping = {};
    for (let addressId in propertyPhotoState) {
      streetViewStatusMapping[addressId] =
        propertyPhotoState[addressId].streetViewStatus;
    }
    return streetViewStatusMapping;
  }
);

export const getPhotosStreetViewStatus = (addressId) =>
  createSelector(
    getPhotosStateByAddressId(addressId),
    (photosModel) => photosModel.streetViewStatus
  );
