import { EVENTS } from 'legacy/appstore/events';

export const ADDRESS_PARTS_REQUEST = 'ADDRESS_PARTS_REQUEST';

export const getAddressPartsAction = (slug) => ({
  type: ADDRESS_PARTS_REQUEST,
  payload: { slug },
  meta: {
    analytics: {
      event: EVENTS.SEARCH_SUBJECT_PROPERTY,
      props: {
        address_slug: slug
      }
    }
  }
});
