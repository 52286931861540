import React from 'react';
import InputRange, { InputRangeClassNames } from 'react-input-range';
import classNames from 'classnames';
import DEFAULT_CLASS_NAMES from 'react-input-range/lib/js/input-range/default-class-names';
import styles from './RangeSlider.css';
import { useComponentDidUpdate } from 'src/lib/hooks/useComponentDidUpdate';
import { Size, Value } from 'src/lib/components/PhotoGrid/types';

export type PartialInputRangeClassNames = Partial<InputRangeClassNames>;

interface Props {
  theme: PartialInputRangeClassNames;
  step: number;
  className?: string;
  minValue: Size;
  maxValue: Size;
  dataHcName: string;
  disabled?: boolean;
  onChange: (v: Value) => void;
  initialValue: Value;
  onChangeStart?: (v: Value) => void;
  onChangeComplete: (v: Value) => void;
  formatLabel?: (v: number, l: string) => string;
}

export const RangeSlider = ({
  className,
  theme,
  minValue,
  maxValue,
  step,
  dataHcName,
  onChange,
  initialValue,
  disabled = false,
  onChangeStart,
  onChangeComplete,
  formatLabel = (v, l) => l
}: Props) => {
  const [value, setValue] = React.useState<Value>(initialValue);

  useComponentDidUpdate(() => {
    // If we pass in a new initial value then update
    if (value !== initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (newValue: Value) => {
    if (value !== newValue) {
      setValue(newValue);
      onChange(newValue);
    }
  };

  const buildClassName = (className: keyof InputRangeClassNames) =>
    classNames(
      DEFAULT_CLASS_NAMES[className],
      theme[className],
      styles[className]
    );

  const irTheme: InputRangeClassNames = {
    activeTrack: buildClassName('activeTrack'),
    disabledInputRange: buildClassName('disabledInputRange'),
    inputRange: buildClassName('inputRange'),
    labelContainer: buildClassName('labelContainer'),
    maxLabel: buildClassName('maxLabel'),
    minLabel: buildClassName('minLabel'),
    slider: buildClassName('slider'),
    sliderContainer: buildClassName('sliderContainer'),
    track: buildClassName('track'),
    valueLabel: buildClassName('valueLabel')
  };

  return (
    <div data-hc-name={dataHcName} className={className}>
      <InputRange
        classNames={irTheme}
        minValue={minValue}
        maxValue={maxValue}
        value={value}
        formatLabel={formatLabel}
        step={step}
        disabled={disabled}
        onChange={(v) => handleChange?.(v)}
        onChangeStart={onChangeStart}
        onChangeComplete={onChangeComplete}
      />
    </div>
  );
};
