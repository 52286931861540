import { Component } from 'react';
import { Tooltip } from '@hc/component-lib/hclib/components/molecules/tooltip';

import TooltipDefaultTrigger from 'legacy/components/generic/TooltipDefaultTrigger';

import { Tooltip as theme } from 'legacy/css-modules/generic';

/**
 * Apply our theme to the component library tooltip
 */
export default class TooltipWrapper extends Component {
  static defaultProps = {
    trigger: <TooltipDefaultTrigger />
  };

  render() {
    const props = this.props;

    return <Tooltip theme={theme} {...props} trigger={props.trigger} />;
  }
}
