import { connect } from 'react-redux';

import { preferencesSaveFiltersModalToggle } from 'actions/preferences';

import { getPreferencesSaveFiltersModalActive } from 'selectors/preferences';

import SaveFiltersModal from 'legacy/components/SaveFiltersModal';

const mapStateToProps = (state) => {
  return {
    active: !!getPreferencesSaveFiltersModalActive(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(preferencesSaveFiltersModalToggle())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveFiltersModal);
