import { EVENTS } from 'legacy/appstore/events';

import { getTypeOfMapPinsShownOnSearchPage } from 'selectors/analytics';

export const MLS_LISTINGS_GET_REQUEST = 'MLS_LISTINGS_GET_REQUEST';
export const MLS_LISTINGS_GET_SUCCESS = 'MLS_LISTINGS_GET_SUCCESS';
export const MLS_LISTINGS_HOVER = 'MLS_LISTINGS_AFTER_HOVER';
export const MLS_LISTINGS_TOGGLE_SHOW_ON_MAP =
  'MLS_LISTINGS_TOGGLE_SHOW_ON_MAP';
export const MLS_LISTINGS_PROPERTY_PREVIEW = 'MLS_LISTINGS_PROPERTY_PREVIEW';

export const mlsListingsGetListings = () => ({
  type: MLS_LISTINGS_GET_REQUEST,
  payload: {}
});

export const mlsListingsGetSuccess = (propertyList) => ({
  type: MLS_LISTINGS_GET_SUCCESS,
  payload: { propertyList }
});

export const mlsListingsHover = (propertyId) => ({
  type: MLS_LISTINGS_HOVER,
  payload: { propertyId }
});

export const mlsListingsToggleShowOnMap = () => ({
  type: MLS_LISTINGS_TOGGLE_SHOW_ON_MAP,
  meta: {
    analytics: {
      event: EVENTS.MAP_CHANGE_PIN_TYPE,
      propsSelector: getTypeOfMapPinsShownOnSearchPage
    }
  }
});

export const mlsListingsPropertyPreview = (property = {}) => ({
  type: MLS_LISTINGS_PROPERTY_PREVIEW,
  payload: { property },
  meta: {
    analytics: {
      event: EVENTS.SEARCH_MLS_LISTINGS_PROPERTY_PREVIEW,
      props: {
        street_address: property.streetAddress,
        zipcode: property.zipcode,
        unit: property.unit
      }
    }
  }
});
