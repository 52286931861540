import { Component } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { findDOMNode } from 'react-dom';

import { PROPERTY_DATA_SOURCES } from 'legacyGraphQL/constants';

import { PROPERTY_DETAILS_FIELD_COMPONENTS } from 'legacy/utils/property-details';
import PropertyAttributeValueContainer from 'legacy/containers/property-attribute-value';

import { PropertyCompareTable as theme } from 'legacy/css-modules';

const EXPANDABLE = {
  publicRemarks: true
};

const FIELD_CLASSNAMES = {
  salesPrice: theme.TempBorder,
  basePriceAdjustedDiffSqft: theme.TempBorder,
  valueAdjustedDiffSqft: theme.TempBorder,
  leasedPrice: theme.TempBorder,
  rentalBasePriceAdjustedDiffSqft: theme.TempBorder,
  rentalValueAdjustedDiffSqft: theme.TempBorder
};

class PropertyCompareTable extends Component {
  state = {
    expanded: {},
    rowHeights: {}
  };

  // Keep track of row heights so rows line up when there are multiple compare tables
  rows = {};

  componentDidMount() {
    this.updateRowHeights();
  }

  componentDidUpdate() {
    this.updateRowHeights();
  }

  isExpanded = (key) => get(this.state, ['expanded', key], false);

  handleExpandToggle = (key) => {
    this.setState({
      expanded: {
        ...this.state.expanded,
        [key]: !this.isExpanded(key)
      },
      rowHeights: {
        ...this.state.rowHeights,
        [key]: undefined
      }
    });
  };

  findRowHeight = (key) => {
    const row = findDOMNode(this.rows[key]);
    if (row) {
      return row.offsetHeight;
    }
  };

  updateRowHeights = () => {
    const { addressId, attributes, onRowHeightsUpdated, loadedByAttribute } =
      this.props;
    const { rowHeights } = this.state;
    let rowHeightsUpdated = {};
    for (let a = 0; a < attributes.length; a++) {
      const { key } = attributes[a];
      if (!rowHeights[key] && loadedByAttribute[key]) {
        const rowHeight = this.findRowHeight(key);
        if (rowHeights[key] !== rowHeight) {
          rowHeightsUpdated[key] = rowHeight;
        }
      }
    }
    if (!isEmpty(rowHeightsUpdated)) {
      onRowHeightsUpdated(rowHeightsUpdated, addressId);
      this.setState({
        rowHeights: {
          ...this.state.rowHeights,
          ...rowHeightsUpdated
        }
      });
    }
  };

  render() {
    const {
      addressId,
      property,
      showLabels,
      editing,
      loadedByAttribute,
      attributes,
      compType,
      rowHeights
    } = this.props;
    const { rowHeights: rowHeightsState } = this.state;
    return (
      <div>
        {attributes.map((attribute) => {
          if (!attribute) return null;
          const { key } = attribute;
          const EditComponent =
            PROPERTY_DETAILS_FIELD_COMPONENTS[attribute.fieldType];
          const expandable = EXPANDABLE[key];
          const expanded = this.isExpanded(key);
          const style =
            rowHeightsState[key] && rowHeights[key] && loadedByAttribute[key]
              ? { height: `${rowHeights[key]}px` }
              : undefined;
          return (
            <div
              key={`compare-attr-${property.addressId}-${key}`}
              className={classNames(theme.Row, FIELD_CLASSNAMES[key], {
                [theme.expandable]: expandable,
                [theme.expanded]: expandable && expanded
              })}
              style={style}
              ref={(node) => {
                this.rows[key] = node;
              }}
            >
              {showLabels && (
                <div
                  data-hc-name="property-detail-label"
                  className={theme.Label}
                >
                  {attribute.label}
                </div>
              )}
              {editing && EditComponent ? (
                <EditComponent
                  name={key}
                  value={property[key]}
                  property={property}
                  {...attribute.fieldProps}
                />
              ) : (
                <PropertyAttributeValueContainer
                  dataHcName={
                    addressId === 'subject' ? 'subject-value' : 'comp-value'
                  }
                  className={theme.Value}
                  addressId={addressId}
                  compType={compType}
                  attrKey={key}
                  source={PROPERTY_DATA_SOURCES.ANY}
                  property={property}
                  expandable={expandable}
                  expanded={expanded}
                  onExpandToggle={this.handleExpandToggle}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

PropertyCompareTable.defaultProps = {
  onRowHeightsUpdated: () => {},
  rowHeights: {},
  loadedByAttribute: {}
};

export default PropertyCompareTable;
