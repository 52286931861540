import get from 'lodash/get';
import { VR_RECOMPUTE_SUCCESS } from 'actions/edit-report';
import {
  GET_REPORT_NEARBY_PROPERTIES_SUCCESS,
  GET_REPORT_USER_VERSION_SUCCESS,
  GET_REPORT_RENTAL_SUCCESS
} from 'actions/get-report';
import { COMPS_REFRESH_READY_FOR_RECOMPUTE } from 'actions/comps';
import { RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE } from 'actions/rental-comps';
import { EFFECTIVE_DATE_GET_REPORT_SUCCESS } from 'actions/effective-date';

const INITIAL_STATE = {
  nearbyProperties: [],
  nearbyPropertiesRental: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EFFECTIVE_DATE_GET_REPORT_SUCCESS:
      return {
        ...state,
        nearbyProperties: action.payload.report.compsFarmList
      };

    case GET_REPORT_NEARBY_PROPERTIES_SUCCESS:
      return {
        ...state,
        nearbyProperties: action.payload.nearbyProperties || []
      };

    case GET_REPORT_USER_VERSION_SUCCESS:
      return {
        ...state,
        nearbyProperties: action.payload.report.nearbyProperties || []
      };

    case GET_REPORT_RENTAL_SUCCESS:
      return {
        ...state,
        nearbyPropertiesRental: action.payload.report.compsFarmList || []
      };

    case VR_RECOMPUTE_SUCCESS:
      const rentalCompFarm = get(action, [
        'payload',
        'report',
        'metadata',
        'rentalCompFarm'
      ]);
      return {
        ...state,
        nearbyProperties: get(
          action,
          ['payload', 'report', 'nearbyProperties'],
          state.nearbyProperties
        ),
        nearbyPropertiesRental:
          rentalCompFarm && rentalCompFarm.length
            ? Object.keys(rentalCompFarm)
            : state.nearbyPropertiesRental
      };

    case COMPS_REFRESH_READY_FOR_RECOMPUTE:
      return {
        ...state,
        nearbyProperties: action.payload.hcCompsFarmList
      };

    case RENTAL_COMPS_REFRESH_READY_FOR_RECOMPUTE: {
      return {
        ...state,
        nearbyPropertiesRental: action.payload.hcCompsFarmList
      };
    }

    default:
      return state;
  }
};
