import { AxiosResponse, AxiosError } from 'axios';
import {
  all,
  actionChannel,
  take,
  put,
  call,
  select,
  takeEvery
} from 'redux-saga/effects';
import {
  COBRANDING_FETCH,
  CobrandingFetchAction,
  cobrandingFetchSuccess,
  cobrandingFetchError,
  CobrandingRequiredAction,
  COBRANDING_REQUIRED,
  cobrandingFetch
} from '../actions/cobranding.actions';
// TODO: REMOVE THIS IMPORT
// @ts-ignore: Importing from untyped legacy directory
import { getToken } from 'selectors/account-details';
import { selCobrandingStatus } from '../selectors/cobranding.selectors';
import { STATUSES } from 'src/constants';
import { ReportApi } from '../api/reportApi.api';
import { Personalization } from 'src/lib/huell';

export function* handleCobrandingFetch(action: CobrandingFetchAction) {
  try {
    const token: string = yield select(getToken);
    const response: AxiosResponse<Personalization> = yield call(
      ReportApi.fetchCobranding,
      token,
      action.payload.id
    );
    yield put(cobrandingFetchSuccess(action.payload.id, response.data));
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status !== 404) {
        console.log('[TODO] Cobranding Error To Sentry');
      }
      yield put(
        cobrandingFetchError(action.payload.id, error.response.data.message)
      );
    } else if (error.request) {
      yield put(
        cobrandingFetchError(action.payload.id, error.request.data.message)
      );
    }
  }
}

// Using an action channel prevents duplicate api requests from sibling components by allowing
// the _FETCH action dispatched from the first _REQUIRED action to update state
// before any additional _REQUIRED actions are evaluated
function* takeCobrandingRequired() {
  const requiredChannel: typeof COBRANDING_REQUIRED = yield actionChannel(
    COBRANDING_REQUIRED
  );
  while (true) {
    const action: CobrandingRequiredAction =
      yield take<CobrandingRequiredAction>(requiredChannel);
    const { id } = action.payload;
    const status: STATUSES | undefined = yield select(selCobrandingStatus(id));
    if (!status) {
      yield put(cobrandingFetch(id));
    }
  }
}

export function* cobrandingSagas() {
  yield takeEvery(COBRANDING_FETCH, handleCobrandingFetch);
  yield all([call(takeCobrandingRequired)]);
}
